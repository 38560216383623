import React from 'react';
import { 
    Button, 
    Modal, 
    ModalHeader, 
    ModalBody, 
    ModalFooter,
    Form, 
    FormGroup, 
    Label, 
    Input, 
    Col,
    Row,
    FormFeedback
} from 'reactstrap';
import '../../../pages/admin/components/toolbar';
import './modal-contactos.css'
import i18next from '../../../../i18n';

function ModalContactos(props){
    var contactsLoadedView = [];
    let contacts = props.contacts? props.contacts :[];
    let contactsLoaded = props.contactsLoaded? props.contactsLoaded:[];
    
    for (var i = 0; i < contactsLoaded.length; i++) {
        contactsLoadedView.push( <Row className="mt-2" key={i}>
            <Col sm="4">
                <Input type="text" value={contactsLoaded[i].name} readOnly/>
            </Col>
            <Col sm="4">
                <Input type="text" value={contactsLoaded[i].phone} readOnly/>
            </Col>
            <Col sm="2" className="text-center containerIconDelete">
                <i className="fa fa-2x fa-times-circle" data-id={i} onClick={props.deleteContactLoaded} title={i18next.t('delete_contact')}></i>
            </Col>
            <Col sm="2"  className="text-center" >
            {
                contactsLoaded[i].hsm_sent==true?
                (<Button color="warning" onClick={props.sendHSM}  data-id={i}>
                    HSM
                </Button>):
                (<Button color="success" onClick={props.sendHSM}  data-id={i}>
                    HSM
                </Button>)
            }
                
            </Col>
        </Row>);
    } 
    
    return (
        <div>
            <Modal isOpen={props.isOpen} toggle={props.toggleContactModal} fade>
                <ModalHeader toggle={props.toggleContactModal}>Registrar nuevos usuarios</ModalHeader>
                <Form id="form-addcontacts" onSubmit={props.saveContacts} role="form">
                     <ModalBody>
                        <Row className="mt-4">
                            <Col sm="10" className="newContactsContainer">
                                <h3>Nuevos contactos</h3>
                                <Button color="success" onClick={props.addContact} className="mt-3">{i18next.t('add')}</Button>
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col sm="5"><Label className="control-label required-field">{`Nombre del contacto:`}</Label></Col>
                            <Col sm="5"><Label className="control-label required-field">{`Número telefónico:`}</Label></Col>
                        </Row>
                        <div>

                        </div>
                        <FormGroup id="newContactsView">
                        {
                            contacts.map((val, idx)=> {
                                let nameId = `name-${idx}`, phoneId = `phone-${idx}`
                                return (
                                <Row className="mt-2" key={idx}>
                                    <Col sm="5">
                                        <Input onChange={props.onChangeContact} type="text" name={nameId} data-id={idx} id={nameId} data-type="name" className="name" value={val.name}></Input>
                                        <Label className="label-error" >{val.errorName}</Label>
                                    </Col>
                                    <Col sm="5">
                                        <Input onChange={props.onChangeContact} type="text" name={phoneId} data-id={idx} id={phoneId} data-type="phone" maxLength="15" className="phone" value={val.phone}></Input>
                                        <Label className="label-error" >{val.errorPhone}</Label>
                                    </Col>
                                    { idx!=0 ? 
                                        (
                                            <Col sm="2" className="text-center containerIconDelete">
                                                <i className="fa fa-2x fa-times-circle" onClick={props.deleteContact} data-id={idx} title={i18next.t('delete_contact')}></i>
                                            </Col>
                                        ): 
                                        (<span> </span>) 
                                    }
                                    
                                </Row>
                                )
                            })
                        }
                        </FormGroup>

                        <div className="divInline">
                            <small className="colorRed">*</small><small>Campos requeridos</small>
                        </div>

                        {
                            contactsLoaded.length>0?
                            (<Row className="mt-4">
                                <Col sm="12">
                                    <h3>Lista de contactos</h3>
                                </Col>
                            </Row>):
                            (<span></span>)
                        }
                        
                        <div id="contactsLoadedView">
                            {contactsLoadedView}
                        </div>

                        {
                            contactsLoaded.length>0?
                            (<Row>
                                <Col sm="12" className="mt-4 mb-4">
                                    Para enviar el mensaje HSM a todos los contactos registrados <a href="#" onClick={props.sendHSMToAll}>Click aqui</a>
                                </Col>
                                <Col sm="12">
                                    <FormFeedback valid id="messageHSM">Mensajes HSM han sido enviados!</FormFeedback>
                                </Col>
                                <Col sm="12">
                                    <Label id="loading-hsm"><small >Enviando mensajes...</small></Label>
                                </Col>
                            </Row>):
                            (<span></span>)
                        }

                    </ModalBody>
                    <ModalFooter>
                        <Button color="default" className="btn btn-outline-secondary" onClick={props.toggleContactModal}>{i18next.t('cancel')}</Button>
                        <Button type="submit"  color="success" className="btn-guarumo-modal-sm" size="lg">Registrar</Button>
                    </ModalFooter> 
                </Form>
            </Modal>
        </div>
    );
}
  
export default ModalContactos