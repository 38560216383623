import { NodeModel } from "storm-react-diagrams";
import SMPortModel from "../../simplePortModel/SMPortModel";
import i18next from "../../../../../../i18n";

class HANodeModel extends NodeModel {
	extras = {
		text: i18next.t('without_text'),
		title: i18next.t('start_api').toUpperCase(),
		home_element: 'set-home',
		width: 200,
		dbId: 0,
		keyword: "",
		evento: "",
		evento_text: ""
	}
	removeBox = null
	changeHome = null
	toggleEdit = null
	
	constructor() {
		super("ha");
		this.addPort(new SMPortModel(false, "left", 'ha'));
		this.addPort(new SMPortModel(true, "right", 'ha'));
	}
}

export default HANodeModel