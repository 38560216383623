import { AbstractNodeFactory } from "storm-react-diagrams";
import React from "react";

import UFNodeWidget from "./UFNodeWidget";
import UFNodeModel from "./UFNodeModel";

class UFNodeFactory extends AbstractNodeFactory {
	constructor() {
		super("uf");
	}

	generateReactWidget(diagramEngine, node) {
		return <UFNodeWidget node={node} />
	}

	getNewInstance() {
		return new UFNodeModel();
	}
}

export default UFNodeFactory