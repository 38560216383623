import React, { Component } from 'react';
import { connect } from "react-redux";
import i18next from '../../../../../i18n';

import TRIGGERModalLayout from './trigger-modal-layout';
import SMPortModel from '../simplePortModel/SMPortModel';
import { getHandlerPOST,getHandlerPUT,getHandlerGET } from "../../../../utils/handlersApi";
import { cerrarSesion,isAuthorizationValid,isEmpty } from '../../../../utils/commonFunction'

class TRIGGERModal extends Component {
    
    constructor(props){
        super(props)
        this.state = {
            nodeId: "",
            title: "",
            listAllLinks: [],
            listLinksAdded: [],
            linkSelected: { value:'',label:'' },
            botversion_id: 0,
        }
    }
    
    componentWillMount(){
        isAuthorizationValid();
    }

    componentDidMount(){
        this.getLinks();       
    }

    toggleModuleModal = () => {  
        const { node , node: { extras: { listLinks = [], listEvents = [] } } } = this.props.modal; 
        const portsModal = node.getPorts();
        const kportsModal = Object.keys( portsModal );

        if ( listEvents.includes('click') && listLinks.length == 0 ) {
            alert(i18next.t('add_links_event'));
            return ;
        }

        // delete all port from node
        kportsModal.map((key)=>{
            const port = portsModal[key];
            const links = port.getLinks();
            const klinks = Object.keys(links);
            if(port.name != "left"){
                klinks.map((k)=>{
                    // delete links of port
                    links[k].remove();
                });
                // delete port
                node.removePort(port);
            }
        });

        // add events port to node 
        listEvents.map((itemEvent)=>{            
            if(itemEvent !== 'click'){
                // update variable kportsModal
              const kportsModal = Object.keys( portsModal );            
              const exitedPort = kportsModal.filter(( item ) =>  portsModal[item].name == `${itemEvent}`  );
              if ( isEmpty(exitedPort) ){
                  this.props.modal.node.addPort(new SMPortModel(true, `${itemEvent}`,'trigger'));
              }
            }
        });

        // add lisnks port to node
        listLinks.map((ite,index)=>{
            // update variable kportsModal
            const kportsModal = Object.keys( portsModal );            
            const exitedPort = kportsModal.filter(( item ) =>  portsModal[item].name == "right-" + index  );
            if ( isEmpty(exitedPort) ){
                this.props.modal.node.addPort(new SMPortModel(true, `right-${index}`,'trigger'));
            }        
        });
        this.forceUpdate();
        this.props.toggleModuleModal('trigger');
    }

    getLinks = () => {
        const URL = HOST_API + `/api/bot/template_links/${this.props.botId}`;
        getHandlerGET( URL )
        .then((res)=>{
            if(res.status==200&&res.data.links){
                this.setState({ listAllLinks: res.data.links });
            }
            else {
                console.log('No hay plantilla: ',res.data.msg); 
            }
        })
        .catch((error)=>{
            console.log('Error on getLins: ',error.message);            
        });
    }

    closeModal = () => {
        this.props.closeModal('m17');
        this.forceUpdate();        
    }

    handleEvents = (event = '') => {
        const listEventAdded = this.props.modal.node.extras['listEvents'];
        if(listEventAdded.includes(event)){
            // delete, item exists in array 
            const index = listEventAdded.indexOf(event);
            if (index > -1) {
                listEventAdded.splice(index, 1);
            }
            // if events's click event, clear list links added
            if(event === 'click'){
                this.props.modal.node.extras['listLinks'] = [];
            }
        }else{
            // add, item not exits in array
            listEventAdded.push(event);
        }
        this.forceUpdate();
    }
 
    handleChangeLink = (linkSelected) => this.setState({ linkSelected });

    addLinkToList = () => {
        const listLinksAdded = this.props.modal.node.extras['listLinks'];
        const linkSelected = this.state.linkSelected;
                
        if( listLinksAdded.length > 4 ){
            alert(i18next.t('add_link_msg'));
            return;
        }

        if (listLinksAdded.includes(linkSelected.value)) {
            alert(i18next.t('add_link_msg2'));
            return;
        }

        // add link to list links added on extras
        if(linkSelected.value !== ''){
            listLinksAdded.push(linkSelected.value);
            this.setState({ linkSelected: { value:'',label:'' } } );
            this.forceUpdate();
        }else{
            alert(i18next.t('select_link'));
        }
        
    }

    deleteLinkFromList = (index) => {
        const listLinksAdded = this.props.modal.node.extras['listLinks'];
        if (index > -1) {
            listLinksAdded.splice(index, 1);
            this.forceUpdate();
        }
    }

    render() {
        return (
            <TRIGGERModalLayout
            linkSelected = {this.state.linkSelected}
            listAllLinks = { this.state.listAllLinks }
            listLinksAdded = {this.props.modal.node.extras.listLinks || [] }
            listEventsAdded = {this.props.modal.node.extras.listEvents || [] }
            
            isOpen = { this.props.modal.isOpen } 
            botversion_id = { this.props.botversion_id }
            botId = { this.props.botId }
            
            handleChangeLink = { this.handleChangeLink }
            addLinkToList = { this.addLinkToList }
            deleteLinkFromList = {this.deleteLinkFromList}
            handleEvents = { this.handleEvents }
            toggleModuleModal = { this.toggleModuleModal }
            closeModal = { this.closeModal }

          />
        )
    }
}

export default TRIGGERModal
