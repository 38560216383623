var d3 = require('d3');
/**
 * 
 */
const createFilterBar = (nameDiv,datos,formatoD3,group,botID,dateini,datefin, colorChosen) => {

   
    const node = document.createElement('div');
    node.className = "svg-container";
    node.id="chart_"+nameDiv;
  
    var div='#'+nameDiv;
    var chart=document.getElementById('content-'+nameDiv);
  
    const WIDTH = chart.offsetWidth;
    const HEIGHT = chart.offsetHeight;
    const margin = { top: 40, right: 20, bottom: 20, left: 20 };

    
    
    var  width =  WIDTH - margin.left - margin.right,
   height = height = HEIGHT - margin.top - margin.bottom;

   const chartComponentSelection = d3.select("#chart_"+nameDiv);
   chartComponentSelection.attr(
       "style",
       "padding-bottom: " +
         Math.ceil(
           (height + margin.top + margin.bottom) *
             100 /
             (width + margin.left + margin.right)
         ) +
         "%"
     );
   
    var svg = d3.select(node)
            .append("svg")
            .attr("preserveAspectRatio", "xMinYMin meet")
            .attr(
                "viewBox",
                "0 0 "
                  .concat(width + margin.left + margin.right )
                  .concat(" ")
                  .concat(height + margin.top + margin.bottom )
              )
            .classed("svg-content", true)
            ;

    var plot = svg
    .append("g")
    .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
    .attr("id", "filterBarPlot")
    ;

    plot.select("#filterBarPlot_plataform")
        .append("g")
        .attr("id", "filterBarPlot_plataform")

    
    var dataL = margin.left;
    var offset = 80;


        
    plot.append("text")
        .text("Plataforma: ")
		.attr("class","filterTitle")
		.attr("x",dataL)
		.attr("y",height/5)	
        ;
    dataL=("Plataforma".length)*6 + offset
    
    plot.append("text")
        .text(group)
        .attr("class","filterText")
		.attr("id",group.replace(/\s/g,"_"))
		.attr("x",dataL)
        .attr("y",height/5)	
        .attr("fill",colorChosen)
        ;
        
    dataL +=  group.length + offset
    plot.select("#filterBarPlot_bot")
        .append("g")
        .attr("id", "filterBarPlot_bot")

    plot.append("text")
		.text("Bot: ")
		.attr("class","filterTitle")
		.attr("x",dataL)
		.attr("y",height/5)	
        ;
    
        dataL +=  "Bot: ".length * 9
    
    plot.append("text")
		.text(botID)
		.attr("class","filterText")
		.attr("x",dataL)
        .attr("y",height/5)	
        .attr("fill",colorChosen)
        ;
   
 
        dataL +=  botID.length + offset
        
    // List of groups (here I have one group per column)
    var allGroup = ["valueA", "valueB", "valueC"]
        
// add the options to the button
/*var select = plot.append('select')
    .attr('class','select')
  .on('change',onchange)

var options = select
.selectAll('option')
  .data(allGroup).enter()
  .append('option')
      .text(function (d) { return d; })
        .attr("value", function(d) {
    return d;
    });

function onchange() {
  selectValue = d3.select('select').property('value')
  d3.select('body')
      .append('p')
      .text(selectValue + ' is the last selected option.')
};
*/
    return node;

}

export default createFilterBar;