import React, {useState} from 'react';
import {
  Col,
  Row,
  Container,
  Form,
  Button,
  Input,
  Label,
  FormGroup,
  UncontrolledTooltip
 } from 'reactstrap';
 import Admin from '../../admin/containers/admin';
 import SliceMenuApp from '../../admin/components/slice-menu';
 import ToolbarApp from '../../admin/components/toolbar';
 import BotMenuOptions from'../../menus/bot-menu-options';
 import './bot-ia-layout.css';
 import DataTableFilter from "../../analytics/infoViz/common/dataTableFilter";
 import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
 import { faPen, faTrash, faSave, faArrowLeft, faTimes, faCheck } from '@fortawesome/free-solid-svg-icons'
 import { find, propEq } from "ramda";
 import qs from 'qs';
 import i18next from '../../../../i18n';

function BotIALayout(props){
    
    let cantidadTotal = 0;

    var styles ={
        height: {
            width: 100 
        } 
    };

    let handleClick = value => {
        console.log("value", value);
        const { id } = value;
       
      };

  let operator = props.uaQuestions || [];

  let selectedQ = [],allData=[], removeAll=false;
 
      /*AGREGADO EL DIA 13 DE MARZON* */
  const [fileName, setFileName] = useState(''); // Estado para almacenar el nombre del archivo seleccionado
  const updateFileName = (event) => {
      const file = event.target.files[0];
      if (file) {
          setFileName(file.name);
      }
  };
/*FIN* */

  var dataTable=null;
  if(operator[0]){     
       dataTable = {
          columns: [
            {
              label: 'id',
              field: 'id',
              sort: 'asc',
             // width: 150
            },
            {
                label: i18next.t('phrase'),
                field: i18next.t('phrase'),
                sort: 'asc',
               // width: 150
            },
            {
                label: i18next.t('quantity'),
                field: i18next.t('quantity'),
                sort: 'asc',
               // width: 150
            },
        ],
            rows: []};
            const rows = props.uaQuestions?( props.uaQuestions.map((op, index) => {
              console.log('op.repetitions ',op);
              const fin=[];
              fin.push(op.id);
              fin.push(op.question);
              fin.push(op.repetitions);
              cantidadTotal += op.repetitions;
              dataTable.rows.push(fin);  
              return op;
          })):[];
      }


    const options = {
      
        filterType: "textField",
        fixedHeader: true,
        sort: true,
        search: true,
        selectableRows: "multiple",
        responsive: "scrollMaxHeight",
        rowsPerPage: 100,
        rowHove: true,
        selectableRowsHeader: true,
        onTableChange: (action, state) => {
            console.log("ONCHANE ACTION ",action);
            console.dir("ONCHANE STATE ",state);
            if(action=='rowsSelect')
            {
                console.log("one");
                selectedQ = state.selectedRows.data;
                allData = state.data;
                console.log("selectedQ.length ",selectedQ.length);
                console.log("allData.length ",allData.length);
                if(selectedQ.length == allData.length)
                    removeAll=true;
                 else
                    removeAll=false;

            }
            else if(action=='rowDelete')
            {
                console.log("two");
                if(removeAll){
                    selectedQ.map((op, index) => {
                        const uaq= find(propEq("index", op.index ))(allData);
                        console.log("uaq.data[0] ",uaq.data[0]);
                        props.deleteAllUAQuestion(uaq.data[0]);
                    })
                    //props.deleteAllUAQuestion();
                    removeAll=false;
                }else{
                    selectedQ.map((op, index) => {
                        const uaq= find(propEq("index", op.index ))(allData);
                        props.resolveUAQuestion(uaq.data[0]);
                    })
                }
                
            }
          },
          onRowsClick: (rowData, rowMeta) => {
            console.log("rowData", rowData);
            handleClick(this.state.data[rowMeta.dataIndex]);
          },
          onRowsExpand: (curExpanded, allExpanded) => {
            console.log("rowExpand", curExpanded, allExpanded[0]);
            handleClick(this.state.data[allExpanded[0].dataIndex]);
          }
       
      };
      
          var title = i18next.t('ia_message6') + ' ('+cantidadTotal+')';

    return (
        <div className="BotLayout">
          <Admin>
            <SliceMenuApp>
              <BotMenuOptions botId={props.botId} ia_activated={props.ia_activated}/>
            </SliceMenuApp>
            <Container fluid>
                <ToolbarApp>
                    <Row>
                        <Col xs="12" className="text pdtoolbar">
                            <h2 className="text-left ml-4 font-weight-bold">{i18next.t('module_ia')} </h2>                      
                        </Col>
                    </Row>
                </ToolbarApp>
            </Container>
            <Container fluid className="box-fluid">
                <Row>
                    <Col sm="12" md="10" className="ia-container offset-md-1">
                       <Col md="12" className="card-configuration card">
                       { props.showConfig? (
                        <Row id="configuration_ia-panel">
                            <Col md="12">
                                <Form onSubmit={props.linkingIA}>
                                    <Col md="12">
                                        <Row>
                                            <Col md="6" lg="8">
                                                <h2 className="font-weight-bold">{i18next.t('link_bot')}</h2>
                                            </Col>
                                            <Col md="6" lg="4">
                                                <Row>
                                                    <Col className="button-config">
                                                        <Button type="button" className="btn btn-block btn-block-secundary" onClick={props.configBotBack}>{i18next.t('cancel')}</Button>
                                                    </Col>
                                                    <Col className="button-config">
                                                        <Button type="submit" className="btn btn-guarumo-md btn-block">{i18next.t('connect')}</Button>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md="12">
                                            <FormGroup>
                                                <Label for="exampleInputEmail1">Id Dialogflow</Label>
                                                <Input type="text" id="id_Dialogflow_text" name="dialogflow_id" placeholder="id dialogflow" className=""/>
                                            </FormGroup>
                                            <FormGroup className="form-group col-12" style={{paddingLeft:'0px'}}>

                                                <Label for="fileBotid" 
                                                       className="mt-4 btn btn-outline-secondary"
                                                       style={{cursor: "pointer"}}
                                                >
                                                    {i18next.t('file_selected')} {' '}  {/*i18next.t('config_file')*/}
                                                </Label>
                                                
                                                <Input type="file" 
                                                       name="json_config"
                                                       id="fileBotid" 
                                                       style={{ display: 'none' }}
                                                       onChange={updateFileName}
                                                       />
                                                       {fileName && <span style={{color: '#000000', marginLeft: '8px' }}>{fileName}</span>}
                                            </FormGroup>
                                    </Col>
                                </Form>
                            </Col>
                        </Row>
                        ):null}

                        { props.showEdition? (
                        <Row id="edition_ia-card">
                            <Col>

            
                    <div className="module_questions "> 

                               
                    {
                       dataTable && dataTable.rows!=[]?(
                        <Row >
                            <Col xs={12} lg={12} >
                                <h3 className="mb-2">{title}</h3>
                                <br></br>
                                <DataTableFilter
                                title={''}
                                data={ dataTable.rows}
                                columns={dataTable.columns}
                                options={options}
                                />
                           </Col>
                        </Row>): 
                          <Col xs={12} lg={8} className="offset-lg-2 intro-funnels">
                                <Row>
                                    <Col xs={12}><img src="/static/img/blank_state.png" className="img-fluid"/></Col>
                                    <Col xs={12}>
                                        <h2>{i18next.t('ia_message3')}</h2>
                                        <h3>{i18next.t('ia_message4')}</h3>
                                        <p>{i18next.t('ia_message5')}</p>
                                    </Col>
                                </Row>
                            </Col>
                        }
                                </div>
                            
                            </Col>
                        </Row>
                        ):null}
                       </Col>
                    </Col>
                </Row>
            </Container>
          </Admin>      
    </div>
    );
}
  
export default BotIALayout;