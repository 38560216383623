import { AbstractNodeFactory } from "storm-react-diagrams";
import React from "react";

import CDNodeWidget from "./CDNodeWidget";
import CDNodeModel from "./CDNodeModel";

class CDNodeFactory extends AbstractNodeFactory {
	constructor() {
		super("cd");
	}

	generateReactWidget(diagramEngine, node) {
		return <CDNodeWidget node={node} />
	}

	getNewInstance() {
		return new CDNodeModel();
	}
}

export default CDNodeFactory