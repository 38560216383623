import React from "react";
import Admin from "../../admin/containers/admin";
import SliceMenuApp from "../../admin/components/slice-menu";
import {
  Col,
  Row,
  Button,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Table,
  UncontrolledTooltip,
  
} from "reactstrap";
import ToolbarApp from "../../admin/components/toolbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import {Link} from 'react-router-dom';
import BotMenuOptions from '../../menus/bot-menu-options.js';
import "./contact-lists.css";

import DataTableFilter from '../../analytics/infoViz/common/dataTableFilter'


      /* IMAGE */
import imageNew from '../../../../static/img/menu-icons/nuevo_bot.svg'
import i18next from "../../../../i18n";

function ContactListsLayout(props) {
  function getCols(ckeys, el) {
      return ckeys.map((ck, index2) => {          
        if(ck!='num_row'){   
          return <td key={index2}>{el[ck]}</td>;
        }
      });     
  }

  function getItemOptionSelected(ckeys,itemHeader,listElement){
    return ckeys.map((e, k) => {
      if (e != "num_row") {
        return (
          <option key={e} selected={e==listElement[itemHeader]}   >
            {e} 
          </option>
        );
      }
    });
  }

  let contactLists = props.contactLists || [];


  
  let contactsItems = props.contacts || [];
  let contacts = [];
  let ckeys = [];
  let cabeceras = null;
  
  if (contactsItems.length > 0) {
    ckeys = Object.keys(contactsItems[0]).filter(value => {
      return value != "_id";
    });

    cabeceras = (
      <tr><td>Acción</td>
        {ckeys.map((ck, index2) => {
           if(ck!='num_row'){  
              return <td key={index2}>{ck}</td>;
           }
        })}
        </tr>
    );
    var elementsHeaders =null;
    elementsHeaders = ckeys.map((e, k) => {
      if (e != "num_row") {
        return (
          <option key={e} defaultValue={e} >
            {e}
          </option>
        );
      }
    });

    var inputsEditContact = ckeys.map((e, k) => {
      if (e != "num_row") {
        return (
          <div key={e} >
            <Label className="mt-1" for={e}>
              {e}
            </Label>
            <Input type="text" id={e} name={e} defaultValue={props.editContact[e]}/>              
          </div> 
        );
      }else{
        return (
          <div key={e} >          
            <Input type="hidden" id={e} name={e} defaultValue={props.editContact[e]}/>              
          </div>        
         
        );
      }
    });
  }

  let txtaccion = "Guardar";
  if (props.listElement._id) {
    txtaccion = "Editar";
  }

  if (contactsItems.length > 0) {
    contacts = contactsItems.map((el, index) => {
      return <tr key={index} >
            <td className=" text-center align-middle" >
                {
                  <div >                  
                    <Button  outline  color="danger"   
                      onClick={e => props.deleteContact(el.num_row,props.listElement._id)}   >
                      <FontAwesomeIcon icon={faTrash} id={"buttonRemove" + index } />
                    </Button>
                  </div>
                }
            </td>
            {getCols(ckeys, el)}
      </tr>;
    });
  }


  var dataTable=null;
  if(contactLists[0]){
     
       dataTable = {

          columns: [
            {
              label: '',
              field: 'name',
              sort: 'asc',
              options: {
                filter: false,
                sort: false
               }
             // width: 150
            },
            {
              label: i18next.t('title'),
              field: 'titulo',
              sort: 'asc',
             // width: 150
            },
            {
              label: i18next.t('bots_associate'),
              field: 'bots',
              sort: 'asc',
             // width: 150
            },
            {
              label: i18next.t('edition_option'),
              field: 'options',
              sort: 'asc',
              width: 670,
              options: {
                  filter: false,
                  sort: false
                 }
            }],
            rows: []};

            const rows = contactLists.map((el, index) => {

              const fin=[];
              el.id = index;
              fin.push(el);
            /*  fin.push( <td style={{'width':'50px'}}>
              { el.repetidos &&
                <React.Fragment>
                  <FontAwesomeIcon className='repetidos' id={"repetidos" + index} icon={faTimesCircle}/>
                  <UncontrolledTooltip
                      placement="top"
                      target={"repetidos" + index}
                    >i18next.t('message183')
                    </UncontrolledTooltip>
                  </React.Fragment>
              }
            </td>);

            fin.push(el.titulo);

            
            if( el.bots_names == "" )
              fin.push(<p>i18next.t('available')</p>);                
            else if(el.bots_names && el.bots_names != "" && el.bots_names.length > 0 )
            {
              fin.push( el.bots_names.map((b)=>{
                  return ` ${b} /`;
                }));
            }
           
            fin.push(<div className="d-inline-block">
            <Button
              outline
              color="success"
              onClick={e => props.toggleModalEditList(index)}>
              <FontAwesomeIcon icon={faPen} id={"buttonEdit" + index} />
            </Button>
            <UncontrolledTooltip
              placement="top"
              target={"buttonEdit" + index}
            >i18next.t('edit_send_list')
            </UncontrolledTooltip>
            <Button
              outline
              color="danger"
              onClick={e => props.deleteList(index)}
            ><FontAwesomeIcon icon={faTrash} id={"buttonRemove"+index} />
            </Button>
          </div>);

*/
              dataTable.rows.push(fin); 

              return el;
            });
    
      }

      const options = {
          filterType: 'multiselect',
          selectableRows:'none',
          resizableColumns: false,
          responsive: "scrollMaxHeight",
          rowsPerPage:5 ,
          print:false,
          download:false,
          viewColumns:false,
          customRowRender: data => {
            const [ el ] = data;
            const index = el.id;
            return (
              <tr key={index} className={'repetidos'+el.repetidos}>
        <td style={{'width':'50px'}}>
          { el.repetidos &&
            <React.Fragment>
              <FontAwesomeIcon className='repetidos' id={"repetidos" + index} icon={faTimesCircle}/>
              <UncontrolledTooltip
                  placement="top"
                  target={"repetidos" + index}
                >{i18next.t('message183')}
                </UncontrolledTooltip>
              </React.Fragment>
          }
        </td>
        <td>{el.titulo}</td>
        <td>
          {
            el.bots_names == "" &&
              <p>i18next.t('available')</p>
          }
          {
            el.bots_names && el.bots_names != "" && el.bots_names.length > 0 &&
              el.bots_names.map((b)=>{
                return ` ${b} /`;
              })
          }
        </td>
        <td>
          {
            <div className="d-inline-block">
              <Button
                outline
                color="success"
                onClick={e => props.toggleModalEditList(index)}>
                <FontAwesomeIcon icon={faPen} id={"buttonEdit" + index} />
              </Button>
              <UncontrolledTooltip
                placement="top"
                target={"buttonEdit" + index}
              >{i18next.t('edit_send_list')}
              </UncontrolledTooltip>
              <Button
                outline
                color="danger"
                onClick={e => props.deleteList(index)}
              ><FontAwesomeIcon icon={faTrash} id={"buttonRemove"+index} />
              </Button>
            </div>
          }
        </td>
      </tr>
              
            );
          },
         
        };
        
            var title = ''
  return (
    <div className="CMSLayout">
      <Admin>
        <button id="tooltiNewTemplate" className="btn-float"  onClick={(e)=>{props.toggleModalNewList()}}>
              <img src={imageNew}/>
          </button> 
          <UncontrolledTooltip placement="right" target="tooltiNewTemplate">i18next.t('create_new_contact_list')</UncontrolledTooltip> 
        <SliceMenuApp>
        <BotMenuOptions admin={props.isAdmin}/>
        </SliceMenuApp>
        <Container fluid>
          <ToolbarApp>
            <Row>
              <Col sm="9" md="10" lg="10" xl="10" className="text pdtoolbar">
                <h2 className="text-left ml-4 font-weight-bold">
                  {i18next.t('send_list')}
                </h2>
              </Col>
            </Row>
          </ToolbarApp>
        </Container>
        <Container fluid className="box-fluid">
                <Col sm="12" md="10" className="stadistics-container offset-md-1">    
                    {
                       dataTable?(
                        <Row >
                            <Col xs={12} lg={12} >
                                <DataTableFilter
                                title={title}
                                data={ dataTable.rows}
                                columns={dataTable.columns}
                                options={options}
                                />
                           </Col>
                        </Row>): 
                          <Col xs={12} lg={8} className="offset-lg-2 intro-funnels">
                                <Row>
                                    <Col xs={12}><img src="/static/img/blank_state.png" className="img-fluid"/></Col>
                                    <Col xs={12}>
                                        <h2>{i18next.t('contact_list_msg')}</h2>
                                        <h3>{i18next.t('contact_list_msg2')}</h3>
                                        <p>{i18next.t('contact_list_msg3')}</p>
                                    </Col>
                                </Row>
                            </Col>
                        }
                    </Col>
                </Container>
      </Admin>

      <Modal
        className="modalRight"
        isOpen={props.modalNewList}
        fade
        toggle={() => props.toggleModalNewList()}>
        <ModalHeader
          toggle={() => props.toggleModalNewList()}
          className="carousel">
          {i18next.t('config_list')}
        </ModalHeader>
        <ModalBody>
            <Row className="m-0">
            <Col md={12} className="sectionBox">
              <Form id="frm_save_new" onSubmit={props.saveNewHandler}>
              {props.listElement._id && props.totalRepeat > 0 &&
                <FormGroup className="warning-repeat-record mb-0">
                  <p className="mb-0">{`Esta lista tiene ${props.totalRepeat} registro(s) repetido(s).`} </p>
                </FormGroup>
              }
                <FormGroup className="mb-0">
                  <Label>{i18next.t('title')}</Label>
                  <Input
                    type="text"
                    id="titulo"
                    name="titulo"
                    defaultValue={props.listElement.titulo}
                    required
                  />
                  {props.listElement._id && (
                    <div>
                      <Label className="mt-1" for="name">
                      {i18next.t('name')}
                      </Label>
                      <Input type="select" id="name" name="name"  >
                        <option key="" value="" >
                        {i18next.t('select_one')}...
                        </option>
                        {getItemOptionSelected(ckeys,'name',props.listElement)}
                      </Input>
                      <Label className="mt-1" for="whatsapp">
                        Whatsapp
                      </Label>
                      <Input type="select" id="whatsapp" name="whatsapp"  >
                        <option key="" value="" >
                        {i18next.t('select_one')}...
                        </option>
                        {getItemOptionSelected(ckeys,'whatsapp',props.listElement)}
                      </Input>
                      <Label className="mt-1" for="sms">
                        SMS
                      </Label>
                      <Input type="select" id="sms" name="sms"  >
                        <option key="" value="" >
                        {i18next.t('select_one')}...
                        </option>
                        {getItemOptionSelected(ckeys,'sms',props.listElement)}
                      </Input>
                      <Label className="mt-1" for="email">
                        Email
                      </Label>
                      <Input type="select" id="email" name="email" >
                        <option key="" value="" >
                        {i18next.t('select_one')}...
                        </option>
                        {getItemOptionSelected(ckeys,'email',props.listElement)}
                      </Input>
                      <Label className="mt-1" for="sms">
                        {i18next.t('user_identity')}
                      </Label>
                      <Input type="select" id="session_id" name="session_id"  >
                        <option key=""  value="" >
                        {i18next.t('select_one')}...
                        </option>
                        {getItemOptionSelected(ckeys,'session_id',props.listElement)}
                      </Input>
                      

                      <Input
                        type="hidden"
                        id="_id"
                        name="_id"
                        defaultValue={props.listElement._id}
                      />
                      
                    </div>
                  )}
                  <Label className="mt-1">{i18next.t('contacts_upload')}</Label>
                  <Input
                    type="file"
                    id="lista"
                    name="lista"
                    onChange={props.chosenFile}
                  />
                  <Input
                    type="hidden"
                    id="id_company"
                    name="id_company"
                    defaultValue={props.company_id}
                  />
                </FormGroup>
              </Form>
              {props.listElement._id && (
                    <Row>
                      <Col className="col-6 offset-3 pb-4">
                        <a href={'/detailists/'+props.listElement._id} target="_blank" className="btn btn-block btn-guarumo-modal-sm text-reset">{i18next.t('view_data')}</a>
                      </Col> 
                      <Col md={12}>
                        <Form id="frm_validate" onSubmit={props.validateData}>
                          <Input type="hidden" defaultValue={props.listElement._id} name="idlista"/>
                          <FormGroup className="pb-0 separate-col">
                            <strong>{i18next.t('validate_email')}</strong><br></br>
                            <Label check><Input type="checkbox"  id="check" name="check"/>{' '}&nbsp;<span>{i18next.t('verify_domain')}</span></Label>
                            <Label check> <Input type="checkbox" id="ping" name="ping"/>{' '}&nbsp;<span>Ping email</span></Label>
                            <Col className="col-6 offset-3">
                            <Button
                            className="btn-block btn-guarumo-modal-sm mt-2"
                            type="submit"> {i18next.t('validate')} </Button>
                            </Col>
                          </FormGroup>
                          
                        </Form>
                    </Col>
                  </Row>
              )}
            </Col>
            <Col className="col-12 boxButonModal">
              <Row>
               
                <Col className="col-6 offset-3">
                  <Button
                    className="btn-block btn-guarumo-modal-sm"
                    type="submit"
                    form="frm_save_new"
                    style={{ marginBottom: "10px" }}
                  >
                    {txtaccion} {i18next.t('list')}
                  </Button>
                </Col>
              </Row>
            </Col>
            
          </Row>
        </ModalBody>
      </Modal>

     
      <Modal
        className="modalRight"
        isOpen={props.modalEditContact}
        fade
        toggle={() => props.toggleOpenModalEditContact()}
      >
        <ModalHeader
          toggle={() => props.toggleOpenModalEditContact()}
          className="carousel"
        >
          {i18next.t('edit_contact')}
        </ModalHeader>
        <ModalBody>
          
        <Col md={12} className="sectionBox">
              <Form id="frm_edit_contact" onSubmit={props.editContactHandler}>
                <FormGroup>                                
                    <div>
                    {inputsEditContact}

                      <Input
                        type="hidden"
                        id="_id"
                        name="_id"
                        defaultValue={props.listElement._id}
                      />
                    </div>                  

                    <Col className="col-10 offset-1 mt-4">
                      <Row>
                        <Col className="col-6 offset-3">
                          <Button
                            className="btn-block btn-guarumo-modal-sm"
                            type="submit"
                            form="frm_edit_contact"
                            style={{ marginBottom: "10px" }}
                          >
                           {i18next.t('save_edition')}
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                </FormGroup>
              </Form>
            </Col>
        </ModalBody>
      </Modal>

      
    </div>
  );
}

export default ContactListsLayout;
