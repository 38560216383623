import React, { Component } from 'react';
import CompaniesLayout from './companies-layout';
import ModalLoad from '../../components/modals/Loading/moda-loading';
import axios from 'axios';
import qs from 'qs';
import { connect } from 'react-redux';
import { getAllCompanies, saveCompany, newUser, editCompany } from '../../redux/actions/companyActions';
import { isEmpty } from '../../utils/commonFunction';

class Companies extends Component {
  state = {
      current: {},
      new_company: {},
      modalOpen: false,
      modalOpenC: false,
  }

  saveCompany = async () => {
    let c = this.state.current;
    const data = {
        name: c.name
    }

    const { editCompany } = this.props;
    await editCompany(c.id, data);
    this.closeModal();
    
  }

  createCompany = async () => {

    let c = this.state.new_company;
    
    
    console.log('save new company ',c);
  
    if(!c.company || c.company.length < 3){
        alert("El nombre de la empresa debe tener al menos 3 caracteres.");
        return;
    }

    if(!c.password || c.password.length < 6){
        alert("La contraseña debe tener al menos 6 caracteres.");
        return;
    }

    if(!c.username || c.username.length < 6){
        alert("El nombre de usuario debe tener al menos 6 caracteres.");
        return;
    }

    if(/[^A-Za-z0-9]+/.test(c.password)){
        alert("La contraseña debe contener solo letras y números.");
        return;
    }

    if(/[^A-Za-z0-9]+/.test(c.username)){
        alert("El nombre de usuario debe contener solo letras y números.");
        return;
    }

    if(/[\s]+/.test(c.username)){
        alert("El nombre de usuario no puede contener espacios.");
        return;
    }

    const { saveCompany, newUser } = this.props;
    const createNewUser = async (companyID) => {
        const data = {
            roltype_id: 2,
            company_id: companyID,
            grant_type: 'password',
            username: c.username,
            password: c.password,
            email: isEmpty(c.email)?'':c.email,
            first_name: c.first_name,
            last_name: c.last_name,
            icono: c.icono,
            logo: c.logo,
            navColor: c.navColor,
            sidebarColor: c.sidebarColor
        }
        this.closeModalC();
        await newUser(data);
    }
    
    const data = {
        name: c.company,
        icono: c.icono,
        logo: c.logo,
        navbar: c.navColor,
        sidebar: c.sidebarColor
    }

    
    await saveCompany(data, createNewUser );
  }

  editCompany = (company) => {
    this.setState({
        current: company,
        modalOpen: true
    });
  }

  newCompany = () => {
    this.setState({
        modalOpenC: true
    });
  }

  closeModal = () => {
    this.setState({
        modalOpen: false
    });
  }

  closeModalC = () => {
    this.setState({
        modalOpenC: false
    });
  }

  updateProp = (value, key) => {
    let c = this.state.current;
    c[key] = value;
    this.setState({
        current: c
    });
  }

  updatePropNewCompany = (value, key) => {
    let c = this.state.new_company;
    console.log('C ',c);
    c[key] = value;
    this.setState({
        new_company: c
    });
  }

componentDidMount(){
    const { getAllCompanies } = this.props; 
    getAllCompanies();
}


  showContent=()=>{
        return (
            <div>
              <CompaniesLayout
                  current= {this.state.current}
                  modalOpen={this.state.modalOpen}
                  saveCompany={this.saveCompany}
                  editCompany={this.editCompany}
                  closeModal={this.closeModal}
                  updateProp={this.updateProp}
      
                  newc={this.state.new_company}
                  modalOpenC={this.state.modalOpenC}
                  updatePropC={this.updatePropNewCompany}
                  closeModalC={this.closeModalC}
                  createCompany={this.newCompany}
                  saveNewCompany={this.createCompany}
                  
      
                  />
                <ModalLoad/>
            </div>
          );
  }

  render() {
    return this.showContent();
  }
}

const mapStateToProps = ({companyReducer ,}) => 
	({companyReducer,  });

const mapDispatchToProps = {
    getAllCompanies, saveCompany, newUser, editCompany
}

export default connect(mapStateToProps, mapDispatchToProps)(Companies);
