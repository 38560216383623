import { AbstractNodeFactory } from "storm-react-diagrams";
import React from "react";
import CRNodeWidget from "./CRNodeWidget";
import CRNodeModel from "./CRNodeModel";

class CRNodeFactory extends AbstractNodeFactory {
	constructor() {
		super("creport");
	}

	generateReactWidget(diagramEngine, node) {
		return <CRNodeWidget node={node} />
	}

	getNewInstance() {
		return new CRNodeModel();
	}
}

export default CRNodeFactory