import {isDefined} from './isValidate';
import momentzone from "moment-timezone";
import { getHandlerGET } from './handlersApi'


import { pipe, toPairs, transpose, converge, zipObj, head, map, last } from "ramda";


export function getUserInfoLocalStorage(){
  return JSON.parse(localStorage.getItem("UserInfo"));
}


export function getAuthorization() {
return JSON.parse(localStorage.getItem("Authorization"));
} 

export function getOtp(){
  return JSON.parse(localStorage.getItem("otp"));
}

export function cerrarSesion(){
    if(getAuthorization())
    {
      
      getHandlerGET(HOST_API +'/api/auth/logout').
        then((res)=>{
            if(res.status==200){
              localStorage.removeItem('Authorization');
              localStorage.removeItem("otp")
              localStorage.removeItem("user_id")
              localStorage.removeItem("UserInfo")
              localStorage.removeItem("token_apis")
            }
            
            window.location.href = "/login";

        }).catch((error) => {
            
              if (error.response.status == 403 || error.response.status == 404)
                window.location.href = "/login"; 
        });
    }
      
    

    
}

export function isAuthorizationValid(){
  let auth = getAuthorization(  );
  let otp = getOtp();
  if(!auth || !auth.access_token || !otp){
    window.location.href = "/login";
  }
}

export function getRolUser() {
  let rolUser = { isAdmin: false, isSuperUser: false, rol: '', isLogin: false }
  const userLogged = getUserInfoLocalStorage();
  if (isDefined(userLogged)) {
    const { rol } = userLogged;
    if (isDefined(rol)) {
      return {
        ...rolUser,
        isAdmin: rol != 'Usuario',
        isSuperUser: rol == 'SuperUser',
        rol: rol,
        isLogin: true
      }
    }
  }
  return rolUser;
}

export function isEmpty(items) {
  return !isDefined(items) || items.length === 0 || Object.keys(items).length === 0;
}


export function validKeysObjectsWithActions(object, modelo){
  /*
    El modelo debe ser un object y cumplir con el siguiente formato:
    key, representa los nombre de campos que se validarán.
    success, será la funcion que se ejecutara, cuando SI exista la key.
    error, será la funcion que se ejecutara, cuando NO exita la key.

    modelo = {
      key: {
        success: ()=>{},
        error: ()=>{}
      },
      ... 
      key: {
        success: ()=>{},
        error: ()=>{}
      }
    }
  */
  const keysObject = Object.keys(object);
  const keysModelo = Object.keys(modelo);

  keysModelo.map((key)=>{
      if(keysObject.includes(key)){
        if(!isEmpty(object[key])){
          modelo[key]['success']();
        }else{
          if(!('error' in  modelo[key])){
          }else{
            modelo[key]['error'](key);
          }
        }
      }else{
        if(!('error' in  modelo[key])){
        }else{
          modelo[key]['error'](key);
        }
      }

  });

}


export function validKeysObjects(object, modelo){
  // El modelo debe ser una lista con keys a validad, ej:
  // const model = ['key1', ... , 'key2'];

  const keysObject = Object.keys(object)
  const response = {
    presents: [],
    notExists: []
  }
  modelo.map((key)=>{
      if(keysObject.includes(key)){
        if(!isEmpty(object[key])){
          response.presents.push(key);
        }else{
          response.notExists.push(key);
        }
      }else{
        response.notExists.push(key);
      }
  });

  return response;
}


export function UTCFormatter(date) {
  return momentzone(new Date(( !isEmpty(date) ) ? date : null).toISOString()).utc().format();
}

export const renameKeys = keysMap => pipe(
  toPairs, // convert to entries
  transpose, // convert to array of keys, and array of values
  converge(zipObj, [ // zip back to object
    pipe(head, map(key => keysMap[key] || key)), // rename the keys
    last // get the values
  ])
)