import React, { Component } from 'react';
import ModalLoad from '../../../components/modals/modal-load';
import BotAimlLayout from './bot-aiml-layout';


import { getHandlerGET, getHandlerPOST, getHandlerDELETE, getHandlerPUT} from '../../../utils/handlersApi'
import {isAuthorizationValid, cerrarSesion} from '../../../utils/commonFunction'

import i18next from '../../../../i18n';

class BotAiml extends Component {
    constructor(props){
        super(props);
        this.myHelp = {}; // this is an auxiliar to pass values inside other scopes.
        this.state = { 
            displayTr:[],
            synonyms:{},
            botId: this.props.match.params.id,
            hideLoad: 0,
            botversion_id: "",
            groupid:"",
            ia_activated:""
        };
    }

    componentDidMount () {
        const { groupid }  = this.props.match.params;
        this.setState({
            groupid:groupid
        });
        this.getBotInfo();
    }
    componentWillMount(){
        isAuthorizationValid();
      }

  

    /* Get Bot Info */
    getBotInfo () {
        let cont = this.state.hideLoad; this.setState({ hideLoad: cont+1});
        getHandlerGET(HOST_API +"/api/bot/?id="+this.state.botId).
            then((res) =>{
                if(res.status==200){
                    this.setState({botversion_id: res.data.bot[0].bot_version[0].botversion_id, ia_activated:res.data.bot[0].ia_activated});
                    this.listItemsGroup();
                }else{
                    console.log(" No se pudo recuperar info del bot");
                }
                let cont = this.state.hideLoad; this.setState({ hideLoad: cont-1});
            }).catch((error)=> {
                this.setState({ hideLoad: cont-1});
                  if (error.response.status == 403)
                    cerrarSesion();
            });
    }

    // Create a list question Handler
    listItemsGroup = (event) => {
        let cont = this.state.hideLoad; this.setState({ hideLoad: cont+1});
        getHandlerGET(HOST_API +"/api/qna/?botversion_id="+this.state.botversion_id+"&aiml_group_id="+this.state.groupid).
            then((res) =>{
                if(res.status==200){
                    var list = [];
                    res.data.aiml_item_list.forEach(item => {
                        list.push({
                            id: item.id, 
                            aiml_group_id: item.aiml_group_id, 
                            answer: item.answer, 
                            botversion_id: item.botversion_id,
                            statement: item.statement,
                            hidden: false,
                            collapse: false,
                            synonyms: []
                        });
                    });
                    this.setState({displayTr : list});
                   
                }else
                    console.log(" No se pudo items del grupo info del bot");
                
                this.setState({ hideLoad: cont-1});
            }).catch((error)=> {
                this.setState({ hideLoad: cont-1});
                  if (error.response.status == 403)
                    cerrarSesion();
            });
    }
    //Create a new question Handler 
    newQuestion = (event) => {
        let form_id= event.target.id;
        event.preventDefault();
        let cont = this.state.hideLoad; this.setState({ hideLoad: cont+1});
        let form = new FormData(event.target);
        let data = {
            statement : form.get('statement'),
            answer : form.get('answer'),
            botversion_id : this.state.botversion_id,
            aiml_group_id: this.state.groupid
        };

        getHandlerPOST(HOST_API +'/api/qna/',data).
            then((res) => {
                if(res.status==200){
                  document.getElementById(form_id).reset();
                  let aux = this.state.displayTr;
                  aux.push({
                            id: res.data.qna.id, 
                            statement : res.data.qna.statement, 
                            answer : res.data.qna.answer,
                            hidden: false,
                            collapse: false,
                            synonyms: []
                            });
                  this.setState({displayTr : aux});
                }else{
                  console.log(" No se pudo crear la pregunta");
                }
                let cont = this.state.hideLoad; this.setState({ hideLoad: cont-1});
            }).catch((error) => {
                console.log('catch newQuestion',error);
                  if (error.response.status == 403)
                    cerrarSesion();
            });

    }
    // Update a new question Handler
     updateQuestion = (event) => {
        event.preventDefault();
        let cont = this.state.hideLoad; this.setState({ hideLoad: cont+1});
        let form = new FormData(event.target);
        let data = {
            id : form.get('id'),
            statement : form.get('statement'),
            answer : form.get('answer'),
            aiml_group_id : form.get('aiml_group_id')
        };
        this.myHelp = data;
        getHandlerPUT(HOST_API +'/api/qna/',data).
            then((res) => {
                if(res.status==200){
                    for(let i=0; i < this.state.displayTr.length; i++){
                        if(this.state.displayTr[i].id == this.myHelp.id){
                            let aux = this.state.displayTr;
                            aux[i].statement = this.myHelp.statement;
                            aux[i].answer = this.myHelp.answer;
                            aux[i].hidden = !aux[i].hidden;
                            this.setState({displayTr : aux });
                            break;
                        }
                    }
                }else
                  console.log(" No se pudo actualizar la pregunta");
                
                this.setState({ hideLoad: cont-1});
            }).catch((error) => {
                console.log('catch updateQuestion',error);
                  if (error.response.status == 403)
                    cerrarSesion();
            });
    }
    // Create a detele question Handler
    deleteQuestion = (event) => {
        event.preventDefault();
         let cont = this.state.hideLoad; this.setState({ hideLoad: cont+1});
         let form = new FormData(event.target);
         let data = {
             id : form.get('id')
         };
         this.myHelp = form.get('id');
         getHandlerDELETE(HOST_API +'/api/qna/',data).
             then((res) => {
                 if(res.status==200){
                     for(let i=0; i < this.state.displayTr.length; i++){
                         if(this.state.displayTr[i].id == this.myHelp){
                             let aux = this.state.displayTr;
                             aux.splice(i,1);
                             this.setState({displayTr : aux });
                             break;
                         }
                     }
                 }else{
                    alert(i18next.t('delete_question'));
                   console.log(" No se pudo eliminar la pregunta");
                 }
                 let cont = this.state.hideLoad; this.setState({ hideLoad: cont-1});
             }).catch((error) => {
                console.log('catch deleteQuestion',error);
                if (error.response.status == 403)
                  cerrarSesion();
             });
 
    }
   
    /* toogles the hidden state of an specific question 
        (To show or hide edition forms)
     */
    toogleQuestion = (event) => {
        event.preventDefault();
        let form = new FormData(event.target);
        let id = form.get('q-id')
        
        var aux = this.state.displayTr;
            for(let i=0; i< aux.length; i++){
                if(aux[i].id == id){
                    aux[i].hidden = !aux[i].hidden;
                    break;
                }
            }
        this.setState({displayTr : aux});
     }

    /*
    CRUD of Synonyms 
    */
    toogleSynonym = (event) => {
        event.preventDefault();
        let form = new FormData(event.target);
        let id = form.get('s-id');
        let qna_id = form.get('s-qna');
        var aux = this.state.synonyms;

            for(let i=0; i< aux[qna_id].length; i++){
                if(aux[qna_id][i].id == id){
                    aux[qna_id][i].hidden = !aux[qna_id][i].hidden;
                    break;
                }
            }
        this.setState({synonyms : aux});     }

     /* toogles the hidden state of de collapse button.
        (To show or hide list of synonyms)
     */
    toogleCollapse = (event) => {
        event.preventDefault();
        let form = new FormData(event.target);
        let id = form.get('q-id')
        var state = false;
        var aux = this.state.displayTr;
            for(let i=0; i< aux.length; i++){
                if(aux[i].id == id){
                    aux[i].collapse = !aux[i].collapse;
                    state = aux[i].collapse;
                    break;
                }
            }
        this.setState({displayTr : aux});

        if(state) this.listSynonym(id);

     }

    // Create a new synonym Handler
    newSynonym = (event) => {
        let form_id= event.target.id;
        event.preventDefault();
        let cont = this.state.hideLoad; this.setState({ hideLoad: cont+1});
        let form = new FormData(event.target);
        let data = {
            synonym : form.get('synonym'),
            qna_id : form.get('aiml-id')
        };
        this.myHelp = data;

        getHandlerPOST(HOST_API +'/api/qna/synonyms',data).
            then((res) => {
                if(res.status==200){
                    alert('Sinónimo agregado');
                    document.getElementById(form_id).reset();
                    let aux = this.state.synonyms;
                    if(!aux[this.myHelp.qna_id]) 
                        aux[this.myHelp.qna_id] = [];
                        aux[this.myHelp.qna_id].push({
                        id : res.data.synonym.id,
                        hidden : false,
                        val : res.data.synonym.synonym,
                        qna_id : res.data.synonym.aiml_id
                    });
                    this.setState({synonyms : aux});
                    
                }else{
                console.log(" No se pudo crear el sinonimo");
                }
                cont = this.state.hideLoad; this.setState({ hideLoad: cont-1});
            }).catch((error) => {
                console.log('catch newSynonym',error);
                if (error.response.status == 403)
                  cerrarSesion();
            });

    }

    // Update a new synonym Handler
    updateSynonym = (event) => {
        event.preventDefault();
        let cont = this.state.hideLoad; this.setState({ hideLoad: cont+1});
        let form = new FormData(event.target);
        let data = {
            id : form.get('id'),
            synonym : form.get('synonym'),
            qna_id : form.get('qna_id')
        };
        this.myHelp = data;
        getHandlerPUT(HOST_API +'/api/qna/synonyms',data).
            then((res) => {
                if(res.status==200){
                    var aux = this.state.synonyms;
                    for(let i=0; i < aux[this.myHelp.qna_id].length; i++){
                        if(aux[this.myHelp.qna_id][i].id == this.myHelp.id){
                            aux[this.myHelp.qna_id][i].val = this.myHelp.synonym;
                            aux[this.myHelp.qna_id][i].hidden = !aux[this.myHelp.qna_id][i].hidden;
                            this.setState({synonym : aux });
                            break;
                        }
                    }
                }else{
                console.log(" No se pudo actualizar el sinonimo");
                }
                let cont = this.state.hideLoad; this.setState({ hideLoad: cont-1});
            }).catch((error) => {
                console.log('catch updateSynonym',error);
                if (error.response.status == 403)
                  cerrarSesion();
            });

    }

    // Create a list synonym Handler
    listSynonym (qna_id) {
        let cont = this.state.hideLoad; this.setState({ hideLoad: cont+1});
        this.myHelp = qna_id;
        getHandlerGET(HOST_API +"/api/qna/synonyms?qna_id="+qna_id).
            then((res) =>{
                if(res.status==200){
                    var list = {};
                    for (let i=0; i < res.data.synonyms.length; i++){
                        if(!list[this.myHelp])
                            list[this.myHelp] = [];
                        list[this.myHelp].push({
                            id : res.data.synonyms[i].id,
                            hidden : false,
                            val : res.data.synonyms[i].synonym,
                            qna_id : this.myHelp
                        });
                    }
                    this.setState({synonyms : list});
                }else
                    console.log(" No se pudo recuperar la lista de sinonimos");
                
                this.setState({ hideLoad: cont-1});
            }).catch((error)=> {
                console.log('catch listSynonym',error);
                if (error.response.status == 403)
                  cerrarSesion();
            });
    }


    // Create a detele synonym Handler
    deleteSynonym = (event) => {
       event.preventDefault();
        let cont = this.state.hideLoad; this.setState({ hideLoad: cont+1});
        let form = new FormData(event.target);
        let data = {
            id : form.get('s-id'),
            qna_id : form.get('s-qna')
        };
        this.myHelp = data;
        getHandlerDELETE(HOST_API +'/api/qna/synonyms',data).
            then((res) => {
                if(res.status==200){
                    var aux = this.state.synonyms;
                    for(let k=0; k< aux[this.myHelp.qna_id].length; k++ ){
                        if(aux[this.myHelp.qna_id][k].id == this.myHelp.id) {
                            aux[this.myHelp.qna_id].splice(k,1);
                            this.setState({ synonyms: aux });
                            break;
                        }
                    }

                }else{
                  console.log(" No se pudo eliminar el sinonimo");
                }
                let cont = this.state.hideLoad; this.setState({ hideLoad: cont-1});
            }).catch((error) => {
                console.log('soy el deleteSynonym',error);
                if (error.response.status == 403)
                  cerrarSesion();
            });

    }



    handleChangeUpdateInputs = (index,key, value) => {
        this.state.displayTr[index][key] = value;   
        this.forceUpdate();
    }
   
     /* TRAIN handler
     */

    train = (event) =>{
        let cont = this.state.hideLoad; this.setState({ hideLoad: cont+1});
        getHandlerGET(HOST_API +'/api/qna/train?id='+this.state.botversion_id).
            then((res) => {
                if(res.status==200){
                  console.log(" bot entrenado");
                }else{
                  console.log(" No se pudo entrenar");
                }
                let cont = this.state.hideLoad; this.setState({ hideLoad: cont-1});
            }).catch((error) => {
                console.log(error);
                let cont = this.state.hideLoad; this.setState({ hideLoad: cont-1});
            });
     }

     render() {
        return (
        <div>
          <BotAimlLayout 
          displayTr={this.state.displayTr}
          synonyms={this.state.synonyms}
          botId={this.state.botId}
          botversion_id={this.state.botversion_id}
          ia_activated={this.state.ia_activated}

          // functions
          handleChangeUpdateInputs={this.handleChangeUpdateInputs}
          train={this.train}
          newQuestion={this.newQuestion}
          updateQuestion={this.updateQuestion}
          deleteQuestion={this.deleteQuestion}
          newSynonym={this.newSynonym}
          updateSynonym={this.updateSynonym}
          deleteSynonym={this.deleteSynonym}
          deleteGroup={this.deleteGroup}
          toogleQuestion={this.toogleQuestion}
          toogleSynonym={this.toogleSynonym}
          toogleCollapse={this.toogleCollapse}/>
          <ModalLoad hideLoad={this.state.hideLoad} />
        </div>
        )
    }
}

export default BotAiml