import React from "react";
import { Input, Label, FormGroup, Row, Col } from "reactstrap";
import i18next from "../../../i18n";

export default class TabINConfi extends React.Component {
  _data = {};
  _botversion = {};
  _url = "";
  _test_url = "";

  constructor(props) {
    super(props);
  }

  defaultUrl() {
    if (this.props.props.botInfo) {
      this._data = this.props.props.botInfo;
      this._botversion = this._data.bot_version[0];
      this._url =
        `${HOST_API}/api/instagram/webhook/` +
        this._botversion.botversion_id;
    }
  }

  render() {
   
    this.defaultUrl();

    const url = this._url;
    const data = this._data;


    return (
      <Row>
        <Col lg="12">
          <Row form>
            <FormGroup>
              <Label for="webhook_instagram">
                {i18next.t('url_integration')} <small>(Webhook)</small>
              </Label>
              <Input readOnly className="config-input" defaultValue={url} />
            </FormGroup>
          </Row>
          <Row form>
            <FormGroup>
              <Label for="vt_instagram">{i18next.t('user_verification_code')}</Label>
              <Input
                className="config-input"
                type="text"
                id="vt_instagram"
                name="vt_instagram"
                defaultValue={data.vtoken_instagram}
              />
            </FormGroup>
          </Row>
          <Row form>
            <FormGroup>
              <Label for="at_instagram">{i18next.t('instagram_token')}</Label>
              <Input
                className="config-input"
                type="text"
                id="at_instagram"
                name="at_instagram"
                defaultValue={data.token_instagram}
              />
            </FormGroup>
          </Row>
        </Col>
      </Row>
    );
  }
}
