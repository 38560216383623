var d3 = require('d3');

const createScaleColor = (nameDiv, data, color, typeScaleColor,short_name) => {

    let name = color;

    const node = document.createElement('div');
    node.id="chart_"+nameDiv;
   
     
    var div='#'+nameDiv;
    var chart=document.getElementById('content-'+nameDiv);

    const WIDTH = chart.offsetWidth;
    const HEIGHT = chart.offsetHeight - 35 ;
    //const margin = { top: 75, right: 60, bottom: 60, left: 60 };


    const derecha = document.createElement('div');
    //node.className = "svg-container";
    derecha.id="derecha_"+nameDiv;
    derecha.className = "derecha";

    derecha.style.position='absolute'
    derecha.style.top = '0px';
    derecha.style.left='0px';
    derecha.style.width='100%';
    derecha.style.height='30px';
    derecha.style.backgroundColor ='white';

    node.appendChild(derecha);

    const arriba = document.createElement('div');
    //node.className = "svg-container";
    arriba.id="arriba_"+nameDiv;
    arriba.className = "arriba";

    arriba.style.position='absolute'
    arriba.style.top = '-30px';
    arriba.style.left='10px';
    arriba.style.width='100%';
    arriba.style.height='30px';
    arriba.style.backgroundColor ='white';
    arriba.style.paddingTop ='5px';
    arriba.style.paddingLeft ='10px';

   // ===========================================================================================
   //    Creates Title
   // ===========================================================================================
 
 node.appendChild(arriba);

 var title= d3.select(arriba)
   .append("text")
   .attr("class", "chartTitle")
   .style("font-size", '1rem')
   .style("color", 'gray')
   .style("font-weight", 'bold')
   .text(short_name);
   //.text("d3."+name);

  

 
        if(typeScaleColor=="unicolor"){
            color=d3.scaleLinear().domain([1,10]).range([name,"black"]);
            custom(color)
        }         
        else if(typeScaleColor=="Categorica")
        {
            swatches(name);
        }
        else if(typeScaleColor=="predeterminado")
        {
            color = d3.scaleOrdinal().domain(10).range(eval(color));
            custom(color);
           
           // color = d3.scaleOrdinal().domain(datos.dataSet).range(["rgba(46,91,255,1)", "rgba(140,84,255,1)", "rgba(0,193,212,1)", "rgba(250,208,80,1)", "rgba(195,34,168,1)"]);       
        }
        else 
        {
            ramp(name);
           name="d3."+name+"(t * 0.85)";
          const scale = t => eval(name);
          var color = (d) => {
            var c= d3.range(5).map(i => scale(i / 5))
            return c[d];
            };
          //  color = d3.scaleSequential(eval(colorChosen));
        }
   

    function swatches(name) {
        const colors = d3[name];//d3[`scheme${name}`];
        const n = colors.length;
        const dark = d3.lab(colors[0]).l < 50;
        let canvas=null;

        var svg = d3.select(derecha)
        .append("svg")
        .attr("id","svg_"+nameDiv)
        //.attr("preserveAspectRatio", "xMinYMin meet")
        .attr(
            "viewBox",
            "0 0 "+n+" 1")
        .classed("svg-content", true)
        .style("display","block")
        .style("width",n * 33+"px")
        .style("height",33+"px")
        .style("margin","0 10px")
        .style("cursor","pointer")

    

        colors.map((c, i) =>{
            canvas = svg.append("rect")
            .attr("x",i/2)
            .attr("width",0.5)
            .attr("height",0.5)
            .attr("fill",c)

        })
    
 
      }

    function ramp(name) {
        let canvas;
        let colors;
        let dark;
        let n = 10;
        if (d3[name] && d3[name][n]){//(d3[`scheme${name}`] && d3[`scheme${name}`][n]) {
          colors = d3[name][n];//d3[`scheme${name}`][n];
          dark = d3.lab(colors[0]).l < 50;
        } else {
          const interpolate = d3[name];//d3[`interpolate${name}`];
          colors = [];
          dark = d3.lab(interpolate(0)).l < 50;
          for (let i = 0; i < n; ++i) {
            colors.push(d3.rgb(interpolate(i / (n - 1))).hex());
          }
        }
        if (n < 128) {
            var svg = d3.select(derecha)
            .append("svg")
            .attr("id","svg_"+nameDiv)
            .attr("preserveAspectRatio", "none")
            .attr(
                "viewBox",
                "0 0 "+n+" 1")
            .classed("svg-content", true)
            .style("display","block")
            .style("shape-rendering","crispEdges")
            .style("width","100%" + 28+"px")
            //.style("width",n * 33+"px")
            .style("height",33+"px")
            .style("margin","0 10px")
            .style("cursor","pointer")

            colors.map((c, i) =>{
                canvas = svg.append("rect")
                .attr("x",i/2)
                .attr("width",0.5)
                .attr("height",0.5)
                .attr("fill",c)})
        } else {
          const context = (canvas = DOM.canvas(n, 1)).getContext("2d");
          canvas.style.margin = "0 -14px";
          canvas.style.width = "calc(100% + 28px)";
          canvas.style.height = "33px";
          canvas.style.cursor = "pointer";
          for (let i = 0; i < n; ++i) {
            context.fillStyle = colors[i];
            context.fillRect(i, 0, 1, 1);
          }
        }
       
      }

      function custom(name) {
        const colors = [];//d3[`scheme${name}`];

        const n = 10;//name.length;
        for (let i = 0; i < n; ++i) {
            colors.push(name(i));
          }

       
        const dark = d3.lab(colors[0]).l < 50;
        let canvas=null;

        var svg = d3.select(derecha)
        .append("svg")
        .attr("id","svg_"+nameDiv)
        //.attr("preserveAspectRatio", "xMinYMin meet")
        .attr(
            "viewBox",
            "0 0 "+n+" 1")
        .classed("svg-content", true)
        .style("display","block")
        .style("width",n * 33+"px")
        .style("height",33+"px")
        .style("margin","0 10px")
        .style("cursor","pointer")

    

        colors.map((c, i) =>{
            canvas = svg.append("rect")
            .attr("x",i/2)
            .attr("width",0.5)
            .attr("height",0.5)
            .attr("fill",c)

        })
    
 
      }

      function copy(text) {
        const fakeElem = document.body.appendChild(document.createElement("input"));
        fakeElem.style.position = "absolute";
        fakeElem.style.left = "-9999px";
        fakeElem.setAttribute("readonly", "");
        fakeElem.value = "" + text;
        fakeElem.select();
        try {
          return document.execCommand("copy");
        } catch (err) {
          return false;
        } finally {
          fakeElem.parentNode.removeChild(fakeElem);
        }
      }

      function interpolateCosine([ar, ag, ab], [br, bg, bb], [cr, cg, cb], [dr, dg, db]) {
        return t => `rgb(${[
          ar + br * Math.cos(2 * Math.PI * (cr * t + dr)),
          ag + bg * Math.cos(2 * Math.PI * (cg * t + dg)),
          ab + bb * Math.cos(2 * Math.PI * (cb * t + db))
        ].map(v => Math.floor(Math.max(0, Math.min(1, v)) * 255))})`;
      }

      //ramp(interpolateCosine([0.5, 0.5, 0.5], [0.5, 0.5, 0.5], [1.0, 1.0, 1.0], [0.00, 0.33, 0.67]))

return node;
}
export default createScaleColor;