import React, { Component } from 'react';
import FRMLayout from './form-modal-layout';
import axios from 'axios';
import qs from 'qs';
import SMPortModel from '../simplePortModel/SMPortModel';

import { cerrarSesion } from '../../../../utils/commonFunction'

class FRMModal extends Component {
    state = {
        text_blocks: {'bl_0': ""},
        nodeId: "",
        keyword: "",
        title: "",
        selection:  null,
        range: null,
        keyword_valid: true,
        timeout_valid: true,
        error_limit_item:false,
    }

    constructor(props) {
        super(props)
        
        this.typeStetament = {
            audio: 1,
            text: 0
        };
        
    }

    componentWillMount(){
        var auth=JSON.parse(localStorage.getItem('Authorization'));
        if(!auth || !auth.access_token)
          this.props.history.push("/login");
    }

    getAvatarId = (avatarid) => {
        this.props.modal.node.extras.avatar = avatarid;
    }
    
    getTitleOperator = (titleOperator) => {
        this.props.modal.node.extras.titleOperator = titleOperator
    }

    getTimeout = (time) => {
        this.props.modal.node.extras.timeout = time
    }

    getMsgTimeout = (msg) => {
        this.props.modal.node.extras.msg_timeout = msg
    }

    isTimeoutValid = (valid) => {
        this.setState({timeout_valid: valid}, ()=>{
            this.forceUpdate();
        })
    }

  

    toggleModuleModal = () => {
        const node = this.props.modal.node; 
        this.setState({error_limit_item:false});       
        const blocks = node.extras.text_blocks;
        const kblocks = Object.keys(blocks);
        kblocks.forEach((k) => {      
            let text = $('#TMM-input-'+k).val();
            if(blocks[k].type == this.typeStetament.audio ){
                text = blocks[k].text;
            }

            blocks[k] = {
                text,
                name: (blocks[k].is_var) ? blocks[k].var_name : '',
                validation: blocks[k].validation,
                is_var:blocks[k].is_var,
                var_name:blocks[k].var_name,
                type:blocks[k].type
            };
            this.updateStatement(k, text, name, blocks[k].is_var, blocks[k].var_name, blocks[k].type ).then((res) => {
                this.props.updateSchema();
            }).catch((error)=>{
                console.log('catch updateStatement',error);
                if (error.response && error.response.status && error.response.status == 403){
                    cerrarSesion();
                }
            });
            this.forceUpdate();
        });

        const ports = node.getPorts();
        const kports = Object.keys(ports);

        const kport = kports.find(k => {
            const port = ports[k]
            return port.name == "right-timeout"
        })

        let title = this.props.modal.node.extras.titleOperator;
        this.saveTitle(title).then(res => {
            this.props.updateSchema();
            this.forceUpdate();
        }).catch((error)=>{
            console.log('catch saveTitle',error);
            if (error.response && error.response.status && error.response.status == 403){
                cerrarSesion();
            }
        });

        if(!kport){
            if(node.extras.timeout > 0){
                node.addPort(new SMPortModel(true, "right-timeout", 'sm'));
            }            
        }else{
            if(node.extras.timeout <= 0){
                kports.forEach((kport) => {
                    const port = ports[kport];                     
                    if(port.name == "right-timeout"){
                        const links = port.getLinks();
                        const klinks = Object.keys(links);
                        klinks.forEach(klink => {
                            const link = links[klink];
                            link.remove();
                        });
                        node.removePort(port);
                    }                    
                })
            } 
        }

        if(!this.state.timeout_valid)
            return false
        
        if(this.state.keyword_valid)
            this.props.toggleModuleModal('form')
    }
    saveTitle = (title) => {
        const data = {
            id: this.props.modal.node.extras.dbId,
            title:title
        };

        var auth=JSON.parse(localStorage.getItem('Authorization'));
        var headers={
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer '+ auth.access_token 
        }

        return axios({
            method:'put',
            url:HOST_API +'/api/scheme/operator/',
            data:qs.stringify(data),
            headers:headers
        })
    }

    addNewTextBlock = (e,type) => {
        const node = this.props.modal.node;
        this.saveStatement(node.extras.dbId).then((res) => {
            if (res.status== 200){
                 //size: item inside the node
                 const size = Object.keys(node.extras.text_blocks).length
                 if(size > 4){
                     e.persist(); //event is not being nullified
                     this.setState({error_limit_item:true})
                     this.forceUpdate();
                     this.props.updateSchema()
                     return false;
                 }else{
                     const blocks = node.extras.text_blocks;
                     blocks[res.data.statement.id] = {
                        text: "",
                        name: "",
                        validation: "N/A",
                        is_var:false,
                        var_name:"",
                        type
                    };
                     this.setState({error_limit_item:false})
                     this.forceUpdate();
                     this.props.updateSchema();
                     return true;
                 }
            }            
        }).catch((error)=>{
            console.log('catch addNewTextBlock',error);
            if (error.response && error.response.status && error.response.status == 403){
                cerrarSesion();
            }
        });
    }

    removeStatement = (statement) => {
        const data = {
            id: statement,
        };

        var auth=JSON.parse(localStorage.getItem('Authorization'));
        var headers={
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer '+ auth.access_token 
        }

        return axios({
            method:'delete',
            url:HOST_API +'/api/scheme/statement/',
            data:qs.stringify(data),
            headers:headers
        })
    }

    removeTextBlock = (index) => {
        if(this.state.error_limit_item == true){
            this.setState({error_limit_item:false})
        }
        let blocks = this.props.modal.node.extras.text_blocks;
        delete blocks[index];
        this.removeStatement(index).then(res => {
            this.props.updateSchema()
        });
        this.forceUpdate();
    }

    updateValidation = (index, event) => {
        let blocks = this.props.modal.node.extras.text_blocks;
        blocks[index].validation = event.target.value;
        this.forceUpdate();
    }

    updateVariable = (index, event) => {
        let blocks = this.props.modal.node.extras.text_blocks;
        blocks[index].is_var = (event.target.value == 'true');
        if(!blocks[index].is_var) {
            blocks[index].var_name = '';
        }
        this.forceUpdate();
    }

    updateVarName = (index, event) => {
        let blocks = this.props.modal.node.extras.text_blocks;
        blocks[index].var_name = event.target.value ;
        this.forceUpdate();
    }

    saveStatement = (operator) => {
        const data = {
            operator_id: operator,
            text: "",
            name: ""
        };

        var auth=JSON.parse(localStorage.getItem('Authorization'));
        var headers={
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer '+ auth.access_token 
        }

        return axios({
            method:'post',
            url:HOST_API +'/api/scheme/statement/',
            data:qs.stringify(data),
            headers:headers
        });
    }

    updateStatement = (statement, text, name,is_var,var_name,type) => {
        const data = {
            id: statement,
            text,
            name,
            is_var,
            var_name,
            type
        };

        var auth=JSON.parse(localStorage.getItem('Authorization'));
        var headers={
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer '+ auth.access_token 
        }

        return axios({
            method:'put',
            url:HOST_API +'/api/scheme/statement/',
            data:qs.stringify(data),
            headers:headers
        });
    }

    updateKeyword = (text) => {    
        this.props.modal.node.extras.keyword = text;
        this.saveKeyword(text);
        this.forceUpdate();
    }
    /* Update data de keyword */
    saveKeyword = (keyword) => {
        const data = {
            id: this.props.modal.node.extras.dbId,
            keyword:keyword
        };
        var auth=JSON.parse(localStorage.getItem('Authorization'));
        var headers={
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer '+ auth.access_token 
        }
        return axios({
            method:'put',
            url:HOST_API +'/api/scheme/operator/',
            data:qs.stringify(data),
            headers:headers
        })
    }

    updateItem = (k,text) => {  
        this.props.modal.node.extras.text_blocks[k].text = text;
        this.forceUpdate();
    }

    getAudioStatement = (k,url) => {
        this.props.modal.node.extras.text_blocks[k].text = url;
        this.forceUpdate();
    }

 

    /* END SEARCH VARIABLE FUNCTIONS AND HANDLERS */

    isKeywordValid = (val) => {        
        this.setState({keyword_valid:val}, ()=>{
            this.forceUpdate();
        })
    }

    closeModal = () => {
        this.props.closeModal('m6');
        this.forceUpdate();
    }

        //Emoji
    callEmoji = (code,k,type)=>{
            let text = $('#TMM-input-'+k).val();
            text += ` ${code}`;
            $('#TMM-input-'+k).val(text);
            this.updateItem(k,text) 
    }

     // IA set node extras
     getIAId = IAid => this.props.modal.node.extras.ia_id = IAid;

    render() {
        return (
          <FRMLayout 

          typeStetament = {this.typeStetament}

          toggleModuleModal={this.toggleModuleModal}
          addNewTextBlock={this.addNewTextBlock}
          updateValidation={this.updateValidation}
          removeTextBlock={this.removeTextBlock}
          updateKeyword={this.updateKeyword}
          isOpen={this.props.modal.isOpen} 
          text_blocks={this.props.modal.node.extras.text_blocks} 
          nodeId={this.state.nodeId} 
          keyword={this.props.modal.node.extras.keyword} 
          title={this.state.title} 
          varList={this.props.varList}
          botversion_id={this.props.botversion_id}
          operator_id={this.props.modal.node.extras.dbId}
          ia={this.props.modal.node.extras.ia_id || 'none'}       
          isKeywordValid={this.isKeywordValid}
          error_limit_item={this.state.error_limit_item}
          updateItem={this.updateItem}
          getTimeout={this.getTimeout}
          getMsgTimeout={this.getMsgTimeout}
          isTimeoutValid={this.isTimeoutValid}
          timeoutValue={this.props.modal.node.extras.timeout}
          timeoutMsg={this.props.modal.node.extras.msg_timeout}
          closeModal={this.closeModal}
          avatarid={this.props.modal.node.extras.avatar || 0}
          getAvatarId={this.getAvatarId}
          getIAId = {this.getIAId}
          company_id={this.props.company_id}
          titleOperator = {this.props.modal.node.extras.titleOperator || ''}
          getTitleOperator = {this.getTitleOperator}
          callEmoji = {this.callEmoji}
          updateVariable = {this.updateVariable}
          updateVarName = {this.updateVarName}
          getAudioStatement={this.getAudioStatement}
          />
        )
    }
}

export default FRMModal