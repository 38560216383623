import { COMPANY_ACTIONS } from '../types/companyActionsTypes';
import { getHandlerGET, getHandlerPOST, getHandlerDELETE, getHandlerPUT ,fileHandlerImagenPOST} from '../../utils/handlersApi';
import { logOut } from './loginActions';
import { showSuccess, loading, showWarning, hideAlert } from './loadStateActions';
import { cerrarSesion, isEmpty } from '../../utils/commonFunction';
import i18next from '../../../i18n';

export const getAllCompanies = () => async (dispatch, getState) => {
    const URL = `${HOST_API}/api/company/`;
    await getHandlerGET(URL)
    .then((res)=>{
        if(res.status == 200){
            if(res.data.companies){
                dispatch({
                    type: COMPANY_ACTIONS.getAllCompanies(),
                    payload: res.data.companies
                });                
            }
        }
        dispatch(hideAlert());
    })
    .catch((error)=>{
        if (error.response.status == 403){
            dispatch(logOut());
            cerrarSesion();
        }
        dispatch(hideAlert());
    })
        
};


export const deleteCompany = (companyID, data) => async (dispatch) => {
    const URL = `${HOST_API}/api/company/status/${companyID}`;
    dispatch(loading());

    await getHandlerPUT(URL, {status: data})
    .then((res)=>{
        if(res.status == 200){
            dispatch(showSuccess(i18next.t('message136'),2000));
            dispatch(getAllCompanies());
        }        
    })
    .catch((error)=>{
        if (error.response.status == 403){
            dispatch(logOut());
            cerrarSesion();
        }
        dispatch(hideAlert());
        dispatch(showWarning(i18next.t('message137')));
    })
        
};

export const saveCompany = (data,createUser = () => {}) => async (dispatch) => {
    const URL = `${HOST_API}/api/company/`;

    dispatch(loading());

    await getHandlerPOST(URL, data)
    .then((res)=>{
        if (res.status == 200) {
            createUser(res.data.company.id);
            dispatch(showSuccess(i18next.t('message138'),2000));
            dispatch(getAllCompanies());
        }
    })
    .catch((error)=>{
        if (error.response.status == 403){
            dispatch(logOut());
            cerrarSesion();
        }
        dispatch(hideAlert());
        dispatch(showWarning(i18next.t('message139')));
    });
};

export const editCompany = (companyID,data) => async (dispatch) => {

    const URL = `${HOST_API}/api/company/${companyID}`;
    dispatch(loading());

    await getHandlerPUT(URL, data)
    .then((res)=>{
        if (res.status == 200) {
            dispatch(showSuccess(i18next.t('message140'),2000));
            dispatch(getAllCompanies());
        }
    })
    .catch((error)=>{
        if (error.status == 403){
            dispatch(logOut());
            cerrarSesion();
        }
        dispatch(hideAlert());
        dispatch(showWarning(i18next.t('message141')));
    });
};

    /* Users Actions */
export const newUser = (data) => async (dispatch) => {
    const URL = `${HOST_API}/api/user/new_user`

    await getHandlerPOST(URL, data)
    .then((res)=>{
        if (res.status == 200) {
            dispatch(showSuccess(i18next.t('message142'),2000));
        }
    })
    .catch((error)=>{
        if (error.response.status == 403){
            dispatch(logOut());
            cerrarSesion();
        }
        dispatch(hideAlert());
        dispatch(showWarning(i18next.t('message143')));
    });
};

export const getListUser = (companyID) => async (dispatch) => {
    const URL = `${HOST_API}/api/company/users/${companyID}`;
    dispatch(loading());
    await getHandlerGET(URL)
    .then((res)=>{
        if (res.status == 200) {
            if (!isEmpty(res.data.users)) {
                dispatch({
                    type: COMPANY_ACTIONS.getUsersByCompany(),
                    payload: res.data.users
                });
            } else {
                dispatch({
                    type: COMPANY_ACTIONS.getUsersByCompany(),
                    payload: []
                });
            }
        }
        dispatch(hideAlert());
    })
    .catch((error)=>{
        if (error.response.status == 403){
            dispatch(logOut());
            cerrarSesion();
        }
        dispatch(hideAlert());
        dispatch(showWarning(i18next.t('message144')));
    });
};

export const saveEditUser = (data, success) => async (dispatch) => {
    const URL = `${HOST_API}/api/user/admin`;
    
    dispatch(loading());
    await getHandlerPOST(URL,data)
    .then((res)=>{
        if (res.status == 200) {
            
            let ms = '';

            if(!success){

                
                let dataEmail = data.email;
                let email     = JSON.parse(localStorage.getItem("UserInfo")).email;

                setTimeout(() => {

                    if (email !== dataEmail) {

                        dispatch(showSuccess(i18next.t('message145'),7000));

                    } else {
             
                        dispatch(showSuccess(i18next.t('message145'),7000));

                        setTimeout(() => {

                            dispatch(logOut());
                            cerrarSesion();
                            
                        }, 5000);
                    }
                
                }, 100);


            }else {
                
                setTimeout(() => {

                    dispatch(showSuccess(i18next.t('message146'), 7000));
                
                    setTimeout(() => {
                        dispatch(logOut());
                        cerrarSesion();
                    }, 5000);
                
                }, 100);
            }
        
        }else if(res.status == 202){
            dispatch(showWarning('Esta contraseña ya fue usada.',7000));
        }
    })
    .catch((error)=>{
        if (error.response.status == 403){
            dispatch(logOut());
            cerrarSesion();
        }
        dispatch(hideAlert());
        dispatch(showWarning(i18next.t('message147')));
    });
};

export const saveNewUser = (data) => async (dispatch) => {
    const URL = `${HOST_API}/api/user/new_user`;
    dispatch(loading());
    await getHandlerPOST(URL, data)
    .then((res)=>{
        if (res.status == 200) {
           dispatch(showSuccess(i18next.t('message142'),1000));
           dispatch(getListUser(data.company_id));
        }
    })
    .catch((error)=>{
        if (error.response.status == 403){
            dispatch(logOut());
            cerrarSesion();
        }
        dispatch(hideAlert());
        dispatch(showWarning(i18next.t('message143')));
    });
};

export const deleteUser = (user_id, company_id) => async (dispatch) => {
    const URL = `${HOST_API}/api/user/${user_id}`;
    dispatch(loading());
    await getHandlerDELETE(URL)
    .then((res)=>{
        if (res.status == 200) {
           dispatch(showSuccess(i18next.t('message148'), 2000));
           dispatch(getListUser(company_id));
        }
    })
    .catch((error)=>{
        if (error.response.status == 403){
            dispatch(logOut());
            cerrarSesion();
        }
        dispatch(hideAlert());
        dispatch(showWarning(i18next.t('message149')));
    });
};



 /* Avatar */
 export const listAvatar = (company_id) => async (dispatch) => {
    const URL = `${HOST_API}/api/avatar/?company_id=${company_id}`;

    await getHandlerGET(URL)
      .then((res) => {
        if (res.status == 200)
        {
            dispatch({
                type: COMPANY_ACTIONS.getAvatarsByCompany(),
                payload: res.data.data
            });        
        }
      })
      .catch((error)=>{
        if (error.response.status == 403){
            dispatch(logOut());
            cerrarSesion();
        }
        dispatch(hideAlert());
        dispatch(showWarning(i18next.t('message149')));
    });
  }

export const listAvatarBot = (bot_id) => async (dispatch) => {
    const URL = `${HOST_API}/api/avatar/bot/${bot_id}`;
   
    await getHandlerGET(URL)
      .then((res) => {
        if (res.status == 200) {
          let result = res.data.data,
            ar2 = Object.keys(result),
            ar = Object.values(result);
          let arreglo = {};
          for (let i = 0; i < ar2.length; i++) {
            if (arreglo[ar[i]]) {
              arreglo[ar[i]] += ", " + ar2[i];
            } else {
              arreglo[ar[i]] = ar2[i];
            }
          }
          this.setState({ avatarListBot: arreglo });
        }
      })
      .catch((error)=>{
        if (error.response.status == 403){
            dispatch(logOut());
            cerrarSesion();
        }
        dispatch(hideAlert());
        dispatch(showWarning(i18next.t('message149')));
    });
  }