import React from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";
import classnames from "classnames";
import TabFbConfi from "./tab_facebook_confi";
import TabWSConfi from "./tab_whatsapp_confi";
import TabWEBConfi from "./tab_web_confi";
import TabGBMConfi from "./tab_gbm_confi";
import TabINConfi from "./tab_instagram_confi";
import TabTWITTERConfi from "./tab_twitter_confi";
import TabMESSAGEConfi from "./tab_imessage_confi";;
import TabTELEGRAMConfi from "./tab_telegram_confi";

export default class TabChannelConfi extends React.Component {
  constructor(props) {
    super(props);  
    this.state = {
      activeTab: "1",
      optionApiWS: [
        { value: "SMOOCH", text: "Smooch" },
        { value: "GUPSHUP", text: "Gupshup" },
        { value: "ALDEAMO", text: "Aldemo" },
        { value: "MASIVIAN", text: "Masivian" },
        { value: "WAVY", text: "Wavy" },
        { value: "OTRO", text: "Otro" },
      ],
      optionApiTW: [{ value: "SMOOCH", text: "Smooch" }],
      optionApiGBM: [{ value: "ROUTEMOBILE", text: "Route Mobile" }],
      nameApiGBM:"gbmp_api",
      idUserApiGBM:"gbm_username",
      idPwdApiGBM:"gbm_password",
      nameApiWS:"wp_api",
      idUserApiWS:"wp_username",
      idPwdApiWS:"wp_token",
      idPhoneApiWS:"wp_phone",
      nameApiTW:"twitter_api",
      idUserApiTW:"tw_username",
      idPwdApiTW:"tw_token",
      nameApiTL:"telegram_api",
      idUserApiTL:"telegram_username",
      idPwdApiTL:"telegram_token",
      nameApiMS:"imessage_api",
      idUserApiMS:"imessage_username",
      idPwdApiMS:"immessage_token",
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }
  render() {

    return (
      <Row>
        <Col xs="6" sm="4" md="4">
          <Nav tabs vertical pills>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "1" })}
                onClick={() => {
                  this.toggle("1");
                }}
              >
                Facebook
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "2" })}
                onClick={() => {
                  this.toggle("2");
                }}
              >
                Whatsapp
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "3" })}
                onClick={() => {
                  this.toggle("3");
                }}
              >
                Instagram
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "4" })}
                onClick={() => {
                  this.toggle("4");
                }}
              >
                Google Bussiness Message
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "5" })}
                onClick={() => {
                  this.toggle("5");
                }}
              >
                Web
              </NavLink>
            </NavItem>

            <NavItem>
            <NavLink
                className={classnames({ active: this.state.activeTab === "6" })}
                onClick={() => {
                  this.toggle("6");
                }}
              >
                Twitter
              </NavLink>
            </NavItem>

            <NavItem>
            <NavLink
                className={classnames({ active: this.state.activeTab === "7" })}
                onClick={() => {
                  this.toggle("7");
                }}
              >
                Imessage
              </NavLink>
            </NavItem>

            <NavItem>
            <NavLink
                className={classnames({ active: this.state.activeTab === "8" })}
                onClick={() => {
                  this.toggle("8");
                }}
              >
                Telegram
              </NavLink>
            </NavItem>
          </Nav>
        </Col>
        <Col xs="8" sm="8" md="8">
          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId="1">
              <TabFbConfi props={this.props.props}></TabFbConfi>
            </TabPane>
            <TabPane tabId="2">
              <TabWSConfi
                props={this.props.props}
                optionApi={this.state.optionApiWS}
                name={this.state.nameApiWS}
                idUser={this.state.idUserApiWS}
                idPwd={this.state.idPwdApiWS}
                idPhone={this.state.idPhoneApiWS}
                wp_username={this.props.props.botInfo?this.props.props.botInfo.wp_username:''}
                wp_phone={this.props.props.botInfo?this.props.props.botInfo.wp_phone:''}
                wp_token={this.props.props.botInfo?this.props.props.botInfo.wp_token:''}
                wp_api={this.props.props.botInfo?this.props.props.botInfo.wp_api:''}
                handleChangeSelect={this.props.handleChangeSelect}
                setValue={this.props.setValue}
              ></TabWSConfi>
            </TabPane>
            <TabPane tabId="3">
              <TabINConfi
                props={this.props.props}
              ></TabINConfi>
            </TabPane> 
            <TabPane tabId="4">
              <TabGBMConfi
                props={this.props.props}
                optionApi={this.state.optionApiGBM}
                name={this.state.nameApiGBM}
                idUser={this.state.idUserApiGBM}
                idPwd={this.state.idPwdApiGBM}
                wp_username={this.props.props.botInfo?this.props.props.botInfo.gbm_username:''}
                wp_token={this.props.props.botInfo?this.props.props.botInfo.gbm_password:''}
                wp_api={this.props.props.botInfo?this.props.props.botInfo.gbmp_api:''}
                handleChangeSelect={this.props.handleChangeSelect}
              ></TabGBMConfi>
            </TabPane>
            <TabPane tabId="5">
              <TabWEBConfi props={this.props.props} handleChangeSessionVar={this.props.handleChangeSessionVar}></TabWEBConfi>
            </TabPane>

            <TabPane tabId="6">
              <TabTWITTERConfi
                props={this.props.props}
                optionApi={this.state.optionApiTW}
                name={this.state.nameApiTW}
                idUser={this.state.idUserApiTW}
                idPwd={this.state.idPwdApiTW}
                tw_username={this.props.props.botInfo?this.props.props.botInfo.tw_username:''}
                tw_token={this.props.props.botInfo?this.props.props.botInfo.tw_token:''}
                twitter_api={this.props.props.botInfo?this.props.props.botInfo.twitter_api:''}
                handleChangeSelect={this.props.handleChangeSelect}
                setValue={this.props.setValue}
              ></TabTWITTERConfi>
            </TabPane> 

            <TabPane tabId="7">
            <TabMESSAGEConfi
                props={this.props.props}
                optionApi={this.state.optionApiTW}
                name={this.state.nameApiMS}
                idUser={this.state.idUserApiMS}
                idPwd={this.state.idPwdApiMS}
                imessage_username={this.props.props.botInfo?this.props.props.botInfo.imessage_username:''}
                imessage_password={this.props.props.botInfo?this.props.props.botInfo.imessage_password:''}
                imessage_api={this.props.props.botInfo?this.props.props.botInfo.imessage_api:''}
                handleChangeSelect={this.props.handleChangeSelect}
                setValue={this.props.setValue}
              ></TabMESSAGEConfi>
            </TabPane> 

            <TabPane tabId="8">
            <TabTELEGRAMConfi
                props={this.props.props}
                optionApi={this.state.optionApiTW}
                name={this.state.nameApiTL}
                idUser={this.state.idUserApiTL}
                idPwd={this.state.idPwdApiTL}
                telegram_username={this.props.props.botInfo?this.props.props.botInfo.telegram_username:''}
                telegram_password={this.props.props.botInfo?this.props.props.botInfo.telegram_password:''}
                telegram_api={this.props.props.botInfo?this.props.props.botInfo.telegram_api:''}
                handleChangeSelect={this.props.handleChangeSelect}
                setValue={this.props.setValue}
              ></TabTELEGRAMConfi>
            </TabPane> 

          </TabContent>
        </Col>
      </Row>
    );
  }
}
