import { TEMPLATE_ACTIONS } from "../types/templateActionsTypes";

const INITIAL_STATE = {
  listAllTemplate: [],
  editingTemplate: [],
  template: [],
  variableTemplate: [],
  tempVariablesTemplate: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TEMPLATE_ACTIONS.getAllTemplate():
      return {
        ...state,
        listAllTemplate: action.payload,
      };
    case TEMPLATE_ACTIONS.getTemplateByiD():
      return {
        ...state,
        ...action.payload,
      };
    case TEMPLATE_ACTIONS.saveUpdateTemplate():
      return {
        ...state,
        editingTemplate: [],
        template: [],
      };
    case TEMPLATE_ACTIONS.setVariableTemplate():
      return {
        ...state,
        variableTemplate: action.payload,
      };
    case TEMPLATE_ACTIONS.setTempVariableTemplate():
    return {
        ...state,
        tempVariablesTemplate: action.payload,
    };

    default:
      return state;
  }
};
