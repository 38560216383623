import React from 'react';
import './modal-load.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

function ModalLoad(props){
    return (
    	<div>
        {props.hideLoad>0?(<div className="modal-mask" >
            <div className="msg-box" align="center">
                <img src="/static/img/loading.gif" />
            </div>
        </div>):null}
        </div>
    );
}
  
export default ModalLoad
