import React from 'react';
var d3 = require('d3');
import d3DataToJSX from '../../../../../components/infoviz/react-d3/d3DataToJSX';
import ChildComponent from './ChildComponent';
import createLineChart from '../basic-graphs/createMultiLineChart';
import FormatD3 from '../../../../../components/infoviz/utils/defineFormats';
import LineData from '../d3-examples/dataLineChart';
import { isEmpty } from "../../../../../utils/commonFunction";


export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {d3DOM: [], state: [],nameDiv: " ",data:[]};
  }
  componentWillMount() {
    this.setState({nameDiv:this.props.nameDiv });
  }

  //shouldComponentUpdate(prevProps) {
    // Permitimos renders sólo si los datos
    // han cambiado. Así alcanzaremos el siguiente
    // punto del ciclo de vida.
   // return Boolean(prevProps.data !== this.props.data);
 // }
  //componentDidMount()
  //{
  //   var parseDate = d3.timeParse("%Y");

  //   if(isEmpty(props.data)){
  //   props.data.datasetLineChart.forEach(function(d) { 
  //     d.values.forEach(function(d) {
  //       d.datefin =  parseDate(d.datefin);
  //       d.dateini =  parseDate(d.dateini);
  //       d.measure = +d.measure;    
  //     });
  //   });
  // }
  //}


  componentWillReceiveProps(nextProps) {

    let d3Data = d3DataToJSX(createLineChart(nextProps.nameDiv,nextProps.data,FormatD3,nextProps.group,nextProps.color,nextProps.typeScaleColor,nextProps.callBackLineChart,nextProps.title,nextProps.xAxisName,nextProps.yAxisName,nextProps.description,nextProps.granularity));
    this.setState({d3DOM: d3Data.mappedData, state: d3Data.state, data:nextProps.data})
  }

  render() {
    return (
      <React.Fragment>
      <ChildComponent data={this.state} />
      <div id={"tooltip_"+this.state.nameDiv} className="tooltip"></div>
    </React.Fragment>
    )
  }
};