import React,{ Component } from 'react';
import { Button,Input,Container, Row, Col, Form, FormGroup, Label, Alert } from 'reactstrap';
import './changepassword-layout.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import  Recaptcha from 'react-recaptcha';
import i18next from '../../../i18n'


class ChangePasswordLayout extends Component  {
    constructor(props) {
        super(props)
        this.state = {
            isVerify:false
        }
        this.callback = this.callback.bind(this);
    }
     callback =  () => true;
       
     verifyCallback = (response) => {
        if(response)
            this.setState({isVerify:true})
        else
            alert('Dale check al reCAPTCHA para continuar')
      };

    render(){
        return (
            <Container className="d-flex align-items-center">
                <Col className="barra-box"></Col>
                <Row>
                    <Col>
                        <Row>
                            <Col className="d-none d-md-block d-lg-block d-xl-block img-login-box" md={{size:4, offset:1}}>
                            </Col>
                            <Col sm="12" md="6" className="form-login">
                                <h1 className="text-guarumo mb-2">{i18next.t('titleChangepass')}</h1>
                                
                                {
                                this.props.blockAccount.loginReducer.userBlock === true ? 
                                    <p style={{ textAlign: 'left', fontSize: '18px' }}> {i18next.t('block_account')} </p> 
                                    : ""
                                }

                                    <Alert>
                                    <h4 className="alert-heading">
                                        {i18next.t('titleChangepass')}
                                    </h4>
                                    <p>
                                       {i18next.t('textChangepass')}
                                    </p>
                                    <hr />
                                    <p className="mb-0">
                                        {i18next.t('noteChangepass')}
                                    </p>
                                    </Alert>
                                
                                <Form onSubmit={this.props.onSubmit}>
                                    
                                    <FormGroup>
                                        <Label for="oldpassword" className="font-weight-bold font14">{i18next.t('oldpassword')} *</Label><br></br>
                                        <Input type="password" name="oldpassword" id="oldpassword" onChange={this.props.handeloldpasswordChange} placeholder={i18next.t('oldpassword')}/>
                                    </FormGroup>
                                   
                                    <FormGroup className="mt-4">
                                        <Label for="newpassword" className="font-weight-bold font14">{i18next.t('newpassword')} *</Label><br></br>
                                        <Input type="password" name="newpassword" id="newpassword" onChange={this.props.handelnewpasswordChange} placeholder={i18next.t('newpassword')} />
                                    </FormGroup>
                                    
                                    <FormGroup className="mt-4">
                                        <Label for="repeatpassword" className="font-weight-bold font14">{i18next.t('repeatpassword')} *</Label><br></br>
                                        <Input type="password" name="repeatpassword" id="repeatpassword" onChange={this.props.handelrepeatpasswordChange} placeholder={i18next.t('repeatpassword')} />
                                    </FormGroup>

                                    <Recaptcha
                                            sitekey="6LegRqwUAAAAALvAYtvXsF3IRdIvep6mRxY_fThk"
                                            render="explicit"
                                            verifyCallback={this.verifyCallback}
                                            onloadCallback={this.callback}
                                        />
                                    <Col md="12" className="button-box">
                                        <Button type="submit" disabled={this.state.isVerify == false} id="btnlogin" color="secundary" className="btn-block btn-lg btn-guarumo">{i18next.t('change_password')}.</Button>
                                    </Col>
                                </Form>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <SweetAlert success title="Ha iniciado sesión" show={this.props.showAlert} onConfirm={this.props.hideAlert} showConfirm={false} />
                <SweetAlert warning title={this.props.warningMsg} show={this.props.showAlertWarning} onConfirm={this.props.hideAlert} showConfirm={false} />
            </Container>
          )
    }
    
}    

export default ChangePasswordLayout
