import React, {Component} from 'react';
import { connect } from "react-redux";

import { Label,Input,UncontrolledTooltip,Button,Modal,ModalHeader,ModalBody,ModalFooter,Form,FormGroup,Table,Col,Row,Nav,NavItem,TabContent,TabPane,NavLink} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faLink } from '@fortawesome/free-solid-svg-icons'
import IASelector from '../../../../components/ia';
import CMSSelector from '../../../../components/cms'
import Keyword from '../../../../components/keyword/keyword';
import Timeout from '../../../../components/timeout/timeout';
import TitleOperator from "../../../../components/title-operator";
import Mentions from '../../../../components/mentions/metions-redux';
import Avatar from '../../../../components/avatar';
import Audio from '../../../../components/audio-input';
import Emoji from '../../../../components/emoji-picker';
import ModalUrl from '../../../../components/modals/shorturl/modal-shorturl';
import {Link} from 'react-router-dom';
import classnames from 'classnames';
import i18next from '../../../../../i18n';



import {  
    toggleModalViewBoxBlockchain,
    changeActiveTab,
    setChangeConfig } from "../../../../redux/actions/boxActions";

function BMLayout(props) {

// class TMMLayout extends Component {

//     constructor(props){
//         super(props)
//         this.state = {
//         activeTab: '1'
//         };
//         this.toggleTab = this.toggleTab.bind(this);
//     }


const {selectedBoxOperator,listContractBlockchain,listProviderBlockchain, configBoxOperator, modalViewBoxBlockchain,activeItem,avatarid,botmsg_text,dinamicVariablesBoxOperator,node} =props.boxReducer;
const { bot } = props.botReducer;

    function inputEnunciado (bls,kblocks) {
        let block = kblocks.map((k, index) => {
            return <tr key={k} style={{'display':'table','width': 100+'%','tableLayout': 'fixed'}}>
                <td className={ props.visible == 1 ? 'visibilityInput':'td-flujo' } style={{'height':'70px'}}>
               <Row>
                <Col sm="6">
                    <FormGroup>
                        <Label>{i18next.t('column_title')} <i className="fa fa-info-circle" id={"tooltipCol_"+k}></i></Label>
                        <UncontrolledTooltip placement="right" target={"tooltipCol_"+k}> {i18next.t('message43')}</UncontrolledTooltip>
                        <Input id={"TMM-input-value-BL-"+k} type="text" name={"titleColumn_"+k}  defaultValue={bls[k].name=='null'?'':bls[k].name} maxLength={'50'}/>
                    </FormGroup>
                </Col>
                <Col sm="6">
                    <Label>{i18next.t('value')} <i className="fa fa-info-circle" id={"tooltipVal_"+k}></i></Label>
                    <UncontrolledTooltip placement="right" target={"tooltipVal_"+k}> {i18next.t('message44')}</UncontrolledTooltip>
                    <Mentions 
                    className={ props.visible == 1 ? 'visibilityInput':'td-flujo' }
                    defaultValue={bls[k].text}
                    maxlength={'2000'} msg_error={i18next.t('msg_error_limit')}
                    updateItem={props.updateItem}
                    index={index}
                    k={k}/>
                            
                   </Col>
                </Row>
                    <div className="bar-emoji-input">                        
                        <Button color="info" size="lg" onClick={() => props.removeTextBlock(k) }>
                            <FontAwesomeIcon icon={faTrash} />
                        </Button>
                        <Button color="info" size="lg" onClick={() => props.toggleModalUrl(k) }>
                            <FontAwesomeIcon icon={faLink} />
                        </Button>
                        <Emoji id={k} callEmoji={props.callEmoji} type="item" style={{'display':'none',position:'fixed',marginLeft: 60+'%',marginTop:31+'px',zIndex: 100}}/>
                    </div>
                    
                </td>
                <td className={ props.visible == 1 ? 'visibilityBlockInput':'visibilityInput' } >
                    <p>
                        <Link style={{'color':'#494949'}} className="text-reset text-decoration-none" target="_blank" to={'/cms/'+bot.id+'?operador='+props.operator}>
                        {i18next.t('set_up')} CMS
                        </Link>
                    </p>
                </td>
                <td className={ props.visible == 1 ? 'visibilityBlockInput':'visibilityInput' } style={{'width':'8%'}}>
                    <Button color="info" size="lg" onClick={() => props.removeTextBlock(k) }>
                        <FontAwesomeIcon icon={faTrash} />
                    </Button>
                </td>
            </tr>
        });
        return block;
    }
    
    function onchangeProvider(e)
    {
      
      const provider_id=e.target.value;
    
      //props.setSelectedTypeCalendar(type);
   
      props.setSelectedProvider(provider_id);
      props.getListContractBlockchain();
      props.setChangeConfig('provider',Number(provider_id));

    }
    
    function onchangeContract(e)
    {
    
        const contract_id=e.target.value;
    
        props.setChangeConfig('contract',Number(contract_id));
    
    }

    function toggleTab(tab) {
    
        // e.preventDefault();
        // console.log('The link was clicked.',e);
         props.changeActiveTab(tab);
     
    }

    if(selectedBoxOperator&&Object.keys(selectedBoxOperator).length>0&&selectedBoxOperator.operatortype_id==22)
    {    
           let bls = props.boxReducer.text_blocks || {};
            let kblocks = Object.keys(bls);
            let blocks = inputEnunciado(bls,kblocks)

            // let dinamicVariables = dinamicVariablesBoxOperator || {};
            // let kblocksDinVar = []
            
            // if(dinamicVariables)
            //     kblocksDinVar = Object.keys(dinamicVariables);
    
            // let blocks = inputEnunciado(dinamicVariables,kblocksDinVar)

        

        return (
            <React.Fragment>
                <Modal className="blockchain" isOpen={modalViewBoxBlockchain} toggle={props.toggleModalViewBoxBlockchain} fade size="lg">
                    <ModalHeader toggle={props.toggleModalViewBoxBlockchain}>{i18next.t('message45')}  - {node.id}</ModalHeader>
                    <ModalBody>
                        <Form id="BM-form" role="form">  
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                    className={classnames({ active: activeItem === '1' })}
                                     onClick={() => { props.changeActiveTab('1'); }} >
                                    {/* onClick={() => { this.toggleTab('1'); }} > */}
                                    {i18next.t('basic')}
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={activeItem}>
                                <TabPane tabId="1">
                                    <Row>
                                    <Col sm="12">
                                    {listContractBlockchain && Object.keys(listContractBlockchain).length > 0 ? ( 
                                        <FormGroup id='Enunciados' >
                                            <TitleOperator titleOperator={selectedBoxOperator.title} />
                                             <Row>
                                            <Col sm="4" lg="4">
                                            <Label>{i18next.t('provider')}  <i className="fa fa-info-circle" id="tooltipProvider"></i></Label>
                                            <UncontrolledTooltip placement="right" target="tooltipProvider"> {i18next.t('message46')}</UncontrolledTooltip>
                                                
                                            {listProviderBlockchain &&(Object.keys(listProviderBlockchain).length > 0 && (
                                            <Input  id="select_provider"
                                                    type="select"
                                                    className="inputModal" 
                                                    name="provider" 
                                                    defaultValue={(configBoxOperator)?configBoxOperator.provider:''}
                                                    onChange={(e) => onchangeProvider(e)}
                                                    required>
                                                { Object.keys(listProviderBlockchain).map((k, index) => {
                                                    return <option key={index} value={listProviderBlockchain[k].id} >{listProviderBlockchain[k].name}</option>;
                                                    
                                                    })
                                                }
                                            </Input> 
                                            ))}
                                            </Col>
                                            <Col sm="6" lg="6">
                                            <Label>{i18next.t('contract')}  <i className="fa fa-info-circle" id="tooltipContract"></i></Label>
                                            <UncontrolledTooltip placement="right" target="tooltipContract"> {i18next.t('message47')}</UncontrolledTooltip>
                                                
                                            
                                            <Input  type="select"
                                                    className="inputModal" 
                                                    name="contract" 
                                                    defaultValue={(configBoxOperator)?configBoxOperator.contract:''}
                                                    onChange={(e) => onchangeContract(e)}
                                                    required>
                                                { Object.keys(listContractBlockchain).map((k, index) => {
                                                    return <option key={index} value={listContractBlockchain[k].id} >{listContractBlockchain[k].name}</option>;
                                                    
                                                    })
                                                }
                                            </Input> 
                        
                                            </Col>
                                        </Row> 

                                            {/* <div id='div_enunciados' > */}
                                                <Table style={{'textAlign':'left'}}>
                                                    <thead>
                                                    <tr>
                                                        <th colSpan="2">{i18next.t('statements')}</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody style={{'maxHeight':380+'px', 'overflowX':'hidden','overflowY':'scroll', 'display':'block'}}>
                                                    { blocks }
                                                    </tbody>
                                                </Table> 
                                            {/* </div> */}
                                                <Col sm="12" className="button-add">
                                                    <Col sm="12">
                                                        <Button color="info" className="btn-guarumo-modal-blue-sm" onClick={props.addNewTextBlock}>{i18next.t('add_new')}</Button>
                                                    </Col>
                                                </Col>  
                                           
                                        </FormGroup>
                                     ):
                                     <Col sm="12" md="10" className="stadistics-container offset-md-1">
                                        <Col xs={12} lg={8} className="offset-lg-2 intro-funnels">
                                              <Row>
                                                  <Col xs={12}><img src="/static/img/blank_state.png" className="img-fluid"/></Col>
                                                  <Col xs={12}>
                                                      <h2>{i18next.t('message48')}</h2>
                                                      <h3>{i18next.t('message49')}</h3>
                                                      <p>{i18next.t('message50')}</p>
                                                  </Col>
                                              </Row>
                                          </Col>
                                      </Col>
                                      }
                                    </Col>
                                    </Row>
                                </TabPane>
                            </TabContent>
                        </Form>
                        
                    </ModalBody>
                    <ModalFooter>  
                            <Col sm="12" align="right">
                                <Button color="success" className="btn-guarumo-modal-sm" type="button" onClick={props.toggleModuleModal} >{i18next.t('finish')}</Button>
                            </Col>
                    </ModalFooter>
                </Modal>
                <ModalUrl isOpen={props.isOpenUrl} toggleModal={props.toggleModalUrl} bindValue={props.getUrl} input={props.inputStatement}/>
            </React.Fragment>
                  )
                }
                else
                {
                    return(<div></div>)
                }
        
            }
  
// export default TMMLayout
 
const mapStateToProps = ({boxReducer,botReducer,homeReducer}) => {
    return {boxReducer, botReducer, homeReducer};
    };
    
    const mapDispatchToProps = {
        toggleModalViewBoxBlockchain,
        changeActiveTab,
        setChangeConfig
    };
    
    export default connect(mapStateToProps, mapDispatchToProps )(BMLayout);