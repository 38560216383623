import {LOGIN_ACTIONS_TYPES } from '../types/loginActionsTypes';
import { getHandlerPOST,getHandlerPOST_AUTORIZATION,getHandlerGET,getHandlerPOSTGenerarToken } from "../../utils/handlersApi";
import { cerrarSesion, getUserInfoLocalStorage } from "../../utils/commonFunction";
import { isDefined } from '../../utils/isValidate';
import { hideAlert, showWarning, loading, showError } from './loadStateActions';
import { async } from 'ramda-adjunct';
import i18next from "../../../i18n";

export const logIn = (data) => async (dispatch,getState) => {

    // Loading
    dispatch(loading());

    await getHandlerPOST_AUTORIZATION(HOST_API +'/api/auth/token/', data )
    .then(async (res)=>{

        if (res.status == 200){

            await dispatch({
                type: LOGIN_ACTIONS_TYPES.getAuthorization(),
                payload: res.data
            }); 

            await localStorage.setItem('Authorization', JSON.stringify(res.data));

        }else if(res.status == 202){
        
            await dispatch({
                type: LOGIN_ACTIONS_TYPES.errorLogin(),
                payload: 'Error on get Autorization:' + res.data.msg
            });

            await dispatch({
                type: LOGIN_ACTIONS_TYPES.requestBlockUser(),
                payload: true
            });

        }else if(res.status == 204){
            await dispatch({
                type: LOGIN_ACTIONS_TYPES.errorLogin(),
                payload: 'Error on get Autorization:' + res.data.msg
            });
            await dispatch(showError(i18next.t('user_account_error') ));
        }else
        {
            await dispatch({
                type: LOGIN_ACTIONS_TYPES.errorLogin(),
                payload: 'Error on get Autorization:' + res.data.msg
            });
            await dispatch(showError(res.data.msg ));
        }
    })
    .catch((error)=>{
        dispatch({
            type: LOGIN_ACTIONS_TYPES.errorLogin(),
            payload: 'Error on get Autorization:' + error.message
        });
        dispatch(hideAlert());
    });

    await getHandlerPOSTGenerarToken(HOST_API +'/api_backend_ai/api-token-auth/', data)
    .then(async (res)=>{
        if (res.status == 200){
            await localStorage.setItem("email",res.data.email);
            await localStorage.setItem("token_apis",res.data.token);
            await localStorage.setItem("user_id",data.user_id);
            
            await dispatch({
                type: LOGIN_ACTIONS_TYPES.getEmailUsarAuthorization(),
                payload: res.data.email
            }); 
        }
    })
    .catch((error)=>{
        dispatch({
            type: LOGIN_ACTIONS_TYPES.errorLogin(),
            payload: 'Error on get Token:' + error.message
        });
    });
 
        dispatch(hideAlert());
}



export const logInAuth = () => async (dispatch,getState) => {
    
    dispatch(loading());

    const authorization = () => {
        const { authorization,password,username } = getState().loginReducer
        return authorization;
    };  

    const { password,username } = getState().loginReducer
    
    let infoUser = {username, password};
    
    if (authorization()) {
        // Login and Get user logged
        await getHandlerPOST(HOST_API + '/api/auth/login/', infoUser, authorization().access_token)
        .then(async (res) => {
            if (res.status == 200) {

                let user = res.data.user;
                
                localStorage.setItem('UserInfo', JSON.stringify(user));
                localStorage.setItem("otp",true);
                
                await dispatch({
                    type: LOGIN_ACTIONS_TYPES.successLogin(),
                    payload: user
                });

                // Check authorization 
                if(user.new_login == false){
                    await getHandlerGET(HOST_API + '/api/auth/check/',authorization().access_token )
                    .then((res)=>{
                        if (res.status == 200) {
    
                        }
                    })
                    .catch((error)=>{
                        dispatch({
                            type: LOGIN_ACTIONS_TYPES.errorLogin(),
                            payload: 'Error on get Check: ' + error.message
                        });
                        dispatch(hideAlert());
                    })
                }

            }
        })
        .catch((error) => {
            dispatch(hideAlert());
            if (error.response.status == 403){
                dispatch(showWarning(' ¡Error al iniciar sesión, comunicate con soporte para permiso de acceso! '));
                return;
            }

            dispatch(showWarning('Lo sentimos no se ha podido iniciar sesión, por favor verifique sus datos.'));
            dispatch({
                type: LOGIN_ACTIONS_TYPES.errorLogin(),
                payload: 'Error on get Auth Login: ' + error.message
            });
        });
    }

}

export const logInSendOTP = (data) => async (dispatch,getState) => {
 
    const authorization = () => {
        const { authorization } = getState().loginReducer
        return authorization;
    };  


    dispatch(loading());

        await getHandlerPOSTGenerarToken(HOST_API +'/api/auth/send_otp', data)

        .then(async(res)=>{

            if (res.status == 200){

                await dispatch({
                    type: LOGIN_ACTIONS_TYPES.requestOtpsendLogin(),
                });


                await dispatch({
                    type: LOGIN_ACTIONS_TYPES.requestUsernamesendLogin(),
                    payload: data.username
                });

                await dispatch({
                    type: LOGIN_ACTIONS_TYPES.requestPasswordsendLogin(),
                    payload: data.password
                });

                data["user_id"] = res.data.user_id;

                await dispatch(logIn(data));
                
            }
        })
        .catch((error)=>{
            dispatch({
                type: LOGIN_ACTIONS_TYPES.errorLogin(),
                payload: 'Error on get Autorization:' + error.message
            });
            dispatch(hideAlert());
        });

    dispatch(hideAlert());
}

export const logInSendToken = (data) => async (dispatch,getState) => {

    await getHandlerPOSTGenerarToken(HOST_API +'/api_backend_ai/api-token-auth/', data)
    .then((res)=>{
        if (res.status == 200){
            localStorage.setItem("token_apis",res.data.token);
        }
    })
    .catch((error)=>{
        dispatch({
            type: LOGIN_ACTIONS_TYPES.errorLogin(),
            payload: 'Error on get Token:' + error.message
        });
    });
}

export const changePasswordAction = (data) => async(dispatch,getState) => {
    
    dispatch(loading());

        await getHandlerPOST(HOST_API +'/api/auth/change_password', data )
        .then((res)=>{
           
            if (res.status === 200 && res.data.success === true){
                
                dispatch({
                    type: LOGIN_ACTIONS_TYPES.requestChangepassword(),
                    payload: true
                });
                
                dispatch({
                    type: LOGIN_ACTIONS_TYPES.requestChangepasswordMsg(),
                    payload: res.data.message
                });

            }else{
                dispatch({
                    type: LOGIN_ACTIONS_TYPES.requestChangepassword(),
                    payload: false
                });
            }
        })
        .catch((error)=>{
            dispatch({
                type: LOGIN_ACTIONS_TYPES.errorLogin(),
                payload: 'Error on get Autorization:' + error.message
            });
            dispatch(hideAlert());
        });

        dispatch(hideAlert());

}

export const logInOTP = (data) => async (dispatch,getState) => {
    
    const authorization = () => {
        const { authorization,password } = getState().loginReducer
        return authorization;
    };  

    // Loading
    dispatch(loading());

    // Getting Autorization
    //if (!authorization()) {
        await getHandlerPOSTGenerarToken(HOST_API +'/api/auth/verify_otp', data)
        .then( async (res)=>{
            if (res.status == 200){

                dispatch({
                    type: LOGIN_ACTIONS_TYPES.requestOtpLogin(),
                });
                     
                await dispatch(logInAuth());
            }
        })
        .catch((error)=>{
            dispatch({
                type: LOGIN_ACTIONS_TYPES.errorLogin(),
                payload: 'Error on get Autorization:' + error.message
            });
            dispatch(hideAlert());
        });
    //}
        dispatch(hideAlert());
}

export const logOut = () =>  (dispatch) => {

    dispatch({
        type: LOGIN_ACTIONS_TYPES.requestLogout(),      
    });
    cerrarSesion();
}

export const mapLocalStoraToState = () =>  (dispatch) => {
    const userInfo = getUserInfoLocalStorage();
    if (isDefined(userInfo)){
        dispatch({
            type: LOGIN_ACTIONS_TYPES.successLogin(),
            payload: userInfo
        });
    }
}



