import { MODAL_LOAD_ACTIONS_TYPES } from '../types/loadStateActionsTypes';


const INITIAL_STATE = {
    loading: false,
    showSuccess: false,
    msTitle: '' ,
    msSuccess: '' ,
    showWarning: false, 
    msWarning: '', 
    showError:false,
    msError:''
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
        case MODAL_LOAD_ACTIONS_TYPES.hideLoad():
            return {
                ...state,
                loading: false,
                showSuccess: false,
                showWarning: false,
                showError:false,
               // msTitle: '' ,
               // msWarning: '', 
               // msSuccess: '' ,
               // msError: ''
            };

        case MODAL_LOAD_ACTIONS_TYPES.loading():
            return {
                ...state,
                loading: true,
                showSuccess: false,
                showWarning: false,
                showError:false
            };

        case MODAL_LOAD_ACTIONS_TYPES.showSuccess():
            return {
                ...state,
                loading: false,
                showSuccess: true,
                msSuccess: action.payload,
                showWarning: false,
                showError:false
            };

        case MODAL_LOAD_ACTIONS_TYPES.showWarning():
            return {
                ...state,
                loading: false,
                showWarning: true,
                msWarning: action.payload,
                showSuccess: false,
                showError:false
            };

        case MODAL_LOAD_ACTIONS_TYPES.showError():
            return {
                ...state,
                loading: false,
                showWarning: false,
                showError: true,
                msError: action.payload,
                showSuccess: false,
            };
        
        case MODAL_LOAD_ACTIONS_TYPES.setTitle():
            return {
                ...state,
                msTitle:action.payload
            };

		default: return state;
	};
};