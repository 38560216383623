import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import 'react-dual-listbox/lib/react-dual-listbox.css';
import DualListBox from 'react-dual-listbox';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  Row,
  UncontrolledTooltip
} from "reactstrap";
//import "./toolbar.css";
import "../../../../static/css/modal-nuevo.css";
import { saveNewMetric,updateInfoMetric,toggleModalNewMetric, changeBetweenList, selectedMetric } from "../../../redux/actions/analyticsActions";
import TabNuevaMetrica from "../infoViz/common/tab-nueva-metrica";
import i18next from "../../../../i18n";


var operators=[],
    functions=[],
    textareaFocus="metricFormula";

function ModalNuevaMetrica(props) {
  
  const {  updateMetric } = props.analyticsReducer;
  const { analyticsReducer: { listAllBotsbyCompany=[],listAllUnits = [], listAllDimensions = [],modalNewMetric, listAllMetrics,listSelectedMeasures= [],selectedMetric=[] }, toggleModalNewMetric, saveNewMetric,updateInfoMetric,changeBetweenList } = props;


var options=[],met=[],mea=[];

{listAllMetrics &&(listAllMetrics.length > 0 && (
  listAllMetrics.map((measure) => {
   // measure=JSON.parse(measure); 
    if(measure.type_metric=="MET")
      met.push({value:measure.id,label:measure.name});
    else
      mea.push({value:measure.id,label:measure.name});
  return measure})
))}
options.push({label:"Métricas",options:met});
options.push({label:"Medidas",options:mea});




 

  return (
    <div>
      <Modal
        isOpen={modalNewMetric}
        toggle={toggleModalNewMetric}
        className="modal-lg"
        fade
      >
        <ModalHeader toggle={toggleModalNewMetric} charCode="X">
          {updateMetric?(i18next.t('update_info')):i18next.t('create_new_metric')}
          
        </ModalHeader>
        <Form id="form-createmetrica" onSubmit={
          (e)=>{
            updateMetric?[
              updateInfoMetric(selectedMetric.id,e,(urlDirect)=>{
                //props.history.push(urlDirect);
               
                
              })
            ]:
          saveNewMetric(e,listSelectedMeasures,(urlDirect)=>{
            props.history.push(urlDirect);
          });
        }} role="form">
          <ModalBody>
            
              <Row>
             { updateMetric?[
              
             ]:
             <FormGroup>
                        <Label for="metricName" className="control-label required-field">
                        {i18next.t('create')+': '}
                        </Label>
                        <Input
                      type="select" className="platforms-container"name="metricTypeIndi" onChange={(e) => onchangeTypeIndi(e)}>
                      <option key="1" value="medida">{i18next.t('measurement')}</option>
                      <option key="2" value="metrica">{i18next.t('metric')}</option>
                    </Input>

                    </FormGroup>
                }
                <Col sm="6">
                    <FormGroup>
                        <Label for="metricName" className="control-label required-field">
                        {i18next.t('name')+': '}
                        </Label>
                        <Input
                            type="text"
                            className="inputModal"
                            id="metricName"
                            name="metricName"
                            maxLength="130"
                            placeholder={i18next.t('metric_name')}
                            defaultValue={(selectedMetric.name)?selectedMetric.name:''}
                            required
                        />
                    </FormGroup>
                    <Row>

                    
                    <Col sm="6">
              <FormGroup>
                  <Label for="metricShortName" className="control-label required-field">
                    {i18next.t('short_name')}
                  </Label>
                  <Input
                    type="text"
                    className="inputModal"
                    id="metricShortName"
                    name="metricShortName"
                    maxLength="130"
                    placeholder={i18next.t('short_name_metric')}
                    defaultValue={(selectedMetric.short_name)?selectedMetric.short_name:''}
                    required
                  />
                 </FormGroup>
                 </Col>
                 <Col sm="6">
                    <FormGroup>
                      <Label
                        className="control-label required-field"
                        for="metricUnity"
                      >
                        {i18next.t('unit')+': '}
                      </Label>
                      {listAllUnits &&(listAllUnits.length > 0 && (
                           
                        <Input type="select" name="metricUnity" 
                        defaultValue={(selectedMetric.unit)?selectedMetric.unit.id:''}
                         bsSize="sm" id="metricUnity" >
                          
                        {listAllUnits.map((unity) => {
                          unity=JSON.parse(unity); 
                            unity=<option key={unity.id} value={unity.id}>{unity.name}</option>
                        return unity})}
                        </Input>
                        ))}
                      </FormGroup>
                 </Col>
                 </Row>
              <Row>
                <Col sm="6">
              <FormGroup>
              
                    <Label
                      className="control-label required-field"
                      for="metricType"
                    >
                      {i18next.t('type')+': '}
                    </Label>
                    <Input
                      type="select" className="platforms-container"name="metricType" 
                      defaultValue={(selectedMetric.type_indicator=='OPE')?'operacionale':'funcional'}
                      onChange={(e) => onchangeType(e)}>
                       <option key="1" value="operacional">Operacional</option>
                       <option key="2" value="funcional">Funcional</option>
                    </Input>
              </FormGroup>
              </Col>
              <Col sm="6">
              <FormGroup>   
                    <Label
                      className="control-label required-field"
                      for="metricBot"
                    >
                      Bot:
                    </Label>
                    {listAllBotsbyCompany &&(listAllBotsbyCompany.length > 0 && (
                           
                           <Input type="select" name="metricBot" className="platforms-container"
                            bsSize="sm" id="metricBot" disabled>
                             
                           {listAllBotsbyCompany.map((bot) => {
                               bot=<option key={bot.id} value={bot.id}>{bot.name}</option>
                           return bot})}
                           </Input>
                           ))}
                   
              </FormGroup>
              </Col>
              </Row>


                </Col>
                <Col sm="6">
                <FormGroup>
               
                    <Label className="control-label" for="metricDescription">
                    {i18next.t('description')+': '}{" "}
                  </Label>
                  <Input
                    type="textarea"
                    rows={8}
                    name="metricDescription"
                    id="metricDescription"
                    maxLength="100"
                    placeholder={i18next.t('description_metric')}
                    defaultValue={(selectedMetric.description)?selectedMetric.description:''}
                    required
                  />
              
                  </FormGroup>    
                </Col>               
              </Row>
              <hr style={{"border":"1px solid #28a745",size:"10"}}/>
              
              <Row id="measure">          
              <TabNuevaMetrica  
              tooltipFunctions={props.tooltipFunctions}
              functions={props.functions}
              tooltipOperators={props.tooltipOperators}
              operators={props.operators}>

              </TabNuevaMetrica>
              
              </Row>
            
           <Row id="metric" style={{"display":"none"}}>
           <DualListBox
                    id="listSons"
                    options={options}
                    selected={listSelectedMeasures}
                    onChange={onChange}
                    canFilter
                    filterPlaceholder={i18next.t('description_metric') + "..."}
                />
           </Row>
          </ModalBody>
          <ModalFooter>
            <Button onClick={toggleModalNewMetric} outline color="secondary">
            {i18next.t('cancel')}
            </Button>
            <Button type="submit" size="lg" className="btn-guarumo-modal">
              {updateMetric?("Actualizar"):"Crear"}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );

  function onChange (selected) {
    changeBetweenList(selected);
  }
}



function onchangeType(e)
{
  var bots=document.getElementById("metricBot");
  if(e.target.value=='operacional'){
    bots.disabled=true;
    bots.value=""
  }
  else
    bots.disabled=false;
    
}

function onchangeTypeIndi(e)
{
  var measureDiv=document.getElementById("measure");
  var metricDiv=document.getElementById("metric");
  if(e.target.value=='medida'){
    measureDiv.style.display="flex";
    metricDiv.style.display="none";
  }
  else{
    measureDiv.style.display="none";
    metricDiv.style.display="flex";
  }
  
    
}

function onclick(e,type)
{
  // var formula=document.getElementById("metricFormula");
  // var condition=document.getElementById("metricCondition");
  // var dimension=document.getElementById("metricDimension");

  var text="";
  //var text="<span style='color: blue;'>["+e.target.value+"]</span>";
  if(type=="field")
  text+="["+e.target.value+"]";
else if(type=="operator")
  text+=e.target.value;
else if(type=="function")
  text+=e.target.value+"()";

  if(textareaFocus=="metricDimension"&&(type=="operator"||type=="function")){}
  else
    insertAtCaret(textareaFocus, text);
  
  
}

function insertAtCaret(areaId, text) {
  var txtarea = document.getElementById(areaId);
  var scrollPos = txtarea.scrollTop;
  var caretPos = txtarea.selectionStart;

  var front = (txtarea.value).substring(0, caretPos);
  var back = (txtarea.value).substring(txtarea.selectionEnd, txtarea.value.length);
  txtarea.value = front + text + back;
  caretPos = caretPos + text.length;
  txtarea.selectionStart = caretPos;
  txtarea.selectionEnd = caretPos;
  txtarea.focus();
  txtarea.scrollTop = scrollPos;
}

function onFocus(e,textarea)
{
  textareaFocus=textarea;

  var operadores=document.getElementById("metricOperators");
  var funciones=document.getElementById("metricFunction");

  if(textareaFocus=="metricDimension")
  {
    
    operadores.disabled=true;
    funciones.disabled=true;
  }
  else
  {
    operadores.disabled=false;
    funciones.disabled=false;
  }

}

function onTry(e)
{

}

function createListSelect(props)
{

  /**
   * Select Operators
   */
  if(operators.length==0){
  if(props.operators){
    if (props.operators.length > 0) {
      for (var i = 0; i < props.operators.length; i++) {
        let aux = props.operators[i];
        if(aux.id != 1){
          operators.push(
            <option key={i} value={aux.name}>
              {aux.name}
            </option>
          );
        }
      }
    }
  }
}

  /**
   * Select Functions
   */
  if(functions.length==0){
  if(props.functions){
    if (props.functions.length > 0) {
      for (var i = 0; i < props.functions.length; i++) {
        let aux = props.functions[i];
        if(aux.id != 1){
          functions.push(
            <option key={i} value={aux.id}>
              {aux.name}
            </option>
          );
        }
      }
    }
  }
}

}

const mapStateToProps = ({ analyticsReducer, homeReducer }) => {
	return { analyticsReducer, homeReducer };
};

const mapDispatchToProps = {
  saveNewMetric,
  updateInfoMetric,
  toggleModalNewMetric,
  changeBetweenList
 
};


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ModalNuevaMetrica));
