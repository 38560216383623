import React, { Component } from 'react';
import {  
    FormGroup, 
    Input, 
    Button,
    Col,
    Row,
    Label,
    UncontrolledTooltip
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, } from '@fortawesome/free-solid-svg-icons';

import i18next from "../../i18n";


class ExtensionsFilesType extends Component {

    state = {
        extensions: [],
        typingExt:''
    }

    constructor(props){
        super(props);

    }

    bindValue = () => {
        if(typeof this.props.bindValue === "function")
            this.props.bindValue(this.state.extensions);
      }

    updateTitle = (event) => {
        this.setState({typingExt: event.target.value} );
    }

    handleSaveExtension=()=>{
        if(this.state.typingExt=='')
            return 
        this.state.extensions.push(this.state.typingExt);
        document.getElementById('inputExt').value = "";
        this.bindValue();
        this.setState({typingExt:''});
    }

    getDataExtension=()=>{
            let i=0;
        return this.state.extensions.map(element => {                          
            i++; 
            return  <div style={{width:'fit-content'}} key={i} >
                        <span key={i} className="text-success">
                            {element}                                                                                                                              
                            <Button key={i} close 
                            onClick={() => {this.props.handleDeleteExtension(element)} } />                                                                                         
                       </span>
                    </div>                           
        })
    }

    componentDidMount = () => {
     this.setState({ extensions: this.props.extensions || []});
    }

    render() {
        let getDataExtension = this.getDataExtension();
        return (
            <Row>
                <Col sm="12">
                    <FormGroup>
                        <Label>{i18next.t('extension_archivos_label')} <i className="fa fa-info-circle" id="tooltipExtensions"></i></Label>
                        <UncontrolledTooltip placement="right" target="tooltipExtensions">{i18next.t('extension_archivos_icon')} </UncontrolledTooltip>
                        <Input 
                         id="inputExt"
                         type="text"
                         name="inputExtensions" 
                         onChange={(event) => { this.updateTitle(event) }} 
                         defaultValue={this.state.typingExt} maxLength={'50'}/>
                            <Button outline color="success" onClick={ (e) => this.handleSaveExtension()}  >
                                <FontAwesomeIcon icon={faSave} />
                            </Button> 
                            {"   "}
                         {getDataExtension }
                   </FormGroup>
                </Col>
            </Row>
        )
    }
}

export default ExtensionsFilesType