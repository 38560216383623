import React, { Component } from 'react';
import DataBaseLayout from './database-layout';
import ModalLoad from '../../components/modals/Loading/moda-loading';
import axios from 'axios';
import qs from 'qs';
import { connect } from 'react-redux';
import { showDatabaseCollect } from '../../redux/actions/databaseAction';
import {
  getAllListCompanies,
  setSelectedCompany,
  iconLoad,
  iconHideLoad,
} from "../../redux/actions/analyticsActions";

import { 
  isAuthorizationValid,
  getRolUser,
  isEmpty } from '../../utils/commonFunction';

import { APPLICANT } from "../../redux/listComponents";

import {
  isDidMount,
  willUnMount,
} from "../../redux/actions/componentMountActions";

class DataBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAdmin: false,
      isSuperUser: false,
      hideLoad: false,
      user: "",
      rol:'',
    };
  }
  componentWillMount() {
    isAuthorizationValid();
    const { isAdmin = false, isSuper = false,rol = '' } = getRolUser();
    this.setState({
      isAdmin: isAdmin,
      isSuperUser: isSuper,
      rol:rol
    });
  }
  
  async componentDidMount() {
    const {
      homeReducer: { listAllCompanies },
    } = this.props;

    this.props.isDidMount(APPLICANT.HOME());

    //----------------------------------------------------------------
    //  PONE EL ICONO DE CARGA
    //----------------------------------------------------------------
    await this.props.iconLoad();

    //----------------------------------------------------------------
    //  LISTA TODAS LAS COMPAÑÍAS, COJE LA PRIMERA COMO SELECTCOMPANY
    //----------------------------------------------------------------

    if (isEmpty(listAllCompanies)) {
      await this.props.getAllListCompanies();
    }
    
  }

  shouldComponentUpdate(nextProps, nextState) {
    const nextCompany = nextProps.analyticsReducer.selectedCompany;
    const company = this.props.analyticsReducer.selectedCompany;


    if (nextCompany !== company) return true;
    else return false;
  }

  async componentWillUpdate(nextProps, nextState) {
    const nextCompany = nextProps.analyticsReducer.selectedCompany;
    const company = this.props.analyticsReducer.selectedCompany;


    if (nextCompany.lenght == 0 ) {
      //console.log("No Update!!");
    } else if (nextCompany != company) {
      /**
       * Ingresa cuando se cambia la compañía seleccionada
       */

      //----------------------------------------------------
      //  MUESTRA EL ICONO DE CARGA
      //----------------------------------------------------
      await this.props.iconLoad();

      //----------------------------------------------------
      //  Lista los bots de la compañía seleccionada
      //----------------------------------------------------
      await this.props.showDatabaseCollect(this.props.analyticsReducer.selectedCompany);
      
      // /**
      //  * Calcula el reporte de iteraciones sin cubejs
      //  */
      // await this.props.getCustomReport();

      //----------------------------------------------------
      //  OCULTA EL ICONO DE CARGA
      //----------------------------------------------------
      await this.props.iconHideLoad();
    } 
    else
    {
      
    }
  }

  componentWillUnmount() {
    this.props.willUnMount(APPLICANT.HOME());
  }


  showContent=()=>{
        return (
            <div>
              <DataBaseLayout
                  isAdmin={this.state.isAdmin}
                  isSuperUser={this.state.isSuperUser}
                  rol={this.state.rol}
                  />
                <ModalLoad/>
            </div>
          );
  }

  render() {
    return this.showContent();
  }
}

const mapStateToProps = ({analyticsReducer, databaseReducer,componentMountActions,homeReducer}) => 
	({analyticsReducer, databaseReducer,componentMountActions,homeReducer  });

const mapDispatchToProps = {
  getAllListCompanies,
  setSelectedCompany,
  iconLoad,
  iconHideLoad, 
  showDatabaseCollect,
  isDidMount,
  willUnMount,
}

export default connect(mapStateToProps, mapDispatchToProps)(DataBase);
