import React, { Component } from 'react';
import { connect } from "react-redux";
  
import ModalLoading from '../../../components/modals/Loading/moda-loading';

import { WidthProvider, Responsive } from "react-grid-layout";
import _ from "lodash";
const ResponsiveReactGridLayout = WidthProvider(Responsive);

const originalLayout = getFromLS("layout") || [];

import { withRouter } from "react-router-dom";

import FilterBar from '../../analytics/infoViz/common/filterBar'
// import FilterBar from '../../components/infoViz/chart-react-d3/d3Components/FilterBar'

import i18next from '../../../../i18n'

import { 
  Col,
  Row,
  FormGroup,
  Input,
  UncontrolledTooltip
  

} from 'reactstrap';

import { MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';



import { iconHideLoad,
  getTockenCubejs,
  getAllPalettes,
  getAllCharts,getPreview, cleanPreview, cleanAllPreviews, getItems, getAllDashboards,getDashboard} from "../../../redux/actions/analyticsActions";


import { isAuthorizationValid,getRolUser,isEmpty } from "../../../utils/commonFunction";

  
import { APPLICANT } from "../../../redux/listComponents";
import { isDidMount, willUnMount } from "../../../redux/actions/componentMountActions";
import { Empty } from 'antd';
/**
 * This layout demonstrates how to use a grid with a dynamic number of elements.
 */
class AddRemoveLayout extends Component {//extends React.PureComponent {
  static defaultProps = {
    className: "layout",
    isDraggable: false,
    isResizable: false,
  //  items: 12,
    cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
    rowHeight: 30,
  };



  constructor(props) {
    super(props);

    this.state = {
      isAdmin: false,
      isSuperUser: false,
      hideLoad: false,
      user:'',
      items: props.analyticsReducer.items,
      newCounter: 0,
      listChart:[],
      charts:[],
  
    };


    this.onAddItem = this.onAddItem.bind(this);
    this.onBreakpointChange = this.onBreakpointChange.bind(this);
    this.onLayoutChange = this.onLayoutChange.bind(this);
  }

  callBackPieChart=(group,color)=>{
    this.setState({group:group,color:color,botID:'All'})
  }

  callBackLineChart=(group,botID/*,dateini,datefin*/)=>{
     this.setState({botID:botID/*,dateini:dateini,datefin:datefin*/})
   }


  componentWillMount(){
    isAuthorizationValid()
    const { isAdmin = false, isSuper = false } = getRolUser();
    this.setState({
        isAdmin: isAdmin,
        isSuperUser: isSuper
    });
  
  }


  async componentDidMount(){
 
    const { activePage,listAllDashboards,selectedDashboard,AllPalettes, items,previews, allPreviews} = this.props.analyticsReducer;
    
    
    await this.props.getTockenCubejs();

  //--------------------------------------------------------------------------------------------
  //  LISTA TODOS LOS DASHBOARD - COJE EL PRIMERO CON SELECTEDDASHBOARD y LIMPIA LOS PREVIEWS
  //--------------------------------------------------------------------------------------------

 if(isEmpty(listAllDashboards)){
  await this.props.getAllDashboards(activePage); 
 }

 const { listAllDashboards:AllDashboard} = this.props.analyticsReducer;

 //--------------------------------------------------------------------------------------------
 //  SELECCIONA 1 DASHBOARD - COJE EL PRIMERO CON SELECTEDDASHBOARD
 //--------------------------------------------------------------------------------------------

 if(isEmpty(selectedDashboard))
 {
  
   if(AllDashboard.length>0)
   {
     var firstDashboard=[]
     firstDashboard = JSON.parse(AllDashboard[0]);
     await this.props.getDashboard(activePage,firstDashboard.id);  
      //--------------------------------------------------------------------------------------------
      //  CREA EL DASHBOARD
      //--------------------------------------------------------------------------------------------

     await this.drawDashboard();
   }
 }
 else
  await this.drawDashboard();

  await this.props.iconHideLoad();
  
 
}

      shouldComponentUpdate(nextProps,nextState) {
    //    // Permitimos renders sólo si los datos
    // //   // han cambiado. Así alcanzaremos el siguiente
    // //   // punto del ciclo de vida.
    // //   return Boolean(prevProps.analyticsReducer !== this.props.analyticsReducer);
         const {allPreviews,viewDashboard, selectedDashboard, selectedCompany} = this.props.analyticsReducer;
         const nextAllPreviews = nextProps.analyticsReducer.allPreviews;

         const nextItems = nextState.items;
         const items = this.state.items;
         const nextSelectedCompany = nextProps.analyticsReducer.selectedCompany;


         if(nextSelectedCompany!=selectedCompany)
          return false;

         
        
         if(allPreviews && (allPreviews.length>0)&&items.length>0)
          {
            if(this.props.analyticsReducer.AllPalettes.length>0)
            {
              return true;
            }
            else
              return false;
          } 
          else{
              return false;
          }
            


         if(nextItems!=items&&items.length>0)
          return true;
         else{
            if(allPreviews &&(allPreviews.length>0))
              return true;
            else 
              return false;
         }
          return false;
      //   else if(this.state.charts.length!=0 && viewDashboard==false)
      //    return false;
        
    //     if((prevProps.analyticsReducer.allPreviews == allPreviews)||(this.state.charts.length==0 && viewDashboard==true ) ){
    //       return false;
    //     }else{
    //       //console.log("Diferentes componentWillUpdate",prevProps.analyticsReducer.allPreviews,allPreviews);
    //       return true;
    //     }

        
      }
  
async  componentWillUpdate(nextProps,nextState)
 {
   
  const {allPreviews,viewDashboard} = this.props.analyticsReducer;
  const nextAllPreviews = nextProps.analyticsReducer.allPreviews;
  const nextItems = nextState.items;
  const items = this.state.items;
  // const nextlistCharts = nextState.listChart;
  // const listCharts = this.state.listChart;
  const nextCharts = nextState.charts;
  const charts = this.state.charts;

    //--------------------------------------------------------------------------------------------
    //  CREA EL DASHBOARD
    //--------------------------------------------------------------------------------------------
    
    //console.log("componentWillUpdate",nextAllPreviews,allPreviews)
    //console.log("componentWillUpdateItems",items,nextItems);
    //console.log("componentWillUpdatelistCharts",listCharts,nextlistCharts);
    //console.log("componentWillUpdateCharts",charts,nextCharts);
    
      if(nextAllPreviews == allPreviews ){
      //  console.log("Iguales componentWillUpdate",nextAllPreviews,allPreviews);
      }else{
        
        await this.drawDashboard();
        await this.props.iconHideLoad();
      }
    
      if(this.state.charts.length==0 && viewDashboard==true ){
        await this.drawDashboard();
        await this.props.iconHideLoad();
      }else{
       // console.log("Diferentes componentWillUpdate viewDashboard",nextAllPreviews,allPreviews);
       // await this.drawDashboard();
      }
      
  
  }

  

  componentWillUnmount() {
    this.props.willUnMount(APPLICANT.HOME());
  }

async drawDashboard()
{
  const { selectedDashboard:selectedDashboardNew, viewDashboard, listAllCharts} = this.props.analyticsReducer;

 let itemsNew=[]
 
 //--------------------------------------------------------------------------------------------
 //  CREA EL GRID DEL DASHBOARD SELECCIONADO. Si es viewdashboard el tamaño es más pequeño 
 //--------------------------------------------------------------------------------------------
  if(viewDashboard==true)
 {
        if(selectedDashboardNew.charts){  
          itemsNew=selectedDashboardNew.charts.map(function(chart, key, list) {
        
          return {
            i: chart.id.toString(),
            x: chart.x/2,
            y: chart.y,
            w: chart.width/2,
            h: chart.heigth/3,
            add: chart.id === (list.length - 1).toString(),
            metric:chart.metric
          };
        })
      }
 }
 else {
   if(selectedDashboardNew.charts){  
    
     itemsNew=selectedDashboardNew.charts.map(function(chart, key, list) {
     return {
       i: chart.id.toString(),
       x: chart.x,
       y: chart.y,
       w: chart.width,
       h: chart.heigth/3,
       add: chart.id === (list.length - 1).toString(),
       metric:chart.metric
     };
   })
 }
 }

await getItems(itemsNew);
 

//--------------------------------------------------------------------------------------------
//  CREA EL ARRAY DE CHARTS DEL DASHBOARD SELECCIONADO. 
//--------------------------------------------------------------------------------------------
    if(selectedDashboardNew.charts && isEmpty(listChart) ){
     var listChart=[], defaultValue=[];
      listAllCharts.map((chart, index) => {    
          chart = JSON.parse(chart);  
          var data = 
                {
                  value: ""+chart.id,
                  label: chart.metric.name,
                  typechart:chart.type_chart.name,
                  type_chartid:chart.type_chart.id,
                  color:chart.color.id,
                  palette_type:chart.color.palette_type
                }
          listChart.push(data)
          
          if(selectedDashboardNew.charts){
          for(var j=0;j<selectedDashboardNew.charts.length;j++)
          {
            if(chart.id==selectedDashboardNew.charts[j].chart){
                defaultValue.push(listChart[index])
                break;
            }
          }
        }
        return listChart;
      });


    this.setState({
        listChart: listChart,
        charts:selectedDashboardNew.charts,
         items:itemsNew
    });
      
    //--------------------------------------------------------------------------------------------
    //  CREA CADA GRÁFICO
    //--------------------------------------------------------------------------------------------
    if(selectedDashboardNew.charts){  
      //console.log("drawDasboard");
      for(var j=0;j<selectedDashboardNew.charts.length;j++)
      {
        var chart=selectedDashboardNew.charts[j];
        await this.props.getPreview(chart.type_chart.id,chart.color.id, chart.color.palette_type,"chart"+chart.id,chart.metric,chart.icon);
      }
    }
   
  } 

}
  createElement(el) {
    const removeStyle = {
      position: "absolute",
      right: "2px",
      top: 0,
      cursor: "pointer"
    };

    const configStyle = {
        position: "absolute",
        right: "15px",
        top: 0,
        cursor: "pointer"
      };
    const i = el.add ? "+" : el.i;
    if(el.i=="aa"||el.i=="a-a"||el.i=="aaa")
    return"";
    else{
    return (
      <div key={i} data-grid={el} id={"content-chart"+i} i={i} style={{background:"#f5f8fa", border:"none"}}>
        {el.add ? (
          <span
            className="add text"
            onClick={this.onAddItem}
            title="You can add an item by clicking here, too."
          >
            Add +
          </span>
        ) : (
          <React.Fragment>
          
          <Col id={"chart"+i} i={i} x={el.x} y={el.y} w={el.w} h={el.h}  style={{width:"inherit", height:"inherit",overflow: "scroll"}}>
            {/* style={{width:100+"%", height:100+"%",overflow: "scroll"}}> */}
              <React.Fragment>
              {this.props.analyticsReducer.allPreviews &&(this.props.analyticsReducer.allPreviews.length > 0 && (
                    this.onFindChart(i)
                         ))} 
              </React.Fragment>
  
          </Col>                                  
           <span  className="remove"  style={removeStyle} id={"span_chart"+i}>
            <i className='fa fa-info-circle' id={'tooltipDimensions'+i}></i> 
            <UncontrolledTooltip placement="right" target={'tooltipDimensions'+i}>  
                {this.props.analyticsReducer.allPreviews &&(this.props.analyticsReducer.allPreviews.length > 0 && (
                    (this.onFindMetric(i))?
                    (
                      
                      <React.Fragment key={this.onFindMetric(i).id}>
                       <span style={{color:'white', fontSize: '1.5em', fontWeight:'bold'}}>{""+this.onFindMetric(i).short_name}</span>
                     
                      <MDBTable responsive>
                      
                      <MDBTableBody>
                      <tr>
                        <td style={{color:'white'}}>{i18next.t('name')}</td>
                        <td style={{color:'white'}}>{""+this.onFindMetric(i).name}</td>
                        
                      </tr>
                      <tr>
                        <td style={{color:'white'}}>{i18next.t('description')}</td>
                        <td style={{color:'white'}}>{""+this.onFindMetric(i).description}</td>
                      </tr>
                      <tr>
                        <td style={{color:'white'}}> {i18next.t('unit')}</td>
                        <td style={{color:'white'}}>{""+this.onFindMetric(i).unit.name}</td>
                        
                      </tr>
                    </MDBTableBody>
                    </MDBTable>
                    </React.Fragment>
                     ):(
                     <div>"Tooltip i: "+{i} +"metric_id: "+{this.onFindMetric(i)}</div>
                     )
                  ))} 
              </UncontrolledTooltip>
         </span>
         <div id={"tooltip_chart"+i} className= "tooltip" style={{opacity:"0", backgroundColor:"white", border:"2px", borderStyle:"solid", borderRadius:"5px", padding:"5px", zIndex:"5000"}}>

         </div>
         </React.Fragment>
            )
           
        // <span
        //   className="remove"
        //   style={removeStyle}
        //   onClick={this.onRemoveItem.bind(this, i)}
        // >
        //   ...
        // </span> 
      }


        {/* <span
          className="config"
          style={configStyle}
          onClick={this.onRemoveItem.bind(this, i)}
        >
         <i className='fa fa-arrow-alt-circle-down' id={'tooltipDimensions_menu'}></i> 
            <UncontrolledTooltip placement="right" target={'tooltipDimensions_menu'+i}>  

            </UncontrolledTooltip>
        </span> */}
      </div>
    );
      }
  }

  onAddItem(actionMeta) {
    /*eslint no-console: 0*/

    this.setState({
      // Add a new item. It must have a unique key!
      items: this.state.items.concat({
        i: actionMeta.option.value.toString(),//"n" + this.state.newCounter,
        x: (this.state.items.length * 2) % (this.state.cols || 12),
        y: 0,//Infinity, // puts it at the bottom
        w: 2,
        h: 2
      }),
      // Increment the counter to ensure key is always unique.
      newCounter: this.state.newCounter + 1
    });
    const { getPreview } = this.props;

    //OJO ENVIAR METRICA!!!!
    getPreview(actionMeta.option.type_chartid,actionMeta.option.color, actionMeta.option.palette_type,"chart"+actionMeta.option.value,null,null);

  }

  onFindChart(i)
  {
    var chart = this.props.analyticsReducer.allPreviews.find((preview)=>{   
     
      if(preview.nameDiv==="chart"+i ) {
       return(
         preview.chart
         )
      }   
      })

      if(chart) 
        return chart.chart
      else
        return ""
  }

  onFindMetric(i)
  {
   
    var chart = this.props.analyticsReducer.selectedDashboard.charts.find((preview)=>{   
     
      if(""+preview.id==i ) {
       return(
         preview.metric
         )
      }  
      else if(preview.id==i ) {
        return(
          preview.metric
          )
       } 

      })
      if(chart) 
        return chart.metric
      else
        return ""
  }

  
  // We're using the cols coming back from this to calculate where to add new items.
  onBreakpointChange(breakpoint, cols) {
    this.setState({
      breakpoint: breakpoint,
      cols: cols
    });
  }

  onLayoutChange(layout) {
   // console.log("ONLAYOUT", layout);
    this.setState({ items: layout });
  }

  onRemoveItem(i) {
    this.setState({ items: _.reject(this.state.items, { i: i }) });
  }

  onClick(newValue,actionMeta)
  {
    if(actionMeta.action=="select-option")
      this.onAddItem(actionMeta)
    else if(actionMeta.action=="remove-value")
      this.onRemoveItem(actionMeta.removedValue.value.toString())
  
  }

  showContent=()=>{
    const {  isLogin } = getRolUser();
    const {viewDashboard} =this.props.analyticsReducer
    if(isLogin){
      return (
        <React.Fragment>
           { viewDashboard?(<div></div>):(
          <FilterBar>
          
          </FilterBar>
           )}
        <ResponsiveReactGridLayout
          className={'charts_grid'}
          onLayoutChange={this.onLayoutChange}
          onBreakpointChange={this.onBreakpointChange}
          {...this.props}
        >
          {_.map(/*this.props.analyticsReducer.items*/this.state.items, el => this.createElement(el))}
            
        </ResponsiveReactGridLayout>
      </React.Fragment>
     );
    }
    return <ModalLoading />
  }


    render() {
      return this.showContent();
  }
}


const mapStateToProps = ({ analyticsReducer, componentMountReducer }) => {
	return { analyticsReducer, componentMountReducer };
};

const mapDispatchToProps = {
  getAllCharts,
  getAllPalettes,
  isDidMount,
  willUnMount,
  getPreview,
  cleanPreview,
  cleanAllPreviews,
  getItems,
  getAllDashboards,
  getDashboard,
  getTockenCubejs,
  iconHideLoad
  
};
export default connect(mapStateToProps, mapDispatchToProps)(AddRemoveLayout);

function getFromLS(key) {
    let ls = {};
    if (global.localStorage) {
      try {
        ls = JSON.parse(global.localStorage.getItem("rgl-7")) || {};
      } catch (e) {
        /*Ignore*/
      }
    }
    return ls[key];
  }
  
  function saveToLS(key, value) {
    if (global.localStorage) {
      global.localStorage.setItem(
        "rgl-7",
        JSON.stringify({
          [key]: value
        })
      );
    }
  }

//module.exports = AddRemoveLayout;

// if (require.main === module) {
//   require("../test-hook.jsx")(module.exports);
// }

