import { Redirect } from "react-router-dom";
import { HOME_ACTIONS_TYPES } from '../types/homeActionsTypes';
import { MODAL_LOAD_ACTIONS_TYPES } from "../types/loadStateActionsTypes";
import { logOut } from "../actions/loginActions";
import { getHandlerGET,getHandlerPOST,getHandlerDELETE } from "../../utils/handlersApi";
import { cerrarSesion } from "../../utils/commonFunction";
import i18next from "../../../i18n";

const checkStatusComponent = ( components = [ { name: '' } ], nameComponent ) => {   
    let component = components.filter(( item ) => item.name == nameComponent )
    return ( (component.length == 0) ? false : true ); 
}

export const getTotalBots = ( ) => async( dispatch, getState ) => {    
    // get stateAnotherReducer
    // const { components } = getState().componentMountReducer;  
    const { searcher = '' } = getState().homeReducer;
    const { authorization: { access_token } = {access_token} } = getState().loginReducer;

    const parameters = (searcher) ? `&s=${searcher}`:'';
    const url = HOST_API +'/api/bot/total_bots/?' + parameters;

    // Getting total bots
    await getHandlerGET( url , access_token)
        .then((res)=>{
            if(res.status==200 && res.data.total_bots){
                //if (checkStatusComponent(components , nameComponent)){
                    dispatch({
                        type: HOME_ACTIONS_TYPES.successGetTotalBots(),
                        payload: res.data.total_bots
                    }); 
                //}
            }else{
                dispatch({
                    type: MODAL_LOAD_ACTIONS_TYPES.showWarning(), 
                    payload: i18next.t('home_actions_msg')
                });
            }
        })
        .catch((error) => {
            dispatch({
                type: MODAL_LOAD_ACTIONS_TYPES.showWarning(), 
                payload: i18next.t('home_actions_msg2')
            });
            const { response: { status } = { status } } = error; 
            if (status  && status == 403){
                logOut();
                cerrarSesion();
            }
        }
    );
    
}

export const getAllbots = ( page ) => async (dispatch, getState) => {
    const { searcher } = getState().homeReducer;
    console.log("searcher ",searcher);
    //const { components } = getState().componentMountReducer;  
    const { authorization: { access_token } = {access_token} } = getState().loginReducer;
    let params = "&p="+page;    
    if(searcher){
        params = params + "&s=" + searcher;        
        dispatch({
            type: HOME_ACTIONS_TYPES.handleClearSearcher(), 
        }); 
    }
    const url =`${HOST_API}/api/bot/?${params}`;
    
    // Loading getting list bots         
    dispatch({
        type: MODAL_LOAD_ACTIONS_TYPES.loading(),
    });
    await getHandlerGET( url, access_token )
    .then((res)=>{        
        if(res.status==200){            
         // if (checkStatusComponent(components , nameComponent)){
            if(res.data.msg === 'No objects founded'){
                dispatch({
                    type: HOME_ACTIONS_TYPES.notFoundGetListBots(), 
                    payload: i18next.t('home_actions_msg3')
                }); 
            }            
            if(res.data.bot){
                let listBot = res.data.bot.map(e =>JSON.stringify(e))
                dispatch({
                    type: HOME_ACTIONS_TYPES.successGetListBots(), 
                    payload: listBot
                });                
            }
            dispatch({
                type: MODAL_LOAD_ACTIONS_TYPES.hideLoad(), 
            });  

          //}
        }
    })
    .catch((error) => {
        dispatch({
            type: HOME_ACTIONS_TYPES.errorGetListBots(), 
            payload: i18next.t('message160')
        });
        dispatch({
            type: MODAL_LOAD_ACTIONS_TYPES.hideLoad(), 
        });
        const { response: { status } = { status } } = error; 
        if (status  && status == 403){
            logOut();
            cerrarSesion();
        }
    });

    const { error } = getState().homeReducer;
    if (error){
        dispatch({
            type: MODAL_LOAD_ACTIONS_TYPES.showWarning(), 
            payload: error,
        });
    }
      
}

export const getAllPlafform = () => async(dispatch, getState) => {
    
    const url = HOST_API +'/api/platforms/';
    dispatch({
        type: HOME_ACTIONS_TYPES.requestGetListPlafforms()
    });
       
    await getHandlerGET(url)
    .then((res)=>{
        if(res.status==200){
            if(res.data.platforms){
                let platforms = res.data.platforms.map(e =>JSON.stringify(e))
                dispatch({
                    type: HOME_ACTIONS_TYPES.successGetListPlafforms(),
                    payload: platforms
                });          
            }
        }else{
            dispatch({
                type: HOME_ACTIONS_TYPES.errorGetListPlafforms(),
                payload: i18next.t('home_actions_msg4')
            });   
        } 
    })
    .catch((error) => {
        dispatch({
            type: HOME_ACTIONS_TYPES.errorGetListPlafforms(),
            payload: i18next.t('home_actions_msg5')
        });      
        if (error.response.status == 403){
            logOut();
            cerrarSesion();
        }
        dispatch({
            type: MODAL_LOAD_ACTIONS_TYPES.hideLoad(), 
        }); 
    }); 

    const { error } = getState().homeReducer;
    if (error){
        dispatch({
            type: MODAL_LOAD_ACTIONS_TYPES.showWarning(), 
            payload: error,
        });
    }

}

export const getAllCompanies = () => async(dispatch, getState) => {
    
    const url = HOST_API +'/api/company/';
    dispatch({
        type: HOME_ACTIONS_TYPES.requestGetListCompanies()
    });
       
    await getHandlerGET(url)
    .then((res)=>{
        if(res.status==200){
            if(res.data.companies){
                let companies = res.data.companies.map(e =>JSON.stringify(e))
                dispatch({
                    type: HOME_ACTIONS_TYPES.successGetListCompanies(),
                    payload: companies
                });          
            }
        }else{
            dispatch({
                type: HOME_ACTIONS_TYPES.errorGetListCompanies(),
                payload:i18next.t('message55')
            });   
        } 
    })
    .catch((error) => {
        dispatch({
            type: HOME_ACTIONS_TYPES.errorGetListPlafforms(),
            payload:i18next.t('statements_list_msg3')
        });      
        if (error.response.status == 403){
            logOut();
            cerrarSesion();
        }
        dispatch({
            type: MODAL_LOAD_ACTIONS_TYPES.hideLoad(), 
        }); 
    }); 

    const { error } = getState().homeReducer;
    if (error){
        dispatch({
            type: MODAL_LOAD_ACTIONS_TYPES.showWarning(), 
            payload: error,
        });
    }
}


export const saveNewBot = (event, RedirectonSuccess) => async (dispatch,getState) => {
    event.preventDefault();
    dispatch({
        type: MODAL_LOAD_ACTIONS_TYPES.loading()
    });
    const url= `${HOST_API}/api/bot/admin_bot`;
    const form = new FormData(event.target);
    const data = {
        name: form.get("botName"),
        language_id: 1,
        description: form.get("botDescription"),
        url: form.get("botClientUrl"),
        platform_id: form.get("botPlatform"),
        word_to_return:'return',
        word_out_queue:'leave',
        file_encripted:true,
        button_title:{
            'title1': i18next.t('follow_asking'),
            'title2': i18next.t('return')
        },
    }

    await getHandlerPOST(url, data)
        .then((res)=>{
            if(res.status==200){
                if (res.data.bot.length > 0){
                    dispatch({
                        type: MODAL_LOAD_ACTIONS_TYPES.hideLoad(),
                    });
                    dispatch({
                        type: MODAL_LOAD_ACTIONS_TYPES.showSuccess(),
                        payload: i18next.t('home_actions_msg6')
                    });
                    setTimeout(()=>{
                        dispatch({
                            type: HOME_ACTIONS_TYPES.toggleModalNewBot()
                        });
                        dispatch({
                            type: MODAL_LOAD_ACTIONS_TYPES.hideLoad(),
                        });
                        RedirectonSuccess(`/schemas/${res.data.bot[0].id}`);
                    },1200);                    
                }
            }
          
        })
        .catch((error) => {
            dispatch({
                type: MODAL_LOAD_ACTIONS_TYPES.showWarning(),
                payload: i18next.t('home_actions_msg7')
            });
            const { response: { status } = { status } } = error; 
            if (status  && status == 403){
                logOut();
                cerrarSesion();
            }
        });    
}

export const deleteBot = (id) => async (dispatch, getState) =>{
  
    if (confirm(i18next.t('home_actions_msg8'))) {
        dispatch({
            type: MODAL_LOAD_ACTIONS_TYPES.loading()
        });

        var data = { bot_id: id };
        await getHandlerDELETE(HOST_API +'/api/bot/admin_bot',data)
            .then((res)=>{
                if(res.status==200){                  
                    const { activePage } = getState().homeReducer;
                    dispatch(getAllbots( activePage ));
                    dispatch(getTotalBots());                                      
                }
                dispatch({
                    type: MODAL_LOAD_ACTIONS_TYPES.hideLoad()
                })
            }).catch((error) => {
                dispatch({
                    type: MODAL_LOAD_ACTIONS_TYPES.hideLoad()
                })

                dispatch({
                    type: HOME_ACTIONS_TYPES.errorDeleteBot(),
                    payload: i18next.t('home_actions_msg9')
                })

                if (error.response.status == 403){
                        logOut();
                        cerrarSesion();
                }
            });
        }

        const {error} = getState().homeReducer;
        if(error){
            dispatch({
                type: MODAL_LOAD_ACTIONS_TYPES.showWarning(),
                payload: error
            });
        }

}

export const changePageActive = (pageActive) => (dispatch) => {
    dispatch({
        type: HOME_ACTIONS_TYPES.changePageActive(), 
        payload: pageActive
    });
}

export const handleSearching = (query) => (dispatch) => {
    dispatch({
        type: HOME_ACTIONS_TYPES.handleSearching(),
        payload: query.target.value
    }); 
}

export const cleanSearching = () => (dispatch) => {
    dispatch({
        type: HOME_ACTIONS_TYPES.handleSearching(),
        payload: ''
    }); 
}

export const toggleModalNewBot = () => (dispatch) =>{
    dispatch({
        type: HOME_ACTIONS_TYPES.toggleModalNewBot()
    });
}

