var d3 = require('d3');
const data = {}
var parseDate = d3.timeParse("%Y");

data.datasetLineChart = [
    {
      name: "Bot 1",
      values: [
          { group: "Whatsapp", dateini: parseDate(2008), datefin: 2008, measure: 25}, 
          { group: "Whatsapp", dateini: parseDate(2009), datefin: 2008, measure: 10 }, 
          { group: "Whatsapp", dateini: parseDate(2010), datefin: 2008, measure: 20 }, 
          { group: "Whatsapp", dateini: parseDate(2011), datefin: 2008, measure: 30 }, 
          { group: "Whatsapp", dateini: parseDate(2012), datefin: 2008, measure: 40 }
      ]
    },
    {
      name: "Bot 2",
      values: [
          { group: "Web", dateini: parseDate(2008), datefin: 2008, measure: 10 }, 
          { group: "Web", dateini: parseDate(2009), datefin: 2008, measure: 10 }, 
          { group: "Web", dateini: parseDate(2010), datefin: 2008, measure: 23 }, 
          { group: "Web", dateini: parseDate(2011), datefin: 2008, measure: 6 }, 
          { group: "Web", dateini: parseDate(2012), datefin: 2008, measure: 15 }
      ]
    },
    {
      name: "Bot 3",
      values: [
          { group: "Facebook", dateini: parseDate(2008), datefin: 2008, measure: 18}, 
          { group: "Facebook", dateini: parseDate(2009), datefin: 2008, measure: 13 }, 
          { group: "Facebook", dateini: parseDate(2010), datefin: 2008, measure: 21 }, 
          { group: "Facebook", dateini: parseDate(2011), datefin: 2008, measure: 12 }, 
          { group: "Facebook", dateini: parseDate(2012), datefin: 2008, measure: 14 }
      ]
    },
    {
      name: "Bot 4",
      values: [
          { group: "Facebook", dateini: parseDate(2008), datefin: 2008, measure: 10}, 
          { group: "Facebook", dateini: parseDate(2009), datefin: 2008, measure: 23 }, 
          { group: "Facebook", dateini: parseDate(2010), datefin: 2008, measure: 12 }, 
          { group: "Facebook", dateini: parseDate(2011), datefin: 2008, measure: 19 }, 
          { group: "Facebook", dateini: parseDate(2012), datefin: 2008, measure: 34 }
      ]
    }
  ];
    

export default data;