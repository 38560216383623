import React, { Component } from 'react';
import CRMLayout from './custom-report-layout';
import SMPortModel from '../simplePortModel/SMPortModel';

import { getHandlerPOST, getHandlerPUT, getHandlerDELETE } from '../../../../utils/handlersApi'
import { cerrarSesion } from '../../../../utils/commonFunction'

class CRModal extends Component {
    
    state = {
        text_blocks: {'bl_0': ""},
        nodeId: "",
        keyword: "", 
        title: "",
        selection:  null,
        range: null,
        keyword_valid: true,
        timeout_valid: true,
        error_limit_item:false,
        audio:'',
        isOpenUrl:false,
        inputStatement:''
    }
 
    constructor(props){
        super(props)
    }
    
    // Avatar set node extras
    getAvatarId = avatarid => this.props.modal.node.extras.avatar = avatarid;
    // Timeout set node extras
    getTimeout = time => this.props.modal.node.extras.timeout = time
      // Messenger Timeout set node extras
    getMsgTimeout = msg => this.props.modal.node.extras.msg_timeout = msg
    
    /* 
     *  getTitleOperator
     *  actualiza título en el operador y su operador dinámico
     * @titleOperator = string
    */
    getTitleOperator = (titleOperator) => {
        this.props.modal.node.extras.titleOperator = titleOperator;
        this.updateOperator(titleOperator)
    }

    /* 
     *  getOperatorVisible
     * @operator = string {si:1, no:0}
    */
    getOperatorVisible = (visible) => {
        this.props.modal.node.extras.visible = visible
        this.updateOperator('',parseInt(visible))
        if(visible == 1){
            let node = this.props.modal.node; 
            let blocks = node.extras.text_blocks;
            const kblocks = Object.keys(blocks);
            kblocks.forEach((k) => {     
                blocks[k].text = '';
                blocks[k].name = '';
                const data = {
                    id: k,
                    text: '',
                    name: ''
                };
                getHandlerPUT(HOST_API +'/api/scheme/statement/',data)
                    .then((res) => { this.props.updateSchema();  
                    }).catch((error) => {
                        console.log('catch toggleModuleModal statement',error)
                        if (error.response.status == 403)
                            cerrarSesion();
                    });
                this.forceUpdate();
            }); 
        }
        this.forceUpdate();
    }

    getAudio = url => {
        this.props.modal.node.extras.audio = url
    }


    /* 
     *  updateOperator
     *  actualiza título y/o visible (si activa el operador dinámico)
     * @titleOperator = string
     * @visible _ int
    */
    updateOperator = (titleOperator='',visible='') => {
        let id = this.props.modal.node.extras.operatorDynamic;
        let data ={
            title: titleOperator,
            visible:visible
        }
        getHandlerPOST(HOST_API +'/api/operator/dynamic_operator/'+id,data)
            .then((res)=>{
                if(res.status!=200)
                    console.log('error título actualización')
            }).catch((error)=>{
                console.log('catch updateOperator',error);
                if (error.response && error.response.status && error.response.status == 403)
                    cerrarSesion();
            });
    }

    isTimeoutValid = valid => 
        this.setState({timeout_valid: valid}, ()=>{
            this.forceUpdate();
        })
    
    /* 
    *  addNewTextBlock
    *  Creación de statement en el operador e invoca addStatementDynamic
    */
    addNewTextBlock = (e) => {
        let node = this.props.modal.node;
        const data = {
            operator_id: node.extras.dbId,
            text: "",
            name: ""
        };
        getHandlerPOST(HOST_API +'/api/scheme/statement/',data)
            .then((res) => {
                if (res.status== 200){
                        let dataOp = {
                            'title':'',
                            'operator_id':this.props.modal.node.extras.operatorDynamic,
                            'description':'',
                            'image':''}
                        this.addStatementDynamic(dataOp, res.data.statement.id)
                        this.props.modal.node.extras.statements_id[res.data.statement.id] = "";

                    // }
                }            
            }).catch((error)=>{
                console.log('catch addNewTextBlock',error);
                if (error.response && error.response.status==403)
                    cerrarSesion();
            });
    }

    /* 
    *  addStatementDynamic
    *  Creación de statement en el operador dinámico
    *   @data {'title':'','operator_id':this.props.modal.node.extras.operatorDynamic,'description':'','image':''}
    *  @idStatement Id del statement creado en el operador
    */
    addStatementDynamic = (data, idStatement) => {
        let node = this.props.modal.node;
        getHandlerPOST(HOST_API +'/api/operator/dynamic_item/',data)
            .then((res)=>{
                if(res.status==200){
                    this.setState({error_limit_item:false});
                    let blocks = node.extras.text_blocks;
                    blocks[idStatement] = {text:'',name:'',dynamic:res.data.id_item};
                    node.extras.statements = idStatement;
                    this.forceUpdate();
                    this.props.updateSchema();
                    return true;
                }else
                    console.log('res.status',res);
                
            }).catch((error) => { 
                console.log('catch addStatementDynamic',error)
                if (error.response.status == 403)
                    cerrarSesion();
            }); 
    }

    /* 
    *  updateStatementDynamic
    *  Actualización de statement en el operador dinámico
    *  @idItem id del item dinamico
    *  @title string
    */
    updateStatementDynamic = (idItem,title) => {
        if(this.props.modal.node.extras.operatorDynamic)
        {
            let data ={
                'title':title,
                'operator_id':this.props.modal.node.extras.operatorDynamic,
                'description':'',
                'image':''
            }
            getHandlerPOST(HOST_API +'/api/operator/dynamic_item/'+idItem,data)
                .then((res)=>{
                    
                }).catch((error) => {
                    console.log('catch updateStatementDynamic',error)
                    if (error.response.status == 403)
                        cerrarSesion();
                });
        }
    }

    updateItem = (k,text) => {  
        this.props.modal.node.extras.text_blocks[k].text = text;
        this.forceUpdate();
    }

    isKeywordValid = (val) => {        
        this.setState({keyword_valid:val}, ()=>{
            this.forceUpdate();
        })
    }

    //Emoji
    callEmoji = (code,k,type)=>{
        let text = this.props.modal.node.extras.text_blocks[k].text;
            text += ` ${code}`;
        this.updateItem(k,text)
    }

    /* 
    *  toggleModuleModal
    *  Save all operator information 
    */
    toggleModuleModal = () => {
        let node = this.props.modal.node; 
        this.setState({error_limit_item:false})       
        let blocks = node.extras.text_blocks;
        const kblocks = Object.keys(blocks);
         kblocks.forEach((k) => {     
            let text = $('#TMM-input-'+k).val();
            let name = $('#TMM-input-value-'+k).val();
           

            blocks[k].text = text;
            blocks[k].name = name;
            const data = {
                id: k,
                text: text,
                name: name
            };
            getHandlerPUT(HOST_API +'/api/scheme/statement/',data)
                .then((res) => {
                    if(this.props.modal.node.extras.visible != true){
                        let id_dynamic = blocks[k].dynamic;
                        this.updateStatementDynamic(id_dynamic,text)
                    }
                    this.props.updateSchema();  
                }).catch((error) => {
                    console.log('catch toggleModuleModal statement',error)
                    if (error.response.status == 403)
                        cerrarSesion();
                });
            this.forceUpdate();
        }); 
        const data = {
            id: this.props.modal.node.extras.dbId,
            title:this.props.modal.node.extras.titleOperator
        };
        getHandlerPUT(HOST_API +'/api/scheme/operator/',data)
            .then(res => {
                this.props.updateSchema();
                this.forceUpdate();
            }).catch((error) => {
                console.log('catch toggleModuleModal saveTitle',error)
                if (error.response.status == 403)
                    cerrarSesion();
            });
        const ports = node.getPorts();
        const kports = Object.keys(ports);
        const kport = kports.find(k => {
            const port = ports[k]
            return port.name == "right-timeout"
        })
        
        if(!kport){
            if(node.extras.timeout > 0)
                node.addPort(new SMPortModel(true, "right-timeout", 'creport'));           
        }else{
            if(node.extras.timeout <= 0){
                kports.forEach((kport) => {
                    const port = ports[kport];                     
                    if(port.name == "right-timeout"){
                        const links = port.getLinks();
                        const klinks = Object.keys(links);
                        klinks.forEach(klink => {
                            const link = links[klink];
                            link.remove();
                        });
                        node.removePort(port);
                    }                    
                })
            } 
        }

        if(!this.state.timeout_valid)
            return false

        if(this.state.keyword_valid)
            this.props.toggleModuleModal('creport')

        
    }

    /* 
    * removeTextBlock
    * Elimina statement creado e invoca removeTextBlockDynamic
    * @index : id del statement
    */
    removeTextBlock = index => {
        let blocks = this.props.modal.node.extras.text_blocks;
        let dynamic_statement = blocks[index].dynamic;
        if(this.state.error_limit_item == true)
            this.setState({error_limit_item:false})
        delete blocks[index]; 
        const data = {
            id: index,
        }
        getHandlerDELETE(HOST_API +'/api/scheme/statement/',data)
            .then(res => {
                this.removeTextBlockDynamic(dynamic_statement);
                delete this.props.modal.node.extras.statements_id[index];
                this.props.updateSchema(); 
            }).catch((error) => {
                console.log('catch removeTextBlock',error)
                if (error.response.status == 403)
                    cerrarSesion();
            });
            this.forceUpdate();
    }

    removeTextBlockDynamic = id => {
        getHandlerPOST(HOST_API +'/api/operator/dynamic_item/'+id+'/delete')
            .then((res)=>{
                if(res.status==200){
                    console.log('eliminado dinamico')
                }else{
                console.log(" No se pudo obtener la lista");
                }
            }).catch((error) => {
                console.log('catch removeTextBlockDynamic',error)
                if (error.response.status == 403)
                    cerrarSesion();
            });
    }

    closeModal = () => this.props.toggleModuleModal('creport');

    toggleModalUrl = (k) => {
        this.setState({ 
            isOpenUrl: !this.state.isOpenUrl,
            inputStatement:k
        }); 
    }

    getUrl = (k,url) => {
        this.setState({ isOpenUrl: !this.state.isOpenUrl}); 
        let text = this.props.modal.node.extras.text_blocks[k].text;
            text += ` ${url}`;
        this.updateItem(k,text)
    }

    render() {
        return (
          <CRMLayout 
          toggleModuleModal={this.toggleModuleModal}
          addNewTextBlock={this.addNewTextBlock}
          removeTextBlock={this.removeTextBlock}
          isOpen={this.props.modal.isOpen} 
          text_blocks={this.props.modal.node.extras.text_blocks} 
          nodeId={this.state.nodeId} 
          keyword={this.props.modal.node.extras.keyword} 
          title={this.state.title} 
          varList={this.props.varList}
          updateItem={this.updateItem}
          botversion_id={this.props.botversion_id}
          operator_id={this.props.modal.node.extras.dbId}
          ia={this.props.modal.node.extras.ia_id || 'none'}
          visible = {this.props.modal.node.extras.visible || ''}
          operator = {this.props.modal.node.extras.operatorDynamic || ''}
          titleOperator = {this.props.modal.node.extras.titleOperator || ''}
          isKeywordValid={this.isKeywordValid}
          error_limit_item={this.state.error_limit_item}
          getTimeout={this.getTimeout}
          getMsgTimeout={this.getMsgTimeout}
          isTimeoutValid={this.isTimeoutValid}
          timeoutValue={this.props.modal.node.extras.timeout}
          timeoutMsg={this.props.modal.node.extras.msg_timeout}
          avatarid={this.props.modal.node.extras.avatar || 0}
          getAvatarId={this.getAvatarId}
          company_id={this.props.company_id}
          botId ={this.props.botId}
          getOperatorVisible = {this.getOperatorVisible}
          getTitleOperator = {this.getTitleOperator}
          closeModal ={this.closeModal}
          callEmoji = {this.callEmoji}
          getAudio = {this.getAudio}
          audio = {this.props.modal.node.extras.audio || ''}

       
          isOpenUrl = {this.state.isOpenUrl}
          toggleModalUrl = {this.toggleModalUrl}
          getUrl = {this.getUrl}
          inputStatement={this.state.inputStatement}

          />
        )
    }
}

export default CRModal
