import React, { Component } from 'react';
import BotIALayoutModuleTable from './bot-ia-configtable';
import ModalLoad from '../../../components/modals/modal-load';
import {getHandlerPOST,fileHandlerPOST,getHandlerPUT,getHandlerDELETE,getHandlerGET} from '../../../utils/handlersApi'
import {cerrarSesion,isAuthorizationValid} from '../../../utils/commonFunction'
import i18next from '../../../../i18n';
import { showError, setTitle } from '../../../redux/actions/loadStateActions';

class BotIATable extends Component {

    constructor(props){
        super(props);
        this.state = {
            hideLoad:0,
            botId: this.props.match.params.id,
            botversion_id: "",
            ia_activated: false,
            ia_id: 0,
            scheme_id: 0,
            uaQuestions: [],
            listIntent: [],
            listOperator: [],
            showConfig: false,
            showEdition: false,
            showEditionEntity: false,
            dialogflow_id:false,
            currentIntent: {
                id:"",
                name:"",
                answers:[],
                questions:[],
                operator_id:""
            }
           
        };
        this.appendQuestion = this.appendQuestion.bind(this);
        this.appendAnswer = this.appendAnswer.bind(this);
    }

    componentWillMount(){
        isAuthorizationValid();
    }

    componentDidMount() {
        this.getBotInfo();
    }

    shouldComponentUpdate(nextProps, nextState) {
        //----------------------------------------------------
        //  SOLO EJECUTA EL UPDATE SI LA COMPAÑIA FUE CAMBIADA
        //---------------------------------------------------
            

        if(nextState.currentIntent !== this.state.currentIntent)
            return true;
        else if(nextState.dialogflow_id !== this.state.dialogflow_id)
            return true;
        else if(nextState.ia_activated !== this.state.ia_activated)
            return true;
        else if(nextState.ia_id !== this.state.ia_id)
            return true;
        else if(nextState.listIntent !== this.state.listIntent)
            return true;
        else if(nextState.showConfig !== this.state.showConfig)
            return true;
        else if(nextState.showEdition !== this.state.showEdition)
            return true;
        else if(nextState.showEditionEntity !== this.state.showEditionEntity)
            return true;
        else if(nextState.uaQuestions !== this.state.uaQuestions)
            return true;
        else 
            return false;
    }

      async componentWillUpdate(nextProps,nextState)
      {
        
      }

    /*  **** **** **** **** **** **** 
                Initial validations */

    /*recovering bot info*/

    getBotInfo () {
        let cont = this.state.hideLoad;
        this.setState({ hideLoad: cont+1});
        getHandlerGET(HOST_API +"/api/bot/?id="+this.state.botId)
            .then((res) =>{
                if(res.status==200){
                    if (res.data.bot[0].ia_activated){ // ia is activated
                        let botversion= res.data.bot[0].bot_version[0];
                        let enlazado=false
                        if(res.data.bot[0].dialogflow_id == '')
                            enlazado = true
                        else
                            enlazado = false
                        this.setState({botversion_id: botversion.botversion_id, ia_activated: res.data.bot[0].ia_activated, dialogflow_id:enlazado});                       
                        this.getScheme();
                        this.listIntent();
                    }else{ // ia is not activated
                        this.props.history.push('/');
                    }
                this.setState({ hideLoad: cont-1});
                }else{
                    console.log(" No se pudo recuperar info del bot");
                }
            }).catch((error)=> {
                console.log('catch getBotInfo',error)
                if (error.response.status == 403)
                    cerrarSesion();
            });
    }

    /* Obtain the scheme to be able to list the operators*/
    getScheme () {
        let cont = this.state.hideLoad;
        this.setState({ hideLoad: cont+1});
        getHandlerGET(HOST_API +"/api/scheme/?botversion_id="+this.state.botversion_id)
            .then((res)=>{
                if(res.status==200)
                    if(res.data.schemes.length > 0)
                        this.state.scheme_id = res.data.schemes[0].id;
                else
                    console.log(" No se pudo listar los esquemas");
                let cont = this.state.hideLoad;
                this.setState({ hideLoad: cont-1});
            }).catch((error)=>{
                console.log('catch getScheme',error)
                if (error.response.status == 403)
                    cerrarSesion();
            });
    }


    /* validate how to update the operator-ia relation 
     *@data = {} object with the operator id and ia id. 
    */
    validateIAOperatorLink(data){
        if(data.next_action){ // needs to link or unlink the ai with the operator
            
            if(this.state.currentIntent.operator_id){ // update previous linked operator
                this.linkingOperatorIA('none', this.state.currentIntent.operator_id);
            }

            if(data.next_action=='none'){ // unlink
                if(this.state.currentIntent.operator_id){
                    this.linkingOperatorIA('none', this.state.currentIntent.operator_id);
                }
            } else { // linking
                this.linkingOperatorIA(data.id, data.next_action);
            }
        }
    }

    /* linking operator and IA 
            @ia_id =  intent id
            @action_id = next action id
    */

    linkingOperatorIA (ia_id, action_id) {
        let data ={
            id: action_id ,//operator id
            ia_id: ia_id
        };
        getHandlerPUT(HOST_API +"/api/scheme/operator/", data)
            .then((res)=>{
                if(res.status==200){
                }else{
                    console.log(" No se pudo enlazar ia con operador");
                }
            }).catch((error)=>{
                console.log('catch linkingOperatorIA',error)
                if (error.response.status == 403)
                    cerrarSesion();
            });
    }

    /* linking bot and IA */

    linkingIA = (event) => async (dispatch) => {
        event.preventDefault();
        let cont = this.state.hideLoad; this.setState({ hideLoad: cont+1});
        let form = new FormData(event.target);
        form.append('botversion_id', this.state.botversion_id);
        fileHandlerPOST(HOST_API +"/api/ia/", form)
            .then((res) =>{
                if(res.status==200){
                    this.setState({dialogflow_id:false},()=>{
                        this.listIntent();
                        this.configBotBack();
                    })
                    
                }else{
                    dispatch(showError(i18next.t('message190')));
                    alert(i18next.t('message190'));
                    console.log(" No se pudo enlazar la ia");
                } 
                this.setState({ hideLoad: cont-1});
            }).catch((error)=> {
                console.log('catch linkingIA',error)
                dispatch(showError(i18next.t('message190')));
                alert(i18next.t('message190'));
                if (error.response.status == 403)
                    cerrarSesion();
            });

    }

    /*  **** **** **** **** **** **** 
                CRUD OF INTENTS */

    /* handles if the form is to create a new intent or to edit an existing one*/
    intentFormSubmit = (event) => {
        event.preventDefault();
        let form = new FormData(event.target);
        if(form.get('id')){  // is editing
            this.updateIntent(form);
        }else { // is creating a new
            this.createIntent(form);
        }
    }

    /* updating an specific intent handler 
        form = data form
    */
    updateIntent (form) {
        if(form.get('name')==''){
            alert(i18next.t('message169'))
            return null
        }

        let cont = this.state.hideLoad; this.setState({ hideLoad: cont+1});
        let data = {
            name : form.get('name'),
            questions: JSON.stringify(this.state.currentIntent.questions),
            answers: JSON.stringify(this.state.currentIntent.answers),
            botversion_id : this.state.botversion_id,
            id: this.state.currentIntent.id
        };
        this.myHelp = {
            id: this.state.currentIntent.id,
            name: form.get('name'),
            next_action: form.get('next_action')
        };
        getHandlerPUT(HOST_API +'/api/ia/intents',data).
            then((res) => {
                if(res.status==200){
                    this.validateIAOperatorLink(this.myHelp);
                    console.log(" modulo actualizado");
                    var aux = this.state.listIntent;
                    for(let i=0; i< aux.length; i++){
                        if(aux[i].id == this.myHelp.id){
                            aux[i].name = this.myHelp.name;
                            break;
                        }
                    }   
                    this.setState({showEditionEntity: false, showEdition: true});
                    
                }else{
                  console.log(" No se pudo actualizar el modulo");
                }
                let cont = this.state.hideLoad; this.setState({ hideLoad: cont-1});
            }).catch((error) => {
                console.log('catch updateIntent',error)
                if (error.response.status == 403)
                    cerrarSesion();
            });
    }

    /* creating an intent handler 
        form = data form
    */
    createIntent (form) {
        if(form.get('name')==''){
            alert(i18next.t('message169'))
            return null
            }
        
        let cont = this.state.hideLoad; this.setState({ hideLoad: cont+1});
        let data = {
            name : form.get('name'),
            questions: JSON.stringify(this.state.currentIntent.questions),
            answers: JSON.stringify(this.state.currentIntent.answers),
            botversion_id : this.state.botversion_id
        };

        this.myHelp = {
            next_action: form.get('next_action')
        };
        getHandlerPOST(HOST_API +'/api/ia/intents',data).
            then((res) => {
                if(res.status==200){
                    this.myHelp.id = res.data.intent.id;
                    this.validateIAOperatorLink(this.myHelp);
                    let aux = this.state.listIntent;
                    aux.push({
                        id: res.data.intent.id,
                        name: res.data.intent.name
                    })
                    this.setState({ listIntent: aux,showEditionEntity: false, showEdition: true});
                }else
                  console.log(" No se pudo crear el modulo"); 
                this.setState({ hideLoad: cont-1});
            }).catch((error) => {
                console.log('catch createIntent',error)
                if (error.response.status == 403)
                    cerrarSesion();
            });
    }

    // Create a list intent Handler
    listIntent () {
        let cont = this.state.hideLoad;
        this.setState({ hideLoad: cont+1}); 
        getHandlerGET(HOST_API +"/api/ia/intents?botversion_id="+this.state.botversion_id).
            then((res) =>{
                if(res.status==200){
                    var list = [];
                    for(let i=0; i<res.data.intents.length;i++){
                        list.push({
                            id: res.data.intents[i].id,
                            name: res.data.intents[i].display_name
                        });
                    }
                    console.log("setListIntent BOT-IA");

                    this.listUAQuestion();
                    this.setState({ listIntent: list, showEdition: true});

                }else{
                    console.log(" No se pudo recuperar la lista de modulos");
                }

                let cont = this.state.hideLoad;
                this.setState({ hideLoad: cont-1});

            }).catch((error)=> {
                console.log('catch listIntent',error);
                if( error.response.status == 500) {
                    this.setState({ showConfig: true});
                }
                let cont = this.state.hideLoad;
                this.setState({ hideLoad: cont-1});
            });

    }

    /* Get an specific intent info Handler
        id = intent_id
    */
    getIntentInfo (id) {
        let cont = this.state.hideLoad; this.setState({ hideLoad: cont+1});
        this.myHelp = id;
        getHandlerGET(HOST_API +"/api/ia/intents?botversion_id="+this.state.botversion_id+"&intent_id="+escape(id)).
            then((res) =>{
                if(res.status==200){
                    let aux = {
                        id : this.myHelp,
                        name : res.data.intent.intent_name,
                        questions : res.data.intent.questions,
                        answers : res.data.intent.answers,
                        operator_id: res.data.intent.operator_id
                    };
                    this.setState({ currentIntent: aux});
                    this.showEntity();
                }else{
                    console.log(" No se pudo recuperar la informacion del modulo");
                }
                let cont = this.state.hideLoad; this.setState({ hideLoad: cont-1});
            }).catch((error)=> {
                console.log(error);
                let cont = this.state.hideLoad; this.setState({ hideLoad: cont-1});
            });

    }

    /* deletes an intent handler */
    deleteIntent = (event) => {
        event.preventDefault();
        let cont = this.state.hideLoad; this.setState({ hideLoad: cont+1});
        let form = new FormData(event.target);

        let data = {
            id: form.get('id'),
            botversion_id: this.state.botversion_id
        };
        this.myHelp = data;

        getHandlerDELETE(HOST_API +"/api/ia/intents", data).
            then((res) =>{
                if(res.status==200){
                    var aux = this.state.listIntent;
                    for(let i=0; i<aux.length; i++){
                        if(aux[i].id == this.myHelp.id){
                            aux.splice(i,1);
                            this.setState({ listIntent: aux});
                            break;
                        }
                    }
                    this.setState({showEditionEntity: false, showEdition: true});
                    
                }else{
                    console.log(" No se pudo eliminar el intent");
                }
                let cont = this.state.hideLoad; this.setState({ hideLoad: cont-1});
            }).catch((error)=> {
                console.log(error);
                let cont = this.state.hideLoad; this.setState({ hideLoad: cont-1});
            });
    }

     /*  **** **** **** **** **** **** 
                CRUD OF UNANSWERED QUESTIONS */
   

    // Create a list unanswered question Handler
    listUAQuestion () {
        let cont = this.state.hideLoad;
        this.setState({ hideLoad: cont+1});
        getHandlerGET(HOST_API +"/api/ia/response_questions?botversion_id="+this.state.botversion_id).
            then((res) =>{
                if(res.status==200){
                    var list = [];
                    if(res.data.data.length > 0){
                        res.data.data.map ((q) =>{
                            let temp = {}
                            temp.id = q.id;
                            temp.question = q.question
                            temp.response = q.response
                            temp.others_responses = q.others_responses
                            temp.sender_id = q.sender_id
                            temp.created = q.created
                            temp.cantidadRegistros = res.data.data.length
                            list.push(temp)
                        } )
                    }
                    this.setState({ uaQuestions: list});
                }else{
                    console.log(" No se pudo recuperar la lista de preguntas sin responder");
                }
                let cont = this.state.hideLoad;
                this.setState({ hideLoad: cont-1});
            }).catch((error)=> {
                console.log(error);
                let cont = this.state.hideLoad;
                this.setState({ hideLoad: cont-1});
            });

    }

    // Resolve an unanswered question Handler
    resolveUAQuestion= async (id)  =>  {
        //event.preventDefault();
        let cont = this.state.hideLoad; this.setState({ hideLoad: cont+1});
        //let form = new FormData(event.target);
        let data = {
            id : id
        };
        this.myHelp = data;

        await getHandlerDELETE(HOST_API +'/api/ia/questions',data).
            then((res) => {
                if(res.status==200){
                    var aux = this.state.uaQuestions;
                    for(let i=0; i<aux.length; i++){
                        if(aux[i].id == this.myHelp.id){
                            aux.splice(i,1);
                            break;
                        }
                    }
                    this.setState({ uaQuestions: aux});
                }else{
                  console.log(" No se pudo resolver la pregunta sin respuesta");
                }
                let cont = this.state.hideLoad; this.setState({ hideLoad: cont-1});
            }).catch((error) => {
                console.log(error);
                let cont = this.state.hideLoad; this.setState({ hideLoad: cont-1});
            });

    }

 // Resolve an unanswered question Handler
 deleteAllUAQuestion= async (id=null)  =>  {
    //event.preventDefault();
    let cont = this.state.hideLoad; this.setState({ hideLoad: cont+1});
    //let form = new FormData(event.target);
    let data = {
                id:id
        };
    await getHandlerDELETE(HOST_API +'/api/ia/response_questions',data).
        then((res) => {
            if(res.status==200){
                var aux = []
                this.setState({ uaQuestions: aux});
            }else{
              console.log(" No se pudo resolver la pregunta sin respuesta");
            }
            let cont = this.state.hideLoad; this.setState({ hideLoad: cont-1});
        }).catch((error) => {
            console.log(error);
            let cont = this.state.hideLoad; this.setState({ hideLoad: cont-1});
        });

}



    /*   **** **** **** **** **** **** 
                    CURRENT ANDWERS AND QUESTIONS HANDLER */


    /* add new questions to the current intent */
    appendQuestion = (event) => {
        event.preventDefault();
        let form = new FormData(event.target);
        let aux = this.state.currentIntent;
        aux.questions.push(form.get("question"));
        event.target.reset();
        this.setState({currentIntent: aux});
     }

     /*add new answers to the current intent*/
     appendAnswer = (event) => {
        event.preventDefault();
        let form = new FormData(event.target);
        let aux = this.state.currentIntent;
        aux.answers.push(form.get("answer"));
        event.target.reset();
        this.setState({currentIntent: aux});
     }


    /* remove questions to the current intent */
    removeQuestion = (event) => {
        event.preventDefault();
        let form = new FormData(event.target);
        let aux = this.state.currentIntent;
        aux.questions.splice(form.get("index"), 1);
        this.setState({currentIntent: aux});
     }

     /*remove answers to the current intent*/
     removeAnswer = (event) => {
        event.preventDefault();
        let form = new FormData(event.target);
        let aux = this.state.currentIntent;
        aux.answers.splice(form.get("index"), 1);
        this.setState({currentIntent: aux});
     }


    /*   **** **** **** **** **** **** 
                    VIEWS TOOGLES */

     /* show the view to the form for linking ia*/
    configBot = () =>{
        this.setState({showConfig: true, showEdition: false});
    }

    /*returns from the linking ia form */
    configBotBack = () =>{
        this.setState({showConfig: false, showEdition: true});
    }

    /* is going to use the form to create a new entity */
    letCreateEntity = (event) =>{
        this.setState({ currentIntent: {
                id:"",
                name:"",
                answers:[],
                questions:[]
            }});
        this.showEntity();

    }

    /* is going to use the form to edit an existing entity */
    letEditEntity = (event) => {
        event.preventDefault();
        let form = new FormData(event.target);
        this.getIntentInfo(form.get('id'));
    }

    /* shows the entity edition page */
    showEntity = () => {
        this.setState({showEditionEntity: true, showEdition: false});
    }

    /* hides the entity edition page */
    hideEntity= () =>{
        if (confirm(i18next.t('message168'))) {
            this.setState({showEditionEntity: false, showEdition: true});
        }
    }

    /*Eventos para capturar la respuesta o pregunta a agregar */
    handleQuestionChange= (e)=> {
        this.setState({answer: this.state.answer,question: e.target.value});
    }

    handleAnswerChange= (e)=> {
        this.setState({answer: e.target.value,question: this.state.question});
    }


    render() {
        return (
        <div>
          <BotIALayoutModuleTable 
          ia_activated={this.state.ia_activated}
          botId={this.state.botId}
          uaQuestions={this.state.uaQuestions}
          listIntent={this.state.listIntent}
          currentIntent={this.state.currentIntent}
          linkingIA={this.linkingIA}
          configBot={this.configBot}
          configBotBack={this.configBotBack}
          newUAQuestion={this.newUAQuestion}
          resolveUAQuestion={this.resolveUAQuestion}
          deleteAllUAQuestion={this.deleteAllUAQuestion}
          intentFormSubmit={this.intentFormSubmit}
          deleteIntent={this.deleteIntent}
          showEntity={this.showEntity}
          hideEntity={this.hideEntity}
          letCreateEntity={this.letCreateEntity}
          letEditEntity={this.letEditEntity}
          appendQuestion={this.appendQuestion}
          appendAnswer={this.appendAnswer}
          removeQuestion={this.removeQuestion}
          removeAnswer={this.removeAnswer}
          listOperator={this.state.listOperator}

          handleQuestionChange={this.handleQuestionChange}
          handleAnswerChange={this.handleAnswerChange}
          displayQuestion={this.state.showquestion}
          displayAnswer={this.state.showanswer}
          dialogflow_id = {this.state.dialogflow_id}

          showConfig={this.state.showConfig}
          showEdition={this.state.showEdition}
          showEditionEntity={this.state.showEditionEntity}
          />
          <ModalLoad hideLoad={this.state.hideLoad} />
          </div>
        )
    }
}

export default BotIATable;