import React, { Component } from 'react';
import { connect } from "react-redux";
  
import ModalLoading from '../../../components/modals/Loading/moda-loading';

import { WidthProvider, Responsive } from "react-grid-layout";
import _ from "lodash";
const ResponsiveReactGridLayout = WidthProvider(Responsive);

const originalLayout = getFromLS("layout") || [];

import { withRouter } from "react-router-dom";
import Select from 'react-select';

import { 
  Col

} from 'reactstrap';


import { getAllCharts,getPreview,cleanPreview,getItems} from "../../../redux/actions/analyticsActions";


import { isAuthorizationValid,getRolUser,isEmpty } from "../../../utils/commonFunction";

  
import { APPLICANT } from "../../../redux/listComponents";
import { isDidMount, willUnMount } from "../../../redux/actions/componentMountActions";
/**
 * This layout demonstrates how to use a grid with a dynamic number of elements.
 */
class AddRemoveLayout extends Component {//extends React.PureComponent {

  static defaultProps = {
    className: "layout",
    items: [],
    cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
    rowHeight: 30,
    //onLayoutChange: function() {}
  };


  constructor(props) {
    super(props);

    this.state = {
      isAdmin: false,
      isSuperUser: false,
      hideLoad: false,
      user:'',
      items: [],
      newCounter: 0,
      listChart:[],
      groupedOptions:[],
      groupStyles:[],
      groupBadgeStyles:[],
      formatGroupLabel:[],
      colourStyles:[],
      charts:[],
      defaultValue:[],
  
    };

    this.onAddItem = this.onAddItem.bind(this);
    this.onBreakpointChange = this.onBreakpointChange.bind(this);
    this.onLayoutChange = this.onLayoutChange.bind(this);

    props.getItems([]);
  }

  callBackPieChart=(group,color)=>{
    this.setState({group:group,color:color,botID:'All'})
  }

  callBackLineChart=(group,botID/*,dateini,datefin*/)=>{
     this.setState({botID:botID/*,dateini:dateini,datefin:datefin*/})
   }


  componentWillMount(){
    isAuthorizationValid()
    const { isAdmin = false, isSuper = false } = getRolUser();

    this.setState({
        isAdmin: isAdmin,
        isSuperUser: isSuper
    });
  
  }

     async componentDidMount(){
    
 

    const { previews, AllPreviews} = this.props.analyticsReducer;
    
    const { analyticsReducer : {listAllCharts=[],selectedDashboard=[]  },getPreview, cleanPreview } = this.props;
     
    this.props.isDidMount(APPLICANT.HOME());    
    
    

    if(isEmpty(listChart)){
      var listChart=[], defaultValue=[];
     
      listAllCharts.map((chart, index) => {    
          chart = JSON.parse(chart);  
          var data = 
                {
                  value: ""+chart.id,
                  label: chart.metric.name,
                  metric:chart.metric,
                  typechart:chart.type_chart.name,
                  type_chartid:chart.type_chart.id,
                  color:chart.color.id,
                  palette_type:chart.color.palette_type
                }
          listChart.push(data)
          
          if(selectedDashboard.charts){
          for(var j=0;j<selectedDashboard.charts.length;j++)
          {
            if(chart.id==selectedDashboard.charts[j].chart){
                defaultValue.push(listChart[index])
                break;
            }
          }
        }
                
          return listChart;
      });
  
      const groupedOptions = [
        {
          label: 'Metricas Funcionales',
          options: listChart,
         },
        // {
        //   label: 'Metricas Operacionales',
        //   options: listChart,
        // }
      ];
  
      const groupStyles = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width:'100%',
      };

  
      const groupBadgeStyles = {
        backgroundColor: '#EBECF0',
        borderRadius: '2em',
        color: '#172B4D',
        display: 'inline-block',
        fontSize: 12,
        fontWeight: 'normal',
        lineHeight: '1',
        minWidth: 1,
        padding: '0.16666666666667em 0.5em',
        textAlign: 'center',
      };

      
      const formatGroupLabel = data => (
        <div style={groupStyles}>
          <span>{data.label}</span>
          <span style={groupBadgeStyles}>{data.options.length}</span>
        </div>
      );
      
      const colourStyles = {
          container: styles => ({ ...styles, backgroundColor: 'white', width:100+'%' }),
           ///.....
        menuPortal: provided => ({ ...provided, zIndex: 9999 }),
        menu: provided => ({ ...provided, zIndex: 9999 })
        ///.....
      };


      this.setState({
        listChart: listChart,
        groupedOptions:groupedOptions,
        groupStyles:groupStyles,
        groupBadgeStyles:groupBadgeStyles,
        formatGroupLabel:formatGroupLabel,
        colourStyles:colourStyles,
        defaultValue:defaultValue

    });

   
      var items=[]
      
       
      if(selectedDashboard.charts){  
        
      items=selectedDashboard.charts.map(function(chart, key, list) {
          return {
            i: chart.chart.toString()+"_"+selectedDashboard.id,
            x: chart.x/2,
            y: chart.y,
            w: chart.width/2,
            h: chart.heigth/3,
            add: chart.chart === (list.length - 1).toString()
          };
        })
      }

  
    
// items.push({i: 'aa', x: 0, y: 0, w: 4, h: 2},
// {i: 'a-a', x: 4, y: 0, w: 1, h: 2},
// {i: 'aaa', x: 5, y: 0, w: 6, h: 2})

        this.props.getItems(items);
        

             this.setState({
    //     charts:selectedDashboard.charts,
          items:items,
    //      defaultValue:defaultValue,
     });
      
   
    if(selectedDashboard.charts){  
    for(var j=0;j<selectedDashboard.charts.length;j++)
    {
      var chart=selectedDashboard.charts[j];
      getPreview(chart.type_chart.id,chart.color.id, chart.color.palette_type,"chart"+chart.chart+"_"+selectedDashboard.id, chart.metric,chart.icon);
    }
  }
}
else
{
  var listChart=[], defaultValue=[];
     
      listAllCharts.map((chart, index) => {    
          chart = JSON.parse(chart);  
          var data = 
                {
                  value: ""+chart.id,
                  label: chart.metric.name,
                  typechart:chart.type_chart.name,
                  type_chartid:chart.type_chart.id,
                  color:chart.color.id,
                  palette_type:chart.color.palette_type
                }
          listChart.push(data)
          
          if(selectedDashboard.charts){
          for(var j=0;j<selectedDashboard.charts.length;j++)
          {
            if(chart.id==selectedDashboard.charts[j].chart){
                defaultValue.push(listChart[index])
                break;
            }
          }
        }
                
          return listChart;
      });
  
              this.setState({
    //     charts:selectedDashboard.charts,
    //      items:items,
          defaultValue:defaultValue,
     });
}
  }

  createElement(el) {
    const removeStyle = {
      position: "absolute",
      right: "2px",
      top: 0,
      cursor: "pointer"
    };

    const configStyle = {
        position: "absolute",
        right: "15px",
        top: 0,
        cursor: "pointer"
      };
    const i = el.add ? "+" : el.i;
    if(el.i=="aa"||el.i=="a-a"||el.i=="aaa")
    return"";
    else{
     
    return (

      <div key={i} data-grid={el} id={"content-chart"+i} i={i} >
        {el.add ? (
          <span
            className="add text"
            onClick={this.onAddItem}
            title="You can add an item by clicking here, too."
          >
            Add +
          </span>
        ) : (
  
          <Col key={"chart"+i} id={"chart"+i} i={i} x={el.x} y={el.y} w={el.w} h={el.h} chart_id={el.chart_id} className="scroll" style={{width:90+"%", height:90+"%"}}>
             
              {this.props.analyticsReducer.allPreviews &&(this.props.analyticsReducer.allPreviews.length > 0 && (
                     this.props.analyticsReducer.allPreviews.map((preview)=>{               
                       return(
                         preview.nameDiv=="chart"+i?(preview.chart):""
                         )})
                         ))} 
             
  
          </Col>                                  
   
        )}
        {/* <span
          className="remove"
          style={removeStyle}
          onClick={this.onRemoveItem.bind(this, i)}
        // onClick= {this.onClick("Grid",i)}
        >
          x
        </span>  */}
        {/* <span
          className="config"
          style={configStyle}
          onClick={this.onRemoveItem.bind(this, i)}
        >
          ...
        </span> */}
      </div>
    );
      }
  }

  onAddItem(actionMeta) {
    /*eslint no-console: 0*/

    const { getPreview,} = this.props;
    const { selectedDashboard } = this.props.analyticsReducer;

    let i = this.state.newCounter;
    if(selectedDashboard)
    {
      i=actionMeta.option.value.toString()+"_"+selectedDashboard.id
    }
    else
    i=actionMeta.option.value.toString()
   
    var itemsNew = [];
    itemsNew = this.props.analyticsReducer.items.concat({
      i: i,//"n" + this.state.newCounter,
      x: (this.props.analyticsReducer.items.length * 2) % (this.state.cols || 12),
      y: 0,//Infinity, // puts it at the bottom
      w: 2,
      h: 2
    })

    this.setState({
      // Add a new item. It must have a unique key!
      items: this.state.items.concat({
        i: i,//"n" + this.state.newCounter,
        x: (this.state.items.length * 2) % (this.state.cols || 12),
        y: 0,//Infinity, // puts it at the bottom
        w: 2,
        h: 2
      }),
      // Increment the counter to ensure key is always unique.
      newCounter: this.state.newCounter + 1
    });
   

    this.props.getItems(itemsNew);

    getPreview(actionMeta.option.type_chartid,actionMeta.option.color, actionMeta.option.palette_type,"chart"+i,actionMeta.option.metric,null);
      this.forceUpdate();
  }

  // We're using the cols coming back from this to calculate where to add new items.
  onBreakpointChange(breakpoint, cols) {
    this.setState({
      breakpoint: breakpoint,
      cols: cols
    });
  }

  onLayoutChange(layout) {
    
    // this.setState({ items: this.props.analyticsReducer.items });
     this.props.getItems(layout);
    // this.props.onLayoutChange(layout);
     this.setState({ layout: layout });
     
    
  }

  onRemoveItem(i) {
  
    const index = i.indexOf("_");
    if(index == -1)//this.props.analyticsReducer.selectedDashboard)
      i=i+"_"+this.props.analyticsReducer.selectedDashboard.id

    this.setState({ items: _.reject(this.state.items, { i: i }) });
    var itemsNew= _.reject(this.props.analyticsReducer.items, { i: i }) 
    this.props.getItems(itemsNew);
    
    
      this.forceUpdate();
   
  }

  onClick(newValue,actionMeta)
  {
    if(newValue=="Grid")
    {
      this.onRemoveItem(actionMeta)
    }
    else{
      if(actionMeta.action=="select-option")
      this.onAddItem(actionMeta)
    else if(actionMeta.action=="remove-value")
      this.onRemoveItem(actionMeta.removedValue.value.toString())
    }

    
  
  }

  showContent=()=>{
    const {  isLogin } = getRolUser();
    if(isLogin){
      return (
      <React.Fragment>
        {this.state.listChart.length>0?(
          <Select
          isMulti
          closeMenuOnSelect={false}
          formatGroupLabel={this.state.formatGroupLabel}
          styles={this.state.colourStyles}
          options={this.state.groupedOptions}
          onChange = {(newValue, actionMeta) => {this.onClick(newValue,actionMeta)}}
          defaultValue={this.state.defaultValue}
          menuPortalTarget={document.body}
          menuPosition={'fixed'} 
          // getOptionLabel={option => `${option.label}: ${option.typechart}`}           
      />
        ):""}
        {/* {this.state.items&&this.state.items.length>0?( */}
          {this.props.analyticsReducer.items&&this.props.analyticsReducer.items.length>0?(
          <ResponsiveReactGridLayout
          className={'charts_grid'}
          onLayoutChange={this.onLayoutChange}
          onBreakpointChange={this.onBreakpointChange}
          layouts={this.state.layout} 
          {...this.props}
        >
           

          {  //this.state.items.length>0?(

            _.map(this.state.items
              /*this.props.analyticsReducer.items*/, el => //{
              this.createElement(el)
          //  }
              )  
        //  ):""     
        } 

          {/* <div key="aa" id="content-filterBar">
              <FilterBar nameDiv="filterBar" group="All" botID="All" dateini="2018" datefin="2019" color="rgba(195,34,168,1)" typeScaleColor="unicolor" />      
          </div> 
          <div key="a-a" id="content-filterBar">
            <FormGroup className="dp">
            Agrupar por
            <Input type="select" name="select" id="agrupacionSelect">
                <option>i18next.t('year')</option>
                <option>i18next.t('month')</option>
                <option>i18next.t('day')</option>
                <option>{i18next.t('hour')}</option>
            </Input>
            </FormGroup>
              </div> 
              <div key="aaa" id="content-datepicker">
                <Date startDate={this.props.startDate} endDate={this.props.endDate} handleChangeStart={this.props.handleChangeStart} handleChangeEnd={this.props.handleChangeEnd} getdata={this.props.getdata}/> 
               
          </div> */}
        </ResponsiveReactGridLayout>

        ):""}
              </React.Fragment>
     );
    }
    return <ModalLoading />
  }


    render() {
      return this.showContent();
  }
}


const mapStateToProps = ({ analyticsReducer, componentMountReducer }) => {
	return { analyticsReducer, componentMountReducer };
};

const mapDispatchToProps = {
  getAllCharts,
  isDidMount,
  willUnMount,
  getPreview,
  cleanPreview,
  getItems
  
};

export default connect(mapStateToProps, mapDispatchToProps)(AddRemoveLayout);

function getFromLS(key) {
    let ls = {};
    if (global.localStorage) {
      try {
        ls = JSON.parse(global.localStorage.getItem("rgl-7")) || {};
      } catch (e) {
        /*Ignore*/
      }
    }
    return ls[key];
  }
  
  function saveToLS(key, value) {
    if (global.localStorage) {
      global.localStorage.setItem(
        "rgl-7",
        JSON.stringify({
          [key]: value
        })
      );
    }
  }