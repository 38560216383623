import React, { Component } from 'react';
import NotificationsLayout from './notifications-layout';
import ModalLoad from '../../../components/modals/modal-load';

import moment from 'moment';
moment.locale('es');

import {getHandlerGET, getHandlerPOST,
   getHandlerDELETE, getHandlerPUT, fileHandlerPOST} from '../../../utils/handlersApi'
import {cerrarSesion,isAuthorizationValid,getUserInfoLocalStorage,getRolUser} from '../../../utils/commonFunction'


class Notifications extends Component {

  state = {
    isAuth: false,
    modalNewNotification: false,
    modalEditNotification: false,
    listNotifications: [],
    listChannels: [],
    listTemplates: [],
    hideLoad: 0 ,
    botId:this.props.match.params.id,
    botVersionId:'',
    ia_activated: false,
    selectedOption: null,
    channelsId:[],
    companyId:'',
    notification:[],
    listContact:[],
    varList:[],
    idList: '',
    datasms:'',
    datafb:'',
    idTemplate:'',
    selectedDay: moment().format('YYYY-MM-DD HH:mm:ss'),
    isAdmin:false 
  }
  
  constructor(props){
    super(props)
  }

  
  componentWillMount(){
    isAuthorizationValid();
    const { isAdmin = false, isSuper = false } = getRolUser();
    this.setState({
        isAdmin: isAdmin,
        isSuperUser: isSuper
    });
  }

  componentDidMount(){
    const {  isLogin } = getRolUser();
    if(isLogin){
      this.getBot(this.state.botId);
    }
  }

  /************ FUNCTIONS *************/

  /*
  * Function getBot
  * @botid = int (id del bot)
  * Get all information about a specific bot
  */

  getBot = (botid) => {
      let cont = this.state.hideLoad;
      this.setState({ hideLoad: cont+1});
      this.getList();
      getHandlerGET(HOST_API +'/api/bot/?id='+botid)
          .then((res)=>{
              if(res.status==200){
                  if(res.data.bot && res.data.bot.length > 0){
                      this.setState({ botVersionId: res.data.bot[0].bot_version[0].botversion_id, ia_activated:res.data.bot[0].ia_activated, companyId: res.data.bot[0].company_id });
                      this.getListNotificacion(this.state.botVersionId);
                      this.getlistTemplates()
                  }
              } 
          this.setState({ hideLoad: cont-1});
          }).catch((error)=>{
              console.log('catch GET getBot',error);
              if (error.response && error.response.status && error.response.status == 403)
                  cerrarSesion();
          });
  }



  /*Funciton open and close modals */
  toggleModalNewNotification = () => { 
    this.setState({ 
      modalNewNotification: !this.state.modalNewNotification,
      selectedOption:null,
      idTemplate:'',
      idList:'',
      datafb:'',
      datasms:'',
      selectedDay: moment().format('YYYY-MM-DD HH:mm:ss')
     })
   }
  toggleModalEditNotification = () =>{ this.setState({ modalEditNotification: !this.state.modalEditNotification }); }

   /*
    * Function getListNotificacion
    * @botid = int (bot id)
    * Get all information about notifictions
    */
   getListNotificacion = (botid) => {
    let cont = this.state.hideLoad; 
    this.setState({ hideLoad: cont+1});
    getHandlerGET(HOST_API +'/api/notification/notification/?botv='+botid).
        then((res)=>{
            if(res.status==200){
             let list = []
              //Add channelsLabel property with all channels received it
              for (let i = 0; i < res.data.data.length; i++) {
                  let label='';
                  for (let l = 0; l < res.data.data[i].channels.length; l++) {
                    label += res.data.data[i].channels[l].channel+', ';
                  }
                  label = label.substring(0, label.length - 2);
                  let temporal = {
                    ...res.data.data[i],
                    channelsLabel:label
                  }
                  list.push(temporal)
              }
              this.setState({listNotifications:list },()=>{this.getListChannels()});
            }  
            else
              console.log(" No se pudo obtener la lista");
            this.setState({ hideLoad: cont-1});
        }).catch((error) => {
            console.log('catch GET getListNotificacion',error);
            if (error.response.status == 403)
               cerrarSesion();
        });
  }

  /*
  * Function getList
  * Get all list contact by company
  */

  getList = () => {
    getHandlerGET(HOST_API +'/api/listas/bycompany?id_company='+getUserInfoLocalStorage().company_id).
        then((res)=>{
            if(res.status==200)
                this.setState({listContact:res.data})
            else
              console.log(" No se pudo obtener la lista");
        }).catch((error) => {
            console.log('catch GET getListNotificacion',error);
            if (error.response.status == 403)
              cerrarSesion();
        });
  }

  /*
  * Function getListChannels
  * Get all channels available and change their structure
  * original: [0:{id:1,channel:email},1:{id:2,channel:sms}]
  * modified: [0:{value:1,label:email},1:{value:2,label:sms}]
  */

   getListChannels = () => {
    getHandlerGET(HOST_API +'/api/notification/channels/').
        then((res)=>{
            if(res.status==200){
                let options = [];
                let result = res.data.data;
                for (let i = 0; i < result.length; i++) {
                    let temp = {
                        value:result[i].id,
                        label:result[i].channel
                    }
                    options.push(temp)
                }
                this.setState({listChannels:options})
            }else
              console.log(" No se pudo obtener la lista");
        }).catch((error) => {
            console.log('catch GET getListNotificacion',error);
            if (error.response.status == 403)
               cerrarSesion();
        });
  }
  
  /*
  * Function getlistTemplates
  * Get all template available for company
  * @idCompany = int (company id)
  */
  getlistTemplates = () => {
    getHandlerGET(HOST_API +'/api/notification/templates/')
      .then((res)=>{
          if(res.status==200)
            this.setState({listTemplates:res.data})
          else
            console.log(" No se pudo obtener la lista");
      }).catch((error) => {
          console.log('catch GET getListNotificacion',error);
          if (error.response.status == 403)
            cerrarSesion();
      });
  }

  /*
  * Function handleChangeChannels
  * save all selected options from channels in state
  */
  handleChangeChannels = selectedOption =>  this.setState({selectedOption})
  handleChangeDay = day => this.setState({ 
    selectedDay: day.format('YYYY-MM-DD HH:mm:ss')
   })
  handleChangeSelectContact = event => this.getListHeaders(event.target.value);

  // Change input style with validate
  customValidate = (classItem,additionalClass,borderValidate) => {
    if(borderValidate)
      document.getElementsByClassName(classItem)[0].setAttribute('class',`${classItem} ${additionalClass} border-red`)
    else
      document.getElementsByClassName(classItem)[0].setAttribute('class',`${classItem} ${additionalClass}`)
  }

  // Validate if idItem is empty and call customValidate
  validate = (idItem,additionalClass,classItem) =>{
    if(document.getElementById(idItem).value == ""){
      this.customValidate(classItem,additionalClass,true)
      return false;
    }else{
      this.customValidate(classItem,additionalClass,false)
      return true;
    } 
  }

  /*
  * Function saveNotiHandler
  * Save notifications at BD
  */
  saveNotiHandler = () => {
    event.preventDefault();
    let selectionChannels = this.state.selectedOption;
    if(selectionChannels == null || (selectionChannels != null && selectionChannels.length == 0)){
      this.customValidate('css-bg1rzq-control','',true)
      return false
    }else{
      let a = true
      document.getElementsByName('name1')[0].setAttribute('class','')
      document.getElementsByName('name2')[0].setAttribute('class','')
      for (let i = 0; i < selectionChannels.length; i++) {
        let channel = selectionChannels[i].label
        switch (channel) {
          case 'email':
            let b = this.validate('plantillaEmail','form-control','plantillaEmailBox')
            let c = this.validate('subject','form-control','subjectBox')
            a = b && c 
            break;
          case 'sms':
              a = this.state.datasms != ''
              if(!a)
               document.getElementsByName('name1')[0].setAttribute('class','border-red')
          break;
          case 'messenger':
          case 'whatsapp':
              a = this.state.datafb != ''
              if(!a)
               document.getElementsByName('name2')[0].setAttribute('class','border-red')
          break;   
          default:
            break;
        } 
      }
      if(!a)
        return false;
    }
    //Change channels to original structure
    let options = [];
    for (let i = 0; i < this.state.selectedOption.length; i++) {
      let temp = { id:this.state.selectedOption[i].value, channel:this.state.selectedOption[i].label}
      options.push(temp)
    }
    let form = new FormData(event.target);
    let data = {
      title:form.get('title'),
      sms_message:this.state.datasms,
      chat_message:this.state.datafb,
      senddate:this.state.selectedDay,
      send_date:'',
      list_id:form.get('list'),
      channels:JSON.stringify(options),
      template_id: form.get('plantillaEmail'),
      company_id:this.state.companyId,
      bot_version:this.state.botVersionId,
      send_time:'',
      subject:form.get('subject')
    }
    getHandlerPOST(HOST_API +'/api/notification/notification/',data)
      .then((res)=>{
          if(res.status==200){
            alert('Datos guardados con exito');
            this.toggleModalNewNotification();
            this.setState({selectedOption:null},()=>{document.getElementById('frm_notifications').reset()})
            this.getListNotificacion(this.state.botVersionId);
          }
          else
            console.log(" No se pudo GUARDAR");
      }).catch((error) => {
          console.log('catch POST saveNotiHandler',error);
          if (error.response.status == 403)
            cerrarSesion();
      }); 
  }

 /*
  * Function saveNotiHandler
  * update notifications at BD
  */
  saveEditNotiHandler = () => {
    event.preventDefault();
    let selectionChannels = this.state.selectedOption;
    document.getElementsByName('name1')[0].setAttribute('class','')
    document.getElementsByName('name2')[0].setAttribute('class','')
    if(selectionChannels == null){
      this.customValidate('css-bg1rzq-control','',true)
      return false
    }else{
      let a = true
      for (let i = 0; i < selectionChannels.length; i++) {
        let channel = selectionChannels[i].label
        switch (channel) {
          case 'email':
            let b = this.validate('editplantillaEmail','form-control','plantillaEmailBoxEdit')
            let c = this.validate('editsubject','form-control','subjectEditBox')
            a = b && c 
            break;
          case 'sms':
              a = this.state.datasms != ''
              if(!a)
               document.getElementsByName('name1')[0].setAttribute('class','border-red')
          break;
          case 'messenger':
          case 'whatsapp':
              a = this.state.datafb != ''
              if(!a)
               document.getElementsByName('name2')[0].setAttribute('class','border-red')
          break;   
          default:
            break;
        } 
      }
      if(!a)
        return false;
    }
    //Change channels to original structure
    let options = [];
    for (let i = 0; i < this.state.selectedOption.length; i++) {
      let temp = { id:this.state.selectedOption[i].value, channel:this.state.selectedOption[i].label}
      options.push(temp)
    }
    let form = new FormData(event.target);
    let data = {
      notid:form.get('notid'),
      title:form.get('edittitle'),
      sms_message:this.state.datasms,
      chat_message:this.state.datafb,
      senddate:this.state.selectedDay,
      send_date:'',
      subject:form.get('editsubject'),
      list_id:form.get('listEdit'),
      channels:JSON.stringify(options),
      template_id:form.get('editplantillaEmail'),
      company_id:this.state.companyId,
      bot_version:this.state.botVersionId,
      send_time:''
    }
    getHandlerPUT(HOST_API +'/api/notification/notification/',data)
      .then((res)=>{
          if(res.status==200){
            alert('Datos Actualizados con éxito');
            this.toggleModalEditNotification();
            this.getListNotificacion(this.state.botVersionId);
          }
          else
            console.log(" No se pudo actualizar");
      }).catch((error) => {
          console.log('catch POST saveNotiHandler',error);
          if (error.response.status == 403)
            cerrarSesion();
      });
    
  }

  /*
  * Function getNotificaion
  * get specific notification
  * @id = int (notification id)
  */
  getNotification = (id) => {
    let cont = this.state.hideLoad; 
    this.setState({ hideLoad: cont+1});
    getHandlerGET(HOST_API +'/api/notification/notification/?notid='+id).
        then((res)=>{
            if(res.status==200){
              let result = res.data.data;
              let options = []
              for (let i = 0; i < result.channels.length; i++) {
                let temp = {
                    value:result.channels[i].id,
                    label:result.channels[i].channel
                }
                options.push(temp)
            }
            result = {
              ...result,
              channelsMod: options
            }
            
              this.setState({
                  notification:result,
                  selectedOption:options,
                  selectedDay:result.senddate,
                  idList:result.list_id,
                  datasms:result.sms_message,
                  datafb:result.chat_message,
                  idTemplate:result.template_id},
                ()=>{
                  this.toggleModalEditNotification();
                  this.getListHeaders(this.state.idList);
                  });
            }  
            else
              console.log(" No se pudo obtener la lista");
            this.setState({ hideLoad: cont-1});
        }).catch((error) => {
            console.log('catch GET getListNotificacion',error);
            if (error.response.status == 403)
               cerrarSesion();
        });
  }

  /*
  * Function deleteNotification
  * Delete notifications
  * @id = id notification
  */
  deleteNotification = (id) => {
    if (confirm("Desea eliminar esta notificación permanentemente?")) {
      let data = {notid:id}
      getHandlerDELETE(HOST_API +'/api/notification/notification/',data)
        .then((res)=>{
            if(res.status==200){
              this.getListNotificacion(this.state.botVersionId);
            }else{
              console.log(" No se pudo obtener la lista");
            }
        }).catch((error) => {
          console.log('catch POST saveNotiHandler',error);
          if (error.response.status == 403)
            cerrarSesion();
        });
    }
  }

 /*
  * Function getListHeaders
  * get all header with a specific contact list
  * @id = id notification
  */
  getListHeaders = (id) => { 
    getHandlerGET(HOST_API +'/api/listas/cabecerajs?_id='+id). 
        then((res)=>{ 
            if(res.status==200) 
                this.setState({varList:res.data}) 
            else 
              console.log(" No se pudo obtener las cabeceras"); 
        }).catch((error) => { 
            console.log('catch GET getListNotificacion',error); 
            if (error.response.status == 403) 
              cerrarSesion(); 
        }); 
  } 

  updateItem = (k,text) => {  
      if(k==1)
        this.setState({datasms:text});
      else
        this.setState({datafb:text});
      this.forceUpdate();
  }
  

  render() {
    return (
      <div>
      <NotificationsLayout
        isAdmin={this.state.isAdmin}
        toggleModalNewNotification={this.toggleModalNewNotification}
        modalNewNotification={this.state.modalNewNotification}
        toggleModalEditNotification = {this.toggleModalEditNotification}
        modalEditNotification = {this.state.modalEditNotification}
        listNotifications= {this.state.listNotifications}
        listChannels = {this.state.listChannels}
        handleChangeChannels = {this.handleChangeChannels}
        botId={this.state.botId}
        ia_activated = {this.state.ia_activated}
        selectedOption = {this.state.selectedOption}
        idList = {this.state.idList}
        selectedDay={this.state.selectedDay}
        handleChangeDay = {this.handleChangeDay}
        listContact = {this.state.listContact}
        saveNotiHandler={this.saveNotiHandler}
        getNotification = {this.getNotification}
        deleteNotification = {this.deleteNotification}
        notification= {this.state.notification}
        listTemplates = {this.state.listTemplates}
        idTemplate = {this.state.idTemplate}
        getlistTemplates = {this.getlistTemplates}
        getList = {this.getList}
        handleChangeSelectContact = {this.handleChangeSelectContact}
        varList = {this.state.varList}
        updateItem={this.updateItem}
        datafb={this.state.datafb}
        datasms ={this.state.datasms}
        saveEditNotiHandler = {this.saveEditNotiHandler}/>
        <ModalLoad hideLoad={this.state.hideLoad} />
      </div>
    )
  }
}

export default Notifications
