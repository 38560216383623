import React, { Component } from 'react';
import axios from 'axios';
import qs from 'qs';
import EBMLayout from './elements-buttons-modal-layout';
import SMPortModel from '../simplePortModel/SMPortModel';

import { cerrarSesion } from '../../../../utils/commonFunction'

class EBModal extends Component {
    state = {
        answer_blocks: {'bl_0': ""},
        nodeId: "",
        keyword: "",
        title: "",
        keyword_valid: true,
        selection:  null,
        range: null,
        error_limit_item: false,
        error_input_empty: {},
        error_input_type: {},
        timeout_valid: true,
        error_requerido:false

    }

    constructor(props){
        super(props)
    }

    getAvatarId = (avatarid) => {
        this.props.modal.node.extras.avatar = avatarid;
    }
    getTitleOperator = (titleOperator) => {
        this.props.modal.node.extras.titleOperator = titleOperator
    }

    validateEmpty = (node)=>{
        let blocks = node.extras.buttons;
        const kblocks = Object.keys(blocks);
        let error_input_empty = {}
        let err = false;
        kblocks.forEach((k) => {    
            let input = $('#input-'+k).val()   
            if(!err && (input.length <= 0)){
                err = true;
            }   
            error_input_empty[k] = input.length <= 0;            
        });  

        this.setState({error_input_empty: error_input_empty}, ()=> {
            this.forceUpdate();
        });
        return err;
    }


    updateVarName = (index, event) => {
        let blocks = this.props.modal.node.extras.buttons;
        blocks[index].var_name = event.target.value ;
        this.forceUpdate();
    }

    validateType = (node)=>{
        let blocks = node.extras.buttons;
        const kblocks = Object.keys(blocks);
        let error_input_type = {}
        let err = false;
        kblocks.forEach((k) => {     
            if(blocks[k].type == "web_url"){
                if(blocks[k].url == ''){
                    err = true;
                    error_input_type[k] = true 
                } 
            else if(blocks[k].type == "location"){
                    if(blocks[k].var_name == ''){
                        err = true;
                        error_input_type[k] = true 
                    } 
                }}
            else{
                error_input_type[k] =false;
            }   
        });  
        this.setState({error_input_type: error_input_type}, ()=> {
            this.forceUpdate();
        });
        return err;
    }
    
    toggleModuleModal = () => {  
        
        console.log("MODAL");

        let title = this.props.modal.node.extras.titleOperator;
        this.saveTitle(title).then(res => {
            this.props.updateSchema();
            this.forceUpdate();
        }).catch((error)=>{
            console.log('catch saveTitle',error);
            if (error.response && error.response.status && error.response.status == 403){
                cerrarSesion();
            }
        });

        this.setState({error_limit_item:false});  

        if(!this.state.timeout_valid)
            return false

        const handleAnswers = (node) => {
            let blocks = node.extras.buttons;
            const kblocks = Object.keys(blocks);
            console.log("kblocks ",kblocks);
            kblocks.forEach((k) => {    
                let text = $('#input-'+k).val();
                
                blocks[k]['title']=text;      
                
                blocks[k] = {
                    text,
                    name: text,
                    is_var:blocks[k].is_var,
                    var_name:blocks[k].var_name,
                    type:blocks[k].type,
                    title:text,
                    url:blocks[k].url
                };

                this.updateStatement(k, text, text,blocks[k].is_var,blocks[k].var_name,blocks[k].type).then((res) => {
                    this.props.updateSchema();
                }).catch((error) => {
                    console.log('Catch updateStatement:',error);
                    if (error.response.status == 403)
                      cerrarSesion();
                }); 
                this.forceUpdate();
            });
            console.log("ingresa");        
        };

        let node = this.props.modal.node; 
        if(this.validateType(node))
            return false;
        if(this.validateEmpty(node))
            return false;
        
        console.log("ok");

        let headid = node.extras.headid;
        console.log("headid ",headid);
        node.extras.head =$('#input-head').val();
        console.log("node.extras.head ",node.extras.head);

        if(!headid){
            this.saveStatement(node.extras.dbId).then((res) => {
                if (res.status== 200){
                    node.extras.headid = res.data.statement.id;
                }
                /**
                 * Dejar campo de text & name vacio para evitar que se muestre en el mentions 
                 * ya que, no es una variable.
                 * (statement, text, name,is_var, var_name)
                 */
                this.updateStatement(
                            node.extras.headid, 
                            (node.extras.is_var) ? node.extras.head : '', 
                            (node.extras.is_var) ? node.extras.head : '', 
                            node.extras.is_var? node.extras.is_var : false, 
                            node.extras.var_name? node.extras.var_name:'' ,
                            '');
                       
            }).catch((error) => {
                console.log('Catch saveStatement:',error);
                if (error.response.status == 403)
                  cerrarSesion();
            }); 
        }else{
            /**
             * Dejar campo de text & name vacio para evitar que se muestre en el mentions 
             * ya que, no es una variable.
             * (statement, text, name,is_var, var_name)
             */
    
            this.updateStatement(
                        headid,
                        (node.extras.is_var) ? node.extras.head : '', 
                        (node.extras.is_var) ? node.extras.head : '', 
                        node.extras.is_var? node.extras.is_var : false,
                        node.extras.var_name? node.extras.var_name:'' 
                        ,'');
        }
        /*if(!node.extras.head || node.extras.head =='')
        {
            this.setState({error_requerido: true});
            return false;
        }
        
        this.setState({error_requerido: false});
        
        if(!headid){
            this.saveStatement(node.extras.dbId).then((res) => {
                if (res.status== 200){
                    node.extras.headid = res.data.statement.id;
                }
                this.updateStatement(node.extras.headid, node.extras.head, node.extras.head).then((res) => {
                    this.props.updateSchema();
                })
            }).catch((error) => {
                console.log('Catch saveStatement:',error);
                if (error.response.status == 403)
                  cerrarSesion();
            }); 
        }else{
            this.updateStatement(headid, node.extras.head, node.extras.head).then((res) => {
                this.props.updateSchema();
            })
        }*/

        handleAnswers(node);  
        const ports = node.getPorts();
        console.log("ports one ",ports);
        const kports = Object.keys(ports);

        let blocks = node.extras.buttons;
        const kblocks = Object.keys(blocks);

        const kport = kports.find(k => {
            const port = ports[k]
            return port.name == "right-timeout"
        })
        
        if(!kport){
            if(node.extras.timeout > 0){
                node.addPort(new SMPortModel(true, "right-timeout", 'sm'));
            }
        }else{
            if(node.extras.timeout <= 0){
                kports.forEach((kport) => {
                    const port = ports[kport];                     
                    if(port.name == "right-timeout"){
                        const links = port.getLinks();
                        const klinks = Object.keys(links);
                        klinks.forEach(klink => {
                            const link = links[klink];
                            link.remove();
                        });
                        node.removePort(port);
                    }                    
                })
            } 
        }


        kblocks.forEach((kblock) => {
            const kport = kports.find(k => {
                const port = ports[k]
                return port.name == "right-" + kblock
            })
            if(!kport){
                const block = blocks[kblock]
                if(block.type == "postback"||block.type == "location"){
                    node.addPort(new SMPortModel(true, "right-" + kblock, 'eb'));
                } 
            } 
        })

        kports.forEach((kport) => {
            const port = ports[kport]        
            console.log("PORT ",port); 
            if(port.name != "left"){
                const n = port.name.replace("right-", "")
                if(kblocks.indexOf(n) == -1 || (blocks[n].type != "postback"&&blocks[n].type != "location")){
                    const links = port.getLinks();
                    const klinks = Object.keys(links);
                    klinks.forEach(klink => {
                        const link = links[klink];
                        link.remove();
                    });
                    node.removePort(port);
                }
            }
            
        })

        if(!this.state.keyword_valid)
            return false;
        
        
        this.props.toggleModuleModal('elements_button')
        
    }

    addNewTextBlock = (e) => {
        let node = this.props.modal.node;
        this.saveStatement(node.extras.dbId).then((res) => {
            if (res.status== 200){
                let size = Object.keys(node.extras.buttons).length;
                if(size > 2){
                    e.persist(); //event is not being nullified
                    this.setState({error_limit_item:true});
                    this.forceUpdate();
                    this.props.updateSchema()
                    return false;
                }else{
                    let blocks = node.extras.buttons;
                    blocks[res.data.statement.id] = {title: "", type: "postback", url: "", is_var:false,var_name:"",};
                    this.setState({error_limit_item:false})
                    this.forceUpdate();
                    this.props.updateSchema()
                    return true;
                }
            }            
        }).catch((error) => {
            console.log('Catch addNewTextBlock:',error);
            if (error.response.status == 403)
              cerrarSesion();
        });
    }

    saveTitle = (title) => {
        const data = {
            id: this.props.modal.node.extras.dbId,
            title:title
        };

        var auth=JSON.parse(localStorage.getItem('Authorization'));
        var headers={
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer '+ auth.access_token 
        }

        return axios({
            method:'put',
            url:HOST_API +'/api/scheme/operator/',
            data:qs.stringify(data),
            headers:headers
        })
    }

    updateItem = (text) => {  
        this.props.modal.node.extras.head = text;
        this.forceUpdate();
    }


    removeStatement = (statement) => {
        const data = {
            id: statement,
        };

        var auth=JSON.parse(localStorage.getItem('Authorization'));
        var headers={
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer '+ auth.access_token 
        }

        return axios({
            method:'delete',
            url:HOST_API +'/api/scheme/statement/',
            data:qs.stringify(data),
            headers:headers
        })
    }

    removeTextBlock = (index) => {
        this.setState({error_limit_item:false});   
        let blocks = this.props.modal.node.extras.buttons;
        delete blocks[index];
        this.removeStatement(index).then(res => {
            this.props.updateSchema()
        }).catch((error) => {
            console.log('Catch removeTextBlock:',error);
            if (error.response.status == 403)
              cerrarSesion();
        });
        this.forceUpdate();
    }

    updateTextBlock = (index, prop, event) => {
            let blocks = this.props.modal.node.extras.buttons;
            blocks[index][prop] = event.target.value

            if(prop=='type'&&event.target.value=='location')
            {
                blocks[index]['is_var']= true;
                blocks[index]['var_name'] = 'location';
            }
            else if(prop=='type'){
                blocks[index]['is_var'] = false;
                blocks[index]['var_name'] = '';
                
            }

            this.forceUpdate();
    }

    saveStatement = (operator) => {
        const data = {
            operator_id: operator,
            text: "",
            name: ""
        };

        var auth=JSON.parse(localStorage.getItem('Authorization'));
        var headers={
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer '+ auth.access_token 
        }

        return axios({
            method:'post',
            url:HOST_API +'/api/scheme/statement/',
            data:qs.stringify(data),
            headers:headers
        });
    }

    updateStatement = (statement, text, name,is_var,var_name,type) => {
        const data = {
            id: statement,
            text,
            name,
            is_var,
            var_name,
            type,
            title: text
        };
 
        var auth=JSON.parse(localStorage.getItem('Authorization'));
        var headers={
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer '+ auth.access_token 
        }

        return axios({
            method:'put',
            url:HOST_API +'/api/scheme/statement/',
            data:qs.stringify(data),
            headers:headers
        });
    }

    updateKeyword = (text) => {    
        console.log("btns");
        this.props.modal.node.extras.keyword = text;
        this.saveKeyword(text);
        this.forceUpdate();
    }

     /* Update data de keyword */
     saveKeyword = (keyword) => {
        const data = {
            id: this.props.modal.node.extras.dbId,
            keyword:keyword
        };
        var auth=JSON.parse(localStorage.getItem('Authorization'));
        var headers={
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer '+ auth.access_token 
        }
        return axios({
            method:'put',
            url:HOST_API +'/api/scheme/operator/',
            data:qs.stringify(data),
            headers:headers
        })
    }

    isKeywordValid = (val) => {        
        this.setState({keyword_valid:val}, ()=>{
            this.forceUpdate();
        })
    }

    closeModal = () => {
        this.props.closeModal('m4');
        this.forceUpdate();
    }

    // Timeout functions
    getTimeout = (time) => {
        this.props.modal.node.extras.timeout = time
    }

    getMsgTimeout = (msg) => {
        this.props.modal.node.extras.msg_timeout = msg
    }

    isTimeoutValid = (valid) => {
        this.setState({timeout_valid: valid}, ()=>{
            this.forceUpdate();
        })
    }

      //Emoji
    callEmoji = (code,k,type)=>{
        if(type === "head"){
         let text = $('#input-head').val();
          text += ` ${code}`;
          $('#input-head').val(text) 
          this.updateItem(text)   
        }else{
         let text = $('#input-'+k).val();
          text += ` ${code}`;
          $('#input-'+k).val(text)  
        }
    }

     // IA set node extras
     getIAId = IAid => this.props.modal.node.extras.ia_id = IAid;

    render() {
        return (
          <EBMLayout
          toggleModuleModal={this.toggleModuleModal}
          addNewTextBlock={this.addNewTextBlock}
          removeTextBlock={this.removeTextBlock}
          updateTextBlock={this.updateTextBlock}
          updateVarName={this.updateVarName}
          updateKeyword={this.updateKeyword}
          isOpen={this.props.modal.isOpen}
          buttons={this.props.modal.node.extras.buttons}
          nodeId={this.state.nodeId}
          keyword={this.props.modal.node.extras.keyword}
          isKeywordValid={this.isKeywordValid}
          head={this.props.modal.node.extras.head}
          title={this.state.title}
          botversion_id={this.props.botversion_id}
          operator_id={this.props.modal.node.extras.dbId}
          ia={this.props.modal.node.extras.ia_id || 'none'}
          error_limit_item={this.state.error_limit_item}
          error_input_type={this.state.error_input_type}
          error_input_empty={this.state.error_input_empty}
          closeModal={this.closeModal}
          varList={this.props.varList}
          updateItem={this.updateItem}
          getTimeout={this.getTimeout}
          getMsgTimeout={this.getMsgTimeout}
          isTimeoutValid={this.isTimeoutValid}
          timeoutValue={this.props.modal.node.extras.timeout}
          timeoutMsg={this.props.modal.node.extras.msg_timeout}
          avatarid={this.props.modal.node.extras.avatar || 0}
          getAvatarId={this.getAvatarId}
          getIAId = {this.getIAId}
          company_id={this.props.company_id}
          titleOperator = {this.props.modal.node.extras.titleOperator || ''}
          getTitleOperator = {this.getTitleOperator}
          callEmoji = {this.callEmoji}
          error_requerido={this.state.error_requerido}
          />
        )
    }
}

export default EBModal