//Layout modal GOTO
import React from 'react';
import { 
    Button, 
    Modal, 
    ModalHeader, 
    ModalBody, 
    ModalFooter,
    Form, 
    FormGroup, 
    Alert, 
    Col,
    Row,
    Input
} from 'reactstrap';
require('../../../../components/modals/modal.css');
import SchemesSelector from '../../../../components/schemes';
import AllOperatorSelector from '../../../../components/allOperator';
import TitleOperator from "../../../../components/title-operator";

import i18next from "../../../../../i18n";

function GOMLayout(props){
    return (
        <div>
            <Modal className="goto" isOpen={props.isOpen} toggle={props.closeModal} fade size="lg">
                <ModalHeader toggle={props.closeModal}>{i18next.t('set_up')} Go to</ModalHeader>
                <ModalBody>
                    <Form role="form">
                        <Row>
                            <Col sm="12">
                                <FormGroup>
                                    <TitleOperator titleOperator={props.titleOperator} bindValue={props.getTitleOperator}/>
                                    <SchemesSelector bindValue={props.getSchema} botversion_id={props.botversion_id} 
                                    schema={props.scheme}/>
                                    <Alert color="danger" isOpen={props.validSchema}>
                                        {i18next.t('must_select_scheme')}
                                    </Alert> 
                                    <AllOperatorSelector bindValue={props.getOperator}
                                    botversion_id={props.botversion_id} 
                                    operator={props.operator} schemaId={props.scheme} 
                                    operatorsList={props.operatorsList} />
                                    <Alert color="danger" isOpen={props.validOperator}>
                                        {i18next.t('must_select_operator')}
                                    </Alert>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter>   
                    <Col sm="12" align="right">
                        <Button color="success" className="btn-guarumo-modal-sm" type="button" onClick={props.toggleModuleModal} >{i18next.t('finish')}</Button>
                    </Col>
                </ModalFooter>
            </Modal>
        </div>
    );
}
  
export default GOMLayout
