import React from 'react';
import Admin from '../../admin/containers/admin';
import SliceMenuApp from '../../admin/components/slice-menu';
import moment from "moment";
import { 
  Col,
  Row,
  Button, 
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Table,
  Pagination, 
  PaginationItem, 
  PaginationLink
} from 'reactstrap';
import { Link } from "react-router-dom";
import Select from 'react-select'
// import makeAnimated from 'react-select/lib/animated';
import ToolbarApp from '../../admin/components/toolbar';
import BotMenuOptions from'../../menus/bot-menu-options';
import '../statistic/bot-statistics-layout.css';

import DataTableFilter from '../../analytics/infoViz/common/dataTableFilter'
import i18next from '../../../../i18n';


function BotFunnelsLayout(props) {
    moment.locale('es');

    // default options and select options
     var optionsA = [];
     var defaultOptions=[];
     props.eventList.map((item,index)=>{
         let added = false;
         for(let i=0; i<props.current.events.length; i++){
             if(item.id.toString() == props.current.events[i]){
                 defaultOptions.push({value:item.id, label:item.event_name})
                 added = true;
                 break;
             } 
         }
         if (!added){
             optionsA.push({value: item.id, label: item.event_name});
         }
     });
    // // pages array
    // var pages = [];
    //     for(let i=0; i<props.pagination.pages; i++){
    //         pages.push(i+1);
    //     } 
    // var ep1 = false; // first dots ...
    // var ep2 = false; // second dots ...

    // var pagination_list = [];
    // pages.map((k, index)=>{
    //     let label = "";
    //     if(k>1 && k<(props.pagination.current-1)){
    //         if(!ep1){
    //             label ="...";
    //             ep1 = true;
    //         }
    //     }else if(k<(props.pagination.pages) && k>(props.pagination.current+1)){
    //         if(!ep2){
    //             label ="...";
    //             ep2 = true;  
    //         }

    //     } else {
    //         label =k;
    //     }
    //     if(label){
    //        pagination_list.push( <PaginationItem key={k} active={k === props.pagination.current}>
    //           <PaginationLink onClick={(e)=>{props.tableData(k)}}>
    //             {label}
    //           </PaginationLink>
    //         </PaginationItem> );
    //     }

    // })

    // bot info
    var data = {};
  
    // selects ///  -- languages
    var sel_language = [];
    var aux2 = false;
    for(let i = 0; i < props.languages.length; i++){
      let aux = JSON.parse(props.languages[i]);
      if(aux.id == data.language_id) {
        aux2 = true;
      }
      sel_language.push(<option key={"leng_id-"+i} value={aux.id} selected={aux2} >{aux.language}</option>);
      aux2= false;
    }

/**
 * Tabla de datos con filtro
 */

var dataTable=null;
if(props.data[0]){ 
     dataTable = {
        columns: [

        {
            label: i18next.t('name'),
            name: 'name',
            sort: 'asc',
        //     width: 270
            },
          {
            label: i18next.t('creation'),
            name: 'creation',
            sort: 'asc',
         //   width: 270
          },
          {
            label: i18next.t('edition_option'),
            name: 'options',
            sort: 'asc',
            width: 670,
            options: {
                filter: false,
                sort: false
               }
          }],
          rows: []};

          const rows  = props.data.map((k, index) => {   
             
            const fin=[];
            fin.push(k.funnel_name); 
            fin.push(moment(k.created).format('DD MMM YYYY'));
            fin.push(
                <Row className="d-flex align-items-center justify-content-center">
                        <Col className="col-2"> 
                            <Link to={"/bot/"+props.botId+"/funnel/"+k.id} data-aiml="a" className="btn btn-square btn-link-eye"><i className="fa fa-eye"></i></Link>
                        </Col>
                        <Col className="col-2">
                            <Button type="submit" outline color="success" className="btn-square" onClick={(e)=>{props.toggleList(2,index)}}>
                            <i className="fa fa-edit "></i>
                            </Button>
                        </Col> 
                        <Col className="col-2">
                            <Button type="submit" outline color="danger" className="btn-square" onClick={(e)=>{props.deleteFunnel(index)}}>
                                <i className="fa fa-trash"></i>
                            </Button>
                        </Col> 
                    </Row>
            )
            dataTable.rows.push(fin); 
            return k;
        });

    }
   
    const options = {
        filterType: 'multiselect',
        selectableRows:'none',
        resizableColumns: false,
        responsive: "scrollMaxHeight",
        rowsPerPage:10 ,
        print:false,
        download:true,
        viewColumns:false,
      };


/**Tabla de datos anterior */
  /*  var dataTable=null;
    if(props.data[0]){
        dataTable = props.data.map((k, index) => {            
            return <tr key={index}>
                <td>
                    <p> {k.funnel_name}</p>
                </td>
                <td></td>
                <td>{moment(k.created).format('DD MMM YYYY')}</td>
                <td>
                    <Row className="d-flex align-items-center justify-content-center">
                        <Col className="col-2"> 
                            <Link to={"/bot/"+props.botId+"/funnel/"+k.id} data-aiml="a" className="btn btn-square btn-link-eye"><i className="fa fa-eye"></i></Link>
                        </Col>
                        <Col className="col-2">
                            <Button type="submit" outline color="success" className="btn-square" onClick={(e)=>{props.toggleList(2,index)}}>
                            <i className="fa fa-edit "></i>
                            </Button>
                        </Col> 
                        <Col className="col-2">
                            <Button type="submit" outline color="danger" className="btn-square" onClick={(e)=>{props.deleteFunnel(index)}}>
                                <i className="fa fa-trash"></i>
                            </Button>
                        </Col> 
                    </Row>
                </td>
            </tr>
        });
    }*/
    var title = ''
    const customStyles = {
        ///.....
        menuPortal: provided => ({ ...provided, zIndex: 9999 }),
        menu: provided => ({ ...provided, zIndex: 9999 })
        ///.....
      }
      
  return (
    <div className="BotLayout">
          <Admin>
            <SliceMenuApp>
                <BotMenuOptions botId={props.botId}  ia_activated={props.ia_activated}/>
            </SliceMenuApp>
            <Container fluid>
                <ToolbarApp>
                    <Row>
                        <Col sm="3" md="3" className="text pdtoolbar">
                        <h2 className="text-left ml-4 font-weight-bold">{i18next.t('funnels_list')} <span className="btn-help"></span></h2>                      
                        </Col>
                    </Row>
                </ToolbarApp>
            </Container>
            <Container fluid className="box-fluid">
                <Col sm="12" md="10" className="stadistics-container offset-md-1">
                    {
                        props.showList?(
                        <Row >
                            
                            <Col xs={12} lg={8} className="offset-lg-2 intro-funnels">
                                <Row>
                                    <Col xs={12}><img src="/static/img/embudos.png" className="img-fluid"/></Col>
                                    <Col xs={12}>
                                        <h3>{i18next.t('msg_conversion_actions')}</h3>
                                        <p>{i18next.t('funnel_msg_create')}</p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs="12" lg="8" className="offset-lg-2">
                                <Row className="filter-information">
                                    <Col xs={12} lg={12}>
                                            <Row>
                                                {/* <Col xs={12} lg={7}>
                                                    <Label>Buscador</Label>
                                                    <Input className="form-control" type="text" name="buscador" defaultValue={props.buscador} onChange={props.handleFind}></Input>
                                                </Col>
                                                <Col  xs={12} lg={2}>
                                                    <Button className="btn filter-bt btn-guarumo-md btn-block" onClick={(e)=>{props.tableData(1)}}>Buscar</Button>
                                                </Col> */}
                                                <Col  xs={12} lg={3}>
                                                    <Button className="btn filter-bt btn-guarumo-md btn-block" onClick={(e)=>props.toggleList(1)}>{i18next.t('funnel_create')}</Button>
                                                </Col>
                                            </Row>
                                    </Col>
                                </Row>
                            </Col>
                    
                            <Col xs={12} lg={10} className="offset-lg-1">
                           { props.data[0]?(
                          
                                <DataTableFilter
                                title={title}
                                data={ dataTable.rows}
                                columns={dataTable.columns}
                                options={options}
                                />
                            ):<div></div>}
                              { /*  <Table className="table-bordered table-informacion">
                                <thead>
                                    <tr>
                                        <th>Nombre</th>
                                        <th>{i18next.t('description')}</th>
                                        <th>{i18next.t('creation')}</th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {dataTable}
                                    </tbody>
                                </Table>
                            <div className="pagination-wrapper d-flex align-items-center justify-content-center">
                                <Pagination size="sm" aria-label="Page navigation funnel">
                                    <PaginationItem disabled={props.pagination.current <= 1}>
                                    <PaginationLink previous onClick={(e)=>{props.tableData(props.pagination.current-1)}} />
                                    </PaginationItem>
                                        { pagination_list}
                                    <PaginationItem disabled={props.pagination.current >= props.pagination.pages}>
                                    <PaginationLink next onClick={(e)=>{props.tableData(props.pagination.current+1)}} />
                                    </PaginationItem>
                                </Pagination>
                            </div> */}

                            </Col>
                        </Row>): ''}

                    {
                        !props.showList?(
                        <Form id="form_create_embudo" onSubmit={props.submitFunnel}>
                        <Row className="text-center">
                            <Col xs={12} lg={6} className="offset-lg-3">
                                <Row>
                                    <Col xs={12} lg={12} className="new-funnel-form">
                                        <h3>{props.current.id ?i18next.t('edit'):i18next.t('create')} {i18next.t('funnel')}</h3>
                                        <Input type="hidden" name="id" defaultValue={props.current.id || ''} />
                                        <FormGroup>
                                            <Label className="label-funnel">{i18next.t('name')}</Label>
                                            <Input type="text" className="input_name" name="name" defaultValue={props.current.name} placeholder={i18next.t('funnel_name') + "..."} required/>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label className="label-funnel">{i18next.t('events')}</Label>
                                            <Select isMulti 
                                            name="events"
                                            onKeyDown={(event) => { props.updateEvents(event) }}
                                            onChange={(event) => { props.updateEvents(event) }}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            // components={makeAnimated()}
                                            defaultValue={defaultOptions}
                                            options={optionsA}
                                            placeholder={i18next.t('events')} required
                                            menuPortalTarget={document.body}
                                            menuPosition={'fixed'} 
                                            styles={customStyles}
                                            />
                                        </FormGroup>
                                        <Row className="text-center">
                                            <Col  xs={6} lg={3} className="offset-lg-3">
                                                <br></br>
                                                <Button className="btn filter-btn btn-block btn-danger" onClick={(e)=>props.toggleList(1)}>{i18next.t('cancel')}</Button>
                                            </Col>
                                            <Col  xs={6} lg={3}>
                                                <br></br>
                                                <Button type="submit" className="btn btn-guarumo-md btn-block" > {props.current.id?i18next.t('save'):i18next.t('create')}</Button>  
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                
                            </Col>


                            
                        </Row>
                        </Form>
                        ): ''}
                </Col>
            </Container>
          </Admin>      
    </div>
  )
}

export default BotFunnelsLayout