import React, { Component } from "react";
import AdminLayout from "../components/admin-layout";
import ModalUser from "../components/modal-user";
import { connect } from 'react-redux';
import { logOut, } from "../../../redux/actions/loginActions";
import { withRouter } from "react-router-dom";
import { getAuthorization } from "../../../utils/commonFunction";
import i18next from '../../../../i18n';

class Admin extends Component {
  state = {
    isOpen: false,
    collapseDrop: false,
    user: {}
  };

  constructor(props) {
    super(props);
    this.cerrarSesion = this.cerrarSesion.bind(this);
  }

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("UserInfo"));
    this.setState({user});
  }


  toggle = () => this.setState({isOpen: !this.state.isOpen});
  toggleModalNewUser = () => this.setState({modalUser: !this.state.modalUser});
 
  cerrarSesion = async () => {
    const { logOut } = this.props;

    const { loginReducer: { otp }  } = this.props;

    await logOut();
    const { history,loginReducer: { isLogin= false} } = this.props;
    if(!isLogin){
      if(getAuthorization()){
        await localStorage.removeItem('Authorization');
      }
      if(history) history.push('/login');
    }
  }

  
  render() {
    return (
      <AdminLayout
        toggle={this.toggle}
        isOpen={this.state.isOpen}
        toggleModalNewUser={this.toggleModalNewUser}
        logOut={this.cerrarSesion}
        user={this.state.user}>
        {this.props.children}
        <ModalUser
          toggleModal={this.toggleModalNewUser}
          isOpen={this.state.modalUser}
          title={i18next.t('edit_user')}
          name={""}/>
      </AdminLayout>
    );
  }
}

const mapStateToProps = ({loginReducer,}) => {
	return {loginReducer,};
};

const mapDispatchToProps = {
  logOut,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Admin));

