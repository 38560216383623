// Actions
export const HOME_ACTIONS_TYPES = {
    successGetTotalBots: () => 'SUCCESS_GET_TOTAL_BOTS',

    notFoundGetListBots: () => 'NOT_FOUND_GET_LIST_BOTS',
    requestGetListBots: () => 'REQUEST_GET_LIST_BOTS',
    successGetListBots: () => 'SUCCESS_GET_LIST_BOTS',
    errorGetListBots: () => 'ERROR_GET_LIST_BOTS',
    changePageActive: () => 'CHANGE_PAGE_ACTIVE',
    handleSearching: () => 'HANDLE_SEARCHING',
    handleClearSearcher: () => 'HANDLE_CLEAR_SEARCHER',
    
    requestGetListPlafforms: () => 'REQUEST_GET_LIST_PLAFFORMS',
    successGetListPlafforms: () => 'SUCCESS_GET_LIST_PLAFFORMS',
    errorGetListPlafforms: () => 'ERROR_GET_LIST_PLAFFORMS',

    requestGetListCompanies: () => 'REQUEST_GET_LIST_COMPANIES',
    successGetListCompanies: () => 'SUCCESS_GET_LIST_COMPANIES',
    errorGetListCompanies: () => 'ERROR_GET_LIST_COMPANIES',

    toggleModalNewBot: () => 'TOGGLE_MODAL_NEW_BOT',
    errorDeleteBot: () => 'ERROR_DELETE_BOT',


};
  