import { NodeModel } from "storm-react-diagrams";
import SMPortModel from "../simplePortModel/SMPortModel";
import i18next from '../../../../../i18n';

class SQRNodeModel extends NodeModel {
	extras = {
		ia_id:0,
		text: i18next.t('without_text'),
		title: i18next.t('scanner_code'),
		botmsg_id:"",
		botmsg_text:"",
		botmsg_name:"",
		type:"",
		home_element: 'set-home',
		width: 200,
		dbId: 0,
		keyword: "",
		var_name:"",
		statements_id: [{}],
		config:{
			item_type: "qrcode",
		}
	}
	removeBox = null
	changeHome = null
	toggleEdit = null
	customClone = null
	
	constructor() {
		super("scanqr");
		this.addPort(new SMPortModel(false, "left", "scanqr"));
		this.addPort(new SMPortModel(true, "right", "scanqr"));

	}
}

export default SQRNodeModel