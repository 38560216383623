  import React from 'react';
  import BarData from './d3-examples/barChart';
  import BarChart from './d3Components/BarChart';
 
  
  class convertBarChart extends React.Component {
    constructor(props) {
      super(props);
      this.state = {d3: ''} 
    }
  
    componentDidMount() {
      this.setState({d3: BarData});
      
    }
  
    render() {
      
      return(      
        <div>
          <BarChart nameDiv={this.props.nameDiv} data={this.props.data} group={this.props.group} botID={this.props.botID} dateini={this.props.dateini} datefin={this.props.datefin} color={this.props.color} typeScaleColor={this.props.typeScaleColor} title={this.props.title}  xAxisName={this.props.xAxisName}  yAxisName={this.props.yAxisName} description={this.props.description}/>
        </div>
          
      )
    }
  }
  export default convertBarChart;