var d3 = require('d3');
import  '../../../infoViz/common/virtualscroller.js'
import { find, propEq, sort, descend, prop } from "ramda";



var dateFormat = require('dateformat');



const createLineChart = (nameDiv,datos,formatoD3,group,colorChosen,typeScaleColor,callBackLineChart,title,xAxisName,yAxisName,description,granularity) => {

  let color;
  
  let xScaleNew=null;

//let granularity = document.getElementById('agrupacionSelect');
//granularity = granularity.options[granularity.selectedIndex].value

  const node = document.createElement('div');
  //node.className = "svg-container";
  node.id="chart_"+nameDiv;
  

 
  let div='#'+nameDiv;
  let chart=document.getElementById('content-'+nameDiv);

  const WIDTH = chart.offsetWidth;
  const HEIGHT = chart.offsetHeight;
  const margin = { top: 70, right: 30, bottom: 10, left: 30 };

  node.style.width=WIDTH;
  node.style.height=HEIGHT;
   // set the dimensions and margins of the graph
   let  width =  WIDTH - margin.left - margin.right,
   height = height = HEIGHT - margin.top - margin.bottom;

  /*
  
  var width = 500;
  var height = 200;
  var margin = 60;
  */
  let duration = 250;
  
  let lineOpacity = "0.5";
  let lineOpacityHover = "0.85";
  let otherLinesOpacityHover = "0.1";
  let lineStroke = "1.5px";
  let lineStrokeHover = "2.5px";
  
  let circleOpacity = '0.85';
  let circleOpacityOnLineHover = "0.25"
  let circleRadius = 3;
  let circleRadiusHover = 6;
  
  
  /* Format Data */
  let parseDate = d3.timeParse("%Y");

// set initial dateini value
//let group = "All";

  let ds = [];

  /*datos.datasetLineChart.forEach(function(d) { 
    d.values.forEach(function(d) {
      d.dateini = parseDate(d.dateini);
      d.measure = +d.measure;    
    });
  });*/

  let locale = d3.timeFormatLocale({
    "dateTime": "%A, %e de %B de %Y, %X",
	"date": "%d/%m/%Y",
	"time": "%H:%M:%S",
	"periods": ["AM", "PM"],
	"days": ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"],
	"shortDays": ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
	"months": ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"],
	"shortMonths": ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"]
  });
  
  let formatMillisecond = locale.format(".%L"),
      formatSecond = locale.format(":%S"),
      formatMinute = locale.format("%I:%M"),
      formatHour = locale.format("%I %p"),
      formatDay = locale.format("%a %d"),
      formatWeek = locale.format("%b %d"),
      formatMonth = locale.format("%B"),
      formatYear = locale.format("%Y");
      
  function multiFormat(date) {
    return (d3.timeSecond(date) < date ? formatMillisecond
        : d3.timeMinute(date) < date ? formatSecond
        : d3.timeHour(date) < date ? formatMinute
        : d3.timeDay(date) < date ? formatHour
        : d3.timeMonth(date) < date ? (d3.timeWeek(date) < date ? formatDay : formatWeek)
        : d3.timeYear(date) < date ? formatMonth
        : formatYear)(date);
  }

  function formatDate(date)
  {
    date  = date.toUTCString();
    if(granularity=='year')
    {
      date = dateFormat(date, "yyyy");
    }
    else if (granularity=='month')
    {
      date = dateFormat(date, "yyyy-mm");
    }
    else if ((granularity=='week')||(granularity=='day'))
    {
      date = dateFormat(date, "yyyy-mm-dd");
    }
    else if (granularity=='hour')
    {
      date = dateFormat(date, "yyyy-mm-dd hh tt");
    }
    else
    {
      date = dateFormat(date, "yyyy-mm-dd hh:mm:ss.l tt");
    }
    return date;
  }

  function datasetLineChartChosen(group) {
	let ds = [];
	
	for (let x in datos.datasetLineChart) {
        let d = [];
      if(group=='All')
      {
        ds.push({values:datos.datasetLineChart[x].values,name:datos.datasetLineChart[x].name});
      }
      else
      {
          for (let y in datos.datasetLineChart[x].values) {
            
            if(datos.datasetLineChart[x].values[y].group==group){
              d.push(datos.datasetLineChart[x].values[y]);
            } 
          }
            if(d.length!=0)
              ds.push({values:d,name:datos.datasetLineChart[x].name});
      }
    }


	return ds;
}

datos.datasetLineChart_new = datasetLineChartChosen(group);   


const izquierda = document.createElement('div');
  //node.className = "svg-container";
  izquierda.id="izquierda_"+nameDiv;
  izquierda.className = "izquierda";

  izquierda.style.position='absolute'
  izquierda.style.top = '35px';
  izquierda.style.left='10px';
  izquierda.style.width='75%';
  izquierda.style.height='85%';
  izquierda.style.backgroundColor ='white';

  node.appendChild(izquierda);

  const derecha = document.createElement('div');
  //node.className = "svg-container";
  derecha.id="derecha_"+nameDiv;
  derecha.className = "derecha";

  derecha.style.position='absolute'
  derecha.style.top = '35px';
  derecha.style.left='78%';
  derecha.style.width='20%';
  derecha.style.height='80%';
  derecha.style.backgroundColor ='blue';

  node.appendChild(derecha);

  const arriba = document.createElement('div');
  //node.className = "svg-container";
  arriba.id="arriba_"+nameDiv;
  arriba.className = "arriba";

  arriba.style.position='absolute'
  arriba.style.top = '0px';
  arriba.style.left='0px';
  arriba.style.width='100%';
  arriba.style.height='30px';
  arriba.style.backgroundColor ='white';
  arriba.style.paddingTop ='5px';
  arriba.style.paddingLeft ='10px';

    // ===========================================================================================
    //    Creates Title
    // ===========================================================================================
  
  node.appendChild(arriba);

  let titleChart= d3.select(arriba)
    .append("text")
    .attr("class", "chartTitle")
    .style("font-size", '1.5rem')
    .style("color", 'gray')
    .style("font-weight", 'bold')
    .text(title);


		

const chartComponentSelection = d3.select("#chart_"+nameDiv);
chartComponentSelection.attr(
    "style",
    "padding-bottom: " +
      Math.ceil(
         (height + margin.top + margin.bottom) *
           100 /
           (width + margin.left + margin.right)
      ) +
      "%"
  );

  
  
  /* Scale */
  /*let xScale = d3.scaleTime()
    .domain(d3.extent(datos.datasetLineChart_new[0].values, d => d.dateini))
    .range([0, width]);*/

    let dates=[];

    datos.datasetLineChart_new.forEach(plataforma=>{
      plataforma.values.forEach(bot => {
        let d={date_ini:bot.dateini}
        //bot.dateini=parseDate(bot.dateini);
        dates.push(d);
      }); 
    })

    // {
    //   "dateTime": "%A, %e de %B de %Y, %X",
    //   "date": "%d/%m/%Y",
    //   "time": "%H:%M:%S",
    //   "periods": ["AM", "PM"],
    //   "days": ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"],
    //   "shortDays": ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
    //   "months": ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"],
    //   "shortMonths": ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"]
    // }
    let xScale = d3.scaleTime()
    .domain(d3.extent(dates, d=>d.date_ini))
    .range([0, width]);

    

    let rExtent = d3.extent(dates, function(d) { return circleRadius; });
  
    let rScale = d3.scaleLinear()
      .domain([0, circleRadius])
      .range([3, height/10 * 0.2 ]);


    // if(xScaleNew==null)
    //   xScaleNew=xScale;
  
    let numbers=[];
    datos.datasetLineChart_new.forEach(plataforma=>{
      plataforma.values.forEach(bot => {
        let d={measure:Number(bot.measure)}
        numbers.push(d);
      }); 
    })

  let yScale = d3.scaleLinear()
    .domain([0, d3.max(numbers, d => d.measure)])
    .range([height, 0]);

    let arrayScale = [];
    arrayScale[nameDiv] = {"xS":xScale,"yS":yScale};


    if(typeScaleColor=="unicolor")
      color=d3.scaleLinear().domain([1,10]).range([colorChosen,"black"]);
    else if(typeScaleColor=="Categorica")
    {
      var colorChosen="d3."+colorChosen;
       color = d3.scaleOrdinal(eval(colorChosen));
    }
    else if(typeScaleColor=="predeterminado")
        {
            color = d3.scaleOrdinal().domain(datos.datasetLineChart).range(eval(colorChosen));
           
           // color = d3.scaleOrdinal().domain(datos.dataSet).range(["rgba(46,91,255,1)", "rgba(140,84,255,1)", "rgba(0,193,212,1)", "rgba(250,208,80,1)", "rgba(195,34,168,1)"]);       
        }
    else 
    {
      var colorChosen="d3."+colorChosen+"(t * 0.85)";
      const scale = t => eval(colorChosen);
      color = (d) => {
        let c= d3.range(5).map(i => scale(i / 5))
        return c[d];
        };
      //  color = d3.scaleSequential(eval(colorChosen));
    }

  
    // create a tooltip

    let Tooltip = d3.select("#tooltip_"+nameDiv)//.select("svg")
    .style("opacity", 0)
    .attr("class", "tooltipLine")
    .attr("name_div", function(d){ return ""+nameDiv+"" })
    .style("background-color", "white")
    .style("opacity","0")
    .style("border", "solid")
    .style("border-width", "0.5px")
    .style("border-radius", "5px")
    .style("padding", "5px")
    .style("z-index","5000")
    

    let datosSVG = []
    datosSVG[nameDiv] = datos.datasetLineChart_new



  /* Add SVG */
  let svg = d3.select(izquierda).append("svg")
  .attr("id","svg_"+nameDiv)
  .attr("name_div", function(d){ return ""+nameDiv+"" })
  .attr("preserveAspectRatio", "xMinYMin meet")
  .attr(
      "viewBox",
      "0 0 "
        .concat(width + margin.left + margin.right)
        .concat(" ")
        .concat(height + margin.top + margin.bottom)
    )
  .classed("svg-content", true)
  // .call(d3.zoom().on("zoom", function () {
  //   svg.attr("transform", d3.event.transform)
  // }))
  .call(d3.zoom().on("zoom", function () {
    g.attr("transform", d3.event.transform)
}))


  const g = svg.append("g")
  .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
  .attr("id","svg_g_"+nameDiv)
  .attr("name_div", function(d){ return ""+nameDiv+"" })
    
    
    /* Add Axis into SVG */
  let xAxis = d3.axisBottom(xScale)
  .ticks(10)
  .tickSize(-(height))
  .tickSizeOuter(0)
  .tickFormat(multiFormat);

let yAxis = d3.axisLeft(yScale)
  .ticks(5)
  .tickSize(-(width))
  .tickSizeOuter(0);

// add the X and Y gridlines
let grid=   g.append("g")			
//.call(zoom)
.attr("class", "grid")
.attr("id","grid_"+nameDiv)
.attr("name_div", function(d){ return ""+nameDiv+"" })

const xAxisDraw = grid.append("g")
.attr("id","xaxis_"+nameDiv)
.attr("name_div", function(d){ return ""+nameDiv+"" })
.attr("className", "x axis")
.attr("transform", `translate(0, ${height})`)
.call(xAxis);

const yAxisDraw = grid.append("g")
.attr("className", "y axis")
.attr("id","yaxis_"+nameDiv)
.attr("name_div", function(d){ return ""+nameDiv+"" })
.call(yAxis)
.append('text')
.attr("y", 15)
.attr("transform", "rotate(-90)")
.attr("fill", "#000")
.text(" "+yAxisName);




let focus = g.append('g')
.attr('id',"tooltiplinefocus_"+nameDiv)
.attr("name_div", function(d){ return ""+nameDiv+"" })
.attr('class', 'focus')
.style('opacity', '0');


let tooltipLine = focus.append('line')
                        .attr('id',"tooltipline_"+nameDiv)
                        .attr("name_div", function(d){ return ""+nameDiv+"" })
                        .attr('class', 'x-hover-line hover-line')
                        .attr('y1' , 0)
                        .attr('y2', height);

/* Add line into SVG */
let line = d3.line()
.x(d => xScale(d.dateini))
.y(d => yScale(d.measure));

let lines = g.append('g')
.attr('class', 'lines')
.attr("id","lines_"+nameDiv)
.attr("name_div", function(d){ return ""+nameDiv+"" })


lines.selectAll('.line')
.data(datos.datasetLineChart_new).enter()
.append('path')
.attr('class', 'line')  
.attr('classe', 'line-group_'+nameDiv) 
.attr('d', d => line(d.values))
//.attr('id',"pathLine_"+nameDiv)
.attr("bot_id", function(d){ return ""+d.name+"" })
.attr("color", function(d,i){ return color(i) })
.attr("index",function(d,i){ return i })
.attr("id", function(d,i){ return "line_"+nameDiv+"_"+i})//return d.name.replace(/\s/g,"_") })
.attr("name_div", function(d){ return ""+nameDiv+"" })
.style('stroke', (d, i) => color(i))
.style('opacity', lineOpacity)
.on("mouseover", function(d) {
    
  nameDiv =this.getAttribute("name_div")
  let i=this.getAttribute("index");
  let name = this.getAttribute("bot_id") 
  let colorLine = this.getAttribute("color") 
  let clase = this.getAttribute("classe")

  let currentOpacity = this.style.opacity;

  if(currentOpacity==0)
  {
    console.log("Oculto");
  }
  else if(clase=='line-group_'+nameDiv){

    d3.select(this)
    .style('opacity', lineOpacityHover)
    .style("stroke-width", lineStrokeHover)
    .style("cursor", "pointer");
 

    
  /*d3.select("#svg_g_"+nameDiv)//g
  .append("text")
    .attr("class", "title-text")
    .style("fill", colorLine)        
    .text(name)
    .attr("text-anchor", "middle")
    .attr("x", (width-margin.right)/2)
    .attr("y", 15);*/

    Tooltip = d3.select("#tooltip_"+nameDiv);

    Tooltip
    .style("opacity", 0.8)

    focus = d3.select("#tooltiplinefocus_"+nameDiv)
    focus.style("opacity", 0)
  }

   
})
.on("mouseleave",function(d){

  nameDiv =this.getAttribute("name_div")
  let clase = this.getAttribute("classe")
  let currentOpacity = this.style.opacity;

  if(currentOpacity==0)
  {
    console.log("Oculto");
  }else
  if(clase=='line-group_'+nameDiv){

  Tooltip = d3.select("#tooltip_"+nameDiv)

  Tooltip
    .style("opacity", 0)

    focus = d3.select("#tooltiplinefocus_"+nameDiv)
    focus.style("opacity", 0)
  }
})
.on("mouseout", function(d) {
    /*let atributo = this.parentNode.getAttribute("selected"); 
    if(atributo!="true")
    {
       /* d3.selectAll(".line")
          .style('opacity', lineOpacity);
          d3.selectAll('.circle')
          .style('opacity', circleOpacity);* /
        d3.select(this)
        .style('opacity', lineOpacity)
        .style("stroke-width", lineStroke)
        .style("cursor", "none");
    }*/

    nameDiv =this.getAttribute("name_div")
    let clase = this.getAttribute("classe")
    let currentOpacity = this.style.opacity;

    if(currentOpacity==0)
    {
      console.log("Oculto");
    }
    else if(clase=='line-group_'+nameDiv){

    d3.select("#svg_g_"+nameDiv)//g
    .select(".title-text").remove();
    
    d3.select(this)
    .style('opacity', lineOpacity)
    .style("stroke-width", lineStroke)
    .style("cursor", "none");

    }   
    
})
.on("mousemove",  function(d) {


  nameDiv =this.getAttribute("name_div")
  let i=this.getAttribute("index");
  let name = this.getAttribute("bot_id") 
  let colorLine = this.getAttribute("color") 
  let clase = this.getAttribute("classe")

  let currentOpacity = this.style.opacity;

  if(currentOpacity==0)
  {
    console.log("Oculto");
  }
  else if(clase=='line-group_'+nameDiv){

    let texto= "<table>";
  texto=texto+
  "<tr>"+
  "<td style='padding:3px; 5px; bgcolor:"+colorLine+"'><i class='fa fa-circle' style='color:"+colorLine+";'></i></td>"+
  //"<td style='padding:3px; 5px; font-weight: bold;'>"+title+"</td>"+
  "<td style='padding:3px; 5px; font-weight: bold; color:"+colorLine+";'>"+name+"</td>"+
  "</tr>"+
  "</table>";

 // texto=title//"Sesiones por bot<br>Plataforma: "+group+"<br>Bot: "+d.name
  Tooltip = d3.select("#tooltip_"+nameDiv)

  let left = d3.event.pageX - document.getElementById("svg_"+nameDiv).getBoundingClientRect().x + 35
  let top = d3.event.pageY - document.getElementById("svg_"+nameDiv).getBoundingClientRect().y + 40

  let x = d3.event.pageX - 50;
  let y = d3.event.pageY - 300 ;

//console.log(x,y,left,top);

  let tooltipWidth =  parseInt(Tooltip.style.width, 10);
  let tooltipHeight =  parseInt(Tooltip.style.height, 10);

  let screenWidth = width;
  let screenHeight = height;

  if ((left + tooltipWidth) > screenWidth) {
    left = screenWidth - tooltipWidth - 100;
  }
  if((top + tooltipHeight) > screenHeight) {
    top = screenHeight - tooltipHeight ;
  }

  //alert(left+"-"+top+" - "+texto)
  //console.log(Tooltip)

  Tooltip
  .html(texto)
  .style("left", left + "px")
  .style("top", top+ "px")
  }

 
  

})
.on("click", function(d,i){
   /* let atributo = this.getAttribute("bot_id"); 
    //console.log(atributo);

    //unselected();

    d3.selectAll(".line-group")
   // .attr("selected",function(d){ return "false"});
    
    d3.select(this)
  //  .attr("selected","true")
    
    d3.selectAll('.line')
    .style('opacity', otherLinesOpacityHover)
   // .attr("selected",function(d){ return "false"});
    d3.selectAll('.circle')
    .style('opacity', circleOpacityOnLineHover);


    d3.select(this.children[0])
    .style('opacity', lineOpacityHover)
    .style("stroke-width", lineStrokeHover)
    .style("cursor", "pointer");

    Tooltip = d3.select("#tooltip_"+nameDiv)

    Tooltip
    .style("opacity", 1)
    

    callBackLineChart(group,atributo/*,d.dateIni,d.dateFin* /);*/
}
)


/* Add circles in the line */
lines.selectAll(".circle-group_"+nameDiv)
.data(datos.datasetLineChart_new).enter()
.append("g")
.attr("class", "circle-group_"+nameDiv)  
.attr("index",function(d,i){ return i })
.attr("id", function(d,i){ return "line_"+nameDiv+"_"+i})//d.name.replace(/\s/g,"_") })
.attr("name",function(d){ return d.name })
.attr("colorid",(d, i) =>  i)
.attr("name_div", function(d){ return ""+nameDiv+"" })
.style("stroke", (d, i) => color(i))
.style("fill",(d, i) => color(i))
.selectAll(".circle_"+nameDiv)
.data(d => d.values).enter()
.append("circle")
.attr("class","circle_"+nameDiv)
.attr("cx", d => xScale(d.dateini))
.attr("cy", d => yScale(d.measure))
.attr('r', function(d) { 
  d.scaledRadius = rScale(circleRadius); 
  return d.scaledRadius; 
})
.attr("name_div", function(d){ return ""+nameDiv+"" })
.attr("dateini", function(d){ return d.dateini })
//.attr("r", circleRadius)
.style('opacity', circleOpacity)
.on("mouseover", function(d) {

  const classe = this.getAttribute("class")
  nameDiv =this.getAttribute("name_div")


  let currentOpacity = d3.selectAll("path#"+this.parentNode.id)._groups[0][0].style.opacity;

  if(currentOpacity==0)
  {
    console.log("Oculto");
  }
  else
  if(classe=="circle_"+nameDiv){
    d3.select(this)
    .transition()
    .duration(duration)
    .attr("r", circleRadiusHover);

     Tooltip = d3.select("#tooltip_"+nameDiv)

     Tooltip
     .style("opacity", 0.8)

     focus = d3.select("#tooltiplinefocus_"+nameDiv)
     focus.style("opacity", 1)
  }

   
})
.on("mouseout", function(d) {
  const classe = this.getAttribute("class")
  nameDiv =this.getAttribute("name_div")

  if(classe=="circle_"+nameDiv){
    d3.select(this) 
    .transition()
    .duration(duration)
    .attr("r", circleRadius);  
  }
})
.on("mouseleave",function(d){
  const classe = this.getAttribute("class")
  nameDiv =this.getAttribute("name_div")

  if(classe=="circle_"+nameDiv){
  
     Tooltip = d3.select("#tooltip_"+nameDiv)
  
     Tooltip
       .style("opacity", 0)
  
      focus = d3.select("#tooltiplinefocus_"+nameDiv)
      focus.style("opacity", 0)
  }
  })
  .on("mousemove",  function(d) {
    const classe = this.getAttribute("class")
    nameDiv =this.getAttribute("name_div")
  
    if(classe=="circle_"+nameDiv){
       let dateIni = new Date(this.getAttribute("dateini"))

        if(arrayScale[nameDiv].xScaleNew)
            xScaleNew = arrayScale[nameDiv].xScaleNew;
    
       //let color = eval("("+this.getAttribute("funColor")+")")
    
    
       //let dateini  = d.dateini.getDate() + "-" + (d.dateini.getMonth() + 1) + "-" + d.dateini.getFullYear();
       let options = { weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric',hour:'numeric' ,minute:'numeric' };
    
       let datosFecha = []
       let datosTotales = []

       datosSVG[nameDiv].forEach(function(f,i) { 

        let currentOpacity = d3.selectAll("path#line_"+nameDiv+"_"+i)._groups[0][0].style.opacity;

        if(currentOpacity==0)
        {}
        else{
          // datos.datasetLineChart_new.forEach(function(f,i) { 
            datosFecha=find(propEq("dateini", dateIni))(f.values)
            if(datosFecha){
            if(datosFecha.length){}else{
              datosFecha.i=i;
              datosFecha.name=f.name;
              datosTotales.push(datosFecha);
            }}
        }
       });
    
       //let dateini  =  d.dateini.getFullYear();
    
    
       let texto= "";
       texto= "<table>";
       let dateini=dateIni.toLocaleDateString("es-ES",options)
    
       const diff = function(a, b) { return a.measure - b.measure; };
    
       datosTotales=sort(diff, datosTotales);
    
       //for(let j=0;j<datosTotales.length;j++)
       for(let j=datosTotales.length-1;j>=0;j--)
       {
         dateini=datosTotales[j].dateini.toLocaleDateString("es-ES",options)
    
         dateini = formatDate(dateIni);
    
         texto=texto+
         "<tr>"+
         "<td style='padding:3px; 5px; bgcolor:"+color(datosTotales[j].i)+"'><i class='fa fa-circle' style='color:"+color(datosTotales[j].i)+";'></i></td>"+
         "<td style='padding:3px; 5px; font-weight: bold;'>"+datosTotales[j].name+"</td>"+
         "<td style='padding:3px; 5px;'>"+dateini+"</td>"+
         "<td style='padding:3px; 5px; font-weight: bold; color:"+color(datosTotales[j].i)+";'>"+datosTotales[j].measure+"</td>"+
         "</tr>";
       }
      
       texto=texto+"</table>";
       //texto=""+title+"<br>Valor: "+this.parentNode.getAttribute("name")+"  ("+d.measure+" "+xAxisName+")<br>Fecha: "+dateini//"Sesiones por bot<br>Plataforma: "+group+"<br>Bot: "+this.parentNode.getAttribute("name")+"<br>Valor: "+d.measure+"<br>Fecha: "+dateini
      
    
       Tooltip = d3.select("#tooltip_"+nameDiv)
    
    
       //let left = d3.event.pageX - 50;
       let left = d3.event.pageX - document.getElementById("svg_"+nameDiv).getBoundingClientRect().x + 35
       let top =  margin.top ;
    
       let tooltipWidth =  parseInt(Tooltip.style.width, 10);
       let tooltipHeight =  parseInt(Tooltip.style.height, 10);
    
       let screenWidth = width;
       let screenHeight = height;
    
       if ((left + tooltipWidth) > screenWidth) {
         left = screenWidth - tooltipWidth - 100;
       }
       if((top + tooltipHeight) > screenHeight) {
         top = screenHeight - tooltipHeight ;
       }
      // tooltip.style.left = left + 'px';
      // tooltip.style.top = (d3.event.pageY + 20) + 'px';
    
    
       Tooltip
       .html(texto)
       .style("left", left + "px")
      // .style("top", (d3.event.pageY - height/2) + 'px')
      // .style("left", (d3.mouse(this)[0]+30) + "px")
       .style("top", top + "px")
       //.selectAll()
        // .data(datos.datasetLineChart_new).enter()
        // .append('div')
        // .style('color', (d, i) => color(i))
        // .html(d => d.name + ': ' + d.values.find(h => h.date_ini == dateini).measure);
    
       //focus.attr("transform", "translate(" + xScale(d.dateini) + ",0)");
    
       tooltipLine = d3.select("#tooltipline_"+nameDiv)  
    
       const x = this.getAttribute("cx")

      // if(xScaleNew){
         tooltipLine  
         .attr('stroke', 'black')
         .attr('x1', x)//xScaleNew(dateIni))
         .attr('x2', x)//xScaleNew(dateIni))
         .attr('y1', 0)
         .attr('y2', height);
      //  }
      //  else{
      //    tooltipLine  
      //    .attr('stroke', 'black')
      //    .attr('x1', xScale(dateIni))
      //    .attr('x2', xScale(dateIni))
      //    .attr('y1', 0)
      //    .attr('y2', height);
      // }
    }
     })




// Zoom specific updates
//************************************************************
let zoom = d3.zoom().on('zoom', zoomed);

//svg.call(zoom); //OJO SE DESHABILITA EL ZOOM


function zoomed() {
  
  nameDiv =this.getAttribute("name_div")
  
  if(d3.event){
    let transform = d3.event.transform;

    //transform.x = Math.min(0, transform.x);
    transform.y = 0;

    //d3.selectAll(div).select("svg").select("g").select("#lines_"+nameDiv)
    //.attr("transform", "translate(" + transform.x+","+ transform.y+ ")"); //scale(" + d3.event.transform.k + ")
    
    /*d3.selectAll('.line').style("stroke-width", 2/d3.event.transform.k);

    lineStroke = 1.5 + (d3.event.transform.k);
    lineStrokeHover = 2.5;//d3.event.transform.k;
*/
    //circleRadius = 4/d3.event.transform.k;
    //circleRadiusHover =5/d3.event.transform.k;

    
    yScale = arrayScale[nameDiv].yS;
    xScale = arrayScale[nameDiv].xS;

    xScaleNew = transform.rescaleX(xScale);

    arrayScale[nameDiv].xScaleNew = xScaleNew;

   // xScaleNew = transform.rescaleX(arrayScale[nameDiv].xs);
    

    d3.selectAll("#"+nameDiv).select("svg").select("g").select("#xaxis_"+nameDiv)
    .call(xAxis.scale(xScaleNew));

    //d3.selectAll(div).select("svg").select("g").select("#yaxis_"+nameDiv)
    //.call(yAxis.scale(d3.event.transform.rescaleY(yScale)));

    let c = d3.selectAll(".circle-group_"+nameDiv)
    .selectAll("circle")
    //.attr('r', circleRadius)
    .attr('cx', function(d) { return xScaleNew(d.dateini) })
  


   

    /* Add line into SVG */
    let lineNew = d3.line()
    .x(d => xScaleNew(d.dateini))
    .y(d => yScale(d.measure));

    let l = d3.selectAll("#lines_"+nameDiv) 
    .selectAll(".line")
    .attr('d',function(d) { 
      //console.log(this,d)
      return lineNew(d.values) 
    })// d => lineNew(d.values))

   // console.log(l);

  /*  //let l = d3.selectAll(div).select("svg").select("g").select("#lines_"+nameDiv).selectAll("#pathLine_"+nameDiv)
    let l = d3.selectAll("#lines_"+nameDiv)
    .data(datosSVG[nameDiv])
    

    //l.exit().remove()//remove unneeded circles
    //.enter().append("path")

    .selectAll("path")
    //l.transition()
    //.duration(500)
    .attr('d', d => lineNew(d.values))
    .style("stroke-width", lineStroke)*/
 
	}
	}




//------------------------------------------
// Add a legend at the end of each line
//----------------------------------------
/*svg
.selectAll(".myLabels")
.data(datos.datasetLineChart_new)
.enter()
.append('g')
.attr("class","myLabels")
.append("text")
.datum(function(d) { return {name: d.name, value: d.values[d.values.length - 1]}; }) // keep only the last value of each time sery
.attr("transform", function(d) { return "translate(" + xScale(d.value.dateini) + "," + yScale(d.value.measure) + ")"; }) // Put the text at the position of the last point
.attr("x", 12) // shift the text a bit more right
.text(function(d) { return d.name; })
.attr("index",function(d,i){ return i })
.attr("id", function(d,i){ return "line_"+nameDiv+"_"+i})//d.name.replace(/\s/g,"_") })
.style("fill", (d, i) => color(i))
.style("font-size", 10);*/
//-----------------------------------------------------------------------------------

//--------------------------------------------
// Leyenda sin scroll
//_____________________________________________
/*let total=datos.datasetLineChart_new.length
// Add a legend (interactive)
// Add one dot in the legend for each name.
svg.selectAll(".dots-group")
.data(datos.datasetLineChart_new)
.enter()
.append("g")
.attr("class", "dots-group")  
.append("circle")
// .attr("transform", function(d,i) { return "translate(" + 300  + ", -50)"; }) // Put the text at the position of the last point
.attr("cx", width-60)//function(d,i){ return ((width-2*(i*60)) + i*120)-(i+1)*60})
.attr("cy", function(d,i){ return 0 + i*25})///-25) // 100 is where the first dot appears. 25 is the distance between dots
.attr("r", 7)
.style("fill", function (d, i) {
return color(i);
})
.style("stroke", "#fff")
.on("click", function(d){
// is the element currently visible ?
let currentOpacity = d3.selectAll("#" + d.name.replace(/\s/g,"_")).style("opacity")
// Change the opacity: from 0 to 1 or from 1 to 0
d3.selectAll("#" + d.name.replace(/\s/g,"_")).transition().style("opacity", currentOpacity == 1 ? 0:1)

let currentFillColor=d3.select(this).style('fill');
let currentStrokeColor=d3.select(this).style('stroke');

d3.select(this) 
.style("stroke", currentFillColor)
.style("fill", currentStrokeColor)

});


svg
.selectAll(".myLegend")
.data(datos.datasetLineChart_new)
.enter()
.append('g')
.attr("class","myLegend")
.append("text")
//.attr('x', function(d,i){ return 10+((width-2*(i*60)) + i*120)-(i+1)*60})
//.attr('y', -20)
.attr('x', width-40)
.attr('y', function(d,i){ return 0 + i*25})
.text(function(d) { return d.name; })
.style("fill", (d, i) => color(i))
.style("font-size", 15)
.on("click", function(d){
callBackLineChart(group,d.name/*,d.dateIni,d.dateFin* /);
}
)*/
 
//---------------------------------------------------------
//Leyenda con scroll
//---------------------------------------------------------

 let viewport = d3.select(derecha).append("div")
 .attr("class","viewport2")
 .attr("name_div", function(d){ return ""+nameDiv+"" })
 .style("height","100%")
 .style("width","100%")

 //let nodeT=createLegend(viewport,color);

  //------------------------------------------------------
  //  LEYENDA
  //-----------------------------------------------------


 let colorScale = color;

 let scrollSVG = viewport.append("svg")
     .attr("name_div", function(d){ return ""+nameDiv+"" })
     .attr("class", "scroll-svg");

 let defs = scrollSVG.insert("defs", ":first-child");

 createFilters(defs);

 let chartGroup = scrollSVG.append("g")
     .attr("class", "chartGroup_"+nameDiv)
     .attr("name_div", function(d){ return ""+nameDiv+"" })
     //.attr("filter", "url(#dropShadow1)"); // sometimes causes issues in chrome

 chartGroup.append("rect")
     .attr("fill", "#FFFFFF");
     

 let rowEnter = function(rowSelection) {
     /*rowSelection.append("rect")
         .attr("rx", 3)
         .attr("ry", 3)
         .attr("width", "250")
         .attr("height", "24")
         .attr("fill-opacity", 0.25)
         .attr("stroke", "#999999")
         .attr("stroke-width", "2px");
     rowSelection.append("text")
         .attr("transform", "translate(10,15)");*/
        
         rowSelection
        .append("g")
        .attr("class", "dots-group")  
        .attr("name_div", function(d){ return ""+nameDiv+"" })
        .append("circle")
        // .attr("transform", function(d,i) { return "translate(" + 300  + ", -50)"; }) // Put the text at the position of the last point
        .attr("cx", 25)//function(d,i){ return ((width-2*(i*60)) + i*120)-(i+1)*60})
        .attr("cy", function(d,i){ return 12 })///-25) // 100 is where the first dot appears. 25 is the distance between dots
        .attr("r", 7)
        .attr("transform", "translate(0,10)")
        .attr("name_div", function(d){ return ""+nameDiv+"" })
        .attr("index", function(d,i){ return i })
        .style("fill", function (d, i) {
        return color(i);
        })
        .style("stroke", "#fff")
        .on("click", function(d){
        // is the element currently visible ?
        nameDiv =this.getAttribute("name_div");
        const index =this.getAttribute("index");

        let currentOpacity = d3.selectAll("#line_"+nameDiv+"_"+index).style("opacity")
        // Change the opacity: from 0 to 1 or from 1 to 0
        d3.selectAll("#line_"+nameDiv+"_"+index).transition().style("opacity", currentOpacity == 0.5 ? 0:0.5)

        let currentFillColor=d3.select(this).style('fill');
        let currentStrokeColor=d3.select(this).style('stroke');

        d3.select(this) 
        .style("stroke", currentFillColor)
        .style("fill", currentStrokeColor)

        });

rowSelection.append("text")
         .attr("transform", "translate(40,25)");
       
 };
 let rowUpdate = function(rowSelection) {
     rowSelection.select("rect")
         .attr("fill", function(d) {
             return colorScale(d.id);
         });
     rowSelection.select("text")
         .text(function (d) {
             return d.name;
         });
 };

 let rowExit = function(rowSelection) {
 };

 let v1=d3.select(".viewport1");
 
 let virtualScroller = d3.VirtualScroller()
     .rowHeight(30)
     .enter(rowEnter)
     .update(rowUpdate)
     .exit(rowExit)
     .svg(scrollSVG)
     .totalRows(datos.datasetLineChart_new.length)
     .viewport(viewport);//d3.select(".viewport1"));

 // tack on index to each data item for easy to read display
 
 datos.datasetLineChart_new.forEach(function(nextState, i) {
     nextState.index = i;
     nextState.id = i;
 });
//  states.items.forEach(function(nextState, i) {
//   nextState.index = i;
// });


 virtualScroller.data(datos.datasetLineChart_new, function(d) { 
   return d.id; 
 });

 chartGroup.call(virtualScroller);

 function createFilters(svgDefs) {
     let filter = svgDefs.append("svg:filter")
         .attr("id", "dropShadow1")
         .attr("x", "0")
         .attr("y", "0")
         .attr("width", "200%")
         .attr("height", "200%");

     filter.append("svg:feOffset")
         .attr("result", "offOut")
         .attr("in", "SourceAlpha")
         .attr("dx", "1")
         .attr("dy", "1");

     filter.append("svg:feColorMatrix")
         .attr("result", "matrixOut")
         .attr("in", "offOut")
         .attr("type", "matrix")
         .attr("values", "0.1 0 0 0 0 0 0.1 0 0 0 0 0 0.1 0 0 0 0 0 0.2 0");

     filter.append("svg:feGaussianBlur")
         .attr("result", "blurOut")
         .attr("in", "matrixOut")
         .attr("stdDeviation", "1");

     filter.append("svg:feBlend")
         .attr("in", "SourceGraphic")
         .attr("in2", "blurOut")
         .attr("mode", "normal");
 }

 function makeCurlyBrace(x1,y1,x2,y2,w,q)
 {
     //Calculate unit vector
     let dx = x1-x2;
     let dy = y1-y2;
     let len = Math.sqrt(dx*dx + dy*dy);
     dx = dx / len;
     dy = dy / len;

     //Calculate Control Points of path,
     let qx1 = x1 + q*w*dy;
     let qy1 = y1 - q*w*dx;
     let qx2 = (x1 - .25*len*dx) + (1-q)*w*dy;
     let qy2 = (y1 - .25*len*dy) - (1-q)*w*dx;
     let tx1 = (x1 -  .5*len*dx) + w*dy;
     let ty1 = (y1 -  .5*len*dy) - w*dx;
     let qx3 = x2 + q*w*dy;
     let qy3 = y2 - q*w*dx;
     let qx4 = (x1 - .75*len*dx) + (1-q)*w*dy;
     let qy4 = (y1 - .75*len*dy) - (1-q)*w*dx;

     return ( "M " +  x1 + " " +  y1 +
         " Q " + qx1 + " " + qy1 + " " + qx2 + " " + qy2 +
         " T " + tx1 + " " + ty1 +
         " M " +  x2 + " " +  y2 +
         " Q " + qx3 + " " + qy3 + " " + qx4 + " " + qy4 +
         " T " + tx1 + " " + ty1 );
 }
 //--------------------------------
    return node;

}


export default createLineChart;