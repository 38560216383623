 import React from 'react';
 import LineData from './d3-examples/dataLineChart';
 import LineChart from './d3Components/LineChart';

 
 class convertLineChart extends React.Component {
   constructor(props) {
     super(props);
     this.state = {d3: ''}
   }
 
   componentDidMount() {
     this.setState({d3: LineData});
   }
 
   render() {
     
     return(
       <div>
         <LineChart nameDiv={this.props.nameDiv} data={this.props.data} group={this.props.group} color={this.props.color} typeScaleColor={this.props.typeScaleColor} callBackLineChart={this.props.callBackLineChart} title={this.props.title}  xAxisName={this.props.xAxisName}  yAxisName={this.props.yAxisName} description={this.props.description} granularity={this.props.granularity}/>
       </div>
     )
   }
 }
 export default convertLineChart;