import React,{ Fragment } from 'react';
import Admin from '../../admin/containers/admin';
import SliceMenuApp from '../../admin/components/slice-menu'
import { 
  Col,
  Row,
  Button, 
  Container,
	Input,
	Label,
	Table,
	Collapse,
	Form,
	Badge,
	UncontrolledTooltip
} from 'reactstrap';
import './bot-aiml-layout.css';
import ToolbarApp from '../../admin/components/toolbar';
import ModalNewGroup from '../../../components/modal-new-group-layout'
import BotMenuOptions from'../../menus/bot-menu-options';
import {Link} from 'react-router-dom'
import i18next from '../../../../i18n';

function BotAimlLayout(props) {
	// display of forms for Question Update
	var updateQuestionForms = [];
	for (let i=0; i<props.displayTr.length; i++) {
		updateQuestionForms.push(<Form key={i} onSubmit={props.updateQuestion} id={"updateQuestionForm-"+ props.displayTr[i].id}> </Form>);
	}

	// display for the list of synonyms
	var displaySyn = {};
	for(var prop in props.synonyms) {
		displaySyn[prop] = [];
		props.synonyms[prop].map((syn,index) => { 
			displaySyn[prop].push(
					<Badge key={"syn-"+syn.id} color="primary" hidden={syn.hidden}>
						 {syn.val}  
						<Form onSubmit={props.deleteSynonym} hidden id={"deleteSynonymForm-"+syn.id}>
								<Input type="hidden" defaultValue={syn.id}  name="s-id" hidden/>
								<Input type="hidden" defaultValue={syn.qna_id}  name="s-qna" hidden/>
						</Form>
						<Button type="submit" color="primary" form={"deleteSynonymForm-"+syn.id} className="removeSynonym btn-primary">
									<i className="fa fa-times"></i>
							</Button>
					</Badge>
			)});
	}


	const _listQuestionsHTML = () => {
		return props.displayTr.map((item, index) => {
			return (
				<Fragment key={index}>
					<tr key={index}>
						<td className="align-middle text-center">
							<Input type="hidden" form={"updateQuestionForm-" + item.id} defaultValue={item.id} name={"id"} hidden />
							<Input type="hidden" form={"updateQuestionForm-" + item.id} defaultValue={item.aiml_group_id} name={"aiml_group_id"} hidden />
							<Input type="text" form={"updateQuestionForm-" + item.id} value={item.statement} onChange={(e)=>{props.handleChangeUpdateInputs(index,'statement',e.target.value)}} name={"statement"} placeholder={i18next.t('question')} required hidden={!item.hidden} />
							<span hidden={item.hidden}> {item.statement} </span>
						</td>
						<td className="align-middle text-center">
							<Input type="text" form={"updateQuestionForm-" + item.id} value={item.answer} onChange={(e)=>{props.handleChangeUpdateInputs(index,'answer',e.target.value)}} name={"answer"} placeholder={i18next.t('answer')} required hidden={!item.hidden} />
							<span hidden={item.hidden}> {item.answer} </span>
						</td>
						<td className="align-middle text-center">
							<div style={{ display: 'table' }} className="col-12">
								<div style={{ display: 'table-row' }}>
									<div style={{ border: 'none', display: 'table-cell' }}>
										<Form onSubmit={props.newSynonym} id={"newSynonym-" + index}>
											<Input type="hidden" defaultValue={item.id} name="aiml-id" hidden />
											<Input type="text" defaultValue="" name="synonym" placeholder={i18next.t('add_synonym')} required />
										</Form>
									</div>
									<div className="" style={{ border: 'none', display: 'table-cell' }}>
										<Button form={"newSynonym-" + index} title={i18next.t('add_synonym')} type="submit" outline color="success" className="btn-block btn-addon" >
											<i className="fa fa-save">
											</i>
										</Button>
									</div>
									<div className="" style={{ border: "none", display: "table-cell" }}>
										<Form onSubmit={props.toogleCollapse}>
											<Input type="hidden" defaultValue={item.id} name="q-id" hidden />
											<Button type="submit" title={i18next.t('list_synonym')} outline color="primary" className="btn-block btn-addon " id="btn-list" >
												<i className="fa fa-eye">
												</i>
											</Button>

										</Form>
									</div>
								</div>
							</div>
							<Collapse isOpen={item.collapse} className="accordion-body" >
								<h4>{i18next.t('list_synonym')}</h4>
								<div className="text-left col-sm-12" >
									{displaySyn[item.id]}
								</div>
							</Collapse>
						</td>
						<td className="align-middle text-center">
							<div style={{ display: 'table' }}>
								<div style={{ display: 'table-row' }}>
									<div style={{ display: 'table-cell' }}>
										<Form onSubmit={props.toogleQuestion}>
											<Input type="hidden" defaultValue={item.id} name="q-id" hidden />
											<Button type="submit" title={i18next.t('edit_question')} outline color="primary" className="btn" hidden={item.hidden}>
												<i className="fa fa-pencil-alt"></i>
											</Button>
										</Form>
										<Button type="submit" form={"updateQuestionForm-" + item.id} title={i18next.t('save')} className="removeQuestion btn" outline color="success" data-aiml="a" hidden={!item.hidden}>
											<i className="fa fa-save"></i>
										</Button>
									</div>
									<div style={{ display: 'table-cell' }}>
										<Form onSubmit={props.deleteQuestion} >
											<Input type="hidden" name="id" defaultValue={item.id} />
											<Button type="submit" className="removeQuestion btn" outline color="danger" data-aiml="a">
												<i className="fa fa-trash"></i>
											</Button>
										</Form>
									</div>
								</div>
							</div>


						</td>
					</tr>
				</Fragment>
			)
		});
	}



  return (
    <div className="BotLayout">
			<Admin>
				<SliceMenuApp>
					<BotMenuOptions botId={props.botId} ia_activated={props.ia_activated}/>
				</SliceMenuApp>
				<Container fluid>
						<ToolbarApp>
								<Row>
									<Col sm="6" md="4" lg="3" className="text pdtoolbar">
										<h2 className="text-left ml-4 font-weight-bold">{i18next.t('qua_title_view')}</h2>                      
									</Col>
								</Row>
						</ToolbarApp>
				</Container>
				<Container fluid className="box-fluid">
					<Col sm="12" md="10" className="aiml-container offset-md-1">
						<Col md="12">
							<Row className="card-train">
								<Col md="12">
									<Row>
										<Col md="6" lg="8" xl="8">
											<h1 className="font-weight-bold">{i18next.t('questions_answers')}</h1>    
										</Col>
										<Col md="3" lg="2" xl="2">
											<Button type="button" onClick={props.train} className="btn btn-block pull-right btn-guarumo-md">{i18next.t('train')}</Button>    
										</Col>
										<Col md="3" lg="2" xl="2">
											<Link to={`/bot/${props.botId}/aiml-group`} className="btn btn-block pull-right btn-cancel-md text-reset">{i18next.t('back')}</Link>    
										</Col>
									</Row>
								</Col>
								<Col md="12" className="describe-aiml">
									<Form onSubmit={props.newQuestion} id="newQuestionForm"> </Form>{updateQuestionForms}
									<Col>
										<Row className="col-md-12 col-lg-12 ">
											<Col className="col-sm-12 col-md-4 col-lg-5">
												<div className="form-group">
													<Label for="pregunta">{i18next.t('write_question')}</Label>
													<Input type="text" form="newQuestionForm" id="pregunta"  name="statement" placeholder={i18next.t('example_qna2')} required/>
												</div>
											</Col>
											<Col className="col-sm-12 col-5 col-md-4 col-lg-5">	
												<div className="form-group">
													<Label for="respuesta">{i18next.t('message161')}</Label>
													<Input type="text" form="newQuestionForm" id="respuesta" name="answer" placeholder={i18next.t('example_qna')} required/>
												</div>
											</Col>
											<Col className="col-sm-12 align-middle col-md-4 col-lg-2">
												<Button type="submit" form="newQuestionForm" outline color="success" className="btn16 btn-block" id="addQuestion" >{i18next.t('save')}</Button>	
											</Col>
										</Row>
									</Col>
								</Col>
								
							
								<Table className="col-12" bordered>
									<thead>
										<tr className="success">
											<th>
												<p>{i18next.t('message162')}</p>
											</th>
											<th>
												<p>{i18next.t('message163')}</p>
											</th>
											<th style={{width:'40%'}}>
												<p>{i18next.t('message164')}</p>
											</th>
											<th style={{width:'5%'}} >
											</th>
										</tr>
									</thead>
									<tbody>
                      					{_listQuestionsHTML()}
									</tbody>
								</Table>
							</Row>
						</Col>
					</Col>
				</Container>
				<ModalNewGroup toggleModal={props.toggleModalNewGroup} isOpen={props.modalBot} saveGroup={props.saveGroup}/>
			</Admin>      
    </div>
  )
}

export default BotAimlLayout