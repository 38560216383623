import React, { Component } from "react";
import { Col }  from 'reactstrap';
import {Line} from "react-chartjs-2";

class Linea extends Component {
    state = {
        mayor:0,
        data:this.props.data || [],
        step:1
    }

    componentDidUpdate(prevProps,prevState){
        if(prevProps.data.datasets != this.props.data.datasets){
            this.setState({
                data:this.props.data
            },()=>this.getMaxNumber(this.state.data))
        }  
    }

    getMaxNumber(data){
        let data_values = data.datasets[0].data;
        let mayor_value = this.getMaxOfArray(data_values);
        if(mayor_value != this.state.mayor){
            this.setState({
                mayor:mayor_value
            },()=>this.getStep(this.state.mayor))  
        }
    }
   
    getStep = (mayor) =>{
        let step=0;
        if(mayor <= 1){
            step = 1
        }else if(mayor > 1 && mayor <= 50){
            step = 10
        }else if(mayor > 50 && mayor <= 100){
            step = 25
        }else if(mayor > 100 && mayor <= 300){
            step = 50
        }else if(mayor > 300 && mayor <= 2000){
            step = 100
        }else if(mayor > 2000){
            step = 300
        } 
        this.setState({step})
    }

    getMaxOfArray(numArray) {
        return Math.max.apply(null, numArray);
    }

    render() {
        const options = {
            maintainAspectRatio:false,
            layout: {
                padding: {
                    left: 10,
                    right: 0,
                    top: 0,
                    bottom: 0
                }
            },
            legend: {
                display: false,
                position:'bottom',
                labels: {
                    fontColor: '#5a5a5a',
                    fontSize:14
                }
            },
            scales: {
                xAxes: [{
                    stacked: true
                }],
                yAxes: [{
                    gridLines: {
                        display: true,
                        drawBorder: false,
                        drawOnChartArea: false,
                    },
                    ticks: {
                        beginAtZero:true,
                        stepSize: this.state.step
                    }
                }]
            }
            
        };
      return (
        <Col>
             <Line
                data={this.state.data}
                options={options}
                width={400} 
                height={400}
            />
        </Col>
      );
    }
  }
  
  export default Linea;