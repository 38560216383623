import React from 'react';
import { 
    Col,
    Row,
    Button, 
    Form, 
    FormGroup,
    Input,
    InputGroup, 
    InputGroupAddon, 
    InputGroupText,
} from 'reactstrap';
import './toolbar.css';

function ToolbarApp(props){
    return (
        <Col className="d-none d-md-block toolbar">
            {props.children}
        </Col>
    );
}
  
export default ToolbarApp