import React, { Component } from 'react';
import { connect } from "react-redux";

import axios from 'axios';
import qs from 'qs';
import QRMLayout from './quick-replies-modal-layout';
import SMPortModel from '../simplePortModel/SMPortModel';
import { cerrarSesion } from '../../../../utils/commonFunction'

import { isDidMount, willUnMount } from "../../../../redux/actions/componentMountActions";

import { iconLoad, iconHideLoad,setChangeConfig,updateBoxOperator,
    updateOperator,addTextBlock,createNewStatement,updateStatement,
    cleanSelection,removeTextBlock} from "../../../../redux/actions/boxActions";
 import { find, propEq } from "ramda";

class QRModal extends Component {
    state = {
        answer_blocks: {'bl_0': ""},
        nodeId: "",
        keyword: "",
        title: "",
        selection:  null,
        range: null,
        keyword_valid: true,
        error_input_empty: {},
        timeout_valid: true,
        error_limit_item: false,
        audio:''
    }

    constructor(props){
        super(props)
    }

    getAvatarId = (avatarid) => {
        this.props.modal.node.extras.avatar = avatarid;
    }
    getTitleOperator = (titleOperator) => {
        this.props.modal.node.extras.titleOperator = titleOperator
    }
    getAudio = url => {
        this.props.modal.node.extras.audio = url
    }


    validateQR = (node)=>{
        let blocks = node.extras.answer_blocks;
        const kblocks = Object.keys(blocks);
        let error_input_empty = {}
        let err = false;

        kblocks.forEach((k) => {    
            let input = $('#input-'+k).val()   
            if(!err && (input.length <= 0)){
                err = true;
            }   
            error_input_empty[k] = input.length <= 0;            
        });  

        this.setState({error_input_empty: error_input_empty}, ()=> {
            this.forceUpdate();
        });
        return err;
    }

    toggleModuleModal = async () => {   
       
        if(!this.state.keyword_valid)
            return false;

        if(!this.state.timeout_valid)
            return false

        let node = this.props.modal.node; 

        if(this.validateQR(node))
            return false;

        //Estrae y actualiza el título del operador
        // node.extras.titleOperator = form.get('titleOperator');
        //OJO SE DEBE CAMBIAR AL COMPONENTE title-operator.js cuando todas las cajas usen redux
        await this.props.updateBoxOperator('title', this.props.modal.node.extras.titleOperator);//this.getTitleOperator(node.extras.titleOperator);   

        

        let headid = node.extras.headid;
        let text = $('#input-head').val();
        node.extras.head = text;
        if(!headid){
            this.saveStatement(node.extras.dbId).then((res) => {
                if (res.status== 200){
                    node.extras.headid = res.data.statement.id;
                }
                /**
                 * Dejar campo de text & name vacio para evitar que se muestre en el mentions 
                 * ya que, no es una variable.
                 * (statement, text, name,is_var, var_name)
                 */
                this.updateStatement(
                            node.extras.headid, 
                            (node.extras.is_var) ? node.extras.head : '', 
                            (node.extras.is_var) ? node.extras.head : '', 
                            node.extras.is_var, 
                            node.extras.var_name? node.extras.var_name:'' ,
                            node);
                       
            }).catch((error) => {
                console.log('Catch saveStatement:',error);
                if (error.response.status == 403)
                  cerrarSesion();
            }); 
        }else{
            /**
             * Dejar campo de text & name vacio para evitar que se muestre en el mentions 
             * ya que, no es una variable.
             * (statement, text, name,is_var, var_name)
             */
    
            this.updateStatement(
                        headid,
                        (node.extras.is_var) ? node.extras.head : '', 
                        (node.extras.is_var) ? node.extras.head : '', 
                        node.extras.is_var,
                        node.extras.var_name? node.extras.var_name:'' 
                        ,node);
        }



        let blocks = node.extras.answer_blocks;
        const kblocks = Object.keys(blocks);

        const tam = kblocks.length;
        let i=0;
        let err_input = {}

        if( kblocks.length==0)
            node.extras.dinamicVariables={}
        
        if(i==tam){

            this.terminar(node);
        }

         kblocks.forEach(async (k) => {    
            let text = $('#input-'+k).val();
           
            blocks[k].text = text;
            blocks[k].name = text;

            err_input[k] = text.length > 20;
           
            
                
            if(Number(k)<100 && (!blocks[k].statement_id || blocks[k].statement_id=='')){
                this.props.createNewStatement(node.extras.dbId,k,node,'var_answer_blocks').then((res) => {
                    i++;
                    if(i==tam){

                        this.terminar(node);
                    }
                })
                
            }else { 
                if(!blocks[k].statement_id || blocks[k].statement_id=='')
                    blocks[k].statement_id=k;
                // Edita el statement cuando ya existe en la base de datos
                this.updateStatement(blocks[k].statement_id, blocks[k].text,  blocks[k].name, blocks[k].var_name!=''?true:false,blocks[k].var_name?blocks[k].var_name:'',node,'var_answer_blocks');
                    i++;
                    if(i==tam){

                        this.terminar(node);
                       }
                
            }

            
            
        });

     
     
      
       // handleAnswers(node);  
       
      

      
    }
    terminar = async (node) =>{
       
                let blocks = node.extras.answer_blocks;
                const kblocks = Object.keys(blocks);

                let ports = node.getPorts();
                let kports = Object.keys(ports);

                kblocks.forEach((kblock) => {
                    const kport = kports.find(kp => {
                        const port = ports[kp]
                        const name =  blocks[kblock].statement_id?blocks[kblock].statement_id:kblock;
                        return port.name == "right-" + name
                    })
                    if(!kport){
                         const nameN = blocks[kblock].statement_id?blocks[kblock].statement_id:kblock;
                        node.addPort(new SMPortModel(true, "right-" + nameN, 'qr'));
                    }
                })

                kports.forEach((kport) => {
                    const port = ports[kport]       
                    if(port.name != "left"){
                        const n = port.name.replace("right-", "")

                        let variable; 
                        kblocks.forEach((kblock) => {
                            if(blocks[kblock].statement_id==Number(n)||kblock==Number(n))
                                variable = kblock;
                        })
                        
                        if(!variable ){
                            const links = port.getLinks();
                            const klinks = Object.keys(links);
                            klinks.forEach(klink => {
                                const link = links[klink];
                                link.remove();
                            });
                            node.removePort(port);
                        }
                    }
                    
                })
        

                 ports = node.getPorts();
                 kports = Object.keys(ports);

                const kport = kports.find(k => {
                    const port = ports[k]
                    return port.name == "right-timeout"
                })
                
                if(!kport){
                    if(node.extras.timeout > 0){
                        node.addPort(new SMPortModel(true, "right-timeout", 'sm'));
                    }            
                }else{
                    if(node.extras.timeout <= 0){
                        kports.forEach((kport) => {
                            const port = ports[kport];                     
                            if(port.name == "right-timeout"){
                                const links = port.getLinks();
                                const klinks = Object.keys(links);
                                klinks.forEach(klink => {
                                    const link = links[klink];
                                    link.remove();
                                });
                                node.removePort(port);
                            }                    
                        })
                    } 
                }
        
                const {configBoxOperator} = this.props.boxReducer;
        
               
                 //Extrae y guarda la nueva configuración
                node.extras.config = configBoxOperator;
        
                await this.props.updateOperator(node.extras.titleOperator,node.extras.keyword,node.extras.config);
                
                //Actualiza el esquema
                await this.props.updateSchema();
                
                //Limpia selecciones
                await this.props.cleanSelection();
        
                this.props.toggleModuleModal('quickreplies')
    }

    addNewTextBlock = (e) => {


        const node = this.props.modal.node;
        const blocks = node.extras.answer_blocks;
        const kports = Object.keys(blocks);
        const k = kports.length ? kports.length : 0;
       
        
        if(k >= 10){
            e.persist(); //event is not being nullified
            this.setState({error_limit_item:true});
            this.forceUpdate();
            //this.props.updateSchema()
            return false;
        }else{
            blocks[k] = {text:'',name:'',is_var: true};
            this.props.addTextBlock(blocks);
            //blocks[res.data.statement.id] = temp;
            //node.addPort(new SMPortModel(true, "right-" + res.data.statement.id, 'qr'));
        }        


    }

    removeStatement = (statement) => {
        this.setState({error_limit_item:false});
        const data = {
            id: statement,
        };

        var auth=JSON.parse(localStorage.getItem('Authorization'));
        var headers={
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer '+ auth.access_token 
        }

        return axios({
            method:'delete',
            url:HOST_API +'/api/scheme/statement/',
            data:qs.stringify(data),
            headers:headers
        })
    }

    removeTextBlock = (index) => {
        this.props.removeTextBlock(index);
        // let blocks = this.props.modal.node.extras.answer_blocks;
        // delete blocks[index];
        // this.removeStatement(index).then(res => {
        //     this.props.updateSchema()
        // });
        // this.forceUpdate();
    }

    
    saveStatement = (operator) => {
        const data = {
            operator_id: operator,
            text: "",
            name: ""
        };

        var auth=JSON.parse(localStorage.getItem('Authorization'));
        var headers={
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer '+ auth.access_token 
        }

        return axios({
            method:'post',
            url:HOST_API +'/api/scheme/statement/',
            data:qs.stringify(data),
            headers:headers
        });
    }

    //Actualizar información de statement
    updateStatement = (statement, text, name, is_var, var_name,node,type) => {
        const data = {
            id: statement,
            text: text,
            name: name,
            is_var:is_var,
            var_name:var_name
        };

        this.props.updateStatement(data,node,type);
    
    }

    updateKeyword = (text) => {  
        this.props.modal.node.extras.keyword = text;
        this.saveKeyword(text);
        this.forceUpdate();
    }

    /* Update data de keyword */
    saveKeyword = (keyword) => {
        const data = {
            id: this.props.modal.node.extras.dbId,
            keyword:keyword
        };
        var auth=JSON.parse(localStorage.getItem('Authorization'));
        var headers={
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer '+ auth.access_token 
        }
        return axios({
            method:'put',
            url:HOST_API +'/api/scheme/operator/',
            data:qs.stringify(data),
            headers:headers
        })
    }

    isKeywordValid = (val) => {        
        this.setState({keyword_valid:val}, ()=>{
            this.forceUpdate();
        })
    }

    /* END SEARCH VARIABLE FUNCTIONS AND HANDLERS */
    updateItem = (text) => {  
        this.props.modal.node.extras.head = text;
        this.forceUpdate();
    }
    closeModal = () => {
        this.props.closeModal('m2');
        this.forceUpdate();
    }

    // Timeout functions
    getTimeout = (time) => {
        this.props.modal.node.extras.timeout = time
    }

    getMsgTimeout = (msg) => {
        this.props.modal.node.extras.msg_timeout = msg
    }

    isTimeoutValid = (valid) => {
        this.setState({timeout_valid: valid}, ()=>{
            this.forceUpdate();
        })
    }

       //Emoji
    callEmoji = (code,k,type)=>{
        if(type === "head"){
        let text = $('#input-head').val();
            text += ` ${code}`;
            $('#input-head').val(text)
            this.updateItem(text)   
        }else{
        let text = $('#input-'+k).val();
            text += ` ${code}`;
            $('#input-'+k).val(text)  
        }
    }

    updateValidation = (event) => {
       this.props.modal.node.extras.is_var = (event.target.value == 'true');
       console.log('event.target.value',event.target.value);
       
        if(!this.props.modal.node.extras.is_var)
            this.props.modal.node.extras.var_name = ''
        this.forceUpdate();
    }

    updateVarName = (event) => {
        this.props.modal.node.extras.var_name = event.target.value;
        this.forceUpdate();
    }

    handleChangeSwitch = (checkedSwitchEnunciado)=>{
        this.props.modal.node.extras.isEnunciadoAudio =  checkedSwitchEnunciado;
        this.forceUpdate();
    }

     // IA set node extras
     getIAId = IAid => this.props.modal.node.extras.ia_id = IAid;

    render() {
        return (
          <QRMLayout 
          toggleModuleModal={this.toggleModuleModal}
          addNewTextBlock={this.addNewTextBlock}
          removeTextBlock={this.removeTextBlock}
          updateKeyword={this.updateKeyword}
          isKeywordValid={this.isKeywordValid}
          isOpen={this.props.modal.isOpen} 
          answer_blocks={this.props.modal.node.extras.answer_blocks} 
          nodeId={this.state.nodeId} 
          keyword={this.props.modal.node.extras.keyword} 
          head={this.props.modal.node.extras.head} 
          title={this.state.title}
          varList={this.props.varList}
          botversion_id={this.props.botversion_id}
          operator_id={this.props.modal.node.extras.dbId}
          ia={this.props.modal.node.extras.ia_id || 'none'}
          error_input_empty={this.state.error_input_empty}
          closeModal={this.closeModal}
          error_limit_item = {this.state.error_limit_item}
          getTimeout={this.getTimeout}
          getMsgTimeout={this.getMsgTimeout}
          isTimeoutValid={this.isTimeoutValid}
          timeoutValue={this.props.modal.node.extras.timeout}
          timeoutMsg={this.props.modal.node.extras.msg_timeout}
          updateItem={this.updateItem}
          avatarid={this.props.modal.node.extras.avatar || 0}
          getAvatarId={this.getAvatarId}
          getIAId = {this.getIAId}
          company_id={this.props.company_id}
          getTitleOperator = {this.getTitleOperator}
          titleOperator = {this.props.modal.node.extras.titleOperator || ''}
          callEmoji = {this.callEmoji}
          updateValidation={this.updateValidation}
          updateVarName = {this.updateVarName}
          is_var = {this.props.modal.node.extras.is_var}
          var_name = {this.props.modal.node.extras.var_name}
          audio = {this.props.modal.node.extras.audio || ''}
          getAudio = { this.getAudio } 
          checkedSwitchEnunciado = {this.props.modal.node.extras.isEnunciadoAudio || false}
          handleChangeSwitch = {this.handleChangeSwitch}
          />
        )
    }
}
const mapStateToProps = ({ boxReducer,homeReducer, componentMountReducer }) => {
    return { boxReducer,homeReducer, componentMountReducer };
   };
   
   const mapDispatchToProps = {
    isDidMount,
    willUnMount,
    iconLoad,
    iconHideLoad,
    setChangeConfig,
    updateBoxOperator,
    updateOperator,
    addTextBlock,
    createNewStatement,
    updateStatement,
    cleanSelection,
    removeTextBlock
//toggleModalViewBoxBlockchain,

   };
   
   export default connect(mapStateToProps, mapDispatchToProps)(QRModal);
