import React, {Component, useState} from 'react';
import { connect } from "react-redux";

import { Label,Button,Modal,ModalHeader,ModalBody,ModalFooter,Form,FormGroup,Input,Col,Row,Nav,NavItem,TabContent,TabPane,NavLink,UncontrolledTooltip} from 'reactstrap';
import IASelector from '../../../../components/ia';
import Keyword from '../../../../components/keyword/keyword-redux';
import Mentions from '../../../../components/mentions/metions-redux';
import Avatar from '../../../../components/avatar';
import TitleOperator from "../../../../components/title-operator";
import Emoji from '../../../../components/emoji-picker';
import classnames from 'classnames';
import CreatableInputOnly from '../../../../components/inputSelect.js'

import { isEmpty} from "../../../../utils/commonFunction";
import { iconLoad, 
        iconHideLoad, 
        toggleModalViewBoxCatalog,
        changeActiveTab,
        setChangeConfig,
        updateVariableBoxOperator,
        
       } from "../../../../redux/actions/boxActions";

import SelectStatements from '../../../../components/selected-statements'
import TabAdvanced from "../../../../components/box/tab_advanced";
import i18next from '../../../../../i18n';

// import Select from 'react-select';


function CATMLayout(props) {
   
    
    const {selectedBoxOperator, varList,listTypeCatalog,configBoxOperator, modalViewBoxCatalog,activeItem,variablesBoxOperator,avatarid,botmsg_text,exit_var,listTypeSourceCatalog} =props.boxReducer;

   
    const { bot,botVersionId } = props.botReducer;
   

 

  
    if(selectedBoxOperator&&Object.keys(selectedBoxOperator).length>0&&selectedBoxOperator.operatortype_id==24)
    {

            if(configBoxOperator&&configBoxOperator.item_type)
            {
                //selectedDate = moment(new Date(configBoxOperator.selectedDate));
            
             
                return (
                    <React.Fragment>
                    <Modal className="catalog"
                    isOpen={modalViewBoxCatalog}
                    toggle={props.toggleModalViewBoxCatalog} 
                    // isOpen={props.isOpen} toggle={props.closeModal} 
                    fade size="lg">
                        <ModalHeader toggle={props.toggleModalViewBoxCatalog}>{i18next.t('config_catalog')}</ModalHeader>
                        <Form id="CATM-form" role="form">  
                        <ModalBody>
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeItem === '1' })}
                                    onClick={(e) => { toggleTab('1'); }} >
                                    {i18next.t('basic')}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeItem === '2' })}
                                    onClick={(e) => { toggleTab('2'); }}>
                                    {i18next.t('advanced')}
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={activeItem}>
                                <TabPane tabId="1">
                                    
                                    <TitleOperator titleOperator={selectedBoxOperator.title} />

                                        <Row>
                                            <Col sm="12"> 
                                                <Label>{i18next.t('statement')} <i className="fa fa-info-circle" id="tooltipEnunciado"></i></Label>
                                                <UncontrolledTooltip placement="right" target="tooltipEnunciado"> {i18next.t('message8')}</UncontrolledTooltip>
                                                  <FormGroup>
                                                    <Mentions defaultValue={botmsg_text || ''}  maxlength={'2000'} msg_error={i18next.t('msg_error_limit')} k={0} updateItem={props.updateItem}/>
                                        
                                                        <div className="options-bar-input" style={{'right':'1px'}}>
                                                        <Emoji id={'head'} callEmoji={props.callEmoji} type="head" style={{'display':'none',position: 'absolute',right: 268+'px',zIndex: 1}}/>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                          </Row>
                                          <Row>
                                          <Col sm="6">
                                              <FormGroup>
                                                <Label>
                                                  {i18next.t('catalog_type')}{" "}
                                                  <i
                                                    className="fa fa-info-circle"
                                                    id="tooltipTipo"
                                                  ></i>
                                                </Label>
                                                <UncontrolledTooltip
                                                  placement="right"
                                                  target="tooltipTipo"
                                                >
                                                  {" "}
                                                  {i18next.t('catalog_type_info')}
                                                </UncontrolledTooltip>
                                                {listTypeCatalog &&(Object.keys(listTypeCatalog).length > 0 && (
                                                  <Input  type="select"
                                                    className="inputModal" 
                                                    name="type" 
                                                    defaultValue={(configBoxOperator)?configBoxOperator.item_type_short:''}
                                                    onChange={(e) => onchangeTypeCatalog(e)}
                                                    required>
                                                { Object.keys(listTypeCatalog).map((k, index) => {
                                                    return <option key={index} value={listTypeCatalog[k].type} >{listTypeCatalog[k].text}</option>;
                                                    
                                                    })
                                                }
                                            </Input> 
                                            ))}
                                              </FormGroup>
                                              </Col>
                                              <Col sm="6">
                                                 <FormGroup>
                                                {console.log("configBoxOperator catalogo ",configBoxOperator)}
                                                {console.log("varList catalogo ",varList)}
                                                {varList&&varList.length > 0 ? (
                                                        <SelectStatements 
                                                            varList={ varList } 
                                                            varSelected={ configBoxOperator.customer_id } 
                                                            onchangeStatements={ props.setChangeConfig } 
                                                            nameVar={ 'customer_id' } 
                                                            label={ 'Id ' + i18next.t('customer') } 
                                                            tooltip={i18next.t('id_final_user') + ' ' + i18next.t('customer') }
                                                            k={'0'}
                                                        ></SelectStatements>
                                                ):null}
                                               
                                              </FormGroup>
                                              </Col>
                                            
                                         </Row>
                                         <Row>
                                        <Col sm="6">
                                            { variablesBoxOperator && Object.keys(variablesBoxOperator).length > 0 ? (
                                            
                                              <FormGroup>
                                                <Label>
                                                  {i18next.t('message12')}{" "}
                                                  <i
                                                    className="fa fa-info-circle"
                                                    id="tooltipVariable"
                                                  ></i>
                                                </Label>
                                                <UncontrolledTooltip
                                                  placement="right"
                                                  target="tooltipVariable"
                                                >
                                                  {" "}
                                                  {i18next.t('message13')}
                                                </UncontrolledTooltip>

                                                <Input
                                                  type="text"
                                                  className="inputModal"
                                                  placeholder= {i18next.t('message12')}
                                                  
                                                  onChange={(event) => {
                                                    props.updateVariableBoxOperator(
                                                      "var_main",
                                                      event.target.value,
                                                      "variables"
                                                    );
                                                  }}
                                                  defaultValue={
                                                    variablesBoxOperator && variablesBoxOperator["var_main"]
                                                      ? variablesBoxOperator["var_main"].var_name
                                                      : ""
                                                  }
                                                />
                                              </FormGroup>
                                              
                                              
                                            ) : null}
                                            {exit_var ? (
                                              <Row>
                                                <div className="alert alert-danger" role="alert">
                                                  {i18next.t('message14')}
                                                </div>
                                              </Row>
                                            ) : (
                                              <div></div>
                                            )}
                                            </Col>
                                            <Col sm="6">
                                            {variablesBoxOperator && Object.keys(variablesBoxOperator).length > 0 ? (
                                            
                                              <FormGroup>
                                                <Label>
                                                  {i18next.t('message15')}{" "}
                                                  <i
                                                    className="fa fa-info-circle"
                                                    id="tooltipReferencia"
                                                  ></i>
                                                </Label>
                                                <UncontrolledTooltip
                                                  placement="right"
                                                  target="tooltipReferencia"
                                                >
                                                  {" "}
                                                  {i18next.t('message16')}
                                                </UncontrolledTooltip>

                                                <Input
                                                  type="text"
                                                  className="inputModal"
                                                  placeholder={i18next.t('message15')}
                                                  onChange={(event) => {
                                                    props.updateVariableBoxOperator(
                                                      "var_json",
                                                      event.target.value,
                                                      "variables"
                                                    );
                                                  }}
                                                  defaultValue={
                                                    variablesBoxOperator && variablesBoxOperator["var_json"]
                                                      ? variablesBoxOperator["var_json"].var_name
                                                      : ""
                                                  }
                                                />
                                              </FormGroup>
                                              
                                              
                                            ) : null}
                                            {exit_var ? (
                                              <Row>
                                                <div className="alert alert-danger" role="alert">
                                                  {i18next.t('message14')}
                                                </div>
                                              </Row>
                                            ) : (
                                              <div></div>
                                            )}
                                            </Col>
                                        </Row>
                                         { configBoxOperator.item_type_short=='smartdata' &&
                                         <Row>
                                           <Col sm="6">
                                           
                                              <FormGroup>
                                                <Label>
                                                  {i18next.t('message17')}{" "}
                                                  <i
                                                    className="fa fa-info-circle"
                                                    id="tooltipTipoSource"
                                                  ></i>
                                                </Label>
                                                <UncontrolledTooltip
                                                  placement="right"
                                                  target="tooltipTipoSource"
                                                >
                                                  {" "}
                                                  {i18next.t('message18')}
                                                </UncontrolledTooltip>
                                                {listTypeSourceCatalog &&(Object.keys(listTypeSourceCatalog).length > 0 && (
                                                  <Input  type="select"
                                                    className="inputModal" 
                                                    name="type" 
                                                    defaultValue={(configBoxOperator)?configBoxOperator.source_type:listTypeSourceCatalog[0].id?listTypeSourceCatalog[0].id:''}
                                                    onChange={(e) => onchangeSourceTypeCatalog(e)}
                                                    required>
                                                { Object.keys(listTypeSourceCatalog).map((k, index) => {
                                                   
                                                    return <option key={index} value={listTypeSourceCatalog[k].id} >{listTypeSourceCatalog[k].name}</option>;
                                                    
                                                    })
                                                }
                                            </Input> 
                                            ))}
                                              </FormGroup>
                                              
                                              </Col>
                                              {configBoxOperator.source_type==='variable'  &&

                                                varList&&varList.length > 0 ? (
                                                  <Col sm="6">
                                                    <SelectStatements 
                                                        varList={ varList } 
                                                        varSelected={ configBoxOperator.variable_products } 
                                                        onchangeStatements={ props.setChangeConfig } 
                                                        nameVar={ 'variable_products' } 
                                                        label={i18next.t('message19')}
                                                        tooltip={i18next.t('message20')}
                                                        k={'0'}
                                                    ></SelectStatements>
                                                  </Col>
                                                ):null
                                              }
                                               {configBoxOperator.source_type!=='variable'  ? (
                                               <Col sm="6">
                                                 <FormGroup>
                                                <Label>
                                                  {i18next.t('stock_keys')}{" "}
                                                  <i
                                                    className="fa fa-info-circle"
                                                    id="tooltipKeys"
                                                  ></i>
                                                </Label>
                                                <UncontrolledTooltip
                                                  placement="right"
                                                  target="tooltipKeys"
                                                >
                                                  {" "}
                                                  {i18next.t('message21')}
                                                </UncontrolledTooltip>
                                               <Input
                                                  type="text"
                                                  className="inputModal"
                                                  placeholder="id_keys"
                                                  onChange={(event) => {
                                                    props.setChangeConfig(
                                                      "id_keys",
                                                      event.target.value
                                                    );
                                                  }}
                                                  defaultValue={(configBoxOperator)?configBoxOperator.id_keys:''}
                                                />
                                              </FormGroup>
                                              </Col>
                                              
                                            ) : null}

{configBoxOperator.source_type==='shopify'  ? (
                                               <Col sm="6">
                                                 <FormGroup>
                                                <Label>
                                                  {i18next.t('message22')}{" "}
                                                  <i
                                                    className="fa fa-info-circle"
                                                    id="tooltipKeysTags"
                                                  ></i>
                                                </Label>
                                                <UncontrolledTooltip
                                                  placement="right"
                                                  target="tooltipKeysTags"
                                                >
                                                  {" "}
                                                  {i18next.t('message23')}
                                                </UncontrolledTooltip>
                                                <CreatableInputOnly
                                                getHandler={getHandlerTags}
                                                getHandlerRemove={getHandlerRemoveTags}
                                                values={(configBoxOperator)?configBoxOperator.tags_categories:"[]"}
                                                >

                                                </CreatableInputOnly>
                                  {/* <Select
                                    options={listStatements?listStatements:[]}
                                    classNamePrefix="select"
                                    className="basic-single"
                                    isMulti={ isMulti }
                                    isDisabled={false}
                                    isLoading={false}
                                    isClearable={false}
                                    isRtl={false}
                                    isSearchable={true}
                                    onChange={ hadleSelect }
                                    name="type"
                                    value={ activeVar }
                                    styles={colourStyles}
                                    menuPortalTarget={document.body}
                                    menuPosition={'fixed'} 
                                                                      
                                /> */}
                                           </FormGroup>
                                              </Col> ) : null}
                                         </Row>
                                        }
                  
                                        
                                </TabPane>
                                <TabPane tabId="2">
                                    <Row>
                                        <Col sm="6">
                                            <IASelector botversion_id={botVersionId} operator_id={selectedBoxOperator.id} ia={selectedBoxOperator.ia_id} />
                                            <Avatar company_id={bot.company_id} avatarid={avatarid} bindValue={props.getAvatarId} />
                                        </Col>
                                        <Col sm="6">
                                        <Keyword keyword={selectedBoxOperator.keyword} isValid={props.isKeywordValid} />
                                        </Col>
                                    </Row>

                                </TabPane>
                            </TabContent>                     
                        </ModalBody>
                        <ModalFooter>
                            <Col sm="12" align="right">
                            {exit_var?(
                                
                                <Button color="default" size="lg" type="button" onClick={props.toggleModalViewBoxCatalog} >{i18next.t('cancel')}</Button>
                                ):
                                    <Button color="success" size="lg" type="button" onClick={props.toggleModuleModal} >{i18next.t('finish')}</Button>
                                }
                            </Col>
                        </ModalFooter>
                        </Form>
                    </Modal>           
                </React.Fragment>
                    )
        }
        else
        {
            return(<div></div>)
        }

    }
    else
    {
        return(<div></div>)
    }
       
        
  function getHandlerTags(tags){
    const {configBoxOperator} =props.boxReducer;

    let tag = configBoxOperator.tags_categories;
    console.log('Antes de',tag);
    console.log('tags_categories',tags);
    if(tag)
    {
      tag = JSON.parse(tag);
      tag.push(tags);
    }
    else 
      tag=[tags];
    console.log('new_tags_categories',tag);

    props.setChangeConfig('tags_categories',JSON.stringify(tag));
}

function getHandlerRemoveTags(tags){
 
  props.setChangeConfig('tags_categories',JSON.stringify(tags));
}
 function toggleTab(tab) {
    
    // e.preventDefault();
    // console.log('The link was clicked.',e);
     props.changeActiveTab(tab);
 
}
function onchangeTypeCatalog(e)
 {
   
   var type=e.target.value;

   props.setChangeConfig('item_type_short',type);
   props.setChangeConfig('item_type',type+'_catalog');

  
 }
 function onchangeSourceTypeCatalog(e)
 {
   
   var type=e.target.value;

   props.setChangeConfig('source_type',type);

  
 }

 }
 

 
 const mapStateToProps = ({boxReducer,botReducer,homeReducer}) => {
     return {boxReducer, botReducer, homeReducer};
     };
     
     const mapDispatchToProps = {
         iconLoad,
         iconHideLoad,
         toggleModalViewBoxCatalog,
         changeActiveTab,
         setChangeConfig,
         updateVariableBoxOperator,
   
     };
     
     export default connect(mapStateToProps, mapDispatchToProps )(CATMLayout);
