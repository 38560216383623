import React, { Component } from 'react';
import SchemasLayout from './bot-schemas-layout';
import ModalLoad from '../../../components/modals/modal-load';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getHandlerPOST, getHandlerGET, getHandlerPUT } from '../../../utils/handlersApi'
import { cerrarSesion,getRolUser,isAuthorizationValid } from '../../../utils/commonFunction'

import { setSelectedBot, getExportScheme, getDuplicateScheme, getLoadSchema } from '../../../redux/actions/botActions';
import { setVarList } from "../../../redux/actions/boxActions";

import i18next from '../../../../i18n';

class Schemas extends Component {
  _isMounted = false;

  state = {
    isAuth: false,
    modalSchema: false,
    modalEditarSchema: false,
    listSchemas: [],
    hideLoad: 0 ,
    botId:this.props.match.params.id,
    botVersionId:'',
    dataSchema:[],
    initial:'',
    anonimo:'',
    platform:'',
    ia_activated: false,
    isAdmin:false
  }

  constructor(props){
    super(props)          
  }

  componentWillMount(){
    isAuthorizationValid();
    const { isAdmin = false, isSuper = false } = getRolUser();
    if (this._isMounted)
      this.setState({
          isAdmin: isAdmin,
          isSuperUser: isSuper
      });
  }

  componentDidMount(){
    this._isMounted = true;
    this.getBot(this.state.botId);
  }

  componentWillUnmount = () => this._isMounted = false;
  
   /*
    * Función que consulta el de bot de base de datos
    * @botid = int (id del bot)
    */
 
  getBot = (botid) => {
    let cont = this.state.hideLoad;
    if (this._isMounted) this.setState({ hideLoad: cont+1});
    getHandlerGET(HOST_API + '/api/bot/?id=' +botid)
      .then((res)  =>{
        if(res.status==200)
          if (this._isMounted){
            try {
              const { data: { bot: vbot = [] } } = res;

              if (vbot && vbot.length > 0) {
                  const bot = res.data.bot[0];
                  //guardamos en el state la iformación del bot actual y su version
                  this.setState({
                    botVersionId: bot.bot_version[0].botversion_id,
                    bot: bot,
                    company_id: bot.company_id,
                    ia_activated: bot.ia_activated,
                    platform: bot.platform
                });

                this.setSchemaList(this.state.botVersionId);
               
  
                this.props.setSelectedBot(bot.bot_version[0].botversion_id,bot, bot.company_id,bot.ia_activated,bot.platform,this.state.initial);
                
                //Actualiza la lista de variables
               // this.props.setVarList();

                this.setState({ hideLoad: cont-1});
              }

          } catch (error) {
            console.log('Error aqui: ', error.message);

        }
          }
      }).catch((error)=>{
        console.log('Catch GET getBot:',error);
        if (error.response.status == 403)
          cerrarSesion();
      });
  }

  toggleModalNewSchema = () => {if (this._isMounted){ this.setState({ modalSchema: !this.state.modalSchema}) } }
  toggleModalEditSchema = () => {if (this._isMounted) { this.setState({modalEditarSchema: !this.state.modalEditarSchema}) } }
  
  setSchemaList = (id) => {
    let cont = this.state.hideLoad; 
    this.setState({ hideLoad: cont+1});
    
    getHandlerGET(HOST_API +'/api/scheme/botversion/?botv='+id)
        .then((res)=>{
            if(res.status==200){
              var list = [],
              items = [...res.data.data]
              let init = res.data.initial_scheme || '',
                  lastScheme= ''
              let a = items.filter(e => e.id==init); 
              if(res.data.data){
                 res.data.data.map(a=>{
                  lastScheme = a.id
                  let temp = {
                    'idschema':a.id,
                    'title':a.title,
                    'id': this.state.botId,
                    'bot_version': this.state.botVersionId  
                  }
                  list.push(JSON.stringify(temp));
                })  
                if(a.length == 0 && lastScheme != '')
                  this.initialStep(lastScheme);
                if (this._isMounted)  
                  this.setState({ listSchemas: list, initial: init, anonimo:res.data.anonimo_scheme || '', hideLoad: cont-1}); 
              }
            }
        }).catch((error) => {
          console.log('Catch GET setSchemaList:',error);
          if (error.response.status == 403)
            cerrarSesion();
        });
  }
  
  deleteSchema  = (id) => {
    if (confirm(i18next.t('delete_schema'))) {
      let cont = this.state.hideLoad; 
      if (this._isMounted) this.setState({ hideLoad: cont+1});
      var data = { scheme_id: id};
      getHandlerPOST(HOST_API +'/api/scheme/del_scheme/',data)
        .then((res)=>{
          if(res.status==200)
            this.setSchemaList(this.state.botVersionId);
          if (this._isMounted) this.setState({ hideLoad: cont-1});
          }).catch((error)=>{
            console.log('Catch POST deleteSchema:',error);
            if (error.response.status == 403)
              cerrarSesion();
          });
    }
  }

  saveSchema = (event) => {
    event.preventDefault();
    let cont = this.state.hideLoad;
    if (this._isMounted) this.setState({modalSchema: !this.state.modalSchema, hideLoad: cont+1});
    let form = new FormData(event.target);
    let data ={
      title: form.get("title"),
      botversion_id: this.state.botVersionId,
      initial_step: 1,
      content: ''
    }
    getHandlerPOST(HOST_API +'/api/scheme/',data)
      .then((res)=>{
        if(res.status==200)
          this.setSchemaList(this.state.botVersionId); 
        if (this._isMounted) this.setState({ hideLoad: cont-1});
      }).catch((error)=>{
        console.log('Catch POST saveSchema:',error);
        if (error.response.status == 403)
          cerrarSesion();
    });
  }

  editSchema = data => {
    if (this._isMounted)
      this.setState({
        modalEditarSchema: !this.state.modalEditarSchema,
        dataSchema : data
      });
  }

  updateSchema = (event) =>{
    event.preventDefault();
    let cont = this.state.hideLoad;
    if (this._isMounted) this.setState({modalEditarSchema: !this.state.modalEditarSchema, hideLoad: cont+1});
    let form = new FormData(event.target);
    let data ={
      title: form.get("title"),
      scheme_id:form.get('scheme_id')
    }
    getHandlerPOST(HOST_API +'/api/scheme/data_scheme/',data)
      .then((res)=>{
        if(res.status==200)
          this.setSchemaList(this.state.botVersionId);
        if (this._isMounted) this.setState({ hideLoad: cont-1, dataSchema:[]});
      }).catch((error)=>{
        console.log('Catch POST updateSchema:',error);
        if (error.response.status == 403)
          cerrarSesion();
    });
  }

  initialStep = (idSchema) =>{
    let cont = this.state.hideLoad; 
    if (this._isMounted) this.setState({ hideLoad: cont+1});
      var data = {
        id_scheme: idSchema,
        id_botv:this.state.botVersionId
      };
      getHandlerPUT(HOST_API +'/api/scheme/data_scheme/',data)
        .then((res)=>{
          if(res.status==200)
            this.setSchemaList(this.state.botVersionId);
          if (this._isMounted) this.setState({ hideLoad: cont-1});
        }).catch((error)=>{
          console.log('Catch PUT initialStep:',error);
          if (error.response.status == 403)
            cerrarSesion();
        });
  }

  anonymous = idSchema =>{
    var data = {
      id_scheme: idSchema,
      id_botv:this.state.botVersionId
    }
    getHandlerPUT(HOST_API +'/api/scheme/set_anomino/',data)
        .then((res)=>{
            if(res.status==200){
              this.setSchemaList(this.state.botVersionId);
            }
        }).catch((error) => {
          console.log('Catch GET anonymous:',error);
          if (error.response.status == 403)
            cerrarSesion();
        });
  }

  render() {
    return (
      <div>
      <SchemasLayout
        isAdmin={this.state.isAdmin}
        toggleModalNewSchema={this.toggleModalNewSchema}
        modalSchema={this.state.modalSchema}
        saveSchema={this.saveSchema}
        setSchemaList={this.setSchemaList} 
        listSchemas= {this.state.listSchemas}
        deleteSchema={this.deleteSchema}
        editSchema= {this.editSchema}
        modalEditarSchema = {this.state.modalEditarSchema}
        updateSchema = {this.updateSchema}
        toggleModalEditSchema = {this.toggleModalEditSchema}
        dataSchema = {this.state.dataSchema}
        initial = {this.state.initial}
        anonimo = {this.state.anonimo}
        initialStep = {this.initialStep}
        botId={this.state.botId}
        ia_activated = {this.state.ia_activated}
        anonymous = {this.anonymous}
        platform = {this.state.platform}
        getExportScheme = {getExportScheme}
        getLoadSchema = {getLoadSchema}
        getDuplicateScheme = {getDuplicateScheme} />
        <ModalLoad hideLoad={this.state.hideLoad} />
      </div>
    )
  }
}
const mapStateToProps = ({ botReducer,boxReducer,homeReducer,companyReducer, componentMountReducer,loadStateReducer }) => {
  return { botReducer,boxReducer,homeReducer,companyReducer, componentMountReducer,loadStateReducer };
 };
 
 const mapDispatchToProps = {
  setSelectedBot,
  setVarList,
  getExportScheme,
  getDuplicateScheme,
  getLoadSchema,
 };
 
 export default connect(mapStateToProps, mapDispatchToProps)(Schemas);


