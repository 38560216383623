import { NodeModel } from "storm-react-diagrams";
import SMPortModel from "../simplePortModel/SMPortModel";

class SMSModel extends NodeModel {
	extras = {
		title: "SMS",
		notification: {},
		width: 200,
		dbId: 0,
		text: "",
		evento: "",
        evento_text: "",
        mensaje: "",
        celular: ""
    }
    
	removeBox = null
	changeHome = null
	toggleEdit = null
	customClone = null
	
	constructor() {
		super("sms");
		this.addPort(new SMPortModel(false, "left"));
		this.addPort(new SMPortModel(true, "right"));
	}
}

export default SMSModel