import { TEMPLATE_ACTIONS } from '../types/templateActionsTypes';
import { BOX_ACTIONS_TYPES } from '../types/boxActionsTypes';
import { getHandlerGET, getHandlerPOST, getHandlerDELETE, getHandlerPUT } from '../../utils/handlersApi';
import { logOut } from './loginActions';
import { showSuccess, loading, showWarning, hideAlert } from './loadStateActions';
import { cerrarSesion, isEmpty } from '../../utils/commonFunction';

import { find, propEq } from "ramda";
import moment from "moment";
import i18next from '../../../i18n';

export const getAllTemplate = () => async (dispatch) => {
    const URL = `${HOST_API}/api/notification/templates/`;
    dispatch(loading());

    await getHandlerGET(URL)
    .then((res)=>{
        if(res.status == 200){

            let optionsList =  res.data.map((k, index) => {
                if(k.template_name!='')
                    k.value=k.id;
                    k.label=k.template_name;
                    return k;
                })

                console.log("Lista plantillas",optionsList);
                
            dispatch({
                type: TEMPLATE_ACTIONS.getAllTemplate(),
                payload: optionsList
            });
        }
        dispatch(hideAlert());
    })
    .catch((error)=>{
        const { response: { status } = { status } } = error;
        if (status && status == 403) {
          logOut();
          cerrarSesion();
        }
        dispatch(showWarning(i18next.t('message70') + error));
        
    })
};

export const deleteTemplate = (tempid) => async (dispatch) => {
    const URL = `${HOST_API}/api/notification/template/`;
    dispatch(loading());
    await getHandlerDELETE(URL,{tempid})
    .then((res)=>{
        if(res.status == 200){
            dispatch(getAllTemplate());
        }
        dispatch(showSuccess(i18next.t('message71'), 2000));
    })
    .catch((error)=>{
        const { response: { status } = { status } } = error;
        if (status && status == 403) {
          logOut();
          cerrarSesion();
        }
        dispatch(showWarning(i18next.t('message72') + ' \n'+ error));
        
    })
        
};

export const saveTemplate = (data, success) => async (dispatch) => {
    const URL = `${HOST_API}/api/notification/template/`;
    dispatch(loading());
    await getHandlerPOST(URL, data)
    .then((res)=>{
        if(res.status == 200){
            dispatch(showSuccess(i18next.t('message71'), 2000));
            dispatch({
                type: TEMPLATE_ACTIONS.saveUpdateTemplate()
            });
            if(success){
                success();
            }
        }
    })
    .catch((error)=>{
        const { response: { status } = { status } } = error;
        if (status && status == 403) {
          logOut();
          cerrarSesion();
        }
        dispatch(showWarning(i18next.t('message72') + '\n' + error));

    })
        
};

export const imageTemplate = (id_template) => async (dispatch,getState) => {
  
    const {listAllTemplate} = getState().templateReducer;

    const template =find(propEq("id", parseInt(id_template)))(listAllTemplate);
    
    dispatch({
        type: BOX_ACTIONS_TYPES.setUrlMultimediaPreview(),
        payload:template.url_image?template.url_image:'none'
    });
    
    dispatch({
        type: BOX_ACTIONS_TYPES.setContentType(),
        payload: 'image/png'
    });
    
        
};



export const updateTemplate = (data, success) => async (dispatch) => {
    const URL = `${HOST_API}/api/notification/template/`;
    dispatch(loading());
    await getHandlerPUT(URL, data)
    .then((res)=>{
        if(res.status == 200){
            dispatch(showSuccess(i18next.t('message73'), 2000));
            dispatch({
                type: TEMPLATE_ACTIONS.saveUpdateTemplate()
            });
            if(success){
                success();
            }
        }
    })
    .catch((error)=>{
        const { response: { status } = { status } } = error;
        if (status && status == 403) {
          logOut();
          cerrarSesion();
        }
        dispatch(showWarning(i18next.t('message74') + '\n' + error));

    })
        
};



export const getTemplateByiD = (id,success) => async (dispatch, getState) => {
    const URL = `${HOST_API}/api/notification/templates/?tempid=${id}`;
    dispatch(loading());
    
    await getHandlerGET(URL)
    .then((res)=>{
        if(res.status == 200){
            const data = {
                template: JSON.parse(res.data.data.content_json),
                editingTemplate: res.data.data
            }; 
            dispatch({
                type: TEMPLATE_ACTIONS.getTemplateByiD(),
                payload: data
            });
            if (success) {
                success();
            }
        }
        dispatch(hideAlert());
    })
    .catch((error)=>{
        const { response: { status } = { status } } = error;
        if (status && status == 403) {
          logOut();
          cerrarSesion();
        }
      
        dispatch(showWarning(i18next.t('message75') + '\n'+error));
        
    })
};


export const resetTemplate = () => async ( dispatch ) => {
    dispatch({ type: TEMPLATE_ACTIONS.saveUpdateTemplate() });
};

export const resetTempVariablesTemplate = (tempVariablesTemplate) => async ( dispatch ) => {
    
    dispatch({ type: TEMPLATE_ACTIONS.setTempVariableTemplate(),
        payload:tempVariablesTemplate
     });
};

export const updateTempVariableTemplate = (index,value) => async ( dispatch,getState ) => {

    const { tempVariablesTemplate} = getState().templateReducer;
    
    let variablesTemplate = tempVariablesTemplate;

    variablesTemplate[index].value = value;
    
    

    dispatch({
        type: TEMPLATE_ACTIONS.setTempVariableTemplate(),
        payload: variablesTemplate
    });
};


export const setTempVariableTemplate = (key,value) => async ( dispatch,getState ) => {

    const { tempVariablesTemplate} = getState().templateReducer;
    
    let variablesTemplate = tempVariablesTemplate;

    if (variablesTemplate) {
        variablesTemplate.push({
          key: key?key:"",
          value: value?value:"",
        });
      } else {
        variablesTemplate = [
          {
              key: key?key:"",
              value: value?value:"",
          },
        ];
      }

    dispatch({
        type: TEMPLATE_ACTIONS.setTempVariableTemplate(),
        payload: variablesTemplate
    });
};

export const getVariableTemplate = (id_template,variablesLoad) => async (dispatch, getState) => {
    
    const URL = `${HOST_API}/api/notification/template/list_vars/?tempid=`+id_template;
    
    dispatch(loading());

    await getHandlerGET(URL)
    .then((res)=>{
        if(res.status == 200){

            let optionsList =  res.data.vars;

            dispatch(resetTempVariablesTemplate([]));
            
            res.data.vars.map((k, index) => {
                dispatch(setTempVariableTemplate(k,variablesLoad[k]?variablesLoad[k]:''));
            //     if(k!='')
            //         k.value=k.id;
            //         k.label=k.template_name;
            //         return k;
                 })

                console.log("Lista variables",optionsList);
                
            dispatch({
                type: TEMPLATE_ACTIONS.setVariableTemplate(),
                payload: optionsList
            });
        }
        dispatch(hideAlert());
    })
    .catch((error)=>{
        const { response: { status } = { status } } = error;
        if (status && status == 403) {
          logOut();
          cerrarSesion();
        }
        dispatch(showWarning(i18next.t('message75') + error));
        
    })
};
