import React from 'react';
import Admin from '../../admin/containers/admin';
import SliceMenuApp from '../../admin/components/slice-menu';
import { 
  Col,
  Row,
  Button, 
  Container,
  Card,
  FormGroup,
  Label,

} from 'reactstrap';
import ToolbarApp from '../../admin/components/toolbar';
import BotMenuOptions from'../../menus/bot-menu-options';
import './bot-statistics-layout.css';
import Linea from "../../../components/linea";
import Circulo from "../../../components/circulo";
import Date from "../../../components/datepicker";
import i18next from '../../../../i18n';


function BotStatistics(props) {
    // bot info
    var data = {};
  return (
    <div className="BotLayout">

          <Admin>
            <SliceMenuApp>
                <BotMenuOptions botId={props.botId} ia_activated={props.ia_activated}/>
            </SliceMenuApp>
            <Container fluid>
                <ToolbarApp>
                    <Row>
                        <Col sm="3" md="3" className="text pdtoolbar">
                            <h2 className="text-left ml-4 font-weight-bold">{i18next.t('stadistics')} <span className="btn-help"></span></h2>                      
                        </Col>
                    </Row>
                </ToolbarApp>
            </Container>
            <Container fluid className="box-fluid">
                <Col sm="12" md="10"  className="stadistics-container  offset-md-1">
                    <Row>
                        <Col lg="10" className="offset-lg-1 pt-5">
                            <Row className="card-information pb-5"> 
                                <Col className="col-12">
                                     <h2 className="font-weight-bold">{i18next.t('dashboard_general')}</h2>
                                </Col>
                                <Col md={{ size: '4', offset: 2 }} className="pt-4">
                                    <Card>
                                        <Row className="no-gutters">
                                            <Col md={12} className="title-card"><p>{i18next.t('tecnology')}</p></Col>
                                            <Col md={12} className="img-tecnologias"><p><img src="/static/img/facebook.png" className="img-fluid"/><img src="/static/img/rcs.png" className="img-fluid" /><img src="/static/img/web.png" className="img-fluid" /></p></Col>
                                        </Row>
                                    </Card>
                                </Col>
                                
                                <Col md={4}>
                                    <Card>
                                        <Row className="no-gutters">
                                            <Col md={12}>
                                                <Row>
                                                    <Col md={5} className="d-flex align-items-center justify-content-center"><span className="number-card">{props.user}</span></Col>
                                                    <Col md={7} className="icon-card"><img src="/static/img/usuarios_nuevos.png" />   </Col>
                                                </Row>
                                            </Col>
                                            <Col md={12} className="footer-card"><p>{i18next.t('total_users')}</p></Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs="12" md="12" lg={{size:'10',offset:1}} className="pt-3 pb-5">
                            <Row className="filter-information">
                                    <Col xs={12}>
                                        <Row>
                                            <Col xs={12} md={12} lg={3} className="Label-filter d-flex align-items-center justify-content-center">
                                                <Label>{i18next.t('interval_dates')}</Label>
                                            </Col>
                                            <Col xs={12} md={12} lg={7} className="date-picker-filter d-flex align-items-center justify-content-center">
                                                <FormGroup>
                                                    <Date startDate={props.startDate} endDate={props.endDate} handleChangeStart={props.handleChangeStart} handleChangeEnd={props.handleChangeEnd}/> 
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} md={12} lg={2} className="d-flex align-items-center justify-content-center">
                                                <Button className="btn filter-btn pl-5 pr-5 btn-guarumo-md" onClick={props.getData}>{i18next.t('filter')}</Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                        </Col>
                        <Col xs="12">
                                <Row className="graphic"> 
                                    <Col xs={12} md={12} lg={4}>
                                        <Circulo data={props.data1} /> 
                                    </Col>
                                    <Col xs={12} md={12} lg={4}>
                                        <Linea data={props.data2}  /> 
                                    </Col>
                                    <Col xs={12} md={12} lg={4}>
                                        <Linea data={props.data3}  /> 
                                    </Col>
                                </Row>
                            </Col>
                    </Row>
                </Col>
             </Container>
          </Admin>      
    </div>
  )
}

export default BotStatistics