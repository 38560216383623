import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Col,
  Row,
  Container,
  Form,
  Button,
  Input,
  Label,
  FormGroup,
  UncontrolledTooltip
 } from 'reactstrap';
 import Admin from '../../admin/containers/admin';
 import SliceMenuApp from '../../admin/components/slice-menu';
 import ToolbarApp from '../../admin/components/toolbar';
 import BotMenuOptions from'../../menus/bot-menu-options';
 import './bot-ia-layout.css';
 import DataTableFilter from "../../analytics/infoViz/common/dataTableFilter";
 import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
 import { faPen, faTrash, faSave, faArrowLeft, faTimes, faCheck } from '@fortawesome/free-solid-svg-icons'
 import { find, propEq } from "ramda";
 import qs from 'qs';
 import i18next from '../../../../i18n';
 import {linkingIA,setChangeState,setChangeStateEntity,intentFormSubmit,getIntentInfo,setChangeHideEntity} from '../../../redux/actions/botActions';



function BotIALayoutModule(props){

    const {listIntent,showEdition,showConfig,showEditionEntity,questions,answers,idAnswers,nameModule} = props.botReducer;

    let cantidadTotal = 0;

    var styles ={
        height: {
            width: 100 
        } 
    };

    let handleClick = value => {
        console.log("value", value);
        const { id } = value;
       
      };

  let operator = props.uaQuestions || [];

  let selectedQ = [],allData=[], removeAll=false;
      
  /*AGREGADO EL DIA 13 DE MARZON* */
  const [fileName, setFileName] = useState(''); // Estado para almacenar el nombre del archivo seleccionado
    const updateFileName = (event) => {
        const file = event.target.files[0];
        if (file) {
            setFileName(file.name);
        }
    };
  /*FIN* */

  const [formData, setFormData] = useState({
    question: '',
    answer: ''
  });


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const [questionsArray, setQuestionsArray] = useState([]);
  const [answerArray, setAnswersArray] = useState([]);
  const [answerName, setAnswerName] = useState('');
  const [answerID, setAnswerId] = useState('');

  useEffect(() => {
    
      // Inicializar questionsArray con el valor de props.botReducer.questions
      if (props.botReducer.questions) {
          setQuestionsArray(props.botReducer.questions);
      }

      // Inicializar answerArray con el valor de props.botReducer.answers
      if (props.botReducer.answers) {
          setAnswersArray(props.botReducer.answers);
      }

      if(props.botReducer.nameModule){
         setAnswerName(props.botReducer.nameModule);
      }

      if(props.botReducer.idAnswers){
        setAnswerId(props.botReducer.idAnswers);
      }

  }, [props.botReducer.questions, props.botReducer.answers,props.botReducer.idAnswers,props.botReducer.nameModule]); // Ejecutar efecto cuando cambien las preguntas o respuestas


    const handleSubmit = (event) => {
        event.preventDefault();
        const updatedQuestionsArray = [...questionsArray, formData.question];
        setQuestionsArray(updatedQuestionsArray);
        setFormData({ question: '' }); // Limpiar el campo de texto después de guardar
    };

    const removeQuestion = (index) => {
        const updatedQuestionsArray = [...questionsArray];
        updatedQuestionsArray.splice(index, 1);
        setQuestionsArray(updatedQuestionsArray);
    };


    const handleSubmitAnswer = (event) => {
        event.preventDefault();
        const updatedAnswersArray = [...answerArray, formData.answer];
        setAnswersArray(updatedAnswersArray);
        setFormData({ answer: '' }); // Limpiar el campo de texto después de guardar
    };


    const removeAnswer = (index) => {
        const updatedAnswersArray = [...answerArray];
        updatedAnswersArray.splice(index, 1);
        setAnswersArray(updatedAnswersArray);
    };


    // Función para manejar el envío del formulario
    /*const handleSubmit = (event) => {
        event.preventDefault();
        // Aquí puedes enviar los datos del formulario a donde sea necesario
        props.intentFormSubmit(formData); // Enviar datos al manejador linkingIA
      };*/

    return (
        <div className="BotLayout">
          <Admin>
            <SliceMenuApp>
              <BotMenuOptions botId={props.botId} ia_activated={props.ia_activated}/>
            </SliceMenuApp>
            <Container fluid>
                <ToolbarApp>
                    <Row>
                        <Col xs="12" className="text pdtoolbar">
                            <h2 className="text-left ml-4 font-weight-bold">{i18next.t('module_ia')} </h2>                      
                        </Col>
                    </Row>
                </ToolbarApp>
            </Container>
            <Container fluid className="box-fluid">
                <Row>
                    <Col sm="12" md="10" className="ia-container offset-md-1">
                       <Col md="12" className="card-configuration card">
                       {showConfig? (
                        <Row id="configuration_ia-panel">
                            <Col md="12">
                                <Form onSubmit={props.linkingIA}>
                                    <Col md="12">
                                        <Row>
                                            <Col md="6" lg="8">
                                                <h2 className="font-weight-bold">{i18next.t('link_bot')}</h2>
                                            </Col>
                                            <Col md="6" lg="4">
                                                <Row>
                                                    <Col className="button-config">
                                                        <Button type="button" className="btn btn-block btn-block-secundary" onClick={() => props.setChangeState()}>{i18next.t('cancel')}</Button>
                                                    </Col>
                                                    <Col className="button-config">
                                                        <Button type="submit" className="btn btn-guarumo-md btn-block">{i18next.t('connect')}</Button>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md="12">
                                            <FormGroup>
                                                <Label for="exampleInputEmail1">Id Dialogflow</Label>
                                                <Input type="text" id="id_Dialogflow_text" name="dialogflow_id" placeholder={i18next.t('ia_message2')} className=""/>
                                            </FormGroup>
                                            <FormGroup className="form-group col-12" style={{paddingLeft:'0px'}}>
                                                <Label for="fileBotid" 
                                                        className="mt-4 btn btn-outline-secondary"
                                                        style={{cursor: "pointer"}}>
                                                            {i18next.t('file_selected')} {' '} {/*i18next.t('config_file')*/}
                                                </Label>
                                                <Input type="file" 
                                                       name="json_config" 
                                                       id="fileBotid" 
                                                       style={{ display: 'none' }}
                                                       onChange={updateFileName}/>
                                                       {fileName && <span style={{color: '#000000', marginLeft: '8px' }}>{fileName}</span>}
                                            </FormGroup>
                                    </Col>
                                </Form>

                                
                            </Col>
                        </Row>
                        ):null}

                        { showEdition? (
                        <Row id="edition_ia-card">
                            <Col md="12" className="pt-5"> 
                                <Row>
                                    <Col md="6">
                                        <h2 className="font-weight-bold">{i18next.t('configure_modules')}</h2>
                                    </Col>
                                    <Col md="6">
                                    <Row className="button-main">
                                        <Col className="col-6">
                                            <Button type="button" color="secondary" className="btn btn-block btn-config"  onClick={() => props.setChangeState()}>{i18next.t('config_edit')}</Button>
                                        </Col>
                                        <Col className="col-6">
                                            <Button type="button" className="btn btn-guarumo-md btn-block" onClick={() => props.setChangeStateEntity()}>{i18next.t('create_module')}</Button>
                                        </Col>
                                    </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <div className=" mb-4"> 
                                    <h2 className="titleh2 font-weight-bold">{i18next.t('created_modules')}</h2> 
                                    <div className="panel-body-module_ia">
                                    {listIntent.map((item,index) => 
                                    <Form key={index}> 
                                        <Input type="hidden" defaultValue={item.id} name="id" hidden/>
                                        <Button key={index} type="button" onClick={() => props.getIntentInfo(item.id)} className='list-group-item list-group-item-action modules_intents card-body' > {item.name}</Button>
                                    </Form>
                                        )}
                                    </div>
                                </div>
                         
                            </Col>
                        </Row>
                        ):null}

                        { showEditionEntity? (
                        <Row id="ia_edition_entity" className="ia_edition_entity">
                            <Col md="12" className="pt-4">
                                <h2 className="titleh2 font-weight-bold">{i18next.t('new_module')}</h2>
                            </Col>
                            
                            <Col md="12">
                                <Form id="form-intent-submit" onSubmit={props.intentFormSubmit}>
                                    <Row>
                                        <Col md="8" lg="7">
                                            <Label for="exampleInputEmail1">{i18next.t('module_name')}</Label>
                                            <Input type="hidden" defaultValue={idAnswers != ''?idAnswers:''} name="id" hidden/>
                                            <Input className="" name="name" type="text" defaultValue={nameModule != ''?nameModule:''} placeholder={i18next.t('module_name')} />
                                            <Input className="hidden" name="question" type="hidden"  value={questionsArray} />
                                            <Input className="hidden" name="answer" type="hidden"  value={answerArray} /> 
                                        </Col>
                                        <Col md="4" lg="5" className="button-form row">
                                            <Col md="6" className="p-2">
                                                <Button type="button" color="secondary" className="btn btn-block" onClick={() => props.setChangeHideEntity()}>{i18next.t('back')}</Button>
                                            </Col>
                                            <Col  md="6" className="p-2">
                                                <Button type="submit" className="btn btn-guarumo-md btn-block">{i18next.t('save')}</Button>
                                            </Col>
                                            
                                            
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                            
                            <Col md="12" className="module_questions">
                                <div>	
                                    <Label>{i18next.t('question_user')}...</Label>
                                    <Form className="form-wrapper" onSubmit={handleSubmit}>
                                        <input type="text" 
                                               name="question" 
                                               placeholder={i18next.t('question_write')} 
                                               value={formData.question}
                                               onChange={handleInputChange}
                                               required />
                                        <button type="submit" className="btn-save"><i className="fa fa-save"> </i></button>
                                    </Form>
                                    <div className="preguntas_ia">
                                    {questionsArray.map((question, index) => (
                                        <Form key={index}>
                                            <div className='list-group-item list-group-item-action card-body-question' onMouseEnter={props.showOptions} onMouseLeave={props.hideOptions}>
                                                {question}
                                                <Input type="hidden" defaultValue={index} name="index" hidden/> 
                                                <button type='button' onClick={() => removeQuestion(index)} className='btn btn-danger pull-right btn-delete'> 
                                                    <i className="fa fa-1x fa-trash"></i>
                                                </button>
                                            </div>
                                        </Form>
                                    ))}
                                    </div>
                                </div>
                                <div className="module_questions  mt-4">	
                                    <Label>{i18next.t('bot_can_response')}</Label>
                                    <Form className="form-wrapper" onSubmit={handleSubmitAnswer}>
                                        <input type="text" 
                                               name="answer" 
                                               className="answer_input" 
                                               placeholder={i18next.t('write_response')} 
                                               required
                                               value={formData.answer}
                                               onChange={handleInputChange}/>
                                        <button type="submit" className="btn-save"><i className="fa fa-save"> </i></button>
                                    </Form>
                                    <div className="respuestas_ia col-12" >
                                    {answerArray.map((item,index) => 
                                        <Form key={index}>
                                            <div className='list-group-item list-group-item-action card-body-question' onMouseEnter={props.showOptions} onMouseLeave={props.hideOptions} >
                                                {item}
                                                <Input type="hidden" defaultValue={index} name="index" hidden/> 
                                                <button type='button' onClick={() => removeAnswer(index)} className='btn btn-danger pull-right btn-delete'> 
                                                <i className="fa fa-1x fa-trash"> </i>
                                                </button>
                                            </div>
                                        </Form>
                                        )}  
                                    </div>
                                </div>
                                    
                            </Col>
                        

                            { props.currentIntent.id ? (
                            <Col>
                                <Row>
                                <Col xs="6" className="pt-4">
                                        <h2 className="font-weight-bold">{i18next.t('delete_module')}</h2>
                                </Col> 
                                <Col xs="6" className="text-right">
                                <Form onSubmit={props.deleteIntent}>
                                        <Input type="hidden" defaultValue={props.currentIntent.id} name="id" hidden />
                                        <Button type='submit' className='btn btn-danger btn-16' style={{'fontSize':'14px','marginTop':'10px'}}> {i18next.t('delete_module')}</Button>
                                </Form>
                                </Col> 
                                </Row>
                            </Col>
                            ): null}
                        </Row>
                        ):null}
                       </Col>
                    </Col>
                </Row>
            </Container>
          </Admin>      
    </div>
    );
}
  
const mapStateToProps = ({ botReducer,boxReducer,homeReducer,companyReducer, componentMountReducer,loadStateReducer }) => {
    return { botReducer,boxReducer,homeReducer,companyReducer, componentMountReducer,loadStateReducer };
   };
   
   const mapDispatchToProps = {
        linkingIA,setChangeState,setChangeStateEntity,intentFormSubmit,getIntentInfo,setChangeHideEntity
   };
   
   export default connect(mapStateToProps, mapDispatchToProps)(BotIALayoutModule);