import React, { Component } from 'react';
import axios from 'axios';
import {  
    FormGroup, 
    Input, 
    Col,
    Row,
    Label,
    UncontrolledTooltip
} from 'reactstrap';
import i18next from '../../i18n';


class SchemesSelector extends Component {

    state = {
        schemesList: [],
        schema:this.props.schema
    }
    
    constructor(props){
        super(props);
        this.updateShemaData = this.updateShemaData.bind(this);
    }


    /***** **** **** **** **** **** 
                    AJAX HANDLERS */

     // GET requests handler
     getHandler(url){
        var auth=JSON.parse(localStorage.getItem('Authorization'));
        var headers={
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': 'Bearer '+auth.access_token
        }

        return axios({
          method:'get',
          url: HOST_API +url,
          headers:headers
        })
     }

    // Create a list schemes Handler
    getSchemesList () {
        this.getHandler("/api/scheme/botversion/?botv="+this.props.botversion_id).
            then((res) =>{
                if(res.status==200){
                    var list = [];
                    if(res.data.data){
                        for(let i=0; i<res.data.data.length;i++) {
                            /* 
                                Ajusto objeto, renombro el id (idschema) y 
                                se agrego botId (id)
                            */
                            let temp ={
                                'idschema':res.data.data[i].id,
                                'title':res.data.data[i].title,
                                'id': this.state.botId 
                            }
                            list.push(temp);
                        }
                    }
                    this.setState({ schemesList: list}); 
                }else{
                    console.log(" No se pudo obtener la lista");
                }
            }).catch((error)=> {
                console.log(" catch getSchemesList",error);
            });
    }

    bindValue = () => {
        if(typeof this.props.bindValue === "function")
             this.props.bindValue(this.state.schema,this.props.k?this.props.k:'');
    }
    updateScheme = (event) => {
        this.setState({schema: event.target.value}, () => {
            this.bindValue();
        });
    }

    updateShemaData(){
        if(this.props.schemeSeleccion1){
                this.setState({schema: this.props.schemeSeleccion1}, () => {
                this.bindValue();
            });
        }
    }

    componentDidMount = () => {
        this.setState({ schema: this.props.schema});
        this.getSchemesList();
        this.updateShemaData();
    }

  
    render() {
        const list = this.state.schemesList;
        const options = list.map((item, index) => {    
            return <option key={index + 1} value={item.idschema} >{item.title}</option>
        });

        return (
            <Row>
            
                <Col sm="12">
                    <FormGroup>
                        <Label>{i18next.t('flows')} <i className="fa fa-info-circle" id="tooltipSchema"></i></Label>
                        <UncontrolledTooltip placement="right" target="tooltipSchema">{i18next.t('message40')}</UncontrolledTooltip>
                        <Input type="select" name={this.props.k?this.props.k:"select"} onChange={(event) => { this.updateScheme(event) }} value={this.state.schema?this.state.schema:this.props.schemeSeleccion1}>
                            <option value='' key={0}>{i18next.t('select_one')}</option>
                            {
                                options
                            }
                        </Input>
                    </FormGroup>
                </Col>
            </Row>
        )

        
    }
}

export default SchemesSelector