import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import DataTableFilter from '../../pages/analytics/infoViz/common/dataTableFilter';
/*DATA TABLE FILTER AXIOS*/
import DataTableAxiosFilter from '../../pages/analytics/infoViz/common/dataTableAxiosFilter';
/*END DATA TABLE FILTER AXIOS*/
import i18next from '../../../i18n';

import { 
    Col,
    Row,
    Button,
    ModalBody, 
    Input,
    Label,
  } from 'reactstrap';

import { DATABASE_ACTIONS } from '../../redux/types/databaseActionTypes'
import { deleteCompany } from '../../redux/actions/companyActions';
import { uploadFileDinamic, selectCompanyAction, uploadFileDinamicUpdate, showDatabaseCollect, 
        uploadFileDinamicVisualizar, filtrarCollection } from '../../redux/actions/databaseAction';
        
import IconButton from '@material-ui/core/IconButton';
import FilterListIcon from '@material-ui/icons/FilterList'; // Importa el icono FilterList


 
function Filter(props){

    const { companyReducer: { listAllCompanies = [] }, deleteCompany } = props;
    const { listCollection, visualizarCollection, sinRegistroFiltro, nombreCompany, visualizarCollectionPagination } = props.databaseReducer;
    const [searchSectionVisible, setSearchSectionVisible] = useState(false);

    const [filtroFechaInicio, setFiltroFechaInicio] = useState('');
    const [filtroFechaFin, setFiltroFechaFin] = useState('');


    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage,setRowsPerPage] = useState(20);

    useEffect(() => {
      /*if(visualizarCollectionPagination.registrosFiltros == ''){
      }else{
          handlePageChange(page)
      }*/
        handlePageChange(page,rowsPerPage)
        setPage(page)
        setData(visualizarCollection);
    }, [page, rowsPerPage]);

    useEffect(() => {
      // Obtén la fecha actual
      const fechaActual = new Date();

      // Establece el formato de fecha de hoy desde las 00:00:00 a las 23:59:59
      const fechaInicioHoy = new Date(fechaActual.getFullYear(), fechaActual.getMonth(), fechaActual.getDate(), 0, 0, 0);
      const fechaFinHoy = new Date(fechaActual.getFullYear(), fechaActual.getMonth(), fechaActual.getDate(), 23, 59, 59);

      // Formatea las fechas y establece los valores predeterminados
      setFiltroFechaInicio(formatarFecha(fechaInicioHoy));
      setFiltroFechaFin(formatarFecha(fechaFinHoy));
      setData(visualizarCollection);
    }, []); // El efecto se ejecuta solo una vez al montar el componente
    
    const handlePageChange = async (page, rowsPerPage) => {
      console.log("QUIEN LLAMAR")
      page = Number(page) + 1;
      await props.uploadFileDinamicVisualizar(nombreCompany, page, rowsPerPage);
    };

    const handleFechaInicioChange = (event) => {
      const fechaSeleccionada = new Date(event.target.value+"T00:00:00");
      setFiltroFechaInicio(formatarFecha(fechaSeleccionada));
    };
    
    const handleFechaFinChange = (event) => {
      const fechaSeleccionada = new Date(event.target.value+"T23:59:59");
      setFiltroFechaFin(formatarFecha(fechaSeleccionada));
    };
    
    // Función para formatear la fecha
    const formatarFecha = (date) => {
      const año = date.getFullYear();
      const mes = String(date.getMonth() + 1).padStart(2, '0');
      const dia = String(date.getDate()).padStart(2, '0');
      const horas = String(date.getHours()).padStart(2, '0');
      const minutos = String(date.getMinutes()).padStart(2, '0');
      const segundos = String(date.getSeconds()).padStart(2, '0');
    
      return `${año}-${mes}-${dia}T${horas+':'}${minutos}:${segundos}`;
    };

    

  {/* ==============================================
              VOLVER DINAMIDO
    =============================================== */}
    
    let resultado = [];

    const [formValuesDinamic, setFormValuesDinamic] = useState({});

    const reorderedKeysVisualizar = visualizarCollection && visualizarCollection.length > 0 ? 
      Array.from(new Set(visualizarCollection.reduce((allKeys, obj) => {
        // Obtenemos las claves de cada objeto
        const keys = Object.keys(obj);
        // Agregamos las claves al conjunto (set)
        return allKeys.concat(keys);
      }, []))) : [];
      
          // Reorder keys to move 'created' to the end
    const reorderKeys = (keys, keyToMove) => {
      // Find the index of the key to move
      const index = keys.indexOf(keyToMove);
      if (index !== -1) {
          // Remove the key from its current position
          const [removedKey] = keys.splice(index, 1);
          // Append the key to the end of the array
          keys.push(removedKey);
      }
      return keys;
    };

    // Reorder the keys such that 'created' is at the end
    const keysVisualizar = reorderKeys(reorderedKeysVisualizar, 'created');

    const visualizarCollectionArray = visualizarCollection && visualizarCollection.length > 0?visualizarCollection:[];
   
    const columnsVisualizar = keysVisualizar.length > 0 ? keysVisualizar.map((field) => ({
      label: field,
      field: field,
      sort: 'asc',
    })) : [];

    if (sinRegistroFiltro) {
        // Transform each row in visualizarCollectionArray to an array of values matching columnsVisualizar
        resultado = visualizarCollectionArray && visualizarCollectionArray.length>0 ? visualizarCollectionArray.map((row) => {
            // Create an array for each row
            let transformedRowArray = [];
            // Add values in the order of columnsVisualizar fields
            columnsVisualizar.forEach((column) => {
                const key = column.field;
                // Include the value for each key, or 'N/A' if it's undefined
                const value = row[key] !== undefined ? row[key] : 'N/A';
                transformedRowArray.push(value);
            });
            return transformedRowArray;
        }): [] ;
    } else {
        resultado = [];
    }
      

    const handleChangeDinamic = (e) => {
      
      let { name, value } = e.target;
      
      const newData = { ...formValuesDinamic, [name]: value }
      
      for (const key in newData) {
        if (newData[key] === '') {
          delete newData[key];
        }
      }

      setFormValuesDinamic(newData);

    };


    const handleSubmitDinamic = async (e) => {
      e.preventDefault();
      if (filtroFechaInicio && filtroFechaFin) {
        formValuesDinamic.created = `${filtroFechaInicio}|${filtroFechaFin}`;
        formValuesDinamic.nameCompany = nombreCompany
      } else if (filtroFechaInicio) {
        formValuesDinamic.created = `${filtroFechaInicio}`;
        formValuesDinamic.nameCompany = nombreCompany
      } else if (filtroFechaFin) {
        formValuesDinamic.created = `${filtroFechaFin}`;
        formValuesDinamic.nameCompany = nombreCompany
      }else{
        formValuesDinamic.nameCompany = nombreCompany
      }
      await props.filtrarCollection(formValuesDinamic);
    };
  
    {/* ========================================
              FIN VOLVER DINAMIDO
    =========================================== */}

    var title = ''


    {/*=======================================
          INFORMACION PARA TABLA VISUALIZAR
      ======================================== */}
    let titleTable ='';

    let optionsTable = {
        filterType: 'dropdown', // Habilitar menús de filtrado
        filter: false,
        selectableRows:'none',
        resizableColumns: false,
        responsive: "scrollMaxHeight",
        serverSide: true,
        count: visualizarCollectionPagination.total_records,
        page: page,
        rowsPerPage: rowsPerPage,
        onTableChange: (action, tableState) => {
            if (action === 'changePage') {
                setPage(tableState.page);
            } else if (action === 'changeRowsPerPage') {
              // Si se cambia el número de registros por página, actualiza `rowsPerPage` y realiza una nueva consulta
              setRowsPerPage(tableState.rowsPerPage);
              setPage(0); // Reiniciar la página a 0 para la nueva consulta
            }
        },
        print:true,
        download: true, // Habilitar la opción de descarga
        viewColumns:false,
        search: true, 
        customToolbar: () => {
          return (
            <div>
              <IconButton onClick={() => toggleSearchSection()}>
                <FilterListIcon />
              </IconButton>
            </div>
          );
        },
    };
    
    const toggleSearchSection = () => {
      setSearchSectionVisible(!searchSectionVisible);
    };

      for (let i = 0; i < resultado.length; i++) {
        let subarray = resultado[i];

        for (let j = 0; j < subarray.length; j++) {
          let elemento = subarray[j];

          if (typeof elemento === 'object' && elemento !== null) {
            // Verifica si el elemento es un objeto y no es nulo
            subarray[j] = JSON.stringify(elemento);
          }
        }
      }

    
    {/*=======================================
        END INFORMACION PARA TABLA VISUALIZAR
      ======================================== */}

    return (
        <ModalBody>
            {searchSectionVisible && (
              <div className="search-section" style={{ maxHeight: '200px', overflowY: 'auto' }}>
                <Row>
                <Col xs={4} lg={4}>
                <Label for="fechaInicio">{i18next.t('start_date')+': '}</Label>
                  <Input
                    key="fechaInicio"
                    type="date"
                    placeholder={i18next.t('start_date')}
                    name="fechaInicio"
                    id="fechaInicio"
                    value={filtroFechaInicio.slice(0, 10)} // Muestra solo la parte de la fecha (sin la hora)
                    onChange={handleFechaInicioChange}
                  />
                </Col>
                <Col xs={4} lg={4}>
                <Label for="fechaFin">{i18next.t('end_date')+': '}</Label>
                  <Input
                    key="fechaFin"
                    type="date"
                    placeholder={i18next.t('end_date')}
                    name="fechaFin"
                    id="fechaFin"
                    value={filtroFechaFin.slice(0, 10)} // Muestra solo la parte de la fecha (sin la hora)
                    onChange={handleFechaFinChange}
                  />
                </Col>
                    {columnsVisualizar.map((column) => (
                    <Col xs={4} lg={4} >
                        <Input
                        key={column.field}
                        type="text"
                        placeholder={column.field}
                        name={column.field}
                        id={column.field}
                        value={formValuesDinamic[column.field] || ''}
                        onChange={handleChangeDinamic}
                        />
                    </Col>
                    ))}
                    <Col xs={12} lg={12} className="mb-3">
                        <Button className="btn-block" color="success" size="lg" type="button" onClick={(e) => handleSubmitDinamic(e)}>{i18next.t('search')}</Button>
                    </Col>
                </Row>
              </div>
            )}

            <Row >
                <Col xs={12} lg={12} >
                {
                    <Row >
                        <Col xs={12} lg={12} >
                            <DataTableAxiosFilter
                                title={titleTable}
                                data={resultado}
                                columns={columnsVisualizar}
                                options={optionsTable}
                            />
                        </Col>
                    </Row>
                }
                </Col>
            </Row>
        </ModalBody>
    );
}


const mapStateToPros = ({companyReducer, databaseReducer}) => ({companyReducer, databaseReducer})

const mapDispatchToProps = {
    deleteCompany,
    uploadFileDinamic,
    uploadFileDinamicUpdate,
    selectCompanyAction,
    showDatabaseCollect,
    uploadFileDinamicVisualizar,
    filtrarCollection
}; 

export default connect(mapStateToPros, mapDispatchToProps)(withRouter(Filter))