import React from 'react';
import FilterBar from './d3Components/FilterBar';

class convertFilterBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {d3: ''}
  }

  componentDidMount() {
    this.setState({d3: ''});
  }

  render() {
    
    return(
    
        <FilterBar nameDiv={this.props.nameDiv} group={this.props.group} botID={this.props.botID} dateini={this.props.dateini} datefin={this.props.datefin} color={this.props.color} />
    )
  }
}
export default convertFilterBar;