import React, { Component } from 'react';
import KeywordLayout from './keyword-layout';

class Keyword extends Component {

      state = {
        valid: true
      }
  
      constructor(props) {
          super(props);
      }

      valid = (texto)=> {
        var regexp = /^[A-Z]+$/;
        this.setState({valid:!(texto.length > 0 && !texto.match(regexp))}, ()=>{
            this.forceUpdate();
            this.props.isValid(this.state.valid);
        });
      }

      updateKeyword = (event) => {        
        const v = event.target.value;
        this.valid(v);        
        this.props.updateKeyword(v);
        this.forceUpdate();
    }
  
      render() {
          return (
              <div>
                  <KeywordLayout 
                  keyword={this.props.keyword}
                  valid={this.state.valid}
                  updateKeyword={this.updateKeyword} />
              </div>
              
          )
      }

  }
  
  export default Keyword
