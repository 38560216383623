import React, { Component } from 'react';
import {  
    FormGroup, 
    Input, 
    Col,
    Row,
    Label,
    UncontrolledTooltip
} from 'reactstrap';

import i18next from "../../i18n";

class CheckBoxEditable extends Component {

    state = {
        enabled: this.props.isVariable,
        name_variable: this.props.name_variable
    }

    constructor(props){
        super(props);
    }

    bindValue = () => {
        if(typeof this.props.bindValue === "function")
             this.props.bindValue(this.state.enabled,this.state.name_variable);
    }

    updateCheck = (e) => {
        
        this.setState({enabled: !this.state.enabled,name_variable:''}, () => {
            this.bindValue();
        });
    }

    updateNameVariable = (event) => {
        this.setState({name_variable: event.target.value}, () => {
            this.bindValue();
        });
    }

    componentDidMount = () => {
       // this.setState({ title: this.props.titleOperator});
    }


    render() {

        return (
            <Row>
                <Col sm="12">
                    <FormGroup>
                        <Label>{i18next.t('use_var_as')}  <i className="fa fa-info-circle" id="tooltipVariable"></i></Label>
                        <UncontrolledTooltip placement="right" target="tooltipVariable"> {i18next.t('define_name_varible')}</UncontrolledTooltip>
                        <br />  
                        <Label check>
                                <Input type="checkbox" id="checkbox_variable" className="inputModal" style={{marginLeft:'1px', marginTop:'10px'}} checked={this.state.enabled} onChange ={(e)=>this.updateCheck(e)}  />{''}
                                <Input type="text" name="name_variable" value={this.state.name_variable} disabled={!this.state.enabled} onChange={(event) => { this.updateNameVariable(event) }} placeholder={i18next.t('define_name_varible')}  maxLength={'50'} style={{marginLeft:'25px'}}/>
                        </Label>   
                    </FormGroup>
                </Col>
            </Row>
        )
    }
}

export default CheckBoxEditable