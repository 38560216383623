import React, {Component} from 'react';
import { connect } from "react-redux";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

import { Label,Button,Modal,ModalHeader,ModalBody,ModalFooter,Form,FormGroup,Input,Col,Row,Nav,NavItem,TabContent,TabPane,NavLink,UncontrolledTooltip,Alert} from 'reactstrap';
import IASelector from '../../../../components/ia';
import Keyword from '../../../../components/keyword/keyword-redux';
import Mentions from '../../../../components/mentions/metions-redux';
import MentionsAlone from '../../../../components/mentions/metions-alone';
import Avatar from '../../../../components/avatar';
import TitleOperator from "../../../../components/title-operator";
import Emoji from '../../../../components/emoji-picker';
import classnames from 'classnames';
import TabAdvanced from "../../../../components/box/tab_advanced";
import i18next from '../../../../../i18n';

import { isEmpty} from "../../../../utils/commonFunction";
import { 
        setSelectedTypeLocation, 
        toggleModalViewBoxLocation,
        changeActiveTab,
        setChangeConfig,
        setSelectedListLocation,
        setInitialDinamicVariables,
        setFormatVariables,
        updateVariableBoxOperator,
        addNewStatementBlock,
        removeStatementBlock,
        onchangeListColumnsLocation,
        } from "../../../../redux/actions/boxActions";



let  item_type;


function LCMLayout(props) {
   
    
     const {node,selectedBoxOperator,varList,listTypeLocation,selectedTypeLocation,listLocation,selectedListLocation, configBoxOperator, modalViewBoxLocation,activeItem,variablesBoxOperator,dinamicVariablesBoxOperator,avatarid,botmsg_text,exit_var} =props.boxReducer;

    const { bot,botVersionId } = props.botReducer;
    item_type = null;
    
    if(selectedBoxOperator&&Object.keys(selectedBoxOperator).length>0&&selectedBoxOperator.operatortype_id==20)
    {

            if(configBoxOperator&&configBoxOperator.item_type)
            {
                item_type = selectedTypeLocation.type;//configBoxOperator.item_type;
               
                
                let variables = variablesBoxOperator;

                let kblocksVar = variables?Object.keys(variables):[];

                let dinamicVariables = dinamicVariablesBoxOperator;
                let kblocksDinVar = []
                
                if(dinamicVariables)
                    kblocksDinVar = dinamicVariables?Object.keys(dinamicVariables):[];
        

                return (
                    <React.Fragment>
                    <Modal className="location"
                    isOpen={modalViewBoxLocation}
                    toggle={props.toggleModalViewBoxLocation} 
                    // isOpen={props.isOpen} toggle={props.closeModal} 
                    fade size="lg">
                        <ModalHeader toggle={props.toggleModalViewBoxLocation}>{i18next.t('set_up')} {i18next.t('location')} - {node.id}</ModalHeader>
                        <Form id="LCM-form" role="form">  
                        <ModalBody>
                        <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeItem === '1' })}
                                        onClick={(e) => { toggleTab('1'); }} >
                                        {i18next.t('basic')}
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                <NavLink
                                    className={classnames({ active: activeItem === '2' })}
                                    onClick={(e) => { toggleTab('2'); }}>
                                    {i18next.t('advanced')}
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={activeItem}>
                                <TabPane tabId="1">
                                    <Col sm="12">
                                        <TitleOperator titleOperator={selectedBoxOperator.title} />
                                        <Row>
                                            <Col sm="12"> 
                                                <Label>{i18next.t('statement')} <i className="fa fa-info-circle" id="tooltipEnunciado"></i></Label>
                                                <UncontrolledTooltip placement="right" target="tooltipEnunciado"> {i18next.t('message8')}</UncontrolledTooltip>
                                                {/* <Input type="hidden" defaultValue={props.botmsg_id || ''} name="botmsg_id" hidden/> */}
                                                <FormGroup>
                                                    <Mentions defaultValue={botmsg_text || ''}  maxlength={'2000'} msg_error={i18next.t('limit_allowed') +' 2000'} k={0}/>
                                                    <div className="options-bar-input" style={{'right':'1px'}}>
                                                        <Emoji id={'head'} callEmoji={props.callEmoji} type="head" style={{'display':'none',position: 'absolute',right: 268+'px',zIndex: 1}}/>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="6">
                                            <Label>{i18next.t('type')} {i18next.t('location')}  <i className="fa fa-info-circle" id="tooltipType"></i></Label>
                                            <UncontrolledTooltip placement="right" target="tooltipType"> {i18next.t('type_location_msg')}</UncontrolledTooltip>
                                                
                                            {listTypeLocation &&(Object.keys(listTypeLocation).length > 0 && (
                                            <Input  type="select"
                                                    className="inputModal" 
                                                    name="type" 
                                                    defaultValue={(configBoxOperator)?item_type:''}
                                                    onChange={(e) => onchangeTypeLocation(e)}
                                                    required>
                                                { Object.keys(listTypeLocation).map((k, index) => {
                                                    return <option key={index} value={listTypeLocation[k].type} >{listTypeLocation[k].text}</option>;
                                                    
                                                    })
                                                }
                                            </Input> 
                                            ))}
                                            </Col>
                                        </Row> 
                                        
                                        {item_type=='closest_location'?
                                            (
                                                <Row>
                                                    <Col lg="6">
                                                    <Label>{i18next.t('location_list')}  <i className="fa fa-info-circle" id="tooltipLista"></i></Label>
                                                    <UncontrolledTooltip placement="right" target="tooltipLista"> {i18next.t('location_list_msg')}</UncontrolledTooltip>
                                                        
                                                    {listLocation &&(Object.keys(listLocation).length > 0) ? (
                                                    
                                                    <Input  type="select"
                                                            className="inputModal" 
                                                            name="type" 
                                                            defaultValue={(selectedListLocation)?selectedListLocation.id:''}
                                                            onChange={(e) => onchangeListLocation(e)}
                                                            required>
                                                        { Object.keys(listLocation).map((k, index) => {
                                                            return <option key={index} value={listLocation[k].id} >{listLocation[k].title}</option>;
                                                            
                                                            })
                                                        }
                                                        
                                                    </Input> 
                                                    
                                                    ):<div className="alert alert-danger" role="alert">
                                                        {i18next.t('location_list_msg2')}
                                                    </div>
                                                    }
                                                   
                                                </Col>
                                                <Col sm="6">
                                                    <Button style ={ {'marginTop': 25+"px"}}color="info" className="btn-guarumo-modal-blue-sm" onClick={props.addNewStatementBlock}>{i18next.t('add_var')}</Button>
                                                </Col>
                                                
                                                    {
                                                    kblocksDinVar.map((variable,i) => {
                                                            if(dinamicVariables[variable].list==true)
                                                            {
                                                                return <Col lg="6" key={"dinamicBlock"+i}>
                                                                    <Row>
                                                                                <Col lg="6">
                                                                        <Label>{i18next.t('columns_list')}  <i className="fa fa-info-circle" id={"tooltipListaColumn"+i}></i></Label>
                                                                        <UncontrolledTooltip placement="right" target={"tooltipListaColumn"+i}> {i18next.t('columns_list_msg')}</UncontrolledTooltip>
                                                                            
                                                                        {selectedListLocation && selectedListLocation.columns && (Object.keys(selectedListLocation.columns).length > 0) ? (
                                                                        <Input  type="select"
                                                                                className="inputModal" 
                                                                                name="type" 
                                                                                defaultValue={(variable)?variable:''}
                                                                                onChange={(e) => props.onchangeListColumnsLocation(variable,e.target.value)}
                                                                                required>
                                                                            { selectedListLocation.columns.map((k, index) => {
                                                                                
                                                                                return <option key={index} value={k} >{k}</option>;
                                                                                
                                                                                })
                                                                            }
                                                                        </Input> 
                                                                        ):<div className="alert alert-danger" role="alert">
                                                                            {i18next.t('location_list_msg2')}
                                                                        </div>
                                                                        }
                                                                    </Col>
                                                                    <Col lg="5" key={'colDinVar_'+i}>
                                                                        <Label>{variable+" "}<i className="fa fa-info-circle" id={"tooltipVar_"+i}></i></Label>
                                                                        <UncontrolledTooltip placement="right" target={"tooltipVar_"+i}>{i18next.t('name_var_msg') + " " + variable}</UncontrolledTooltip>
                                                                        <FormGroup>
                                                                            <Input type="text" className="inputModal" placeholder={variable} onChange={(event) => { props.updateVariableBoxOperator(variable,event.target.value,'dinamicas') }}  defaultValue={dinamicVariables[variable].var_name} />
                                                                        </FormGroup>
                                                                </Col>
                                                                <Col lg="1">
                                                                    <Button style={{'marginTop':28+"px",'marginLeft':-10+"px"}} color="info" size="lg" onClick={() => props.removeStatementBlock(variable) }><FontAwesomeIcon icon={faTrash} /></Button>
                                                                </Col>
                                                                </Row>
                                                                
                                                            </Col>
                                                            }
                                                        })
                                                        
                                                    }
                                              
                                            </Row>
                                           
                                            ):item_type=='map_location'?
                                            (
                                                <Row>
                                                    <Col lg="3">
                                                        <FormGroup>
                                                            <Label>{i18next.t('latitud_var')} <i className="fa fa-info-circle" id={"tooltipLatitude"}></i></Label>
                                                            <UncontrolledTooltip placement="right" target={"tooltipLatitude"}> {i18next.t('latitud_var_info')}</UncontrolledTooltip>
                                                                
                                                            {varList&&varList.length > 0 ? (
                                                            <Input  type="select"
                                                                    className="inputModal" 
                                                                    name="type" 
                                                                    defaultValue={(configBoxOperator)?configBoxOperator.lat_statement:'none'}
                                                                    onChange={(e) => props.setChangeConfig("lat_statement",e.target.value)}
                                                                    required>
                                                                    <option key={'none'} value={'none'} >{''}</option>;
                                                                { varList.map((k, index) => {
                                                                    if(k.text!='')
                                                                        return <option key={index} value={k.id} >{k.text}</option>;
                                                                    })
                                                                }
                                                            </Input> 
                                                            ):<div className="alert alert-danger" role="alert">
                                                                {i18next.t('message24')}
                                                            </div>
                                                            }
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="3">
                                                        <FormGroup>
                                                            <Label>{i18next.t('longitud_var')} <i className="fa fa-info-circle" id={"tooltipLongitude"}></i></Label>
                                                            <UncontrolledTooltip placement="right" target={"tooltipLongitude"}> {i18next.t('longitud_var_info')}</UncontrolledTooltip>
                                                                
                                                            {varList&&varList.length > 0 ? (
                                                            <Input  type="select"
                                                                    className="inputModal" 
                                                                    name="type" 
                                                                    defaultValue={(configBoxOperator)?configBoxOperator.long_statement:'none'}
                                                                    onChange={(e) => props.setChangeConfig("long_statement",e.target.value)}
                                                                    required>
                                                                    <option key={'none'} value={'none'} >{''}</option>;
                                                                { varList.map((k, index) => {
                                                                    if(k.text!='')
                                                                        return <option key={index} value={k.id} >{k.text}</option>;
                                                                    })
                                                                }
                                                            </Input> 
                                                            ):<div className="alert alert-danger" role="alert">
                                                                {i18next.t('message24')}
                                                            </div>
                                                            }
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="3">
                                                        <FormGroup>
                                                            <Label>{i18next.t('icono_var')} <i className="fa fa-info-circle" id={"tooltipIcono"}></i></Label>
                                                            <UncontrolledTooltip placement="right" target={"tooltipIcono"}> {i18next.t('message25')}</UncontrolledTooltip>
                                                                
                                                            {varList&&varList.length > 0 ? (
                                                            <Input  type="select"
                                                                    className="inputModal" 
                                                                    name="type" 
                                                                    defaultValue={(configBoxOperator)?configBoxOperator.icon_statement:'none'}
                                                                    onChange={(e) => props.setChangeConfig("icon_statement",e.target.value)}
                                                                    required>
                                                                    <option key={'none'} value={'none'} >{''}</option>;
                                                                { varList.map((k, index) => {
                                                                    if(k.text!='')
                                                                        return <option key={index} value={k.id} >{k.text}</option>;
                                                                    })
                                                                }
                                                            </Input> 
                                                            ):<div className="alert alert-danger" role="alert">
                                                                {i18next.t('message24')}
                                                            </div>
                                                            }
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="3">
                                                        <FormGroup>
                                                            <Label>{i18next.t('message')} popup <i className="fa fa-info-circle" id={"tooltipPopup"}></i></Label>
                                                            <UncontrolledTooltip placement="right" target={"tooltipPopup"}> {i18next.t('message26')}</UncontrolledTooltip>
                                                            <MentionsAlone varList={varList} defaultValue={(configBoxOperator)?configBoxOperator.pop_up:' '} updateItem={onChangePopup} maxlength={'2000'} msg_error={i18next.t('limit_allowed') + ' 2000'}/>
                                                            <div className="options-bar-input" style={{'right':'1px'}}>
                                                                <Emoji id={'item'} callEmoji={props.callEmoji} type="pop_up" style={{'display':'none',position: 'absolute',right: 268+'px',zIndex: 1}}/>
                                                            </div>
                                                            
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            ):
                                                <Row  style={{"marginTop": 15+"px"}}>
                                                
                                                </Row>
                                         }
                                         
                                         <Row>
                                            {
                                               kblocksVar.map((variable) => {
                                                    
                                                    return <Col lg="3" key={'colVar_' + variable}>
                                                    <Label>{variables[variable].title+" "}<i className="fa fa-info-circle" id={"tooltip_"+variable}></i></Label>
                                                    <UncontrolledTooltip placement="right" target={"tooltip_"+variable}>{variables[variable].description}</UncontrolledTooltip>
                                                    <FormGroup>
                                                        <Input type="text" className="inputModal" placeholder={variable} onChange={(event) => { props.updateVariableBoxOperator(variable,event.target.value,'variables') }}  defaultValue={variables[variable].var_name} />
                                                    </FormGroup>
                                                </Col>
                                                })
                                                
                                            }
                                        </Row>
                                        {exit_var?
                                            <Row>
                                                <div className="alert alert-danger" role="alert">
                                                        {i18next.t('message27')}
                                                </div></Row>:<div></div>
                                            
                                        }
                                    </Col>

                                </TabPane>
                                <TabPane tabId="2">
                                    <Row>
                                        <Col sm="6">
                                            <IASelector botversion_id={botVersionId} operator_id={selectedBoxOperator.id} ia={selectedBoxOperator.ia_id} />
                                            <Avatar company_id={bot.company_id} avatarid={avatarid} bindValue={props.getAvatarId} />
                                        </Col>
                                        <Col sm="6">
                                        <Keyword keyword={selectedBoxOperator.keyword} isValid={props.isKeywordValid} />
                                        </Col>
                                    </Row>

                                </TabPane>
                            </TabContent>                  
                        </ModalBody>
                        <ModalFooter>
                            <Col sm="12" align="right">
                            
                            {exit_var||item_type=='closest_location'&&(selectedListLocation==null||selectedListLocation.length==0)?(
                                
                            <Button color="default" size="lg" type="button" onClick={props.toggleModalViewBoxLocation} >{i18next.t('cancel')}</Button>
                            ):
                                <Button color="success" size="lg" type="button" onClick={props.toggleModuleModal} >{i18next.t('finish')}</Button>
                            }
                            </Col>
                        </ModalFooter>
                        </Form>
                    </Modal>           
                </React.Fragment>
                    )
        }
        else
        {
            return(<div></div>)
        }

    }
    else
    {
        return(<div></div>)
    }
       
    function onChangePopup(e)
    {
        props.setChangeConfig("pop_up",e);
    }
    
 function onchangeTypeLocation(e)
 {
   
    item_type=e.target.value;

   props.setSelectedTypeLocation(item_type);

   props.setFormatVariables();

 }

 function onchangeListLocation(e)
 {
   
   var id_list=e.target.value;
   
   props.setSelectedListLocation(id_list);
   props.setInitialDinamicVariables();

 }
 
 function toggleTab(tab) {
    
    // e.preventDefault();
    // console.log('The link was clicked.',e);
    console.log("TAB: "+tab);
     props.changeActiveTab(tab);
 
}

 }
 
 
 const mapStateToProps = ({botReducer, boxReducer,homeReducer}) => {
     return {botReducer,boxReducer, homeReducer};
     };
     
     const mapDispatchToProps = {
         setSelectedTypeLocation,
         toggleModalViewBoxLocation,
         changeActiveTab,
         setChangeConfig,
         setSelectedListLocation,
         setInitialDinamicVariables,
         setFormatVariables,
         updateVariableBoxOperator,
         addNewStatementBlock,
         removeStatementBlock,
         onchangeListColumnsLocation
     };
     
     export default connect(mapStateToProps, mapDispatchToProps )(LCMLayout);
 

     