 import React from 'react';
 import PSData from './d3-examples/dataPSChart1';
 import PSChart from './d3Components/PSChart';

 
 class convertPSChart extends React.Component {
   constructor(props) {
     super(props);
     this.state = {d3: ''}
   }
 
   componentDidMount() {
     this.setState({d3: PSData("All","All","2008")});
   }
   componentWillReceiveProps(nextProps) {
    this.setState({d3: PSData("All","All","2008")});
  }
   render() {
     
     return(  
         <PSChart nameDiv={this.props.nameDiv} data={this.state.d3} group={this.props.group} botID={this.props.botID} dateini={this.props.dateini} datefin={this.props.datefin} color={this.props.color} typeScaleColor={this.props.typeScaleColor} title={this.props.title}  xAxisName={this.props.xAxisName}  yAxisName={this.props.yAxisName} description={this.props.description}/>
     )
   }
 }
 export default convertPSChart;