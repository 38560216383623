import React from 'react';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import { hideAlert } from "../../../redux/actions/loadStateActions";


// Assets
import '../modal-load.css';

function ModalLoad(props){
    const { 
        loading = false,
        msSuccess = '',
        showSuccess = false,
        msWarning = '', 
        showWarning = false,
        msError = '',
        showError = false,
        msTitle = ''
     } = props.loadStateReducer;
    const { hideAlert } = props;

    return (
    	<div>
            {loading &&
            <div className="modal-mask" >
                <div className="msg-box" align="center">
                    <img src="/static/img/loading.gif" />
                </div>
            </div> }    
            <SweetAlert success title={msTitle!=''?msTitle:msSuccess} showConfirm={true} show={showSuccess} onConfirm={hideAlert}>
            {
                msTitle!=''?(
                    <div style={{'overflow':'auto' ,'height':120+'px', 'width': 98+'%'}}>
                        <hr />
                          <div dangerouslySetInnerHTML={{ __html: msSuccess }} />
                    </div>
                ):null
            }
            </SweetAlert>
            <SweetAlert warning title={msTitle!=''?msTitle:msWarning} showConfirm={true} show={showWarning} onConfirm={hideAlert}>
                {
                    msTitle!=''?(
                        <div style={{'overflow':'auto' ,'height':120+'px', 'width': 98+'%'}}>
                            <hr/>
                            <div dangerouslySetInnerHTML={{ __html: msWarning }} />
                        </div>
                    ):null
                }
            </SweetAlert>
            <SweetAlert error title={msTitle!=''?msTitle:msError} showConfirm={true} show={showError} onConfirm={hideAlert}>
            {
                msTitle!=''?(
                    <div style={{'overflow':'auto' ,'height':120+'px', 'width': 98+'%'}}>
                        <hr/>
                        <div dangerouslySetInnerHTML={{ __html: msError }} />
                    </div>
                ):null
            }
            </SweetAlert> 
            
        </div>
    );
}

const mapStateToProps = ({ loadStateReducer }) => {
	return { loadStateReducer };
};

const mapDispatchToProps = {
    hideAlert,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalLoad)