import  React  from 'react';
import { Col,  Row,  Card,} from 'reactstrap';

export default (props) => {
    const {title, detail, img , type, channel_id, onClikcOptions} = props;
    return (
        <Card onClick={()=>onClikcOptions(type,channel_id,title)} style={{ margin: '10px', }} >
                <Row >
                    <Col style={{ textAlign: 'left', margin: '0px' }}>
                        <div style={{ background: '#F3F6FF', }}>
                            <img src={ img } style={{ padding: '5px', height: '50px' }} />
                        </div>
                    </Col>
                    <Col style={{ alignSelf: 'center', }} >
                        <Row>
                            <h4 style={{ margin: '0px' }}> {title} </h4>
                        </Row>
                        <Row>
                            <small style={{ margin: '0px' }}> {detail} </small>
                        </Row>
                    </Col>
                </Row>
            </Card>
    );
}