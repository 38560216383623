import { AbstractNodeFactory } from "storm-react-diagrams";
import React from "react";

import PAYNodeWidget from "./PAYNodeWidget";
import PAYNodeModel from "./PAYNodeModel";

class PAYNodeFactory extends AbstractNodeFactory {
	constructor() {
		super("payment");
	}

	generateReactWidget(diagramEngine, node) {
		return <PAYNodeWidget node={node} />
	}

	getNewInstance() {
		return new PAYNodeModel();
	}
}

export default PAYNodeFactory