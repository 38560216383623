import React, { Component } from 'react';
import { connect } from "react-redux";

import APMLayout from './agent-pass-modal-layout';
import axios from 'axios';
import qs from 'qs';

import { iconLoad, 
    iconHideLoad, 
    updateBoxOperator,
    setBotmsgText,
    setChangeConfig,
    updateOperator,
    cleanSelection,
    setVarList,
    toggleModalViewBoxAgent } from "../../../../redux/actions/boxActions";


class APModal extends Component {

    state = {
        nodeId: "",
        title:"",
        selection:  null,
        range: null,
        error_head_empty: false,
        keyword_valid: true
    }

    constructor(props){
        super(props);
    }

    getTitleOperator = (titleOperator) => {
        this.props.modal.node.extras.titleOperator = titleOperator
    }

    
    validateEmptyHead = (node)=>{
        let blocks = node.extras.botmsg_text;
        let error_head_empty = false
        let err = false;
        if(blocks.length <= 0){
            err = true;
        }
        error_head_empty = blocks.length <= 0;
        this.setState({
            error_head_empty: error_head_empty
        }, ()=> {
            this.forceUpdate();
        });
        return err;
    }

    toggleModuleModal = async ()  =>{
        this.setState({error_head_empty: false});

     
       if(!this.state.keyword_valid)
            return false; 

        //Pone icono de carga
        this.props.iconLoad();

        //Carga el formulario para extraer los datos
        let form = new FormData(document.getElementById('APM-form'));

        //Selecciona el nodo asociado a la caja
        let node = this.props.modal.node;
        
        //Extrae y actualiza el mensaje
        node.extras.botmsg_text = $('#input-head_0').val();
        

        if(this.validateEmptyHead(node))
        return false;

        this.props.setChangeConfig('botmsg_text',node.extras.botmsg_text);


        //Extrae y actualiza el avatar
        //node.extras.avatar = $('#input-head').val();
        this.props.setChangeConfig('avatarid',node.extras.avatar);

        
        //Estrae y actualiza el título del operador
        node.extras.titleOperator = form.get('titleOperator');
        //OJO SE DEBE CAMBIAR AL COMPONENTE title-operator.js cuando todas las cajas usen redux
        await this.props.updateBoxOperator('title', node.extras.titleOperator);//this.getTitleOperator(node.extras.titleOperator);   

        const {configBoxOperator,selectedBoxOperator} = this.props.boxReducer;

        //Extrae y actualiza los Keywords
        node.extras.keyword = selectedBoxOperator.keyword;

        //Extrae y actualiza la url del agente
        node.extras.url_agent=$('#input-url').val();
        this.props.setChangeConfig('url_agent',node.extras.url_agent);
        
    
         //Extrae y guarda la nueva configuración
         
         node.extras.config = configBoxOperator;

         await this.props.updateOperator(node.extras.titleOperator,node.extras.keyword,node.extras.config);


          //Actualiza el esquema
         await this.props.updateSchema();

         //Limpia selecciones
         await this.props.cleanSelection();

         //Cierra el modal
        await this.props.toggleModalViewBoxAgent();

    }


    updateItem = (k,text) => {  
        this.props.modal.node.extras.botmsg_text = text;
        // this.saveKeyword(text);
        this.forceUpdate();
    }

    /* Update data de keyword */
    saveKeyword = (keyword) => {
        const data = {
            id: this.props.modal.node.extras.dbId,
            keyword:keyword
        };
        var auth=JSON.parse(localStorage.getItem('Authorization'));
        var headers={
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer '+ auth.access_token 
        }
        return axios({
            method:'put',
            url:HOST_API +'/api/scheme/operator/',
            data:qs.stringify(data),
            headers:headers
        })
    }

    updateKeyword = (k,text) => {    
        this.props.modal.node.extras.keyword = text;
        this.forceUpdate();
    }

    isKeywordValid = (val) => {        
        this.setState({keyword_valid:val}, ()=>{
            this.forceUpdate();
        })
    }

    closeModal = () => this.props.closeModal('m8');

    saveTitle = (title) => {
        const data = {
            id: this.props.modal.node.extras.dbId,
            title:title
        };

        var auth=JSON.parse(localStorage.getItem('Authorization'));
        var headers={
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer '+ auth.access_token 
        }

        return axios({
            method:'put',
            url:HOST_API +'/api/scheme/operator/',
            data:qs.stringify(data),
            headers:headers
        })
    }

    //Inserta los Emoji al enunciado
    callEmoji = (code,k,type)=>{

        let text = this.props.modal.node.extras.botmsg_text?this.props.modal.node.extras.botmsg_text:'';
            text += ` ${code}`;
        this.updateItem(k,text);

        // let botmsg_text = $('#input-head_0').val();

        // let text = botmsg_text; 
        //  text += ` ${code}`;

        // this.props.modal.node.extras.botmsg_text = text;
        this.props.setBotmsgText(text);

        
        // // this.saveKeyword(text);
       // this.forceUpdate();
        
    }

    render() {
        return (
          <APMLayout 
            toggleModuleModal={this.toggleModuleModal}
            isOpen={this.props.modal.isOpen} 
            url={this.props.modal.node.extras.url_agent} 
            error_head_empty={this.state.error_head_empty}
            isKeywordValid={this.isKeywordValid}
            closeModal={this.closeModal}
            callEmoji = {this.callEmoji}
            updateItem = {this.updateItem}
          />
        )
    }
}

const mapStateToProps = ({ botReducer,boxReducer,homeReducer, componentMountReducer }) => {
    return { botReducer,boxReducer,homeReducer, componentMountReducer };
   };
   
   const mapDispatchToProps = {
   // isDidMount,
   // willUnMount,
    iconLoad,
    iconHideLoad,
    updateBoxOperator,
    setBotmsgText,
    setChangeConfig,
    updateOperator,
    cleanSelection,
    setVarList,
    toggleModalViewBoxAgent
    
   };
   
   export default connect(mapStateToProps, mapDispatchToProps)(APModal);
