import React from 'react';
import Admin from '../../admin/containers/admin';

import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';

import { MDBBtn, MDBTable, MDBTableBody, MDBTableHead  , MDBDataTable } from 'mdbreact';

import SliceMenuApp from '../../admin/components/slice-menu';
import SelectedCompanies from '../../companies/selected-company';

import '../../../../static/css/dashboard.css';

/* IMAGE */
import newMetric from '../../../../static/img/menu-icons/nuevo_bot.svg'

import { 
    Col,
    Row,
    Button, 
    Container,
    Card,
    FormGroup,
    Label,
    Input,
    Form,
    UncontrolledTooltip
  
  } from 'reactstrap';
  import ToolbarApp from '../../admin/components/toolbar';
  import BotMenuOptions from '../../menus/analytics-menu-options';

import ModalNuevaMetrica from '../modals/modal-nueva-metrica-cubejs';


//import ModalNuevaMetrica from '../../components/infoViz/modal-nueva-metrica';
import ModalViewMetric from '../modals/modal-ver-metrica';

import { getMetricsWithoutParent,
    changePageActive,
    deleteMetric, 
    handleSearching,
    viewMetric, 
    selectedViewMetric, 
    selectedUpdateMetric, 
    selectedCreateMetric,
    setSelectedCompany,
    getAllMetrics,
    getAllCharts,
    cleanListDashboard, iconLoad} from "../../../redux/actions/analyticsActions";
 

import { Link } from "react-router-dom";

import DatableFilter from '../infoViz/common/dataTableFilter'
import i18next from '../../../../i18n';

function BotAdminMetricasLayout(props) {
    
    const {  activePage = 1, listAllMetrics = [] , selectedCompany} = props.analyticsReducer;
    const {  deleteMetric, selectedViewMetric, selectedUpdateMetric, selectedCreateMetric } = props;
    const {  listAllCompanies=[] } = props.homeReducer;

    var dataTable=null;
    if(listAllMetrics[0]){
       
         dataTable = {
            columns: [
              {
                label: i18next.t('name'),
                field: 'name',
                sort: 'asc',
               // width: 150
              },
              {
                label: i18next.t('short_name'),
                field: 'short_name',
                sort: 'asc',
             //   width: 270
              },
              {
                label: i18next.t('description'),
                field: 'description',
                sort: 'asc',
           //     width: 270
              },
              {
                label: i18next.t('unit'),
                field: 'unit',
                sort: 'asc',
           //     width: 270
              },
              {
                label: i18next.t('indicator_type'),
                field: 'type_indicator',
                sort: 'asc',
           //     width: 270
              },
              {
                label: i18next.t('metric_type'),
                field: 'type_metric',
                sort: 'asc',
          //      width: 270
              },
        //       {
        //         label: 'Operación',
        //         field: 'operation',
        //         sort: 'asc',
        //   //      width: 270
        //       },
              {
                label: i18next.t('edition_option'),
                field: 'options',
                sort: 'asc',
                width: 670,
                options: {
                    filter: false,
                    sort: false
                   }
              }],
              rows: []};

              const rows = listAllMetrics.map((k, index) => {    
               // k = JSON.parse(k);  
                const fin=[];
                fin.push(k.name);
                fin.push(k.short_name);
                fin.push(k.description);
                fin.push(k.unit.name);
                if(k.type_metric=="MEA")
                    fin.push("Medida");
                 else
                    fin.push("Métrica");
                if(k.type_indicator=="OPE")
                    fin.push("Operacional");
                 else
                    fin.push("Funcional");
             //   fin.push(k.operation);
                fin.push(
                    <Row className="d-flex align-items-center justify-content-center">
                             <Col className="col-2"> 
                                <Button type="submit" title="Ver" className="btn-square btn-link-eye" onClick={(e)=>{selectedViewMetric(k)}}>
                                 <i className="fa fa-eye "></i>
                                 </Button>
                                 {/* <Link to={"/bot/"+props.botId+"/funnel/"+k.id} data-aiml="a" className="btn btn-square btn-link-eye"><i className="fa fa-eye"></i></Link> */}
                             </Col>
                            {/* <Col className="col-2">
                                 <Button type="submit" outline color="success" className="btn-square" onClick={(e)=>{selectedUpdateMetric(k)}}>
                                 <i className="fa fa-edit "></i>
                                 </Button>   
                             </Col> 
                             <Col className="col-2">
                                 <Button type="submit" outline color="danger" className="btn-square" onClick={(e)=>{deleteMetric(k.id)}}>
                                     <i className="fa fa-trash"></i>
                                 </Button>
                             </Col>   */}
                         </Row>
                )
                dataTable.rows.push(fin); 
                return k;
            });
        }

        const options = {
            filterType: 'multiselect',
            selectableRows:'none',
            resizableColumns: false,
            responsive: "scrollMaxHeight",
            rowsPerPage:10 ,
            print:false,
            download:false,
            viewColumns:false,
          };
          
              var title = ''
    return (
        <div className="BotLayout">

            <Admin>
             {/* <button id="tooltiNewMetric" className="btn-float"  onClick={(e)=>{selectedCreateMetric()}}>
                <img src={newMetric}/>
            </button>
            <UncontrolledTooltip placement="right" target="tooltiNewMetric">Nueva Medida/Métrica</UncontrolledTooltip>  */}

                <SliceMenuApp>
                    <BotMenuOptions admin={props.isAdmin} handleFind={props.handleFind}/>
                </SliceMenuApp>
                <Container fluid>
                <ToolbarApp>
                    <Row>
                        <Col sm="8" md="8" className="text pdtoolbar">
                            <h2 className="text-left ml-4 font-weight-bold">{i18next.t('metric_admin')} <span className="btn-help"></span></h2>                      
                        </Col>
                        {props.isAdmin?(
                            <SelectedCompanies onchangeCompanies={onchangeCompanies} ></SelectedCompanies>
                      ):null}
                    </Row>
                </ToolbarApp>
            </Container>
            <Container fluid className="box-fluid">
                <Col sm="12" md="10" className="stadistics-container offset-md-1">
                    {
                        dataTable?(
                        <Row >
                    <Col xs={12} lg={12} >
                      <DatableFilter
                        title={title}
                        data={ dataTable.rows}
                        columns={dataTable.columns}
                        options={options}>
                      </DatableFilter>
                        

                            {/* <Col xs={12} lg={10} className="offset-lg-1">
                           
                            <MDBDataTable 
                striped
                bordered
                small
                responsive
                sorting={'true'}
                order={['name', 'asc' ]}
                entriesLabel="Número de registros"
                paginationLabel={["Anterior", "Siguiente"]}
                infoLabel={["Mostrando", "al", "de", "registros"]}
                searchLabel="Buscar"
                theadColor="indigo"
                entriesOptions={[ 5, 10, 15,20,50,100 ]}
                entries={5} 
                //scrollX
                // maxHeight="50vh"
                data={dataTable}
                        />*/}
                            </Col>
                        </Row>): 
                          <Col xs={12} lg={8} className="offset-lg-2 intro-funnels">
                                <Row>
                                    <Col xs={12}><img src="/static/img/blank_state.png" className="img-fluid"/></Col>
                                    <Col xs={12}>
                                        <h2>{i18next.t('without_metrics')}</h2>
                                        <h3>{i18next.t('message178')}</h3>
                                        <p>{i18next.t('message179')}</p>
                                    </Col>
                                </Row>
                            </Col>
                        }
                </Col>
            </Container>
            <ModalNuevaMetrica 
                 tooltipFunctions={props.tooltipFunctions}
                 functions={props.functions}
                 tooltipOperators={props.tooltipOperators}
                 operators={props.operators}
                // bots={props.bots}
                />

                <ModalViewMetric 
                 tooltipFunctions={props.tooltipFunctions}
                 functions={props.functions}
                 tooltipOperators={props.tooltipOperators}
                 operators={props.operators}
                // bots={props.bots}
                />
            </Admin>
        </div>
    )

    //----------------------------------------------------------------------------
    //  CUANDO SE SELECCIONA UNA COMPAÑÍA ACTUALIZA EL SELECTEDCOMPANY
    //------------------------------------------------------------------------------
    async function onchangeCompanies(e)
    {
        const id_company=e.value; 

        await props.iconLoad();  
        await props.setSelectedCompany(id_company);
    
    }

}

const mapStateToProps = ({analyticsReducer,homeReducer}) => {
    return {analyticsReducer, homeReducer};
};

const mapDispatchToProps = {
    getAllMetrics,
    getAllCharts,
  getMetricsWithoutParent,
  changePageActive,
  deleteMetric,
  handleSearching,
  viewMetric,
  selectedViewMetric,
  selectedUpdateMetric,
  selectedCreateMetric,
  setSelectedCompany,
  cleanListDashboard, 
  iconLoad
 
};

export default connect(mapStateToProps, mapDispatchToProps )(withRouter(BotAdminMetricasLayout));
