import { AbstractNodeFactory } from "storm-react-diagrams";
import React from "react";

import VariableNodeModel from "./VariableNodeModel";
import VariableNodeWidget from "./VariableNodeWidget";

class VariableNodeFactory extends AbstractNodeFactory {
	constructor() {
		super("variable");
	}

	generateReactWidget(diagramEngine, node) {
		return <VariableNodeWidget node={node} />
	}

	getNewInstance() {
		return new VariableNodeModel();
	}
}

export default VariableNodeFactory