import { dirname } from 'path';

var d3 = require('d3');
var dataT = [];
var datosNew = [];

const createPSChart = (nameDiv,datos,formatoD3,group,botID,dateini,datefin,colorChosen, typeScaleColor,title,xAxisName,yAxisName,description) => {

    const node = document.createElement('div');
    node.className = "svg-container";
    node.id="chart_"+nameDiv;

    const explanation = document.createElement('div');
    explanation.id="explanation_"+nameDiv;
    explanation.style.visibility = "hidden";

    const newSpan = document.createElement('span');
    newSpan.id="percentage_"+nameDiv;
    explanation.appendChild(newSpan);

   // node.appendChild(explanation);

    const sequence = document.createElement('div');
    sequence.id="sequence_"+nameDiv;

   // node.appendChild(sequence);

    const legend = document.createElement('div');
    legend.id="legend_"+nameDiv;

  
    var div='#'+nameDiv;
    var chart=document.getElementById('content-'+nameDiv);
  
    const WIDTH = chart.offsetWidth;
    const HEIGHT = chart.offsetHeight;
    const margin = { top: 50, right: 60, bottom: 35, left: 40 };

    // set the dimensions and margins of the graph
   var  width =  WIDTH - margin.left - margin.right,
   height = height = HEIGHT - margin.top - margin.bottom;

  // create a tooltip
  var Tooltip = d3.select("#tooltip_"+nameDiv)//.select("svg")
  .style("opacity", 0)
  .attr("class", "tooltip")
  .style("background-color", "white")
  .style("border", "solid")
  .style("border-width", "2px")
  .style("border-radius", "5px")
  .style("padding", "5px")

  // Three function that change the tooltip when user hover / move / leave a cell
  var mouseoverPS = function(d,i) {

    if(this.className.baseVal=="slice"+nameDiv)
    {
        var d = d3.select(d3.event.target).datum();

        Tooltip
        .style("opacity", 1)
        d3.select(this)
        .style("stroke", "black")
        .style("opacity", 1)


        var percentage = (100 * d.value / totalSize).toPrecision(3);
      var percentageString = percentage + "%";
      if (percentage < 0.1) {
        percentageString = "< 0.1%";
      }

    

    const lineHeight = 1.6;
    const margin = { top: 10, left: 10 };
    const textColor = "rgb(28, 116, 203)";
    const textHeight = 8;

    var texto ="";

    if(d.parent==null)
      texto=d.data.name;
    else
      texto= d.parent.data.name+"\n"+d.data.name

      var text=d3.select("#percentage_"+nameDiv)
      .attr("y",-height/8)
      .attr("dy", "0.1em")
          .attr("text-anchor", "middle")
          .text(percentageString)

    text.selectAll("tspan.text")
      .data(texto.split("\n"))
      .enter()
      .append("tspan")
      //.attr("class", "text")
      .text(d => d)
      .attr("font-size", textHeight)
      .attr("fill", function(d,i) { return color(i+3); })
      .attr("x", margin.left)
      .attr("dx", -10)
      .attr("dy", Math.floor(textHeight * lineHeight));

      d3.select("#explanation_"+nameDiv)
          .style("visibility", "");

      var sequenceArray = d.ancestors().reverse();
      sequenceArray.shift(); // remove root node from the array
    // updateBreadcrumbs(sequenceArray, percentageString);

    }     
}
var mousemovePS = function(d,i) {
  if(this.className.baseVal=="slice"+nameDiv)
  {
    var d = d3.select(d3.event.target).datum();
    var texto= "";
    if(d.parent==null)
      texto=""+d.data.name+"<br>Plataforma: "+group+"<br>Bot: "+botID+"<br>Valor: " + d.value;
    else
      texto=d.parent.data.name+" "+d.data.name+"<br>Plataforma: "+group+"<br>Bot: "+botID+"<br>Valor: " + d.value
    Tooltip
    .html(texto)
    .style("left", (d3.mouse(this)[0]+150) + "px")
    .style("top", (d3.mouse(this)[1]) +80+ "px")
  }
}
var mouseleavePS = function(d,i) {
  if(this.className.baseVal=="slice"+nameDiv)
  {
    Tooltip
    .style("opacity", 0)
    d3.select(this)
    .style("stroke", "none")
    .style("opacity", 0.8)

    /*
  // Hide the breadcrumb trail
  svg.select("#trail")
      .style("visibility", "hidden");

  // Deactivate all segments during transition.
  svg.selectAll("path").on("mouseover", null);

  // Transition each segment to full opacity and then reactivate it.
  svg.selectAll("path")
      .transition()
      .duration(1000)
      .style("opacity", 1)
      .on("end", function() {
              d3.select(this).on("mouseover", mouseoverPS);
            });

  d3.select("#explanation_"+nameDiv)
      .style("visibility", "hidden");

     */
   }
}

  const chartComponentSelection = d3.select("#chart_"+nameDiv);
chartComponentSelection.attr(
    "style",
    "padding-bottom: " +
      Math.ceil(
        (height + margin.top + margin.bottom) *
          100 /
          (width + margin.left + margin.right)
      ) +
      "%"
  );



  var color;
    if(typeScaleColor=="unicolor")
      color=d3.scaleLinear().domain([1,10]).range([colorChosen,"black"]);
    else if(typeScaleColor=="Categorica")
    {
      var colorChosen="d3."+colorChosen;
       color = d3.scaleOrdinal(eval(colorChosen));
    }
    else if(typeScaleColor=="predeterminado")
        {
            color = d3.scaleOrdinal().domain(datos.dataPSChart).range(eval(colorChosen));
           
           // color = d3.scaleOrdinal().domain(datos.dataSet).range(["rgba(46,91,255,1)", "rgba(140,84,255,1)", "rgba(0,193,212,1)", "rgba(250,208,80,1)", "rgba(195,34,168,1)"]);       
        }
    else 
    {
      var colorChosen="d3."+colorChosen+"(t * 0.85)";
      const scale = t => eval(colorChosen);
      color = (d) => {
        var c= d3.range(10).map(i => scale(i / 10))
        return c[d];
        };
      //  color = d3.scaleSequential(eval(colorChosen));
    }
  // Dimensions of sunburst.
//var width = 750;
//var height = 600;
var radius = Math.min(width, height) / 2;

// Breadcrumb dimensions: width, height, spacing, width of tip/tail.
var b = {
  w: 95, h: 30, s: 3, t: 10
};

// Total size of all segments; we set this later, after loading the data.
var totalSize = 0; 

var svg = d3.select(node).append("svg:svg")
   // .attr("width", width)
   // .attr("height", height)
   .attr("id","svg_"+nameDiv)
   .attr("preserveAspectRatio", "xMinYMin meet")
   .attr(
       "viewBox",
       "0 0 "
         .concat(width + margin.left + margin.right )
         .concat(" ")
         .concat(height + margin.top + margin.bottom )
     )
   .classed("svg-content", true)
    .append("svg:g")
    .attr("id", "container")
  //  .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
    .attr("transform", "translate(" + width / 2 + ","  .concat((height  / 2)+ margin.top ) .concat( ")"));

    // Title
    svg
    .append("text")
    .attr("class", "chartTitle")
    .attr("x",-width/2)
    .attr("y", -(height+margin.top)/2 )
   //.style("text-anchor", "middle")
    .style("font-size", 17)
    .text(title);

    //============================================================================================
    // Add the label at the end, for the percentage.
    svg.append("text")
   .attr("id", "percentage_"+nameDiv)
   .style("fill", "#000")
   .text("100%");


var partition = d3.partition()
    .size([2 * Math.PI, radius * radius]);

var arc = d3.arc()
    .startAngle(function(d) { return d.x0; })
    .endAngle(function(d) { return d.x1; })
    .innerRadius(function(d) { return Math.sqrt(d.y0); })
    .outerRadius(function(d) { return Math.sqrt(d.y1); });

 
  datosNew = datasetPSChosen();
  createVisualization(datosNew);

  
function datasetPSChosen() {
    
    dataT=datos;
    var ds = [];
	
	for (var x in datos.dataPSChart) {
			if(datos.dataPSChart[x].group==group){
        if(datos.dataPSChart[x].bot==botID){
            ds.push(datos.dataPSChart[x].data);
            return ds[0][0];
          }
        }
    }


  datos.dataPSChart.forEach(function(d, index) {
 
    if(d.group==group)
    {
        if(d.bot=botID)
        {
          dataT=d.data;
          return dataT[0];
        }
    }
  });
  return dataT[0];
}
// Main function to draw and set up the visualization, once we have the data.
function createVisualization(datos) {

  // Basic setup of page elements.
 // initializeBreadcrumbTrail();
  drawLegend();

  // Bounding circle underneath the sunburst, to make it easier to detect
  // when the mouse leaves the parent g.
  svg.append("svg:circle")
      .attr("r", radius)
      .style("opacity", 0);

  // Turn the data into a d3 hierarchy and calculate the sums.
  var root = d3.hierarchy(datos)
      .sum(function(d) { return d.size; })
      .sort(function(a, b) { return b.value - a.value; });
  
  // For efficiency, filter nodes to keep only those large enough to see.
  var nodes = partition(root).descendants()
      .filter(function(d) {
          return (d.x1 - d.x0 > 0.005); // 0.005 radians = 0.29 degrees
      });

      var arcs = svg.selectAll('g.slice')     //this selects all <g> elements with class slice (there aren't any yet)
      .data(nodes)                          //associate the generated pie data (an array of arcs, each having startAngle, endAngle and value properties) 
      .enter()                            //this will create <g> elements for every 'extra' data element that should be associated with a selection. The result is creating a <g> for every object in the data array
          .append('svg:g')                //create a group to hold each slice (we will have a <path> and a <text> element associated with each slice)
             .attr('class', 'slice'+nameDiv)    //allow us to style things in the slices (like text)
            .attr('id',function(d,i){
                return 'ps_slice'+i
            })
            .attr("pointer-events", "all")
            .on("mouseover", mouseoverPS)
            .on("mousemove", mousemovePS)
            .on("mouseleave", mouseleavePS)

    // destroy our events after 3 seconds
    setTimeout(() => {
      arcs
        .dispatch("mouseout")
        .on("mousemove", null)
        .on("mouseout", null)
        .on("mouseleave", null)
    }, 3000)

            var path =arcs.append('svg:path')


  //var path = svg.data([datos]).selectAll("path")
    //  .data(nodes)
    //  .enter().append("svg:path")
      .attr("display", function(d) { return d.depth ? null : "none"; })
      .attr("d", arc)
      .attr("fill-rule", "evenodd")
      .style("fill", function(d,i) { return color(i); })
      .style("opacity", 1)
   //   .on("mouseover", mouseoverPS)
   //   .on("mouseleave", mouseleave)
   //   .on("mousemove", mousemove);

  // Add the mouseleave handler to the bounding circle.
 // d3.select("#container").on("mouseleave", mouseleave);

  // Get total size of the tree = value of root node from partition.
  totalSize = path.datum().value;
 };


function initializeBreadcrumbTrail() {
  // Add the svg area.
  
  var trail = d3.select(node).select("svg").append("g")
      .attr("width", width)
      .attr("height", 50)
      .attr("id", "trail");
  // Add the label at the end, for the percentage.
   trail.append("g:text")
    .attr("id", "endlabel")
    .style("fill", "#000")
    .text("j");

}

// Generate a string that describes the points of a breadcrumb polygon.
function breadcrumbPoints(d, i) {
  var points = [];
  points.push("0,0");
  points.push(b.w + ",0");
  points.push(b.w + b.t + "," + (b.h / 2));
  points.push(b.w + "," + b.h);
  points.push("0," + b.h);
  if (i > 0) { // Leftmost breadcrumb; don't include 6th vertex.
    points.push(b.t + "," + (b.h / 2));
  }
  return points.join(" ");
}

// Update the breadcrumb trail to show the current sequence and percentage.
function updateBreadcrumbs(nodeArray, percentageString) {

  // Data join; key function combines name and depth (= position in sequence).
  var trail = d3.select("#trail")
      .selectAll("g")
      .data(nodeArray, function(d) { return d.data.name + d.depth; });

  // Remove exiting nodes.
  trail.exit().remove();

  // Add breadcrumb and label for entering nodes.
  var entering = trail.enter().append("svg:g");

  entering.append("svg:polygon")
      .attr("points", breadcrumbPoints)
      .style("fill", function(d,i) {
         return color(i);
         });
        

  entering.append("svg:text")
      .attr("x", (b.w + b.t) / 2)
      .attr("y", b.h / 2)
      .attr("dy", "0.35em")
      .attr("text-anchor", "middle")
      .text(function(d) { return d.data.name; });

  // Merge enter and update selections; set position for all nodes.
  entering.merge(trail).attr("transform", function(d, i) {
    return "translate(" + i * (b.w + b.s) + ", "+(height+margin.top+5)+")";
  });

  // Now move and update the percentage at the end.
  d3.select("#trail").select("#endlabel")
      .attr("x", (nodeArray.length + 0.5) * (b.w + b.s))
      .attr("y", (height+margin.top+((b.h/3)*2)))//b.h / 2)
      .attr("dy", "0.35em")
      .attr("text-anchor", "middle")
      .text(percentageString);

  // Make the breadcrumb trail visible, if it's hidden.
  d3.select("#trail")
      .style("visibility", "");

}

function drawLegend() {

  // Dimensions of legend item: width, height, spacing, radius of rounded rect.
  var li = {
    w: 75, h: 30, s: 3, r: 3
  };

  // Turn the data into a d3 hierarchy and calculate the sums.
  var root = d3.hierarchy(datosNew)
      .sum(function(d) { return d.size; })
      .sort(function(a, b) { return b.value - a.value; });
  
  // For efficiency, filter nodes to keep only those large enough to see.
  var nodes = partition(root).descendants()
      .filter(function(d) {
          return (d.x1 - d.x0 > 0.005); // 0.005 radians = 0.29 degrees
      });

      
  var legend = d3.select(node).select("svg")//d3.select("#legend").append("svg:svg")
     // .attr("width", li.w)
     // .attr("height", d3.keys(colors).length * (li.h + li.s));

     legend.selectAll(".dots-group")
     .data(d3.entries(nodes))
     .enter()
     .append("g")
     .attr("class", "dots-group")  
     .append("circle")
    // .attr("transform", function(d,i) { return "translate(" + 300  + ", -50)"; }) // Put the text at the position of the last point
      // .attr("date",function(d,i){ return d.date})
       .attr("cx", width-28)
       .attr("cy",function(d,i){ return height/2+(i*15)}) // 100 is where the first dot appears. 25 is the distance between dots
       .attr("r", 5)
       .style("fill", function (d, i) {
        if(d.value.parent==null)
          return "#ffff";
        else
           return color(i);
       })
       .style("stroke", "#fff")
       ;
   
         legend
         .selectAll(".myLegend")
         .data(d3.entries(nodes))
         .enter()
           .append('g')
           .attr("class","myLegend")
           .append("text")
             .attr('x', width-18)
             .attr('y', function(d,i){ return height/2+(i*15)+3})
             .text(function(d) { 
               if(d.value.parent==null)
                return "Leyenda";
               else
                return d.value.parent.data.name+" "+d.value.data.name; 
              })       
             .style("fill", (d, i) => color(i))
             .style("font-size", 8)
          


  
}


// Take a 2-column CSV and transform it into a hierarchical structure suitable
// for a partition layout. The first column is a sequence of step names, from
// root to leaf, separated by hyphens. The second column is a count of how 
// often that sequence occurred.
function buildHierarchy(csv) {
  var root = {"name": "root", "children": []};
  for (var i = 0; i < csv.length; i++) {
    var sequence = csv[i][0];
    var size = +csv[i][1];
    if (isNaN(size)) { // e.g. if this is a header row
      continue;
    }
    var parts = sequence.split("-");
    var currentNode = root;
    for (var j = 0; j < parts.length; j++) {
      var children = currentNode["children"];
      var nodeName = parts[j];
      var childNode;
      if (j + 1 < parts.length) {
   // Not yet at the end of the sequence; move down the tree.
 	var foundChild = false;
 	for (var k = 0; k < children.length; k++) {
 	  if (children[k]["name"] == nodeName) {
 	    childNode = children[k];
 	    foundChild = true;
 	    break;
 	  }
 	}
  // If we don't already have a child node for this branch, create it.
 	if (!foundChild) {
 	  childNode = {"name": nodeName, "children": []};
 	  children.push(childNode);
 	}
 	currentNode = childNode;
      } else {
 	// Reached the end of the sequence; create a leaf node.
 	childNode = {"name": nodeName, "size": size};
 	children.push(childNode);
      }
    }
  }
  return root;
};


  return node;

}

export default createPSChart;