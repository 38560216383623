import React, { Component } from 'react';
import { MentionsInput, Mention } from "react-mentions";

import i18next from "../../../i18n";

const defaultStyle = {
  control: {
    height: "33px",
    width: "100%"

  },
  highlighter: {
    overflow: 'hidden',
  },
  input:{
    borderRadius:"4px",
    border:"1px solid #cdcdcd",
    padding:"6px"
  },
  suggestions: {
    marginTop: "30px",
    marginLeft: "10px",

    list: {
      backgroundColor: "#fff",
      border: "1px solid #d2d6de",
      zIndex: "100!important",
      height: "131px",
      overflow: "auto",
      borderStyle: "solid",
      borderColor: "silver",
      borderWidth: "0.5px",
    },
    item: {
      padding: "5px 15px",
      borderBottom: "1px solid #cdcdcd",

      "&focused": {
        backgroundColor: "#5a5a5a",
        color: "white",
        fontSize: 18,
      }
    }
  }
};

const defaultMentionStyle ={
    backgroundColor: '#75b74b',
    borderRadius:"3px",
    paddingBottom:'2px'
}

class Mentions extends Component {
  constructor(props){
    super(props);
}
    state = {
        searchActive: true,
        search:'',
        error_input:false,
        value: this.props.defaultValue
    }
    
    componentWillUpdate(nextProps,nextState){

      const  message  = nextProps.defaultValue;

      if(message!=this.state.value)
      {
          this.setState({value: message});
      }
  }

    onChange = e => {
        let value = e.target.value;
        let statement_length = value.length;
          if(statement_length > this.props.maxlength){
            this.setState({error_input:true});
          }else{
            this.updateItem(value);
            this.setState({error_input:false});
          }
          this.setState({value});
    };
    updateItem = (e) =>{
      this.props.updateItem(e)
    }
    render() {

      const formatIdList = (id, index) => {
  
        // El id no es 0, corresponde a una variable diferente de session_var
        if( id != 0 ) return id;

        return `s${index}`;
      };

        var data =[]
        let k = this.props.k;
        if(this.props.varList.length > 0){
            this.props.varList.map((elem, index )=>{
                if(elem.text != ""){
                  let type =''
                  if(elem.operator_id)
                    type ='normal'
                  else
                    type ='header'
                    
                    let temp = {};
                    temp.id = formatIdList(elem.id, index);
                    temp.display = elem.text;
                    temp.type = ("session_var" in elem && elem.session_var ) ? "session_var" : type;
                    data.push(temp);
                }
            });
        }
        return (
          <div className="modal-maxWidth-alone">
            <MentionsInput
                singleLine
                allowSpaceInQuery
                style={defaultStyle}
                value={this.state.value}
                onChange={this.onChange}
                id={"input-head"}
                name={'name'}
                markup="[__id__]"
                displayTransform={id => { 
                  let text =String(id); 
                  let bandera = '';
                  let nameVar = '';
                  data.filter((e)=>{
                    if(String(e.id) == id){
                      bandera = e.type; 
                      nameVar = e.display; 
                    }
                  });

                   // De acuerdo a la bandera  será el formato del mentions
                  if (bandera ==  "session_var") {

                    if (text.search('ses') == -1) {
                      return "[ses=" + nameVar + "]";
                    }else{
                      return "[" + nameVar + "]";
                    } 

                  }else if(bandera == "normal"){

                    if (text.search('var') == -1) {
                      return "[var=" + id + "]";
                    }else{
                      return "[" + id + "]";
                    } 

                  }else if(bandera == "header"){

                    if (text.search('var_list') == -1) {
                      return "[var_list=" + id + "]";
                    }else{
                      return "[" + id + "]";
                    } 

                  }else{

                    return "[" + id + "]";

                  } 

              }}
            >
                <Mention trigger={/(\@([A-Za-z0-9_.]*))$/} data={data} style={defaultMentionStyle}/>
            </MentionsInput>
            <small><strong>@</strong> {i18next.t('used_variable')}</small>
            { this.state.error_input && 
                <span className="warning-character">{this.props.msg_error}</span>
            } 
        </div>
        );
    }
}


export default Mentions
