import { AbstractPortFactory, PortModel } from "storm-react-diagrams";

class SimplePortFactory extends AbstractPortFactory {
	cb = (initialConfig) => PortModel;

	constructor(type, cb) {
		super(type);
		this.cb = cb;
	}

	getNewInstance(initialConfig) {
		return this.cb(initialConfig);
	}
}

export default SimplePortFactory