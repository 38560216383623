import React, { Component } from 'react';
import { PortWidget } from "storm-react-diagrams";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash, faCopy, faHome, faFilter } from '@fortawesome/free-solid-svg-icons'
import i18next from '../../../../../i18n';

class QRNodeWidget extends Component {
	static defaultProps = {
		width: 170,
		height: 170,
		node: null,
		text: i18next.t('without_text'),
		title: i18next.t('quick_replies').toUpperCase(),
		home_element: 'set-home'
	};

	state = {
		hideTools: true,
	}

	constructor(props = defaultProps) {
		super(props);
	}

	toggleHome = () => {
		this.props.node.changeHome(this.props.node.id);
	}

	toggleTools = () => {
		this.setState({
			hideTools: !this.state.hideTools
		});
	}

	decodeTitle = (text) => {
		try{
			return text 
		}catch(e){
			console.log("Error: ", e, text)
			return text
		}
		
	}

	render() {
        const node = this.props.node;
        const bls = node.extras.answer_blocks || {};
        const kblocks = Object.keys(bls);        
        const blocks = kblocks.map((k, index) => {
			const name=bls[k].statement_id?bls[k].statement_id:k;
			const namePort ="right-" + name;
            return <PortWidget name={namePort} node={node} key={k} />
        });
		return (
			<div className="container-node">
				<span className={this.props.node.extras.home_element} onClick={this.toggleHome}>
					<FontAwesomeIcon icon={faHome} />
				</span>
				<div className="toolbar-node">
					<span className="tool">
						<FontAwesomeIcon icon={faPen} onClick={ () => this.props.node.toggleEdit('quickreplies', this.props.node.id) } />
					</span>

					<span className="tool" onClick={ () => this.props.node.removeBox(this.props.node.id) }>
						<FontAwesomeIcon icon={faTrash} />
					</span>

					{/* <span className="tool" onClick={() => this.props.node.customClone(this.props.node.id) }>
						<FontAwesomeIcon icon={faCopy} />
					</span> */}

					<span className="tool" onClick={ () => this.props.node.toggleEventModal(this.props.node, true) }>
						<FontAwesomeIcon icon={faFilter} /> {i18next.t('event')}
					</span>
				</div>
				<div className="srd-default-node quickreplies" style={{
					width: this.props.node.extras.width,
					minHeight: 40 + (blocks.length * 14.06)
				}}>
					<div className="srd-default-node__title">
						<div className="srd-default-node__name ">{this.props.node.extras.title}</div>
					</div>
					<div className="srd-default-node__text">
						<div className="plain-text-preview">{i18next.t('statement')+': '} {this.decodeTitle(this.props.node.extras.head) || this.decodeTitle(this.props.node.extras.text)}</div>
					</div>
					<div className="srd-default-node__ports ">
						<div className="srd-default-node__left">
							<PortWidget name="left" node={this.props.node} />
						</div>
						<div className="srd-default-node__right">
							{blocks}
							{
								this.props.node.extras.timeout > 0 &&
								<PortWidget className="port-time timeout-port"name="right-timeout" node={this.props.node} />
							}
						</div>
					</div>
				</div>
			</div>
			
		);
	}
}

export default QRNodeWidget