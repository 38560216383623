import React from 'react';
import Admin from '../../admin/containers/admin';
import SliceMenuApp from '../../admin/components/slice-menu';
import moment from "moment";
import { 
  Col,
  Row,
  Button, 
  Container,
  FormGroup,
  Input,
  Label,
  Table,
  Pagination, 
  PaginationItem, 
  PaginationLink
} from 'reactstrap';
import ToolbarApp from '../../admin/components/toolbar';
import BotMenuOptions from'../../menus/bot-menu-options';
import '../statistic/bot-statistics-layout.css';

import DataTableFilter from '../../analytics/infoViz/common/dataTableFilter'
import i18next from '../../../../i18n';


function BotEventsLayout(props) {
    moment.locale('es');
    // pages array
  /*  var pages = [];
        for(let i=0; i<props.pagination.pages; i++){
            pages.push(i+1);
        } 
    var ep1 = false; // first dots ...
    var ep2 = false; // second dots ...

    var pagination_list = [];
    pages.map((k, index)=>{
        let label = "";
        if(k>1 && k<(props.pagination.current-1)){
            if(!ep1){
                label ="...";
                ep1 = true;
            }
        }else if(k<(props.pagination.pages) && k>(props.pagination.current+1)){
            if(!ep2){
                label ="...";
                ep2 = true;  
            }
        } else {
            label =k;
        }
        if(label){
           pagination_list.push( <PaginationItem key={k} active={k === props.pagination.current}>
              <PaginationLink onClick={(e)=>{props.tableData(k)}}>
                {label}
              </PaginationLink>
            </PaginationItem> );
        }
    })
*/

    // languages
    var languages = [];
    // bot info
    var data = {};
  
    // selects ///  -- languages
    var sel_language = [];
    var aux2 = false;
    for(let i = 0; i < props.languages.length; i++){
      let aux = JSON.parse(props.languages[i]);
      if(aux.id == data.language_id) {
        aux2 = true;
      }
      sel_language.push(<option key={"leng_id-"+i} value={aux.id} selected={aux2} >{aux.language}</option>);
      aux2= false;
    }


    let datos = props.data || [];
    
    let allInteractions =0;
    if(datos.length > 0){
        for (let t = 0; t < datos.length; t++) {
            allInteractions += parseInt(datos[t].interactions);
        }
    }

    /**
 * Tabla de datos con filtro
 */

var dataTable=null;
if(datos){ 
     dataTable = {
        columns: [                      
        {
            label: i18next.t('event'),
            name: 'evento',
            sort: 'asc',
        //     width: 270
            },
          {
            label: i18next.t('total_interactions'),
            name: 'total_interacciones',
            sort: 'asc',
       //     width: 270
          },
          {
            label: i18next.t('porcentage'),
            name: 'porcentaje',
            sort: 'asc',
         //   width: 270
          },
          {
            label: i18next.t('creation'),
            name: 'creacion',
            sort: 'asc',
          }],
          rows: []};

          const rows  =  datos.map((k, index) => {
            let promedio = 0;
           if(allInteractions != 0){
                promedio = Math.floor((parseInt(k.interactions) / allInteractions) * 100);
           } 
             
            const fin=[];
            fin.push(k.event_name); 
            fin.push(k.interactions);
            fin.push(promedio+" %");
            fin.push(moment(k.created).format('DD MMM YYYY'))
            dataTable.rows.push(fin); 
            return k;
        });

    }
    
    const options = {
        filterType: 'multiselect',
        selectableRows:'none',
        resizableColumns: false,
        responsive: "scrollMaxHeight",
        rowsPerPage:10 ,
        print:false,
        download:true,
        viewColumns:false,
        
      };

      var title = ''
/**Tabla de datos anterior */
/*
    const dataTable = datos.map((k, index) => {
        let promedio = 0;
       if(allInteractions != 0){
            promedio = Math.floor((parseInt(k.interactions) / allInteractions) * 100);
       }
        return <tr key={index}>
            <td>
                <p> {k.event_name}</p>
            </td>
            <td>
                <p> {k.interactions}</p>
            </td>
            <td>
                <p className="porcentaje">{promedio} %</p>
            </td>
            <td>{moment(k.created).format('DD MMM YYYY')}</td>
        </tr>
    });
    */
  return (
    <div className="BotLayout">
          <Admin>
            <SliceMenuApp>
                <BotMenuOptions botId={props.botId}  ia_activated={props.ia_activated}/>
            </SliceMenuApp>
            <Container fluid>
                <ToolbarApp>
                    <Row>
                        <Col sm="3" md="3" className="text pdtoolbar">
                        <h2 className="text-left ml-4 font-weight-bold">{i18next.t('event_list')} <span className="btn-help"></span></h2>                      
                        </Col>
                    </Row>
                </ToolbarApp>
            </Container>
            <Container fluid className="box-fluid">
                <Col sm="12" md="10"  className="stadistics-container  offset-md-1">
                    <Row>
                    <Col xs={12} lg={8} className="offset-lg-2 intro-funnels">
                            <Row>
                                <Col xs={12}><img src="/static/img/eventos.png" className="img-fluid"/></Col>
                                <Col xs={12}>
                                    <h3>{i18next.t('msg_consult_actions')}</h3>
                                </Col>
                            </Row>
                        </Col>
                        {/* <Col xs="12" lg="8" className="offset-lg-2">
                            <Row className="filter-information">
                                <Col xs={12} lg={12}>
                                        <Row>
                                            <Col xs={12} lg={10}>
                                                <FormGroup>
                                                    <Label>Buscador</Label>
                                                    <Input className="form-control" type="text" name="buscador" defaultValue={props.buscador} onChange={props.handleFind}></Input>
                                                </FormGroup>   
                                            </Col>
                                            <Col  xs={12} lg={2} className="mt-2">
                                                <Button className="btn btn-guarumo-md btn-block filter-bt" onClick={(e)=>{props.tableData(1)}}>Buscar</Button>
                                            </Col>
                                        </Row>
                                </Col>
                            </Row>
                        </Col> */}
                
                        <Col xs={12} lg={10} className="offset-lg-1">
                        
                                <DataTableFilter
                                title={title}
                                data={ dataTable.rows}
                                columns={dataTable.columns}
                                options={options}
                                />
          
                        {/* <Table className="col-12" bordered>
                            <thead>
                                <tr>
                                    <th>{i18next.t('event')}</th>
                                    <th>{i18next.t('total_interactions')}</th>
                                    <th>{i18next.t('porcentage')}</th>
                                    <th>{i18next.t('creation')}</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {dataTable}
                                </tbody>
                            </Table>
                        <div className="pagination-wrapper">

                        <Pagination className="d-flex align-items-center justify-content-center" size="sm" aria-label="Page navigation funnel">

                            <PaginationItem disabled={props.pagination.current <= 1}>
                            <PaginationLink previous onClick={(e)=>{props.tableData(props.pagination.current-1)}} />
                            </PaginationItem>

                            {
                                pagination_list
                            }

                            <PaginationItem disabled={props.pagination.current >= props.pagination.pages}>
                            <PaginationLink next onClick={(e)=>{props.tableData(props.pagination.current+1)}} />
                            </PaginationItem>

                        </Pagination>

                        </div> */}

                        </Col>
                        



                    </Row>
                </Col>
            </Container>
          </Admin>      
    </div>
  )
}

export default BotEventsLayout