import React, { Component } from 'react';
import { connect } from "react-redux";

import axios from 'axios'; 
import qs from 'qs'; 
import CNMLayout from './carousel-modal-layout'; 
import SMPortModel from '../simplePortModel/SMPortModel'; 

import {
    isAuthorizationValid,
    getRolUser,
  } from "../../../../utils/commonFunction";

  import {
    isDidMount,
    willUnMount,
  } from "../../../../redux/actions/componentMountActions";

  import { APPLICANT } from "../../../../redux/listComponents";


import {
  showSuccess,
  showError,
  showWarning
} from "../../../../redux/actions/loadStateActions";

import {

    iconLoad, 
    iconHideLoad, 
    updateBoxOperator,
    setBotmsgText,
    setChangeConfig,
    updateOperator,
    updateDinamicOperator,
    cleanSelection,
    setVarList,
    addTextBlock,
    updateTextBlock,
    updateStatement,
    createNewStatement,
    updateStatementDynamic,
    addStatementDynamic,
    toggleModalViewBoxCarousel,
    setPostback_buttons,
    setAllPostback_buttons } from "../../../../redux/actions/boxActions";

 
class CNModal extends Component { 
     
    constructor(props){ 
        super(props) 
        this.state = {
            cards: [], 
            nodeId: "", 
            keyword: "", 
            keyword_valid: true, 
            title: "", 
            timeout_valid: true, 
            error_input_empty: {}, 
            error_input_type: {}, 
            error_limit_item: false, 
            update:false
        }
    } 

    componentWillMount() {
        isAuthorizationValid();
        const { isAdmin = false, isSuper = false } = getRolUser();
        this.setState({
          isAdmin: isAdmin,
          isSuperUser: isSuper,
        });
      }
    
    
    
      componentWillUnmount() {
        this.props.willUnMount(APPLICANT.HOME());
      }


    getUrlCarrusel = (e) => { 
        this.props.modal.node.extras.url = e.target.value; 
    }
   
    // Timeout functions 
    getTimeout = (time) => { 
        this.props.modal.node.extras.timeout = time 
    } 

    isTimeoutValid = (valid) => { 
        console.log("is_valid carrusel", valid) 
        this.setState({timeout_valid: valid}, ()=>{ 
            this.forceUpdate(); 
        }) 
    } 

    getMsgTimeout = (msg) => { 
        this.props.modal.node.extras.msg_timeout = msg 
    } 

    handleChange = async () => { 
    
        // this.type = this.type=='false'?'true':'false';
       // let blocks = this.props.modal.node.extras.cards; 
        await this.props.addTextBlock([]);
        
        const { configBoxOperator} = this.props.boxReducer;

        if(!configBoxOperator.type||configBoxOperator.type=='')
            await this.addNewTextBlock();
        this.props.setChangeConfig('type',configBoxOperator.type=='var'?'':'var');
        this.props.setChangeConfig('var','');

        this.setState({update: !this.state.update}, ()=> { 
            this.forceUpdate(); 
        }); 
        
    }
     /*  
     *  getOperatorVisible 
     * @operator = string {si:1, no:0} 
    */ 
    getOperatorVisible = (visible) => { 
        this.props.modal.node.extras.visible = visible 
        let id = this.props.modal.node.extras.operatorDynamic; 
        let data ={ 
            title: '', 
            visible:parseInt(visible) 
        } 
        this.props.updateDinamicOperator(id,data) 
        if(visible == 1){
            let node = this.props.modal.node; 
            let cards = node.extras.cards;

            
            cards.forEach((k,index) => {   
                // const data = {
                //     id: k.titleid,
                //     text: '',
                //     name: '',
                //     is_var:false,
                //     var_name:var_name
                // };

                this.updateStatementDynamic(k.dynamic,'');
                this.updateStatement(k.titleid,'','');
                this.props.updateTextBlock(index,'title','');
                this.props.updateTextBlock(index,'subtitle','');
                this.props.updateTextBlock(index,'url','');
            }); 
        }
        this.forceUpdate();
        
    } 

    validateEmpty = (node)=>{ 
        let blocks = node.extras.cards; 
        const kblocks = Object.keys(blocks); 
        let error_input_empty = {} 
        let err = false; 
        kblocks.forEach((k) => {   
            let button = blocks[k].buttons  
            const kblocks_button = Object.keys(button); 
            kblocks_button.forEach((k) => {  
                let input = $('#input-'+k).val()  
                if(!err && (input.length <= 0)){ 
                    err = true; 
                } 
                error_input_empty[k] = input.length <= 0; 
                this.setState({error_input_empty: error_input_empty}, ()=> { 
                    this.forceUpdate(); 
                }); 
            });      
        });   
        return err; 
    } 


 
    validateType = (node)=>{ 
        let blocks = node.extras.cards; 
        const kblocks = Object.keys(blocks); 
        let error_input_type = {} 
        let err = false; 
        kblocks.forEach((k) => {  
            let button = blocks[k].buttons  
            const kblocks_button = Object.keys(button);   
            kblocks_button.forEach((k) => {  
                if(button[k].type == "web_url"){ 
                    if(button[k].url == ''){ 
                        err = true; 
                        error_input_type[k] = true  
                    } 
                }else{ 
                    error_input_type[k] =false; 
                } 
            }); 
        });   
        this.setState({error_input_type: error_input_type}, ()=> { 
            this.forceUpdate(); 
        }); 
        return err;  
    } 
 
    async jsonConcat (o2) { 
        const { buttons } = this.props.boxReducer;   
        let o1 = buttons;
        console.log("o1",o1);
        console.log("o2",o2);
        for (var key in o2) { 
            if(o2[key].statement_id)
                o1[o2[key].statement_id] = o2[key]; 
            
        } 
    
        console.log(" for o1",o1);
        console.log(" for o2",o2);
    } 
 
    toggleModuleModal = async () => {    
        this.setState({error_limit_item:false});  
        if(!this.state.keyword_valid){
            this.props.showWarning( "El Keyword digitado NO es válido. Por favor verifíquelo.");
             return false; 
       }
 
       if(!this.state.timeout_valid){
        this.props.showWarning( "El TIMEOUT digitado NO es válido. Por favor verifíquelo.");
        return false; 
        }  

       //Pone icono de carga
       this.props.iconLoad();

        let node = this.props.modal.node;  

         //Carga el formulario para extraer los datos
         let form = new FormData(document.getElementById('CM-form'));

        if(this.state.type=='false')
            if(this.validateEmpty(node)) 
                return false; 
        if(this.validateType(node)) 
            return false; 


        const {selectedBoxOperator,avatarid,botmsg_text} = this.props.boxReducer;
             
        //Extrae y actualiza el avatar
        node.extras.avatar = avatarid;
        this.props.setChangeConfig('avatarid',node.extras.avatar);

        //Estrae y actualiza el título del operador
        node.extras.titleOperator = form.get('titleOperator');
        //OJO SE DEBE CAMBIAR AL COMPONENTE title-operator.js cuando todas las cajas usen redux
        await this.props.updateBoxOperator('title', node.extras.titleOperator); 


 
        let headid = node.extras.headid;
       // let text = $('#input-head').val();
        node.extras.head = botmsg_text;
        if(!headid){
            await this.props.createNewStatement(node.extras.dbId,0,node,'headid');
        }else{
            /**
             * Dejar campo de text & name vacio para evitar que se muestre en el mentions 
             * ya que, no es una variable.
             * (statement, text, name,is_var, var_name)
             */
    
             const data = {
                id: node.extras.headid,
                text: (node.extras.is_var) ? node.extras.head : '',
                name: (node.extras.is_var) ? node.extras.head : '',
                is_var:node.extras.is_var,
                var_name:node.extras.var_name? node.extras.var_name:''
            };
            await this.props.updateStatement(data,node,'');
        }
         //Guarda el enunciado
         this.props.setChangeConfig('botmsg_text',node.extras.head);

        //Crear los items_dinámicos y los statements  
        await this.handleCards(node);
        await this.terminar(node);
            
    }

    terminar = async (node) =>{
        
         //Si tiene TIMEOUT configura el puerto
         const ports = node.getPorts(); 
         const kports = Object.keys(ports); 
         const kport = kports.find(k => { 
             const port = ports[k] 
            if (port && port.name)
                return port.name == "right-timeout" 
            else
                return null;
         }) 
          
         if(!kport){ 
             if(node.extras.timeout > 0){ 
                 node.addPort(new SMPortModel(true, "right-timeout", 'cn')); 
             }             
         }else{ 
             if(node.extras.timeout <= 0){ 
                 kports.forEach((kport) => { 
                     const port = ports[kport];                      
                     if(port && port.name == "right-timeout"){ 
                         const links = port.getLinks(); 
                         const klinks = Object.keys(links); 
                         klinks.forEach(klink => { 
                             const link = links[klink]; 
                             link.remove(); 
                         }); 
                         node.removePort(port); 
                     }                     
                 }) 
             }  
         }
 
         let url_rss = this.props.modal.node.extras.url || '';
         const rss_port = kports.find(k => { 
             const port = ports[k] 
            if (port && port.name)
                return port.name == "right-rss" 
            else
                return null;
         })
 
         if (url_rss.length > 0 && !rss_port) {
             node.addPort(new SMPortModel(true, "right-rss", 'cn')); 
         }else if (rss_port && url_rss.length <= 0) {
             kports.forEach((cp) => { 
                 const port = ports[cp];                      
                 if(port && port.name == "right-rss"){ 
                     const links = port.getLinks(); 
                     const klinks = Object.keys(links); 
                     klinks.forEach(klink => { 
                         const link = links[klink]; 
                         link.remove(); 
                     }); 
                     node.removePort(port); 
                 }                     
             }) 
         }
 
         this.forceUpdate(); 
         
 
         const {configBoxOperator,selectedBoxOperator} = this.props.boxReducer;
         
 
         //Extrae y actualiza los Keywords y la IA
         node.extras.keyword = selectedBoxOperator.keyword;
         node.extras.ia_id = selectedBoxOperator.ia_id;
 
         //this.props.setChangeConfig('url_agent',node.extras.url_agent);
         
     
          //Extrae y guarda la nueva configuración
          
          node.extras.config = configBoxOperator;
 
           //Actualizar en BD el título, keyword y configuración del operador
           await this.props.updateOperator(node.extras.titleOperator,node.extras.keyword,node.extras.config,node.extras.ia_id);
 
           //Actualiza el esquema
           await this.props.updateSchema();
 
           //Limpia selecciones
           await this.props.cleanSelection();
  
           //Cierra el modal
          await this.props.toggleModalViewBoxCarousel();
  }

    handleCards = async (node) => { 

        await this.props.setAllPostback_buttons({});
       
        const { text_blocks } = this.props.boxReducer;            
        node.extras.cards = text_blocks;

        let statements_ids=[];

        let cards = text_blocks;      
        const ncards = cards.filter((card) => { 
            return card != null 
        }); 
        node.extras.cards = ncards; 
        let kcards = Object.keys(cards); 
        
        var ports = node.getPorts();  
        var kports = Object.keys(ports); 

        // kcards.forEach(async (k) => {  
        for (const k of kcards) {
            if(cards[k] == null) 
                return; 

            if(this.props.modal.node.extras.visible == true){ 
            }else
            { 
                if(!cards[k].dynamic || cards[k].dynamic==''){
                    let dataOp = {'title':cards[k].title,'operator_id':node.extras.operatorDynamic,'description':cards[k].subtitle,'image':cards[k].url} 
                    await this.props.addStatementDynamic(dataOp,k);
                   
                }else { 
                    // Edita el statement cuando ya existe en la base de datos
                    let data_card ={'title':cards[k].title,'operator_id':node.extras.operatorDynamic,'description':cards[k].subtitle,'image':cards[k].url} 
                    //actualizar operador dinámico 
                    await this.props.updateStatementDynamic(cards[k].dynamic,data_card) 
                }

                
                let button = cards[k].buttons  
                const kblocks_button = Object.keys(button); 
                for (const k_button of kblocks_button) {
                // kblocks_button.forEach(async (k_button) => {  
                    
                    if(button[k_button].statement_id=='')
                    {
                        
                        await this.props.createNewStatement(node.extras.dbId,k_button,node,'var_cards',k).then((res) => {
                            const name=button[k_button].statement_id?button[k_button].statement_id:k;
                             const namePort ="right-" + name;
                             const kport = kports.find(l => { 
                                const port = ports[l] 
                                return port.name == namePort
                            }) 
                            if(!kport){ 
                                if(button[k_button].type =="postback"){
                                    statements_ids[statements_ids.length+1]=button[k_button].statement_id;
                                    node.addPort(new SMPortModel(true, namePort, 'cn')); 
                                }
                            }
                        
                        })
                    
                    }
                    else{
                        const data = {
                            id: button[k_button].statement_id,
                            text: button[k_button].title,
                            name: button[k_button].title,
                            is_var:false,
                            var_name:''
                        };
                        await this.props.updateStatement(data,node,'var_cards').then((res) => {
                            const name=button[k_button].statement_id?button[k_button].statement_id:k_button;
                             const namePort ="right-" + name;
                             const kport = kports.find(l => { 
                                const port = ports[l] 
                                if (port && port.name)
                                    return port.name == namePort
                                else 
                                    return null;
                            }) 
                            if(!kport){ 
                                if(button[k_button].type =="postback"){
                                    statements_ids[statements_ids.length+1]=button[k_button].statement_id;
                                    node.addPort(new SMPortModel(true, namePort, 'cn')); 
                                    
                                }
                            }
                    
                        });
                    }
                    console.log("CICLO POR CARDS button[k_button]",button[k_button])
                    if(button[k_button].type =="postback"){
                        await this.props.setPostback_buttons(button[k_button].statement_id,button[k_button]);
                    }
                   
                }
                
                
            }

            
            // await this.jsonConcat(cards[k].buttons);   
 
                          

        }
       
        await this.handlePorts(node);
    
    }; 
   

    handlePorts = async (node) => { 
        var ports = node.getPorts();  
        var kports = Object.keys(ports); 

        const { postback_buttons } = this.props.boxReducer;   
        let blocks = postback_buttons;
        console.log('handlePorts blocks',blocks);
       
        kports.forEach((k) => { 
            const port = ports[k];   
            if(port.name != "left" && port.name != "right-timeout"){ 
                const n = port.name.replace("right-", "") 
                console.log('n',n)
                console.log('blocks',blocks)
                let kblocks = Object.keys(blocks); 
                console.log('kblocks',kblocks)
                console.log('kports',kports)
                console.log('ports',ports)
                console.log('kblocks[0]',kblocks[0])
                console.log('kblocks["0"]',kblocks["0"])
                console.log('blocks["n"]',blocks[n])
            
                let portExist = blocks[n];
            
               /* kblocks.forEach(key => {
                    console.log('key',key)
                    console.log('blocks[key]',blocks[key])
                if (blocks[key].statement_id === n) {
                    portExist = blocks[key];
                }
                });*/
                console.log('portExist',portExist)
                if(!portExist|| (portExist && portExist.type != "postback")){ 
                    const links = port.getLinks(); 
                    const klinks = Object.keys(links); 
                    klinks.forEach(klink => { 
                        const link = links[klink]; 
                        link.remove(); 
                    }); 
                    node.removePort(port); 
                } 
            } 
             
        }) 

    }       
        // var ports = node.getPorts();  
        // var kports = Object.keys(ports); 
        // console.log("handlePorts ports",ports);
        // console.log("handlePorts kports",kports);
    
        // const { text_blocks} = this.props.boxReducer;    
        // let cards = text_blocks; 
        // let kcards = Object.keys(cards); 
        
        
        
        // kports.forEach((k) => { 
        //     const port = ports[k];   
        //     if(port.name != "left" && port.name != "right-timeout"){ 
        //         const n = port.name.replace("right-", "") 
        //         let buttonsExit = false;
        //         kcards.forEach(async (k) => {  
        //             if(cards[k] == null) 
        //                 return; 
        //             let buttons = cards[k].buttons  
        //             buttonsExit = buttons.find(l => { 
        //                 const button = buttons[l] 
        //                 // return port.name == "right-"+k 
        //                 console.log("port.name == namePort",n);
        //                 console.log("port.name == button.statement_id",button.statement_id);
        //                 return button.statement_id == n
        //             }) 
        //             if(buttons[k].type != "postback"){ 
        //                 const links = port.getLinks(); 
        //                 const klinks = Object.keys(links); 
        //                 klinks.forEach(klink => { 
        //                     const link = links[klink]; 
        //                     link.remove(); 
        //                 }); 
        //                 node.removePort(port); 
        //             } 
        //         });

        //         // if(!buttonsExit){ 
        //         //     const links = port.getLinks(); 
        //         //     const klinks = Object.keys(links); 
        //         //     klinks.forEach(klink => { 
        //         //         const link = links[klink]; 
        //         //         link.remove(); 
        //         //     }); 
        //         //     node.removePort(port); 
        //         // } 
        //     } 
                
        // }) 

	// }      
   
    /* 
    *  addNewTextBlock
    *  Creación de statement en el operador e invoca addStatementDynamic
    */
    addNewTextBlock = async (e) => {
        const { text_blocks} = this.props.boxReducer;
       
        let size = Object.keys(text_blocks).length; 
        if(size > 10){ 
            e.persist(); //event is not being nullified 
            this.setState({error_limit_item:true}); 
            return false; 
        }else{ 
            const blocks = text_blocks;
            const kports = Object.keys(blocks);
            const k = kports.length ? Number(kports[ kports.length-1]) + 1 : 0;
              
            blocks[k] ={titleid: '', title: "", subtitle: "", url: "",dynamic:'', buttons: {}}; 
            this.props.addTextBlock(blocks);
        }
                    
    }

    addNewButton = (card) => { 
        const { text_blocks} = this.props.boxReducer;

        let buttons = text_blocks[card].buttons; 
        const k = Object.keys(buttons).length ? Number(Object.keys(buttons)[ Object.keys(buttons).length-1]) + 1 : 0;
        buttons[k] = {title: "", type: "postback", url: "",statement_id:""};
        this.props.updateTextBlock(card,'buttons',buttons);

    } 

    removeButton= (card, index) => { 
        const { text_blocks} = this.props.boxReducer;
        let blocks = text_blocks[card].buttons; 
        delete blocks[index]; 

        this.props.updateTextBlock(card,'buttons',blocks);
    } 

    limitTextBlock = (prop, event,limit) => { 
        
        if(prop != 'url' ){ 
           let length_data = event.target.value.length 
           if(event.keyCode !== 8){ 
               if( length_data > limit){ 
                   event.preventDefault();  
                   event.stopPropagation(); 
                   return false; 
               } 
           } 
       } 
        
       this.forceUpdate(); 
   } 

   updateButton = (card, index, prop, event) => { 
        const { text_blocks} = this.props.boxReducer;
        let blocks = text_blocks[card].buttons; 
        blocks[index][prop] = event.target.value 
        this.props.updateTextBlock(card,'buttons',blocks);
    } 

 
   
 

    saveStatement = (operator) => { 
        const data = { 
            operator_id: operator, 
            text: "", 
            name: "" 
        }; 
 
        var auth=JSON.parse(localStorage.getItem('Authorization')); 
        var headers={ 
            'Content-Type': 'application/x-www-form-urlencoded', 
            'Authorization': 'Bearer '+ auth.access_token  
        } 
 
        return axios({ 
            method:'post', 
            url:HOST_API +'/api/scheme/statement/', 
            data:qs.stringify(data), 
            headers:headers 
        }); 
    } 
 

    //Actualizar información de statement
    updateStatement = (statement, text, name, is_var, var_name,node,type) => {
        const data = {
            id: statement,
            text: text,
            name: name,
            is_var:is_var,
            var_name:var_name
        };

        this.props.updateStatement(data,node,type);

    }
 
    isKeywordValid = (val) => {         
        this.setState({keyword_valid:val}, ()=>{ 
            this.forceUpdate(); 
        }) 
    } 

 
    callEmoji = (code,k,type)=>{
        let bls = this.props.boxReducer.text_blocks || {};
     

        if(type === "head"){

            const cursorPos = $('#input-head').prop('selectionStart');
            const v = $('#input-head').val();
            const textBefore = v.substring(0,  cursorPos);
            const textAfter  = v.substring(cursorPos, v.length);


            let enunciado = textBefore + ` ${code}` + textAfter;
            $('#input-head').val(enunciado);

        }else if(type ==='item'){
           let i1=k.substring(0,k.indexOf('-'));
           let i2 = k.substring(k.indexOf('-')+1,k.length);
            //botones
            const buttons = bls[i1].buttons;

            const nameDiv='#input-'+k;
            const cursorPosItem = $(nameDiv).prop('selectionStart');
            const vItem = $(nameDiv).val();
            let item = '';
            if(vItem){
                const textBeforeItem = vItem.substring(0,  cursorPosItem);
                const textAfterItem  = vItem.substring(cursorPosItem, vItem.length);

                item = textBeforeItem + ` ${code}` + textAfterItem;
            }
            else 
                item = `${code}`;

            $(nameDiv).val(item);

            buttons[i2].title = item;

            this.props.updateTextBlock(i1, 'buttons', buttons);

        }else if(type ==='titulo'){
            // let node = this.props.modal.node; 
            // let cards = node.extras.cards;  
            // let card = cards.map((c)=>{
            //     let t = c.title;
            //         t += ` ${code}`;
            //     c.title = t 
            //     return c;
            // })
            // node.extras.cards = card;


            const cursorPos = $('#titulo-'+k).prop('selectionStart');
            const v = $('#titulo-'+k).val();
            const textBefore = v.substring(0,  cursorPos);
            const textAfter  = v.substring(cursorPos, v.length);


            let titulo = textBefore + ` ${code}` + textAfter;
            
            const post = k.substring(k.lastIndexOf('-')+1,k.length);
            this.props.updateTextBlock(post, 'title', titulo);

            
            $('#titulo-'+k).val(titulo);

        }else if(type ==='subtitulo'){
           
            const cursorPos = $('#subtitulo-'+k).prop('selectionStart');
            const v = $('#subtitulo-'+k).val();
            const textBefore = v.substring(0,  cursorPos);
            const textAfter  = v.substring(cursorPos, v.length);


            let subtitulo = textBefore + ` ${code}` + textAfter;
            
            const pos = k.substring(k.lastIndexOf('-')+1,k.length);
            this.props.updateTextBlock(pos, 'subtitle', subtitulo);

            
            $('#subtitulo-'+k).val(subtitulo);

            // let node = this.props.modal.node; 
            // let cards = node.extras.cards;  
            // let card = cards.map((c)=>{
            //     let t1 = c.subtitle;
            //         t1 += ` ${code}`;
            //     c.subtitle = t1   
            //     return c;
            // })
            // node.extras.cards = card;
        }
        this.forceUpdate(); 
    }

 
     showContent=()=>{
        const {  isLogin } = getRolUser();
        if(isLogin){
          return (
          <CNMLayout 
          toggleModuleModal={this.toggleModuleModal} 
          addNewTextBlock={this.addNewTextBlock} 
          addNewButton={this.addNewButton} 
          removeButton={this.removeButton} 
          updateButton={this.updateButton}
          isKeywordValid={this.isKeywordValid} 
          getOperatorVisible = {this.getOperatorVisible} 
          operator = {this.props.modal.node.extras.operatorDynamic || 'none'} 
         
          getTimeout={this.getTimeout} 
          getMsgTimeout={this.getMsgTimeout} 
          isTimeoutValid={this.isTimeoutValid} 
          timeoutValue={this.props.modal.node.extras.timeout} 
          timeoutMsg={this.props.modal.node.extras.msg_timeout} 
           
          error_input_type={this.state.error_input_type} 
          error_input_empty={this.state.error_input_empty} 
          handleChange={this.handleChange}
          limitTextBlock={this.limitTextBlock }  
          error_limit_item ={this.state.error_limit_item}  
          getOperator = {this.getOperator} 
          visible = {this.props.modal.node.extras.visible || ''} 
          callEmoji = {this.callEmoji}
          getUrlCarrusel = {this.getUrlCarrusel}
          urlCarrusel = {this.props.modal.node.extras.url || ''}
          update = {this.state.update}
          /> 
          );
        }
        return <ModalLoading />
      }

    render() {
        const {selectedBoxOperator } = this.props.boxReducer;
            //Si la caja seleccionada es tipo carrusel
          if(selectedBoxOperator&&selectedBoxOperator.operatortype_id==3)
          {
            return this.showContent();
          }
          else
            return <div></div>
    }
} 
 
const mapStateToProps = ({ botReducer,boxReducer,homeReducer, componentMountReducer }) => {
    return { botReducer,boxReducer,homeReducer, componentMountReducer };
   };
   
   const mapDispatchToProps = {
    iconLoad,
    iconHideLoad,
    updateBoxOperator,
    setBotmsgText,
    setChangeConfig,
    updateOperator,
    updateDinamicOperator,
    addStatementDynamic,
    cleanSelection,
    setVarList,
    toggleModalViewBoxCarousel,
    createNewStatement,
    updateStatementDynamic,
    isDidMount,
    willUnMount,
    showSuccess,
    showError,  
    showWarning,
    addTextBlock,
    updateTextBlock,
    updateStatement,
    setPostback_buttons,
    setAllPostback_buttons
   };
   
   export default connect(mapStateToProps, mapDispatchToProps)(CNModal);
