import React, { Component } from 'react';
import { PortWidget } from "storm-react-diagrams";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash, faCopy, faHome, faFilter } from '@fortawesome/free-solid-svg-icons'
import i18next from '../../../../../../i18n';

class EANodeWidget extends Component {
	static defaultProps = {
		width: 170,
		height: 170,
		node: null,
		text: i18next.t('without_text'),
		title: i18next.t('end_api').toUpperCase(),
		home_element: 'set-home'
	};

	state = {
		hideTools: true,
	}

	constructor(props = defaultProps) {
		super(props);
	}

	toggleHome = () => {
		this.props.node.changeHome(this.props.node.id);
	}

	toggleTools = () => {
		this.setState({
			hideTools: !this.state.hideTools
		});
	}

	render() {
		return (
			<div className="container-node">
				<span className={this.props.node.extras.home_element} onClick={this.toggleHome}>
					<FontAwesomeIcon icon={faHome} />
				</span>
				<div className="toolbar-node">
					<span className="tool">
						<FontAwesomeIcon icon={faPen} onClick={ () => this.props.node.toggleEdit('api', this.props.node.id) } />
					</span>

					<span className="tool" onClick={ () => this.props.node.removeBox(this.props.node.id) }>
						<FontAwesomeIcon icon={faTrash} />
					</span>

					<span className="tool" onClick={ () => this.props.node.toggleEventModal(this.props.node, true) }>
						<FontAwesomeIcon icon={faFilter} /> {i18next.t('event')}
					</span>
				</div>
				<div className="srd-default-node external_api" style={{
					width: this.props.node.extras.width,
				}}>
					<div className="srd-default-node__title">
						<div className="srd-default-node__name ">{this.props.node.extras.title}</div>
					</div>
					<div className="srd-default-node__text">
						<div className="plain-text-preview">{this.props.node.extras.text}</div>
					</div>
					<div className="srd-default-node__ports ">
						<div className="srd-default-node__left">
							<PortWidget name="left" node={this.props.node} />
						</div>
						<div className="srd-default-node__right">
							<PortWidget name="right" node={this.props.node} />
						</div>
					</div>
				</div>
			</div>
			
		);
	}
}

export default EANodeWidget