import $ from "jquery";
import React from "react";
import ReactDOM from "react-dom";

window.jQuery = $;
window.$ = $;

require("jquery-ui-sortable");
require("formBuilder");

class FormBuilder extends React.Component {

  constructor(props){
    super(props)
    var self = this;
    let aux = {
        onSave: (e) => { 
          self.props.saveFormBuilder(self.form.actions.getData()) 
        },
        formData: JSON.stringify(self.props.formData)
    };
    this.options = aux;
  }

  componentDidMount() {
    this.form = $(this.fb).formBuilder(this.options);
  }

  render() {
    return (
      <div id="fb-editor" ref={el => (this.fb = el)}>
      </div>
    );
  }
}

export default FormBuilder;
