import { NodeModel } from "storm-react-diagrams";
import SMPortModel from "../simplePortModel/SMPortModel";
import i18next from '../../../../../i18n';

class CDNodeModel extends NodeModel {
	extras = {
		ia_id:0,
		text: i18next.t('without_text'),
		title: i18next.t('calendar').toUpperCase(),
		botmsg_id:"",
		botmsg_text:"",
		botmsg_name:"",
		type:"",
		home_element: 'set-home',
		width: 200,
		dbId: 0,
		keyword: "",
		var_name:"",
		var_name_json:"",
		statements_id: [{}],
		config:{
			item_type: "date_calendar",
			zone:"America/Bogota",
			startDate:null,//"01/01/2020",
			endDate:null,
			showTimeSelect:true,
			timeIntervals:30,
			minDate:null,
			maxDate: null,
			maxTime: null,
			minTime: null,
			excludeDates: null,
			excludeTimes: null
			
		}
	}
	removeBox = null
	changeHome = null
	toggleEdit = null
	customClone = null
	
	constructor() {
		super("cd");
		this.addPort(new SMPortModel(false, "left", "cd"));
		this.addPort(new SMPortModel(true, "right", "cd"));

	}
}

export default CDNodeModel