import React from 'react';
import { Alert, Input,UncontrolledTooltip } from 'reactstrap';
import i18next from '../../../i18n';

function KeywordLayout(props){
    return(
        <div>            
            <label>{i18next.t('keyword')} <i className="fa fa-info-circle" id="tooltipKeyword"></i></label>
            <UncontrolledTooltip placement="right" target="tooltipKeyword">{i18next.t('tooltipKeyword')}</UncontrolledTooltip>
            <Input type="text" className="inputModal" maxLength="30" placeholder={i18next.t('keyword')} defaultValue={props.keyword || ''} onChangeCapture={props.updateKeyword} required></Input>
            <Alert color="danger" isOpen={!props.valid}>
                {i18next.t('invalidKeyword')}.
            </Alert>
        </div>
    )
    
}

export default KeywordLayout
