import { NodeModel } from "storm-react-diagrams";
import SMPortModel from "../simplePortModel/SMPortModel";
import i18next from '../../../../../i18n';

class LCNodeModel extends NodeModel {
	extras = {
		ia_id:0,
		text: i18next.t('without_text'),
		title: i18next.t('multimedia').toUpperCase(),
		botmsg_id:"",
		botmsg_text:"",
		botmsg_name:"",
		type:"",
		home_element: 'set-home',
		width: 200,
		dbId: 0,
		keyword: "",
		var_name:"",
		var_name_json:"",
		statements_id: [{}],
		config:{
			item_type: "multimedia",
		},
		variables:{},
		variablesTemplate: [{
			key: '',
			value: ''
	  }],
		dinamicVariables:{}
	}
	removeBox = null
	changeHome = null
	toggleEdit = null
	customClone = null
	
	constructor() {
		super("multimedia");
		this.addPort(new SMPortModel(false, "left", "multimedia"));
		this.addPort(new SMPortModel(true, "right", "multimedia"));

	}
}

export default LCNodeModel