import React, { Component } from "react";
import { connect } from "react-redux";

import ModalLoading from "../../../components/modals/Loading/moda-loading";

import CustomersReportLayout from "./bot_customer_reports_layout";

import {
  isAuthorizationValid,
  getRolUser,
  isEmpty,
} from "../../../utils/commonFunction";

import { APPLICANT } from "../../../redux/listComponents";
import {
  isDidMount,
  willUnMount,
} from "../../../redux/actions/componentMountActions";
import {
  getTockenCubejs,
  getAllListCompanies,
  setSelectedCompany,
  getAllBotsbyCompany,
  //getPagination,
  interactionReportTable,
  getBuilderQuery,
  iconLoad,
  iconHideLoad,
  updateReportType
} from "../../../redux/actions/analyticsActions";

class CustomersReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAdmin: false,
      isSuperUser: false,
      hideLoad: false,
      user: "",
    };
  }
  componentWillMount() {
    isAuthorizationValid();
    const { isAdmin = false, isSuper = false } = getRolUser();
    this.setState({
      isAdmin: isAdmin,
      isSuperUser: isSuper,
    });
  }

  async componentDidMount() {
    const {
      homeReducer: { listAllCompanies },
    } = this.props;

    this.props.isDidMount(APPLICANT.HOME());

    //----------------------------------------------------------------
    //  PONE EL ICONO DE CARGA
    //----------------------------------------------------------------
    await this.props.iconLoad();

    //----------------------------------------------------------------
    //  ACTUALIZA EL TOCKEN DE CUBEJS
    //----------------------------------------------------------------
    //  await this.props.getTockenCubejs();

    //----------------------------------------------------------------
    //  LISTA TODAS LAS COMPAÑÍAS, COJE LA PRIMERA COMO SELECTCOMPANY
    //----------------------------------------------------------------

    if (isEmpty(listAllCompanies)) {
      await this.props.getAllListCompanies();
    }
    const {
      analyticsReducer: {
        listAllBotsbyCompany,
        selectedCompany,
        reportType
      },
    } = this.props;

    //-------------------------------------------------------------------
    //  LISTA LOS BOTS Y SELECCIONA EL PRIMERO DE LA COMPAÑÍA
    //-------------------------------------------------------------------

    if (selectedCompany!='' && isEmpty(listAllBotsbyCompany)) {
      await this.props.getAllBotsbyCompany(selectedCompany);
    }

    if(reportType=='' )
    {
        /**
       * Actualiza el tipo de reporte a reporte de interacciones
       */
        await this.props.updateReportType('interactions');
    }
    else if(reportType!='interactions')
    {
      /**
       * Actualiza el tipo de reporte a reporte de interacciones
       */
      await this.props.updateReportType('interactions');
    }
    else{
        //----------------------------------------------------
        //  OCULTA EL ICONO DE CARGA
        //----------------------------------------------------
        await this.props.iconHideLoad();
    }
   
  }

  shouldComponentUpdate(nextProps, nextState) {
    const nextCompany = nextProps.analyticsReducer.selectedCompany;
    const company = this.props.analyticsReducer.selectedCompany;

    const nextBot = nextProps.analyticsReducer.selectedBot;
    const Bot = this.props.analyticsReducer.selectedBot;

    //SIN CUBEJS
    const operationFinal = this.props.analyticsReducer.dataframe;
    const operationFinalNew = nextProps.analyticsReducer.dataframe;

    const reporteType = this.props.analyticsReducer.reportType;
    const reporteTypeNew = nextProps.analyticsReducer.reportType;

    //CON CUBEJS
    //const operationFinal = this.props.analyticsReducer.operationFinal;
    //const operationFinalNew = nextProps.analyticsReducer.operationFinal;

    if (nextCompany !== company) return true;
    else if (nextBot !== Bot) return true;
    else if (operationFinal !== operationFinalNew) return true;
    else if(reporteType !== reporteTypeNew) return true;
    else
      return false;
  }
  async componentWillUpdate(nextProps, nextState) {

    const nextCompany = nextProps.analyticsReducer.selectedCompany;
    const company = this.props.analyticsReducer.selectedCompany;

    const nextBot = nextProps.analyticsReducer.selectedBot;
    const Bot = this.props.analyticsReducer.selectedBot;

    const nextBotReducer = nextProps.botReducer.bot;
    const BotReducer = this.props.botReducer.bot;

    //SIN CUBEJS
    const operationFinal = this.props.analyticsReducer.dataframe;
    const operationFinalNew = nextProps.analyticsReducer.dataframe;

    //CON CUBEJS
    //const operationFinal = this.props.analyticsReducer.operationFinal;
    //const operationFinalNew = nextProps.analyticsReducer.operationFinal;

    if (nextCompany.lenght == 0 || nextBot.lenght == 0 || nextBotReducer.lenght == 0) {
      //console.log("No Update!!");
    } else if (nextCompany != company) {
      /**
       * Ingresa cuando se cambia la compañía seleccionada
       */

      //----------------------------------------------------
      //  MUESTRA EL ICONO DE CARGA
      //----------------------------------------------------
      await this.props.iconLoad();

      //----------------------------------------------------
      //  Lista los bots de la compañía seleccionada
      //----------------------------------------------------
      await this.props.getAllBotsbyCompany(
        this.props.analyticsReducer.selectedCompany
      );

      /**
       * Calcula el reporte de iteraciones sin cubejs
       */
      // await this.props.interactionReportTable('customers');

      //----------------------------------------------------
      //  OCULTA EL ICONO DE CARGA
      //----------------------------------------------------
      // await this.props.iconHideLoad();

    } else if (nextBot !== Bot) {
      /**
       * Ingresa cuando se cambia el bot seleccionado y se ha generado la nueva consulta
       */

      if (nextBot.id) {
        // if (operationFinal.filters) {
        //   if (operationFinal.filters[0].values[0] !== Bot.id) {
            
              /**
               * Calcula el reporte de iteraciones sin cubejs
               */
              await this.props.interactionReportTable('customers');
          
        } else {
          if (Bot.id) {

            /**
             * Calcula el reporte de iteraciones sin Cubejs
             */
            await this.props.interactionReportTable('customers');

            //----------------------------------------------------
            //  OCULTA EL ICONO DE CARGA
            //----------------------------------------------------
            await this.props.iconHideLoad();
          }
        
      }
    } else if (operationFinal != operationFinalNew) {
      if (Bot.id) {
        //----------------------------------------------------
        //  OCULTA EL ICONO DE CARGA
        //----------------------------------------------------
        await this.props.iconHideLoad();
      }
    }
    else{
      //----------------------------------------------------
      //  MUESTRA EL ICONO DE CARGA
      //----------------------------------------------------
      await this.props.iconLoad();
      
      /**
     * Calcula el reporte de iteraciones sin Cubejs
     */
       await this.props.interactionReportTable('customers');

       //----------------------------------------------------
       //  OCULTA EL ICONO DE CARGA
       //----------------------------------------------------
       await this.props.iconHideLoad();
    }
  }

  componentWillUnmount() {
    this.props.willUnMount(APPLICANT.HOME());
  }

  showContent = () => {
    console.log('BIEN BIEN');
    const { isLogin } = getRolUser();
    if (isLogin) {
      return (
        <React.Fragment>
          <CustomersReportLayout
            isAdmin={this.state.isAdmin}
            isSuperUser={this.state.isSuperUser}
          />
        </React.Fragment>
      );
    }
    return <ModalLoading />;
  };

  render() {
    return this.showContent();
  }
}

const mapStateToProps = ({
  analyticsReducer,
  homeReducer,
  componentMountReducer,
  botReducer
}) => {
  return { analyticsReducer, homeReducer, componentMountReducer, botReducer };
};

const mapDispatchToProps = {
  getAllListCompanies,
  getAllBotsbyCompany,
  setSelectedCompany,
  getTockenCubejs,
  isDidMount,
  willUnMount,
  getBuilderQuery,
  interactionReportTable,
  iconLoad,
  iconHideLoad,
  updateReportType
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomersReport);
