import { NodeModel } from "storm-react-diagrams";
import SMPortModel from "../simplePortModel/SMPortModel";
import i18next from '../../../../../i18n';

class UFNodeModel extends NodeModel {
	extras = {
		ia_id:0,
		text: i18next.t('without_text'),
		title: i18next.t('upload_file').toUpperCase(),
		botmsg_id:"",
		isVariable:false,
		name_variable:"",
		botmsg_text:"",
		botmsg_name:"",
		cancelmsg:"",
		home_element: 'set-home',
		width: 200,
		dbId: 0,
		keyword: "",
		msg_timeout: "",
        timeout: 0
	}
	removeBox = null
	changeHome = null
	toggleEdit = null
	customClone = null
	
	constructor() {
		super("uf");
		this.addPort(new SMPortModel(false, "left", "uf"));
		this.addPort(new SMPortModel(true, "right-load", "uf"));
		this.addPort(new SMPortModel(true, "right-cancel", "uf"));

	}
}

export default UFNodeModel