// Dependencies
import { combineReducers } from 'redux';

// Containers Reducers
import loginReducer from './reducers/loginReducer';
import componentMountReducer from './reducers/componentMountReducer'
import homeReducer from "./reducers/homeReducer";
import analyticsReducer from "./reducers/analyticsReducer";
import boxReducer from "./reducers/boxReducer";
import botReducer from "./reducers/botReducer";
import loadStateReducer from "./reducers/loadStateReducer";
import companyReducer from './reducers/companyReducer';
import templateReducer from './reducers/templateReducer';
import databaseReducer from './reducers/databaseReducer';


export default combineReducers({
    loginReducer,
    homeReducer,
    componentMountReducer,
    loadStateReducer,
    companyReducer,
    templateReducer,
    analyticsReducer,
    boxReducer,
    botReducer,
    databaseReducer
});