import React, { Component } from 'react';
import {  
    Tooltip
} from 'reactstrap';


class BtnTooltip extends Component {

    state = {
        isOpen: false
    }

    constructor(props){
        super(props);
    }

    btnClick = () => {
        if (this.props.clickAction && typeof this.props.clickAction == "function"){
            this.props.clickAction();
        }
    }

    toggleTooltip = () => {
        let nv = !this.state.isOpen;
        this.setState({
            isOpen: nv
        });
    }

    render() {

        return (
            <div>
                <button className="btn-module" onClick={() => this.btnClick() } id={this.props.elementID}>
                    {
                        this.props.children
                    }
                </button> 
                <Tooltip placement="bottom" isOpen={this.state.isOpen} target={this.props.elementID} toggle={this.toggleTooltip}> 
                    {
                        this.props.text
                    }
                </Tooltip> 
            </div>
        )
    }
}

export default BtnTooltip