import React, { Component } from 'react';
import { connect } from "react-redux";

import BotLayout from './bot-layout';
import { DiagramEngine, DiagramModel } from "storm-react-diagrams"
import SMNodeFactory from '../operators/single-message/SMNodeFactory'
import SMNodeModel from '../operators/single-message/SMNodeModel';
import SMPortModel from '../operators/simplePortModel/SMPortModel';
import SimplePortFactory from '../operators/simplePortModel/SimplePortFactory';
import axios from 'axios';
import { find, propEq } from "ramda";
import qs from 'qs';
import ModalLoad from '../../../components/modals/modal-load';
import ModalLoading from '../../../components/modals/Loading/moda-loading';

import GONodeModel from '../operators/go-to/GONodeModel';
import GONodeFactory from '../operators/go-to/GONodeFactory';

import QRNodeModel from '../operators/quick-replies/QRNodeModel';
import QRNodeFactory from '../operators/quick-replies/QRNodeFactory';
import UFNodeModel from '../operators/upload-file/UFNodeModel';
import UFNodeFactory from '../operators/upload-file/UFNodeFactory';
import EBNodeModel from '../operators/elements-button/EBNodeModel';
import EBNodeFactory from '../operators/elements-button/EBNodeFactory';
import CNodeModel from '../operators/carousel/CNodeModel';
import CNodeFactory from '../operators/carousel/CNodeFactory';
import FRMNodeModel from '../operators/form/FRMNodeModel';
import FRMNodeFactory from '../operators/form/FRMNodeFactory';
import WVNodeModel from '../operators/web-view/WVNodeModel';
import WVNodeFactory from '../operators/web-view/WVNodeFactory';
import APNodeModel from '../operators/agent-pass/APNodeModel';
import APNodeFactory from '../operators/agent-pass/APNodeFactory';

import VariableNodeModel from '../operators/variable/VariableNodeModel';
import VariableNodeFactory from '../operators/variable/VariableNodeFactory';

import EANodeModel from '../operators/api/end-api/EANodeModel';
import EANodeFactory from '../operators/api/end-api/EANodeFactory';

import HANodeModel from '../operators/api/home-api/HANodeModel';
import HANodeFactory from '../operators/api/home-api/HANodeFactory';

import base64 from 'base-64'
import utf8 from 'utf8'
import IFNodeFactory from '../operators/conditional/IFNodeFactory';
import IFNodeModel from '../operators/conditional/IFNodeModel';

import SMSFactory from '../operators/sms/SMSFactory';
import SMSModel from '../operators/sms/SMSModel';

import DSNodeFactory from '../operators/desuscripcion/DSNodeFactory';
import DSNodeModel from "../operators/desuscripcion/DSNodeModel";

import EMAILNodeFactory from '../operators/email/EMAILNodeFactory';
import EMAILNodeModel from "../operators/email/EMAILNodeModel";

import TRIGGERNodeFactory from '../operators/trigger/TRIGGERNodeFactory';
import TRIGGERNodeModel from "../operators/trigger/TRIGGERNodeModel";

import CDNodeModel from '../operators/calendar-date/CDNodeModel';
import CDNodeFactory from '../operators/calendar-date/CDNodeFactory';

import SQRNodeModel from '../operators/scan-qr/SQRNodeModel';
import SQRNodeFactory from '../operators/scan-qr/SQRNodeFactory';

import CATNodeModel from '../operators/catalog/CATNodeModel';
import CATNodeFactory from '../operators/catalog/CATNodeFactory';

import PAYNodeModel from '../operators/payment/PAYNodeModel';
import PAYNodeFactory from '../operators/payment/PAYNodeFactory';

import LCNodeModel from '../operators/location-coordinates/LCNodeModel';
import LCNodeFactory from '../operators/location-coordinates/LCNodeFactory';


import MMNodeModel from '../operators/multimedia/MMNodeModel';
import MMNodeFactory from '../operators/multimedia/MMNodeFactory';

import CRNodeModel from '../operators/custom-report/CRNodeModel';
import CRNodeFactory from '../operators/custom-report/CRNodeFactory';

import BNodeModel from '../operators/blockchain/BNodeModel';
import BNodeFactory from '../operators/blockchain/BNodeFactory';


require('./bot.css');

import { getHandlerPOST, getHandlerGET, getHandlerPUT, getHandlerDELETE } from '../../../utils/handlersApi'
import { cerrarSesion, getRolUser,isAuthorizationValid, validKeysObjectsWithActions, isEmpty} from '../../../utils/commonFunction'



import { isDidMount, willUnMount } from "../../../redux/actions/componentMountActions";
import { iconLoad, 
        iconHideLoad, 
        getListTypeCalendar,
        updateOperator,
        setSelectedOperator,
        setVariablesBoxOperator,
        setSelectedTypeLocation,
        setBotmsgText,
        toggleModalViewBoxCalendar,
        toggleModalViewBoxScanQR,
        toggleModalViewBoxCatalog,
        toggleModalViewBoxPayment,
        toggleModalViewBoxLocation,
        toggleModalViewBoxMultimedia,
        setSelectedTypeMultimedia,
        toggleModalViewBoxCReport,
        toggleModalViewBoxBlockchain,
        toggleModalViewBoxWebview,
        toggleModalViewBoxVariable,
        toggleModalViewBoxUploadFile,
        toggleModalViewBoxTrigger,
        toggleModalViewBoxSMS,
        toggleModalViewBoxText,
        toggleModalViewBoxQuickReply,
        toggleModalViewBoxGoTo,
        toggleModalViewBoxForm,
        toggleModalViewBoxEmail,
        toggleModalViewBoxButtons,
        toggleModalViewBoxDesuscription,
        toggleModalViewBoxConditional,
        toggleModalViewBoxApi,
        setContentType,
        setUrlMultimediaPreview,
        toggleModalViewBoxAgent,
        toggleModalViewBoxCarousel,
        setVarList,
        setListAllOperators,
        setAvatarid,
        setSelectedListLocation,
        setDinamicVariablesBoxOperator,
        setChangeConfig,
        setConfig,
        addTextBlock,
        setSelectedProvider,
        setNode,
        changeActiveTab,
        updateConfigOperator,
        removeModuleDB,
        deleteOperator,
        addButtons,
        setHead
        } from "../../../redux/actions/boxActions";
import { showSuccess, loading, showWarning, hideAlert, showError, setTitle } from '../../../redux/actions/loadStateActions';
import { setSelectedBot,setSelectedScheme,setListIntent } from '../../../redux/actions/botActions';
import { imageTemplate, resetTempVariablesTemplate} from '../../../redux/actions/templateActions'
import i18next from '../../../../i18n';


const templateModal = {
    isOpen: false,
    node: {
        extras: {}
    }
};

const drawModalSimple = {
    title: '',
    width: 170,
    head: '',
    text: i18next.t('edit_component_info'),
    evento: '',
    titleOperator: '',
    isEventEdited: false
};

const drawModalComplete = {
    title: '',
    width: 170,
    head: '',
    text: i18next.t('edit_component_info'),
    evento: '',
    titleOperator: '',
    keyword: '',
    home_element: 'set-home',
    msg_timeout: "",
    timeout: 0
};

class Bot extends Component {
    _isMounted = false
        //Motor de flujo 
    engine = null
        //modelo donde vamos a dibujar nuestros nodos 
    model = null

    ChatBotItem = {}

    //state por defecto 
    state = {
        plataformas: [],
        modalContact: false,
        hideLoad: 0,
        listWebform: [], // lista de webforms
        // varList: [], // lista de enunciados
        ia_activated: false,
        botid: this.props.match.params.id || 0, //id del bot activo
        botVersionId: 0, //version del bot activo
        initial_step: '0', //initial step del bot
        init_schema: '', //Schema inicial
        anonimo_scheme: '',
        idSchema: this.props.match.params.idSchema, //id del schema creado en la vista schemas
        current_node: '', //node actual seleccionado
        company_id: 0,
        //listado de modales
        modals: {
            /* 
             * Object.assign : hace copia del objeto (templateModal)
             * sobreescribe o agrega propiedades que se agreguen dentro de assign
             */
            m1: Object.assign(templateModal, { node: { extras: { text_blocks: {} } } }),
            m2: Object.assign(templateModal, { node: { extras: { answer_blocks: {} } } }),
            m3: Object.assign(templateModal),
            m4: Object.assign(templateModal),
            m5: Object.assign(templateModal),
            m6: Object.assign(templateModal),
            m7: Object.assign(templateModal),
            m8: Object.assign(templateModal),
            m9: Object.assign(templateModal),
            m10: Object.assign(templateModal, { botversion_id: '' }),
            m11: { isOpen: false, botversion_id: '' },
            m12: Object.assign(templateModal, { botversion_id: '' }),
            m13: Object.assign(templateModal, { botversion_id: '' }),
            m_if: Object.assign(templateModal, { botversion_id: '' }),
            m14: Object.assign(templateModal, { node: { extras: { text_blocks: {} } } }),
            m15: Object.assign(templateModal),
            m16: Object.assign(templateModal),
            m17: Object.assign(templateModal),
            m18: Object.assign(templateModal),
            m_cd: Object.assign(templateModal),
            m_scanqr: Object.assign(templateModal),
            m_catalog: Object.assign(templateModal),
            m_payment: Object.assign(templateModal),
            m_location: Object.assign(templateModal),
            m_multimedia: Object.assign(templateModal),
            m_creport: Object.assign(templateModal),
            m_blockchain: Object.assign(templateModal),
        }
    }

    constructor(props) {
        super(props)

        //creamos la instacia del motor de flujos
        this.engine = new DiagramEngine();
        //this.engine = new SRD.DiagramEngine(); >>>
 
        //instalamos las factories por defecto
        //una factory es la interfaz que comunica el tipo de node a dibujar y el componente de react que controla el html y la interaccion de usuarios
        this.engine.installDefaultFactories();

    }

    /*  
     * 
     * funcion de react que se ejecuta cuando el componente ya fue montado 
     *  
     */

    componentDidMount() {
        this._isMounted = true
        
        this.getBot();


        
        //registramos las custom factories creadas para nuestros modulos
        //@sm = es la factory e identificador para nuestros simple modules o modulos de texto plano
        this.engine.registerPortFactory(new SimplePortFactory("sm", config => new SMPortModel()));
        this.engine.registerPortFactory(new SimplePortFactory("qr", config => new SMPortModel()));
        this.engine.registerPortFactory(new SimplePortFactory("uf", config => new SMPortModel()));
        this.engine.registerPortFactory(new SimplePortFactory("eb", config => new SMPortModel()));
        this.engine.registerPortFactory(new SimplePortFactory("cn", config => new SMPortModel()));
        this.engine.registerPortFactory(new SimplePortFactory("frm", config => new SMPortModel()));
        this.engine.registerPortFactory(new SimplePortFactory("wv", config => new SMPortModel()));
        this.engine.registerPortFactory(new SimplePortFactory("cd", config => new SMPortModel()));
        this.engine.registerPortFactory(new SimplePortFactory("scanqr", config => new SMPortModel()));
        this.engine.registerPortFactory(new SimplePortFactory("catalog", config => new SMPortModel()));
        this.engine.registerPortFactory(new SimplePortFactory("payment", config => new SMPortModel()));
        this.engine.registerPortFactory(new SimplePortFactory("multimedia", config => new SMPortModel()));
        this.engine.registerPortFactory(new SimplePortFactory("location", config => new SMPortModel()));
        this.engine.registerPortFactory(new SimplePortFactory("ha", config => new SMPortModel()));
        this.engine.registerPortFactory(new SimplePortFactory("ea", config => new SMPortModel()));
        this.engine.registerPortFactory(new SimplePortFactory("ap", config => new SMPortModel()));
        this.engine.registerPortFactory(new SimplePortFactory("go", config => new SMPortModel()));
        this.engine.registerPortFactory(new SimplePortFactory("if", config => new SMPortModel()));
        this.engine.registerPortFactory(new SimplePortFactory("sms", config => new SMPortModel()));
        this.engine.registerPortFactory(new SimplePortFactory("img", config => new SMPortModel()));
        this.engine.registerPortFactory(new SimplePortFactory("dsus", config => new SMPortModel()));
        this.engine.registerPortFactory(new SimplePortFactory("email", config => new SMPortModel()));
        this.engine.registerPortFactory(new SimplePortFactory("trigger", config => new SMPortModel()));
        this.engine.registerPortFactory(new SimplePortFactory("variable", config => new SMPortModel()));
        this.engine.registerPortFactory(new SimplePortFactory("creport", config => new SMPortModel()));
        this.engine.registerPortFactory(new SimplePortFactory("blockchain", config => new SMPortModel()));
        
        this.engine.registerNodeFactory(new CRNodeFactory());
        this.engine.registerNodeFactory(new BNodeFactory());
        this.engine.registerNodeFactory(new SMNodeFactory());
        this.engine.registerNodeFactory(new QRNodeFactory());
        this.engine.registerNodeFactory(new UFNodeFactory());
        this.engine.registerNodeFactory(new EBNodeFactory());
        this.engine.registerNodeFactory(new CNodeFactory());
        this.engine.registerNodeFactory(new FRMNodeFactory());
        this.engine.registerNodeFactory(new WVNodeFactory());
        this.engine.registerNodeFactory(new CDNodeFactory());
        this.engine.registerNodeFactory(new SQRNodeFactory());
        this.engine.registerNodeFactory(new CATNodeFactory());
        this.engine.registerNodeFactory(new PAYNodeFactory());
        this.engine.registerNodeFactory(new LCNodeFactory());
        this.engine.registerNodeFactory(new MMNodeFactory());
        this.engine.registerNodeFactory(new HANodeFactory());
        this.engine.registerNodeFactory(new EANodeFactory());
        this.engine.registerNodeFactory(new APNodeFactory());
        this.engine.registerNodeFactory(new IFNodeFactory());
        this.engine.registerNodeFactory(new GONodeFactory());
        this.engine.registerNodeFactory(new SMSFactory());
        this.engine.registerNodeFactory(new DSNodeFactory());
        this.engine.registerNodeFactory(new EMAILNodeFactory());
        this.engine.registerNodeFactory(new TRIGGERNodeFactory());
        this.engine.registerNodeFactory(new VariableNodeFactory());


    }

    componentWillUnmount = () => this._isMounted = false;

    componentWillMount() {
        isAuthorizationValid();
    }

    /*
     * getBot
     * trae info del bot, initialSchema e initial_step
     * Así como asegurarse que exista un schema, si no lo crea
     */
    getBot = () => {
        let cont = this.state.hideLoad;
        this.setState({ hideLoad: cont + 1 });
        getHandlerGET(HOST_API + '/api/bot/?id=' + this.state.botid)
            .then(async (res) => {
                if (res.status == 200) {
                    try {
                        const { data: { bot: vbot = [] } } = res;

                        if (vbot && vbot.length > 0) {
                            const bot = res.data.bot[0];
                            //guardamos en el state la iformación del bot actual y su version
                            this.setState({
                                botVersionId: bot.bot_version[0].botversion_id,
                                bot: bot,
                                company_id: bot.company_id,
                                ia_activated: bot.ia_activated,
                                platform: bot.platform
                            }, () => this.botPlatforms());

                            this.props.setSelectedBot(bot.bot_version[0].botversion_id,bot, bot.company_id,bot.ia_activated,bot.platform,this.state.idSchema);
                            
                            this.setInitialSchema();
                            this.setupChat(bot.id, bot.bot_version[0].botversion_id);
                            //debugger;
                            
                            //Actualiza la lista de Intents
                            this.props.setListIntent();

                            //obtenemos el schema actual del bot 
                            getHandlerGET(HOST_API + '/api/scheme/?id=' + this.state.idSchema)
                                .then((res1) => {
                                
                                    if (res1.status == 200) {
                                        if (res1.data) {
                                            //validamos si tenemos un scheme guardado en base de datos
                                            
                                            if (res1.data.scheme.content.length == 0) {
                                                this.setupFlow() // si no hay schema guardado configuramos nuestro flujo

                                                if (this._isMounted)
                                                    this.setState({ hideLoad: cont - 1 });

                                            } else {
                                                // si existe, agregamos el initial step al state
                                                if (this._isMounted)
                                                    this.setState({
                                                        initial_step: res1.data.scheme.initial_step
                                                    });
                                                    this.props.setSelectedScheme(res1.data.scheme);

                                                    //Actualiza la lista de variables
                                                    this.props.setVarList();
                                                    
                                                    //agregamos el schema al flujo
                                                    this.setupFlow(res1.data.scheme.content)

                                                    if (this._isMounted)
                                                       this.setState({ hideLoad: cont - 1 });
                                            }
                                            //Actualiza la lista de variables
                                            this.props.setVarList();
                                        }
                                    }
                                }).catch((error) => {
                                    this.setState({ hideLoad: cont - 1 });
                                  
                                    if (error.response && error.response.status && error.response.status == 403)
                                        cerrarSesion();
                                });

                        }

                    } catch (error) {
                        console.log('Error aqui: ', error.message);

                    }
                }
            }).catch((error) => {
                this.setState({ hideLoad: cont - 1 });
          
                if (error.response.status == 502) {
                    this.props.showError(i18next.t('info_error_reload_flow'),2000);
                    location.reload();
                }
                if (error.response && error.response.status && error.response.status == 403)
                    cerrarSesion();

            });
    }

    botPlatforms = () => {
        getHandlerGET(HOST_API + '/api/platforms/by/?id=' + this.state.botid)
        .then((res) => {
            if (res.status == 200) {
                this.setState({ plataformas: res.data });
            }
        }).catch((error) => {
            
            if (error.response.status == 403)
                cerrarSesion();
        });
    }
        /*
         * setInitialSchema
         * Busca el schema inicial del bot
         */
    setInitialSchema = () => {
        getHandlerGET(HOST_API + '/api/scheme/botversion/?botv=' + this.state.botVersionId)
        .then((res) => {
            if (res.status == 200) {
                const init_schema = res.data.initial_scheme || '';
                const anonimo_scheme = res.data.anonimo_scheme || '';
                this.setState({ init_schema, anonimo_scheme });
            }
        }).catch((error) => {
            console.log('catch setInitialSchema', error);
            if (error.response.status == 403)
                cerrarSesion();
        });
    }

    /* 
     * Funcion que actua como switch para cerrar lo modales de cada operador sin guardar 
     * @m = string (alias del operador) 
     */

    closeModal = m => {
        let modals = this.state.modals
        switch (m) {
            case 'm1':
                modals.m1.isOpen = false
                break;
            case 'm2':
                modals.m2.isOpen = false
                break;
            case 'm3':
                modals.m3.isOpen = false
                break;
            case 'm4':
                modals.m4.isOpen = false
                break;
            case 'm5':
                modals.m5.isOpen = false
                break;
            case 'm6':
                modals.m6.isOpen = false
                break;
            case 'm7':
                modals.m7.isOpen = false
                break;
            case 'm8':
                modals.m8.isOpen = false
                break;
            case 'm9':
                modals.m9.isOpen = false
                break;
            case 'm10':
                modals.m10.isOpen = false
                break;
            case 'm11':
                modals.m11.isOpen = false
                break;
            case 'm12':
                modals.m12.isOpen = false
                break;
            case 'm13':
                modals.m13.isOpen = false
                break;
            case 'm14':
                modals.m14.isOpen = false
                break;
            case 'm15':
                modals.m15.isOpen = false
                break;
            case 'm16':
                modals.m16.isOpen = false
            case 'm17':
                modals.m17.isOpen = false
                break;
            case 'm18':
                modals.m18.isOpen = false
                break;
            case 'm_cd':
                modals.m_cd.isOpen = false
                break;
            case 'm_scanqr':
                modals.m_scanqr.isOpen = false
                break;  
            case 'm_catalog':
                modals.m_catalog.isOpen = false
                break;   
            case 'm_payment':
                modals.m_payment.isOpen = false
                break;    
            case 'm_location':
                modals.m_location.isOpen = false
                break;
            case 'm_multimedia':
                modals.m_multimedia.isOpen = false
                break;
            case 'm_if':
                modals.m_if.isOpen = false
                break;
            case 'mContact':
                modals.mContact.isOpen = false
                break;
            case 'm_creporte':
                modals.m_creport.isOpen = false
                break;
            case 'm_blockchain':
                modals.m_blockchain.isOpen = false
                break;
            default:
                break;
        }
        this.setState({ modals })
    }

    /* 
     * Función para guardar el schema del dashboard en base de datos 
     */
    updateSchema = () => {
        //obtenemos el modelo actual con el que el motor está trabajando 
        const model = this.engine.getDiagramModel();

        model.setGridSize(2);
        
        // de el obtenermos el string de json del modelo desiaralizandolo 
        var schema = JSON.stringify(model.serializeDiagram());
        //data para enviar en el servicio web 
        const data = {
            id: this.state.idSchema,
            initial_step: this.state.initial_step,
            content: schema
        };

        //obtenemos el token de autenticación 
        var auth = JSON.parse(localStorage.getItem('Authorization'));
        var headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + auth.access_token
        }

        return axios({
            method: 'put',
            url: HOST_API + '/api/scheme/',
            data: qs.stringify(data),
            headers: headers
        })
    }

    /* 
     * Función para desplegar la modal de edición de un modulo o operador 
     * Esta función recibe los siguientes parametros: 
     * @module_name = string 
     * @nodeId = string 
     * Esta función no retorna nada 
     */

    toggleModuleModal = async  (module_name, nodeId = null) =>  {
        /*
         * establecemos la variable node con un valor por
         * defecto ya que un null puede causar un error o excepción  
         */
        

        let node = {
            extras: {
                text_blocks: {},
                answer_blocks: {},
                buttons: {},
                cards: []
            }
        };
        await this.props.iconLoad();
        this.setState({ hideLoad: 1 });

        await this.updateNodeIAIds()
            .then(async () => {  
                if (nodeId != null) {

                    //si no es nulo buscamos ese node y lo almacenamos en la variable node declarada anteriormente
                    node = this.engine.getDiagramModel().getNode(nodeId);

                    //Selecciona el operador y la configuración del operador
                    await this.props.setSelectedOperator(node.extras.dbId);

                    if(node.extras.head)
                        await this.props.setHead(node.extras.head);
                    else
                        await this.props.setHead('');

                    if(node.extras.config){
                        //Carga el enunciado
                        await this.props.setBotmsgText(node.extras.config.botmsg_text);

                        //Carga el avatar de la caja
                        await this.props.setAvatarid(node.extras.config.avatarid)

                        //Carga las variables del operador
                        if(!node.extras.variables)
                            node.extras.variables = []
                        
                        await this.props.setVariablesBoxOperator(node.extras.variables);
                        
                    }
                    //marcamos como deseleccionado ese nodo ya que si no lo hacemos 
                    //cuando se pulse la tecla borrar ese nodo es eliminado del dashboard
                    node.selected = false
                    await this.props.setNode(node);

                    await this.props.changeActiveTab('1');
                    
                    await this.forceUpdate();
                }
                switch (module_name) {
                    case 'text_module':
                        this.setState(async (state) => {
                            state.modals.m1 = {
                                isOpen: !this.state.modals.m1.isOpen,
                                node: node
                            }
                            return state;
                        })
                        break;
                    case 'quickreplies':
                        this.setState(async (state) => {
                            state.modals.m2 = {
                                isOpen: !this.state.modals.m2.isOpen,
                                node: node
                            }

                        if(node.extras.answer_blocks && node.extras.answer_blocks != {})
                            await this.props.addTextBlock(node.extras.answer_blocks);
                        
                            return state;
                        })
                        break;
                    case 'upload_file':
                        this.setState((state) => {
                            state.modals.m3 = {
                                isOpen: !this.state.modals.m3.isOpen,
                                node: node
                            }
                            return state;
                        })
                        break;
                    case 'elements_button':
                        this.setState(async (state) => {
                            state.modals.m4 = {
                                isOpen: !this.state.modals.m4.isOpen,
                                node: node
                            }
                            return state;
                        })
                        break;
                    case 'elements_generic':
                        this.setState(async (state) => {
                            state.modals.m5 = {
                                isOpen: !this.state.modals.m5.isOpen,
                                node: node
                            }
                            
                            //Carga las variables del operador
                            await this.props.addTextBlock(node.extras.cards);

                            await this.props.toggleModalViewBoxCarousel();
                            return state;
                        })
                        break;
                    case 'form':
                        this.setState(async (state) => {
                            state.modals.m6 = {
                                isOpen: !this.state.modals.m6.isOpen,
                                node: node
                            }
                            //Carga las variables del operador
                            await this.props.addTextBlock(node.extras.text_blocks);
                            
                            this.props.toggleModalViewBoxForm();
                            return state;
                        })
                        break;
                    case 'webview':
                        // Se recolecta la lista de enunciados y lista de webforms cuando se abre la modal.
                        if (!this.state.modals.m7.isOpen) {
                            this.getWebformList();
                        }
                        this.setState((state) => {
                            state.modals.m7 = {
                                isOpen: !this.state.modals.m7.isOpen,
                                node: node
                            }
                            return state;
                        })
                        break;
                    case 'calendar':
                        // Se recolecta la lista de enunciados y lista de tipos de calendario cuando se abre la modal.
                        this.setState((state) => {
                            state.modals.m_cd = {
                                isOpen: !this.state.modals.m_cd.isOpen,
                                node: node
                            }
                            //Carga el enunciado
                            this.props.toggleModalViewBoxCalendar();
                            return state;
                        })
                        break;
                    case 'scanqr':
                        this.setState((state) => {
                            state.modals.m_scanqr = {
                                isOpen: !this.state.modals.m_scanqr.isOpen,
                                node: node
                            }
                            this.props.toggleModalViewBoxScanQR();
                            return state;
                        })
                        break;
                    case 'catalog':
                        this.setState((state) => {
                            state.modals.m_catalog = {
                                isOpen: !this.state.modals.m_catalog.isOpen,
                                node: node
                            }
                        this.props.toggleModalViewBoxCatalog();
                            return state;
                        })
                        break;
                    case 'payment':
                        this.setState((state) => {
                            state.modals.m_payment = {
                                isOpen: !this.state.modals.m_payment.isOpen,
                                node: node
                            }
                             this.props.toggleModalViewBoxPayment();
                            return state;
                        })
                        break;
                    case 'location':
                        this.setState((state) => {
                            state.modals.m_location = {
                                isOpen: !this.state.modals.m_location.isOpen,
                                node: node
                            }
                        //Carga el tipo de localizacion seleccionado
                        this.props.setSelectedTypeLocation(node.extras.config.item_type);

                        //Si el tipo de localizacion seleccionado es closed-location carga la lista seleccionada
                        if(node.extras.config.item_type=='closest_location'){
                            this.props.setSelectedListLocation(node.extras.config.file);
                            //Carga las variables del operador
                            //this.props.setDinamicVariablesBoxOperator(node.extras.dinamicVariables);
                            
                        }

                            //Carga las variables del operador
                            this.props.setDinamicVariablesBoxOperator(node.extras.dinamicVariables);
                        
                        //Carga las variables del operador
                        //   this.props.setVariablesBoxOperator(node.extras.variables);

                        //Abre el modal
                        this.props.toggleModalViewBoxLocation();
                        
                            return state;
                        })
                        break;
                    case 'multimedia':

                        this.setState((state) => {
                            state.modals.m_multimedia = {
                                isOpen: !this.state.modals.m_multimedia.isOpen,
                                node: node
                            }
                        
                        //Carga la forma en que se cargara la url del archivo seleccionado (desde variable, desde url, desde archivo)
                        this.props.setSelectedTypeMultimedia(node.extras.config.item_type);

                        

                        if(node.extras.config.item_type=='url_multimedia'||node.extras.config.item_type=='local_multimedia')
                        {
                            this.props.setUrlMultimediaPreview(node.extras.config.url);
                            this.props.setContentType(node.extras.config.content_type);
                        }
                        else if(node.extras.config.item_type=='template_multimedia')
                        {
                            this.props.imageTemplate(node.extras.config.idtemplateSelected);
                            this.props.resetTempVariablesTemplate(node.extras.variablesTemplate);
                        }
                        else if(node.extras.config.item_type=='variable_multimedia')
                        {
                        //  this.props.setUrlMultimediaPreview(configBoxOperator.url);
                        //  this.props.setContentType(configBoxOperator.content_type);
                        }

                /*if(item_type=='variable_multimedia')
                {
                    props.setUrlMultimediaPreview('none');
                    props.setContentType('');
                }
                else if(item_type=='url_multimedia')
                {
                    props.setUrlMultimediaPreview(configBoxOperator.url);
                    props.setContentType(configBoxOperator.content_type);
                }
                else if(item_type=='local_multimedia')
                {
                    props.setUrlMultimediaPreview('none');
                    props.setContentType(configBoxOperator.content_type);
                }*/

                        /*
                            

                            //Si el tipo de localizacion seleccionado es closed-location carga la lista seleccionada
                            if(node.extras.config.item_type=='closest_location'){
                                this.props.setSelectedListLocation(node.extras.config.file);
                                //Carga las variables del operador
                                //this.props.setDinamicVariablesBoxOperator(node.extras.dinamicVariables);
                                
                            }

                             //Carga las variables del operador
                             this.props.setDinamicVariablesBoxOperator(node.extras.dinamicVariables);
                           */

                            //Carga las variables del operador
                            //this.props.setVariablesBoxOperator(node.extras.variables);

                            
                            //Abre el modal
                            this.props.toggleModalViewBoxMultimedia();
                           
                                return state;
                            })
                            break;
                    case 'creport':
                        this.setState(async (state) => {
                            state.modals.m_creport = {
                                isOpen: !this.state.modals.m_creport.isOpen,
                                node: node
                            }
                            if(node.extras.text_blocks && node.extras.text_blocks != {})
                                await this.props.addTextBlock(node.extras.text_blocks);

                            this.props.toggleModalViewBoxCReport();
                                return state;
                            })
                        break;
                    case 'blockchain':
                        // if (!this.state.modals.m_blockchain.isOpen) {
                        //     //this.getStatementList();
                        // }
                        this.setState((state) => {
                            state.modals.m_blockchain = {
                                isOpen: !this.state.modals.m_blockchain.isOpen,
                                node: node
                        }

                        const {selectedContractBlockchain, selectedProviderBlockchain} = this.props.boxReducer;

                        if(node.extras.config && node.extras.config.provider)
                        {
                            this.props.setSelectedProvider(node.extras.config.provider);
                        }
                        else if(selectedContractBlockchain&&selectedContractBlockchain.id)
                        {
                            this.props.setChangeConfig('provider',selectedProviderBlockchain.id);
                            this.props.setSelectedProvider(selectedProviderBlockchain.id);
                            this.props.setChangeConfig('contract',selectedContractBlockchain.id);
                        }

                        if(node.extras.text_blocks && node.extras.text_blocks != {})
                            this.props.addTextBlock(node.extras.text_blocks);
                        

                        this.props.toggleModalViewBoxBlockchain();
                            return state;
                        })
                        break;
                    case 'agent_pass':
                        this.setState((state) => {
                            state.modals.m8 = {
                                isOpen: !this.state.modals.m8.isOpen,
                                node: node
                            }
                             //Abre el modal
                             this.props.toggleModalViewBoxAgent();

                            return state;
                        })
                        break;
                    case 'api':
                        this.setState((state) => {
                            state.modals.m9 = {
                                isOpen: !this.state.modals.m9.isOpen,
                                node: node
                            }
                            return state;
                        });
                        break;
                    case 'external_api_begin':
                        break;
                    case 'conditional':
                         this.setState(async (state) => {
                            state.modals.m_if = {
                                isOpen: !this.state.modals.m_if.isOpen,
                                node: node
                            }
                            return state;
                        })
                        break;
                    case 'go_to':
                        this.setState((state) => {
                            state.modals.m12 = {
                                isOpen: !this.state.modals.m12.isOpen,
                                node: node
                            }
                            return state;
                        })
                        break;
                    case 'sms':
                        this.setState((state) => {
                            state.modals.m13 = {
                                isOpen: !this.state.modals.m13.isOpen,
                                node: node
                            }
                            return state;
                        })
                        break;
                    case 'img':
                        // if (!this.state.modals.m14.isOpen)
                        //     //this.getStatementList();
                        this.setState((state) => {
                            state.modals.m14 = {
                                isOpen: !this.state.modals.m14.isOpen,
                                node: node
                            }
                            return state;
                        })
                        break;
                    case 'dsus':
                        this.setState((state) => {
                            state.modals.m15 = {
                                isOpen: !this.state.modals.m15.isOpen,
                                node: node
                            }

                            this.props.toggleModalViewBoxDesuscription();
                            return state;
                        })
                        break;
                    case 'email':
                        this.setState((state) => {
                            state.modals.m16 = {
                                isOpen: !this.state.modals.m16.isOpen,
                                node: node
                            }

                            if(!node.extras.config){
                                this.props.setConfig(node.extras.variables);
                            }

                            if(node.extras.variables)
                                this.props.resetTempVariablesTemplate(node.extras.variables);

                            this.props.toggleModalViewBoxEmail();
                            return state;
                        })
                        break;
                    case 'trigger':
                        this.setState((state) => {
                            state.modals.m17 = {
                                isOpen: !this.state.modals.m17.isOpen,
                                node: node
                            }
                            return state;
                        })
                        break;
                    case 'variable':
                        this.setState(async (state) => {
                            state.modals.m18 = {
                                isOpen: !this.state.modals.m18.isOpen,
                                node: node
                            }
                            return state;
                        })
                        break;
                    default:
                        console.log('modulo no identificado para editar en la función toggle')
                        break;
                }
                this.setState({ hideLoad: 0 });
            }).catch((error) => {
                console.log('catch updateNodeIAIds', error)
                if (error.response && error.response.status == 403)
                    cerrarSesion();
            });
        
        await this.props.iconHideLoad();
    }

    /* 
     * Funcion para establecer cual de los modulos es el inicial 
     * esta funcion recibe los siguientes parametros: 
     * @nodeId = string 
     */

    changeHome = (nodeId) => {
        //guardamos en el estate cual es nodeId principal o inicial 
        this.setState({
            initial_step: nodeId
        }, () => {
            //cuando el cambio se ejecute guardamos el schema en base de datos 
            this.updateSchema()
                .then((res) => {
                    console.log('update changeHome')
                }).catch((error) => {
                    console.log('catch changeHome', error);
                    if (error.response.status == 403)
                        cerrarSesion();
                });
        })

        //en este paso obtenemos todos los nodos para establecer las clases que estos deben tener y solo el nodo 
        //que haga match con el id del nodo principal que el usuario marco tendra una clase llamada 'ishome'  
        //que hace que tenga un colo de sombra especial para resaltarlo de los demas e indicar que es el principal 
        let nodes = this.engine.getDiagramModel().getNodes();

        //la variable nodes me devuelve un objeto y para poderlo iterar es necesario traer las keys de ese objeto 
        let knodes = Object.keys(nodes);

        //Si es formulario quitar el First_query
        if(nodeId.startsWith('First_query'))
            nodeId = nodeId.substring(nodeId.indexOf('y') + 2, nodeId.length);
        //iteramos las keys del objeto node 
        knodes.forEach(name => {
            //si la key hace match le agregamos la clase ishome 
            if (name == nodeId) {
                nodes[name].extras.home_element = 'set-home ishome'
            } else {
                //si no simplemente la clase 'set-home' para que mantenga los estilos 
                nodes[name].extras.home_element = 'set-home'
            }
            //esta función es propia de react y lo que hace es refrescar la vista con los cambios aplicados 
            this.forceUpdate();
        });

    }

    /* 
     * Función para crear operador dinámico 
     * type: { text, carousel} 
     * @data = objeto { title: '', type: "text", botid:'',operator_id:''} 
     * @nodeId: id del operador creado en el flujo 
     * esta función no retorna nada 
     */
    AddOperatorDynamic = (data, nodeId) => {
        getHandlerPOST(HOST_API + '/api/operator/dynamic_operator/', data)
            .then((res) => {
                if (res.status == 200) {
                    //actualizamos operatorDynamic en los extras del nodo 
                    let node = this.engine.getDiagramModel().getNode(nodeId);
                    let extras = _.clone(node.extras);
                    extras.operatorDynamic = res.data.operator_id;
                    node.extras = extras;
                    this.forceUpdate();
                } else {
                    console.log("Error status !=200", res);
                }
            }).catch((error) => {
                console.log('catch AddOperatorDynamic', error);
                if (error.response.status == 403) {
                    this.cerrarSesion();
                }
            });
    }

    /* 
     * Función para guardar un modulo en base de datos y crear operador dinámico 
     * @nodeId = string 
     * @type = int {8: modulo de texto plano, 2: quick replies} 
     * esta funcion no retorna nada 
     * Operador dinámico disponible {type:8, type:3} 
     */
    saveModuleDB = async (nodeId, type, isClone = false) => {
        //variable data que guarda el cuerpo de la petición 
        let data = {
            scheme_id: this.state.idSchema,
            operatorinternal_id: nodeId
        };
        if (type != 0)
            data.operatortype_id = type;
        await getHandlerPOST(HOST_API + '/api/scheme/operator/', data)
            .then((res) => {
                if (res.status == 200) {
                    //Identifico type y creo operador dinámico
                    let data = {};
                    let type_value='';
                    
                    switch (type) {
                        case 8:
                        case 3:
                        case 21:
                        case 22:
                            if(type==8)
                                type_value = 'text'
                            else if (type==3)
                                type_value = 'carousel'
                            else if (type==21)
                                type_value = 'custom_report'
                            else if (type==22)
                                type_value = 'blockchain'
                            //operador dínamico texto plano
                            data = {
                                title: i18next.t('default_title'),
                                type: type_value,
                                botid: this.state.botid,
                                operator_id: res.data.operator.id
                            }
                            this.AddOperatorDynamic(data, nodeId);
                            break;
                        default:
                            break;
                    }


                    //busco la caja o el nodo que acabe de crear y le asigno el identificador de base de datos
                    let node = this.engine.getDiagramModel().getNode(nodeId);
                    let extras = _.clone(node.extras);
                    extras.dbId = res.data.operator.id;
                    node.extras = extras;

                    //SI la caja tiene parámentros de configuración
                    if(node.extras.config)
                    {
                        this.props.updateConfigOperator(res.data.operator.id,JSON.stringify(node.extras.config));

                    }

                    const {variablesBoxOperator} = this.props.boxReducer;
                    let varNew = {}
                     varNew = variablesBoxOperator;

                    //SI el resultado de la caja se usará como variable (Caja sin statements)
                    if(node.extras.is_var)
                    {
                        if(type==13){
                            node.extras.var_name = node.extras.variables['var_main'].var_name;
                        }
                        this.saveStatement(node.extras.dbId,node.extras.title,node.extras.is_var)
                        .then((result) => {
                            if (result.status== 200){
                                node.extras.botmsg_id = result.data.statement.id;
                                node.extras.statements_id[result.data.statement.id] = node.extras.var_name;
                                node.extras.variables['var_main'].statement_id = result.data.statement.id;
                                
                                varNew['var_main'].statement_id = result.data.statement.id;

                                this.updateStatement(result.data.statement.id,'','var_main',node.extras.is_var,node.extras.var_name)
                                this.props.setVariablesBoxOperator(varNew);
                  
                            }
                        }).catch((error)=>{
                            console.log('catch saveStatement: ',error);
                            if (error.response && error.response.status && error.response.status == 403){
                                cerrarSesion();
                            }
                        });
                    }

                     //SI el resultado de la caja se usará como variable en un API y definirá un formato especifico JSON  para interpretar(Caja sin statements)
                     if(type==19)
                     {
                         
                         this.saveStatement(node.extras.dbId,node.extras.title,true)
                         .then((result) => {
                             if (result.status== 200){
                                 node.extras.statements_id[result.data.statement.id] = node.extras.var_name_json;
                                 this.updateStatement(result.data.statement.id,node.extras.title,'var_json',node.extras.is_var,node.extras.var_name_json)
            
                             }
                         }).catch((error)=>{
                             console.log('catch saveStatement: ',error);
                             if (error.response && error.response.status && error.response.status == 403){
                                 cerrarSesion();
                             }
                         });
                     }

                    // this.forceUpdate();
                    // this.updateSchema()
                    //     .then((res) => {
                    if (isClone)
                        this.endClone(node);
                        // }).catch((error) => {
                        //     console.log('catch updateSchema', error);
                        //     if (error.response.status == 403) {
                        //         this.cerrarSesion();
                        //     }
                        // });
                }
            }).catch((error) => {
                console.log('catch saveModuleDB', error);
                if (error.response.status == 403)
                    this.cerrarSesion();
            });
    }

    saveStatement = (operator, text,is_var) => {
        //OJO REVISAR SI TODO SIGUE FUNCIONANDO BIEN!! SI NO VOLVER A TEXT=TEXT Y NAME=TEXT
        const data = {
            operator_id: operator,
            text:  (is_var) ? text : '',
            name:  (is_var) ? text : ''
        };

        var auth = JSON.parse(localStorage.getItem('Authorization'));
        var headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + auth.access_token
        }

        return axios({
            method: 'post',
            url: HOST_API + '/api/scheme/statement/',
            data: qs.stringify(data),
            headers: headers
        });
    }
    updateStatement = (statement, text, name,is_var, var_name) => {
        const data = {
            id: statement,
            text: text,
            name: name,
            is_var:is_var,
            var_name
        };
        var auth=JSON.parse(localStorage.getItem('Authorization'));
        var headers={
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer '+ auth.access_token 
        }

        return axios({
            method:'put',
            url:HOST_API +'/api/scheme/statement/',
            data:qs.stringify(data),
            headers:headers
        });
    }

    saveConfig = (operator_id,config) => {
        const data = {
            id: operator_id,
            config:config
        };

        var auth=JSON.parse(localStorage.getItem('Authorization'));
        var headers={
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer '+ auth.access_token 
        }

        return axios({
            method:'put',
            url:HOST_API +'/api/scheme/operator/',
            data:qs.stringify(data),
            headers:headers
        })

    }
    setupNodeAndSave = async (node, type) => {

        await this.props.iconLoad();

        let cnodes = this.engine.getDiagramModel().getSelectedItems();
        if (cnodes.length <= 0)
            cnodes = this.engine.getDiagramModel().getNodes();
        let new_x = 75;
        let new_y = 75;
        const kcnodes = Object.keys(cnodes);
        let nl = kcnodes.length;
        if (nl > 0) {
            let inode = kcnodes[nl - 1];
            let old_node = cnodes[inode];
            const pos_x = old_node.x;
            const pos_y = old_node.y;
            new_x = pos_x + new_x;
            new_y = pos_y + new_y;
        }

        node.setPosition(new_x, new_y);
        this.engine.getDiagramModel().addNode(node);
        this.forceUpdate();
        
        await this.updateSchema()
            .then((res) => {
                
            }).catch((error) => {
                console.log('catch setupNodeAndSave', error);
                if (error.response.status == 403)
                    cerrarSesion();
            });
        //Limpia la lista de operadores
        await this.props.setListAllOperators([]);

        //guardamos el modulo en base de datos 
        await this.saveModuleDB(node.id, type);
       
        //Trae la lista actualizada de operadores
        await this.updateNodeIAIds();

        await this.props.iconHideLoad();
    }

    cloneService(op_id, iid) {
        //data para enviar en el servicio web 
        const data = {
            internal_id: iid
        };

        var auth = JSON.parse(localStorage.getItem('Authorization'));
        var headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + auth.access_token
        }

        return axios({
            method: 'post',
            url: `${HOST_API}/api/scheme/operator/${op_id}/clone`,
            data: qs.stringify(data),
            headers: headers
        })
    }

    cloneObject = (obj) => {
        if (obj === null || typeof obj !== 'object')
            return obj;
        var temp = obj.constructor();
        for (var key in obj) {
            temp[key] = this.cloneObject(obj[key]);
        }
        return temp;
    }

    doClone = (nodeId) => {
        const node = this.engine.getDiagramModel().getNode(nodeId);
        let item = node.clone({});
        item.setPosition(item.x + 100, item.y + 100);
        this.engine.getDiagramModel().addNode(item);
        this.cloneService(node.extras.dbId, item.id)
            .then((res) => {
                let n_extras = this.cloneObject(item.extras);
                n_extras.dbId = res.data.operator.id;
                let text_blocks = {};
                let old_text_blocks = {};
                let k_o_t_b = [];
                switch (item.type) {
                    case 'sm':
                        res.data.operator.statements.forEach((element) => {
                            text_blocks[element.id] = { text: element.text, name: element.name }
                        })
                        n_extras.text_blocks = text_blocks;
                        break;
                    case 'wv':
                        if (res.data.operator.statements.length > 0) {
                            n_extras.botmsg_id = res.data.operator.statements[0].id;
                            n_extras.botmsg_text = res.data.operator.statements[0].text;
                            n_extras.botmsg_name = res.data.operator.statements[0].name;
                        }
                        break;
                    case 'cd':
                    case 'scanqr':
                    case 'catalog':
                    case 'payment':
                    case 'location':
                    case 'multimedia':
                        if (res.data.operator.statements.length > 0) {
                            n_extras.botmsg_id = res.data.operator.statements[0].id;
                            n_extras.botmsg_text = res.data.operator.statements[0].text;
                            n_extras.botmsg_name = res.data.operator.statements[0].name;
                            n_extras.config = res.data.operator.statements[0].config;
                        }
                        break;
                    case 'frm':
                        old_text_blocks = node.extras.text_blocks;
                        k_o_t_b = Object.keys(old_text_blocks);
                        res.data.operator.statements.forEach((element, k) => {
                            text_blocks[element.id] = { text: element.text, name: element.name, validation: old_text_blocks[k_o_t_b[k]].validation }
                        })

                        break;
                    case 'qr':
                        res.data.operator.statements.forEach((element) => {
                            if (element.text == node.extras.head) {
                                n_extras.headid = element.id;
                                n_extras.head = element.text;
                                n_extras.head_name = element.name;
                            } else {
                                text_blocks[element.id] = { text: element.text, name: element.name }
                            }
                        })
                        n_extras.answer_blocks = text_blocks;
                        n_extras.config = res.data.operator.statements[0].config;
                        break;
                    case 'uf':
                        if (res.data.operator.statements.length > 0) {
                            n_extras.botmsg_id = res.data.operator.statements[0].id;
                            n_extras.botmsg_text = res.data.operator.statements[0].text;
                            n_extras.botmsg_name = res.data.operator.statements[0].name;
                            n_extras.isVariable = res.data.operator.statements[0].isVariable;
                            n_extras.name_variable = res.data.operator.statements[0].name_variable;
                        }
                        break;
                    case 'eb':
                        old_text_blocks = node.extras.buttons;
                        k_o_t_b = Object.keys(old_text_blocks);
                        res.data.operator.statements.forEach((element) => {
                            if (element.text == node.extras.head) {
                                n_extras.headid = element.id;
                                n_extras.head = element.text;
                                n_extras.head_name = element.name;
                            } else {
                                k_o_t_b.forEach((k) => {
                                    if (element.text == old_text_blocks[k].title) {
                                        text_blocks[element.id] = {
                                            title: element.text,
                                            type: old_text_blocks[k].type,
                                            url: old_text_blocks[k].url
                                        }
                                    }
                                })
                            }
                        })
                        n_extras.buttons = text_blocks;
                        break;
                    case 'cn':
                        let cards = n_extras.cards;
                        let sts_used = [];

                        cards.forEach((card) => {
                            res.data.operator.statements.forEach((element) => {
                                if (element.text == card.title && sts_used.indexOf(element.id) < 0) {
                                    sts_used.push(element.id);
                                    card.titleid = element.id;
                                }
                            });

                            let btns = card.buttons;
                            let n_btns = {};
                            let k_btns = Object.keys(btns);
                            k_btns.forEach((k_btn) => {
                                res.data.operator.statements.forEach((element) => {
                                    const btn = btns[k_btn];
                                    if (element.text == btn.title && sts_used.indexOf(element.id) < 0) {
                                        sts_used.push(element.id);
                                        n_btns[element.id] = { title: btn.title, type: btn.type, url: btn.url };
                                    }
                                });
                            })
                            card.buttons = n_btns;
                        });
                        res.data.operator.statements.forEach((element) => {
                            if (element.text == node.extras.head) {
                                n_extras.headid = element.id;
                                n_extras.head = element.text;
                                n_extras.head_name = element.name;
                            }
                        })
                        break;
                }
                item.extras = n_extras;
                const model = this.engine.getDiagramModel();
                const schema = JSON.stringify(model.serializeDiagram());
                const dataSchema = {
                    id: this.state.idSchema,
                    initial_step: this.state.initial_step,
                    content: schema
                };
                getHandlerPUT(HOST_API + '/api/scheme/', dataSchema)
                    .then((res) => {
                        console.log('do clone con éxito.')
                    }).catch((error) => {
                        console.log('catch doClone')
                        if (error.response.status == 403)
                            cerrarSesion();
                    });
                this.forceUpdate();
            }).catch((error) => {
                console.log('catch cloneService')
                if (error.response.status == 403)
                    cerrarSesion();
            });
    }

    endClone = (node) => {
        if (node.extras.text_blocks) {
            const text_blocks = node.extras.text_blocks;
            const ktext_blocks = Object.keys(text_blocks);
            ktext_blocks.forEach((k) => {
                this.saveStatement(node.extras.dbId, text_blocks[k].text).then((res) => {
                    if (res.status == 200) {
                        text_blocks[res.data.statement.id] = text_blocks[k].text;
                        delete text_blocks[k];
                        this.forceUpdate();
                        const model = this.engine.getDiagramModel();
                        const schema = JSON.stringify(model.serializeDiagram());
                        const dataSchema = {
                            id: this.state.idSchema,
                            initial_step: this.state.initial_step,
                            content: schema
                        };

                

                        getHandlerPUT(HOST_API + '/api/scheme/', dataSchema)
                            .then((res) => {
                                console.log('endClone éxito.')
                            }).catch((error) => {
                                console.log('catch endClone')
                                if (error.response.status == 403)
                                    cerrarSesion();
                            });
                    }
                });
            });
        }
    }

    /* 
     * Función para dibujar en el dashboard un modulo o node 
     * Si es un módulo dínamico, se crea es BD  
     * @module_name = string 
     */
    drawModule = (module_name, oldNodeId = "") => {
            //por defecto el node es nulo y es de tipo 0
            var node = null;
            let type = 0;
            switch (module_name) {
                //modulo de texto, módulo dínamico
               case 'sms':
                    //el node es de tipo SMSModel (Simple Model Node)
                    node = new SMSModel();
                    //indicamos las coordenadas en que queremos dibujar el nodo
                    node.setPosition(200, 200);
                    //establecemos los extras por defecto
                    node.extras = Object.assign(
                            drawModalSimple, {
                                title: "SMS",
                                text_blocks: {},
                                celular: '',
                                mensaje: '',
                                time: '0',
                                config: '0', //opcion de inmediato o programado
                                typetime: '',
                                type: 12
                            })
                        //establecemos la funcion que lo eliminara
                    node.removeBox = this.removeNode
                        //establecemos la funcion que permitira seleccionarlo como principal
                    node.changeHome = this.changeHome
                        //función que permitira editarlo                
                    node.toggleEdit = this.toggleModuleModal
                    
                    node.customClone = this.doClone
                        //tipo de componente en Base de datos
                    type = 12;
                    break;

                case 'text_module':
                    //el node es de tipo SMNodeModel (Simple Model Node)
                    node = new SMNodeModel();
                    //indicamos las coordenadas en que queremos dibujar el nodo
                    node.setPosition(200, 200);
                    //establecemos los extras por defecto
                    node.extras = Object.assign(
                            drawModalComplete, {
                                title: i18next.t('plane_text').toUpperCase(),
                                text_blocks: {},
                                operatorDynamic: '',
                                visible: '',
                                type: 8,
                                avatar: 0,
                                audio: '',
                                error_limit_item:false,
                                error_min_items:true,
                                error_vacio:true

                            })
                        //establecemos la funcion que lo eliminara
                    node.removeBox = this.removeNode
                        //establecemos la funcion que permitira seleccionarlo como principal
                    node.changeHome = this.changeHome
                        //función que permitira editarlo                
                    node.toggleEdit = this.toggleModuleModal
                    node.customClone = this.doClone
                        //tipo de componente en Base de datos
                    type = 8;
                    break;

                case 'quickreplies':
                    //el node es de tipo QRNodeModel (Quick Replies Node Model)
                    node = new QRNodeModel();
                    //indicamos las coordenadas en que queremos dibujar el nodo
                    node.setPosition(200, 200);
                    //establecemos los extras por defecto
                    node.extras = Object.assign(
                            drawModalComplete, {
                                title: i18next.t('quick_replies').toUpperCase(),
                                text: i18next.t('edit_component_info2'),
                                answer_blocks: {},
                                headid: "",
                                type: 2,
                                is_var: false,
                                var_name: '',
                                statements_id: {},
                                audio: '',
                                config:{
                                    //Valores por defecto caja creada 
                                    interactive_message:"True",
                                    
                                   }
                            })
                        //establecemos la funcion que lo eliminara
                    node.removeBox = this.removeNode
                        //establecemos la funcion que permitira seleccionarlo como principal
                    node.changeHome = this.changeHome
                        //función que permitira editarlo                
                    node.toggleEdit = this.toggleModuleModal,
                        //tipo de componente en Base de datos
                        node.customClone = this.doClone
                    type = 2;
                    break;

                    //modulo de cargar archivo
                case 'upload_file':
                    //el node es de tipo SMNodeModel (Simple Model Node)
                    node = new UFNodeModel();
                    //indicamos las coordenadas en que queremos dibujar el nodo
                    node.setPosition(200, 200);
                    //establecemos los extras por defecto
                    node.extras = Object.assign(
                            drawModalSimple, {
                                title: i18next.t('upload_file').toUpperCase(),
                                text: i18next.t('edit_component_info3'),
                                botmsg_id: "",
                                isVariable:false,
                                name_variable: "",
                                botmsg_text: "",
                                botmsg_name: "",
                                cancelmsg: "",
                                home_element: 'set-home',
                                keyword: "",
                                msg_timeout: "",
                                timeout: 0,
                                type: 6
                            })
                        //establecemos la funcion que lo eliminara
                    node.removeBox = this.removeNode;
                    //establecemos la funcion que permitira seleccionarlo como principal
                    node.changeHome = this.changeHome;
                    //función que permitira editarlo                
                    node.toggleEdit = this.toggleModuleModal;
                    //tipo de componente en Base de datos
                    node.customClone = this.doClone
                    type = 6;
                    break;

                case 'elements_button':
                    //el node es de tipo EBNodeModel (Quick Replies Node Model)
                    node = new EBNodeModel();
                    //indicamos las coordenadas en que queremos dibujar el nodo
                    node.setPosition(200, 200);
                    //establecemos los extras por defecto
                    node.extras = Object.assign(
                            drawModalComplete, {
                                title: i18next.t('buttons_list').toUpperCase(),
                                text: i18next.t('edit_component_info2'),
                                buttons: {},
                                headid: "",
                                type: 4
                            })
                        //establecemos la funcion que lo eliminara
                    node.removeBox = this.removeNode
                        //establecemos la funcion que permitira seleccionarlo como principal
                    node.changeHome = this.changeHome
                        //función que permitira editarlo                
                    node.toggleEdit = this.toggleModuleModal,
                        //tipo de componente en Base de datos
                        node.customClone = this.doClone
                    type = 4;
                    break;

                case 'elements_generic':
                    //el node es de tipo EBNodeModel (Quick Replies Node Model)
                    node = new CNodeModel();
                    //indicamos las coordenadas en que queremos dibujar el nodo
                    node.setPosition(200, 200);
                    //establecemos los extras por defecto
                    node.extras = Object.assign(
                            drawModalComplete, {
                                title: i18next.t('carousel').toUpperCase(),
                                text: i18next.t('edit_component_info2'),
                                cards: [],
                                headid: "",
                                visible: '',
                                operatorDynamic: '',
                                type: 3,
                                url: '',
                                config:{type:''}
                            })
                        //establecemos la funcion que lo eliminara
                    node.removeBox = this.removeNode
                        //establecemos la funcion que permitira seleccionarlo como principal
                    node.changeHome = this.changeHome
                        //función que permitira editarlo                
                    node.toggleEdit = this.toggleModuleModal,
                        //tipo de componente en Base de datos
                        node.customClone = this.doClone
                    type = 3;
                    break;

                case 'form':
                    //el node es de tipo SMNodeModel (Simple Model Node)
                    node = new FRMNodeModel();
                    //indicamos las coordenadas en que queremos dibujar el nodo
                    node.setPosition(200, 200);
                    //establecemos los extras por defecto
                    node.extras = Object.assign(
                            drawModalComplete, {
                                title: i18next.t('form').toUpperCase(),
                                text_blocks: {},
                                headid: "",
                                type: 1,
                                audio: ''
                            })
                        //establecemos la funcion que lo eliminara
                    node.removeBox = this.removeNode
                        //establecemos la funcion que permitira seleccionarlo como principal
                    node.changeHome = this.changeHome
                        //función que permitira editarlo                
                    node.toggleEdit = this.toggleModuleModal,
                        //tipo de componente en Base de datos
                        node.customClone = this.doClone
                    type = 1;
                    break;

                    //modulo de vista web
                case 'webview':
                    //el node es de tipo WVNodeModel (Web View Node)
                    node = new WVNodeModel();
                    //indicamos las coordenadas en que queremos dibujar el nodo
                    node.setPosition(200, 200);
                    //establecemos los extras por defecto
                    node.extras = Object.assign(
                        drawModalSimple, {
                            title: i18next.t('web_view').toUpperCase(),
                            botmsg_id: "",
                            botmsg_text: "",
                            botmsg_name: "",
                            webform: "",
                            home_element: 'set-home',
                            keyword: "",
                            titleOperator: '',
                            xCheck:true,
                            text:i18next.t('txt_long_edit_component'),
                            type: 5
                        })

                    //establecemos la funcion que lo eliminara
                    node.removeBox = this.removeNode;
                    //establecemos la funcion que permitira seleccionarlo como principal
                    node.changeHome = this.changeHome;
                    //función que permitira editarlo                
                    node.toggleEdit = this.toggleModuleModal;
                    //tipo de componente en Base de datos
                    node.customClone = this.doClone
                    type = 5;
                    break;
                //modulo de calendario
                case 'calendar':
                    //el node es de tipo CDNodeModel (Calendar Date Node)
                    node = new CDNodeModel();
                    //indicamos las coordenadas en que queremos dibujar el nodo
                    node.setPosition(200, 200);
                    //establecemos los extras por defecto
                    node.extras = Object.assign(
                        drawModalSimple, {
                            title: i18next.t('calendar').toUpperCase(),
                            botmsg_id: "",
                            botmsg_text: "",
                            botmsg_name: "",
                            calendar: "",
                            home_element: 'set-home',
                            keyword: "",
                            titleOperator: '',
                            is_var: true,
                            var_name:'',
                            var_name_json:"",
                            statements_id: {},
                            text: i18next.t('info_calendar'),
                            type: 19,
                            config:{
                                //Valores por defecto caja creada 
                                botmsg_text:"",
                                item_type : 'date_calendar',
                                zone : "America/Bogota",
                                selectedStartDate : null,
                                selectedDefaultOption : 'dateTodayDefault',
                                selectedMaxDate :false,
                                selectedMinDate : false,
                                showTimeSelect : false,
                                startDate : null,
                                endDate : null,
                                timeIntervals : 30,
                                minDate : null,
                                maxDate : null,
                                maxTime : null,
                                minTime : null,
                                excludeDates : null,
                                excludeTimes : null
                                
                            }
                        })

                    //establecemos la funcion que lo eliminara
                    node.removeBox = this.removeNode;
                    //establecemos la funcion que permitira seleccionarlo como principal
                    node.changeHome = this.changeHome;
                    //función que permitira editarlo                
                    node.toggleEdit = this.toggleModuleModal;
                    //tipo de componente en Base de datos
                    node.customClone = this.doClone
                    type = 19;
                    break;
                //modulo de scanqr
                case 'scanqr':
                    //el node es de tipo SQRNodeModel (Scan QR Node)
                    node = new SQRNodeModel();
                    //indicamos las coordenadas en que queremos dibujar el nodo
                    node.setPosition(200, 200);

                    variables_json = {'var_main':{'statement_id':'','var_name':'','title':'Variable escaner qr','description':'Nombre para la variable que almacenará el valor escaneado en el qr','type':'text','format':'text'}}; 
                    
                    //establecemos los extras por defecto
                    node.extras = Object.assign(
                        drawModalSimple, {
                            title: i18next.t('scanner').toUpperCase() + " QR",
                            botmsg_id: "",
                            botmsg_text: "",
                            botmsg_name: "",
                            home_element: 'set-home',
                            keyword: "",
                            titleOperator: '',
                            is_var: true,
                            var_name:'',
                            statements_id: {},
                            text: i18next.t('scanner_qr_info'),
                            type: 23,
                            variables:variables_json,
                            config:{
                                //Valores por defecto caja creada 
                                botmsg_text:"",
                                item_type : 'qrcode',
                               }
                        })

                    //establecemos la funcion que lo eliminara
                    node.removeBox = this.removeNode;
                    //establecemos la funcion que permitira seleccionarlo como principal
                    node.changeHome = this.changeHome;
                    //función que permitira editarlo                
                    node.toggleEdit = this.toggleModuleModal;
                    //tipo de componente en Base de datos
                    node.customClone = this.doClone
                    type = 23;

                    this.props.setVariablesBoxOperator(variables_json);
                    break;
                //modulo de catalogo
                case 'catalog':
                    //el node es de tipo CATNodeModel (Catalog Node)
                    node = new CATNodeModel();
                    //indicamos las coordenadas en que queremos dibujar el nodo
                    node.setPosition(200, 200);

                    variables_json = {'var_main':{'statement_id':'','var_name':'','title':'Variable orden','description':'Nombre para la variable que almacenará la orden del carrito de compras','type':'text','format':'text'},'var_json':{'statement_id':'','var_name':'','title':'Variable referencia de pago','description':'Nombre para la variable que almacenará la referencia de pago del carrito de compras','type':'text','format':'text'}}; 
                    
                    //establecemos los extras por defecto
                    node.extras = Object.assign(
                        drawModalSimple, {
                            title: i18next.t('catalog').toUpperCase(),
                            botmsg_id: "",
                            botmsg_text: "",
                            botmsg_name: "",
                            home_element: 'set-home',
                            keyword: "",
                            titleOperator: '',
                            is_var: true,
                            var_name:'',
                            var_name_json:"",
                            statements_id: {},
                            text: i18next.t('catalog_config_info'),
                            type: 24,
                            variables:variables_json,
                            config:{
                                //Valores por defecto caja creada 
                                botmsg_text:"",
                                item_type : 'smartdata_catalog',
                                item_type_short : 'smartdata',
                                source_type: 'shopify'
                               }
                        })

                    //establecemos la funcion que lo eliminara
                    node.removeBox = this.removeNode;
                    //establecemos la funcion que permitira seleccionarlo como principal
                    node.changeHome = this.changeHome;
                    //función que permitira editarlo                
                    node.toggleEdit = this.toggleModuleModal;
                    //tipo de componente en Base de datos
                    node.customClone = this.doClone
                    type = 24;

                    this.props.setVariablesBoxOperator(variables_json);
                    break;
                 //modulo de pagos
                 case 'payment':
                    //el node es de tipo PAYNodeModel (Payment Node)
                    node = new PAYNodeModel();
                    //indicamos las coordenadas en que queremos dibujar el nodo
                    node.setPosition(200, 200);

                    variables_json = {'var_main':{'statement_id':'','var_name':'','title':'Variable pagos','description':'Nombre para la variable que almacenará el estado de la transacción','type':'text','format':'text'}}; 
                    
                    //establecemos los extras por defecto
                    node.extras = Object.assign(
                        drawModalSimple, {
                            title: i18next.t('payments_online').toUpperCase(),
                            botmsg_id: "",
                            botmsg_text: "",
                            botmsg_name: "",
                            home_element: 'set-home',
                            keyword: "",
                            titleOperator: '',
                            is_var: true,
                            var_name:'',
                            statements_id: {},
                            text: i18next.t('payment_method_info'),
                            type: 25,
                            variables:variables_json,
                            config:{
                                //Valores por defecto caja creada 
                                botmsg_text:"",
                                item_type : 'wompi_payment',
                                currency:'COP',
                                reference_id:'',
                                amount_in_cents:'',
                                id_keys:'',
                                source_type: 'wompi'
                               }
                        })

                    //establecemos la funcion que lo eliminara
                    node.removeBox = this.removeNode;
                    //establecemos la funcion que permitira seleccionarlo como principal
                    node.changeHome = this.changeHome;
                    //función que permitira editarlo                
                    node.toggleEdit = this.toggleModuleModal;
                    //tipo de componente en Base de datos
                    node.customClone = this.doClone
                    type = 25;

                    this.props.setVariablesBoxOperator(variables_json);
                    break;
                case 'location':
                    //el node es de tipo LCNodeModel (Location Coordinates Node)
                    node = new LCNodeModel();
                    //indicamos las coordenadas en que queremos dibujar el nodo
                    node.setPosition(200, 200);
                    //establecemos los extras por defecto longitud_var

                    let variables_json = {'lat':{'statement_id':'','var_name':'','title':i18next.t('latitud_var'),'description':i18next.t('name_info_variable_latitud'),'type':'numeric','format':'decimal'}, 
                    'long':{'statement_id':'','var_name':'','title':i18next.t('longitud_var'),'description':i18next.t('name_info_variable'),'type':'numeric','format':'decimal'}}
                     

                    node.extras = Object.assign(
                        drawModalSimple, {
                            title: i18next.t('location').toUpperCase(),
                            botmsg_id: "",
                            botmsg_text: "",
                            botmsg_name: "",
                            location: "",
                            home_element: 'set-home',
                            keyword: "",
                            titleOperator: '',
                            is_var: false,
                            statements_id: {},
                            text: i18next.t('location_config_info'),
                            type: 20,
                            config:{
                                //Valores por defecto caja creada 
                                item_type : 'capture_location',
                                botmsg_text: ""
                            },
                            variables:variables_json,
                            dinamicVariables:{}

                        })

                    //establecemos la funcion que lo eliminara
                    node.removeBox = this.removeNode;
                    //establecemos la funcion que permitira seleccionarlo como principal
                    node.changeHome = this.changeHome;
                    //función que permitira editarlo                
                    node.toggleEdit = this.toggleModuleModal;
                    //tipo de componente en Base de datos
                    node.customClone = this.doClone
                    type = 20;

                    
                    this.props.setVariablesBoxOperator(variables_json);
                    break;
                case 'multimedia':
                        //el node es de tipo MMNodeModel (Multimedia Node)
                        node = new MMNodeModel();
                        //indicamos las coordenadas en que queremos dibujar el nodo
                        node.setPosition(200, 200);
                        //establecemos los extras por defecto
    
                         variables_json = {'var_main':{'statement_id':'','var_name':'','title':'Variable url archivo','description':'Nombre para la variable que almacenará la url del archivo','type':'text','format':'text'}}; 
                      //  'long':{'statement_id':'','var_name':'','title':'Variable longitud','description':'Nombre para longitud de usuario','type':'numeric','format':'decimal'}}
                         
                    
                        node.extras = Object.assign(
                            drawModalComplete, {
                                title: "MULTIMEDIA",
                                multimedia: "",
                                home_element: 'set-home',
                                statements_id: {},
                                is_var: true,
                                text: i18next.t('multimedia_config_info'),
                                type: 13,
                                config:{
                                    //Valores por defecto caja creada 
                                    item_type : 'url_multimedia',
                                    url: 'none',
                                    url_statement: 'none',
                                    idtemplateSelected: 'none',
                                    multimedia_type: 'none',
                                },
                                variables:variables_json,
                                dinamicVariables:{}
    
                            })
    
                        //establecemos la funcion que lo eliminara
                        node.removeBox = this.removeNode;
                        //establecemos la funcion que permitira seleccionarlo como principal
                        node.changeHome = this.changeHome;
                        //función que permitira editarlo                
                        node.toggleEdit = this.toggleModuleModal;
                        //tipo de componente en Base de datos
                        node.customClone = this.doClone
                        
                        type = 13;
    
                        this.props.setVariablesBoxOperator(variables_json);
                        
                        break;
                //modulo de reporte personalizado 
                case 'custom_report':
                    //el node es de tipo CRNodeModel (Custom Report Node)
                    node = new CRNodeModel();
                    //indicamos las coordenadas en que queremos dibujar el nodo
                    node.setPosition(200, 200);
                    //establecemos los extras por defecto
                    node.extras = Object.assign(
                        drawModalSimple, {
                            title: i18next.t('custom_report').toUpperCase(),
                            botmsg_id:"",
                            botmsg_text:"",
                            botmsg_name:"",
                            text_blocks: {},
                            operatorDynamic: '',
                            visible: '',
                            home_element: 'set-home',
                            width: 200,
                            keyword: "",
                            statements_id: {},
                            titleOperator: '',                
                            text: i18next.t('report_config_info'),
                            type: 21,
                            error_limit_item:false,
                            error_min_items:true,
                            error_vacio:true
                            
                        })

                    //establecemos la funcion que lo eliminara
                    node.removeBox = this.removeNode;
                    //establecemos la funcion que permitira seleccionarlo como principal
                    node.changeHome = this.changeHome;
                    //función que permitira editarlo                
                    node.toggleEdit = this.toggleModuleModal;
                    //tipo de componente en Base de datos
                    node.customClone = this.doClone
                    type = 21;
                    break;
                case 'blockchain':

                    const {selectedContractBlockchain, selectedProviderBlockchain} = this.props.boxReducer;

                    if(!selectedContractBlockchain.id)
                    {
                        this.props.setTitle(i18next.t('service_not_found'));
                        this.props.showWarning(i18next.t('blockchain_config_info') + " <br /><br /><div class='alert alert-danger' role='alert'>"+
                        i18next.t('soporte_info') + " !</div>",6000);
                  
                    }
                    else
                    {
                        //el node es de tipo BNodeModel (Blockchain Node)
                        node = new BNodeModel();
                        //indicamos las coordenadas en que queremos dibujar el nodo
                        node.setPosition(200, 200);
                        //establecemos los extras por defecto
                        node.extras = Object.assign(
                            drawModalSimple, {
                                title: "BLOCKCHAIN",
                                botmsg_id:"",
                                botmsg_text:"",
                                botmsg_name:"",
                                text_blocks: {},
                                operatorDynamic: '',
                                visible: '',
                                home_element: 'set-home',
                                width: 200,
                                keyword: "",
                                statements_id: {},
                                titleOperator: '',                
                                text: i18next.t('blockchain_config_info2'),
                                type: 22,
                                error_limit_item:false,
                                error_min_items:true,
                                error_vacio:true,
                                config:{
                                    contract:'',
                                    provider:''
                                }
                                
                            })

                        //establecemos la funcion que lo eliminara
                        node.removeBox = this.removeNode;
                        //establecemos la funcion que permitira seleccionarlo como principal
                        node.changeHome = this.changeHome;
                        //función que permitira editarlo                
                        node.toggleEdit = this.toggleModuleModal;
                        //tipo de componente en Base de datos
                        node.customClone = this.doClone
                        type = 22;
                    }
                    break;
                case 'external_api_begin':
                    //el node es de tipo SMNodeModel (Simple Model Node)
                    node = new HANodeModel();
                    //establecemos los extras por defecto
                    node.extras = {
                            title: i18next.t('start_api').toUpperCase(),
                            width: 170,
                            //texto que se muestra en la vista previa de la caja 
                            text: i18next.t('edit_component_info'),
                            //clase por defecto para el boton de set as home 
                            home_element: 'set-home',
                            //palabra clave del componente 
                            keyword: "",
                            head: "",
                            evento: ""
                        }
                        //establecemos la funcion que lo eliminara
                    node.removeBox = this.removeNode
                        //establecemos la funcion que permitira seleccionarlo como principal
                    node.changeHome = this.changeHome
                        //tipo de componente en Base de datos
                    node.customClone = this.doClone
                    type = 7;
                    break;

                case 'external_api_end':
                    //el node es de tipo SMNodeModel (Simple Model Node)
                    node = new EANodeModel();
                    //establecemos los extras por defecto
                    node.extras = Object.assign(
                            drawModalSimple, {
                                title: i18next.t('end_api').toUpperCase(),
                                home_element: 'set-home',
                                validate: false,
                                url_api: "",
                                keyword: "",
                                head: '',
                                begin: oldNodeId,
                                type: 7,
                                config:{
                                    send_session_id: true
                                }
                            })
                        //establecemos la funcion que lo eliminara
                    node.removeBox = this.removeNode
                        //establecemos la funcion que permitira seleccionarlo como principal
                    node.changeHome = this.changeHome
                        //función que permitira editarlo                
                    node.toggleEdit = this.toggleModuleModal
                        //tipo de componente en Base de datos
                    node.customClone = this.doClone
                    type = 7;
                    break;

                    //modulo de paso a agente
                case 'agent_pass':
                    node = new APNodeModel();
                    //indicamos las coordenadas en que queremos dibujar el nodo
                    node.setPosition(200, 200);
                    //establecemos los extras por defecto
                    node.extras = Object.assign(
                            drawModalSimple, {
                                title: i18next.t('switch_to_agent').toUpperCase(),
                                home_element: 'set-home',
                                text: i18next.t('switch_to_agent_config_info'),
                                botmsg_text: "",
                                url_agent:"",
                                botmsg_name: "",
                                keyword: "",
                                type: 9,
                                config:{
                                    //Valores por defecto caja creada 
                                    variable_agent : 'none',
                                    botmsg_text: "",
                                    url_agent:"",
                                    var_list: "[]"
                                }
                            })
                        //establecemos la funcion que lo eliminara
                    node.removeBox = this.removeNode;
                    //establecemos la funcion que permitira seleccionarlo como principal
                    node.changeHome = this.changeHome;
                    //función que permitira editarlo                
                    node.toggleEdit = this.toggleModuleModal;
                    //tipo de componente en Base de datos
                    node.customClone = this.doClone
                    type = 9;
                    break;

                    //modulo de cargar archivo
                case 'conditional':
                    //el node es de tipo SMNodeModel (Simple Model Node)
                    node = new IFNodeModel();
                    //indicamos las coordenadas en que queremos dibujar el nodo
                    node.setPosition(200, 200);
                    //establecemos los extras por defecto
                    node.extras = Object.assign(
                            drawModalSimple, {
                                title: i18next.t('conditional').toUpperCase(),
                                home_element: 'set-home',
                                text: i18next.t('conditional_config_info'),
                                conditionals: {},
                                keyword: "",
                                type: 11
                            })
                        //establecemos la funcion que lo eliminara
                    node.removeBox = this.removeNode;
                    //establecemos la funcion que permitira seleccionarlo como principal
                    node.changeHome = this.changeHome;
                    //función que permitira editarlo                
                    node.toggleEdit = this.toggleModuleModal;
                    //tipo de componente en Base de datos
                    node.customClone = this.doClone
                    type = 11;
                    break;
                    //modulo de cargar archivo
                case 'go_to':
                    //el node es de tipo SMNodeModel (Simple Model Node)
                    node = new GONodeModel();
                    //indicamos las coordenadas en que queremos dibujar el nodo
                    node.setPosition(200, 200);
                    //establecemos los extras por defecto
                    node.extras = Object.assign(
                            drawModalSimple, {
                                title: "GO TO",
                                text: i18next.t('conditional_config_info'),
                                home_element: 'set-home',
                                keyword: "",
                                schema: '',
                                operatorGoTo: '',
                                type: 10
                            })
                        //establecemos la funcion que lo eliminara
                    node.removeBox = this.removeNode;
                    //función que permitira editarlo                
                    node.toggleEdit = this.toggleModuleModal;
                    type = 10;
                    break;
                case 'dsus':
                    //el node es de tipo DSNodeModel (Simple Model Node)
                    node = new DSNodeModel();
                    //indicamos las coordenadas en que queremos dibujar el nodo
                    node.setPosition(200, 200);
                    //establecemos los extras por defecto
                    node.extras = Object.assign(
                            drawModalSimple, {
                                title: i18next.t('unsubscribe').toUpperCase(),
                                question: '',
                                text: i18next.t('unsubscribe_config_info'),
                                edited: false,
                                idStatementSayBye: 0,
                                idStatementQuestion: 0,
                                sayBye: '',
                                type: 15

                            })
                        //establecemos la funcion que lo eliminara
                    node.removeBox = this.removeNode
                        //establecemos la funcion que permitira seleccionarlo como principal
                    node.changeHome = this.changeHome
                        //función que permitira editarlo                
                    node.toggleEdit = this.toggleModuleModal
                    node.customClone = this.doClone
                        //tipo de componente en Base de datos
                    type = 15;
                    break;
                case 'email':
                    //el node es de tipo DSNodeModel (Simple Model Node)
                    node = new EMAILNodeModel();
                    //indicamos las coordenadas en que queremos dibujar el nodo
                    node.setPosition(200, 200);
                    //establecemos los extras por defecto                
                    node.extras = Object.assign(
                        drawModalSimple, {
                            title: "EMAIL",
                            type: 16,
                            edited: false,
                            text: i18next.t('email_config_info'),
                            idStatementSubject: 0,
                            subject: '',
                            idtemplateSelected: 0,
                            emailReceiver: '',
                            time: '0',
                            config: '0', //opcion de inmediato o programado
                            typetime: '',
                            emailSender: '',
                            emailsCCO: '',
                            variables: [{
                                key: '',
                                value: ''
                            }],

                        })

                    //establecemos la funcion que lo eliminara
                    node.removeBox = this.removeNode
                        //establecemos la funcion que permitira seleccionarlo como principal
                    node.changeHome = this.changeHome
                        //función que permitira editarlo                
                    node.toggleEdit = this.toggleModuleModal
                    node.customClone = this.doClone
                        //tipo de componente en Base de datos
                    type = 16;
                    break;
                case 'trigger':
                    //el node es de tipo DSNodeModel (Simple Model Node)
                    node = new TRIGGERNodeModel();
                    //indicamos las coordenadas en que queremos dibujar el nodo
                    node.setPosition(200, 200);
                    //establecemos los extras por defecto                
                    node.extras = Object.assign(
                        drawModalSimple, {
                            title: "TRIGGER",
                            type: 17,
                            text: i18next.t('trigger_config_info'),

                            listLinks: [],
                            listEvents: []

                        })

                    //establecemos la funcion que lo eliminara
                    node.removeBox = this.removeNode
                        //establecemos la funcion que permitira seleccionarlo como principal
                    node.changeHome = this.changeHome
                        //función que permitira editarlo                
                    node.toggleEdit = this.toggleModuleModal
                    node.customClone = this.doClone
                        //tipo de componente en Base de datos
                    type = 17;
                    break;
                case 'variable':
                    //el node es de tipo DSNodeModel (Simple Model Node)
                    node = new VariableNodeModel();
                    //indicamos las coordenadas en que queremos dibujar el nodo
                    node.setPosition(200, 200);
                    //establecemos los extras por defecto                
                    node.extras = Object.assign(
                        drawModalSimple, {
                            title: "VARIABLE",
                            type: 16,
                            edited: false,
                            text: i18next.t('variable_config_info'),
                            variables: [],
                        })

                    //establecemos la funcion que lo eliminara
                    node.removeBox = this.removeNode
                        //establecemos la funcion que permitira seleccionarlo como principal
                    node.changeHome = this.changeHome
                        //función que permitira editarlo                
                    node.toggleEdit = this.toggleModuleModal
                    node.customClone = this.doClone
                        //tipo de componente en Base de datos
                    type = 18;
                    break;

                default:
                    break;
            }

            //validamos que el node no sea nulo     
            if (node != null) {
                node.extras.type = type;
                node.toggleEventModal = this.toggleEventModal
                this.setupNodeAndSave(node, type);
                if (module_name == 'external_api_begin') {
                    this.drawModule('external_api_end', node.id);
                }
            }
        }
        /* 
         * Función para eliminar un modulo de la base de datos 
         * @operator = int (es el id de la caja en base de datos) 
         */
    removeModuleDB = (operator) => {
        //data para enviar en el servicio web 
        const data = {
            id: operator,
        };
        //obtenemos el token de autenticación 
        var auth = JSON.parse(localStorage.getItem('Authorization'));
        var headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + auth.access_token
        }

        return axios({
            method: 'delete',
            url: HOST_API + '/api/scheme/operator/',
            data: qs.stringify(data),
            headers: headers
        })
    }

    removeStatement = (statement) => {
        const data = {
            id: statement,
        };

        var auth = JSON.parse(localStorage.getItem('Authorization'));
        var headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + auth.access_token
        }

        return axios({
            method: 'delete',
            url: HOST_API + '/api/scheme/statement/',
            data: qs.stringify(data),
            headers: headers
        })
    }

    /* 
     * Función para eliminar un modulo del dashboard 
     * @nodeId = string 
     */
    removeNode = (nodeId) => {
        if (confirm(i18next.t('msg_confirm_delete'))) {
            //obtengo el node a eliminar y elimino primero el operador dinamico, si tiene
            let node = this.engine.getDiagramModel().getNode(nodeId);
            
            if (node.extras.operatorDynamic)
                this.props.deleteOperator(node.extras.operatorDynamic);
        
            this.props.removeModuleDB(node.extras.dbId);

            //obtenermos los links que estén conectados a ese nodo y los eliminamos
            let ports = node.ports;
            let kports = Object.keys(ports);
            kports.forEach((kport) => {
                const port = ports[kport];
                const links = port.getLinks();
                const klinks = Object.keys(links);
                klinks.forEach(klink => {
                    const link = links[klink];
                    link.remove();
                });
                node.removePort(port);
            });

            //eliminamos el node del dashboard
            this.engine.getDiagramModel().removeNode(nodeId);
           
            
            //guardamos el schema en base de datos
            this.updateSchema()
                .then((res) => {
                    console.log('schema guardado con éxito despues de removeNode')
                     //Actualiza la lista de variables
                     this.props.setVarList();
                     //Muestra mensaje de eliminación correcta del nodo
                    this.props.showSuccess(i18next.t('msg_delete_nodo'),2000);

                    this.forceUpdate();
                }).catch((error) => {
                    console.log('catch updateSchema removeNode', error);
                    if (error.response.status == 403)
                        this.cerrarSesion();
                });
        }
    }

    /* 
     * 
     * Función para configurar el schema (modelo) en el motor de flujos 
     * @schema = string or null 
     *  
     */
    setupFlow(schema = null) {
        //creo el modelo actual con el que voy a trabajar    
       
        this.model = new DiagramModel();
        //this.model = new SRD.DiagramModel(); 
        //this.model.setOffset(100, 100);
        //this.model.setZoomLevel(15);
        
        //this.model.setGridSize(200); 
        //valido si he obtenido un schema anteriormente y que fue enviado por la funcion 
        if (schema != null) {
            //lo registramos en nuestro modelo 
            this.model.deSerializeDiagram(JSON.parse(schema), this.engine);
            this.updateNodeIAIds();
        } else {
            this.updateSchema();
        }


        //agregamos el modelo o schema al motor de flujos 
        this.engine.setDiagramModel(this.model);

        //actualizamos la vista 
        this.forceUpdate();

        //obtenemos los nodes del schema y le asignamos las funciones que este puede hacer 
        let nodes = this.engine.getDiagramModel().getNodes();

        let knodes = Object.keys(nodes);
        knodes.forEach(name => {
            nodes[name].removeBox = this.removeNode
            nodes[name].changeHome = this.changeHome
            nodes[name].toggleEdit = this.toggleModuleModal
            nodes[name].customClone = this.doClone
            nodes[name].toggleEventModal = this.toggleEventModal
            this.forceUpdate();
        });
    }

    /* 
     * 
     * Función que enviar la data del flujo al servicio web para genera el script 
     * @json_obj = str (string con la información del flujo ya lista para ser procesada por el servicio web) 
     *  
     */
    generateBot = (json_obj) => {
        //data para enviar en el servicio web   
        const data = {
            botversion_id: this.state.botVersionId,
            idSchema: this.state.idSchema,
            json_content: json_obj
        };

        //obtenemos el token de autenticación 
        var auth = JSON.parse(localStorage.getItem('Authorization'));
        var headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + auth.access_token
        }

        return axios({
            method: 'post',
            url: HOST_API + '/api/bot/generate',
            data: qs.stringify(data),
            headers: headers
        })
    }

    /* 
     * Función para guardar el bot y generar el script
     * para usarlo en un cliente de chat 
     */
    saveBotAndGenerate = async () => {
        this.props.loading();

        let cont = this.state.hideLoad;
        this.setState({ hideLoad: 1 });
       
        //se actualiza el esquema.
        await this.updateSchema()
            .then((res) => {
                this.setState({ hideLoad: 1 });
                console.log('updateSchema saveBotAndGenerate éxitoso')
            }).catch((error) => {
                console.log('catch updateSchema saveBotAndGenerate', error)
                if (error.response && error.response.status == 403)
                    this.cerrarSesion();
            });

        //funcion para convertir un array a objetos 
        this.setState({ hideLoad: cont + 1 });

        function toObject(arr) {
            var rv = {};
            for (var i = 0; i < arr.length; ++i)
                rv[i] = arr[i];
            return rv;
        }
        let tha = 0,
            tea = 0;
        let botLink = {},
            botLinkFirst = {},
            action = {},
            botPbs = [],
            botQrs = [],
            botFls = [],
            botUfls = [],
            actions = [],
            botExt_init = [],
            botExt_end = [],
            botTxtModule = [],
            botOthers = [],
            botAI = [],
            botPasoAgnt = [],
            botTimeout = [],
            botConditional = [],
            botGoTo = [],
            botSMS = [],
            botIMG = [],
            botDSUS = [],
            botEMAIL = [],
            botCALENDAR = [],
            botSCANQR = [],
            botCATALOG = [],
            botPAYMENT = [],
            botMULTIMEDIA = [],
            botVIEWWEB = [],
            botTRIGGER = [],
            botREPORT = [],
            botBLOCKCHAIN = [],
            botVars = [];

        //Obtenemos los links del flujo 
        var links = this.model.getLinks();
        //como los links son un objeto y necesitamos iterarlo obtenemos las keys del objecto links y las iteramos 
        var klinks = Object.keys(links);
        //al igual que los links obtenemos los nodos y obtenemos las keys del objeto y los iteramos 
        var nodes = this.model.getNodes();
        var knodes = Object.keys(nodes);


        // comprobacion de que existe un Home. 
        var home_exist = false;
        let bandera = 1;
        let validacion = 0;
        knodes.forEach((k) => {
            if (nodes[k].extras.home_element && nodes[k].extras.home_element.includes("ishome")) {
                home_exist = true;
            }
            //validacion de opeador segun plataformas
            let plataformas = this.state.plataformas;
            if (validacion != 1) {
                if (plataformas.length > 0) {
                    plataformas.forEach(element => {
                        if (validacion != 1) {
                            switch (element) {
                                case 1:
                                    //RCS
                                    if (nodes[k].extras.title == "CARRUSEL") {
                                        this.props.showError(i18next.t('message_scheme'),2000);
                                        bandera = 0
                                        validacion = 1
                                    }
                                    else if (nodes[k].extras.title == "LISTA DE BOTONES") {
                                        this.props.showError(i18next.t('message_scheme2'),2000);
                     
                                        bandera = 0
                                        validacion = 1
                                    }
                                    else if (nodes[k].extras.title == "IMAGEN") {
                                        this.props.showError(i18next.t('message_scheme3'),2000);
                                        bandera = 0
                                        validacion = 1
                                    }
                                    else if (nodes[k].extras.title == "MULTIMEDIA") {
                                        this.props.showError(i18next.t('message_scheme4'),2000);
                                        bandera = 0
                                        validacion = 1
                                    }
                                    break;
                                case 2:
                                    //Facebook

                                    break;
                                case 3:
                                    //Web

                                    break;
                                case 4:
                                    //Whatsapp
                                    if (nodes[k].extras.title == "CARRUSEL") {
                                        this.props.showError(i18next.t('message_scheme'),2000);
                                        bandera = 0
                                        validacion = 1
                                    }
                                    break;
                                case 5:
                                    //Voz
                                    if (nodes[k].extras.title == "CARRUSEL") {
                                        this.props.showError(i18next.t('message_scheme'),2000);
                                        bandera = 0
                                        validacion = 1
                                    }
                                    else if (nodes[k].extras.title == "LISTA DE BOTONES") {
                                        this.props.showError(i18next.t('message_scheme2'),2000);
                     
                                        bandera = 0
                                        validacion = 1
                                    }
                                    else if (nodes[k].extras.title == "IMAGEN") {
                                        this.props.showError(i18next.t('message_scheme3'),2000);
                                        bandera = 0
                                        validacion = 1
                                    }
                                    else if (nodes[k].extras.title == "MULTIMEDIA") {
                                        this.props.showError(i18next.t('message_scheme4'),2000);
                                        bandera = 0
                                        validacion = 1
                                    }
                                    break;
                                case 6:
                                    //SMS
                                    if (nodes[k].extras.title == "CARRUSEL") {
                                        this.props.showError(i18next.t('message_scheme'),2000);
                                        bandera = 0
                                        validacion = 1
                                    }
                                    else if (nodes[k].extras.title == "LISTA DE BOTONES") {
                                        this.props.showError(i18next.t('message_scheme2'),2000);
                     
                                        bandera = 0
                                        validacion = 1
                                    }
                                    else if (nodes[k].extras.title == "IMAGEN") {
                                        this.props.showError(i18next.t('message_scheme3'),2000);
                                        bandera = 0
                                        validacion = 1
                                    }
                                    else if (nodes[k].extras.title == "MULTIMEDIA") {
                                        this.props.showError(i18next.t('message_scheme4'),2000);
                                        bandera = 0
                                        validacion = 1
                                    }
                                    else if (nodes[k].extras.title == "DESUSCRIPCIÓN") {
                                        this.props.showError(i18next.t('message_scheme5'),2000);
                                        bandera = 0
                                        validacion = 1
                                    }
                                    break;

                                default:
                                    break;
                            }
                        }
                    });
                }
            } else {
                this.setState({ hideLoad: cont - 1 });
            }

        })
        bandera = 1;
        
       

        if (bandera == 1) {
            if (this.state.idSchema == this.state.init_schema)
                if (!home_exist) 
                    this.props.showWarning(i18next.t('message_scheme6'),4000);
                  
            klinks.forEach((k) => {
                const link = links[k];
                botLink = {}
                if (!link.targetPort) {
                    return;
                }

                //obtenemos el target del link 
                const target = link.targetPort.parent
                    //obtenemos el sorce del link 
                const source = link.sourcePort.parent

                /*if(source.type=='frm')
                {
                    if(source.id.indexOf('First_query') < 0 && source.id.indexOf('paso-') < 0)
                        source.id = 'First_query_'+source.id
                }*/
                if(target.type == 'frm')
                {
                    if(target.id.startsWith('First_query') == false && target.id.startsWith('paso-') == false)
                        target.id = 'First_query_'+target.id
                }

                if (link.sourcePort.name == "right-timeout") {

                    if(source.id.startsWith('First_query'))
                        source.id = source.id.substring(source.id.indexOf('y') + 2, source.id.length);
                    
                    botLink.from = source.id;
                    botLink.to = source.id + "-timeout"; //target.id; 
                    botTimeout.push(botLink);

                    //creamos el action virtual para el timeout 
                    let action = {
                        data: {},
                        event: ''
                    };

                    action.id = source.id + "-timeout";
                    //el tipo de node 
                    action.type = 'timeout_module'
                        //guardo los ids en base de datos de los enunciados del modulo 
                    action.data.text = source.extras.msg_timeout
                    action.data.next_action = target.id
                    actions.push(action)
                    return;
                }

                switch (source.type) {
                    //guardamos el los ids de los nodos
                    case 'email':
                        botLink.from = source.id
                        botLink.to = target.id
                        botEMAIL.push(botLink)
                        break;
                    case 'trigger':
                        botLink.from = source.id
                        botLink.to = target.id
                        botTRIGGER.push(botLink)
                        break;
                    case 'dsus':
                        botLink.from = source.id + "-stay"
                        botLink.to = target.id
                        botDSUS.push(botLink)

                        botLink = {}
                        botLink.from = source.id
                        botLink.to = target.id
                        botDSUS.push(botLink)
                        break;
                    case 'img':
                        botLink.from = source.id
                        botLink.to = target.id
                        botIMG.push(botLink)
                        break;
                    case 'sm':
                        botLink.from = source.id
                        botLink.to = target.id
                        botTxtModule.push(botLink)
                        break;
                    case 'sms':
                        botLink.from = source.id
                        botLink.to = target.id
                        botSMS.push(botLink)
                        break;

                    case 'variable':
                        botLink.from = source.id
                        botLink.to = target.id
                        botVars.push(botLink)
                        break;

                    case 'qr':
                        botLink.from = link.sourcePort.name
                        botLink.to = target.id
                        botQrs.push(botLink)
                        break;
                    case 'uf':
                        //upload file
                        if (link.sourcePort.position == 'right-load')
                            botLink.from = 'File_Uploaded'
                        else
                            botLink.from = 'Upload_aborted'
                        botLink.to = target.id
                        botLink.this_from = source.id
                        botUfls.push(botLink)
                        break;
                    case 'eb':
                        //element button
                        botLink.from = link.sourcePort.name
                        botLink.to = target.id
                        botPbs.push(botLink)
                        break;
                    case 'cn':
                        //carrusel
                        if (link.sourcePort.name == "right-rss") {
                            botLink.from = `${link.sourcePort.name}-${source.id}`;
                        } else {
                            botLink.from = link.sourcePort.name;
                        }
                        botLink.to = target.id;
                        botQrs.push(botLink);

                        break;
                    case 'ap':
                        botLink.from = source.id
                        botLink.to = target.id
                        botPasoAgnt.push(botLink)
                        break;
                    case 'frm':
                        const preguntas = source.extras.text_blocks
                        const kpreguntas = Object.keys(preguntas);
                        kpreguntas.forEach((kpregunta, index) => {
                            const pregunta = preguntas[kpregunta];
                            botLink = {}
                            botLinkFirst = {}
                           
                            if(source.id.startsWith('First_query'))
                                source.id = source.id.substring(source.id.indexOf('y') + 2, source.id.length);
                                
                            if (index == 0 ){
                                botLink.from = 'First_query_'+source.id 
                                botLinkFirst.from =  source.id 
                                botLinkFirst.to =  'First_query_'+source.id
                                
                                const existe = find(propEq("from", source.id ))(actions);
 
                            }
                            else
                                botLink.from = 'paso-' + index + "[]" + source.id

                            if (index == (kpreguntas.length - 1)) {
                                botLink.to = target.id
                                botLink.validacion = pregunta.validation
                                botLink.return_data = true
                            } else {
                                botLink.to = 'paso-' + (index + 1) + "[]" + source.id
                                botLink.validacion = pregunta.validation
                                botLink.return_data = true
                            }

                            botLink.this_from = source.id
                            botFls.push(botLink)

                          
                        })
                        break;

                    case 'ea':
                        botLink.from = source.id
                        botLink.to = target.id
                        botExt_end.push(botLink)
                        break;
                    case 'ha':
                        botLink.from = source.id
                        botLink.to = target.id
                        botExt_init.push(botLink)
                        break;
                    case 'if':
                        botLink.from = `${source.id}-${link.sourcePort.position}`
                        botLink.to = target.id
                        botConditional.push(botLink)
                        break;
                    case 'go':
                        botLink.from = source.id
                        botLink.to = target.id
                        botGoTo.push(botLink)
                        break;
                     case 'multimedia':
                         botLink.from = source.id
                         botLink.to = target.id
                         botMULTIMEDIA.push(botLink)
                         break;
                    case 'creport':
                        botLink.from = source.id
                        botLink.to = target.id
                        botREPORT.push(botLink)
                        break; 
                    case 'scanqr':
                        botLink.from = source.id
                        botLink.to = target.id
                        botSCANQR.push(botLink)
                        break; 
                    case 'catalog':
                        if (link.sourcePort.position == 'right-load')
                            botLink.from = 'Catalog_OK'
                        else
                            botLink.from = 'Catalog_canceled'
                        botLink.to = target.id
                        botLink.this_from = source.id
                        botCATALOG.push(botLink)

                        //botLink.from = source.id
                    
                        break; 
                    case 'payment':
                        botLink.from = source.id
                        botLink.to = target.id
                        botPAYMENT.push(botLink)
                        break; 
                    case 'blockchain':
                        botLink.from = source.id
                        botLink.to = target.id
                        botBLOCKCHAIN.push(botLink)
                        break;    
                    default:
                        botLink.from = source.id
                        botLink.to = target.id
                        botOthers.push(botLink)
                        break;
                }
            });

            knodes.forEach((k) => {
                //obtenemos el node actual 
                const node = nodes[k];
                if (node.extras.ia_id && node.extras.ia_id!='none') {
                    if(node.type == 'frm' && k.startsWith('First_query') == false)
                    {
                        botAI.push({
                            from: node.extras.ia_id,
                            to: 'First_query_'+ k
                        })
                    }else{
                        botAI.push({
                            from: node.extras.ia_id,
                            to: k
                        })
                    }
                    
                }
                //establecemos el action por defecto ya que un valor nulo nos puede generar error 
                action = {
                        data: {},
                        event: ''
                    }
                    //validamos el tipo de node 
                let blocks = {};
                let kblocks = [];
                let enunciados = [];

                if ("timeout" in node.extras)
                    action.data.timeout = node.extras.timeout;
                
                if ('avatar' in node.extras)
                    action.data.avatar = node.extras.avatar;

                if ('keyword' in node.extras)
                    action.data.pClave = node.extras.keyword;

                if ('titleOperator' in node.extras)
                    action.data.titleOperator = node.extras.titleOperator;

                if ('config' in node.extras)
                    action.data.config =  node.extras.config;
                
                if ('dbId' in node.extras)
                    action.data.operator_id = node.extras.dbId;

                //el action id es el id del node 
                action.id = k
                action.event = ""
                action.bot_id = this.state.BotId;
                action.operator_id = node.extras.dbId;
                if (node.extras.event_id && node.extras.event_id.toString().length > 0) {
                    action.event = node.extras.event_id;
                }
                //ARMA EL json_content del BACKEND
                switch (node.type) {
                    case 'sm':
                        action.type = 'text_module'
                            //obtengo los bloques de texto o enunciados

                        const model = {
                            text_blocks: {
                                success: () => {
                                    blocks = node.extras.text_blocks;
                                    kblocks = Object.keys(blocks);
                                    action.data.statements = kblocks;
                                    enunciados = kblocks.map((kb) => {
                                        if (kb in blocks) {
                                            return blocks[kb].text
                                        }
                                    });
                                    //guardo el texto como tal de los enunciados del modulo
                                    action.data.enunciados = enunciados;
                                },
                                error: (key) => {
                                    action.data.enunciados = [];
                                    action.data.statements = [];
                                    console.log(`The key: ${key} isn't present on node.extras`)
                                }
                            },
                            audio: {
                                success: () => {
                                    action.data.audio = node.extras.audio;
                                },
                                error: () => {
                                    action.data.audio = "";
                                }
                            }
                        };

                        validKeysObjectsWithActions(node.extras, model);

                      
                        if (isNaN(parseInt(node.extras.visible)))
                            action.data.operatorDynamic = "0";
                        else
                            action.data.operatorDynamic = node.extras.operatorDynamic;

                        
                        break;
                    case 'img':
                        action.type = 'img'
                        action.url = node.extras.url;
                        break;

                    case 'sms':
                        action.type = 'sms'
                        action.data.celphone = node.extras.celular;
                        action.data.message = node.extras.mensaje;
                        action.data.time = node.extras.time;
                        action.data.typetime = node.extras.typetime;
                        break;
                    case 'variable':
                        action.type = 'variable'
                        // TODO: verificar que funcione el cambio de {} a []
                        action.data.variables = node.extras.variables;
                        break;

                    case 'email':
                        action.type = 'email'
                        action.data.edited = node.extras.edited;
                        action.data.idStatementSubject = node.extras.idStatementSubject;
                        action.data.subject = node.extras.subject;
                        action.data.idtemplateSelected = node.extras.idtemplateSelected;
                        action.data.emailSender = node.extras.emailSender;
                        action.data.emailReceiver = node.extras.emailReceiver;
                        action.data.emailsCCO = node.extras.emailsCCO;
                        action.data.variables = node.extras.variables;
                        action.data.time = node.extras.time;
                        action.data.typetime = node.extras.typetime;

                        break;
                    case 'trigger':
                        action.type = 'trigger'
                        action.data.listEvents = node.extras.listEvents;
                        action.data.listLinks = node.extras.listLinks;

                        break;
                    case 'dsus':
                        action.type = 'dsus';
                        action.data.question = node.extras.question;
                        action.data.idStatementQuestion = node.extras.idStatementQuestion;
                        action.data.edited = node.extras.edited;
                        actions.push(action);

                        action = {
                            data: {},
                            event: ''
                        }
                        action.id = k + "-bye"
                        action.type = 'dsus-bye';
                        action.data.idStatementSayBye = node.extras.idStatementSayBye;
                        action.data.sayBye = node.extras.sayBye;
                        action.data.edited = node.extras.edited;
                        actions.push(action);

                        action = {
                            data: {},
                            event: ''
                        }
                        action.id = k + "-stay"
                        action.type = 'dsus-stay'
                        break;
                    case 'qr':
                        action.type = 'quickreplies'
                        blocks = node.extras.answer_blocks;
                        kblocks = Object.keys(blocks);
                        action.data.statements = node.extras.headid
                        action.data.isEnunciadoAudio = node.extras.isEnunciadoAudio
                        action.data.audio = ""
                        if ('audio' in node.extras) {
                            action.data.audio = node.extras.audio;
                        }
                        //guardo el texto como tal de los enunciados del modulo
                        enunciados = kblocks.map((kb) => {
                            if (kb in blocks) {
                                const name =  blocks[kb].statement_id?blocks[kb].statement_id:kb;
                                return { Payload: "right-" + name, Title: blocks[kb].text }
                            }
                        });
                        action.data.list = enunciados

                        action.data.message = (!node.extras.isEnunciadoAudio) ? node.extras.head : '';
                        
                        break;

                    case 'uf':
                        action.type = 'upload_file';
                        action.data.statements = node.extras.botmsg_id
                        action.data.isVariable = node.extras.isVariable;
                        action.data.name_variable = node.extras.name_variable;
                        action.data.message = node.extras.botmsg_text;
                        action.data.abort = node.extras.cancelmsg;
                        action.data.list = "";
                        action.data.extensions = node.extras.extensions;
                        action.data.msgError = node.extras.msgError;

                        botLink = {};
                        botLink.to = node.id;
                        botLink.from = "Upload_error";
                        botLink.this_from = node.id;
                        botUfls.push(botLink)
                        break;

                    case 'if':
                        action.type = 'conditional';
                        let cs = node.extras.conditionals;
                        let k_cs = Object.keys(cs);
                        let list_c = k_cs.map((kc) => {
                            return cs[kc];
                        });
                        let a = [];
                        a.push(list_c);
                        action.data.list = a;
                        break;

                    case 'eb':
                        action.type = 'elements_button'
                        blocks = node.extras.buttons;
                        kblocks = Object.keys(blocks);
                        action.data.statements = node.extras.headid
                        action.data.message = node.extras.head
                        enunciados = kblocks.map((kb) => {
                            let obj = { Title: blocks[kb].title, Type: blocks[kb].type }
                            if (blocks[kb].type != "web_url" && blocks[kb].type != "reload_url")
                                obj["Payload"] = "right-" + kb;
                            else
                                obj["Url"] = blocks[kb].url;

                            return obj;
                        });
                        action.data.list = enunciados;
                        break;

                    case 'cn':
                        action.type = 'elements_generic'
                        action.data.statements = node.extras.headid
                        action.data.message = node.extras.head
                        action.data.url = node.extras.url
                       
                        const cards = node.extras.cards;
                        enunciados = cards.map((card) => {
                            const buttons = card.buttons;
                            const kbuttons = Object.keys(buttons);
                            const btns = kbuttons.map((kbtn) => {
                                if (kbtn in buttons) {
                                    return {
                                        Payload: "right-" + buttons[kbtn].statement_id,
                                        Title: buttons[kbtn].title,
                                        Type: buttons[kbtn].type,
                                        Url: buttons[kbtn].url
                                    };
                                }
                            });
                            if(node.extras.config.type=='var')
                                return { Buttons: btns, type: 'var', var: node.extras.config.var };
                            else 
                                return { Buttons: btns, Image_url: card.url, Subtitle: card.subtitle, Title: card.title };
                        
                        });
                       
                        if(node.extras.config.type=='var')
                        { 
                            action.data.config =  enunciados[0];
                            action.data.list = [];
                        }
                        else
                            action.data.list = enunciados;

                        if (isNaN(parseInt(node.extras.visible)))
                            action.data.operatorDynamic = 0;
                        else
                            action.data.operatorDynamic = node.extras.operatorDynamic;

                        break;

                    case 'frm':
                        const preguntas = node.extras.text_blocks;
                        const kpreguntas = Object.keys(preguntas);
                        action.type = 'pregunta';
                        kpreguntas.forEach((kpregunta, index) => {
                            const pregunta = preguntas[kpregunta];
                            action = {
                                data: {},
                                event: ''
                            }
                            action.type = 'pregunta';
                            if (index == 0)
                            {
                                if(k.startsWith('First_query'))
                                {
                                    action.id = k;
                                }
                                else
                                    action.id = 'First_query_' + k
                            }
                            else
                            {
                                if(k.startsWith('First_query'))
                                {
                                    action.id = 'paso-' + index + "[]" + k.substring(k.indexOf('y') + 2, k.length);
                                }
                                else
                                    action.id = 'paso-' + index + "[]" + k
                            }
                                

                            action.data.audio = (pregunta.type == 1) ? pregunta.text : ''
                            action.data.statements = preguntas[kpregunta].statement_id?preguntas[kpregunta].statement_id:kpregunta;//kpregunta
                            action.data.message = pregunta.text
                            action.data.list = ''
                            if ("timeout" in node.extras)
                                action.data.timeout = node.extras.timeout;
                            actions.push(action)
                            action = null
                        })
                        break;
                    case 'wv':
                        action.type = 'webview';
                        action.data.statements = node.extras.botmsg_id
                        action.data.message = node.extras.botmsg_text;
                        action.data.message_html = node.extras.botmsg_name;
                        action.data.list = node.extras.webform;
                        action.data.xCheck = node.extras.xCheck;

                        break;
                    case 'creport':
                    case 'blockchain':
                        if(node.type=='creport')
                            action.type = 'custom_report'; //AQUI ARREGLAR
                        else
                            action.type = 'blockchain';
                       
                        action.data.message = node.extras.botmsg_text;
                        action.data.message_html = node.extras.botmsg_name;
                       

                        let model_report = {
                            text_blocks: {
                                success: () => {
                                    blocks = node.extras.text_blocks;
                                    kblocks = Object.keys(blocks);
                                    if(Object.keys(node.extras.statements_id)[0] != 0){
                                        const st = Object.keys(node.extras.statements_id);
                                        action.data.statements = st;
                                    }
                                    else
                                        action.data.statements = kblocks;
                                    
                                    enunciados = kblocks.map((kb) => {
                                        if (kb in blocks) {
                                            return blocks[kb].text
                                        }
                                    });
                                    //guardo el texto como tal de los enunciados del modulo
                                    action.data.enunciados = enunciados;
                                },
                                error: (key) => {
                                    action.data.enunciados = [];
                                    action.data.statements = [];
                                    console.log(`The key: ${key} isn't present on node.extras`)
                                }
                            }
                        };

                        validKeysObjectsWithActions(node.extras, model_report);

                        if (isNaN(parseInt(node.extras.visible)))
                            action.data.operatorDynamic = "0";
                        else
                            action.data.operatorDynamic = node.extras.operatorDynamic;

                        break;
                    
                    case 'cd':
                        action.type = 'calendar'; //AQUI ARREGLAR
                        blocks = node.extras.statements_id;
                        kblocks = Object.keys(blocks);
                        action.data.statements = kblocks;//noode.extras.botmsg_id;//
                        action.data.message = node.extras.botmsg_text;
                        action.data.message_html = node.extras.botmsg_name;
                        action.data.list = node.extras.calendarType;
                        break;
                    case 'scanqr':
                        action.type = 'qrcode'; //AQUI ARREGLAR
                        blocks = node.extras.statements_id;
                        kblocks = Object.keys(blocks);
                        action.data.statements = kblocks;//noode.extras.botmsg_id;//
                        action.data.message = node.extras.botmsg_text;
                        action.data.message_html = node.extras.botmsg_name;
                        break;
                    case 'catalog':
                        action.type = 'catalog'; //AQUI ARREGLAR
                        blocks = node.extras.statements_id;
                        kblocks = Object.keys(blocks);
                        action.data.statements = kblocks;//noode.extras.botmsg_id;//
                        action.data.message = node.extras.botmsg_text;
                        action.data.message_html = node.extras.botmsg_name;
                        action.data.type = node.extras.config.item_type_short;

                        botLink = {};
                        botLink.to = node.id;
                        botLink.from = "Catalog_error";
                        botLink.this_from = node.id;
                        botCATALOG.push(botLink)
                        break;
                    case 'payment':
                        action.type = 'payment'; //AQUI ARREGLAR
                        blocks = node.extras.statements_id;
                        kblocks = Object.keys(blocks);
                        action.data.statements = kblocks;//noode.extras.botmsg_id;//
                        action.data.message = node.extras.botmsg_text;
                        action.data.message_html = node.extras.botmsg_name;
                        action.data.type = node.extras.config.item_type;
                        
                        break;
                    case 'multimedia':
                        action.type = 'multimedia'
                        action.url = node.extras.url;
                        blocks = node.extras.statements_id;
                        kblocks = Object.keys(blocks);
                       // action.data.list = node.extras.locationType;
                        action.data.statements = kblocks?kblocks:["0"];//noode.extras.botmsg_id;//
                        if(!action.data.config.idtemplateSelected)
                            action.data.config.idtemplateSelected = 'none';
                        if(!action.data.config.multimedia_type)   
                            action.data.config.multimedia_type = 'none';
                        action.data.variables = node.extras.variablesTemplate?node.extras.variablesTemplate:[];
                        
                        break;
                    case 'location':
                        action.type = 'location'; //AQUI ARREGLAR
                        blocks = node.extras.statements_id;
                        kblocks = Object.keys(blocks);
                        action.data.message = node.extras.botmsg_text;
                        action.data.message_html = node.extras.botmsg_name;
                        action.data.list = node.extras.locationType;
                        action.data.statements = kblocks;//noode.extras.botmsg_id;//
                        //action.data.variables =  node.extras.variables;
                        
                        break;
    

                    case 'ap':
                        action.type = 'switch_to_agent';
                        action.data.inital_message = node.extras.botmsg_text;
                        action.data.url_agent = node.extras.url_agent;
                        action.data.list = "";

                        if('config' in node.extras)
                        {
                            if(!node.extras.config.var_list)
                            {
                                node.extras.config.var_list= "[]";
                            }
                            else
                            {
                                if(typeof node.extras.config.var_list == "string")
                                    node.extras.config.var_list = JSON.parse(node.extras.config.var_list);
                            }
                            action.data.config =  node.extras.config;
                        }
                        break;

                    case 'ha':
                        action.type = 'external_api_beg_' + tha;
                        tha++;
                        break;

                    case 'ea':
                        action.type = 'external_api_end_' + tea;
                        tea++;
                        let statements_api = [];
                        const received_headers = node.extras.received_headers;
                        action.data.external_keys = [];
                        if (received_headers) {
                            action.data.external_keys = received_headers.map((h) => {
                                statements_api.push(h.statement);
                                return { external_key: h.name, statement: h.statement }
                            });
                        }

                        action.data.headers = {
                            body: node.extras.enctype,
                            method: node.extras.method,
                            url: node.extras.url_api,
                            validation: node.extras.validate
                        }

                        action.data.keys_api_ext = [];
                        if (node.extras.headers_keys) {
                            const hk = node.extras.headers_keys;
                            const khk = Object.keys(hk);
                            action.data.keys_api_ext = khk.map((h) => {
                                const header = hk[h];
                                statements_api.push(header.statement);
                                return {
                                    llave: header.value,
                                    statement: header.key
                                }
                            });
                        }

                        action.data.own_keys = [];
                        if (node.extras.custom_keys) {
                            action.data.own_keys = node.extras.custom_keys.map((h) => {
                                return {
                                    input_data: base64.encode(utf8.encode(h.text)),
                                    input_key: base64.encode(utf8.encode(h.name)),
                                    visual_data: ""
                                }
                            });
                        }

                        action.data.own_headers = [];
                        if (node.extras.custom_headers) {
                            action.data.own_headers = node.extras.custom_headers.map((h) => {
                                return {
                                    input_data: base64.encode(utf8.encode(h.text)),
                                    input_key: base64.encode(utf8.encode(h.name))
                                }
                            });
                        }

                        action.data.begin = node.extras.begin;
                        action.data.statements = statements_api;

                        if ('config' in node.extras)
                            action.data.config =  node.extras.config;
                        else
                            action.data.config = {
                                send_session_id: true
                            }
                        break;
                    case 'go':
                        action.type = 'go';
                        action.data.schema = node.extras.schema;
                        action.data.operatorGoTo = node.extras.operatorGoTo;
                        break;
                }
                if (action != null)
                    actions.push(action);
            });

            let finalObjects = {}
            finalObjects['botPbs'] = toObject(botPbs)
            finalObjects['botQrs'] = toObject(botQrs)
            finalObjects['botFls'] = toObject(botFls)
            finalObjects['botUfls'] = toObject(botUfls)
            finalObjects['botExt_init'] = toObject(botExt_init)
            finalObjects['botExt_end'] = toObject(botExt_end)
            finalObjects['botTxtModule'] = toObject(botTxtModule)
            finalObjects['botOthers'] = toObject(botOthers)
            finalObjects['actions'] = toObject(actions)
            finalObjects['botPasoAgnt'] = toObject(botPasoAgnt)
            finalObjects['botAI'] = toObject(botAI)
            finalObjects['botTimeout'] = toObject(botTimeout)
            finalObjects['botConditional'] = toObject(botConditional)
            finalObjects['botGoTo'] = toObject(botGoTo)
            finalObjects['botSMS'] = toObject(botSMS)
            finalObjects['botIMG'] = toObject(botIMG)
            finalObjects['botDSUS'] = toObject(botDSUS)
            finalObjects['botEMAIL'] = toObject(botEMAIL)
            finalObjects['botTRIGGER'] = toObject(botTRIGGER)
            finalObjects['botVars'] = toObject(botVars)
            finalObjects['botMULTIMEDIA'] = toObject(botMULTIMEDIA)
            finalObjects['botReport'] = toObject(botREPORT)
            finalObjects['botBlockchain'] = toObject(botBLOCKCHAIN)
            finalObjects['botScanqr'] = toObject(botSCANQR)
            finalObjects['botCatalog'] = toObject(botCATALOG)
            finalObjects['botPayment'] = toObject(botPAYMENT)

            this.setState({ hideLoad: cont +1 });

            await this.updateSchema()
                .then((res) => {
                    console.log('updateSchema saveBotAndGenerate éxitoso' , res)
                    
                }).catch((error) => {
                    console.log('catch updateSchema saveBotAndGenerate', error)
                    if (error.response && error.response.status == 403)
                        this.cerrarSesion();
                });

            //enviamos la data al servicio web que genera el script. 
            await this.generateBot(JSON.stringify(finalObjects))
                .then((res) => {
                    if (res.status == "200") {
                        this.setState({ hideLoad: 0 });
                        this.props.showSuccess(i18next.t('message_scheme7'),2000);
                       
                        location.reload();
                        //this.props.history.push(`/schemas/${res.data.bot[0].id}`)

                    } else {
                        this.props.showError(i18next.t('message_scheme8'),2000);
                        
                    }
                }).catch((error) => {
                    console.log('catch generateBot', error)
                    this.setState({ hideLoad: cont - 1 });
                    if (error.response.status == 403)
                        this.cerrarSesion();
                });
        } else {
            this.setState({ hideLoad: cont - 1 });
        }
    }

    /* 
    Funcion para obtener la lista de oepradores y actualizar en los nodos todos los ia_id  
    */
    async updateNodeIAIds() {
        const { listAllOperators } = this.props.boxReducer;
        return new Promise(async (resolve, reject) => {

            let isBotChange = true
            if(listAllOperators.length){
                const { scheme_id = 0 } = listAllOperators[0]
                isBotChange = (this.state.idSchema != scheme_id);
            }
            //console.log("isBotChange: ", isBotChange, this.state.idSchema, listAllOperators)
            if(isBotChange){

                console.log("Entro a actualizar la lista de operadores")
               await getHandlerGET(HOST_API + '/api/scheme/operator/?scheme_id=' + this.state.idSchema).then(async (res) => {
                    if (res.status == 200) {
                        if (res.data.operators) {
                            // se buscan y actualizan los ia_ids en los nodes. 
                            await this.props.setListAllOperators(res.data.operators);
                           
                            console.log("Actualizó la lista de operadores")

                            var nodes = this.model.getNodes();
                            for (let i = 0; i < res.data.operators.length; i++) {
                                let operator = res.data.operators[i];
                                if (nodes[operator.operatorinternal_id]) {
                                    nodes[operator.operatorinternal_id].extras.ia_id = operator.ia_id;
                                }
                            }
                            //se actualiza el esquema. 
                            await this.updateSchema().then((res) => {
                                resolve(true);
                            }).catch((error) => {
                                console.log('catch updateSchema')
                                if (error.response.status == 502) {
                                    this.props.showError(i18next.t('info_error_reload_flow'),2000);
                
                                    location.reload();
                                }
                                if (error.response.status == 403)
                                    this.cerrarSesion();
    
                            })
                            this.forceUpdate();
                        } else {
                            resolve(true);
                        }
    
                    } else {
                        resolve(true);
                    }
    
                }).catch((error) => {
                    console.log('catch updateNodeIAIds', error)
                    if (error.response && error.response.status == 403) {
                        this.cerrarSesion();
                    }
                    if (error.response.status == 502) {
                        this.props.showError(i18next.t('info_error_reload_flow'),2000);
                        location.reload();
                    }
                    reject(false);
                });
           
            }

            resolve(true);
        }) 

    }

    

    /*
     * getWebformList
     * lista de webform
     */
    getWebformList() {
        getHandlerGET(HOST_API + "/api/webforms/?botversion_id=" + this.state.botVersionId)
            .then((res) => {
                if (res.status == 200) {
                    if (res.data.list && res.data.list.length > 0) {
                        var listWebform = [];
                        let forms = res.data.list
                        forms.map(f => {
                            let temp = {};
                            temp.title = f.title;
                            if (f.url)
                                temp.url = f.url;
                            else
                                temp.url = "/webform/" + f.id;
                            listWebform.push(temp);
                        })
                        this.setState({ listWebform });
                    }
                }
            }).catch((error) => {
                console.log('catch getWebformList', error)
                if (error.response && error.response.status == 403)
                    this.cerrarSesion();
            });
    }

    setupChat = (botId, botVersion) => {
        const user = JSON.parse(localStorage.getItem('UserInfo'));
        const Params = {
            'BotId': botId,
            'BotVersionId': botVersion,
            //'RemoteSessionId': "jgomez@guarumo.com",
            'RemoteSessionId': "", 
            'Status': 'none',
            'BotHost': window.location.origin,
            'Initial_Step': '/reset',
            'start_type':'test_bot_'+ botVersion
        }

        this.ChatBotItem = new ChatBot(Params)
    }

    toggleEventModal = (node, isOpen = true) => {
        if (node.selected)
            node.selected = false
        this.setState((state) => {
            state.modals.m10 = {
                isOpen: isOpen,
                node: node,
                botversion_id: this.state.botVersionId
            }
            return state;
        })
    }

    toggleHelpModal = () => {
        localStorage.setItem('HelpModal', false);
        this.setState((state) => {
            state.modals.m11 = {
                isOpen: !this.state.modals.m11.isOpen,
                botversion_id: this.state.botVersionId
            }
            return state;
        })
    }

    /* Contacts */
    toggleContactModal = () => {
        this.setState({ modalContact: !this.state.modalContact })
    }


    // Esconde los botones home, si el esquema no es 
    // seleccionado como esquema principal
    hideAll = id => {
        if (id != this.state.init_schema)
            if (id == this.state.anonimo_scheme)
                $('.set-home').fadeIn('fast');
            else
                $('.set-home').fadeOut('fast');
        else
            $('.set-home').fadeIn('fast');
    }

    showContent=()=>{
        const {  isLogin } = getRolUser();
        const { bot } = this.props.botReducer;

        if(isLogin && bot.id){
          return ( <div>
            <BotLayout engine = { this.engine }
            drawModule = { this.drawModule }
            closeModal = { this.closeModal }
            saveBotAndGenerate = { this.saveBotAndGenerate }
            m1 = { this.state.modals.m1 }
            m2 = { this.state.modals.m2 }
            m3 = { this.state.modals.m3 }
            m4 = { this.state.modals.m4 }
            m5 = { this.state.modals.m5 }
            m6 = { this.state.modals.m6 }
            m7 = { this.state.modals.m7 }
            m8 = { this.state.modals.m8 }
            m9 = { this.state.modals.m9 }
            m10 = { this.state.modals.m10 }
            m11 = { this.state.modals.m11 }
            m12 = { this.state.modals.m12 }
            m13 = { this.state.modals.m13 }
            m14 = { this.state.modals.m14 }
            m15 = { this.state.modals.m15 }
            m16 = { this.state.modals.m16 }
            m17 = { this.state.modals.m17 }
            m18 = { this.state.modals.m18 }
            m_cd = { this.state.modals.m_cd }
            m_scanqr = { this.state.modals.m_scanqr }
            m_catalog = { this.state.modals.m_catalog }
            m_payment = { this.state.modals.m_payment }
            m_location = { this.state.modals.m_location }
            m_multimedia = { this.state.modals.m_multimedia }
            m_creport = { this.state.modals.m_creport }
            m_blockchain = { this.state.modals.m_blockchain }
            m_if = { this.state.modals.m_if }
            updateSchema = { this.updateSchema }
            toggleEventModal = { this.toggleEventModal }
            toggleHelpModal = { this.toggleHelpModal }
            toggleModuleModal = { this.toggleModuleModal }
            toggleContactModal = { this.toggleContactModal }
            modalContact = { this.state.modalContact }
            botId = { this.state.botid }
            company_id = { this.state.company_id }
            botversion_id = { this.state.botVersionId }
            totalbox={this.props.boxReducer.listAllOperators.length }
            ia_activated = { this.state.ia_activated }
            varList = { this.props.boxReducer.varList }
            listWebform = { this.state.listWebform }
            platform = { this.state.platform }
            idSchema = { this.state.idSchema }
            hideAll = { this.hideAll }
            />  <ModalLoad hideLoad = { this.state.hideLoad }
            />  
            </div >
        );
    }
    return <ModalLoading />
  }

render() {
    return this.showContent();
}
}

const mapStateToProps = ({ loadStateReducer,botReducer,boxReducer,homeReducer, componentMountReducer }) => {
return { loadStateReducer,botReducer,boxReducer,homeReducer, componentMountReducer };
};

const mapDispatchToProps = {
isDidMount,
willUnMount,
showSuccess, loading, showWarning,showError, hideAlert,setTitle,
iconLoad,
iconHideLoad,
getListTypeCalendar,
setSelectedOperator,
toggleModalViewBoxCalendar,
toggleModalViewBoxCatalog,
toggleModalViewBoxPayment,
toggleModalViewBoxScanQR,
toggleModalViewBoxLocation,
setSelectedTypeMultimedia,
toggleModalViewBoxMultimedia,
toggleModalViewBoxCReport,
toggleModalViewBoxBlockchain,
toggleModalViewBoxWebview,
toggleModalViewBoxVariable,
toggleModalViewBoxUploadFile,
toggleModalViewBoxTrigger,
toggleModalViewBoxSMS,
toggleModalViewBoxText,
toggleModalViewBoxQuickReply,
toggleModalViewBoxGoTo,
toggleModalViewBoxForm,
toggleModalViewBoxEmail,
toggleModalViewBoxButtons,
toggleModalViewBoxDesuscription,
toggleModalViewBoxConditional,
toggleModalViewBoxApi,
setContentType,
toggleModalViewBoxAgent,
toggleModalViewBoxCarousel,
setVariablesBoxOperator,
setSelectedTypeLocation,
setBotmsgText,
setSelectedListLocation,
setDinamicVariablesBoxOperator,
setSelectedBot,
setVarList,
setListAllOperators,
setAvatarid,
setUrlMultimediaPreview,
setChangeConfig,
setConfig,
imageTemplate,
resetTempVariablesTemplate,
addTextBlock,
setSelectedProvider,
setNode,
setSelectedScheme,
changeActiveTab,
setListIntent,
updateConfigOperator,
removeModuleDB,
deleteOperator,
addButtons,
setHead,
updateOperator


};

export default connect(mapStateToProps, mapDispatchToProps)(Bot);

