import { DATABASE_ACTIONS } from '../types/databaseActionTypes';


const INITIAL_STATE = {
    avatarList: [],
    listCollection:[],
    visualizarCollection:[],
    databasesShowValues: [],
    visualizarCollectionPagination:{},
    sinRegistroFiltro:true,
    nombreCompany:null,
    nameCollection:null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
        case DATABASE_ACTIONS.getCollectionByCompany():
            return {
                ...state,
                listCollection: action.payload,
            };
        case DATABASE_ACTIONS.getAvatarsByCompany():
            return {
                ...state,
                avatarList: action.payload,
            };
        case DATABASE_ACTIONS.getVisualizarCollection():
            return {
                ...state,
                visualizarCollection: action.payload,
            }
        case DATABASE_ACTIONS.arrayDataBasesShowValues():
            return {
                ...state,
                databasesShowValues: action.payload,
        };
        case DATABASE_ACTIONS.getVisualizarCollectionPagination():
            return {
                ...state,
                visualizarCollectionPagination: action.payload,
            }
        case DATABASE_ACTIONS.getSinRegistrosFiltroCollection():
            return {
                 ...state,
                 sinRegistroFiltro: action.payload,
            }
        case DATABASE_ACTIONS.getNombreCompany():
            return {
                 ...state,
                 nombreCompany: action.payload,
            }
        case DATABASE_ACTIONS.getNameCollection():
            return {
                    ...state,
                    nameCollection: action.payload,
            }
		default: return state;
	};
};