import { AbstractNodeFactory } from "storm-react-diagrams";
import EANodeWidget from "./EANodeWidget";
import React from "react";
import EANodeModel from "./EANodeModel";

class EANodeFactory extends AbstractNodeFactory {
	constructor() {
		super("ea");
	}

	generateReactWidget(diagramEngine, node) {
		return <EANodeWidget node={node} /> 
	}

	getNewInstance() {
		return new EANodeModel();
	}
}

export default EANodeFactory