import React, { Component } from "react";
import { connect } from "react-redux";

import MMMLayout from "./multimedia-module-layout";
import SMPortModel from "../simplePortModel/SMPortModel";

import { fileHandlerPOST } from "../../../../utils/handlersApi";
import {
  isAuthorizationValid,
  getRolUser,
  cerrarSesion,
  isEmpty,
} from "../../../../utils/commonFunction";

import { APPLICANT } from "../../../../redux/listComponents";
import {
  isDidMount,
  willUnMount,
} from "../../../../redux/actions/componentMountActions";

import {
  showSuccess,
  showError,
  showWarning
} from "../../../../redux/actions/loadStateActions";

import {
  iconLoad,
  iconHideLoad,
  toggleModalViewBoxMultimedia,
  setFileMultimediaPreview,
  setUrlMultimediaPreview,
  updateVariableBoxOperator,
  setContentType,
  setChangeConfig,
  updateOperator,
  createNewStatement,
  updateStatement,
  removeStatement,
  cleanSelection,
  setVarList,
  updateBoxOperator
} from "../../../../redux/actions/boxActions";

import i18next from "../../../../../i18n";

import { getAllTemplate, resetTempVariablesTemplate,updateTempVariableTemplate} from "../../../../redux/actions/templateActions";

var mime = require("mime-types");

class MMModal extends Component {
  constructor(props) {
    super(props);
    this.onDrop1 = this.onDrop1.bind(this);
    this.state = {
      keyword_valid: true,

      isAdmin: false,
      isSuperUser: false,
      hideLoad: false,
      user: "",
      nodeId: "",
      modal_multimedia: [],
      tmpMultimedia: "",
      tmpInput: "",
    };
  }

  componentWillMount() {
    isAuthorizationValid();
    const { isAdmin = false, isSuper = false } = getRolUser();
    this.setState({
      isAdmin: isAdmin,
      isSuperUser: isSuper,
    });
  }

//   componentWillMount(){
//     var auth=JSON.parse(localStorage.getItem('Authorization'));
//     if(!auth || !auth.access_token)
//       this.props.history.push("/login");

//       if(this.props.modal.node.extras.keyword!='')
//         this.setState({keyword_valid:true}, ()=>{
//             this.forceUpdate();
//     })
// }


  async componentDidMount() {
    //----------------------------------------------------------------
    //  PONE EL ICONO DE CARGA
    //----------------------------------------------------------------
    this.props.iconLoad();

    this.props.isDidMount(APPLICANT.HOME());

    const { listAllTemplate } = this.props.templateReducer;

    if (isEmpty(listAllTemplate)) await this.props.getAllTemplate();
    //----------------------------------------------------
    //  OCULTA EL ICONO DE CARGA
    //----------------------------------------------------
    this.props.iconHideLoad();
  }
  shouldComponentUpdate(nextProps, nextState) {
    const nextModal = nextProps.modal;
    const modal = this.props.modal;

    return modal !== nextModal;
  }

  componentWillUnmount() {
    this.props.willUnMount(APPLICANT.HOME());
  }

  /**
   *  Carga archivo desde URL
   */
  saveUrl = (event) => {
    event.preventDefault();
    let form = new FormData(event.target);
    let multimedia = form.get("multimediaModal");

    let c_t = mime.lookup(multimedia);

    /**
     * Actualiza la url y el content-type al cargar archivo desde URL
     */
    this.props.setUrlMultimediaPreview(multimedia);
    this.props.setContentType(c_t);

    /**
     * Limpia la opción de cargar arcivo desde archivos locales
     */
    this.props.setFileMultimediaPreview([]);
    this.setState({ tmpInput: multimedia }, () => {
      //alert('URL guardada con éxito.')
      //document.getElementById('divide').style.display = 'none';
      //document.getElementById('box-form-drop').style.display = 'none';
    });
  };

  addElementVariable = (key,value) => {
    if (this.props.modal.node.extras["variablesTemplate"]) {
      this.props.modal.node.extras["variablesTemplate"].push({
        key: key?key:"",
        value: value?value:"",
      });
    } else {
      this.props.modal.node.extras.variablesTemplate = [
        {
            key: key?key:"",
            value: value?value:"",
        },
      ];
    }
 };

  removeVariable = (index) => {
    this.props.modal.node.extras.variablesTemplate.splice(index, 1);
   };

  removeAllVariable = async () =>{
    
    //this.props.modal.node.extras.variablesTemplate = [];

    const { variableTemplate } = this.props.templateReducer;

    await this.props.resetTempVariablesTemplate([]);

    await variableTemplate.map((value, index) => {
      this.addElementVariable(value);
      console.log("variable a pintar:",value);

    });

   // await this.forceUpdate();
  }

 
  /*
   *  toggleModuleModal
   *  Save all operator information
   */
  toggleModuleModal = async () => {
    this.setState({ modal_multimedia: [] });

    let node = this.props.modal.node;

    let form = new FormData(document.getElementById('MMM-form'));
    
    console.log("form ",form.get('titleOperator'));
    
    node.extras.titleOperator = form.get('titleOperator');
    
    await this.props.updateBoxOperator('title', node.extras.titleOperator);

    //Pone icono de carga
    this.props.iconLoad();

    const {
      configBoxOperator,
      contentType,
      urlMultimediaPreview,
      selectedTypeMultimedia,
      variablesBoxOperator,
    } = this.props.boxReducer;
    
    console.log("configBoxOperator ",configBoxOperator);

    const {
        tempVariablesTemplate
    } = this.props.templateReducer;

    /**
     * Dependiendo el tipo seleccionado actualiza las variables y el config del operador
     */
    if (
      selectedTypeMultimedia == "variable_multimedia"
    ) {
      this.props.setUrlMultimediaPreview("none");
      this.props.setContentType("");

      this.props.setChangeConfig("url", "none");
      this.props.setChangeConfig("content_type", "");

      this.props.updateVariableBoxOperator("var_main", "", "variables");
  

    }else if(selectedTypeMultimedia == "template_multimedia")
    {
        this.props.setUrlMultimediaPreview("none");
        this.props.setContentType("");

        this.props.setChangeConfig("url", "none");
        this.props.setChangeConfig("content_type", "");
        this.props.setChangeConfig("url_statement", "none");
    } 
    else if (urlMultimediaPreview != "") {
      this.props.setChangeConfig("url", urlMultimediaPreview);
      this.props.setChangeConfig("url_statement", "none");
    }

    if(selectedTypeMultimedia != "template_multimedia")
    {
      this.props.setChangeConfig("idtemplateSelected", "none");
      this.props.setChangeConfig("multimedia_type", "none");
      
     
    }

    //Si el tipo de multimedia cambió se actualiza el tipo en el config
    if (
      selectedTypeMultimedia &&
      node.extras.config.item_type != selectedTypeMultimedia
    ) {
      //Actualiza el tipo seleccionado
      await this.props.setChangeConfig("item_type", selectedTypeMultimedia);
      node.extras.config.item_type = selectedTypeMultimedia;

      //Elimina todo los staments para crear los nuevos
      //await this.removeAllStatements();
    }

    if (contentType != "")
      this.props.setChangeConfig("content_type", contentType);

    if (this.state.tmpImage && this.state.tmpImage != "") {
      node.extras.url = this.state.tmpImage;
    } else {
      node.extras.url = this.state.tmpInput;
    }

    const ports = node.getPorts();
    const kports = Object.keys(ports);
    const kport = kports.find((k) => {
      const port = ports[k];
      return port.name == "right-timeout";
    });

    if (!kport) {
      if (node.extras.timeout > 0)
        node.addPort(new SMPortModel(true, "right-timeout", "sm"));
    } else {
      if (node.extras.timeout <= 0) {
        kports.forEach((kport) => {
          const port = ports[kport];
          if (port.name == "right-timeout") {
            const links = port.getLinks();
            const klinks = Object.keys(links);
            klinks.forEach((klink) => {
              const link = links[klink];
              link.remove();
            });
            node.removePort(port);
          }
        });
      }
    }

    //Actualiza las variables. Si no cambió el tipo actualiza los statements, si cambió crea los nuevos
    let blocksVar = variablesBoxOperator;
    let kblocksVar = Object.keys(blocksVar);

    if (kblocksVar.length == 0) node.extras.variables = {};

    await kblocksVar.map(async(variable) => {
      //Si no se tiene un id de statement se crea en la base de datos
      if (blocksVar[variable].statement_id == "") {
        await this.props.createNewStatement(node.extras.dbId, variable, node,'variables');
      } else {
        // Edita el statement cuando ya existe en la base de datos
        this.updateStatement(
          blocksVar[variable].statement_id,
          "",
          variable,
          blocksVar[variable].var_name != "" ? true : false,
          blocksVar[variable].var_name,
          node
        );
      }
    });

    //Extrae y guarda la nueva configuración
    node.extras.config = configBoxOperator;


    tempVariablesTemplate.map((value, index) => {
      const valor = $('#input-head_'+index).val();;
      this.props.updateTempVariableTemplate(index,valor);
    })
    
    const {
      tempVariablesTemplate: variablesTemplate1
  } = this.props.templateReducer;

    //Actualiza las variables del template
    node.extras.variablesTemplate = variablesTemplate1;

    //Actualiza el operador
    await this.props.updateOperator(
      node.extras.titleOperator,
      node.extras.keyword,
      node.extras.config
    );

    //await this.props.updateOperator(node.extras.titleOperator,node.extras.keyword,node.extras.config);

    //Actualiza el esquema
    await this.props.updateSchema();

    //Limpia selecciones
    await this.props.cleanSelection();

    //Actualiza la lista de variables
    await this.props.setVarList();

    //Cierra el modal
    await this.props.toggleModalViewBoxMultimedia();
  };


  //Actualizar información de statement
  updateStatement = (statement, text, name, is_var, var_name, node) => {
    const data = {
      id: statement,
      text: text,
      name: name,
      is_var: is_var,
      var_name: var_name,
    };

    this.props.updateStatement(data, node, "variables");
  };

  //Eliminar un Statment
  removeStatementbyId = (statement) => {
    this.setState({ error_limit_item: false });
    const data = {
      id: statement,
    };

    this.props.removeStatement(data);
    this.props.updateSchema();
  };

  //Eliminar todos los statements
  removeAllStatements = () => {
    let blocks = this.props.modal.node.extras.statements_id;
    let kblocks = Object.keys(blocks);
    console.log("Statements a eliminar: ", kblocks);
    kblocks.map((variable) => {
      this.removeStatementbyId(variable);
      delete blocks[variable];
    });
  };
  /*
   * Function onDrop1
   * funtion componente drag and drop
   */
  onDrop1(files) {
    this.setState(
      {
        error_modal_size: false,
        modal_multimedia: files.map((file) => ({
          file,
          preview: URL.createObjectURL(file),
          type: file.type,
        })),
      },
      () => {
        if (document.getElementById("box-form"))
          document.getElementById("box-form").style.display = "none";

        this.subirMultimedia();
      }
    );
  }

  /*
   * Function subirMultimedia
   * Carga el archivo al server s3
   */
  subirMultimedia = () => {
    let currentMultimedia = this.state.modal_multimedia;
    if (currentMultimedia.length > 0) {
      //Pone el ícono de carga
      this.props.iconLoad();

      let form = new FormData();
      form.append("multimedia", this.state.modal_multimedia[0].file);

      this.props.setContentType(this.state.modal_multimedia[0].file.type);
      this.props.setFileMultimediaPreview(this.state.modal_multimedia[0].file);
      this.props.setUrlMultimediaPreview("none");

      fileHandlerPOST(HOST_API + "/api/bot/upload_multimedia/", form)
        .then((res) => {
          if (res.status != 200)
            this.props.showError(
              i18next.t('message69') + " <br />" + res
            );
          else {
            this.props.showSuccess(i18next.t('message66'), 2000);
          }

          this.setState({ hideLoad: 0, tmpMultimedia: res.data.multimedia });
          this.props.setUrlMultimediaPreview(res.data.multimedia);

          //----------------------------------------------------
          //  OCULTA EL ICONO DE CARGA
          //----------------------------------------------------
          this.props.iconHideLoad();
        })
        .catch((error) => {
          //console.log('Catch POST subirMultimedia:',error);
          this.props.showError(
            i18next.t('message69') + " <br />" + error
          );
          if (error.response.status == 403) cerrarSesion();
        });
    }
  };

  showContent = () => {
    const { isLogin } = getRolUser();

    const { selectedBoxOperator } = this.props.boxReducer;
    if (isLogin) {
      //Si la caja seleccionada es tipo multimedia
      if (selectedBoxOperator && selectedBoxOperator.operatortype_id == 13) {
        return (
          <MMMLayout
            modal_multimedia={this.state.modal_multimedia}
            onDrop1={this.onDrop1}
            toggleModuleModal={this.toggleModuleModal}
            multimedia={this.props.modal.node.extras.url || ""}
            saveUrl={this.saveUrl}
            handleChangeNameVar={this.handleChangeNameVar}
            variables={this.props.modal.node.extras.variablesTemplate || []}
          />
        );
      } else {
        return <div></div>;
      }
    }
    return <ModalLoading />;
  };

  render() {
    return this.showContent();
  }
}

const mapStateToProps = ({
  botReducer,
  boxReducer,
  homeReducer,
  templateReducer,
  componentMountReducer,
}) => {
  return {
    botReducer,
    boxReducer,
    homeReducer,
    templateReducer,
    componentMountReducer,
  };
};

const mapDispatchToProps = {
  isDidMount,
  willUnMount,
  iconLoad,
  iconHideLoad,
  showSuccess,
  showError,
  toggleModalViewBoxMultimedia,
  updateVariableBoxOperator,
  setFileMultimediaPreview,
  setUrlMultimediaPreview,
  setContentType,
  setChangeConfig,
  updateOperator,
  createNewStatement,
  updateStatement,
  removeStatement,
  cleanSelection,
  setVarList,
  getAllTemplate,
  resetTempVariablesTemplate,
  updateTempVariableTemplate,
  showWarning,
  updateBoxOperator
};

export default connect(mapStateToProps, mapDispatchToProps)(MMModal);
