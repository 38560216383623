import React, { Component } from 'react';
import { 
    Input
} from 'reactstrap';

import Mentions from './mentions/mentions';
import i18next from "../../i18n";


class InputValidate extends Component {
    state = {
        error_input:{},
        value: ''
    }
    constructor(props){
        super(props)
        this.state.value = props.defaultValue || ""
    }
    onKeyDown = e => {
        let k = this.props.k;
        let statement_length = e.target.value.length
        let a = this.state.error_input;
        this.props.error_input_empty[k] = false
        if(e.keyCode !== 8){
            if(statement_length >= this.props.maxlength){
                e.preventDefault(); 
                e.stopPropagation();
                this.setState({error_input:a});
                a[k]=true;
                return false;
            }else{
                a[k]=false;
                this.setState({error_input:a})
                return true;
            }
        }else{
            a[k]=false;
            this.setState({error_input:a})
            return true;
        }  
        
    };
    onChange = e => {
        // this.setState({value : e.target.value})
        this.setState({value : e})
        if(this.props.onChange)
            this.props.onChange(e);
    }
    render() {
        let k = this.props.k;
        return (
            <div>
                <Input 
                    id={"input-"+k} 
                    className="inputModal" 
                    data-placeholder="respuesta"
                    onKeyDown={(event) => { this.onKeyDown(event) }}
                    onChange={(event) => { this.onChange(event) }}
                    defaultValue={this.state.value}
                 /> 
                { 
                    this.state.error_input[k] &&
                    <span className="warning-character">{this.props.msg_error}</span>
                } 
                {
                    this.props.error_input_empty[k] && 
                    <span className="warning-character">{i18next.t('msg_error_empty')}</span>
                }  
            </div>
        )    
    }
}


export default InputValidate