import React from 'react';
import NavbarApp from '../components/navbar';
import './admin-layout.css';

const AdminLayout = (props) => (
  <div className="AdminLayout">
    <NavbarApp 
    toggle={props.toggle} 
    logOut={props.logOut} 
    isOpen={props.isOpen} 
    updateUser={props.toggleModalNewUser}
    user={props.user} />
    {props.children}
  </div>
)

export default AdminLayout
