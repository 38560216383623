import React, { Component } from 'react';
import { connect } from "react-redux";

import ModalLoading from '../../../components/modals/Loading/moda-loading';

import AdminDashboardLayout from './bot_admin_dashboard_layout';
import { isAuthorizationValid,getRolUser,isEmpty } from "../../../utils/commonFunction";

import { APPLICANT } from "../../../redux/listComponents";
import { isDidMount, willUnMount } from "../../../redux/actions/componentMountActions";
import { iconLoad, iconHideLoad,getTockenCubejs, getAllDashboards, getAllCharts,getAllPalettes,getAllListCompanies } from "../../../redux/actions/analyticsActions";


class AdminDashboard extends Component {
    constructor(props){
      super(props);
        this.state = {
            isAdmin: false,
            isSuperUser: false,
            hideLoad: false,
            user:'',
            companies : [],
            modalDashboard:false,
            listDashboard: [],
            buscador: "",
            charts:[]
        }
    }
    componentWillMount(){
        isAuthorizationValid()
        const { isAdmin = false, isSuper = false } = getRolUser();
        this.setState({
            isAdmin: isAdmin,
            isSuperUser: isSuper
        });
      
      }
      async componentDidMount(){
            const { analyticsReducer : { activePage, listAllDashboards, listAllCharts, AllPalettes } } = this.props;
            const { homeReducer : {  listAllCompanies} } = this.props;

            this.props.isDidMount(APPLICANT.HOME());    
        
            //----------------------------------------------------------------
            //  PONE EL ICONO DE CARGA
            //----------------------------------------------------------------
             await this.props.iconLoad();

            //----------------------------------------------------------------
            //  ACTUALIZA EL TOCKEN DE CUBEJS
            //----------------------------------------------------------------
            await this.props.getTockenCubejs();
           
            //----------------------------------------------------------------
            //  LISTA TODAS LAS COMPAÑÍAS, COJE LA PRIMERA COMO SELECTCOMPANY
            //----------------------------------------------------------------
            if(isEmpty(listAllCompanies)){
              await this.props.getAllListCompanies();     
            }
              //----------------------------------------------------------------
              //  LISTA TODOS LOS DASHBOARDS DE LA COMPAÑÍA
              //----------------------------------------------------------------
            if(isEmpty(listAllDashboards)){
              await this.props.getAllDashboards(activePage); 
            }
          //----------------------------------------------------
          //  OCULTA EL ICONO DE CARGA
          //----------------------------------------------------
            await this.props.iconHideLoad();

           
       }   
       shouldComponentUpdate(nextProps, nextState) {
          //----------------------------------------------------
          //  SOLO EJECUTA EL UPDATE SI LA COMPAÑIA FUE CAMBIADA
          //----------------------------------------------------
          const nextCompany = nextProps.analyticsReducer.selectedCompany;
          const company = this.props.analyticsReducer.selectedCompany;
          return nextCompany !== company;
        }

        async componentWillUpdate(nextProps,nextState)
      {
       
        //-----------------------------------------------------------------------
        //  SI SE ELIGIÓ OTRA COMPAÑÍA
        //-----------------------------------------------------------------------
        const nextCompany = nextProps.analyticsReducer.selectedCompany;
        const company = this.props.analyticsReducer.selectedCompany;
    
        if(nextCompany!==company)
        {
        //-----------------------------------------------------------------------
        //  LISTA TODOS LOS DASHBOARDS DE LA COMPAÑÍA
        //-----------------------------------------------------------------------
          await this.props.getAllDashboards(); 
          //----------------------------------------------------
          //  OCULTA EL ICONO DE CARGA
          //----------------------------------------------------
          await this.props.iconHideLoad();
        }

      }
  
     
      
          componentWillUnmount() {
            this.props.willUnMount(APPLICANT.HOME());
          }
    
showContent=()=>{
    const {  isLogin } = getRolUser();
    if(isLogin){
      return (
        <React.Fragment>
         <AdminDashboardLayout 
        isAdmin={this.state.isAdmin}
        isSuperUser={this.state.isSuperUser}
    />
    
    </React.Fragment>
    
     );
    }
    return <ModalLoading />
  }
     
    render() {
        return this.showContent();
    }
}
const mapStateToProps = ({ analyticsReducer, homeReducer,componentMountReducer }) => {
	return { analyticsReducer,homeReducer, componentMountReducer };
};

const mapDispatchToProps = {
  getAllCharts,
  getAllDashboards,
  getAllListCompanies,
  isDidMount,
  willUnMount,
  getAllPalettes,
  getTockenCubejs,
  iconLoad,
  iconHideLoad
  
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboard);

