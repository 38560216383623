import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
// don't want to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init
const resources = {
  es: {
    translation: {
      warning5:"Texto botón enviar puede ser icono o un texto de usuario no mayor a 10 caracteres",
      warning6:"Habilita o deshabilita la entrada del texto del chat cuando el bot visualice alguna opción de seleccion con botones.",
      warning7:"Ubicación en el sitio web donde se visualizará el bot",
      sidebarColor:"Seleccione el color de la barra de menú lateral",
      faviconCompany:"Seleccione el favicon de la compañía",
      navbarColor:"Seleccione el color de la barra de navegación superior",
      logoCompany:"Seleccione el logo de la compañía",
      sign_in: "Iniciar Sesión",
      password: "Contraseña",
      email: "Correo electrónico",
      next: "Siguiente",
      username: "Usuario",
      otp_token: "Por favor ingrese el token de autenticación que fue enviado al correo",
      otp_token_verify: ". Si el token es verificado, usted podrá continuar",
      init_session: "Iniciar Sesión",
      init_session_btn: "Ingresar",
      edit_profile: "Editar Perfil",
      close_session: "Cerrar Sesión",
      new_bot: "Nuevo Bot",
      search: "Buscar",
      modified_in: "Modificado en",
      created_in: "Creado el",
      close_in: "Cerrado en",
      test: "Probar",
      edit: "Editar",
      export: "Exportar",
      duplicatedBot: "Duplicar Bot",
      import: "Importar",
      create_bot:'Crear Bot',
      import_bot:'Importar Bot',
      create_schema:'Crear Esquema',
      import_schema:'Importar Esquema',
      duplicatedShema: "Duplicar Esquema",
      delete: "Eliminar",
      company: "Compañía",
      select_coleccion: "Selecciona la colección",
      base_de_datos_sobrescribir:"Base de datos a sobrescribir",
      base_de_datos_add_new_register: "Agregar nuevos registros",
      base_de_datos_delete_register: "Eliminar registros actuales y agregar nuevos",
      Eliminar: "Eliminar",
      name_coleccion: "Nombre colección",
      nueva_coleccion: "Nueva colección",
      admin_companies: "Administración de Empresas",
      admin_database: "Base de datos",
      group_for: "Agrupar por",
      start_date: "Fecha Inicio",
      end_date: "Fecha Fin",
      send_date: "Fecha de Envio",
      filter: "Filtrar",
      year: "Año",
      month: "Mes",
      day: "Día",
      week: "Semana",
      hour: "Hora",
      consolidate: "Consolidado",
      print: "Imprimir",
      see_columns: "Ver Columnas",
      filter_table: "Filtrar Tabla",
      table: "Tabla",
      reports: "Reportes",
      report: "Reporte",
      report_interactions: "Reporte de interacciones",
      report_personalized: "Reporte Personalizado",
      report_blockchain: "Reporte de Blockchain",
      blockchain: "Blockchain",
      report_email: "Reporte de Email",
      report_sms: "Reporte SMS",
      admin_metrics: "Administración de Métricas",
      admin_graphics: "Administración de Gráficas",
      admin_dashboard: "Administración Dashboard",
      first_1000_records: "Se listarán los primeros 10.000 registros, para acceder a la información completa por favor descargue el achivo de datos xlsx.",
      without_personalized_report: "AÚN NO TIENES REPORTES PERSONALIZADOS",
      hear_found_personalized_report: "Aquí encontrarás los reportes personalizados del bot",
      in_report_box_values: "Incluye una caja reporte en el flujo con los valores que deseas almacenar para generar el reporte personalizado.",
      email_reports: "Reporte de Correos Electrónicos",
      registers_not_founded: "No se encontraron registros en el periodo Seleccionado",
      name: "Nombre",
      name_short: "Nombre corto",
      description: "Descripción",
      unit: "Unidad",
      indicator_type: "Tipo de indicador",
      metric_type: "Tipo de Medida",
      edition_option: "Opciones/Edición",
      general_information: "Información General",
      minutes: "Minutos",
      minute: "Minute",
      functional: "Funcional",
      dimensions: "Dimensiones",
      dimension: "Dimension",
      graphic_type: "Tipo de gráfico",
      info_graphic: "Información del gráfico",
      previews: "Previsualización",
      info_data_last_month: "si no hay datos en el último mes del indicador seleccionado se mostrarán datos de prueba",
      metric: "Metrica",
      select_graphics_type: "Seleccione un tipo de gráfica",
      info_dashboard: "Información del dashboard",
      dashboard_title: "Título del dashboard",
      channel: "Canal",
      date: "Fecha",
      registers_not_founded: "No se encontraron registros",
      title: "Título",
      rename: "Renombrar",
      flow: "Flujo",
      new_flow: "Crear Nuevo Flujo",
      start_flow: "Flujo Inicial",
      anonymous_flow: "Flujo Anónimo",
      anonymous_flow_deactivate: "Desactivar flujo anónimo",
      project_name: "Nombre del proyecto",
      bot_name: "Nombre del bot",
      language: "Lenguaje",
      spanish: "Español",
      platform: "Plataforma",
      primary_platform: "Plataforma principal",
      web: "Web",
      add_platforms: "Plataformas adicionales",
      font_size: "Tamaño de fuente",
      header_background: "Fondo Encabezado",
      user_balloon_background: "Fondo globo usuario",
      bot_balloon_background: "Fondo globo bot",
      background_chat: "fondo chat",
      background_buttons: "Fondo botones",
      border_color_buttons: "Color de Borde botones",
      color_text_balloon_user: "Color texto globo usuario",
      color_text_balloon_bot: "Color texto globo bot",
      color_text_buttons: "Color texto botones",
      color_text_send_button: "Color texto boton enviar",
      color_link_balloon_user: "Color enlaces globo usuario",
      color_link_balloon_bot: "Color enlaces globo bot",
      background_button_send: "Fondo botón enviar",
      background_chat_close: "Fondo cerrar chat",
      color_chat_close: "Color cerrar chat",
      text_button_send: "Texto botón enviar",
      use_icon_text: "Usar icono usar texto",
      in_text_chat: "Entrada de texto del chat",
      enable: "Habilitar",
      disable: "Deshabilitar",
      locate_bot: "Ubicación bot",
      bot: "Bot",
      corner_lower_left: "Esquina inferior inquierda",
      up_bot_start: "Desplegar bot al iniciar en web ",
      up_bot_start_movil: "Desplegar bot al iniciar en movil ",
      yes: "Si",
      avatar_animation: "Avatar Animación",
      nothing: "Ninguna",
      placeholder_field_text: "Texto placeholder campo de texto chat",
      
      write_message: "Escribe tu mensaje",
      msg_button_back_ia: "Mensaje botón regresar IA",
      back: "Regresar",
      msg_button_continue_ia: "Mensaje botón continuar IA",
      msg_return: "Mensaje para regresar",
      return: "Regresar",
      continue:"Continuar",
      word_return_queue_pa: "Palabra para abandonar la cola de espera de paso agente",
      firts_timeout: "Primer tiempo de espera",
      second_timeout: "Segundo tiempo de espera",
      third_timeout: "Tercer tiempo de espera",
      firts_msg_timeout: "Primer mensaje tiempo de espera",
      second_msg_timeout: "Segundo mensaje tiempo de espera",
      third_msg_timeout: "Tercer mensaje tiempo de espera",
      test_phone_number: "Número de celular para pruebas",
      contact_list: "Lista de Contactos",
      phone_celnumber: "Número Celular",
      select_one: "Seleccione uno",
      platforms: "Plataformas",
      url_chat_pa: "Url agente de chat",
      msg_not_connect: "Mensaje Agente no conectado",
      template_email: "Plantilla de email",
      avanced_chat: "Avanzado Chat",
      size_files: "Tamaño de archivos",
      sender_voice: "Remitente de voz",
      for_defect: "Por Defecto",
      sender_email: "Remitente de email",
      url_integration: "Url de integración",
      user_verification_code: "Código de verificación usuario",
      token_facebook: "Token de facebook",
      groups_qna: "Grupos QnA",
      group: "Grupo",
      operation: "Operacion",
      operations: "Operaciones",
      activate_ia: "Activar IA",
      basic_data: "Datos básicos",
      visual_aparience: "Apariencia Visual",
      advanceds: "Avanzados",
      integrations: "Integraciones",
      avatars: "Avatares",
      avatar: "Avatar",
      home_screen_portal: "Portal Pantalla Inicio",
      home: "Inicio",
      default: "Por defecto",
      activate_bot: "Activar bot",
      avatars_list: "Listado de avatar",
      window_chat_close: "Ventana Chat Cerrado",
      bot_not_understand: "Bot no entiede",
      bot_writing: "Bot escribiendo",
      start_chat: "Inicio Chat",
      edit_avatar: "Editar Avatar",
      upload_image: "Arrastra o haz click para seleccionar imagen",
      file_selected: "Seleccionar archivo",
      nothing_select_file: "Ningun archivo seleccionado",
      limit_image: "Se recomienda que las dimensiones de la imagen no sean mayor a 500px, una relacion 1:1 (Cuadrada) y el tamaño de subida no sea mayor a 200 KB",
      module_ia: "Módulo de Inteligencia Artificial",
      configure_modules: "Configuración de Módulos",
      created_modules: "Módulos Creados",
      questions_without_response: "Preguntas sin respuesta asociada",
      change_setting: "Cambiar Ajuste",
      create_module: "Crear Módulo",
      module_web_views: "Módulo Vista Web",
      list_web_views: "Lista de Vistas Web",
      create: "Crear",
      connect: "Enlazar",
      stadistics: "Estadisticas",
      dashboard_general: "Dashboard General",
      tecnology: "Tecnología",
      total_users: "Total usuarios",
      interval_dates: "Intervalo de fechas",
      funnels_list: "Lista de Embudos",
      msg_conversion_actions: "Mide conversaciones de una secuencia de acciones",
      funnel_msg_create: "Crea un embudo para obtener más información sobre la tasa de conversión y el tiempo de finalización de cualquier flujo específico de tu aplicación o sitio web.",
      funnel_name: "Nombre del embudo",
      events: "Eventos",
      create: "Crear",
      cancel: "Cancelar",
      event_list: "Lista de Eventos",
      msg_consult_actions: "Consulta las acciones que las personas realizan en tu producto.",
      event: "Evento",
      total_interactions: "Total interacciones",
      percentage: "Porcentaje",
      creation: "Creation",
      rows_filters: "Filas por página",
      general_report: "Reporte General",
      user: "Usuario",
      registers_not_found_dates: "Parece que no hay datos para este rango de fechas, selecciona otro.",
      statement: "Enunciado",
      operator_title: "Título identificador del operador",
      campaign_massive: "Campañas Masivas",
      campaign_look: "Ver Campañas",
      shipping_list: "Listas de Envío",
      save: "Guardar",
      refresh_template_email_list: "Recargar lista de plantillas Email",
      select_option: "Seleccione una opción",
      new_template: "Nueva Plantilla",
      email_template: "Plantilla de Email",
      configure_campaign: "Configure Campaign",
      campaign_type: "Tipo de Campaña",
      new_list: "Nueva Lista",
      refresh_contact_list: "Refrescar Lista de Contactos",
      message: "Mensaje",
      add_url: "Añadir URL",
      subject: "Asunto",
      stop_campaign: "Detener Campaña",
      new_campaign: "Nueva Campaña",
      msg_without_campaign: "AÚN NO TIENE CAMPAÑAS",
      msg_here_existing_campigns: "Aquí encontrarás las campañas existentes",
      upload_file: "Subir Archivo",
      optional: "Opcional",
      bot_edition: "Edición del Bot",
      info_edition: "Agrega, edita, duplica o elimina diferentes módulos dentro de tu bot, cada módulo le brinda diferentes funciones a tu bot",
      info_upload_file: "Configura el mensaje que enviar a tu bot para solicitar archivos adjuntos y el mensaje para cancelar la subida de archivos",
      info_webview: "Puedes enlazar tu bot con diferentes formularios que hayas creado en el menu lateral de webform",
      buttons_list: "Lista de Botones",
      info_buttons_list: "Agrega a tu bot listas de botones y a cada uno asignarle que redireccione a una url o que continue el flujo",
      carousel: "Carrusel",
      carousel_info: "Crea un carrusel de imagenes cargadas de diferentes URL y a cada una asignarle diferentes botones los cuales pueden redireccionar a una URL o avanzar en el flujo",
      form: "Formulario",
      form_info: "Añade a tu bot preguntas cortas de formulario para hacerle al usuario",
      quick_replies_info: "Asigna al bot respuestas predefinidas a la pregunta que realiza el usuario, en el output se puede asignar que la respuesta siga el flujo general del bot o que siga un flujo individual para esa respuesta",
      calendar: "Calendario",
      calendar_info: "La caja calendario es una caja que debe permitir a los usuarios del bot seleccionar una  fecha (con o sin hora) o rango de fechas dentro de un calendario",
      location: "Localización",
      location_info: "La caja localización es una caja que debe permitir a los usuarios del bot seleccionar una o varias ubicaciones",
      multimedia:"Multimedia",
      multimedia_info: "La caja multimedia es una caja que debe permitir a los usuarios del bot recibir archivos de imagen, audio, video o documento",
      report_info: "La caja reportes es una caja que debe permitir a los usuarios del bot crear reportes personalizados almacenando el valor de las variables seleccionadas",
      blockchain_info: "La caja blockchain es una caja que debe permitir a los usuarios del bot guardar en blockchain el valor de las variables seleccionadas",
      scanner: "Escaner",
      scanner_info: "La caja escaner qr es una caja que debe permitir a los usuarios del bot leer un código qr haciendo uso de la cámara",
      catalog: "Catálogo",
      catalog_info: "La caja catálogo es una caja que permite a los usuarios del bot hacer compras a través de un catálogo de productos",
      payments_online: "Pagos en Linea",
      payments_online_info: "La caja de pagos en línea es una caja que permite a los usuarios del bot hacer pagos en línea a través de diferentes pasarelas de pago",
      plane_text: "Texto Plano",
      quick_replies: "Respuesta Rápida",
      external_api: "Api Externa",
      conditional: "Condicional",
      switch_to_agent: "Paso Agente",
      custom_report: "Reporte Personalizado",
      unsubscribe: "Desuscripción",
      contacts: "Contactos",
      variable: 'Variable',
      edit_component_info: "Edite este componente y agregue un enunciado.",
      info_error_reload_flow: "¡ups! Tuvimos problemas para cargar el flujo que desea editar, lo volveré a cargar por usted.",
      edit_component_info2: "Edite este componente y agregue sus opciones.",
      edit_component_info3: "Edite este componente y agregue el bot y el mensaje de cancelación",
      web_view: "Vista Web",
      info_calendar: "Seleccione la configuración del calendario",
      scanner_qr_info: "Seleccione la configuración del escáner qr",
      catalog_config_info: "Seleccione la configuración del catálogo.",
      payment_method_info: "Seleccione la configuración del método de pago",
      location_config_info: "Seleccione la configuración del cuadro de ubicación",
      name_info_variable: "Nombre para longitud de usuario",
      name_info_variable_latitud: "Nombre para latitud de usuario",
      multimedia_config_info: "Por favor seleccione la configuración de la caja multimedia",
      report_config_info: "Seleccione la configuración del informe",
      service_not_found: "servicio no encontrado",
      blockchain_config_info: "Para usar el cuadro Blockchain, debe comprar el servicio y tener al menos un contrato inteligente creado.",
      blockchain_config_info2: "Seleccione la configuración de la cadena de bloques",
      soporte_info: "Póngase en contacto con soporte para contratar este servicio.",
      start_api: "Inicio Api",
      end_api: "Fin Api",
      switch_to_agent_config_info: "Edite este componente y agregue los mensajes del bot",
      conditional_config_info: "Edite este componente y agregue el bot y el mensaje de cancelación ",
      unsubscribe_config_info: "Edite este componente y agregue una declaración y un mensaje de despedida",
      email_config_info: "Edite este componente, agregue asunto, remitente, lista contada, etc.",
      trigger_config_info: "Edite este componente...",
      variable_config_info: "Edite este componente para configurar sus variables.",
      msg_confirm_delete: "¿Desea eliminar este operador de forma permanente?",
      msg_delete_nodo: "El nodo ha sido eliminado con éxito.",
      message_scheme: "Lo siento, según la configuración de la plataforma del bot, desconozco que permitan cajas de carrusel, quítalas para seguir guardando el flujo.",
      message_scheme2: "Lo siento, según la configuración de la plataforma del bot, no sé permiten cuadros de lista de botones, elimínelos para continuar guardando el flujo.",
      message_scheme3: "Lo siento, según la configuración de la plataforma del bot, desconozco permiten cajas de tipo imagen, quítalas para seguir guardando el flujo.",
      message_scheme4: "Lo siento, según la configuración de la plataforma del bot, desconozco permiten cajas multimedia, quítalas para seguir guardando el flujo.",
      message_scheme5: "Lo siento, según la configuración de la plataforma del bot, desconozco permiten casillas de tipo suscripción, quítalas para seguir guardando el flujo.",
      message_scheme6: "Recuerde marcar cualquier operador como 'Inicio' haciendo clic en la casita para que el script tenga un punto de inicio",
      message_scheme7: "Flujo guardado con éxito.",
      message_scheme8: "Hubo problemas para guardar el flujo, inténtalo de nuevo.",
      configure_event: "Configurar Evento",
      event_name: "Nombre del Evento",
      finish: "Guardar Cambios",
      without_text: 'Sin Texto',
      scanner_code: 'Escanear Código',
      flows: 'Flujos',
      settings: 'Ajustes',
      setting_module: 'Configurar modulo',
      questions_multiple_ask: 'Preguntas con multiples respuestas',
      id_servicio: 'Id Servicio',
      ia: 'Inteligencia Artificial',
      notifications: 'Notificaciones',
      switch_to_agent_var: 'Variable paso agente',
      switch_to_agent_msg: 'Seleccione la variable que contiene la información del paso agente a redirigir',
      switch_to_agent_msg2: 'Seleccione las variables que desea enviar al API paso agente',
      switch_to_agent_msg3: 'Configurar paso agente',
      variable_json_api:'Variable JSON API',
      basic: 'Básico',
      advanced: "Avanzado",
      welcome_msg: 'Mensaje de bienvenida',
      welcome_msg2: 'Mensaje de bienvenida al paso agente que se imprimirá en el bot',
      add_message: 'Debe agregar un mensaje',
      switch_to_agent_url: 'Url paso agente',
      switch_to_agent_msg4: 'Url que conecta con el API de paso agente',
      attention: 'Atención',
      message1: 'si no especifica una url se tomará la que se haya definido en la configuración del bot',
      message2: 'Escribe la url del paso a agente',
      other_vars: 'Otras variables',
      name_key: 'Nombre de la llave',
      value: 'Valor',
      name_var: "Nombre variable",
      url_api: 'URL de la API',
      will_be_consumed: 'que se consumirá',
      field_required: 'Este campo es requerido',
      validate_result: 'Validar resultado',
      message3: 'Enviar id de sesión y de usuario',
      send_method: 'Método de envío',
      message4: 'Método que se usará para consumir la API',
      shipping_coding: 'Codificación de envío',
      type_coding: 'Tipo de codificación en la que se enviarán los datoa a la API',
      txt_long_edit_component:"Edite este componente y agregue el mensaje del bot y formulario web",
      select: 'Seleccione',
      headers: 'Encabezados',
      action: 'Acción',
      actions: 'Acciones',
      add_headers: 'Agregar encabezado',
      data_sended: 'Datos de enviados',
      add_key: 'Agregar llave',
      data_received: 'Datos recibidos',
      close_windows: 'Ventana chat cerrado',
      user_writing: 'Usuario escribiendo',
      configure_bot: 'Configuración del Bot',
      save_changes: 'Guardar cambios',
      visual_appearance: 'Apariencia visual',
      bot_to_create: 'sobre el bot a crear',
      languages_msg:'Idiomas en los cuales estará habilitado del bot',
      background_header: 'Fondo encabezado',
      message5: 'puede ser icono o un texto de usuario no mayor a 10 caracteres',
      use_text: "Usar texto",
      use_icon: "Usar icono",
      message6: 'Habilita o deshabilita la entrada del texto del chat cuando el bot visualice alguna opción de seleccion con botones.',
      keep_web_history: 'Mantener historial del chat web',
      keep_web_history_info: 'Habilita o deshabilita el historial del chat. Si se habilita mostrará las conversaciones anteriores del usuario.',
      message7: 'Ubicación en el sitio web donde se visualizará el bot',
      enable_up_window_bot: 'Habilitar despliegue automático de la ventana del Bot.',
      description_field_text: 'Descripción del campo de texto en la caja del bot',
      timeout_type_first: 'Tipo de primer Timeout',
      timeout_type_second: 'Tipo de segundo Timeout',
      timeout_type_third: 'Tipo de tercer Timeout',
      timeout: 'Timeout',
      must_select_scheme: 'Debe seleccionar un esquema',
      must_select_operator: 'Debe seleccionar un operador',
      infor_select_operator:"Operador que se usará para comparar los datos y evaluar la codición",
      send_message: 'Enviar Mensaje',
      go_to: 'Ir a',
      add_contact_list: 'Agregar lista',
      this_list_have: 'Esta lista tiene',
      duplicate: 'repetido',
      register: 'registro',
      recharge_template: 'Recargar plantilla',
      size_files_max: 'Tamaño máximo permitido para los archivos a cargar en la plataforma',
      new_avatar: 'Nuevo avatar',
      use_avatar: 'Uso Avatar',
      message8: 'Enunciado que se imprimirá en el bot',
      message9: 'Variable visible al usuario',
      message10: 'Nombre de la varible donde se almacenará la información seleccionada',
      message11: 'Nombre donde se almacenará un JSON con la información seleccionada y la estructura del formato dependiendo del tipo de calendario seleccionado',
      type_calendar: 'Tipo de Calendario',
      type_calendar_config: 'Tipo de Calendario que se configurará',
      time_zone: 'Zona horaria',
      time_zone_config: 'Zona horaria que manejará el calendario',
      minimum_date: 'Fecha mínima',
      minimum_date_config: 'Fecha mínima que permitirá seleccionar el calendario',
      maximum_date: 'Fecha máxima',
      maximum_date_config: 'Fecha máxima que permitirá seleccionar el calendario',
      date_selected_default: 'Fecha seleccionada por defecto',
      date_selected_default2: 'Fecha que aparecerá seleccionada por defecto en el calendario',
      current_date: 'Fecha actual',
      carouse_info: 'Seleccione la variable que contiene la información del carrusel',
      var_carousel: 'Variable carrusel',
      max_20_chars: 'Máximo 20 caracteres',
      advance: 'Avanzar',
      new_tab: 'Pestaña',
      reload: 'Recargar',
      invalid: 'Invalida',
      type: 'Tipo',
      add_new: 'Agregar nuevo',
      set_up: 'Configurar',
      image: 'Imagen',
      load_data_from_var: 'Cargar datos desde Variable',
      carousel_info2: 'Si selecciona esta opción los datos del carrusel deberán ser cargados desde una variable',
      buttons: 'Botones',
      max_buttons_allowed: 'Máximo botones permitidos',
      catalog_type: 'Tipo de catálogo',
      catalog_type_info: 'Seleccione el tipo de catálogo que deseea usar',
      customer: 'Cliente',
      id_final_user: 'Ingrese el id que identificará al usuario final',
      message12: 'Variable para almacenar el id de la orden',
      message13: 'Nombre de la varible donde se almacenará el id de la orden generada',
      message14: 'Ya existe una variable con ese nombre en el bot, por favor utiliza otro nombre.',
      message15: 'Variable para almacenar la referencia de pago',
      message16: 'Nombre de la varible donde se almacenará la referencia para generar el pago de la orden generada',
      message17: 'Fuente de información de productos',
      message18: 'Seleccione la fuente de información de donde se traerán los productos a visualizar en el catálogo',
      message19: 'Variable con lista de productos',
      message20: 'Seleccione la variable que contiene la información de los productos a mostrar en el catálogo',
      stock_keys: 'Id almacén llaves',
      message21: 'Ingrese el id proporcionado por el equipo de desarrollo que almacena las llaves de acceso',
      message22: 'Etiquetas que se usarán como categorías',
      message23: 'Ingrese el nombre de todas las colecciones que desee usar como categorías en el catálogo',
      write_enter:"Escriba y presione enter.. ",
      limit_allowed: 'Limite permitido',
      numeric: 'Numérico',
      message_error:"Ingrese un mensaje de error",
      message_error_label:"Mensaje de error",
      extension_archivos_label:"Extensiones de archivos",
      extension_archivos_icon:"Enter the extension to allow ex: .png .pdf",
      text: 'Texto',
      phone: 'Teléfono',
      question: 'Pregunta',
      validation: 'Validación',
      allowed_box: 'Permitidas máximo cajas',
      add: "Agregar",
      type_location_msg: 'Tipo de localización que se utilizará',
      location_list: 'Lista de Ubicaciones',
      location_list_msg: 'Seleccione la lista de localizaciones que se utilizará',
      location_list_msg2: 'Por favor cree una lista de ubicaciones para poder ser asignada',
      add_var: 'Agregar Variable',
      columns_list: 'Lista de columnas',
      columns_list_msg: 'Seleccione la columna que utilizará como variable',
      name_var_msg: 'Nombre de la variable que guardará la información de la columna',
      latitud_var: 'Variable con latitud',
      latitud_var_info: 'Seleccione la variable que contiene la información de la latitud a graficar',
      message24: 'No hay variables creadas',
      longitud_var: 'Variable con longitud',
      longitud_var_info: 'Seleccione la variable que contiene la información de la longitud a graficar',
      icono_var: 'Variable con icono',
      message25: 'Seleccione la variable que contiene la imagen que se usará en el icono para poner la ubicaión en el mapa',
      message26: 'Digite la información que se mostrará al darle click al ícono de la ubicación graficada',
      message27: 'Ya existe una variable con ese nombre en el bot, por favor utiliza otro nombre.',
      configure_file: 'Configurar Archivo',
      message28: 'Cómo desea cargar el archivo multimedia',
      files_locals: 'Archivos locales',
      template: 'Plantilla',
      message29: 'Nombre de la varible donde se almacenará la url seleccionada',
      message30: 'Ya existe una variable con ese nombre en el bot, por favor utiliza otro nombre.',
      file: 'Archivo',
      no:"No",
      upload_file: 'Cargar Archivo',
      message31: 'Arrastra o haz click para seleccionar el archivo',
      var_url_file: 'Variable con la url del archivo',
      message32: 'Seleccione la variable que contiene la información de la url del archivo a cargar',
      select_template_for_file: 'Seleccione la plantilla para generar el archivo',
      template_use_for_file: 'Plantilla que se utilizará para generar el archivo',
      message33: 'No hay plantillas para ser asignadas',
      message34: 'Qué tipo de archivo desea crear',
      select_file_to_create: 'Seleccione el tipo de archivo que se creará',
      template_vars: 'Variables de la plantilla',
      create_vars_msg: 'Cree las variables que se utilizarán en la plantilla para generar el archivo',
      configure_payment_online: 'Configurar Pagos en Línea',
      payment_gateway: 'Pasarela de pagos',
      payment_type_select: 'Seleccione la pasarela de pago a utilizar',
      payment_var_code: 'Variable para almacenar el código de pago',
      payment_var_id: 'Nombre de la varible donde se almacenará el id de pago',
      payment_var_amount: 'Seleccione la variable que contiene el monto a pagar',
      payment_reference: 'Referencia de pago',
      payment_var_reference: 'Seleccione la variable que contiene el Id de la referencia de pago',
      payment_var_currency: 'Seleccione la moneda en la que se efectuará el pago',
      whatsapp_menu_interactive: 'Menú interactivo Whatsapp',
      message35: 'Si selecciona esta opción se mostrará un Menú interactivo en Whatsapp',
      statement_type: 'Tipo de enunciado ',
      configure_scanner: 'Configurar Escaner',
      scanner_type_code: 'Tipo de código a escanear',
      scanner_type_code_msg: 'Seleccione el tipo de código que desea que los usuarios escaneen',
      scanner_name_var_code: 'Nombre de la varible donde se almacenará la información escaneada del código qr',
      configure_plane_text: 'Configurar texto plano',
      statements: 'Enunciados',
      message36: 'El enunciado no puede estar vacío',
      sended_time: 'Tiempo de envio',
      immediate: 'Inmediato',
      scheduled: 'Programado',
      number: 'Número',
      days: 'Días',
      hours: 'Horas',
      phone_number_to_msg: 'Número celular a donde se enviará el mensaje de texto',
      sms_message_info: 'Escriba a continuación el mensaje a enviar. Máximo 160 carácteres.',
      configure_upload_file: 'Configurar subir archivo',
      text_button_cancel: 'Texto botón acción cancelar ',
      text_button_cancel_msg: 'Texto que aparecerá en el botón cancelar',
      add_name_var: 'Debe agregar el nombre de la variable',
      select_form_llink_up: 'Selecciona un formulario a vincular',
      message37: 'Seleccione la vista web que se mostrará en el bot',
      show_x_close_webview: 'Mostrar X para cerrar la vista web',
      valid_dimensions: 'Dimensiones Validas',
      valid_size: 'Tamaño Valido',
      drag_click_image: 'Arrastra o haz click para seleccionar la imagen',
      edit_flow: 'Editar Flujo',
      names: 'Nombres',
      last_name: 'Apellidos',
      confirm_pwd: 'Confirmar Contraseña',
      user_account_error:'El usuario o contraseña no son correctos por favor intente nuevamente',
      block_account: 'Tu cuenta ha sido bloqueada por seguridad debido a múltiples intentos fallidos de inicio de sesión. Por favor, espera 15 minutos antes de intentar nuevamente.',
      oldpassword: "Contraseña antigua",
      newpassword: "Nueva Contraseña",
      repeatpassword: "Repita la contraseña (de nuevo)",
      titleChangepass: "Contraseña y seguridad",
      textChangepass: "Cambio de contraseña: Proteja su cuenta. Elija una contraseña fuerte y única para garantizar su seguridad en línea. No comparta su contraseña y actualícela regularmente. ¡Mantenga su cuenta segura!",
      noteChangepass: "Nota: Si cambia la contraseña, finalizara la sesion actual.",
      try_again: 'Inténtalo de nuevo con una contraseña que no hayas usado antes',
      change_password_editar_60: ' Cambiar contraseña cada 90 días',
      change_password_60: ' Cambiar contraseña cada 60 días',
      use_var: 'usar variable',
      use_var_as: 'Usar como variable',
      define_name_varible:'Defina un nombre para poder usar el archivo como variable',
      intent: 'Intento',
      select_intent_operator: 'Selecciona el intento relacionado a este operador',
      ia_message: 'No hay intentos de ia configurados para ser asignados',
      inactivity: 'Inactividad',
      invalid_phone: 'Número invalido',
      timeout_message: 'Tiempo en inactividad (Segundos) que debe esperar el bot para disparar el mensaje predeterminado, el mínimo de tiempo permitido es de 1 segundo.',
      seconds: 'Segundos',
      timeout_message2: 'Mensaje a mostrar una vez se cumpla el tiempo de inactividad establecido',
      message39: 'Selecciona la caja a la que se re dirigira en el flujo previamente seleccionado',
      message38: 'A través de este titulo podrá identificar el operador y llegar a él a través de una caja go to',
      message40: 'Selecciona el flujo al que deseas re dirigirte',
      provider: 'Proveedor',
      phone_number: 'Número Telefónico',
      write_phone_number: 'Digite el número telefónico',
      message41: 'Selecciona el avatar relacionado a este operador',
      message42: 'Seleccione el nombre de la llaveapi/scheme/statement/',
      column_title: 'Título de la columna',
      message43: 'Este es el nombre que tendrá la columna que se almacenará en blockchain',
      message44: 'Este es el valor que tendrá la columna que se almacenará en blockchain',
      message45: 'Configurar almacenamiento blockchain',
      message46: 'Seleccione el proveedor de servicio de Blockchain',
      contract: 'Contrato',
      message47: 'Seleccione el contrato con el que se almacenará la información',
      message48: 'AÚN NO TIENES CONTRATOS BLOCKCHAIN',
      message49: 'Si tiene un contrato blockchain podrá almacenar de forma segura e incambiable su información',
      message50: 'Para poder acceder a esta funcionalidad debe contratar el servicio de blockchain.',
      select_var: "Seleccione una variable",
      compare_value: "Valor a comparar",
      select_var_compare: 'Seleccione la variable que desea comparar',
      verify_list_fields: 'Por favor verifique que todos los campos estén llenos.',
      configure_conditional: 'Configurar condicional',
      write_subject: 'Escriba el asunto',
      select_template: 'Selecione plantilla',
      reload_template_email_list: 'Recargar lista de plantillas Email',
      email_receiver: 'Correo destinatario',
      email_cc: 'Correos en copia',
      value_var: 'Valor variable',
      events_message: 'Seleccione los eventos que desee utilizar',
      delivered: 'Entregado',
      message51: 'Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto.',
      open: 'Abierto',
      refused: 'Rechazado',
      links_event: 'Links del evento Click',
      maximum: 'Máximo',
      add_link: 'Agregar link',
      metric: 'Métrica',
      select_color: 'Seleccione una escala de color',
      without_metrics: 'AÚN NO TIENES MÉTRICAS',
      metrics_message: 'Para crear una gráfica es necesario haber creado por lo menos una métrica',
      create_metric: 'Crear Métrica',
      update_info: 'Actualizar información',
      create_new_dashboard: 'Crear nuevo tablero de control',
      description_dashboard: 'Descripción del tablero de control',
      dashboard: "Tablero de control ",
      select_graphics: 'Seleccione las gráficas',
      message52: 'AÚN NO TIENES GRÁFICAS',
      message53: 'Para crear un tablero de control es necesario haber creado por lo menos una gráfica',
      create_graphic: 'Crear gráfica',
      validate_message: "La contraseña debe tener al menos 10 caracteres.",
      validate_message2: "La contraseña debe tener un mínimo 10 caracteres y al menos una letra minúscula, una letra mayúscula, un dígito y un carácter especial.",
      validate_message3: "El nombre de usuario debe tener al menos 10 caracteres.",
      validate_message4: "El nombre de usuario debe contener solo letras y números.",
      validate_message5: "El nombre de usuario no puede contener espacios.",
      validate_message6: "Formato de correo no valido",
      validate_message7: "Seleccione un rol.",
      rol:"Rol",
      validate_message8: "Está seguro de eliminar este usuario?",
      admin_users: 'Administración de usuarios',
      admin_user_message: 'AÚN NO TIENE USUARIOS',
      admin_user_message2: 'Aquí encontrarás los usuarios existentes de la empresa',
      admin_user_message3: 'Crea un usuario para poder utilizar la plataforma.',
      create_user: 'Crear Usuario',
      new_password: 'Nueva Contraseña',
      admin: 'Administrador',
      campaigns: 'Campañas',
      edit_user: 'Editar Usuario',
      message54: 'Es dinámico',
      enable_dinamic_operator: 'Habilita operador dinámico',
      upload_image2: 'Subir Imagen',
      create_operator_msg: '¡Operador creado satisfactoriamente!',
      operator_created_msg: '¡Error al crear el operador, intente nuevamente!',
      operator_edited_msg: 'Operador editado correctamente',
      operator_edited_msg2: '¡Error al actualizar el operador, intente nuevamente!',
      operator_delete_msg: 'Operador eliminado correctamente',
      operator_delete_msg2: '¡Error al eliminar el operador, intente nuevamente!',
      operator_delete_msg3: "¡Error al eliminar el operador dinámico, intente nuevamente!",
      statement_create_msg: '¡Error al crear el Statement, intente nuevamente!',
      statement_edit_msg: '¡Error al actualizar el Statement, intente nuevamente!',
      operator_list_msg: '¡Error al obtener la lista de operadores, intente nuevamente!',
      statements_list_msg: '¡ No se pudo obtener la lista de variables!',
      statements_list_msg2: '¡ Error, al obtener la configuración del variables!',
      statements_list_msg3: '¡ Error, al obtener la lista de compañías!',
      whatsapp_message: '¡El mensaje ws fue enviado!',
      whatsapp_message2: '¡Error al crear el mensaje a ws, intente nuevamente!',
      message55: '¡ No se pudo obtener la lista de compañías!',
      message56: '¡ No se pudo obtener la lista de tipos de calendario!',
      message57: '¡ Error, al obtener la lista de tipos de calendario!',
      location_msg: '¡ No se pudo obtener la lista de tipos de localización!',
      location_msg2: '¡ Error, al obtener la lista de tipos de localización! ',
      location_msg3: '¡ No se pudo obtener la lista de localizaciones!',
      location_msg4: '¡ Error, al obtener la lista de localizaciones!',
      blockchain_msg: '¡ No se pudo obtener la lista de proveedores de blockchain!',
      blockchain_msg2: '¡ Error, al obtener la lista de proveedores de blockchain!',
      blockchain_msg3: '¡No se pudo obtener la lista de contratos de blockchain!',
      blockchain_msg4: '¡ Error, al obtener la lista de contratos de blockchain!',
      message58: 'Enunciado que le aparecerá al usuario en el bot',
      message59: 'Enunciado previo al envio del link para que el usuario se desuscriba',
      welcome_msg: 'Mensaje de despedida',
      message60: '¡Se ha presentado un error!',
      form_msg: "Formulario guardado correctamente",
      form_msg2: "Lo sentimos no se pudo guardar el formulario",
      message189: 'Debe guardar antes de editar.',
      message61: 'No pudo actualizar el ítem, inténtelo de nuevo',
      message62: 'Excede el tamaño permitido',
      message63: 'Imagen subida correctamente.',
      config_msg: "Configuración guardada correctamente.",
      config_msg2: " No se pudo actualizar la configuracion",
      warning_msg: "El número telefónico digitado no es un número válido. Por favor verifíquelo.",
      message64: "Las dimensiones de la imagen no son las correctas",
      message65: "Desea eliminar este avatar permanentemente",
      avatar_msg: "No se puede eliminar este avatar, esta siendo usado por un bot actualmente.",
      message66: 'Archivo subido correctamente.!',
      message67: "El Keyword digitado NO es válido. Por favor verifíquelo.",
      message68: "El TIMEOUT digitado NO es válido. Por favor verifíquelo.",
      message69: 'Ocurrió un error al cargar el archivo',
      message70: 'Error al obtener las plantillas.',
      message71: 'Plantilla eliminada correctamente',
      message72: 'Error al borrar la plantilla, verifique que no esté en uso por uno o más bots.',
      message71: 'Plantilla guardada con éxito.',
      message72: 'Error al crear la plantilla.',
      message73: 'Plantilla actualizada con éxito.',
      message74: 'Error al actualizar la plantilla.',
      message75: 'Error al obtener la plantilla.',
      message76: 'Error al obtener las variables de la plantilla.',
      message76: '¡La métrica fue eliminada correctamente!',
      message77: 'Desea eliminar este metrica permanentemente',
      message78: '¡Error al borrar la métrica!',
      message79: "Ocurrió un error!",
      message80: "¡No se encontraron gráficas!",
      message81: "¡La lista de gráficas no pudo ser obtenida!",
      message82: "¡Error al crear la gráfica, debe seleccionar un tipo de gráfica y una escala de color!",
      message83: "¡Nueva gráfica creada exitosamente!",
      message84: "¡Error al crear la gráfica, intente nuevamente!",
      message85: "¡La gráfica fue actualizada exitosamente!",
      message86: "¡Error al actualizar la gráfica!",
      message87: " ¿ Desea eliminar esta gráfica permanentemente ? ",
      message88: "¡La gráfica fue eliminada correctamente!",
      message89: "¡Error al borrar la gráfica!",
      message90: "¡La lista de Dashboards no pudo ser obtenida!",
      message91: "¡Error al crear el dashboard, intente nuevamente",
      message92: "¡El dashboard fue actualizado exitosamente!",
      message93: "¡Error al actualizar el dashboard!",
      message94: " ¿ Desea eliminar el dashboard permanentemente ? ",
      message95: "¡EL Dashboard fue eliminado correctamente!",
      message96: "¡Error al borrar el dashboard!",
      message97: "¡No se encontraron dashboards!",
      message98: "¡La información del dashboards no pudo ser obtenida!",
      message99: "¡ Error, no se pudo obtener el número de unidades!",
      message100: "¡ Error, al listar el total de unidades!",
      message101: "¡No se encontraron Unidades!",
      message102: "¡La lista de unidades no pudo ser obtenida!",
      message103: "¡No se encontraron Dimensiones!",
      message104: "¡La lista de dimenionsiones no pudo ser obtenida!",
      message105: "¡No se encontraron tipos de gráficos!",
      message106: "¡La lista de tipos de gráficos no pudo ser obtenida!",
      message107: "¡El tipo de gráfico no pudo ser obtenido!",
      message108: "¡ No se pudo obtener la lista de canales !",
      message109: "¡ Error, al obtener la lista de canales!",
      message110: "¡ No se pudo obtener la lista de paletas de color !",
      message111: "¡ Error, al obtener la lista de paletas de color!",
      message112: "¡No se encontraron metricas!",
      message113: "¡La lista de metricas with parent no pudo ser obtenida!",
      message114: "¡La lista de metricas no pudo ser obtenida!",
      message115: "¡La metrica no pudo ser obtenida!",
      message116: "¡Nueva métrica creada exitosamente!",
      message117: "¡Error al crear la metrica, intente nuevamente!",
      message118: "¡La métrica fue actualizada exitosamente!",
      message119: "¡Error al actualizar la métrica!",
      message120: "¡Nuevo dashboard creado exitosamente!",
      message121: "¡La cantidad de medidas no pudo ser obtenida!",
      message122: "¡Debe seleccionar una fecha inicial, intente nuevamente!",
      message123: "¡Debe seleccionar una fecha final, intente nuevamente!",
      message124: "¡Tocken exitoso !",
      message125: "¡Error, al obtener el tocken!",
      message126: "Debe agregar dos fechas para poder filtrar, de lo contrario se procederá a filtrar por la fecha de hoy",
      message127: "Se ha enviado una copia del reporte del ",
      message128: 'Se ha excedido el tiempo límite de espera para generar la consulta. <br /> <br /><span style="font-weight:bold;font-style:italic">Por favor seleccione un rango de fechas más corto. </span>',
      message129: "¡ No se pudo obtener la lista de idiomas!",
      message130: "¡ Error, al obtener la lista de idiomas!",
      message131: "Ocurrió un error al obtener el reporte de interacciones del ",
      message132: "Tiempo límite excedido para generar el reporte del ",
      message133: " Por favor seleccione un rango de fechas más corto",
      message134: "¡ No se pudo obtener la lista de compañías!",
      message135: "¡ Error, al obtener la lista de compañías!",
      message136: 'Compañía modificada correctamente',
      message137: 'Error al eliminar compañia',
      message138: 'Compañia Creado correctamente',
      message139: 'Error al crear compañia',
      message140: 'Compañia editada correctamente',
      message141: 'Error al editar compañia',
      message142: 'Usuario creado correctamente',
      message143: 'Error al crear usuario',
      message144: 'Error al consultar usuarios',
      message145: 'Usuario Editado correctamente',
      message146: 'Su contraseña ha sido cambiada exitosamente. Por favor, vuelva a iniciar sesión para continuar.',
      message147: 'Error al editar usuario',
      message148: 'Usuario eliminado correctamente',
      message149: 'Lo sentimos no es posible realizar esta acción.',
      message150: "Reporte de Correos electrónicos!",
      message151: "Error al generar el reporte!",
      message152: "Ocurrió un error al obtener el reporte de correos electrónicos del ",
      message153: "Ocurrió un error al obtener el reporte personalizado del ",
      message154: "Ocurrió un error al obtener el reporte de clientes del ",
      message155: "El bot seleccionado no tiene Reportes personalizados!!",
      message156: "¡Error al obtener la lista de reportes personalizados!",
      message157: "Ocurrió un error al obtener el reporte de SMS del ",
      message158: "Ocurrió un error al obtener el reporte de blockchain del ",
      message159: "Reporte Blockchain!",
      message187: "Base de datos sobre escrita correctamente",
      message188: "Base de datos creada correctamente",
      message190: "Ups, ocurrió un error. NO se pudo enlazar la IA. Por favor verifique los datos ingresados",
      instagram_token: "Token de instagram",
      group: "Grupo",
      groups: "Grupos",
      new_groups: "Nuevo grupo",
      groups_msg: "Configura cómo va a responder tu bot a las preguntas de lo usuarios. Puedes definir un grupo, sus preguntas y sus respuestas o también puedes agregarle sinónimos de preguntas. Entre más preguntas agregues, mejor será el bot.",
      options: "Opciones",
      module_qna: 'Módulo de QnA',
      link_bot: 'Enlace del bot',
      config_file: 'Archivo de configuración',
      config_edit: 'Cambiar Ajustes',
      ia_message2: "Ingresa el id del dialogflow",
      new_module: "Nuevo módulo",
      module_name: "Nombre módulo",
      question_user: "Si el usuario pregunta",
      question_write: "Escribir pregunta",
      bot_can_response: "El bot puede responder",
      write_response: "Escribir respuesta",
      delete_module: "Eliminar Módulo",
      ia_multiple_response: "Preguntas con múltiples respuestas/ Cantidad Total",
      ia_message3: "No se encontraron preguntas sin respuestas asociadas",
      ia_message4: "Aquí encontrarás la listas de preguntas sin respuestas asociadas",
      ia_message5: "Con esta lista podrás entrenar la Inteligencia Artificial del Bot.",
      ia_message6: "Preguntas sin respuesta asociada / Cantidad Total",
      ia_message7: "No se encontraron preguntas con más de una respuesta",
      ia_message8: "Aquí encontrarás la listas de preguntas con más de una respuesta",
      click_here: 'Clic Aqui',
      testing_bot: 'Probar bot',
      message160: "¡La lista de chats no pudo ser obtenida! ",
      create_company: 'Crear Empresa',
      create_database: 'Crear Base de datos',
      sobreescribir_database: 'Sobrescribir base de datos',
      company_admin_msg: 'AÚN NO TIENE EMPRESAS',
      company_admin_msg2: 'Aquí encontrarás las empresas existentes',
      company_admin_msg3: 'Crea una empresa para poder crearle sus bots.',
      company_name: "Nombre de la empresa",
      company_administrator: "Nombre del administrador",
      company_admin_msg4: "Nombre de Usuario para iniciar sesión",
      home_actions_msg: '¡ Error, no se pudo obtener el número de bots!',
      home_actions_msg2: '¡ Error, al listar el total de bots!',
      home_actions_msg3: '¡No se encontraron bots!',
      home_actions_msg4: '¡ No se pudo obtener la lista de canales !',
      home_actions_msg5: '¡ Error, al obtener la lista de canales !',
      home_actions_msg6: '¡Nuevo bot creado exitosamente!',
      home_actions_msg7: '¡Error al crear el bot, intente nuevamente!',
      home_actions_msg8: " ¿ Desea eliminar este bot permanentemente ? ",
      home_actions_msg9: '¡Error al borrar el bot!',
      create_new_group: 'Crear nuevo Grupo',
      create_new:'Nueva Variable',
      name_group: "Nombre del grupo",
      question: "Pregunta",
      answer: "Respuesta",
      add_synonym: "Agregar un sinónimo",
      list_synonym: "lista de sinónimos",
      edit_question: "Editar Pregunta",
      qua_title_view: "Preguntas y respuestas de QnA",
      questions_answers: "Preguntas y respuestas",
      train: "Entrenar",
      write_question:"Escribe una pregunta",
      message161: "El bot debe responder",
      example_qna: "Ejemplo: Me llamo Marvin",
      example_qna2: "Ejemplo: Cómo te llamas",
      message162: "Si preguntan",
      message163: "El bot responde",
      message164: "Otra forma de preguntar",
      synonym: "sinónimo",
      edit_synonym: "Editar sinónimo",
      train_bot: "Entrenar Bot",
      message165: "Configura cómo va a responder tu bot a las preguntas de lo usuarios. Puedes definir una pregunta específica y su respuesta o también puedes agregarle sinónimos de preguntas. Entre más preguntas agregues, mejor será el bot.",
      message166: "Escribe una pregunta que deba responder el bot",
      message167: "Escribe lo que el bot debe responder",
      add_question: "Agregar Pregunta",
      message168: "Los cambios no almacenados seran eliminados",
      message169: 'Debe ingresar el nombre del módulo',
      message170: "Por favor digite el nombre de la vista web",
      funnel_create: "Crear embudo",
      funnel: 'Embudo',
      message171: 'Debe agregar un evento para continuar.',
      message171: 'Debe existir al menos un evento para continuar.',
      message172: "Desea eliminar este embudo permanentemente?",
      message173: "Aquí encontrarás las gráficas que pueden ser usadas en un dashboard",
      message174: "Crea una gráfica para poder analizar la información de cualquier flujo de tu aplicación y su funcionamiento.",
      message175: 'AÚN NO TIENES DASHBOARDS',
      message176: 'Aquí encontrarás los dashboard existentes',
      message177: 'Crea un dashboard para poder analizar la información de cualquier flujo de tu aplicación y su funcionamiento.',
      metric_admin: 'Administración de Medidas/Métricas',
      message178: 'Aquí encontrarás las medidas que pueden ser graficadas en un dashboard',
      message179: 'Crea una medida para poder analizar la información de cualquier flujo de tu aplicación y su funcionamiento.',
      next_page: "Sig. pág.",
      previous_page: "Prev pág.",
      rows_for_page: "Filas por página",
      all: "Todos",
      filters: "Filtros",
      clear: "Limpiar",
      see_columns_msg: "Mostrar/Ocultar Columnas de la tabla",
      row_selected: "fila(s) seleccionada",
      row_selected_delete: "Eliminar filas seleccionadas",
      phrase: 'Frase',
      quantity: 'Cantidad',
      web_view_module: 'Módulo de Vista Web',
      web_view_create: 'Crear Vista Web',
      connect_web_view: 'Enlazar un vista web',
      web_view_name: 'Nombre vista web',
      web_view_example: 'Escribe la url del sitio ej:',
      operator: 'operador',
      operator_type: 'Tipo de operador',
      config_cms: 'Configuración CMS',
      config_cms_msg: 'AÚN NO TIENE CONFIGURACIONES CMS CREADAS',
      config_cms_msg2: 'Aquí encontrarás la listas de configuraciones CMS del bot',
      config_cms_msg3: 'Crea una nueva configuracón CMS para ser listada.',
      config_operator: 'Configurar Operador',
      tarjects: 'Tarjetas',
      statements: 'Enunciados',
      max_item_lose: 'Máximo de items permitido',
      add_image: 'Agregar imagen',
      url_image: 'URL de la Imagen',
      upload_image_msg: 'Se recomienda que la imagen tenga una relacion 2:1 (Rectangular) y el tamaño de subida no sea mayor a 800 KB',
      channels: 'Canales',
      status: 'Estado',
      create_new_notification: 'Crear Nueva Notificación',
      notifications_msg: 'AÚN NO TIENE NOTIFICACIONES',
      notifications_msg2: 'Aquí encontrarás las notificaciones existentes del bot',
      notifications_msg3: 'Crea una notificación nueva.',
      notifications_config: 'Configurar notificación',
      notification_edit: 'Editar notificación',
      create_new_contact_list: 'Crear Nueva Lista de envíos',
      contact_list_msg: 'AÚN NO TIENE LISTAS DE ENVÍO CREADAS',
      contact_list_msg2: 'Aquí encontrarás las listas de envío existentes de la empresa',
      contact_list_msg3: 'Crea una nueva lista de envío.',
      template_email_msg :'Selecciona una lista de envió para visualizar las variables disponibles y así poder personalizar las plantillas email para tus clientes, sólo debes copiar y pegar la variable en la parte que prefieras.',
      send_list: 'Lista de Envio',
      save_template: 'Guardar plantilla',
      edit_template: 'Editar plantilla',
      save_edition: 'Guardar edición',
      template_title: 'Título plantilla',
      create_new_template: 'Crear Nueva Plantilla',
      templates_email: 'Plantillas de Email',
      without_templates: 'AÚN NO TIENE PLANTILLAS',
      template_email_msg2: 'Aquí encontrarás las plantillas existentes de la empresa',
      contact_name: "Indique el nombre del contacto",
      contact_phone: "El número de contacto que intenta agregar ya existe.",
      contact_filter_msg: 'Debe agregar dos fechas para poder filtrar, de lo contrario se procederá a filtrar por la fecha de hoy',
      custom_report_msg: 'Este es el nombre que tendrá la columna del reporte',
      custom_report_msg2: 'Este es el valor que tendrá la columna del reporte',
      custom_report_msg3: 'Configurar reporte personalizado',
      add_links_event: '¡ Debes agregar links al evento click o eliminar el evento  !',
      add_link_msg: '¡ Ya has agregado 5 links !',
      add_link_msg2: '¡ Ya has agregado ese link !',
      select_link: ' Selecciona un link ',
      complete_info_add_var: 'Completa la información para agregar la variable',
      config_vars: 'Configurar variables',
      list_var: 'Listado de variables',
      report_list: 'Reporte Listo!',
      report_generate_msg: 'Se ha enviado una copia del reporte del ',
      report_generate_msg2: 'Se ha excedido el tiempo límite de espera para generar el reporte del ',
      report_generate_msg3: ' El reporte se seguirá generando y se enviará una copia al correo electrónico ',
      report_generate_msg4: 'Por favor seleccione un rango de fechas más corto.',
      time_limit: 'Tiempo Límite Excedido!!',
      new_users: "Nuevos usuarios",
      user_activate: "Usuarios activos",
      message180: "Desea eliminar esta lista permanentemente?",
      message181: '¡La lista no se puedo borrar, porque tiene una campaña asociada!',
      contact_edit_msg: "Contacto editado exitosamente!",
      data_update_msg: "Datos actualizados con éxito.",
      message182: 'La lista que intentas validar no tiene columnas de correo o email',
      bots_associate: 'Bots asociados',
      message183: 'Esta lista tiene registros repetidos',
      available: 'Disponible',
      edit_send_list: 'Editar lista de envío',
      config_list: 'Configurar lista',
      user_identity: 'Identificador usuario',
      contacts_upload: 'Cargar contactos',
      view_data: 'Ver datos',
      validate_email: 'Validar correo',
      verify_domain: 'Verificar dominio',
      validate: 'Validar',
      list: 'list',
      edit_contact:'Editar contacto',
      download: 'Descargar',
      Sobreescribir:'Sobreescribir',
      Visualizacion:'Visualización',
      Visualizacion_logs:'Visualización logs',
      create_consult: 'Crear Consulta',
      test_consult: 'Probar Consulta',
      message184: 'Corresponden a los parametros que se utilizarán para agrupar la información',
      dimensions_metric: "Escriba las dimensiones de la medida",
      measurement: 'Medida',
      write_form_measurement: "Escriba la fórmula de la medida",
      metric_message2: "Escriba la condición para filtrar la medida",
      fields: 'Campos',
      functions: 'Funciones',
      operators: 'Operadores',
      consult: "Consulta",
      update: 'Actualizar',
      create_new_metric: "Crear nueva Medida / Métrica",
      metric_name: "Nombre de la medida/métrica",
      short_name: 'Nombre corto',
      short_name_metric: "Nombre corto de la medida/métrica",
      description_metric: "Descripción de la medida/métrica",
      operational: 'Operacional',
      report_interactions_msg: 'No se encontraron Reportes de interacción',
      report_interactions_msg2: 'Aquí encontrarás la listas de Reportes de interacción',
      see_more: 'Ver más',
      edit_company: 'Editar Empresa',
      company_message: 'No hay compañías creadas',
      blockchain_report: 'Reportes Blockchain',
      blockchain_report_msg: 'AÚN NO TIENES REPORTES BLOCKCHAIN',
      blockchain_report_msg2: 'Incluye una caja blockchain en el flujo con los valores que deseas almacenar para generar el reporte de blockchain',
      report_customer: 'Reporte de Clientes',
      management: 'Administración',
      custom_reports: 'Reportes Personalizados',
      database_admin_msg: 'AÚN NO TIENE BASES DE DATOS',
      database_admin_msg2: 'Aquí encontrarás las bases de datos existentes',
      database_admin_msg3: 'Crea una base de datos para utilizarla en tus bots.',
      generate:'Generar',
      shorten_url:'Recortar URL',
      tooltipKeyword:'Palabra clave para llegar a este operador',
      invalidKeyword:'Palabra Clave No Válida, debe estar en letras mayúsculas, sin espacios ni caracteres especiales.',
      keyword:'Palabra Clave',
      audio:'Audio',
      tooltip_audio:'Adjuntar archivo de audio al operador',
      msg_file:'El archivo supera el máximo permitido',
      config_api: 'Configurar API',
      config_calendar: 'Configurar Calendario',
      config_catalog: 'Configurar Catálogo',
      config_webview: 'Configurar Vista Web',
      config_quickreply: 'Configurar Respuesta Rápida',
      config_sender: 'Configurar Remitente',
      config_image: 'Configurar Imagen',
      config_desuscription: 'Configurar Desuscripción',
      msg_error_limit:'Limite permitido 2000 caracteres',
      msg_error_variable:'No hay variables para ser asignadas',
      msg_error_empty:'No se permiten items vacios',
      used_variable:'para usar variable',
      variable_multimedia:'variable multimedia',
      url_nueva:'nueva url',
      sort: "Ordenar",
      for:"por",
      of:"de",
      creation:"Creación",
      change_password:"Cambie la contraseña ahora",
      change_password_error:"Tu nueva contraseña no es igual a 'Repita la contraseña (de nuevo)'. Por favor, escríbela correctamente.",
      change_password_ok:"Tu contraseña se cambió correctamente.",
      change_password_no:"Autenticación fallida. Usuario o contraseña incorrectos.",
      delete_contact:"Eliminar contacto",
      delete_question:"No se pudo eliminar la pregunta",
      delete_schema:"¿Desea eliminar este esquema permanentemente?",
      error_selected_bot:"No se ha seleccionado un bot",
      bot_id:"Bot id",
      bot_version:"Bot version",
      box:"Cajas",
      component:"Componente",
      time_dimention:"dimensión de tiempo",
      with:"Con",
      without:"Sin",
      scala:"Escala",
      close:"Cerrar",
      measures_tree:"Árbol de medidas",
      default_title:"Título por defecto",
      verify_email:"Verificar Correo",
      complete_info:"Por favor, completa todos los campos",
      user_variable:"Variables de usuario",
      user_variable_msg:"Para crear una nueva variable, escriba el nombre y presione enter.",
      validate_number_text:"¡Sólo permite letras o numero!",
      enter_create:"Enter para crear ",
      code:"Código de inserción",
      copy_code:"Copiar código",
      name_new_variable:"Escribe el nombre de la nueva variable.",
      corner_lower_right: "Esquina inferior derecha",
      border_lower_center:"Borde inferior Centro", 
      corner_up_right: "Esquina superior derecha",
      corner_up_left: "Esquina superior izquierda",
      follow_asking:"Seguir preguntando",
      title_add_register:'¿Confirmas si deseas agregar nuevos registros?',
      add_register:'¿Estás seguro de que deseas Agregar nuevos registros?',
      title_delete_register:'¿Confirmas si deseas eliminar registros actuales y agregar nuevos?',
      delete_register:'¿Estás seguro de que deseas Eliminar registros actuales y agregar nuevos?',
      confirm: 'Confirmar',
      file_encripted:'¿Se almacenaran los archivos encriptados?',
      db_generate_msg: ' La base de datos se seguirá generando y se enviará una mensaje al correo electrónico cuando se encuentre lista ',
      },
  },
  en: {
    translation: {
      warning5:"Send button text can be icon or user text no longer than 10 characters",
      warning6:"Enable or disable the entry of chat text when the bot displays a selection option with buttons.",
      warning7:"Location on the website where the bot will be displayed",
      sidebarColor:"Select the color of the sidebar",
      faviconCompany:"Select the company favicon",
      navbarColor:"Select the color of the top navigation bar",
      logoCompany:"Select the company logo",
      sign_in: "Sign in",
      password: "Password",
      email: "Email",
      next: "Next",
      username: "Username",
      otp_token: "Please enter the authentication token that was sent to ",
      otp_token_verify:". If the token is verified, you can continue",
      init_session: "Login",
      init_session_btn: "Log in",
      edit_profile: "Edit Profile",
      close_session: "Close Session",
      new_bot: "New Bot",
      search: "Search",
      modified_in: "Modified in",
      created_in: "Created in",
      close_in: "Close in",
      test: "Test",
      edit: "Edit",
      export: "Export",
      duplicatedBot: "Duplicate Bot",
      import: "Import",
      create_bot:'Create Bot',
      import_bot:'Import Bot',
      duplicatedShema: "Duplicate Schema",
      create_schema:'Create Schema',
      import_schema:'Import Schema',
      delete: "Delete",
      company: "Company",
      select_coleccion: "Select the collection",
      base_de_datos_sobrescribir:"Database to overwrite",
      base_de_datos_add_new_register: "Add new records",
      base_de_datos_delete_register: "Delete current records and add new ones",
      Eliminar: "Delete",
      name_coleccion: "Name collection",
      nueva_coleccion: "New collection",
      admin_companies: "Companies Administration",
      admin_database:   "Data bases",
      group_for: "Group For",
      start_date: "Start Date",
      end_date: "End Date",
      send_date: "Send Date",
      filter: "Filter",
      year: "Year",
      month: "Month",
      day: "Day",
      week: "Week",
      hour: "Hour",
      consolidate: "Consolidate",
      print: "Print",
      see_columns: "See Columns",
      filter_table: "Filter Table",
      table: "Table",
      reports: "reports",
      report: "Report",
      report_interactions: "Interactions Report",
      report_personalized: "Personalized Report",
      report_blockchain: "Blockchain Report",
      blockchain: "Blockchain",
      report_email: "Email Report",
      report_sms: "SMS Report",
      admin_metrics: "Metrics Management",
      admin_graphics: "Graphics Management",
      admin_dashboard: "Dashboard Management",
      first_1000_records: "The first 10,000 records will be listed, to access the complete information please download the xlsx data file.",
      without_personalized_report: "YOU DO NOT HAVE PERSONALIZED REPORTS YET",
      hear_found_personalized_report: "Here you will find the custom reports of the bot",
      in_report_box_values: "Include a report box in the flow with the values ​​you want to store to generate the custom report",
      email_reports: "Email Reports",
      registers_not_founded: "Registers not founded",
      name: "Name",
      name_short: "Nombre Short",
      description: "Description",
      unit: "Unit",
      indicator_type: "Indicator Type",
      metric_type: "Metric Type",
      edition_option: "Options/Edit",
      general_information: "General Information",
      minutes: "Minutes",
      minute: "Minut",
      functional: "Functional",
      dimensions: "Dimensions",
      dimension: "Dimension",
      graphic_type: "Graphic Type",
      info_graphic: "Graphic Information",
      previews: "Previews",
      info_data_last_month: "Info Data last Month",
      metric: "Metric",
      select_graphics_type: "Select graphics type",
      info_dashboard: "Information dashboard",
      dashboard_title: "Title Dashboard",
      channel: "Channel",
      date: "Date",
      registers_not_founded: "Registers not founded",
      title: "Title",
      rename: "Rename",
      flow: "Flow",
      new_flow: "Create new flow",
      start_flow: "Flujo Inicial",
      anonymous_flow: "Anonymous Flow",
      anonymous_flow_deactivate: "Deactivate Anonymous Flow",
      project_name: "Project Name",
      bot_name: "Bot name",
      language: "Language",
      spanish: "Spanish",
      platform: "Platform",
      primary_platform: "Primary Platform",
      web: "Web",
      add_platforms: "Adds Platforms",
      font_size: "Font Size",
      header_background: "Header Background",
      user_balloon_background: "User balloon background",
      bot_balloon_background: "bot balloon background",
      background_chat: "Background chat",
      background_buttons: "Background buttons",
      border_color_buttons: "Border color buttons",
      color_text_balloon_user: "Color text balloon user",
      color_text_balloon_bot: "Color text balloon bot",
      color_text_buttons: "Color text buttons",
      color_text_send_button: "Color text send button",
      color_link_balloon_user: "Color links balloon user",
      color_link_balloon_bot: "Color links balloon bot",
      background_button_send: "Background button send",
      background_chat_close: "Background chat close",
      color_chat_close: "Color chat close",
      text_button_send: "Text button send",
      use_icon_text: "Use icon text",
      in_text_chat: "In text Chat",
      enable: "Enable",
      disable: "Disable",
      locate_bot: "Locate bot",
      bot: "Bot",
      corner_lower_left: "Corner lower left",
      up_bot_start: "Up bot to start ",
      up_bot_start_movil: "Deploy bot on mobile launch ",
      yes: "Yes",
      avatar_animation: "Avatar Animation",
      nothing: "Nothing",
      placeholder_field_text: "text placeholder chat text field",
      write_message: "Write message",
      msg_button_back_ia: "Message button back IA",
      back: "Back",
      msg_button_continue_ia: "Message button continue IA",
      msg_return: "Message to return",
      return: "return",
      continue:"Continue",
      word_return_queue_pa: "Word to leave the agent step queue leave",
      firts_timeout: "Firts Timeout",
      second_timeout: "Second Timeout",
      third_timeout: "Third Timeout",
      timeout:'Timeout',
      firts_msg_timeout: "Firts message timeout",
      second_msg_timeout: "Secod message timeout",
      third_msg_timeout: "Third message timeout",
      test_phone_number: "Test Phone Number",
      contact_list: "Contact List",
      phone_celnumber: "Celphone Number",
      select_one: "Select One",
      platforms: "Platforms",
      url_chat_pa: "Url chat agent",
      msg_not_connect: "Message agent not connect",
      template_email: "Email Template",
      avanced_chat: "Avanced Chat",
      size_files: "Size Files",
      sender_voice: "Sender voice",
      for_defect: "For defect",
      sender_email: "Sender Email",
      url_integration: "Url Integration",
      user_verification_code: "User Verification Code",
      token_facebook: "Facebook Token",
      groups_qna: "Groups QnA",
      group: "Group",
      operation: "Operation",
      operations: "Operations",
      activate_ia: "Activate IA",
      basic_data: "Basic Data",
      visual_aparience: "Visual Aparience",
      advanceds: "Advanceds",
      integrations: "Integrations",
      avatars: "Avatars",
      avatar: "Avatar",
      home_screen_portal: "Home screen portal",
      home: "Home",
      default: "Default",
      activate_bot: "Activate Bot",
      avatars_list: "Avatars List",
      window_chat_close: "Window Chat Close",
      bot_not_understand: "Bot Not Understand",
      bot_writing: "Bot writing",
      start_chat: "Start Chat",
      edit_avatar: "Edit Avatar",
      upload_image: "Drag or click to select the image",
      file_selected: "Selected file",
      nothing_select_file: "None select file",
      limit_image: "It is recommended that the dimensions of the image are not greater than 500px, a ratio 1:1 (Square) and the upload size is not greater than 200 KB",
      module_ia: "Artificial Intelligence Module",
      configure_modules: "Module Configuration",
      created_modules: "Modules Created",
      questions_without_response: "Questions without associated answer",
      change_setting: "change setting",
      create_module: "create module",
      module_web_views: "Module web views",
      list_web_views: "List web views",
      create: "Create",
      connect: "Connect",
      stadistics: "Stadistics",
      dashboard_general: "Dashboard General",
      tecnology: "Tecnology",
      total_users: "Total users",
      interval_dates: "Interval Dates",
      funnels_list: "Funnels list",
      msg_conversion_actions: "Measure conversions from a sequence of actions",
      funnel_msg_create: "Create a funnel to learn more about the conversion rate and completion time for any specific flow on your app or website.",
      funnel_name: "Funnel name",
      events: "Events",
      create: "Create",
      cancel: "Cancel",
      event_list: "Event List",
      msg_consult_actions: "See the actions people take on your product Event.",
      event: "Event",
      total_interactions: "Total interactions",
      percentage: "Percentage",
      creation: "Creation",
      rows_filters: "Rows Filters",
      general_report: "General report",
      user: "User",
      registers_not_found_dates: "There seems to be no data for this date range, please select anothe.",
      statement: "Statement",
      operator_title: "Operador Title",
      campaign_massive: "Massive Campaigns",
      campaign_look: "Look Campaigns",
      shipping_list: "Shipping List",
      save: "Save",
      refresh_template_email_list: "Refresh Template Email List",
      select_option: "Select option",
      new_template: "New Template",
      email_template: "Email Template",
      configure_campaign: "Configure Campaign",
      campaign_type: "Campaign Type",
      new_list: "Nueva Lista",
      refresh_contact_list: "Refresh Contact List",
      message: "Message",
      add_url: "Add URL",
      subject: "Subject",
      stop_campaign: "Stop Campaign",
      new_campaign: "New Campaign",
      msg_without_campaign: "No Campaigns Founded",
      msg_here_existing_campigns: "Here you will find the existing campaigns",
      upload_file: "Upload File",
      optional: "Optional",
      bot_edition: "Bot Edition",
      info_edition: "Add, edit, duplicate or remove different modules within your bot, each module provides different functions to your bot",
      info_upload_file: "Configure the message to send to your bot to request attachments and the message to cancel file upload",
      info_webview: "You can link your bot with different forms that you have created in the webform side menu",
      buttons_list: "Buttons List",
      info_buttons_list: "Add lists of buttons to your bot and assign each one to redirect to a url or continue the flow",
      carousel: "Carousel",
      carousel_info: "Create a carousel of images loaded from different URLs and each one assign different buttons which can redirect to a URL or move forward in the flow",
      form: "Form",
      form_info: "Add short form questions to your bot to ask the user",
      quick_replies_info: "Assigns the bot predefined responses to the question the user asks, in the output you can assign the response to follow the general flow of the bot or to follow an individual flow for that response",
      calendar: "Calendar",
      calendar_info: "The calendar box is a box that should allow bot users to select a date (with or without time) or range of dates within a calendar",
      location: "Location",
      location_info: "The location box is a box that should allow bot users to select one or more locations",
      multimedia:"Multimedia",
      multimedia_info: "Media box is a box that should allow bot users to receive image, audio, video or document files",
      report_info: "The reports box is a box that should allow bot users to create custom reports by storing the value of selected variables",
      blockchain_info: "The blockchain box is a box that should allow the bot users to save the value of the selected variables in the blockchain",
      scanner: "Scanner",
      scanner_info: "The qr scanner box is a box that should allow bot users to read a qr code using the camera",
      catalog: "Catalog",
      catalog_info: "The catalog box is a box that allows bot users to make purchases through a product catalog",
      payments_online: "Online payments",
      payments_online_info: "Online payment box is a box that allows bot users to make online payments through different payment gateways",
      plane_text: "Plane text",
      quick_replies: "Quick Replies",
      external_api: "External Api",
      conditional: "Conditional",
      switch_to_agent: "Switch to Agent",
      custom_report: "Custom Report",
      unsubscribe: "Unsubscription",
      contacts: "Contacts", 
      variable: 'Variable',     
      edit_component_info: "Edit this component and add a statement.",
      info_error_reload_flow: "oops! We had trouble loading the flow you want to edit, I'll reload it for you.",
      edit_component_info2: "Edit this component and add your options.",
      edit_component_info3: "Edit this component and add the bot and cancellation message",
      web_view: "Web View",
      info_calendar: "Please select calendar settings",
      scanner_qr_info: "Please select the settings of the qr scanner",
      catalog_config_info: "Please select the configuration from the catalog",
      payment_method_info: "Please select the payment method settings",
      location_config_info: "Please select the location box configuration",
      name_info_variable: "Name for user length",
      name_info_variable_latitud:"Name for user latitude",
      multimedia_config_info: "Please select the configuration of the multimedia box",
      report_config_info: "Please select the report configuration",
      service_not_found: "service not found",
      blockchain_config_info: "In order to use the Blockchain box, you must purchase the service and have at least one smart contract created.",
      blockchain_config_info2: "Please select the blockchain configuration",
      soporte_info: "Please contact support to contract this service",
      start_api: "Start Api",
      end_api: "End Api",
      switch_to_agent_config_info: "Edit this component and add the bot messages",
      conditional_config_info: "Edit this component and add the bot and cancellation message ",
      unsubscribe_config_info: "Edit this component and add a goodbye statement and message",
      email_config_info: "Edit this component, add subject, sender, counted list and so on...",
      trigger_config_info: "Edit this component...",
      variable_config_info: "Edit this component to set up your variables.",
      msg_confirm_delete: "Do you want to delete this operator permanently?",
      msg_delete_nodo: "The node has been successfully removed",
      message_scheme: "Sorry, according to the platform configuration of the bot, I don't know they allow carousel boxes, remove them to continue saving the flow.",
      message_scheme2: "Sorry, according to the platform configuration of the bot, I don't know they allow button list boxes, remove them to continue saving the flow.",
      message_scheme3: "Sorry, according to the platform configuration of the bot, I don't know they allow image-type boxes, remove them to continue saving the flow.",
      message_scheme4: "Sorry, according to the platform configuration of the bot, I don't know they allow multimedia boxes, remove them to continue saving the flow.",
      message_scheme5: "Sorry, according to the platform configuration of the bot, I don't know they allow subscription type boxes, remove them to continue saving the flow.",
      message_scheme6: "Remember to mark any operator as 'Home' by clicking on the little house so that the script has a starting point",
      message_scheme7: "Flow saved successfully.",
      message_scheme8: "There were problems saving the flow, please try again.",
      configure_event: "Configure Event",
      event_name: "Name of the event",
      finish: "Save",
      without_text: 'No text',
      scanner_code: 'Scan Code',
      flows: 'Flows',
      settings: 'Settings',
      setting_module: 'Setting Module',
      questions_multiple_ask: 'Questions with multiple answers',
      id_servicio: 'Id Services',
      ia: 'Artificial intelligence',
      notifications: 'Notifications',
      switch_to_agent_var: 'Agent step variable',
      switch_to_agent_msg: 'Select the variable that contains the agent step information to redirect',
      switch_to_agent_msg2: 'Select the variables you want to send to the API step agent',
      switch_to_agent_msg3: 'Configure agent step',
      variable_json_api:"JSON API variable",
      basic: 'Basic',
      advanced: "Advanced",
      welcome_msg: 'Welcome message',
      welcome_msg2: 'Welcome message to the agent step that will be printed on the bot',
      add_message: 'You must add a message',
      switch_to_agent_url: 'agent step url',
      switch_to_agent_msg4: 'Url that connects to the agent step API',
      attention: 'Attention',
      message1: 'If you do not specify a url, the one defined in the bot configuration will be taken',
      message2: 'Write the url of the step to agent',
      other_vars: 'Other variables',
      name_key: 'key name',
      value: 'Value',
      name_var: "Variable Name",
      url_api: 'URL of API',
      will_be_consumed: 'that will be consumed',
      field_required: 'This field is required',
      validate_result: 'Validate result',
      message3: 'Send session and user id',
      send_method: 'Shipping method',
      message4: 'Method to use to consume the API',
      shipping_coding: 'Shipping Coding',
      type_coding: 'Type of encoding in which the data will be sent to the API',
      txt_long_edit_component:'Edit this component and add the bot message and web form',
      select: 'Select',
      headers: 'Headers',
      action: 'Action',
      actions: 'Actions',
      add_headers: 'Add Headers',
      data_sended: 'Data Sended',
      add_key: 'Add Key',
      data_received: 'Data Received',
      close_windows: 'Close Windows',
      user_writing: 'User Writing',
      configure_bot: 'Configure Bot',
      save_changes: 'Save Changes',
      visual_appearance: 'Visual Appearance',
      bot_to_create: 'Bot To Create',
      languages_msg:'Languages ​​in which the bot will be enabled',
      background_header: 'Background Header',
      message5: 'It can be an icon or a user text of no more than 10 characters',
      use_text: "Use Text",
      use_icon: "Use Icon",
      message6: 'Enable or disable the input of the chat text when the bot displays any selection option with buttons.',
      keep_web_history: 'Keep web chat history',
      keep_web_history_info: "Enable or disable chat history. If enabled, it will show the user's previous conversations.",
      message7: 'Location on the website where the bot will be displayed',
      enable_up_window_bot: 'Enable automatic display of the Bot window.',
      description_field_text: 'Description of the text field in the bot box',
      timeout_type_first: 'Type of first Timeout',
      timeout_type_second: 'Type of Second Timeout',
      timeout_type_third: 'Type of third Timeout',
      must_select_scheme: 'You must select a scheme',
      must_select_operator: 'You must select an operator',
      infor_select_operator:'Operator to use to compare the data and evaluate the code',
      send_message: 'Send Message',
      go_to: 'Go to',
      add_contact_list: 'Add Contact List',
      this_list_have: 'This list has ',
      duplicate: 'Duplicate',
      register: 'Register',
      recharge_template: 'Recharge Template',
      size_files_max: 'Maximum size allowed for files to be uploaded to the platform',
      new_avatar: 'New avatar',
      use_avatar: 'Use Avatar',
      message8: 'displayStatement to be printed in the bot',
      message9: 'Variable visible to the user',
      message10: 'Name of the variable where the selected information will be stored',
      message11: 'Name where a JSON will be stored with the selected information and the structure of the format depending on the type of calendar selected',
      type_calendar: 'Calendar Type',
      type_calendar_config: 'Type of Calendar to be configured',
      time_zone: 'Time zone',
      time_zone_config: 'Time zone that will handle the calendar',
      minimum_date: 'minimum date',
      minimum_date_config: 'Minimum date that will allow the calendar to be selected',
      maximum_date: 'maximum date',
      maximum_date_config: 'Maximum date that will allow the calendar to be selected',
      date_selected_default: 'Date selected by default',
      date_selected_default2: 'Date that will appear selected by default in the calendar',
      current_date: 'Current date',
      carouse_info: 'Select the variable that contains the carousel information',
      var_carousel: 'variable carousel',
      max_20_chars: 'Maximum 20 characters',
      advance: 'Advance',
      new_tab: 'New Tab',
      reload: 'Reload',
      invalid: 'Invalid',
      type: 'Type',
      add_new: 'Add New',
      set_up: 'Set Up',
      image: 'Image',
      load_data_from_var: 'Load data from Variable',
      carousel_info2: 'If you select this option the carousel data must be loaded from a variable',
      buttons: 'Buttons',
      max_buttons_allowed: 'Maximum buttons allowed',
      catalog_type: 'Catalog type',
      catalog_type_info: 'Select the type of catalog you want to use',
      customer: 'Customer',
      id_final_user: 'Enter the id that will identify the end user',
      message12: 'Variable to store the id of the order',
      message13: 'Name of the variable where the id of the generated order will be stored',
      message14: 'There is already a variable with that name in the bot, please use another name.',
      message15: 'Variable to store the payment reference',
      message16: 'Name of the variable where the reference will be stored to generate the payment of the generated order',
      message17: 'Product Information Source',
      message18: 'Select the source of information from which the products to be displayed in the catalog will be brought',
      message19: 'Variable with product list',
      message20: 'Select the variable that contains the information of the products to be displayed in the catalog',
      stock_keys: 'Key storage id',
      message21: 'Enter the id provided by the development team that stores the access keys',
      message22: 'Tags to be used as categories',
      message23: 'Enter the name of all the collections you want to use as categories in the catalog',
      write_enter:"Type and press enter..",
      limit_allowed: 'Allowed limit',
      numeric: 'Numeric',
      text: 'Text',
      phone: 'Phone',
      question: 'Question',
      validation: 'Validation',
      allowed_box: 'maximum boxes allowed',
      message_error: "Enter an error message",
      message_error_label:"Error message",
      extension_archivos_label:"File extensions",
      extension_archivos_icon:"Enter the extension to allow ex: .png .pdf",
      add: "Add",
      type_location_msg: 'Type of location to use',
      location_list: 'Location List',
      location_list_msg: 'Select the list of locations to be used',
      location_list_msg2: 'Please create a list of locations to be assigned',
      add_var: 'Add Variable',
      columns_list: 'column list',
      columns_list_msg: 'Select the column to use as a variable',
      name_var_msg: 'Name of the variable that will store the column information',
      latitud_var: 'variable with latitude',
      latitud_var_info: 'Select the variable that contains the latitude information to plot',
      message24: 'No variables created',
      longitud_var: 'variable with length',
      longitud_var_info: 'Select the variable that contains the length information to plot',
      icono_var: 'Variable with icon',
      message25: 'Select the variable that contains the image that will be used in the icon to put the location on the map',
      message26: 'Type the information that will be displayed when clicking on the icon of the graphed location',
      message27: 'There is already a variable with that name in the bot, please use another name.',
      configure_file: 'Configure File',
      message28: 'How you want to upload the media file',
      files_locals: 'local files',
      template: 'Template',
      message29: 'Name of the variable where the selected url will be stored',
      message30: 'There is already a variable with that name in the bot, please use another name.',
      no:"No",
      file: 'File',
      upload_file: 'Upload File',
      message31: 'Drag or click to select the file',
      var_url_file: 'Variable with the url of the file',
      message32: 'Select the variable that contains the url information of the file to upload',
      select_template_for_file: 'Select the template to generate the file',
      template_use_for_file: 'Template to be used to generate the file',
      message33: 'No templates to be assigned',
      message34: 'What kind of file do you want to create',
      select_file_to_create: 'Select the type of file to be created',
      template_vars: 'template variables',
      create_vars_msg: 'Create the variables that will be used in the template to generate the file',
      configure_payment_online: 'Set up Online Payments',
      payment_gateway: 'Payment gateway',
      payment_type_select: 'Select the payment gateway to use',
      payment_var_code: 'Variable to store the payment code',
      payment_var_id: 'Name of the variable where the payment id will be stored',
      payment_var_amount: 'Select the variable that contains the amount to be paid',
      payment_reference: 'Payment reference',
      payment_var_reference: 'Select the variable that contains the Id of the payment reference',
      payment_var_currency: 'Select the currency in which the payment will be made',
      whatsapp_menu_interactive: 'Whatsapp interactive menu',
      message35: 'If you select this option, an interactive menu will be displayed in WhatsApp',
      statement_type: 'statement type',
      configure_scanner: 'Configure Scanner',
      scanner_type_code: 'Type of code to scan',
      scanner_type_code_msg: 'Select the type of code you want users to scan',
      scanner_name_var_code: 'Name of the variable where the information scanned from the qr code will be stored',
      configure_plane_text: 'Configure plain text',
      statements: 'Statements',
      message36: 'The statement cannot be empty',
      sended_time: 'Shipping time',
      immediate: 'Immediate',
      scheduled: 'Programmed',
      number: 'Number',
      days: 'Days',
      hours: 'Hours',
      phone_number_to_msg: 'Mobile number where the text message will be sent',
      sms_message_info: 'Write below the message to send. Maximum 160 characters.',
      configure_upload_file: 'configure file upload',
      text_button_cancel: 'Cancel action button text',
      text_button_cancel_msg: 'Text that will appear on the cancel button',
      add_name_var: 'You must add the name of the variable',
      select_form_llink_up: 'Select a form to link',
      message37: 'Select the web view to be displayed in the bot',
      show_x_close_webview: 'Show X to close webview',
      valid_dimensions: 'Valid Dimensions',
      valid_size: 'Size valid',
      drag_click_image: 'Drag or click to select the image',
      edit_flow: 'Edit Flow',
      names: 'Names',
      last_name: 'Last Name',
      confirm_pwd: 'Confirm Password',
      block_account: 'Your account has been locked for security reasons due to multiple failed login attempts. Wait 15 minutes before trying again.',
      oldpassword: "Old password",
      newpassword: "New password",
      repeatpassword: "Repeat the password again",
      titleChangepass: "Password and Security",
      textChangepass: "Password change: Protect your account. Choose a strong and unique password to ensure your security online. Do not share your password and update it regularly. Keep your account safe!",
      noteChangepass: "Note: If you change the password, the current session will end.",
      try_again: 'Try again with a password you have not used before',
      change_password_editar_60: ' Change password every 90 days',
      change_password_60: ' Change password every 60 days',
      use_var: 'Use variable',
      use_var_as:'Use as variable',
      define_name_varible:'Define a name so you can use the file as a variable',
      intent: 'Intent',
      select_intent_operator: 'Select the intent related to this operator',
      ia_message: 'No ai intents configured to be assigned',
      inactivity: 'Inactivity',
      invalid_phone: 'invalid number',
      timeout_message: 'Idle time (Seconds) that the bot must wait to fire the default message, the minimum time allowed is 1 second.',
      seconds: 'Seconds',
      timeout_message2: 'Message to be displayed once the established inactivity time has elapsed',
      message39: 'Select the box to which it will be redirected in the previously selected flow',
      message38: 'Through this title you will be able to identify the operator and reach him through a go to box',
      message40: 'Select the stream you want to redirect to',
      provider: 'Provider',
      phone_number: 'Phone Number',
      write_phone_number: 'Enter the phone number',
      message41: 'Select the avatar related to this operator',
      message42: 'Select the name of the key api/scheme/statement/',
      column_title: 'column title',
      message43: 'This is the name of the column that will be stored in the blockchain',
      message44: 'This is the value that the column will have that will be stored in the blockchain',
      message45: 'Configuring blockchain storage',
      message46: 'Select Blockchain Service Provider',
      contract: 'Contract',
      message47: 'Select the contract with which the information will be stored',
      message48: "YOU DON'T HAVE BLOCKCHAIN ​​CONTRACTS YET",
      message49: 'If you have a blockchain contract, you can store your information securely and unchangeably',
      message50: 'In order to access this functionality, you must contract the blockchain service.',
      select_var: "Select a variable",
      compare_value: "Value to compare",
      select_var_compare: 'Select the variable you want to compare',
      verify_list_fields: 'Please verify that all fields are filled.',
      configure_conditional: 'set conditional',
      write_subject: 'Write the subject',
      select_template: 'select template',
      reload_template_email_list: 'Reload list of Email templates',
      email_receiver: 'Recipient email',
      email_cc: 'Copy emails',
      value_var: 'variable value',
      events_message: 'Select the events you want to use',
      delivered: 'Delivered',
      message51: 'Lorem Ipsum is simply the dummy text of the printers and typesetters.',
      open: 'Open',
      refused: 'Refused',
      links_event: 'Click Event Links',
      maximum: 'Maximum',
      add_link: 'add link',
      metric: 'Metrics',
      select_color: 'Select a color scale',
      without_metrics: "YOU DON'T HAVE METRICS YET",
      metrics_message: 'To create a graph it is necessary to have created at least one metric',
      create_metric: 'Create Metric',
      update_info: 'Update information',
      create_new_dashboard: 'Create new dashboard',
      description_dashboard: 'Dashboard Description',
      dashboard: "Dashboard",
      select_graphics: 'select graphs',
      message52: "YOU DON'T HAVE GRAPHICS YET",
      message53: 'To create a dashboard it is necessary to have created at least one graph',
      create_graphic: 'create graph',
      validate_message: "The password must be at least 10 characters.",
      validate_message2: "The password must be at least 10 characters long and contain at least one lowercase letter, one uppercase letter, one digit, and one special character.",
      validate_message3: "Username must be at least 10 characters.",
      validate_message4: "The username must contain only letters and numbers.",
      validate_message5: "The username cannot contain spaces.",
      validate_message6: "Invalid email format",
      validate_message7: "Select a role.",
      rol:"Role",
      validate_message8: "Are you sure to delete this user?",
      admin_users: 'Users Management',
      admin_user_message: 'NO USERS YET',
      admin_user_message2: 'Here you will find the existing users of the company',
      admin_user_message3: 'Create a user to be able to use the platform.',
      create_user: 'create user',
      new_password: 'New Password',
      admin: 'Administrator',
      campaigns: 'Campaigns',
      edit_user: 'Edit User',
      message54: 'Is Dinamic',
      enable_dinamic_operator: 'Enable Dinamic Operator',
      upload_image2: 'upload image',
      create_operator_msg: 'Operator created successfully!',
      operator_created_msg: 'Error creating operator, please try again!',
      operator_edited_msg: 'Operator edited successfully',
      operator_edited_msg2: 'Failed to update operator, please try again!',
      operator_delete_msg: 'Operador eliminado correctamente',
      operator_delete_msg2: 'Error deleting operator, please try again!',
      operator_delete_msg3: "¡Error al eliminar el operador dinámico, intente nuevamente!",
      statement_create_msg: 'Error creating Statement, please try again!',
      statement_edit_msg: '¡Error updating the Statement, try again!',
      operator_list_msg: 'Error getting list of operators, please try again!',
      statements_list_msg: 'Could not get variable list!',
      statements_list_msg2: 'Error, when obtaining the configuration of the variables!',
      statements_list_msg3: 'Error, getting the list of companies!',
      whatsapp_message: 'The ws message was sent!',
      whatsapp_message2: 'Error creating message to ws, please try again!',
      message55: 'Could not get list of companies!',
      message56: 'Could not get list of calendar types!',
      message57: 'Error, getting list of calendar types!',
      location_msg: 'Could not get list of location types!',
      location_msg2: 'Error, getting the list of location types!',
      location_msg3: 'Could not get list of locations!',
      location_msg4: '¡ Error, getting the list of locations!',
      blockchain_msg: 'Failed to get list of blockchain providers!',
      blockchain_msg2: 'Error, getting list of blockchain providers!',
      blockchain_msg3: 'Failed to get list of blockchain contracts!',
      blockchain_msg4: 'Error, getting list of blockchain contracts!',
      message58: 'Statement that will appear to the user in the bot',
      message59: 'Statement prior to sending the link for the user to unsubscribe',
      welcome_msg: 'Goodbye message',
      message60: 'An error has occurred!',
      form_msg: "Form saved successfully",
      form_msg2: "Sorry, the form could not be saved.",
      message189: 'You must save before editing.',
      message61: 'Could not update the item, please try again',
      message62: 'Exceeds the allowed size',
      message63: 'Image uploaded successfully.',
      config_msg: "Configuration saved successfully.",
      config_msg2: "Could not update configuration",
      warning_msg: "The phone number entered is not a valid number. Please check it.",
      message64: "The dimensions of the image are not correct",
      message65: "You want to delete this avatar permanently",
      avatar_msg: "This avatar cannot be deleted, it is currently being used by a bot.",
      message66: 'File uploaded successfully!',
      message67: "The entered keyword is NOT valid. Please check it.",
      message68: "The entered TIMEOUT is NOT valid. Please check it.",
      message69: 'An error occurred while uploading the file',
      message70: 'Error getting templates.',
      message71: 'Template deleted successfully',
      message72: 'Failed to delete template, please verify that it is not in use by one or more bots.',
      message71: 'Template saved successfully.',
      message72: 'Error creating template.',
      message73: 'Template updated successfully.',
      message74: 'Error updating template.',
      message75: 'Error getting template.',
      message76: 'Error getting template variables.',
      message76: 'The metric was successfully removed!',
      message77: 'Do you want to delete this metric permanently?',
      message78: 'Failed to clear metric!',
      message79: "An error occurred!",
      message80: "No graphics found!",
      message81: "The list of graphs could not be obtained!",
      message82: "Error creating chart, you must select a chart type and color scale!",
      message83: "New chart created successfully!",
      message84: "Error creating graph, please try again!",
      message85: "The graph was successfully updated!",
      message86: "Error updating graph!",
      message87: " Do you want to delete this graph permanently ?",
      message88: "The graph was successfully removed!",
      message89: "Error deleting graph!",
      message90: "Dashboards list could not be retrieved!",
      message91: "Failed to create dashboard, please try again",
      message92: "The dashboard was successfully updated!",
      message93: "Failed to update dashboard!",
      message94: "Do you want to delete the dashboard permanently ?",
      message95: "The Dashboard was successfully removed!",
      message96: "Error deleting dashboard!",
      message97: "No dashboards found!",
      message98: "Dashboards information could not be retrieved!",
      message99: "Error, could not get the number of units!",
      message100: "Error, when listing the total units!",
      message101: "No Units found!",
      message102: "The list of units could not be obtained!",
      message103: "No Dimensions found!",
      message104: "The dimension list could not be obtained!",
      message105: "No chart types found!",
      message106: "The list of chart types could not be obtained!",
      message107: "The chart type could not be obtained!",
      message108: "Could not get channel list !",
      message109: "Error, getting the channel list!",
      message110: "Could not get list of color palettes !",
      message111: "Error, getting the list of color palettes!",
      message112: "No metrics found!",
      message113: "The list of metrics with parent could not be obtained!",
      message114: "The list of metrics could not be obtained!",
      message115: "The metric could not be obtained!",
      message116: "New metric created successfully!",
      message117: "Error creating the metric, try again!",
      message118: "The metric was successfully updated!",
      message119: "Failed to update metric!",
      message120: "New dashboard created successfully!",
      message121: "The number of measurements could not be obtained!",
      message122: "You must select a start date, please try again!",
      message123: "You must select an end date, please try again!",
      message124: "¡Token Successful !",
      message125: "Error, getting the tocken!",
      message126: "You must add two dates to be able to filter, otherwise it will proceed to filter by today's date",
      message127: "A copy of the report has been sent",
      message128: 'The timeout waiting to generate the query has been exceeded. <br /> <br /><span style="font-weight:bold;font-style:italic">Please select a shorter date range. </span>',
      message129: "Could not get language list!",
      message130: "Error, getting the list of languages!",
      message131: "An error occurred while getting the interactions report from the ",
      message132: "Time limit exceeded to generate the report ",
      message133: " Please select a shorter date range",
      message134: "Could not get list of companies!",
      message135: "Error, getting the list of companies!",
      message136: 'Company changed successfully',
      message137: 'Error deleting company',
      message138: 'Company Created successfully',
      message139: 'Error creating company',
      message140: 'Company edited correctly',
      message141: 'Error editing company',
      message142: 'User created successfully',
      message143: 'Error creating user',
      message144: 'Error when consulting users',
      message145: 'User Edited successfully',
      message146: 'User Edited Successfully, Start Again!',
      message147: 'Error editing user',
      message148: 'User deleted successfully',
      message149: 'Sorry, this action is not possible.',
      message150: "Email Report!",
      message151: "Error generating report!",
      message152: "An error occurred while getting the email report from the ",
      message153: "An error occurred while getting the custom report from",
      message154: "An error occurred while obtaining the customer report from the",
      message155: "The selected bot does not have custom reports!!",
      message156: "Error getting list of custom reports!",
      message157: "An error occurred while getting the SMS report from the ",
      message158: "An error occurred while getting the blockchain report from the",
      message159: "Blockchain Report!",
      message187: "Database overwritten successfully",
      message188: "Database created successfully",
      message190: "Oops, an error occurred. AI could NOT be linked. Please verify the data entered",
      instagram_token: "instagram token",
      group: "Group",
      groups: "Groups",
      new_groups: "New Group",
      groups_msg: "Configure how your bot will answer user questions. You can define a group, its questions and its answers or you can also add question synonyms to it. The more questions you add, the better the bot will be.",
      options: "Options",
      module_qna: 'Module QnA',
      link_bot: 'Link Bot',
      config_file: 'Set up file',
      config_edit: 'Edit Settings',
      ia_message2: "Enter the id of the dialogflow",
      new_module: "new module",
      module_name: "Module name",
      question_user: "If the user asks",
      question_write: "write question",
      bot_can_response: "The bot can reply",
      write_response: "write answer",
      delete_module: "Delete Module",
      ia_multiple_response: "Questions with multiple answers / Total Quantity",
      ia_message3: "No questions without associated answers were found",
      ia_message4: "Here you will find the list of questions without associated answers",
      ia_message5: "With this list you will be able to train the Artificial Intelligence of the Bot.",
      ia_message6: "Questions without associated answers / Total Quantity",
      ia_message7: "No questions with more than one answer were found.",
      ia_message8: "Here you will find the lists of questions with more than one answer",
      click_here: 'Click here',
      testing_bot: 'Testing the bot',
      message160: "The chat list could not be obtained!",
      create_company: 'Create Company',
      create_database: 'Create Data bases',
      sobreescribir_database:'overwrite database',
      company_admin_msg: 'HAS NO COMPANIES YET',
      company_admin_msg2: 'Here you will find existing companies',
      company_admin_msg3: 'Create a company to be able to create your bots.',
      company_name: "Company name",
      company_administrator: "Administrator name",
      company_admin_msg4: "Username to login",
      home_actions_msg: 'Error, could not get the number of bots!',
      home_actions_msg2: 'Error, when listing the total number of bots!',
      home_actions_msg3: 'No bots found!',
      home_actions_msg4: 'Could not get channel list !',
      home_actions_msg5: 'Error, when obtaining the list of channels!',
      home_actions_msg6: 'New bot successfully created!',
      home_actions_msg7: 'Failed to create bot, please try again!',
      home_actions_msg8: "Do you want to delete this bot permanently ?",
      home_actions_msg9: 'Error deleting bot!',
      create_new_group: 'Create new Group',
      create_new:'New Variable',
      name_group: "Group name",
      question: "Question",
      answer: "Answer",
      add_synonym: "add a synonym",
      list_synonym: "synonym list",
      edit_question: "Edit Question",
      qua_title_view: "QnA Questions and Answers",
      questions_answers: "Questions and answers",
      train: "Train",
      write_question: "write a question",
      message161: "The bot should respond",
      example_qna: "Example: My name is Marvin",
      example_qna2: "Example: What is your name?",
      message162: "if they ask",
      message163: "the bot responds",
      message164: "another way to ask",
      synonym: "synonym",
      edit_synonym: "edit synonym",
      train_bot: "Train Bot",
      message165: "Configure how your bot will answer user questions. You can define a specific question and its answer or you can also add question synonyms to it. The more questions you add, the better the bot will be.",
      message166: "Write a question for the bot to answer",
      message167: "Write what the bot should answer",
      add_question: "Add Question",
      message168: "Unsaved changes will be deleted.",
      message169: 'You must enter the name of the module',
      message170: "Please enter the name of the web view",
      funnel_create: "create funnel",
      funnel: 'Funnel',
      message171: 'You must add an event to continue.',
      message171: 'There must be at least one event to continue.',
      message172: "Do you want to delete this funnel permanently?",
      message173: "Here you will find the graphs that can be used in a dashboard",
      message174: "Create a graph to be able to analyze the information of any flow of your application and its operation.",
      message175: "YOU DON'T HAVE DASHBOARDS YET",
      message176: 'Here you will find the existing dashboards',
      message177: 'Create a dashboard to be able to analyze the information of any flow of your application and its operation.',
      metric_admin: 'Measures/Metrics Administration',
      message178: 'Here you will find the measurements that can be graphed on a dashboard',
      message179: 'Create a measure to be able to analyze the information of any flow of your application and its operation.',
      next_page: "Next. pag.",
      previous_page: "Prev pág.",
      rows_for_page: "Rows per page",
      all: "All",
      filters: "Filters",
      clear: "Clear",
      see_columns_msg: "Show/Hide Table Columns",
      row_selected: "selected row(s)",
      row_selected_delete: "Delete selected rows",
      phrase: 'Phrase',
      quantity: 'Quantity',
      web_view_module: 'Web View Module',
      web_view_create: 'Create Web View',
      connect_web_view: 'Link a web view',
      web_view_name: 'Web view name',
      web_view_example: 'Write the url of the site eg:',
      operator: 'Operator',
      operator_type: 'Operator Type',
      config_cms: 'CMS Configuration',
      config_cms_msg: 'NO CMS CONFIGURATIONS CREATED YET',
      config_cms_msg2: 'Here you will find the list of CMS configurations of the bot',
      config_cms_msg3: 'Create a new CMS configuration to be listed.',
      config_operator: 'Configure Operator',
      tarjects: 'Tarjects',
      statements: 'Statements',
      max_item_lose: 'Maximum items allowed',
      add_image: 'Add Image',
      url_image: 'URL Image',
      upload_image_msg: 'It is recommended that the image have a 2:1 ratio (Rectangular) and the upload size is no larger than 800 KB',
      channels: 'Channels',
      status: 'Status',
      create_new_notification: 'Create New Notification',
      notifications_msg: 'NO NOTIFICATIONS YET',
      notifications_msg2: 'Here you will find the existing notifications of the bot',
      notifications_msg3: 'Create a new notification.',
      notifications_config: 'set notification',
      notification_edit: 'edit notification',
      create_new_contact_list: 'Create New Shipping List',
      contact_list_msg: 'YOU HAVE NO SHIPPING LISTS CREATED YET',
      contact_list_msg2: 'Here you will find the existing shipping lists of the company',
      contact_list_msg3: 'Create a new mailing list.',
      template_email_msg :'Select a mailing list to view the available variables and thus be able to customize the email templates for your clients, you just have to copy and paste the variable in the part you prefer.',
      send_list: 'Mailing list',
      save_template: 'Save Template',
      edit_template: 'Edit Template',
      save_edition: 'Save Edit',
      template_title: 'Template Title',
      create_new_template: 'Create New Template',
      templates_email: 'Templates Email',
      without_templates: 'NO TEMPLATES YET',
      template_email_msg2: 'Here you will find the existing templates of the company',
      contact_name: "Enter the name of the contact",
      contact_phone: "The contact number you are trying to add already exists.",
      contact_filter_msg: "You must add two dates to be able to filter, otherwise it will proceed to filter by today's date",
      custom_report_msg: 'This is the name that the report column will have',
      custom_report_msg2: 'This is the value that the report column will have',
      custom_report_msg3: 'Configure custom report',
      add_links_event: 'You must add links to the click event or remove the event !',
      add_link_msg: 'You have already added 5 links !',
      add_link_msg2: "You've already added that link!",
      select_link: ' Select a link ',
      complete_info_add_var: 'Fill in the information to add the variable',
      config_vars: 'configure variables',
      list_var: 'variable list',
      report_list: 'Report Ready!',
      report_generate_msg: 'A copy of the report has been sent ',
      report_generate_msg2: 'The waiting time limit for generating the report has been exceeded. ',
      report_generate_msg3: ' The report will continue to be generated and a copy will be sent to the email ',
      report_generate_msg4: 'Please select a shorter date range.',
      time_limit: 'Time Limit Exceeded!!',
      new_users: "New Users",
      user_activate: "Active users",
      message180: "Do you want to delete this list permanently?",
      message181: 'The list cannot be deleted, because it has an associated campaign!',
      contact_edit_msg: "Contact successfully edited!",
      data_update_msg: "Data updated successfully.",
      message182: 'The list you are trying to validate does not have mail or email columns',
      bots_associate: 'Associated bots',
      message183: 'This list has duplicate records',
      available: 'Available',
      edit_send_list: 'Edit shipping list',
      config_list: 'Configure list',
      user_identity: 'User identifier',
      contacts_upload: 'Load contacts',
      view_data: 'View Data',
      validate_email: 'Validate Email',
      verify_domain: 'Verify Domain',
      validate: 'Validate',
      list: 'list',
      edit_contact :'Edit Contact',
      download: 'Download',
      Sobreescribir:'Overwrite',
      Visualizacion:'Display',
      Visualizacion_logs:'Display logs',
      create_consult: 'Create Consult',
      test_consult: 'Test Consult',
      message184: 'They correspond to the parameters that will be used to group the information',
      dimensions_metric: "Write the dimensions of the measure",
      measurement: 'Measurement',
      write_form_measurement: "Write the formula of the measure",
      metric_message2: "Enter the condition to filter the measure",
      fields: 'Fields',
      functions: 'Functions',
      operators: 'Operators',
      consult: "Consultation",
      update: 'Update',
      create_new_metric: "Create new Measure / Metric",
      metric_name: "Measure/Metric Name",
      short_name: 'Short Name',
      short_name_metric: "Short name of the measure/metric",
      description_metric: "Measurement/Metric Description",
      operational: 'Operational',
      report_interactions_msg: 'No Interaction Reports found',
      report_interactions_msg2: 'Here you will find the lists of Interaction reports',
      see_more: 'See More',
      edit_company: 'Edit Company',
      company_message: 'No companies created',
      blockchain_report: 'Report Blockchain',
      blockchain_report_msg: 'YOU HAVE NO REPORTS YET BLOCKCHAIN',
      blockchain_report_msg2: 'Include a blockchain box in the flow with the values ​​you want to store to generate the blockchain report',
      report_customer: 'Customer Report',
      management: 'Management',
      custom_reports: 'Custom Reports',
      database_admin_msg: 'HAS NO DATABASES YET',
      database_admin_msg2: 'Here you will find existing databases',
      database_admin_msg3: 'Create a database to be able to be use in your bots.',
      generate:'Generate',
      shorten_url:'Shorten URL',
      tooltipKeyword:'Keyword to reach this operator',
      invalidKeyword:'Invalid Keyword, must be in uppercase letters, without spaces or special characters.',
      keyword:'Keyword',
      audio:'Audio',
      tooltip_audio:'Attach audio file to operator',
      msg_file:'The file exceeds the maximum allowed',
      config_api: 'Configure API',
      config_calendar: 'Configure Calendar',
      config_catalog: 'Configure Catalog',
      config_webview: 'Configure Web View',
      config_quickreply: 'Configure Quick Reply',
      config_sender: 'Configure Sender',
      config_image: 'Configure Image',
      config_desuscription: 'Configure Unsubscription',
      msg_error_limit:'Limit allowed 2000 characters',
      msg_error_variable:'There are no variables to be assigned',
      msg_error_empty:'Empty items are not allowed',
      used_variable:'to use variable',
      variable_multimedia: 'Multimedia variable',
      url_nueva: 'New URL',
      sort: 'Sort',
      for: 'for',
      of: 'of',
      creation: 'Creation',
      change_password: 'Change password now',
      change_password_error: 'Your new password does not match "Repeat password (again)". Please write it correctly.',
      change_password_ok: 'Your password has been changed successfully.',
      change_password_no: 'Authentication failed. Incorrect username or password.',
      delete_contact: "Delete contact",
      delete_question: "Could not delete question",
      delete_schema: "Do you want to delete this schema permanently?",
      error_selected_bot: "No bot selected",
      bot_id: "Bot ID",
      bot_version: "Bot Version",
      box: "Boxes",
      component: "Component",
      time_dimention: "Time dimension",
      with: "With",
      without: "Without",
      scala: "Scale",
      close: "Close",
      measures_tree: "Measures tree",
      default_title: "Default title",
      verify_email: "Verify Email",
      complete_info: "Please fill in all fields",
      user_variable: "User Variables",
      user_variable_msg: "To create a new variable, enter the name and press enter.",
      validate_number_text: "Only letters or numbers are allowed!",
      enter_create: "Press Enter to create",
      code: "Insertion code",
      copy_code: "Copy code",
      name_new_variable: "Enter the name of the new variable.",
      corner_lower_right: "Lower right corner",
      border_lower_center: "Lower center border",
      corner_up_right: "Upper right corner",
      corner_up_left: "Upper left corner",
      follow_asking: "Continue asking",
      title_add_register: 'Do you confirm you want to add new records?',
      add_register: 'Are you sure you want to add new records?',
      title_delete_register: 'Do you confirm you want to delete current records and add new ones?',
      delete_register: 'Are you sure you want to delete current records and add new ones?',
      confirm: 'Confirm',
      file_encripted: 'Will the files be stored encrypted?',
      db_generate_msg: 'The database will continue to be generated, and a message will be sent to the email when it is ready'
    },
  },
    pr: {
      translation: {
      warning5:"O texto do botão Enviar pode ser um ícone ou texto do usuário com no máximo 10 caracteres",
      warning6:"Ativa ou desativa a entrada de texto do chat quando o bot exibe uma opção de seleção com botões.",
      warning7:"Local no site onde o bot será exibido",
      sidebarColor:"Selecione a cor da barra lateral",
      faviconCompany:"Selecione o favicon da empresa",
      navbarColor:"Selecione a cor da barra de navegação superior",
      logoCompany:"Selecione o logotipo da empresa",
      sign_in: "Iniciar sessão ",
      password: "Senha ",
      email: "Correio eletrônico ",
      next: "Seguindo ",
      username: "Do utilizador ",
      otp_token: "Por favor, insira o token de autenticação que foi enviado para o correio",
      otp_token_verify: ". Se o token for selecionado, você poderá continuar",
      init_session: "Iniciar sessão ",
      init_session_btn: "Entrar ",
      edit_profile: "Editar Perfil ",
      close_session: "Fechar Sessão ",
      new_bot: "Novo bot ",
      search: "Procurar ",
      modified_in: "Modificado em ",
      created_in: "Criou o ",
      close_in: "Fechado ",
      test: "Provar ",
      edit: "Editar ",
      export: "Exportar ",
      duplicatedBot: "Bot duplicado ",
      import: "Matéria ",
      create_bot: "Criar bot ",
      import_bot: "Importar bot ",
      create_schema: "Criar esquema ",
      import_schema: "Esquema de importação ",
      duplicatedShema: "Esquema duplicado ",
      delete: "Excluir ",
      company: "Empresa",
      select_coleccion: "Selecione a coleção",
      base_de_datos_sobrescribir: "Base de dados a sobrescrever",
      base_de_datos_add_new_register: "Adicionar novos registros",
      base_de_datos_delete_register: "Excluir registros atuais e adicionar novos",
      Eliminar: "Excluir",
      name_coleccion: "Nome da coleção",
      nueva_coleccion: "Nova coleção",
      admin_companies: "Administração de Empresas ",
      admin_database:   "Bancos de dados",
      group_for: "Grupo por ",
      start_date: "Data de início ",
      end_date: "Data final ",
      send_date: "Data de envio ",
      filter: "Filtro ",
      year: "Ano ",
      month: "Mês ",
      day: "Dia ",
      week: "Semana",
      hour: "Hora ",
      consolidate: "Consolidado ",
      print: "Imprimir ",
      see_columns: "Veja colunas ",
      filter_table: "Tabela de filtro ",
      table: "Tabela ",
      reports: "Relatórios ",
      report: "Relatório ",
      report_interactions: "Relatório de interações ",
      report_personalized: "Relatório personalizado ",
      report_blockchain: "Relatório Blockchain ",
      blockchain: "Blockchain",
      report_email: "Relatório de e -mail ",
      report_sms: "Relatório SMS ",
      admin_metrics: "Administração métrica ",
      admin_graphics: "Administração de gráfico ",
      admin_dashboard: "Administração do painel ",
      first_1000_records: "Os primeiros 10.000 registros serão listados, para acessar as informações completas, faça o download do XLSX Data Chivo ",
      without_personalized_report: "Você ainda não tem relatórios personalizados ",
      hear_found_personalized_report: "Aqui você encontrará os relatórios personalizados do bot ",
      in_report_box_values: "Inclui uma caixa de relatório no fluxo com os valores que você deseja armazenar para gerar o relatório personalizado. ",
      email_reports: "Relatório de e -mail ",
      registers_not_founded: "Nenhum registro foi encontrado no período selecionado ",
      name: "Nome ",
      name_short: "Nome curto ",
      description: "Descrição ",
      unit: "Unidade ",
      indicator_type: "Tipo de indicador ",
      metric_type: "Tipo de medida ",
      edition_option: "Opções/edição ",
      general_information: "Medida de informação geral ",
      minutes: "Minutos ",
      minute: "Minuto ",
      functional: "Funcional ",
      dimensions: "Dimensões ",
      dimension: "Dimensão ",
      graphic_type: "Tipo Gŕaphic ",
      info_graphic: "Informações gráficas ",
      previews: "Visualização ",
      info_data_last_month: "Se não houver dados no último mês do indicador selecionado, os dados de teste serão exibidos ",
      metric: "Métricas ",
      select_graphics_type: "Selecione um tipo de gráfico ",
      info_dashboard: "Informações do painel ",
      dashboard_title: "Título do painel ",
      channel: "Canal ",
      date: "Data ",
      registers_not_founded: "Nenhum registro foi encontrado ",
      title: "Qualificação ",
      rename: "Renomear ",
      flow: "Fluxo ",
      new_flow: "Criar novo fluxo ",
      start_flow: "Fluxo inicial ",
      anonymous_flow: "Fluxo anônimo ",
      anonymous_flow_deactivate: "Desativar o fluxo anônimo ",
      project_name: "Nome do projeto ",
      bot_name: "Nome do bot ",
      language: "Linguagem ",
      spanish: "Espanhol ",
      platform: "Plataforma ",
      primary_platform: "Plataforma principal ",
      web: "Rede ",
      add_platforms: "Plataformas adicionais ",
      font_size: "Tamanho da fonte",
      header_background: "Fundo do cabeçalho ",
      user_balloon_background: "Fundo do balão do usuário ",
      bot_balloon_background: "Fundo do balão do bot ",
      background_chat: "Fundo do chat ",
      background_buttons: "Fundo dos botões ",
      border_color_buttons: "Cor da borda dos botões ",
      color_text_balloon_user: "Cor do texto do balão do usuário ",
      color_text_balloon_bot: "Cor do texto do balão do bot ",
      color_text_buttons: "Cor do texto dos botões ",
      color_text_send_button: "Cor do texto do botão enviar ",
      color_link_balloon_user: "Cor dos links do balão do usuário ",
      color_link_balloon_bot: "Cor dos links do balão do bot ",
      background_button_send: "Fundo do botão enviar ",
      background_chat_close: "Fundo do fechar chat ",
      color_chat_close: "Cor do fechar chat ",
      text_button_send: "Texto do botão enviar ",
      use_icon_text: "Usar ícone usar texto ",
      in_text_chat: "Entrada de texto do chat ",
      enable: "Habilitar ",
      disable: "Desativar ",
      locate_bot: "Localização do bot ",
      bot: "Bot",
      corner_lower_left: "Inquérito de canto inferior ",
      up_bot_start: "Implantar bot ao iniciar na web",
      yes: "Sim ",
      avatar_animation: "Animação de Avatar ",
      nothing: "Nenhum ",
      placeholder_field_text: "Campo de texto de bate -papo de texto de espaço reservado ",
      write_message: "Escreva sua mensagem ",
      msg_button_back_ia: "Botão de mensagem retorna ",
      back: "Volte ",
      msg_button_continue_ia: "Botão de mensagem continue ia ",
      msg_return: "Mensagem para retornar ",
      return: "Volte ",
      continue:"Continuar",
      word_return_queue_pa: "Palavra para deixar a cauda de espera do agente ",
      firts_timeout: "Primeiro tempo de espera ",
      second_timeout: "Segundo tempo de espera ",
      third_timeout: "Terceira espera ",
      firts_msg_timeout: "Primeira mensagem Tempo de espera ",
      second_msg_timeout: "Segunda mensagem Tempo de espera ",
      third_msg_timeout: "Terceira mensagem Tempo de espera ",
      test_phone_number: "Testando o número do telefone celular ",
      contact_list: "Lista de contatos ",
      phone_celnumber: "Número de celular ",
      select_one: "Escolha um ",
      platforms: "Plataformas ",
      url_chat_pa: "Agente de bate -papo de URL ",
      msg_not_connect: "Mensagem de agente não conectado ",
      template_email: "Modelo de email ",
      avanced_chat: "Bate -papo avançado ",
      size_files: "Tamanho do arquivo ",
      sender_voice: "Remetente de voz ",
      for_defect: "Por padrão ",
      sender_email: "Remetente de e -mail ",
      url_integration: "URL de integração ",
      user_verification_code: "Código de verificação do usuário ",
      token_facebook: "Token do Facebook ",
      groups_qna: "Grupon QNA ",
      group: "Conjunto ",
      operation: "Operação ",
      operations: "Operações ",
      activate_ia: "Ativar IA ",
      basic_data: "Dados básicos ",
      visual_aparience: "Aparência visual ",
      advanceds: "Avançado ",
      integrations: "Integrações ",
      avatar: "Avatar",
      avatars: "Avatares ",
      home_screen_portal: "Portal da tela inicial ",
      home: "Inicial",
      default: "Por padrão ",
      activate_bot: "Ative Bot ",
      avatars_list: "Lista de Avatar ",
      window_chat_close: "Janela de bate -papo fechada ",
      bot_not_understand: "Bot não entende ",
      bot_writing: "Escrita de bot ",
      start_chat: "Início do bate -papo ",
      edit_avatar: "Editar Avatar ",
      upload_image: "Arraste ou clique para selecionar a imagem ",
      file_selected: "Selecionar arquivo para importar",
      nothing_select_file: "Nenhum arquivo selecionado ",
      limit_image: "Recomenda -se que as dimensões da imagem não sejam maiores que 500px a proporção 1: 1 (quadrado) e o tamanho da ascensão não é superior a 200 kb ",
      module_ia: "Módulo de inteligência artificial ",
      configure_modules: "Configuração do módulo ",
      created_modules: "Módulos criados ",
      questions_without_response: "Perguntas de resposta em particular ",
      change_setting: "Alterar ajuste ",
      create_module: "Criar módulo ",
      module_web_views: "Módulo de visualização da web ",
      list_web_views: "Lista de visualizações da web ",
      create: "Criar ",
      connect: "Link ",
      stadistics: "Estatisticas ",
      dashboard_general: "Painel geral ",
      tecnology: "Tecnologia ",
      total_users: "Usuários totais ",
      interval_dates: "Intervalo de datas ",
      funnels_list: "Lista de funil ",
      msg_conversion_actions: "Medir conversas de uma sequência de ações ",
      funnel_msg_create: "Crie um funil para obter mais informações sobre a taxa de conversão e o tempo de conclusão de qualquer fluxo específico do seu aplicativo ou site. ",
      funnel_name: "Nome do funil ",
      events: "Eventos ",
      create: "Criar ",
      cancel: "Cancelar ",
      event_list: "Lista de evento ",
      msg_consult_actions: "Verifique as ações que as pessoas executam em seu produto. ",
      event: "Evento ",
      total_interactions: "Interações totais ",
      percentage: "Percentagem ",
      creation: "CRIAÇÃO ",
      rows_filters: "Linhas por página ",
      general_report: "Relatório Geral ",
      user: "Do utilizador ",
      registers_not_found_dates: "Parece que não há dados para esse intervalo de datas, selecione outro. ",
      statement: "Declaração ",
      operator_title: "Título do Identificador do Operador ",
      campaign_massive: "Campanhas maciças ",
      campaign_look: "Veja campanhas ",
      shipping_list: "Listas de remessa ",
      save: "Manter ",
      refresh_template_email_list: "Reabastecer a lista de modelos de e -mail ",
      select_option: "Selecione uma opção ",
      new_template: "Novo modelo ",
      email_template: "Modelo de email ",
      configure_campaign: "Configure a campanha ",
      campaign_type: "Tipo de campanha ",
      new_list: "Nova lista ",
      refresh_contact_list: "Lista de contatos de creme ",
      message: "Mensagem ",
      add_url: "Adicione URL ",
      subject: "Assunto ",
      stop_campaign: "Stop Campaign ",
      new_campaign: "Nova campanha ",
      msg_without_campaign: "Você ainda não tem campanhas ",
      msg_here_existing_campigns: "Aqui você encontrará as campanhas existentes ",
      upload_file: "Subir arquivo ",
      optional: "Opcional",
      bot_edition: "Edição de bot ",
      info_edition: "Adicionar, editar, dobrar ou eliminar módulos diferentes dentro do seu bot, cada módulo fornece funções diferentes ao seu bot ",
      info_upload_file: "Defina a mensagem para enviar para o seu bot para solicitar anexos e a mensagem para cancelar o arquivo ",
      info_webview: "Você pode vincular seu bot a diferentes formulários que você criou no menu lateral da Webform ",
      buttons_list: "Lista de botões ",
      info_buttons_list: "Adicione às suas listas de botões e a cada atribuição para redirecionar para um URL ou continuar o fluxo ",
      carousel: "Carrossel ",
      carousel_info: "Crie um carrossel de imagens carregadas com URLs diferentes e para atribuir botões diferentes que podem redirecionar um URL ou avançar no fluxo ",
      form: "Forma ",
      form_info: "Adicione ao seu formulário de formulário curto para perguntar ao usuário ",
      quick_replies_info: "Atribua as respostas predefinidas à pergunta que o usuário fez, na saída que pode ser atribuída que a resposta segue o fluxo geral do bot ou segue um fluxo individual para essa resposta ",
      calendar: "Calendário ",
      calendar_info: "A caixa do calendário é uma caixa que deve permitir que os usuários de bot selecionem uma data (com ou sem tempo) ou intervalo de datas dentro de um calendário ",
      location: "Localização ",
      location_info: "A caixa de localização é uma caixa que deve permitir que os usuários de bot selecionem um ou mais locais ",
      multimedia:"Multimídia",
      multimedia_info: "A caixa multimídia é uma caixa que deve permitir que os usuários de inicialização recebam arquivos de imagem, áudio, vídeo ou documento ",
      report_info: "Os relatórios são uma caixa que deve permitir que os usuários de bot criem relatórios personalizados que armazenam o valor das variáveis ​​selecionadas ",
      blockchain_info: "A caixa de blockchain é uma caixa que deve permitir que os usuários de bot salve o valor das variáveis ​​selecionadas no blockchain ",
      scanner: "Scanner ",
      scanner_info: "O scanner QR é uma caixa que deve permitir que os usuários de bot leiam um código QR usando a câmera ",
      catalog: "Catálogo ",
      catalog_info: "A caixa de catálogos é uma caixa que permite que os usuários de bot fazem compras através de um catálogo de produtos ",
      payments_online: "Pagamentos online ",
      payments_online_info: "A caixa de pagamento on -line é uma caixa que permite que os usuários de pilhagem faça pagamentos on -line através de diferentes passarelas de pagamento ",
      plane_text: "Texto de avião ",
      quick_replies: "Resposta rápida ",
      external_api: "API externa ",
      conditional: "Condicional ",
      switch_to_agent: "Etapa do agente ",
      custom_report: "Relatório personalizado ",
      unsubscribe: "Desconforto ",
      contacts: "Contatos ",
      variable: 'Variáveis',
      edit_component_info: "Encaminhe este componente e adicione uma instrução. ",
      info_error_reload_flow: "UPS! Tivemos problemas para carregar o fluxo que você deseja editar, vou carregá -lo novamente. ",
      edit_component_info2: "Encaminhe este componente e adicione suas opções. ",
      edit_component_info3: "Edite este componente e adicione a mensagem de bot e cancelamento ",
      web_view: "Visualização da web ",
      info_calendar: "Selecione a configuração do calendário ",
      scanner_qr_info: "Selecione a configuração do scanner QR ",
      catalog_config_info: "Selecione a configuração do catálogo. ",
      payment_method_info: "Selecione a configuração do método de pagamento ",
      name_info_variable: "Nome para comprimento do usuário",
      name_info_variable_latitud:"Nome para latitude do usuário",
      multimedia_config_info: "Selecione a configuração da caixa multimídia ",
      report_config_info: "Selecione a configuração do relatório ",
      service_not_found: "serviço não encontrado ",
      blockchain_config_info: "Para usar a caixa Blockchain, você deve comprar o serviço e ter pelo menos um contrato inteligente criado. ",
      blockchain_config_info2: "Selecione a configuração da corrente de bloco ",
      soporte_info: "Entre em contato com o suporte para contratar este serviço. ",
      start_api: "API doméstica ",
      end_api: "API END ",
      switch_to_agent_config_info: "Edite este componente e adicione as mensagens do bot ",
      conditional_config_info: "Edite este componente e adicione a mensagem de bot e cancelamento ",
      unsubscribe_config_info: "Edite este componente e adicione uma mensagem de declaração e despedida ",
      email_config_info: "Edite este componente, adicione um assunto, remetente, lista, etc. ",
      trigger_config_info: "Edite este componente ... ",
      variable_config_info: "Edite este componente para configurar suas variáveis. ",
      msg_confirm_delete: "Deseja eliminar esse operador permanentemente? ",
      msg_delete_nodo: "O nó foi eliminado com sucesso. ",
      message_scheme: "Desculpe, de acordo com a configuração da plataforma de bot, não sei se eles permitem caixas de carrossel, levam -as embora para continuar mantendo o fluxo. ",
      message_scheme2: "Desculpe, de acordo com a configuração da plataforma de bot, não sei os quadros da lista de botões, elimine -os para continuar mantendo o fluxo. ",
      message_scheme3: "Desculpe, de acordo com a configuração da plataforma de bot, não sei permitir caixas do tipo imagem, levá -las para continuar mantendo o fluxo. ",
      message_scheme4: "Desculpe, de acordo com a configuração da plataforma de bot, não permito caixas multimídia, levando -as para continuar mantendo o fluxo. ",
      message_scheme5: "Desculpe, de acordo com a configuração da plataforma de bot, não sei permitir caixas de assinatura, levá -las para continuar mantendo o fluxo. ",
      message_scheme6: "Lembre -se de marcar qualquer operador como 'Start' clicando na casa para que o script tenha um ponto de partida ",
      message_scheme7: "Fluxo economizado com sucesso. ",
      message_scheme8: "Havia problemas para salvar o fluxo, tente novamente. ",
      configure_event: "Configure o evento ",
      event_name: "Nome do evento ",
      finish: "Guardar mudanças ",
      without_text: "Nenhum texto ",
      scanner_code: "Código de digitalização ",
      flows: "Flui ",
      settings: "Configurações ",
      setting_module: "Configure o módulo ",
      questions_multiple_ask: "Perguntas com várias respostas ",
      ia: "Inteligência artificial ",
      notifications: "Notificações ",
      switch_to_agent_var: "Agente variável ",
      switch_to_agent_msg: "Selecione a variável que contém as informações da etapa do agente para redirecionar ",
      switch_to_agent_msg2: "Selecione as variáveis ​​que você deseja enviar para o agente da API Step ",
      switch_to_agent_msg3: "Configure a etapa do agente ",
      variable_json_api:"Variável API JSON",
      basic: "Essencial ",
      advanced: "Avançado ",
      welcome_msg: "Mensagem de boas-vindas ",
      welcome_msg2: "Mensagem de boas -vindas à etapa do agente que será impressa no bot ",
      add_message: "Você deve adicionar uma mensagem ",
      switch_to_agent_url: "Agente de etapa de URL ",
      switch_to_agent_msg4: "URL que se conecta com o agente da API ",
      attention: "Atenção ",
      message1: "Se você não especificar um URL, o que foi definido na configuração do bot será levado ",
      message2: "Escreva o URL da etapa para o agente ",
      other_vars: "Outras variáveis ",
      name_key: "Nome -chave ",
      value: "Valor ",
      name_var: "Nome variável ",
      url_api: "API URL ",
      will_be_consumed: "que será consumido ",
      field_required: "Este campo é obrigatório ",
      validate_result: "Validar resultado ",
      message3: "Sessão e ID de usuário ",
      send_method: "Método de envio ",
      message4: "Método que será usado para consumir a API ",
      shipping_coding: "Codificação de envio ",
      type_coding: "Tipo de codificação em que os dados serão enviados para a API ' ",
      txt_long_edit_component:"Edite este componente e adicione a mensagem do bot e o formulário da web",
      select: "Selecione ",
      headers: "Cabeçalhos ",
      action: "Ação ",
      actions: "Ações ",
      add_headers: "Adicione o cabeçalho ",
      data_sended: "Dados enviados ",
      add_key: "Adicione a chave ",
      data_received: "Informação recebida ",
      close_windows: "Janela de bate -papo fechada ",
      user_writing: "Escrita do usuário ",
      configure_bot: "Configuração de bot ",
      save_changes: "Guardar mudanças ",
      visual_appearance: "Aparência visual ",
      bot_to_create: "Sobre o bot para criar ",
      languages_msg: "Idiomas em que será ativado a partir do bot ",
      background_header: "Plano de fundo ",
      message5: "Pode ser um ícone ou um texto do usuário que não exceda 10 caracteres ",
      use_text: "Use texto ",
      use_icon: "Use o ícone ",
      message6: "Habilite ou desative a entrada do texto de bate -papo quando o bot visualizar alguma opção de seleção com os botões. ",
      keep_web_history: "Mantenha o histórico de bate -papo na web ",
      keep_web_history_info: "Ativar ou desativar o histórico de bate -papo. Se estiver ativado, você mostrará as conversas anteriores do usuário. ",
      message7: "Localização no site onde o bot será exibido ",
      enable_up_window_bot: "Ativar implantação automática da janela do bot. ",
      description_field_text: "Descrição do campo de texto na caixa de inicialização ",
      timeout_type_first: "Tipo de primeiro tempo limite ",
      timeout_type_second: "Tipo de segundo tempo limite ",
      timeout_type_third: "Terceiro Tipo de Timeout ",
      must_select_scheme: "Você deve selecionar um esquema ",
      must_select_operator: "Você deve selecionar um operador",
      infor_select_operator: "Operador a ser usado para comparar os dados e avaliar o código",
      send_message: "Enviar mensagem ",
      go_to: "Vai ",
      add_contact_list: "Adicionar lista ",
      this_list_have: "Esta lista tem ",
      duplicate: "repetido ",
      register: "registro ",
      recharge_template: "Modelo de recarga ",
      size_files_max: "O tamanho máximo permitido para os arquivos serem carregados na plataforma ",
      new_avatar: "Novo avatar ",
      use_avatar: "Uso de avatar ",
      message8: "Declaração que será impressa no bot ",
      message9: "Variável visível do usuário",
      message10: "Nome da variável onde as informações selecionadas serão armazenadas ",
      message11: "Nome onde um JSON será armazenado com as informações selecionadas e a estrutura de formato, dependendo do tipo de calendário selecionado ",
      type_calendar: "Tipo de calendário ",
      type_calendar_config: "Tipo de calendário que será configurado ",
      time_zone: "Fuso horário ",
      time_zone_config: "Fuso horário que lidará com o calendário ",
      minimum_date: "Data mínima ",
      minimum_date_config: "Data mínima que permitirá a seleção do calendário ",
      maximum_date: "Data máxima ",
      maximum_date_config: "Data máxima que permitirá a seleção do calendário ",
      date_selected_default: "Data selecionada por padrão ",
      date_selected_default2: "Data que aparecerá selecionada por padrão no calendário ",
      current_date: "Data atual ",
      carouse_info: "Selecione a variável que contém as informações do carrossel ",
      var_carousel: "Variável de carrossel ",
      max_20_chars: "Máximo 20 caracteres ",
      advance: "Avançar ",
      new_tab: "Cílio ",
      reload: "Recarrega ",
      invalid: "Invalida ",
      type: "Cara ",
      add_new: "Adicionar novo ",
      set_up: "Configurar ",
      image: "Imagem ",
      load_data_from_var: "Carregar dados da variável ",
      carousel_info2: "Se você selecionar esta opção, os dados do carrossel devem ser carregados de uma variável ",
      buttons: "Botões ",
      max_buttons_allowed: "Botões máximos permitidos ",
      catalog_type: "Tipo de catálogo ",
      catalog_type_info: "Selecione o tipo de catálogo que você deseja usar ",
      customer: "Cliente ",
      id_final_user: "Digite o ID que identificará o usuário final ",
      message12: "Variável para armazenar o ID do pedido ",
      message13: "Nome da variável onde o ID da ordem gerado será armazenado ",
      message14: "Já existe uma variável com esse nome no bot, use outro nome. ",
      message15: "Variável para armazenar a referência de pagamento ",
      message16: "Nome da variável em que a referência será armazenada para gerar o pagamento do pedido gerado ",
      message17: "Fonte de informações do produto ",
      message18: "Selecione a fonte de informações da qual os produtos serão trazidos para visualizar no catálogo ",
      message19: "Variável com lista de produtos ",
      message20: "Selecione a variável que contém as informações dos produtos a serem exibidos no catálogo ",
      stock_keys: "Id Keys de armazém ",
      message21: "Digite o ID fornecido pela equipe de desenvolvimento que armazena as chaves de acesso ",
      message22: "Tags que serão usadas como categorias ",
      message23: "Digite o nome de todas as coleções que você deseja usar como categorias no catálogo ",
      write_enter:"Digite e pressione enter..",
      limit_allowed: "Limite permitido ",
      numeric: "Numérico ",
      text: "Texto ",
      phone: "Telefone ",
      question: "Perguntar ",
      validation: "Validação ",
      allowed_box: "Caixas máximas permitidas ",
      add: "Adicionar ",
      type_location_msg: "Tipo de localização que será usada ",
      message_error:"Digite uma mensagem de erro",
      message_error_label:"Mensagem de erro",
      extension_archivos_label:"Extensões de arquivo",
      extension_archivos_icon:"Digite a extensão para permitir ex: .png .pdf",
      location_list: "Lista de locais ",
      location_list_msg: "Selecione a lista de locais que serão usados ",
      location_list_msg2: "Crie uma lista de locais a serem atribuídos ",
      add_var: "Adicione variável ",
      columns_list: "Lista de colunas ",
      columns_list_msg: "Selecione a coluna que você usará como uma variável ",
      name_var_msg: "Nome da variável que salvará as informações da coluna ",
      latitud_var: "Variável com latitude ",
      latitud_var_info: "Selecione a variável que contém as informações da latitude para representar o gráfico ",
      message24: "Não há variáveis ​​criadas ",
      longitud_var: "Variável com comprimento ",
      longitud_var_info: "Selecione a variável que contém as informações do comprimento para o gráfico ",
      icono_var: "Variável com ícone ",
      message25: "Selecione a variável que contém a imagem que será usada no ícone para colocar a ubicion no mapa ",
      message26: "Digite as informações que serão mostradas ao clicar no ícone do local gráfico ",
      message27: "Já existe uma variável com esse nome no bot, use outro nome. ",
      configure_file: "Configure o arquivo ",
      message28: "Como você deseja carregar o arquivo multimídia ",
      files_locals: "Arquivos locais ",
      template: "Modelo ",
      message29: "Nome da variável onde o URL selecionado será armazenado ",
      message30: "Já existe uma variável com esse nome no bot, use outro nome. ",
      no:"não",
      file: "Arquivo ",
      upload_file: "Upload de arquivo ",
      message31: "Arraste ou clique para selecionar o arquivo ",
      var_url_file: "Variável com o URL do arquivo ",
      message32: "Selecione a variável que contém as informações do arquivo para carregar ",
      select_template_for_file: "Selecione o modelo para gerar o arquivo ",
      template_use_for_file: "Modelo que será usado para gerar o arquivo ",
      message33: "Não há modelos a serem atribuídos ",
      message34: "Que tipo de arquivo você deseja criar ",
      select_file_to_create: "Selecione o tipo de arquivo que será criado ",
      template_vars: "Variáveis ​​de modelo ",
      create_vars_msg: "Crie as variáveis ​​que serão usadas no modelo para gerar o arquivo ",
      configure_payment_online: "Configure pagamentos on -line ",
      payment_gateway: "Gateway de pagamento ",
      payment_type_select: "Selecione a passarela de pagamento para usar ",
      payment_var_code: "Variável para armazenar o código de pagamento ",
      payment_var_id: "Nome da variável onde o ID de pagamento será armazenado ",
      payment_var_amount: "Selecione a variável que contém o valor a pagar ",
      payment_reference: "Referência de pagamento ",
      payment_var_reference: "Selecione a variável que contém o ID da referência de pagamento ",
      payment_var_currency: "Selecione a moeda em que o pagamento será feito ",
      whatsapp_menu_interactive: "Menu interativo do WhatsApp ",
      message35: "Se você selecionar esta opção, um menu interativo será exibido no WhatsApp ",
      statement_type: "Tipo de declaração ",
      configure_scanner: "Configure o scanner ",
      scanner_type_code: "Tipo de código para digitalizar ",
      scanner_type_code_msg: "Selecione o tipo de código que você deseja que os usuários digitalizem ",
      scanner_name_var_code: "Nome da variável em que as informações digitalizadas do código QR serão armazenadas ",
      configure_plane_text: "Configure texto plano ",
      statements: "Declarações ",
      message36: "A declaração não pode estar vazia ",
      sended_time: "Tempo de envio ",
      immediate: "Imediato ",
      scheduled: "Programado ",
      number: "Número ",
      days: "Dias ",
      hours: "Horas ",
      phone_number_to_msg: "Número da célula onde a mensagem de texto será enviada ",
      sms_message_info: "Escreva abaixo a mensagem a ser enviada. Máximo de 160 caracteres. ",
      configure_upload_file: "Configure o arquivo up ",
      text_button_cancel: "Cancelar o texto do botão de ação ",
      text_button_cancel_msg: "Texto que aparecerá no botão Cancelar ",
      add_name_var: "Você deve adicionar o nome da variável ",
      select_form_llink_up: "Selecione um formulário para vincular ",
      message37: "Selecione a visualização da web que será exibida no bot ",
      show_x_close_webview: "Mostre x para fechar a visualização da web ",
      valid_dimensions: "Dimensões válidas ",
      valid_size: "Tamanho válido ",
      drag_click_image: "Arraste ou clique para selecionar a imagem ",
      edit_flow: "Editar fluxo ",
      names: "Nomes ",
      last_name: "Sobrenomes ",
      confirm_pwd: "Confirmar senha ",
      block_account: "Sua conta foi bloqueada por motivos de segurança devido a diversas tentativas de login malsucedidas. Aguarde 15 minutos antes de tentar novamente.",
      oldpassword: "Senha Antiga",
      newpassword: "Nova senha",
      repeatpassword: "Repita a senha novamente",
      titleChangepass: "Senha e segurança",
      textChangepass: "Alteração de senha: Proteja sua conta. Escolha uma senha forte e exclusiva para garantir sua segurança online. Não compartilhe sua senha e atualize-a regularmente. Mantenha sua conta segura!",
      noteChangepass: "Nota: Se você alterar a senha, a sessão atual será encerrada.",
      try_again:"Tente novamente com uma senha que você nunca usou antes",
      change_password_editar_60: " Alterar senha a cada 90 dias",
      change_password_60: " Alterar senha a cada 60 dias",
      use_var: "Use variável",
      use_var_as:"Usar como variável",
      define_name_varible:"Defina um nome para que você possa usar o arquivo como variável",
      intent: "Testado ",
      select_intent_operator: "Selecione a tentativa relacionada a este operador ",
      ia_message: "Não há tentativas de configurar para ser atribuído ",
      inactivity: "Inatividade ",
      invalid_phone: "Número inválido ",
      timeout_message: "Tempo em inatividade (segundos) de que o bot deve esperar para gravar a mensagem padrão, o tempo mínimo permitido é 1 segundo. ",
      seconds: "Segundos ",
      timeout_message2: "Mensagem para mostrar assim que o tempo de inatividade estabelecido é cumprido ",
      message39: "Selecione a caixa a ser direcionada no fluxo selecionado anteriormente ",
      message38: "Através deste título, você pode identificar o operador e alcançá -lo através de uma caixa ",
      message40: "Selecione o fluxo que você deseja abordar ",
      provider: "Fornecedor ",
      phone_number: "Número de telefone ",
      write_phone_number: "Digit o número de telefone ",
      message41: "Selecione o avatar relacionado a este operador ",
      message42: "Selecione o nome do llaveapi/scheme/statement/ ",
      column_title: "Título da coluna ",
      message43: "Este é o nome que terá a coluna que será armazenada em blockchain ",
      message44: "Este é o valor que terá a coluna que será armazenada em blockchain ",
      message45: "Configure o armazenamento de blockchain ",
      message46: "Selecione o provedor de serviços blockchain ",
      contract: "Contrato ",
      message47: "Selecione o contrato com o qual as informações serão armazenadas ",
      message48: "Você ainda não tem contratos de blockchain ",
      message49: "Se você tiver um contrato de blockchain, pode desequilibrar suas informações com segurança e ",
      message50: "Para acessar essa funcionalidade, você deve contratar o serviço Blockchain. ",
      select_var: "Selecione uma variável",
      compare_value:"Valor para comparar",
      select_var_compare: "Selecione a variável que deseja comparar ",
      verify_list_fields: "Por favor, verifique se todos os campos estão cheios. ",
      configure_conditional: "Configure condicional ",
      write_subject: "Escreva o assunto ",
      select_template: "Selecione modelo ",
      reload_template_email_list: "Reabastecer a lista de modelos de e -mail ",
      email_receiver: "Correio do destinatário ",
      email_cc: "Copie e -mails ",
      value_var: "Valor variável ",
      events_message: "Selecione os eventos que deseja usar ",
      delivered: "Entregue ",
      message51: "Lorem ipsum é simplesmente o texto recheado dos arquivos de impressão e texto. ",
      open: "Abrir ",
      refused: "Recusou ",
      links_event: "Clique em Links de eventos ",
      maximum: "Máximo ",
      add_link: "Adicione link ",
      metric: "Métricas ",
      select_color: "Selecione uma escala de cor ",
      without_metrics: "Você ainda não tem métricas ",
      metrics_message: "Para criar um gráfico, é necessário ter criado pelo menos uma métrica ",
      create_metric: "Criar métrica ",
      update_info: "Atualizar informação ",
      create_new_dashboard: "Crie um novo painel ",
      description_dashboard: "Descrição do painel ",
      dashboard: "Painel ",
      select_graphics: "Selecione os gráficos ",
      message52: "Você ainda não tem gráficos ",
      message53: "Para criar um painel, é necessário ter criado pelo menos um gráfico ",
      create_graphic: "Criar gráfico ",
      validate_message: "A senha deve ter pelo menos 10 caracteres ",
      validate_message2: "A senha deve ter no mínimo 10 caracteres e pelo menos uma letra minúscula, uma letra maiúscula, um dígito e um caractere especial. ",
      validate_message3: "O nome de usuário deve ter pelo menos 10 caracteres. ",
      validate_message4: "O nome de usuário deve conter apenas letras e números. ",
      validate_message5: "O nome de usuário não pode conter espaço. ",
      validate_message6: "Formato de email não válido ",
      validate_message7: "Selecione uma função. ",
      rol:"Função. ",
      validate_message8: "Você tem certeza de excluir este usuário? ",
      admin_users: "Gerenciamento de usuários ",
      admin_user_message: "Você ainda não tem usuários ",
      admin_user_message2: "Aqui você encontrará os usuários existentes da empresa ",
      admin_user_message3: "Crie um usuário para usar a plataforma. ",
      create_user: "Criar usuário ",
      new_password: "Nova senha ",
      admin: "Administrador ",
      campaigns: "Sinos ",
      edit_user: "Editar usuário ",
      message54: "É dinâmico ",
      enable_dinamic_operator: "Ativar operador dinâmico ",
      upload_image2: "Imagem para cima ",
      create_operator_msg: "Operador criou satisfatoriamente! ",
      operator_created_msg: "Erro ao criar o operador, tente novamente! ",
      operator_edited_msg: "Operador editado corretamente ",
      operator_edited_msg2: "Erro ao atualizar o operador, tente novamente! ",
      operator_delete_msg: "Operador excluído corretamente ",
      operator_delete_msg2: "Erro eliminando o operador, tente novamente! ",
      operator_delete_msg3: "Erro eliminando o operador dinâmico, tente novamente! ",
      statement_create_msg: "Erro ao criar a declaração, tente novamente! ",
      statement_edit_msg: "Erro ao atualizar a instrução, tente novamente! ",
      operator_list_msg: "Erro para obter a lista de operadores, tente novamente! ",
      statements_list_msg: "A lista de variáveis ​​não pôde ser obtida! ",
      statements_list_msg2: "Erro para obter a configuração das variáveis! ' ",
      statements_list_msg3: "Erro para obter a lista de empresas! ",
      whatsapp_message: "A mensagem WS foi enviada! ",
      whatsapp_message2: "Erro ao criar a mensagem para WS tentou novamente! ",
      message55: "Você não conseguiu a lista de empresas! ",
      message56: "A lista de tipos de calendário não pôde ser obtida! ",
      message57: "Erro para obter a lista de tipos de calendário! ",
      location_msg: "A lista de tipos de localização não pôde ser obtida! ",
      location_msg2: "Erro para obter a lista de tipos de localização! ",
      location_msg3: "A lista de locais não pôde ser obtida! ",
      location_msg4: "Erro para obter a lista de locais! ",
      blockchain_msg: "Você não conseguiu a lista de fornecedores de blockchain! ",
      blockchain_msg2: "Erro para obter a lista de fornecedores de blockchain! ",
      blockchain_msg3: "Você não conseguiu a lista de contratos de blockchain! ",
      blockchain_msg4: "Erro para obter a lista de contratos de blockchain! ",
      message58: "Mensagem exibida ao usuário no bot",
      message59: "Mensagem antes do envio do link para o usuário cancelar a inscrição",
      welcome_msg: "Mensagem de despedida",
      message60: "Um erro ocorreu!",
      form_msg: "Formulário salvo com sucesso",
      form_msg2: "Desculpe, o formulário não pôde ser salvo",
      message189: "Você deve salvar antes de editar.",
      message61: "Não foi possível atualizar o item, tente novamente",
      message62: "Excede o tamanho permitido",
      message63: "Imagem enviada com sucesso.",
      config_msg: "Configuração salva com sucesso.",
      config_msg2: "Não foi possível atualizar a configuração",
      warning_msg: "O número de telefone digitado não é válido. Por favor, verifique.",
      message64: "As dimensões da imagem não estão corretas",
      message65: "Deseja excluir este avatar permanentemente",
      avatar_msg: "Não é possível excluir este avatar, ele está sendo usado por um bot atualmente.",
      message66: "Arquivo enviado com sucesso!",
      message67: "A palavra-chave digitada NÃO é válida. Por favor, verifique.",
      message68: "O TIMEOUT digitado NÃO é válido. Por favor, verifique.",
      message69: "Ocorreu um erro ao carregar o arquivo",
      message70: "Erro ao obter os modelos.",
      message71: "Modelo excluído com sucesso",
      message72: "Erro ao excluir o modelo, verifique se não está sendo usado por um ou mais bots.",
      message73: "Modelo salvo com sucesso.",
      message74: "Erro ao criar o modelo.",
      message75: "Erro ao obter o modelo.",
      message76: "Erro ao obter as variáveis do modelo.",
      message77: "A métrica foi excluída com sucesso!",
      message78: "Erro ao excluir a métrica!",
      message79: "Ocorreu um erro!",
      message80: "Não foram encontrados gráficos!",
      message81: "A lista de gráficos não pôde ser obtida!",
      message82: "Erro ao criar o gráfico, você deve selecionar um tipo de gráfico e uma escala de cores!",
      message83: "Novo gráfico criado com sucesso!",
      message84: "Erro ao criar o gráfico, tente novamente!",
      message85: "O gráfico foi atualizado com sucesso!",
      message86: "Erro ao atualizar o gráfico!",
      message87: "Deseja excluir este gráfico permanentemente?",
      message88: "O gráfico foi excluído com sucesso!",
      message89: "Erro ao excluir o gráfico!",
      message90: "A lista de Dashboards não pôde ser obtida!",
      message91: "Erro ao criar o dashboard, tente novamente",
      message92: "O dashboard foi atualizado com sucesso!",
      message93: "Erro ao atualizar o dashboard!",
      message94: "Deseja excluir o dashboard permanentemente?",
      message95: "O Dashboard foi excluído com sucesso!",
      message96: "Erro ao excluir o dashboard!",
      message97: "Não foram encontrados dashboards!",
      message98: "As informações do dashboard não puderam ser obtidas!",
      message99: "Erro, não foi possível obter o número de unidades!",
      message100: "Erro ao listar o total de unidades!",
      message101: "Não foram encontradas Unidades!",
      message102: "A lista de unidades não pôde ser obtida!",
      message103: "Não foram encontradas Dimensões!",
      message104: "A lista de dimensões não pôde ser obtida!",
      message105: "Não foram encontrados tipos de gráficos!",
      message106: "A lista de tipos de gráficos não pôde ser obtida!",
      message107: "O tipo de gráfico não pôde ser obtido!",
      message108: "Não foi possível obter a lista de canais!",
      message109: "Erro ao obter a lista de canais!",
      message110: "Não foi possível obter a lista de paletas de cores!",
      message111: "Erro ao obter a lista de paletas de cores!",
      message112: "Não foram encontradas métricas!",
      message113: "A lista de métricas com pai não pôde ser obtida!",
      message114: "A lista de métricas não pôde ser obtida!",
      message115: "A métrica não pôde ser obtida!",
      message116: "Nova métrica criada com sucesso!",
      message117: "Erro ao criar a métrica, tente novamente!",
      message118: "A métrica foi atualizada com sucesso!",
      message119: "Erro ao atualizar a métrica!",
      message120: "Novo dashboard criado com sucesso!",
      message121: "A quantidade de medidas não pôde ser obtida!",
      message122: "Você deve selecionar uma data inicial, tente novamente!",
      message123: "Você deve selecionar uma data final, tente novamente!",
      message124: "Token bem-sucedido!",
      message125: "Erro ao obter o token!",
      message126: "Você deve adicionar duas datas para poder filtrar, caso contrário, será filtrado pela data de hoje",
      message127: "Uma cópia do relatório foi enviado em",
      message128: "O limite de tempo de espera para gerar a consulta foi excedido. <br /> <br /> <span style=\"font-weight:bold;font-style:italic\">Por favor, selecione um intervalo de datas mais curto. </span>",
      message129: "Não foi possível obter a lista de idiomas!",
      message130: "Erro ao obter a lista de idiomas!",
      message131: "Ocorreu um erro ao obter o relatório de interações do",
      message132: "Tempo limite excedido para gerar o relatório do",
      message133: " Por favor, selecione um intervalo de datas mais curto",
      message134: "Não foi possível obter a lista de empresas!",
      message135: "Erro ao obter a lista de empresas!",
      message136: "Empresa modificada com sucesso",
      message137: "Erro ao excluir empresa",
      message138: "Empresa criada corretamente ",
      message139: "Erro ao criar empresa ",
      message140: "Empresa editada corretamente ",
      message141: "Editar Empresa de Editar ",
      message142: "Usuário criado corretamente ",
      message143: "Erro ao criar usuário ",
      message144: "Erro ao consultar usuários ",
      message145: "Usuário editado corretamente ",
      message146: "Usuário editado corretamente, comece de novo! ",
      message147: "Erro editar usuário ",
      message148: "Usuário excluído corretamente ",
      message149: "Achamos que não é possível executar essa ação. ",
      message150: "Relatório de postagem eletrônica! ",
      message151: "Erro a geração do relatório! ",
      message152: "Ocorreu um erro ao obter o relatório de email do ",
      message153: "Ocorreu um erro ao obter o relatório personalizado do ",
      message154: "Ocorreu um erro ao obter o relatório do cliente do ",
      message155: "O bot selecionado tem relatórios personalizados! ",
      message156: "Erro para obter a lista de relatórios personalizados! ",
      message157: "Ocorreu um erro ao obter o relatório SMS do ",
      message158: "Ocorreu um erro ao obter o relatório da blockchain do ",
      message159: "Relatório Blockchain! ",
      message187: "base de datos sobre escrita correctamente",
      message190: "Ops! Ocorreu um erro. A IA NÃO pôde ser vinculada. Verifique os dados inseridos",
      instagram_token: "Token do Instagram ",
      group: "Conjunto ",
      groups: "Grupos ",
      new_groups: "Novo grupo ",
      groups_msg: "Configure como você responderá ao seu bot às perguntas dos usuários. Você pode definir um grupo, suas perguntas e suas respostas ou também pode adicionar sinônimos para perguntas. As perguntas mais agregadas ",
      options: "Opções ",
      module_qna: "Módulo QNA ",
      link_bot: "Link de bot ",
      config_file: "Arquivo de configuração ",
      config_edit: "Alterar ajustes ",
      ia_message2: "Digite o ID do diálogo ",
      new_module: "Novo módulo ",
      module_name: "Nome do módulo ",
      question_user: "Se o usuário pedir ",
      question_write: "Escrever pergunta ",
      bot_can_response: "O bot pode responder ",
      write_response: "Escreva resposta ",
      delete_module: "Remova o módulo ",
      ia_multiple_response: "Perguntas com várias respostas/ valor total ",
      ia_message3: "Nenhuma pergunta foi encontrada sem respostas associadas ",
      ia_message4: "Aqui você encontrará as listas de perguntas sem respostas associadas ",
      ia_message5: "Com esta lista, você pode treinar a inteligência artificial do bot. ",
      ia_message6: "Perguntas de resposta associadas / valor total ",
      ia_message7: "Não foram encontradas perguntas com mais de uma resposta.",
      ia_message8: "Aqui você encontrará as listas de perguntas com mais de uma resposta",
      click_here: "Clique aqui ",
      testing_bot: "Experimente o bot ",
      message160: "A lista de bate -papos não pôde ser obtida! ",
      create_company: "Criar Empresa",
      create_database:"Criar Base de Dados",
      sobreescribir_database: "Sobrescrever Base de Dados", 
      company_admin_msg: "Ainda não tem empresas ",
      company_admin_msg2: "Aqui você encontrará empresas existentes ",
      company_admin_msg3: "Crie uma empresa para criar seus bots. ",
      company_name: "Nome da empresa ",
      company_administrator: "Nome do administrador ",
      company_admin_msg4: "Nome de usuário para fazer login ",
      home_actions_msg: "Erro, o número de bots não pôde ser obtido! ",
      home_actions_msg2: "Erro ao listar o total de bots! ",
      home_actions_msg3: "Nenhum robô foi encontrado! ",
      home_actions_msg4: "A lista de canais não pôde ser obtida! ",
      home_actions_msg5: "Erro para obter a lista de canais! ",
      home_actions_msg6: "Novo bot criado com sucesso! ",
      home_actions_msg7: "Erro ao criar o bot, tente novamente! ",
      home_actions_msg8: "Você quer eliminar esse bot permanentemente? ",
      home_actions_msg9: "Erro excluindo o bot! ",
      create_new_group: "Crie um novo grupo ",
      create_new:"Nova variável",
      name_group: "Nome do grupo ",
      question: "Perguntar ",
      answer: "Responder ",
      add_synonym: "Adicionar sinônimo ",
      list_synonym: "Lista de sinônimos ",
      edit_question: "Editar pergunta ",
      qua_title_view: "Perguntas e respostas da QNA ",
      questions_answers: "Perguntas e respostas ",
      train: "Trem ",
      write_question: "Escreva uma pergunta ",
      message161: "O bot deve responder ",
      example_qna: "Exemplo: meu nome é Marvin ",
      example_qna2: "Exemplo: qual é o seu nome ",
      message162: "Se você perguntar ",
      message163: "O bot responde ",
      message164: "Outra maneira de perguntar ",
      synonym: "sinônimo ",
      edit_synonym: "Editar sinônimo ",
      train_bot: "Bot de trem ",
      message165: "Configure como você responderá ao seu bot às perguntas dos usuários. Você pode definir uma pergunta específica e sua resposta ou também pode adicionar sinônimos para perguntas. Quanto mais agregadas questões, melhor será o bot. ",
      message166: "Escreva uma pergunta que deve responder ao bot ",
      message167: "Escreva o que o bot deve responder ",
      add_question: "Adicione a pergunta ",
      message168: "As alterações não -depositadas serão eliminadas ",
      message169: "O nome do módulo deve entrar ",
      message170: "Por favor, digite o nome da visualização da web ",
      funnel_create: "Criar funil ",
      funnel: "Funil ",
      message171: "Você deve adicionar um evento para continuar. ",
      message171: "Deve haver pelo menos um evento para continuar. ",
      message172: "Você quer eliminar esse funil permanentemente? ",
      message173: "Aqui você encontrará os gráficos que podem ser usados ​​em um painel ",
      message174: "Crie um gráfico para analisar as informações de qualquer fluxo do seu aplicativo e de sua operação. ",
      message175: "Você ainda não tem painéis ",
      message176: "Aqui você encontrará o painel existente ",
      message177: "Crie um painel para analisar as informações de qualquer fluxo do seu aplicativo e de sua operação. ",
      metric_admin: "Medição/administração métrica ",
      message178: "Aqui você encontrará as medidas que podem ser gráficas em um painel ",
      message179: "Crie uma medida para analisar as informações de qualquer fluxo do seu aplicativo e sua operação. ",
      next_page: "Sig. P. ",
      previous_page: "Anterior. ",
      rows_for_page: "Linhas por página ",
      all: "Todos ",
      filters: "Filtros ",
      clear: "Limpar ",
      see_columns_msg: "Mostrar/ocultar colunas na tabela ",
      row_selected: "Linhas selecionadas (s) ",
      row_selected_delete: "Remova linhas selecionadas ",
      phrase: "Frase ",
      quantity: "Quantia ",
      web_view_module: "Módulo de visualização da web ",
      web_view_create: "Crie uma visualização da web ",
      connect_web_view: "Vincular uma visualização da web ",
      web_view_name: "Nome Web View ",
      web_view_example: "Escreva o URL do site ex ",
      operator: "operador ",
      operator_type: "Tipo de operador ",
      config_cms: "Configuração do CMS ",
      config_cms_msg: "Ainda não criou configurações de CMS ",
      config_cms_msg2: "Aqui você encontrará as listas de configurações do MOT CMS ",
      config_cms_msg3: "Crie uma nova configuração do CMS a ser listada. ",
      config_operator: "Configurar operador ",
      tarjects: "Cartões ",
      statements: "Declarações ",
      max_item_lose: "Itens máximos permitidos ",
      add_image: "Adicionar imagem ",
      url_image: "imagem URL ",
      upload_image_msg: "Recomenda -se que a imagem tenha um relacionamento 2 ",
      channels: "Canais ",
      status: "Estado ",
      create_new_notification: "Crie nova notificação ",
      notifications_msg: "Você ainda não tem notificações ",
      notifications_msg2: "Aqui você encontrará as notificações existentes do bot ",
      notifications_msg3: "Crie uma nova notificação. ",
      notifications_config: "Configurar notificação ",
      notification_edit: "Editar notificação ",
      create_new_contact_list: "Crie nova lista de remessa ",
      contact_list_msg: "Você ainda não tem listas de remessa criadas ",
      contact_list_msg2: "Aqui você encontrará as listas de remessa existentes da empresa ",
      contact_list_msg3: "Crie uma nova lista de remessas. ",
      template_email_msg: "Selecione uma lista de remessa para exibir as variáveis ​​disponíveis e, assim, personalizar os modelos de email para seus clientes, basta copiar e colar a variável na parte que preferir. ",
      send_list: "Lista de correspondência ",
      save_template: "Salvar modelo ",
      edit_template: "Modelo de edição ",
      save_edition: "Save Edition ",
      template_title: "Título do modelo ",
      create_new_template: "Crie novo modelo ",
      templates_email: "Modelos de e -mail ",
      without_templates: "Você ainda não tem modelos ",
      template_email_msg2: "Aqui você encontrará os modelos existentes da empresa ",
      contact_name: "Indicar o nome de contato ",
      contact_phone: "O número de contato que você tenta adicionar já existe. ",
      contact_filter_msg: "Você deve adicionar duas datas para filtrar, você deve adicionar duas datas para filtrar ",
      custom_report_msg: "Este é o nome que terá a coluna do relatório ",
      custom_report_msg2: "Este é o valor da coluna do relatório ",
      custom_report_msg3: "Configure o relatório personalizado ",
      add_links_event: "Você deve adicionar links ao evento de clique ou excluir o evento! ",
      add_link_msg: "Você já adicionou 5 links! ",
      add_link_msg2: "Você já adicionou esse link! ",
      select_link: "Selecione um link ",
      complete_info_add_var: "Preencha as informações para adicionar a variável ",
      config_vars: "Configure variáveis ",
      list_var: "Lista de variáveis ",
      report_list: "Relatório pronto! ",
      report_generate_msg: "Uma cópia do relatório do ",
      report_generate_msg2: "O tempo limite de espera foi excedido para gerar o relatório do ",
      report_generate_msg3: "O relatório continuará sendo gerado e uma cópia será enviada para e -mail ",
      report_generate_msg4: "Selecione uma faixa mais curta de datas. ",
      time_limit: "Tempo excedido !! ",
      new_users: "Novos usuários ",
      user_activate: "Usuários ativos ",
      message180: "Você quer eliminar esta lista permanentemente? ",
      message181: "A lista não pode ser excluída porque possui uma campanha associada! ",
      contact_edit_msg: "Contato com êxito! ",
      data_update_msg: "Dados atualizados com sucesso. ",
      message182: "A lista que você tenta validar não tem colunas de e -mail ou e -mail ",
      bots_associate: "Bots associados ",
      message183: "Esta lista tem registros repetidos ",
      available: "Disponível ",
      edit_send_list: "Editar lista de remessa ",
      config_list: "Lista de configurações ",
      user_identity: "Identificador do usuário ",
      contacts_upload: "Carregar contatos ",
      view_data: "Veja dados ",
      validate_email: "Validar e -mail ",
      verify_domain: "Verifique o domínio ",
      validate: "Validar ",
      list: "lista ",
      edit_contact: "Editar Contato ",
      download: "Descarga ",
      Sobreescribir: "Sobrescrever",
      Visualizacion: "Visualização",
      Visualizacion_logs:'Visualização logs',
      create_consult: "Criar consulta ",
      test_consult: "Consulta de teste ",
      message184: "Corresponder aos parâmetros que serão usados ​​para agrupar as informações ",
      dimensions_metric: "Escreva as dimensões da medição ",
      measurement: "Extensão ",
      write_form_measurement: "Escreva a fórmula de medição ",
      metric_message2: "Escreva a condição para filtrar a medida ",
      fields: "Campos ",
      functions: "Funções ",
      operators: "Operadores ",
      consult: "Consulta ",
      update: "Atualizar ",
      create_new_metric: "Crie nova medida / métrica ",
      metric_name: "Medir nome/metrica ",
      short_name: "Nome curto ",
      short_name_metric: "Nome curto da medida/métrica ",
      description_metric: "Medida/Descrição Métrica ",
      operational: "Operacional ",
      report_interactions_msg: "Nenhum relato de interação foi encontrado ",
      report_interactions_msg2: "Aqui você encontrará as listas de relatórios de interação ",
      see_more: "Ver mais ",
      edit_company: "Editar empresa ",
      company_message: "Não há empresas criadas ",
      blockchain_report: "Relatórios de blockchain ",
      blockchain_report_msg: "Você ainda não tem relatórios de blockchain ",
      blockchain_report_msg2: "Inclui uma caixa de blockchain no fluxo com os valores que você deseja armazenar para gerar o relatório da blockchain ",
      report_customer: "Relatório do cliente ",
      management: "Administração ",
      custom_reports: "Relatórios personalizados ",
      database_admin_msg: "Ainda não há bancos de dados",
      database_admin_msg2: "Aqui você encontrará bancos de dados existentes.",
      database_admin_msg3: "Crie um banco de dados para poder utilizá-lo em seus bots.",
      generate:'Gerar',
      shorten_url:'Encurtar URL',
      tooltipKeyword:'Palavra-chave para chegar a este operador',
      invalidKeyword:'Palavra-chave inválida, deve estar em letras maiúsculas, sem espaços ou caracteres especiais.',
      keyword:'Palavra-chave',
      audio:'Áudio',
      tooltip_audio:'Anexar arquivo de áudio ao operador',
      msg_file:'O arquivo excede o máximo permitido',
      config_api: 'Configurar API',
      config_calendar: 'Configurar Calendário',
      config_catalog: 'Configurar Catálogo',
      config_webview: 'Configurar visualização da Web',
      config_quickreply: 'Configurar resposta rápida',
      config_sender: 'Configurar remetente',
      config_image: 'Configurar imagem',
      config_desuscription: 'Configurar cancelamento de assinatura',
      msg_error_limit:'Limite permitido de 2.000 caracteres',
      msg_error_variable:'Não há variáveis ​​a serem atribuídas',
      msg_error_empty:'Itens vazios não são permitidos',
      used_variable:'usar variáveis',
      variable_multimedia: 'Variável multimídia',
      url_nueva: 'Nova URL',
      sort: 'Ordenar',
      for: 'por',
      of: 'de',
      creation: 'Criação',
      change_password: 'Mudar senha agora',
      change_password_error: 'Sua nova senha não é igual a "Repita a senha (novamente)". Por favor, escreva-a corretamente.',
      change_password_ok: 'Sua senha foi alterada com sucesso.',
      change_password_no: 'Falha na autenticação. Usuário ou senha incorretos.',
      delete_contact: "Excluir contato",
      delete_question: "Não foi possível excluir a pergunta",
      delete_schema: "Deseja excluir este esquema permanentemente?",
      error_selected_bot: "Nenhum bot selecionado",
      bot_id: "ID do Bot",
      bot_version: "Versão do Bot",
      box: "Caixas",
      component: "Componente",
      time_dimention: "Dimensão de tempo",
      with: "Com",
      without: "Sem",
      scala: "Escala",
      close: "Fechar",
      measures_tree: "Árvore de medidas",
      default_title: "Título padrão",
      verify_email: "Verificar E-mail",
      complete_info: "Por favor, preencha todas as informações",
      user_variable: "Variáveis do usuário",
      user_variable_msg: "Para criar uma nova variável, digite o nome e pressione enter.",
      validate_number_text: "Apenas letras ou números são permitidos!",
      enter_create: "Pressione Enter para criar",
      code: "Código de inserção",
      copy_code: "Copiar código",
      name_new_variable: "Digite o nome da nova variável.",
      corner_lower_right: "Canto inferior direito",
      border_lower_center: "Borda inferior Centro",
      corner_up_right: "Canto superior direito",
      corner_up_left: "Canto superior esquerdo",
      follow_asking: "Continuar perguntando",
      title_add_register: 'Confirma se deseja adicionar novos registros?',
      add_register: 'Tem certeza de que deseja adicionar novos registros?',
      title_delete_register: 'Confirma se deseja excluir os registros atuais e adicionar novos?',
      delete_register: 'Tem certeza de que deseja excluir os registros atuais e adicionar novos?',
      confirm: 'Confirmar',
      file_encripted: 'Os arquivos serão armazenados criptografados?',
      db_generate_msg: 'O banco de dados continuará sendo gerado e uma mensagem será enviada para o e-mail quando estiver pronto'
    }
  },
};


i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    fallbackLng: "en",
    debug: true,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });


export default i18n;