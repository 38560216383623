import React, {Component} from 'react';
import { Label,Button,Modal,ModalHeader,ModalBody,ModalFooter,Form,FormGroup,Input,Col,Row,Nav,NavItem,TabContent,TabPane,NavLink, UncontrolledTooltip} from 'reactstrap';
import IASelector from '../../../../components/ia';
import Keyword from '../../../../components/keyword/keyword';
import Mentions from '../../../../components/mentions/metions-alone';
import Avatar from '../../../../components/avatar';
import TitleOperator from "../../../../components/title-operator";
import Emoji from '../../../../components/emoji-picker';
import classnames from 'classnames';
import TabAdvanced from "../../../../components/box/tab_advanced";
import i18next from '../../../../../i18n';

class WVMLayout extends Component {
    constructor(props){
        super(props)
        this.state = {
        activeTab: '1'
        };
        this.toggleTab = this.toggleTab.bind(this);
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
          this.setState({
            activeTab: tab
          });
        }
    }
    sel_webform = (webform) => {
        var sel_webform = [];
        sel_webform.push(<option key="default" value="" > {i18next.t('select_form_llink_up')}</option>);
            var aux = false;
            for(let i = 0; i < webform.length; i++){
                if(webform[i].url == this.props.webform) {
                    aux = true;
                }
            sel_webform.push(<option key={i} value={webform[i].url} selected={aux} >{webform[i].title}</option>);
            aux= false;
            }
        return sel_webform;
    }

    render(){
        let webform = this.props.listWebform;
        let list = this.sel_webform(webform);
        return (
            <React.Fragment>
                <Modal className="webview" isOpen={this.props.isOpen} toggle={this.props.closeModal} fade size="lg">
                    <ModalHeader toggle={this.props.closeModal}>{i18next.t('config_webview')}</ModalHeader>
                    <Form id="WVM-form" role="form">  
                    <ModalBody>
                    <Nav tabs>
                            <NavItem>
                                <NavLink
                                className={classnames({ active: this.state.activeTab === '1' })}
                                onClick={() => { this.toggleTab('1'); }} >
                                {i18next.t('basic')}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                className={classnames({ active: this.state.activeTab === '2' })}
                                onClick={() => { this.toggleTab('2'); }}>
                                {i18next.t('advanced')}
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={this.state.activeTab}>
                            <TabPane tabId="1">
                                <Col sm="12">
                                    <UncontrolledTooltip placement="right" target="tooltipEnunciado"> {i18next.t('message8')}</UncontrolledTooltip>
                                    <TitleOperator titleOperator={this.props.titleOperator} bindValue={this.props.getTitleOperator}/>
                                    <Label>{i18next.t('statement')} <i className="fa fa-info-circle" id="tooltipEnunciado"></i></Label>
                                  <Input type="hidden" defaultValue={this.props.botmsg_id || ''} name="botmsg_id" hidden/>
                                    <FormGroup>
                                        <Mentions varList={this.props.varList}  defaultValue={this.props.botmsg_text || ''}  updateItem={this.props.updateItem} maxlength={'2000'} msg_error={i18next.t('limit_allowed') + ' 2000'}/>
                                        <div className="options-bar-input" style={{'right':'1px'}}>
                                            <Emoji id={'head'} callEmoji={this.props.callEmoji} type="head" style={{'display':'none',position:'fixed',marginLeft: -265+'px',zIndex: 100}}/>
                                            
                                        </div>
                                    </FormGroup>
                                    
                                    <Label>{i18next.t('web_view')} <i className="fa fa-info-circle" id="tooltipVista"></i></Label>
                                    <UncontrolledTooltip placement="right" target="tooltipVista"> {i18next.t('message37')}</UncontrolledTooltip>
                                
                                    <Input type="select" className="inputModal" name="webform" required>
                                        {list}
                                    </Input> 
                                    <br />
                                    <FormGroup check>
                                        <Label for="xCheck" check>
                                            <Input type="checkbox" name="check" id="xCheck" defaultChecked={this.props.xCheck}  onChange={(e) => {this.props.getCloseButton(e)}}/>
                                            &nbsp; {i18next.t('show_x_close_webview')}</Label>
                                    </FormGroup>
                                </Col>
                            </TabPane>
                            <TabPane tabId="2">
                                <Row>
                                    <Col sm="6">
                                        <IASelector botversion_id={this.props.botversion_id} operator_id={this.props.operator_id} ia={this.props.ia} getIAId={this.props.getIAId} />
                                        <Avatar company_id={this.props.company_id} avatarid={this.props.avatarid} bindValue={this.props.getAvatarId} />
                                    </Col>
                                    <Col sm="6">
                                    <Keyword keyword={this.props.keyword} isValid={this.props.isKeywordValid} updateKeyword={this.props.updateKeyword}/>
                                    </Col>
                                </Row>

                            </TabPane>
                        </TabContent>                  
                    </ModalBody>
                    <ModalFooter>
                        <Col sm="12" align="right">
                            <Button color="success" size="lg" type="button" onClick={this.props.toggleModuleModal} >{i18next.t('finish')}</Button>
                        </Col>
                    </ModalFooter>
                    </Form>
                </Modal>           
            </React.Fragment>
        );
    }
}
  
export default WVMLayout
