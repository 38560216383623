import React, { Component } from 'react';
import ModalLoad from '../../../components/modals/modal-load';
import BotAimlLayout from './bot-aiml-group-layout';

import { getHandlerPOST, getHandlerGET, getHandlerDELETE, getHandlerPUT } from '../../../utils/handlersApi'
import { cerrarSesion } from '../../../utils/commonFunction'

import { setSelectedBot } from '../../../redux/actions/botActions';

import i18next from '../../../../i18n';

class BotAiml extends Component {
    _isMounted = false;
    constructor(props){
        super(props);
        this.myHelp = {}; // this is an auxiliar to pass values inside other scopes.
        this.state = { 
            displayTr:[],
            botId: this.props.match.params.id,
            hideLoad: 0,
            botversion_id: "",
            ia_activated: false,
            modalBot: false,
        };
    }

    componentDidMount () {
        this.getBotInfo();
        this.listGroup();
        this._isMounted = true;
    }

    componentWillMount(){
        var auth=JSON.parse(localStorage.getItem('Authorization'));
        if(!auth || !auth.access_token){
            this.props.history.push("/login");
        }
      }

   componentWillUnmount = () => this._isMounted = false;

    toggleModalNewGroup = (event) => {
        this.setState({
          modalBot: !this.state.modalBot
        });
      }
    /* Get Bot Info */
    getBotInfo () {
        let cont = this.state.hideLoad; this.setState({ hideLoad: cont+1});
        getHandlerGET(HOST_API +"/api/bot/?id="+this.state.botId).
            then((res) =>{
                if(res.status==200){
                    let botversion= res.data.bot[0].bot_version[0];
                    this.setState({botversion_id: botversion.botversion_id, ia_activated: res.data.bot[0].ia_activated});
                }else{
                    console.log(" No se pudo recuperar info del bot");
                }
                let cont = this.state.hideLoad; this.setState({ hideLoad: cont-1});
            }).catch((error)=> {
                  if (error.response.status == 403)
                    cerrarSesion();
            });
    }

    // Create a list question Handler
    listGroup = (event) => {
        let cont = this.state.hideLoad; this.setState({ hideLoad: cont+1});
        getHandlerGET(HOST_API +"/api/group/?bot_id="+this.state.botId)
            .then((res) =>{
                if(res.status==200){
                    if(res.data.msg != 'No se encontraron Aiml_groups para ese bot'){
                        var list = [];
                        for (let i=0; i < res.data.aiml_group_list.length; i++){
                            list.push({
                                id: res.data.aiml_group_list[i].id, 
                                name: res.data.aiml_group_list[i].name, 
                                bot_id: res.data.aiml_group_list[i].bot_id
                            });
                        }
                        this.setState({displayTr : list});
                    }
                    
                }else{
                    console.log(" No se pudo recuperar info del bot");
                }
                let cont = this.state.hideLoad; this.setState({ hideLoad: cont-1});
            }).catch((error)=> {
                  if (error.response.status == 403)
                    cerrarSesion();
            });
    }

    handleChangeInputGroup = (key, value) => {
        this.state.displayTr[key].name = value;
        this.forceUpdate();
    }

    // Update a new question Handler
    updateGroup = (id, name) => {
        let cont = this.state.hideLoad; this.setState({ hideLoad: cont+1});
        let data = { id , name , bot_id: this.state.botId  };
        this.myHelp = data;
        getHandlerPUT(HOST_API +'/api/group/',data)
            .then((res) => {
                if(res.status==200){
                    for(let i=0; i < this.state.displayTr.length; i++){
                        if(this.state.displayTr[i].id == this.myHelp.id){
                            let aux = this.state.displayTr;
                            aux[i].name = this.myHelp.name;
                            aux[i].hidden = !aux[i].hidden;
                            this.setState({displayTr : aux });
                            this.listGroup();
                            break;
                        }
                    }
                }else{
                  console.log(" No se pudo actualizar la pregunta");
                }
                cont = this.state.hideLoad; this.setState({ hideLoad: cont-1});
            }).catch((error) => {
                console.log('catch updateGroup',error);
                  if (error.response.status == 403)
                    cerrarSesion();
            });
    }
    // Create a detele question Handler
    deleteGroup = (ID, key) => {
        let cont = this.state.hideLoad;
        this.setState({ hideLoad: cont+1});
       
        getHandlerDELETE(HOST_API +'/api/group/?id='+ID).
            then((res) => {
                if(res.status==200){
                    const temp= this.state.displayTr;
                    temp.splice(key,1);
                    this.setState({displayTr: temp});
                }else{
                    alert(i18next.t('delete_question'));
                  console.log(" No se pudo eliminar la pregunta");
                }
                cont = this.state.hideLoad; 
                this.setState({ hideLoad: cont-1});
            }).catch((error) => {
                console.log('catch deleteGroup',error);
                  if (error.response.status == 403)
                    cerrarSesion();
            });

    }
     // Create Bot Handler
    saveGroup = (event) => {  
        event.preventDefault();
        this.toggleModalNewGroup(event);
        let cont = this.state.hideLoad; this.setState({ hideLoad: cont+1});
        let form = new FormData(event.target);
        let data = {
            name: form.get("groupName"),
            bot_id : this.state.botId
        }
        getHandlerPOST(HOST_API +'/api/group/', data).
            then((res)=>{
                if(res.status==200){
                this.listGroup();
                }else{
                console.log(" No se pudo crear el ");
                }
                let cont = this.state.hideLoad; this.setState({ hideLoad: cont-1});
            }).catch((error) => {
                console.log('catch saveGroup',error);
                  if (error.response.status == 403)
                    cerrarSesion();
            });

    }
     /* toogles the hidden state of an specific question 
        (To show or hide edition forms)
     */
    toogleQuestion = (key) => {
        this.state.displayTr[key].hidden = !this.state.displayTr[key].hidden;
        this.forceUpdate();
    }

   
     /*
        toogles the "Activate AI" switch
     */
     handleChangeSwitch = (event) => {
        let cont = this.state.hideLoad; this.setState({ hideLoad: cont+1});
        var aux = this.state.ia_activated;
        var data = 1;
        this.myHelp = true;
        if(aux){
            data = 0;
            this.myHelp = false;
        }
        getHandlerGET(HOST_API +"/api/ia/?status="+data+"&botversion_id="+this.state.botversion_id).
            then((res) =>{
                if(res.status==200){
                    this.setState({ia_activated : this.myHelp});
                }else{
                    console.log(" No se pudo cambiar el estado de la ia");
                }
                let cont = this.state.hideLoad; this.setState({ hideLoad: cont-1});
            }).catch((error)=> {
                console.log('catch handleChangeSwitch',error);
                  if (error.response.status == 403)
                    cerrarSesion();
            });
     }


     render() {
        return (
        <div>
          <BotAimlLayout 
            botId = {this.state.botId}
            modalBot = {this.state.modalBot}
            displayTr = {this.state.displayTr}
            botversion_id = {this.state.botversion_id}
            ia_activated = {this.state.ia_activated}

            // Functions
            saveGroup = {this.saveGroup}
            deleteGroup = {this.deleteGroup}
            updateGroup = {this.updateGroup}
            toogleQuestion = {this.toogleQuestion}
            handleChangeSwitch = {this.handleChangeSwitch}
            toggleModalNewGroup = {this.toggleModalNewGroup}
            handleChangeInputGroup = {this.handleChangeInputGroup}
          />
          <ModalLoad hideLoad = {this.state.hideLoad} />
        </div>
        )
    }
}

export default BotAiml