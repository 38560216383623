import React from 'react';
import {
  Col,
  Row,    
  Container,
  Form,
  Button,
  Input,
  Label,
  FormGroup       
 } from 'reactstrap';
 import Admin from '../../admin/containers/admin';
 import SliceMenuApp from '../../admin/components/slice-menu';
 import ToolbarApp from '../../admin/components/toolbar';
 import BotMenuOptions from'../../menus/bot-menu-options';
 import FormBuilder from '../../../components/form-builder';
 import './bot-webforms-layout.css';
 import i18next from '../../../../i18n';

function BotWebFormsLayout(props){

    function change_date_format(date) {
        date = date.slice(0,10);
        let aux = date.split('-');
        date = aux[2]+"/"+aux[1]+"/"+aux[0];
        return date;
    }

    return (
        <div className="BotLayout">
          <Admin>
            <SliceMenuApp>
              <BotMenuOptions botId={props.botId} ia_activated={props.ia_activated}/>
            </SliceMenuApp>
            <Container fluid>
                <ToolbarApp>
                  <Row>
                    <Col  lg="12" className="text pdtoolbar">
                      <h2 className="text-left ml-4 font-weight-bold">{i18next.t('web_view_module')}</h2>                      
                    </Col>
                  </Row>
                </ToolbarApp>
            </Container>
            <Container fluid className="box-fluid">
            <Col sm="12" md="10" className="offset-md-1">
              <Col md="12">
                  <Row>
                    <Col md="12" className="webform-container">
                      { props.showWebFormList ? (
                        <Row id="webforms-list-panel">
                            <Col className="col-12">
                                <Row>
                                    <Col sm="12" md="6" lg="8">
                                        <h2 className="font-weight-bold">{i18next.t('list_web_views')}</h2>
                                    </Col>
                                    <Col sm="12" md="6" lg="4">
                                      <Row  className="webforms-list-panel-button">
                                        <Col md="6">
                                        <Button type="button" color="secondary" className="btn mr-4 btn-block" onClick={props.letCreateFormBuilder}> {i18next.t('create')}  </Button>
                                        </Col>
                                        <Col md="6">
                                        <Button type="button" className="btn btn-guarumo-md btn-block" onClick={props.letCreateForm}>{i18next.t('connect')}</Button>
                                        </Col>
                                      </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col className="col-12 box-item-webform">
                              <Row>
                                  { props.listWebForms.map((item,index) => {
                                    return (    
                                        <Col key={index} className="col-sm-12 col-xs-12 col-md-4 col-lg-3 col-xl-2">
                                          <Col className="item-box">
                                          <div type="button" data-toggle="dropdown">
                                                  <div className="item-menu-options">
                                                      <img className="menu-options" src="../../static/img/menu-options.png"></img>
                                                  </div>
                                                  <Row className="box-content">
                                                      <Col md="12">
                                                        <h2> {item.title} </h2>
                                                        <small>{i18next.t('created_in')+': '} {change_date_format(item.reg_date)}</small>
                                                      </Col>
                                                  </Row>
                                              </div>
                                              <ul className="dropdown-menu dropdown-bot">
                                                  { item.url ? (<li><a className="option-btn" onClick={ (e) => props.letEditWebForm(item.id) }>{i18next.t('edit')}</a></li> ) 
                                                    : ( <li><a className="option-btn" onClick={ (e) => props.letEditWebForm(item.id) }>{i18next.t('edit')}</a></li> ) }
                                                  <li><a className="option-btn" onClick={ (e) => props.deleteWebForm(item.id) }>{i18next.t('delete')}</a></li>
                                              </ul>
                                              
                                          </Col>
                                        </Col>  
                                    )
                                  }
                                )}
                              </Row>
                            </Col>
                        </Row>
                      ): null }

{ props.showFormBuilder ? (
                          <Row id="webforms-bind-form-panel" className="col-12">
                          <Col className="col-12">
                            <Row>
                              <Col md="8" lg="10" className="pl-5">
                                  <h2 className="font-weight-bold">{i18next.t('web_view_create')}</h2>  
                              </Col>
                              <Col md="4" lg="2">
                                <div className="pull-right">
                                    <Button type="button" color="secondary" className="btn btn-block" onClick={props.goBackFromFormBuilder}>{i18next.t('cancel')}</Button>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                            
                            <Form id="form-webform-builder" className="col-12">
                              <Col className="col-12">
                                  <Row>
                                        <Col md="12 mt-3">
                                        <label>{i18next.t('name')}</label>
                                          <Input type="hidden" defaultValue={props.currentWebForm.id} name="id" hidden/>
                                          <Input className="" name="title" type="text" defaultValue={props.currentWebForm.title} />
                                        </Col>
                                  </Row>
                              </Col>
                              <Col className="col-12 generator-form">
                                    <FormBuilder formData={props.currentWebForm.template} saveFormBuilder={props.saveFormBuilder}/> 
                              </Col>
                            </Form>
                          </Row>
                          ): null }

                        { props.showBindForm ? (
                          <Row id="webforms-bind-form-panel">
                              <Col sm="12" md="6" lg="8">
                                  <h2 className="font-weight-bold">{i18next.t('connect_web_view')}</h2>
                              </Col>
                              <Col sm="12" md="6" lg="4">
                                <Row  className="webforms-list-panel-button">
                                  <Col md="6">
                                    <Button type="button" className="btn mr-4 btn-block" onClick={props.goBackFromForm}>{i18next.t('cancel')}</Button>
                                  </Col>
                                  <Col md="6">
                                    <Button type="submit" form="submitNewForm" className="btn btn-guarumo-md btn-block"> { props.currentWebForm.id? i18next.t('save') : i18next.t('create') }</Button>
                                  </Col>
                                </Row>
                              </Col>
                              <Form onSubmit={props.submitWebFormBind} className="col-12" id="submitNewForm">
                                <Col className="col-12 form-box">
                                    <FormGroup>
                                      <Label>{i18next.t('web_view_name')}</Label>
                                        <Input type="hidden" defaultValue={props.currentWebForm.id} name="id" hidden/>
                                        <Input className="" name="title" type="text" defaultValue={props.currentWebForm.title}  required/>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>URL</Label>
                                        <Input className="modal-input" name="url" type="url" required defaultValue={props.currentWebForm.url} placeholder={i18next.t('web_view_example') + " http://... o https://... "} />
                                    </FormGroup>
                                </Col>
                              </Form>
                          </Row>
                          ): null }

                      

                          </Col>
                      </Row>
              
              </Col>
            </Col>
            </Container>
          </Admin>      
    </div>
    );
}
  
export default BotWebFormsLayout;
