import { HOME_ACTIONS_TYPES } from '../types/homeActionsTypes';


const INITIAL_STATE = {
    totalBots: 1, // For navigation
    listAllBots: [],
    allPlatforms: [],
    listAllCompanies: [],
    loagin: false,
    modalNewBot: false,
    searcher: '',
    botClone: [],
    error:'',
    activePage: 1,

};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
        case HOME_ACTIONS_TYPES.errorDeleteBot():
            return {
                ...state,
                error: action.payload
            };

        case HOME_ACTIONS_TYPES.toggleModalNewBot():
            return {
                ...state,
                modalNewBot: !state.modalNewBot,
            };

            case HOME_ACTIONS_TYPES.successGetListCompanies():
            return {
                ...state,
                listAllCompanies: action.payload,
                loagin: false,
                error: ''
            };

            case HOME_ACTIONS_TYPES.errorGetListCompanies():
                return {
                    ...state,
                    error: action.payload,
                    loagin: false,
                  
                };
            case HOME_ACTIONS_TYPES.requestGetListCompanies():
                return {
                    ...state,
                    loagin: true,
                    error: ''
                };
        case HOME_ACTIONS_TYPES.successGetListPlafforms():
            return {
                ...state,
                allPlatforms: action.payload,
                loagin: false,
                error: ''
            };

            listAllCompanies
            case HOME_ACTIONS_TYPES.errorGetListPlafforms():
                return {
                    ...state,
                    error: action.payload,
                    loagin: false,
                    error: ''
                };
            case HOME_ACTIONS_TYPES.requestGetListPlafforms():
                return {
                    ...state,
                    loagin: true,
                    error: ''
                };
        
        case HOME_ACTIONS_TYPES.handleClearSearcher():
            return {
                ...state,
                searcher:  '',
                loagin: false,
                error: ''
            };

        case HOME_ACTIONS_TYPES.handleSearching():
            return {
                ...state,
                searcher:  action.payload,
                loagin: false,
                error: ''
            };

        case HOME_ACTIONS_TYPES.successGetTotalBots():
            return {
                ...state,
                totalBots: action.payload,
                error: ''
            };

        case HOME_ACTIONS_TYPES.requestGetListBots():
            return {
                ...state,
                loagin: true,
                error:'',
            };

        case HOME_ACTIONS_TYPES.successGetListBots():
            return {
                ...state,
                listAllBots: action.payload,
                error:'',
                loagin: false,
            };

        case HOME_ACTIONS_TYPES.errorGetListBots():
            return {
                ...state,
                error: action.payload,
                loagin: false,
            };

        case HOME_ACTIONS_TYPES.notFoundGetListBots():
            return {
                ...state,
                listAllBots: [],
                error: action.payload,
                loagin: false,
            };
            
        case HOME_ACTIONS_TYPES.changePageActive():
            return {
                ...state,
                activePage: action.payload ,
                error: '',
                loagin: false,
            };

		default: return state;
	};
};