// Dependencies
import React from 'react';
import {
  Col, Row, Button, Container, Modal, ModalHeader,
  ModalBody, FormGroup, Label, Input, Form, ModalFooter
} from 'reactstrap';
import { connect } from 'react-redux';
import EmailEditor from 'react-email-editor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';

// Components
import Admin from '../../admin/containers/admin';
import SliceMenuApp from '../../admin/components/slice-menu'
import BotMenuOptions from '../../menus/bot-menu-options.js';
import { getTemplateByiD } from '../../../redux/actions/templateActions';
import { isEmpty } from '../../../utils/commonFunction';
import i18next from '../../../../i18n';

// Assets
import './templates-layout.css';


class NewTemplateLayout extends React.Component {
  constructor(props) {
    super(props);
    global.unlayer = window.unlayer;
  }
  state = {
    updateDesign: [],
    stateList: ''
  }

  exportHtml = () => {
    /**
     * **NOTE:** @namespace exportHtml en `editor` @returns un objeto `data`, con el `design` y `html` del contenido.
     */
    this.editor.exportHtml(data => {
      const { design, html } = data
      const { templateReducer: { editingTemplate } } = this.props;

      this.setState({ updateDesign: design })
      console.log('Design',design);
      console.log('html',html);
      this.props.receiveData(html, design);

      if (editingTemplate.name)
        this.props.toggleEditModal();
      else
        this.props.toggleModal();
    })
  }
  onDesignLoad = (data) => { }

  /**
   * @name onLoad: Actualiza el componente con el diseño a editar en `design`
   * @param design
   */
  onLoad = (design) => {
    if (!isEmpty(this.editor) && Object.keys(this.editor).includes("loadDesign")) {
      try {
        this.editor.loadDesign(design);
      } catch (error) {
        console.log(error.message);
      }
    }
  }

  /**
   * @name getVariables: Evento handle al seleccionar una lista de variables de envio.
   * @param {Event} value valor id retornado del select
   */
  getVariables(value) {
    this.setState({ stateList: value })
    this.props.getVariables(value)
  }

  /**
   * @name componentDidUpdate: Actualiza el componente, cuando termina de cargar y recibe los datos en `template`
   * @param {Any} prevProps Props anteriores
   * @param {Any} prevState Estado anterior
   */
  componentDidUpdate(prevProps, prevState) {
    const { templateReducer: { editingTemplate, template } } = this.props;
    const { templateReducer: { editingTemplate: prevPropEditTemplate, template: prevPropTemplate } } = prevProps;

    if (!isEmpty(template) && prevPropTemplate !== template) {
      this.handleLoad();
    }
    if (prevPropEditTemplate != editingTemplate) {
      if ( editingTemplate.list_id ) {
        this.setState({
          stateList: editingTemplate.list_id
        });
        this.getVariables(editingTemplate.list_id);
      }
    }
  }

  /**
   * @name defaultListVariables: Retorna las varibles presentes en la lista seleccionada
   * @returns {String} listVariables
   */
  defaultListVariables() {
    var listVariables = '';
    if (this.props.listVariables.length > 0) {
      for (let i = 0; i < this.props.listVariables.length; i++) {
        listVariables += `*|${this.props.listVariables[i]}|*   `
      }
    }
    return listVariables;
  }

  /**
   * @name handleLoad: Recarga el componente, cunado recibe datos en `template`
   */
  handleLoad = () => {
    const { templateReducer: { template = [] } } = this.props;

    if (Object.keys(template).length > 0) {
      if (this.state.updateDesign.length == 0) {
        this.onLoad(template)
      }
    }
  }


  render() {
    const { templateReducer: { editingTemplate } } = this.props;
    const variables = this.defaultListVariables();
    return (
      <div className="TemplateLayout">
        <Admin>
          <SliceMenuApp>
            <BotMenuOptions botId={this.props.botId} ia_activated={this.props.ia_activated}/>
          </SliceMenuApp>
          <Container fluid className="p-0">
            <Container fluid className="template-fluid">
              <button className="btn-floatSave" onClick={this.exportHtml} style={{ 'zIndex': '2000', 'right': '50px', 'top': '90px' }}>
                <FontAwesomeIcon icon={faSave} />
              </button>
              <Col md={12} style={{ 'background': 'white' }}>
                <Row className="box-variables">
                  <Col md={9}>
                    <h4>{i18next.t('template_email_msg')}</h4>
                  </Col>
                  <Col md={9} className="mt-4">
                    <FormGroup>
                      <Row>
                        <Col sm="5" md="2">
                          <h4 style={{ 'fontWeight': '300' }}>{i18next.t('send_list')}</h4>
                        </Col>
                        <Col sm="7" md="6">
                          <select value={this.state.stateList} className="form-control config-input" name="loadList" onChange={(e) => { this.getVariables(e.target.value) }} required>
                            <option key={"list-0"} value=''>- {i18next.t('select_one')} -</option>
                            {this.props.listContact.map(el =>
                              <option key={el._id} value={el._id}>{el.titulo}</option>
                            )}
                          </select>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <Row>
                      <Col sm="5" md="2">
                        <h4 style={{ 'fontWeight': '300' }}>Variables</h4>
                      </Col>
                      <Col sm="7" md="8" className="pb-4" style={{ 'fontSize': '16px' }}>
                        {variables}
                      </Col>
                    </Row>

                  </Col>
                </Row>
              </Col>
              <EmailEditor className="editorEmail"
                ref={editor => this.editor = editor}
                onDesignLoad={this.onDesignLoad}
                options={{
                  locale: 'es-ES',
                  customJS: [
                    window.location.protocol + '//' + window.location.host + '/custom.js',
                  ]
                }}
              />
            </Container>
          </Container>
        </Admin>

        <Modal isOpen={this.props.isOpen} toggle={this.props.toggleModal} className="modal-lg" fade>
          <ModalHeader toggle={this.props.toggleModal} charCode="X">{i18next.t('save_template')}</ModalHeader>
          <Form id="form-template" onSubmit={this.props.saveTemplate}>
            <ModalBody>
              <FormGroup>
                <Input type="hidden" name="tempStateList" defaultValue={this.state.stateList || ''} />
                <Row>
                  <Col sm="2">
                    <Label for="title" className="control-label required-field">{i18next.t('title')+': '}</Label>
                  </Col>
                  <Col sm="10">
                    <Input type="text" className="inputModal" id="title" name="title" maxLength="30" required></Input>
                  </Col>
                </Row>
              </FormGroup>
              {
                this.state.stateList === '' &&
                <FormGroup>
                  <Row>
                    <Col sm="2">
                      <Label for="title" className="control-label required-field">{i18next.t('list')+': '}</Label>
                    </Col>
                    <Col sm="10">
                      <select value={editingTemplate.list_id} className="config-input" name="lista" required>
                        <option key={"list-0"} value=''>- {i18next.t('select_one')} -</option>
                        {this.props.listContact.map(el =>
                          <option key={el._id} value={el._id}>{el.titulo}</option>
                        )}
                      </select>
                    </Col>
                  </Row>
                </FormGroup>
              }
            </ModalBody>
            <ModalFooter>
              <Button onClick={this.props.toggleModal} outline color="secondary">{i18next.t('cancel')}</Button>
              <Button type="submit" size="lg" className="btn-guarumo-modal" >{i18next.t('create')}</Button>
            </ModalFooter>
          </Form>
        </Modal>
        {

          editingTemplate &&
          <Modal isOpen={this.props.isOpenEdit} toggle={this.props.toggleEditModal} className="modal-lg" fade>
            <ModalHeader toggle={this.props.toggleEditModal} charCode="X">{i18next.t('edit_template')}</ModalHeader>
            <Form id="form-template" onSubmit={this.props.saveEditTemplate}>
              <ModalBody>
                <FormGroup>
                  <Input type="hidden" name="tempEditId" defaultValue={editingTemplate.id || ''} />
                  <Input type="hidden" name="tempStateList" defaultValue={this.state.stateList || ''} />
                  <Row>
                    <Col sm="2">
                      <Label for="title" className="control-label required-field">{i18next.t('title')+': '}</Label>
                    </Col>
                    <Col sm="10">
                      <Input type="text" className="inputModal" id="editTitle" name="editTitle" maxLength="30" required defaultValue={editingTemplate.name}></Input>
                    </Col>
                  </Row>
                </FormGroup>
                {
                  this.state.stateList === '' &&
                  <FormGroup>
                    <Row>
                      <Col sm="2">
                        <Label for="title" className="control-label required-field">{i18next.t('list')+': '}</Label>
                      </Col>
                      <Col sm="10">
                        <select value={editingTemplate.list_id} className="config-input" name="listaEdit" required>
                          <option key={"list-0"} value=''>- {i18next.t('select_one')} -</option>
                          {this.props.listContact.map(el =>
                            <option key={el._id} value={el._id}>{el.titulo}</option>
                          )}
                        </select>
                      </Col>
                    </Row>
                  </FormGroup>
                }

              </ModalBody>
              <ModalFooter>
                <Button onClick={this.props.toggleEditModal} outline color="secondary">{i18next.t('cancel')}</Button>
                <Button type="submit" size="lg" className="btn-guarumo-modal" >{i18next.t('save_edition')}</Button>
              </ModalFooter>
            </Form>
          </Modal>
        }
      </div>
    );
  }

}

const mapStateToProps = ({ templateReducer }) => ({ templateReducer });

const mapDispatchToProps = {
  getTemplateByiD
};

export default connect(mapStateToProps, mapDispatchToProps)(NewTemplateLayout);

