import { AbstractNodeFactory } from "storm-react-diagrams";

import React from "react";
import GONodeWidget from "./GONodeWidget";
import GONodeModel from "./GONodeModel";

class GONodeFactory extends AbstractNodeFactory {
	constructor() {
		super("go");
	}

	generateReactWidget(diagramEngine, node) {
		return <GONodeWidget node={node} />
	}

	getNewInstance() {
		return new GONodeModel();
	}
}

export default GONodeFactory