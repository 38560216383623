import React, { Component } from 'react';
import { connect } from "react-redux";
import { isEmpty } from '../../utils/commonFunction';
import { setVarList,setBotmsgText } from "../../redux/actions/boxActions";
import { Label,Col,UncontrolledTooltip} from 'reactstrap';
import Mentions from '../mentions/metions-alone';
import Emoji from '../emoji-picker';
import Audio from '../audio-input';
import i18next from '../../../i18n';

class MainStatement extends Component {
    constructor(props){
      super(props);
  }

  componentDidMount()
  {
    const {varListSelected} = this.props.boxReducer;
    if(isEmpty(varListSelected))
      this.props.setVarList();
  }

  callEmoji = (code)=>{
    
    const cursorPos = $('#input-head').prop('selectionStart');
    const v = $('#input-head').val();
    const textBefore = v.substring(0,  cursorPos);
    const textAfter  = v.substring(cursorPos, v.length);

    let text = textBefore + ` ${code}` + textAfter;
    $('#input-head').val(text)
    
    this.props.setBotmsgText(text);
    
    if(this.props.updateItemHead)
      this.props.updateItemHead(text);   
    
  }
  updateItemHead =(text)=>{
    if(this.props.updateItemHead)
      this.props.updateItemHead(text);   
    
    this.props.setBotmsgText(text);
  }
  render() {
    const {varListSelected} = this.props.boxReducer;
    return( 
      <Col md={7} className="mb-2">
        <Label>{i18next.t('statement')} <i className="fa fa-info-circle" id="tooltipEnunciado"></i></Label>
        <UncontrolledTooltip placement="right" target="tooltipEnunciado"> {i18next.t('message58')}</UncontrolledTooltip>
        <Mentions varList={varListSelected}  defaultValue={this.props.head || ''}  updateItem={this.updateItemHead} maxlength={'2000'} msg_error={i18next.t('limit_allowed') + ' 2000'}/>
        <div className="options-bar-input" style={{'right':'16px','bottom':'-7px'}}>
          <Emoji id={'head'} callEmoji={this.callEmoji} type="head" style={{'display':'none',position:'fixed',marginLeft: -240+'px',zIndex: 100}}/>
        </div>
      </Col>
    );
  }
}

const mapStateToProps = ({ boxReducer,homeReducer, componentMountReducer }) => {
  return { boxReducer,homeReducer, componentMountReducer };
 };
 
 const mapDispatchToProps = {
  setVarList,
  setBotmsgText
 };
 
 export default connect(mapStateToProps, mapDispatchToProps)(MainStatement);