import React, {Component} from 'react';
import EmojiPicker from 'emoji-picker-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faGrinWink } from '@fortawesome/free-solid-svg-icons'
import './custom-emoji.css';
import 'emoji-picker-react/dist/universal/style.scss';
import { Col,Button } from 'reactstrap';

class MyComponent extends Component {

    constructor(props){
        super(props)
        this.emojiCallback.bind(this);
    }

    emojiCallback = (code) => {
        let k = this.props.id
        let type = this.props.type
        const emoji = String.fromCodePoint(`0x${code}`)
        this.props.callEmoji(emoji,k,type);
    }

    isOpen = (k) =>{
        if(document.getElementById(k).style.display == "block")
            document.getElementById(k).style.display='none'
        else
            document.getElementById(k).style.display='block'
    } 


    render() {
        let k = this.props.id;
        let style = this.props.style;
        if(!style)
            style = {'display':'none'}
        return (
            <React.Fragment >
                <Button className="btnEmoji" color="info" size={"lg"} onClick={() => this.isOpen(k) }>
                    <FontAwesomeIcon icon={faGrinWink} />
                </Button>
                <Col id={k} style={style} className="  ">
                    <EmojiPicker className='' onEmojiClick={this.emojiCallback} pickerStyle={{ position: 'fixed !important' }} disableDiversityPicker/>
                </Col>
            </React.Fragment>
        );
    }
}

export default  MyComponent;