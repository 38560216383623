import React, {Component, useState} from 'react';
import { connect } from "react-redux";

import { Label,Button,Modal,ModalHeader,ModalBody,ModalFooter,Form,FormGroup,Input,Col,Row,Nav,NavItem,TabContent,TabPane,NavLink,UncontrolledTooltip} from 'reactstrap';
import IASelector from '../../../../components/ia';
import Keyword from '../../../../components/keyword/keyword-redux';
import Mentions from '../../../../components/mentions/metions-redux';
import Avatar from '../../../../components/avatar';
import TitleOperator from "../../../../components/title-operator";
import Emoji from '../../../../components/emoji-picker';
import classnames from 'classnames';

import { isEmpty} from "../../../../utils/commonFunction";
import { iconLoad, 
        iconHideLoad, 
        toggleModalViewBoxPayment,
        changeActiveTab,
        setChangeConfig,
        updateVariableBoxOperator,
       } from "../../../../redux/actions/boxActions";

import SelectStatements from '../../../../components/selected-statements'
import i18next from '../../../../../i18n';



function PAYMLayout(props) {
   
    
    const {node,selectedBoxOperator, varListSelected,configBoxOperator, modalViewBoxPayment,activeItem,variablesBoxOperator,avatarid,botmsg_text,exit_var,listTypeSourcePayment,listCurrencyPayment} =props.boxReducer;

   
    const { bot,botVersionId } = props.botReducer;
   
 
    if(selectedBoxOperator&&Object.keys(selectedBoxOperator).length>0&&selectedBoxOperator.operatortype_id==25)
    {

            if(configBoxOperator&&configBoxOperator.item_type)
            {
                //selectedDate = moment(new Date(configBoxOperator.selectedDate));
            
             
                return (
                    <React.Fragment>
                    <Modal className="payment"
                    isOpen={modalViewBoxPayment}
                    toggle={props.toggleModalViewBoxPayment} 
                    // isOpen={props.isOpen} toggle={props.closeModal} 
                    fade size="lg">
                        <ModalHeader toggle={props.toggleModalViewBoxPayment}>{i18next.t('configure_payment_online')} - {node.id}</ModalHeader>
                        <Form id="PAYM-form" role="form">  
                        <ModalBody>
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeItem === '1' })}
                                    onClick={(e) => { toggleTab('1'); }} >
                                    {i18next.t('basic')}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeItem === '2' })}
                                    onClick={(e) => { toggleTab('2'); }}>
                                    {i18next.t('advanced')}
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={activeItem}>
                                <TabPane tabId="1">
                                    <TitleOperator titleOperator={selectedBoxOperator.title} />
                                        <Row>
                                            <Col sm="12"> 
                                                <Label>{i18next.t('statement')} <i className="fa fa-info-circle" id="tooltipEnunciado"></i></Label>
                                                <UncontrolledTooltip placement="right" target="tooltipEnunciado"> {i18next.t('message8')}</UncontrolledTooltip>
                                                  <FormGroup>
                                                    <Mentions defaultValue={botmsg_text || ''}  maxlength={'2000'} msg_error={i18next.t('limit_allowed') + ' 2000'} k={0} updateItem={props.updateItem}/>
                                        
                                                        <div className="options-bar-input" style={{'right':'1px'}}>
                                                        <Emoji id={'head'} callEmoji={props.callEmoji} type="head" style={{'display':'none',position: 'absolute',right: 268+'px',zIndex: 1}}/>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                          </Row>
                                          <Row>
                                          <Col sm="6">
                                          <FormGroup>
                                                <Label>
                                                  {i18next.t('limit_allowed')}{" "}
                                                  <i
                                                    className="fa fa-info-circle"
                                                    id="tooltipTipoSource"
                                                  ></i>
                                                </Label>
                                                <UncontrolledTooltip
                                                  placement="right"
                                                  target="tooltipTipoSource"
                                                >
                                                  {" "}
                                                  {i18next.t('payment_type_select')}
                                                </UncontrolledTooltip>
                                                {listTypeSourcePayment &&(Object.keys(listTypeSourcePayment).length > 0 && (
                                                  <Input  type="select"
                                                    className="inputModal" 
                                                    name="type" 
                                                    defaultValue={(configBoxOperator)?configBoxOperator.source_type:''}
                                                    onChange={(e) => onchangeSourceTypePayment(e)}
                                                    required>
                                                { Object.keys(listTypeSourcePayment).map((k, index) => {
                                                   
                                                    return <option key={index} value={listTypeSourcePayment[k].id} >{listTypeSourcePayment[k].name}</option>;
                                                    
                                                    })
                                                }
                                            </Input> 
                                            ))}
                                              </FormGroup>
                                              </Col>
                                            <Col sm="6">
                                            { variablesBoxOperator && Object.keys(variablesBoxOperator).length > 0 ? (
                                            
                                              <FormGroup>
                                                <Label>
                                                  {i18next.t('payment_var_code')}{" "}
                                                  <i
                                                    className="fa fa-info-circle"
                                                    id="tooltipVariable"
                                                  ></i>
                                                </Label>
                                                <UncontrolledTooltip
                                                  placement="right"
                                                  target="tooltipVariable"
                                                >
                                                  {" "}
                                                  {i18next.t('payment_var_id')}
                                                </UncontrolledTooltip>

                                                <Input
                                                  type="text"
                                                  className="inputModal"
                                                  placeholder="variable_payment"
                                                  onChange={(event) => {
                                                    props.updateVariableBoxOperator(
                                                      "var_main",
                                                      event.target.value,
                                                      "variables"
                                                    );
                                                  }}
                                                  defaultValue={
                                                    variablesBoxOperator && variablesBoxOperator["var_main"]
                                                      ? variablesBoxOperator["var_main"].var_name
                                                      : ""
                                                  }
                                                />
                                              </FormGroup>
                                              
                                              
                                            ) : null}
                                            {exit_var ? (
                                              <Row>
                                                <div className="alert alert-danger" role="alert">
                                                  {i18next.t('message30')}
                                                </div>
                                              </Row>
                                            ) : (
                                              <div></div>
                                            )}
                                            </Col>
                                            
                                         </Row>
                                         
                                         <Row>
                                               <Col sm="6">
                                                 <FormGroup>
                                                <Label>
                                                  Id {i18next.t('stock_keys')}{" "}
                                                  <i
                                                    className="fa fa-info-circle"
                                                    id="tooltipKeys"
                                                  ></i>
                                                </Label>
                                                <UncontrolledTooltip
                                                  placement="right"
                                                  target="tooltipKeys"
                                                >
                                                  {" "}
                                                  {i18next.t('message21')}
                                                </UncontrolledTooltip>
                                               <Input
                                                  type="text"
                                                  className="inputModal"
                                                  placeholder="id_keys"
                                                  onChange={(event) => {
                                                    props.setChangeConfig(
                                                      "id_keys",
                                                      event.target.value
                                                    );
                                                  }}
                                                  defaultValue={(configBoxOperator)?configBoxOperator.id_keys:''}
                                                />
                                              </FormGroup>
                                              </Col>
                                         </Row>
                                  
                                        <Row>
                                          {console.log("configBoxOperator payment ",configBoxOperator)}
                                          {console.log("varListSelected payment ",varListSelected)}
                                        {
                                            configBoxOperator.source_type == 'paymentsway' ? 
                                            (
                                            <Col sm="4">
                                              <FormGroup>
                                                  {varListSelected&&varListSelected.length > 0 ? (
                                                    <SelectStatements 
                                                        varSelected={ configBoxOperator.service_id } 
                                                        onchangeStatements={ props.setChangeConfig } 
                                                        nameVar={ 'service_id' } 
                                                        label={i18next.t('id_servicio')} 
                                                        tooltip={i18next.t('id_servicio')}
                                                        k={'0'}
                                                    ></SelectStatements>
                                                  ):null}
                                                  </FormGroup>
                                            </Col>
                                            ):('')
                                          }
                                        <Col sm="4">
                                          <FormGroup>
                                              
                                              {varListSelected&&varListSelected.length > 0 ? (
                                                <SelectStatements 
                                                    varList={ varListSelected } 
                                                    varSelected={ configBoxOperator.amount_in_cents } 
                                                    onchangeStatements={ props.setChangeConfig } 
                                                    nameVar={ 'amount_in_cents' } 
                                                    label={ 'Valor a pagar' } 
                                                    tooltip={i18next.t('payment_var_amount')}
                                                    k={'0'}
                                                ></SelectStatements>
                                              ):null}
                                               
                                              </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                        <FormGroup>
                                                
                                                {varListSelected&&varListSelected.length > 0 ? (
                                                        <SelectStatements 
                                                            varList={ varListSelected } 
                                                            varSelected={ configBoxOperator.reference_id } 
                                                            onchangeStatements={ props.setChangeConfig } 
                                                            nameVar={ 'reference_id' } 
                                                            label={i18next.t('payment_reference')}
                                                            tooltip={i18next.t('payment_var_reference')}
                                                            k={'0'}
                                                        ></SelectStatements>
                                                ):null}
                                               
                                              </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                        <FormGroup>
                                                <Label>
                                                  Moneda{" "}
                                                  <i
                                                    className="fa fa-info-circle"
                                                    id="tooltipCurrency"
                                                  ></i>
                                                </Label>
                                                <UncontrolledTooltip
                                                  placement="right"
                                                  target="tooltipCurrency"
                                                >
                                                  {" "}
                                                  {i18next.t('payment_var_currency')}
                                                </UncontrolledTooltip>
                                                {listCurrencyPayment &&(Object.keys(listCurrencyPayment).length > 0 && (
                                                  <Input  type="select"
                                                    className="inputModal" 
                                                    name="type" 
                                                    defaultValue={(configBoxOperator)?configBoxOperator.currency:''}
                                                    onChange={(e) => onchangeCurrencyPayment(e)}
                                                    required>
                                                { Object.keys(listCurrencyPayment).map((k, index) => {
                                                   
                                                    return <option key={index} value={listCurrencyPayment[k].id} >{listCurrencyPayment[k].name}</option>;
                                                    
                                                    })
                                                }
                                            </Input> 
                                            ))}
                                              </FormGroup>
                                              </Col>
                                        </Row>
                                </TabPane>
                                <TabPane tabId="2">
                                    <Row>
                                        <Col sm="6">
                                            <IASelector botversion_id={botVersionId} operator_id={selectedBoxOperator.id} ia={selectedBoxOperator.ia_id} />
                                            <Avatar company_id={bot.company_id} avatarid={avatarid} bindValue={props.getAvatarId} />
                                        </Col>
                                        <Col sm="6">
                                        <Keyword keyword={selectedBoxOperator.keyword} isValid={props.isKeywordValid} />
                                        </Col>
                                    </Row>

                                </TabPane>
                            </TabContent>                     
                        </ModalBody>
                        <ModalFooter>
                            <Col sm="12" align="right">
                            {exit_var?(
                                
                                <Button color="default" size="lg" type="button" onClick={props.toggleModalViewBoxPayment} >{i18next.t('cancel')}</Button>
                                ):
                                    <Button color="success" size="lg" type="button" onClick={props.toggleModuleModal} >{i18next.t('finish')}</Button>
                                }
                            </Col>
                        </ModalFooter>
                        </Form>
                    </Modal>           
                </React.Fragment>
                    )
        }
        else
        {
            return(<div></div>)
        }

    }
    else
    {
        return(<div></div>)
    }
       
              
 function toggleTab(tab) {
    
    // e.preventDefault();
    // console.log('The link was clicked.',e);
     props.changeActiveTab(tab);
 
}
function onchangeCurrencyPayment(e)
 {
   
   var type=e.target.value;

   props.setChangeConfig('currency',type);


  
 }
 function onchangeSourceTypePayment(e)
 {
   
   var type=e.target.value;

   props.setChangeConfig('source_type',type);
   props.setChangeConfig('item_type',type+'_payment');

  
 }

 }
 

 
 const mapStateToProps = ({boxReducer,botReducer,homeReducer}) => {
     return {boxReducer, botReducer, homeReducer};
     };
     
     const mapDispatchToProps = {
         iconLoad,
         iconHideLoad,
         toggleModalViewBoxPayment,
         changeActiveTab,
         setChangeConfig,
         updateVariableBoxOperator,
   
     };
     
     export default connect(mapStateToProps, mapDispatchToProps )(PAYMLayout);
