import React from 'react';
import { Row } from 'reactstrap';
import './bots-layout.css';
import BotBox from'./bot-box.js';
import { prop } from 'ramda';

function BotsLayout(props){
    // Este componente es usado para imprimir el layout de bots y schemas, 
    // la lista que imprime viene en la variable (dataView).
    var rows = [];
    props.dataView.map((item, index)=>{
        rows.push(<BotBox key={index} 
            data={item} 
            deleteBot={props.deleteBot} 
            type={props.type} 
            editSchema={props.editSchema} 
            deleteSchema={props.deleteSchema} 
            initial={props.initial} 
            anonimo = {props.anonimo}
            initialStep={props.initialStep}
            anonymous={props.anonymous}
            cloneBot = {props.cloneBot}
            getExportBot = {props.getExportBot}
            getDuplicateBot = {props.getDuplicateBot}
            getExportScheme = {props.getExportScheme}
            getDuplicateScheme = {props.getDuplicateScheme} 
            getLoadSchema = {props.getLoadSchema} />);
    });
   
    return (
        <div className="box">
            <Row className="bots-preview"> {rows} </Row>
        </div>
    );
}


export default BotsLayout;
