import React from 'react';
import { 
    Button, 
    Input,Table,
    Modal, 
    ModalHeader, 
    ModalBody, 
    ModalFooter,
    Form, 
    FormGroup, 
    Col,
    Row,
    Label,
    UncontrolledTooltip
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faSync, faPlus} from '@fortawesome/free-solid-svg-icons'
import Mentions from '../../../../components/mentions/mentions';

import i18next from '../../../../../i18n';

function EMAILModalLayout(props){


    let template = props.listTemplates || [];
    var sel_template = [];
    template.map((t, i) => {     
      sel_template.push(<option key={"template_id-"+i} value={t.id} selected={t.id == props.idtemplateSelected}> {t.template_name} </option>);
    })

    let variableElements=()=>{
        let listElements=[]
        props.variables.map((value,index)=>{
            listElements.push(
                <tr key={index}>
                    <td style ={{width:'50%'}}>
                        <Input type="text" id={'key-'+index} defaultValue={value.key} onChange={(e) => props.handleChangeNameVar(index,e.target.value)} />
                    </td>
                    <td style ={{width:'40%', textAlign:'center'}}>
                        <Mentions k={'valueVar-'+index}  defaultValue={value.value} className={'td-flujo'} varList={props.varList} updateItem={props.updateItem} />
                    </td> 
                    <td style ={{width:'10%'}}>
                        <Button color="info" size="lg" onClick={() => props.removeVariable(index) }><FontAwesomeIcon icon={faTrash} /></Button>
                    </td>
                </tr>
            )
        })
        return listElements;
    }

        return (
            <div>
                <Modal className="email" isOpen={props.isOpen} toggle={props.closeModal} fade size="lg">
                    <ModalHeader toggle={props.closeModal}>{i18next.t('set_up')} email</ModalHeader>
                    <ModalBody>
                        <Form id="EM-form" role="form">  
                            <FormGroup>
                                <Row>
                                    <Col>
                                    <Label>{i18next.t('sended_time')}</Label>
                                        <Input type="select" name="select" onChange={(event) => {props.showMetion(event)}} value={props.config} >
                                            <option value="">- {i18next.t('select_one')} -</option>
                                            <option value="0">{i18next.t('immediate')}</option>
                                        </Input>
                                        <Label>{i18next.t('subject')+': '} <i className="fa fa-info-circle" id="tooltipsubject"></i></Label>
                                        <UncontrolledTooltip placement="right" target="tooltipsubject"> {i18next.t('write_subject')}</UncontrolledTooltip>
                                        <Mentions
                                        className={'td-flujo'}
                                        varList={props.varList}
                                        defaultValue={props.subject}
                                        maxlength={'2000'} msg_error={i18next.t('limit_allowed') + ' 2000'}
                                        updateItem={props.updateItem}
                                        index={'subject'}
                                        k={'subject'}/>
                                         </Col>
                                    <Col>    
                                        <Row>
                                            <Col md={9}>
                                            <Label>{i18next.t('select_template')+': '} </Label>
                                            <Input type="select" className="inputModal" id="idtemplateSelected" name="idtemplateSelected" placeholder=" " onChange={props.handleChangeInput} value={props.typetime} > 
                                                <option key={"template_id-0"} value='' >-{i18next.t('select_one')}-</option>
                                            {sel_template}
                                            </Input> 
                                            </Col>
                                            <Col md={2} className="pl-0 pt-5 pr-0 text-center">
                                                <Button outline color="success" onClick={ (e) => props.getlistTemplates()}>
                                                    <FontAwesomeIcon icon={faSync} id='buttonRefresh' style={{'fontSize':'18px'}} />
                                                </Button>
                                                <UncontrolledTooltip placement="bottom" target='buttonRefresh'> {i18next.t('reload_template_email_list')}</UncontrolledTooltip>
                                            </Col>
                                            <Col md={1} className="pl-0 pt-5">
                                                <a href={"/Newtemplates/"}  target="_blank" id="buttonRefreshE">
                                                    <FontAwesomeIcon icon={faPlus} style={{'fontSize':'18px', 'color':'green'}} />
                                                </a>
                                                <UncontrolledTooltip placement="bottom" target='buttonRefreshE'>{i18next.t('add_contact_list')}</UncontrolledTooltip>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Label>{i18next.t('sender_email')+': '} </Label>
                                                <Mentions 
                                                className={'td-flujo'}
                                                varList={props.varList}
                                                defaultValue={props.emailSender}
                                                maxlength={'2000'} msg_error={i18next.t('limit_allowed') + ' 2000'}
                                                updateItem={props.updateItem}
                                                index={'emailSender'}
                                                k={'emailSender'}/>
                                               
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Label>{i18next.t('email_receiver')+': '} </Label>
                                        <Mentions 
                                                className={'td-flujo'}
                                                varList={props.varList}
                                                defaultValue={props.emailReceiver}
                                                maxlength={'2000'} msg_error={i18next.t('limit_allowed') + ' 2000'}
                                                updateItem={props.updateItem}
                                                index={'emailReceiver'}
                                                k={'emailReceiver'}/>
                                                                  
                                    </Col>
                                    <Col>
                                        <Label>{i18next.t('email_cc')+': '} </Label>
                                        <Mentions 
                                                className={'td-flujo'}
                                                varList={props.varList}
                                                defaultValue={props.emailsCCO}
                                                maxlength={'2000'} msg_error={i18next.t('limit_allowed') + ' 2000'}
                                                updateItem={props.updateItem}
                                                index={'emailsCCO'}
                                                k={'emailsCCO'}/>
                                      
                                    </Col>
                                </Row>
                                <Row>
                                        <Col sm="12">
                                            <FormGroup>
                                                <Table style={{'textAlign':'center'}}>
                                                    <thead>
                                                        <tr>
                                                            <th style ={{width:'50%'}}>{i18next.t('name_var')}</th>
                                                            <th style ={{width:'40%'}}>{i18next.t('value_var')}</th>
                                                            <th style ={{width:'10%'}}>{i18next.t('action')}</th>
                                                        </tr>
                                                    </thead>                                    
                                                    <tbody>
                                                        {variableElements()}
                                                    </tbody>
                                                </Table>    
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className="justify-content-md-center">
                                    <Button className="" color="info" size="lg" onClick={()=>props.addElementVariable()} >{i18next.t('add_var')}</Button>
                                </Row>

                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Col sm="12" align="right">
                            <Button color="success" className="btn-guarumo-modal-sm" type="button" onClick={props.toggleModuleModal} >{i18next.t('finish')}</Button>
                        </Col>
                    </ModalFooter>
                </Modal>
            </div>
        );
}
  
export default EMAILModalLayout
