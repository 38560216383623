import { BOT_ACTIONS_TYPES } from '../types/botActionsTypes';

const INITIAL_STATE = {
    botVersionId: "",
    bot: [],
    idSchema:0,
    company_id: 0,
    ia_activated: '',
    listIntent:[],
    platform: '',
    schema:[],
    urlPath:'',
    showEdition:false,
    showConfig:true,
    showEditionEntity:false,
    nameModule:'',
    idAnswers:'',
    questions:[],
    answers:[],
    timeout:{
        operatorsList1:[],
        operatorsList2:[],
        operatorsList3:[],
        validOperator1:true,
        validOperator2:true,
        validOperator3:true,
        validScheme1:true,
        validScheme2:true,
        validScheme3:true,
        type1:'send_message',
        type2:'send_message',
        type3:'send_message'
    }

}

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {

         /**
         * Reducer Bot
         */
         case BOT_ACTIONS_TYPES.setBotVersionId():
             return {
                 ...state,
                 botVersionId: action.payload,
          };
          case BOT_ACTIONS_TYPES.setBot():
            return {
                ...state,
                bot: action.payload,
         };
         case BOT_ACTIONS_TYPES.setSchema():
            return {
                ...state,
                schema: action.payload,
         };
         case BOT_ACTIONS_TYPES.setIdSchema():
            return {
                ...state,
                idSchema: action.payload,
         };
         case BOT_ACTIONS_TYPES.setCompanyId():
            return {
                ...state,
                company_id: action.payload,
         };
         case BOT_ACTIONS_TYPES.setIa_activated():
            return {
                ...state,
                ia_activated: action.payload,
         };
         case BOT_ACTIONS_TYPES.setListIntent():
            return {
                ...state,
                listIntent: action.payload,
         };
         case BOT_ACTIONS_TYPES.setShowEdition():
            return {
                ...state,
                showEdition: action.payload,
         };
         case BOT_ACTIONS_TYPES.setShowConfig():
            return {
                ...state,
                showConfig: action.payload,
         };
         case BOT_ACTIONS_TYPES.setShowEditionEntity():
            return {
                ...state,
                showEditionEntity: action.payload,
         };
         
         case BOT_ACTIONS_TYPES.setPlatform():
            return {
                ...state,
                platform: action.payload,
         };
         case BOT_ACTIONS_TYPES.setUrlPath():
            return {
                ...state,
                urlPath: action.payload,
         };
         case BOT_ACTIONS_TYPES.setTimeout():
            return {
                ...state,
                timeout: action.payload,
         };
         case BOT_ACTIONS_TYPES.setShowQuestions():
            return {
                ...state,
                questions: action.payload,
         };
         case BOT_ACTIONS_TYPES.setNameModule():
            return {
                ...state,
                nameModule: action.payload,
         };
         case BOT_ACTIONS_TYPES.setIdAnswers():
            return {
                ...state,
                idAnswers: action.payload,
         };
         case BOT_ACTIONS_TYPES.setShowAnswers():
            return {
                ...state,
                answers: action.payload,
         };
            default: return state;
        };
        
    };