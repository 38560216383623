import React, {Component} from 'react';
import { Label,Input,UncontrolledTooltip,Button,Modal,ModalHeader,ModalBody,ModalFooter,Form,FormGroup,Table,Col,Row,Nav,NavItem,TabContent,TabPane,NavLink} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faLink } from '@fortawesome/free-solid-svg-icons'
import IASelector from '../../../../components/ia';
import CMSSelector from '../../../../components/cms'
import Keyword from '../../../../components/keyword/keyword';
import Timeout from '../../../../components/timeout/timeout';
import TitleOperator from "../../../../components/title-operator";
import Mentions from '../../../../components/mentions/mentions';
import Avatar from '../../../../components/avatar';
import Audio from '../../../../components/audio-input';
import Emoji from '../../../../components/emoji-picker';
import ModalUrl from '../../../../components/modals/shorturl/modal-shorturl';
import {Link} from 'react-router-dom';
import classnames from 'classnames';
import i18next from '../../../../../i18n';



class CRMLayout extends Component {

    constructor(props){
        super(props)
        this.state = {
        activeTab: '1'
        };
        this.toggleTab = this.toggleTab.bind(this);
    }

    inputEnunciado = (bls,kblocks) => {
        let block = kblocks.map((k, index) => {
            return <tr key={k} style={{'display':'table','width': 100+'%','tableLayout': 'fixed'}}>
                <td className={ this.props.visible == 1 ? 'visibilityInput':'td-flujo' } style={{'height':'70px'}}>
               <Row>
                <Col sm="6">
                    <FormGroup>
                        <Label>{i18next.t('column_title')} <i className="fa fa-info-circle" id={"tooltipCol_"+k}></i></Label>
                        <UncontrolledTooltip placement="right" target={"tooltipCol_"+k}> {i18next.t('custom_report_msg')}</UncontrolledTooltip>
                        <Input id={"TMM-input-value-"+k} type="text" name={"titleColumn_"+k}  defaultValue={bls[k].name=='null'?'':bls[k].name} maxLength={'50'}/>
                    </FormGroup>
                </Col>
                <Col sm="6">
                    <Label>{i18next.t('value')} <i className="fa fa-info-circle" id={"tooltipVal_"+k}></i></Label>
                    <UncontrolledTooltip placement="right" target={"tooltipVal_"+k}> {i18next.t('custom_report_msg2')}</UncontrolledTooltip>
                    <Mentions 
                        className={ this.props.visible == 1 ? 'visibilityInput':'td-flujo' }
                        varList={this.props.varList}
                        k={k}
                        defaultValue={bls[k].text}
                        updateItem={this.props.updateItem}/>
                </Col>
                </Row>
                    <div className="bar-emoji-input">                        
                        <Button color="info" size="lg" onClick={() => this.props.removeTextBlock(k) }>
                            <FontAwesomeIcon icon={faTrash} />
                        </Button>
                        <Button color="info" size="lg" onClick={() => this.props.toggleModalUrl(k) }>
                            <FontAwesomeIcon icon={faLink} />
                        </Button>
                        <Emoji id={k} callEmoji={this.props.callEmoji} type="item" style={{'display':'none',position:'fixed',marginLeft: 60+'%',marginTop:31+'px',zIndex: 100}}/>
                    </div>
                    
                </td>
                <td className={ this.props.visible == 1 ? 'visibilityBlockInput':'visibilityInput' } >
                    <p>
                        <Link style={{'color':'#494949'}} className="text-reset text-decoration-none" target="_blank" to={'/cms/'+this.props.botId+'?operador='+this.props.operator}>
                            {i18next.t('config_cms')}
                        </Link>
                    </p>
                </td>
                <td className={ this.props.visible == 1 ? 'visibilityBlockInput':'visibilityInput' } style={{'width':'8%'}}>
                    <Button color="info" size="lg" onClick={() => this.props.removeTextBlock(k) }>
                        <FontAwesomeIcon icon={faTrash} />
                    </Button>
                </td>
            </tr>
        });
        return block;
    }
    
    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
          this.setState({
            activeTab: tab
          });
        }
    }

    render(){
        let bls = this.props.text_blocks || {};
        let kblocks = Object.keys(bls);
        let blocks = this.inputEnunciado(bls,kblocks)

        return (
            <React.Fragment>
                <Modal className="report" isOpen={this.props.isOpen} toggle={this.props.closeModal} fade size="lg">
                    <ModalHeader toggle={this.props.closeModal}>{i18next.t('custom_report_msg3')}</ModalHeader>
                    <ModalBody>
                        <Form role="form"> 
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                    className={classnames({ active: this.state.activeTab === '1' })}
                                    onClick={() => { this.toggleTab('1'); }} >
                                    {i18next.t('basic')}
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={this.state.activeTab}>
                                <TabPane tabId="1">
                                    <Row>
                                    <Col sm="12">
                                        <FormGroup id='Enunciados' >
                                            <TitleOperator titleOperator={this.props.titleOperator} bindValue={this.props.getTitleOperator}/>
                                            {/* <div id='div_enunciados' > */}
                                                <Table style={{'textAlign':'left'}}>
                                                    <thead>
                                                    <tr>
                                                        <th colSpan="2">{i18next.t('statements')}</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody style={{'maxHeight':380+'px', 'overflowX':'hidden','overflowY':'scroll', 'display':'block'}}>
                                                    { blocks }
                                                    </tbody>
                                                </Table> 
                                            {/* </div> */}
                                                <Col sm="12" className="button-add">
                                                    <Col sm="12">
                                                        <Button color="info" className="btn-guarumo-modal-blue-sm" onClick={this.props.addNewTextBlock}>{i18next.t('add_new')}</Button>
                                                    </Col>
                                                </Col>  
                                           
                                        </FormGroup>
                                    </Col>
                                    </Row>
                                </TabPane>
                            </TabContent>
                        </Form>
                    </ModalBody>
                    <ModalFooter>  
                            <Col sm="12" align="right">
                                <Button color="success" className="btn-guarumo-modal-sm" type="button" onClick={this.props.toggleModuleModal} >{i18next.t('finish')}</Button>
                            </Col>
                    </ModalFooter>
                </Modal>
                <ModalUrl isOpen={this.props.isOpenUrl} toggleModal={this.props.toggleModalUrl} bindValue={this.props.getUrl} input={this.props.inputStatement}/>
            </React.Fragment>
        );
    }
}
  
export default CRMLayout
