import React, {Component} from "react";
import Admin from "../../admin/containers/admin";
import SliceMenuApp from "../../admin/components/slice-menu";
import {
  Col,
  Row,
  Button,
  Container,
  Table,
  UncontrolledTooltip
} from "reactstrap";
import ToolbarApp from "../../admin/components/toolbar";
import BotMenuOptions from '../../menus/bot-menu-options.js';;
import Pagination from "react-js-pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; 
import { faTrash, faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import {Link} from 'react-router-dom';


class ContactListsLayout extends Component {
  
      constructor(props) {
        super(props);
        this.state = {
            activePage: 1,
            camposkeys:[]
        }
        this.handlePageChange = this.handlePageChange.bind(this);
      }
    
      deleteContact=(num_row,_id)=>{
        if (confirm("Desea eliminar el contacto?")) {
          var data = {
            num_row: num_row,
            _id: _id
          };
          let url_api = HOST_API + "/api/listas/delete_contacts";
          getHandlerPOST(url_api, data)
            .then(res => {
            this.getContacts(_id,1);
            })
            .catch(this.errorAction);
        }
      };
      
      handlePageChange(pageNumber){
        this.setState({activePage: pageNumber},()=>{this.props.getContacts(this.props.idList,this.state.activePage)});
      }
    
      displayPage = all => Math.round(all / 12);

      getCabeceras = () =>{
          let list = this.props.contacts;
          let ckeys = [];
          let cabeceras = null
          if(list){
            if (list.length > 0) { 
              ckeys = Object.keys(list[0]).filter(value => { 
              return value != "_id" && value != "repeated" && value != "valid_email" && value != "reason"; 
              }); 
          
              cabeceras = ( 
              <tr>
                <td></td>
                <td>Acción</td> 
                  {ckeys.map((ck, index2) => { 
                  if(ck!='num_row'){   
                      return <td key={index2}>{ck}</td>; 
                  } 
                  })} 
                  </tr> 
              );   
              return cabeceras;
          }
          }  
      }

      getDatos = () =>{
        let list = this.props.contacts;
        let contacts = []
          if (list.length > 0) { 
            let cakeys = [];
            cakeys = Object.keys(list[0]).filter(value => { 
                return value != "_id" && value != "repeated" && value != "valid_email" && value != "reason";  
            }); 
            contacts = list.map((el, index) => { 
                return <tr key={index} className={'repetidos'+el.repeated}> 
                      <td>
                      {
                        el.valid_email && el.valid_email != '' &&
                        <React.Fragment>
                          <FontAwesomeIcon className='validos' id={"true" + index} icon={faCheckCircle}/>
                          <UncontrolledTooltip
                              placement="top"
                              target={"true" + index}
                            >Correo Valido
                            </UncontrolledTooltip>
                        </React.Fragment>
                      }
                      {
                        el.valid_email === false &&
                        <React.Fragment>
                          <FontAwesomeIcon className='repetidos' id={"false" + index} icon={faTimesCircle}/>
                          <UncontrolledTooltip
                              placement="top"
                              target={"false" + index}
                            >{el.reason}
                            </UncontrolledTooltip>
                        </React.Fragment>
                      }   
                      </td>
                      <td className=" text-center align-middle" style={{'width':'10%'}}> 
                          { 
                            <div>                   
                              <Button  outline  color="danger"    
                                onClick={ e => this.props.deleteContact(el.num_row,this.props.idList)}   > 
                                <FontAwesomeIcon icon={faTrash} id={"buttonRemove" + index } /> 
                              </Button> 
                            </div> 
                          } 
                      </td> 
                      {this.getCols(cakeys, el)} 
                </tr>; 
              }); 
          }
          return contacts;
        }

    getCols(ckeys, el) { 
        return ckeys.map((ck, index2) => {           
          if(ck!='num_row'){    
            return <td key={index2}>{el[ck]}</td>; 
          } 
        });      
    } 


    render() { 
        let cabeceras = this.getCabeceras();
        let contactos = this.getDatos();
        let display = this.displayPage(this.props.allItems) > 6 ? 6 : this.displayPage(this.props.allItems);
        return (
            <div className="CMSLayout">
            <Admin>
                <SliceMenuApp>
                  <BotMenuOptions
                  admin={this.props.isAdmin} 
                  />
                </SliceMenuApp>
                <Container fluid>
                <ToolbarApp>
                    <Row>
                    <Col sm="9" md="10" lg="10" className="text pdtoolbar">
                        <h2 className="text-left ml-4 font-weight-bold">
                        Detalle lista de contacto
                        </h2>
                    </Col>
                      <Col sm="3" md="2" lg="2" className="filter-stuff">
                          <Link to={'/contact-lists/'} className="btn btn-guarumo-md btn-block">Volver</Link>
                      </Col>
                    </Row>
                </ToolbarApp>
                </Container>
                <Container fluid className="box-fluid">
                    <Container className="user-container col-10 offset-1 pl-0 pr-0">
                            <Col md="12" className="pl-0 pr-0">
                                <Row>
                                <Col className="table-responsive"> 
                                <Table className="table-hover table table-sm table-condensed table-bordered table-detail"> 
                                    <thead>{cabeceras}</thead> 
                                    <tbody>{contactos}</tbody> 
                                </Table> 
                                </Col> 
                                </Row>
                            </Col>
                    </Container>
                    <Col md="12" className="pl-0 pr-0">
                        <Col className="col-4 offset-4">
                            <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={this.props.allItems} 
                            pageRangeDisplayed={display}
                            onChange={this.handlePageChange}/>
                        </Col>
                    </Col>
                </Container>
            </Admin>      
            </div>
        );
     }
}

export default ContactListsLayout;
