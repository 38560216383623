import React, { Component } from 'react';
import { connect } from "react-redux";

import { Row,Col } from 'reactstrap';
import DatePicker from 'react-datepicker'; // https://reactdatepicker.com/ for options and settings.
import { Helmet } from 'react-helmet';
import qs from 'qs';
import moment from 'moment';
import Button from  './Button';
import 'react-datepicker/dist/react-datepicker.css'; // Base styling for Calendar
import './DatePickerSimple.css'; // Custom Styling for Calendar

import { iconLoad, iconHideLoad, setSelectedOperator,setCalendarDate, setConfigbyTypeCalendar,setChangeConfig } from "../../redux/actions/boxActions";
import { isAuthorizationValid,getRolUser,isEmpty,cerrarSesion } from "../../utils/commonFunction";
import ModalLoading from '../modals/Loading/moda-loading';






class DatePickerSimple extends Component {
    constructor(props){
        super(props);
        const receivedParameters = qs.parse(window.location.search, { ignoreQueryPrefix: true });

       // let config = window.configCalendar;

        console.log("Parametros de configuracion Calendario: ",window.configCalendar);

        console.log("Props Calendario: ",props);

        

        if(receivedParameters.operator_id)
        {
            console.log("Parametro recibidos: Operator_id:",receivedParameters.operator_id);
            console.log("Parametro recibidos: Session_id:",receivedParameters.session_id);
            console.log("Parametro recibidos: bot_version_id:",receivedParameters.bot_version_id);

           this.state = {
               operator_id : receivedParameters.operator_id,
               session_id : receivedParameters.session_id,
               bot_version_id : receivedParameters.bot_version_id,
               platform : receivedParameters.platform
           }
        }
       /* else if(config){
            this.state = {
                selectedDate: moment(new Date(config.startDate)),
                item_type: config.item_type,
                startDate:moment(new Date(config.startDate)),//moment().subtract(1,'months'),
                endDate:moment(new Date(config.endDate)),
                showTimeSelect:Boolean.valueOf(config.showTimeSelect)
                // timeIntervals:config.timeIntervals,
                // minDate:moment(new Date(config.minDate)),
                // maxDate: moment(new Date(config.maxDate)),
                // maxTime: config.maxTime,
                // minTime: config.minTime,
                // excludeDates: config.excludeDates,
                // excludeTimes: config.excludeTimes,
                // filterDate:config.filterDate,        
            }
        }*/
       
    }
    componentDidMount() {

         //----------------------------------------------------------------
        //  PONE EL ICONO DE CARGA
        //----------------------------------------------------------------
        this.props.iconLoad();


        const {configBoxOperator} = this.props.boxReducer;

        if(isEmpty(configBoxOperator)&&this.state&&this.state.operator_id){
            this.props.setSelectedOperator(this.state.operator_id)
        }
        
        

        //----------------------------------------------------
        //  OCULTA EL ICONO DE CARGA
        //----------------------------------------------------
        this.props.iconHideLoad(); 

    }

    componentDidUpdate(nextProps,nextState)
    {
        const configBoxOperator = this.props.boxReducer.configBoxOperator;
        const newconfigOperator = nextProps.boxReducer.configBoxOperator;

        if(configBoxOperator!=newconfigOperator&&newconfigOperator.selectedStartDate)
        {
            this.state = {
                selectedStartDate: moment(new Date(newconfigOperator.selectedStartDate)),
                item_type: newconfigOperator.item_type,
                startDate:moment(new Date(newconfigOperator.startDate)),//moment().subtract(1,'months'),
                endDate:moment(new Date(newconfigOperator.endDate)),
                //showTimeSelect:Boolean.valueOf(newconfigOperator.showTimeSelect)
                showTimeSelect : (newconfigOperator.showTimeSelect=='False')?false:true
                // timeIntervals:config.timeIntervals,
                // minDate:moment(new Date(newconfigOperator.minDate)),
                // maxDate: moment(new Date(newconfigOperator.maxDate)),
                // maxTime: newconfigOperator.maxTime,
                // minTime: newconfigOperator.minTime,
                // excludeDates: newconfigOperator.excludeDates,
                // excludeTimes: newconfigOperator.excludeTimes,
                // filterDate:newconfigOperator.filterDate,    
            }
        }
    }
    /*componentDidUpdate(props,newProps)
    {
        console.log("Update")
        
    }*/
    handleChange = (date) => {
        this.setState({
            selectedStartDate: date
        });
    }
    hangeDateStart(d)
    {
        //this.setState({startDate:d});

        this.props.setChangeConfig('selectedStartDate',d.format('MM/DD/YYYY h:mm a'));

        this.props.setConfigbyTypeCalendar();
    }
    changeDateEnd(d)
    {
        //this.setState({endDate:d});
        this.props.setChangeConfig('endDate',d.format('MM/DD/YYYY h:mm a'));
    }
    submitDate = () => {
        const { server } = this.props;
       // const { startDate,endDate, item_type } = this.state;

        const { selectedStartDate,selectedEndDate, item_type } = this.props.boxReducer.configBoxOperator;
       // userId='92febfc59a36d19639cd3781' 
       // fetch(`${server}/date`, {
        // fetch(`https://da58bc33.ngrok.io/date`, {
        //     method: 'POST',
        //     body: JSON.stringify(this.state),
        //     headers: {
        //         'Content-Type': 'application/json'
        //     }
        // })
        // .then(response => {
        //     console.log(response, `Submitted ${selectedDate.format('MMM Do YYYY')} for user(${userId})`);
        //     window.WebviewSdk.close();
        // })
        // .catch(err => console.log(err));
       // if(startDate)
       //     console.log( `Su cita será asignada para el día ${startDate.format('DD MMM YYYY')}`);

        /*let startDateSelected =  moment(new Date(selectedStartDate));
        let endDateSelected =  moment(new Date(selectedEndDate));

        if(this.state.platform=='web')//(this.state.bot_version_id)
            window.getCalendarDate (startDateSelected, endDateSelected,item_type);
        else*/
        this.props.setCalendarDate(this.state.platform,this.state.bot_version_id, this.state.session_id,this.state.operator_id,(urlDirect)=>{
            this.props.history.push(urlDirect);
          });
            

    }

   
    showContent=()=>{
          const {configBoxOperator, msmbtnPostback} = this.props.boxReducer;

            if(configBoxOperator.item_type||window.configCalendar)
            {
                //const { startDate,endDate, item_type, showTimeSelect, selectedDate } = this.state;
                
                let selectedStartDate, item_type,startDate,endDate,showTimeSelect,zone, minDate,maxDate;

                selectedStartDate = configBoxOperator.selectedStartDate?moment(new Date(configBoxOperator.selectedStartDate)):null;
                item_type = configBoxOperator.item_type;
                startDate  = configBoxOperator.startDate?moment(new Date(configBoxOperator.startDate)):null;
                endDate = configBoxOperator.endDate?moment(new Date(configBoxOperator.endDate)):null;
                minDate  = configBoxOperator.minDate?moment(new Date(configBoxOperator.minDate)):null;
                maxDate =  configBoxOperator.maxDate?moment(new Date(configBoxOperator.maxDate)):null;
               // showTimeSelect = Boolean.valueOf(configBoxOperator.showTimeSelect);
                showTimeSelect = (configBoxOperator.showTimeSelect=='False')?false:true
                zone = configBoxOperator.zone;


                var datePicker;
                if (item_type=='date_calendar'||item_type=='datetime_calendar') {
                    datePicker =  <div>
                    <Helmet title={'Seleccione una fecha'} />
                    <DatePicker
                        selected={selectedStartDate?selectedStartDate:moment()}//{this.props.analyticsReducer.startDateDashboard}//{this.props.startDate}
                        selectsStart  
                        minDate={minDate?minDate:undefined}
                        maxDate={maxDate?maxDate:undefined}
                        startDate={startDate?startDate:undefined}//{  this.props.analyticsReducer.startDateDashboard}//{this.props.startDate}
                        endDate={endDate?endtDate:undefined}//{  this.props.analyticsReducer.endDateDashboard}//{this.props.endDate}
                        onChange={(d)=>{this.hangeDateStart(d)}}//{this.props.changeStartDateDashboard(d)}}//{this.props.handleChangeStart}
                        openToDate={selectedStartDate?selectedStartDate:moment()}
                        //isClearable={true}
                        showTimeSelect={showTimeSelect}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        //className="input-border"
                        dateFormat={showTimeSelect==true?"YYYY/MM/DD  hh:mm a":"YYYY/MM/DD"}
                        className="form-control"
                        timeInputLabel={i18next.t('hour')}
                        inline
                        
                        
                    /></div>;
                } else {
                    datePicker = <div />;
                }
          return ( 
          
            <div id="datepicker-simple">
                {datePicker}
                    <div className="navBottom">
                        <Button
                            // selectedStartDate={selectedStartDate}
                            selectedStartDate={msmbtnPostback?msmbtnPostback:selectedStartDate?selectedStartDate.format('DD/MM/YYYY  hh:mm a'):null}
                            submitDate={this.submitDate}
                        />
                    </div>
                </div>
    );
    }else
    {
        return <ModalLoading />
    }

}

render() {
return this.showContent();
}
}

const mapStateToProps = ({ boxReducer,homeReducer, componentMountReducer }) => {
return { boxReducer,homeReducer, componentMountReducer };
};

const mapDispatchToProps = {
iconLoad,
iconHideLoad,
setSelectedOperator,
setCalendarDate,
setConfigbyTypeCalendar,
setChangeConfig

};

export default connect(mapStateToProps, mapDispatchToProps)(DatePickerSimple);
