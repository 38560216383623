import React from 'react';
import { 
    Button, 
    Modal, 
    ModalHeader, 
    ModalBody, 
    ModalFooter,
    Form, 
    FormGroup, 
    Table, 
    Input, 
    Col,
    Row,
    Label
} from 'reactstrap';
import '../search-variable.css';
import {ToastsContainer, ToastsStore} from 'react-toasts';
import i18next from '../../../i18n';

function EventLayout(props){
    return (
        <div>
            <Modal isOpen={props.isOpen} toggle={ () => props.toggleModuleModal() } fade size="lg">
                <ModalHeader toggle={ () => props.toggleModuleModal() }>{i18next.t('configure_event')}</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col sm="12">
                            <FormGroup>
                                <Label>{i18next.t('event')}</Label>
                                <Input type="text" className="inputModal"
                                    placeholder={i18next.t('event_name')} 
                                    value={props.eventTemp || ''} onChange={(event) => { props.updateEventName(event) }} required
                                    />
                                <p>{i18next.t('event_name')+': '}  <a style={{color:'green'}} > {props.event} </a></p>
                                <ToastsContainer store={ToastsStore}/>
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Col sm="6" align="right">
                        <Button
                         color="success"
                         size="lg" 
                         type="button" 
                         className="btn-guarumo-modal-sm btn btn-success"
                         onClick={props.toggleModuleModal} > {i18next.t('finish')}</Button>
                    </Col>
                </ModalFooter>
            </Modal>
        </div>
    );
}
  
export default EventLayout