import React, {Component, useState} from 'react';
import { connect } from "react-redux";

import { Label,Button,Modal,ModalHeader,ModalBody,ModalFooter,Form,FormGroup,Input,Col,Row,Nav,NavItem,TabContent,TabPane,NavLink,UncontrolledTooltip} from 'reactstrap';
import IASelector from '../../../../components/ia';
import Keyword from '../../../../components/keyword/keyword-redux';
import Mentions from '../../../../components/mentions/metions-redux';
import Avatar from '../../../../components/avatar';
import TitleOperator from "../../../../components/title-operator";
import Emoji from '../../../../components/emoji-picker';
import classnames from 'classnames';
import TabAdvanced from "../../../../components/box/tab_advanced";
import i18next from '../../../../../i18n';

import { isEmpty} from "../../../../utils/commonFunction";
import { iconLoad, 
        iconHideLoad, 
        toggleModalViewBoxScanQR,
        changeActiveTab,
        setChangeConfig,
        updateVariableBoxOperator,
        
       } from "../../../../redux/actions/boxActions";


let  item_type;


function SQRMLayout(props) {
   
    
    const {node,selectedBoxOperator, listTypeScanner,configBoxOperator, modalViewBoxScanQR,activeItem,variablesBoxOperator,avatarid,botmsg_text,exit_var} =props.boxReducer;

   
    const { bot,botVersionId } = props.botReducer;
   
    item_type = null;
 

  
    if(selectedBoxOperator&&Object.keys(selectedBoxOperator).length>0&&selectedBoxOperator.operatortype_id==23)
    {

            if(configBoxOperator&&configBoxOperator.item_type)
            {
                //selectedDate = moment(new Date(configBoxOperator.selectedDate));
                item_type = configBoxOperator.item_type;
             
                return (
                    <React.Fragment>
                    <Modal className="scanqr"
                    isOpen={modalViewBoxScanQR}
                    toggle={props.toggleModalViewBoxScanQR} 
                    // isOpen={props.isOpen} toggle={props.closeModal} 
                    fade size="lg">
                        <ModalHeader toggle={props.toggleModalViewBoxScanQR}>{i18next.t('configure_scanner')} - {node.id}</ModalHeader>
                        <Form id="SQRM-form" role="form">  
                        <ModalBody>
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeItem === '1' })}
                                    onClick={(e) => { toggleTab('1'); }} >
                                    {i18next.t('basic')}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeItem === '2' })}
                                    onClick={(e) => { toggleTab('2'); }}>
                                    {i18next.t('advanced')}
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={activeItem}>
                                <TabPane tabId="1">
                                    <TitleOperator titleOperator={selectedBoxOperator.title} />
                                        <Row>
                                            <Col sm="12"> 
                                                <Label>{i18next.t('statement')} <i className="fa fa-info-circle" id="tooltipEnunciado"></i></Label>
                                                <UncontrolledTooltip placement="right" target="tooltipEnunciado"> {i18next.t('message8')}</UncontrolledTooltip>
                                                  <FormGroup>
                                                    <Mentions defaultValue={botmsg_text || ''}  maxlength={'2000'} msg_error={i18next.t('limit_allowed') + ' 2000'} k={0} updateItem={props.updateItem}/>
                                        
                                                        <div className="options-bar-input" style={{'right':'1px'}}>
                                                        <Emoji id={'head'} callEmoji={props.callEmoji} type="head" style={{'display':'none',position: 'absolute',right: 268+'px',zIndex: 1}}/>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                          </Row>
                                          <Row>
                                          <Col sm="6">
                                              <FormGroup>
                                                <Label>
                                                  {i18next.t('scanner_type_code')}{" "}
                                                  <i
                                                    className="fa fa-info-circle"
                                                    id="tooltipTipo"
                                                  ></i>
                                                </Label>
                                                <UncontrolledTooltip
                                                  placement="right"
                                                  target="tooltipTipo"
                                                >
                                                  {" "}
                                                  {i18next.t('scanner_type_code_msg')}
                                                </UncontrolledTooltip>
                                                {listTypeScanner &&(Object.keys(listTypeScanner).length > 0 && (
                                            <Input  type="select"
                                                    className="inputModal" 
                                                    name="type" 
                                                    defaultValue={(configBoxOperator)?item_type:''}
                                                    onChange={(e) => onchangeTypeScanner(e)}
                                                    required>
                                                { Object.keys(listTypeScanner).map((k, index) => {
                                                    return <option key={index} value={listTypeScanner[k].type} >{listTypeScanner[k].text}</option>;
                                                    
                                                    })
                                                }
                                            </Input> 
                                            ))}
                                              </FormGroup>
                                              </Col>
                                            <Col sm="6">
                                            { Object.keys(variablesBoxOperator).length > 0 ? (
                                            
                                              <FormGroup>
                                                <Label>
                                                  {i18next.t('message9')}{" "}
                                                  <i
                                                    className="fa fa-info-circle"
                                                    id="tooltipVariable"
                                                  ></i>
                                                </Label>
                                                <UncontrolledTooltip
                                                  placement="right"
                                                  target="tooltipVariable"
                                                >
                                                  {" "}
                                                  {i18next.t('scanner_name_var_code')}
                                                </UncontrolledTooltip>

                                                <Input
                                                  type="text"
                                                  className="inputModal"
                                                  placeholder= {i18next.t('message9')}
                                                  onChange={(event) => {
                                                    props.updateVariableBoxOperator(
                                                      "var_main",
                                                      event.target.value,
                                                      "variables"
                                                    );
                                                  }}
                                                  defaultValue={
                                                    variablesBoxOperator && variablesBoxOperator["var_main"]
                                                      ? variablesBoxOperator["var_main"].var_name
                                                      : ""
                                                  }
                                                />
                                              </FormGroup>
                                              
                                              
                                            ) : null}
                                            {exit_var ? (
                                              <Row>
                                                <div className="alert alert-danger" role="alert">
                                                  {i18next.t('message14')}
                                                </div>
                                              </Row>
                                            ) : (
                                              <div></div>
                                            )}
                                            </Col>
                                              

                                         </Row>
                                        
                                </TabPane>
                                <TabPane tabId="2">
                                    <Row>
                                        <Col sm="6">
                                            <IASelector botversion_id={botVersionId} operator_id={selectedBoxOperator.id} ia={selectedBoxOperator.ia_id} />
                                            <Avatar company_id={bot.company_id} avatarid={avatarid} bindValue={props.getAvatarId} />
                                        </Col>
                                        <Col sm="6">
                                        <Keyword keyword={selectedBoxOperator.keyword} isValid={props.isKeywordValid} />
                                        </Col>
                                    </Row>

                                </TabPane>
                            </TabContent>                     
                        </ModalBody>
                        <ModalFooter>
                            <Col sm="12" align="right">
                            {exit_var?(
                                
                                <Button color="default" size="lg" type="button" onClick={props.toggleModalViewBoxScanQR} >{i18next.t('cancel')}</Button>
                                ):
                                    <Button color="success" size="lg" type="button" onClick={props.toggleModuleModal} >{i18next.t('finish')}</Button>
                                }
                            </Col>
                        </ModalFooter>
                        </Form>
                    </Modal>           
                </React.Fragment>
                    )
        }
        else
        {
            return(<div></div>)
        }

    }
    else
    {
        return(<div></div>)
    }
       
              
 function toggleTab(tab) {
    
    // e.preventDefault();
    // console.log('The link was clicked.',e);
     props.changeActiveTab(tab);
 
}
function onchangeTypeScanner(e)
 {
   
   var type=e.target.value;

   props.setChangeConfig('item_type',type);

  
 }

 }
 

 
 const mapStateToProps = ({boxReducer,botReducer,homeReducer}) => {
     return {boxReducer, botReducer, homeReducer};
     };
     
     const mapDispatchToProps = {
         iconLoad,
         iconHideLoad,
         toggleModalViewBoxScanQR,
         changeActiveTab,
         setChangeConfig,
         updateVariableBoxOperator,
   
     };
     
     export default connect(mapStateToProps, mapDispatchToProps )(SQRMLayout);
