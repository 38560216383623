import React, { Component } from "react";
import { Col }  from 'reactstrap';
import {Bar} from "react-chartjs-2";

export class Barra extends Component {
    state = {
        mayor:0
    }
    getMaxOfArray(numArray) {
        return Math.max.apply(null, numArray);
    }
    render() {
        let info_bar = this.props.data.datasets;
        let step=0;
        if(info_bar && info_bar.length > 0){
            let data_values = this.props.data.datasets[0].data;
            let mayor_value = this.getMaxOfArray(data_values);
            if(mayor_value != this.state.mayor){
                this.setState({
                    mayor:mayor_value
                })  
            }
            if(this.state.mayor <= 1){
                step = 1
            }else if(this.state.mayor > 1 && this.state.mayor <= 50){
                step = 10
            }else if(this.state.mayor > 50 && this.state.mayor <= 100){
                step = 25
            }else if(this.state.mayor > 100 && this.state.mayor <= 300){
                step = 50
            }else if(this.state.mayor > 300 && this.state.mayor <= 2000){
                step = 100
            }else if(this.state.mayor > 2000){
                step = 300
            } 
        }
        
      const options = {
        maintainAspectRatio:false,
        legend: {
            display: false,
            position:'bottom',
            labels: {
                fontColor: '#5a5a5a',
                fontSize:14
            }
        },
        scales: {
            xAxes: [{
                stacked: true
            }],
            yAxes: [{
                stacked: true,
                ticks: {
                    beginAtZero:true,
                    stepSize: step
                }
            }]
        }
      };
      return (
        <Col>
          <Bar
                data={this.props.data}
                width={100}
                height={500}
                options={options}
            />
        </Col>
      );
    }
  }
  
  export default Barra;