import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
 
import { Redirect } from 'react-router-dom'

import ScaleColor from '../infoViz/chart-react-d3/convertScaleColor'


import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  Row,
  FormText,
  UncontrolledTooltip
} from "reactstrap";
//import "./toolbar.css";
import "../../../../static/css/modal-nuevo.css";

import {Link} from 'react-router-dom'
import metricas from '../../../../static/img/menu-icons/metricas.svg'

import { saveNewChart,updateInfoChart,toggleModalNewChart, selectedChart, getPreview, cleanPreview,countTimeDimensions,countMeasures,countDimensions,getListSelectedTypeCharts, getMetricbyId } from "../../../redux/actions/analyticsActions";

import i18next from "../../../../i18n";

function renderRedirect(){
  
    return <Redirect to='/adminmetricas' />
  
}



function ModalNuevoChart(props) {

  const {  updateChart, viewChart,numDimensions,numMeasures,timeDimensions, previews} = props.analyticsReducer;
  const { analyticsReducer: { modalNewChart, listAllMetrics= [],listSelectedTypeCharts=[],typeCharts= [],AllPalettes=[],listSelectedChart= [],selectedChart=[]}, toggleModalNewChart, saveNewChart,updateInfoChart, getPreview, cleanPreview,countTimeDimensions ,countMeasures,countDimensions,getMetricbyId, getListSelectedTypeCharts} = props;

  
  return (

    <div>
     <Modal
        isOpen={modalNewChart}
        toggle={toggleModalNewChart}
        className="modal-lg"
        fade
      >
        <ModalHeader toggle={toggleModalNewChart} charCode="X">
          {updateChart?(i18next.t('update_info')):viewChart?(i18next.t('info_graphic')):i18next.t('create_graphic')}
        </ModalHeader>
        
          {listAllMetrics.length>0||viewChart?(
            <React.Fragment>
               <Form id="form-createChart" onSubmit={
          (e)=>{
            updateChart?[
              updateInfoChart(selectedChart.id,e,(urlDirect)=>{
                //props.history.push(urlDirect);
                
              })
            ]:
          saveNewChart(e,listSelectedChart,(urlDirect)=>{
            props.history.push(urlDirect);
          });
        }} role="form">

          <ModalBody>
            <Row>
                <Col sm="6">
                <FormGroup>
                  <Label className="control-label required-field" for="name">
                  {i18next.t('name')+': '}
                  </Label>
                  <Input 
                  type="text"
                  className="platforms-container" 
                  name="name"
                  disabled={(viewChart)?true:false}
                  defaultValue={(selectedChart)?selectedChart.id:''}
                  />
                  
                </FormGroup>
                <FormGroup>
                  <Label className="control-label required-field" for="metrics">
                  {i18next.t('metric')+': '}
                  </Label>
                  {listAllMetrics &&(listAllMetrics.length > 0 && (
                  <Input
                    type="select" 
                    className="platforms-container" 
                    name="metrics"
                   // disabled={true}
                     disabled={(viewChart||updateChart)?true:false}
                    defaultValue={(selectedChart.metric)?selectedChart.metric.id:''}
                    onChange={(e) => onchangeMetric(e,listAllMetrics,numDimensions,numMeasures)}>

                    {listAllMetrics.map((metric) => {
                             //metric=JSON.parse(metric);
                             
                             metric=<option key={metric.id} value={metric.id}>{metric.name}</option>
                           return metric})}</Input>
                    ))}
                    <FormText id="componets">
                    {i18next.t('component')+': '} {numDimensions+" "+i18next.t('dimensions')+" y "+numMeasures+" "+i18next.t('measurement')+"s. "+(timeDimensions?i18next.t('with')+" ":i18next.t('without')+" ")+" "+i18next.t('time_dimention')}
                        </FormText>
               </FormGroup>
                  <Row>
                    <Label className="control-label required-field" for="charts" >
                        {i18next.t('select_graphics_type')+': '}
                    </Label>
                  </Row>  
                  <div className="scroll" style={{height:50+"vh"}}>
                  <Row style={{'marginTop':10+"px"}}>
                 
                   {listSelectedTypeCharts &&(listSelectedTypeCharts.length > 0 && (
                    listSelectedTypeCharts.map((listSelectedTypeChart,i)=>{
                      listSelectedTypeChart=JSON.parse(listSelectedTypeChart)
                      
                    return( <Col key={"typchart"+i} sm="5" style={{'marginBottom':10+"px",'marginLeft':10+"px"}}>
                      <FormGroup check>
                        <Input type="radio" id={listSelectedTypeChart.id} name="typechart" value={listSelectedTypeChart.id}
                        defaultChecked={(selectedChart.type_chart)&&(selectedChart.type_chart.id==listSelectedTypeChart.id)?true:false} 
                        onChange={(e) => onTry(e)}
                        disabled={(viewChart)?true:false}></Input>
                          <div className="divChart"> 
                            <img src={listSelectedTypeChart.image} width="80%" height="100%" alt={listSelectedTypeChart.name} style={{"marginLeft":10+"px"}} />
                          </div>
                        </FormGroup> 
                    </Col>)
                    
                    })
                  ))} 
                  
                  </Row>
                  </div>
                  
            </Col>
            <Col sm="6">              
              <Row>
                  <Label className="control-label required-field" for="charts" >
                      {i18next.t('previews')} <FormText id="componets"> ({i18next.t('info_data_last_month')})</FormText>
                  </Label>
                  
              </Row>  
              <div className="react-grid-layout layout" style={{height: 35+"vh"}}>
              <div id="content-preview" className="scroll" style={{width:90+"%",height:90+"%",position:"absolute"}}>
                <div id="preview" style={{width:"inherit", height:"inherit",overflow: "scroll"}}>
                  {previews.nameDiv=="preview"?(previews.chart):""}
                </div>
              </div>
              </div>
              <Row>
                    <Label className="control-label required-field" for="charts" >
                        {i18next.t('select_color')+': '}
                    </Label>
             </Row>  
                  <div className="scroll" style={{height:25+"vh"}}>
                    {AllPalettes &&(AllPalettes.length > 0 && (
                      AllPalettes.map((typepalette,i)=>{
                      typepalette=JSON.parse(typepalette);
                      return(
                      <Row key={"typepalette_"+typepalette.type}> 
                        <div style={{"marginLeft":20+"px"}}>
                          <br></br>
                            <Label className="control-label required-field" for="charts" >
                            {i18next.t('scala')} {typepalette.type}
                            </Label>
                            <br></br>
                        </div>
                        <Col sm="12">
                          {typepalette.values.map((palette,i)=>
                              <Row  key={"palette"+palette.id} style={{"marginTop":30+"px", "height":20+"px"}}>
                                <Col sm="1"  style={{"paddingLeft":20+"px", "backgroungColor":"white"}}>
                                  <FormGroup check>
                                  <Input type="radio" id={typepalette.type+"_"+palette.id} name="palettes" value={palette.id}
                                      defaultChecked={(selectedChart.color&&selectedChart.color.id==palette.id)?true:false}
                                      onChange={(e) => onTry(e)}
                                      disabled={(viewChart)?true:false}
                                    ></Input>
                                </FormGroup>
                                </Col>
                                <Col sm="11" style={{"backgroungColor":"white"}}>
                                  <div id={"content-imgPalettes"+palette.id} style={{"marginLeft":0+"px"}}>
                                    <ScaleColor  nameDiv={"imgPalettes"+palette.id} data={""} color={palette.name} short_name={palette.short_name} typeScaleColor={typepalette.type}/>
                                  </div>
                                </Col>
                                </Row>
                      )}
                        </Col>
                      </Row>  
                      )})
))}     </div>
            </Col>        
              </Row>      
          
              </ModalBody>
          <ModalFooter>
          <Button onClick={cleanPreview} outline color="secondary">
              {viewChart?(i18next.t('close')):i18next.t('cancel')}
            </Button>
            {viewChart?(""):
            <Button type="submit" size="lg" className="btn-guarumo-modal">
              {updateChart?("Actualizar"):"Crear"}
            </Button>
            }
          </ModalFooter>
        </Form>
            </React.Fragment>
          ):
          <React.Fragment>
             <Form id="form-createChart" onSubmit={props.saveChart} role="form">
              <ModalBody>
              <Col xs={12} lg={8} className="offset-lg-2 intro-funnels" style={{"textAlign":"center"}}>
                  <Row>
                      <Col xs={12}><img src="/static/img/blank_state.png" className="img-fluid"/></Col>
                      <Col xs={12}>
                          <h2>{i18next.t('without_metrics')}</h2>
                          <h3>{i18next.t('metrics_message')}</h3>
                      </Col>
                  </Row>
                  </Col>
              </ModalBody>
              <ModalFooter>
              <Button onClick={toggleModalNewChart} outline color="secondary">
              {i18next.t('cancel')}
            </Button>
              
                <Link to="/adminmetricas" className="btn-guarumo-modal">
                    {i18next.t('create_metric')}
                </Link>
              </ModalFooter>
            </Form>
          </React.Fragment>
        }    
      </Modal>
    </div>
   
  
  );

async function onTry(e)
{
  var typecharts=document.getElementsByName("typechart");
  var colors=document.getElementsByName("palettes");
  var metric = document.getElementsByName("metrics");

  var typepalette="";

  var typechart, color;
  for (var i = 0; i < typecharts.length; i++) {
    if (typecharts[i].checked) {
      typechart=typecharts[i].value;
     
    }
}

for (var i = 0; i < colors.length; i++) {
  if (colors[i].checked) 
  {
    color=colors[i].value;
    typepalette=colors[i].id;
    typepalette=typepalette.substring(0,typepalette.indexOf("_"));

  }
}
const { selectedMetric } = props.analyticsReducer;

//console.log("Metrica seleccionada: ",selectedMetric)

if(color){
  props.getPreview(typechart,color, typepalette,"preview",selectedMetric);
}

}
function drawInput(nameDiv,typepalette,palette,selectedChart,viewChart)
{
  let div = document.getElementById(nameDiv);
 
  if(div){
    div.innerHtml = '<Input type="radio" id={typepalette.type+"_"+palette.id} name="palettes" value={palette.id}'+
    'defaultChecked={(selectedChart.color&&selectedChart.color.id==palette.id)?true:false}'+
    'onChange={(e) => onTry(e)}'+
    'disabled={(viewChart)?true:false}'+
  '></Input>'
    return div;
  }
 
  return "";

  
}
  async function onchangeMetric(e)
{
  
  var id=Number(e.target.value);

  await props.countDimensions(id);
 
  
  await props.countMeasures(id);


  await props.countTimeDimensions(id);


  await props.getListSelectedTypeCharts();


  await props.getMetricbyId(id);

  

  const { selectedMetric:selectedMetric1 } = props.analyticsReducer;

  //console.log(id, "Nueva Metrica seleccionada: ",selectedMetric1)

  await onTry(e);
    
}
}


const mapStateToProps = ({ analyticsReducer,  }) => {
	return { analyticsReducer,  };
};

const mapDispatchToProps = {
  saveNewChart,
  updateInfoChart,
  toggleModalNewChart,
  countMeasures,
  countDimensions,
  countTimeDimensions,
  getListSelectedTypeCharts, 
  getPreview,
  cleanPreview,
  getMetricbyId
};


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ModalNuevoChart));
