import React, { Component } from 'react';
import { connect } from "react-redux";

import { setAvatarid } from "../redux/actions/boxActions";

import axios from 'axios';
import {  
    FormGroup, 
    Input, 
    Col,
    Row,
    Label,
    UncontrolledTooltip
} from 'reactstrap';

import { listAvatar } from '../redux/actions/companyActions';
import { isEmpty } from "../utils/commonFunction";
import i18next from '../../i18n';

class Avatar extends Component {
    _isMounted = false;
    state = {
        avatarid:this.props.avatarid,
    }

    constructor(props){
        super(props);
    }

    componentDidMount = () => {
        this._isMounted = true;
        this.setState({avatarid: this.props.avatarid});

        const {avatarList} = this.props.companyReducer;

        if(isEmpty(avatarList)){
            this.props.listAvatar(this.props.company_id);
        }

    }

    componentWillUnmount = () => this._isMounted = false;


    updateAvatar = () =>{
        this.setState({avatarid: event.target.value});
        this.props.setAvatarid(event.target.value);
        this.bindValue(event.target.value);
    }
  
    bindValue = avatarid =>{
        if(typeof this.props.bindValue === "function")
            this.props.bindValue(avatarid);
    }
  
    render() {

        const {avatarList} = this.props.companyReducer;
        const list = avatarList;
        if(list){
            const options = list.map((item, index) => 
                <option key={index + 1} value={item.id}>{item.titulo.length > 0? item.titulo : item.purpose}</option>
            );
        

            return (
                <Row>
                    <Col sm="12">
                        <FormGroup>
                            <Label>{i18next.t('avatars')} <i className="fa fa-info-circle" id="tooltipavatar"></i></Label>
                            <UncontrolledTooltip placement="right" target="tooltipavatar">{i18next.t('message41')}</UncontrolledTooltip>
                            <Input type="select" name="select" onChange={(event) => { this.updateAvatar(event) }} value={(this.state.avatarid)?this.state.avatarid:''}>
                                <option value='0' key={0}>{i18next.t('select_one')}</option>
                                {
                                    options
                                }
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
            )
        }
    }
}


const mapStateToProps = ({ boxReducer,homeReducer,companyReducer, componentMountReducer }) => {
  return { boxReducer,homeReducer,companyReducer, componentMountReducer };
 };
 
 const mapDispatchToProps = {
    setAvatarid,
    listAvatar
 };
 
 export default connect(mapStateToProps, mapDispatchToProps)(Avatar);