import React, { Component } from 'react';
import i18next from '../../../../i18n';
import HMLayout from './help-modal-layout';
import axios from 'axios';
import qs from 'qs';


class HelpModal extends Component {
  state = {
      items : [
        {
          src: '/static/img/tutorial/1.png',
          class:'HM-edit-bot',
          title: i18next.t('bot_edition'),
          description: i18next.t('info_edition')
        },
        {
          src: '/static/img/tutorial/2.png',
          class:'HM-upload-file',
          title: i18next.t('upload_file'),
          description: i18next.t('info_upload_file')
        },
        {
          src: '/static/img/tutorial/3.png',
          class:'HM-webview',
          title: 'WebView',
          description: i18next.t('info_webview')
        }
        ,
        {
          src: '/static/img/tutorial/4.png',
          class:'HM-btn-list',
          title: i18next.t('buttons_list'),
          description: i18next.t('info_buttons_list')
        },
        {
          src: '/static/img/tutorial/5.png',
          class:'HM-carousel',
          title: i18next.t('carousel'),
          description: i18next.t('carousel_info')
        },
        {
          src: '/static/img/tutorial/6.png',
          class:'HM-form',
          title: i18next.t('form'),
          description: i18next.t('form_info')
        },
        {
          src: '/static/img/tutorial/7.png',
          class:'HM-quickreplies',
          title: 'QuickReplies',
          description: i18next.t('quick_replies_info')
        },
        {
          src: '/static/img/tutorial/8.png',
          class:'HM-calendar',
          title: i18next.t('calendar'),
          description: i18next.t('calendar_info')
        },
        {
          src: '/static/img/tutorial/9.png',
          class:'HM-location',
          title: i18next.t('location'),
          description: i18next.t('location_info')
        },
        {
          src: '/static/img/tutorial/10.png',
          class:'HM-multimedia',
          title: 'Multimedia',
          description: i18next.t('multimedia_info')
        },
        {
          src: '/static/img/tutorial/11.png',
          class:'HM-report',
          title: i18next.t('report'),
          description: i18next.t('report_info')
        },
        {
          src: '/static/img/tutorial/12.png',
          class:'HM-blockchain',
          title: 'Blockchain',
          description: i18next.t('blockchain_info')
        },
        {
          src: '/static/img/tutorial/13.png',
          class:'HM-scanqr',
          title: i18next.t('scanner') + ' QR:',
          description: i18next.t('scanner_info')
        },
        {
          src: '/static/img/tutorial/14.png',
          class:'HM-catalog',
          title: i18next.t('catalog'),
          description: i18next.t('catalog_info')
        },
        {
          src: '/static/img/tutorial/15.png',
          class:'HM-payment',
          title: i18next.t('payments_online'),
          description: i18next.t('payments_online_info')
        }
      ],  
      activeIndex: 0 
  }

  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }

  componentWillMount(){
    var auth=JSON.parse(localStorage.getItem('Authorization'));
    if(!auth || !auth.access_token)
      this.props.history.push("/login");
  }

  componentDidMount () {
    var first_time=localStorage.getItem('HelpModal');
    if(!first_time){
      this.props.toggleHelpModal();
    }
  }


  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === this.state.items.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? this.state.items.length - 1 : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

    render() {
        return (
          <HMLayout 
          toggleHelpModal={this.props.toggleHelpModal}
          isOpen={this.props.isOpen} 
          onExiting={this.onExiting}
          onExited={this.onExited}
          next={this.next}
          previous={this.previous}
          goToIndex={this.goToIndex}
          index={this.state.activeIndex}
          items={this.state.items}

          />
        )
    }
}

export default HelpModal