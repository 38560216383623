import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import GetApp from "@material-ui/icons/GetApp";
import { withStyles } from "@material-ui/core/styles";

import i18next from '../../../../../i18n';


import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const defaultToolbarStyles = {
  iconButton: {
  },
};

class CustomToolbar extends React.Component {
  
  handleClick = () => {
    console.log("clicked on icon!");
    
  }

  render() {
    const { classes } = this.props;

    return (
    <React.Fragment>
        {this.props.props.downloadFile?(
        <React.Fragment>
                <Tooltip title={i18next.t('name') +"XLSX"}>
                          {this.props.props.metric?
                            (<IconButton className={classes.iconButton} onClick={this.handleClick}>
                            <GetApp className={classes.deleteIcon} />
                            </IconButton>):
                            <IconButton className={classes.iconButton}  onClick={(e)=>{this.props.props.downloadFile()}}>
                            <GetApp className={classes.deleteIcon} />
                            </IconButton>}
                        </Tooltip>
             </React.Fragment>
    ):
    <React.Fragment>
        <ExcelFile element={ 
             <React.Fragment>
                <Tooltip title={i18next.t('name') +"XLSX"}>
                          {this.props.props.metric?
                            (<IconButton className={classes.iconButton} onClick={this.handleClick}>
                            <GetApp className={classes.deleteIcon} />
                            </IconButton>):
                            <IconButton className={classes.iconButton}  onClick={(e)=>{this.handleClick()}}>
                            <GetApp className={classes.deleteIcon} />
                            </IconButton>}
                        </Tooltip>
             </React.Fragment>
            
               
            }>
            <ExcelSheet data={this.props.resultSet.resultSet.rawData()} name="BOTAI">
            {this.props.dataTable.columns.map((k, index) => {    
                return <ExcelColumn key={index} label={k.label} value={k.field}/>
            })}
            </ExcelSheet>
        </ExcelFile>
      </React.Fragment>
  }
    </React.Fragment>
    );
  }

}

export default withStyles(defaultToolbarStyles, { name: "CustomToolbar" })(CustomToolbar);