
 import React from 'react';
 import DonutData from './d3-examples/donutChart';
 import DonutChart from './d3Components/DonutChart';

 
 class convertDonutChart extends React.Component {
   constructor(props) {
     super(props);
     this.state = {d3: ''}
   }
 
   componentDidMount() {
     this.setState({d3: DonutData});
   }
 
   render() {
     
     return(
       <div>
         <DonutChart nameDiv={this.props.nameDiv} data={this.props.data} callBackPieChart={this.props.callBackPieChart} color={this.props.color} typeScaleColor={this.props.typeScaleColor} color={this.props.color} title={this.props.title}  xAxisName={this.props.xAxisName}  yAxisName={this.props.yAxisName} description={this.props.description}/>
       </div>
         
     )
   }
 }
 export default convertDonutChart;