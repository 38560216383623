import React from 'react';
import Admin from '../../admin/containers/admin';
import SliceMenuApp from '../../admin/components/slice-menu';
import { 
  Col,
  Row,
  Button, 
  Container,
  FormGroup,
  Label
} from 'reactstrap';
import ToolbarApp from '../../admin/components/toolbar';
import BotMenuOptions from'../../menus/bot-menu-options';
import '../statistic/bot-statistics-layout.css';
import Bar from "../../../components/bar";
import Date from "../../../components/datepicker";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight} from '@fortawesome/free-solid-svg-icons'
import i18next from '../../../../i18n';


function BotFunnelLayout(props) {
    let event = props.breadcrumb || [];
    const eventList = event.map((k, index) => {
        let separador ='';
        if(index + 1 < event.length)
            separador =<span className="separator" key={index+2}><FontAwesomeIcon icon={faArrowRight} /></span>;
        return <React.Fragment key={index+1}>
            <span key={index+1}>{index+1}. {k}</span> 
            {separador}
        </React.Fragment>
    });
  return (
    <div className="BotLayout" key={props.botId}>
          <Admin>
            <SliceMenuApp>
                <BotMenuOptions botId={props.botId} ia_activated={props.ia_activated}/>
            </SliceMenuApp>
            <Container fluid>
                <ToolbarApp>
                    <Row>
                        <Col sm="3" md="3" className="text pdtoolbar">
                        <h2 className="text-left ml-4 font-weight-bold">{i18next.t('funnel')+ ':' + props.funnelname}<span className="btn-help"></span></h2>                      
                        </Col>
                    </Row>
                </ToolbarApp>
            </Container>
            <Container fluid className="box-fluid">
                <Col sm="12" md="10" className="stadistics-container  offset-md-1">
                    <Row>
                        <Col lg="10" className="offset-1">
                                <Row className="funnel-events"> 
                                    <Col md={12}>
                                        <h3>{props.funnelname}</h3>
                                    </Col> 
                                    <Col md={12} className="pt-4">
                                        <h4>{eventList}</h4>
                                    </Col>
                                </Row>
                        </Col>
                        <Col xs="12" lg="8" className="offset-lg-2">
                            <Row className="filter-information">
                                <Col xs={12}>
                                    <Row>
                                        <Col xs={12} md={12} lg={3} className="Label-filter d-flex align-items-center justify-content-center">
                                            <Label>{i18next.t('interval_dates')}</Label>
                                        </Col>
                                        <Col xs={12} md={12} lg={7} className="date-picker-filter d-flex align-items-center justify-content-center">
                                            <FormGroup>
                                                <Date startDate={props.startDate} endDate={props.endDate} handleChangeStart={props.handleChangeStart} handleChangeEnd={props.handleChangeEnd}/> 
                                            </FormGroup>
                                        </Col>
                                        <Col xs={12} md={12} lg={2}>
                                            <Button className="btn filter-btn btn-block" onClick={props.getData}>{i18next.t('filter')}</Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} md={12} lg={8} className="graphic offset-lg-2">
                            <Bar data={props.data3}  /> 
                        </Col>
                    </Row>
                </Col>
            </Container>
          </Admin>      
    </div>
  )
}

export default BotFunnelLayout