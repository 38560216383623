// Build data for a classic bar chart
const data = {}

// Labels are displayed in component, quantities are calculated to define height of each bar
data.datasetBarChart = [
  { group: "All", botID: "All", category: "Bot", measure: 63850.4963 }, 
  { group: "All", botID: "All", category: "Usuario", measure: 78258.0845 }, 
  { group: "All", botID: "All", category: "Paso Agente", measure: 60610.2355 }, 
  { group: "All", botID: "All", category: "No entendidos", measure: 30493.1686 }, 
  { group: "Web", botID: "All", category: "Bot", measure: 19441.5648 }, 
  { group: "Web", botID: "All", category: "Usuario", measure: 25922.0864 }, 
  { group: "Web", botID: "All", category: "Paso Agente", measure: 9720.7824 }, 
  { group: "Web", botID: "All", category: "No entendidos", measure: 6480.5216 }, 
  { group: "Facebook", botID: "All", category: "Bot", measure: 22913.2728 }, 
  { group: "Facebook", botID: "All", category: "Usuario", measure: 7637.7576 }, 
  { group: "Facebook", botID: "All", category: "Paso Agente", measure: 23549.7526 }, 
  { group: "Facebook", botID: "All", category: "No entendidos", measure: 1909.4394 }, 
  { group: "Facebook", botID: "Bot 3", category: "Bot", measure: 2213.2728 }, 
  { group: "Facebook", botID: "Bot 3", category: "Usuario", measure: 767.7576 }, 
  { group: "Facebook", botID: "Bot 3", category: "Paso Agente", measure: 2359.7526 }, 
  { group: "Facebook", botID: "Bot 3", category: "No entendidos", measure: 909.4394 }, 
  { group: "Facebook", botID: "Bot 4", category: "Bot", measure: 2913.2728 }, 
  { group: "Facebook", botID: "Bot 4", category: "Usuario", measure: 767.7576 }, 
  { group: "Facebook", botID: "Bot 4", category: "Paso Agente", measure: 2549.7526 }, 
  { group: "Facebook", botID: "Bot 4", category: "No entendidos", measure: 199.4394 }, 
  { group: "Whatsapp", botID: "All", category: "Bot", measure: 1041.5124 }, 
  { group: "Whatsapp", botID: "All", category: "Usuario", measure: 2430.1956 }, 
  { group: "Whatsapp", botID: "All", category: "Paso Agente", measure: 15275.5152 }, 
  { group: "Whatsapp", botID: "All", category: "No entendidos", measure: 4166.0496 }
  ]
  ;

  /*

//Set margins for bar graph within svg element
data.margins = {top: 20, right: 20, bottom: 70, left: 40};

//Define label of y-axis
data.yAxisLabel = 'My Money!';

// Colors are optional for each bar
// If colors are not given, bars will default to 'steelblue'
data.fill = [];

//Define the width of the svg element on the page
data.width = 960;

//Define the height of the bar chart
data.height = 700;

// Define tick intervals for y-axis
data.ticks = 10;

//Define a class for the svg element for styling
data.barClass = 'bar';

/* EXAMPLE CSS

.bar text {
  font: 14px sans-serif;
  text-anchor: middle;
}

*/

export default data;