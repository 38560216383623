
import React, {Component} from 'react';

import { Button,Modal,ModalHeader,ModalBody,ModalFooter,Form,FormGroup,Input,Col,Row,Label,Nav,NavItem,TabContent,TabPane,NavLink, UncontrolledTooltip} from 'reactstrap';
import IASelector from '../../../../components/ia'
import Mentions from '../../../../components/mentions/metions-alone';
import Keyword from '../../../../components/keyword/keyword';
import Timeout from '../../../../components/timeout/timeout';
import Avatar from '../../../../components/avatar';
import TitleOperator from "../../../../components/title-operator";
import CheckBoxEditable from "../../../../components/checkBoxEditable"
import MsgError from "../../../../components/type-file";
import ExtensionsFilesType from "../../../../components/extensions-files";
import Emoji from '../../../../components/emoji-picker';
import classnames from 'classnames';
import TabAdvanced from "../../../../components/box/tab_advanced";
import i18next from '../../../../../i18n';

class UFMLayout extends Component {
    constructor(props){
        super(props)
        this.state = {
        activeTab: '1'
        };
        this.toggleTab = this.toggleTab.bind(this);
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
          this.setState({
            activeTab: tab
          });
        }
    }
    render(){
        return (
            <React.Fragment>
                <Modal className="upload_file" isOpen={this.props.isOpen} toggle={this.props.closeModal} fade size="lg">
                   <ModalHeader toggle={this.props.closeModal}>{i18next.t('configure_upload_file')}</ModalHeader>
                    <Form id="UFM-form" role="form">  
                    <ModalBody>  
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                className={classnames({ active: this.state.activeTab === '1' })}
                                onClick={() => { this.toggleTab('1'); }} >
                                {i18next.t('basic')}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                className={classnames({ active: this.state.activeTab === '2' })}
                                onClick={() => { this.toggleTab('2'); }}>
                                {i18next.t('advanced')}
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={this.state.activeTab}>
                            <TabPane tabId="1">
                                <Col sm="12">
                                    <FormGroup>
                                        <TitleOperator titleOperator={this.props.titleOperator} bindValue={this.props.getTitleOperator}/>
                                        <MsgError bindValue={this.props.getmsgError} msgError={this.props.msgError}  />
                                        <ExtensionsFilesType bindValue={this.props.getExtensions} extensions={this.props.extensions} handleDeleteExtension={this.props.handleDeleteExtension}  /> 
                                    </FormGroup> 
                                    <FormGroup>   
                                        <Label>{i18next.t('statement')} <i className="fa fa-info-circle" id="tooltipEnunciado"></i></Label>
                                        <UncontrolledTooltip placement="right" target="tooltipEnunciado"> {i18next.t('message8')}</UncontrolledTooltip>
                                        <Input type="hidden" defaultValue={this.props.botmsg_id || ''} name="botmsg_id" hidden/>
                                        <Mentions varList={this.props.varList}  defaultValue={this.props.botmsg_text || ''}  updateItem={this.props.updateItem} maxlength={'2000'} msg_error={i18next.t('limit_allowed') + ' 2000'}/>
                                        { this.props.error_head_empty && 
                                            <span className="warning-character">{i18next.t('add_message')}</span>
                                        }
                                        <div className="options-bar-input" style={{'right':'1px'}}>
                                            <Emoji id={'head'} callEmoji={this.props.callEmoji} type="head"/>
                                        </div>
                                    </FormGroup>
                                    
                                    <FormGroup>
                                        
                                        <Label> {i18next.t('text_button_cancel')} <i className="fa fa-info-circle" id="tooltipCancelar"></i></Label>
                                        <UncontrolledTooltip placement="right" target="tooltipCancelar"> {i18next.t('text_button_cancel_msg')}</UncontrolledTooltip>
                                        
                                        <Input id="cancelmsgText" type="text" className="inputModal" name="cancelmsg" maxLength="20" defaultValue={this.props.cancelmsg || ''} onChange={this.props.validateCancelMsg} />                              
                                        { this.props.error_input_cancel && 
                                            <span className="warning-character">{i18next.t('limit_allowed')} 20</span>
                                        }
                                        { this.props.error_input_empty && 
                                            <span className="warning-character">{i18next.t('add_message')}</span>
                                        }
                                        <div className="options-bar-input" style={{'right':'1px','bottom':'-22px'}}>
                                            <Emoji id={'cancelmsg'} callEmoji={this.props.callEmoji} type="item"/>
                                        </div>
                                    </FormGroup>
                                    <FormGroup>
                                        <CheckBoxEditable isVariable={this.props.isVariable} name_variable={this.props.name_variable} bindValue={this.props.getVariable}/>
                                        { this.props.error_variable_empty && 
                                            <span className="warning-character">{i18next.t('add_name_var')}</span>
                                        }
                                    </FormGroup>

                                  
                                     
                                    
                                </Col>
                            </TabPane>
                            <TabPane tabId="2">
                                <Row>
                                    <Col sm="6">
                                        <IASelector botversion_id={this.props.botversion_id} operator_id={this.props.operator_id} ia={this.props.ia} getIAId={this.props.getIAId} />
                                        <Avatar company_id={this.props.company_id} avatarid={this.props.avatarid} bindValue={this.props.getAvatarId} />
                                    </Col>
                                    <Col sm="6">
                                        <Timeout bindValue={this.props.getTimeout} bindMsg={this.props.getMsgTimeout} bindValid={this.props.isTimeoutValid} defaultValue={this.props.timeoutValue} defaultMsg={this.props.timeoutMsg} />
                                        <Keyword keyword={this.props.keyword} isValid={this.props.isKeywordValid} updateKeyword={this.props.updateKeyword}/>
                                    </Col>
                                </Row>
                            </TabPane>
                        </TabContent> 
                    </ModalBody>
                    <ModalFooter>
                            <Col sm="12" align="right">
                                <Button color="success" size="lg" type="button" onClick={this.props.toggleModuleModal} >{i18next.t('finish')}</Button>
                            </Col>
                    </ModalFooter>
                    </Form>
                </Modal>           
            </React.Fragment>
        );
    }
}
  
export default UFMLayout
 
