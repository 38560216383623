import React, { Component } from 'react';
import CMSLayout from './cms-layout';
import ModalLoad from '../../../components/modals/modal-load';

import {getHandlerGET, getHandlerPOST,fileHandlerPOST} from '../../../utils/handlersApi'
import {cerrarSesion,isAuthorizationValid} from '../../../utils/commonFunction'
import i18next from '../../../../i18n';


class CMS extends Component {

  constructor(props){
    super(props);
    this.state = {
      botId: this.props.match.params.id,
      ia_activated : false,
      hideLoad: 0,
      listOperators:[],
      botInfo:null,
      modalOpenC: false,
      modalOpenFile:false,
      operator:[],
      operatorDetail: [],
      items: [],
      modal_image:[],
      limit_input:false,
      modalOpenMore:false,
      buttonModalImg:true,
      error_modal_size:false,
      ok_ventana_size:false,
      msj_error_modal_size:'',
      tmpImage:'',
      botversion_id:'',
      search:this.props.location.search || ''
    };
    this.onDrop1 = this.onDrop1.bind(this);
  }
  
  componentDidMount(){
   this.getBotInfo();
  }

  componentWillMount(){
    isAuthorizationValid();
  }
 
   /************ FUNCTIONS *************/

  getHeaders = () => {
    //obtenemos el token de autenticación
    const auth=JSON.parse(localStorage.getItem('Authorization'));
    const headers={
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Bearer '+ auth.access_token 
    }
    return headers;
  }

  /*
  * Funtion getSearch
  * @string = get url
  * open the specified operator
  */
  getSearch = (string) => {
    const params = new URLSearchParams(string); 
    const tags = params.get('operador');
    if(tags != null)
      this.getOperator(tags);
  }
  
  /* Functions Close modals and reset state variable */
  closeModalC = () =>  this.setState({ modalOpenC: !this.state.modalOpenC, limit_input: false}); 
  closeModalOpenMore = () =>  this.setState({ modalOpenMore: false}); 
  closeModalFile = () => this.setState({modalOpenFile: false,error_modal_size:false,ok_ventana_size:false,msj_error_modal_size:'',buttonModalImg:true});
  

  /* Functions open modals and reset state variable */
  toggleNewOperator = () => this.setState({modalOpenNew: !this.state.modalOpenNew});
  toggleDropzone = () => this.setState({modalOpenFile: !this.state.modalOpenFile,modal_image:[],ok_ventana_size:false});

  /* Functions saveUrl set temporary state variable */
  saveUrl = (event) => {
    event.preventDefault();
    let form = new FormData(event.target);
    let img = form.get("imageModal");
    this.setState({tmpImage: img, modalOpenFile : !this.state.modalOpenFile},()=>{ alert('URL guardada con éxito.')});
  }

  /* Get Bot Info */
  getBotInfo () {
    let cont = this.state.hideLoad; 
    this.setState({ hideLoad: cont+1});
    getHandlerGET(HOST_API +"/api/bot/?id="+this.state.botId).
        then((res) =>{
            if(res.status==200){
                this.setState({botversion_id: res.data.bot[0].bot_version[0].botversion_id, ia_activated: res.data.bot[0].ia_activated});
                this.getallOperator();
            }else
              console.log(" No se pudo recuperar info del bot");
            this.setState({ hideLoad: cont-1});
        }).catch((error)=> {
            console.log('Catch GET getBotInfo:',error);
            if (error.response.status == 403)
              cerrarSesion();
        });
  }

  /*
  * Funtion getOperator
  * @id = id operator dinamyc
  * get all informations about operator
  */

  getOperator = (id) => {
    getHandlerGET(HOST_API +'/api/operator/dynamic_operator/'+id)
      .then((res)=>{
        if(res.status==200){
          this.setState({operator: res.data.data}, 
            () => this.getOperatorAllItem(this.state.operator.id));
        }
      this.setState({modalOpenC: !this.state.modalOpenC});
    }).catch((error)=>{
      console.log('Catch GET getOperator:',error);
      if (error.response.status == 403)
        cerrarSesion();
    });
  }

  /*
  * Funtion getOperatorAllItem
  * @id = id operator dinamyc
  * get all items from operator
  */

  getOperatorAllItem = (id) => {
    this.setState({items: [] });
    getHandlerGET(HOST_API +'/api/operator/dynamic_item/?opid='+id)
      .then((res)=>{
        if(res.status==200){
          let result = res.data.data.res;
          let temp = [];
          result.forEach( item => { temp.push(item)});
          this.setState({ items: this.state.items.concat(temp)});
        }
    }).catch((error)=>{
      console.log('Catch GET getOperatorAllItem:',error);
      if (error.response.status == 403)
        cerrarSesion();
    });
  }

  /*
  * Funtion getOperatorItem
  * @id = id item from operator dinamyc
  * get all information about a specific items, use only with carousel 
  */
  getOperatorItem = (id) => {
    this.setState({operatorDetail: []});
    getHandlerGET(HOST_API +'/api/operator/dynamic_item/'+id)
      .then((res)=>{
        if(res.status==200){
          this.setState({operatorDetail: res.data.data, modalOpenMore: !this.state.modalOpenMore},
            ()=> this.setState({tmpImage:this.state.operatorDetail[0].url}));
        }
    }).catch((error)=>{
      console.log('Catch GET getOperatorItem:',error);
      if (error.response.status == 403)
        cerrarSesion();
      if(error.response.status == 404)
        alert(i18next.t('message60'))
      this.setState({ hideLoad: cont-1});
    });
  }

  /*
  * Funtion saveOperatorItem
  * save info about item from operator 
  */

  saveOperatorItem = (event) => {
      event.preventDefault();
      let cont = this.state.hideLoad; 
      this.setState({ hideLoad: cont+1});
      let form = new FormData(event.target);
      let idItem = form.get("idItem");
      let id_operador = form.get("idOperator");
          let data ={
            'title':form.get("title"),
            'operator_id':form.get("idOperator"),
            'description':form.get("description"),
            'image':form.get("image")
          }
        getHandlerPOST(HOST_API +'/api/operator/dynamic_item/'+idItem,data)
          .then((res)=>{
                if(res.status==200){
                  alert('Datos guardados con éxito. ');
                  this.getOperatorAllItem(id_operador);
                }else
                  alert(i18next.t('message61'));
            this.setState({ hideLoad: cont-1, modalOpenMore: false});
          }).catch((error) => {
            console.log('Catch POST saveOperatorItem:',error);
              if (error.response.status == 403)
                cerrarSesion();
            }); 
  }

  /* 
  * Function getallOperator 
  * Trae todos los operadores dinámicos de este bot
  * Devuelve un objeto
  */
  getallOperator = () => {
    let cont = this.state.hideLoad;
    this.setState({ hideLoad: cont+1});
    getHandlerGET(HOST_API +'/api/operator/dynamic_operator/?bot='+this.state.botId)
      .then((res)=>{
        if(res.status==200){
          this.setState({listOperators: res.data.data},()=>{this.getSearch(this.state.search);});
        }
        this.setState({ hideLoad: cont-1});
    }).catch((error)=>{
        console.log('Catch GET getallOperator:',error);
        if (error.response.status == 403)
          cerrarSesion();
    });
  }

 /* 
  * Function onDrop1 
  * funtion componente drag and drop
  */
  onDrop1(files) {
    this.infoImg(files[0])
      this.setState({
        error_modal_size:false,
        modal_image: files.map(file => ({file,
            preview: URL.createObjectURL(file)
          }))
      });
  }

  /* 
  * Function infoImg 
  * @file: image file
  * get all information about a specific image 
  */
  infoImg(file){
    var url = URL.createObjectURL(file); 
    var img = new Image; 
    let msj_size = i18next.t('message62'),
        size= file.size;
    img.onload = function(){ 
      if(size > 800000){
        this.setState({buttonModalImg:true,error_modal_size:true,msj_error_modal_size:msj_size,ok_ventana_size:false});
      }else{
        this.setState({
          buttonModalImg:false,
          ok_ventana_size:true,
          error_modal_size:false,
        });
      }
    }.bind(this); 
    img.src = url;
  }
  
   /* 
  * Function subirImg 
  * upload image at server s3
  */
  subirImg = () => {
    this.setState({detailSaved: false,hideLoad:1}); 
    let currentImage = this.state.modal_image;
    if(currentImage.length > 0){
      let form = new FormData();
      form.append('image', this.state.modal_image[0].file);
      fileHandlerPOST(HOST_API +'/api/bot/upload_images/',form)
        .then((res)=>{
          if(res.status!=200)
            console.log('Hubo un error',res)
          else
            alert(i18next.t('message63'));
          this.setState({ hideLoad: 0, tmpImage:res.data.img, modalOpenFile: !this.state.modalOpenFile})
        }).catch((error) => {
          console.log('Catch POST subirImg:',error);
          if (error.response.status == 403)
            cerrarSesion();
        });
            
    }
  }

  callEmoji = (code,k,type)=>{
    if(type === "head"){
    let text = $('#input-head').val();
        text += ` ${code}`;
        $('#input-head').val(text)
        this.updateItemHead(text)   
    }else{

        const cursorPos = $('#input-'+k).prop('selectionStart');
        const v = $('#input-'+k).val();
        const textBefore = v.substring(0,  cursorPos);
        const textAfter  = v.substring(cursorPos, v.length);


        let texto = textBefore + ` ${code}` + textAfter;
        $('#input-'+k).val(texto);


    }
}



  render() {
    return (
      <div>
        <CMSLayout
          listOperators={this.state.listOperators}
          closeModalC={this.closeModalC}
          modalOpenC={this.state.modalOpenC}
          getOperator={this.getOperator}
          getOperatorItem = {this.getOperatorItem}
          toggleNewOperator={this.toggleNewOperator}
          operator ={this.state.operator}
          operatorDetail = {this.state.operatorDetail}
          items ={this.state.items}
          limit_input = {this.state.limit_input}
          saveOperatorItem = {this.saveOperatorItem}
          modalOpenMore = {this.state.modalOpenMore}
          closeModalOpenMore = {this.closeModalOpenMore}
          closeModalFile = {this.closeModalFile}
          modalOpenFile = {this.state.modalOpenFile}
          toggleDropzone = {this.toggleDropzone}
          onDrop1={this.onDrop1} 
          modal_image={this.state.modal_image} 
          subirImg = {this.subirImg}
          buttonModalImg= {this.state.buttonModalImg}
          ok_ventana_size={this.state.ok_ventana_size}
          tmpImage = {this.state.tmpImage}
          error_modal_size = {this.state.error_modal_size}
          msj_error_modal_size = {this.state.msj_error_modal_size}
          botId = {this.state.botId} 
          ia_activated = {this.state.ia_activated}
          saveUrl = {this.saveUrl}
          callEmoji= {this.callEmoji}
          updateItem = {this.updateItem}
          />
        <ModalLoad hideLoad={this.state.hideLoad} />
      </div>
    )
  }
}

export default CMS
