import React, { Component } from 'react';
import { connect } from "react-redux";

import { Label,Col,Row,UncontrolledTooltip} from 'reactstrap';

import IASelector from '../ia'
import Keyword from '../keyword/keyword-redux';
import Avatar from '../avatar';
import Timeout from '../timeout/timeout';


class TabAdvanced extends Component {
    constructor(props){
      super(props);
  }
  render() {
    const {selectedBoxOperator,avatarid} = this.props.boxReducer;
    const { bot,botVersionId } = this.props.botReducer;
    if(selectedBoxOperator){
        return( 
            <Row>
                {this.props.isKeywordValid?
                    <Col sm="6">
                        <IASelector botversion_id={botVersionId} operator_id={selectedBoxOperator.id} ia={selectedBoxOperator.ia_id} />
                        <Avatar company_id={bot.company_id} avatarid={avatarid} />    
                    </Col>
             :null} 
            <Col sm="6">
                <Row>
                    <Col sm="12" md="12" className="keyword">
                        {this.props.timeout?
                            <Timeout bindValue={this.props.getTimeout} bindMsg={this.props.getMsgTimeout} bindValid={this.props.isTimeoutValid} defaultValue={this.props.timeoutValue} defaultMsg={this.props.timeoutMsg} />
                        :null}
                        {this.props.isKeywordValid?
                        <Keyword keyword={selectedBoxOperator.keyword} isValid={this.props.isKeywordValid} />
                        :null}
                    </Col>
                </Row>
            </Col>
        </Row>
        );
    }else{
        return null;
    }
    
  }
}

const mapStateToProps = ({ botReducer,boxReducer,homeReducer, componentMountReducer }) => {
  return { botReducer,boxReducer,homeReducer, componentMountReducer };
 };
 
 const mapDispatchToProps = {

 };
 
 export default connect(mapStateToProps, mapDispatchToProps)(TabAdvanced);