import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {Link} from 'react-router-dom'
import { UncontrolledTooltip, Button } from 'reactstrap';

/* IMAGES */
import home from '../../../static/img/menu-icons/home.svg'
import companies from '../../../static/img/menu-icons/admin_users.svg'
import modulo1 from '../../../static/img/module-icons/modulo1.png'
import campañas from '../../../static/img/menu-icons/campannas.svg'
import report from '../../../static/img/menu-icons/reportes.svg'
import { getRolUser } from '../../utils/commonFunction';
import { Redirect } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' 
import { faDatabase } from '@fortawesome/free-solid-svg-icons'
import {cleanSearching,getAllbots} from "../../redux/actions/homeActions";
import i18next from "../../../i18n";

function AdminMenuOptions(props){
    function renderRedirect(){
  
        props.cleanSearching();
        props.getAllbots(1);
       
    }

    const { isAdmin = false, rol = '' } = getRolUser();
    return (
        <span>
            {props.handleFind &&
                <li style={{'height':'50px'}}></li>
            } 
            {(rol != 'Reportes') && (rol != 'AdminCampaign') &&
            <li id="tooltipHomeAdmin" > 
            <Link to="/" onClick={(e)=>{renderRedirect(e);}}>
                <img src={home}/>
                <UncontrolledTooltip placement="right" target="tooltipHomeAdmin">{i18next.t('home')}</UncontrolledTooltip>
            </Link>
            </li> 
            }
            {
                isAdmin && (rol != 'Reportes') && (rol != 'AdminCampaign') &&
                <li  id="tooltipDataBase" className="mt-2 mb-1"> 
                    <Link to="/database">
                    <FontAwesomeIcon icon={faDatabase} size="2xl"  style={{color: "#fafafa",}}/>
                        <UncontrolledTooltip placement="right" target="tooltipDataBase">{i18next.t('admin_database')}</UncontrolledTooltip>
                    </Link>
                </li>
                
            }
            {isAdmin &&
            <li id="tooltipMassiveCampaign">   
                {/* <Link to={"/campaigns/list-campaigns"} onClick={(e)=>{renderRedirect(e);}} className="sideboard-btn sideboard-webforms">   */}
                <a href="/campaigns/list-campaigns">    
                    <img src={campañas} style={{'marginTop':'15px'}}/>  
                </a>
                {/* </Link>   */}
                <UncontrolledTooltip placement="right" target="tooltipMassiveCampaign">{i18next.t('campaign_massive')}</UncontrolledTooltip>  
            </li> }
                {
                isAdmin && (rol != 'AdminCampaign') &&
                <li  id="tooltipReport"> 
                    <Link to="/dashboard">
                        <img src={report} />
                        <UncontrolledTooltip placement="right" target="tooltipReport">{i18next.t('dashboard')}</UncontrolledTooltip>
                    </Link>
                </li>            
            }    
            {
                isAdmin && (rol != 'Reportes') && (rol != 'AdminCampaign') &&
                <li  id="tooltipUserAdmin"> 
                    <Link to="/companies">
                        <img src={companies} />
                        <UncontrolledTooltip placement="right" target="tooltipUserAdmin">{i18next.t('admin_companies')}</UncontrolledTooltip>
                    </Link>
                </li>
                
            }
        </span>
    );
}


const mapStateToProps = ({ homeReducer,  }) => {
	return { homeReducer,  };
};

const mapDispatchToProps = {
    cleanSearching,
    getAllbots
};


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AdminMenuOptions));
