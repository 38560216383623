// Actions
export const DATABASE_ACTIONS = {
    getAllCompanies: () => 'GET_ALL_CoMPANIES',
    handleOnchageInputs: () => 'HANDLE_ONCHANGE_INPUTS',
    deleteCompany: () => 'DELETE_COMPANY',
    getCollectionByCompany: () => 'GET_COLLECTION_BY_COMPANY',
    getAvatarsByCompany: () => 'GET_AVATARS_BY_COMPANY',
    getVisualizarCollection:()=>'GET_VISUALIZAR_COLLECTION',
    getSinRegistrosFiltroCollection:()=>'GET_SIN_RESGISTRO_COLLECTION',
    getNombreCompany:()=>'GET_NOMBRE_COMPANY',
    getVisualizarCollectionPagination:() => 'GET_VISUALIZAR_COLLECTION_PAGINATION',
    arrayDataBasesShowValues:() => 'GET_DATABASE_SHOW_VALUES',
    getNameCollection:() => 'GET_NAME_COLLECTION',
};
  

