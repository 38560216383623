import React from 'react';
import {
  Col,
  Row,
  FormGroup,
  Label,
  CardHeader,
  Container,
  Form,
  Input,
  Button
 } from 'reactstrap';
 import '../webform/bot-webforms-layout.css';



class BotUnsuscrubeRenderLayout extends React.Component {

    constructor(props) {
        super(props);
      
        this.state = {
          selectedOptionInput:0,     
        };
      }

      handleOptionChangeInput = e => this.setState({ selectedOptionInput: e.target.value},()=>{console.log('state',this.state.selectedOptionInput)});

      render() {
        return (
            <React.Fragment>
                <Container fluid >
                          <Col md={6} className="offset-3">
                            <Form onSubmit={this.props.saveForm}>
                                <CardHeader>
                                    <Row>
                                        <Col md={12}>
                                          <h2>¿Realmente quieres dar de baja a tu suscripción?</h2>
                                        </Col>
                                        <Col md={12}>
                                            <FormGroup check>
                                                <Label check>
                                                  <Input type="radio" name="suscripcion" value='1' onChange={(e)=>{this.handleOptionChangeInput(e)}}/>&nbsp;Si
                                                </Label>
                                            </FormGroup>
                                            <FormGroup check>
                                              <Label check>
                                                <Input type="radio" name="suscripcion" value='0' onChange={(e)=>{this.handleOptionChangeInput(e)}}/>&nbsp;No
                                              </Label>
                                            </FormGroup>
                                        </Col>
                                        <Col className="col-4 offset-4">
                                            <Button type="submit" className="btn-block">Enviar</Button>
                                        </Col>
                                    </Row>
                                </CardHeader>
                            </Form>                      
                          </Col>
                </Container>  
        </React.Fragment>
        );
      }

}
  
export default BotUnsuscrubeRenderLayout;


