import { AbstractNodeFactory } from "storm-react-diagrams";
import React from "react";
import CNodeWidget from "./CNodeWidget";
import CNodeModel from "./CNodeModel";

class CNodeFactory extends AbstractNodeFactory {
	constructor() {
		super("cn");
	}

	generateReactWidget(diagramEngine, node) {
		return <CNodeWidget node={node} />
	}

	getNewInstance() {
		return new CNodeModel();
	}
}

export default CNodeFactory