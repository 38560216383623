import React, {useState, useEffect} from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Media,
  
 } from 'reactstrap';
 import './navbar.css';

 import logo from '../../../../static/img/logo.png'
 import user from '../../../../static/img/user_image.png'
 import {Link} from 'react-router-dom'

 import { useTranslation, withTranslation, Trans } from 'react-i18next';
 import i18next from '../../../../i18n';
 
 

function NavbarApp(props){
  
  const { t, i18n } = useTranslation();
  const [lenguageSelect, setLenguageSelect] = useState(i18n.language);

  const changeLanguage = (lng) => {
    setLenguageSelect(lng);
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    i18next
  },[i18n.language]);

  const languages = [
    { code: 'es', name: 'Español'},
    { code: 'en', name: 'English'},
    { code: 'pr', name: 'Português'},
    // { code: 'rus', name: 'Русский'},
    // { code: 'deu', name: 'Deutsch'}
  ]

  const options = languages.map(language => {
    if(language.code != props.lang){
      return <li key={props.lang} onClick={() => changeLanguage(language.code)}>
              <div value={language.code} className={language.code} ></div>
            </li>
    }  
  });

    return (
      <div>
        <Navbar color="white" light expand="sm" className="fixedTop" >
          <Link to="/">
            <Media src={logo} style={{'height':'70px','marginLeft':'10px'}}/>
          </Link>
          <NavbarToggler className="ml-auto mr-4" onClick={props.toggle} />
          <Collapse isOpen={props.isOpen} navbar>
            <Nav className="ml-auto" navbar>

            
            {/* <button type="button" onClick={() => changeLanguage('es')}>
              es
            </button>
            <button type="button" onClick={() => changeLanguage('en')}>
              en
            </button> */}
            
            <div className="lang"> 
              <div 
                className={lenguageSelect.indexOf('-')==-1?lenguageSelect:lenguageSelect.substring(0, lenguageSelect.indexOf('-'))}
              >
              </div>
                <ul className="dropdown">
                  {options}
                </ul>
            </div>

              <UncontrolledDropdown nav inNavbar className="userDrop">
                
                <DropdownToggle nav caret >
                <small> {(props.user)?props.user.username:'' || ""}</small>
                <img src={user} alt="person" className="img-circle"/>
                </DropdownToggle>

                <DropdownMenu right>
                  <DropdownItem onClick={props.updateUser}>
                  {i18next.t('edit_profile')}
                  </DropdownItem>
                  <DropdownItem onClick={props.logOut}>
                  {i18next.t('close_session')}
                  </DropdownItem>
                </DropdownMenu>
                
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
}
  
export default NavbarApp