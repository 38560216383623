import React, {Fragment} from 'react';

import { 
    Button, 
    Modal, 
    ModalHeader, 
    ModalBody, 
    ModalFooter,
    Input, 
    Col,
    Row,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption
} from 'reactstrap';

import '../modal.css';

function HMLayout(props){
    
    const activeIndex = props.index;

    const slides = props.items.map((item) => {
      return (
        <CarouselItem key={item.src} onExiting={props.onExiting} onExited={props.onExited} >
          <h2 className={item.class}> {item.title} </h2> 
          <p> {item.description}  </p>
          <div>
          <img src={item.src} alt={item.title} />
          </div>
        </CarouselItem>
      );
    });

    return (
        <div >
            <Modal className="HM-layout" isOpen={props.isOpen} toggle={ () => props.toggleHelpModal() } fade >
                <ModalHeader className="help-modal-header" toggle={ () => props.toggleHelpModal() }></ModalHeader>
                <ModalBody>
                  <Carousel className="rounded-li" interval={false} activeIndex={activeIndex} next={props.next} previous={props.previous} >
                    <CarouselIndicators items={props.items} activeIndex={activeIndex} onClickHandler={props.goToIndex} />
                    {slides}
                    <CarouselControl direction="prev" directionText="Previous" onClickHandler={props.previous} />
                    <CarouselControl direction="next" directionText="Next" onClickHandler={props.next} />
                  </Carousel>
         
                </ModalBody>
            </Modal>           
        </div>
    );
}
  
export default HMLayout
 

                  