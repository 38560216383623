import React from 'react';
import styled from 'styled-components';
import { Button as ButtonReact } from 'reactstrap';

const StyledButton = styled.button`
    background: #3f9034;
    border: none;
    border-radius: 3px;
    color: #fff;
    cursor: pointer;
    height: 40px;
    font-size: 14px;
    font-weight: 300;
    margin: 8px;
    text-transform: uppercase;
    width: 100%;
`;

const Button = ({ selectedStartDate, submitDate }) => {
    if(selectedStartDate){
        const displayDate = selectedStartDate;//.format('DD/MM/YYYY  hh:mm a');

        return (
            <StyledButton onClick={() => submitDate()} className="over_iframe_button">
                {`Confirmar (${displayDate})`}
            </StyledButton>
        )
    }
    else{
        return(<ButtonReact color="secondary" style={{height: 40+"px", fontSize: 14+"px",fontWeight: 300,margin: 8+"px",textTransform: 'uppercase',width: 100+"%"}} disabled>
        {`Seleccione una fecha`}
        </ButtonReact>)
    }
       
}

export default Button;