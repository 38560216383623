import { AbstractNodeFactory }  from "storm-react-diagrams";
import React from "react";
import APNodeWidget from "./APNodeWidget";
import APNodeModel from "./APNodeModel";

class APNodeFactory extends AbstractNodeFactory {
	constructor() {
		super("ap");
	}

	generateReactWidget(diagramEngine, node) {
		return <APNodeWidget node={node} />
	}

	getNewInstance() {
		return new APNodeModel();
	}
}

export default APNodeFactory