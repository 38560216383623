import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom"; 
import { UncontrolledTooltip } from 'reactstrap'; 
import {Link} from 'react-router-dom'
 
/*  images */
import home from '../../../static/img/menu-icons/home.svg'
import flujo from '../../../static/img/menu-icons/flujos.svg'
import setup from '../../../static/img/menu-icons/configurar.svg'
import entrenar from '../../../static/img/menu-icons/entrenar.svg'
import ia from '../../../static/img/menu-icons/ia.svg'
import Webform from '../../../static/img/menu-icons/web_forms.svg'
import report from '../../../static/img/menu-icons/reportes.svg'
import cms from '../../../static/img/menu-icons/cms.svg'
import push from '../../../static/img/menu-icons/push.svg'
import html from '../../../static/img/menu-icons/html.svg'

import {getAllbots} from "../../redux/actions/homeActions";
import i18next from "../../../i18n";


let showSubMenu = () => document.getElementById('stats-submenu').hidden = false;  
let hideSubMenu =  () => document.getElementById('stats-submenu').hidden = true; 

/*SUBMENU IA */
let showSubMenuIA = () => document.getElementById('stats-submenuIA').hidden = false;  
let hideSubMenuIA =  () => document.getElementById('stats-submenuIA').hidden = true; 
/*FIN SUBMENU IA */
  
function BotMenuOptions(props){ 
  console.log("props.botReducer ",props.botReducer);
  const { bot  } = props.botReducer;

  if(bot){

    return ( 
      <span> 
        {/* <li id="tooltipBack" > 
            <Link to="/" onClick={(e)=>{props.getAllbots(1);}}>
                <img src={home}/>
                <UncontrolledTooltip placement="right" target="tooltipBack">Atrás</UncontrolledTooltip>
            </Link>
            </li>  */}
              <li id="tooltiphome"> 
                <Link to={"/"} className="sideboard-btn sideboard-dashboard"> 
                  <img src={home} /> 
                </Link> 
                <UncontrolledTooltip placement="right" target="tooltiphome">Home</UncontrolledTooltip> 
              </li> 
              <li id="tooltipflujos"> 
                <Link to={"/schemas/"+bot.id} className="sideboard-btn sideboard-dashboard"> 
                  <img src={flujo} /> 
                </Link> 
                <UncontrolledTooltip placement="right" target="tooltipflujos">{i18next.t('flows')}</UncontrolledTooltip> 
              </li> 
 

              <li id="tooltipsetup">  
                  <Link to={"/bot/"+bot.id+"/setup"} className="sideboard-btn sideboard-config"> 
                    <img src={setup}/> 
                  </Link> 
                  <UncontrolledTooltip placement="right" target="tooltipsetup">{i18next.t('settings')}</UncontrolledTooltip> 
              </li> 
              <li id="tooltipQNA">  
                  <Link to={"/bot/"+bot.id+"/aiml-group"} className="sideboard-btn sideboard-qna"> 
                    <img src={entrenar} /> 
                  </Link> 
                  <UncontrolledTooltip placement="right" target="tooltipQNA">QnA</UncontrolledTooltip> 
              </li> 
              { props.ia_activated ? ( 
                  <li id="tooltipIA" className="stat-menu-item" onMouseOver={showSubMenuIA} onMouseLeave={hideSubMenuIA}>  
                      <a className="sideboard-btn sideboard-statistics"> 
                        <img src={ia} /> 
                      </a> 
                      {/*<Link to={"/bot/"+bot.id+"/ia"} className="sideboard-btn sideboard-ia"> 
                        <img src={ia} /> 
                        </Link>
                        */}
                      <div id="stats-submenuIA" hidden> 
                        <ul> 
                          <li> 
                            <Link to={"/bot/"+bot.id+"/iamodule"} >  {i18next.t('setting_module')} </Link> 
                          </li> 
                          <li>  
                            <Link to={"/bot/"+bot.id+"/ia"} className="sideboard-btn sideboard-ia">{i18next.t('questions_without_response')} </Link>  
                          </li> 
                          <li>  
                            <Link to={"/bot/"+bot.id+"/iamoduletable"} >  {i18next.t('questions_multiple_ask')} </Link>  
                          </li> 
                        </ul> 
                      </div> 
                      <UncontrolledTooltip placement="top" target="tooltipIA">{i18next.t('ia')}</UncontrolledTooltip> 
                  </li> 
                ): (<span> </span>) } 
              <li id="tooltipFormulario">  
                  <Link to={"/bot/"+bot.id+"/web-forms"} className="sideboard-btn sideboard-webforms"> 
                    <img src={Webform} /> 
                  </Link> 
                  <UncontrolledTooltip placement="right" target="tooltipFormulario">{i18next.t('web_view')}</UncontrolledTooltip> 
              </li> 
 
              <li id="tooltipReport" className="stat-menu-item" onMouseOver={showSubMenu} onMouseLeave={hideSubMenu} > 
                  <a className="sideboard-btn sideboard-statistics"> 
                    <img src={report} /> 
                  </a> 
                  <div id="stats-submenu" hidden> 
                    <ul> 
                      {/* <li> 
                        <Link to={"/bot/"+bot.id+"/statistics"} >  Dashboard </Link> 
                       </li>  */}
                       <li>  
                        <Link to={"/bot/"+bot.id+"/funnels"} >  {i18next.t('funnels_list')} </Link>  
                      </li> 
                      <li>  
                        <Link to={"/bot/"+bot.id+"/events"} >  {i18next.t('event_list')} </Link>  
                      </li> 
                      {/* <li>  
                        <Link to={"/bot/"+props.botId+"/generalstats"} >  {i18next.t('report_interactions')} </Link>  
                      </li>  */}
                    </ul> 
                  </div> 
                  <UncontrolledTooltip placement="top" target="tooltipReport">{i18next.t('reports')}</UncontrolledTooltip> 
              </li> 
              <li id="tooltipCMS">  
                  <Link to={"/cms/"+bot.id} className="sideboard-btn sideboard-webforms"> 
                    <img src={cms} style={{'marginTop':'15px'}}/> 
                  </Link> 
                  <UncontrolledTooltip placement="right" target="tooltipCMS">CMS</UncontrolledTooltip> 
              </li> 
 
              <li id="tooltipNotification">  
                  <Link to={"/notifications/"+bot.id} className="sideboard-btn sideboard-webforms"> 
                    <img src={push} style={{'marginTop':'15px'}}/> 
                  </Link> 
                  <UncontrolledTooltip placement="right" target="tooltipNotification">{i18next.t('notifications')}</UncontrolledTooltip> 
              </li> 
 
              <li id="tooltiphtml">  
                <Link to="/templates"> <img src={html} /> </Link> 
                <UncontrolledTooltip placement="right" target="tooltiphtml">{i18next.t('email_template')}</UncontrolledTooltip> 
              </li> 
      </span> 
    ); 
  }
} 
const mapStateToProps = ({ homeReducer, botReducer }) => {
	return { homeReducer, botReducer };
};

const mapDispatchToProps = {
    getAllbots
};


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BotMenuOptions));
 