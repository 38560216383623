import React, { useState, useEffect } from 'react';

import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';

import Select from 'react-select';
import { find, propEq, map, pipe, toPairs, transpose, converge, zipObj, head, last, reject } from "ramda";

import { 
    Col,
    Row,
    FormGroup,
    Label,
    Input, 
    UncontrolledTooltip
} from 'reactstrap';

const SelectedStatements = ({ onchangeStatements, varSelected, isMulti = false, ...props }) => {

    const {varListSelected} = props.boxReducer;
    const 
        [ operatorVar, setOperatorVar ] = useState([]),
        [ activeVar, setActiveVar ] = useState(0),
        [ isLoad, setIsLoad ] = useState(false)


    useEffect(() => {
        if(varSelected){
            let selectedVar = varSelected

            if(!Array.isArray(varSelected)){
                selectedVar = [ Number(varSelected) ]
            }
            
            const activeVarList = varListSelected.filter( ({ value }) => selectedVar.includes( value ))
            
            setActiveVar(
                map(renameKeys({ text: 'label', id: 'value' }), activeVarList)
            )
        }
    }, [ varSelected ])




    if(varListSelected && varListSelected.length>0)
    {
        let listStatements = []
        listStatements = varListSelected; //map(renameKeys({ text: 'label', id: 'value' }), varList)
        //listStatements = reject(propEq('label', ''))(listStatements);
        //Agrega valor por defecto (SIN SELECCIÓN)
        listStatements.push({label:'', value:'none'})

        //Revisa cual es el valor seleccionado para usarlo en dafault value

        const colourStyles = {
            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                color: isDisabled
                ? '#ccc'
                : isSelected
                ? 'white'
                : 'black'
            };
            },
            multiValue: (styles, { data }) => {
                return {
                  ...styles,
                  backgroundColor: '#75b74b',
                };
              },
             ///.....
             menuPortal: provided => ({ ...provided, zIndex: 9999 }),
             menu: provided => ({ ...provided, zIndex: 9999 })
             ///.....
        };

        const hadleSelect = (list) => {
            if(!list) list = []
            let finalList;
            if(Array.isArray(list)){
                finalList = list.length?list.map( ({ value }) => value):[]
            }else{
                finalList = list.value
            }
            
            setOperatorVar(finalList)
            onchangeStatements(props.nameVar, finalList)
        }

     

        return (
            // <Col sm="12" md="12" className="filter-stuff">
                <FormGroup style={{ clear: "both" }}>
                    <Row>
                        <Col className="col-lg-12">
                            
                            <Label>{props.label} <i className="fa fa-info-circle" id={"tooltipListVariables_"+props.nameVar}></i></Label>
                            <UncontrolledTooltip placement="right" target={"tooltipListVariables_"+props.nameVar}> {props.tooltip}</UncontrolledTooltip>
                                                 
                            {listStatements&&listStatements.length > 0 ? (
                                <Select
                                    options={listStatements?listStatements:[]}
                                    classNamePrefix="select"
                                    className="basic-single"
                                    isMulti={ isMulti }
                                    isDisabled={false}
                                    isLoading={false}
                                    isClearable={true}
                                    isRtl={false}
                                    isSearchable={true}
                                    onChange={ hadleSelect }
                                    name="type"
                                    value={ activeVar }
                                    styles={colourStyles}
                                    menuPortalTarget={document.body}
                                    menuPosition={'fixed'} 
                                                                      
                                />
                            ):<div className="alert alert-danger" role="alert">
                                No hay variables creadas
                            </div>
                            }
                        </Col>
                    </Row>
                </FormGroup>
        );
    }
    else 
        return(
            <div className="alert alert-danger" role="alert">
                No hay variabes creadas
            </div>
        );
}

const renameKeys = keysMap => pipe(
    toPairs, // convert to entries
    transpose, // convert to array of keys, and array of values
    converge(zipObj, [ // zip back to object
      pipe(head, map(key => keysMap[key] || key)), // rename the keys
      last // get the values
    ])
  )
  

const mapStateToProps = ({boxReducer}) => {
    return {boxReducer};
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps )(withRouter(SelectedStatements));