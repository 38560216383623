import { MODAL_LOAD_ACTIONS_TYPES } from '../types/loadStateActionsTypes';

const checkStatusComponent = ( components = [ { name: '' } ], nameComponent ) => {   
    let component = components.filter(( item ) => item.name == nameComponent )
    return ( (component.length == 0) ? false : true ); 
}

export const hideAlert = ( nameComponent ) => ( dispatch, getState ) => {
    dispatch({
        type: MODAL_LOAD_ACTIONS_TYPES.hideLoad()
    })      
}

export const loading = () => ( dispatch, getState ) => {
    dispatch({
        type: MODAL_LOAD_ACTIONS_TYPES.loading()
    })      
}

export const setTitle = (msTitle) => ( dispatch, getState ) => {
    dispatch({
        type: MODAL_LOAD_ACTIONS_TYPES.setTitle(),
        payload: msTitle,
      });   
}


export const showSuccess = ( msSuccess, showTime = 2000 ) => ( dispatch, getState ) => {
    dispatch({
        type: MODAL_LOAD_ACTIONS_TYPES.showSuccess(),
        payload: msSuccess
    })      

    setTimeout(() => {
        dispatch(hideAlert());

        dispatch(setTitle (''));
    },showTime);


}

export const showWarning = ( msWarning,showTime = 2000 ) => ( dispatch, getState ) => {
    dispatch({
        type: MODAL_LOAD_ACTIONS_TYPES.showWarning(),
        payload: msWarning
    })      

    setTimeout(() => {
        dispatch(hideAlert());

        dispatch(setTitle (''));
    },showTime);
}

export const showError = ( msError,showTime = 2000  ) => ( dispatch, getState ) => {
    dispatch({
        type: MODAL_LOAD_ACTIONS_TYPES.showError(),
        payload: msError
    })     
    
    setTimeout(() => {
        dispatch(hideAlert());

        dispatch(setTitle (''));
    },showTime);
}
