import React, {Component} from 'react';
import {Button,Modal,ModalHeader,ModalBody,ModalFooter,Form,FormGroup,Table,Input,Col,Row,Label,Nav,NavItem,TabContent,TabPane,NavLink,UncontrolledTooltip} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import IASelector from '../../../../components/ia';
import Keyword from '../../../../components/keyword/keyword';
import Mentions from '../../../../components/mentions/metions-alone';
import InputValidate from '../../../../components/input-validate';
import Timeout from '../../../../components/timeout/timeout';
import TitleOperator from "../../../../components/title-operator";
import Avatar from '../../../../components/avatar';
import Emoji from '../../../../components/emoji-picker';
import classnames from 'classnames';
import TabAdvanced from "../../../../components/box/tab_advanced";
import i18next from '../../../../../i18n';

class EBMLayout extends Component {

    constructor(props){
        super(props)
        this.state = {
        activeTabLocal: '1'
        };
        this.toggleTab = this.toggleTab.bind(this);
    }

    toggleTab(tab) {
        if (this.state.activeTabLocal !== tab) {
          this.setState({
            activeTabLocal: tab
          });
        }
    }
    Botones = (bls,kblocks) =>{
        const block = kblocks.map((k, index) => {
            return <tr key={k}>
                <td>
                    <InputValidate k={k} maxlength={'20'} msg_error={'Máximo 20 caracteres'} defaultValue={bls[k].title} error_input_empty={this.props.error_input_empty}
                    varList={this.props.varList} />
                </td>
                <td>
                    <Input type="select" name="select" onChange={(event) => { this.props.updateTextBlock(k, 'type', event) }} value={bls[k].type}>
                        <option value="postback">{i18next.t('advance')}</option>
                        <option value="web_url">URL/{i18next.t('new_tab')}</option>
                        <option value="reload_url">URL/{i18next.t('reload')}</option>
                        {/* <option value="image">Imágen</option>
                        <option value="document">Documento</option>
                        <option value="video">Video</option>
                        <option value="location">Localización</option> */}
                    </Input>
                </td>
                <td>
                    {bls[k].type == "location"?(
                        <Input type="text" hidden={bls[k].is_var != true} className="inputModal" placeholder={i18next.t('name_var')} onChange={(event) => { this.props.updateVarName(k, event) }}  value={bls[k].var_name} />
                    ):
                        <Input type="text" hidden={bls[k].type == "postback"} className="inputModal" placeholder="URL" onChange={(event) => { this.props.updateTextBlock(k, "url", event) }} value={bls[k].url} ></Input>
                    }

                    { this.props.error_input_type[k] &&
                        <span className="warning-character">URL {i18next.t('invalid')}</span>
                    }
                </td>
                <td>
                    <Button color="info" size="lg" onClick={() => this.props.removeTextBlock(k) }><FontAwesomeIcon icon={faTrash} /></Button>
                    <Emoji id={k} callEmoji={this.props.callEmoji} type="item" style={{'display':'none',position:'fixed',marginLeft: -160+'px',zIndex: 100}}/>
                </td>
            </tr>
        });
        return block;
    }

    render(){
        let bls = this.props.buttons || {};
        let kblocks = Object.keys(bls);
        let blocks = this.Botones(bls,kblocks);
        return (
            <div>
                <Modal className="elements_button" isOpen={this.props.isOpen} toggle={this.props.closeModal} fade size="lg">
                    <ModalHeader toggle={this.props.closeModal}>{i18next.t('set_up')} {i18next.t('buttons_list')} </ModalHeader>
                    <ModalBody>
                    <Form role="form"> 
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                    className={classnames({ active: this.state.activeTabLocal === '1' })}
                                    onClick={() => { this.toggleTab('1'); }} >
                                    {i18next.t('basic')}
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                    className={classnames({ active: this.state.activeTabLocal === '2' })}
                                    onClick={() => { this.toggleTab('2'); }}>
                                    {i18next.t('advanced')}
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={this.state.activeTabLocal}>
                                <TabPane tabId="1">
                                    <Col sm="12">
                                        <FormGroup>
                                            <TitleOperator titleOperator={this.props.titleOperator} bindValue={this.props.getTitleOperator}/>
                                        </FormGroup>
                                        <FormGroup>
                                        <Label>{i18next.t('statement')} <i className="fa fa-info-circle" id="tooltipEnunciado"></i></Label>
                                        <UncontrolledTooltip placement="right" target="tooltipEnunciado"> {i18next.t('message8')}</UncontrolledTooltip>
                                                <Mentions varList={this.props.varList}  defaultValue={this.props.head || ''}  updateItem={this.props.updateItem} maxlength={'640'} msg_error={i18next.t('limit_allowed') +' 640'}/>
                                                    { this.props.error_requerido && 
                                                        <span className="warning-character">{i18next.t('field_required')}</span>
                                                    }
                                                <div className="options-bar-input" style={{'right':'0px','bottom':'-7px'}}>
                                                    <Emoji id={'head'} callEmoji={this.props.callEmoji} type="head" style={{'display':'none',position:'fixed',marginLeft: -265+'px',zIndex: 100}}/>
                                                </div>
                                        </FormGroup>
                                        <Table>
                                            <thead>
                                            <tr>
                                                <th>{i18next.t('title')}</th>
                                                <th>{i18next.t('type')}</th>
                                                <th>URL</th>
                                                <th></th>
                                            </tr>
                                            </thead>
                                            <tbody>{ blocks }</tbody>
                                        </Table> 
                                        <Col sm="12" className="button-add">
                                            { this.props.error_limit_item && 
                                                <Col sm="12">
                                                    <span className="warning-character">{i18next.t('max_buttons_allowed')} 3</span>
                                                </Col>
                                            }
                                            <Col sm="12">
                                                <Button color="info" className="btn-guarumo-modal-blue-sm" onClick={this.props.addNewTextBlock}>{i18next.t('add_new')}</Button>
                                            </Col>
                                        </Col>
                                    </Col>                                
                                </TabPane>
                                <TabPane tabId="2">
                                    <Row>
                                        <Col sm="6">
                            
                                        <IASelector botversion_id={this.props.botversion_id} 
                                                    operator_id={this.props.operator_id} 
                                                    ia={this.props.ia} 
                                                    getIAId={this.props.getIAId} />
                                        <Avatar      company_id={this.props.company_id} avatarid={this.props.avatarid} bindValue={this.props.getAvatarId} />
                                        </Col>
                                        <Col sm="6">
                                            <Row>
                                                <Col sm="12" md="12" className="keyword">
                                                    <Timeout bindValue={this.props.getTimeout} bindMsg={this.props.getMsgTimeout} bindValid={this.props.isTimeoutValid} defaultValue={this.props.timeoutValue} defaultMsg={this.props.timeoutMsg} />
                                                    <Keyword keyword={this.props.keyword} isValid={this.props.isKeywordValid} updateKeyword={this.props.updateKeyword}/>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </TabPane>
                            </TabContent>
                    </Form>
                    </ModalBody>
                    <ModalFooter> 
                        <Col sm="12" align="right">        
                            <Button color="success" className="btn-guarumo-modal-sm" onClick={() => this.props.toggleModuleModal()}>{i18next.t('finish')}</Button>
                        </Col>  
                    </ModalFooter>
                </Modal>           
            </div>
        );
       
    }
}


  
export default EBMLayout