import React from 'react';
import Admin from '../../admin/containers/admin';
import SliceMenuApp from '../../admin/components/slice-menu'
import { 
  Col,
  Row,
  Button, 
  Container,
  Modal, 
  ModalHeader, 
  ModalBody, 
  FormGroup, 
  Label,
  Input,
  Form,
  Table,
  UncontrolledTooltip
} from 'reactstrap';
import Select from 'react-select';
import ToolbarApp from '../../admin/components/toolbar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash, faSync} from '@fortawesome/free-solid-svg-icons'
import BotMenuOptions from '../../menus/bot-menu-options';
import './notificacions-layout.css';
import Datetime from 'react-datetime';
import './react-datetime.css';
import Mentions from '../../../components/mentions/mentions-header'; 
require('moment/locale/es')
import DataTableFilter from '../../analytics/infoViz/common/dataTableFilter'
import i18next from '../../../../i18n';
      /* IMAGE */
import imageNew from '../../../../static/img/menu-icons/nuevo_bot.svg'

function NotificaionsLayout(props) {
  let notification = props.listNotifications || [];
  let listAllnotifications=[]


  var dataTable=null;
  if(notification[0]){     
       dataTable = {
          columns: [
            {
              label: i18next.t('title'),
              name: i18next.t('title'),
              field: i18next.t('title'),
              sort: 'asc',
             // width: 150
            },
            {
              label: i18next.t('channels'),
              name: i18next.t('channels'),
              field: i18next.t('channels'),
              sort: 'asc',
             // width: 150
            },
            {
              label: i18next.t('status'),
              name: i18next.t('status'),
              field: i18next.t('status'),
              sort: 'asc',
             // width: 150
            },
            {
                label: i18next.t('send_date'),
                name: i18next.t('send_date'),
                field: 'fecha_envio',
                sort: 'asc',
               // width: 150
              },
            {
              label: i18next.t('edition_option'),
              name: i18next.t('edition_option'),
              field: 'options',
              sort: 'asc',
              width: 670,
              options: {
                  filter: false,
                  sort: false
                 }
            }],
            rows: []};

            const rows =  notification.map((no, index) => {
             
              const fin=[];
              fin.push(no.title);
              fin.push(no.channelsLabel);
              fin.push(no.senddate);
              if(no.status == 0)
                fin.push('Pendiente');
              else
                fin.push('Enviada');

              fin.push(<div className="d-inline-block">
                    { no.status === 0 &&
                        <Button outline color="success" onClick={ (e) => props.getNotification(no.id)}>
                            <FontAwesomeIcon icon={faPen} id={'buttonEdit'+index}/>
                            <UncontrolledTooltip placement="top" target={'buttonEdit'+index}> {i18next.t('edit')}</UncontrolledTooltip>
                        </Button>
                    }
                    <Button outline color="danger" onClick={ (e) => props.deleteNotification(no.id)}>
                        <FontAwesomeIcon icon={faTrash} id={'buttonDelete'+index} />
                    </Button>
                    <UncontrolledTooltip placement="top" target={'buttonDelete'+index} > {i18next.t('delete')}</UncontrolledTooltip>
                </div>
             );

              dataTable.rows.push(fin); 
              return no;
          });
      }

      const options = {
          filterType: 'multiselect',
          selectableRows:'none',
          resizableColumns: false,
          responsive: "scrollMaxHeight",
          rowsPerPage:5 ,
          print:false,
          download:false,
          viewColumns:false,
        };
        
            var title = ''


  let template = props.listTemplates || [];
  var sel_template = [];
  template.map((t, i) => {
    if(t.id == props.idTemplate)
        aux2 = true;
    sel_template.push(<option key={"template_id-"+i} value={t.id} selected={aux2}>{t.template_name}</option>);
  })

  let contact = props.listContact || [];
  var sel_contat = [];
  var aux2 = false; 
  if(props.notification){
    contact.map((n, i) => {
        if(n._id == props.idList) 
            aux2 = true;
        sel_contat.push(<option key={"contEdit_id-"+i} value={n._id} selected={aux2}>{n.titulo}</option>);
      })
  }
  
  return (
    <div className="NotificaionsLayout">
          <Admin>
          <button id="tooltiNewTemplate" className="btn-float"  onClick={(e)=>{props.toggleModalNewNotification()}}>
                <img src={imageNew}/>
            </button> 
            <UncontrolledTooltip placement="right" target="tooltiNewTemplate">{i18next.t('create_new_notification')}</UncontrolledTooltip> 
        
            <SliceMenuApp>
            <BotMenuOptions botId={props.botId} ia_activated={props.ia_activated} idSchema = {props.idSchema}/>
            </SliceMenuApp>
            <Container fluid>
                <ToolbarApp>
                    <Row>
                        <Col sm="9" md="9" lg="9" className="text pdtoolbar">
                        <h2 className="text-left ml-4 font-weight-bold">{i18next.t('notifications')}</h2>                      
                        </Col>
                    </Row>
                </ToolbarApp>
            </Container>
            <Container fluid className="box-fluid">
                <Col sm="12" md="10" className="stadistics-container offset-md-1">    
                    {
                       dataTable?(
                        <Row >
                            <Col xs={12} lg={12} >
                                <DataTableFilter
                                title={title}
                                data={ dataTable.rows}
                                columns={dataTable.columns}
                                options={options}
                                />  
                           </Col>
                        </Row>): 
                          <Col xs={12} lg={8} className="offset-lg-2 intro-funnels">
                                <Row>
                                    <Col xs={12}><img src="/static/img/blank_state.png" className="img-fluid"/></Col>
                                    <Col xs={12}>
                                        <h2>{i18next.t('notifications_msg')}</h2>
                                        <h3>{i18next.t('notifications_msg2')}</h3>
                                        <p>{i18next.t('notifications_msg3')}</p>
                                    </Col>
                                </Row>
                            </Col>
                        }
                    </Col>
                </Container>

            <Modal className="modalRight" isOpen={props.modalNewNotification} fade toggle={ () => props.toggleModalNewNotification() }>
                <ModalHeader toggle={() => props.toggleModalNewNotification()} className='notification'>{i18next.t('notifications_config')}</ModalHeader>
                <ModalBody className='notification-body'>
                        <Col md={12} className="sectionBox">
                            <Form id="frm_notifications" onSubmit={props.saveNotiHandler}>
                                <FormGroup> 
                                    <Label className="plusBtn">{i18next.t('contact_list')} &nbsp;<a target="_blank" href={"/contact-lists/"} className="pull-right btn btn-success">{i18next.t('new_list')}</a></Label>
                                    <Row>
                                        <Col className="col-10">
                                            <Input type="select" onChange={props.handleChangeSelectContact} className="config-input" name="list" required>
                                            <option key={"contact_id-0"} value='' style={{'display':'none'}}>-{i18next.t('select_one')} -</option>
                                            {sel_contat}
                                            </Input>
                                        </Col>
                                        <Col className="col-2 pl-0">
                                            <Button outline color="success" onClick={ (e) => props.getList()}>
                                                <FontAwesomeIcon icon={faSync} id='buttonRefreshE' style={{'fontSize':'18px'}} />
                                            </Button>
                                            <UncontrolledTooltip placement="bottom" target='buttonRefreshE'> {i18next.t('refresh_contact_list')}</UncontrolledTooltip>
                                        </Col>
                                    </Row>
                                    <Label>{i18next.t('title')}</Label> 
                                    <Input type="text" id="title" name="title" maxLength={200} required></Input> 
                                    <Label>{i18next.t('message')} SMS</Label>
                                    <Mentions k={1} varList={props.varList}  defaultValue={props.datasms || ''} updateItem={props.updateItem} maxlength={'160'} msg_error={i18next.t('limit_allowed') + ' 160'}/>
                                    <Label>{i18next.t('message')} Whatsapp / Facebook</Label>
                                    <Mentions k={2} varList={props.varList}  defaultValue={props.datafb || ''} updateItem={props.updateItem} maxlength={'2000'} msg_error={i18next.t('limit_allowed') + ' 2000'}/>
                                    <Label>{i18next.t('channel')}</Label> 
                                    <Select value={props.selectedOption} onChange={props.handleChangeChannels} options={props.listChannels} isMulti={true} name="channel" />
                                    <Label>{i18next.t('send_date')}</Label>
                                    <Datetime defaultValue={props.selectedDay} dateFormat={'YYYY-MM-DD'} timeFormat={'HH:mm:ss'} nChange = {props.handleChangeDay} inputProps={{ placeholder: 'YYYY-MM-DD HH:mm:ss', readOnly: true }} locale="es" />
                                    <Label className="plusBtn">{i18next.t('template_email')} &nbsp;<a target="_blank" href="/Newtemplates" className="pull-right btn btn-success">{i18next.t('new_template')}</a> </Label> 
                                    <Row>
                                        <Col className="col-10">
                                            <Input type="select" name="plantillaEmail" id="plantillaEmail" className="plantillaEmailBox">
                                                <option key={"template_id-0"} value='' style={{'display':'none'}}>-{i18next.t('select_option')}-</option>
                                                {sel_template}
                                            </Input>
                                        </Col>
                                        <Col className="col-2 pl-0">
                                        <Button outline color="success" onClick={ (e) => props.getlistTemplates()}>
                                            <FontAwesomeIcon icon={faSync} id='buttonRefresh' style={{'fontSize':'18px'}} />
                                        </Button>
                                        <UncontrolledTooltip placement="bottom" target='buttonRefresh'> {i18next.t('refresh_template_email_list')}</UncontrolledTooltip>
                                        </Col>
                                    </Row>                             
                                    <Label>{i18next.t('subject')}</Label> 
                                    <Input className="subjectBox" type="text" id="subject" name="subject" maxLength={500}></Input> 
                            </FormGroup> 
                            </Form>
                        </Col>
                        <Col  className="buttonModal">
                            <Col className="col-10 offset-1">
                                <Row>
                                    <Col className="col-6 offset-6"><Button className="btn-block btn-guarumo-modal-sm" type="submit" form="frm_notifications">{i18next.t('save')}</Button></Col>         
                                </Row>
                            </Col>
                        </Col>
                    
                </ModalBody>
            </Modal> 
            {
                props.notification &&
                <Modal className="modalRight" isOpen={props.modalEditNotification} fade toggle={ () => props.toggleModalEditNotification() }>
                    <ModalHeader toggle={() => props.toggleModalEditNotification()} className='carousel'>{i18next.t('notification_edit')}</ModalHeader>
                    <ModalBody className='notification-body'>
                        <Col md={12} className="sectionBox">
                            <Form id="frm_notifications" onSubmit={props.saveEditNotiHandler}>
                                <FormGroup> 
                                    <Input type="hidden" name="notid" defaultValue={props.notification.id} />
                                <Label className="plusBtn">{i18next.t('contact_list')} &nbsp;<a target="_blank" href={"/contact-lists/"+props.botId } className="pull-right btn btn-success">{i18next.t('new_list')}</a></Label>
                                <Row>
                                    <Col className="col-10">
                                        <Input type="select" className="config-input" name="listEdit" required>
                                            <option key={"contEdit_id-0"} value='' style={{'display':'none'}}>-{i18next.t('contact_list')} -</option>
                                            {sel_contat}
                                        </Input>
                                    </Col>
                                    <Col className="col-2 pl-0">
                                        <Button outline color="success" onClick={ (e) => props.getList()}>
                                            <FontAwesomeIcon icon={faSync} id='buttonRefreshE' style={{'fontSize':'18px'}} />
                                        </Button>
                                        <UncontrolledTooltip placement="bottom" target='buttonRefreshE'> {i18next.t('refresh_contact_list')}</UncontrolledTooltip>
                                    </Col>
                                </Row>
                                
                                <Label>{i18next.t('title')}</Label> 
                                <Input type="text" id="edittitle" name="edittitle" defaultValue={props.notification.title || ''} maxLength={200} required></Input> 
                                <Label>{i18next.t('message')} SMS</Label>
                                <Mentions k={1} varList={props.varList}  defaultValue={props.datasms || ''} updateItem={props.updateItem} maxlength={'160'} msg_error={i18next.t('limit_allowed') + ' 160'}/>
                                <Label>{i18next.t('message')} Whatsapp / Facebook</Label>
                                <Mentions k={2} varList={props.varList}  defaultValue={props.datafb || ''} updateItem={props.updateItem} maxlength={'2000'} msg_error={i18next.t('limit_allowed') + ' 2000'}/>
                                <Label>{i18next.t('channel')}</Label> 
                                <Select value={props.selectedOption} onChange={props.handleChangeChannels} options={props.listChannels} isMulti={true} name="channel" />
                                <Label>{i18next.t('send_date')}</Label> 
                                <Datetime defaultValue={props.selectedDay} dateFormat={'YYYY-MM-DD'} timeFormat={'HH:mm:ss'} onChange = {props.handleChangeDay} inputProps={{ placeholder: 'YYYY-MM-DD HH:mm:ss', readOnly: true }} locale="es" />
                                <Label className="plusBtn">{i18next.t('template_email')} &nbsp;<a target="_blank" href="/Newtemplates" className="pull-right btn btn-success">{i18next.t('new_template')}</a></Label>
                                <Row>
                                    <Col className="col-10">
                                        <Input type="select" name="editplantillaEmail" id="editplantillaEmail" className="plantillaEmailBoxEdit">
                                            <option key={"template_id-0"} value='' style={{'display':'none'}}>- {i18next.t('select_option')}-</option>
                                            {sel_template}
                                        </Input>
                                    </Col>
                                    <Col className="col-2 pl-0">
                                    <Button outline color="success" onClick={ (e) => props.getlistTemplates()}>
                                        <FontAwesomeIcon icon={faSync} id='buttonRefreshE' style={{'fontSize':'18px'}} />
                                    </Button>
                                    <UncontrolledTooltip placement="bottom" target='buttonRefreshE'> {i18next.t('refresh_template_email_list')}</UncontrolledTooltip>
                                    </Col>
                                </Row> 
                                <Label>{i18next.t('subject')}</Label> 
                                <Input defaultValue={props.notification.subject} type="text" id="editsubject" name="editsubject" className="subjectEditBox" maxLength={500} /> 
                            </FormGroup> 
                            </Form>
                        </Col>
                        <Col  className="buttonModal">
                            <Col className="col-10 offset-1">
                                <Row>
                                    <Col className="col-6 offset-6"><Button className="btn-block btn-guarumo-modal-sm" type="submit" form="frm_notifications">{i18next.t('save')}</Button></Col>         
                                </Row>
                            </Col>
                        </Col>
                </ModalBody>
                 </Modal> 
            }
            
          </Admin>
                                    
           
            
    </div>
  )
}

export default NotificaionsLayout