var d3 = require('d3');
const createBarChart = (nameDiv,datos,formatoD3,group,botID,dateini,datefin,colorChosen, typeScaleColor,title,xAxisName,yAxisName,description) => {

    const node = document.createElement('div');

   // node.className = "svg-container";
    node.id="chart_"+nameDiv;
  
    var div='#'+nameDiv;
    var chart=document.getElementById('content-'+nameDiv);
  
    const WIDTH = chart.offsetWidth;
    const HEIGHT = chart.offsetHeight;
    const margin = { top: 40, right: 60, bottom: 40, left: 40 };
   
    const arriba = document.createElement('div');
    //node.className = "svg-container";
    arriba.id="arriba_"+nameDiv;
    arriba.className = "arriba";
  
    arriba.style.position='absolute'
    arriba.style.top = '0px';
    arriba.style.left='0px';
    arriba.style.width='100%';
    arriba.style.height='30px';
    arriba.style.backgroundColor ='white';
    arriba.style.paddingTop ='5px';
    arriba.style.paddingLeft ='10px';
  
      // ===========================================================================================
      //    Creates Title
      // ===========================================================================================
    
    node.appendChild(arriba);
  
    var title= d3.select(arriba)
      .append("text")
      .attr("class", "chartTitle")
      .style("font-size", '1.5rem')
      .style("color", 'gray')
      .style("font-weight", 'bold')
      .text(title);

/*
############# BAR CHART ###################
-------------------------------------------
*/
    
    // set initial group value
    //var group = "All";
    
    function datasetBarChosen(group,botID) {
        var ds = [];
        for (var x in datos.datasetBarChart) {
             if(datos.datasetBarChart[x].group==group){
                 if(datos.datasetBarChart[x].botID==botID)
                 {
                    ds.push(datos.datasetBarChart[x]);
                 }
                
             } 
            }
        return ds;
    }
    
    
  function dsBarChartBasics() {

         // set the dimensions and margins of the graph
   var  width =  WIDTH - margin.left - margin.right,
   height = height = HEIGHT - margin.top - margin.bottom;
    
    /* var margin = {top: 30, right: 5, bottom: 20, left: 100},
    width = 400 - margin.left - margin.right,
    height = 200 - margin.top - margin.bottom,*/
    var colorBar = d3.schemeCategory10,
    barPadding = 1.5
    ;

         
    if(typeScaleColor=="unicolor")
      colorBar=colorChosen;
    else if(typeScaleColor=="Categorica")
    {
      colorChosen="d3."+colorChosen;
      colorBar = d3.scaleOrdinal(eval(colorChosen));
    }
    else if(typeScaleColor=="predeterminado")
        {
            color = d3.scaleOrdinal().domain(datos.datasetBarChart).range(eval(colorChosen));
            colorBar=color(0);
           // color = d3.scaleOrdinal().domain(datos.dataSet).range(["rgba(46,91,255,1)", "rgba(140,84,255,1)", "rgba(0,193,212,1)", "rgba(250,208,80,1)", "rgba(195,34,168,1)"]);       
        }
    else 
    {
      colorChosen="d3."+colorChosen+"(t * 0.85)";
      const scale = t => eval(colorChosen);
      var color = (d) => {
        var c= d3.range(5).map(i => scale(i / 5))
        return c[d];
        };
        colorBar=color(0);
      //  color = d3.scaleSequential(eval(colorChosen));
    }
            
            return {
                margin : margin, 
                width : width, 
                height : height, 
                colorBar : colorBar, 
                barPadding : barPadding
            }			
            ;
    }
    
    function dsBarChart() {
    
        var firstDatasetBarChart = datasetBarChosen(group,botID);         	
        
        var basics = dsBarChartBasics();
        
        var margin = basics.margin,
            width = basics.width,
           height = basics.height,
            colorBar = basics.colorBar,
            barPadding = basics.barPadding
            ;
           
            const chartComponentSelection = d3.select("#chart_"+nameDiv);
            chartComponentSelection.attr(
                "style",
                "padding-bottom: " +
                  Math.ceil(
                    (height + margin.top + margin.bottom) *
                      100 /
                      (width + margin.left + margin.right)
                  ) +
                  "%"
              );
            
                             
        var 	xScale = d3.scaleBand()//.scaleOrdinal()//d3.scaleLinear()
                            .domain(firstDatasetBarChart.map(function(d) { return d.category; }))
                            //.domain([0, firstDatasetBarChart.length])
                            .range([margin.left, width-margin.right])
                            .padding(0.1)
                            ;
      
        var xScaleNew = xScale;

        var numbers=[];
    
    
    firstDatasetBarChart.forEach(data=>{      
        var d={measure:Number(data.measure)}
        numbers.push(d);

    })



        // Create linear y scale 
        // Purpose: No matter what the data is, the bar should fit into the svg area; bars should not
        // get higher than the svg height. Hence incoming data needs to be scaled to fit into the svg area.  
        var yScale = d3.scaleLinear()
                // use the max funtion to derive end point of the domain (max value of the dataset)
                // do not use the min value of the dataset as min of the domain as otherwise you will not see the first bar
               .domain([0, d3.max(numbers, function(d) { return d.measure; })])
               // As coordinates are always defined from the top left corner, the y position of the bar
               // is the svg height minus the data value. So you basically draw the bar starting from the top. 
               // To have the y position calculated by the range function
               .nice()
               .range([height-margin.bottom, margin.top])
               ;
        
   

        // create a tooltip
        var Tooltip = d3.select("#tooltip_"+nameDiv)//.select("svg")
            .style("opacity", 0)
            .attr("class", "tooltipLine")
            .style("background-color", "white")
            .style("opacity","0")
            .style("border", "solid")
            .style("border-width", "0.5px")
            .style("border-radius", "5px")
            .style("padding", "5px")
            .style("z-index","5000")

        // Three function that change the tooltip when user hover / move / leave a cell
        var mouseover = function(d) {
            Tooltip
            .style("opacity", 0.8)
            d3.select(this)
            .style("stroke", "black")
            .style("opacity", 0.8)
        }
        var mousemove = function(d) {


            var texto= "";
            texto= "<table>";
          //  var dateini=d.category.toLocaleDateString("es-ES",options);

            const diff = function(a, b) { return a.measure - b.measure; };

                texto=texto+
                "<tr>"+
                "<td style='padding:3px; 5px; bgcolor:"+colorBar(0)+"'><i class='fa fa-circle' style='color:"+colorBar+";'></i></td>"+
                //"<td style='padding:3px; 5px; font-weight: bold;'>"+datosTotales[j].name+"</td>"+
                "<td style='padding:3px; 5px;'>"+d.category+"</td>"+
                "<td style='padding:3px; 5px; font-weight: bold; color:"+colorBar+";'>"+d.measure+"</td>"+
                "</tr>"
                +"</table>";

            Tooltip
            .html(texto)
            //.html(title+"<br>"+d.category+"<br>Valor: " + d.measure/*""+d.category+"<br>Plataforma: "+d.group+"<br>Bot: "+d.botID+"<br>Valor: " + d.measure*/)
            .style("left", (d3.mouse(this)[0]+70) + "px")
            .style("top", (d3.mouse(this)[1]) + "px")
        }
        var mouseleave = function(d) {
            Tooltip
            .style("opacity", 0)
            d3.select(this)
            .style("stroke", "none")
            .style("opacity", 0.8)
        }

        //Create SVG element
        
        var svg = d3.select(node)
                .append("svg")
                //.attr("width", width + margin.left + margin.right)
               // .attr("height", height + margin.top + margin.bottom)
                .attr("id","svg_"+nameDiv)
                .attr("preserveAspectRatio", "xMinYMin meet")
                .attr(
                    "viewBox",
                    "0 0 "
                      .concat(width + margin.left + margin.right )
                      .concat(" ")
                      .concat(height + margin.top + margin.bottom )
                  )
                .classed("svg-content", true)
                ;
        
        var plot = svg
                .append("g")
                .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
                ;

                    // ==========================================================
               //  Axis
               // ==========================================================
               var xAxis = d3.axisBottom(xScale)
                            .ticks(10)
                            .tickSize(-(height))
                            .tickSizeOuter(0)

               var yAxis = d3.axisLeft(yScale)
                            .ticks(5)
                            .tickSize(-(width))
                            .tickSizeOuter(0);

                          
                     // Add y labels to plot	
        // add the X and Y gridlines
        var grid=   plot.append("g")			
        .attr("class", "grid")
        .attr("id","grid_"+nameDiv)

        const xAxisDraw = grid.append("g")
        .attr("id","xaxis_"+nameDiv)
        .attr("className", "x axis")
        .attr("transform", `translate(0, ${height})`)
        .call(xAxis)
        .selectAll("text")
        .style("text-anchor", "end")
      .attr("dx", "2.5em")
      .attr("dy", "-2.5em")
      .attr("transform", "rotate(-45)" )
        //.text(function(d) { return d.category;})
      /*  .attr("text-anchor", "end")
        .attr("transform", function(d,i) {
                var x = (i * (width / firstDatasetBarChart.length)) + ((width / firstDatasetBarChart.length - barPadding) / 2);
                return "translate(" + x + "," + -35 + ")rotate(-45)";
            })*/

                    
     plot.selectAll("rect")
     .data(firstDatasetBarChart)
     .enter()
     .append("rect")
     .attr("id","rect_"+nameDiv)
     .attr("x", d => xScale(d.category))
      .attr("y", d => yScale(d.measure))
      .attr("height", d => yScale(0) - yScale(d.measure))
      .attr("width", xScale.bandwidth())
    //   .attr("x", function(d, i) {
    //       return xScale(d.category);
    //   })
    //   .attr("width", xScale.bandwidth())
    // // .attr("width", width / firstDatasetBarChart.length - barPadding)   
    //   .attr("y", function(d) {
    //       return yScale(d.measure);
    //   })  
    //   .attr("height", function(d) {
        
    //     var h=height-yScale(d.measure)-20;
    //     if(h<0)
    //       h=0;
    //     return h;
    //   })
      .attr("fill", colorBar)
      .style("opacity", 0.8)
      .on("mouseover", mouseover)
      .on("mousemove", mousemove)
      .on("mouseleave", mouseleave)
     /* .merge(plot)
      .transition()
      //.ease(d3.easeLinear)
      .duration(1000)
      //.delay(1500)*/
      ;

      plot.selectAll("text")
      .data(firstDatasetBarChart)
      .enter()
      .append("text")
      .text(function(d) {
            return formatoD3.formatAsInteger(Math.round(d.measure));
      })
      .attr("text-anchor", "middle")
      .attr("x", d => xScale(d.category))
      .attr("y", d => yScale(d.measure))
      .attr("height", d => yScale(0) - yScale(d.measure))
      .attr("width", xScale.bandwidth());

      // Set x position to the left edge of each bar plus half the bar width
   /*   .attr("x", function(d, i) {
            return (i * (width / firstDatasetBarChart.length)) + ((width / firstDatasetBarChart.length - barPadding) / 2);
      })
      .attr("y", function(d) {
            return yScale(d.measure) + 14;
      })*/

      const yAxisDraw = grid.append("g")
      .attr("className", "y axis")
      .attr("id","yaxis_"+nameDiv)
      .call(yAxis)
      .append('text')
      .attr("y", 15)
      .attr("transform", "rotate(-90)")
      .attr("fill", "#000")
      .text(" "+yAxisName);

/*

var xLabels = svg
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + (margin.top + height)  + ")")
      ;

xLabels.selectAll("text.xAxis")
    .data(firstDatasetBarChart)
    .enter()
    .append("text")
    .text(function(d) { return d.category;})
    .attr("text-anchor", "end")
      // Set x position to the left edge of each bar plus half the bar width
 /*     .attr("x", function(d, i) {
              return (i * (width / firstDatasetBarChart.length)) + ((width / firstDatasetBarChart.length - barPadding) / 2);
      })
    .attr("y", 15)* /
    .attr("transform", function(d,i) {
            var x = (i * (width / firstDatasetBarChart.length)) + ((width / firstDatasetBarChart.length - barPadding) / 2);
            return "translate(" + x + "," + -35 + ")rotate(-45)";
        })
    .attr("class", "xAxis");
  */
   

             
                // Zoom specific updates
                //************************************************************
                const extent = [[margin.left, margin.top], [width - margin.right, height - margin.top]];

                var zoom = d3.zoom()
                    .scaleExtent([1, 8])
                    .translateExtent(extent)
                    .extent(extent)
                    .on('zoom', zoomed);

/*              

                svg.call(d3.zoom()
                    .scaleExtent([1, 8])
                    .translateExtent(extent)
                    .extent(extent)
                    .on("zoom", zoomed));*/
              
                svg.call(zoom);


                function zoomed() {
                  if(d3.event){
                    var transform = d3.event.transform;

                //    transform.x = Math.min(0, transform.x);
                //    transform.y = 0;

             //       d3.selectAll(div).select("svg").select("g").selectAll("#rect_"+nameDiv)
              //      .attr("transform", "translate(" + transform.x+","+ transform.y+ ")scale(" + d3.event.transform.k + ")");
                    
                 //   d3.selectAll('.line').style("stroke-width", 2/d3.event.transform.k);

                 //   lineStroke = 2/d3.event.transform.k;
                 //   lineStrokeHover = 2/d3.event.transform.k;

                 //   circleRadius = 4/d3.event.transform.k;
                  //  circleRadiusHover =5/d3.event.transform.k;

                    

                  xScale.range([margin.left, width - margin.right].map(d => d3.event.transform.applyX(d)));
                 var c= d3.selectAll("#rect_"+nameDiv).attr("x", d => xScale(d.category)).attr("width", xScale.bandwidth());
                 var d=d3.selectAll("#xaxis_"+nameDiv).call(xAxis);
              //   console.log(d);

          

                  }
                  }


              //.attr("style", "font-size: 12; font-family: Helvetica, sans-serif")
              		
         
            //  var  xLabelsAxis=xLabels.selectAll("text")  // select all the text elements for the xaxis
            //  .attr("transform", function(d,i) {
            //      var x = (i * (width / firstDatasetBarChart.length)) + ((width / firstDatasetBarChart.length - barPadding) / 2);
            //      return "translate(" + x + "," + height + ")rotate(-45)";
            //  });
           
           

    }
    
    dsBarChart();


        return node;
        
        }
        
        export default createBarChart;