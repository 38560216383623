import React,{useState} from 'react';
import { 
    Button, 
    Modal, 
    ModalHeader, 
    ModalBody, 
    ModalFooter,
    Form, 
    FormGroup, 
    Label, 
    Input, 
    Col,
    Row,
} from 'reactstrap';
import '../../admin/components/toolbar.css';
import '../../admin/components/modal-nuevo-bot.css'
import i18next from '../../../../i18n';


function ModalNuevoSchema(props){
    console.log("props ",props.dataView[0]);
    let idschema = '';
    if(props.dataView[0]){
        idschema = JSON.parse(props.dataView[0])
        console.log(idschema.idschema);
    }
    const [isChecked, setIsChecked] = useState(false);
    const [archivoBot, setArchivoBot] = useState(null);

      /*AGREGADO EL DIA 13 DE MARZON* */
        const [fileName, setFileName] = useState(''); // Estado para almacenar el nombre del archivo seleccionado
      /*FIN* */
  
    const subirArchivo = (e) =>{
        const file = e[0];
            if (file) {
                setFileName(file.name);
            }
        setArchivoBot(e);
    }
  
    const insertarArchivo = () => {
        props.getLoadSchema({archivoBot:archivoBot,idschema:idschema.idschema})
    }
  
    const handleOnChange = () => {
      setIsChecked(!isChecked);
    };

    return (
        <div>
            <Modal isOpen={props.isOpen} toggle={props.toggleModal} className="modal-lg" fade>
                <ModalHeader toggle={props.toggleModal} charCode="X">{i18next.t('new_flow')}</ModalHeader>
                <Form id="form-createSchema" onSubmit={props.saveSchema} role="form">
                <ModalBody>

                                            
                <FormGroup>
                            <Row className="mb-3">
                                <Col sm="2" >
                                </Col>
                                <Col className="d-flex justify-content-start">
                                <FormGroup check>
                                    <Input
                                        name="radio1"
                                        type="radio"
                                        checked={!isChecked}
                                        onChange={handleOnChange}
                                    />
                                    {' '}
    

                                        <Label check className="ml-2 mr-4">
                                        {i18next.t('create_schema')+': '}
                                        </Label>
                                    </FormGroup>
                                    {/* {<FormGroup check>
                                    <Input
                                        name="radio2"
                                        type="radio"
                                        checked={isChecked}
                                        onChange={handleOnChange}
                                    />
                                    {' '}
                                        <Label check className="ml-2">
                                            {i18next.t('import_schema')+': '}
                                        </Label>
                                    </FormGroup>} */}
                                </Col>
                            </Row>
                            {
                                !isChecked?'':(<Row className="mb-3">
                                <Col sm="2">
                                    <Label 
                                           className="mt-4 btn btn-outline-secondary"
                                           style={{cursor: "pointer"}} 
                                           for="exampleFile">
                                    {i18next.t('file_selected')} {' '} {i18next.t('upload_file')}
                                    </Label>
                                </Col>
                                <Col sm="10">
                                    <Input
                                    id="exampleFile"
                                    name="file"
                                    type="file"
                                    label={i18next.t('upload_file')}
                                    style={{ display: 'none' }}
                                    onChange={(e) => subirArchivo(e.target.files)}
                                    />
                                    {fileName && <span style={{color: '#000000', marginLeft: '8px' }}>{fileName}</span>}
                                </Col>
                                </Row>)
                            }
                        </FormGroup>

                        {!isChecked?(<FormGroup>
                            <Row>
                                <Col sm="2">
                                    <Label for="title" className="control-label required-field">{i18next.t('title')+': '}</Label>
                                </Col>
                                <Col sm="10">
                                    <Input type="text" className="inputModal" id="title" name="title" maxLength="30" required></Input>
                                </Col>
                            </Row>
                        </FormGroup>):''}

                </ModalBody>
                <ModalFooter>
                    <Button onClick={props.toggleModal} outline color="secondary">{i18next.t('cancel')}</Button>
                    {/*<Button type="submit" size="lg" className="btn-guarumo-modal">Crear</Button>*/}
                    {!isChecked?(<Button type="submit" size="lg" className="btn-guarumo-modal">{i18next.t('create')}</Button>):
                        (
                        <Button type="button" onClick={ () => insertarArchivo() } size="lg" className="btn-guarumo-modal">
                        {i18next.t('import')}
                        </Button>
                        )
                    }
                </ModalFooter>
                </Form>
            </Modal>
        </div>
    );
}
  
export default ModalNuevoSchema