import { AbstractNodeFactory } from "storm-react-diagrams";
import React from "react";

import WVNodeWidget from "./WVNodeWidget";
import WVNodeModel from "./WVNodeModel";

class WVNodeFactory extends AbstractNodeFactory {
	constructor() {
		super("wv");
	}

	generateReactWidget(diagramEngine, node) {
		return <WVNodeWidget node={node} />
	}

	getNewInstance() {
		return new WVNodeModel();
	}
}

export default WVNodeFactory