import React, {Component} from 'react';
import { Button,Modal,ModalHeader,ModalBody,ModalFooter,Form,FormGroup,Table,Col,Row,Nav,NavItem,TabContent,TabPane,NavLink} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faLink } from '@fortawesome/free-solid-svg-icons'
import IASelector from '../../../../components/ia';
import CMSSelector from '../../../../components/cms'
import Keyword from '../../../../components/keyword/keyword';
import Timeout from '../../../../components/timeout/timeout';
import TitleOperator from "../../../../components/title-operator";
import Mentions from '../../../../components/mentions/mentions';
import Avatar from '../../../../components/avatar';
import Audio from '../../../../components/audio-input';
import Emoji from '../../../../components/emoji-picker';
import ModalUrl from '../../../../components/modals/shorturl/modal-shorturl';
import {Link} from 'react-router-dom';
import classnames from 'classnames';
import TabAdvanced from "../../../../components/box/tab_advanced";
import i18next from '../../../../../i18n';



class TMMLayout extends Component {

    constructor(props){
        super(props)
        this.state = {
        activeTab: '1'
        };
        this.toggleTab = this.toggleTab.bind(this);
    }

    inputEnunciado = (bls,kblocks) => {
        let block = kblocks.map((k, index) => {
            return <tr key={k}>
                <td className={ this.props.visible == 1 ? 'visibilityInput':'td-flujo' } style={{'height':'70px', 'zIndex':100-index}}>
                    <Mentions 
                        className={ this.props.visible == 1 ? 'visibilityInput':'td-flujo' }
                        varList={this.props.varList}
                        k={k}
                        defaultValue={bls[k].text}
                        updateItem={this.props.updateItem}/>
                    <div className="bar-emoji-input">                        
                        <Button color="info" size="lg" onClick={() => this.props.removeTextBlock(k) }>
                            <FontAwesomeIcon icon={faTrash} />
                        </Button>
                        <Button color="info" size="lg" onClick={() => this.props.toggleModalUrl(k) }>
                            <FontAwesomeIcon icon={faLink} />
                        </Button>
                        <Emoji id={k} callEmoji={this.props.callEmoji} type="item" style={{'display':'none',position:'fixed',marginLeft: 60+'%',marginTop:31+'px',zIndex: 100}}/>
                   
                        
                    </div>
                </td>
                <td className={ this.props.visible == 1 ? 'visibilityBlockInput':'visibilityInput' } >
                    <p>
                        <Link style={{'color':'#494949'}} className="text-reset text-decoration-none" target="_blank" to={'/cms/'+this.props.botId+'?operador='+this.props.operator}>
                        {i18next.t('set_up')} CMS
                        </Link>
                    </p>
                </td>
                <td className={ this.props.visible == 1 ? 'visibilityBlockInput':'visibilityInput' } style={{'width':'8%'}}>
                    <Button color="info" size="lg" onClick={() => this.props.removeTextBlock(k) }>
                        <FontAwesomeIcon icon={faTrash} />
                    </Button>
                </td>
            </tr>
        });
        return block;
    }
    
    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
          this.setState({
            activeTab: tab
          });
        }
    }

    render(){
        let bls = this.props.text_blocks || {};
        let kblocks = Object.keys(bls);
        let blocks = this.inputEnunciado(bls,kblocks)

        return (
            <React.Fragment>
                <Modal className="singlemessage" isOpen={this.props.isOpen} toggle={this.props.closeModal} fade size="lg">
                    <ModalHeader toggle={this.props.closeModal}>{i18next.t('configure_plane_text')}</ModalHeader>
                    <ModalBody>
                        <Form id="TM-form" role="form">  
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                    className={classnames({ active: this.state.activeTab === '1' })}
                                    onClick={() => { this.toggleTab('1'); }} >
                                    {i18next.t('basic')}
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                    className={classnames({ active: this.state.activeTab === '2' })}
                                    onClick={() => { this.toggleTab('2'); }}>
                                    {i18next.t('advanced')}
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={this.state.activeTab}>
                                <TabPane tabId="1">
                                    <Row>
                                    <Col sm="12">
                                        <FormGroup>
                                            <TitleOperator titleOperator={this.props.titleOperator} bindValue={this.props.getTitleOperator}/>
                                            <Table style={{'textAlign':'left'}}>
                                                <thead>
                                                <tr>
                                                    <th colSpan="2">{i18next.t('statements')}</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                { blocks }
                                                </tbody>
                                            </Table> 
                                            <Col sm="12" className="button-add">
                                                { this.props.error_limit_item && 
                                                    <Col sm="12">
                                                        <span className="warning-character">{i18next.t('allowed_box')} 5</span>
                                                    </Col>
                                                }
                                                {
                                                    this.props.error_empty_item && 
                                                    <Col sm="12">
                                                        <span className="warning-character">{i18next.t('message36')}</span>
                                                    </Col>
                                                }
                                                <Col sm="12">
                                                    <Button color="info" className="btn-guarumo-modal-blue-sm" onClick={this.props.addNewTextBlock}>{i18next.t('add_new')}</Button>
                                                </Col>
                                            </Col>  
                                        </FormGroup>
                                    </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId="2">
                                <Row>
                                    <Col sm="6">
                                        <IASelector botversion_id={this.props.botversion_id} operator_id={this.props.operator_id} ia={this.props.ia} getIAId={this.props.getIAId} />
                                        <CMSSelector bindValue={this.props.getOperatorVisible} bot_id={this.props.botId} operator={this.props.visible} filter={"text"}/>
                                        <Avatar company_id={this.props.company_id} avatarid={this.props.avatarid} bindValue={this.props.getAvatarId} />
                                        {/* <Audio operator_id={this.props.operator_id} audio={this.props.audio} bindValue={this.props.getAudio}/> */}
                                    </Col>
                                    <Col sm="6">
                                        <Row>
                                            <Col sm="12" md="12" className="keyword">
                                                <Timeout bindValue={this.props.getTimeout} bindMsg={this.props.getMsgTimeout} bindValid={this.props.isTimeoutValid} defaultValue={this.props.timeoutValue} defaultMsg={this.props.timeoutMsg} />
                                                <Keyword keyword={this.props.keyword} isValid={this.props.isKeywordValid} updateKeyword={this.props.updateKeyword}/>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </TabPane>
                            </TabContent>
                        </Form>
                    </ModalBody>
                    <ModalFooter>  
                            <Col sm="12" align="right">
                                <Button color="success" className="btn-guarumo-modal-sm" type="button" onClick={this.props.toggleModuleModal} >{i18next.t('finish')}</Button>
                            </Col>
                    </ModalFooter>
                </Modal>
                <ModalUrl isOpen={this.props.isOpenUrl} toggleModal={this.props.toggleModalUrl} bindValue={this.props.getUrl} input={this.props.inputStatement}/>
            </React.Fragment>
        );
    }
}
  
export default TMMLayout
