import React, { Component } from "react";
import { Col }  from 'reactstrap';
import {Pie} from "react-chartjs-2";

export class Circulo extends Component {
  

    render() {
      const options = {
        maintainAspectRatio:false,
        legend: {
            display: true,
            position:'bottom',
            labels: {
                fontColor: '#5a5a5a',
                fontSize:14
            }
        },
        scales: {
            yAxes: [{
                display: false, //elimina el plano
            }]
        }
      };
      return (
        <Col>
          <Pie
                data={this.props.data}
                width={100}
                height={400}
                options={options}
            />
        </Col>
      );
    }
  }
  
  export default Circulo;