const createDataPSChart = (group,bot,category) => {

    
const data = {}

var dataT = {}

data.config = 
 {
    containerId: "chartContainer",
    values: [{
            group: "All", bot: "All", category: 2008,
            data: [
                {value: 25,label: "Nuevos",inner: [
                                                        {value: 15,label: "Comprometidos"}, 
                                                        {value: 10,label: "No Comprometidos"}
                                                    ]}, 
                {value: 50,label: "Recurrentes",inner: [
                                                            {value: 20,label: "Comprometidos"}, 
                                                            {value: 30,label: "No Comprometidos"}
                                                        ]}
                    ]
                },
                {
                    group: "Facebook", bot: "All", category: 2008,
                    data: [
                        {value: 8,label: "Nuevos",inner: [
                                                                {value: 5,label: "Comprometidos"}, 
                                                                {value: 3,label: "No Comprometidos"}
                                                            ]}, 
                        {value: 10,label: "Recurrentes",inner: [
                                                                    {value: 2,label: "Comprometidos"}, 
                                                                    {value: 8,label: "No Comprometidos"}
                                                                ]}
                            ]
                },{
                    group: "Whatsapp", bot: "All", category: 2008,
                    data: [
                        {value: 35,label: "Nuevos",inner: [
                                                                {value: 20,label: "Comprometidos"}, 
                                                                {value: 15,label: "No Comprometidos"}
                                                            ]}, 
                        {value: 20,label: "Recurrentes",inner: [
                                                                    {value: 12,label: "Comprometidos"}, 
                                                                    {value: 8,label: "No Comprometidos"}
                                                                ]}
                            ]
                        },
                        {
                            group: "Web", bot: "All", category: 2008,
                            data: [
                                {value: 35,label: "Nuevos",inner: [
                                                                        {value: 12,label: "Comprometidos"}, 
                                                                        {value: 23,label: "No Comprometidos"}
                                                                    ]}, 
                                {value: 43,label: "Recurrentes",inner: [
                                                                            {value: 23,label: "Comprometidos"}, 
                                                                            {value: 20,label: "No Comprometidos"}
                                                                        ]}
                                    ]
                                }
            ]
};
dataT=data;
data.config.values.forEach(function(d, index) {

    if(d.group==group)
    {
        d.containerId="chartContainer";
       dataT=d;
        return dataT;
    }
  });
  return dataT;
}
export default createDataPSChart;