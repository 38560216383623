import {AbstractNodeFactory} from "storm-react-diagrams";
import React from "react";

import SMSWidget from "./SMSWidget";
import SMSModel from "./SMSModel";

class SMSFactory extends AbstractNodeFactory {
	constructor() {
		super("sms");
	}

	generateReactWidget(diagramEngine, node) {
		return <SMSWidget node={node} />
	}

	getNewInstance() {
		return new SMSModel();
	}
}

export default SMSFactory