import React, {Component} from 'react';
import EmojiPicker from 'emoji-picker-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faGrinWink } from '@fortawesome/free-solid-svg-icons'
import './custom-emoji.css';
import 'emoji-picker-react/dist/universal/style.scss';
import { Col,Button
} from 'reactstrap';

class MyComponent extends Component {

    constructor(props){
        super(props)
        this.emojiCallback.bind(this);
    }

    emojiCallback = (code) => {
        let k = this.props.id
        let type = this.props.type
        const emoji = String.fromCodePoint(`0x${code}`)
        this.props.callEmoji(emoji,k,type) 
    }

    isOpen = (k) =>{
        if(document.getElementById('emoji'+k).style.display == "block")
            document.getElementById('emoji'+k).style.display='none'
        else
            document.getElementById('emoji'+k).style.display='block'
    } 


    render() {
        let k = this.props.id;
        return (
            <React.Fragment >
                <Button className="btnEmoji" color="info" size={"lg"} onClick={() => this.isOpen(k) }>
                    <FontAwesomeIcon icon={faGrinWink} />
                </Button>
                <Col id={'emoji'+k} style={{'display':'none'}} className="boxEmojiPicker">
                    <EmojiPicker onEmojiClick={this.emojiCallback} disableDiversityPicker/>
                </Col>
            </React.Fragment>
        );
    }
}

export default  MyComponent;