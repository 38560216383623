// Actions
export const COMPANY_ACTIONS = {
    getAllCompanies: () => 'GET_ALL_CoMPANIES',
    handleOnchageInputs: () => 'HANDLE_ONCHANGE_INPUTS',
    deleteCompany: () => 'DELETE_COMPANY',
    getUsersByCompany: () => 'GET_USERS_BY_COMPANY',
    getAvatarsByCompany: () => 'GET_AVATARS_BY_COMPANY',
};
  

