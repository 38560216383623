import React, { Component } from 'react';
import { 
    Button, 
    Modal, 
    ModalHeader, 
    ModalBody, 
    ModalFooter,
    Form, 
    FormGroup, 
    Label, 
    Input, 
    Col,
    Row,
} from 'reactstrap';
import '../../admin/components/modal-nuevo-bot.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import Mentions from '../../../components/mentions/mentions-header';
const utf8 = require('utf8');
import i18next from '../../../../i18n';

class ModalCustomURL extends Component {
    state = {
        input:this.props.input,
        url:'',
        variables:[]
    }

    constructor(props){
        super(props);
    }

    saveVariable = (event) => {
        event.preventDefault();
        let form = new FormData(event.target);
        let temp = {
            name: form.get('nameVariable'),
            value:this.props.dataurl,
        }
        var joined = this.state.variables.concat(temp);
        this.setState({variables:joined},()=>{
            this.props.clean();
            document.getElementById("frm_variables").reset();
        })
    }
    deleteOption = (index) =>{
        let list = this.state.variables
        list.splice(index, 1);
        this.setState({list})
    }

    generarUrl = () => {
       try {
        let url = document.getElementById('url').value;
        let dominio = new URL('',url)
        url = dominio.origin +dominio.pathname
        let list = this.state.variables;
        list.map((item,index)=>{
            if (index == 0)
                url += '?'+item.name.trim()+'='+item.value.trim()
            else
                url += '&'+item.name+'='+item.value.trim()
        })
        //this.props.ConcatItem(encodeURI(url))
        this.props.ConcatItem(url)
        this.props.toggleModal();
        this.setState({variables:[]})
       } catch (error) {
           alert('Hubo un error con la URL, ¡Intenta nuevamente!')
       }
    }

    variables = (list) =>{
        var lista = list.map((e,index)=>{
            return <tr key={index}>
            <td>{e.name}</td>
            <td>{(e.value)}</td>
            <td style={{'width':'30px'}}>
                <Button color="success" onClick={()=>{this.deleteOption(index)}}><FontAwesomeIcon icon={faTrash} /></Button>
            </td>
            </tr>
        })
        return lista
    }

    render() {
        let listVariables = this.variables(this.state.variables)
        return (
            <div>
                <Modal isOpen={this.props.isOpen} toggle={this.props.toggleModal} className="modal-lg" fade>
                    <ModalHeader toggle={this.props.toggleModal} charCode="X">Personalizar URL</ModalHeader>
                    <ModalBody>
                            <FormGroup>
                                <Row>
                                    <Col sm="2">
                                        <Label for="title" className="control-label required-field">URL base:</Label>
                                    </Col>
                                    <Col sm="10">
                                        <Input type="text" className="inputModal" id="url" name="url" required></Input>
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Form id="frm_variables" onSubmit={this.saveVariable}>
                                    <Row>
                                        <Col md={5}>
                                            <Label for="title" className="control-label required-field">{i18next.t('name')}</Label>
                                            <Input type="text" className="inputModalVariable" id="nameVariable" name="nameVariable" required />
                                        </Col>
                                        <Col md={5}>
                                            <Label for="title" className="control-label required-field">Valor</Label>
                                            <Mentions 
                                            varList={this.props.varList}
                                            k={3}
                                            defaultValue={(this.props.dataurl)}
                                            updateItem={this.props.updateItem}/>
                                        </Col>
                                        <Col md={2}>
                                            <Button color="success" className="mt-5" type="submit"><FontAwesomeIcon icon={faPlus} /></Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </FormGroup>
                            <Col md={12}>
                            <h4>Variables</h4>
                            <table className="col-12 table table-sm text-center">
                                <thead>
                                    <tr>
                                        <th>
                                        {i18next.t('name')}
                                        </th>
                                        <th>Valor</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {listVariables}
                                </tbody>
                            </table>
                            </Col>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={this.props.toggleModal} outline color="secondary">{i18next.t('cancel')}</Button>
                        <Button size="lg" className="btn-guarumo-modal" onClick={()=>{this.generarUrl()}}>{i18next.t('generate')}</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}
  
export default ModalCustomURL