const createDataSquareText = (datos,formatoD3) => {

const data = {}

var dataT = {}

data.dataset = [
  {

    name: "Estadística 1",
    title: "Duración Promedio Sesión",
    icon: "https://image.flaticon.com/icons/svg/784/784662.svg",
    values: [
        { group: "All", bot: "All", date: 2008, measure: 28 }, 
        { group: "All", bot: "All", date: 2009, measure: 23 }, 
        { group: "All", bot: "All", date: 2010, measure: 31 }, 
        { group: "All", bot: "All", date: 2011, measure: 22 }, 
        { group: "All", bot: "All", date: 2012, measure: 24 }, 
        { group: "All", bot: "Bot 1", date: 2008, measure: 28 }, 
        { group: "All", bot: "Bot 2", date: 2008, measure: 23 }, 
        { group: "All", bot: "Bot 3", date: 2008, measure: 31 }, 
        { group: "All", bot: "Bot 4", date: 2008, measure: 31 }, 
        { group: "All", bot: "Bot 1", date: 2009, measure: 2 }, 
        { group: "All", bot: "Bot 2", date: 2009, measure: 3 }, 
        { group: "All", bot: "Bot 3", date: 2009, measure: 1 }, 
        { group: "All", bot: "Bot 1", date: 2010, measure: 8 }, 
        { group: "All", bot: "Bot 2", date: 2010, measure: 2 }, 
        { group: "All", bot: "Bot 3", date: 2010, measure: 3 }, 
        { group: "All", bot: "Bot 1", date: 2011, measure: 2 }, 
        { group: "All", bot: "Bot 2", date: 2011, measure: 2 }, 
        { group: "All", bot: "Bot 3", date: 2011, measure: 11 }, 
        { group: "All", bot: "Bot 1", date: 2012, measure: 18 }, 
        { group: "All", bot: "Bot 2", date: 2012, measure: 13 }, 
        { group: "All", bot: "Bot 3", date: 2012, measure: 11 }, 
        { group: "Whatsapp", bot: "All", date: 2008, measure: 5 }, 
        { group: "Web", bot: "Bot 2", date: 2008, measure: 2 }, 
        { group: "Facebook", bot: "Bot 3", date: 2008, measure: 1 }, 
        { group: "All", bot: "Bot 4", date: 2008, measure: 133 }, 
        { group: "Web", bot: "Bot 2", date: 2009, measure: 2 }, 
        { group: "Facebook", bot: "Bot 3", date: 2009, measure: 1 }, 
        { group: "Web", bot: "Bot 2", date: 2010, measure: 2 }, 
        { group: "Facebook", bot: "Bot 3", date: 2010, measure: 1 }, 
        { group: "Whatsapp", bot: "Bot 1", date: 2011, measure: 2 }, 
        { group: "Web", bot: "Bot 2", date: 2011, measure: 2 }, 
        { group: "Facebook", bot: "Bot 3", date: 2011, measure: 1 }, 
        { group: "Whatsapp", bot: "Bot 1", date: 2012, measure: 2 }, 
        { group: "Web", bot: "Bot 2", date: 2012, measure: 2 }, 
        { group: "Web", bot: "All", date: 2008, measure: 51 }, 
        { group: "Facebook", bot: "Bot 3", date: 2012, measure: 1 }, 
        { group: "Whatsapp", bot: "All", date: 2009, measure: 4 }, 
        { group: "Whatsapp", bot: "All", date: 2010, measure: 3 }, 
        { group: "Whatsapp", bot: "All", date: 2011, measure: 2 }, 
        { group: "Whatsapp", bot: "All", date: 2012, measure: 1 }, 
        { group: "Facebook", bot: "All", date: 2008, measure: 25}, 
        { group: "Facebook", bot: "Bot 1", date: 2008, measure: 12 }, 
        { group: "Facebook", bot: "Bot 1", date: 2009, measure: 2 }, 
        { group: "Facebook", bot: "Bot 1", date: 2010, measure: 22 }, 
        { group: "Facebook", bot: "Bot 3", date: 2008, measure: 10 }, 
        { group: "Facebook", bot: "Bot 4", date: 2008, measure: 10 }, 
        { group: "Facebook", bot: "All", date: 2009, measure: 2 }, 
        { group: "Facebook", bot: "All", date: 2010, measure: 5 }, 
        { group: "Facebook", bot: "All", date: 2011, measure: 20 }, 
        { group: "Facebook", bot: "All", date: 2012, measure: 51 }
    ]
  },
  {
    name: "Estadística 2",
    title: "Sesión más larga",
    icon: "https://image.flaticon.com/icons/svg/149/149234.svg",
    values: [
        { group: "All", bot: "All", date: 2008, measure: 28 }, 
        { group: "All", bot: "All", date: 2009, measure: 23 }, 
        { group: "All", bot: "All", date: 2010, measure: 31 }, 
        { group: "All", bot: "All", date: 2011, measure: 22 }, 
        { group: "All", bot: "All", date: 2012, measure: 24 }, 
        { group: "All", bot: "Bot 1", date: 2008, measure: 28 }, 
        { group: "All", bot: "Bot 2", date: 2008, measure: 23 }, 
        { group: "All", bot: "Bot 3", date: 2008, measure: 31 }, 
        { group: "All", bot: "Bot 4", date: 2008, measure: 31 }, 
        { group: "All", bot: "Bot 1", date: 2009, measure: 2 }, 
        { group: "All", bot: "Bot 2", date: 2009, measure: 3 }, 
        { group: "All", bot: "Bot 3", date: 2009, measure: 1 }, 
        { group: "All", bot: "Bot 1", date: 2010, measure: 8 }, 
        { group: "All", bot: "Bot 2", date: 2010, measure: 2 }, 
        { group: "All", bot: "Bot 3", date: 2010, measure: 3 }, 
        { group: "All", bot: "Bot 1", date: 2011, measure: 2 }, 
        { group: "All", bot: "Bot 2", date: 2011, measure: 2 }, 
        { group: "All", bot: "Bot 3", date: 2011, measure: 11 }, 
        { group: "All", bot: "Bot 1", date: 2012, measure: 18 }, 
        { group: "All", bot: "Bot 2", date: 2012, measure: 13 }, 
        { group: "All", bot: "Bot 3", date: 2012, measure: 11 }, 
        { group: "Whatsapp", bot: "All", date: 2008, measure: 5 }, 
        { group: "Web", bot: "Bot 2", date: 2008, measure: 2 }, 
        { group: "Facebook", bot: "Bot 3", date: 2008, measure: 1 }, 
        { group: "Facebook", bot: "Bot 4", date: 2008, measure: 10 }, 
        { group: "Web", bot: "Bot 2", date: 2009, measure: 2 }, 
        { group: "Facebook", bot: "Bot 3", date: 2009, measure: 1 }, 
        { group: "Web", bot: "Bot 2", date: 2010, measure: 2 }, 
        { group: "Facebook", bot: "Bot 3", date: 2010, measure: 1 }, 
        { group: "Whatsapp", bot: "Bot 1", date: 2011, measure: 2 }, 
        { group: "Web", bot: "Bot 2", date: 2011, measure: 2 }, 
        { group: "Facebook", bot: "Bot 3", date: 2011, measure: 1 }, 
        { group: "Whatsapp", bot: "Bot 1", date: 2012, measure: 2 }, 
        { group: "Web", bot: "Bot 2", date: 2012, measure: 2 }, 
        { group: "Facebook", bot: "Bot 3", date: 2012, measure: 1 }, 
        { group: "Whatsapp", bot: "All", date: 2009, measure: 4 }, 
        { group: "Whatsapp", bot: "All", date: 2010, measure: 3 }, 
        { group: "Whatsapp", bot: "All", date: 2011, measure: 2 }, 
        { group: "Whatsapp", bot: "All", date: 2012, measure: 1 }, 
        { group: "Facebook", bot: "All", date: 2008, measure: 25}, 
        { group: "Facebook", bot: "Bot 1", date: 2008, measure: 12 }, 
        { group: "Facebook", bot: "Bot 1", date: 2009, measure: 2 }, 
        { group: "Facebook", bot: "Bot 1", date: 2010, measure: 22 }, 
        { group: "Facebook", bot: "Bot 3", date: 2008, measure: 10 }, 
        { group: "Facebook", bot: "All", date: 2009, measure: 2 }, 
        { group: "Facebook", bot: "All", date: 2010, measure: 5 }, 
        { group: "Facebook", bot: "All", date: 2011, measure: 20 }, 
        { group: "Web", bot: "All", date: 2008, measure: 25 }, 
        { group: "Facebook", bot: "All", date: 2012, measure: 51 }
    ]
  },
  {
    name: "Estadística 3",
    title:"Tiempo promedio con Agente",
    icon: "https://image.flaticon.com/icons/svg/950/950299.svg",
    values: [
        { group: "All", bot: "All", date: 2008, measure: 28 }, 
        { group: "All", bot: "All", date: 2009, measure: 23 }, 
        { group: "All", bot: "All", date: 2010, measure: 31 }, 
        { group: "All", bot: "All", date: 2011, measure: 22 }, 
        { group: "All", bot: "All", date: 2012, measure: 24 }, 
        { group: "All", bot: "Bot 1", date: 2008, measure: 28 }, 
        { group: "All", bot: "Bot 2", date: 2008, measure: 23 }, 
        { group: "All", bot: "Bot 3", date: 2008, measure: 31 }, 
        { group: "All", bot: "Bot 4", date: 2008, measure: 31 }, 
        { group: "All", bot: "Bot 1", date: 2009, measure: 2 }, 
        { group: "All", bot: "Bot 2", date: 2009, measure: 3 }, 
        { group: "All", bot: "Bot 3", date: 2009, measure: 1 }, 
        { group: "All", bot: "Bot 1", date: 2010, measure: 8 }, 
        { group: "All", bot: "Bot 2", date: 2010, measure: 2 }, 
        { group: "All", bot: "Bot 3", date: 2010, measure: 3 }, 
        { group: "All", bot: "Bot 1", date: 2011, measure: 2 }, 
        { group: "All", bot: "Bot 2", date: 2011, measure: 2 }, 
        { group: "All", bot: "Bot 3", date: 2011, measure: 11 }, 
        { group: "All", bot: "Bot 1", date: 2012, measure: 18 }, 
        { group: "All", bot: "Bot 2", date: 2012, measure: 13 }, 
        { group: "All", bot: "Bot 3", date: 2012, measure: 11 }, 
        { group: "Web", bot: "All", date: 2008, measure: 15 }, 
        { group: "Whatsapp", bot: "All", date: 2008, measure: 5 }, 
        { group: "Web", bot: "Bot 2", date: 2008, measure: 2 }, 
        { group: "Facebook", bot: "Bot 3", date: 2008, measure: 1 }, 
        { group: "Facebook", bot: "Bot 4", date: 2008, measure: 10 }, 
        { group: "Web", bot: "Bot 2", date: 2009, measure: 2 }, 
        { group: "Facebook", bot: "Bot 3", date: 2009, measure: 1 }, 
        { group: "Web", bot: "Bot 2", date: 2010, measure: 2 }, 
        { group: "Facebook", bot: "Bot 3", date: 2010, measure: 1 }, 
        { group: "Whatsapp", bot: "Bot 1", date: 2011, measure: 2 }, 
        { group: "Web", bot: "Bot 2", date: 2011, measure: 2 }, 
        { group: "Facebook", bot: "Bot 3", date: 2011, measure: 1 }, 
        { group: "Whatsapp", bot: "Bot 1", date: 2012, measure: 2 }, 
        { group: "Web", bot: "Bot 2", date: 2012, measure: 2 }, 
        { group: "Facebook", bot: "Bot 3", date: 2012, measure: 1 }, 
        { group: "Whatsapp", bot: "All", date: 2009, measure: 4 }, 
        { group: "Whatsapp", bot: "All", date: 2010, measure: 3 }, 
        { group: "Whatsapp", bot: "All", date: 2011, measure: 2 }, 
        { group: "Whatsapp", bot: "All", date: 2012, measure: 1 }, 
        { group: "Facebook", bot: "All", date: 2008, measure: 25}, 
        { group: "Facebook", bot: "Bot 1", date: 2008, measure: 12 }, 
        { group: "Facebook", bot: "Bot 1", date: 2009, measure: 2 }, 
        { group: "Facebook", bot: "Bot 1", date: 2010, measure: 22 }, 
        { group: "Facebook", bot: "Bot 3", date: 2008, measure: 10 }, 
        { group: "Facebook", bot: "All", date: 2009, measure: 2 }, 
        { group: "Facebook", bot: "All", date: 2010, measure: 5 }, 
        { group: "Facebook", bot: "All", date: 2011, measure: 20 }, 
        { group: "Facebook", bot: "All", date: 2012, measure: 51 }
    ]
  },
  {
    name: "Estadística 4",
    title: "Tiempo promedio de respuesta del bot",
    icon: "https://image.flaticon.com/icons/svg/1766/1766441.svg",
    values: [
        { group: "All", bot: "All", date: 2008, measure: 28 }, 
        { group: "All", bot: "All", date: 2009, measure: 23 }, 
        { group: "All", bot: "All", date: 2010, measure: 31 }, 
        { group: "All", bot: "All", date: 2011, measure: 22 }, 
        { group: "All", bot: "All", date: 2012, measure: 24 }, 
        { group: "All", bot: "Bot 1", date: 2008, measure: 28 }, 
        { group: "All", bot: "Bot 2", date: 2008, measure: 23 }, 
        { group: "All", bot: "Bot 3", date: 2008, measure: 31 }, 
        { group: "All", bot: "Bot 4", date: 2008, measure: 13 }, 
        { group: "All", bot: "Bot 1", date: 2009, measure: 2 }, 
        { group: "All", bot: "Bot 2", date: 2009, measure: 3 }, 
        { group: "All", bot: "Bot 3", date: 2009, measure: 1 }, 
        { group: "All", bot: "Bot 1", date: 2010, measure: 8 }, 
        { group: "All", bot: "Bot 2", date: 2010, measure: 2 }, 
        { group: "All", bot: "Bot 3", date: 2010, measure: 3 }, 
        { group: "All", bot: "Bot 1", date: 2011, measure: 2 }, 
        { group: "All", bot: "Bot 2", date: 2011, measure: 2 }, 
        { group: "All", bot: "Bot 3", date: 2011, measure: 11 }, 
        { group: "All", bot: "Bot 1", date: 2012, measure: 18 }, 
        { group: "All", bot: "Bot 2", date: 2012, measure: 13 }, 
        { group: "All", bot: "Bot 3", date: 2012, measure: 11 }, 
        { group: "Web", bot: "All", date: 2008, measure: 5 }, 
        { group: "Whatsapp", bot: "All", date: 2008, measure: 5 }, 
        { group: "Web", bot: "Bot 2", date: 2008, measure: 2 }, 
        { group: "Facebook", bot: "Bot 3", date: 2008, measure: 1 }, 
        { group: "Facebook", bot: "Bot 4", date: 2008, measure: 11 }, 
        { group: "Web", bot: "Bot 2", date: 2009, measure: 2 }, 
        { group: "Facebook", bot: "Bot 3", date: 2009, measure: 1 }, 
        { group: "Web", bot: "Bot 2", date: 2010, measure: 2 }, 
        { group: "Facebook", bot: "Bot 3", date: 2010, measure: 1 }, 
        { group: "Whatsapp", bot: "Bot 1", date: 2011, measure: 2 }, 
        { group: "Web", bot: "Bot 2", date: 2011, measure: 2 }, 
        { group: "Facebook", bot: "Bot 3", date: 2011, measure: 1 }, 
        { group: "Whatsapp", bot: "Bot 1", date: 2012, measure: 2 }, 
        { group: "Web", bot: "Bot 2", date: 2012, measure: 2 }, 
        { group: "Facebook", bot: "Bot 3", date: 2012, measure: 1 }, 
        { group: "Whatsapp", bot: "All", date: 2009, measure: 4 }, 
        { group: "Whatsapp", bot: "All", date: 2010, measure: 3 }, 
        { group: "Whatsapp", bot: "All", date: 2011, measure: 2 }, 
        { group: "Whatsapp", bot: "All", date: 2012, measure: 1 }, 
        { group: "Facebook", bot: "All", date: 2008, measure: 25}, 
        { group: "Facebook", bot: "Bot 1", date: 2008, measure: 12 }, 
        { group: "Facebook", bot: "Bot 1", date: 2009, measure: 2 }, 
        { group: "Facebook", bot: "Bot 1", date: 2010, measure: 22 }, 
        { group: "Facebook", bot: "Bot 3", date: 2008, measure: 10 }, 
        { group: "Facebook", bot: "All", date: 2009, measure: 2 }, 
        { group: "Facebook", bot: "All", date: 2010, measure: 5 }, 
        { group: "Facebook", bot: "All", date: 2011, measure: 20 }, 
        { group: "Facebook", bot: "All", date: 2012, measure: 51 }
    ]
  }

];
dataT=data;
data.dataset.forEach(function(d, index) {

    if(d.name==datos)
    {
       dataT=d;
        return dataT;
    }
  });
  return dataT;
}
export default createDataSquareText;