import React, { Component } from 'react';
var momentzone = require('moment-timezone');
moment.locale('es');
const utf8 = require('utf8');
import moment from 'moment';
import MassiveCampaignLayout from '../../campaigns/massive-campaign/massive-campaign-layout';
import ModalLoad from '../../../components/modals/modal-load';
import {
  getHandlerGET, getHandlerPOST,
  getHandlerDELETE, getHandlerPUT, fileHandlerPOST
} from '../../../utils/handlersApi'
import { cerrarSesion, isAuthorizationValid, getRolUser, getUserInfoLocalStorage, UTCFormatter } from '../../../utils/commonFunction'

class MassiveCampaign extends Component {
  
  constructor(props) {
    super(props)
    this.state = {
      modalNewNotification: false,
      modalEditNotification: false,
      listChannels: [],
      listMessageTypes: [],
      listTemplates: [],
      hideLoad: 0,
      listBots: [],
      listContact: [],
      botVersionId: '',
      ia_activated: false,
      selectedOption: { label: '', value: '' },
      selectedOptionSendDate: { label: '', value: '' },
      selectedOptionMessageType: { label: '', value: '' },
      selectedOptionBot: { label: '', value: '' },
      channelsId: [],
      companyId: '',
      notification: [],
      SendDateOptions: [
        {value: '1', label: 'Ahora mismo'},
        {value: '2', label: 'Programar'},
      ],
      varList: [],
      idList: '',
      idTemplate: '',
      selectedDay: moment().format('YYYY-MM-DD HH:mm:ss'),
      editedCampaign: false,
      campaignEditing: {},
      showSms:false,
      showSMSType:false,
      showBot:false,
      showEmail:false,
      showWpFb:false,
      showSendDate: false,
      showUpAudio: false,
      showMessageVoz:false,
      showSender: false,
      showOnlySubject:false,
      isOpenCustomUrl:false,
      showContactsList: false,
      message: '',
      dataurl:'',
      isAdmin:false,
      itemSelected:'',
      type:-1,
      titleOpcion:'',
      channel_id: -1,
      listAllSender: [],
      selectedOptionSender: {value: '', label: ''}

    }
  }

  componentWillMount() {
    isAuthorizationValid();
    const { isAdmin = false, isSuper = false } = getRolUser();
    this.setState({
        isAdmin: isAdmin,
        isSuperUser: isSuper
    });
  }

  componentDidMount() {
    this.getListSender("all"); //OJO CON ALL TRAE TODOS SIN PAGINAR
  }


  /************ FUNCTIONS *************/

  /*
  * Function getBot
  * @botid = int (id del bot)
  * Get all information about a specific bot
  */


 toggleModalEditCampaign = () => {   
  this.setState({
    modalNewNotification: !this.state.modalNewNotification,
    editedCampaign: true,
  },()=>{
    this.getlistTemplates()
    this.getListChannels();
    this.getListBots();
    this.fillListMessageTypes();
    this.getLists();
  })
}

/*Funciton open and close modals */
toggleModalNewCampaign = (type, channel_id, titleOpcion) => {
  this.setState({
    type,
    titleOpcion,
    channel_id,
    modalNewNotification: !this.state.modalNewNotification,
    selectedOption: { label: '', value: '' },
    selectedOptionMessageType: { label: '', value: '' },
    selectedOptionBot: { label: '', value: '' },
    editedCampaign: false,
    idTemplate: '',
    message:'',
    showSms:false,
    showSMSType: false,
    showBot:false,
    showContactsList: false,
    showSendDate:false,
    showEmail:false,
    showOnlySubject:false,
    showWpFb:false,
    showMessageVoz: false,
    selectedDay: moment().format('YYYY-MM-DD HH:mm:ss'),
  },()=>{
    this.getlistTemplates()
    this.getListChannels();
    this.getListBots();
    this.fillListMessageTypes();
    this.getLists();
    this.showInput(type,channel_id);
    this.getListSender("all"); //OJO CON ALL TRAE TODOS SIN PAGINAR
  })
}

/*
 * Function fillListMessageTypes
 * Fill Messages types for sending
 */

fillListMessageTypes = () => {
  let options = [
    {
      value: 'TYPE_MESSAGE',
      label: 'Escribir Mensaje'
    },
    {
      value: 'BY_BOT',
      label: 'Usar un bot'
    }
  ];
  this.setState({ listMessageTypes: options });
}




/*
* Function getListBots
* Get all list bots by company
* res: [{id,name,botversion_id},{id,name,botversion_id}]
*/

getListBots = () => {
  getHandlerGET(HOST_API + '/api/bot/list_simple/').
    then((res) => {
      if (res.status == 200) {
        let options = [];
        let result = res.data.bot;
        for (let i = 0; i < result.length; i++) {
          let temp = {
            value: result[i].bot_version_id[0],
            label: result[i].name,
            lista: result[i].list_id
          }
          options.push(temp)
        }
        this.setState({ listBots: options })
      } else
        console.log(" No se pudo obtener la lista de bots");
    }).catch((error) => {
      console.log('catch GET getListBots', error);
      if (error.response.status == 403)
        cerrarSesion();
    });
}

/*
* Function getListChannels
* Get all channels available and change their structure
* original: [0:{id:1,channel:email},1:{id:2,channel:sms}]
* modified: [0:{value:1,label:email},1:{value:2,label:sms}]
*/

getListChannels = () => {
  getHandlerGET(HOST_API + '/api/notification/channels/').
    then((res) => {
      if (res.status == 200) {
        let options = [];
        let result = res.data.data;
        for (let i = 0; i < result.length; i++) {
          let temp = {
            value: result[i].id,
            label: result[i].channel
          }
          options.push(temp)
        }
        this.setState({ listChannels: options })
      } else
        console.log(" No se pudo obtener la lista");
    }).catch((error) => {
      console.log('catch GET getListNotificacion', error);
      if (error.response.status == 403)
        cerrarSesion();
    });
}

/*
* Function getlistTemplates
* Get all template available for company
* @idCompany = int (company id)
*/
getlistTemplates = () => {
  getHandlerGET(HOST_API + '/api/notification/templates/')
    .then((res) => {
      if (res.status == 200)
        this.setState({ listTemplates: res.data })
      else
        console.log(" No se pudo obtener la lista");
    }).catch((error) => {
      console.log('catch GET getListNotificacion', error);
      if (error.response.status == 403)
        cerrarSesion();
    });
}

/*
* Function handleChangeChannels
* save all selected options from channels in state
*/
handleChangeBots = selectedOptionBot => this.setState({ selectedOptionBot },()=> this.getListHeaders(this.state.selectedOptionBot))
handleChangeSendDate = selectedOptionSendDate => this.setState({ selectedOptionSendDate }, ()=>this.showInputDate(this.state.selectedOptionSendDate))
handleChangeSender = selectedOptionSender => this.setState({selectedOptionSender})
handleChangeSelectContact = event => this.getListHeaders(event.target.value);
handleChangeInputValueBots = (text) =>{
 getHandlerGET(HOST_API + '/api/bot/list_simple/?s='+text).
 then((res) => {
   if (res.status == 200) {
     
     let result = res.data.bot;
     let options = [];
     for (let i = 0; i < result.length; i++) {
       let temp = {
         value: result[i].bot_version_id[0],
         label: result[i].name
       }
       options.push(temp)
     }
     
     this.setState({ listBots: options })
   } else
     console.log(" No se pudo obtener la lista de bots");
 }).catch((error) => {
   console.log('catch GET getListBots', error);
   if (error.response.status == 403)
     cerrarSesion();

 });
}
handleChangeDay = day => this.setState({
  selectedDay: day.format('YYYY-MM-DD HH:mm:ss')
})


/*
* Function saveNotiHandler
* Save notifications at BD
*/
handleSaveCampaign = (event) => {

  event.preventDefault();
   
  const form = new FormData(event.target);
  const channel = form.get('channel');
  const typeMessage = form.get('tipo_Mensaje'); 
  const sender = form.get('remitente_campaign');
  const sender_id = form.get('sender_id');
  const typeSendDate = form.get('tipo_envio');
  const contactList = form.get("list");
  const bot = form.get('bot');
  const title = form.get('title');
  const templateEmail = form.get('plantillaEmail');
  const subject = form.get('subject');


  if (templateEmail === '') {
    alert("Por favor seleccione una plantilla.")
    return;
  }
  if (subject === '') {
    alert("Por favor, escriba un asunto.")
    return;
  }

  if (channel == '') {
    alert("Por favor seleccione un canal.")
    return;
  }

  if(bot == ''){
    alert("Por favor seleccione un bot.")
    return;
  }
  
  if ((channel == '1' || channel == '5') && typeMessage == '1' ) {
    if(this.state.message == ''){
      alert("Por favor escriba un mensaje.")
      return;
    }
  }

  if(contactList == ''){
    alert("Por favor seleccione una lista de contacto.")
    return;
  }

  if(typeSendDate == ''){
    alert("Por favor seleccione cuando quiere enviar su campaña.")
    return;
  }
  
  if( sender == ''){
    alert("Por favor seleccione el remitente  ")
    return;
  }

  const data = {
    title: utf8.encode(title) ,
    send_date: ( form.get('tipo_envio') == '2') ? UTCFormatter(this.state.selectedDay):  UTCFormatter(),
    botversion_id: bot,
    channel: channel,
    message_type: typeMessage,
    list_id: contactList,
    template_id: templateEmail,
    message:  encodeURI(this.state.message),   
    subject: utf8.encode( subject || '') ,
    sender_id: sender_id
  }

  document.getElementById('frm_notification').reset();

  if (this.state.editedCampaign) {
    const campaign_id = document.getElementById('campaign_id').value
    // Editando        
    const sendData = { ...data, campaign_id }
    getHandlerPUT(HOST_API + '/api/campaign/', sendData)
      .then((res) => {
       
        if (res.status == 200) {
          this.setState({selectedOption: null, selectedOptionBot: null, editedCampaign: false, modalNewNotification: !this.state.modalNewNotification })
          alert('Datos guardados con exito');             
        }
        else
          console.log(" No se pudo GUARDAR");
      })
      .catch((error) => {
        alert('Hubo un error, ¡Intente nuevamente!')
        console.log('catch PUT handleSaveCampaign', error);
        if (error.response.status == 403)
          cerrarSesion();
      });
  } else {
    // Guardando
    getHandlerPOST(HOST_API + '/api/campaign/', data)
      .then((res) => {
        if (res.status == 200) {
          this.setState({ selectedOption: null, selectedOptionBot: null,modalNewNotification: !this.state.modalNewNotification })
          alert('Datos guardados con exito');
        }
        else
          console.log(" No se pudo GUARDAR");
      })
      .catch((error) => {
        alert('Hubo un error, ¡Intente nuevamente!')
        console.log('catch POST handleSaveCampaign', error);
        if (error.response.status == 403)
          cerrarSesion();
      });
  }

}


/*
* Function handleEditCampaign
* get specific notification
* @id = int (notification id)
*/


handleEditCampaign = (campaignEditing, editedCampaign) => {
  this.showInput(campaignEditing.channel.id)
  this.updateItem(campaignEditing.channel.id, campaignEditing.message)
  this.setState({ campaignEditing, editedCampaign });
  this.getListHeaders(campaignEditing.bot.list_id);
  this.toggleModalEditCampaign();
  
}




updateItem = (k, text) => {
  if (k == 1){    
    this.setState({ message: text}); 
  }else if(k == 2){
    this.setState({ message: text });
  }else{
    this.setState({ dataurl: (text) });
  }  
  this.forceUpdate();
}

ConcatItem = (text) =>{
    let sms = this.state.message+' '+text
  this.updateItem(1,sms)

}

cleanInput = () => {
  this.setState({ dataurl: '' });
}

/*
* Function getListHeaders
* get all header with a specific contact list
* @id = id notification
*/
getListHeaders = (option) => { 
  let id = option.lista || option;
getHandlerGET(HOST_API +'/api/listas/cabecerajs?_id='+id). 
    then((res)=>{ 
        if(res.status==200) 
            this.setState({varList:res.data}) 
        else 
          console.log(" No se pudo obtener las cabeceras"); 
    }).catch((error) => { 
        console.log('catch GET getListNotificacion',error); 
        if (error.response.status == 403) 
          cerrarSesion(); 
    }); 
}

/*
* Function getList
* Get all list contact by company
*/

getLists = () => {
  getHandlerGET(HOST_API +'/api/listas/bycompany?id_company='+getUserInfoLocalStorage().company_id).
      then((res)=>{
          if(res.status==200)
              this.setState({listContact:res.data})
          else
            console.log(" No se pudo obtener la lista");
      }).catch((error) => {
          console.log('catch GET getListNotificacion',error);
          if (error.response.status == 403)
            cerrarSesion();
      });
}

/*
* Show inputs
* show input depend channel selected
*/

showInput = (type,channel_id) => {
  this.setState({
    showSms:false,
    showSMSType:false,
    showBot:false,
    showContactsList: false,
    showWpFb:false,
    showEmail:false,
    showMessageVoz: false,
    showUpAudio: false,
    showSender:false,
    showOnlySubject:false,
  })
 
  switch (`${type}:${channel_id}`) {
    case '1:1':
      this.setState({showSms:true, showContactsList: true,showSender:true})
    break;

    case '2:1':
      this.setState({showBot:true})
    break;

    case 2:
    case 4:
      this.setState({showWpFb:true})
    break;

    case '1:3':
      this.setState({showEmail: true, showContactsList: true, showSender:true})
    break;

    case '2:3':
      this.setState({showOnlySubject: true, showBot:true})
    break;

    case '1:5': 
      this.setState({showMessageVoz: true, showContactsList: true, showSender: true }) 
    break;
 
    case '3:5':
      this.setState({ showContactsList: true, showUpAudio: true, showSender: true })
    break;
    case '2:5':
      this.setState({ showContactsList: true, showBot:true })
    break;

    default:
      break;
  }
}

showInputDate = (option) => {
  this.setState({
    showSendDate:false
  })

  let value = option.value || option
  this.setState({
    showSendDate:value == "2"
  })
}




// Change input style with validate
customValidate = (classItem,idiTem,borderValidate) => {
  if (borderValidate) {      
    document.getElementsByClassName(idiTem)[0].setAttribute('class',` ${classItem}  ${idiTem}  border-red`)
  } else {
    document.getElementsByClassName(idiTem)[0].setAttribute('class', ` ${classItem}  ${idiTem}`)
  }
}

toggleModalCustomUrl = () => {
  this.setState({isOpenCustomUrl: !this.state.isOpenCustomUrl,dataurl:''})
}


  handleChangeSelectCard = (itemSelected) =>{
    this.setState({itemSelected});
  }


  getAudio = ( url ) => {
    this.setState({ message: url });
  }


  getListSender = (p) => {   
    getHandlerGET(HOST_API + '/api/senders/?p='+p)
    .then((res) => {
        if (res.status == 200) {
            this.setState({ listAllSender: res.data.senders });
              
        }else{
            console.log(" No se pudo obtener la lista");
        }
    }).catch((error) => {
        console.log('catch GET getListSender', error);
        if (error.response.status == 403){
            cerrarSesion();
        }
    });
}

  render() {
    return (
      <div>
    <MassiveCampaignLayout
      itemSelected={this.state.itemSelected}
      handleChangeSelectCard={this.handleChangeSelectCard}


      editedCampaign={this.state.editedCampaign}
      campaignEditing={this.state.campaignEditing}
      isAdmin={this.state.isAdmin}
      toggleModalNewCampaign={this.toggleModalNewCampaign}
      toggleModalEditCampaign={this.toggleModalEditCampaign}
      modalNewNotification={this.state.modalNewNotification}
      modalEditNotification={this.state.modalEditNotification}
      listChannels={this.state.listChannels}
      listMessageTypes={this.state.listMessageTypes}
      listBots={this.state.listBots}
      listContact={this.state.listContact}
      SendDateOptions={this.state.SendDateOptions}
      handleChangeSendDate={this.handleChangeSendDate}
      handleChangeSender={this.handleChangeSender}
      handleChangeBots={this.handleChangeBots}
      handleChangeInputValueBots={this.handleChangeInputValueBots}
      botId={this.state.botId}
      ia_activated={this.state.ia_activated}
      selectedOption={this.state.selectedOption}
      selectedOptionBot={this.state.selectedOptionBot}
      selectedOptionSender={this.state.selectedOptionSender}
      idList={this.state.idList}
      selectedDay={this.state.selectedDay}
      handleChangeDay={this.handleChangeDay}
      handleSaveCampaign={this.handleSaveCampaign}
      handleEditCampaign={this.handleEditCampaign}
      notification={this.state.notification}
      listTemplates={this.state.listTemplates}
      idTemplate={this.state.idTemplate}
      getlistTemplates={this.getlistTemplates}
      varList={this.state.varList}
      updateItem={this.updateItem}
      message={this.state.message}
      showWpFb = {this.state.showWpFb}
      showSms= {this.state.showSms}
      showSMSType= {this.state.showSMSType}
      showMessageVoz={this.state.showMessageVoz}
      showUpAudio={this.state.showUpAudio}
      showSender={this.state.showSender}
      getAudio={this.getAudio}
      showBot= {this.state.showBot}
      showContactsList={this.state.showContactsList}
      showSendDate={this.state.showSendDate}
      showEmail = {this.state.showEmail}
      showOnlySubject={this.state.showOnlySubject} 
      isOpenCustomUrl = {this.state.isOpenCustomUrl}
      toggleModalCustomUrl= {this.toggleModalCustomUrl}
      dataurl = {this.state.dataurl}
      cleanInput = {this.cleanInput}
      ConcatItem = {this.ConcatItem}
      getLists={this.getLists}
      type={this.state.type}
      titleOpcion={this.state.titleOpcion}
      channel_id={this.state.channel_id}
      listAllSender={this.state.listAllSender}
      handleChangeSelectContact={this.handleChangeSelectContact}

    /> 
    <ModalLoad hideLoad={this.state.hideLoad} />
    </div>     
    )
  }
}

export default MassiveCampaign
