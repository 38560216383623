// Dependencies
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import { Col, Row, Button, Container, Table, UncontrolledTooltip } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { Link } from 'react-router-dom';

// Components
import Admin from '../../admin/containers/admin';
import SliceMenuApp from '../../admin/components/slice-menu'
import ToolbarApp from '../../admin/components/toolbar'
import BotMenuOptions from '../../menus/bot-menu-options.js';
import { deleteTemplate } from '../../../redux/actions/templateActions';

import DataTableFilter from '../../analytics/infoViz/common/dataTableFilter'

/* IMAGE */
import imageNew from '../../../../static/img/menu-icons/nuevo_bot.svg'
import i18next from '../../../../i18n';

function TemplateLayout(props) {

  const { deleteTemplate, templateReducer: { listAllTemplate = [] } } = props;

  const redirectTo = () => {
    props.history.push(`/Newtemplates`)
};

  var dataTable=null;
  if(listAllTemplate[0]){
     
       dataTable = {

          columns: [
            {
              label: i18next.t('template_title'),
              field: 'name',
              sort: 'asc',
             // width: 150
            },
            {
              label: i18next.t('edition_option'),
              field: 'options',
              sort: 'asc',
              width: 670,
              options: {
                  filter: false,
                  sort: false
                 }
            }],
            rows: []};

            const rows =  listAllTemplate.map((no, index) => {


              //k = JSON.parse(k);  
              const fin=[];
              fin.push(no.template_name);
             
              fin.push(
                  <Row className="d-flex align-items-center justify-content-center">
                          {
                               <div className="d-inline-block">
                               <Link to={"/Newtemplates/" + no.id} className="btn btn-outline-success" style={{ 'fontSize': '18px', 'marginRight': '5px' }}>
                                 <FontAwesomeIcon icon={faPen} id={'buttonEdit' + index} />
                               </Link>
                               <UncontrolledTooltip placement="top" target={'buttonEdit' + index}> {i18next.t('edit')}</UncontrolledTooltip>
                               <Button outline color="danger" onClick={(e) => deleteTemplate(no.id)}>
                                 <FontAwesomeIcon icon={faTrash} id={'buttonDelete' + index} />
                               </Button>
                               <UncontrolledTooltip placement="top" target={'buttonDelete' + index} > {i18next.t('delete')}</UncontrolledTooltip>
                             </div>
                          }
                       </Row>
              )
              dataTable.rows.push(fin); 
              return no;
          });
      }
      const options = {
          filterType: 'multiselect',
          selectableRows:'none',
          resizableColumns: false,
          responsive: "scrollMaxHeight",
          rowsPerPage:5 ,
          print:false,
          download:false,
          viewColumns:false,
          
        };
        
            var title = ''

           
  return (
    <div className="NotificaionsLayout">
      <Admin>
              <button id="tooltiNewTemplate" className="btn-float"  onClick={(e)=>{redirectTo()}}>
                <img src={imageNew}/>
            </button> 
            <UncontrolledTooltip placement="right" target="tooltiNewTemplate">{i18next.t('create_new_template')}</UncontrolledTooltip> 
        <SliceMenuApp>
          <BotMenuOptions botId={props.botId} ia_activated={props.ia_activated} />
        </SliceMenuApp>
        <Container fluid>
          <ToolbarApp>
            <Row>
              <Col sm="9" md="9" lg="9" className="text pdtoolbar">
                <h2 className="text-left ml-4 font-weight-bold">{i18next.t('templates_email')}</h2>
              </Col>
            </Row>
          </ToolbarApp>
        </Container>
        <Container fluid className="box-fluid">
                <Col sm="12" md="10" className="stadistics-container offset-md-1">    
                    {
                       dataTable?(
                        <Row >
                            <Col xs={12} lg={12} >
                                <DataTableFilter
                                title={title}
                                data={ dataTable.rows}
                                columns={dataTable.columns}
                                options={options}
                                />  
                           </Col>
                        </Row>): 
                          <Col xs={12} lg={8} className="offset-lg-2 intro-funnels">
                                <Row>
                                    <Col xs={12}><img src="/static/img/blank_state.png" className="img-fluid"/></Col>
                                    <Col xs={12}>
                                        <h2>{i18next.t('without_templates')}</h2>
                                        <h3>{i18next.t('template_email_msg2')}</h3>
                                        <p>{i18next.t('create_new_template')}</p>
                                    </Col>
                                </Row>
                            </Col>
                        }
                    </Col>
                </Container>
      </Admin>

    </div>
  )
}


const mapStateToProps = ({ templateReducer }) => ({ templateReducer });

const mapDispatchToProps = {
  deleteTemplate
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TemplateLayout))

