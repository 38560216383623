import React, { Component } from 'react';
import {  
    FormGroup, 
    Input, 
    Col,
    Row,
    Label,
    UncontrolledTooltip
} from 'reactstrap';

const utf8 = require("utf8");

import i18next from '../../i18n'

class TitleOperator extends Component {


    constructor(props){
        super(props);
        
        this.state = {
            title: props.titleOperator
        }
    }

    bindValue = () => {
        if(typeof this.props.bindValue === "function")
             this.props.bindValue(this.state.title);
    }

    updateTitle = (event) => {
        this.setState({title: event.target.value}, () => {
            this.bindValue();
        });
    }

    componentDidMount = () => {
        this.setState({ title: this.props.titleOperator});
    }

     componentWillUpdate(nextProps,nextState)
    {
        const title = this.state.title;
        const newTitle = nextProps.titleOperator;

        if(title != newTitle)
        {
            this.setState({ title: newTitle});
        }
    }

    render() {
        
        return (
            <Row>
                <Col sm="12">
                    <FormGroup>
                        <Label>{i18next.t('operator_title')}<i className="fa fa-info-circle" id="tooltiptitle"></i></Label>
                        <UncontrolledTooltip placement="right" target="tooltiptitle"> {i18next.t('message38')}</UncontrolledTooltip>
                        <Input type="text" name="titleOperator" onChange={(event) => { this.updateTitle(event) }} defaultValue={this.state.title==null?'':decodeURI(this.state.title)} maxLength={'50'}/>
                    </FormGroup>
                </Col>
            </Row>
        )
    }
}

export default TitleOperator