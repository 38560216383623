import React, { Component } from 'react';
import { MentionsInput, Mention } from "react-mentions";


const defaultStyle = {
  control: {
    height: "auto",
    width: "100%",
  },
  highlighter: {
    overflow: 'hidden',
  },
  input:{
    borderRadius:"4px",
    border:"1px solid #cdcdcd",
    padding:"6px",
    overflow: 'auto',
      height: 50,
  },
  suggestions: {
    marginTop: "30px",
    marginLeft: "10px",

    list: {
      backgroundColor: "#fff",
      border: "1px solid #d2d6de",
      zIndex: "100!important",
      height: "131px",
      overflow: "auto",
      borderStyle: "solid",
      borderColor: "silver",
      borderWidth: "0.5px",
    },
    item: {
      padding: "5px 15px",
      borderBottom: "1px solid #cdcdcd",
      
      
      "&focused": {
        backgroundColor: "#5a5a5a",
        color: "white",
        fontSize: 18,
        
      }
    }
  }
};

const defaultMentionStyle ={
    backgroundColor: '#75b74b',
    borderRadius:"3px",
    paddingBottom:'2px'
}

class Mentions extends Component {
    state = {
        searchActive: true,
        search:'',
        error_input:{},
    }
    constructor(props){
        super(props);
    }
   
    onChange = e => { 
        let value = e.target.value;
         let statement_length = value.length;
         let a = this.state.error_input;
          if(statement_length > this.props.maxlength){
            let k = this.props.k;
            a[k]=true;
            this.setState({error_input:a});
          }else{
            this.updateItem(value)
          } 
    };
    updateItem = (e) =>{
        let k = this.props.k;
        this.props.updateItem(k,e)
      }
    render() {
        var data =[]
        let k = this.props.k;
        if(this.props.varList.length > 0){
            this.props.varList.map((elem)=>{
                if(elem != ""){
                    let temp = {};
                    temp.id = elem;
                    temp.display = elem;
                    data.push(temp);
                }
            });
        }
        return (
          <div className="modal-maxWidth-alone">
            <MentionsInput
                allowSpaceInQuery
                style={defaultStyle}
                value={this.props.defaultValue}
                onChange={this.onChange}
                id={"input-msj"+k}
                name={'name'+k}
                markup="*|__id__|*"
                displayTransform={id => { 
                    return " *|" + id + "|* "; 
              }}
            >
                <Mention trigger={/(\@([A-Za-z0-9_.]*))$/} data={data} style={defaultMentionStyle}/>
            </MentionsInput>
            <small><strong>@</strong> para seleccionar cabecera</small>
            { this.state.error_input[k] && 
                <span className="warning-character float-right">{this.props.msg_error}</span>
            } 
        </div>
        );
    }
}


export default Mentions
