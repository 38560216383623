import { AbstractNodeFactory } from "storm-react-diagrams";
import React from "react";

import TRIGGERNodeModel from "./TRIGGERNodeModel";
import TRIGGERNodeWidget from "./TRIGGERNodeWidget";

class TRIGGERNodeFactory extends AbstractNodeFactory {
	constructor() {
		super("trigger");
	}

	generateReactWidget(diagramEngine, node) {
		return <TRIGGERNodeWidget node={node} />
	}

	getNewInstance() {
		return new TRIGGERNodeModel();
	}
}

export default TRIGGERNodeFactory;