import React,{ Component } from 'react';
import { Button,Input,Container, Row, Col, Form, FormGroup, Label } from 'reactstrap';
import './login-layout.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import  Recaptcha from 'react-recaptcha';
import i18next from '../../../i18n'


class LoginLayout extends Component  {
    constructor(props) {
        super(props)
        this.state = {
            isVerify:false
        }
        this.callback = this.callback.bind(this);
    }
     callback =  () => true;
       
     verifyCallback = (response) => {
        if(response)
            this.setState({isVerify:true})
        else
            alert('Dale check al reCAPTCHA para continuar')
      };

    render(){
        return (
            <Container className="d-flex align-items-center">
                <Col className="barra-box"></Col>
                <Row>
                    <Col>
                        <Row>
                            <Col className="d-none d-md-block d-lg-block d-xl-block img-login-box" md={{size:4, offset:2}}>
                            </Col>
                            <Col sm="12" md="4" className="form-login">
                                <h1 className="text-guarumo">{i18next.t('init_session')}</h1>
                                
                                {
                                this.props.blockAccount.loginReducer.userBlock === true ? 
                                    <p style={{ textAlign: 'left', fontSize: '18px' }}> {i18next.t('block_account')} </p> 
                                    : ""
                                }
                                
                                <Form onSubmit={this.props.onSubmit}>
                                    <FormGroup>
                                        <Label for="correo" className="font-weight-bold font14">{i18next.t('username')}*</Label><br></br>
                                        <Input name="email" id="email" onChange={this.props.handleEmailChange} placeholder={i18next.t('username')}/>
                                    </FormGroup>
                                    <FormGroup className="mt-4">
                                        <Label for="password" className="font-weight-bold font14">{i18next.t('password')}*</Label><br></br>
                                        <Input type="password" name="password" id="password" onChange={this.props.handlePasswordChange} placeholder={i18next.t('password')} />
                                    </FormGroup>
                                    <Recaptcha
                                            sitekey="6Le8PRInAAAAAE10CXRIdXfkmxGMoXckUDDyJcfC"
                                            render="explicit"
                                            verifyCallback={this.verifyCallback}
                                            onloadCallback={this.callback}
                                        />
                                    <Col md="12" className="button-box">
                                        <Button disabled={this.state.isVerify == false} id="btnlogin" color="secundary" className="btn-block btn-lg btn-guarumo">{i18next.t('init_session_btn')}</Button>
                                    </Col>
                                </Form>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <SweetAlert success title="Ha iniciado sesión" show={this.props.showAlert} onConfirm={this.props.hideAlert} showConfirm={false} />
                <SweetAlert warning title={this.props.warningMsg} show={this.props.showAlertWarning} onConfirm={this.props.hideAlert} showConfirm={false} />
            </Container>
          )
    }
    
}    

export default LoginLayout
