import { AbstractNodeFactory } from "storm-react-diagrams";
import React from "react";

import SMNodeWidget from "./SMNodeWidget";
import SMNodeModel from "./SMNodeModel";

class SMNodeFactory extends AbstractNodeFactory {
	constructor() {
		super("sm");
	}

	generateReactWidget(diagramEngine, node) {
		return <SMNodeWidget node={node} />
	}

	getNewInstance() {
		return new SMNodeModel();
	}
}

export default SMNodeFactory