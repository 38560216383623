import { NodeModel } from "storm-react-diagrams";
import SMPortModel from "../simplePortModel/SMPortModel";
import i18next from '../../../../../i18n';

class APNodeModel extends NodeModel {
	extras = {
		ia_id:0,
		text: i18next.t('without_text'),
		title: i18next.t('switch_to_agent').toUpperCase(),
		botmsg_text:"",
		url_agent:"",
		botmsg_name:"",
		home_element: 'set-home',
		width: 200,
		keyword: "",
		config:{
			//Valores por defecto caja creada 
			variable_agent : 'none',
			botmsg_text: "",
			url_agent:"",
			var_list: "[]"
		}
	}
	removeBox = null
	changeHome = null
	toggleEdit = null
	customClone = null
	
	constructor() {
		super("ap");
		this.addPort(new SMPortModel(false, "left", "ap"));
		this.addPort(new SMPortModel(true, "right", "ap"));

	}
}

export default APNodeModel