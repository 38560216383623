import { PortModel, DefaultLinkModel }from "storm-react-diagrams";

class SMPortModel extends PortModel {
	position = "left";
	in = false;

	constructor(isInput, pos = "left", node_type="sm") {		
		super(pos, node_type);
		this.in = isInput;
		this.position = pos;
	}
	serialize() {
		return _.merge(super.serialize(), {
			in: this.in,
			position: this.position
		});
	}
	deSerialize(data, engine) {
		super.deSerialize(data, engine);
		this.position = data.position;
		this.in = data.in;
	}

	createLinkModel() {
		return new DefaultLinkModel();
	}

	canLinkToPort(port) {
		if(this.name == 'left')
			return false;

		if (port instanceof SMPortModel) {
			return this.in !== port.in;
		}
		return true;
	}
}

export default SMPortModel