import React, {Component} from 'react';
import { connect } from "react-redux";

import { Button,Modal,ModalHeader,ModalBody,ModalFooter,Form,
    FormGroup,Table,Label,Col,Row,Input,Nav,NavItem,TabContent,
    TabPane,NavLink,UncontrolledTooltip} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import Switch from "react-switch";
import IASelector from '../../../../components/ia'
import Timeout from '../../../../components/timeout/timeout';
import Mentions from '../../../../components/mentions/metions-alone';
import InputValidate from '../../../../components/input-validate';
import TitleOperator from "../../../../components/title-operator";
import Keyword from '../../../../components/keyword/keyword';
import Avatar from '../../../../components/avatar';
import Emoji from '../../../../components/emoji-picker';
import Audio from '../../../../components/audio-input';
import classnames from 'classnames';
import MainStatement from '../../../../components/box/main_statement'
import TabAdvanced from "../../../../components/box/tab_advanced";
import i18next from '../../../../../i18n';

import {setChangeConfig,
    changeActiveTab}  from "../../../../redux/actions/boxActions";

function QRMLayout(props) {
   
    
    const {selectedBoxOperator, configBoxOperator, activeItem} =props.boxReducer;

    let interactive = true;
    
// class QRMLayout extends Component {

//     constructor(props){
//         super(props)
//         this.state = {
//         activeTab: '1',
//         };
//         this.toggleTab = this.toggleTab.bind(this);
//     }

    // function toggleTab(tab) {
    //     if (this.state.activeTab !== tab) {
    //       this.setState({
    //         activeTab: tab
    //       });
    //     }
    // }
    function toggleTab(tab) {
    
        // e.preventDefault();
        // console.log('The link was clicked.',e);
        console.log("TAB: "+tab);
         props.changeActiveTab(tab);
     
    }

    function handleChange(e) {
    
        interactive = !interactive;
        props.setChangeConfig('interactive_message',interactive===true?'True':'False');
    }

    const inputEnunciado = (bls,kblocks) => {
        return kblocks.map((k, index) => {
            return <tr key={k}>
                <td className="td-flujo" style={{'width':'100%','height':'70px'}}>
                    <InputValidate k={k} maxlength={'24'} msg_error={'Máximo 24 caracteres permitidos'} defaultValue={bls[k].text} error_input_empty={props.error_input_empty} varList={props.varList} />
                    <div className="options-bar-input">
                        <Button color="info" size="lg" onClick={() => props.removeTextBlock(k) }>
                            <FontAwesomeIcon icon={faTrash} />
                        </Button>
                        <Emoji id={k} callEmoji={props.callEmoji} type="item" style={{'display':'none',position:'fixed',marginLeft: -240+'px',zIndex: 100}}/>
                   
                    </div>
                </td>   
            </tr>
        });
    }
    if(selectedBoxOperator&&Object.keys(selectedBoxOperator).length>0&&selectedBoxOperator.operatortype_id==2)
    {
        let bls = props.boxReducer.text_blocks || {};
        //const bls = props.answer_blocks || [];
        const kblocks = Object.keys(bls);
        const blocks = inputEnunciado(bls,kblocks);
       
        interactive = (configBoxOperator)&&(configBoxOperator.interactive_message=='False')?false:true;
        return (
            <div>
                <Modal className="quickreplies" isOpen={props.isOpen} toggle={props.closeModal} fade size="lg">
                    <ModalHeader toggle={props.closeModal}>{i18next.t('config_quickreply')}</ModalHeader>
                    <ModalBody>
                        <Form role="form"> 
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                    // className={classnames({ active: this.state.activeTab === '1' })}
                                    className={classnames({ active: activeItem === '1' })}
                                    onClick={(e) => { toggleTab('1'); }} >
                                    {i18next.t('basic')}
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                    // className={classnames({ active: this.state.activeTab === '2' })}
                                    className={classnames({ active: activeItem === '2' })}
                                    onClick={(e) => { toggleTab('2'); }} >
                                    {i18next.t('advanced')}
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            {/* <TabContent activeTab={this.state.activeTab}> */}
                            <TabContent activeTab={activeItem}>
                                <TabPane tabId="1">
                                    <Row>
                                        <Col sm="12">
                                            <FormGroup>
                                                <TitleOperator titleOperator={props.titleOperator} bindValue={props.getTitleOperator}/>
                                               <Row>
                                               <Col>
                                                    <Label check style={{"marginLeft":15+"px"}}>
                                                        <Input type="checkbox" id='interactive_menu' name="interactive_menu" value="{i18next.t('whatsapp_menu_interactive')}"
                                                        defaultChecked={interactive} 
                                                        onChange={(e) => handleChange(e)}></Input>
                                                        &nbsp;
                                                                
                                                        {i18next.t('whatsapp_menu_interactive')}  <i className="fa fa-info-circle" id="tooltipInteractiveMenu"></i></Label>
                                                    <UncontrolledTooltip placement="right" target="tooltipInteractiveMenu"> {i18next.t('message35')}</UncontrolledTooltip>
                                                    </Col>
                                               </Row>
                                               <Row>
                                                   <Col align="center" >
                                                        <Label>
                                                            {i18next.t('statement_type')}                                                 
                                                        </Label>
                                                   </Col>
                                               </Row>
                                               <Row>
                                                    <Col md={5}  align="right"  >
                                                        <strong>
                                                        {i18next.t('text')}
                                                        </strong>
                                                    </Col>
                                                    <Col md={2}align="center" >
                                                        <label htmlFor="normal-switch">
                                                            <Switch
                                                            onChange={props.handleChangeSwitch}
                                                            checked={props.checkedSwitchEnunciado}
                                                            checkedIcon = {false}
                                                            uncheckedIcon = {false}
                                                            id="normal-switch"
                                                            />
                                                        </label>
                                                    </Col>
                                                    <Col md={5} align="lef"  >
                                                        <strong>
                                                        {i18next.t('audio')}
                                                        </strong>
                                                    </Col>
                                               </Row>
                                               <Row>                                                  
                                                    {   (props.checkedSwitchEnunciado == false) &&
                                                        <Col md={7} className="mb-2">
                                                            <Label>{i18next.t('statement')}</Label>
                                                            <Mentions varList={props.varList}  defaultValue={props.head || ''}  updateItem={props.updateItem} maxlength={'2000'} msg_error={i18next.t('msg_error_limit')}/>
                                                            <div className="options-bar-input" style={{'right':'16px','bottom':'-7px'}}>
                                                                <Emoji id={'head'} callEmoji={props.callEmoji} type="head" style={{'display':'none',position:'fixed',marginLeft: -240+'px',zIndex: 100}}/>
            
                                                            </div>
                                                        </Col>
                                                    }

                                                    {    (props.checkedSwitchEnunciado == true) &&
                                                        <Col md={7} className="mb-2">
                                                            <Audio operator_id={props.operator_id} audio={props.audio} bindValue={props.getAudio}/>
                                                        </Col>
                                                    }
                                                    
                                                    <Col md={5}>
                                                        <Row>
                                                            <Col>
                                                                <Label>¿{i18next.t('variable')}?</Label>
                                                                <Input type="select" name="select" onChange={(event) => {props.updateValidation(event) }}  defaultValue={props.is_var}>
                                                                    <option value="">- {i18next.t('select_one')} -</option>
                                                                    <option value={true}>{i18next.t('yes')}</option>
                                                                    <option value={false}>No</option>
                                                                </Input>
                                                            </Col>
                                                            <Col className="p-0 mt-5">
                                                                <Input type="text" hidden={props.is_var != true}  className="inputModal" placeholder="Nombre variable" onChange={(event) => { props.updateVarName(event) }}  defaultValue={props.var_name}/>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                   
                                                </Row>
                                                <Table >
                                                    <thead>
                                                        <tr>
                                                            <th>{i18next.t('title')}</th>                                        
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>{blocks}</tbody>
                                                </Table>
                                                <Col sm="12" className="button-add">
                                                        { props.error_limit_item && 
                                                            <Col sm="12">
                                                                <span className="warning-character">{i18next.t('allowed_box')} 10</span>
                                                            </Col>
                                                        }
                                                    <Col sm="12">
                                                        <Button color="info" className="btn-guarumo-modal-blue-sm" onClick={props.addNewTextBlock}>{i18next.t('add_new')}</Button>
                                                    </Col>
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId="2">
                                    <Row>
                                        <Col sm="6">
                                            <IASelector botversion_id={props.botversion_id} operator_id={props.operator_id} ia={props.ia} getIAId={props.getIAId} />
                                            <Avatar company_id={props.company_id} avatarid={props.avatarid} bindValue={props.getAvatarId} />
                                            
                                        </Col>
                                        <Col sm="6">
                                            <Row>
                                                <Col sm="12" md="12" className="keyword">
                                                    <Timeout bindValue={props.getTimeout} bindMsg={props.getMsgTimeout} bindValid={props.isTimeoutValid} defaultValue={props.timeoutValue} defaultMsg={props.timeoutMsg} />
                                                    <Keyword keyword={props.keyword} isValid={props.isKeywordValid} updateKeyword={props.updateKeyword}/> 
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </TabPane>
                            </TabContent>
                        </Form>

                      
                    </ModalBody>
                    <ModalFooter>  
                            <Col sm="12" align="right">
                                <Button color="success" className="btn-guarumo-modal-sm" type="button" onClick={props.toggleModuleModal} >{i18next.t('finish')}</Button>
                            </Col>
                    </ModalFooter>
                </Modal>           
            </div>
        );
    }
    else
    {
        return(<div></div>)
    }
}

const mapStateToProps = ({botReducer, boxReducer,homeReducer}) => {
    return {botReducer,boxReducer, homeReducer};
    };
    
    const mapDispatchToProps = {
        setChangeConfig,
        changeActiveTab,
          };


export default connect(mapStateToProps, mapDispatchToProps)(QRMLayout);


