import React, { Component } from "react";
import BotWebFormsLayout from './bot-webforms-layout';
import ModalLoad from '../../../components/modals/modal-load';
import {getHandlerGET, getHandlerPOST, getHandlerDELETE, getHandlerPUT} from '../../../utils/handlersApi'
import {isAuthorizationValid} from '../../../utils/commonFunction'
import { showError, setTitle } from '../../../redux/actions/loadStateActions';
import i18next from "../../../../i18n";


class BotWebForms extends Component {

    state = {
        hideLoad:0,
        botId: this.props.match.params.id,
        botversion_id: "",
        ia_activated: false,
        listWebForms: [],
        currentWebForm: {
            id:"",
            title:"",
            url:"",
            reg_date:""
        },
        showWebFormList: true,
        showFormBuilder: false,
        showBindForm:false
       
    };

    constructor(props){
        super(props);
    }

    componentWillMount(){
        isAuthorizationValid();
    }

    componentDidMount() {
        const { id } = this.props.match.params
        //guardo el id en el state
        this.setState({
            botId: id
        });

        this.getBotInfo();
    }

    /*  **** **** **** **** **** **** 
                Initial validations */

    /*recovering bot info*/

    getBotInfo () {
        let cont = this.state.hideLoad; this.setState({ hideLoad: cont+1});

        getHandlerGET(HOST_API +"/api/bot/?id="+this.state.botId).
            then((res) =>{
                if(res.status==200){
                    let botversion= res.data.bot[0].bot_version[0];
                    this.setState({botversion_id: botversion.botversion_id, ia_activated: res.data.bot[0].ia_activated});                       
                    this.listWebForms();
                }else{
                    console.log(" No se pudo recuperar info del bot");
                }
                let cont = this.state.hideLoad; this.setState({ hideLoad: cont-1});
            }).catch((error)=> {
                console.log(error);
                let cont = this.state.hideLoad; this.setState({ hideLoad: cont-1});
            });
    }

    /*  **** **** **** **** **** **** 
                CRUD OF WEBFORMS */

    /* handles if the form is to create a new webform or to edit an existing one (from the form builder) 
        @template: array with all the formbuilder data
    */
    saveFormBuilder = (template) => async (dispatch) => {
        let form = new FormData(document.getElementById('form-webform-builder'));
        if(form.get('title')){
            if(form.get('id')){  // is editing
                this.updateWebform(form, template);
            }else { // is creating a new
                this.createWebform(form, template);
            }
        }
        else{
            alert(i18next.t('message170'));
            dispatch(showError(i18next.t('message170'),2000));
                        
        }

      
    }

    /* updating an specific webform from form builder handler 
        @form = data form
        @template: array with all the formbuilder data
    */
    updateWebform (form, template) {
        let cont = this.state.hideLoad; this.setState({ hideLoad: cont+1});
        let data = {
            title : form.get('title'),
            template: JSON.stringify(template),
            botversion_id : this.state.botversion_id,
            id: this.state.currentWebForm.id
        };

        getHandlerPUT(HOST_API +'/api/ia/webforms/',data)
            .then((res) => {
                if(res.status==200){
                    var aux = this.state.listWebForms;
                    for(let i=0; i< aux.length; i++){
                        if(aux[i].id == res.data.webform.id){
                            aux[i].title = res.data.webform.title;
                            aux[i].reg_date = res.data.webform.reg_date;
                            break;
                        }
                    }   
                    // saves and returns to the webforms list
                    this.setState({ 
                        listWebForms: aux,
                        showFormBuilder: false,
                        showWebFormList: true
                    });
                    
                }else{
                  console.log(" No se pudo actualizar el webform");
                }
                let cont = this.state.hideLoad; this.setState({ hideLoad: cont-1});
            }).catch((error) => {
                console.log(error);
                let cont = this.state.hideLoad; this.setState({ hideLoad: cont-1});
            });
    }

    /* creating a webform from form builder
        @form = data form
        @template: array with all the formbuilder data
    */
    createWebform (form,  template) {
        let cont = this.state.hideLoad; this.setState({ hideLoad: cont+1});
        let data = {
            title : form.get('title'),
            template: JSON.stringify(template),
            botversion_id : this.state.botversion_id
        };

        getHandlerPOST(HOST_API +'/api/webforms/',data).
            then((res) => {
                if(res.status==200){
                    // add the new one to the list
                    let aux = this.state.listWebForms;
                    aux.push({
                        id: res.data.webform.id,
                        title: res.data.webform.title,
                        reg_date: res.data.webform.reg_date,
                        url:res.data.webform.url
                    })
                    // saves and returns to the webforms list
                    this.setState({ 
                        listWebForms: aux,
                        showFormBuilder: false,
                        showWebFormList: true
                    });
                    
                }else{
                  console.log(" No se pudo crear el webform");
                }
                let cont = this.state.hideLoad; this.setState({ hideLoad: cont-1});
            }).catch((error) => {
                console.log(error);
                let cont = this.state.hideLoad; this.setState({ hideLoad: cont-1});
            });
    }

    // Create a list of webforms Handler
    listWebForms () {
        let cont = this.state.hideLoad; this.setState({ hideLoad: cont+1});
        
        getHandlerGET(HOST_API +"/api/webforms/?botversion_id="+this.state.botversion_id).
            then((res) =>{
                if(res.status==200){
                    if(res.data.list){
                        var list = [];
                        for(let i=0; i<res.data.list.length;i++){

                            list.push({
                                id: res.data.list[i].id,
                                title: res.data.list[i].title,
                                url: res.data.list[i].url,
                                reg_date: res.data.list[i].reg_date
                            });
                        }
                        this.setState({ listWebForms: list});
                    }
                }else{
                    console.log(" No se pudo recuperar la lista de modulos");
                }
                let cont = this.state.hideLoad; this.setState({ hideLoad: cont-1});
            }).catch((error)=> {
                console.log(error);
                let cont = this.state.hideLoad; this.setState({ hideLoad: cont-1});
            });

    }

    /* Get an specific WebForm info Handler
        id = webform_id
    */
    getWebFormInfo (id) {
        let cont = this.state.hideLoad; this.setState({ hideLoad: cont+1});
        this.myHelp = id;
        getHandlerGET(HOST_API +"/api/webforms/?id="+escape(id)).
            then((res) =>{
                if(res.status==200){
                    let aux = {};
                    if(res.data.webform.template){
                        let aux_array = JSON.parse(res.data.webform.template);
                        let kObj = Object.keys(aux_array);
                        var template = [];
                        kObj.forEach((k)=>{
                            template.push(aux_array[k]);
                        });

                        aux = {
                            id : this.myHelp,
                            template: template,
                            title: res.data.webform.title,
                            reg_date: res.data.webform.reg_date
                        };
                        this.setState({ 
                            currentWebForm: aux,
                            showWebFormList : false,
                            showFormBuilder : true
                        });

                    } else {
                        aux = {
                            id : this.myHelp,
                            url: res.data.webform.url,
                            title: res.data.webform.title,
                            reg_date: res.data.webform.reg_date
                        };
                        this.setState({ 
                            currentWebForm: aux,
                            showWebFormList : false,
                            showBindForm : true
                        });
                                   
                    }


                    
                }else{
                    console.log(" No se pudo recuperar la informacion del webform");
                }
                let cont = this.state.hideLoad; this.setState({ hideLoad: cont-1});
            }).catch((error)=> {
                console.log(error);
                let cont = this.state.hideLoad; this.setState({ hideLoad: cont-1});
            });

    }

    /* deletes a Webform handler */
    deleteWebForm = (id) => {

        let cont = this.state.hideLoad; this.setState({ hideLoad: cont+1});

        let data = {
            id: id
        };
        this.myHelp = data;

        getHandlerDELETE(HOST_API +"/api/webforms/", data).
            then((res) =>{
                if(res.status==200){
                    var aux = this.state.listWebForms;
                    for(let i=0; i<aux.length; i++){
                        if(aux[i].id == this.myHelp.id){
                            aux.splice(i,1);
                            this.setState({ listWebForms: aux});
                            break;
                        }
                    }

                }else{

                    console.log(" No se pudo eliminar el webform");
                }
                let cont = this.state.hideLoad; this.setState({ hideLoad: cont-1});
            }).catch((error)=> {
                console.log(error);
                let cont = this.state.hideLoad; this.setState({ hideLoad: cont-1});
            });
    }

    /* Creating a new Webform with bind */
    submitWebFormBind = (event) => {
        event.preventDefault();
        let cont = this.state.hideLoad; this.setState({ hideLoad: cont+1});
        let form = new FormData(event.target);

        let data = {
            id : form.get('id'),
            title : form.get('title'),
            url: form.get('url'),
            botversion_id : this.state.botversion_id
        };

        if(data.id){ // is updating
            getHandlerPUT(HOST_API +'/api/webforms/',data).
                    then((res) => {
                        if(res.status==200){
                            // add the new one to the list
                            let aux = this.state.listWebForms;

                            for(let i=0; i<aux.length; i++){
                                if(aux[i].id == res.data.webform.id){
                                    aux[i].id= res.data.webform.id,
                                    aux[i].title= res.data.webform.title,
                                    aux[i].url= res.data.webform.url,
                                    aux[i].reg_date= res.data.webform.reg_date
                                    break;
                                }
                            }

                            // saves and returns to the webforms list
                            this.setState({ 
                                listWebForms: aux,
                                showBindForm: false,
                                showWebFormList: true
                            });
                            
                        }else{
                          console.log(" No se pudo editar el webform");
                        }
                        let cont = this.state.hideLoad; this.setState({ hideLoad: cont-1});
                    }).catch((error) => {
                        console.log(error);
                        let cont = this.state.hideLoad; this.setState({ hideLoad: cont-1});
                    });

        } else { // is creating
            getHandlerPOST(HOST_API +'/api/webforms/',data).
                then((res) => {
                    if(res.status==200){
                        // add the new one to the list
                        let aux = this.state.listWebForms;
                        aux.push({
                            id: res.data.webform.id,
                            title: res.data.webform.title,
                            url: res.data.webform.url,
                            reg_date: res.data.webform.reg_date
                        })
                        // saves and returns to the webforms list
                        this.setState({ 
                            listWebForms: aux,
                            showBindForm: false,
                            showWebFormList: true
                        });
                        
                    }else{
                      console.log(" No se pudo crear el webform");
                    }
                    let cont = this.state.hideLoad; this.setState({ hideLoad: cont-1});
                }).catch((error) => {
                    console.log(error);
                    let cont = this.state.hideLoad; this.setState({ hideLoad: cont-1});
                });
        }


    }


    /*   **** **** **** **** **** **** 
                    VIEWS TOGGLES */


    /* is going to use the form to create a new webform with the form builder */
    letCreateFormBuilder = (event) =>{

        this.setState({
            showFormBuilder : true,
            showWebFormList : false,
            currentWebForm : {
                id: "",
                title: "",
                template: [],
                reg_date: ""
            }
        })

    }

    /* is going to use the form to edit the webform and decide which form template use
        @wf = {} the current Webform
    */
    letEditWebForm = (id) =>{
        this.getWebFormInfo(id);
    }


    /* cancels or saves the Webform from the form builder*/
    goBackFromFormBuilder = (event) =>{

       if (confirm(i18next.t('message168'))) {
            this.setState({
                showFormBuilder : false,
                showWebFormList : true
            })   
        }
        
    }

    /* is going to use the form to create a new webform with the binding form */
    letCreateForm = (event) =>{
        this.setState({
            showBindForm : true,
            showWebFormList : false,
            currentWebForm : {
                id: "",
                title: "",
                url: "",
                reg_date: ""
            }
        });

    }

    /* cancels or saves the Webform from the binding form*/
    goBackFromForm = (event) =>{
       if (confirm(i18next.t('message168'))) {
            this.setState({
                showBindForm : false,
                showWebFormList : true
            })   
        }
    }


    render() {
        return (
        <div>
          <BotWebFormsLayout 
          saveFormBuilder={this.saveFormBuilder}
          currentWebForm={this.state.currentWebForm}
          ia_activated={this.state.ia_activated}
          botId={this.state.botId}
          listWebForms={this.state.listWebForms}
          WebFormSubmit={this.WebFormSubmit}
          letCreateFormBuilder={this.letCreateFormBuilder}
          letEditWebForm={this.letEditWebForm}
          goBackFromFormBuilder={this.goBackFromFormBuilder}
          letCreateForm={this.letCreateForm}
          goBackFromForm={this.goBackFromForm}
          showBindForm={this.state.showBindForm}
          showFormBuilder={this.state.showFormBuilder}
          showWebFormList={this.state.showWebFormList}
          deleteWebForm={this.deleteWebForm}
          submitWebFormBind={this.submitWebFormBind}
          />

          <ModalLoad hideLoad={this.state.hideLoad} />
          </div>
        )
    }
}

export default BotWebForms;