// Actions
export const LOGIN_ACTIONS_TYPES = {
    requestLogin: () => 'REQUEST_LOGIN',
    successLogin: () => 'SUCCESS_LOGIN',
    errorLogin: () => 'ERROR_LOGIN',
    requestLogout: () => 'REQUEST_LOGOUT',
    getUserLogged:() => 'GET_USER_LOGGED',
    loadingLog: () => 'LOADING_LOGIN',
    getAuthorization: () => 'GET_AUTHORIZATION',
    requestOtpsendLogin: () => 'REQUEST_OTP_SEND_LOGIN', 
    requestOtpLogin:() => 'REQUEST_OTP_LOGIN',
    requestChangepassword:() => 'REQUEST_CHANGE_PASSWORD',
    requestChangepasswordMsg: () => 'REQUEST_CHANGE_PASSWORD_MSG',
    requestBlockUser: () => 'REQUEST_BLOCK_USER',
    requestPasswordsendLogin: () => 'REQUEST_PASSWORD_SEND_LOGIN',
    requestUsernamesendLogin: () => 'REQUEST_USERNAME_SEND_LOGIN',
    getEmailUsarAuthorization: () => 'REQUEST_EMAILNAME_SEND_LOGIN',
    //errorAuthorization: () => 'ERROR_AUTHORIZATION',

  };
  