import { AbstractNodeFactory } from "storm-react-diagrams";
import React from "react";

import CATNodeWidget from "./CATNodeWidget";
import CATNodeModel from "./CATNodeModel";

class CATNodeFactory extends AbstractNodeFactory {
	constructor() {
		super("catalog");
	}

	generateReactWidget(diagramEngine, node) {
		return <CATNodeWidget node={node} />
	}

	getNewInstance() {
		return new CATNodeModel();
	}
}

export default CATNodeFactory