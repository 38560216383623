import React, {Component} from 'react';
import { Button,Modal,ModalHeader,ModalBody,ModalFooter,Form,
    FormGroup,Table,Input,Col,Row,Label,Nav,NavItem,TabContent,
    TabPane,NavLink,UncontrolledTooltip} from 'reactstrap';
import Switch from "react-switch";
import '../../../../components/search-variable.css';
import IASelector from '../../../../components/ia';
import Select from 'react-select';
import TitleOperator from "../../../../components/title-operator";
import MentionsAlone from '../../../../components/mentions/metions-alone';
import Mentions from '../../../../components/mentions/mentions';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import TabAdvanced from "../../../../components/box/tab_advanced";
import i18next from '../../../../../i18n';

class APILayout extends Component {

    constructor(props){
        super(props)
        this.state = {
        activeTab: '1',
        varSelected: {label:'', value:''}
        };
        this.toggleTab = this.toggleTab.bind(this);
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
          this.setState({
            activeTab: tab
          });
        }
    }

    getTextFromKey = (varList, key) => {
        let var_list = varList || [];
        let items = var_list.filter((item) => (((item.id == 0 && `[ses=${item.text}]` == key) || item.id == key) )
        );

        return (items.length > 0) ? items[0].name : "";
    }

    optionsVariable = () => {
        const var_list = this.props.varList || [];
        return var_list.filter((item) =>( item.text && item.text.length > 0))
        .map((item) => ({ value: ((item.id == 0 )?  `[ses=${item.text}]` : item.id), label: item.name }));
    }

    getNameFromId = (ID) => {
        const var_list = this.props.varList || [];
        const tem = var_list.filter((item) => ((item.id == 0 && `[ses=${item.text}]` == ID) || item.id == ID));
        return (tem.length > 0) ? tem[0].name : 'Selecciona...';
    }

    changeValueParameter = (key, id) => {
        const varSelected = {label: this.getNameFromId(id) , value: id};
        this.setState({varSelected});
        this.props.changeValueParameter(key, id);
    }

    enviados = (h_keys,kh_keys) => {
        if(kh_keys && kh_keys.length > 0){  
            let enviados = [];
            kh_keys.forEach((k2) => {
                enviados.push(<tr key={k2}>
                    <td style={{width:'40%'}}>
                        <span>{h_keys[k2].value}</span>
                    </td>
                    <td style={{width:'40%'}}>
                        <strong>{ this.getTextFromKey(this.props.varList, h_keys[k2].key) }</strong>
                    </td>
                    <td style={{width:'20%'}}>
                        <Button color="info" size="lg" onClick={() => this.props.removeParameter(k2) }><FontAwesomeIcon icon={faTrash} /></Button>
                    </td>
                </tr>)
            })
            return enviados;
        }
    } 

    custom_keys = (ckeys) =>{
        let custom_keys = [];
        ckeys.forEach((k, index) => {
            if(k == null)
                return;
            custom_keys.push(<tr key={index}>
                <td style={{width:'40%'}}>
                    <Input type="text" className="inputModal" placeholder={i18next.t('name_key')} value={k.name} onChange={(event) => this.props.updateCustomKey(event, index, 'name') }></Input>
                </td>
                <td style={{width:'45%'}}>
                <Input type="text" className="inputModal" placeholder={i18next.t('value')} value={k.text} onChange={(event) => this.props.updateCustomKey(event, index, 'text') }></Input>
                </td>
                <td style={{width:'15%'}}> 
                    <Button color="info" size="lg" onClick={() => this.props.removeCustomKey(index) }><FontAwesomeIcon icon={faTrash} /></Button>
                </td>
            </tr>)
        });
        return custom_keys;
    }

    custom_headers = (ckeys) =>{
        let custom_headers = [];
        ckeys.forEach((k, index) => {
            if(k == null)
                return;
            custom_headers.push(<tr key={index}>
                <td style={{width:'40%'}}>
                    <Input type="text" className="inputModal" placeholder={i18next.t('name_key')} value={k.name} onChange={(event) => this.props.updateItemHeader(event, index, 'name') }></Input>
                </td>
                <td style={{width:'45%'}}>
                <Input type="text" className="inputModal" placeholder={i18next.t('value') + ' '} value={k.text} onChange={(event) => this.props.updateItemHeader(event, index, 'text') }></Input> 
                </td>
                <td style={{width:'15%'}}> 
                    <Button color="info" size="lg" onClick={() => this.props.removeCustomHeader(index) }><FontAwesomeIcon icon={faTrash} /></Button>
                </td>
            </tr>)
        });
        return custom_headers;
    }
    
    recibidos = irecibidos => {
        
        let recibidos = [];
        irecibidos.forEach((k, index) => {
            if(k == null)
                return;
            recibidos.push(<tr key={index}>
                <td style={{width:'35%'}}>
                    <Input type="text" className="inputModal" placeholder={i18next.t('name_key')} value={k.name} onChange={(event) => this.props.updateReceivedKey(event, index, 'name') } />
                </td>
                <td style={{width:'20%'}}>
                    <Input type="select" name="select" onChange={(event) => { this.props.updateValidation(index, event) }} value={k.is_var}>
                        <option value="">- {i18next.t('select_one')} -</option>
                        <option value={'true'}>{i18next.t('yes')}</option>
                        <option value={'false'}>No</option>
                    </Input>
                </td>
                <td style={{width:'30%'}}>
                    <Input type="text" hidden={k.is_var != 'true'} className="inputModal" placeholder={i18next.t('name_var')} onChange={(event) => { this.props.updateVariable(index, event) }} value={k.var_name}/>
                </td>
                <td style={{width:'15%'}}>
                    <Button color="info" size="lg" onClick={() => this.props.removeReceivedKey(index) }><FontAwesomeIcon icon={faTrash} /></Button>
                </td>
            </tr>)
        });
        return recibidos;
    }

    render(){
        let headers = this.props.headers || {};
        let irecibidos = this.props.received_headers || [];
        let h_keys = this.props.h_keys;
        let kh_keys = Object.keys(h_keys);
        let ckeys = this.props.custom_keys;
        let enviados = this.enviados(h_keys,kh_keys)
        let custom_keys = this.custom_keys(ckeys)
        let cheaders = this.props.custom_headers;
        let custom_headers = this.custom_headers(cheaders)
        let recibidos = this.recibidos(irecibidos);

        const customStyles = {
            ///.....
            menuPortal: provided => ({ ...provided, zIndex: 9999 }),
            menu: provided => ({ ...provided, zIndex: 9999 })
            ///.....
          }
          
        return (
            <React.Fragment>
                <Modal className="api" isOpen={this.props.isOpen} toggle={this.props.closeModal} fade size="lg">
                    <ModalHeader toggle={this.props.closeModal}>{i18next.t('config_api')}</ModalHeader>
                    <ModalBody>
                    <Form role="form"> 
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                    className={classnames({ active: this.state.activeTab === '1' })}
                                    onClick={() => { this.toggleTab('1'); }} >
                                    {i18next.t('basic')}
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                    className={classnames({ active: this.state.activeTab === '2' })}
                                    onClick={() => { this.toggleTab('2'); }}>
                                    {i18next.t('advanced')}
                                    </NavLink>
                                </NavItem>
                            </Nav> 
                            <TabContent activeTab={this.state.activeTab}>
                                <TabPane tabId="1">
                                    <Row>
                                        <Col sm="12">
                                            <FormGroup>
                                                <TitleOperator titleOperator={this.props.titleOperator} bindValue={this.props.getTitleOperator}/>
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>{i18next.t('url_api')} <i className="fa fa-info-circle" id="tooltipEnunciado"></i></Label>
                                                <UncontrolledTooltip placement="right" target="tooltipEnunciado"> {i18next.t('url_api')} {i18next.t('will_be_consumed')}</UncontrolledTooltip>
                                    
                                                    <MentionsAlone varList={this.props.varList}  defaultValue={this.props.url_api || ''}  updateItem={this.props.updateItem} maxlength={'2000'} msg_error={i18next.t('msg_error_limit')}/>
                                                    { this.props.error_url && 
                                                        <span className="warning-character">{i18next.t('field_required')}</span>
                                                    }
                                            </FormGroup>
                                            <Row>
                                            <Col sm="6">
                                            <FormGroup>
                                                <Label>
                                                {i18next.t('validate_result')}
                                                    <span>  </span>
                                                    <Switch
                                                    onChange={this.props.handleValidate}
                                                    checked={this.props.validate}
                                                    id="normal-switch"/>
                                                </Label>
                                            </FormGroup>
                                            </Col>
                                            <Col sm="6">
                                            <FormGroup>
                                                <Label>
                                                {i18next.t('message3')}
                                                    <span>  </span>
                                                    <Switch
                                                    onChange={this.props.handleValidateSession}
                                                    checked={this.props.send_session_id}
                                                    id="session_id-switch"/>
                                                </Label>
                                                
                                            </FormGroup>
                                            </Col>
                                            </Row>
                                            <Row>
                                                <Col md={6}>
                                                    <FormGroup>
                                                    
                                                        <Label>{i18next.t('send_method')+': '} <i className="fa fa-info-circle" id="tooltipMETODO"></i></Label>
                                                        <UncontrolledTooltip placement="right" target="tooltipMETODO"> {i18next.t('message4')} (GET o POST)</UncontrolledTooltip>
                                    
                                                        <Input type="select" onChange={(event) => { this.props.updateMethod(event) }} value={this.props.method}>
                                                            <option value="">{i18next.t('select_option')}</option>
                                                            <option value="method_post">POST</option>
                                                            <option value="method_get">GET</option>
                                                            <option value="method_put">PUT</option>
                                                            <option value="method_delete">DELETE</option>
                                                            <option value="method_patch">PATCH</option>
                                                        </Input>
                                                        { this.props.error_method && 
                                                            <span className="warning-character">{i18next.t('field_required')}</span>
                                                        }
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6}>
                                                    <FormGroup>
                                                            <Label>{i18next.t('shipping_coding')+': '} <i className="fa fa-info-circle" id="tooltipCodificacion"></i></Label>
                                                            <UncontrolledTooltip placement="right" target="tooltipCodificacion"> {i18next.t('type_coding')} (JSON o Form Data)</UncontrolledTooltip>
                                    
                                                            <Input type="select" onChange={(event) => { this.props.updateEncType(event) }} value={this.props.enctype}>
                                                                <option value="">{i18next.t('select')}</option>
                                                                <option value="body_JSON">JSON</option>
                                                                <option value="body_Form_Data">Form Data</option>
                                                                <option value="body_Url_Encoded">Url Encoded</option>
                                                            </Input>
                                                            { this.props.error_encode && 
                                                                <span className="warning-character">{i18next.t('field_required')}</span>
                                                            }
                                                        </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm="12">
                                                    <h2>{i18next.t('headers')}</h2>
                                                    <Table>
                                                        <thead>
                                                            <tr>
                                                                <th style={{width:'40%'}}>{i18next.t('name_key')}</th>
                                                                <th style={{width:'45%'}}>{i18next.t('value')}</th>
                                                                <th style={{width:'15%'}}>{i18next.t('action')}</th>
                                                            </tr>
                                                        </thead>                                    
                                                        <tbody>
                                                            {custom_headers}
                                                        </tbody>
                                                    </Table>
                                                    <Button className="offset-4 col-4" color="info" size="lg" onClick={this.props.addCustomHeader}>{i18next.t('add_headers')}</Button>
                                                </Col>
                                                </Row>
                                                <br />
                                            <Row>
                                                <Col sm="6">
                                                    <h2>{i18next.t('data_sended')}</h2>
                                                    <Table>
                                                        <thead>
                                                            <tr>
                                                                <th style={{width:'40%'}}>{i18next.t('name_key')}</th>
                                                                <th style={{width:'45%'}}>{i18next.t('value')}</th>
                                                                <th style={{width:'15%'}}>{i18next.t('actions')}</th>
                                                            </tr>
                                                        </thead>                                    
                                                        <tbody>
                                                            <tr>
                                                                <td style={{width:'40%'}}><Input type="text" onChange={ (e) => { this.props.changeValueParameter('value', e.target.value) } }  /></td>
                                                                <td style={{width:'45%'}}>
                                                                    <Select
                                                                        id={'optionVar'}
                                                                        name={'optionVar'}
                                                                        onChange={(e) => { this.changeValueParameter('key', e.value) }}
                                                                        value={this.state.varSelected}
                                                                        options={this.optionsVariable()}
                                                                        placeholder={'Selecciona ...'}
                                                                        isMulti={false}
                                                                        menuPortalTarget={document.body}
                                                                        menuPosition={'fixed'} 
                                                                        styles={customStyles}
                                                                    />
                                                                </td>
                                                                <td style={{width:'15%'}}>
                                                                    <Button color="success" onClick={this.props.addParameter}><FontAwesomeIcon icon={faPlus} /></Button>
                                                                </td>
                                                            </tr>
                                                            {enviados}
                                                            {custom_keys}
                                                        </tbody>
                                                    </Table>
                                                    <Button className="offset-4 col-4" color="info" size="lg" onClick={this.props.addCustomkey}>{i18next.t('add_key')}</Button>
                                                </Col>
                                                <Col sm="6">
                                                    <h2>{i18next.t('data_received')}</h2>
                                                    { this.props.error_recibido && 
                                                        <span className="warning-character">{i18next.t('field_required')}</span>
                                                    }
                                                    <Table>
                                                        <thead>
                                                            <tr>
                                                                <th style={{width:'35%'}}>{i18next.t('name_key')}</th>
                                                                <th style={{width:'20%'}}></th>
                                                                <th style={{width:'30%'}}></th>
                                                                <th style={{width:'15%'}}>{i18next.t('actions')}</th>
                                                            </tr>
                                                        </thead>                                    
                                                        <tbody>
                                                            {recibidos}
                                                        </tbody>
                                                    </Table>
                                                    <Button className="offset-4 col-4" color="info" size="lg" onClick={this.props.addReceivedKey}>{i18next.t('add_key')}</Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId="2">
                                    <Row>
                                        <Col sm="6">
                                            <IASelector botversion_id={this.props.botversion_id} operator_id={this.props.operator_id} ia={this.props.ia} getIAId={this.props.getIAId} />
                                        </Col>
                                    </Row>
                                </TabPane>
                            </TabContent>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Col sm="6" align="right">
                            <Button color="success" size="lg" type="button" onClick={this.props.toggleModuleModal} >{i18next.t('finish')}</Button>
                        </Col>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}
  
export default APILayout