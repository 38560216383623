import React from 'react';
import Admin from '../../admin/containers/admin';

import SliceMenuApp from '../../admin/components/slice-menu';

import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';

import SelectedCompanies from '../../companies/selected-company';

import { MDBBtn, MDBTable, MDBTableBody, MDBTableHead  , MDBDataTable } from 'mdbreact';
import '../../../../static/css/dashboard.css';

/* IMAGE */
import newChart from '../../../../static/img/menu-icons/nuevo_bot.svg'

import { 
    Col,
    Row,
    Button, 
    Container,
    Card,
    FormGroup,
    Label,
    Input,
    Form,
    UncontrolledTooltip
  
  } from 'reactstrap';
  import ToolbarApp from '../../admin/components/toolbar';
  import BotMenuOptions from '../../menus/analytics-menu-options';

import ModalNuevoChart from '../modals/modal-nuevo-chart';
//import ModalViewMetric from '../../../components/infoViz/modal-ver-metrica';

import {iconLoad, 
        getAllMetrics,
        getAllCharts,
        changePageActive,
        deleteChart, 
        handleSearching,
        cleanListDashboard,
        viewChart, 
        selectedViewChart, 
        selectedUpdateChart, 
        selectedCreateChart,
        setSelectedCompany} from "../../../redux/actions/analyticsActions";
 

import { Link } from "react-router-dom";
import DataTableFilter from '../infoViz/common/dataTableFilter'
import i18next from '../../../../i18n';

function BotAdminChartLayout(props) {
    const {  activePage = 1, listAllCharts = [],selectedCompany } = props.analyticsReducer;
    const {  deleteChart, selectedViewChart, selectedUpdateChart, selectedCreateChart } = props;
    const {  listAllCompanies=[] } = props.homeReducer;

    var dataTable=null;
    if(listAllCharts[0]){
       
         dataTable = {
            columns: [

            {
                label: i18next.t('name'),
                field: 'name',
                sort: 'asc',
            //     width: 270
                },
              {
                label: i18next.t('metric')+'/'+i18next.t('measurement'),
                field: 'metric',
                sort: 'asc',
           //     width: 270
              },
              {
                label: i18next.t('graphic_type'),
                field: 'type_chart',
                sort: 'asc',
             //   width: 270
              },
              {
                label: i18next.t('edition_option'),
                field: 'options',
                sort: 'asc',
                width: 670,
                options: {
                    filter: false,
                    sort: false
                   }
              }],
              rows: []};

              const rows = listAllCharts.map((k, index) => {    
                k = JSON.parse(k);  
                const fin=[];
                fin.push(k.id); //OJO DEBE SER .NAME
                fin.push(k.metric.name);
                fin.push(k.type_chart.name);
                fin.push(
                    <Row key={k.id} className="d-flex align-items-center justify-content-center">
                             <Col className="col-2"> 
                                <Button key={"buttom_view_chart_"+k.id} title="Ver" type="submit" className="btn-square btn-link-eye" onClick={(e)=>{selectedViewChart(k)}}>
                                 <i className="fa fa-eye "></i>
                                 </Button>
                                 {/* <Link to={"/bot/"+props.botId+"/funnel/"+k.id} data-aiml="a" className="btn btn-square btn-link-eye"><i className="fa fa-eye"></i></Link> */}
                             </Col>
                               {/* <Col className="col-2">
                                 <Button type="submit" outline color="success" className="btn-square" onClick={(e)=>{selectedUpdateChart(k)}}>
                                 <i className="fa fa-edit "></i>
                                 </Button>   
                             </Col> 
                             <Col className="col-2">
                                 <Button type="submit" outline color="danger" className="btn-square" onClick={(e)=>{deleteChart(k.id)}}>
                                     <i className="fa fa-trash"></i>
                                 </Button>
                             </Col>    */}
                         </Row>
                )
                dataTable.rows.push(fin); 
                return k;
            });

        }

        const options = {
            filterType: 'multiselect',
            selectableRows:'none',
            resizableColumns: false,
            responsive: "scrollMaxHeight",
            rowsPerPage:10 ,
            print:false,
            download:false,
            viewColumns:false,
           
          };
          
              var title = ''

    return (
        <div className="BotLayout">
            
            <Admin>
           
             {/* <button id="tooltiNewChart" className="btn-float" onClick={(e)=>{selectedCreateChart()}}>
                <img src={newChart}/>
            </button>
            
            <UncontrolledTooltip placement="right" target="tooltiNewChart">Nuevo Gráfico</UncontrolledTooltip>
             */}
                <SliceMenuApp>
                    <BotMenuOptions admin={props.isAdmin} handleFind={props.handleFind}/>
                </SliceMenuApp>
                <Container fluid>
                <ToolbarApp>
                    <Row>
                        <Col sm="8" md="8" className="text pdtoolbar">
                            <h2 className="text-left ml-4 font-weight-bold">{i18next.t('admin_graphics')} <span className="btn-help"></span></h2>                      
                        </Col>
                        {props.isAdmin?(
                            <SelectedCompanies onchangeCompanies={onchangeCompanies} ></SelectedCompanies>
                      ):null}
                    </Row>
                </ToolbarApp>
            </Container>
            <Container fluid className="box-fluid">
                <Col sm="12" md="10" className="stadistics-container offset-md-1">
                    {
                         dataTable?(
                        <Row >
                            <Col xs={12} lg={12} >
                            <DataTableFilter
                                title={title}
                                data={ dataTable.rows}
                                columns={dataTable.columns}
                                options={options}
                                /> 

                            {/* <MDBDataTable 
                            striped
                            bordered
                            small
                            responsive
                            sorting={'true'}
                            order={['name', 'asc' ]}
                            entriesLabel="Número de registros"
                            paginationLabel={["Anterior", "Siguiente"]}
                            infoLabel={["Mostrando", "al", "de", "registros"]}
                            searchLabel="Buscar"
                            theadColor="indigo"
                            entriesOptions={[ 5, 10, 15,20,50,100 ]}
                            entries={5} 
                            //scrollX
                            // maxHeight="50vh"
                            data={dataTable}
                            /> */}
                            </Col>
                            </Row>
): 
<Col xs={12} lg={8} className="offset-lg-2 intro-funnels">
    <Row>
        <Col xs={12}><img src="/static/img/blank_state.png" className="img-fluid"/></Col>
        <Col xs={12}>
            <h2>{i18next.t('message52')}</h2>
            <h3>{i18next.t('message173')}</h3>
            <p>{i18next.t('message174')}</p>
        </Col>
    </Row>
</Col>
}
</Col>
</Container>
<ModalNuevoChart />

{/* <ModalViewChart 

/> */}
</Admin>
</div>
)

    //----------------------------------------------------------------------------
    //  CUANDO SE SELECCIONA UNA COMPAÑÍA ACTUALIZA EL SELECTEDCOMPANY
    //------------------------------------------------------------------------------
    async function onchangeCompanies(e)
    {
        const id_company=e.value;   
       
        await props.iconLoad();
        await props.setSelectedCompany(id_company);
    
    }

}


const mapStateToProps = ({analyticsReducer,homeReducer}) => {
    return {analyticsReducer, homeReducer};
};

const mapDispatchToProps = {
changePageActive,
deleteChart,
handleSearching,
viewChart,
selectedViewChart,
selectedUpdateChart,
cleanListDashboard,
selectedCreateChart,
setSelectedCompany,
getAllMetrics,
getAllCharts,
iconLoad
};

export default connect(mapStateToProps, mapDispatchToProps )(withRouter(BotAdminChartLayout));
