import React, { Component } from 'react';
import { connect } from "react-redux";

import ModalLoading from '../../../components/modals/Loading/moda-loading';

import AdminMetricasLayout from './bot_admin_metricas_layout';

import { isAuthorizationValid,getRolUser,isEmpty } from "../../../utils/commonFunction";


import { APPLICANT } from "../../../redux/listComponents";
import { isDidMount, willUnMount } from "../../../redux/actions/componentMountActions";
import { 
  getTockenCubejs,
  getAllCharts,
  getAllPalettes,
  getAllListCompanies, 
  getMetricsWithoutParent,
  getAllMetrics, 
  getAllUnits, 
  getAllDimensions, 
  setSelectedBot,
  iconLoad,
iconHideLoad } from "../../../redux/actions/analyticsActions";


class AdminMetricas extends Component {
    constructor(props){
      super(props);
      this.state = {
        isAdmin: false,
        isSuperUser: false,
        hideLoad: false,
        user:'',   
        tooltipFunctions:"Count: Cuenta el número de filas\n"+
                        "CountD: Cuenta el número de filas diferentes<br />"+
                        "Max: Devuelve el valor máximo<br />"+
                        "Min: Devuelve el valor mínimo<br />"+
                        "AVG: Devuelve el valor promedio<br />"+
                        "Sum: Devuelve el resultado de la suma<br />",
        functions:[{id:"count", name:"Count"},{id:"countd", name:"CountD"},{id:"max", name:"Max"},{id:"min", name:"Min"},{id:"avg", name:"AVG"},{id:"sum", name:"Sum"}],
        tooltipOperators:' + : Suma<br />'+
                          "- : Resta<br />"+
                          "* : Multiplicación<br />"+
                          "/ : División<br />"+
                          "% : Módulo<br />"+
                          "^ : Potencia<br />"+
                          "== : Igual<br />"+
                          "!= : Diferente<br />"+
                          " >  : Mayor<br />"+
                          " <  : Menor<br />"+
                          " >=  : Mayor o igual<br />"+
                          " <=  : Menor o igual<br />"+
                          "and: Valida que cumpla todos los criterios<br />"+
                          " or: Valida que cumpla alguno de los criterios<br />",
        operators:[{id:"plus", name: "+"},{id:"minus", name:"-"},{id:"multi", name:"*"},{id:"divide", name:"/"},{id:"module", name:"%"},{id:"potencia", name:"^"},{id:"equal", name:"=="},{id:"diferent", name:"!="},{id:"major", name:">"},{id:"minor", name:"<"},{id:"majorequal", name:">="},{id:"minorequal", name:"<="},{id:"and", name:"and"},{id:"or", name:"or"}],
       
    }
    }

    componentWillMount(){
        isAuthorizationValid()
        const { isAdmin = false, isSuper = false } = getRolUser();
        this.setState({
            isAdmin: isAdmin,
            isSuperUser: isSuper
        });
      
      }

      async componentDidMount(){
        const { analyticsReducer : { activePage,listAllMetrics, listAllUnits, listAllDimensions, listAllMeasures,listAllBotsbyCompany ,listAllDashboards, listAllCharts, AllPalettes} } = this.props;
        
        const { homeReducer : {listAllCompanies} } = this.props;
  
        this.props.isDidMount(APPLICANT.HOME());   
        
        //----------------------------------------------------------------
        //  PONE EL ICONO DE CARGA
        //----------------------------------------------------------------
        await this.props.iconLoad();

        //----------------------------------------------------------------
        //  ACTUALIZA EL TOCKEN DE CUBEJS
        //----------------------------------------------------------------
          await this.props.getTockenCubejs();

         //----------------------------------------------------------------
        //  LISTA TODAS LAS COMPAÑÍAS, COJE LA PRIMERA COMO SELECTCOMPANY
        //----------------------------------------------------------------
        if(isEmpty(listAllCompanies)){
          await this.props.getAllListCompanies();     
        }

        //----------------------------------------------------
        //  LISTA LAS METRICAS DE LA COMPAÑIA
        //----------------------------------------------------
        if(isEmpty(listAllMetrics)){
          await this.props.getAllMetrics(); 
        }
        else{
          this.forceUpdate();
        }
         //----------------------------------------------------
          //  OCULTA EL ICONO DE CARGA
          //----------------------------------------------------
        await this.props.iconHideLoad();
      }
      shouldComponentUpdate(nextProps, nextState) {
        //----------------------------------------------------
        //  SOLO EJECUTA EL UPDATE SI LA COMPAÑIA FUE CAMBIADA
        //----------------------------------------------------
        const nextCompany = nextProps.analyticsReducer.selectedCompany;
        const company = this.props.analyticsReducer.selectedCompany;
        
        return nextCompany !== company;
    }

      async componentWillUpdate(nextProps,nextState)
      {
        //-------------------------------------------------------------------
        //  LISTA LAS METRICAS DE LA COMPAÑIA SI SE SELECCIONA OTRA COMPAÑÍA
        //------------------------------------------------------------------- 
        const nextCompany = nextProps.analyticsReducer.selectedCompany;
        const company = this.props.analyticsReducer.selectedCompany;
    
        if(nextCompany!==company){
          //-------------------------------------------------------------------
          //  LISTA LAS METRICAS DE LA COMPAÑIA 
          //------------------------------------------------------------------- 
          await this.props.getAllMetrics(); 
           //----------------------------------------------------
          //  OCULTA EL ICONO DE CARGA
          //----------------------------------------------------
          await this.props.iconHideLoad();
        }

      }

  
      componentWillUnmount() {
        this.props.willUnMount(APPLICANT.HOME());
      }
  
  
showContent=()=>{
    const {  isLogin } = getRolUser();
    if(isLogin){
      return (
        <React.Fragment>
        <AdminMetricasLayout 
         isAdmin={this.state.isAdmin}
         isSuperUser={this.state.isSuperUser}
         tooltipFunctions={this.state.tooltipFunctions}
         functions={this.state.functions}
         tooltipOperators={this.state.tooltipOperators}
         operators={this.state.operators}
    />
 
    </React.Fragment>
     );
    }
    return <ModalLoading />
  }
     
    render() {
        return this.showContent();
    }
}

const mapStateToProps = ({ analyticsReducer, homeReducer, componentMountReducer }) => {
	return { analyticsReducer,homeReducer, componentMountReducer };
};

const mapDispatchToProps = {
  getMetricsWithoutParent,
  getAllMetrics,
  getAllUnits,
  getAllDimensions,
  getAllListCompanies, 
  getAllCharts,
  getAllPalettes,
  setSelectedBot,
  getTockenCubejs,
  isDidMount,
  willUnMount,
  iconLoad,
  iconHideLoad
  
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminMetricas);

