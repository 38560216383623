import { Redirect } from "react-router-dom";
import React from "react";
import { BOX_ACTIONS_TYPES } from '../types/boxActionsTypes';
import { HOME_ACTIONS_TYPES } from '../types/homeActionsTypes';
import { MODAL_LOAD_ACTIONS_TYPES } from "../types/loadStateActionsTypes";
import { logOut } from "../actions/loginActions";
import { getHandlerGET,getHandlerPOST,getHandlerDELETE,getHandlerPUT,getHandlerPOSTJson,getHandlerPUTJson } from "../../utils/handlersApi";
import { cerrarSesion, renameKeys,UTCFormatter, isEmpty } from "../../utils/commonFunction";
import { showSuccess, loading, showWarning, hideAlert } from './loadStateActions';
import { find, propEq, map, reject,pipe,mergeAll } from "ramda";
import i18next from "../../../i18n";

import moment from "moment";

const utf8 = require('utf8');

/**
 * ACTIONS BOXES
 */

export const iconLoad = () => (dispatch, getState) => {
    dispatch({
        type: MODAL_LOAD_ACTIONS_TYPES.loading(),
    });
}

export const iconHideLoad = () => (dispatch, getState) => {
   dispatch({
       type: MODAL_LOAD_ACTIONS_TYPES.hideLoad(),
   });
}

export const cleanSelection = () => (dispatch, getState) => {
    

    //Deseleccionar Box
    dispatch({
        type: BOX_ACTIONS_TYPES.selectedBoxOperator(),
        payload: []
    });

    //Deseleccionar Config Box
    dispatch({
        type: BOX_ACTIONS_TYPES.successGetConfigBoxOperator(),
        payload: []
    });

    //Deseleccionar Variables Box
    dispatch({
        type: BOX_ACTIONS_TYPES.setVariablesBoxOperator(),
        payload: []
    });

    //Deseleccionar Variables Dinámicas Box
    dispatch({
        type: BOX_ACTIONS_TYPES.setDinamicVariablesBoxOperator(),
        payload: []
    });

    

    //Deseleccionar Enunciado
    dispatch({
        type: BOX_ACTIONS_TYPES.setBotmsgText(),
        payload: ''
    });

    //Deseleccionar Encabezado
    dispatch({
        type: BOX_ACTIONS_TYPES.setHead(),
        payload: ''
    });

    //Deseleccionar Avatar
    dispatch({
        type: BOX_ACTIONS_TYPES. setAvatarid(),
        payload: ''
    });

    //Deseleccionar tipo de localizacion
    dispatch({
        type: BOX_ACTIONS_TYPES. selectedTypeLocation(),
        payload: []
    });
    

 }


//Crear nuevo Operador
export const createNewOperator = (data)=> async (dispatch, getState) => {
    
    const url = HOST_API +'/api/scheme/operator/';

    await getHandlerPOSTJson(url, data)
        .then((res)=>{
            if(res.status==200){
                if (res.data){
                
                    dispatch({
                        type: MODAL_LOAD_ACTIONS_TYPES.showSuccess(),
                        payload: i18next.t('create_operator_msg')
                    });

                                
                }
            }
        
        })
        .catch((error) => {
            
            dispatch({
                type: MODAL_LOAD_ACTIONS_TYPES.showWarning(),
                payload: i18next.t('operator_created_msg') + '\n' + error
            });
            const { response: { status } = { status } } = error; 
            if (status  && status == 403){
                logOut();
                cerrarSesion();
            }
        }); 
}
 //Actualizar información de un Operador
 export const updateOperator = (title,keyword,config,ia_id='')=> async (dispatch, getState) => {
    
    const {selectedBoxOperator } = getState().boxReducer;

    const data = {
        id: selectedBoxOperator.id,
        title,
        keyword,
        ia_id,
        config:JSON.stringify(config)
    };
 
     const url = HOST_API +'/api/scheme/operator/';

   
     await getHandlerPUT(url,data)
     .then((res)=>{
         if (res.status == 200) {
 
             dispatch({
                 type: MODAL_LOAD_ACTIONS_TYPES.showSuccess(),
                 payload: i18next.t('operator_edited_msg')
             });
             
             setTimeout(()=>{
                 
                 dispatch(iconHideLoad());
                  //dispatch(updateSchema());
             },1200); 
            
          
         }
     })
     .catch((error) => {
         console.log("ERROR - CATCH: "+error);
        setTimeout(()=>{
            dispatch({
                type: MODAL_LOAD_ACTIONS_TYPES.showWarning(),
                payload: i18next.t('operator_edited_msg2') + '\n' + error
            });
        },1200); 
         const { response: { status } = { status } } = error; 
         if (status  && status == 403){
             logOut();
             cerrarSesion();
         }
     });
 
 }
 
 export const updateConfigOperator = (operator_id,config) => async (dispatch,getState) => {
   
        const data = {
            id: operator_id,
            config:config
        };


        const url = HOST_API +'/api/scheme/operator/';

   
     await getHandlerPUT(url,data)
     .then((res)=>{
         if (res.status == 200) {
 
             dispatch({
                 type: MODAL_LOAD_ACTIONS_TYPES.showSuccess(),
                 payload: i18next.t('operator_edited_msg')
             });
             
             setTimeout(()=>{
                 
                 dispatch(iconHideLoad());
                  //dispatch(updateSchema());
             },1200); 
            
          
         }
     })
     .catch((error) => {
         console.log("ERROR - CATCH: "+error);
        setTimeout(()=>{
            dispatch({
                type: MODAL_LOAD_ACTIONS_TYPES.showWarning(),
                payload: i18next.t('operator_edited_msg2') + '\n' + error
            });
        },1200); 
         const { response: { status } = { status } } = error; 
         if (status  && status == 403){
             logOut();
             cerrarSesion();
         }
     });
 }
 
/* 
* Función para eliminar un modulo de la base de datos 
* @operator = int (es el id de la caja en base de datos) 
*/

export const removeModuleDB = (operator) => async (dispatch,getState) => {
   
    //data para enviar en el servicio web 
    const data = {
        id: operator,
    };

    const url = HOST_API +'/api/scheme/operator/';


 await getHandlerDELETE(url, data)
 .then((res)=>{
     if (res.status == 200) {

         dispatch({
             type: MODAL_LOAD_ACTIONS_TYPES.showSuccess(),
             payload: i18next.t('operator_delete_msg')
         });
         
         setTimeout(()=>{
             
             dispatch(iconHideLoad());
              //dispatch(updateSchema());
         },1200); 
        
      
     }
 })
 .catch((error) => {
     console.log("ERROR - CATCH: "+error);
    setTimeout(()=>{
        dispatch({
            type: MODAL_LOAD_ACTIONS_TYPES.showWarning(),
            payload: i18next.t('operator_delete_msg2') + '\n' + error
        });
    },1200); 
     const { response: { status } = { status } } = error; 
     if (status  && status == 403){
         logOut();
         cerrarSesion();
     }
 });
}

 //elimina el operador dinamico 
 export const deleteOperator = (id) => async (dispatch,getState) => {
 
    await getHandlerPOST(HOST_API + '/api/operator/dynamic_operator/' + id + '/delete')
        .then((res) => {
            if (res.status != 200)
                console.log(" No se pudo deleteOperator");
        }).catch((error) => {
            console.log("ERROR - CATCH: "+error);
            setTimeout(()=>{
                dispatch({
                    type: MODAL_LOAD_ACTIONS_TYPES.showWarning(),
                    payload: i18next.t('operator_delete_msg3') + '\n' + error
                });
            },1200); 
            const { response: { status } = { status } } = error; 
            if (status  && status == 403){
                logOut();
                cerrarSesion();
            }
        });
}

    /*  
     *  updateOperator 
     *  actualiza título y/o visible (si activa el operador dinámico) 
     * @titleOperator = string 
     * @visible _ int 
    */ 
    export const updateDinamicOperator = (id,data) => async (dispatch,getState) => {

        await getHandlerPOST(HOST_API +'/api/operator/dynamic_operator/'+id,data) 
            .then((res)=>{ 
                if(res.status!=200){ console.log('error título actualización')}else{ 
                    console.log('actualice el operador') 
                } 
            }).catch((error)=>{ 
                console.log('catch updateOperator',error); 
                if (error.response && error.response.status && error.response.status == 403){ 
                    this.cerrarSesion(); 
                } 
            }); 
    } 

//Crear nuevo Statement
export const createNewStatement = (operator,variable,node,type,i)=> async (dispatch, getState) => {
    
    const { text_blocks,buttons} = getState().boxReducer; 

   

    const {variablesBoxOperator,dinamicVariablesBoxOperator} = getState().boxReducer;
    
    let varNew = {}

    if(type=='variables')
    {
        varNew = variablesBoxOperator;
        node.extras.variables = variablesBoxOperator;
    }
    else if(type=='dinamicas')
    {
        varNew = dinamicVariablesBoxOperator;
        node.extras.dinamicVariables = dinamicVariablesBoxOperator;
    }
    else if(type=='var_dinamicas')
    {
        varNew = node.extras.text_blocks;
    }
    else if(type=='var_answer_blocks'){
        varNew = node.extras.answer_blocks;
    }
    else if(type=='var_cards'){
        const bt = text_blocks[i].buttons;
        varNew = [{
            text: bt[variable].title,
            name: bt[variable].title,
            is_var:'False',
            var_name:'',
            statement_id:''
        }]
    }
    else if(type=='var_form'){
        varNew = text_blocks;
    }
    else if(type=='var_buttons')
    {
        
        varNew = buttons;
    }
    else if(type=='headid')
    {
        varNew = [{
            text: (node.extras.is_var) ? node.extras.head : '',
            name: (node.extras.is_var) ? node.extras.head : '',
            is_var:node.extras.is_var?node.extras.is_var:'False',
            var_name:node.extras.is_var? node.extras.var_name:''
        }]
    }
    
    const data = {
        operator_id: operator,
        text: '',
        name:'',
        is_var:'False',
        var_name:''
    };

    const url = HOST_API +'/api/scheme/statement/';

    await getHandlerPOST(url, data)
    .then(async (res)=>{
        if(res.status==200){
            if (res.data){

                if(type=='variables')
                    node.extras.variables[variable].statement_id = res.data.statement.id;
                else if(type=='dinamicas')  
                    node.extras.dinamicVariables[variable].statement_id = res.data.statement.id;
                else if(type=='var_dinamicas'||type=='var_form')  
                    node.extras.text_blocks[variable].statement_id = res.data.statement.id;
                else if(type=='var_answer_blocks')
                   node.extras.answer_blocks[variable].statement_id = res.data.statement.id;
                else if(type=='headid')
                    node.extras.headid = res.data.statement.id;
                else if(type=='var_cards'){
                    node.extras.cards[i].buttons[variable].statement_id = res.data.statement.id;
                    varNew[0].statement_id = res.data.statement.id;
                    varNew[0].id = res.data.statement.id;
                    }
                else if(type=='var_buttons'){
                    node.extras.buttons[variable].statement_id = res.data.statement.id;
                    node.extras.buttons[variable].id = res.data.statement.id;
                }
                if( node.extras.statements_id)
                    node.extras.statements_id[res.data.statement.id] = varNew[variable].var_name?varNew[variable].var_name:varNew[variable].name;

                if(type!='headid' && type!='var_cards'){
                   
                    varNew[variable].statement_id = res.data.statement.id;
                    
                }
                if(type!='var_cards'){
                    varNew[variable].id = res.data.statement.id;
                    await dispatch(updateStatement(varNew[variable], node,type));
                }
                else
                    await dispatch(updateStatement(varNew[0], node,type));
               

                if(type=='variables'||type=='var_answer_blocks')
                {
                    dispatch({
                        type: BOX_ACTIONS_TYPES.setVariablesBoxOperator(),
                        payload: varNew
                    });
                }
                else if(type=='dinamicas')
                {
                    dispatch({
                        type: BOX_ACTIONS_TYPES.setDinamicVariablesBoxOperator(),
                        payload: varNew
                    });
                }
                else if(type=='var_cards')
                {
                    varNew = node.extras.cards

                    dispatch({
                        type: BOX_ACTIONS_TYPES.setTextBlock(),
                        payload: varNew
                    });
                }
                else if(type=='var_form')
                {
                   
                    dispatch({
                        type: BOX_ACTIONS_TYPES.setTextBlock(),
                        payload: varNew
                    });
                }
                else if(type=='var_buttons')
                {
                    dispatch({
                        type: BOX_ACTIONS_TYPES.setButtons(),
                        payload: varNew
                    });
                }
                
            }
        }
      
    })
    .catch((error) => {
        console.log("ERROR - CATCH: "+error);

        setTimeout(()=>{
            dispatch({
                type: MODAL_LOAD_ACTIONS_TYPES.showWarning(),
                payload: i18next.t('statement_create_msg') + '\n' + error
            });
        },1200);  
        const { response: { status } = { status } } = error; 
        if (status  && status == 403){
            logOut();
            cerrarSesion();
        }
    });
  
    

}

//Crear nuevo Statement
export const updateStatement = (data,node,type)=> async (dispatch, getState) => {
    
    const url = HOST_API +'/api/scheme/statement/';

    const {variablesBoxOperator,dinamicVariablesBoxOperator,text_blocks,buttons} = getState().boxReducer;
    
    let varNew = {}

    if(type=='variables'||type=='var_answer_blocks')
    {
        varNew = variablesBoxOperator;
        
    }
    else if(type=='dinamicas')
    {
        varNew = dinamicVariablesBoxOperator;
       
    }
   
    console.log('update statement data:',data)

    await getHandlerPUT(url, data)
    .then((res)=>{
        if(res.status==200){
            if (res.data){
            
                if(type=='variables')
                {
                    node.extras.variables = variablesBoxOperator;
                   
                    dispatch({
                        type: BOX_ACTIONS_TYPES.setVariablesBoxOperator(),
                        payload: varNew
                    });
                }
                else if(type=='dinamicas')
                {
                    node.extras.dinamicVariables = dinamicVariablesBoxOperator;

                    dispatch({
                        type: BOX_ACTIONS_TYPES.setDinamicVariablesBoxOperator(),
                        payload: varNew
                    });
                }
                else if(type=='var_form')
                {
                    node.extras.text_blocks = text_blocks;
                }
                else if(type=='var_buttons')
                    node.extras.buttons = buttons;
                              
            }
        }
        else
        {
            console.log("ERROR - ENDPOINT: "+res.msg);
            setTimeout(()=>{
                dispatch({
                    type: MODAL_LOAD_ACTIONS_TYPES.showWarning(),
                    payload: i18next.t('statement_edit_msg') + '\n' + res.msg
                });
            },1200);
        }
      
    })
    .catch((error) => {
        console.log("ERROR - CATCH: "+error);

        setTimeout(()=>{
            dispatch({
                type: MODAL_LOAD_ACTIONS_TYPES.showWarning(),
                payload: i18next.t('statement_edit_msg') + '\n' + error
            });
        },1200);  
        
        const { response: { status } = { status } } = error; 
        if (status  && status == 403){
            logOut();
            cerrarSesion();
        }
    });
}

//Eliminar Statement
export const removeStatement = (data)=> async (dispatch, getState) => {
    
    const url = HOST_API +'/api/scheme/statement/';

    await getHandlerDELETE(url, data)
    .then((res)=>{
               
        

        // dispatch({
        //     type: MODAL_LOAD_ACTIONS_TYPES.showSuccess(),
        //     payload: '¡El Statement fue eliminado!'
        // });

        // setTimeout(()=>{
            
        //     dispatch(iconHideLoad());
        //         //dispatch(updateSchema());
        // },1200);

    })
    .catch((error) => {
        console.log("ERROR - CATCH: "+error);
        setTimeout(()=>{
                dispatch({
                    type: MODAL_LOAD_ACTIONS_TYPES.showWarning(),
                    payload: i18next.t('statement_edit_msg') +data+'\n'+error
                });

         },1200);
        const { response: { status } = { status } } = error; 
        if (status  && status == 403){
            logOut();
            cerrarSesion();
        }
    });
}



//Agregar nuevo Statement dinámico
export const addNewStatementBlock = (data)=> async (dispatch, getState) => {
    
    const {dinamicVariablesBoxOperator} = getState().boxReducer;

    let newVar = dinamicVariablesBoxOperator;

    const kblocks = Object.keys(dinamicVariablesBoxOperator);

    for (let key of kblocks){
        console.log(key);
        if(dinamicVariablesBoxOperator[key].list==false)
        {
            //variable.list='true';
            newVar[key].list = true
            break;
        }
    }
    
    dispatch(setDinamicVariablesBoxOperator(newVar));


}

//Remover Statement dinámico
export const removeStatementBlock = (key)=> async (dispatch, getState) => {
    
    const {dinamicVariablesBoxOperator} = getState().boxReducer;

    let newVar = dinamicVariablesBoxOperator;

   
        console.log(key);
        if(dinamicVariablesBoxOperator[key].list==true)
        {
            //variable.list='true';
            newVar[key].list = false
            newVar[key].var_name = ''
        }
    
    
    dispatch(setDinamicVariablesBoxOperator(newVar));


}


//Update Statement dinámico
export const onchangeListColumnsLocation = (oldkey,key)=> async (dispatch, getState) => {
    
    const {dinamicVariablesBoxOperator} = getState().boxReducer;

    let newVar = dinamicVariablesBoxOperator;

    newVar[oldkey].list = false
    newVar[oldkey].var_name = ''

    newVar[key].list = true
    newVar[key].var_name = ''
      
    
    dispatch(setDinamicVariablesBoxOperator(newVar));


}


export const setListAllOperators = (list)=> async (dispatch, getState) => {
    dispatch({
        type: BOX_ACTIONS_TYPES.successGetListBoxOperator(),
        payload: list
    }); 
}
//
export const listAllOperators = ()=> async (dispatch, getState) => {

    const { selectedSchema } = getState().boxReducer;
    const url = HOST_API +"/api/scheme/operator/?scheme_id="+selectedSchema.id;
    
getHandlerGET(url)
.then((res) =>{
    if(res.status==200){
        if(res.data.operators){
            if(res.data.operators.length > 0){
                var items = [...res.data.operators];
                //a = array con resultados filtrados
                let a = items.filter(e => e.title && e.title.length > 0);
                var list = [];
                for(let i=0; i<a.length;i++) {
                    let temp ={
                        'title':a[i].title,
                        'id': a[i].id 
                    }
                    list.push(temp);
                }
               
                dispatch(setListAllOperators(list)); 
            }
        }
    }else{
        console.log(" No se pudo obtener la lista");
    }
}).catch((error)=> {
    console.log("ERROR - CATCH: "+error);
    setTimeout(()=>{
        dispatch({
            type: MODAL_LOAD_ACTIONS_TYPES.showWarning(),
            payload: i18next.t('operator_list_msg') + ' \n' + error
        });
    },1200);
    const { response: { status } = { status } } = error; 
            if (status  && status == 403){
                logOut();
                cerrarSesion();
            }
});
}


 // Selecciona un operador
 export const setSelectedOperator = (id_operator) => async (dispatch, getState) => {


    const { listAllOperators } = getState().boxReducer;

    if(listAllOperators && listAllOperators.length>0){

        const operator = listAllOperators.find( ({ id }) => id == id_operator)

        if(operator){
            dispatch({
                type: BOX_ACTIONS_TYPES.selectedBoxOperator(),
                payload: operator
            }); 
    
            dispatch({
                type: BOX_ACTIONS_TYPES.successGetConfigBoxOperator(),
                payload: operator.config
            }); 
        }
        else
            console.log("No se encontro el operador");
        
    }

}   

    /*
    const url = HOST_API +"/api/scheme/operator/?id="+ id_operator;

    await dispatch({
        type: BOX_ACTIONS_TYPES.requestGetConfigBoxOperator()
    });
       
    await getHandlerGET(url)
    .then((res)=>{
        if(res.status==200){
            if(res.data){
                let operator = res.data.operator;

                operator.title = decodeURIComponent(res.data.operator.title)
                
                 dispatch({
                    type: BOX_ACTIONS_TYPES.selectedBoxOperator(),
                    payload: operator
                }); 

               let conf = res.data.config;

                
                dispatch({
                    type: BOX_ACTIONS_TYPES.successGetConfigBoxOperator(),
                    payload: conf
                }); 
               
    
            }
        }else{
            dispatch({
                type: BOX_ACTIONS_TYPES.errorGetConfigBoxOperator(),
                payload:'¡ No se pudo obtener la información del operador!'
            });   
 
        }
   
    })
    .catch((error) => {
        console.log("ERROR - CATCH: "+error);
        setTimeout(()=>{
            dispatch({
                type: BOX_ACTIONS_TYPES.errorGetConfigBoxOperator(),
                payload:'¡ Error, al obtener la configuración del operador!'
            });   
        },1200);

           
        const { response: { status } = { status } } = error; 
            if (status  && status == 403){
                logOut();
                cerrarSesion();
            }
    
 
    }); 
 
    const { error } = getState().homeReducer;
    if (error){
        dispatch({
            type: MODAL_LOAD_ACTIONS_TYPES.showWarning(), 
            payload: error,
        });
    }*/
 

 // Trae la lista de Variables
 export const setVarList = () => async (dispatch, getState) => {

  const {botVersionId,schema} = getState().botReducer;

  if(schema && schema.id){
    const url = HOST_API + '/api/scheme/statements/bot/' + botVersionId + '/' + schema.id;

   
    await getHandlerGET(url)
    .then(async (res)=>{
        if(res.status==200){
            if(res.data){
                console.log("Statements:",res.data.statements)
                
                 dispatch({
                    type: BOX_ACTIONS_TYPES.setVarList(),
                    payload: res.data.statements
                }); 
                /**
                 * Actualizar varListSelected 
                 * */
                let optionsList = [];

                const double = k => ({id: k.id,value: k.id,is_var: k.is_var,name: k.name,operator_id:k.operator_id,text:k.text,label:k.text,var_name:k.var_name}) ;

                const result=map(double,res.data.statements)
      
                optionsList = reject(propEq('label', ''))(result);
                optionsList = reject(propEq('is_var', false))(optionsList);

                 dispatch({
                    type: BOX_ACTIONS_TYPES.setVarListSelected(),
                    payload: optionsList
                }); 
              
            }
        }else{
            dispatch({
                type: BOX_ACTIONS_TYPES.errorGetConfigBoxOperator(),
                payload:i18next.t('statements_list_msg')
            });   
 
        }
   
    })
    .catch((error) => {
        console.log("ERROR - CATCH: "+error);
        setTimeout(()=>{
            dispatch({
                type:  MODAL_LOAD_ACTIONS_TYPES.showWarning(),
                payload: i18next.t('statements_list_msg2')
            });   
        },1200);

           
        const { response: { status } = { status } } = error; 
        if (status  && status == 403){
            logOut();
            cerrarSesion();
        }
    
 
    }); 
 
    const { error } = getState().homeReducer;
    if (error){
        dispatch({
            type: MODAL_LOAD_ACTIONS_TYPES.showWarning(), 
            payload: error,
        });
    }
  }

    
 
}

export const setCalendarDate = (platform,bot_version_id,session_id,operator_id,RedirectonSuccess)=> async (dispatch, getState) => {
    
    const {msmbtnPostback,data_dict, configBoxOperator} = getState().boxReducer;

    if(platform=='web')
    {
        var respuesta = {};
        respuesta.action = 'close_continue';
        respuesta.reply = msmbtnPostback;
        respuesta.type = configBoxOperator.item_type;
        respuesta.data_dict=data_dict;
        respuesta.operator_type='calendar';

        var r = JSON.stringify(respuesta);
        parent.postMessage(r, window.location.origin + '/bot');

    //    window.getCalendarDate (startDateSelected, endDateSelected,item_type);
    }
    else 
    {
        const url = HOST_API +'/api/whatsapp/calendar/webhook/'+bot_version_id;

        dispatch(getCalendarDate(session_id,operator_id));

        const {data_ws} = getState().boxReducer;

        const data= data_ws;
        
        await getHandlerPOSTJson(url, data)
        .then((res)=>{
            if(res.status==200){
                if (res.data){
                
                    dispatch({
                        type: MODAL_LOAD_ACTIONS_TYPES.showSuccess(),
                        payload: i18next.t('whatsapp_message')
                    });

                    const dateJson = JSON.parse(data);
                const urlR = 'https://api.whatsapp.com/send?phone=14384763261&text='+dateJson.messages[0].text;

                window.location.replace(urlR);

                //dispatch(renderRedirect(urlR));
                RedirectonSuccess(urlR);
                                
                }
            }
        
        })
        .catch((error) => {
            console.log("ERROR - CATCH: "+error);
            dispatch({
                type: MODAL_LOAD_ACTIONS_TYPES.showWarning(),
                payload: i18next.t('whatsapp_message2') + ' \n' + error
            });
            const { response: { status } = { status } } = error; 
            if (status  && status == 403){
                logOut();
                cerrarSesion();
            }
        });
    }
        
}

export const renderRedirect = (url)=> async (dispatch, getState) =>  {
  
    return <Redirect to=''url />
  
}
export const getCalendarDate = (session_id,operator_id)=> async (dispatch, getState) =>  {

    const {msmbtnPostback,data_dict, configBoxOperator} =getState().boxReducer;

    /*let format =[];
    let msmbtnPostback = '';


    switch (type) {
        case 'date_calendar':
            msmbtnPostback = `${startDate.format('DD/MM/YYYY')}`;
            format = [{'date':`${startDate.format('DD/MM/YYYY')}`}];
            break;
        case 'datetime_calendar':
            msmbtnPostback = `${startDate.format('DD/MM/YYYY h:mm a')}`
            format = [{'date':`${startDate.format('DD/MM/YYYY')}`,'hour':`${startDate.format('hh:mm')}`}];
            break;
        case 'time_calendar':
            msmbtnPostback = `${startDate.format('h:mm a')}`
            format = [{'hour':`${startDate.format('hh:mm')}`}];
            break;
        case 'daterange_calendar':
            msmbtnPostback = `Fecha inicio: ${startDate.format('DD/MM/YYYY')}, Fecha fin:${endDate.format('DD/MM/YYYY')}`;
            format = [{'date_range':{'initial':`${startDate.format('DD/MM/YYYY')}`, 'final': `${endDate.format('DD/MM/YYYY')}`}}]     
            break;
        case 'datetimespaces_calendar':
            msmbtnPostback = 'Procesando'
            format = [{'date':`${startDate.format('DD/MM/YYYY')}`,
                'time_range1': {
                    'initial' : `${startDate.format('h:mm a')}`,        
                    'final' : `${startDate.format('hh:mm')}`}, 
               'time_range2':  {
                    'initial' : `${startDate.format('h:mm a')}`,        
                    'final' : `${startDate.format('hh:mm')}`}, 
              }]
            break;
        case 'datetimepart_calendar':
            msmbtnPostback = 'Procesando'
            format = [{'date':`${startDate.format('DD/MM/YYYY')}`, 'session': 'mañana’/’tarde'}]            
            break;
        case 'datetimerange_calendar':
            msmbtnPostback = 'Procesando'
            format = [{'day_range':  {
                'initial' : 'monday',        
                'final' : 'friday'},
           'time_range1': {
                'initial' : 'hhi:mmi',        
                'final' : 'hhf:mmf'}, 
           'time_range2':  {
                'initial' : 'hhi:mmi',        
                'final' : 'hhf:mmf'}, 
          }]
            break;
        case 'daterange_calendar':
            msmbtnPostback = 'Procesando'
            format = [{'date_range':{
                'initial':'DDi/MMi/YYYYi', 
                'final': 'DDf/MMf/YYYYf'}
          } ]
          
            break;
        case 'schedule_calendar':
            msmbtnPostback = `${startDate.format('DD/MM/YYYY, h:mm a')}`
            format = [{'date':`${startDate.format('DD/MM/YYYY')}`,'hour':`${startDate.format('hh:mm')}`}];
            break;
       default :
            msmbtnPostback = 'Pendiente'
        break;
    }
   */

    let respuesta = {};
    respuesta.trigger = '';
    
    let messages = {};
    messages.type = 'text';
    messages.text = msmbtnPostback;
    messages.item_type = configBoxOperator.item_type;
    messages.data_dict=data_dict; //OJO REVISAR SI ACA TAMBIEN SE LLAMA AHORA DATA_DICT O FORMAT
    //respuesta.action = 'close_continue';
    messages.authorId = 'view';
    messages.session = session_id;
    messages.operator_id = operator_id;

    respuesta.messages = [messages];


    
    let r = JSON.stringify(respuesta);
    
    dispatch({
        type: BOX_ACTIONS_TYPES.setData_ws(),
        payload: r
    });
    
    
}

export const toggleModalViewBoxCalendar = () => (dispatch, getState) =>{
    dispatch({
        type: BOX_ACTIONS_TYPES.toggleModalViewBoxCalendar()
    });
}


export const toggleModalViewBoxScanQR = () => (dispatch, getState) =>{
    dispatch({
        type: BOX_ACTIONS_TYPES.toggleModalViewBoxScanQR()
    });
}

export const toggleModalViewBoxCatalog = () => (dispatch, getState) =>{
    dispatch({
        type: BOX_ACTIONS_TYPES.toggleModalViewBoxCatalog()
    });
}

export const toggleModalViewBoxPayment = () => (dispatch, getState) =>{
    dispatch({
        type: BOX_ACTIONS_TYPES.toggleModalViewBoxPayment()
    });
}

export const toggleModalViewBoxBlockchain = () => (dispatch, getState) =>{
    dispatch({
        type: BOX_ACTIONS_TYPES.toggleModalViewBoxBlockchain()
    });
}

export const toggleModalViewBoxLocation = () => (dispatch, getState) =>{
    dispatch({
        type: BOX_ACTIONS_TYPES.setExitVar(),
        payload: false
    }); 

    dispatch({
        type: BOX_ACTIONS_TYPES.toggleModalViewBoxLocation()
    });
    
}

export const toggleModalViewBoxAgent = () => (dispatch, getState) =>{
    dispatch({
        type: BOX_ACTIONS_TYPES.toggleModalViewBoxAgent()
    });
}

export const toggleModalViewBoxCarousel = () => (dispatch, getState) =>{

    const {modalViewBoxCarousel} =getState().boxReducer;

    if(modalViewBoxCarousel)
        dispatch(addTextBlock([]));

    dispatch({
        type: BOX_ACTIONS_TYPES.toggleModalViewBoxCarousel()
    });
}

export const toggleModalViewBoxMultimedia = () => (dispatch, getState) =>{
    dispatch({
        type: BOX_ACTIONS_TYPES.setExitVar(),
        payload: false
    }); 
    dispatch({
        type: BOX_ACTIONS_TYPES.toggleModalViewBoxMultimedia()
    });
}

export const toggleModalViewBoxApi = () => (dispatch, getState) =>{
    dispatch({
        type: BOX_ACTIONS_TYPES.toggleModalViewBoxApi()
    });
}

export const toggleModalViewBoxConditional = () => (dispatch, getState) =>{
    dispatch({
        type: BOX_ACTIONS_TYPES.toggleModalViewBoxConditional()
    });
}

export const toggleModalViewBoxDesuscription = () => (dispatch, getState) =>{
    dispatch({
        type: BOX_ACTIONS_TYPES.toggleModalViewBoxDesuscription()
    });
}
export const toggleModalViewBoxButtons = () => (dispatch, getState) =>{
    dispatch({
        type: BOX_ACTIONS_TYPES.toggleModalViewBoxButtons()
    });
}
export const toggleModalViewBoxEmail = () => (dispatch, getState) =>{
    dispatch({
        type: BOX_ACTIONS_TYPES.toggleModalViewBoxEmail()
    });
}
export const toggleModalViewBoxForm = () => (dispatch, getState) =>{
    dispatch({
        type: BOX_ACTIONS_TYPES.toggleModalViewBoxForm()
    });
}
export const toggleModalViewBoxGoTo = () => (dispatch, getState) =>{
    dispatch({
        type: BOX_ACTIONS_TYPES.toggleModalViewBoxGoTo()
    });
}
export const toggleModalViewBoxQuickReply = () => (dispatch, getState) =>{
    dispatch({
        type: BOX_ACTIONS_TYPES.toggleModalViewBoxQuickReply()
    });
}
export const toggleModalViewBoxText = () => (dispatch, getState) =>{
    dispatch({
        type: BOX_ACTIONS_TYPES.toggleModalViewBoxText()
    });
}
export const toggleModalViewBoxSMS = () => (dispatch, getState) =>{
    dispatch({
        type: BOX_ACTIONS_TYPES.toggleModalViewBoxSMS()
    });
}
export const toggleModalViewBoxTrigger = () => (dispatch, getState) =>{
    dispatch({
        type: BOX_ACTIONS_TYPES.toggleModalViewBoxTrigger()
    });
}
export const toggleModalViewBoxUploadFile = () => (dispatch, getState) =>{
    dispatch({
        type: BOX_ACTIONS_TYPES.toggleModalViewBoxUploadFile()
    });
}
export const toggleModalViewBoxVariable = () => (dispatch, getState) =>{
    dispatch({
        type: BOX_ACTIONS_TYPES.setExitVar(),
        payload: false
    }); 

    dispatch({
        type: BOX_ACTIONS_TYPES.toggleModalViewBoxVariable()
    });
}
export const toggleModalViewBoxWebview = () => (dispatch, getState) =>{
    dispatch({
        type: BOX_ACTIONS_TYPES.toggleModalViewBoxWebview()
    });
}
export const toggleModalViewBoxCReport = () => (dispatch, getState) =>{
    dispatch({
        type: BOX_ACTIONS_TYPES.toggleModalViewBoxCReport()
    });
}


export const setUrlMultimediaPreview = (url) => (dispatch, getState) =>{
    dispatch({
        type: BOX_ACTIONS_TYPES.setUrlMultimediaPreview(),
        payload: url
    });
}


export const setFileMultimediaPreview = (file) => (dispatch, getState) =>{
    dispatch({
        type: BOX_ACTIONS_TYPES.setFileMultimediaPreview(),
        payload: file
    });
}

export const setContentType = (content) => (dispatch, getState) =>{
    dispatch({
        type: BOX_ACTIONS_TYPES.setContentType(),
        payload: content
    });
}

export const addTextBlock = (text) => (dispatch, getState) =>{
    dispatch({
        type: BOX_ACTIONS_TYPES.setTextBlock(),
        payload: text
    });
}

export const addButtons = (text) => (dispatch, getState) =>{
    dispatch({
        type: BOX_ACTIONS_TYPES.setButtons(),
        payload: text
    });
}

export const setHead = (text) => (dispatch, getState) =>{
    dispatch({
        type: BOX_ACTIONS_TYPES.setHead(),
        payload: text
    });
}



export const updateTextBlock = (index,key,value) => (dispatch, getState) =>{

    const {text_blocks} = getState().boxReducer;

    let textblock = text_blocks;

    textblock[index][key] = value;

    dispatch({
        type: BOX_ACTIONS_TYPES.setTextBlock(),
        payload: textblock
    });
}

export const updateButtons = (index,key,value) => (dispatch, getState) =>{

    const {buttons} = getState().boxReducer;

    let butt = buttons;

    butt[index][key] = value;

    dispatch({
        type: BOX_ACTIONS_TYPES.setButtons(),
        payload: butt
    });
}

export const setPostback_buttons = (key,value) => (dispatch, getState) =>{

    const {postback_buttons} = getState().boxReducer;

    let butt = postback_buttons;

    butt[key] = value;

    dispatch({
        type: BOX_ACTIONS_TYPES.setPostback_buttons(),
        payload: butt
    });
}

export const setAllPostback_buttons = (postback_buttons) => (dispatch, getState) =>{

    dispatch({
        type: BOX_ACTIONS_TYPES.setPostback_buttons(),
        payload: postback_buttons
    });
}


export const setTextBlock = (index,text,name,isvar) => (dispatch, getState) =>{
    const {text_blocks} = getState().boxReducer;

    let textblock = text_blocks;

    if(text)
        textblock[index].text = text
    if(name)
        textblock[index].name = name
    if(isvar)
        textblock[index].is_var = isvar
    
    dispatch({
        type: BOX_ACTIONS_TYPES.setTextBlock(),
        payload: text
    });
}

export const removeTextBlock = (index) => async (dispatch, getState) =>{
    const {text_blocks} = getState().boxReducer;
    
    await console.log("Antes de borrar",text_blocks,"S va a borrar el index: "+index);
    
    let text = text_blocks;

    let dynamic_statement = text_blocks[index].dynamic; 
    
    if(dynamic_statement && dynamic_statement!='')
        await dispatch(removeTextBlockDynamic(dynamic_statement)); 
                
    await delete text[index];


    await dispatch({
        type: BOX_ACTIONS_TYPES.setTextBlock(),
        payload: text
    });
}

export const removeTextBlockDynamic = (id)=> async (dispatch, getState) => {

    getHandlerPOST(HOST_API +'/api/operator/dynamic_item/'+id+'/delete')
        .then((res)=>{
            if(res.status==200){
                console.log('eliminado dinamico')
            }else{
            console.log(" No se pudo eliminar el operador dinámico");
            }
        }).catch((error) => {
            console.log('catch removeTextBlockDynamic',error)
            if (error.response.status == 403)
                cerrarSesion();
        });
}


 /*  
    *  addStatementDynamic 
    *  Creación de statement en el operador dinámico 
    *   @data {'title':'','operator_id':this.props.modal.node.extras.operatorDynamic,'description':'','image':''} 
    *  @idStatement Id del statement creado en el operador 
    */ 

 export const addStatementDynamic = (data, index)=> async (dispatch, getState) => {
   // let node = this.props.modal.node; 
    await getHandlerPOST(HOST_API +'/api/operator/dynamic_item/',data) 
        .then(async (res)=>{ 
            if(res.status==200){ 
                await dispatch(updateTextBlock(index,'dynamic',res.data.id_item));
            }else{ 
                console.log('res.status',res); 
            } 
        }).catch((error) => { console.log('catch addStatementDynamic',error);});  
} 

 /*  
*  updateStatementDynamic 
*  Actualización de statement en el operador dinámico 
*  @idItem id del item dinamico 
*  @title string 
*/ 
export const updateStatementDynamic = (idItem,data)=> async (dispatch, getState) => {

    await getHandlerPOST(HOST_API +'/api/operator/dynamic_item/'+idItem,data) 
    .then((res)=>{ 
        if(res.status==200){ 
            console.log('actualizado statement con éxito.'); 
        }else{ 
            console.log('res.status',res.status) 
        } 
    }).catch((error) => {console.log('catch updateStatementDynamic',error);}); 
} 

export const changeActiveTab = (tabActive) => (dispatch, getState) => {
    dispatch({
        type: BOX_ACTIONS_TYPES.changeActiveTabBoxOperator(), 
        payload: tabActive
    });
}

export const setNode = (node) => (dispatch, getState) => {
    dispatch({
        type: BOX_ACTIONS_TYPES.setNode(), 
        payload: node
    });
}


export const getAllListCompanies = () => async(dispatch, getState) => {
   
   const url = HOST_API +'/api/company/';//OJO solo trae AVON /16
   /**
    * Muestra ícono de carga
    */
   

   await dispatch({
       type: HOME_ACTIONS_TYPES.requestGetListCompanies()
   });
      
   await getHandlerGET(url)
   .then((res)=>{
       if(res.status==200){
           if(res.data.companies){//OJO COMPANIES PARA TODAS (res.data.company)
               let companies = res.data.companies;//OJO PARA TODAS CON COMPANIES res.data.companies;[res.data.company]
               dispatch({
                   type: HOME_ACTIONS_TYPES.successGetListCompanies(),
                   payload: companies
               }); 
               
                dispatch(setSelectedCompany(companies[0].id));//OJO companies[0].id));

           }
       }else{
           dispatch({
               type: HOME_ACTIONS_TYPES.errorGetListCompanies(),
               payload: i18next.t('message55')
           });   

       }
  
   })
   .catch((error) => {
    console.log("ERROR - CATCH: "+error);
    setTimeout(()=>{
        dispatch({
            type: HOME_ACTIONS_TYPES.errorGetListPlafforms(),
            payload: i18next.t('statements_list_msg3') + error
        });   
    },1200);

            
    const { response: { status } = { status } } = error; 
    if (status  && status == 403){
        logOut();
        cerrarSesion();
    }
   

   }); 

   const { error } = getState().homeReducer;
   if (error){
       dispatch({
           type: MODAL_LOAD_ACTIONS_TYPES.showWarning(), 
           payload: error,
       });
   }

  
}


// Cambia algún parametro de configuración de una caja
export const setChangeConfig = (key,value) => async (dispatch, getState) => {
    const { configBoxOperator } = getState().boxReducer;

    const conf = configBoxOperator;
    conf[key] = value;

    await dispatch({
        type: BOX_ACTIONS_TYPES.successGetConfigBoxOperator(),
        payload: conf
    });
 }
 
 // Cambia algún parametro de configuración de una caja
export const setConfig = (conf) => async (dispatch, getState) => {
   
    await dispatch({
        type: BOX_ACTIONS_TYPES.successGetConfigBoxOperator(),
        payload: conf
    });
 }


  // Trae la lista de diferente tipos de Codigos a escanear
export const getListTypeScanner = () => async(dispatch, getState) => {
    const url = HOST_API +"/api/qrcode/types";
       
    await getHandlerGET(url)
    .then((res)=>{
        if(res.status==200){
            if(res.data){
                let type = res.data;
                dispatch({
                    type: BOX_ACTIONS_TYPES.setListTypeScanner(),
                    payload:type
                });                
            }
        }else{
            dispatch({
                type: BOX_ACTIONS_TYPES.errorGetListTypeCalendar(),
                payload:'¡ No se pudo obtener la lista de tipos de códigos a escanear!'
            });   
 
        }
   
    })
    .catch((error) => {
        console.log("ERROR - CATCH: "+error);
        setTimeout(()=>{
            dispatch({
                type: BOX_ACTIONS_TYPES.errorGetListTypeCalendar(),
                payload:'¡ Error, al obtener la lista de de tipos de códigos a escanear!'
            });  
        },1200);
 
             
        const { response: { status } = { status } } = error; 
            if (status  && status == 403){
                logOut();
                cerrarSesion();
            }
    
 
    }); 
 
    const { error } = getState().homeReducer;
    if (error){
        dispatch({
            type: MODAL_LOAD_ACTIONS_TYPES.showWarning(), 
            payload: error,
        });
    }
   
 }

//  export const getListTypeScanner = () => async(dispatch, getState) => {

//     const type =[
//         {id: 0,type:'aztec',text:'Aztec'},
//         {id: 1,type:'codabar',text:'Codabar'},
//         {id:  2,type:'code39',text:'Code39'},
//         {id:  3,type:'code93',text:'Code93'},
//         {id:  4,type:'code128',text:'Code128'},
//         {id:  5,type:'data_matrix_ecc200',text:'Data Matrix ecc200'},
//         {id:  6,type:'ean8',text:'EAN8'},
//         {id:  7,type:'ean13',text:'EAN13'},
//         {id:  8,type:'itf',text:'ITF'},
//         {id:  9,type:'maxicode',text:'Maxicode'},
//         {id:  10,type:'pdf417',text:'PDF417'},
//         {id:  11,type:'qrcode',text:'QR code'},
//         {id:  12,type:'rss14',text:'RSS14'},
//         {id:  13,type:'rss_expanded',text:'RSS Expanded'},
//         {id:  14,type:'upc_a',text:'UPC A'},
//         {id:  15,type:'upc_e',text:'UPC E'},
//         {id:  16,type:'upc_ean_extension',text:'UPC EAN Extension'}
//      ];

//      dispatch({
//         type: BOX_ACTIONS_TYPES.setListTypeScanner(),
//         payload:type
//     }); 
// }

export const getListTypeCatalog = () => async(dispatch, getState) => {

    const type =[
        {id: 0,type:'none',text:''},
        {id: 1,type:'smartdata',text:'Smart Data & Automation'},
        {id: 2,type:'whatsapp',text:'Whatsapp'},
        {id: 3,type:'kapalia',text:'Kapalia'},
     ];

     dispatch({
        type: BOX_ACTIONS_TYPES.setListTypeCatalog(),
        payload:type
    }); 
}
/**--------------------------------------------------------------------------
 * BOX CALENDAR DATE (CD)
 *--------------------------------------------------------------------------*/

 // Trae la lista de diferente tipos de calendarios
export const getListTypeCalendar = () => async(dispatch, getState) => {
    const url = HOST_API +"/api/calendar/types";

    await dispatch({
        type: BOX_ACTIONS_TYPES.requestGetListTypeCalendar()
    });
       
    await getHandlerGET(url)
    .then((res)=>{
        if(res.status==200){
            if(res.data){
                let typecalendar = res.data;
                dispatch({
                    type: BOX_ACTIONS_TYPES.successGetListTypeCalendar(),
                    payload: typecalendar
                }); 
                
                // dispatch(setSelectedTypeCalendar(typecalendar[1].type));
 
            }
        }else{
            dispatch({
                type: BOX_ACTIONS_TYPES.errorGetListTypeCalendar(),
                payload: i18next.t('message56')
            });   
 
        }
   
    })
    .catch((error) => {
        console.log("ERROR - CATCH: "+error);
        setTimeout(()=>{
            dispatch({
                type: BOX_ACTIONS_TYPES.errorGetListTypeCalendar(),
                payload: i18next.t('message57')
            });  
        },1200);

             
        const { response: { status } = { status } } = error; 
            if (status  && status == 403){
                logOut();
                cerrarSesion();
            }
    
 
    }); 
 
    const { error } = getState().homeReducer;
    if (error){
        dispatch({
            type: MODAL_LOAD_ACTIONS_TYPES.showWarning(), 
            payload: error,
        });
    }
 
   
 }
  
 // Selecciona un tipo de calendario
 export const setSelectedTypeCalendar = (type) => async (dispatch, getState) => {

    const {listTypeCalendar , configBoxOperator } = getState().boxReducer;
    
    let typeCalendar=[];

    if(listTypeCalendar)
    {
        let key = Object.keys(listTypeCalendar);
        let tp = key.map((k, index) => {
            if(listTypeCalendar[k].type == type) {
                typeCalendar=listTypeCalendar[k];
            }
       
        })
    }
    
    const conf = configBoxOperator;
    conf.item_type = type;

    await dispatch({
        type: BOX_ACTIONS_TYPES.successGetConfigBoxOperator(),
        payload: conf
    });

    await dispatch({
        type: BOX_ACTIONS_TYPES.selectedTypeCalendar(),
        payload: typeCalendar
    }); 
}

 // Selecciona un tipo de scanner
 export const setSelectedTypeScanner = (type) => async (dispatch, getState) => {

    const {listTypeScanner , configBoxOperator } = getState().boxReducer;
    
    let typeScanner=[];

    if(listTypeScanner)
    {
        let key = Object.keys(listTypeScanner);
        let tp = key.map((k, index) => {
            if(listTypeScanner[k].type == type) {
                typeScanner=listTypeScanner[k];
            }
       
        })
    }
    
    const conf = configBoxOperator;
    conf.item_type = type;

    await dispatch({
        type: BOX_ACTIONS_TYPES.successGetConfigBoxOperator(),
        payload: conf
    });

    await dispatch({
        type: BOX_ACTIONS_TYPES.setSelectedTypeScanner(),
        payload: typeScanner
    }); 
}
 
// Selecciona un tipo de catalogo
export const setSelectedTypeCatalog = (type) => async (dispatch, getState) => {

    const {listTypeCatalog , configBoxOperator } = getState().boxReducer;
    
    let typeCatalog=[];

    if(listTypeCatalog)
    {
        let key = Object.keys(listTypeCatalog);
        let tp = key.map((k, index) => {
            if(listTypeCatalog[k].type == type) {
                typeCatalog=listTypeCatalog[k];
            }
       
        })
    }
    
    const conf = configBoxOperator;
    conf.item_type = type;

    await dispatch({
        type: BOX_ACTIONS_TYPES.successGetConfigBoxOperator(),
        payload: conf
    });

    await dispatch({
        type: BOX_ACTIONS_TYPES.setSelectedTypeCatalog(),
        payload: typeCatalog
    }); 
}

 // Dependiendo del tipo de calendario pone el formato a la selección realizada por el usuario del bot
export const setConfigbyTypeCalendar = () => async (dispatch, getState) => {

    const {configBoxOperator } = getState().boxReducer;
    
    let selectedTypeCalendar  = configBoxOperator.item_type;
    
    let startDate = configBoxOperator.selectedStartDate?moment(configBoxOperator.selectedStartDate):null;

    let endDate = configBoxOperator.selectedEndDate?moment(configBoxOperator.selectedEndDate):null;

  let msmbtnPostback ='';
  
  let data_dict = {};

  switch (selectedTypeCalendar) {
      case 'date_calendar':
          msmbtnPostback = `${startDate.format('DD/MM/YYYY')}`;
          data_dict = {'format' : { 'date':'DD/MM/YYYY'},'values':{'date':`${startDate.format('DD/MM/YYYY')}`}};
          break;
      case 'datetime_calendar':
          msmbtnPostback = `${startDate.format('DD/MM/YYYY h:mm a')}`
          data_dict = {'format' : {'date':'DD/MM/YYYY', 'hour': 'hh:mm'},
                       'values' : {'date':`${startDate.format('DD/MM/YYYY')}`,'hour':`${startDate.format('hh:mm')}`}};
          break;
      case 'time_calendar':
          msmbtnPostback = `${startDate.format('h:mm a')}`
          data_dict = {'format' : {'hour': 'hh:mm'},'values':{'hour':`${startDate.format('hh:mm')}`}};
          break;
      case 'daterange_calendar':
          msmbtnPostback = `Fecha inicio: ${startDate.format('DD/MM/YYYY')}, Fecha fin:${endDate.format('DD/MM/YYYY')}`;
          data_dict = {'format' : {'date_range':{'initial':'DD/MM/YYYY','final': 'DD/MM/YYYY'}},
                      'values':{'date_range':{'initial':`${startDate.format('DD/MM/YYYY')}`, 'final': `${endDate.format('DD/MM/YYYY')}`}}}     
          break;
      case 'datetimespaces_calendar':
          msmbtnPostback = 'Procesando'
          data_dict = {'format' : {},'values':{'date':`${startDate.format('DD/MM/YYYY')}`,
              'time_range1': {
                  'initial' : `${startDate.format('h:mm a')}`,        
                  'final' : `${startDate.format('hh:mm')}`}, 
             'time_range2':  {
                  'initial' : `${startDate.format('h:mm a')}`,        
                  'final' : `${startDate.format('hh:mm')}`}, 
            }}
          break;
      case 'datetimepart_calendar':
          msmbtnPostback = 'Procesando'
          data_dict = {'format' : {},'values':{'date':`${startDate.format('DD/MM/YYYY')}`, 'session': 'mañana’/’tarde'}}            
          break;
      case 'datetimerange_calendar':
          msmbtnPostback = 'Procesando'
          data_dict = {'format' : {},'values':{'day_range':  {
              'initial' : 'monday',        
              'final' : 'friday'},
         'time_range1': {
              'initial' : 'hhi:mmi',        
              'final' : 'hhf:mmf'}, 
         'time_range2':  {
              'initial' : 'hhi:mmi',        
              'final' : 'hhf:mmf'}, 
        }}
          break;
      case 'daterange_calendar':
          msmbtnPostback = 'Procesando'
          data_dict = {'format' : {'date_range':{'initial':'DD/MM/YYYY','final': 'DD/MM/YYYY'}},'values':{'date_range':{
              'initial':'DDi/MMi/YYYYi', 
              'final': 'DDf/MMf/YYYYf'}
        } }
        
          break;
      case 'schedule_calendar':
          msmbtnPostback = `${startDate.format('DD/MM/YYYY, h:mm a')}`
          data_dict = {'format' : {'date':'DD/MM/YYYY', 'hour': 'hh:mm'},'values':{'date':`${startDate.format('DD/MM/YYYY')}`,'hour':`${startDate.format('hh:mm')}`}};
          break;
     default :
          msmbtnPostback = 'Pendiente'
      break;
  }
    

    await dispatch({
        type: BOX_ACTIONS_TYPES.setMsmbtnPostback(),
        payload: msmbtnPostback
    }); 

    await dispatch({
        type: BOX_ACTIONS_TYPES.setData_dict(),
        payload: data_dict
    }); 


}
/**
 * Configuración CAJA MULTIMEDIA
 */
 // Selecciona un tipo de localización
 export const setSelectedTypeMultimedia = (type) => async (dispatch, getState) => {

    await dispatch({
        type: BOX_ACTIONS_TYPES.selectedTypeMultimedia(),
        payload: type
    }); 
}

/**
 * Configuración CAJA LOCALIZACIÓN
 */
 // Selecciona un tipo de localización
 export const setSelectedTypeLocation = (type) => async (dispatch, getState) => {

    const {listTypeLocation , configBoxOperator } = getState().boxReducer;
    
    let typeLocation=[];

    if(listTypeLocation)
    {
        let key = Object.keys(listTypeLocation);
        let tp = key.map((k, index) => {
            if(listTypeLocation[k].type == type) {
                typeLocation=listTypeLocation[k];
            }
       
        })
    }
    

    await dispatch({
        type: BOX_ACTIONS_TYPES.selectedTypeLocation(),
        payload: typeLocation
    }); 
}

// Trae la lista de diferente tipos de localización
export const getListTypeLocation = () => async(dispatch, getState) => {
    const url = HOST_API +"/api/location/types";

    await dispatch({
        type: BOX_ACTIONS_TYPES.requestGetListTypeLocation()
    });
       
    await getHandlerGET(url)
    .then((res)=>{
        if(res.status==200){
            if(res.data){
                let typelocation = res.data;
                dispatch({
                    type: BOX_ACTIONS_TYPES.successGetListTypeLocation(),
                    payload: typelocation
                }); 
                
                // dispatch(setSelectedTypeCalendar(typecalendar[1].type));
 
            }
        }else{
            dispatch({
                type: BOX_ACTIONS_TYPES.errorGetListTypeLocation(),
                payload: i18next.t('location_msg')
            });   
 
        }
   
    })
    .catch((error) => {
        console.log("ERROR - CATCH: "+error);
        dispatch({
            type: BOX_ACTIONS_TYPES.errorGetListTypeLocation(),
            payload: i18next.t('location_msg2') + error
        });      
        const { response: { status } = { status } } = error; 
            if (status  && status == 403){
                logOut();
                cerrarSesion();
            }
    
 
    }); 
 
    const { error } = getState().homeReducer;
    if (error){
        dispatch({
            type: MODAL_LOAD_ACTIONS_TYPES.showWarning(), 
            payload: error,
        });
    }
 
   
 }


 // Trae la lista de diferente listas de ubicaciones
export const getListLocation = () => async(dispatch, getState) => {
    
    //const {selectedCompany} = getState().boxReducer;

   // const selectedCompany = '7'
    let params = '';

    //if(selectedCompany)
    //    params=params+"company="+selectedCompany;//OJO SOLO ESTA TRAYENDO LAS DE LA EMPRESA

    const url = HOST_API+`/api/location/coordinates_file`
    
 

    await dispatch({
        type: BOX_ACTIONS_TYPES.requestGetListLocation()
    });
       
    await getHandlerGET(url)
    .then((res)=>{
        if(res.status==200){
            if(res.data){
                let location = res.data.message;
                dispatch({
                    type: BOX_ACTIONS_TYPES.successGetListLocation(),
                    payload: location
                }); 
                
                if(location[0]){
                    dispatch(setSelectedListLocation(location[0].id));
                    dispatch(setInitialDinamicVariables());
                    
                }
 
            }
        }else{
            dispatch({
                type: BOX_ACTIONS_TYPES.errorGetListLocation(),
                payload: i18next.t('location_msg3')
            });   
 
        }
   
    })
    .catch((error) => {
        console.log("ERROR - CATCH: "+error);
        dispatch({
            type: BOX_ACTIONS_TYPES.errorGetListLocation(),
            payload: i18next.t('location_msg4')
        });      
        const { response: { status } = { status } } = error; 
            if (status  && status == 403){
                logOut();
                cerrarSesion();
            }
    
 
    }); 
 
    const { error } = getState().homeReducer;
    if (error){
        dispatch({
            type: MODAL_LOAD_ACTIONS_TYPES.showWarning(), 
            payload: error,
        });
    }
 
   
 }

// Selecciona un tipo de calendario
export const setSelectedListLocation = (id) => async (dispatch, getState) => {

    const {listLocation } = getState().boxReducer;
    
    let location=[];


    if(listLocation)
    {
        let key = Object.keys(listLocation);
        let tp = key.map((k, index) => {
            if(listLocation[k].id == id) {
                location=listLocation[k];
            }
       
        })
    }
   
    await dispatch({
        type: BOX_ACTIONS_TYPES.selectedListLocation(),
        payload: location
    }); 


}
 
// Selecciona el formato de las variables dependiendo del tipo de localizaion seleccionada
export const setInitialDinamicVariables = () => async (dispatch, getState) => {

    const {selectedListLocation } = getState().boxReducer;
    
    let dinamicVariablesBoxOperator = {};

     //Crea las variables dinámicas
     selectedListLocation.columns.map((k, index) => {
        dinamicVariablesBoxOperator[k]={};
        dinamicVariablesBoxOperator[k].var_name = '';
        dinamicVariablesBoxOperator[k].statement_id = '';
        dinamicVariablesBoxOperator[k].list = false;
        dinamicVariablesBoxOperator[k].is_var = false;
    })

    await dispatch({
        type: BOX_ACTIONS_TYPES.setDinamicVariablesBoxOperator(),
        payload: dinamicVariablesBoxOperator
    });
}

// Selecciona el formato de las variables dependiendo del tipo de localizaion seleccionada
export const setFormatVariables = () => async (dispatch, getState) => {

    const {selectedTypeLocation, selectedListLocation } = getState().boxReducer;
    let variables_json={}
   
    let dinamicVariablesBoxOperator = {};

    switch (selectedTypeLocation.type) {
        case 'capture_location':
        case 'set_location':
            variables_json = {'lat':{'statement_id':'','var_name':'','title':i18next.t('latitud_var'),'description':i18next.t('name_info_variable'),'type':'numeric','format':'decimal'}, 
            'long':{'statement_id':'','var_name':'','title':i18next.t('longitud_var'),'description':i18next.t('name_info_variable'),'type':'numeric','format':'decimal'}}
             break;
        case 'route_location':
            variables_json = {'distance':{'statement_id':'','var_name':'','title':'Variable distancia','description':'Nombre para almacenar la distancia calculada','type':'numeric','format':'decimal'}, 
            'text_step':{'statement_id':'','var_name':'','title':'Variable instrucciones','description':'Nombre para almacenar las instrucciones para llegar','type':'numeric','format':'decimal'}}
             break;
        case 'closest_location':
            variables_json = {}
            //Crea las variables dinámicas
            if(selectedListLocation && selectedListLocation.columns){
                selectedListLocation.columns.map((k, index) => {
                    dinamicVariablesBoxOperator[k]={};
                    dinamicVariablesBoxOperator[k].var_name = '';
                    dinamicVariablesBoxOperator[k].statement_id = '';
                    dinamicVariablesBoxOperator[k].list = false;
                })
            }
            

             break;
        case 'city_location':
            variables_json = {'city':{'statement_id':'','var_name':'','title':'Variable ciudad','description':'Nombre para almacenar la ciudad digitada por el usuario','type':'text','format':'text'} 
            }
             break; 
       default :
            variables_json = {};
        break;
    }
      
  
      dispatch(setVariablesBoxOperator(variables_json));

      
      dispatch(setDinamicVariablesBoxOperator(dinamicVariablesBoxOperator));

}

export const setVariablesBoxOperator = (variables_json) => async (dispatch, getState) => {
    await dispatch({
        type: BOX_ACTIONS_TYPES.setVariablesBoxOperator(),
        payload: variables_json
    }); 
}

export const setDinamicVariablesBoxOperator = (variables_json) => async (dispatch, getState) => {
    await dispatch({
        type: BOX_ACTIONS_TYPES.setDinamicVariablesBoxOperator(),
        payload: variables_json
    }); 
}

export const setBotmsgText = (enunciado) => async (dispatch, getState) => {
    await dispatch({
        type: BOX_ACTIONS_TYPES.setBotmsgText(),
        payload: enunciado
    }); 
}

export const setAvatarid = (avatar_id) => async (dispatch, getState) => {
    await dispatch({
        type: BOX_ACTIONS_TYPES.setAvatarid(),
        payload: avatar_id
    }); 
}

export const updateVariableBoxOperatorbyID = (key,value) => async (dispatch, getState) => {
    
    const {variablesBoxOperator } = getState().boxReducer;

    let varNew = {};
    
    varNew = variablesBoxOperator;

    varNew[key].var_name = value ;

    await dispatch({
        type: BOX_ACTIONS_TYPES.setVariablesBoxOperator(),
        payload: varNew
    });

}

export const updateVariableBoxOperator = (key,value,type) => async (dispatch, getState) => {

    const {variablesBoxOperator,dinamicVariablesBoxOperator,varList,text_blocks } = getState().boxReducer;

    let varNew = {};
    let exit_var = false;

    let statement=find(propEq("var_name", value))(varList);

    let statement1=Object.keys(text_blocks).find(e => text_blocks[e].var_name === value);

    if(statement&&value!='' || statement1&&value!='')
        exit_var = true
    else
        exit_var = false

    await dispatch({
        type: BOX_ACTIONS_TYPES.setExitVar(),
        payload: exit_var
    }); 

    if(exit_var==false){
        if(type=='variables')
        {
            varNew = variablesBoxOperator;

            varNew[key].var_name = value;

            await dispatch({
                type: BOX_ACTIONS_TYPES.setVariablesBoxOperator(),
                payload: varNew
            }); 
        }
        else if(type=='dinamicas')
        {
            varNew = dinamicVariablesBoxOperator;

            varNew[key].var_name = value;

            if(value!='')
                varNew[key].is_var = true
            else
                varNew[key].is_var = false

            await dispatch({
                type: BOX_ACTIONS_TYPES.setDinamicVariablesBoxOperator(),
                payload: varNew
            }); 
        }

    }
    
}

export const updateBoxOperator = (key,value) => async (dispatch, getState) => {

    const {selectedBoxOperator } = getState().boxReducer;

    let boxNew = selectedBoxOperator;

    boxNew[key] = value;

    await dispatch({
        type: BOX_ACTIONS_TYPES.selectedBoxOperator(),
        payload: boxNew
    }); 
}


export const getListProviderBlockchain = () => async(dispatch, getState) => {
    
    //const {selectedCompany} = getState().boxReducer;

   // const selectedCompany = '7'
    let params = '';

    //if(selectedCompany)
    //    params=params+"company="+selectedCompany;//OJO SOLO ESTA TRAYENDO LAS DE LA EMPRESA

    const url = HOST_API+`/blockchain/providers`
    
       
    await getHandlerGET(url)
    .then((res)=>{
        if(res.status==200){
            if(res.data){
                let provider = res.data;
                dispatch({
                    type: BOX_ACTIONS_TYPES.setListProviderBlockchain(),
                    payload: provider
                }); 
                
                if(provider[0]){
                    dispatch(setSelectedProvider(provider[0].id));
                }
 
            }
        }else{
            dispatch({
                type: BOX_ACTIONS_TYPES.errorGetListLocation(),
                payload: i18next.t('blockchain_msg')
            });   
 
        }
   
    })
    .catch((error) => {
        console.log("ERROR - CATCH: "+error);
        dispatch({
            type: BOX_ACTIONS_TYPES.errorGetListLocation(),
            payload: i18next.t('blockchain_msg2')
        });      
        const { response: { status } = { status } } = error; 
            if (status  && status == 403){
                logOut();
                cerrarSesion();
            }
    
 
    }); 
 
    const { error } = getState().homeReducer;
    if (error){
        dispatch({
            type: MODAL_LOAD_ACTIONS_TYPES.showWarning(), 
            payload: error,
        });
    }
 
   
 }


export const getListContractBlockchain = () => async(dispatch, getState) => {
    
    const {selectedCompany, selectedProviderBlockchain} = getState().boxReducer;
    const { bot } = getState().botReducer;

    if(selectedProviderBlockchain)
    {
   // const selectedCompany = '7'
    let params = '';

    if(bot.company_id)
        params = params + "?company=" + bot.company_id + "&provider=" + selectedProviderBlockchain;//OJO SOLO ESTA TRAYENDO LAS DE LA EMPRESA
    else
        params = params + "?provider=" + selectedProviderBlockchain;//OJO SOLO ESTA TRAYENDO LAS DE LA EMPRESA
    
        const url = HOST_API + `/blockchain/contract/` + params;
       
    await getHandlerGET(url)
    .then((res)=>{
        if(res.status==200){
            if(res.data){
                let contract = res.data;
                dispatch({
                    type: BOX_ACTIONS_TYPES.setListContractBlockchain(),
                    payload: contract
                }); 
                
                if(contract[0]){
                    dispatch(setSelectedContract(contract[0].id));   
                }
 
            }
        }else{
            dispatch({
                type: BOX_ACTIONS_TYPES.errorGetListLocation(),
                payload: i18next.t('blockchain_msg2')
            });   
        }
    })
    .catch((error) => {
        console.log("ERROR - CATCH: "+error);
        dispatch({
            type: BOX_ACTIONS_TYPES.errorGetListLocation(),
            payload: i18next.t('blockchain_msg4')
        });      
        const { response: { status } = { status } } = error; 
            if (status  && status == 403){
                logOut();
                cerrarSesion();
            }
    
 
    }); 
 
    const { error } = getState().homeReducer;
    if (error){
        dispatch({
            type: MODAL_LOAD_ACTIONS_TYPES.showWarning(), 
            payload: error,
        });
    }
    }
   
 }


 export const setSelectedProvider = (id_provider) => async (dispatch, getState) => {
    
    const {listProviderBlockchain } = getState().boxReducer;

    const provider=find(propEq("id", id_provider))(listProviderBlockchain);

    await dispatch({
        type: BOX_ACTIONS_TYPES.setSelectedProviderBlockchain(),
        payload: provider
    });

}

export const setSelectedContract = (id_contract) => async (dispatch, getState) => {
    
    const {listContractBlockchain } = getState().boxReducer;

    const contract=find(propEq("id", id_contract))(listContractBlockchain);

    await dispatch({
        type: BOX_ACTIONS_TYPES.setSelectedContractBlockchain(),
        payload: contract
    });

}

export const setExitVar = (value) => async (dispatch, getState) => {

    const {varList,text_blocks } = getState().boxReducer;
    
    let exit_var = false;

    let statement=find(propEq("var_name", value))(varList);

    let statement1=Object.keys(text_blocks).find(e => text_blocks[e].var_name === value);

    if(statement&&value!='' || statement1&&value!='')
        exit_var = true
    else
        exit_var = false

    dispatch({
        type: BOX_ACTIONS_TYPES.setExitVar(),
        payload: exit_var
    }); 

}

