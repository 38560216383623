import React from 'react';
import Admin from '../../admin/containers/admin';
import SliceMenuApp from '../../admin/components/slice-menu'
import {
    Col,
    Row,
    Button,
    Container,
    Modal,
    ModalHeader,
    ModalBody,
    FormGroup,
    Label,
    Input,
    Form,
    Table,
    UncontrolledTooltip
} from 'reactstrap';
import Select from 'react-select';
import ToolbarApp from '../../admin/components/toolbar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash, faCheckCircle, faSync, faStopCircle, faSearchLocation } from '@fortawesome/free-solid-svg-icons'
import BotMenuOptions from '../../menus/bot-menu-options.js';
import '../massive-campaign/massive-campaign-layout.css';

import DataTableFilter from '../../analytics/infoViz/common/dataTableFilter'
import i18next from '../../../../i18n';

      /* IMAGE */
import imageNew from '../../../../static/img/menu-icons/nuevo_bot.svg'

function senderLayout(props) {    
    // Listando los remitentes
    let senders = props.listAllSender || [];
    let listAllSender = []

    let nameChannel = (name)=>{
        let res= '';
        switch (name) {
            case 'EM':
                res='Email';
                break;
            case 'PH':
                res='Móvil';
            case 'TW':
                res='Móvil';
                break;
            default:
                break;
        }
        return res;
    }

    let formatStatus = (status='',channel_type)=>{
        let res= '';
        switch (`${channel_type}:${status}`) {
            case 'EM:Success':
                res='Verificado';
                break;
            case 'EM:Pending':
                res='Pendiente';
                break;
            case 'PH:':
                res='No aplica';
                break;
            case 'TW:':
                res='No aplica';
                break;
            default:
                res= 'Email no validado';
                break;
        }
        return res;
    }


    let setSelectedCompanies = () => {       
        const company = props.allListcompanies.filter(( item ) => item.id == props.editCompany);
        if(company && company.length>0){
            const { id='', name='' } = company[0];
             return ({label: name, value: id });
        }        
       return ({label: '', value: '' });
    }


    let setOptionsCompanies = () => {
        let res=[];
        props.allListcompanies.map((item)=>{
            res.push({label: item.name, value: item.id });
        });
        return res;
    }
    
    var dataTable=null;
    if(senders[0]){
       
         dataTable = {
            columns: [
              {
                label: i18next.t('type'),
                field: 'tipo',
                sort: 'asc',
               // width: 150
              },
              {
                label: i18next.t('name'),
                field: 'nombre',
                sort: 'asc',
               // width: 150
              },
              {
                label: i18next.t('value'),
                field: 'valor',
                sort: 'asc',
               // width: 150
              },
              {
                label: i18next.t('status'),
                field: 'estado',
                sort: 'asc',
               // width: 150
              },
              {
                label: i18next.t('edition_option'),
                field: 'options',
                sort: 'asc',
                width: 670,
                options: {
                    filter: false,
                    sort: false
                   }
              }],
              rows: []};
  
              const rows =  senders.map((sender, index) => {

                const { id, channel_type, name, value, status } = sender;
  
                //k = JSON.parse(k);  
                const fin=[];
                fin.push(nameChannel(channel_type));
                fin.push(name);
                fin.push(value);
                fin.push(formatStatus(status,channel_type));
                fin.push(
                    <div className="d-inline-block">        
                        <Button color={(status=='Success')?'success':'secondary'} disabled={(status!='Success' && channel_type === 'EM') ? false : true } outline onClick={(e) => props.handleEmailVerify(value,id)} >
                            <FontAwesomeIcon icon={faCheckCircle} id='buttonVerifyEmail' style={{ 'fontSize': '18px' }} />
                            <UncontrolledTooltip placement="bottom" target='buttonVerifyEmail'> {i18next.t('verify_email')}</UncontrolledTooltip>
                        </Button>                        
                        {props.isSuperUser && 
                            <Button outline color="success" onClick={(e) => props.toggleModalSender(sender, true)}>
                                <FontAwesomeIcon icon={faPen} id={'buttonEdit' + index} />
                                <UncontrolledTooltip placement="top" target={'buttonEdit' + index}> {i18next.t('edit')}</UncontrolledTooltip>
                            </Button>   
                        }           
                        <Button outline color="danger" onClick={(e) => props.deleteSender(id)}>
                            <FontAwesomeIcon icon={faTrash} id={'buttonDelete' + index} />
                        </Button>
                        <UncontrolledTooltip placement="top" target={'buttonDelete' + index} >{i18next.t('delete')}</UncontrolledTooltip>
                    </div>
                    );
               
               
                dataTable.rows.push(fin); 
                return sender;
            });
        }
  
        const options = {
            filterType: 'multiselect',
            selectableRows:'none',
            resizableColumns: false,
            responsive: "scrollMaxHeight",
            rowsPerPage:5 ,
            print:false,
            download:false,
            viewColumns:false,
            customToolbar: () => {
                return (
                <Button outline color="success" onClick={ (e) => props.getListSender("all")}>
                    <FontAwesomeIcon icon={faSync} id='buttonRefreshE' style={{'fontSize':'18px'}} />
                </Button>
                );
              },
            
          };
          
              var title = ''
  

    return (
        <div className="NotificaionsLayout">
            <Admin>
            <button id="tooltiNewTemplate" className="btn-float"  onClick={(e)=>{props.toggleModalNewSender(false)}}>
                <img src={imageNew}/>
            </button> 
            <UncontrolledTooltip placement="right" target="tooltiNewTemplate">Crear Nuevo remitente</UncontrolledTooltip> 
        
                <SliceMenuApp>
                    <BotMenuOptions admin={props.isAdmin} />
                </SliceMenuApp>
                <Container fluid>
                    <ToolbarApp>
                        <Row>
                            <Col sm="9" md="9" lg="9" className="text pdtoolbar">
                                <h2 className="text-left ml-4 font-weight-bold">Lista de remitentes</h2>
                            </Col>
                        </Row>
                    </ToolbarApp>
                </Container>
                <Container fluid className="box-fluid">
                <Col sm="12" md="10" className="stadistics-container offset-md-1">    
                    {
                       dataTable?(
                        <Row >
                            <Col xs={12} lg={12} >
                                <DataTableFilter
                                title={title}
                                data={ dataTable.rows}
                                columns={dataTable.columns}
                                options={options}
                                />  
                           </Col>
                        </Row>): 
                          <Col xs={12} lg={8} className="offset-lg-2 intro-funnels">
                                <Row>
                                    <Col xs={12}><img src="/static/img/blank_state.png" className="img-fluid"/></Col>
                                    <Col xs={12}>
                                        <h2>AÚN NO TIENE REMITENTES</h2>
                                        <h3>Aquí encontrarás la lista de remitentes de la empresa</h3>
                                        <p>Crea un nuevo remitente.</p>
                                    </Col>
                                </Row>
                            </Col>
                        }
                    </Col>
                </Container>

                <Modal className="modalRight" isOpen={props.modalSender} fade toggle={() => props.toggleModalNewSender(false)}>
                    <ModalHeader toggle={() =>  props.toggleModalNewSender(false) } className='notification'>{i18next.t('config_sender')}</ModalHeader>
                    <ModalBody className='notification-body'>
                        <Col md={12} className="sectionBox">
                            <Form id="frm_notification" onSubmit={props.handleSaveSender}>
                                <FormGroup>       
                                    <Input type="hidden" id="sender_id" name="sender_id" maxLength={200}
                                        defaultValue={props.sender_id}  />    

                                    <Label>Tipo canal</Label>
                                    <Input type="select" id="channel_type" name="channel_type" maxLength={200}
                                        defaultValue={props.channel_type}
                                        required >
                                        <option key='EM' value='EM'> Email</option>
                                        <option key='PH' value='PH'> Móvil</option>
                                        <option key='TW' value='TW'> Móvil Tw</option>

                                    </Input>

                                    <Label>{i18next.t('name')}</Label>
                                    <Input type="text" id="name" name="name" maxLength={200}
                                        defaultValue={props.name}
                                        required />
                                    <Label>Valor</Label>
                                    <Input type="text" id="value" name="value" maxLength={200}
                                        defaultValue={props.value}
                                        required />

                                    <Label for="companies">Compañia</Label>
                                    <Select
                                      id="companies"                                    
                                      value={props.editCompany}
                                      options={setOptionsCompanies()}
                                      onChange={props.handleChangeCompanies}
                                      isMulti={false}
                                      name="companies"
                                    />                                   
                                </FormGroup>
                            </Form>
                        </Col>
                        <Col className="buttonModal">
                            <Col className="col-10 offset-1">
                                <Row>
                                    <Col className=" col-6 offset-3">
                                        <Button className="btn-block btn-guarumo-modal-sm" type="submit" form="frm_notification">
                                            Guardar
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Col>
                    </ModalBody>
                </Modal>                  
            </Admin>

        </div>
    )
}

export default senderLayout;