import React, {Component} from 'react';
import { Button,Modal,ModalHeader,ModalBody,ModalFooter,Form,FormGroup,Table,Input,Col,Row,Label,Nav,NavItem,TabContent,TabPane,NavLink} from 'reactstrap';
import Switch from "react-switch";
import Select from 'react-select';
import '../../../../components/search-variable.css';
import Mentions from '../../../../components/mentions/mentions';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import TabAdvanced from "../../../../components/box/tab_advanced";
import TitleOperator from "../../../../components/title-operator";
import i18next from '../../../../../i18n';

class VariableLayout extends Component {

    constructor(props){
        super(props)
        this.state = {
            activeTab: '1',
            statement: {
                name: '',
                value: ''
            }
        };
        this.toggleTab = this.toggleTab.bind(this);
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
          this.setState({
            activeTab: tab
          });
        }
    }

    getTextFromKey = (key) => {
        const var_list = this.props.varList;
        const items = var_list.filter((item) => {
            return item.id == key;
        });
        return items.length > 0? items[0].name : "";
    }

    getLabelFromID = (key)=>{
        const var_list = this.props.varList || [];
        const au = var_list.filter((item) =>  (item.id===key));
        if (au.length > 0) {
            return au[0].name;
        }
        return ;
    }

    optionsVariable = () => {
        const var_list = this.props.varList || [];
        return var_list.filter((item) =>  ( item.text && item.text.length > 0))
        .map((item) => ({ value: item.id, label: item.name }));
    }

    current_vars = () => {
        const { variables = [] } = this.props;
        if ( variables.length >= 0 ){
            return variables.map((item, index) => {
                return (
                <tr key={this.props.operator_id + "_" + index}>
                    <td style={{width:'45%'}}>
                        <Input 
                            disabled = {!item.isNew}
                            id={index}
                            name={index} type="text"
                            className="inputModal"
                            placeholder={i18next.t('name')}
                            value={item.name}
                            onChange={(e) => this.props.updateReceivedKey( index, e.target.value, 'name') }
                        />
                    </td>
                    <td style={{width:'45%'}}>
                    <FormGroup 
                            style={{textAlign:'start'}}
                            >
                        <Mentions 
                            k={index}
                            index={index} 
                            name={index}
                            varList={this.props.varList} 
                            defaultValue={item.value}
                            updateItem={this.props.updateReceivedKey}
                        />                 
                    </FormGroup>
                    </td>
                    <td style={{width:'10%'}}>
                        <Button color="info" size="lg" onClick={() => this.props.removeParameter(index) }><FontAwesomeIcon icon={faTrash} /></Button>
                    </td>
                </tr>);
            });
        } else {
            const vars = this.props.variables || {};
            return Object.keys(vars).map((k, index) => {
                return (
                    <tr key={this.props.operator_id + "_" + index} >
                        <td style={{width:'45%'}}>
                            <Input 
                                disabled
                                id={index}
                                name={index} type="text"
                                className="inputModal"
                                placeholder={i18next.t('name')}
                                value={vars[k].name}
                                onChange={(e) => this.props.updateReceivedKey( index, e.target.value,'name') }
                                />
                        </td>
                        <td style={{width:'45%'}}>
                            <FormGroup style={{textAlign:'start'}}>
                                <Mentions 
                                    k={index}
                                    index={index} 
                                    name={index}
                                    varList={this.props.varList} 
                                    defaultValue={vars[k].value || ''}
                                    updateItem={this.props.updateReceivedKey}
                                    />                 
                            </FormGroup>
                        </td>
                        <td style={{width:'10%'}}>
                            <Button color="info" size="lg" onClick={() => this.props.removeParameter(index) }><FontAwesomeIcon icon={faTrash} /></Button>
                        </td>
                    </tr>
                );
            });
        }
    }

    // key: recibe 'name' o 'value'
    changeValueParameter = (key, value) => {
        const { statement } = this.state;
        statement[key] = (key != 'name') ? value : this.getTextFromKey(value);
        if(key == 'name'){
            statement['id'] = value; 
        }
        this.setState({ statement });
    }
    
    addCurrentVar = (e) => {
        const { statement } = this.state;
        if(statement.name !== '' && statement.value !==''){
            this.props.addCurrentVar( statement );
            this.setState({ statement: {name:'',value:'', id:''} });
        }else{
            alert(i18next.t('complete_info_add_var'));
        }

    }

    

    render(){
        const customStyles = {
            ///.....
            menuPortal: provided => ({ ...provided, zIndex: 9999 }),
            menu: provided => ({ ...provided, zIndex: 9999 })
            ///.....
          }

        return (
            <React.Fragment>
                <Modal className="variable" isOpen={this.props.isOpen} toggle={this.props.closeModal} fade size="lg">
                    <ModalHeader toggle={this.props.closeModal}>{i18next.t('config_vars')}</ModalHeader>
                    <ModalBody>
                    <Form role="form"> 
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                    className={classnames({ active: this.state.activeTab === '1' })}
                                    onClick={() => { this.toggleTab('1'); }} >
                                    {i18next.t('basic')}
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={this.state.activeTab}>
                                <TabPane tabId="1">
                                    <Row>
                                        <Col sm="12">                                            
                                            <Row>
                                                <Col sm="12">
                                                    <h2>{i18next.t('list_var')}</h2>
                                                    <Table>
                                                        <thead>
                                                            <tr>
                                                                <th style={{width:'45%'}}>{i18next.t('name')}</th>
                                                                <th style={{width:'45%'}}>{i18next.t('value')}</th>
                                                                <th style={{width:'10%'}}>{i18next.t('actions')}</th>
                                                            </tr>
                                                        </thead>                                    
                                                        <tbody>
                                                            <tr>
                                                                <td style={{width:'45%'}}>
                                                                    <Select
                                                                        onChange = { (e) => { this.changeValueParameter('name', e.value) } }
                                                                        value = { {value: this.state.statement.id, label: this.getLabelFromID(this.state.statement.id) } }
                                                                        options = {this.optionsVariable()}
                                                                        placeholder={i18next.t('select_one')}
                                                                        isMulti={false}
                                                                        menuPortalTarget={document.body}
                                                                        menuPosition={'fixed'} 
                                                                        styles={customStyles}

                                                                    />
                                                                </td>
                                                                <td style={{width:'45%'}}>
                                                                <FormGroup style={{textAlign:'start'}}>
                                                                    <Mentions 
                                                                        k={'value'}
                                                                        index={0} 
                                                                        name={0}
                                                                        varList={this.props.varList} 
                                                                        defaultValue={this.state.statement.value || ''}
                                                                        updateItem={this.changeValueParameter}
                                                                        />                 
                                                                </FormGroup>
                                                                    {/* <Input type="text" value={this.state.statement.value} onChange={ (e) => { this.changeValueParameter('value', e.target.value) } }  /> */}
                                                                    </td>
                                                                <td style={{width:'10%'}}>
                                                                    <Button color="success" onClick={this.addCurrentVar}><FontAwesomeIcon icon={faPlus} /></Button>
                                                                </td>
                                                            </tr>
                                                            {this.current_vars()}
                                                        </tbody>
                                                    </Table>
                                                    <Button className="offset-4 col-4" color="info" size="lg" onClick={this.props.addNewTextBlock}>{i18next.t('create_new')}</Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </TabPane>
                            </TabContent>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Col sm="6" align="right">
                            <Button color="success" size="lg" type="button" onClick={this.props.toggleModuleModal} >{i18next.t('finish')}</Button>
                        </Col>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}
  
export default VariableLayout