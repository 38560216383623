import React from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Col, Row, Button, 
  Container,  FormGroup, Input, Label,
  Table, Pagination, PaginationItem, PaginationLink } from 'reactstrap';

import { getBuilderQuery } from "../../../../redux/actions/analyticsActions";


function paginationTable(props) 
{
    const {analyticsReducer: {languages, downloadFile} } = props;

        var pagination = props.pagination;
       // pagination.pages = props.pages / 10;
        //pagination.current = 1;
        // pages array
        var pages = [];
        for(let i=0; i<pagination.pages; i++){
            pages.push(i+1);
        } 
        var ep1 = false; // first dots ...
        var ep2 = false; // second dots ...

        var pagination_list = [];
        pages.map((k, index)=>{
        let label = "";
        if(k>1 && k<(pagination.current-1)){
            if(!ep1){
                label ="...";
                ep1 = true;
            }
        }else if(k<(pagination.pages) && k>(pagination.current+1)){
            if(!ep2){
                label ="...";
                ep2 = true;  
            }

        } else {
            label =k;
        }
        if(label){
            pagination_list.push( <PaginationItem key={k} active={k === pagination.current}>
            <PaginationLink onClick={(e)=>onchange(e,k)}>
                {label}
            </PaginationLink>
            </PaginationItem> );
        }

        });



        // bot info
        var data = {};

        // selects ///  -- languages
        const sel_language = [];
        var aux2 = false;
        for(let i = 0; i < languages.length; i++){
        let aux = JSON.parse(languages[i]);
        if(aux.id == data.language_id) {
        aux2 = true;
        }
        sel_language.push(<option key={"leng_id-"+i} value={aux.id} selected={aux2} >{aux.language}</option>);
        aux2= false;
        }


        const parseMessage  = (text) =>{
        if ( RegExp("^[{]+.+[\}]+$").test(text) ) {
            return JSON.parse(`${text}`).text;
        }
        return text;
        }

        const dataTable = [];
        const datos = props.data || [];
        // there's no data
        if (datos.length > 0 ) { 
        datos.map((k, index) => {
            dataTable.push(<tr key={index}>
                {k.map((td,j)=>{
                return(<td key={j}>
                    <p> {td}</p>
                </td>)
                })}
            </tr> );
        }); 
        } else {
        let msg = "Parece que no hay datos para este rango de fechas, selecciona otro.";
        dataTable.push(<tr key={1}><td colSpan={4}><p><i>{msg}</i></p></td></tr>);
        }


        return (
            <React.Fragment>
                <Col xs={12}>
                    <Col className="col-2 offset-10 mb-3">
                        <Button type="button" onClick={downloadFile} className="btn filter-btn btn-block btn-success" >Descargar</Button> 
                    </Col>
                    </Col>
                    <Col xs={12}  className="table-responsive">
                    <a id="target-download" hidden > 
                    </a>
                    <Table className="col-12 pt-5" bordered>
                    <thead>
                    <tr>{props.columns.map((k,i)=>{
                    return <th key={'th'+i}>{k.label}</th>
                    })}

                    </tr>
                    </thead>
                    <tbody>
                        {dataTable}
                    </tbody>
                    </Table>


                    <div className="pagination-wrapper">

                    <Pagination className="d-flex align-items-center justify-content-center" size="sm" aria-label="Page navigation funnel">

                    <PaginationItem disabled={pagination.current <= 1}>
                    <PaginationLink previous onClick={(e)=>onchange(e,pagination.current-1)} />
                    </PaginationItem>

                    {
                    pagination_list
                    }

                    <PaginationItem disabled={pagination.current >= pagination.pages}>
                    <PaginationLink next onClick={(e)=>onchange(e,pagination.current+1)} />
                    </PaginationItem>

                    </Pagination>

                    </div>

                    </Col>
            </React.Fragment>
        );

        function onchange(e,current)
        {
          props.props.getBuilderQuery(current);
        }
        
}
const mapStateToProps = ({ analyticsReducer,  }) => {
	return { analyticsReducer,  };
};

const mapDispatchToProps = {
    getBuilderQuery,
};


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(paginationTable));


