import React from 'react';
import Admin from '../../admin/containers/admin';
import SliceMenuApp from '../../admin/components/slice-menu';
import { Col, Row, Button, 
  Container,  FormGroup, Input, Label,
  Table, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import ToolbarApp from '../../admin/components/toolbar';
import BotMenuOptions from'../../menus/bot-menu-options';
import './bot-statistics-layout.css';
import Date from "../../../components/datepicker";
import { messageUser } from '../../../utils/isValidate';
import { decode } from 'punycode';
import i18next from '../../../../i18n';


function BotGeneralStatsLayout(props) {
    
    // pages array
    var pages = [];
        for(let i=0; i<props.pagination.pages; i++){
            pages.push(i+1);
        } 
    var ep1 = false; // first dots ...
    var ep2 = false; // second dots ...

    var pagination_list = [];
    pages.map((k, index)=>{
        let label = "";
        if(k>1 && k<(props.pagination.current-1)){
            if(!ep1){
                label ="...";
                ep1 = true;
            }
        }else if(k<(props.pagination.pages) && k>(props.pagination.current+1)){
            if(!ep2){
                label ="...";
                ep2 = true;  
            }

        } else {
            label =k;
        }
        if(label){
           pagination_list.push( <PaginationItem key={k} active={k === props.pagination.current}>
              <PaginationLink onClick={(e)=>{props.tableData(k)}}>
                {label}
              </PaginationLink>
            </PaginationItem> );
        }

    })


    // languages
    var languages = [];
    // bot info
    var data = {};
  
    // selects ///  -- languages
    const sel_language = [];
    var aux2 = false;
    for(let i = 0; i < props.languages.length; i++){
      let aux = JSON.parse(props.languages[i]);
      if(aux.id == data.language_id) {
        aux2 = true;
      }
      sel_language.push(<option key={"leng_id-"+i} value={aux.id} selected={aux2} >{aux.language}</option>);
      aux2= false;
    }

   
    const parseMessage  = (text) =>{
        if ( RegExp("^[{]+.+[\}]+$").test(text) ) {
            return JSON.parse(`${text}`).text;
        }
        return text;
    }

    const dataTable = [];
    const datos = props.data || [];
    // there's no data
    if (datos.length > 0 ) { 
       datos.map((k, index) => {
            dataTable.push(<tr key={index}>
                <td>
                    <p> {k.moment.substr(0,16)}</p>
                </td>
                <td>
                    <p> {k.session_id}</p>
                </td>
                {
                     (( k.interactor == 'receiver' )? 
                     <React.Fragment><td><p>{`${parseMessage(k.message)}`}</p></td><td><p>{ /* space */}</p></td></React.Fragment> : 
                     <React.Fragment><td><p>{/* space */}</p></td><td><p>{`${parseMessage(k.message)}`}</p></td></React.Fragment>)
                }
            </tr> );
        }); 
    } else {
        let msg = i18next.t('registers_not_found_dates');
        dataTable.push(<tr key={1}><td colSpan={4}><p><i>{msg}</i></p></td></tr>);
    }



  return (
    <div className="BotLayout">
          <Admin>
            <SliceMenuApp>
                <BotMenuOptions botId={props.botId}/>
            </SliceMenuApp>
            <Container fluid>
                <ToolbarApp>
                    <Row>
                        <Col sm="3" md="3" className="text pdtoolbar">
                        <h2 className="text-left ml-4 font-weight-bold">{i18next.t('report_interactions')} <span className="btn-help"></span></h2>                      
                        </Col>
                    </Row>
                </ToolbarApp>
            </Container>
            <Container fluid className="box-fluid">
                <Col sm="12" md="10"  className="stadistics-container  offset-md-1">
                    <Row>
                        <Col xs="12" md="12" lg="8" className="offset-lg-2">
                            <Row className="filter-information">
                            <Col className="col-12">
                            <h2 className="font-weight-bold">{i18next.t('general_report')}</h2>
                        </Col>
                        <Col xs={12}>
                                    <Row className="filter-information">
                                        <Col xs={12}>
                                            <Row>
                                                <Col xs={12} md={12} lg={3} className="Label-filter d-flex align-items-center justify-content-center">
                                                    <Label>{i18next.t('interval_dates')}</Label>
                                                </Col>
                                                <Col xs={12} md={12} lg={7} className="date-picker-filter d-flex align-items-center justify-content-center">
                                                    <FormGroup>
                                                        <Date startDate={props.startDate} endDate={props.endDate} handleChangeStart={props.handleChangeStart} handleChangeEnd={props.handleChangeEnd}/> 
                                                    </FormGroup>
                                                </Col>
                                                <Col xs={12} md={12} lg={2} className="d-flex align-items-center justify-content-center">
                                                    <Button className="btn filter-btn pl-5 pr-5 btn-guarumo-md" onClick={(e)=>{props.tableData(1)}}>{i18next.t('filter')}</Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    </Col>
                                </Row>
                        </Col>
                        <Col xs={12}>
                            <Col className="col-2 offset-10 mb-3">
                                <Button type="button" onClick={props.downloadFile} className="btn filter-btn btn-block btn-success" >{i18next.t('download')}</Button> 
                            </Col>
                        </Col>
                        <Col xs={12}  className="table-responsive">
                        <a id="target-download" hidden > 
                        </a>
                        <Table className="col-12 pt-5" bordered>
                        <thead>
                            <tr>
                                <th>{i18next.t('date')}</th>
                                <th>ID</th>
                                <th>{i18next.t('user')}</th>
                                <th>Bot</th>
                            </tr>
                            </thead>
                            <tbody>
                                {dataTable}
                            </tbody>
                        </Table>


                    <div className="pagination-wrapper">

                      <Pagination className="d-flex align-items-center justify-content-center" size="sm" aria-label="Page navigation funnel">

                        <PaginationItem disabled={props.pagination.current <= 1}>
                          <PaginationLink previous onClick={(e)=>{props.tableData(props.pagination.current-1)}} />
                        </PaginationItem>

                        {
                            pagination_list
                        }

                        <PaginationItem disabled={props.pagination.current >= props.pagination.pages}>
                          <PaginationLink next onClick={(e)=>{props.tableData(props.pagination.current+1)}} />
                        </PaginationItem>

                      </Pagination>

                      </div>

                    </Col>
                    </Row>
                </Col>
            </Container>
          </Admin>      
    </div>
  )
}

export default BotGeneralStatsLayout