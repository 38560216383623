import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import qs from "qs";

import { Layout, Divider, Empty, Select } from "antd";
import { QueryBuilder, QueryRenderer } from "@cubejs-client/react";
import { find, propEq } from "ramda";
import cubejs from "@cubejs-client/core";
import { timeParse } from "d3-time-format";
import "antd/dist/antd.css";

import ReactExport from "react-export-excel";

import DataTable from "./dataTableCubejs";

import ChartRenderer from "./ChartRenderer";
var d3 = require("d3");
var dateFormat = require("dateformat");

//Cuando se reciban los datos del WS se descomentan y descomentan estos archivos
import LineChart from "../chart-react-d3/convertLineChartInteractive";
//import LineChart from '../../components/infoViz/chart-react-d3/d3Components/LineChart'
import BarChart from "../chart-react-d3/convertBarChart";
//import BarChart from '../../components/infoViz/chart-react-d3/d3Components/BarChart'
import DSPieChart from "../chart-react-d3/convertDonutChart";
//import DSPieChart from '../../components/infoViz/chart-react-d3/d3Components/DonutChart'
import PSChart from "../chart-react-d3/convertPSChart";
//import PSChart from '../../components/infoViz/chart-react-d3/d3Components/PSChart'
import SquareText from "../chart-react-d3/convertSquareText";
import { ascetic } from "react-syntax-highlighter/dist/styles/hljs";
//import SquareText from '../../components/infoViz/chart-react-d3/d3Components/SquareText'

import {
  getBuilderQuery,
  saveNewMetric,
  setDataChart,
  cleanDataChart,
  cleanPreview,
  loading,
} from "../../../../redux/actions/analyticsActions";

import { showError } from "../../../../redux/actions/loadStateActions";
import { logOut } from "../../../../redux/actions/loginActions";

import i18next from '../../../../../i18n';

function cubejsQueryRenderData(props) {
  /* Format Data */
  var parseDate = d3.timeParse("%Y");

  const {
    analyticsReducer: {
      tokenCubejs,
      selectedMetric,
      granularity,
      startDateDashboard,
      endDateDashboard,
      selectedCompany,
    },
  } = props;

  const cubejsApi = cubejs(
    tokenCubejs,
    // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE1NzIzODE5MDksImV4cCI6MTU3MjQ2ODMwOX0.Sn_NS6d3Mhua9b5geayUlYVQGrZcI4tOg36yeEvVT6k",
    { apiUrl: CUBE_API }
  );

  let dimensions = ["ChatTraceability.botName"];
  let measures = ["ChatTraceability.countDistinctSession"];
  let operation = {};
  let filters = [
    {
      dimension: "ChatTraceability.companyId",
      operator: "equals",
      values: ["" + selectedCompany],
    },
  ];

  let granu = granularity;

  if (granularity == "null") granu = null;

  let timeDimensions = [{}];
  if (granularity == "all") {
    timeDimensions = [
      {
        dimension: "ChatTraceability.startTime",
        dateRange: [startDateDashboard, endDateDashboard],
      },
    ];
  } else {
    timeDimensions = [
      {
        dimension: "ChatTraceability.startTime",
        dateRange: [startDateDashboard, endDateDashboard],
        granularity: granu,
      },
    ];
  }

  let title = "",
    xAxisName = "",
    yAxisName = "",
    description = "";

  if (props.metric) {
    if (props.nameDiv == "preview" && props.metric.length == 0) {
    } else {
      title = props.metric.short_name;
      xAxisName = props.metric.unit.symbol;
      yAxisName = "";
    }

    if (props.metric.length == 0 || props.metric.operation == "") {
      operation = {
        measures: measures, //, "LineItems.quantity", "LineItems.price", "Orders.count",  "Orders.number", "Users.count"'],
        dimensions: dimensions, //, "Orders.status",  "Orders.createdAt", "Orders.completedAt",  "Users.city",  "Users.gender","Users.company", "Users.createdAt"']
        filters: filters,
        timeDimensions: timeDimensions,
        timezone: "America/Bogota",
      };
    } else {
      // operation=JSON.parse(props.metric.operation);

      let model = "";
      measures = [];
      for (var i = 0; i < props.metric.measures.length; i++) {
        const m = props.metric.measures[i];
        model = m.model;
        measures.push(m.model + "." + m.name);
      }

      let finalMeasure = null;

      if (measures.length > 0) finalMeasure = measures;

      // measures=props.metric.measures.map((m)=>{
      //   return ""+m.model+"."+m.name;
      // })

      dimensions = [];
      for (var j = 0; j < props.metric.dimension.length; j++) {
        const n = props.metric.dimension[j];
        model = n.model;
        dimensions.push(n.model + "." + n.name);
      }

      let finalDimensions = null;

      if (dimensions.length > 0) finalDimensions = dimensions;

      //-------------------------------------------------
      // FILTRO POR COMPAÑÍA //OJO CUANDO SE ACTUALICE LA BD CON LA INFO DE COMPAÑIA EN COVERSATIONS SE DEBE QUITAR EL IF
      //-------------------------------------------------
      //if(model=='Conversations'){}else{
      filters = [
        {
          dimension: model + ".companyId",
          operator: "equals",
          values: ["" + selectedCompany],
        },
      ];

      // }

      //-------------------------------------------------
      // FILTROS DE DIMENSIONES
      //-------------------------------------------------
      if (props.metric.filters_dimension.length > 0) {
        for (var k = 0; k < props.metric.filters_dimension.length; k++) {
          const f = props.metric.filters_dimension[k];
          filters.push({
            dimension: "" + f.dimension.model + "." + f.dimension.name,
            operator: "" + f.filter_operator.values,
            values: ["" + f.values],
          });
        }
      }

      //-------------------------------------------------
      // FILTROS DE MEDIDAS
      //-------------------------------------------------
      if (props.metric.filters_measures.length > 0) {
        for (var k = 0; k < props.metric.filters_measures.length; k++) {
          const f = props.metric.filters_measures[k];
          filters.push({
            dimension: "" + f.dimension.model + "." + f.dimension.name,
            operator: "" + f.filter_operator.values,
            values: ["" + f.values],
          });
        }
      }

      if (props.metric.time_dimension) {
        if (props.nameDiv == "preview") {
          timeDimensions = [
            {
              dimension:
                "" +
                props.metric.time_dimension.dimension.model +
                "." +
                props.metric.time_dimension.dimension.name,
              dateRange: [
                moment().subtract(1, "months").format("L"),
                moment().format("L"),
              ],
              granularity: "day",
            },
          ];
        } else {
          if (!props.metric.time_dimension.granularity) {
            //timeDimensions=null
            timeDimensions = [
              {
                dimension:
                  "" +
                  props.metric.time_dimension.dimension.model +
                  "." +
                  props.metric.time_dimension.dimension.name,
                dateRange: [startDateDashboard, endDateDashboard],
                granularity: null,
              },
            ];
          } else {
            if (granu == "all") {
              timeDimensions = [
                {
                  dimension:
                    "" +
                    props.metric.time_dimension.dimension.model +
                    "." +
                    props.metric.time_dimension.dimension.name,
                  dateRange: [startDateDashboard, endDateDashboard],
                },
              ];

              finalDimensions.push(
                "" +
                  props.metric.time_dimension.dimension.model +
                  "." +
                  props.metric.time_dimension.dimension.name
              );
            } else {
              timeDimensions = [
                {
                  dimension:
                    "" +
                    props.metric.time_dimension.dimension.model +
                    "." +
                    props.metric.time_dimension.dimension.name,
                  dateRange: [startDateDashboard, endDateDashboard],
                  granularity: granu,
                },
              ];
            }
          }
        }
      } else timeDimensions = null;

      if (props.metric.additional_params.length > 0) {
        for (var h = 0; h < props.metric.additional_params.length; h++) {
          if (props.metric.additional_params[h].key == "segments")
            operation[props.metric.additional_params[h].key] = [
              props.metric.additional_params[h].values,
            ];
          else
            operation[props.metric.additional_params[h].key] =
              props.metric.additional_params[h].values;
        }
      }

      if (timeDimensions != null) operation.timeDimensions = timeDimensions;
      if (finalMeasure != null) operation.measures = finalMeasure;
      if (finalDimensions != null) operation.dimensions = finalDimensions;
      if (filters != null) operation.filters = filters;

      operation.timezone = "America/Bogota";
    }
  } else {
    operation = {
      measures: measures, //, "LineItems.quantity", "LineItems.price", "Orders.count",  "Orders.number", "Users.count"'],
      dimensions: dimensions, //, "Orders.status",  "Orders.createdAt", "Orders.completedAt",  "Users.city",  "Users.gender","Users.company", "Users.createdAt"']
    };
  }

  if (operation == {}) {
    return "Sin operación";
  } else {
    function formatDate(date) {
      if (granularity == "year") {
        date = dateFormat(date, "yyyy");
      } else if (granularity == "month") {
        date = dateFormat(date, "yyyy-mm");
      } else if (granularity == "week" || granularity == "day") {
        date = dateFormat(date, "yyyy-mm-dd");
      } else if (granularity == "hour") {
        date = dateFormat(date, "yyyy-mm-dd hh tt");
      } else {
        date = dateFormat(date, "yyyy-mm-dd hh:mm:ss.l tt Z");
      }
      return date;
    }
    return ( 
      <QueryRenderer
        query={operation}
        cubejsApi={cubejsApi}
        render={(resultSet) => {
          try {
            if (!resultSet.resultSet) {
              var bots = document.getElementById("agrupacionSelect");
              if (bots) bots.disabled = true;

              bots = document.getElementById("btn_filtrar");
              if (bots) bots.disabled = true;

              return (
                <img
                  src="/static/img/loading.gif"
                  style={{ display: "block", margin: "auto" }}
                />
              );
            } else if (resultSet.resultSet.loadResponse) {
              cleanDataChart();

              let data = {};
              let datosTotal = [];
              let preview = [];
              let typechart = props.typechart;

              let numDimensions = 0;
              let numMeasures = 0;
              let numTimeDimensions = 0;

              if (resultSet.resultSet.loadResponse.query.dimensions)
                numDimensions =
                  resultSet.resultSet.loadResponse.query.dimensions.length;

              if (resultSet.resultSet.loadResponse.query.measures)
                numMeasures =
                  resultSet.resultSet.loadResponse.query.measures.length;

              if (resultSet.resultSet.loadResponse.query.timeDimensions)
                numTimeDimensions =
                  resultSet.resultSet.loadResponse.query.timeDimensions.length;

              if (numTimeDimensions > 0) {
                if (
                  resultSet.resultSet.loadResponse.query.timeDimensions[0]
                    .granularity == null
                ) {
                  numTimeDimensions = 0;
                  if (typechart == 2) typechart = 3;
                }
              }

              //   let rawData=resultSet.resultSet.rawData();
              //   if(rawData.length==1)
              //     typechart = 5

              if (typechart == 1) {
                //-------------------------------------------------
                //               BARCHART
                //-------------------------------------------------
                let datos = [];
                let rawData = resultSet.resultSet.rawData();
                for (var i = 0; i < rawData.length; i++) {
                  datos = rawData[i];

                  let dimension = [];

                  if (numTimeDimensions == 0)
                    dimension =
                      datos[
                        resultSet.resultSet.loadResponse.query.dimensions[0]
                      ];
                  else {
                    let date =
                      datos[
                        resultSet.resultSet.loadResponse.query.timeDimensions[0]
                          .dimension
                      ];
                    dimension = formatDate(date);
                    //   const parseTime = timeParse('%Y%m%d');
                    //   dimension=new Date(datos[resultSet.resultSet.loadResponse.query.timeDimensions[0].dimension])
                    //   dimension=dimension.toLocaleDateString("es-ES")
                  }
                  if (i == 0) {
                    datosTotal = [
                      {
                        group: "All",
                        botID: "All",
                        category: dimension,
                        measure:
                          datos[
                            resultSet.resultSet.loadResponse.query.measures[0]
                          ],
                      },
                    ];
                  } else {
                    datosTotal.push({
                      group: "All",
                      botID: "All",
                      category: dimension,
                      measure:
                        datos[
                          resultSet.resultSet.loadResponse.query.measures[0]
                        ],
                    });
                  }
                }
                data.datasetBarChart = datosTotal;

                if (
                  props.nameDiv == "preview" &&
                  data.datasetBarChart.length <= 1
                ) {
                  data.datasetBarChart = [
                    {
                      group: "All",
                      botID: "All",
                      category: "Bot",
                      measure: 63850.4963,
                    },
                    {
                      group: "All",
                      botID: "All",
                      category: "Usuario",
                      measure: 78258.0845,
                    },
                    {
                      group: "All",
                      botID: "All",
                      category: "Paso Agente",
                      measure: 60610.2355,
                    },
                    {
                      group: "All",
                      botID: "All",
                      category: "No entendidos",
                      measure: 30493.1686,
                    },
                    {
                      group: "Web",
                      botID: "All",
                      category: "Bot",
                      measure: 19441.5648,
                    },
                    {
                      group: "Web",
                      botID: "All",
                      category: "Usuario",
                      measure: 25922.0864,
                    },
                    {
                      group: "Web",
                      botID: "All",
                      category: "Paso Agente",
                      measure: 9720.7824,
                    },
                    {
                      group: "Web",
                      botID: "All",
                      category: "No entendidos",
                      measure: 6480.5216,
                    },
                    {
                      group: "Facebook",
                      botID: "All",
                      category: "Bot",
                      measure: 22913.2728,
                    },
                    {
                      group: "Facebook",
                      botID: "All",
                      category: "Usuario",
                      measure: 7637.7576,
                    },
                    {
                      group: "Facebook",
                      botID: "All",
                      category: "Paso Agente",
                      measure: 23549.7526,
                    },
                    {
                      group: "Facebook",
                      botID: "All",
                      category: "No entendidos",
                      measure: 1909.4394,
                    },
                    {
                      group: "Whatsapp",
                      botID: "All",
                      category: "Bot",
                      measure: 1041.5124,
                    },
                    {
                      group: "Whatsapp",
                      botID: "All",
                      category: "Usuario",
                      measure: 2430.1956,
                    },
                    {
                      group: "Whatsapp",
                      botID: "All",
                      category: "Paso Agente",
                      measure: 15275.5152,
                    },
                    {
                      group: "Whatsapp",
                      botID: "All",
                      category: "No entendidos",
                      measure: 4166.0496,
                    },
                  ];
                }

                function barChart() {
                  return (
                    <React.Fragment>
                      <BarChart
                        nameDiv={props.nameDiv}
                        data={data}
                        group={"All"}
                        botID={"All"}
                        dateini={"All"}
                        datefin={"All"}
                        color={props.color.name}
                        typeScaleColor={props.typepalette}
                        title={title}
                        xAxisName={xAxisName}
                        yAxisName={yAxisName}
                        description={description}
                      />
                    </React.Fragment>
                  );
                }

                preview = barChart();

                var bots = document.getElementById("agrupacionSelect");
                if (bots) bots.disabled = false;

                bots = document.getElementById("btn_filtrar");
                if (bots) bots.disabled = false;
              } else if (typechart == 2) {
                //-------------------------------------------------
                //              MULTILINECHART
                //-------------------------------------------------

                // data.datasetLineChart =[]
                let datosLine = [];
                let datosTotalLine = [];
                let name;
                let countNames = 0;
                let rawData = resultSet.resultSet.rawData();
                let serie = [];

                for (var i = 0; i < rawData.length; i++) {
                  datosLine = rawData[i];

                  let dimension = [];
                  let nameNew = "";

                  dimension = new Date(
                    datosLine[
                      resultSet.resultSet.loadResponse.query.timeDimensions[0].dimension
                    ]
                  );

                  // dimension=dimension.toLocaleDateString("es-ES")

                  if (i == 0) {
                    name =
                      datosLine[
                        resultSet.resultSet.loadResponse.query.dimensions[0]
                      ];
                    datosTotalLine = [
                      {
                        name: name,
                        values: [
                          {
                            group: "All", //k[resultSet.resultSet.loadResponse.query.dimensions[0]],
                            dateini: dimension,
                            measure:
                              datosLine[
                                resultSet.resultSet.loadResponse.query
                                  .measures[0]
                              ],
                          },
                        ],
                      },
                    ];
                  } else {
                    nameNew =
                      datosLine[
                        resultSet.resultSet.loadResponse.query.dimensions[0]
                      ];
                    serie = find(propEq("name", nameNew))(datosTotalLine);
                    if (serie) {
                      serie.values.push({
                        group: "All", //k[resultSet.resultSet.loadResponse.query.dimensions[0]],
                        dateini: dimension,
                        measure:
                          datosLine[
                            resultSet.resultSet.loadResponse.query.measures[0]
                          ],
                      });
                    }
                    // if(name==nameNew)
                    // {
                    //   datosTotalLine[countNames].values.push(
                    //       {
                    //         group:"All",//k[resultSet.resultSet.loadResponse.query.dimensions[0]],
                    //         dateini:dimension,
                    //         measure:datosLine[resultSet.resultSet.loadResponse.query.measures[0]]
                    //        }
                    //     );
                    // }
                    else {
                      countNames++;
                      name = nameNew;
                      datosTotalLine.push({
                        name: name,
                        values: [
                          {
                            group: "All", //k[resultSet.resultSet.loadResponse.query.dimensions[0]],
                            dateini: dimension,
                            measure:
                              datosLine[
                                resultSet.resultSet.loadResponse.query
                                  .measures[0]
                              ],
                          },
                        ],
                      });
                    }
                  }
                }

                data.datasetLineChart = datosTotalLine;

                if (
                  props.nameDiv == "preview" &&
                  data.datasetLineChart.length <= 1
                ) {
                  data.datasetLineChart = [
                    {
                      name: "Bot 1",
                      values: [
                        {
                          group: "Whatsapp",
                          dateini: parseDate(2008),
                          datefin: 2008,
                          measure: 25,
                        },
                        {
                          group: "Whatsapp",
                          dateini: parseDate(2009),
                          datefin: 2008,
                          measure: 10,
                        },
                        {
                          group: "Whatsapp",
                          dateini: parseDate(2010),
                          datefin: 2008,
                          measure: 20,
                        },
                        {
                          group: "Whatsapp",
                          dateini: parseDate(2011),
                          datefin: 2008,
                          measure: 30,
                        },
                        {
                          group: "Whatsapp",
                          dateini: parseDate(2012),
                          datefin: 2008,
                          measure: 40,
                        },
                      ],
                    },
                    {
                      name: "Bot 2",
                      values: [
                        {
                          group: "Web",
                          dateini: parseDate(2008),
                          datefin: 2008,
                          measure: 10,
                        },
                        {
                          group: "Web",
                          dateini: parseDate(2009),
                          datefin: 2008,
                          measure: 10,
                        },
                        {
                          group: "Web",
                          dateini: parseDate(2010),
                          datefin: 2008,
                          measure: 23,
                        },
                        {
                          group: "Web",
                          dateini: parseDate(2011),
                          datefin: 2008,
                          measure: 6,
                        },
                        {
                          group: "Web",
                          dateini: parseDate(2012),
                          datefin: 2008,
                          measure: 15,
                        },
                      ],
                    },
                    {
                      name: "Bot 3",
                      values: [
                        {
                          group: "Facebook",
                          dateini: parseDate(2008),
                          datefin: 2008,
                          measure: 18,
                        },
                        {
                          group: "Facebook",
                          dateini: parseDate(2009),
                          datefin: 2008,
                          measure: 13,
                        },
                        {
                          group: "Facebook",
                          dateini: parseDate(2010),
                          datefin: 2008,
                          measure: 21,
                        },
                        {
                          group: "Facebook",
                          dateini: parseDate(2011),
                          datefin: 2008,
                          measure: 12,
                        },
                        {
                          group: "Facebook",
                          dateini: parseDate(2012),
                          datefin: 2008,
                          measure: 14,
                        },
                      ],
                    },
                    {
                      name: "Bot 4",
                      values: [
                        {
                          group: "Facebook",
                          dateini: parseDate(2008),
                          datefin: 2008,
                          measure: 10,
                        },
                        {
                          group: "Facebook",
                          dateini: parseDate(2009),
                          datefin: 2008,
                          measure: 23,
                        },
                        {
                          group: "Facebook",
                          dateini: parseDate(2010),
                          datefin: 2008,
                          measure: 12,
                        },
                        {
                          group: "Facebook",
                          dateini: parseDate(2011),
                          datefin: 2008,
                          measure: 19,
                        },
                        {
                          group: "Facebook",
                          dateini: parseDate(2012),
                          datefin: 2008,
                          measure: 34,
                        },
                      ],
                    },
                  ];
                }

                function lineChart() {
                  return (
                    <div>
                      <LineChart
                        nameDiv={props.nameDiv}
                        data={data}
                        group={"All"}
                        color={props.color.name}
                        typeScaleColor={props.typepalette}
                        callBackLineChart={null}
                        title={title}
                        xAxisName={xAxisName}
                        yAxisName={yAxisName}
                        description={description}
                        granularity={granularity}
                      />
                    </div>
                  );
                }

                // return (
                //   <ChartRenderer resultSet={resultSet.resultSet} props={props} />
                //   );

                preview = lineChart();

                var bots = document.getElementById("agrupacionSelect");
                if (bots) bots.disabled = false;

                bots = document.getElementById("btn_filtrar");
                if (bots) bots.disabled = false;
              } else if (typechart == 3) {
                //-------------------------------------------------
                //             /**PIECHART */
                //-------------------------------------------------

                data.dataSet = resultSet.resultSet.rawData().map((k, index) => {
                  const datos = {
                    category:
                      k[resultSet.resultSet.loadResponse.query.dimensions[0]],
                    measure:
                      k[resultSet.resultSet.loadResponse.query.measures[0]],
                  };

                  return datos;
                });
                let withNumber = false;
                for (var i = 0; i < data.dataSet.length; i++) {
                  if (data.dataSet[i].measure != 0) {
                    withNumber = true;
                    break;
                  }
                }

                if (props.nameDiv == "preview" && !withNumber) {
                  data.dataSet = [
                    { category: "Web", measure: 1 },
                    { category: "Facebook", measure: 2 },
                    { category: "Whatsapp", measure: 1 },
                  ];
                }
                function dsChart() {
                  return (
                    <div>
                      <DSPieChart
                        nameDiv={props.nameDiv}
                        data={data}
                        callBackPieChart={null}
                        color={props.color.name}
                        typeScaleColor={props.typepalette}
                        title={title}
                        xAxisName={xAxisName}
                        yAxisName={yAxisName}
                        description={description}
                      />
                    </div>
                  );
                }

                preview = dsChart();

                var bots = document.getElementById("agrupacionSelect");
                if (bots) bots.disabled = false;

                bots = document.getElementById("btn_filtrar");
                if (bots) bots.disabled = false;
              } else if (typechart == 4) {
                /**SUNBURN */

                /*
                  data.dataPSChart = resultSet.resultSet.rawData().map((k, index) => {
                    
  
                    for(var j=0;j<numDimensions;j++)
                    {
                      let children={ "name": resultSet.resultSet.loadResponse.query.dimensions[0],
                      "children": [{
                        "name":  resultSet.resultSet.loadResponse.query.dimensions[1],
                        "children": [
                          {"name":  resultSet.resultSet.loadResponse.query.dimensions[2], "size": 1},
                          {"name": "No Comprometidos", "size": 1}
                          ]
                          }
                      ]
                    }
                    }
                    
                    let data;
  
                    data.push({
                      "name": "Usuarios",
                            "children": [
                            {
                            "name": "Nuevos",
                            "children": [
                            {"name": "Comprometidos", "size": 1},
                            {"name": "No Comprometidos", "size": 1}
                            ]
                            }
                          ]
                        })
  
                    //  category:k[resultSet.resultSet.loadResponse.query.dimensions[0]],
                   //   measure:k[resultSet.resultSet.loadResponse.query.measures[0]]
                
  
                    const datos={
                      group: "All", bot: "All", date: 2008,
                      data: data
                    }
  
                    return datos;
                  });*/

                data.dataPSChart = [
                  {
                    group: "All",
                    bot: "All",
                    date: 2008,
                    data: [
                      {
                        name: "Usuarios",
                        children: [
                          {
                            name: "Nuevos",
                            children: [
                              { name: "Comprometidos", size: 1 },
                              { name: "No Comprometidos", size: 1 },
                            ],
                          },
                          {
                            name: "Recurrentes",
                            children: [
                              { name: "Comprometidos", size: 5 },
                              { name: "No Comprometidos", size: 3 },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ];

                function psChart() {
                  return (
                    <div>
                      <PSChart
                        nameDiv={props.nameDiv}
                        data={data}
                        group={"All"}
                        botID={"All"}
                        dateini={"All"}
                        datefin={"All"}
                        color={props.color.name}
                        typeScaleColor={props.typepalette}
                        title={title}
                        xAxisName={xAxisName}
                        yAxisName={yAxisName}
                        description={description}
                      />
                    </div>
                  );
                }

                preview = psChart();

                var bots = document.getElementById("agrupacionSelect");
                if (bots) bots.disabled = false;

                bots = document.getElementById("btn_filtrar");
                if (bots) bots.disabled = false;
              } else if (typechart == 5) {
                //-------------------------------------------------
                //            /*SQUARETEXT */
                //-------------------------------------------------

                data = resultSet.resultSet.rawData().map((k, index) => {
                  const datos = {
                    name: props.metric.name,
                    title: props.metric.short_name,
                    unit: props.metric.unit.symbol,
                    icon: props.icon, //"https://image.flaticon.com/icons/svg/784/784662.svg",
                    measure: +(
                      Math.round(
                        k[resultSet.resultSet.loadResponse.query.measures[0]] +
                          "e+2"
                      ) + "e-2"
                    ),
                  };

                  return datos;
                });

                function textChart() {
                  return (
                    <div>
                      <SquareText
                        nameDiv={props.nameDiv}
                        data={data[0]}
                        dato={"Estadística 4"}
                        group={"All"}
                        botID={"All"}
                        dateini={"All"}
                        datefin={"All"}
                        color={props.color.name}
                        typeScaleColor={props.typepalette}
                        description={description}
                      />
                    </div>
                  );
                }

                preview = textChart();

                var bots = document.getElementById("agrupacionSelect");
                if (bots) bots.disabled = false;

                bots = document.getElementById("btn_filtrar");
                if (bots) bots.disabled = false;
              } else if (typechart == 6) {
                var bots = document.getElementById("agrupacionSelect");
                if (bots) bots.disabled = false;

                bots = document.getElementById("btn_filtrar");
                if (bots) bots.disabled = false;
                //------------------------------
                // Tabla de datos con download
                //------------------------------
                return <DataTable resultSet={resultSet} props={props} />;
              } else {
                cleanPreview(1);
              }
              setDataChart(data);

              return preview;
            }
          } catch (error) {
            console.log("ERROR - CATCH: " + error);
            const { response: { status } = { status } } = error;
            let msg = "";

            if (status && status == 400) {
              msg = i18next.t('message128')
             } else { 
              msg = "¡Error al generar la consulta!\n" + error;
            }
            setTimeout(() => {
              props.showError(msg);
            }, 1200);

            if (status && status == 403) {
              props.logOut();
            }
          }
        }}
      />
    );
  }
}
const mapStateToProps = ({
  analyticsReducer,
  loadStateReducer,
  loginReducer,
}) => {
  return { analyticsReducer, loadStateReducer, loginReducer };
};

const mapDispatchToProps = {
  saveNewMetric,
  setDataChart,
  cleanDataChart,
  cleanPreview,
  getBuilderQuery,
  showError,
  logOut,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(cubejsQueryRenderData));
