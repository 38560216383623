import React, { Component } from 'react';
import { connect } from "react-redux";

import BMLayout from './blockchain-layout';
import SMPortModel from '../simplePortModel/SMPortModel';
import { APPLICANT } from "../../../../redux/listComponents";

import { getHandlerPOST, getHandlerPUT, getHandlerDELETE } from '../../../../utils/handlersApi'
import { isAuthorizationValid,getRolUser,isEmpty,cerrarSesion } from "../../../../utils/commonFunction";

import { isDidMount, willUnMount } from "../../../../redux/actions/componentMountActions";

import { iconLoad, iconHideLoad, getListProviderBlockchain,getListContractBlockchain,
    toggleModalViewBoxBlockchain, addTextBlock,updateOperator, cleanSelection, 
    updateBoxOperator,createNewStatement,updateStatement,removeTextBlock} from "../../../../redux/actions/boxActions";

import axios from 'axios';
import qs from 'qs';

class BModal extends Component {
    
    state = {
        text_blocks: {'bl_0': ""},
        nodeId: "",
        keyword: "", 
        title: "",
        selection:  null,
        range: null,
        keyword_valid: true,
        timeout_valid: true,
        error_limit_item:false,
        audio:'',
        isOpenUrl:false,
        inputStatement:''
    }
 
    constructor(props){
        super(props)
    }
    
    componentWillMount(){
        isAuthorizationValid()
        const { isAdmin = false, isSuper = false } = getRolUser();
        this.setState({
            isAdmin: isAdmin,
            isSuperUser: isSuper
        });
      }


    componentDidMount()
    {
        const { boxReducer : { listProviderBlockchain,listContractBlockchain,configBoxOperator} } = this.props;

        this.props.isDidMount(APPLICANT.HOME());    

        //----------------------------------------------------------------
        //  PONE EL ICONO DE CARGA
        //----------------------------------------------------------------
         this.props.iconLoad();

        //----------------------------------------------------------------
        //  LISTA TODAS LOS POROVEEDORES DE BLOCKCHAIN, COJE EL PRIMERO COMO SELECTEDPROVIDERBLOCKCHAIN
        //----------------------------------------------------------------
        if(isEmpty(listProviderBlockchain)){
             this.props.getListProviderBlockchain();     
        }

        //----------------------------------------------------------------
        //  LISTA TODAS LOS CONTRATOS DE BLOCKCHAIN, COJE EL PRIMERO COMO SELECTEDPROVIDERBLOCKCHAIN
        //----------------------------------------------------------------
        if(isEmpty(listContractBlockchain)){
            this.props.getListContractBlockchain();     
       }


        //----------------------------------------------------
        //  OCULTA EL ICONO DE CARGA
        //----------------------------------------------------
        this.props.iconHideLoad(); 

    }
   
   /* componentWillUpdate(nextProps,nextState)
    {
        const tab = this.props.boxReducer.activeItem;
        const newTab = nextProps.boxReducer.activeItem;

        if(tab != newTab)
        {
            this.forceUpdate();
        }
    }*/

    componentWillUnmount() {
        this.props.willUnMount(APPLICANT.HOME());
      }



    // Avatar set node extras
    getAvatarId = avatarid => this.props.modal.node.extras.avatar = avatarid;
    // Timeout set node extras
    getTimeout = time => this.props.modal.node.extras.timeout = time
      // Messenger Timeout set node extras
    getMsgTimeout = msg => this.props.modal.node.extras.msg_timeout = msg
    

    /* 
     *  getOperatorVisible
     * @operator = string {si:1, no:0}
    */
    getOperatorVisible = (visible) => {
        this.props.modal.node.extras.visible = visible
        this.updateOperator('',parseInt(visible))
        if(visible == 1){
            let node = this.props.modal.node; 
            let blocks = node.extras.text_blocks;
            const kblocks = Object.keys(blocks);
            kblocks.forEach((k) => {     
                blocks[k].text = '';
                blocks[k].name = '';
                const data = {
                    id: k,
                    text: '',
                    name: ''
                };
                getHandlerPUT(HOST_API +'/api/scheme/statement/',data)
                    .then((res) => { this.props.updateSchema();  
                    }).catch((error) => {
                        console.log('catch toggleModuleModal statement',error)
                        if (error.response.status == 403)
                            cerrarSesion();
                    });
                this.forceUpdate();
            }); 
        }
        this.forceUpdate();
    }

    getAudio = url => {
        this.props.modal.node.extras.audio = url
    }


    /* 
     *  updateOperator
     *  actualiza título y/o visible (si activa el operador dinámico)
     * @titleOperator = string
     * @visible _ int
    */
    updateOperator = (titleOperator='',visible='') => {
        let id = this.props.modal.node.extras.operatorDynamic;
        let data ={
            title: titleOperator,
            visible:visible
        }
        getHandlerPOST(HOST_API +'/api/operator/dynamic_operator/'+id,data)
            .then((res)=>{
                if(res.status!=200)
                    console.log('error título actualización')
            }).catch((error)=>{
                console.log('catch updateOperator',error);
                if (error.response && error.response.status && error.response.status == 403)
                    cerrarSesion();
            });
    }

    isTimeoutValid = valid => 
        this.setState({timeout_valid: valid}, ()=>{
            this.forceUpdate();
        })
    
    /* 
    *  addNewTextBlock
    *  Creación de statement en el operador e invoca addStatementDynamic
    */
    addNewTextBlock = (e) => {
        const node = this.props.modal.node;
        const blocks = node.extras.text_blocks;
        const kports = Object.keys(blocks);
        const k = kports.length ? kports.length : 0;
        blocks[k] = {text:'',name:'',statement_id:''};
        this.props.addTextBlock(blocks);
                    
                    /*
        let node = this.props.modal.node;
        const data = {
            operator_id: node.extras.dbId,
            text: "",
            name: ""
        };
        getHandlerPOST(HOST_API +'/api/scheme/statement/',data)
            .then((res) => {
                if (res.status== 200){
                        let dataOp = {
                            'title':'',
                            'operator_id':this.props.modal.node.extras.operatorDynamic,
                            'description':'',
                            'image':''}
                        this.addStatementDynamic(dataOp, res.data.statement.id)
                }            
            }).catch((error)=>{
                console.log('catch addNewTextBlock',error);
                if (error.response && error.response.status==403)
                    cerrarSesion();
            });*/
    }
    /* 
    *  addStatementDynamic
    *  Creación de statement en el operador dinámico
    *   @data {'title':'','operator_id':this.props.modal.node.extras.operatorDynamic,'description':'','image':''}
    *  @idStatement Id del statement creado en el operador
    */
    addStatementDynamic = (data, idStatement) => {
        let node = this.props.modal.node;
        getHandlerPOST(HOST_API +'/api/operator/dynamic_item/',data)
            .then((res)=>{
                if(res.status==200){
                    this.setState({error_limit_item:false});
                    let blocks = node.extras.text_blocks;
                    blocks[idStatement] = {text:'',name:'',dynamic:res.data.id_item};
                    this.props.addTextBlock(blocks);
                    this.forceUpdate();
                    this.props.updateSchema();
                    return true;
                }else
                    console.log('res.status',res);
                
            }).catch((error) => { 
                console.log('catch addStatementDynamic',error)
                if (error.response.status == 403)
                    cerrarSesion();
            }); 
    }

    /* 
    *  updateStatementDynamic
    *  Actualización de statement en el operador dinámico
    *  @idItem id del item dinamico
    *  @title string
    */
    updateStatementDynamic = (idItem,title) => {
        if(this.props.modal.node.extras.operatorDynamic)
        {
            let data ={
                'title':title,
                'operator_id':this.props.modal.node.extras.operatorDynamic,
                'description':'',
                'image':''
            }
            getHandlerPOST(HOST_API +'/api/operator/dynamic_item/'+idItem,data)
                .then((res)=>{
                    
                }).catch((error) => {
                    console.log('catch updateStatementDynamic',error)
                    if (error.response.status == 403)
                        cerrarSesion();
                });
        }
    }

    updateItem = (k,text) => {  
        this.props.modal.node.extras.text_blocks[k].text = text;
        this.forceUpdate();
    }

    isKeywordValid = (val) => {        
        this.setState({keyword_valid:val}, ()=>{
            this.forceUpdate();
        })
    }

    //Emoji
    callEmoji = (code,k,type)=>{
        let text = this.props.modal.node.extras.text_blocks[k].text;
            text += ` ${code}`;
        this.updateItem(k,text);
        
        this.props.addTextBlock(this.props.modal.node.extras.text_blocks);

    }

    /* 
    *  toggleModuleModal
    *  Save all operator information 
    */
    toggleModuleModal = async () => {


        //Carga el formulario para extraer los datos
        let form = new FormData(document.getElementById('BM-form'));

        let node = this.props.modal.node; 

        //Pone icono de carga
        this.props.iconLoad();

     
        this.setState({error_limit_item:false})   

         //Estrae y actualiza el título del operador
         node.extras.titleOperator = form.get('titleOperator');
         //OJO SE DEBE CAMBIAR AL COMPONENTE title-operator.js cuando todas las cajas usen redux
         await this.props.updateBoxOperator('title', node.extras.titleOperator);//this.getTitleOperator(node.extras.titleOperator);   
 
        
        
        const ports = node.getPorts();
        const kports = Object.keys(ports);
        const kport = kports.find(k => {
            const port = ports[k]
            return port.name == "right-timeout"
        })
        
        if(!kport){
            if(node.extras.timeout > 0)
                node.addPort(new SMPortModel(true, "right-timeout", 'blockchain'));           
        }else{
            if(node.extras.timeout <= 0){
                kports.forEach((kport) => {
                    const port = ports[kport];                     
                    if(port.name == "right-timeout"){
                        const links = port.getLinks();
                        const klinks = Object.keys(links);
                        klinks.forEach(klink => {
                            const link = links[klink];
                            link.remove();
                        });
                        node.removePort(port);
                    }                    
                })
            } 
        }
       
        
     
        let blocks = node.extras.text_blocks;
        const kblocks = Object.keys(blocks);

        if( kblocks.length==0)
            node.extras.dinamicVariables={}

         kblocks.forEach((k) => {    
            let name = $('#TMM-input-value-BL-'+k).val(); 
            let text = $('#input-head_'+k).val()
            

            blocks[k].text = text;
            blocks[k].name = name;


            if(!blocks[k].statement_id || blocks[k].statement_id==''){
                this.props.createNewStatement(node.extras.dbId,k,node,'var_dinamicas');
                
            }else { 
                // Edita el statement cuando ya existe en la base de datos
                this.updateStatement(blocks[k].statement_id, blocks[k].text,  blocks[k].name, blocks[k].var_name!=''?true:false,blocks[k].var_name,'var_dinamicas')
            }
        });
       
        if(!this.state.timeout_valid)
            return false

        const {configBoxOperator} = this.props.boxReducer;
        
        node.extras.config = configBoxOperator;

        if(!configBoxOperator.contract){
            node.extras.config.contract = '' 
            node.extras.config.provider = '' 
        }else
        {
            node.extras.config.contract = configBoxOperator.contract;
            node.extras.config.provider = configBoxOperator.provider;
        }
            
        
         //Actualiza el operador
         await this.props.updateOperator(node.extras.titleOperator,node.extras.keyword,node.extras.config);
 
         //Actualiza el esquema
         await this.props.updateSchema();
 
         //Limpia selecciones
         await this.props.cleanSelection();
 
         //Cierra el modal
         await this.props.toggleModalViewBoxBlockchain();
 

        
    }


     //Actualizar información de statement
     updateStatement = (statement, text, name, is_var, var_name,node,type) => {
        const data = {
            id: statement,
            text: text,
            name: name,
            is_var:is_var,
            var_name:var_name
        };

        this.props.updateStatement(data,node,type);
    
    }

    //Eliminar un Statment
    removeStatementbyId = (statement) => {
        this.setState({error_limit_item:false});
        const data = {
            id: statement,
        };

        this.props.removeStatement(data);
        this.props.updateSchema();

    }

    //Eliminar todos los statements
    removeAllStatements = () => {
        let blocks = this.props.modal.node.extras.statements_id;
        let kblocks = Object.keys(blocks);
        console.log("Statements a eliminar: ",kblocks)
        kblocks.map((variable) => {
            this.removeStatementbyId(variable);
            delete blocks[variable];

        });
        
    }

    saveConfig = (config) => {
        const data = {
            id: this.props.modal.node.extras.dbId,
            config:config
        };

        var auth=JSON.parse(localStorage.getItem('Authorization'));
        var headers={
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer '+ auth.access_token 
        }

        return axios({
            method:'put',
            url:HOST_API +'/api/scheme/operator/',
            data:qs.stringify(data),
            headers:headers
        })

    }

    /* 
    * removeTextBlock
    * Elimina statement creado e invoca removeTextBlockDynamic
    * @index : id del statement
    */
    removeTextBlock = index => {
        this.props.removeTextBlock(index);
        // let blocks = this.props.modal.node.extras.text_blocks;
        // if(this.state.error_limit_item == true)
        //     this.setState({error_limit_item:false})
        // delete blocks[index]; 
        // const data = {
        //     id: index,
        // }
        // getHandlerDELETE(HOST_API +'/api/scheme/statement/',data)
        //     .then(res => {
        //         this.removeTextBlockDynamic(dynamic_statement);
        //         this.props.updateSchema(); 
        //     }).catch((error) => {
        //         console.log('catch removeTextBlock',error)
        //         if (error.response.status == 403)
        //             cerrarSesion();
        //     });
            this.forceUpdate();
    }

    removeTextBlockDynamic = id => {
        getHandlerPOST(HOST_API +'/api/operator/dynamic_item/'+id+'/delete')
            .then((res)=>{
                if(res.status==200){
                    console.log('eliminado dinamico')
                }else{
                console.log(" No se pudo obtener la lista");
                }
            }).catch((error) => {
                console.log('catch removeTextBlockDynamic',error)
                if (error.response.status == 403)
                    cerrarSesion();
            });
    }

    closeModal = () => this.props.toggleModuleModal('blockchain');

    toggleModalUrl = (k) => {
        this.setState({ 
            isOpenUrl: !this.state.isOpenUrl,
            inputStatement:k
        }); 
    }

    getUrl = (k,url) => {
        this.setState({ isOpenUrl: !this.state.isOpenUrl}); 
        let text = this.props.modal.node.extras.text_blocks[k].text;
            text += ` ${url}`;
        this.updateItem(k,text)
    }


showContent=()=>{
    const {  isLogin } = getRolUser();
    if(isLogin){
      return (

        <BMLayout 
        toggleModuleModal={this.toggleModuleModal}
        addNewTextBlock={this.addNewTextBlock}
        removeTextBlock={this.removeTextBlock}
        isOpen={this.props.modal.isOpen} 
        text_blocks={this.props.modal.node.extras.text_blocks} 
        nodeId={this.state.nodeId} 
        keyword={this.props.modal.node.extras.keyword} 
        title={this.state.title} 
        varList={this.props.varList}
        updateItem={this.updateItem}
        botversion_id={this.props.botversion_id}
        operator_id={this.props.modal.node.extras.dbId}
        ia={this.props.modal.node.extras.ia_id || 'none'}
        visible = {this.props.modal.node.extras.visible || ''}
        operator = {this.props.modal.node.extras.operatorDynamic || ''}
        titleOperator = {this.props.modal.node.extras.titleOperator || ''}
        isKeywordValid={this.isKeywordValid}
        error_limit_item={this.state.error_limit_item}
        getTimeout={this.getTimeout}
        getMsgTimeout={this.getMsgTimeout}
        isTimeoutValid={this.isTimeoutValid}
        timeoutValue={this.props.modal.node.extras.timeout}
        timeoutMsg={this.props.modal.node.extras.msg_timeout}
        avatarid={this.props.modal.node.extras.avatar || 0}
        getAvatarId={this.getAvatarId}
        company_id={this.props.company_id}
        botId ={this.props.botId}
        getOperatorVisible = {this.getOperatorVisible}
        closeModal ={this.closeModal}
        callEmoji = {this.callEmoji}
        getAudio = {this.getAudio}
        audio = {this.props.modal.node.extras.audio || ''}

     
        isOpenUrl = {this.state.isOpenUrl}
        toggleModalUrl = {this.toggleModalUrl}
        getUrl = {this.getUrl}
        inputStatement={this.state.inputStatement}

        />
      );
    }
    return <ModalLoading />
  }

render() {
    const {selectedBoxOperator } = this.props.boxReducer;
        //Si la caja seleccionada es tipo blockchain
      if(selectedBoxOperator&&selectedBoxOperator.operatortype_id==22)
      {
        return this.showContent();
      }
      else
        return <div></div>
}
}

const mapStateToProps = ({ boxReducer,homeReducer, componentMountReducer }) => {
return { boxReducer,homeReducer, componentMountReducer };
};

const mapDispatchToProps = {
isDidMount,
willUnMount,
iconLoad,
iconHideLoad,
getListProviderBlockchain,
getListContractBlockchain,
toggleModalViewBoxBlockchain,
addTextBlock,
updateOperator,
cleanSelection,
updateBoxOperator,
createNewStatement,
updateStatement,
removeTextBlock

};

export default connect(mapStateToProps, mapDispatchToProps)(BModal);