import { AbstractNodeFactory } from "storm-react-diagrams";
import React from "react";

import SQRNodeWidget from "./SQRNodeWidget";
import SQRNodeModel from "./SQRNodeModel";

class SQRNodeFactory extends AbstractNodeFactory {
	constructor() {
		super("scanqr");
	}

	generateReactWidget(diagramEngine, node) {
		return <SQRNodeWidget node={node} />
	}

	getNewInstance() {
		return new SQRNodeModel();
	}
}

export default SQRNodeFactory