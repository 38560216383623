import React, { Component } from 'react';
import { 
    Button, 
    Modal, 
    ModalHeader, 
    ModalBody, 
    ModalFooter,
    Form, 
    FormGroup, 
    Label, 
    Input, 
    Col,
    Row,
} from 'reactstrap';
import '../../../pages/admin/components/modal-nuevo-bot.css'
import { getHandlerGET } from '../../../utils/handlersApi'
import i18next from '../../../../i18n';


class ModalShortUrl extends Component {
    state = {
        input:this.props.input,
        preview:''
    }

    constructor(props){
        super(props);
    }

    shortUrl = (e) => {
        e.preventDefault();
        let form = new FormData(e.target);
        let url = encodeURI(form.get('url'))
        getHandlerGET(HOST_API +'/api/shortener/?url='+url)
            .then((res)=>{
              if(res.status==200){
                this.setState({
                    preview: res.data.shortenedUrl
                },()=> this.bindValue()) 
              }
            }).catch((error) => {
              console.log('Catch GET shortUrl:',error);
              if (error.response.status == 403)
                cerrarSesion();
            });  
    }

    bindValue = () => {
        if(typeof this.props.bindValue === "function")
             this.props.bindValue(this.props.input,this.state.preview);
    }

    render() {
        return (
            <div>
                <Modal isOpen={this.props.isOpen} toggle={this.props.toggleModal} className="modal-lg" fade>
                    <ModalHeader toggle={this.props.toggleModal} charCode="X"> {i18next.t('shorten_url')} </ModalHeader>
                    <Form id="form-shortUrl"  role="form" onSubmit={this.shortUrl}>
                    <ModalBody>
                            <FormGroup>
                                <Row>
                                    <Col sm="2">
                                        <Label for="title" className="control-label required-field">URL:</Label>
                                    </Col>
                                    <Col sm="10">
                                        <Input type="text" className="inputModal" id="url" name="url" required></Input>
                                    </Col>
                                </Row>
                            </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={this.props.toggleModal} outline color="secondary">{i18next.t('cancel')}</Button>
                        <Button type="submit" size="lg" className="btn-guarumo-modal">{i18next.t('generate')}</Button>
                    </ModalFooter>
                    </Form>
                </Modal>
            </div>
        );
    }
}
  
export default ModalShortUrl