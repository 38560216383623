import { COMPONENT_MOUNT_ACTIONS_TYPES } from '../types/componentMountActionsTypes';


const INITIAL_STATE = {
    components: [
        {
            name:'',
        },
    ]    
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
        case COMPONENT_MOUNT_ACTIONS_TYPES.isMounted():
            return {
                ...state,
                components: action.payload,
            };
		default: return state;
	};
};