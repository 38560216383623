import { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import { getAuthorization } from '../../../utils/commonFunction';
import i18next from '../../../../i18n';

class HandleError extends Component {

  constructor(props){
    super(props)
    this.isLoginUser();
  }

  state = {
    handleError: false,
    error:'',
    info:''
  }
  
  isLoginUser(){
    const { history,loginReducer: { isLogin= false} } = this.props; 
    if(!isLogin && !getAuthorization()){
      if(history){
        history.push('/login');
      }
    }
  }

  componentDidCatch(error, info) {
    this.setState({
      handleError: true,
      msg:error,
      info:info
    })
    // envía este error a un servicio como Sentry
  }
  render() {
    if (this.state.handleError) {
    }
    return this.props.children
  }
}

const mapStateToProps = ({loginReducer,}) => {
	return {loginReducer,};
};

export default connect(mapStateToProps, null)(withRouter(HandleError));

