//Dependencies
import React from 'react';
import ReactDOM from 'react-dom'
import AppRoutes from './routes';

import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';
import rootReducers  from "../redux/rootReducers";
import { composeWithDevTools } from 'redux-devtools-extension';

import '../../static/css/bootstrap/bootstrap.min.css';
import '../utils/index.css';

// import i18n (needs to be bundled ;)) 
import '../../i18n';

const middleware = [
    reduxThunk, 
  ];

const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25});

const store = createStore(
	rootReducers, // Reducers
	{}, // Estado inicial
    composeEnhancers
        (
        applyMiddleware(...middleware )
    )
);

const homeContainer = document.getElementById('home-container')
ReactDOM.render(
    <Provider store={store}>
        <AppRoutes />
    </Provider>,

    homeContainer
);

