 import {getAuthorization} from './commonFunction';
 import axios from 'axios';
 import qs from 'qs';
 
  export function fileHandlerPOST(url, data,access_token){
    var headers = {
      "Content-Type": "multipart/form-data",
      "Authorization": `Bearer ${(access_token)?access_token:getAuthorization().access_token}` 
    };

    return axios({
      method: "POST",
      url: url,
      data: data,
      headers: headers
    });
  }

  export function fileHandlerImagenPOST(url, data,access_token){

    const formData = new FormData();

    formData.append('name', data.name);
    formData.append('logo', data.logo);
    formData.append('icon', data.icon);
    formData.append('navColor', data.navColor);
    formData.append('sidebarColor', data.sidebarColor);

        var headers = {
          "Content-Type": "multipart/form-data; boundary=---011000010111000001101001",
          "Authorization": `Bearer ${(access_token)?access_token:getAuthorization().access_token}` 
        };

        return axios({
          method: "POST",
          url: url,
          data: formData,
          headers: headers
        });
    }


    export function fileHandlerPOST_Token(url, data,access_token){
      let headers = {
        'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
        'Authorization': 'Token ' + localStorage.getItem("token_apis"),//APIS_BACKEND_TOKEN,
        }

    return axios({
      method: "POST",
      url: url,
      data: data,
      headers: headers
    });
  }

  export function getHandlerGET_TOKEN(url,access_token,data) {   
    let headers = {
      'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
      'Authorization': 'Token ' + localStorage.getItem("token_apis"),//APIS_BACKEND_TOKEN,
      }

    return axios({
      method:'get',
      url: url,
      headers:headers,
      data:data?qs.stringify(data):''
    });
  }

export function getHandlerGET(url,access_token,data) {   
    let headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      "Authorization": `Bearer ${(access_token)?access_token:getAuthorization().access_token}` 
    };

    return axios({
      method:'get',
      url: url,
      headers:headers,
      data:data?qs.stringify(data):''
    });
  }

export function getHandlerPOST_AUTORIZATION(url, data) {
  let headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'Authorization': 'Basic NThiOEk3MnNpRExGR1d5M2NibE9OZ2dXYVhObHIzanR5TDI2QkxaSDp6SmdwRDBRQ1gxVzNMbHk5UVFXZnRXcGp6ek1VTHMzMGRWVGdtMzVZa3JqVWJ6UVowMzN6ZXc3Z1FNdWJ6ZGJDU1dQQVlhZ0J5VVZ6WExmWUROeXFiaEVPYjVZUTM3bHFtd0J4OVE3NmJBRTRVcXhXYWswRkNUTjN4Nm00Z1pLZg=='
  }

  return axios({
    method: "post",
    url: url,
    headers: headers,
    data: qs.stringify(data)
  });
}

export function getHandlerPOST_TOKEN(url, data) {
  data.forEach((value, key) => {
});
  let headers = {
    'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
    'Authorization': 'Token ' + localStorage.getItem("token_apis"),//APIS_BACKEND_TOKEN,
    }

  return axios({
    method: "post",
    url: url,
    headers: headers,
    data: qs.stringify(data)
  });
}

export function getHandlerPOST(url, data, access_token) {  
  
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    "Authorization": `Bearer ${(access_token)?access_token:getAuthorization().access_token}` 
  }    

  return axios({
    method: "post",
    url: url,
    headers: headers,
    data: qs.stringify(data)
  });
}

export function getHandlerPOSTGenerarToken(url, data, access_token) {  
 
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
  }    

  return axios({
    method: "post",
    url: url,
    headers: headers,
    data: qs.stringify(data)
  });
}

export function getHandlerPUT(url, data,access_token){
        var headers={
          'Content-Type': 'application/x-www-form-urlencoded',
          "Authorization": `Bearer ${(access_token)?access_token:getAuthorization().access_token}` 
        }
     
        return axios({
          method:'put',
          url:url,
          data: qs.stringify(data),
          headers:headers
        })
     }

export function getHandlerDELETE(url, data,access_token){
        var headers={
          'Content-Type': 'application/x-www-form-urlencoded',
          "Authorization": `Bearer ${(access_token)?access_token:getAuthorization().access_token}` 
        }
 
        return axios({
          method:'delete',
          url:url,
          data: qs.stringify(data),
          headers:headers
        })
     }

    export function getHandlerPOSTJson(url, data,access_token) {
    var headers = {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${(access_token)?access_token:getAuthorization().access_token}` 
    };
  
    return axios({
      method: "post",
      url: url,
      headers: headers,
      data: data
    });
  }

  export function getHandlerPUTJson(url, data,access_token){
    var headers={
      'Content-Type': "application/json",
      "Authorization": `Bearer ${(access_token)?access_token:getAuthorization().access_token}` 
    }
  
    return axios({
      method:'put',
      url:url,
      data: data,
      headers:headers
    })
  }

  export function getHandlerTokenGET(url, params='') {   
    
    let headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      "Authorization": `token `+ localStorage.getItem("token_apis")
    };
    
    if(params != ''){
      return axios({
          method:'get',
          url: url,
          headers:headers,
          params:JSON.parse(params)
        });
    }else{
        return axios({
          method:'get',
          url: url,
          headers:headers,
        });
    }

}