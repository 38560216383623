import React from "react";
import RGL, { WidthProvider } from "react-grid-layout";

import { connect } from "react-redux";
import { isAuthorizationValid,getRolUser,isEmpty } from "../../../../utils/commonFunction";


import { APPLICANT } from "../../../../redux/listComponents";
import { isDidMount, willUnMount } from "../../../../redux/actions/componentMountActions";

import i18next from '../../../../../i18n';

import { 
    FormGroup,
    Input 
  
  } from 'reactstrap';

  import FilterBar from '../chart-react-d3/convertFilterBar'
// import FilterBar from '../../../../components/infoViz/chart-react-d3/d3Components/FilterBar'

import Date from '../../../../components/datepicker-dashboard';
import moment from "moment";
import { getDashboard, cleanAllPreviews,changeGranularityDashboard} from "../../../../redux/actions/analyticsActions";


const ReactGridLayout = WidthProvider(RGL);

class NoDraggingLayout extends React.PureComponent {
    static defaultProps = {
      className: "layout",
      isDraggable: false,
      isResizable: false,
      items: 12,
      cols: 12,
      rowHeight: 30,
      onLayoutChange: function() {}
    };
  
    constructor(props) {
      super(props);
  
      const layout = [
          {i: 'aa', x: 0, y: 0, w: 4, h: 2},
          {i: 'a-a', x: 4, y: 0, w: 2, h: 2},
          {i: 'aaa', x: 6, y: 0, w: 6, h: 2},
        ];
       
  
      this.state = { layout,
                     group:"All",
                     color:"rgba(195,34,168,1)",
                     typeScaleColor:"unicolor",
                     botID:'All',
                     dateini:"2018",
                     datefin:"2019",
                    // startDate: moment(),
                    // endDate: moment(),
          };
        //  this.handleChangeStart = this.handleChangeStart.bind(this);
        //  this.handleChangeEnd = this.handleChangeEnd.bind(this);   
          this.getdata = this.getdata.bind(this);
    }

    componentWillMount(){
      isAuthorizationValid()
      const { isAdmin = false, isSuper = false } = getRolUser();
      this.setState({
          isAdmin: isAdmin,
          isSuperUser: isSuper
      });
    
    }

    async componentDidMount(){
      
      this.props.isDidMount(APPLICANT.HOME());  


    }
  
    componentWillUnmount() {
      this.props.willUnMount(APPLICANT.HOME());
    }

     /*
    * Función para cambiar las fechas del filtro
    * d es la fecha en moment.js
    */
   handleChangeStart(d){
    this.setState({startDate: d});
    }
    handleChangeEnd(d){
        this.setState({endDate: d});
    }

    getdata(e)
    {  
      const {selectedDashboard} = this.props.analyticsReducer;

      if(e)
        this.props.changeGranularityDashboard(e.target.value);

         this.props.getDashboard("",selectedDashboard.id);
         this.props.cleanAllPreviews();

    }

    onLayoutChange(layout) {
      this.props.onLayoutChange(layout);
    }
  
    callBackPieChart=(group,color)=>{
      this.setState({group:group,color:color,botID:'All'})
    }
  
    callBackLineChart=(group,botID/*,dateini,datefin*/)=>{
       this.setState({botID:botID/*,dateini:dateini,datefin:datefin*/})
     }
     
     
     showContent=()=>{
      const {  isLogin } = getRolUser();

      if(isLogin){
        return (
            <ReactGridLayout
            layout={this.state.layout}
            onLayoutChange={this.onLayoutChange}
            {...this.props}
          >
          
             <div key="aa" id="content-filterBar">
                {/*  <FilterBar nameDiv="filterBar" group={this.state.group} botID={this.state.botID} dateini={this.state.dateini} datefin={this.state.datefin} color={this.state.color} typeScaleColor={this.state.typeScaleColor} />      
                */}</div> 
              <div key="a-a" id="content-filterBar">
                <FormGroup className="dp">
                {i18next.t('group_for')}
                <Input type="select" name="select" id="agrupacionSelect" defaultValue="hour" onChange={(e)=>this.getdata(e)}>
                    <option key={"year"} value={"year"}>{i18next.t('year')}</option>
                    <option key={"month"} value={"month"}>{i18next.t('month')}</option>
                    <option key={"week"} value={"week"}>{i18next.t('week')}</option>
                    <option key={"day"} value={"day"}>{i18next.t('day')}</option>
                    <option key={"hour"} value={"hour"}>{i18next.t('hour')}</option>
                    {/* <option key={"all"}  value={"all"}>Todos los datos</option> */}
                    <option key={"null"}  value={"null"}>{i18next.t('consolidate')}</option>
                </Input>
                </FormGroup>
                  </div> 
             <div key="aaa" id="content-datepicker" >
                    <Date getdata={this.getdata}/> 
              </div>
          </ReactGridLayout>
        );
      }

      
    }

    render() {
      return this.showContent();
  }
}

const mapStateToProps = ({ analyticsReducer, componentMountReducer }) => {
return { analyticsReducer, componentMountReducer };
};

const mapDispatchToProps = {

isDidMount,
willUnMount,
cleanAllPreviews,
getDashboard,
changeGranularityDashboard

};

export default connect(mapStateToProps, mapDispatchToProps)(NoDraggingLayout);



    
      