import React, { Component } from 'react';
import { connect } from "react-redux";
  
import ModalLoading from '../../../components/modals/Loading/moda-loading';

import AdminChartLayout from './bot_admin_chart_layout';
import { isAuthorizationValid,getRolUser,isEmpty } from "../../../utils/commonFunction";
  
import { APPLICANT } from "../../../redux/listComponents";
import { isDidMount, willUnMount } from "../../../redux/actions/componentMountActions";
import {  
  getTockenCubejs,
  getAllCharts,
  getAllListCompanies, 
  getAllTypeChart, 
  getAllPalettes, 
  getAllMetrics, 
  countDimensions, 
  countMeasures, 
  countTimeDimensions, 
  getListSelectedTypeCharts,
  iconLoad,
  iconHideLoad } from "../../../redux/actions/analyticsActions";


class AdminChart extends Component {
    constructor(props){
      super(props);
      this.state = {
        isAdmin: false,
        isSuperUser: false,
        hideLoad: false,
        user:'',
        companies : [],    
        buscador: "",
     
    }
    }
    componentWillMount(){
        isAuthorizationValid()
        const { isAdmin = false, isSuper = false } = getRolUser();
        this.setState({
            isAdmin: isAdmin,
            isSuperUser: isSuper
        });
        
      }

      async componentDidMount(){
           
          const { analyticsReducer : { activePage, listAllDashboards,listAllCharts,typeCharts,AllPalettes, listAllMetrics, numDimensions, numMeasures,previews=[], selectedBot,selectedMetric } } = this.props;
          const { homeReducer : {  listAllCompanies} } = this.props;


          this.props.isDidMount(APPLICANT.HOME());    
      
        //----------------------------------------------------------------
        //  PONE EL ICONO DE CARGA
        //----------------------------------------------------------------
          await this.props.iconLoad();

        //----------------------------------------------------------------
        //  ACTUALIZA EL TOCKEN DE CUBEJS
        //----------------------------------------------------------------
          await this.props.getTockenCubejs();
          
          
        //----------------------------------------------------------------
        //  LISTA TODAS LAS COMPAÑÍAS, COJE LA PRIMERA COMO SELECTCOMPANY
        //----------------------------------------------------------------
          if(isEmpty(listAllCompanies)){
            await this.props.getAllListCompanies();     
          }
        
          //----------------------------------------------------
          //  LISTA LAS GRÁFICAS DE LA COMPAÑIA
          //----------------------------------------------------
          if(isEmpty(listAllCharts)){
            await this.props.getAllCharts();     
         }
         else
         {
            this.forceUpdate();
         }

          //----------------------------------------------------
          //  OCULTA EL ICONO DE CARGA
          //----------------------------------------------------
         await  this.props.iconHideLoad(); 

        } 


        shouldComponentUpdate(nextProps, nextState) {
          //----------------------------------------------------
          //  SOLO EJECUTA EL UPDATE SI LA COMPAÑIA FUE CAMBIADA
          //----------------------------------------------------
          const nextCompany = nextProps.analyticsReducer.selectedCompany;
          const company = this.props.analyticsReducer.selectedCompany;

          return nextCompany !== company;
      }

        async componentWillUpdate(nextProps,nextState)
        {

          //-------------------------------------------------------------------
          //  LISTA LAS GRÁFICAS DE LA COMPAÑIA SI SE SELECCIONÓ OTRA COMPAÑÍA
          //-------------------------------------------------------------------
         
           const nextCompany = nextProps.analyticsReducer.selectedCompany;
          const company = this.props.analyticsReducer.selectedCompany;

          if(nextCompany!==company){
          //------------------------------------------------------------------
          //  LISTA LAS GRÁFICAS DE LA COMPAÑIA
          //-------------------------------------------------------------------
            await  this.props.getAllCharts(); 
          //----------------------------------------------------
          //  OCULTA EL ICONO DE CARGA
          //----------------------------------------------------
            await  this.props.iconHideLoad(); 
          }
  
        }
    
    
        componentWillUnmount() {
          this.props.willUnMount(APPLICANT.HOME());
        }


showContent=()=>{
    const {  isLogin } = getRolUser();
    if(isLogin){
      return (
        <React.Fragment>
        <AdminChartLayout 
        isAdmin={this.state.isAdmin}
        isSuperUser={this.state.isSuperUser}
        handleFind={this.handleFind}
    />
  
    </React.Fragment>
     );
    }
    return <ModalLoading />
  }
     
    render() {
        return this.showContent();
    }
}


const mapStateToProps = ({ analyticsReducer, homeReducer,componentMountReducer }) => {
	return { analyticsReducer,homeReducer, componentMountReducer };
};

const mapDispatchToProps = {
  getAllCharts,
  getAllMetrics,
  getAllTypeChart,
  getAllPalettes,
  countDimensions,
  countMeasures,
  countTimeDimensions,
  getAllListCompanies,
  getListSelectedTypeCharts,
  isDidMount,
  willUnMount,
  getTockenCubejs,
  iconLoad,
  iconHideLoad
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminChart);

