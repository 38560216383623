import { AbstractNodeFactory } from "storm-react-diagrams";
import React from "react";

import LCNodeWidget from "./LCNodeWidget";
import LCNodeModel from "./LCNodeModel";

class LCNodeFactory extends AbstractNodeFactory {
	constructor() {
		super("location");
	}

	generateReactWidget(diagramEngine, node) {
		return <LCNodeWidget node={node} />
	}

	getNewInstance() {
		return new LCNodeModel();
	}
}

export default LCNodeFactory