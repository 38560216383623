import { AbstractNodeFactory } from "storm-react-diagrams";
import React from "react";

import HANodeWidget from "./HANodeWidget";
import HANodeModel from "./HANodeModel";

class HANodeFactory extends AbstractNodeFactory {
	constructor() {
		super("ha");
	}

	generateReactWidget(diagramEngine, node) {
		return <HANodeWidget node={node} />
	}

	getNewInstance() {
		return new HANodeModel();
	}
}

export default HANodeFactory