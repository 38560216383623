import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
 
import { Redirect } from 'react-router-dom'

//import Select from '../../components/infoViz/selected_chart'

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  Row,
  CustomInput,
  UncontrolledTooltip
} from "reactstrap";
//import "./toolbar.css";
import "../../../../static/css/modal-nuevo.css";
import "../../../../static/css/new-dashboard.css";

//import DashboardGrid from '../../containers/analytics/dashboard_grid'

import DashboardGrid from '../grid/dashboard_create_grid'
//import DashboardViewGrid from '../../containers/analytics/bot_dashboard_grid';
import DashboardViewGrid from '../grid/dashboard_view_grid'


import {Link} from 'react-router-dom'

import {getTockenCubejs, saveNewDashboard,updateInfoDashboard,toggleModalNewDashboard,getPreview } from "../../../redux/actions/analyticsActions";

import i18next from "../../../../i18n";


function renderRedirect(){
  
    return <Redirect to='/adminmetricas' />
  
}

function ModalNuevoDashboard(props) {
 
  const {  updateDashboard, viewDashboard,selectedDashboard} = props.analyticsReducer;
  const { analyticsReducer: { modalNewDashboard, listAllDashboard= [],listAllCharts= [],listSelectedDashboard= [] }, toggleModalNewDashboard, saveNewDashboard,updateInfoDashboard } = props;

  return (
    <div>
      <Modal
        isOpen={modalNewDashboard}
        toggle={toggleModalNewDashboard}
        className="modal-lg"
        fade
      >
        <ModalHeader toggle={toggleModalNewDashboard} charCode="X">
        {updateDashboard?(i18next.t('update_info')):viewDashboard?(i18next.t('info_dashboard')):i18next.t('create_new_dashboard')}
        </ModalHeader>
        {listAllCharts.length>0||viewDashboard?(
            <React.Fragment>
              <Form id="form-createChart" onSubmit={
                  (e)=>{
                    updateDashboard?[
                      
                      updateInfoDashboard(selectedDashboard.id,e,(urlDirect)=>{

                      })
                    ]:
                  saveNewDashboard(e,listSelectedDashboard,(urlDirect)=>{
                    props.history.push(urlDirect);
                  });
                }} role="form">

          <ModalBody>
          <Row>
              <FormGroup>
                        <Label for="dashboardName" className="control-label required-field">
                            {i18next.t('dashboard_title')+': '}
                        </Label>
                        <Input
                            type="text"
                            className="inputModal"
                            id="dashboardName"
                            name="dashboardName"
                            maxLength="130"
                            placeholder={i18next.t('dashboard_title')}
                            disabled={(viewDashboard)?true:false}
                            defaultValue={(selectedDashboard.name)?selectedDashboard.name:''}
                            required
                        />
                    </FormGroup>  
              <FormGroup>
               
                    <Label className="control-label" for="dashboardDescription">
                    {i18next.t('description')+': '}{" "}
                  </Label>
                  <Input
                    type="textarea"
                    rows={4}
                    name="dashboardDescription"
                    id="dashboardDescription"
                    maxLength="100"
                    disabled={(viewDashboard)?true:false}
                    defaultValue={(selectedDashboard.description)?selectedDashboard.description:''}
                    placeholder={i18next.t('description_dashboard')}
                  />              
                  </FormGroup>
              </Row>
              <Row>
                { viewDashboard?(
                   <FormGroup>
                      <DashboardViewGrid 
                       className={"layout"} 
                       rowHeight= {100}
                       charts={(selectedDashboard.charts)?selectedDashboard.charts:[]}
                     />
                      </FormGroup>
                ):(
                      <FormGroup>
                      <Label className="control-label" for="dashboardDescription">
                            {i18next.t('select_graphics')+': '}{" "}
                        </Label>

                          <DashboardGrid 
                          className={"layout"} 
                          rowHeight= {100}
                          charts={(selectedDashboard.charts)?selectedDashboard.charts:[]}
                        />
                          </FormGroup>
                )}
               
                  </Row>
          </ModalBody>
          <ModalFooter>
          <Button onClick={toggleModalNewDashboard} outline color="secondary">
          {viewDashboard?(i18next.t('close')):i18next.t('cancel')}
            </Button>
            {viewDashboard?(""):
            <Button type="submit" size="lg" className="btn-guarumo-modal">
              {updateDashboard?("Actualizar"):"Crear"}
            </Button>
            }
          </ModalFooter>
        </Form>
        </React.Fragment>
          ):
          <React.Fragment>
             <Form id="form-createChart"  role="form">
              <ModalBody>
              <Col xs={12} lg={8} className="offset-lg-2 intro-funnels" style={{"textAlign":"center"}}>
                  <Row>
                      <Col xs={12}><img src="/static/img/blank_state.png" className="img-fluid"/></Col>
                      <Col xs={12}>
                          <h2>{i18next.t('message52')}</h2>
                          <h3>{i18next.t('message53')}</h3>
                      </Col>
                  </Row>
                  </Col>
              </ModalBody>
              <ModalFooter>
              <Button onClick={toggleModalNewDashboard} outline color="secondary">
              {i18next.t('cancel')}
            </Button>
              
                <Link to="/adminchart" className="btn-guarumo-modal">
                    {i18next.t('create_graphic')}
                </Link>
              </ModalFooter>
            </Form>
          </React.Fragment>
        }    
      </Modal>

    </div>
  );
}

const mapStateToProps = ({ analyticsReducer,  }) => {
	return { analyticsReducer,  };
};

const mapDispatchToProps = {
  saveNewDashboard,
  updateInfoDashboard,
  toggleModalNewDashboard,
  getPreview,
  getTockenCubejs
};


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ModalNuevoDashboard));

