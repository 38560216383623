import $ from "jquery";
import React from "react";
import ReactDOM from "react-dom";
import {
  Form,
  Button
} from 'reactstrap';
import axios from 'axios';

import SweetAlert from 'react-bootstrap-sweetalert';

window.jQuery = $;
window.$ = $;

require("jquery-ui-sortable");
import '../../node_modules/formBuilder/dist/form-render.min'
import qs from 'qs';
import i18next from "../../i18n";

class FormRender extends React.Component {

  state = {
    statements: {},
    showAlertOk: false,
    showAlertWarning: false
  }

  getHandler(url){
    var auth=JSON.parse(localStorage.getItem('Authorization'));
    var headers={
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Bearer '+ auth.access_token
    }

    return axios({
      method:'get',
      url: url,
      headers: headers
    })
  }

  constructor(props){
    super(props)
    var self = this;
    let aux = {
        onSave: (e) => { 
          self.props.saveFormBuilder(self.form.actions.getData())
        },
        formData: JSON.stringify(self.props.formData),
        dataType: 'json',
        onFieldAdd: function(fieldId) {
          const currentFieldId = fieldId
        },
    };
    this.options = aux;
  }

  getHandler(url){
    var auth=JSON.parse(localStorage.getItem('Authorization'));
    var headers={
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Bearer '+auth.access_token
    }

    return axios({
      method:'get',
      url: url,
      headers:headers
    })
 }

 getInputs = () => {
    this.setState({ statements: {}});
    this.getHandler('/api/webforms/inputs?form_id=' + this.props.formId).then((res) => {
     if(res.status == 200){
      const inputs = res.data.inputs;
      if(inputs.length > 0){
        let inps = {};
        for(let input of inputs){
          inps[input.name] = input.statement_id;
        }
        this.setState({ statements: inps});
      }
     }
   })
 }

  componentDidMount() {
    this.rendered = $(this.fb);
    this.rendered.formRender(this.options);
    this.getInputs()
  }

  submitData = (event) => {
    //  consultar todos los statements ids del formulario que se recibe
    //  Obtener los statements id que hagan match con el nombre del input 
    //  Validar que todos los inputs tengan un statement id, sino lo tienen crearlo.
    
    event.preventDefault();
    let form1 = new FormData(event.target);
    let form2 = new FormData();

    for (let pair of form1.entries()) {
        const n = this.state.statements[pair[0]];
        form2.append(n, pair[1])
    }

    axios.post('/api/webforms/save?session_id=' + this.props.botSession, form2, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then((res) => {
      this.setState({showAlertOk: true});
    }).catch((err) => {
      this.setState({showAlertWarning: true});
    })

  }

  hideAlert=(a)=>{
    let state = {}
    state[a] = false
    this.setState(state);
  }

  render() {
    return (
      <div>
        <form id="fb-render" onSubmit={this.submitData} ref={el => (this.fb = el)}></form>
        <Button type="submit" form="fb-render" className="btn btn-success">{i18next.t('send')}</Button>
        <SweetAlert success title={i18next.t('form_msg')} show={this.state.showAlertOk} onConfirm={() => this.hideAlert('showAlertOk')} showConfirm={false} />
        <SweetAlert warning title={i18next.t('form_msg2')} show={this.state.showAlertWarning} onConfirm={() => this.hideAlert('showAlertWarning')} showConfirm={false} />
      </div>
    );
  }
}

export default FormRender;
