var d3 = require('d3');
import  '../../../infoViz/common/virtualscroller.js'

const createDonutChart = (nameDiv,datos,formatoD3,callBackPieChart, colorChosen, typeScaleColor,title,xAxisName,yAxisName,description) => {

    // datos.dataSet=JSON.parse(datos.dataSet);

    var color;
    var arcSelected='none';
    let dataArcSelected = [];
    let totaldatos = [];

    totaldatos[nameDiv] = datos;

    if(typeScaleColor=="unicolor")
          color=d3.scaleLinear().domain([1,10]).range([colorChosen,"black"]);
        else if(typeScaleColor=="Categorica")
        {
           colorChosen="d3."+colorChosen;
           color= d3.scaleOrdinal(eval(colorChosen));
        }
        else if(typeScaleColor=="predeterminado")
        {
            color = d3.scaleOrdinal().domain( totaldatos[nameDiv].dataSet).range(eval(colorChosen));
           
           // color = d3.scaleOrdinal().domain(datos.dataSet).range(["rgba(46,91,255,1)", "rgba(140,84,255,1)", "rgba(0,193,212,1)", "rgba(250,208,80,1)", "rgba(195,34,168,1)"]);       
        }
        else 
        {
           colorChosen="d3."+colorChosen+"(t * 0.85)";
          const scale = t => eval(colorChosen);
          var color = (d) => {
            var c= d3.range(5).map(i => scale(i / 5))
            return c[d];
            };
          //  color = d3.scaleSequential(eval(colorChosen));
        }
   
     const node = document.createElement('div');
    // node.className = "svg-container";
     node.id="chart_"+nameDiv;
   
     
     var div='#'+nameDiv;
     var chart=document.getElementById('content-'+nameDiv);

     const WIDTH = chart.offsetWidth;
     const HEIGHT = chart.offsetHeight - 35 ;
     //const margin = { top: 75, right: 60, bottom: 60, left: 60 };

     const izquierda = document.createElement('div');
  //node.className = "svg-container";
  izquierda.id="izquierda_"+nameDiv;
  izquierda.className = "izquierda";

  izquierda.style.position='absolute'
  izquierda.style.top = '35px';
  izquierda.style.left='10px';
  izquierda.style.width='65%';
  izquierda.style.height='85%';
  izquierda.style.backgroundColor ='white';

  node.appendChild(izquierda);

  const derecha = document.createElement('div');
  //node.className = "svg-container";
  derecha.id="derecha_"+nameDiv;
  derecha.className = "derecha";

  derecha.style.position='absolute'
  derecha.style.top = '35px';
  derecha.style.left='68%';
  derecha.style.width='30%';
  derecha.style.height='80%';
  derecha.style.backgroundColor ='white';

  node.appendChild(derecha);

  const arriba = document.createElement('div');
  //node.className = "svg-container";
  arriba.id="arriba_"+nameDiv;
  arriba.className = "arriba";

  arriba.style.position='absolute'
  arriba.style.top = '0px';
  arriba.style.left='0px';
  arriba.style.width='100%';
  arriba.style.height='30px';
  arriba.style.backgroundColor ='white';
  arriba.style.paddingTop ='5px';
  arriba.style.paddingLeft ='10px';

    // ===========================================================================================
    //    Creates Title
    // ===========================================================================================
  
  node.appendChild(arriba);

  var title= d3.select(arriba)
    .append("text")
    .attr("class", "chartTitle")
    .style("font-size", '1.5rem')
    .style("color", 'gray')
    .style("font-weight", 'bold')
    .text(title);
    
      
     var donut = donutChart()
     .width(WIDTH)
     .height(HEIGHT)
     .cornerRadius(3) // sets how rounded the corners are on each slice
     .padAngle(0.015) // effectively dictates the gap between slices
     .variable('Probability')
     .category('Species')
     .color(color);

     d3.select(izquierda)
    .datum( totaldatos[nameDiv].dataSet) // bind data to the div
    .call(donut);

function donutChart() {
    var width,
        height,
        margin = {top: 40, right: 10, bottom: 10, left: 10},
        colour = d3.scaleOrdinal(d3.schemeCategory10), // colour scheme
        color, // colour scheme
        variable, // value in data that will dictate proportions on chart
        category, // compare data by
        padAngle, // effectively dictates the gap between slices
        floatFormat = d3.format('.4r'),
        cornerRadius, // sets how rounded the corners are on each slice
        radius = Math.min(width, height) / 2,
        percentFormat = d3.format(',.2%');

        
        

    function chart(selection){
        let dataTotal = []
        selection.each(function(data) {

            dataTotal[nameDiv] = data;
            
            //console.log ("name_div: "+nameDiv,"data: ",data)
            // generate chart

            // ===========================================================================================
            // Set up constructors for making donut. See https://github.com/d3/d3-shape/blob/master/README.md
            radius = Math.min(width, height) / 2;

            // creates a new pie generator
            var pie = d3.pie()
                .value(function(d) { return d.measure; })
                //.value(function(d) { return floatFormat(d[variable]); })
                .sort(null);

            // contructs and arc generator. This will be used for the donut. The difference between outer and inner
            // radius will dictate the thickness of the donut
            var arc = d3.arc()
                .outerRadius(radius * 0.8)
                .innerRadius(radius * 0.6)
                .cornerRadius(cornerRadius)
                .padAngle(padAngle);

            // this arc is used for aligning the text labels
            var outerArc = d3.arc()
                .outerRadius(radius * 0.9)
                .innerRadius(radius * 0.9);
            // ===========================================================================================

            // ===========================================================================================
            // append the svg object to the selection
            var svg = selection.append('svg')
                .attr("id","svg_"+nameDiv)
                .attr("name_div", function(d){ return ""+nameDiv+"" })
                .attr("preserveAspectRatio", "xMinYMin meet")
                .attr(
                    "viewBox",
                    "0 0 "
                      .concat(width - width/3)
                      .concat(" ")
                      .concat(height + margin.top + margin.bottom)
                  )
                .classed("svg-content", true)
                .append('g')
                .attr('transform', 'translate(' + width / 3 + ',' + ((height+margin.top) / 2)  + ')')
                .attr("name_div", function(d){ return ""+nameDiv+"" });
            // ===========================================================================================
        
         //   .attr("transform", "translate(" + (margin.left+(width/10)) + "," + margin.top + ")");
      
            //============================================================================================
           
            // ===========================================================================================
            // g elements to keep elements within svg modular
            svg.append('g').attr('class', 'slices');
            svg.append('g').attr('class', 'labelName');
            svg.append('g').attr('class', 'lines');
            // ===========================================================================================

            // ===========================================================================================
            // add and colour the donut slices
            var path = svg.select('.slices')
                .datum( dataTotal[nameDiv]).selectAll('path')
                .data(pie)
              .enter().append('path')
              .attr('fill', function(d, i) { return color(i); } )
               // .attr('fill', function(d) { return colour(d.data[category]); })

                .attr('d', arc)
                .attr('class', 'slice'+nameDiv)    //allow us to style things in the slices (like text)
                .attr('id',function(d,i){
                    return 'pie_slice_'+nameDiv+"_"+i
                })
                .attr('category',function(d){
                    return d.data.category;
                })
                .attr('measure',function(d){
                    return d.data.measure;
                })
                .attr('selected',function(d){
                    return "false";
                })
                .attr("name_div", function(d){ return ""+nameDiv+"" })
                .on('mouseenter',onMouseEnter)
                .on('mouseout',onMouseOut)
                .on("click", function(d,i){
                    
                     d3.event.preventDefault();
                     d3.event.stopPropagation(); 

                    const atributo = this.getAttribute("category"); 
                    const measure = this.getAttribute("measure"); 
                    const fill = this.getAttribute("fill"); 
                    nameDiv = this.getAttribute("name_div"); 

                    const dataSelected = {"category":atributo,"measure":measure,"fill":fill}

                    unselected(nameDiv);
        
                    if(arcSelected!=atributo)
                    {
                        d3.select(this)
                        .attr("selected","true")
                        .style("stroke", "black")
                        .style("opacity", 1)

                        arcSelected = atributo;
                        dataArcSelected [nameDiv]=dataSelected;
                    }
                    else
                    {
                        arcSelected = 'none';
                        dataArcSelected= [];
                    }
                    
        
                    
                   // callBackPieChart(atributo, color(i));
                 })
                      ;
            // ===========================================================================================

            // ===========================================================================================
            // add text labels
       /*     var label = svg.select('.labelName').selectAll('text')
                .data(pie)
              .enter().append('text')
                .attr('dy', '.35em')
                .html(function(d) {
                    // add "key: value" for given category. Number inside tspan is bolded in stylesheet.
                    return d.data.category + ':' + d.data.measure ;
                })
                .attr('transform', function(d) {

                    // effectively computes the centre of the slice.
                    // see https://github.com/d3/d3-shape/blob/master/README.md#arc_centroid
                    var pos = outerArc.centroid(d);

                    // changes the point to be on left or right depending on where label is.
                    pos[0] = radius * 0.95 * (midAngle(d) < Math.PI ? 1 : -1);
                    return 'translate(' + pos + ')';
                })
                .style('text-anchor', function(d) {
                    // if slice centre is on the left, anchor text to start, otherwise anchor to end
                    return (midAngle(d)) < Math.PI ? 'start' : 'end';
                });


            // ===========================================================================================

            // ===========================================================================================
            // add lines connecting labels to slice. A polyline creates straight lines connecting several points
            var polyline = svg.select('.lines')
                .selectAll('polyline')
                .data(pie)
              .enter().append('polyline')
                .attr('points', function(d) {

                    // see label transform function for explanations of these three lines.
                    var pos = outerArc.centroid(d);
                    pos[0] = radius * 0.95 * (midAngle(d) < Math.PI ? 1 : -1);
                    return [arc.centroid(d), outerArc.centroid(d), pos]
                });
                */
            // ===========================================================================================

            // ===========================================================================================
            var circle= svg.append('circle')
                        .attr('class', 'toolCircle_'+nameDiv)
                        .attr('r', radius * 0.55) // radius of tooltip circle
                        .style('fill',function(d, i) { return color(i); } )// colour based on category mouse is over
                        .style('fill-opacity', 0.35)
                        .style("opacity", 1);

            var tooltipcircle=  svg.append('text')
                        .attr('class', 'toolText_'+nameDiv)
                        .attr('dy', -15) // hard-coded. can adjust this to adjust text vertical alignment in tooltip
                        .html(" ") // add text to the circle.
                        .style('font-size', '.9em')
                        .style('text-anchor', 'middle')
                        .style("opacity", 1);; // centres text in tooltip

            // add tooltip to mouse events on slices and labels
        
            // ===========================================================================================

            // ===========================================================================================
            // add tooltip to mouse events on slices and labels
            // d3.selectAll('.labelName text, .slices path').call(toolTip);
            // ===========================================================================================

            // ===========================================================================================

          //  label.on('mouseenter',onMouseEnter);
          //  label.on('mouseout',onMouseOut);

            //d3.selectAll('.labelName text, .slices path').call(toolTip);
            // ===========================================================================================

            // ===========================================================================================
            // Functions

            // calculates the angle for the middle of a slice
            function midAngle(d) { return d.startAngle + (d.endAngle - d.startAngle) / 2; }

            // function that creates and adds the tool tip to a selected element
            function onMouseEnter(data,div)
            {
                if(this){
                    nameDiv =this.getAttribute("name_div");

                    const category =this.getAttribute("category");

                    const measure =this.getAttribute("measure");

                    const dataSelected = {"category":category,"measure":measure}

                    const fill =this.getAttribute("fill");

                    var c=d3.select('.toolCircle_'+nameDiv).style("opacity",1);
                    var t=d3.select('.toolText_'+nameDiv)
                    .html(toolTipHTML(dataSelected))//(data.data))
                    .style("fill","rgb(0,0,0)")
                    .style('font-size', '1.5em')
                    .style('fill', fill)//color(data.data.index)) 
                    .style('text-anchor', 'middle');
                }
                else
                {
                    var t=d3.select('.toolText_'+div)
                    .html(toolTipHTML(data))//(data.data))
                    .style("fill","rgb(0,0,0)")
                    .style('font-size', '1.5em')
                    .style('fill', data.fill)//color(data.data.index)) 
                    .style('text-anchor', 'middle');

                    //alert(data.category);
                }
                
              
            }
            function onMouseOut(data)
            {
                nameDiv =this.getAttribute("name_div");
               

                if(arcSelected == 'none')
                {
                    var c=d3.select('.toolCircle_'+nameDiv).style("opacity",0);
                    var t=d3.select('.toolText_'+nameDiv)
                    .html("Hola")//(toolTipHTML(data.data))
                    .style("fill","rgba(255,255,255,0)");
                }
                else
                {
                    onMouseEnter(dataArcSelected[nameDiv],nameDiv);
                }
                
                  
            }
            function toolTip(selection) {

                // add tooltip (svg circle element) when mouse enters label or slice
                selection.on('mouseenter', function (data) {

                    svg.append('text')
                        .attr('class', 'toolCircle_'+nameDiv)
                        .attr('dy', -15) // hard-coded. can adjust this to adjust text vertical alignment in tooltip
                        .html(toolTipHTML(data)) // add text to the circle.
                        .style('font-size', '.9em')
                        .style('text-anchor', 'middle'); // centres text in tooltip

                    svg.append('circle')
                        .attr('class', 'toolCircle_'+nameDiv)
                        .attr('r', radius * 0.55) // radius of tooltip circle
                        .style('fill', colour(data.data[category])) // colour based on category mouse is over
                        .style('fill-opacity', 0.35);

                });

                // remove the tooltip when mouse leaves the slice/label
                selection.on('mouseout', function () {
                    d3.selectAll('.toolCircle_'+nameDiv).remove();
                });
            }
            //================================================================
            function unselected(nameDiv)
            {
                      d3.selectAll(".slice"+nameDiv)
                        .attr("selected",function(d){
                          return "false";
                      })
                      .style("stroke", "none")
                      .style("opacity", 0.8)
            }
            // function to create the HTML string for the tool tip. Loops through each key in data object
            // and returns the html string key: value
            function toolTipHTML(data) {

                var tip = '',
                    i   = 0;

                for (var key in data) {

                    // if value is a number, format it as a percentage
                    var value = (!isNaN(parseFloat(data[key]))) ? data[key] : data[key];
                    //percentFormat(data[key]) : data[key];

                    // leave off 'dy' attr for first tspan so the 'dy' attr on text element works. The 'dy' attr on
                    // tspan effectively imitates a line break.
                    // if (i === 0) tip += '<tspan x="0">' + key + ': ' + value + '</tspan>';
                    // else tip += '<tspan x="0" dy="1.2em">' + key + ': ' + value + '</tspan>';
                    if (i === 0) tip += "<tspan font-weight='bold' fill='black'>"+ value + "</tspan>";
                    else tip += "<tspan x='0' dy='1.2em' font-weight='bold' font-size='2em'>" + value + "</tspan>";
                    
                    i++;
                    
                    if(i>1)
                        break;
                }

                return tip;
            }
            // ===========================================================================================
            // Function by zoom
            // ===========================================================================================

            var zoom = d3.zoom().on('zoom',  zoomed);

            svg.call(zoom);

            function zoomed(data,index, group) {
                
                data =  dataTotal[nameDiv];

               // console.log(data,index,group,this)

                if(d3.event){
                var transform = d3.event.transform;

                nameDiv = this.getAttribute("name_div"); 
            
                //transform.x = Math.min(0, transform.x);
                //transform.y = 0;

                var xx= (width / 3)+ transform.x;
                var yy= ((height+margin.top) / 2)+ transform.y;
            
                d3.selectAll("#"+nameDiv).select("svg").select("g")//.selectAll(".slices")
                .attr('transform', 'translate(' + xx + ',' + yy  + ')');
              //  .attr("transform", "translate(" + transform.x+","+ transform.y+ ")");

             //   d3.selectAll(div).select("svg").select("g").selectAll(".slices")
             //   .attr("transform", "scale(" + d3.event.transform.k + ")");
                
            //    d3.selectAll('.line').style("stroke-width", 2/d3.event.transform.k);
            
             //   lineStroke = 2/d3.event.transform.k;
             //   lineStrokeHover = 2/d3.event.transform.k;
            
                var radiusNew = Math.min(width, height) / 2;
                radiusNew = radiusNew * d3.event.transform.k;
               // circleRadiusHover =5/d3.event.transform.k;
            
                var arcNew = d3.arc()
                .outerRadius(radiusNew * 0.8)
                .innerRadius(radiusNew * 0.6)
                .cornerRadius(cornerRadius)
                .padAngle(padAngle);

                var c = d3.selectAll(".slice"+nameDiv)
                .attr('d', arcNew);

                var circle= d3.selectAll(".toolCircle_"+nameDiv)
                .attr('r', radiusNew * 0.55) // radius of tooltip circle
              //  .style('fill',function(d, i) { return color(i); } )// colour based on category mouse is over
              //  .style('fill-opacity', 0.35)
               // .style("opacity", 1);
            
                }
            }
   
        });
    }

    // getter and setter functions. See Mike Bostocks post "Towards Reusable Charts" for a tutorial on how this works.
    chart.width = function(value) {
        if (!arguments.length) return width;
        width = value;
        return chart;
    };

    chart.height = function(value) {
        if (!arguments.length) return height;
        height = value;
        return chart;
    };

    chart.margin = function(value) {
        if (!arguments.length) return margin;
        margin = value;
        return chart;
    };

    chart.radius = function(value) {
        if (!arguments.length) return radius;
        radius = value;
        return chart;
    };

    chart.padAngle = function(value) {
        if (!arguments.length) return padAngle;
        padAngle = value;
        return chart;
    };

    chart.cornerRadius = function(value) {
        if (!arguments.length) return cornerRadius;
        cornerRadius = value;
        return chart;
    };

    chart.color = function(value) {
        if (!arguments.length) return color;
        color = value;
        return chart;
    };

    chart.variable = function(value) {
        if (!arguments.length) return variable;
        variable = value;
        return chart;
    };

    chart.category = function(value) {
        if (!arguments.length) return category;
        category = value;
        return chart;
    };

    return chart;
}

    // ===========================================================================================
    //   LEYENDA CON SCROLL
    // ===========================================================================================

    var height=HEIGHT,width=WIDTH;

    var viewport = d3.select(derecha).append("div")
    .attr("class","viewport2")
    .style("height","100%")
    .style("width","100%")

  //------------------------------------------------------
  //  LEYENDA
  //-----------------------------------------------------


  var colorScale = color;

  var scrollSVG = viewport.append("svg")
      .attr("class", "scroll-svg");
 
  var defs = scrollSVG.insert("defs", ":first-child");
 
  createFilters(defs);
 
  var chartGroup = scrollSVG.append("g")
      .attr("class", "chartGroup")
      //.attr("filter", "url(#dropShadow1)"); // sometimes causes issues in chrome
 
  chartGroup.append("rect")
      .attr("fill", "#FFFFFF");
 
      var rowEnter = function(rowSelection) {
        /*rowSelection.append("rect")
            .attr("rx", 3)
            .attr("ry", 3)
            .attr("width", "250")
            .attr("height", "24")
            .attr("fill-opacity", 0.25)
            .attr("stroke", "#999999")
            .attr("stroke-width", "2px");
        rowSelection.append("text")
            .attr("transform", "translate(10,15)");*/
           
            rowSelection
           .append("g")
           .attr("class", "dots-group")  
           .append("circle")
           // .attr("transform", function(d,i) { return "translate(" + 300  + ", -50)"; }) // Put the text at the position of the last point
           .attr("cx", 25)//function(d,i){ return ((width-2*(i*60)) + i*120)-(i+1)*60})
           .attr("cy", function(d,i){ return 12 })///-25) // 100 is where the first dot appears. 25 is the distance between dots
           .attr("r", 7)
           .attr("transform", "translate(0,10)")
           .style("fill", function (d, i) {
           return color(i);
           })
           .style("stroke", "#fff")
           .on("click", function(d){
          
           var currentFillColor=d3.select(this).style('fill');
           var currentStrokeColor=d3.select(this).style('stroke');
   
           d3.select(this) 
           .style("stroke", currentFillColor)
           .style("fill", currentStrokeColor)
   
           });
   
   rowSelection.append("text")
            .attr("transform", "translate(40,25)");
          
    };
    var rowUpdate = function(rowSelection) {
        rowSelection.select("rect")
            .attr("fill", function(d) {
                return colorScale(d.id);
            });
        rowSelection.select("text")
            .text(function (d) {
                return d.category + "("+d.measure+")";
            });
    };
   
    var rowExit = function(rowSelection) {
    };
   
    var v1=d3.select(".viewport2");
    
    var virtualScroller = d3.VirtualScroller()
        .rowHeight(30)
        .enter(rowEnter)
        .update(rowUpdate)
        .exit(rowExit)
        .svg(scrollSVG)
        .totalRows( totaldatos[nameDiv].dataSet.length)
        .viewport(viewport);//d3.select(".viewport1"));
   
    // tack on index to each data item for easy to read display
    
    totaldatos[nameDiv].dataSet.forEach(function(nextState, i) {
        nextState.index = i;
        nextState.id = i;
    });
   //  states.items.forEach(function(nextState, i) {
   //   nextState.index = i;
   // });
   
   
    virtualScroller.data( totaldatos[nameDiv].dataSet, function(d) { 
      return d.id; 
    });
   
    chartGroup.call(virtualScroller);
   
    function createFilters(svgDefs) {
        var filter = svgDefs.append("svg:filter")
            .attr("id", "dropShadow1")
            .attr("x", "0")
            .attr("y", "0")
            .attr("width", "200%")
            .attr("height", "200%");
   
        filter.append("svg:feOffset")
            .attr("result", "offOut")
            .attr("in", "SourceAlpha")
            .attr("dx", "1")
            .attr("dy", "1");
   
        filter.append("svg:feColorMatrix")
            .attr("result", "matrixOut")
            .attr("in", "offOut")
            .attr("type", "matrix")
            .attr("values", "0.1 0 0 0 0 0 0.1 0 0 0 0 0 0.1 0 0 0 0 0 0.2 0");
   
        filter.append("svg:feGaussianBlur")
            .attr("result", "blurOut")
            .attr("in", "matrixOut")
            .attr("stdDeviation", "1");
   
        filter.append("svg:feBlend")
            .attr("in", "SourceGraphic")
            .attr("in2", "blurOut")
            .attr("mode", "normal");
    }
   
    function makeCurlyBrace(x1,y1,x2,y2,w,q)
    {
        //Calculate unit vector
        var dx = x1-x2;
        var dy = y1-y2;
        var len = Math.sqrt(dx*dx + dy*dy);
        dx = dx / len;
        dy = dy / len;
   
        //Calculate Control Points of path,
        var qx1 = x1 + q*w*dy;
        var qy1 = y1 - q*w*dx;
        var qx2 = (x1 - .25*len*dx) + (1-q)*w*dy;
        var qy2 = (y1 - .25*len*dy) - (1-q)*w*dx;
        var tx1 = (x1 -  .5*len*dx) + w*dy;
        var ty1 = (y1 -  .5*len*dy) - w*dx;
        var qx3 = x2 + q*w*dy;
        var qy3 = y2 - q*w*dx;
        var qx4 = (x1 - .75*len*dx) + (1-q)*w*dy;
        var qy4 = (y1 - .75*len*dy) - (1-q)*w*dx;
   
        return ( "M " +  x1 + " " +  y1 +
            " Q " + qx1 + " " + qy1 + " " + qx2 + " " + qy2 +
            " T " + tx1 + " " + ty1 +
            " M " +  x2 + " " +  y2 +
            " Q " + qx3 + " " + qy3 + " " + qx4 + " " + qy4 +
            " T " + tx1 + " " + ty1 );
    }
 

return node;
}
export default createDonutChart;