import { NodeModel } from "storm-react-diagrams";
import SMPortModel from "../simplePortModel/SMPortModel";
import i18next from '../../../../../i18n';

class CNodeModel extends NodeModel {
	extras = {
		ia_id:0,
		text: i18next.t('without_text'),
		title: i18next.t('carousel').toUpperCase(),
		cards: [],
		home_element: 'set-home',
		width: 200,
		dbId: 0,
		keyword: "",
		head: "",
		headid: "",
		msg_timeout: "",
        timeout: 0
	}
	removeBox = null
	changeHome = null
	toggleEdit = null
	customClone = null
	
	constructor() {
		super("cn");
		this.addPort(new SMPortModel(false, "left", 'cn'));
	}
}

export default CNodeModel