import { AbstractNodeFactory } from "storm-react-diagrams";
import React from "react";
import EBNodeWidget from "./EBNodeWidget";
import EBNodeModel from "./EBNodeModel";

class EBNodeFactory extends AbstractNodeFactory {
	constructor() {
		super("eb");
	}

	generateReactWidget(diagramEngine, node) {
		return <EBNodeWidget node={node} />
	}

	getNewInstance() {
		return new EBNodeModel();
	}
}

export default EBNodeFactory