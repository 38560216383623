import React from 'react';
import STData from './d3-examples/dataSquareText';
import SquareText from './d3Components/SquareText';
import FormatD3 from '../../../../components/infoviz/utils/defineFormats'

import { 
  Col,
  Row
} from 'reactstrap';


class convertSquareText extends React.Component {
  constructor(props) {
    super(props);
    this.state = {data: ''}
  }

  componentDidMount() {
    let d3Data = STData(this.props.dato, FormatD3);
    this.setState({data: d3Data});
  }
     
  
  render() {
    
    return(  
       
        // <SquareText nameDiv={this.props.nameDiv} 
        // data={this.props.data}  
        // group={this.props.group}
        //  botID={this.props.botID} 
        //  dateini={this.props.dateini} 
        //  datefin={this.props.datefin} 
        //  color={this.props.color} 
        //  typeScaleColor={this.props.typeScaleColor}
        //  description={this.props.description}/>
        <div id={this.props.nameDiv} class="card-dashboard">
          <div class="card-dashboard-header"  style={{backgroundColor:this.props.color}}>{this.props.data.title}</div>
          <div class="card-dashboard-main">
            <Row class="card-dashboard-main">
              <Col sm="4">
                <img class="material-icons" src={this.props.data.icon} height="auto" width="100%"  />
              </Col>
              <Col sm="7" style={{height:100+"%"}}>
                <div class="main-description"  style={{color:this.props.color}}>
                  <p style={{textAlign:"center",fontSize:2.5+"em"}}>{this.props.data.measure+" "+this.props.data.unit}</p>
                  </div>
              </Col>
            </Row>
           
          </div>
        </div>
    )
  }
}
export default convertSquareText;