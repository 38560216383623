var d3 = require('d3');

// Build data for a classic bar chart
const data = {}

var locale = d3.timeFormatLocale({
	"dateTime": "%A, %e de %B de %Y, %X",
	"date": "%d/%m/%Y",
	"time": "%H:%M:%S",
	"periods": ["AM", "PM"],
	"days": ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"],
	"shortDays": ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
	"months": ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"],
	"shortMonths": ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"]
  });


//d3.timeFormatDefaultLocale(locale);

data.formatAsPercentage = d3.format('%');
data.formatAsPercentage1Dec = d3.format('.1%');
data.formatAsInteger = d3.format(',');
data.fsec = d3.timeFormat('%S s');
data.fmin = d3.timeFormat('%M m');
data.fhou = d3.timeFormat('%H h');
data.fwee = d3.timeFormat('%a');
data.fdat = d3.timeFormat('%d d');
data.fmon = d3.timeFormat('%b');

data.formatMillisecond = locale.format(".%L"),
data.formatSecond = locale.format(":%S"),
data.formatMinute = locale.format("%I:%M"),
data.formatHour = locale.format("%I %p"),
data.formatDay = locale.format("%a %d"),
data.formatWeek = locale.format("%b %d"),
data.formatMonth = locale.format("%B"),
data.formatYear = locale.format("%Y");
	
data.wrap= function(text, width) {
	  text.each(function() {
	    var text = d3.select(this),
	        words = text.text().split(/\s+/).reverse(),
	        word,
	        line = [],
	        lineNumber = 0,
	        lineHeight = 30, // ems
	        y = text.attr("y"),
	        dy = parseFloat(text.attr("dy")),
	        tspan = text.text(null).append("tspan").attr("x", 300).attr("y", y).attr("dy", dy + "em");
	    while (word = words.pop()) {
	      line.push(word);
	      tspan.text(line.join(" "));
	      if (tspan.node().getComputedTextLength() > width) {
	      	y=parseInt(y)+20;
	        line.pop();
	        tspan.text(line.join(" "));
	        line = [word];
	        tspan = text.append("tspan").attr("x", 300).attr("y", y).attr("dy", ++lineNumber * lineHeight + dy + "em").text(word);
	      }
	    }
	  });
  }
    
export default data;
