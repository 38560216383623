import React from 'react';
import './slice-menu.css';

function SliceMenuApp(props){
    return (
        <div className="slice-menu d-md-block">
            <ul>
                {
                    props.children
                }
            </ul>
        </div>
    );
}
  
export default SliceMenuApp