import React, { Component } from 'react';
import ChangePasswordLayout from './changepassword-layout';
import ModalLoad from '../../components/modals/Loading/moda-loading';
import { connect } from 'react-redux';
import {logIn,logInSendOTP, changePasswordAction, logOut} from "../../redux/actions/loginActions";
import { getAuthorization } from '../../utils/commonFunction';
import i18next from '../../../i18n'

class ChangePassword extends Component {
    state = {
        login:{
            oldpassword:'',
            newpassword:'',
            repeatpassword:'',
            username:'',
            password:'',
            grant_type:'password',
        },
        redirect: false,
        showAlert:false,
        showAlertWarning: false,
        showSuccess:false,
        warningMsg: "",
    }

    constructor(props) {
        super(props);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        
        this.handeloldpasswordChange    = this.handeloldpasswordChange.bind(this);
        this.handelnewpasswordChange    = this.handelnewpasswordChange.bind(this);
        this.handelrepeatpasswordChange = this.handelrepeatpasswordChange.bind(this);

        this.onSubmit = this.onSubmit.bind(this);
        this.hideAlert = this.hideAlert.bind(this);
    }

    hideAlert=(e)=>{
        this.setState({showAlert:false});
    }

    handleEmailChange= (e)=> {
        this.setState({login:{oldpassword   : e.target.value,
                              newpassword   : this.state.login.newpassword,
                              repeatpassword: this.state.login.repeatpassword}});
    }
    
    /* ================================
                NEW 
    ====================================*/
    handeloldpasswordChange = (e) => {
        this.setState({login:{oldpassword   : e.target.value,
                              newpassword   : this.state.login.newpassword,
                              repeatpassword: this.state.login.repeatpassword}});
    }

    handelnewpasswordChange = (e) => {
        this.setState({login:{oldpassword   : this.state.login.oldpassword,
                              newpassword   : e.target.value,
                              repeatpassword: this.state.login.repeatpassword}});
    }

    handelrepeatpasswordChange = (e) => {
        this.setState({login:{oldpassword   : this.state.login.oldpassword,
                              newpassword   : this.state.login.newpassword,
                              repeatpassword: e.target.value}});
    }
    /* ================================
                END
    ====================================*/

    handlePasswordChange= (e)=> {
        this.setState({login:{username:this.state.login.username,password: e.target.value,grant_type:this.state.login.grant_type}});
    }
    
    onSubmit = async (e) => {

        e.preventDefault();
        
        const { changePasswordAction, logOut } = this.props;    

        if(this.state.login.newpassword != this.state.login.repeatpassword){

            this.setState({ showAlertWarning: true, warningMsg: i18next.t('change_password_error')  });
                
            setTimeout(() => {
                this.setState({showAlertWarning: false});
            }, 2000);
            
            return;
        }else{

            const regex_pass = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{10,}$/gm;

            if (regex_pass.test(this.state.login.newpassword) == false) {
                    alert(i18next.t('validate_message2'));
                    return;
            }

        }

        const { loginReducer: { error, changepassword, changepasswordmsg}  } = this.props;
        const usernamechangepassword = JSON.parse(localStorage.getItem('UserInfo'));
        console.log("error ",error);
        if(error){
            // An error has occurred 
            this.setState({showAlertWarning: true, warningMsg: error});
            setTimeout(() => {
                this.setState({showAlertWarning: false});
            }, 2000);

        }else if(usernamechangepassword){
            //Request Login success
            // TODO: Quitar bloque de bloque de codigo, cuando lo que se guardaba en lo localStorage no se requiera más
                
                await changePasswordAction({username    : usernamechangepassword.username, 
                                            password    : this.state.login.oldpassword, 
                                            new_password: this.state.login.newpassword});

            // END TODO: 
            if(this.props.loginReducer.changepassword === true && this.props.loginReducer.changepasswordmsg != "Tiene contraseñas iguales."){
                
                this.setState({showAlertWarning: true, warningMsg: i18next.t('change_password_ok') });
                
                setTimeout(() => {
                    this.setState({showAlertWarning: false});
                }, 2000);

                await logOut();

                setTimeout(() => {
                    this.hideAlert();
                    this.props.history.push("/login");
                }, 2500);

            }else if(this.props.loginReducer.changepassword === true && this.props.loginReducer.changepasswordmsg === "Tiene contraseñas iguales."){

                this.setState({showAlertWarning: true, warningMsg: this.props.loginReducer.changepasswordmsg});
                
                setTimeout(() => {
                    this.setState({showAlertWarning: false});
                }, 2000);

            }else{
                
                this.setState({showAlertWarning: true, warningMsg: i18next.t('change_password_no')});
                
                setTimeout(() => {
                    this.setState({showAlertWarning: false});
                }, 2000);
           
            }

        }      
    }

    render() {
        return (
            <div>
                <ChangePasswordLayout
                    onSubmit                    = {this.onSubmit}

                    handleEmailChange           = {this.handleEmailChange}
                    handlePasswordChange        = {this.handlePasswordChange}

                    handeloldpasswordChange     = {this.handeloldpasswordChange}
                    handelnewpasswordChange     = {this.handelnewpasswordChange}
                    handelrepeatpasswordChange  = {this.handelrepeatpasswordChange}
                    
                    hideAlert                   = {this.hideAlert}
                    showAlert                   = {this.state.showAlert}
                    showAlertWarning            = {this.state.showAlertWarning}
                    warningMsg                  = {this.state.warningMsg}
                    blockAccount                = {this.props}
                />
                <ModalLoad  />
            </div>
            
        )
    }
}

const mapStateToProps = ({loginReducer,}) => {
	return {loginReducer,};
};

const mapDispatchToProps = {
	logIn,
    logInSendOTP,
    changePasswordAction,
    logOut
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);

