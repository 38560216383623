import React, { Component } from "react";
import ModalLoad from "../../../components/modals/modal-load";
import DetailContactList from "./detailt-list-layout";
import {getHandlerPOST,getHandlerGET} from '../../../utils/handlersApi'
import {isAuthorizationValid,cerrarSesion} from '../../../utils/commonFunction'

class ContactLists extends Component {
    _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      botversion_id:'',
      company_id: 0,
      hideLoad: 0,
      idList:this.props.match.params.lista,
      allItems:1,
      contacts: []
    };
  }
  
  componentWillMount(){
    isAuthorizationValid();
  }

  componentDidMount() {
    this.allList();
    this.getContacts(this.state.idList,1)
    this._isMounted = true;
  }

  componentWillUnmount = () => this._isMounted = false;
 
  deleteContact=(num_row,_id)=>{
    if (confirm("Desea eliminar el contacto?")) {
      var data = {
        num_row: num_row,
        _id: _id
      };
      let url_api = HOST_API + "/api/listas/delete_contacts";
      getHandlerPOST(url_api, data)
        .then(res => {
        this.getContacts(_id,1);
        })
        .catch(this.errorAction);
    }
  };

  /* Get Bot Info */
  getBotInfo() {
    let cont = this.state.hideLoad;
    this.setState({ hideLoad: cont + 1 });
    getHandlerGET(HOST_API + "/api/bot/?id=" + this.state.botId)
      .then(res => {
        if (res.status == 200) {
          let botversion = res.data.bot[0].bot_version[0];
          this.setState({
            botversion_id: botversion.botversion_id,
            company_id: res.data.bot[0].company_id
          });
        }else{
          console.log(" No se pudo recuperar info de la lista");
        }
        let cont = this.state.hideLoad;
        this.setState({ hideLoad: cont - 1 });
      })
      .catch(error => {
        console.log('catch getBotInfo',error);
        let cont = this.state.hideLoad;
        this.setState({ hideLoad: cont - 1 });
      });
  }

  getContacts = (id,page) => {
    let cont = this.state.hideLoad;
    this.setState({ hideLoad: cont + 1 });
    let params = "&p="+page;
    getHandlerGET(HOST_API + "/api/listas/content?_id="+id+params)
      .then(res => {
        if (res.status == 200) {
          this.setState({ contacts: res.data.data });
        }
        let cont = this.state.hideLoad;
        this.setState({ hideLoad: cont - 1 });
      })
      .catch(error => {
        console.log('catch getContacts',error);
        this.cerrarSesion();
        let cont = this.state.hideLoad;
        this.setState({ hideLoad: cont - 1 });
      });
  };

  /* all bots */
  allList = () => {
      getHandlerGET(HOST_API +'/api/listas/content/total?_id='+this.state.idList)
          .then((res)=>{
              if(res.status==200){
                if(res.data)
                  if (this._isMounted)
                    this.setState({allItems:res.data})
              }else
                console.log(" No se pudo obtener la lista");
          }).catch((error) => {
            console.log('Catch GET allList:',error);
            if (error.response.status == 403)
              cerrarSesion();
          });
  }


  render() {
    return (
      <div>
        <DetailContactList
          contactLists={this.state.contactLists}
          botId={this.state.botId}
          idList = {this.state.idList}
          company_id={this.state.company_id}
          deleteContact={this.deleteContact} 
          allItems = {this.state.allItems}
          getContacts = {this.getContacts}
          contacts={this.state.contacts} 
        />
        <ModalLoad hideLoad={this.state.hideLoad} />
      </div>
    );
  }
}

export default ContactLists;
