import  { AbstractNodeFactory } from "storm-react-diagrams";
import React from "react";
import IFNodeWidget from "./IFNodeWidget";
import IFNodeModel from "./IFNodeModel";

class IFNodeFactory extends AbstractNodeFactory {
	constructor() {
		super("if");
	}

	generateReactWidget(diagramEngine, node) {
		return <IFNodeWidget node={node} />
	}

	getNewInstance() {
		return new IFNodeModel();
	}
}

export default IFNodeFactory