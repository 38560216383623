import React,{ Component } from 'react';
import { Button,Input,Container, Row, Col, Form, FormGroup, Label } from 'reactstrap';
import { connect } from 'react-redux';
import './login-layout.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import  Recaptcha from 'react-recaptcha';
import {logIn, logInOTP, logOut} from "../../redux/actions/loginActions";
import i18next from '../../../i18n'


class ValidateLayout extends Component  {
    constructor(props) {
        super(props)
        this.state = {
            isVerify:false
        }
        this.callback = this.callback.bind(this);
    }
     callback =  () => true;
       
     verifyCallback = (response) => {
        if(response)
            this.setState({isVerify:true})
        else
            alert('Dale check al reCAPTCHA para continuar')
      };
      

    render(){

        {this.props.loginReducer.email == '' ?  window.location.href = "/login": ''}

        return (
            <Container className="d-flex align-items-center">
                <Col className="barra-box"></Col>
                <Row>
                    <Col>
                        <Row>
                            <Col className="d-none d-md-block d-lg-block d-xl-block img-login-box" md={{size:4, offset:2}}>
                            </Col>
                            <Col sm="12" md="4" className="form-login">
                                <h1 className="text-guarumo">{i18next.t('init_session')}</h1>
                                <Form onSubmit={this.props.onSubmit}>
                                    <FormGroup>
                                        <Label for="correo" className="font-weight-bold font14">{i18next.t('otp_token')} {}{this.props.loginReducer.email.replace(/(?<=^.{2})[^@\s]/g, '****')} {i18next.t('otp_token_verify')}</Label><br>
                                        </br>
                                        <Input name="text" className="otp" id="otp" onChange={this.props.handleOtp} placeholder={i18next.t('otp_token')}/>
                                    </FormGroup>
                                    <Col md="12" className="button-box">
                                        <Button id="btnlogin" color="secundary" className="btn-block btn-lg btn-guarumo">{i18next.t('init_session_btn')}</Button>
                                    </Col>
                                </Form>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <SweetAlert success title="Ha iniciado sesión" show={this.props.showAlert} onConfirm={this.props.hideAlert} showConfirm={false} />
                <SweetAlert warning title={this.props.warningMsg} show={this.props.showAlertWarning} onConfirm={this.props.hideAlert} showConfirm={false} />
            </Container>
          )
    }
    
}    

const mapStateToProps = ({loginReducer,}) => {
	return {loginReducer,};
};

const mapDispatchToProps = {
	logIn,
    logInOTP,
    logOut,
};

export default connect(mapStateToProps, mapDispatchToProps)(ValidateLayout);
