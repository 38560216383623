import React, { Component } from 'react';
import { Input, Alert } from 'reactstrap'

import i18next from '../../../i18n';

class TextVar extends Component {
    state = {
        value: ""
    }
    constructor(props){
        super(props)
    }

    cathValue = (e) => {
        this.setState({value: e.target.value})
        this.props.onChange({"value": e.target.value});
    }

    render(){
        return <div>
            <Input placeholder={i18next.t('compare_value')} onChange={this.cathValue} defaultValue={this.props.defaultValue} />
        </div>
    }
}

export default TextVar