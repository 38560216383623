
import React, { Component } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBTabPane, MDBTabContent, MDBNav, MDBNavItem, MDBNavLink, MDBIcon } from
"mdbreact";

import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
    Button,
    FormGroup,
    Label,
    Input,
    Col,
    Row,
    UncontrolledTooltip
  } from "reactstrap";

//import CubejsFilter from "./cubejsFilter"
import QueryCubejs from "../cubejs/cubejsQueryRenderData"
import { changeActiveTab} from "../../../../redux/actions/analyticsActions";
import i18next from "../../../../../i18n";

var operators=[],
    functions=[],
    textareaFocus="metricFormula";

function tabNuevaMetrica(props) {

    const { analyticsReducer: { activeItem = "1", listAllDimensions = [],}, changeActiveTab } = props;
  
    createListSelect(props);

        return (
          <MDBContainer>
          <MDBNav className="nav-tabs mt-5">
            <MDBNavItem>
              <MDBNavLink to="#" active={activeItem === "1"} onClick={(e) => changeActiveTab("1")}  role="tab" >
               {i18next.t('create_consult')}
              </MDBNavLink>
            </MDBNavItem>
            <MDBNavItem>
              <MDBNavLink to="#" active={activeItem === "2"} onClick={(e) => changeActiveTab("2")} role="tab" >
               {i18next.t('test_consult')}
              </MDBNavLink>
            </MDBNavItem>
          </MDBNav>
          <MDBTabContent activeItem={activeItem} >
            <MDBTabPane tabId="1" role="tabpanel">
                <Row>
            <Col sm="8">
                <Row>
                <Col sm="4">
              <FormGroup>
                  <Label className="control-label" for="metricDimensions">
                    {i18next.t('dimensions')+': '} <i className='fa fa-info-circle' id='tooltipDimensions'></i>
                  </Label>
                  <UncontrolledTooltip placement="right" target="tooltipDimensions">
                          {i18next.t('message184')}
                    </UncontrolledTooltip>
                    <Input
                    type="textarea"
                    rows={4}
                    name="metricDimension"
                    id="metricDimension"
                    maxLength="100"
                    placeholder={i18next.t('dimensions_metric')}
                    onFocus={(e) => onFocus(e,"metricDimension")}
                  />
                  </FormGroup>
                  </Col>
              <Col sm="4">
              <FormGroup>
                  <Label className="control-label" for="metricFormula">
                  {i18next.t('measurement')+': '}{" "}
                  </Label>
                  <Input
                    type="textarea"
                    rows={4}
                    name="metricFormula"
                    id="metricFormula"
                    maxLength="100"
                    placeholder={i18next.t('write_form_measurement')}
                    onFocus={(e) => onFocus(e,"metricFormula")}
                  />
                </FormGroup>
                </Col>
                <Col sm="4">
                <FormGroup>
                  <Label className="control-label" for="metricCondition">
                    Filtro:{" "}
                  </Label>
                  <Input
                    type="textarea"
                    rows={4}
                    name="metricCondition"
                    id="metricCondition"
                    maxLength="100"
                    placeholder={i18next.t('metric_message2')}
                    onFocus={(e) => onFocus(e,"metricCondition")}
                  />
                </FormGroup>
                </Col>
                </Row>
                  <FormGroup>
                <Row>
                  <Col sm="4">
                    <Label
                      className="control-label required-field"
                      for="metricFields"
                    >
                      {i18next.t('fields')+': '}
                    </Label>
                     {listAllDimensions &&(listAllDimensions.length > 0 && (
                           
                           <Input type="select" name="select"  bsSize="sm" id="metricCampos" multiple onClick={(e) => onclick(e,"field")} >
                             
                           {listAllDimensions.map((dimension) => {
                             dimension=JSON.parse(dimension);
                           dimension=<option key={dimension.id} value={dimension.name}>{dimension.name}</option>
                           return dimension})}
                           </Input>
                           ))}
                  </Col>
                  <Col sm="4">
                   
                  <Label
                      className="control-label required-field"
                      for="metricFunction"
                    >
                      {i18next.t('functions')+': '}  <i className='fa fa-info-circle' id='tooltipCount'></i>
                    </Label>
                    <UncontrolledTooltip placement="right" target="tooltipCount">{ReactHtmlParser(props.tooltipFunctions)} 
                    </UncontrolledTooltip>
                    {props.functions &&(props.functions.length > 0 && (
                    <Input
                      type="select" className="platforms-container" id="metricFunction" name="metricFunction" multiple onClick={(e) => onclick(e,"function")}>
                     {functions}
                    </Input>
                    ))}
                  </Col>
                  <Col sm="4">
                  
                  <Label
                      className="control-label required-field"
                      for="metricOperators"
                    >
                      {i18next.t('operators')+': '} <i className='fa fa-info-circle' id='tooltipOperators'></i>
                    </Label>
                    <UncontrolledTooltip placement="right" target="tooltipOperators">{ReactHtmlParser(props.tooltipOperators)} </UncontrolledTooltip>
                    {props.operators &&(props.operators.length > 0 && (
                    <Input
                      type="select" className="platforms-container" id="metricOperators" name="metricOperators" multiple onClick={(e) => onclick(e,"operator")}>
                      {operators}
                    </Input>
                    
                ))}
                  </Col>
                </Row>
               
              </FormGroup>
                </Col>
                <Col sm="4">
              <FormGroup>
              <br></br>
                    <Input
                    type="textarea"
                    rows={10}
                    name="metricResult"
                    id="metricResult"
                    maxLength="100"
                    placeholder={i18next.t('consult')}
                    onFocus={(e) => onFocus(e,"metricResult")}
                    disabled="true"
                  />
                  </FormGroup>
                  </Col>
               </Row>
            </MDBTabPane>
            <MDBTabPane tabId="2" role="tabpanel">
               <FormGroup>
                 <Button outline color="success" onClick={(e) => onTry(e)} size="sm" >
                   {i18next.t('update')} 
                 </Button>
                 <br></br>
                 <br></br>
                 </FormGroup>
                 <FormGroup style={{overflow:'auto'}}>
                   <QueryCubejs
                    typechart={'6'}
                    nameDiv={'table'}
                    // color={color}
                    // typepalette={typepalette}
                   // metric={selectedMetric} //OJO SE DEBE REVISAR QUE INFO SE ENVÍA PARA PROBAR LA CONSULTA
                    // icon={icon}
                   >
                  
                   </QueryCubejs>
            </FormGroup> 
            </MDBTabPane>
          </MDBTabContent>
        </MDBContainer>
//     return (
//         <MDBContainer>
//         <MDBNav tabs>
//         <MDBNavItem>
//           <MDBNavLink
//             to="#"
//             active={activeItem === "1"}
//              onClick={changeActiveTab("1")}
//             role="tab"
//           >
//            
//           </MDBNavLink>
//         </MDBNavItem>
//         <MDBNavItem>
//           <MDBNavLink
//             to="#"
//             active={activeItem === "2"}
//            onClick={changeActiveTab("2")}
//             role="tab"
//           >
//             <MDBIcon icon="heart" /> {i18next.t('test_consult')}
//           </MDBNavLink>
//         </MDBNavItem>
        
//       </MDBNav>
//       <MDBTabContent
//         className="card"
//         activeItem={activeItem}

     );
  }


function onChange (selected) {
    changeBetweenList(selected);
  }



function onchangeType(e)
{
  var bots=document.getElementById("metricBot");
  if(e.target.value=='operacional'){
    bots.disabled=true;
    bots.value=""
  }
  else
    bots.disabled=false;
    
}

function onchangeTypeIndi(e)
{
  var measureDiv=document.getElementById("measure");
  var metricDiv=document.getElementById("metric");
  if(e.target.value=='medida'){
    measureDiv.style.display="flex";
    metricDiv.style.display="none";
  }
  else{
    measureDiv.style.display="none";
    metricDiv.style.display="flex";
  }
  
    
}

function onclick(e,type)
{
  // var formula=document.getElementById("metricFormula");
  // var condition=document.getElementById("metricCondition");
  // var dimension=document.getElementById("metricDimension");

  var text="";
  //var text="<span style='color: blue;'>["+e.target.value+"]</span>";
  if(type=="field")
  text+="["+e.target.value+"]";
else if(type=="operator")
  text+=e.target.value;
else if(type=="function")
  text+=e.target.value+"()";

  if(textareaFocus=="metricDimension"&&(type=="operator"||type=="function")){}
  else
    insertAtCaret(textareaFocus, text);
  
  
}

function insertAtCaret(areaId, text) {
  var txtarea = document.getElementById(areaId);
  var scrollPos = txtarea.scrollTop;
  var caretPos = txtarea.selectionStart;

  var front = (txtarea.value).substring(0, caretPos);
  var back = (txtarea.value).substring(txtarea.selectionEnd, txtarea.value.length);
  txtarea.value = front + text + back;
  caretPos = caretPos + text.length;
  txtarea.selectionStart = caretPos;
  txtarea.selectionEnd = caretPos;
  txtarea.focus();
  txtarea.scrollTop = scrollPos;
}

function onFocus(e,textarea)
{
  textareaFocus=textarea;

  var operadores=document.getElementById("metricOperators");
  var funciones=document.getElementById("metricFunction");

  if(textareaFocus=="metricDimension")
  {
    
    operadores.disabled=true;
    funciones.disabled=true;
  }
  else
  {
    operadores.disabled=false;
    funciones.disabled=false;
  }

}

function onTry(e)
{

}

function createListSelect(props)
{

  /**
   * Select Operators
   */
  if(operators.length==0){
  if(props.operators){
    if (props.operators.length > 0) {
      for (var i = 0; i < props.operators.length; i++) {
        let aux = props.operators[i];
        if(aux.id != 1){
          operators.push(
            <option key={i} value={aux.name}>
              {aux.name}
            </option>
          );
        }
      }
    }
  }
}

  /**
   * Select Functions
   */
  if(functions.length==0){
  if(props.functions){
    if (props.functions.length > 0) {
      for (var i = 0; i < props.functions.length; i++) {
        let aux = props.functions[i];
        if(aux.id != 1){
          functions.push(
            <option key={i} value={aux.id}>
              {aux.name}
            </option>
          );
        }
      }
    }
  }
}
}   

const mapStateToProps = ({ analyticsReducer }) => {
	return { analyticsReducer };
};

const mapDispatchToProps = {
    changeActiveTab,
};


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(tabNuevaMetrica));

