import React, { Component } from 'react';
import SenderLayout from './senders-layout';
import ModalLoad from '../../../components/modals/modal-load';
import {
  getHandlerGET, getHandlerPOST,
  getHandlerDELETE, getHandlerPUT
} from '../../../utils/handlersApi'
import { cerrarSesion, isAuthorizationValid, getRolUser,getUserInfoLocalStorage } from '../../../utils/commonFunction'


class Sender extends Component {
    state = {
        modalSender: false,
        editedSender: false,
        sender_id: 0,
        channel_type: '',
        name: '',
        value: '',
        listAllSender: [],
        company_id: 0,    
        isAdmin:false,
        enableEmail: false,
        statusEmail: '',
        allListcompanies:[],
        editCompany:''
    }

    constructor(props) {
        super(props)
    }

    componentWillMount() {
        isAuthorizationValid();
        const { isAdmin = false, isSuperUser = false } = getRolUser();
        const { company_id } = getUserInfoLocalStorage();
        this.setState({
            isAdmin: isAdmin,
            isSuperUser: isSuperUser,
            company_id: company_id
        });
    }

    componentDidMount() {
        this.getListSender("all"); //OJO CON ALL TRAE TODOS SIN PAGINAR  
        if(this.state.isSuperUser){
            this.getCompanies();
        }
    }

    
    getListSender = (p) => {   
        getHandlerGET(HOST_API + '/api/senders/?p='+p)
        .then((res) => {
            if (res.status == 200) {
                this.setState({ listAllSender: res.data.senders });
            }else{
                console.log(" No se pudo obtener la lista");
            }
        }).catch((error) => {
            console.log('catch GET getListSender', error);
            if (error.response.status == 403){
                cerrarSesion();
            }
        });
    }

    handleEmailVerify = (email, id) => {
        let data = { email, sender_id: id };
        getHandlerPOST(HOST_API + "/api/verify_email", data)
          .then((res) => {
            // La verificacion de email, respondera con un string asi :
            // ['Pending'|'Success'|'Failed'|'TemporaryFailure'|'NotStarted'|'Not Found']
            switch (res.data.VerificationStatus) {
              case 'Pending':
                this.setState({ enableEmail: false, statusEmail: 'Pendiente',  })
                alert('Por favor revise en su bandeja de entrada... ');
                break;                
              case 'Success':
                this.setState({ enableEmail: true, statusEmail: 'Verificado', })
                break;
              default:
                this.setState({ enableEmail: false, statusEmail: 'Email no valido',  })
                break;
            }
            this.forceUpdate();
          }).catch((error) => {
            console.log('Error on handleEmailVerif()', error);
          });
    
      }
    
    toggleModalSender = (sender,edit) => {    
        const { name , value = '', channel_type ='', id = 0, company:company_id } = sender;
        // obtener datos de la compañia a partir del company id que tiene el sender
        const company = this.state.allListcompanies.filter(( item ) => item.id == company_id);
        let companySelected = {value: '',label: ''};
        if(company && company.length>0){
            const { id='', name='' } = company[0];
            companySelected = {label: name, value: id };
        }   

        this.setState({
            editCompany: companySelected,
            editedSender: edit,
            name: name,
            value: value,
            channel_type: channel_type,
            sender_id: id,
            modalSender: !this.state.modalSender
        });
    }

    toggleModalNewSender = (edit) => { 
        this.setState({
            editCompany: '',
            name: '',
            value: '',
            channel_type: '',
            sender_id: '',
            editedSender: edit,
            modalSender: !this.state.modalSender
        });
    }


    getCompanies =() => {
        getHandlerGET(HOST_API +'/api/company/')
        .then((res)=>{
            if(res.status==200){
                if(res.data.companies){
                    this.setState({allListcompanies: res.data.companies});
                }
            }
        })
        .catch((error)=>{
            console.log('Cach error getCompanies:', error);
            
        });
    }

    deleteSender = ( id ) => {
        if (confirm("¿Desea eliminar este remitente permanentemente?")) {
        let data = { sender_id: id }
        getHandlerDELETE(HOST_API + '/api/senders/', data)
            .then((res) => {
            if (res.status == 200) {
                this.getListSender("all");//OJO CON ALL TRAE TODOS SIN PAGINAR
            } else {
                console.log(" No se pudo eliminar el remitente");
            }
            }).catch((error) => {
            console.log('catch Delete deleteCampaign', error);
            if (error.response.status == 403)
                cerrarSesion();
            });
        }
    }

    handleChangeType = type => this.setState({ type })

    //-- salto de SaveSender
    handleSaveSender = (event) => {
        event.preventDefault();     
        let form = new FormData(event.target);    
        if(!form.get('companies')){
            alert('¡ Selecione la compañia a la que pertenecera el remitente !');
            return;
        }
        let data = {
            sender_id: parseInt(form.get('sender_id')),
            name: form.get('name'),
            value: form.get('value'),
            channel_type: form.get('channel_type'),
            company_id: form.get('companies')
        }    
        document.getElementById("frm_notification").reset();
        if (this.state.editedSender) {
             getHandlerPUT(HOST_API + '/api/senders/', data)
                .then((res) => {         
                if (res.status == 200) {
                    this.getListSender("all"); //OJO CON ALL TRAE TODOS SIN PAGINAR  
                    alert('Datos guardados con exito');             
                }
                else
                    console.log(" No se pudo GUARDAR");
                })
                .catch((error) => {
                alert('Hubo un error, ¡Intente nuevamente!')
                console.log('catch PUT handleSaveSender', error);
                if (error.response.status == 403)
                    cerrarSesion();
                });
        } else {        
            getHandlerPOST(HOST_API + '/api/senders/', data)
                .then((res) => {
                if (res.status == 200) {
                    this.getListSender("all"); //OJO CON ALL TRAE TODOS SIN PAGINAR    
                    alert('Datos guardados con exito');
                }
                else
                    console.log(" No se pudo GUARDAR");
                })
                .catch((error) => {
                alert('Hubo un error, ¡Intente nuevamente!')
                console.log('catch POST handleSaveSender', error);
                if (error.response.status == 403)
                    cerrarSesion();
                });
        }
        this.setState({
            name:'',
            sender_id:'',
            value:'',
            channel_type:''
        })
        this.toggleModalNewSender(false);

    }

    handleChangeCompanies = (option) =>  this.setState({ editCompany: option});


    // // Change input style with validate
    // customValidate = (classItem,idiTem,borderValidate) => {
    //     if (borderValidate) {      
    //     document.getElementsByClassName(idiTem)[0].setAttribute('class',` ${classItem}  ${idiTem}  border-red`)
    //     } else {
    //     document.getElementsByClassName(idiTem)[0].setAttribute('class', ` ${classItem}  ${idiTem}`)
    //     }
    // }

    render() {
        return (
        <div>
            <SenderLayout
            modalSender={this.state.modalSender}
            channel_type={this.state.channel_type}
            name={this.state.name}
            value={this.state.value}
            sender_id={this.state.sender_id}
            listAllSender={this.state.listAllSender}
            isAdmin={this.state.isAdmin}
            isSuperUser={this.state.isSuperUser}
            statusEmail={this.state.statusEmail}
            allListcompanies={this.state.allListcompanies}
            editCompany={this.state.editCompany}
            
            // functions
            toggleModalSender={this.toggleModalSender}
            deleteSender={this.deleteSender}
            handleSaveSender={this.handleSaveSender}
            handleChangeType={this.handleChangeType}
            toggleModalNewSender={this.toggleModalNewSender}
            handleEmailVerify={this.handleEmailVerify}
            handleChangeCompanies={this.handleChangeCompanies}
            getListSender = {this.getListSender}

            />
            <ModalLoad hideLoad={this.state.hideLoad} />
        </div>
        )
    }
}

export default Sender
