import React, { Component } from "react";
import ReactDOM from "react-dom";
import BotUnsuscrubeRenderLayout from './bot-unsuscribe-render-layout';
import ModalLoad from '../../../components/modals/modal-load';
import axios from 'axios';
import qs from 'qs';

class BotUnsuscribeRender extends Component {

    state = {
        hideLoad:0,
        botSession: this.props.match.params.session,
        botversion_id: this.props.match.params.botversion,
        channel:this.props.match.params.channel,
        operatorId:this.props.match.params.operatorid
       
    };
    constructor(props){
        super(props);
    }

    saveForm= (e) => {
        e.preventDefault();
        let form = new FormData(e.target);
        let data = {
            user_response:form.get('suscripcion'),
            bot_version:this.state.botversion_id,
            session_id:this.state.botSession,
            channel:this.state.channel,
            opt_id:this.state.operatorId
        }
        this.postHandler(HOST_API + '/api/listas/unsuscribe/',data)
        .then(response =>{
            if(response.status == 200){
                alert('Gracias por tu repuesta')
                window.close();
            }
        })
        .catch(error => {
          alert("No fue posible editar el usuario, ¡intentelo nuevamente! ")
        });
    

    }



    /*   **** **** **** **** **** **** 
                    AJAX HANDLERS */


     // POST requests handler
     postHandler(url, data){
        var auth=JSON.parse(localStorage.getItem('Authorization'));
        var headers={
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': 'Bearer '+auth.access_token
        }

        return axios({
          method:'post',
          url:url,
          data: qs.stringify(data),
          headers:headers
        })
     }
    
    render() {
        return (
        <div>
          <BotUnsuscrubeRenderLayout 
          saveForm={this.saveForm}
          />

          <ModalLoad hideLoad={this.state.hideLoad} />
          </div>
        )
    }
}

export default BotUnsuscribeRender;