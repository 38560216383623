const createDataPSChart = (group,bot,date) => {

    
    const data = {}
    
    var dataT = {}
    
    data.dataPSChart= [
        {
            group: "All", bot: "All", date: 2008,
            data: [
                {
                "name": "Usuarios",
                "children": [
                {
                "name": "Nuevos",
                "children": [
                {"name": "Comprometidos", "size": 1},
                {"name": "No Comprometidos", "size": 1}
                ]
                },
                {
                "name": "Recurrentes",
                "children": [
                {"name": "Comprometidos", "size": 5},
                {"name": "No Comprometidos", "size": 3}
                ]
                }
                ]

            }]
        },
        {
            group: "Facebook", bot: "All", date: 2008,
            data: [
                {
                "name": "Usuarios",
                "children": [
                {
                "name": "Nuevos",
                "children": [
                {"name": "Comprometidos", "size": 3},
                {"name": "No Comprometidos", "size": 5}
                ]
                },
                {
                "name": "Recurrentes",
                "children": [
                {"name": "Comprometidos", "size": 5},
                {"name": "No Comprometidos", "size": 1}
                ]
                }
                ]

            }]
        },
        {
            group: "Facebook", bot: "Bot 3", date: 2008,
            data: [
                {
                "name": "Usuarios",
                "children": [
                {
                "name": "Nuevos",
                "children": [
                {"name": "Comprometidos", "size": 2},
                {"name": "No Comprometidos", "size": 1}
                ]
                },
                {
                "name": "Recurrentes",
                "children": [
                {"name": "Comprometidos", "size": 3},
                {"name": "No Comprometidos", "size": 2}
                ]
                }
                ]

            }]
        },
        {
            group: "Facebook", bot: "Bot 1", date: 2008,
            data: [
                {
                "name": "Usuarios",
                "children": [
                {
                "name": "Nuevos",
                "children": [
                {"name": "Comprometidos", "size": 6},
                {"name": "No Comprometidos", "size": 2}
                ]
                },
                {
                "name": "Recurrentes",
                "children": [
                {"name": "Comprometidos", "size": 2},
                {"name": "No Comprometidos", "size": 4}
                ]
                }
                ]

            }]
        },
        {
            group: "Web", bot: "All", date: 2008,
            data: [
                {
                "name": "Usuarios",
                "children": [
                {
                "name": "Nuevos",
                "children": [
                {"name": "Comprometidos", "size": 2},
                {"name": "No Comprometidos", "size": 5}
                ]
                },
                {
                "name": "Recurrentes",
                "children": [
                {"name": "Comprometidos", "size": 1},
                {"name": "No Comprometidos", "size": 9}
                ]
                }
                ]

            }]
        },
        {
            group: "Whatsapp", bot: "All", date: 2008,
            data: [
                {
                "name": "Usuarios",
                "children": [
                {
                "name": "Nuevos",
                "children": [
                {"name": "Comprometidos", "size": 2},
                {"name": "No Comprometidos", "size": 4}
                ]
                },
                {
                "name": "Recurrentes",
                "children": [
                {"name": "Comprometidos", "size": 8},
                {"name": "No Comprometidos", "size": 4}
                ]
                }
                ]

            }]
        }
    ];
/*dataT=data;
data.dataPSChart.forEach(function(d, index) {
    console.log(d);
    if(d.group==group)
    {
       dataT=d.data;
        return dataT;
    }
  });
  return dataT[0];*/
  return data;
}
export default createDataPSChart;