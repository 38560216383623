import React, { Component } from 'react';
import EMAILModalLayout from './email-modal-layout';

import { getHandlerPOST, getHandlerPUT, getHandlerGET } from "../../../../utils/handlersApi";
import { cerrarSesion, isAuthorizationValid } from '../../../../utils/commonFunction'

class EMAILModal extends Component {

    state = {
        nodeId: "",
        title: "",
        listTemplates: []
    }

    constructor(props) {
        super(props)
    }

    componentWillMount() {
        isAuthorizationValid();
        this.getlistTemplates();
    }

    toggleModuleModal = async () => {
        let node = this.props.modal.node;
        let subject = $('#TMM-input-subject').val();
        let idtemplateSelected = $('#idtemplateSelected').val();
        let emailReceiver = $('#TMM-input-emailReceiver').val();
        let emailSender = $('#TMM-input-emailSender').val();
        let emailsCCO = $('#TMM-input-emailsCCO').val();

        let blocks = node.extras.variables;
        const kblocks = Object.keys(blocks);
        kblocks.forEach((k) => {
            let text = $('#TMM-input-valueVar-' + k).val();
            blocks[k].value = text;
        });
        this.props.modal.node.extras['subject'] = subject;
        this.props.modal.node.extras['idtemplateSelected'] = idtemplateSelected;
        this.props.modal.node.extras['emailReceiver'] = emailReceiver;
        this.props.modal.node.extras['emailSender'] = emailSender;
        this.props.modal.node.extras['emailsCCO'] = emailsCCO;
        
        if (this.props.modal.node.extras.edited) {
            await this.updateStatement(this.props.modal.node.extras.idStatementSubject, subject, subject);
        } else {
            await this.saveStatement(this.props.modal.node.extras.dbId, subject, subject, 'idStatementSubject',this.props.modal.node.extras);
            this.props.modal.node.extras['edited'] = true;
           
        }
        await this.forceUpdate();
        await this.props.toggleModuleModal('email');

        console.log(' Email -> this.props.modal.node', this.props.modal.node);
    }

    getlistTemplates = () => {
        getHandlerGET(HOST_API + '/api/notification/templates/')
            .then((res) => {
                if (res.status == 200)
                    this.setState({ listTemplates: res.data })
                else
                    console.log(" No se pudo obtener la lista");
            }).catch((error) => {
                console.log('catch GET getListNotificacion', error);
                if (error.response.status == 403)
                    cerrarSesion();
            });
    }

    saveStatement = async (operator, text, name, idStatement,extras) =>  {
        const data = {
            operator_id: operator,
            text: "" + text,
            name: "" + name
        };

        await getHandlerPOST(HOST_API + '/api/scheme/statement/', data).then(async (res) => {
            
            extras[idStatement] = res.data.statement.id;
            await this.props.updateSchema();

        }).catch((error) => {
            console.log('Catch updateStatement:', error);
            if (error.response.status == 403)
                cerrarSesion();
        });
    }

    updateStatement = (idStatement, text, name) => {
        const data = {
            id: idStatement,
            text: text,
            name: name
        };
        getHandlerPUT(HOST_API + '/api/scheme/statement/', data).then((res) => {
            this.props.updateSchema();
        }).catch((error) => {
            console.log('Catch updateStatement:', error);
            if (error.response.status == 403)
                cerrarSesion();
        });
    }

    handleChangeInput = (e) => {
        let value = e.target.value;
        let k = e.target.id
        this.updateItem(k, value)
    }

    handleChangeNameVar = (key, text) => {
        this.props.modal.node.extras.variables[key].key = text
    }

    updateItem = (k, text) => {
        let reg = /(\d+)/g
        if (k.includes('valueVar')) {
            /* Obtiene el index de la variable a modificar */
            this.props.modal.node.extras.variables[k.match(reg)[0]].value = text;
        } else {
            this.props.modal.node.extras[k] = text;
        }
        this.forceUpdate();
    }

    closeModal = () => {
        this.props.closeModal('m16');
        this.forceUpdate();
    }

    //Emoji
    callEmoji = (code, k, type) => {
        let text = this.props.modal.node.extras[k];
        text += ` ${code}`;
        this.updateItem(k, text)
    }

    addElementVariable = () => {
        this.props.modal.node.extras['variables'].push({
            key: '',
            value: ''
        })
        this.forceUpdate();
    }

    removeVariable = (index) => {
        this.props.modal.node.extras.variables.splice(index, 1);
        this.forceUpdate();
    }

    showMetion = (e) => {
        this.props.modal.node.extras.config = event.target.value;
        if (this.props.modal.node.extras.config == '0')
            this.props.modal.node.extras.time = '0';
        else
            this.props.modal.node.extras.time = '';
        this.forceUpdate();
    }

    changeTypeTime = () => {
        this.props.modal.node.extras.typetime = event.target.value;
    }


    render() {
        return ( <
            EMAILModalLayout nodeId = { this.state.nodeId }
            title = { this.state.title }
            toggleModuleModal = { this.toggleModuleModal }
            closeModal = { this.closeModal }
            updateItem = { this.updateItem }
            callEmoji = { this.callEmoji }
            handleChangeInput = { this.handleChangeInput }

            subject = { this.props.modal.node.extras.subject }
            idtemplateSelected = { this.props.modal.node.extras.idtemplateSelected }
            emailSender = { this.props.modal.node.extras.emailSender }
            emailReceiver = { this.props.modal.node.extras.emailReceiver }
            emailsCCO = { this.props.modal.node.extras.emailsCCO }
            variables = { this.props.modal.node.extras.variables || [] }
            addElementVariable = { this.addElementVariable }
            removeVariable = { this.removeVariable }
            handleChangeNameVar = { this.handleChangeNameVar }

            listTemplates = { this.state.listTemplates }
            getlistTemplates = { this.getlistTemplates }


            config = { this.props.modal.node.extras.config }
            isOpen = { this.props.modal.isOpen }
            botversion_id = { this.props.botversion_id }
            operator_id = { this.props.modal.node.extras.dbId }
            titleOperator = { this.props.modal.node.extras.titleOperator || '' }
            company_id = { this.props.company_id }
            botId = { this.props.botId }
            showMetion = { this.showMetion }
            varList = { this.props.varList }
            time = { this.props.modal.node.extras.time || '' }

            changeTypeTime = { this.changeTypeTime }
            typeTime = { this.props.modal.node.extras.typetime || '' }
            />
        )
    }
}

export default EMAILModal