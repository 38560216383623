import React, { Component } from 'react';
import { connect } from "react-redux";

import ModalLoad from "../../../components/modals/modal-load";
import {
  showSuccess,
  showError,
  loading,
  showWarning,
  hideAlert,
  setTitle,
} from "../../../redux/actions/loadStateActions";


import BotConfigurationLayout from "./bot-configuration-layout";

import {
  getHandlerGET,
  getHandlerPOST,
  getHandlerDELETE,
  getHandlerPUT,
  fileHandlerPOST,
} from "../../../utils/handlersApi";
import {
  cerrarSesion,
  isAuthorizationValid,
  isEmpty,
} from "../../../utils/commonFunction";
import { getUserInfoLocalStorage } from "../../../utils/commonFunction";


import { listAvatar } from '../../../redux/actions/companyActions';

import PhoneInput, {
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from "react-phone-number-input";

import { setSelectedBot, setListIntent, setUrlImageS3,setChangeConfig,setChangeTimeoutConfi  } from '../../../redux/actions/botActions';
import i18next from '../../../../i18n';

class BotConfiguration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      platforms: [],
      selectedOption: null,
      selectedOption1: null,
      modalAvatar: false,
      languages: [],
      modal_image: [],
      botInfo: null,
      botId: this.props.match.params.id,
      botversion_id:0,
      hideLoad: 0,
      ia_activated: false,
      detailSaved: false,
      iconTxtBtnSend: "",
      displayColorPicker1: false,
      displayColorPicker2: false,
      displayColorPicker3: false,
      displayColorPicker4: false,
      displayColorPicker5: false,
      displayColorPicker6: false,
      displayColorPicker7: false,
      displayColorPicker8: false,
      displayColorPicker9: false,
      displayColorPicker10: false,
      displayColorPicker11: false,
      displayColorPicker12: false,
      displayColorPicker13: false,
      displayColorPicker14: false,
      displayColorPicker15: false,
      color1: "",
      color2: "",
      color3: "",
      color4: "",
      color5: "",
      color6: "",
      color7: "",
      color8: "",
      color9: "",
      color10: "",
      color11: "",
      color12: "",
      color13: "",
      color14: "",
      color15: "",

      buttonModalImgSizeUpdate: true,
      buttonModalImgUpdate: true,
      buttonModalImg: true,
      buttonModalImgSize: true,

      ok_header_size: false,
      ok_ventana_size: false,
      ok_header_relacion: false,
      ok_ventana_relacion: false,
      error_ventana_size: false,
      error_ventana_relacion: false,
      msj_error_ventana_relacion: "",
      msj_error_ventana_size: "",
      error_header_size: false,
      error_header_relacion: false,

      msj_error_header_relacion: "",
      msj_error_header_size: "",

      error_modal_size: false,
      error_modal_relacion: false,
      msj_error_modal_size: "",
      msj_error_modal_relacion: "",
      ok_modal_relacion: false,
      ok_modal_size: false,

      avatarList: [],
      avatarListBot: [],

      modalAvatar_update: false,
      modal_image_update: [] || "",
      error_modal_size_update: false,
      error_modal_relacion_update: false,
      msj_error_modal_size_update: "",
      msj_error_modal_relacion_update: "",
      ok_modal_relacion_update: false,
      ok_modal_size_update: false,
      idUpdate: "",
      titleUpdate: "",
      idcontactlist: 0,
      totalRepeat: 0,
      contact_lists: [],
      statusEmailVerify: "secondary",
      enableEmail: false,
      responseStatusEmail: "",
      listAllSender: [],
      voice_sender_selected: { value: "", label: "" },
      email_sender_selected: { value: "", label: "" },
      template_email_selected: { value: "", label: "" },

      listTemplates: [],

      expected_user_vars: [],
      expected_user_varsSelected: [],
      word_to_return:'return',
      word_out_queue:'leave',
      file_encripted:true,
      wp_phone:'',
      image_link_testing:'',
      main_timeout:{
        time1:'',
        msg1:'',
        type1:'send_message',
        operator1:'',
        scheme1:'',
        time2:'',
        msg2:'',
        type2:'send_message',
        operator2:'',
        scheme2:'',
        time3:'',
        msg3:'',
        type3:'send_message',
        operator3:'',
        scheme3:''
      },
    };
    this.onDrop3 = this.onDrop3.bind(this);
    this.onDrop4 = this.onDrop4.bind(this);
  }

  componentWillMount() {
    isAuthorizationValid();
  }

  async componentDidMount() {
    this.loadLanguages(); // laod languages
    this.setBotInfo("id=" + this.props.match.params.id); // load bot's info
    this.getAllLists();
    this.getListSender("all"); //OJO CON ALL TRAE TODOS SIN PAGINAR
    this.getlistTemplates();
   

    const {avatarList} = this.props.companyReducer;
    const {listIntent} = this.props.botReducer;

    

    if(isEmpty(avatarList) && this.state.botInfo && this.state.botInfo.company_id){
        this.props.listAvatar(this.state.botInfo.company_id);
    }

    if(isEmpty(listIntent) && this.state.botversion_id){
      this.props.setListIntent();
  }

    

  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  setValue = (value) => {
    this.setState({
      wp_phone: value,
    });
  };

  onSuccess = () => {
    console.info("successfully copied");
  };

  handleClick = (input) => {
    switch (input) {
      case "displayColorPicker1":
        this.setState({ displayColorPicker1: !this.state.displayColorPicker1 });
        break;
      case "displayColorPicker2":
        this.setState({ displayColorPicker2: !this.state.displayColorPicker2 });
        break;
      case "displayColorPicker3":
        this.setState({ displayColorPicker3: !this.state.displayColorPicker3 });
        break;
      case "displayColorPicker4":
        this.setState({ displayColorPicker4: !this.state.displayColorPicker4 });
        break;
      case "displayColorPicker5":
        this.setState({ displayColorPicker5: !this.state.displayColorPicker5 });
        break;
      case "displayColorPicker6":
        this.setState({ displayColorPicker6: !this.state.displayColorPicker6 });
        break;
      case "displayColorPicker7":
        this.setState({ displayColorPicker7: !this.state.displayColorPicker7 });
        break;
      case "displayColorPicker8":
        this.setState({ displayColorPicker8: !this.state.displayColorPicker8 });
        break;
      case "displayColorPicker9":
        this.setState({ displayColorPicker9: !this.state.displayColorPicker9 });
        break;
      case "displayColorPicker10":
        this.setState({
          displayColorPicker10: !this.state.displayColorPicker10,
        });
        break;
      case "displayColorPicker11":
        this.setState({
          displayColorPicker11: !this.state.displayColorPicker11,
        });
        break;
      case "displayColorPicker12":
        this.setState({
          displayColorPicker12: !this.state.displayColorPicker12,
        });
        break;
      case "displayColorPicker13":
        this.setState({
          displayColorPicker13: !this.state.displayColorPicker13,
        });
        break;
      case "displayColorPicker14":
        this.setState({
          displayColorPicker14: !this.state.displayColorPicker14,
        });
        break;
      case "displayColorPicker15":
        this.setState({
          displayColorPicker15: !this.state.displayColorPicker15,
        });
        break;
      default:
        break;
    }
  };

  handleClose = (input) => {
    switch (input) {
      case "displayColorPicker1":
        this.setState({ displayColorPicker1: false });
        break;
      case "displayColorPicker2":
        this.setState({ displayColorPicker2: false });
        break;
      case "displayColorPicker3":
        this.setState({ displayColorPicker3: false });
        break;
      case "displayColorPicker4":
        this.setState({ displayColorPicker4: false });
        break;
      case "displayColorPicker5":
        this.setState({ displayColorPicker5: false });
        break;
      case "displayColorPicker6":
        this.setState({ displayColorPicker6: false });
        break;
      case "displayColorPicker7":
        this.setState({ displayColorPicker7: false });
        break;
      case "displayColorPicker8":
        this.setState({ displayColorPicker8: false });
        break;
      case "displayColorPicker9":
        this.setState({ displayColorPicker9: false });
        break;
      case "displayColorPicker10":
        this.setState({ displayColorPicker10: false });
        break;
      case "displayColorPicker11":
        this.setState({ displayColorPicker11: false });
        break;
      case "displayColorPicker12":
        this.setState({ displayColorPicker12: false });
        break;
      case "displayColorPicker13":
        this.setState({ displayColorPicker13: false });
        break;
      case "displayColorPicker14":
        this.setState({ displayColorPicker14: false });
        break;
      case "displayColorPicker15":
        this.setState({ displayColorPicker15: false });
        break;
      default:
        break;
    }
  };

  handleChange = (color, input) => {
    switch (input) {
      case "color1":
        this.setState({
          color1: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`,
        });
        break;
      case "color2":
        this.setState({
          color2: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`,
        });
        break;
      case "color3":
        this.setState({
          color3: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`,
        });
        break;
      case "color4":
        this.setState({
          color4: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`,
        });
        break;
      case "color5":
        this.setState({
          color5: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`,
        });
        break;
      case "color6":
        this.setState({
          color6: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`,
        });
        break;
      case "color7":
        this.setState({
          color7: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`,
        });
        break;
      case "color8":
        this.setState({
          color8: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`,
        });
        break;
      case "color9":
        this.setState({
          color9: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`,
        });
        break;
      case "color10":
        this.setState({
          color10: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`,
        });
        break;
      case "color11":
        this.setState({
          color11: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`,
        });
        break;
      case "color12":
        this.setState({
          color12: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`,
        });
        break;
      case "color13":
        this.setState({
          color13: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`,
        });
        break;
      case "color14":
        this.setState({
          color14: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`,
        });
        break;
      case "color15":
        this.setState({
          color15: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`,
        });
        break;
      default:
        break;
    }
  };

  getlistTemplates = () => {
    getHandlerGET(HOST_API + "/api/notification/templates/")
      .then((res) => {
        if (res.status == 200) this.setState({ listTemplates: res.data });
        else console.log(" No se pudo obtener la lista");
      })
      .catch((error) => {
        if (error.response.status == 403) cerrarSesion();
      });
  };

  // loads the languages availables.
  loadLanguages() {
    getHandlerGET(HOST_API + "/api/language/")
      .then((res) => {
        if (res.status == 200) {
          var list = [];
          if (res.data.languages) {
            for (let i = 0; i < res.data.languages.length; i++) {
              list.push(JSON.stringify(res.data.languages[i]));
            }
          }
          this.setState({ languages: list });
        } else {
          console.log(" No se pudo obtener la lista");
        }
      })
      .catch((error) => {
        console.log("catch loadLanguages", error);
      });
  }

  setBotInfo(query) { 
    let cont = this.state.hideLoad;
    this.setState({ hideLoad: cont + 1 });
    getHandlerGET(HOST_API + "/api/bot/?" + query)
      .then((res) => {
        if (res.status == 200) {
          var list = {};
              console.log("res.data.bot ",res.data.bot[0]);
          if (res.data.bot) {

            const { data: { bot: vbot = [] } } = res;

              if (vbot && vbot.length > 0) {
                  const bot = res.data.bot[0];
                   console.log("bot.image_link_testing ",bot.image_link_testing);
                   console.log('BOT LISTAR ',bot);
              let temp = [
                {
                  value: bot.platform[0].id,
                  label: bot.platform[0].platform,
                },
              ];
              let options = [];
              if (bot.platforms_aditional.length > 0) {
                for (
                  let i = 0;
                  i < bot.platforms_aditional.length;
                  i++
                ) {
                  let temp1 = {
                    value: bot.platforms_aditional[i].id,
                    label: bot.platforms_aditional[i].platform,
                  };
                  options.push(temp1);
                }
              }
              const {
                voice_sender: {
                  id: id_v = "",
                  name: name_v = "",
                  value: value_v = "",
                } = { id: "", name: "", value: "" },
                email_sender: {
                  id: id_e = "",
                  name: name_e = "",
                  value: value_e = "",
                } = { id: "", name: "", value: "" },
                template,
              } = bot.bot_version[0];
              let id_t = "";
              let name_t = "";
              if (typeof template !== "undefined" && template !== null) {
                const { id, template: name } = template;
                id_t = id;
                name_t = name;
              }

              this.setState({
                botversion_id:bot.bot_version[0].botversion_id,
                voice_sender_selected: {
                  value: `${id_v}`,
                  label: `${name_v}: ${value_v}`,
                },
                email_sender_selected: {
                  value: `${id_e}`,
                  label: `${name_e}: ${value_e}`,
                },
                template_email_selected: { value: `${id_t}`, label: `${name_t}` },
                ia_activated: bot.ia_activated,
                color1: bot.chatcolor,
                color2: bot.fondo_globo_user,
                color3: bot.fondo_globo_bot,
                color4: bot.background_color,
                color5: bot.text_user_color_glob,
                color6: bot.link_user_color_glob,
                color7: bot.text_bot_color_glob,
                color8: bot.link_bot_color_glob,
                color9: bot.back_color_btn_send,
                color10: bot.text_color_btn_send,
                color11: bot.border_item,
                color12: bot.background_item,
                color13: bot.font_border_item,
                color14: bot.background_btn_close,
                color15: bot.color_btn_close,
                iconTxtBtnSend: bot.iconTxtBtnSend,
                idcontactlist: bot.bot_version[0].list_id,
                selectedOption: temp,
                selectedOption1: options,
                word_to_return:bot.word_to_return,
                word_out_queue:bot.word_out_queue,
                file_encripted:bot.file_encripted,
                wp_phone: res.data.bot[0].wp_phone,
                image_link_testing:bot.image_link_testing,
                wp_phone: bot.wp_phone,
                expected_user_vars:
                  bot.bot_version[0].expected_user_vars || [],
              });
              list = bot;
             
              this.props.setSelectedBot(bot.bot_version[0].botversion_id,bot, bot.company_id,bot.ia_activated,bot.platform,null);
              }  
              
          }

          console.log(
            "res.data.bot[0].bot_version[0].expected_user_vars ",
            res.data.bot[0].bot_version[0].expected_user_vars
          );

          this.setState({ botInfo: list }, () => {
            this.props.listAvatar(this.state.botInfo.company_id);
            this.listPlatforms();
          });
        } else {
          console.log(" No se pudo obtener la lista");
        }
        let cont = this.state.hideLoad;
        this.setState({ hideLoad: cont - 1 });
      })
      .catch((error) => {
        console.log("catch setBotInfo", error);
        if (
          !isEmpty(error) &&
          !isEmpty(error.response) &&
          error.response.status == 403
        ) {
          cerrarSesion();
        }
        let cont = this.state.hideLoad;
        this.setState({ hideLoad: cont + 1 });
      });
  }

  getTotalRepeatRow = (id) => {
    let cont = this.state.hideLoad;
    this.setState({ hideLoad: cont + 1 });
    getHandlerGET(HOST_API + "/api/listas/recors_repeated_total/?_id=" + id)
      .then((res) => {
        if (res.status == 200) {
          this.setState({ totalRepeat: res.data.total });
        }
        let cont = this.state.hideLoad;
        this.setState({ hideLoad: cont - 1 });
      })
      .catch((error) => {
        console.log("catch getTotalRepeatRow", error);
        let cont = this.state.hideLoad;
        this.setState({ hideLoad: cont - 1 });
      });
  };

  listPlatforms = () => {
    getHandlerGET(HOST_API + "/api/platforms/")
      .then((res) => {
        if (res.status == 200) {
          let result = res.data.platforms;
          let options = [];
          result.map((e) => {
            if (
              this.state.selectedOption[0] &&
              this.state.selectedOption[0].value != e.id
            ) {
              let temp = {
                value: e.id,
                label: e.platform,
              };
              options.push(temp);
            } else if (
              Object.keys(this.state.selectedOption).length > 1 &&
              this.state.selectedOption.value != e.id
            ) {
              let temp = {
                value: e.id,
                label: e.platform,
              };
              options.push(temp);
            }
          });
          this.setState({ platforms: options });
        } else console.log(" No se pudo obtener la lista");
      })
      .catch((error) => {
        console.log("Catch GET loadPlatforms:", error);
        if (error.response.status == 403) cerrarSesion();
      });
  };
  // handles all the updates from the configuration of the bot.

  saveConfigHandler = (event) => {
    event.preventDefault();
    this.setState({ detailSaved: false, hideLoad: 1 });
    let form = new FormData(event.target);

    console.log("forms:", form.values(), event.target.elements);
    this.myHelp = form;
    this.saveBotDetails(form);
    setTimeout(
      function () {
        this.setState({ hideLoad: 0 });
      }.bind(this),
      2000
    );
  };

  // handler for the bot details
  // params: "form" : [form with bot data]

  saveBotDetails(form) {
    this.setState({ detailSaved: false });
    if (this.state.detailSaved == false) {
      let options = [];
      if (this.state.selectedOption[0]) {
        let temp1 = {
          id: this.state.selectedOption[0].value,
          platform: this.state.selectedOption[0].label,
        };
        options.push(temp1);
      }
      if (this.state.selectedOption) {
        let temp1 = {
          id: this.state.selectedOption.value,
          platform: this.state.selectedOption.label,
        };
        options.push(temp1);
      }

      let options1 = [];
      if (this.state.selectedOption1) {
        for (let i = 0; i < this.state.selectedOption1.length; i++) {
          let temp = {
            id: this.state.selectedOption1[i].value,
            platform: this.state.selectedOption1[i].label,
          };
          options1.push(temp);
        }
      }

      let data_conf = {
        botversion_id: form.get("botversion_id"),
        Bots_version: form.get("botID"),
        nickname: form.get("nickname"),
        language_id: form.get("languages"),
        description: form.get("botDescription"),
        chat_filesize: form.get("chat_filesize"),
        name: form.get("botName"),
        wp_phone: this.state.wp_phone,//form.get("wp_phone"),
        wp_username: form.get("wp_username"),
        wp_token: form.get("wp_token"),
        wp_api: form.get("wp_api"),
        twp_api: form.get("twp_api"),
        twitter_api: form.get('twitter_api'),
        tw_username: form.get('tw_username'),
        tw_token: form.get('tw_token') ,  
        telegram_api: form.get('telegram_api'),     
        telegram_username: form.get('telegram_username'),
        telegram_password: form.get('telegram_token') , 
        imessage_api: form.get('twitter_api'),   
        imessage_username: form.get('imessage_username'),
        imessage_password: form.get('immessage_token'),
        gbm_username: form.get("gbm_username"),
        gbm_password: form.get("gbm_password"),
        gbmp_api: form.get("gbmp_api"),
        chatcolor: this.state.color1,
        fondo_globo_user: this.state.color2,
        fondo_globo_bot: this.state.color3,
        background_color: this.state.color4,
        text_user_color_glob: this.state.color5,
        link_user_color_glob: this.state.color6,
        text_bot_color_glob: this.state.color7,
        link_bot_color_glob: this.state.color8,
        back_color_btn_send: this.state.color9,
        text_color_btn_send: this.state.color10,
        border_item: this.state.color11,
        background_item: this.state.color12,
        font_border_item: this.state.color13,
        color_btn_close: this.state.color15,
        background_btn_close: this.state.color14,
        textsize: form.get("chat_textsize"),
        facebook_token: form.get("at_facebook"),
        vtoken_facebook: form.get("vt_facebook"),
        instagram_token: form.get("at_instagram"),
        vtoken_instagram: form.get("vt_instagram"),
        bot_position: form.get("bot_position"),
        url_agente: form.get("url_agente"),
        placeholder: form.get("placeholder"),
        msg_no_agente: form.get("msg_no_agente"),
        animacion: form.get("animacion"),
        show_window: form.get("show_window"),
        show_window_movil: form.get("show_window_movil"),
        text_btn_send: form.get("text_btn_send"),
        iconTxtBtnSend: form.get("TxtBtnSend"),
        disable_text_input: form.get("disable_text_input") == "true",
        active_history_web: form.get("active_history_web") == "true",
        list_id: form.get("contact_list"),
        platform: JSON.stringify(options),
        platform_aditional: JSON.stringify(options1),
        msg_no_connected: form.get("msj_agente"),
        email_sender: this.state.email_sender_selected.value,
        voice_sender: this.state.voice_sender_selected.value,
        template_id: this.state.template_email_selected.value,
        image_link_testing:this.state.image_link_testing,
        expected_user_vars: JSON.stringify(
          this.state.expected_user_varsSelected
        ),
        active: form.get("activeBot") ? form.get("activeBot") : false,
        file_encripted: form.get("fileEncripted") ? form.get("fileEncripted") : false,
        word_to_return:form.get("button_title3"),
        word_out_queue:form.get("button_title4"),
        main_timeout:{
          time1:form.get("timeout_time1"),
          msg1:form.get("timeout_msg1"),
          type1:form.get("timeout_type_first"),
          operator1:form.get("operator1"),
          scheme1:form.get("scheme1"),
          time2:form.get("timeout_time2"),
          msg2:form.get("timeout_msg2"),
          type2:form.get("timeout_type_second"),
          operator2:form.get("operator2"),
          scheme2:form.get("scheme2"),
          time3:form.get("timeout_time3"),
          msg3:form.get("timeout_msg3"),
          type3:form.get("timeout_type_third"),
          operator3:form.get("operator3"),
          scheme3:form.get("scheme3"),
        },
        button_title: {
          title1: form.get("button_title1"),
          title2: form.get("button_title2"),
        },
      };
      
      if (this.state.wp_phone==''||this.state.wp_phone==null||(isPossiblePhoneNumber(this.state.wp_phone) && isValidPhoneNumber(this.state.wp_phone))) {
      
        let cont = this.state.hideLoad;
      this.setState({ hideLoad: cont + 1 });
      getHandlerPUT(HOST_API + "/api/bot/admin_bot", data_conf)
        .then((res) => {
          if (res.status == 200) {
            let setResponseId = `id=${form.get("botID")}`;
            this.setBotInfo(setResponseId);
            this.props.showSuccess(i18next.t('config_msg'));
            this.forceUpdate();
          } else {
            this.props.showError(i18next.t('config_msg2'));
          }
          let cont = this.state.hideLoad;
          this.setState({ hideLoad: cont - 1 });
        })
        .catch((error) => {
          let cont = this.state.hideLoad;
          this.setState({ hideLoad: cont - 1 });
        });

    } else {
      this.props.showWarning(i18next.t('warning_msg'));
    }

      
    }
  }

  /* funcion que da informacion sobre cada uno de los dropzone y el recurso cargado*/
  infoImg(file, item) {
    var url = URL.createObjectURL(file);
    var img = new Image();
    let width = 0,
      height = 0,
      msj = i18next.t('message64'),
      msj_size = i18next.t('message62'),
      size = file.size;
    img.onload = function () {
      width = img.width;
      height = img.height;
      if (width != height) {
        switch (item) {
          case "drop3":
            this.setState({
              buttonModalImgSize: true,
              ok_modal_relacion: false,
              error_modal_relacion: true,
              msj_error_modal_relacion: msj,
            });
            break;
          case "drop4":
            this.setState({
              buttonModalImgUpdate: true,
              ok_modal_relacion_update: false,
              error_modal_relacion_update: true,
              msj_error_modal_relacion_update: msj,
            });
            break;
          default:
            break;
        }
      } else {
        switch (item) {
          case "drop3":
            this.setState({
              buttonModalImgSize: false,
              ok_modal_relacion: true,
            });
            break;
          case "drop4":
            this.setState({
              buttonModalImgUpdate: false,
              ok_modal_relacion_update: true,
            });
            break;
          default:
            break;
        }
      }
      if (size > 600000) {
        switch (item) {
          case "drop3":
            this.setState({
              buttonModalImg: true,
              error_modal_size: true,
              msj_error_modal_size: msj_size,
              ok_modal_size: false,
            });
            break;
          case "drop4":
            this.setState({
              buttonModalImgSizeUpdate: true,
              error_modal_size_update: true,
              msj_error_modal_size_update: msj_size,
              ok_modal_size_update: false,
            });
            break;
          default:
            break;
        }
      } else {
        switch (item) {
          case "drop3":
            this.setState({
              buttonModalImg: false,
              ok_modal_size: true,
            });
            break;
          case "drop4":
            this.setState({
              buttonModalImgSizeUpdate: false,
              ok_modal_size_update: true,
            });
            break;
          default:
            break;
        }
      }
    }.bind(this);
    img.src = url;
  }

  getListSender = (p) => {
    getHandlerGET(HOST_API + "/api/senders/?p=" + p)
      .then((res) => {
        if (res.status == 200) {
          this.setState({ listAllSender: res.data.senders });
          // voice_sender
        } else {
          console.log(" No se pudo obtener la lista");
        }
      })
      .catch((error) => {
        if (
          !isEmpty(error) &&
          !isEmpty(error.response) &&
          error.response.status == 403
        ) {
          cerrarSesion();
        }
      });
  };

  /* Avatar */
  // listAvatar() {
  //   let cont = this.state.hideLoad;
  //   this.setState({ hideLoad: cont + 1 });
  //   getHandlerGET(
  //     HOST_API + "/api/avatar/?company_id=" + this.state.botInfo.company_id
  //   )
  //     .then((res) => {
  //       console.log("response: ", res);
  //       if (res.status == 200)
  //         this.setState({ avatarList: res.data.data }, () =>
  //           this.listAvatarBot()
  //         );
  //       this.setState({ hideLoad: cont - 1 });
  //     })
  //     .catch((error) => {
  //       console.log("catch listAvatar", error);
  //       if (
  //         error.response &&
  //         error.response.status &&
  //         error.response.status == 403
  //       ) {
  //         cerrarSesion();
  //       }
  //       let cont = this.state.hideLoad;
  //       this.setState({ hideLoad: cont - 1 });
  //     });
  // }

  listAvatarBot() {
    
    let cont = this.state.hideLoad;
    this.setState({ hideLoad: cont + 1 });
    getHandlerGET(
      HOST_API + "/api/avatar/bot/" + this.state.botInfo.bot_version[0].bot_id
    )
      .then((res) => {
        if (res.status == 200) {
          let result = res.data.data,
            ar2 = Object.keys(result),
            ar = Object.values(result);
          let arreglo = {};
          for (let i = 0; i < ar2.length; i++) {
            if (arreglo[ar[i]]) {
              arreglo[ar[i]] += ", " + ar2[i];
            } else {
              arreglo[ar[i]] = ar2[i];
            }
          }
          this.setState({ avatarListBot: arreglo });
        }
        let cont = this.state.hideLoad;
        this.setState({ hideLoad: cont - 1 });
      })
      .catch((error) => {
        console.log("catch listAvatarBot", error);
        if (
          error.response &&
          error.response.status &&
          error.response.status == 403
        ) {
          cerrarSesion();
        }
        let cont = this.state.hideLoad;
        this.setState({ hideLoad: cont - 1 });
      });
  }

  deleteAvatar = (id) => {
    if (confirm(i18next.t('message65') + "?")) {
      let cont = this.state.hideLoad;
      this.setState({ hideLoad: cont + 1 });
      getHandlerDELETE(HOST_API + "/api/avatar/" + id)
        .then((res) => {
          if (res.status == 200) {
            this.props.listAvatar(this.state.botInfo.company_id);
            //this.listAvatar();
          } else {
            console.log(" No se pudo obtener la lista");
          }
          let cont = this.state.hideLoad;
          this.setState({ hideLoad: cont - 1 });
        })
        .catch((error) => {
          console.log("catch deleteAvatar", error);
          let cont = this.state.hideLoad;
          this.setState({ hideLoad: cont - 1 });
          if (error.response.status == 403) {
            cerrarSesion();
          }
          if (error.response.status == 400) {
            this.props.showError(
              i18next.t('avatar_msg')
            );
          }
        });
    }
  };

  saveAvatar = (event) => {
    event.preventDefault();
    this.toggleModalNewAvatar(event);
    let cont = this.state.hideLoad;
    this.setState({ hideLoad: cont + 1 });
    let currentImage = "";
    currentImage = this.state.modal_image;
    if (currentImage.length > 0) {
      let form = new FormData(event.target);
      form.append("image_avatar", this.state.modal_image[0].file);
      fileHandlerPOST(HOST_API + "/api/avatar/", form)
        .then((res) => {
          if (res.status == 200) {
            this.setState({ modal_image: !this.state.modal_image });
            this.props.listAvatar(this.state.botInfo.company_id);
            this.props.showSuccess("Configuracion actualizada");
          } else {
            this.props.showError(i18next.t('config_msg2'));
          }
          let cont = this.state.hideLoad;
          this.setState({ hideLoad: cont - 1 });
        })
        .catch((error) => {
          let cont = this.state.hideLoad;
          this.setState({ hideLoad: cont - 1 });
        });
    }
  };

  saveUrlImage = async (event) => {
    await this.props.setUrlImageS3(event);
    const {urlPath} = this.props.botReducer;
    this.state.image_link_testing = urlPath;
  };

  

  onDrop3(files) {
    this.infoImg(files[0], "drop3");
    this.setState({
      error_modal_size: false,
      error_modal_relacion: false,
      modal_image: files.map((file) => ({
        file,
        preview: URL.createObjectURL(file),
      })),
    });
  }

  onDrop4(files) {
    this.infoImg(files[0], "drop4");
    this.setState({
      error_modal_size_update: false,
      error_modal_relacion_update: false,
      modal_image_update: files.map((file) => ({
        file,
        preview: URL.createObjectURL(file),
      })),
    });
  }

  updateAvatar = (event) => {
    event.preventDefault();
    this.toggleModalUpdateAvatar(event);
    let cont = this.state.hideLoad;
    this.setState({ hideLoad: cont + 1 });
    let currentImage = "";
    currentImage = this.state.modal_image_update;
    let form = new FormData(event.target);
    let id = form.get("id_item");
    if (currentImage.length > 0) {
      form.append("image_avatar", this.state.modal_image_update[0].file);
      fileHandlerPOST(HOST_API + "/api/avatar/" + id, form)
        .then((res) => {
          if (res.status == 200) {
            this.setState({
              modal_image_update: !this.state.modal_image_update,
            });
            this.props.listAvatar(this.state.botInfo.company_id);
            this.props.showSuccess(" Configuracion actualizada");
          } else {
            this.props.showError(i18next.t('config_msg2'));
          }
          let cont = this.state.hideLoad;
          this.setState({ hideLoad: cont - 1 });
        })
        .catch((error) => {
          let cont = this.state.hideLoad;
          this.setState({ hideLoad: cont - 1 });
        });
    } else {
      fileHandlerPOST(HOST_API + "/api/avatar/" + id, form)
        .then((res) => {
          if (res.status == 200) {
            this.setState({
              modal_image_update: !this.state.modal_image_update,
            });
            this.props.listAvatar(this.state.botInfo.company_id);
            this.props.showSuccess(" Configuracion actualizada");
          } else {
            this.props.showError(i18next.t('config_msg2'));
          }
          let cont = this.state.hideLoad;
          this.setState({ hideLoad: cont - 1 });
        })
        .catch((error) => {
          let cont = this.state.hideLoad;
          this.setState({ hideLoad: cont - 1 });
        });
    }
    this.setState({ hideLoad: cont - 1 });
  };

  toggleModalNewAvatar = (event) => {
    this.setState({
      modal_image: [],
      modalAvatar: !this.state.modalAvatar,
      error_modal_relacion: false,
      msj_error_modal_size: "",
      msj_error_modal_relacion: "",
      ok_modal_relacion: false,
      ok_modal_size: false,
      buttonModalImgSize: true,
    });
  };

  toggleModalUpdateAvatar = (event, id, title) => {
    this.setState({
      modal_image_update: [],
      modalAvatar_update: !this.state.modalAvatar_update,
      idUpdate: id,
      titleUpdate: title,
      error_modal_size_update: false,
      error_modal_relacion_update: false,
      msj_error_modal_size_update: "",
      msj_error_modal_relacion_update: "",
      ok_modal_relacion_update: false,
      ok_modal_size_update: false,
    });
  };

  saveState = (event, id, state) => {
    let cont = this.state.hideLoad;
    this.setState({ hideLoad: cont + 1 });
    let data = {
      id_bot: this.state.botInfo.bot_version[0].bot_id,
      status: state,
    };
    getHandlerPOST(HOST_API + "/api/avatar/" + id + "/status/", data)
      .then((res) => {
        if (res.status == 200) {
          this.props.listAvatar(this.state.botInfo.company_id);
          this.props.showSuccess(" estado actualizado");
        } else {
          this.props.showError(i18next.t('config_msg2'));
        }
        let cont = this.state.hideLoad;
        this.setState({ hideLoad: cont - 1 });
      })
      .catch((error) => {
        let cont = this.state.hideLoad;
        this.setState({ hideLoad: cont - 1 });
      });
  };

  getAllLists = () => {
    getHandlerGET(
      HOST_API +
        "/api/listas/bycompany?id_company=" +
        getUserInfoLocalStorage().company_id
    )
      .then((res) => {
        if (res.status == 200) {
          this.setState({ contact_lists: res.data });
        }
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status &&
          error.response.status == 403
        ) {
          cerrarSesion();
        }
      });
  };

  handleChangePlatformsMain = (selectedOption) =>
    this.setState({ selectedOption }, () => this.listPlatforms());
  handleChangePlatforms = (selectedOption1) =>
    this.setState({ selectedOption1 }, () => this.listPlatforms());
  handleChangeSelectVoiceSender = (option) =>
    this.setState({ voice_sender_selected: option });
  handleChangeSelectEmailSender = (option) =>
    this.setState({ email_sender_selected: option });
  handleChangeSelectTemplateEmail = (option) =>
    this.setState({ template_email_selected: option });

  callEmoji = (code, k, type) => {
    let text = $("#input-" + k).val();
    text += ` ${code}`;
    $("#input-" + k).val(text);
  };

  handleChangeSessionVar = (newValue, actionMeta) => {
    if (newValue != null && newValue.length > 0) {
      const expected_user_varsSelected = newValue.map((item) => item.value);
      this.setState({ expected_user_varsSelected });
      this.forceUpdate();
    } else {
      // La variable `expected_user_varsSelected` debe tener un string vacio dentro del array
      // que sirve como validación en `bot-configuration-layout.js`, ya que el usuario a eliminado
      // los items del component.
      this.setState({ expected_user_varsSelected: [""] });
    }
  };

  getScheme = (scheme,input) => {
    
    if (scheme !=''){
      const {timeout} = this.props.botReducer;
      let new_timeout = timeout;

      switch (input) {
        case "scheme1":
          new_timeout["validScheme1"] = false;
          break;
        case "scheme2":
          new_timeout["validScheme2"] = false;
          break;
        case "scheme3":
          new_timeout["validScheme3"] = false;
          break;
        }
        this.props.setChangeTimeoutConfi(new_timeout);
        this.getOperatorsList(scheme,input);
      
    }
        
}

getOperator = (operator,input) => {
  const {timeout} = this.props.botReducer;
  let new_timeout = timeout;
  
  const {bot} = this.props.botReducer;
  let new_bot = bot;
  let main_timeout = new_bot['main_timeout']
    

  if (operator !=''){
    switch (input) {
      case "operator1":
        main_timeout["operator1"] = operator;
        new_timeout["validOperator1"] = false;
        break;
      case "operator2":
        main_timeout["operator2"] = operator;
        new_timeout["validOperator2"] = false;
        break;
      case "operator3":
        main_timeout["operator3"] = operator;
        new_timeout["validOperator3"] = false;
        break;
      }
      this.props.setChangeTimeoutConfi(new_timeout);
      this.props.setChangeConfig("main_timeout",main_timeout);
    }       
}

getOperatorsList (idScheme,input) {
  getHandlerGET("/api/scheme/operator/?scheme_id=" + idScheme)
      .then((res) =>{
          if(res.status==200){
              if(res.data.operators){
                  if(res.data.operators.length > 0){
                      var items = [...res.data.operators];
                      //a = array con resultados filtrados
                      let a = items.filter(e => e.title && e.title.length > 0);
                      var list = [];
                      for(let i=0; i<a.length;i++) {
                          let temp ={
                              'title':a[i].title,
                              'id': a[i].id 
                          }
                          list.push(temp);
                      }
                      const {timeout} = this.props.botReducer;
                      let new_timeout = timeout;
                      if(input == "scheme1")
                        new_timeout["operatorsList1"] = list;
                      else if(input == "scheme2")
                        new_timeout["operatorsList2"] = list;
                      else if("scheme3")
                        new_timeout["operatorsList3"] = list;

                        this.props.setChangeTimeoutConfi(new_timeout);
                      
                  }
              }
          }else{
              console.log(" No se pudo obtener la lista");
          }
      }).catch((error)=> {
          if (error.response.status == 403)
              cerrarSesion();
      });
}

validar = (input) => {
  const {timeout} = this.props.botReducer;
  let new_timeout = timeout;

  const {bot} = this.props.botReducer;
  let new_bot = bot;
  let main_timeout = new_bot['main_timeout']
  
  if(input == "scheme1")
  {
    if(main_timeout["scheme1"] == '')
      new_timeout["validScheme1"] = true;
    else
      new_timeout["validScheme1"] = false;

  }
  else if(input == "scheme2")
  {
    if(main_timeout["scheme2"] == '')
      new_timeout["validScheme2"] = true;
    else
      new_timeout["validScheme2"] = false;

  }
  else if(input == "scheme3")
  {
    if(main_timeout["scheme3"] == '')
      new_timeout["validScheme3"] = true;
    else
      new_timeout["validScheme3"] = false;

  }
  else if(input == "operator1")
  {
    if(main_timeout["operator1"] == '')
      new_timeout["validOperator1"] = true;
    else
      new_timeout["validOperator1"] = false;

  }
  else if(input == "operator2")
  {
    if(main_timeout["operator2"] == '')
      new_timeout["validOperator2"] = true;
    else
      new_timeout["validOperator2"] = false;

  }
  else if(input == "operator3")
  {
    if(main_timeout["operator3"] == '')
      new_timeout["validOperator3"] = true;
    else
      new_timeout["validOperator3"] = false;

  }
  this.props.setChangeTimeoutConfi(new_timeout);
}

  render() {
    return (
      <div>
        <ModalLoad hideLoad={this.state.hideLoad} />
        <BotConfigurationLayout        
          listTemplates={this.state.listTemplates}
          listAllSender={this.state.listAllSender}
          voice_sender_selected={this.state.voice_sender_selected}
          email_sender_selected={this.state.email_sender_selected}
          template_email_selected={this.state.template_email_selected}
          handleChangeSelectVoiceSender={this.handleChangeSelectVoiceSender}
          handleChangeSelectEmailSender={this.handleChangeSelectEmailSender}
          handleChangeSelectTemplateEmail={this.handleChangeSelectTemplateEmail}
          getlistTemplates={this.getlistTemplates}
          ia_activated={this.state.ia_activated}
          saveConfigHandler={this.saveConfigHandler}
          botId={this.state.botId}
          botversion_id={this.state.botversion_id}
          botInfo={this.state.botInfo}
          languages={this.state.languages}
          iconTxtBtnSend={this.state.iconTxtBtnSend}
          onDrop1={this.onDrop1}
          onDrop2={this.onDrop2}
          onDrop3={this.onDrop3}
          modal_image={this.state.modal_image}
          handleClick={this.handleClick}
          handleClose={this.handleClose}
          handleChange={this.handleChange}
          getScheme={this.getScheme}
          getOperator={this.getOperator}
          validar={this.validar}
          getOperatorsList={this.getOperatorsList}
          displayColorPicker1={this.state.displayColorPicker1}
          displayColorPicker2={this.state.displayColorPicker2}
          displayColorPicker3={this.state.displayColorPicker3}
          displayColorPicker4={this.state.displayColorPicker4}
          displayColorPicker5={this.state.displayColorPicker5}
          displayColorPicker6={this.state.displayColorPicker6}
          displayColorPicker7={this.state.displayColorPicker7}
          displayColorPicker8={this.state.displayColorPicker8}
          displayColorPicker9={this.state.displayColorPicker9}
          displayColorPicker10={this.state.displayColorPicker10}
          displayColorPicker11={this.state.displayColorPicker11}
          displayColorPicker12={this.state.displayColorPicker12}
          displayColorPicker13={this.state.displayColorPicker13}
          displayColorPicker14={this.state.displayColorPicker14}
          displayColorPicker15={this.state.displayColorPicker15}
          color1={this.state.color1}
          color2={this.state.color2}
          color3={this.state.color3}
          color4={this.state.color4}
          color5={this.state.color5}
          color6={this.state.color6}
          color7={this.state.color7}
          color8={this.state.color8}
          color9={this.state.color9}
          color10={this.state.color10}
          color11={this.state.color11}
          color12={this.state.color12}
          color13={this.state.color13}
          color14={this.state.color14}
          color15={this.state.color15}
          buttonModalImgUpdate={this.state.buttonModalImgUpdate}
          buttonModalImgSizeUpdate={this.state.buttonModalImgSizeUpdate}
          buttonModalImg={this.state.buttonModalImg}
          buttonModalImgSize={this.state.buttonModalImgSize}
          error_ventana_size={this.state.error_ventana_size}
          error_ventana_relacion={this.state.error_ventana_relacion}
          msj_error_ventana_size={this.state.msj_error_ventana_size}
          msj_error_ventana_relacion={this.state.msj_error_ventana_relacion}
          error_header_size={this.state.error_header_size}
          error_header_relacion={this.state.error_header_relacion}
          msj_error_header_size={this.state.msj_error_header_size}
          msj_error_header_relacion={this.state.msj_error_header_relacion}
          error_modal_size={this.state.error_modal_size}
          error_modal_relacion={this.state.error_modal_relacion}
          msj_error_modal_size={this.state.msj_error_modal_size}
          msj_error_modal_relacion={this.state.msj_error_modal_relacion}
          ok_header_relacion={this.state.ok_header_relacion}
          ok_header_size={this.state.ok_header_size}
          ok_ventana_relacion={this.state.ok_ventana_relacion}
          ok_ventana_size={this.state.ok_ventana_size}
          ok_modal_relacion={this.state.ok_modal_relacion}
          ok_modal_size={this.state.ok_modal_size}
          toggle={this.toggle}
          avatarList={this.props.companyReducer.avatarList}
          saveUrlImage={this.saveUrlImage}
          deleteAvatar={this.deleteAvatar}
          toggleModalNewAvatar={this.toggleModalNewAvatar}
          saveAvatar={this.saveAvatar}
          modalAvatar={this.state.modalAvatar}
          avatarListBot={this.state.avatarListBot}
          toggleModalUpdateAvatar={this.toggleModalUpdateAvatar}
          modalAvatar_update={this.state.modalAvatar_update}
          ok_modal_relacion_update={this.state.ok_modal_relacion_update}
          ok_modal_size_update={this.state.ok_modal_size_update}
          error_modal_size_update={this.state.error_modal_size_update}
          error_modal_relacion_update={this.state.error_modal_relacion_update}
          msj_error_modal_size_update={this.state.msj_error_modal_size_update}
          msj_error_modal_relacion_update={
            this.state.msj_error_modal_relacion_update
          }
          updateAvatar={this.updateAvatar}
          modal_image_update={this.state.modal_image_update}
          onDrop4={this.onDrop4}
          idUpdate={this.state.idUpdate}
          titleUpdate={this.state.titleUpdate}
          saveState={this.saveState}
          contact_lists={this.state.contact_lists}
          getAllLists={this.getAllLists}
          idcontactlist={this.state.idcontactlist}
          getTotalRepeatRow={this.getTotalRepeatRow}
          totalRepeat={this.state.totalRepeat}
          platforms={this.state.platforms}
          selectedOption={this.state.selectedOption}
          selectedOption1={this.state.selectedOption1}
          handleChangePlatformsMain={this.handleChangePlatformsMain}
          handleChangePlatforms={this.handleChangePlatforms}
          callEmoji={this.callEmoji}
          expected_user_vars={this.state.expected_user_vars || []}
          expected_user_varsSelected={
            this.state.expected_user_varsSelected || []
          }
          handleChangeSessionVar={this.handleChangeSessionVar}
          setValue={this.setValue}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ botReducer,boxReducer,homeReducer,companyReducer, componentMountReducer,loadStateReducer }) => {
  return { botReducer,boxReducer,homeReducer,companyReducer, componentMountReducer,loadStateReducer };
 };
 
 const mapDispatchToProps = {
    listAvatar,
    showSuccess,
    showError,
    loading,
    showWarning,
    hideAlert,
    setTitle,
    setSelectedBot,
    setListIntent,
    setUrlImageS3,
    setChangeConfig,
    setChangeTimeoutConfi
 };
 
 export default connect(mapStateToProps, mapDispatchToProps)(BotConfiguration);