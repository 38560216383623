import React, { Component } from 'react';
import SMSMLayout from './sms-layout-modal';
import axios from 'axios';
import qs from 'qs';
import {  ToastsStore } from 'react-toasts';

import { getHandlerPOST } from '../../../../utils/handlersApi'
import { cerrarSesion } from '../../../../utils/commonFunction'

class SMSModal extends Component {
    
    state = {
        text_blocks: {'bl_0': ""},
        nodeId: "",
        title: "",
        selection:  null,
        range: null,
        error_limit_item:false,
        isOpenUrl:false,
        inputStatement:'',
        keyword_valid:true
    }
 
    constructor(props){
        super(props)
    }

    componentWillMount(){
        var auth=JSON.parse(localStorage.getItem('Authorization'));
        if(!auth || !auth.access_token)
          this.props.history.push("/login");
    }

  
    /* 
     *  updateOperator
     *  actualiza título y/o visible (si activa el operador dinámico)
     * @titleOperator = string
     * @visible _ int
    */
    updateOperator = (titleOperator='',visible='') => {
        let id = this.props.modal.node.extras.operatorDynamic;
        let data ={
            title: titleOperator,
            visible:visible
        }
        getHandlerPOST(HOST_API +'/api/operator/dynamic_operator/'+id,data)
            .then((res)=>{
                if(res.status==200){
                }else{
                    console.log('error título actualización')
                }
            }).catch((error)=>{
                console.log('catch updateOperator',error);
                if (error.response && error.response.status && error.response.status == 403){
                    cerrarSesion();
                }
            });
    }


    toggleModuleModal = () => {  
        let celular = $('#TMM-input-celular').val();
        let mensaje = $('#TMM-input-mensaje').val();
        this.props.modal.node.extras['celular'] = celular;
        this.props.modal.node.extras['mensaje'] = mensaje;
        this.forceUpdate();
        this.props.updateSchema();  
        this.props.toggleModuleModal('sms');
    }

    saveStatement = (operator) => {
        const data = {
            operator_id: operator,
            text: "",
            name: ""
        };

        var auth=JSON.parse(localStorage.getItem('Authorization'));
        var headers={
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer '+ auth.access_token 
        }

        return axios({
            method:'post',
            url:HOST_API +'/api/scheme/statement/',
            data:qs.stringify(data),
            headers:headers
        });
    }

    updateStatement = (statement, text, name) => {
        const data = {
            id: statement,
            text: text,
            name: name
        };

        var auth=JSON.parse(localStorage.getItem('Authorization'));
        var headers={
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer '+ auth.access_token 
        }

        return axios({
            method:'put',
            url:HOST_API +'/api/scheme/statement/',
            data:qs.stringify(data),
            headers:headers
        });
    }

    updateItem = (k,text) => {
        if(k == 'celular' || k == 'mensaje'){
            /**
             * **NOTE** El opertador SMS, no permite más de 160 caracteres.
             * esta validación, esta limitando la longitud en las etiqueta y el texto.
             * pero, se debe tener presente que, el `valor` de las etiqueta no está siendo limitado
             * y esto, puede causar inconvenientes, si el valor de la etiqueta hace que supere
             * los 160 caracteres permitivo por SMS. 
             */
            if (text.length < 160) {
                this.props.modal.node.extras[k] = text;
            } else {
                ToastsStore.warning("¡Sólo permite 160 carácteres");
            }
        }else{
            text = k;
            k='time';
            this.props.modal.node.extras[k] = text;
        }
        this.forceUpdate();
    }

    closeModal = () => {
        this.props.closeModal('m13');
        this.forceUpdate();
    }
    
       //Emoji
    callEmoji = (code,k,type)=>{
        let text = this.props.modal.node.extras[k];
        text += ` ${code}`;
        this.updateItem(k,text)
    }

    toggleModalUrl = (k) => {
        this.setState({ 
            isOpenUrl: !this.state.isOpenUrl,
            inputStatement:k
        }); 
    }

    getUrl = (k,url) => {
        this.setState({ isOpenUrl: !this.state.isOpenUrl}); 
        let text = this.props.modal.node.extras[k];
            text += ` ${url}`;
        this.updateItem(k,text)
    }

    showMetion = (e) =>{
        this.props.modal.node.extras.config = event.target.value;
        if(this.props.modal.node.extras.config == '0'){
            this.props.modal.node.extras.time = '0';
        }
        else
            this.props.modal.node.extras.time = '';
        this.forceUpdate();
    }

    changeTypeTime = () => {
        this.props.modal.node.extras.typetime = event.target.value;
    }
    isKeywordValid = (val) => {        
        this.setState({keyword_valid:val}, ()=>{
            this.forceUpdate();
        })
    }

    render() {
        return (
          <SMSMLayout 
          toggleModuleModal={this.toggleModuleModal}
          addNewTextBlock={this.addNewTextBlock}
          removeTextBlock={this.removeTextBlock}
          isOpen={this.props.modal.isOpen} 
          nodeId={this.state.nodeId} 
          title={this.state.title} 
          updateItem={this.updateItem}
          botversion_id={this.props.botversion_id}
          operator_id={this.props.modal.node.extras.dbId}
          titleOperator = {this.props.modal.node.extras.titleOperator || ''}
          error_limit_item={this.state.error_limit_item}
          company_id={this.props.company_id}
          botId ={this.props.botId}
          getTitleOperator = {this.getTitleOperator}
          closeModal ={this.closeModal}
          varList={this.props.varList}
          mensaje={this.props.modal.node.extras.mensaje || ''}
          celular={this.props.modal.node.extras.celular || ''}
          callEmoji = {this.callEmoji}
          time = {this.props.modal.node.extras.time || ''}
          isKeywordValid={this.isKeywordValid}
          isOpenUrl = {this.state.isOpenUrl}
          toggleModalUrl = {this.toggleModalUrl}
          getUrl = {this.getUrl}
          inputStatement={this.state.inputStatement}
          showMetion = {this.showMetion}
          config = {this.props.modal.node.extras.config}
          changeTypeTime = {this.changeTypeTime}
          typeTime = {this.props.modal.node.extras.typetime || ''}
          />
        )
    }
}

export default SMSModal
