import { Redirect } from "react-router-dom";
import React from "react";
import { BOT_ACTIONS_TYPES } from '../types/botActionsTypes';
import { HOME_ACTIONS_TYPES } from '../types/homeActionsTypes';
import { MODAL_LOAD_ACTIONS_TYPES } from "../types/loadStateActionsTypes";
import { logOut } from "./loginActions";
import { getHandlerGET,getHandlerPOST,getHandlerDELETE,getHandlerPUT,getHandlerPOSTJson,getHandlerPUTJson,fileHandlerPOST } from "../../utils/handlersApi";
import { cerrarSesion, UTCFormatter, isEmpty } from "../../utils/commonFunction";
import { showSuccess, loading, showWarning, hideAlert,showError } from './loadStateActions';
import { find, propEq } from "ramda";
import qs from 'qs';
import moment from "moment";
import { message } from "antd";
const utf8 = require('utf8');

import {listAvatar} from "./companyActions"; 
import {getAllbots} from "./homeActions"; 
import {handleSearching} from './analyticsActions';

import axios from 'axios';
import { platform } from "chart.js";


/** */ 
export const getBot = (botid) => async (dispatch, getState) => {
    //let cont = this.state.hideLoad;
    //this.setState({ hideLoad: cont + 1 });
    await getHandlerGET(HOST_API + '/api/bot/?id=' + botid)
        .then(async (res) => {
            if (res.status == 200) {
                try {
                    const { data: { bot: vbot = [] } } = res;
                   
                    if (vbot && vbot.length > 0) {
                        const bot = res.data.bot[0];
                        console.log("botbotbot ",bot);
                        await dispatch(
                            setSelectedBot(bot.bot_version[0].botversion_id,bot, bot.company_id,bot.ia_activated,bot.platform)
                        );
                    }
                    else
                    {
                        dispatch({
                            type: MODAL_LOAD_ACTIONS_TYPES.hideLoad(),
                        });
                    }
                } catch (error) {
                    const { response: { status } = { status } } = error;
                    if (status && status == 403) {
                      logOut();
                      cerrarSesion();
                    }
                }
            }
        }).catch((error) => {
            const { response: { status } = { status } } = error;
            if (status && status == 403) {
              logOut();
              cerrarSesion();
            }
        });
}

/**
 * EXPORTAR BOT
 */
 export const getExportBot = (bot_version=null) => {
    console.log("getExportBot ",getExportBot);
     getHandlerGET(HOST_API + '/api/bot/export?version_id=' + bot_version)
    .then(async (res) => {
        if (res.status == 200) {
            try {

                const url = res.data.link
               
                let titleBot = url;
                    titleBot = titleBot.split('/');

                const link = document.createElement('a')
                
                link.href= url
                link.setAttribute('download',titleBot[titleBot.length-1])
                document.body.appendChild(link)
                link.click();

            } catch (error) {
                const { response: { status } = { status } } = error;
                if (status && status == 403) {
                  logOut();
                  cerrarSesion();
                }
            }
        }
    }).catch((error) => {
        const { response: { status } = { status } } = error;
        if (status && status == 403) {
          logOut();
          cerrarSesion();
        }
    });
}


/*DUPLICAR BOT* */
export const getDuplicateBot = (bot_version=null,name='') => async (dispatch, getState) =>  {
    
    const data = {
        version_id: bot_version
     }

    let url = HOST_API + '/api/bot/duplicate'
    await getHandlerPOST(url, data)
        .then(async (res)=>{
            if(res.status==200){
                console.log(res);

                dispatch({
                    type: MODAL_LOAD_ACTIONS_TYPES.showSuccess(),
                    payload: '¡Bot duplicado exitosamente!'
                });

                dispatch(handleSearching(name))

                dispatch(getAllbots(1));
                
                /*setTimeout(()=>{
                    window.location.reload();
                },1000);*/

                /*if (res.data.bot.length > 0){
                    dispatch({
                        type: MODAL_LOAD_ACTIONS_TYPES.hideLoad(),
                    });
                    dispatch({
                        type: MODAL_LOAD_ACTIONS_TYPES.showSuccess(),
                        payload: '¡Nuevo bot creado exitosamente!'
                    });
                    setTimeout(()=>{
                        dispatch({
                            type: HOME_ACTIONS_TYPES.toggleModalNewBot()
                        });
                        dispatch({
                            type: MODAL_LOAD_ACTIONS_TYPES.hideLoad(),
                        });
                        RedirectonSuccess(`/schemas/${res.data.bot[0].id}`);
                    },1200);                    
                }*/
            }
          
        })
        .catch((error) => {
            dispatch({
                type: MODAL_LOAD_ACTIONS_TYPES.showWarning(),
                payload: '¡Error al crear el bot, intente nuevamente!'
            });
            const { response: { status } = { status } } = error; 
            if (status  && status == 403){
                logOut();
                cerrarSesion();
            }
        });
}

/*IMPORT BOT* */
export const getLoadBot = (bot_version=null) => async (dispatch, getState) =>  {
        console.log("FILE ",bot_version);
        console.log("bot_version[0].name ",bot_version[0]);

        let name = bot_version[0].name
        let form = new FormData();
        form.append('bot', bot_version[0])
        //form.append('bot', bot_version.target);

        //let form = new FormData(event.target);
        //form.append('botversion_id', this.state.botversion_id);

    let url = HOST_API + '/api/bot/import'
    fileHandlerPOST(url, form)
        .then((res)=>{
            if(res.status==200){
                console.log(res);
                dispatch({
                    type: MODAL_LOAD_ACTIONS_TYPES.showSuccess(),
                    payload: '¡Bot importado exitosamente!'
                });

                dispatch(handleSearching(name))

                dispatch(getAllbots(1));
            }
          
        })
        .catch((error) => {
            dispatch({
                type: MODAL_LOAD_ACTIONS_TYPES.showWarning(),
                payload: '¡Error al crear el bot, intente nuevamente!'
            });
            const { response: { status } = { status } } = error; 
            if (status  && status == 403){
                logOut();
                cerrarSesion();
            }
        });
}


/*EXPORTAR ESQUEMA */
export const getExportScheme = (bot_version=null) => {
    getHandlerGET(HOST_API + '/api/scheme/export?id=' + bot_version)
   .then(async (res) => {
       if (res.status == 200) {
           try {

               const url = res.data.link
              
               let titleBot = url;
                   titleBot = titleBot.split('/');

               const link = document.createElement('a')
               
               link.href= url
               link.setAttribute('download',titleBot[titleBot.length-1])
               document.body.appendChild(link)
               link.click();

           } catch (error) {
               const { response: { status } = { status } } = error;
               if (status && status == 403) {
                 logOut();
                 cerrarSesion();
               }
           }
       }
   }).catch((error) => {
       const { response: { status } = { status } } = error;
       if (status && status == 403) {
         logOut();
         cerrarSesion();
       }
   });
}

/*DUPLICAR ESQUEMA */
export const getDuplicateScheme = (bot_version=null, id_scheme='') => async (dispatch, getState) =>  {
    
    const data = {
        id: bot_version
     }

    let url = HOST_API + '/api/scheme/duplicate'
     getHandlerPOST(url, data)
        .then((res)=>{
            if(res.status==200){
                console.log(res);

                dispatch({
                    type: MODAL_LOAD_ACTIONS_TYPES.showSuccess(),
                    payload: '¡Esquema duplicado exitosamente!'
                });
                
                setTimeout(()=>{
                    window.location.reload();
                },1200);
                
                /*if (res.data.bot.length > 0){
                    dispatch({
                        type: MODAL_LOAD_ACTIONS_TYPES.hideLoad(),
                    });
                    dispatch({
                        type: MODAL_LOAD_ACTIONS_TYPES.showSuccess(),
                        payload: '¡Nuevo bot creado exitosamente!'
                    });
                    setTimeout(()=>{
                        dispatch({
                            type: HOME_ACTIONS_TYPES.toggleModalNewBot()
                        });
                        dispatch({
                            type: MODAL_LOAD_ACTIONS_TYPES.hideLoad(),
                        });
                        RedirectonSuccess(`/schemas/${res.data.bot[0].id}`);
                    },1200);                    
                }*/
            }
          
        })
        .catch((error) => {
            dispatch({
                type: MODAL_LOAD_ACTIONS_TYPES.showWarning(),
                payload: '¡Error al crear el bot, intente nuevamente!'
            });
            const { response: { status } = { status } } = error; 
            if (status  && status == 403){
                logOut();
                cerrarSesion();
            }
        });
}

/*IMPORT ESQUEMA* */
export const getLoadSchema = (bot_version=null) => {
    console.log("bot_version ",bot_version);
    console.log("FILE ",bot_version.archivoBot);
    console.log("bot_version[0].name ",bot_version.archivoBot[0]);

    let form = new FormData();
    form.append('scheme', bot_version.archivoBot[0])
    form.append('version_id', bot_version.idschema)
    //form.append('bot', bot_version.target);

    //let form = new FormData(event.target);
    //form.append('botversion_id', this.state.botversion_id);

let url = HOST_API + '/api/scheme/import'
fileHandlerPOST(url, form)
    .then((res)=>{
        if(res.status==200){
            console.log(res);
            dispatch({
                type: MODAL_LOAD_ACTIONS_TYPES.showSuccess(),
                payload: '¡Esquema importado exitosamente!'
            });
            
            setTimeout(()=>{
                window.location.reload();
            },1200);
        }
      
    })
    .catch((error) => {
        dispatch({
            type: MODAL_LOAD_ACTIONS_TYPES.showWarning(),
            payload: '¡Error al crear el bot, intente nuevamente!'
        });
        const { response: { status } = { status } } = error; 
        if (status  && status == 403){
            logOut();
            cerrarSesion();
        }
    });
}


/** */
export const setSelectedBot = (botVersion_id,bot,company,ia,platform,idSchema) => (dispatch, getState) => {
    //BotVersion_id del bot seleccionado
    dispatch({
        type: BOT_ACTIONS_TYPES.setBotVersionId(),
        payload: botVersion_id
    });

     //IdSchema del bot seleccionado
     dispatch({
        type: BOT_ACTIONS_TYPES.setIdSchema(),
        payload: idSchema
    });
   
     //Bot Seleccionado
     dispatch({
        type: BOT_ACTIONS_TYPES.setBot(),
        payload: bot
    });

     //Compañia del bot seleccionado
     dispatch({
        type: BOT_ACTIONS_TYPES.setCompanyId(),
        payload: company
    });

    //Lista los AVATARS de la Compañia del bot seleccionado
    dispatch(listAvatar(company));

     //Selecccionar IA
     dispatch({
        type: BOT_ACTIONS_TYPES.setIa_activated(),
        payload: ia
    });

     //Seleccionar plataforma del bot
     dispatch({
        type: BOT_ACTIONS_TYPES.setPlatform(),
        payload: platform
    });
}

export const setSelectedScheme = (schema) => (dispatch, getState) => {
    //Schema del bot seleccionado
    dispatch({
        type: BOT_ACTIONS_TYPES.setSchema(),
        payload: schema
    });
}

export const setListIntent = (id="") => async (dispatch, getState) =>  {
    
    dispatch({
        type: MODAL_LOAD_ACTIONS_TYPES.loading(),
    });

    let url;
    
    if(id == ""){
        let {botVersionId} = getState().botReducer;
         url = HOST_API + '/api/ia/intents?botversion_id=' + botVersionId;
    }else{
        let botVersionId = id;
        url = HOST_API + '/api/ia/intents?botversion_id=' + botVersionId;
    }
    
    await getHandlerGET(url)
    .then((res)=>{
        if(res.status==200){
            if(res.data){

            let listIntent = res.data.intents.map((intent) => {
              let temp = {
                ...intent,
                name: intent.display_name,
              };
              return temp;
            });

            dispatch({
                type: BOT_ACTIONS_TYPES.setListIntent(),
                payload: listIntent
            });
            
            if(listIntent.length > 0){

                dispatch({
                    type: BOT_ACTIONS_TYPES.setShowEdition(),
                    payload: true
                });
    
                dispatch({
                    type: BOT_ACTIONS_TYPES.setShowConfig(),
                    payload: false
                });

            }else{
                
                dispatch({
                    type: BOT_ACTIONS_TYPES.setShowEdition(),
                    payload: false
                });

                dispatch({
                    type: BOT_ACTIONS_TYPES.setShowEditionEntity(),
                    payload: false
                });

                dispatch({
                    type: BOT_ACTIONS_TYPES.setShowConfig(),
                    payload: true
                });
                
            }

            dispatch({
                type: MODAL_LOAD_ACTIONS_TYPES.hideLoad(),
            });
 
        }
          } else console.log(" No se pudo recuperar la lista de modulos");
        
        })
        .catch((error) => {
            const { response: { status } = { status } } = error;
            if (status && status == 403) {
              logOut();
              cerrarSesion();
            }
            
            dispatch({
                type: MODAL_LOAD_ACTIONS_TYPES.hideLoad(),
            });

        });
}

export const setChangeState = () => async (dispatch, getState) =>  {

    const {showEdition,showConfig,showEditionEntity} = getState().botReducer;
    

    dispatch({
        type: BOT_ACTIONS_TYPES.setShowEdition(),
        payload: !showEdition
    });

    dispatch({
        type: BOT_ACTIONS_TYPES.setShowConfig(),
        payload: !showConfig
    });

    dispatch({
        type: BOT_ACTIONS_TYPES.setShowEditionEntity(),
        payload: false
    });

}

export const setChangeHideEntity = () => async (dispatch, getState) =>  {
    
  
    //dispatch(showError("ERRRRRRRRRRRRRR"));
    //const {showEdition,showConfig,showEditionEntity} = getState().botReducer;

    dispatch({
        type: BOT_ACTIONS_TYPES.setShowEdition(),
        payload: true
    });

    dispatch({
        type: BOT_ACTIONS_TYPES.setShowConfig(),
        payload: false
    });

    dispatch({
        type: BOT_ACTIONS_TYPES.setShowEditionEntity(),
        payload: false
    });




}

export const setChangeStateEntity = () => async (dispatch, getState) =>{
    
    const {showEdition} = getState().botReducer;

    dispatch({
        type: BOT_ACTIONS_TYPES.setShowQuestions(),
        payload: []
    });

    dispatch({
        type: BOT_ACTIONS_TYPES.setShowAnswers(),
        payload:[]
    });


    dispatch({
        type: BOT_ACTIONS_TYPES.setNameModule(),
        payload: ''
    });

    dispatch({
        type: BOT_ACTIONS_TYPES.setIdAnswers(),
        payload: ''
    });


    dispatch({
        type: BOT_ACTIONS_TYPES.setShowEdition(),
        payload: !showEdition
    });

    dispatch({
        type: BOT_ACTIONS_TYPES.setShowEditionEntity(),
        payload: true
    });
}

export const intentFormSubmit = (event) => async (dispatch, getState) =>{
    event.preventDefault();
    let form = new FormData(event.target);
    console.log("form ",form);
    if(form.get('id')){  // is editing
        console.log("UPODATE");
        dispatch(updateIntent(form));
    }else { // is creating a new
        console.log("CREATE");
        dispatch(createIntent(form));
    }
}

export const listUAQuestion = () => async (dispatch, getState) => {

    let {botVersionId} = getState().botReducer;
    
    getHandlerGET(HOST_API +"/api/ia/questions?id="+botVersionId).
        then((res) =>{
            if(res.status==200){
                var list = [];
                
                if(res.data.data.length > 0){
                    res.data.data.map ((q) =>{
                        let temp = {}
                        temp.id = q.id;
                        temp.question = q.question
                        temp.repetitions = q.repetitions
                        list.push(temp)
                    } )
                }
                
                dispatch({
                    type: BOT_ACTIONS_TYPES.setListIntent(),
                    payload: list
                });

            }else{
                console.log(" No se pudo recuperar la lista de preguntas sin responder");
            }
        }).catch((error)=> {
            console.log(error);
        });

}

export const getIntentInfo = (id) => async (dispatch, getState) =>{
   
    const {botVersionId} = getState().botReducer;
    
    console.log("id ",id);

    dispatch({
        type: BOT_ACTIONS_TYPES.setShowEdition(),
        payload: false
    });

    //let cont = this.state.hideLoad; this.setState({ hideLoad: cont+1});
    //this.myHelp = id;
    
    getHandlerGET(HOST_API +"/api/ia/intents?botversion_id="+botVersionId+"&intent_id="+escape(id)).
        then((res) =>{
            if(res.status==200){
                console.log("res.data ",res.data);
                /*let aux = {
                    id : this.myHelp,
                    name : res.data.intent.intent_name,
                    questions : res.data.intent.questions,
                    answers : res.data.intent.answers,
                    operator_id: res.data.intent.operator_id
                };*/
                //this.setState({ currentIntent: aux});
                //this.showEntity();
                
                dispatch({
                    type: BOT_ACTIONS_TYPES.setNameModule(),
                    payload: res.data.intent.intent_name
                });

                dispatch({
                    type: BOT_ACTIONS_TYPES.setShowQuestions(),
                    payload: res.data.intent.questions
                });
            
                dispatch({
                    type: BOT_ACTIONS_TYPES.setShowAnswers(),
                    payload: res.data.intent.answers
                });

                dispatch({
                    type: BOT_ACTIONS_TYPES.setIdAnswers(),
                    payload: id
                });

                dispatch({
                    type: BOT_ACTIONS_TYPES.setShowEditionEntity(),
                    payload: true
                });


            }else{
                console.log(" No se pudo recuperar la informacion del modulo");
            }

        }).catch((error)=> {
            console.log(error);
        });
}

function convertirCadenaAArreglo(cadena) {
    if (cadena.includes(',')) {
        return cadena.split(',');
    } else {
        return [cadena];
    }
}

export const createIntent = (form) => async (dispatch, getState) =>{
    const {botVersionId} = getState().botReducer;
    if(form.get('name')==''){
        alert(i18next.t('message169'))
        return null
    }
 
    // Convertir cadenas a arrays según la presencia de comas
    let questions = convertirCadenaAArreglo(form.get('question'));
    let answers = convertirCadenaAArreglo(form.get('answer'));

    console.log("form.get('name') ", form.get('name'));
    console.log("form.get('question') ", questions);
    console.log("form.get('answer') ", answers);

    let data = {
        name: form.get('name'),
        questions: JSON.stringify(questions),
        answers: JSON.stringify(answers),
        botversion_id: botVersionId
    };


    /*this.myHelp = {
        next_action: form.get('next_action')
    };*/
    
    getHandlerPOST(HOST_API +'/api/ia/intents',data).
        then((res) => {
            if(res.status==200){
                //this.myHelp.id = res.data.intent.id;
                //this.validateIAOperatorLink(this.myHelp);
                /*let aux = this.state.listIntent;
                
                aux.push({
                    id: res.data.intent.id,
                    name: res.data.intent.name
                })
                
                this.setState({ listIntent: aux,showEditionEntity: false, showEdition: true});*/
                
                dispatch({
                    type: BOT_ACTIONS_TYPES.setShowEdition(),
                    payload: true
                });
            
                dispatch({
                    type: BOT_ACTIONS_TYPES.setShowEditionEntity(),
                    payload: false
                });

                dispatch(setListIntent());

            }else
              console.log(" No se pudo crear el modulo"); 
            
        }).catch((error) => {
            console.log('catch createIntent',error)
            if (error.response.status == 403)
                cerrarSesion();
        });
}

export const updateIntent = (form) => async (dispatch, getState) =>{
    
    const {botVersionId} = getState().botReducer;

    if(form.get('name')==''){
        alert(i18next.t('message169'))
        return null
    }

    //let cont = this.state.hideLoad; this.setState({ hideLoad: cont+1});
    /*let data = {
        name : form.get('name'),
        questions: form.get('question'),
        answers: form.get('answer'),
        botversion_id : botVersionId,
        id: form.get('id')
    };*/


    let questions = convertirCadenaAArreglo(form.get('question'));
    let answers = convertirCadenaAArreglo(form.get('answer'));


    let data = {
        name: form.get('name'),
        questions: JSON.stringify(questions),
        answers: JSON.stringify(answers),
        botversion_id: botVersionId,
        id: form.get('id')
    };
 

    /*this.myHelp = {
        id: this.state.currentIntent.id,
        name: form.get('name'),
        next_action: form.get('next_action')
    };*/
    
    getHandlerPUT(HOST_API +'/api/ia/intents',data).
        then((res) => {
            if(res.status==200){
                //this.validateIAOperatorLink(this.myHelp);
                /*console.log(" modulo actualizado");
                var aux = this.state.listIntent;
                for(let i=0; i< aux.length; i++){
                    if(aux[i].id == this.myHelp.id){
                        aux[i].name = this.myHelp.name;
                        break;
                    }
                }   
                this.setState({showEditionEntity: false, showEdition: true});
                */
               
                dispatch({
                    type: BOT_ACTIONS_TYPES.setShowEdition(),
                    payload: true
                });
            
                dispatch({
                    type: BOT_ACTIONS_TYPES.setShowEditionEntity(),
                    payload: false
                });

                dispatch(setListIntent());

            }else{
              console.log(" No se pudo actualizar el modulo");
            }
            //let cont = this.state.hideLoad; this.setState({ hideLoad: cont-1});
        }).catch((error) => {
            console.log('catch updateIntent',error)
            if (error.response.status == 403)
                cerrarSesion();
        });
}


export const linkingIA = (event) => async (dispatch, getState) => {

    const {botVersionId} = getState().botReducer;
    
    event.preventDefault();

    let form = new FormData(event.target);

    form.append('botversion_id', botVersionId);

    fileHandlerPOST(HOST_API +"/api/ia/", form)
        .then((res) =>{
            if(res.status==200){

                dispatch(setListIntent());
     
                
            }else{
                
                dispatch(showError(i18next.t('message190')));

            } 

   
        }).catch((error)=> {
            
            dispatch(showError(i18next.t('message190')));
            
            if (error.response.status == 403)
                cerrarSesion();
        });

}


/*
export const listUAQuestion = () => async (dispatch, getState) => {
    
    const {botVersionId} = getState().botReducer;

    getHandlerGET(HOST_API +"/api/ia/questions?id="+botVersionId).
    then((res) =>{
        if(res.status==200){
            var list = [];
            if(res.data.data.length > 0){
                res.data.data.map ((q) =>{
                    let temp = {}
                    temp.id = q.id;
                    temp.question = q.question
                    temp.repetitions = q.repetitions
                    list.push(temp)
                } )
            }
            this.setState({ uaQuestions: list});
        }else{
            console.log(" No se pudo recuperar la lista de preguntas sin responder");
        }
      
    }).catch((error)=> {
        console.log(error);
      
    });

}*/


/*CARGAR IMAGEN URL S3*/
export const setUrlImageS3 = (url) => async (dispatch, getState) => {
    //const {company_id} = getState().botReducer;
    if (url) {
      let form = new FormData();
          form.append("multimedia", url.target.files[0]);
      await fileHandlerPOST(HOST_API + "/api/bot/upload_multimedia/", form)
        .then((res) => {
          if (res.status == 200) {
            console.log(res.data.multimedia);
            dispatch({
                type: BOT_ACTIONS_TYPES.setUrlPath(),
                payload: res.data.multimedia
            });
          } else {
            this.props.showError(" No se pudo actualizar la configuracion");
          }
        }).catch((error) => {
            const { response: { status } = { status } } = error;
            if (status && status == 403) {
              logOut();
              cerrarSesion();
            }
        });
    }
  };

// Cambia algún parametro de configuración del bot
export const setChangeConfig = (key,value) => async (dispatch, getState) => {
    console.log("CALL ME THREE");
    const { bot } = getState().botReducer;

    const new_bot = bot;
    new_bot[key] = value;

    await dispatch({
        type: BOT_ACTIONS_TYPES.setBot(),
        payload: new_bot
    });
 }

 // Cambia algún parametro de configuración del bot
export const setChangeTimeoutConfi = (key,value) => async (dispatch, getState) => {
    const { timeout } = getState().botReducer;

    const new_timeout = timeout;
    new_timeout[key] = value;

    await dispatch({
        type: BOT_ACTIONS_TYPES.setTimeout(),
        payload: new_timeout
    });
 }