import React, {Component} from 'react';
import { Button,Modal,ModalHeader,ModalBody,ModalFooter,Form,FormGroup,Table,Input,Col,Row,Nav,NavItem,TabContent,TabPane,NavLink} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import IASelector from '../../../../components/ia';
import Keyword from '../../../../components/keyword/keyword';
import Timeout from '../../../../components/timeout/timeout';
import TitleOperator from "../../../../components/title-operator";
import Mentions from '../../../../components/mentions/mentions';
import Avatar from '../../../../components/avatar';
import Emoji from '../../../../components/emoji-picker';
import Audio from '../../../../components/audio-input';
import classnames from 'classnames';
import { isDefined } from '../../../../utils/isValidate';
import TabAdvanced from "../../../../components/box/tab_advanced";
import i18next from '../../../../../i18n';

class FRMLayout extends Component {

    constructor(props){
        super(props)
        this.state = {
        activeTab: '1'
        };
        this.toggleTab = this.toggleTab.bind(this);
    }

    
    inputEnunciado = (bls,kblocks) => {
        let block = kblocks.map((k, index) => {
            return <tr key={k}>
                <td className="td-flujo" style={{width:'50%',}}>
                    { (bls[k].type == this.props.typeStetament.text || !isDefined( bls[k].type )) &&            
                        <React.Fragment>
                            <Mentions varList={this.props.varList} k={k} defaultValue={bls[k].text} updateItem={this.props.updateItem}/>
                            <div className="bar-emoji-input">
                                        <Button color="info" size="lg" onClick={() => this.props.removeTextBlock(k) }>
                                            <FontAwesomeIcon icon={faTrash} />
                                        </Button>                                  
                                            &nbsp;
                                        <Emoji id={k} callEmoji={this.props.callEmoji} type="item" style={{'display':'none',position:'fixed',marginLeft: 15+'%',marginTop:31+'px',zIndex: 100}}/>
                            </div>
                            </React.Fragment>            
                    }
                    { (bls[k].type ==  this.props.typeStetament.audio ) && 
                        <React.Fragment>
                            <div>
                                <Audio operator_id={`audio_stements-${k}`} audio={ bls[k].text } bindValue={ (url)=> this.props.getAudioStatement( k, url ) }/>
                            </div>
                            <div className="container-emoji ">
                            <Button color="info" size="lg" onClick={() => this.props.removeTextBlock(k) }>
                                <FontAwesomeIcon icon={faTrash} />
                            </Button>
                            </div>
                        </React.Fragment>
                    }

                </td>
                <td style={{width:'20%'}}>
                    <Input type="select" name="select" onChange={(event) => { this.props.updateValidation(k, event) }} value={bls[k].validation}>
                        <option value="N/A">N/A</option>
                        <option value="E-mail">E-mail</option>
                        <option value="Numerico">{i18next.t('numeric')}</option>
                        <option value="Sentence">{i18next.t('text')}</option>
                        <option value="Phone">{i18next.t('phone')}</option>
                    </Input>
                </td>
                <td style={{width:'10%'}}>
                    <Input type="select" name="select" onChange={(event) => { this.props.updateVariable(k, event) }} value={bls[k].is_var}>
                        <option value="">- {i18next.t('select_one')} -</option>
                        <option value={true}> {i18next.t('yes')} </option>
                        <option value={false}> No </option>
                    </Input>
                </td>
                <td>
                    <Input type="text" hidden={bls[k].is_var != true} className="inputModal" placeholder={i18next.t('name_var')} onChange={(event) => { this.props.updateVarName(k, event) }}  value={bls[k].var_name}/>
                </td>
            </tr>
        });

        return block;
    }


    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
          this.setState({
            activeTab: tab
          });
        }
    }


    render(){
        const bls = this.props.text_blocks || {};
        const kblocks = Object.keys(bls);
        const blocks = this.inputEnunciado(bls,kblocks)
        const { typeStetament } = this.props;
        

        return (
            <React.Fragment>
                <Modal className="form" isOpen={this.props.isOpen} toggle={this.props.closeModal} fade size="lg">
                    <ModalHeader toggle={this.props.closeModal}>{i18next.t('set_up')} {i18next.t('form')}</ModalHeader>
                    <ModalBody>
                        <Form role="form"> 
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                        className={classnames({ active: this.state.activeTab === '1' })}
                                        onClick={() => { this.toggleTab('1'); }} >
                                        {i18next.t('basic')}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                        className={classnames({ active: this.state.activeTab === '2' })}
                                        onClick={() => { this.toggleTab('2'); }}>
                                        {i18next.t('advanced')}
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={this.state.activeTab}>
                                    <TabPane tabId="1">
                                        <Row>
                                            <Col sm="12" md="12">
                                                <FormGroup>
                                                    <TitleOperator titleOperator={this.props.titleOperator} bindValue={this.props.getTitleOperator}/>
                                                    <Table style={{'textAlign':'left'}}>
                                                        <thead>
                                                            <tr>
                                                                <th style={{width:'50%'}}>{i18next.t('question')}</th>
                                                                <th style={{width:'20%'}}>{i18next.t('validation')}</th>
                                                                <th style={{width:'10%'}}>¿{i18next.t('variable')}?</th>
                                                                <th></th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>{blocks}</tbody>
                                                    </Table>
                                                    <Col sm="12" className="button-add">
                                                        { this.props.error_limit_item && 
                                                            <Col sm="12">
                                                                <span className="warning-character">{i18next.t('allowed_box')} 5 </span>
                                                            </Col>
                                                        }
                                                        <Col sm="12">
                                                            <Button color="info" className="btn-guarumo-modal-blue-sm" onClick={(event)=>this.props.addNewTextBlock(event, typeStetament.text )}>{i18next.t('add')} {i18next.t('text')}</Button>
                                                            &nbsp;&nbsp;&nbsp;
                                                            <Button color="info" className="btn-guarumo-modal-blue-sm" onClick={(event)=>this.props.addNewTextBlock(event, typeStetament.audio )}>{i18next.t('add')} audio</Button>
                                                        </Col>
                                                    </Col>   
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <Row>
                                            <Col sm="6">
                                                <IASelector botversion_id={this.props.botversion_id} operator_id={this.props.operator_id} ia={this.props.ia} getIAId={this.props.getIAId} />
                                                <Avatar company_id={this.props.company_id} avatarid={this.props.avatarid} bindValue={this.props.getAvatarId} />
                                            </Col>
                                            <Col sm="6">
                                                <Row>
                                                    <Col sm="12" md="12" className="keyword">
                                                        <Timeout bindValue={this.props.getTimeout} bindMsg={this.props.getMsgTimeout} bindValid={this.props.isTimeoutValid} defaultValue={this.props.timeoutValue} defaultMsg={this.props.timeoutMsg} />
                                                        <Keyword keyword={this.props.keyword} isValid={this.props.isKeywordValid} updateKeyword={this.props.updateKeyword}/>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            
                                        </Row>
                                     </TabPane>
                                </TabContent>
                        </Form>
                    </ModalBody>
                    <ModalFooter>   
                        <Col sm="12" align="right">
                            <Button color="success" className="btn-guarumo-modal-sm" type="button" onClick={this.props.toggleModuleModal} >{i18next.t('finish')}</Button>
                        </Col>
                    </ModalFooter>
                </Modal>           
            </React.Fragment>
        );
    }

}
  
export default FRMLayout