import React from "react";
import { Input, Label, FormGroup, Row, Col } from "reactstrap";


import 'react-phone-number-input/style.css'
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber, isPossiblePhoneNumber }  from 'react-phone-number-input'
import i18next from "../../../i18n";


export default class TabWSConfi extends React.Component {

  
  constructor(props) {
    super(props);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
    
    this.state = {
      wp_api:this.props.wp_api,
      wp_username:this.props.wp_username,
      wp_phone:this.props.wp_phone,
      wp_token:this.props.wp_token,
      value:''
    }

    
  }

  
  componentDidUpdate(prevProps, prevState) {

   if (prevProps.wp_api != this.props.wp_api) {
     this.setState({
       wp_api: this.props.wp_api
     });

     this.defaultUrl(this.props.wp_api);
   }
 }

  handleChangeSelect = (event) => {
   this.setState({ [event.target.name]: event.target.value });
   this.props.handleChangeSelect(event);

    this.defaultUrl(event.target.value);
 };



 defaultUrl(proveedor) {
  if (this.props.props.botInfo) {
    this._data = this.props.props.botInfo;
    this._botversion = this._data.bot_version[0];
    this._url = ''
    if(proveedor == 'GUPSHUP'){
      this._url =`${HOST_API}/api/whatsapp/gupshup/webhook/` +
      this._botversion.botversion_id;
    }else if(proveedor == 'SMOOCH'){
      this._url =`${HOST_API}/api/whatsapp/smooch/webhook/` +
      this._botversion.botversion_id;
    }else if(proveedor == 'MASIVIAN'){
      this._url =`${HOST_API}/api/whatsapp/masivian/webhook/` +
      this._botversion.botversion_id;
    }else if(proveedor == 'ROUTEMOBILE'){
      this._url =`${HOST_API}/api/google_bussiness/routemobile/webhook/` +
      this._botversion.botversion_id;
    }
    else if(proveedor == 'ALDEAMO'){
      this._url =`${HOST_API}/api/whatsapp/aldeamo/webhook/` +
      this._botversion.botversion_id;
    }
    else {
      this._url = `${HOST_API}/api/whatsapp/${proveedor}/webhook/` +
      this._botversion.botversion_id;
    }
  }
}

  render() {
    const customStyles = {
      ///.....
      menuPortal: provided => ({ ...provided, zIndex: 9999 }),
      menu: provided => ({ ...provided, zIndex: 9999 })
      ///.....
    }
    
    const url = this._url;
    return (
      <Row>
        <Col sm="12">
        <Row form>
            <Col md={6}>
              <FormGroup>
                <Label for="webhook_whatsapp">{i18next.t('provider')}</Label>
                <select
                  value={this.state.wp_api}
                  className="form-control config-input"
                  name={this.props.name}
                  onChange={this.handleChangeSelect}
                  menuPortalTarget={document.body}
                  menuPosition={'fixed'} 
                  styles={customStyles}
                >
                  {/* <option value="">- Seleccione uno -</option> */}
                  {this.props.optionApi.map((el, i) => (
                    <option key={"api_id-" + i + '_'+el.value} value={el.value}>
                      {el.text}
                    </option>
                  ))}
                </select>
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <FormGroup>
              <Label for="webhook_whatsapp">
                {i18next.t('url_integration')} <small>(Webhook)</small>
              </Label>
              <Input readOnly className="config-input" defaultValue={url} />
            </FormGroup>
          </Row>
          <Row form>
             <FormGroup>
                <Label for="at_whatsapp">Username / API Key</Label>
                <Input
                  className="config-input"
                  type="text"
                  rows={4}
                  id={this.props.idUser}
                  maxLength="500"
                  name={this.props.idUser}
                  defaultValue={this.props.wp_username?this.props.wp_username:''}
                />
              </FormGroup>
            </Row>
            <Row form>
              <FormGroup>
                <Label for="vt_whatsapp">Token / API Key Secret</Label>
                <Input
                  className="config-input"
                  type="text"
                  rows={4}
                  id={this.props.idPwd}
                  maxLength="500"
                name={this.props.idPwd}
                  defaultValue={this.props.wp_token?this.props.wp_token:''}
                />
              </FormGroup>
            </Row>
            <Row form>
              <FormGroup>
                <Label for="vp_whatsapp">{i18next.t('phone_number')}</Label>
                <PhoneInput
                  defaultCountry="CO"
                  id={this.props.idPhone}
                  placeholder={i18next.t('write_phone_number')}
                  countrySelectProps={{ unicodeFlags: true }}
                  value={this.props.wp_phone?this.props.wp_phone:''}//{value}//{this.state.phone}
                  onChange={this.props.setValue}
                  error={this.state.wp_phone ? (isValidPhoneNumber(this.state.wp_phone) ? undefined : 'Invalid phone number') : 'Phone number required'}
                  />
              </FormGroup>
            </Row>
        </Col>
      </Row>
    );
  }
}
