var d3 = require('d3');
var d3plus = require('d3plus-text');
/**
 * 
 */
const createSquareText = (nameDiv,datos,formatoD3,group,botID,dateini,datefin, colorChosen, typeScaleColor,description) => {

    const node = document.createElement('div');
    node.className = "svg-container";
    node.id="chart_"+nameDiv;
  
    var div='#'+nameDiv;
    var chart=document.getElementById('content-'+nameDiv);
  
    const WIDTH = chart.offsetWidth;
    const HEIGHT = chart.offsetHeight;
    const margin = { top: 40, right: 20, bottom: 20, left: 20 };
    
    var  width =  WIDTH - margin.left - margin.right,
   height = height = HEIGHT - margin.top - margin.bottom;

   var color;

   if(typeScaleColor=="unicolor")
    color=colorChosen;
    else if(typeScaleColor=="Categorica")
    {
      colorChosen="d3."+colorChosen;
      color = d3.scaleOrdinal(eval(colorChosen));
    }
    else if(typeScaleColor=="predeterminado")
        {
            color = d3.scaleOrdinal().domain(datos.values).range(eval(colorChosen));
           
           // color = d3.scaleOrdinal().domain(datos.dataSet).range(["rgba(46,91,255,1)", "rgba(140,84,255,1)", "rgba(0,193,212,1)", "rgba(250,208,80,1)", "rgba(195,34,168,1)"]);       
        }
    else 
    {
      colorChosen="d3."+colorChosen+"(t * 0.85)";
      const scale = t => eval(colorChosen);
      var colorF = (d) => {
        var c= d3.range(5).map(i => scale(i / 5))
        return c[d];
        };
        color=colorF(0);
      //  color = d3.scaleSequential(eval(colorChosen));
    }

   // create a tooltip
   var Tooltip = d3.select("#tooltip_"+nameDiv)//.select("svg")
   .style("opacity", 0)
   .attr("class", "tooltip")
   .style("background-color", "white")
   .style("border", "solid")
   .style("border-width", "2px")
   .style("border-radius", "5px")
   .style("padding", "5px")

   // Three function that change the tooltip when user hover / move / leave a cell
   var mouseover = function(d) {
    Tooltip
    .style("opacity", 1)
    d3.select(this)
    .style("stroke", "black")
    .style("opacity", 1)
}
var mousemove = function(d) {
    Tooltip
    .html(""+datos_new.title+"<br>Plataforma: "+group+"<br>Bot: "+botID+"<br>Valor: " + datos_new.values.measure+" "+datos_new.unit)
    .style("left", (d3.mouse(this)[0]+70) + "px")
    .style("top", (d3.mouse(this)[1]) + "px")
}
var mouseleave = function(d) {
    Tooltip
    .style("opacity", 0)
    d3.select(this)
    .style("stroke", "none")
    .style("opacity", 0.8)
}

   const chartComponentSelection = d3.select("#chart_"+nameDiv);
   chartComponentSelection.attr(
       "style",
       "padding-bottom: " +
         Math.ceil(
           (height + margin.top + margin.bottom) *
             100 /
             (width + margin.left + margin.right)
         ) +
         "%"
     );

    var datos_new=datasetSquareTextChosen(group, botID);

    var svg = d3.select(node)
            .append("svg")
            .attr("id","svg_"+nameDiv)
            .attr("preserveAspectRatio", "xMinYMin meet")
            .attr(
                "viewBox",
                "0 0 "
                  .concat(width + margin.left + margin.right )
                  .concat(" ")
                  .concat(height + margin.top + margin.bottom )
              )
            .classed("svg-content", true)
            ;

    var plot = svg
    .append("g")
    .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
    .attr("id", "SquareTextPlot")
    
    ;

    var data = [
        {text: "Here is <i>some</i> sample text that has been <strong>wrapped</strong> using d3plus.textBox."},
        {text: "...and here is a <b>second</b> sentence!"}
      ];

  /*var t=new d3plus.TextBox()
  .data(data)
  .fontSize(15)
  .width(width)
  .x(function(d, i) { return i * 2; })
  .render();*/

var texto="asdfgh\ndfghjk"

    var titulo=plot.append("text")
		.text(datos_new.title)
		.attr("class","chartTitle")
		.attr("x",0)
    .attr("y",-10)
    .attr("dy",10)		
    .attr("font-size","150%")

   /* titulo.selectAll("tspan.text")
  .data(datos_new.title)
  .enter()
  .append("tspan")
  //.attr("class", "text")
  .text("d => d")
  .attr("font-size", 15)
  .attr("x", 0)
  .attr("dx", -10)
  .attr("dy", 7);*/
    //.call(wrap,50);
    //.call(wrap, 5);

       

      //  .call(wrap, 3)
      /*  .call(function(d, i){
            let textArray = d[0];
            for (let i = 0; i < textArray.length; i++) {
              if (textArray[i].hasChildNodes()) {
                data[i].textNumLines = textArray[i].childNodes.length;
              }
              let textLabelY = parseInt(textArray[i].getAttribute('y'), 10);
              data[i].textLabelY = textLabelY;
            }
          });
        ;*/
        
    plot.append("text")
        .text(datos_new.values.measure)
        //.attr("class","lineChartTitle2")
		    .attr("id",datos_new.name.replace(/\s/g,"_"))
		    .attr("x",(width/2)+20)
        .attr("y",(height/2)+margin.top)	
        .attr("fill",color)
        .attr("font-size","400%")
        .attr("text-anchor","middle")
        .attr("font-family","Verdana")
        .attr("font-weight","bold") ;
       
        

 
        plot.append("svg:image")
        .attr("xlink:href",  datos_new.icon)
        .attr("x", function(d) { return 0;})
        .attr("y", function(d) { return height/8;})
        .attr("height", height)
        .attr("width", width/5)

        plot.append("text")
        .text(datos_new.unit)
        //.attr("class","lineChartTitle2")
		    .attr("id","unit"+datos_new.name.replace(/\s/g,"_"))
		    .attr("x", function(d) { return 10;})
        .attr("y", function(d) { return (height/3)+45;})	
        .attr("fill",color)
        .attr("font-size","200%")
        .attr("text-anchor","middle")
        .attr("font-family","Verdana")
        .attr("font-weight","bold") ;
      //  .on("mouseover", mouseover)
      //  .on("mousemove", mousemove)
      //  .on("mouseleave", mouseleave);

        function datasetSquareTextChosen(group, botID)
        {   var dataT = {}
                    datos.values.forEach(function(f, index) {
                       
                        if(f.group==group&&f.bot==botID){
                            dataT={'name':datos.name,'title':datos.title,'icon':datos.icon,'values':f,'unit':datos.unit};
                            
                            return dataT;
                        }
                
                    });
                    if(dataT.length==0)
                        dataT=datos;
               // }
            //});
            return dataT;
        }
        
        function wrap(text, width) {
          text.each(function() {
            var tam=0;
            var text = d3.select(this),
                words = text.text().split(/\s+/).reverse(),
                word,
                line = [],
                lineNumber = 0,
                lineHeight = 1.1, // ems
                y = text.attr("y"),
                dy = parseFloat(text.attr("dy")),
                tspan = text.text(null).append("tspan").attr("x", 0).attr("y", y).attr("dy", dy + "em")
            while (word = words.pop()) {
              line.push(word)
              tspan.text(line.join(" "))
              tam=tspan._groups[0][0].childNodes[0].length
              if (tam > width) {
                line.pop()
                tspan.text(line.join(" "))
                line = [word]
                tspan = text.append("tspan").attr("x", 0).attr("y", y).attr("dy", `${++lineNumber * lineHeight + dy}em`)
                .text(word);
              }
            }
          })
        }
        
    return node;

}

export default createSquareText;