import { COMPONENT_MOUNT_ACTIONS_TYPES } from '../types/componentMountActionsTypes';

export const isDidMount = (nameComponent) => (dispatch,getState) => {
    const { components = [ { name: '' } ] } = getState().componentMountReducer;
    let component = components.filter(( item ) => item.name == nameComponent )
    if( component.length == 0 ){
        components.push({ name: nameComponent })
        dispatch({
            type: COMPONENT_MOUNT_ACTIONS_TYPES.isMounted(),
            payload: components
        }); 
    }
}

export const willUnMount = ( nameComponent ) => ( dispatch, getState ) => {
    const { components = [] } = getState().componentMountReducer;
    let componentUpdated = components;
    let  index = components.map(( e ) => e.name ).indexOf(nameComponent);
    componentUpdated.splice( index, 1 )
    dispatch({
        type: COMPONENT_MOUNT_ACTIONS_TYPES.isMounted(),
        payload: componentUpdated
    }); 
}
