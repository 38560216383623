import React, { Component } from 'react';
import LoginLayout from './login-layout';
import ModalLoad from '../../components/modals/Loading/moda-loading';
import { connect } from 'react-redux';
import {logIn,logInSendOTP, logInSendToken} from "../../redux/actions/loginActions";
import { getAuthorization } from '../../utils/commonFunction';

class Login extends Component {
    state = {
        login:{
            username:'',
            password:'',
            grant_type:'password',
        },
        redirect: false,
        showAlert:false,
        showAlertWarning: false,
        warningMsg: "",
    }

    constructor(props) {
        super(props);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.hideAlert = this.hideAlert.bind(this);
    }

    hideAlert=(e)=>{
        this.setState({showAlert:false});
    }

    handleEmailChange= (e)=> {
        this.setState({login:{username: e.target.value,password:this.state.login.password,grant_type:this.state.login.grant_type}});
    }

    handlePasswordChange= (e)=> {
        this.setState({login:{username:this.state.login.username,password: e.target.value,grant_type:this.state.login.grant_type}});
    }
    
    onSubmit = async (e) => {

        e.preventDefault();
        
        const { logIn, logInSendOTP, logInSendToken, loginReducer } = this.props;    
        
        //Request Log In
        //await logIn(this.state.login);

        await logInSendOTP({username:this.state.login.username, 
                            password:this.state.login.password, 
                            grant_type:this.state.login.grant_type});
        
        if(this.props.loginReducer.sendOpt === true){
            
            setTimeout(() => {
                this.hideAlert();
                this.props.history.push("/validate");
            }, 3000);
        
        }else{
            this.setState({showAlertWarning: true, warningMsg: "OPT Lo sentimos no se ha podido iniciar sesión, por favor verifique sus datos."});
            setTimeout(() => {
                this.setState({showAlertWarning: false});
            }, 2000);
        }

        return;
        /*const { loginReducer: { isLogin ,error, userInfo, authorization, sendOpt}  } = this.props;

        if(error){
            // An error has occurred 
            this.setState({showAlertWarning: true, warningMsg: error});
            setTimeout(() => {
                this.setState({showAlertWarning: false});
            }, 2000);

        }else if(userInfo){
            //Request Login success
            // TODO: Quitar bloque de bloque de codigo, cuando lo que se guardaba en lo localStorage no se requiera más
                await localStorage.setItem('Authorization', JSON.stringify(authorization));
                await localStorage.setItem('UserInfo', JSON.stringify(userInfo));
                
                console.log("userInfo ",userInfo);

                //await logInSendToken({username:this.state.login.username, password:this.state.login.password});
                await logInSendOTP({username:this.state.login.username, password:this.state.login.password});
                
            // END TODO: 
            if(this.props.loginReducer.sendOpt === true){

                setTimeout(() => {
                    this.hideAlert();
                    this.props.history.push("/validate");
                }, 1000);
            
            }else{
                this.setState({showAlertWarning: true, warningMsg: "OPT Lo sentimos no se ha podido iniciar sesión, por favor verifique sus datos."});
                setTimeout(() => {
                    this.setState({showAlertWarning: false});
                }, 2000);
            }

        }*/      
    }

    render() {
        return (
            <div>
                <LoginLayout
                onSubmit={this.onSubmit}
                handleEmailChange={this.handleEmailChange}
                handlePasswordChange={this.handlePasswordChange}
                hideAlert={this.hideAlert}
                showAlert={this.state.showAlert}
                showAlertWarning={this.state.showAlertWarning}
                warningMsg={this.state.warningMsg}
                blockAccount={this.props}
                />
                <ModalLoad  />
            </div>
            
        )
    }
}

const mapStateToProps = ({loginReducer,}) => {
	return {loginReducer,};
};

const mapDispatchToProps = {
	logIn,
    logInSendOTP,
    logInSendToken
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);

