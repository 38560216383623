import React, { useState } from "react";
import { Input, Label, FormGroup, Row, Col } from "reactstrap";


import 'react-phone-number-input/style.css'
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber, isPossiblePhoneNumber }  from 'react-phone-number-input'
import i18next from "../../../i18n";


export default class TabMESSAGEConfi extends React.Component {

  
  constructor(props) {
    super(props);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
    
    this.state = {
      imessage_api:this.props.imessage_api,
      imessage_username:this.props.imessage_username,
      imessage_password:this.props.imessage_password,
      value:''
    }

    
  }

  
  componentDidUpdate(prevProps, prevState) {

   if (prevProps.imessage_api != this.props.imessage_api) {
     this.setState({
      imessage_api: this.props.imessage_api
     });

     this.defaultUrl(this.props.imessage_api);
   }
 }

  handleChangeSelect = (event) => {
   this.setState({ [event.target.name]: event.target.value });
   this.props.handleChangeSelect(event);
   this.defaultUrl(event.target.value);
 };
 



 defaultUrl(proveedor) {
  if (this.props.props.botInfo) {
    this._data = this.props.props.botInfo;
    this._botversion = this._data.bot_version[0];
    this._url = ''
   
    if(proveedor == 'SMOOCH'){
      this._url =`${HOST_API}/api/imessage/smooch/webhook/` +
      this._botversion.botversion_id;
    }/*else if(proveedor == 'SMOOCH'){
      this._url =`${HOST_API}/api/imessage/smooch/webhook/` +
      this._botversion.botversion_id;
    }else if(proveedor == 'MASIVIAN'){
      this._url =`${HOST_API}/api/imessage/masivian/webhook/` +
      this._botversion.botversion_id;
    }else if(proveedor == 'ROUTEMOBILE'){
      this._url =`${HOST_API}/api/google_bussiness/routemobile/webhook/` +
      this._botversion.botversion_id;
    }*/
    else {
      this._url =`${HOST_API}/api/imessage/smooch/webhook/` +
      this._botversion.botversion_id;
    }
    console.log('proveedor ',proveedor);
  }
}

  render() {
    const customStyles = {
      ///.....
      menuPortal: provided => ({ ...provided, zIndex: 9999 }),
      menu: provided => ({ ...provided, zIndex: 9999 })
      ///.....
    }
    
    const url = this._url;
    console.log('url ',url);
    return (
      <Row>
        <Col sm="12">
        <Row form>
            <Col md={6}>
              <FormGroup>
                <Label for="webhook_imessage">{i18next.t('provider')}</Label>
                <select
                  value={this.state.imessage_api}
                  className="form-control config-input"
                  name={this.props.name}
                  onChange={this.handleChangeSelect}
                  menuPortalTarget={document.body}
                  menuPosition={'fixed'} 
                  styles={customStyles}
                >
                  {/* <option value="">- Seleccione uno -</option> */}
                  {this.props.optionApi.map((el, i) => (
                    <option key={"api_id-" + i + '_'+el.value} value={el.value}>
                      {el.text}
                    </option>
                  ))}
                </select>
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <FormGroup>
              <Label for="webhook_imessage">
                {i18next.t('url_integration')} <small>(Webhook)</small>
              </Label>
              <Input readOnly className="config-input" defaultValue={url} />
            </FormGroup>
          </Row>
          <Row form>
             <FormGroup>
                <Label for="at_imessage">Username / API Key</Label>
                <Input
                  className="config-input"
                  type="text"
                  rows={4}
                  id={this.props.idUser}
                  maxLength="500"
                  name={this.props.idUser}
                />
              </FormGroup>
            </Row>
            <Row form>
              <FormGroup>
                <Label for="vt_imessage">Token / API Key Secret</Label>
                <Input
                  className="config-input"
                  type="text"
                  rows={4}
                  id={this.props.idPwd}
                  maxLength="500"
                  name={this.props.idPwd}
                />
              </FormGroup>
            </Row>
        </Col>
      </Row>
    );
  }
}
