 import React,{ Fragment } from 'react';
import Admin from '../../admin/containers/admin';
import SliceMenuApp from '../../admin/components/slice-menu'
import { 
  Col, Row, Button, Container, Input, Table
} from 'reactstrap';
import { Link } from "react-router-dom";
import Switch from "react-switch";
import './bot-aiml-layout.css';
import ToolbarApp from '../../admin/components/toolbar';
import ModalNewGroup from '../../../components/modal-new-group-layout'
import BotMenuOptions from '../../menus/bot-menu-options';
import i18next from '../../../../i18n';

function BotAimlLayout(props) {
	const _listGroupHTML = props.displayTr.map((item,index) => {
		return (
			<Fragment key={index} >
				<tr key={index}>
					<td className="text-center">
						<Input type="text" value={item.name}  name={"name"+index} placeholder={i18next.t('group')} onChange={(e)=>props.handleChangeInputGroup(index,e.target.value)} required hidden={!item.hidden}/>
						<span hidden={item.hidden}> {item.name} </span>
					</td>
					<td>
						<Row className="text-center col-12 d-flex justify-content-center">
							<Col className="col-2"> 
									<Link to={"/bot/"+props.botId+"/"+item.id+"/aiml"} data-aiml="a" className="btn btn-square btn-link-eye"><i className="fa fa-eye"></i></Link>
							</Col>
							<Col className="col-2">
								<Button title={i18next.t('edit_question')} onClick={()=>props.toogleQuestion(index)} outline color="success" className="btn btn-square" hidden={item.hidden} >
									<i  className="fa fa-pencil-alt"></i>
								</Button>
								<Button   onClick={()=>props.updateGroup(item.id, item.name)} outline color="success" title={i18next.t('save')} className="removeQuestion btn btn-square" data-aiml="a" hidden={!item.hidden}>
									<i className="fa fa-save"></i>
								</Button>
							</Col>
							<Col className="col-2"> 
								<Button outline onClick={()=>props.deleteGroup(item.id,index)} color="danger" className="removeQuestion btn btn-danger btn-square" data-aiml="a">
									<i className="fa fa-trash"></i>
								</Button>
							</Col>
						</Row>
					</td>
				</tr>
			</Fragment>
		)
	});

  return (
    <div className="BotLayout">
			<Admin>
				<SliceMenuApp>
					<BotMenuOptions botId={props.botId} ia_activated={props.ia_activated}/>
				</SliceMenuApp>
	            <Container fluid>
	                <ToolbarApp>
	                    <Row>
	                        <Col sm="6" md="4" lg="3" className="text pdtoolbar">
	                            <h2 className="text-left ml-4 font-weight-bold">{i18next.t('module_qna')}</h2>                      
	                        </Col>
	                    </Row>
	                </ToolbarApp>
	            </Container>
				<Container fluid className="box-fluid">
					<Col sm="12" md="8" className="aiml-container offset-md-2">
						<Col md="12">
							<Row className="card-train">
								<Col md="12">
									<Row>
										<Col md="8" lg="9" xl="9">
											<h1 className="font-weight-bold">{i18next.t('groups_qna')}</h1>    
										</Col>
										<Col md="4" lg="3" xl="3">
											<Button type="button"  color="success" className="btn btn-guarumo-md pull-right btn-block" onClick={props.toggleModalNewGroup}>
												{i18next.t('new_groups')}
											</Button>
										</Col>
									</Row>
								</Col>
								<Col md="12" className="describe-aiml">
									<p>{i18next.t('groups_msg')}</p>
								</Col>
								<Table className="col-12" bordered>
									<thead>
										<tr>
											<th>
												<p>{i18next.t('group')}</p>
											</th>
											<th style={{width:'30%'}} >
												<p>{i18next.t('options')}</p>
											</th>
										</tr>
									</thead>
									<tbody>
                      					{_listGroupHTML}
									</tbody>
								</Table>
							</Row>
							<Row>
								<Col sm="6" md="5" lg="2">
									<h1 className="font-weight-bold">{i18next.t('activate_ia')}</h1>
								</Col>
								<Col sm="6" md="2" lg="2">
								<label htmlFor="normal-switch">
									<Switch
									onChange={props.handleChangeSwitch}
									checked={props.ia_activated}
									onColor={'#75b74b'}
									id="normal-switch"
									/>
								</label>
								</Col>
							</Row>
						</Col>
					</Col>
				</Container>
				<ModalNewGroup toggleModal={props.toggleModalNewGroup} isOpen={props.modalBot} saveGroup={props.saveGroup}/>
			</Admin>      
    </div>
  )
}

export default BotAimlLayout