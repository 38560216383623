import React, {useEffect} from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import cubejs from "@cubejs-client/core";

import Admin from "../../admin/containers/admin";
import SliceMenuApp from "../../admin/components/slice-menu";
import SelectedCompanies from "../../companies/selected-company";
import "../../../../static/css/dashboard.css";

import i18next from "../../../../i18n";

//import Date from '../../../components/datepicker-dashboard';

import {
  Col,
  Row,
  Button,
  Container,
  Card,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import ToolbarApp from "../../admin/components/toolbar";
import BotMenuOptions from "../../menus/analytics-menu-options";

import CustomersReport from "../infoViz/cubejs/cubejsInteractionReport";
//import PlaygroundQueryBuilder from '../../components/infoViz/cubejs-playground/PlaygroundQueryBuilder';

import {
  setSelectedCompany,
  setSelectedBot,
  getAllMetrics,
  getAllCharts,
  getBuilderQuery,
  getPagination,
  iconLoad,
  iconHideLoad,
  interactionReportTable,
} from "../../../redux/actions/analyticsActions";

function BotCustomersReportLayout(props) {
  const { listAllCompanies = [] } = props.homeReducer;
  const {
    listAllBotsbyCompany = [],
    selectedBot,
    selectedCompany,
    operationFinal,
    dateframe,
  } = props.analyticsReducer;

  useEffect(() => {
    const fetchData = async () => {
      if(selectedBot.id)
        await props.interactionReportTable('customers');
    }  
    fetchData();
  }, [])

  return (
    <div className="BotLayout">
      <Admin>
        <SliceMenuApp>
          <BotMenuOptions admin={props.isAdmin} handleFind={props.handleFind} />
        </SliceMenuApp>
        <Container fluid>
          <ToolbarApp>
            <Row>
              <Col sm="6" md="6" className="text pdtoolbar">
                <h2 className="text-left ml-4 font-weight-bold">
                {i18next.t('report_customer')} <span className="btn-help"></span>
                </h2>
              </Col>
              {props.isAdmin ? (
                <SelectedCompanies
                  onchangeCompanies={onchangeCompanies}
                ></SelectedCompanies>
              ) : null}
              <Col sm="3" md="3" className="filter-stuff">
                <FormGroup>
                  <Label
                    sm={3}
                    for="botName"
                    className="text-left font-weight-bold"
                    style={{ color: "#5a5a5a" }}
                  >
                    Bot:
                  </Label>
                  <Input
                    type="select"
                    name="select"
                    bsSize="sm"
                    id="gotSelect"
                    defaultValue={selectedBot ? selectedBot.id : ""}
                    onChange={(e) => onchangeBot(e)}
                  >
                    {listAllBotsbyCompany &&
                      listAllBotsbyCompany.length > 0 &&
                      listAllBotsbyCompany.map((bot, i) => {
                        return (
                          <option key={bot.id} value={bot.id} data={bot}>
                            {bot.name}
                          </option>
                        );
                      })}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
          </ToolbarApp>
        </Container>
        <Container fluid className="box-fluid">
          <Col sm="12" md="10" className="stadistics-container offset-md-1">
            <div id="wdr-component"></div>
            {operationFinal || dateframe ? (
              <CustomersReport
                onFilter={props.interactionReportTable}
                isSuperUser={props.isSuperUser}
              />
            ) : (
              <div></div>
            )}
            {/* <PlaygroundQueryBuilder
                    query={operationFinal}
                    setQuery={(q) => history.push(`/explore?query=${JSON.stringify((q))}`)}
                    cubejsApi={cubejsApi}
                    apiUrl={HOST_API+"/cube/v1"}
                    cubejsToken={null}
                    dashboardSource={null}
                /> */}
          </Col>
        </Container>
      </Admin>
    </div>
  );

  //----------------------------------------------------------------------------
  //  CUANDO SE SELECCIONA UN BOT ACTUALIZA EL SELECTEDBOT
  //------------------------------------------------------------------------------
  async function onchangeBot(e) {
    const id_bot = e.target.value;

    await props.iconLoad();
    await props.setSelectedBot(id_bot);
  }
  //----------------------------------------------------------------------------
  //  CUANDO SE SELECCIONA UNA COMPAÑÍA ACTUALIZA EL SELECTEDCOMPANY
  //------------------------------------------------------------------------------
  async function onchangeCompanies(e) {
    const id_company = e.value;

    await props.iconLoad();
    await props.setSelectedCompany(id_company);
  }
}

const mapStateToProps = ({ analyticsReducer, homeReducer }) => {
  return { analyticsReducer, homeReducer };
};

const mapDispatchToProps = {
  setSelectedCompany,
  setSelectedBot,
  getAllMetrics,
  getAllCharts,
  getBuilderQuery,
  getPagination,
  iconLoad,
  iconHideLoad,
  interactionReportTable,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BotCustomersReportLayout));
