import React from 'react';
import { 
    Button, 
    Input,
    Modal, 
    ModalHeader, 
    ModalBody, 
    ModalFooter,
    Form, 
    FormGroup, 
    Col,
    Row,
    Label,
    UncontrolledTooltip
} from 'reactstrap';
require('../../../../components/modals/modal.css');

import { 
    setChangeConfig,
    toggleModalViewBoxDesuscription
     } from "../../../../redux/actions/boxActions";
import TabAdvanced from "../../../../components/box/tab_advanced";
import TitleOperator from "../../../../components/title-operator";
import i18next from '../../../../../i18n';

function DSModalLayout(props){

        return (
            <div>
                <Modal className="dsus" isOpen={props.isOpen} toggle={props.closeModal} fade size="lg">
                <ModalHeader className="dsus" toggle={props.closeModal}>{i18next.t('config_desuscription')}</ModalHeader>
                    <ModalBody>
                        <Form id="DSM-form" role="form">  
                                <FormGroup>
                                    <Row>
                                    <Col>
                                        <Label>{i18next.t('statement')} <i className="fa fa-info-circle" id="tooltipquestion"></i></Label>
                                        <UncontrolledTooltip placement="right" target="tooltipquestion"> {i18next.t('message59')}</UncontrolledTooltip>
                                        <Input
                                            type="text"
                                            className="inputModal"
                                            id="question"
                                            name="question"
                                            placeholder=" "
                                            onChange={props.handleChangeInput}
                                            defaultValue={props.question}
                                            required
                                            />
                                    </Col>
                                    <Col>
                                        <Label>{i18next.t('welcome_msg')+': '} </Label>
                                        <Input
                                            type="text"
                                            className="inputModal"
                                            id="sayBye"
                                            name="sayBye"
                                            placeholder=" "
                                            onChange={props.handleChangeInput}
                                            defaultValue={props.sayBye}
                                            required
                                            />
                                    </Col>                               
                                    
                                    </Row>
                            </FormGroup>
                                
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Col sm="12" align="right">
                            <Button color="success" className="btn-guarumo-modal-sm" type="button" onClick={props.toggleModuleModal} >{i18next.t('finish')}</Button>
                        </Col>
                    </ModalFooter>
                </Modal>
            </div>
        );
}
  
export default DSModalLayout
