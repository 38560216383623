import React, {useState} from 'react';
import Admin from '../admin/containers/admin';
import SliceMenuApp from '../admin/components/slice-menu'
import { 
  Col,
  Row,
  Button, 
  Container,
  Modal, 
  ModalHeader, 
  ModalBody, 
  ModalFooter,
  FormGroup, 
  Label,
  Input,
  UncontrolledTooltip,
  CustomInput,
  Form,
} from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ToolbarApp from '../admin/components/toolbar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash,faUser, faCheck } from '@fortawesome/free-solid-svg-icons'
import AdminMenuOptions from '../menus/admin-menu-options';
import './companies-layout.css';
import { deleteCompany } from '../../redux/actions/companyActions';
import { getRolUser } from '../../utils/commonFunction';

import DataTableFilter from '../analytics/infoViz/common/dataTableFilter'

    /* IMAGE */
import newCompany from '../../../static/img/menu-icons/nuevo_bot.svg'
import i18next from "../../../i18n";

function CompaniesLayout(props) {
    const { companyReducer: { listAllCompanies = [] }, deleteCompany } = props;
    const { isSuperUser = false, isAdmin = false } = getRolUser();

    const [sidebarColor, setSidebarColor] = useState('blue');
    const [logo, setLogo] = useState(null);
    const [icono, setIcono] = useState(null);
    const [navColor, setNavColor] = useState('black');

    /*AGREGADO EL DIA 13 DE MARZON* */
    const [fileName, setFileName] = useState('');
    const [fileNameLogo, setFileNameLogo] = useState('');
    const [fileNameTwo, setFileNameTwo] = useState(''); // Estado para almacenar el nombre del archivo seleccionado
    const [fileNameIcon, setFileNameIcon] = useState(''); // Estado para almacenar el nombre del archivo seleccionado
    /*FIN* */

    const handleSidebarColorChange = (e) => {
        setSidebarColor(e.target.value);
      };
  
      const handleLogoChange = (e) => {
        const file = e.target.files[0];
        
        const fileLog = file.name;
        if (fileLog) {
            setFileNameLogo(fileLog);
            setFileName(fileLog);
        }

        setLogo(URL.createObjectURL(file));
        /*const formData = new FormData();
        formData.append('logo', file);*/
        props.updatePropC(URL.createObjectURL(file), 'logo') 
      };

  
      const handleIconChange = (e) => {
        const file = e.target.files[0];
        const fileLogTwo = file.name;
        if (fileLogTwo) {
            setFileNameTwo(fileLogTwo);
            setFileNameIcon(fileLogTwo);
        }
        setIcono(URL.createObjectURL(file));
        props.updatePropC(URL.createObjectURL(file), 'icono');
      };
  
      const handleNavColorChange = (e) => {
        setNavColor(e.target.value);
      };

      const handleGuardarConfiguracion = () => {
        // Crear un objeto con la configuración actual
        const configuracionActual = {
          sidebar: sidebarColor,
          logo: logo,
          icono: icono,
          nav: navColor,
        };
    
        // Agregar la configuración actual al estado de datos
        //props.AddConfig(configuracionActual);
        
        // Limpiar el formulario
        setSidebarColor('blue');
        setLogo(null);
        setIcono('default-icon.png');
        setNavColor('black');
      };

    var dataTable=null;
    if(listAllCompanies[0]){
       
         dataTable = {
            columns: [
              {
                label: 'Id ' + i18next.t('company'),
                field: 'id',
                sort: 'asc',
               // width: 150
              },
              {
                label: i18next.t('name'),
                field: 'name',
                sort: 'asc',
           //     width: 270
              },
              {
                label: i18next.t('edition_option'),
                field: 'options',
                sort: 'asc',
                width: 670,
                options: {
                    filter: false,
                    sort: false
                   }
              }],
              rows: []};
 

              const rows = listAllCompanies.map((cp, index) => {

                const redirectTo = () => {
                    props.history.push(`/users/${cp.id}`)
                };

                //k = JSON.parse(k);  
                const fin=[];
                fin.push(cp.id);
                fin.push(cp.name);
               
                fin.push(
                    <Row className="d-flex align-items-center justify-content-center">
                              <Button outline color="primary" onClick={ (e) => redirectTo() } >
                                <FontAwesomeIcon icon={faUser} />
                            </Button>
        
                            {
                                isAdmin && 
                                <div className="d-inline-block">
                                    <Button outline color="success" onClick={ (e) => props.editCompany(cp) }>
                                        <FontAwesomeIcon icon={faPen} />
                                    </Button> 
                                    <Button outline color="danger" onClick={ (e) => deleteCompany( cp.id, !cp.enable) }>
                                        {
                                            cp.enable?
                                            <FontAwesomeIcon icon={faTrash} />
                                            :
                                            <FontAwesomeIcon icon={faCheck} />
                                        }
                                    </Button> 
                                </div>
                            }
                         </Row>
                )
                dataTable.rows.push(fin); 
                return cp;
            });
        }
 
        const options = {
            filterType: 'multiselect',
            selectableRows:'none',
            resizableColumns: false,
            responsive: "scrollMaxHeight",
            rowsPerPage:5 ,
            print:false,
            download:false,
            viewColumns:false,
           
          };
          
              var title = ''

   

    return (    
    <div className="UserLayout">
          <Admin>
          { isSuperUser && 
            <React.Fragment>
                <button id="tooltiNewCompany" className="btn-float"  onClick={(e)=>{props.createCompany()}}>
                    <img src={newCompany}/>
                </button> 
                <UncontrolledTooltip placement="right" target="tooltiNewCompany">{i18next.t('create_company')}</UncontrolledTooltip> 
            </React.Fragment>
            }
             <SliceMenuApp>
                <AdminMenuOptions />
            </SliceMenuApp>
            <Container fluid>
                <ToolbarApp>
                    <Row>
                        <Col xs="7" className="text pdtoolbar">
                            <h2 className="text-left ml-4 font-weight-bold">{i18next.t('admin_companies')}</h2>                      
                        </Col>
                    </Row>
                </ToolbarApp>
            </Container>

                <Container fluid className="box-fluid">
                <Col sm="12" md="10" className="stadistics-container offset-md-1">    
                    {
                       dataTable?(
                        <Row >
                            <Col xs={12} lg={12} >
                                <DataTableFilter
                                title={title}
                                data={ dataTable.rows}
                                columns={dataTable.columns}
                                options={options}
                                />
                           </Col>
                        </Row>): 
                          <Col xs={12} lg={8} className="offset-lg-2 intro-funnels">
                                <Row>
                                    <Col xs={12}><img src="/static/img/blank_state.png" className="img-fluid"/></Col>
                                    <Col xs={12}>
                                        <h2>{i18next.t('company_admin_msg')}</h2>
                                        <h3>{i18next.t('company_admin_msg2')}</h3>
                                        <p>{i18next.t('company_admin_msg3')}</p>
                                    </Col>
                                </Row>
                            </Col>
                        }
                    </Col>
                </Container>
          </Admin>

          <Modal isOpen={props.modalOpen} fade size="lg" toggle={ () => props.closeModal() }>
            <ModalHeader toggle={() => props.closeModal() }>{i18next.t('edit_company')}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col sm="12">
                        <FormGroup>
                            <Label>{i18next.t('name')}</Label>
                            <Input type="text" className="inputModal" minLength="3" placeholder={i18next.t('company_name')} defaultValue={props.current.name || ''} onChange={(e) => props.updateProp(e.target.value, 'name') } required></Input>
                        </FormGroup>
                    </Col>
                    <Col xs={12} lg={12}>
                              <Form >
                                <FormGroup>
                                  <Label for="sidebarColor">{i18next.t('sidebarColor')+': '}</Label>
                                  <Input
                                    type="color"
                                    id="sidebarColor"
                                    defaultValue={props.current.sidebar || ''}
                                    onChange={handleSidebarColorChange}
                                  />
                                </FormGroup>
                                <FormGroup>
                                  <Label for="logo" 
                                         className="btn btn-outline-secondary">
                                         {i18next.t('file_selected')} {' '} {i18next.t('logoCompany')+': '}
                                   </Label>
                                  <CustomInput
                                    type="file"
                                    id="logo"
                                    onChange={handleLogoChange}
                                    style={{ display: 'none' }}
                                    label={props.current.logo ? props.current.logo : i18next.t('file_selected')}
                                  />
                                  {fileName && <span style={{color: '#000000', marginLeft: '8px' }}>{fileName}</span>}
                                  {props.current.logo && <img src={props.current.logo} alt="Logo" className="mt-2" width="100" />}
                                </FormGroup>
                                <FormGroup>
                                  <Label for="icono" id="icono" 
                                         className="btn btn-outline-secondary">{i18next.t('file_selected')} {' '} {i18next.t('faviconCompany')+': '}</Label>
                                  <CustomInput
                                    type="file"
                                    id="icono"
                                    value={icono}
                                    style={{ display: 'none' }}
                                    onChange={handleIconChange}
                                  />
                                  {fileNameTwo && <span style={{color: '#000000', marginLeft: '8px' }}>{fileNameTwo}</span>}
                                  {props.current.icono && <img src={props.current.icono} alt="Logo" className="mt-2" width="100" />}
                                </FormGroup>
                                <FormGroup>
                                  <Label for="navColor">{i18next.t('navbarColor')+': '}</Label>
                                  <Input
                                    type="color"
                                    id="navColor"
                                    defaultValue={props.current.navbar || ''}
                                    onChange={handleNavColorChange}
                                  />
                                </FormGroup>
                              </Form>
                            </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Col sm="6" align="right">
                    <Button color="success" size="lg" type="button" onClick={props.saveCompany}>{i18next.t('finish')}</Button>
                </Col>
            </ModalFooter>
        </Modal>

        <Modal isOpen={props.modalOpenC} fade size="lg" toggle={ () => props.closeModalC() }>
            <ModalHeader toggle={() => props.closeModalC() }>{i18next.t('create_company')}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col sm="6">
                        <FormGroup>
                            <Label>{i18next.t('company')}</Label>
                            <Input type="text" className="inputModal" minLength="3" placeholder={i18next.t('company_name')} defaultValue={props.newc.company || ''} onChange={(e) => props.updatePropC(e.target.value, 'company') } required></Input>
                        </FormGroup>
                    </Col>

                    <Col sm="6">
                        <FormGroup>
                            <Label>{i18next.t('create')} {i18next.t('name')}</Label>
                            <Input type="text" className="inputModal" minLength="3" placeholder={i18next.t('company_administrator')} defaultValue={props.newc.first_name || ''} onChange={(e) => props.updatePropC(e.target.value, 'first_name') } required></Input>
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col sm="6">
                        <FormGroup>
                            <Label>{i18next.t('last_name')}</Label>
                            <Input type="text" className="inputModal" minLength="3" placeholder={i18next.t('last_name')} defaultValue={props.newc.last_name || ''} onChange={(e) => props.updatePropC(e.target.value, 'last_name') } required></Input>
                        </FormGroup>
                    </Col>

                    <Col sm="6">
                        <FormGroup>
                            <Label>{i18next.t('email')}</Label>
                            <Input type="text" className="inputModal" minLength="3" placeholder="Email" defaultValue={props.newc.email || ''} onChange={(e) => props.updatePropC(e.target.value, 'email') } required></Input>
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col sm="6">
                        <FormGroup>
                            <Label>{i18next.t('company_admin_msg4')}</Label>
                            <Input type="text" className="inputModal" minLength="6" placeholder="Username" defaultValue={props.newc.username || ''} onChange={(e) => props.updatePropC(e.target.value, 'username') } required></Input>
                        </FormGroup>
                    </Col>

                    <Col sm="6">
                        <FormGroup>
                            <Label>{i18next.t('password')}</Label>
                            <Input type="password" className="inputModal" minLength="6" placeholder={i18next.t('password')} defaultValue={props.newc.password || ''} onChange={(e) => props.updatePropC(e.target.value, 'password') } required></Input>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} lg={12}>
                        <FormGroup>
                            <Label for="sidebarColor">{i18next.t('sidebarColor')+': '}</Label>
                            <Input
                            type="color"
                            id="sidebarColor"
                            name="sidebarColor"
                            defaultValue={props.newc.sidebarColor || '#3f9034'}
                            onChange={(e) => props.updatePropC(e.target.value, 'sidebarColor') }
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="logo" className="btn btn-outline-secondary control-label">{i18next.t('logoCompany')+': '}</Label>
                            <Input
                                type="file"
                                id="logo"
                                //onChange={handleLogoChange}
                                name="logo"
                                defaultValue={props.newc.logo|| ''}
                                onChange={handleLogoChange}
                                label={logo ? logo.name : i18next.t('file_selected')}
                                style={{ display: 'none' }}
                            />
                            {fileNameLogo && <span style={{color: '#000000', marginLeft: '8px' }}>{fileNameLogo}</span>}
                            {logo && <img src={logo} alt="Logo" className="mt-2" width="100" />}
                        </FormGroup>


                        <FormGroup>
                            <Label for="icon" className="btn btn-outline-secondary control-label" >
                                {i18next.t('file_selected')} {' '} {i18next.t('favicon')+': '}
                            </Label>
                            <CustomInput
                                type="file"
                                name="file"
                                id="icon"
                                style={{ display: 'none' }}
                                defaultValue={props.newc.icono || ''}
                                //onChange={(e) => props.updatePropC(e.target.value, 'icon') }
                                onChange={handleIconChange}
                            />
                            {fileNameIcon && <span style={{color: '#000000', marginLeft: '8px' }}>{fileNameIcon}</span>}
                            {icono && <img src={icono} alt="icono" className="mt-2" width="100" />}
                        </FormGroup>
                        <FormGroup>
                            <Label for="navColor">{i18next.t('navbarColor')+': '}</Label>
                            <Input
                            type="color"
                            id="navColor"
                            name="navColor"
                            defaultValue={props.newc.navColor || '#fff'}
                            onChange={(e) => props.updatePropC(e.target.value, 'navColor') }
                            //onChange={handleNavColorChange}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>

            <ModalFooter>
                <Col sm="6" align="right">
                    <Button color="success" size="lg" type="button" onClick={props.saveNewCompany}>{i18next.t('finish')}</Button>
                </Col>
            </ModalFooter>
        </Modal>
    </div>
    )
}

const mapStateToPros = ({companyReducer}) => ({companyReducer})

const mapDispatchToProps = {
    deleteCompany
}; 

export default connect(mapStateToPros, mapDispatchToProps)(withRouter(CompaniesLayout))