import React, { Component } from 'react';
import DSModalLayout from './ds-modal-layout';

import { getHandlerPOST,getHandlerPUT } from "../../../../utils/handlersApi";
import { cerrarSesion,isAuthorizationValid } from '../../../../utils/commonFunction'

class DSModal extends Component {
    
    state = {
        nodeId: "",
        title: "",
    }
 
    constructor(props){
        super(props)
    }

    componentWillMount(){
        isAuthorizationValid();
    }

    toggleModuleModal = () => {  
        let question = $('#question').val();  
        let sayBye = $('#sayBye').val();
        this.props.modal.node.extras['question'] = question;
        this.props.modal.node.extras['sayBye'] = sayBye;
        this.forceUpdate();
        this.props.toggleModuleModal('dsus');
        if(this.props.modal.node.extras.edited){
            this.updateStatement(this.props.modal.node.extras.idStatementQuestion,question,question);
            this.updateStatement(this.props.modal.node.extras.idStatementSayBye,sayBye,sayBye);
        }else{
            this.saveStatement(this.props.modal.node.extras.dbId,question,question,'idStatementQuestion');
            this.saveStatement(this.props.modal.node.extras.dbId,sayBye,sayBye,'idStatementSayBye');
            this.props.modal.node.extras['edited'] = true;
        }      
    }
   
    saveStatement = (operator,text,name,idStatement) => {
        const data = {
            operator_id: operator,
            text: ""+text,
            name: ""+name
        };

        getHandlerPOST(HOST_API +'/api/scheme/statement/',data).then((res) => {
        this.props.modal.node.extras[idStatement] = res.data.statement.id;
        this.props.updateSchema();
        }).catch((error) => {
            console.log('Catch updateStatement:',error);
            if (error.response.status == 403)
            cerrarSesion();
        });           
    }
    
    updateStatement = (idStatement,text,name) => {
        const data = {
            id: idStatement,
            text: ""+text,
            name: ""+name
        };
       getHandlerPUT(HOST_API +'/api/scheme/statement/',data).then((res) => {         
        this.props.updateSchema();
        }).catch((error) => {
            console.log('Catch updateStatement:',error);
            if (error.response.status == 403)
            cerrarSesion();
        });      
    }

    handleChangeInput=(e)=>{
        let value = e.target.value;
        let k= e.target.id
        this.updateItem(k,value)
    }

    updateItem = (k,text) => {
        this.props.modal.node.extras[k] = text;
        this.forceUpdate();
    }

    closeModal = () => {
        this.props.closeModal('m15');
        this.forceUpdate();
    }
    
       //Emoji
    callEmoji = (code,k,type)=>{
        let text = this.props.modal.node.extras[k];
        text += ` ${code}`;
        this.updateItem(k,text)
    }

    render() {
        return (
          <DSModalLayout 
          nodeId={this.state.nodeId} 
          title={this.state.title} 
          toggleModuleModal={this.toggleModuleModal}
          closeModal ={this.closeModal}
          updateItem={this.updateItem}
          callEmoji = {this.callEmoji}
          handleChangeInput={this.handleChangeInput}

          isOpen={this.props.modal.isOpen} 
          botversion_id={this.props.botversion_id}
          operator_id={this.props.modal.node.extras.dbId}
          titleOperator = {this.props.modal.node.extras.titleOperator || ''}
          company_id={this.props.company_id}
          botId ={this.props.botId}
          varList={this.props.varList}
          question={this.props.modal.node.extras.question || ''}
          sayBye={this.props.modal.node.extras.sayBye || ''}
          />
        )
    }
}

export default DSModal
