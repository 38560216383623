import React from 'react';
import d3DataToJSX from '../../../../../components/infoviz/react-d3/d3DataToJSX';
import ChildComponent from './ChildComponent';
import createFilterBar from '../basic-graphs/createFilterBar';
import FormatD3 from '../../../../../components/infoviz/utils/defineFormats'

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {d3DOM: [], state: [],nameDiv: " "};
  }

  componentWillMount() {
    this.setState({nameDiv:this.props.nameDiv });
  }

  componentWillReceiveProps(nextProps) {
    let d3Data = d3DataToJSX(createFilterBar(nextProps.nameDiv,nextProps.data, FormatD3,nextProps.group,nextProps.botID,nextProps.dateini,nextProps.datefin,nextProps.color));
    this.setState({d3DOM: d3Data.mappedData, state: d3Data.state})
  }

  render() {
    return (
        
        <ChildComponent data={this.state} />
    )
  }
};

