import React, { Component } from 'react';
import ModalLoad from '../../../components/modals/modal-load';
import SweetAlert from 'react-bootstrap-sweetalert';
import BotGeneralStatsLayout from './bot-general-stats-layout';
import moment from "moment";
import momentzone from "moment-timezone";
import axios from 'axios';
import qs from 'qs';
import { UTCFormatter } from '../../../utils/commonFunction';
import { showSuccess, loading, showWarning, hideAlert } from '../../../redux/actions/loadStateActions';
import i18next from '../../../../i18n';

class BotGeneralStats extends Component {
    
    constructor(props){
        super(props);
        this.state = {
            ia_activated: false,
            botId: this.props.match.params.id,
            languages:[],
            hideLoad: 0,
            botVersionId:'',
            bot:'',
            buscador:'',
            data:[],
            pagination:{
              current: 1,
              pages: 1
            },
            startDate: moment(),
            endDate: moment(),
            downloadLink: "",
            msSuccess:"",
            msTittle:"",
            viewModal:false ,
            warningModal:false

        }; 

    }


    /*
    * Función que consulta el de bot de base de datos
    * @botid = int (id del bot)
    */
    getBot(botid){
        //obtenemos el token de autenticación
        var auth=JSON.parse(localStorage.getItem('Authorization'));
        var headers={
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer '+ auth.access_token 
        }
  
        return axios({
            method:'get',
            url:HOST_API +'/api/bot/?id='+botid,
            headers:headers
        })
    }

    /* Funciones para manejar los respectivos cambios en los delimitadores
    de fechas.
    */
    handleChangeStart = (d) =>{
        this.setState({startDate: d});
    }
    handleChangeEnd = (d) => {
        this.setState({endDate: d});
    }

    /*
    * Función que consulta info para reportes
    * @page= int (page number)
      @search= bool (indicates if it's a search or not) */

    tableData = (page) => {
        if(this.state.botVersionId)
        {
          this.setState({ hideLoad: 1});

          const filterData = {
              botversion_id: this.state.botVersionId
          };

          if(this.state.startDate != '' && this.state.startDate != null && this.state.endDate != null){
              filterData.period =  UTCFormatter(this.state.startDate);
              if(this.state.endDate != ''){
                  filterData.period +='|'+ UTCFormatter(this.state.endDate);
              }else{
                  filterData.period +='|'+ UTCFormatter(this.state.startDate);
              }
          }else{
            alert(i18next.t('contact_filter_msg'))
            filterData.period = UTCFormatter(moment())+'|'+ UTCFormatter(moment());
            this.setState({
              startDate: moment(),
              endDate: moment()
            });
          }

          this.setState({downloadLink: '/api/reports/file_download?' + qs.stringify(filterData)});
        
          filterData.page = page;
          this.state.pagination.current = page;
          var str = qs.stringify(filterData);

          console.log('filterData',filterData);
          
          this.getHandler(HOST_API +'/api/reports/file_report?'+str).then(
              (res) => {
                  if(res.status==200 ){
                      let aux = this.state.pagination;
                      aux.pages = res.data.pages;
                      this.setState({
                          data: res.data.results, 
                          pagination: aux
                      });
                  }
                  
                  this.setState({ hideLoad: 0});
              }
          ).catch((error) => {
              console.log(error);
              this.setState({ hideLoad: 0});
              if (error.response && error.response.status && error.response.status == 403){
                  this.cerrarSesion();
              }
          });
        }
    }

    async componentDidMount(){
        //obtengo el id del bot enviado como parametro en la url
        const { id } = this.props.match.params
        //guardo el id en el state
        this.setState({
            botId: id
        });
        await this.loadLanguages(); // laod languages
        await this.getBot(this.state.botId).then(async(res) => {
            if(res.status==200){
                if(res.data.bot && res.data.bot.length > 0){
                    const bot = res.data.bot[0];
                    //guardamos en el state la iformación del bot actual y su version
                    this.setState({
                        botVersionId: bot.bot_version[0].botversion_id,
                        bot: bot,
                        botId: bot.id,
                        ia_activated: bot.ia_activated
                    });
                    await this.tableData(1);
                }
            }
        }).catch((error) => {
            console.log(error);
            if (error.response && error.response.status && error.response.status == 403){
                this.cerrarSesion();
            }
        });
    }

  // loads the languages availables.
  loadLanguages() {
    this.getHandler(HOST_API +'/api/language/').
        then((res)=>{
            if(res.status==200){
              var list = [];
              if(res.data.languages){
                for(let i=0; i<res.data.languages.length;i++) {
                  list.push(JSON.stringify(res.data.languages[i]));
                }
              }
              this.setState({ languages: list});
            }else{
              console.log(" No se pudo obtener la lista");
            }
        }).catch((error) => {
            console.log('catch loadLanguages',error);
    }); 
  }

  /* ajax handler for GET calls */
  getHandler(url){
    var auth=JSON.parse(localStorage.getItem('Authorization'));
      var headers={
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': 'Bearer '+auth.access_token 
      }

      return axios({
          method:'get',
          url: url,
          headers:headers
      })
  }


  /* Handler to download the files */

 activateOneSignal = () =>{
  let email = JSON.parse(localStorage.getItem('UserInfo')).email;

  let link = this.state.downloadLink;

  let titulo = 'bot <span style="font-weight:bold">'+this.state.bot.name+' </span> entre las fechas <span style="font-weight:bold">'+moment(new Date(this.state.startDate)).format('DD/MM/YYYY  hh:mm a')+'</span> y <span style="font-weight:bold">'+moment(new Date(this.state.endDate)).format('DD/MM/YYYY  hh:mm a')+'</span>.';

  
  let _this = this;

  let ms = ''

  let title = '' ;
  
      
      var auth=JSON.parse(localStorage.getItem('Authorization'));
        
      //ms = 'Recibira una notificación en la pantalla cuando tu reporte este disponible.';
      //title = 'Atención!'
      //_this.setState({ msSuccess:ms,msTittle:title,warningModal:true});

      var req = new XMLHttpRequest();

      req.onreadystatechange = function() {
          if (req.readyState === 4 && req.status==200) {
            console.log("respuesta: ",req)
           
            title = i18next.t('report_list')

            ms = i18next.t('report_generate_msg') + titulo + ' email <span style="font-weight:bold">'+email+'</span>';

            //alert(ms);
            
            //end = 1;
            _this.setState({ hideLoad: 0, msSuccess:ms,msTittle:title,viewModal:true});

            let a = document.createElement('a');
            a.href = req.response;
            a.download = 'Reporte '+titulo+'.zip';
            a.click();
          
  
          }
          else if(req.readyState === 4)
          {
              console.log("Error",req);
              title = req.statusText;

              if(title==''||req.status==504){
                title = i18next.t('time_limit');
                ms = i18next.t('report_generate_msg2') + titulo + ' <br /> <br /><span style="font-weight:bold;font-style:italic">' + i18next.t("report_generate_msg3") + '<span style="font-weight:bold">'+email+'</span><br /> <br />' + i18next.t("report_generate_msg4") + '</span>';

              }
              else
              {
                ms = req.responseText;
              }
                
              _this.setState({ hideLoad: 0, msSuccess:ms,msTittle:title,errorModal:true});
          }
           
                      
        }

      req.open("GET", link, true);
      req.withCredentials = true;
      req.responseType = "text";
      req.setRequestHeader("Authorization", 'Bearer '+auth.access_token ); 
      req.timeout = 200000;
      req.send();
       
      }
      
//  }
//   );
  
//  }

  downloadFile =  (event)  => {
    
    this.setState({ hideLoad: 1});
    
    this.activateOneSignal();
  
    
  }

  hideAlertModal=(a)=>{
    let state = {}
    state[a] = false
    this.setState(state);
    //this.setState({a:false});
  }

    render() {
        return (
          <div>
            <BotGeneralStatsLayout 
              ia_activated={this.state.ia_activated}
              botId={this.state.botId} 
              buscador={this.state.buscador}
              languages={this.state.languages}
              data ={this.state.data} 
              handleFind ={this.handleFind} 
              tableData ={this.tableData} 
              pagination={this.state.pagination}
              startDate ={this.state.startDate}
              endDate ={this.state.endDate}
              handleChangeStart ={this.handleChangeStart}  
              handleChangeEnd ={this.handleChangeEnd} 
              downloadFile={this.downloadFile}
              />
            <ModalLoad hideLoad={this.state.hideLoad} />
            
            {this.state.viewModal?(
              <SweetAlert success title={this.state.msTittle} show={this.state.viewModal} onConfirm={() => this.hideAlertModal('viewModal')} showConfirm={true}>
                 <hr/>
                 <div style={{'overflow':'auto' ,'height':120+'px', 'width': 98+'%'}} dangerouslySetInnerHTML={{ __html: this.state.msSuccess }} />
              </SweetAlert> 
              
            ):null} 
            {this.state.warningModal?(
              <SweetAlert warning title={this.state.msTittle} show={this.state.warningModal} onConfirm={() => this.hideAlertModal('warningModal')} showConfirm={true}>
                <hr/>
                <div style={{'overflow':'auto' ,'height':120+'px', 'width': 98+'%'}} dangerouslySetInnerHTML={{ __html: this.state.msSuccess }} />
              </SweetAlert>  
            ):null} 
            {this.state.errorModal?(
              
              <SweetAlert error title={this.state.msTittle} show={this.state.errorModal} onConfirm={() => this.hideAlertModal('errorModal')} showConfirm={true}>
                <hr/>
                <div style={{'overflow':'auto' ,'height':120+'px', 'width': 98+'%'}} dangerouslySetInnerHTML={{ __html: this.state.msSuccess }} />
              </SweetAlert>  
            ):null} 
            
          </div>
         
        )
    }
}

export default BotGeneralStats