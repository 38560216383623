import React from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import i18next from '../../../../../i18n';

import MUIDataTable from "mui-datatables";

import {
  createMuiTheme,
    MuiThemeProvider,
    withStyles
  } from "@material-ui/core/styles";

function dataTableFilter(props) 
{
    let options = props.options;
    
    options.rowsPerPageOptions = [ 5, 10, 15,20,50,100,500,1000 ];
    options.textLabels = {
        body: {
          noMatch: i18next.t('registers_not_founded'),
          toolTip: i18next.t('sort'),
          columnHeaderTooltip: column => `${i18next.t('sort')} ${i18next.t('for')} ${column.label}`
        },
        pagination: {
          next: i18next.t('next_page'),
          previous: i18next.t('previous_page'),
          rowsPerPage: i18next.t('rows_for_page') + ':',
          displayRows: i18next.t('of'),
        },
        toolbar: {
          search: i18next.t('search'),
          downloadCsv: i18next.t('download') + " CSV",
          print: i18next.t('print'),
          viewColumns: i18next.t('see_columns'),
          filterTable: i18next.t('filter_table'),
        },
        filter: {
          all: i18next.t('all'),
          title: i18next.t('filters'),
          reset: i18next.t('clear'),
        },
        viewColumns: {
          title: i18next.t('see_columns'),
          titleAria: i18next.t('see_columns_msg'),
        },
        selectedRows: {
          text: i18next.t('row_selected'),
          delete: i18next.t('delete'),
          deleteAria: i18next.t('row_selected_delete'),
        },
      }

    const getMuiTheme = () =>
    createMuiTheme({
          typography: {
            useNextVariants: true,
          },
          overrides: {
            MUIDataTableHeadCell: {
              root: {
                color:'gray',
                fontWeight:'bold',
                fontSize:'1.3rem',
                backgroundColor:'#f7f7f7',
                textAlign:'center',
              },
              // fixedHeader:
              // {
              //   backgroundColor:' #eeeeee'
              // },
              toolButton:{
                display: "inline-flex"
              },
              sortAction:{
                display: "inline"
              },
              sortActive:{
                color: "rgb(90, 90, 90)"
              }
            },
            MUIDataTableBodyCell: {
              root: {
                backgroundColor: "white",
                height:'25px',
                fontSize:'1rem',
                textAlign:'center'
              }
            },
            MUIDataTableToolbar:{
              titleText: {
                color: "gray",
              }
            },
            MuiTableRow:{
              head:{
                height:'40px'
              },
              root:{
                height:'30px'
              }
  
            }
          }
        });
//console.log("Filas",props.data);
//console.log("Columnas",props.columns);
    return (
        <React.Fragment>
             <MuiThemeProvider theme={getMuiTheme()}>
                <MUIDataTable
                title={props.title}
                data={ props.data}
                columns={props.columns}
                options={options}
                />
            </MuiThemeProvider>  
        </React.Fragment>
    );
}
const mapStateToProps = ({ analyticsReducer,  }) => {
	return { analyticsReducer,  };
};

const mapDispatchToProps = {
  
};


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(dataTableFilter));
