

const regex_pass =/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{10,}$/gm;//RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");
const mail = RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
export const messageUser = RegExp("^[b]+[\']+\S+[\']+$");
import i18next from "../../i18n";

export function isValidatedEqualsPasswor(password, password_conf){
  if(password!=password_conf){
    alert("¡Las contraseñas deben coincidir!");
    return false;
  }

  return true;
}


export function isValidatedPassword(password){
    
    if(password && password.length > 0){

      let validationPassword = regex_pass.test(password);

        if(validationPassword === false){

          alert(i18next.t('validate_message2'));
          return false;       
        }

    }else{

      alert(i18next.t('validate_message2'));
      return false;          

    }     

    return true;
  
  }

// Valida que text, este compuesta por solo numero y texto, devuelve true, si es texto o numero
export function isvalidatedOnlyNumberText(text){
  if(/[^A-Za-z0-9]+/.test(text)){
    return false
   }

   if(/[\s]+/.test(text)){
    return false
   }
  return true
}

export function isValidatedUsername(username){

  if(!username || username.length < 6){
    alert(i18next.t('validate_message3'));
    return false;
  }

  if(/[^A-Za-z0-9]+/.test(username)){
    alert(i18next.t('validate_message4'));
    return false;

   }
  
  if(/[\s]+/.test(username)){
    alert(i18next.t('validate_message5'));
    return false;

   }
   return true;
}

// Valida que el formato del email sea valido, devulve true si es correcto sino false.
export function isValidatedEmail(email){
  if(mail.test(email) == false){
    alert(i18next.t('validate_message6'));
    return false;
   }
   return true;
}

// Valida que tenga un rol asignado, devulve true si es correcto sino false.
export function isValidatedRol(rol){
  if(!rol || rol <= 0){
    alert(i18next.t('validate_message7'));
    return false;
   }
   return true;
}

// Valida que la variable este definida y sea diferente de nulo
export function isDefined(variable) {
  return typeof variable !== 'undefined' && variable !== null;
}




