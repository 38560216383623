import React, { Component } from "react";
import axios from "axios";
import qs from "qs";
import {
  FormGroup,
  Input,
  Col,
  Row,
  Label,
  UncontrolledTooltip,
} from "reactstrap";
import { updateBoxOperator } from "../redux/actions/boxActions";
import { setListIntent } from "../redux/actions/botActions";
import { connect } from "react-redux";
import { isEmpty } from "../utils/commonFunction";
import i18next from "../../i18n";

class IASelector extends Component {
  _isMounted = false;
  state = {
    ia: "",
    operator_id: "",
  };

  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    this._isMounted = true;
    this.setState({ ia: this.props.ia, operator_id: this.props.operator_id });
    
    const {listIntent} = this.props.botReducer;

    if(isEmpty(listIntent)){
        this.props.setListIntent();
    }
  };

   componentWillUnmount = () => {
    this._isMounted = false;
    this.linkingOperatorIA(this.state.ia, this.state.operator_id);
   }

  componentWillMount() {
    var auth = JSON.parse(localStorage.getItem("Authorization"));
    if (!auth || !auth.access_token) {
      this.props.history.push("/login");
    }
  }
  /*
   * linkingOperatorIA
   * Link AI intent with a specific operator
   * @ia_id = AI intent
   * @action_id = operator id
   */
  linkingOperatorIA(ia_id, action_id) {
    let data = {
      id: action_id,
      ia_id: ia_id,
    };

    this.putHandler(HOST_API + "/api/scheme/operator/", data)
      .then(async (res) => {
        if (res.status != 200) {
          console.log(" No se pudo enlazar ia con operador");
        }else
        {
          this.props.updateBoxOperator("ia_id", ia_id);
    
          if(this.props.getIAId)
             this.props.getIAId(ia_id);
        }
      })
      .catch((error) => {
        console.log("catch linkingOperatorIA", error);
        if (error.response.status == 403) this.cerrarSesion();
      });

   
  }


  cerrarSesion() {
    let auth = JSON.parse(localStorage.getItem("Authorization"));
    if (auth) localStorage.removeItem("Authorization");
    window.location.href = "/login";
  }

  updateIA (event){
    this.setState({ ia: event.target.value });
    
    this.linkingOperatorIA(event.target.value, this.state.operator_id);
  
  }

  /***** **** *** AJAX HANDLERS **** ****/

  // PUT requests handler
  putHandler(url, data) {
    var auth = JSON.parse(localStorage.getItem("Authorization"));
    var headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: "Bearer " + auth.access_token,
    };

    return axios({
      method: "put",
      url: url,
      data: qs.stringify(data),
      headers: headers,
    });
  }

  render() {

    const {listIntent} = this.props.botReducer;

    const list = listIntent;
    // const options = list.map((item, index) =>
    //     <option key={index + 1} value={item.id}>{item.name}</option>
    // );
    return (
      <Row>
        <Col sm="12">
          <FormGroup>
            <Label>
            {i18next.t('intent')} IA <i className="fa fa-info-circle" id="tooltipia"></i>
            </Label>
            <UncontrolledTooltip placement="right" target="tooltipia">
              {i18next.t('select_intent_operator')}
            </UncontrolledTooltip>

            {list && list.length > 0 ? (
              <Input
                type="select"
                className="inputModal"
                name="ia_id"
                defaultValue={(this.props.ia)?this.props.ia:'none'}
                onChange={(event) => {
                  this.updateIA(event);
                }}
              >
                <option key={"none"} value={"none"}>
                  {""}
                </option>
                ;
                {list.map((k, index) => {
                  if (k.text != "")
                    return (
                      <option key={index} value={k.id}>
                        {k.name}
                      </option>
                    );
                })}
              </Input>
            ) : (
              <div className="alert alert-danger" role="alert">
                {i18next.t('ia_message')}
              </div>
            )}
            {/* <Input type="select" name="select" onChange={(event) => { this.updateIA(event) }} defaultValue={(this.props.ia)?this.props.ia:''}>
                            <option value='none' key={0}>Seleccione</option>
                            {options}
                        </Input> */}
          </FormGroup>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ botReducer,boxReducer,homeReducer, componentMountReducer }) => {
  return { botReducer, boxReducer,homeReducer, componentMountReducer };
 };
 
 const mapDispatchToProps = {
  updateBoxOperator,
  setListIntent
 };
 
 export default connect(mapStateToProps, mapDispatchToProps)(IASelector);


