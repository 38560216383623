import React,{Component} from "react";
import {  Button,  Modal,  ModalHeader,  ModalBody,  ModalFooter, 
   Form,  FormGroup,  Label,  Input,  Col,  Row, FormFeedback  } from "reactstrap";
import {isValidatedEmail,isValidatedRol,isValidatedUsername,isValidatedPassword,
  isValidatedEqualsPasswor} from "../../../utils/isValidate";
import {isAuthorizationValid, getUserInfoLocalStorage, cerrarSesion} from '../../../utils/commonFunction'
import ModalLoad from '../../../components/modals/Loading/moda-loading';
import { saveEditUser } from "../../../redux/actions/companyActions";
import { connect } from "react-redux";
import i18next from "../../../../i18n";

//  Assets
import "./modal-user.css";
import "../../../utils/loading-modal.css";


class ModalUser extends Component {

  constructor(props) {
    super(props);     
    this.state = {
      loading: true,
      edit:true,
      first_name: '',
      last_name: '',
      username: '',
      id: '',
      company_id: '',
      email: '',
      roltype_id:'',
      password:'',
      password_conf:'', 
      rol:'',
      company:'',
      //password_expiration:true
    };
   
    this.handleChange=this.handleChange.bind(this);
    this.clickUpdateUser=this.clickUpdateUser.bind(this);    
  }

  componentWillMount(){
    isAuthorizationValid();
  }

  componentDidMount() {
    this.fetchData();
  }



  fetchData = async () => {
      const dataUser= getUserInfoLocalStorage();  
      this.setState({
         loading: false,
         ...dataUser
         });   
  };

  handleChange = e => {
    this.setState({
     [e.target.name]: e.target.value,
     edit: false
    });  
  };

  clickUpdateUser = async (e) =>{   
    if(
      isValidatedPassword(this.state.password)&&
      isValidatedUsername(this.state.username) &&
      isValidatedRol(this.state.roltype_id) &&
      isValidatedEmail(this.state.email) &&
      isValidatedEqualsPasswor(this.state.password_conf,this.state.password) ){     
        
        const data = { 
          ...this.state,        
          user_id: this.state.id,
        };  

        if(this.state.password.length > 0){
              data.password = this.state.password
        }

        const { saveEditUser } = this.props;

        // saving user edited
        await saveEditUser(data, ()=>{
          //cerrarSesion();
        });

         
      }   
  }

render() {

  return (
    <div>
      <Modal
        className={"modalRight "}
        isOpen={this.props.isOpen}
        toggle={this.props.toggleModal}
        fade
      >
        <ModalHeader toggle={this.props.toggleModal}  >{this.props.title}
        <div className="lds-ellipsis"   hidden={!this.state.loading}   ><div></div><div></div><div></div><div></div></div>
      
        </ModalHeader>
        <ModalBody className="container">   
          <div className="container-fluid  ">     
            <Form
              id="form-editUser"
              action="/admin/create_bot"
              method="post"
              role="form"
            >
              <FormGroup>
                <Row>
                  <Col sm="3">
                    <Label for="Name" className="control-label">
                      {i18next.t('names')+': '}
                    </Label>
                  </Col>
                  <Col sm="9">
                    <Input
                      type="text"
                      className="inputModal"
                      id="first_name"
                      name="first_name"
                      placeholder={i18next.t('names')}
                      defaultValue={this.state.first_name}
                      onChange={this.handleChange}
                      required
                  
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col sm="3">
                    <Label for="lastName" className="control-label" >
                      {i18next.t('last_name')+': '}
                    </Label>
                  </Col>
                  <Col sm="9">
                    <Input
                      type="text"
                      className="inputModal"
                      id="last_name"
                      name="last_name"
                      placeholder={i18next.t('last_name')}
                      defaultValue={this.state.last_name}
                      onChange={this.handleChange}
                      required
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col sm="3">
                    <Label
                      for="username"
                      className="control-label required-field"
                    >
                     * {i18next.t('username')+': '}
                    </Label>
                  </Col>
                  <Col sm="9">
                    <Input
                      type="text"
                      className="inputModal"
                      id="username"
                      name="username"
                      placeholder="Nombre de usuario"
                      defaultValue={this.state.username}
                      onChange={this.handleChange}
                      required
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col sm="3">
                    <Label
                      for="password"
                      className="control-label required-field"
                    >
                      {i18next.t('password')+': '}
                    </Label>
                  </Col>
                  <Col sm="9">
                    <Input
                      type="password"
                      className="inputModal"
                      id="password"
                      name="password"
                      placeholder={i18next.t('password')}
                      onChange={this.handleChange}
                      required
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col sm="3">
                    <Label
                      for="confirmPassword"
                      className="control-label required-field"
                    >
                      {i18next.t('confirm_pwd')+': '}
                    </Label>
                  </Col>
                  <Col sm="9">

                    <Input
                      type="password"
                      className="inputModal"
                      id="password_conf"
                      name="password_conf"
                      placeholder={i18next.t('confirm_pwd')}
                      onChange={this.handleChange}
                      required
                    />


                  {/*<small className="text-danger">
                   {i18next.t('try_again')}
                  </small>*/}
                  
                  </Col>

                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col sm="3">
                    <Label for="email" className="control-label required-field">
                     * Email:
                    </Label>
                  </Col>
                  <Col sm="9">
                    <Input
                      type="email"
                      className="inputModal"
                      id="email"
                      name="email"
                      placeholder="Email:"
                      defaultValue={this.state.email}
                      onChange={this.handleChange}
                      required
                    />
                  </Col>
                </Row>
              </FormGroup>
             
              <FormGroup>
                <Row>
                  <Col sm="3">
                    <Label for="rol" className="control-label required-field">
                     *  {i18next.t('rol')+': '}
                    </Label>
                  </Col>
                  <Col sm="9">
                    <Input
                      type="text"
                      className="inputModal"
                      id="rol"
                      name="rol"
                      placeholder={i18next.t('rol')}
                      value={this.state.rol}
                      readOnly={true}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col sm="3">
                    <Label
                      for="compañía"
                      className="control-label required-field"
                    >
                     * {i18next.t('company')+': '}
                    </Label>
                  </Col>
                  <Col sm="9">
                    <Input
                      type="text"
                      className="inputModal"
                      id="company"
                      name="company"
                      placeholder={i18next.t('company')}
                      value={this.state.company}
                      readOnly={true}
                    />
                  </Col>
                </Row>
              </FormGroup> 

              {/*<FormGroup check>
                  <Input
                      type="checkbox"
                      checked={this.state.password_expiration}
                      onChange={this.handleChange}
                      className="password_expiration mr-1"
                      name="password_expiration"
                  />
                  {' '}{' '}
                  <Label check className="ml-1">
                      {i18next.t('change_password_editar_60')}
                  </Label>
              </FormGroup>*/}

              <div className="divInline">
                <small className="colorRed">*</small>
                <small>{i18next.t('field_required')} </small>
              </div>
            </Form>
          </div>
        </ModalBody>
        <ModalFooter className="footerUser">
        
          <Button
            color="success"           
            className="btn-editUser-modal"
            size="lg"
            onClick={this.clickUpdateUser}
            hidden={this.state.edit}            
         
          >
            {i18next.t('save')}
          </Button>
         
        
        </ModalFooter>
      </Modal>
      <ModalLoad/>
    </div>
  );
}
}

const mapDispatchToProps = {
  saveEditUser
};

export default connect(null, mapDispatchToProps)(ModalUser);
