import React, { Component } from "react";
import { Row, Col, Button, FormGroup } from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import {
  isAuthorizationValid,
  getRolUser,
  UTCFormatter,
} from "../utils/commonFunction";
import { APPLICANT } from "../redux/listComponents";
import {
  isDidMount,
  willUnMount,
} from "../redux/actions/componentMountActions";
import {
  changeStartDateDashboard,
  changeEndDateDashboard,
  changeDatesDashboard,
  getBuilderQuery,
  getPagination,
  interactionReportTable,
  loadReportSet,
  iconLoad,
  iconHideLoad,
} from "../redux/actions/analyticsActions";
import moment from "moment";
import { ContextConsumer } from "react-is";
import i18next from "../../i18n";

export class Date extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAdmin: false,
      isSuperUser: false,
      startDate: props.analyticsReducer.startDateDashboard, //moment().subtract(1,'months'),
      endDate: props.analyticsReducer.endDateDashboard, //moment(),
    };
  }
  componentWillMount() {
    isAuthorizationValid();
    const { isAdmin = false, isSuper = false } = getRolUser();
    this.setState({
      isAdmin: isAdmin,
      isSuperUser: isSuper,
    });
  }
  
  async componentDidMount() {
    this.props.isDidMount(APPLICANT.HOME());
  }

  showContent = () => {
    const { isLogin } = getRolUser();

    if (isLogin) {
      return (
        <Row>
          <Col xs={4} md={4} lg={4} className="dp">
            <a>{i18next.t('start_date')+':'} </a>
            <DatePicker
              selected={this.state.startDate} //{this.props.analyticsReducer.startDateDashboard}//{this.props.startDate}
              selectsStart
              startDate={this.state.startDate} //{  this.props.analyticsReducer.startDateDashboard}//{this.props.startDate}
              endDate={this.state.endDate} //{  this.props.analyticsReducer.endDateDashboard}//{this.props.endDate}
              onChange={(d) => {
                this.changeDateStart(d);
              }} //{this.props.changeStartDateDashboard(d)}}//{this.props.handleChangeStart}
              //isClearable={true}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              //className="input-border"
              dateFormat="YYYY/MM/DD  hh:mm a"
              className="form-control"
              timeInputLabel={i18next.t('hour')}
            />
          </Col>
          <Col xs={4} md={4} lg={4} className="dp">
            {i18next.t('end_date')+': '}
            <DatePicker
              selected={this.state.endDate} //{  this.props.analyticsReducer.endDateDashboard}//{this.props.endDate}
              selectsEnd
              startDate={this.state.startDate} //{  this.props.analyticsReducer.startDateDashboard}//{this.props.startDate}
              endDate={this.state.endDate} //{  this.props.analyticsReducer.endDateDashboard}//{this.props.endDate}
              onChange={(d) => {
                this.changeDateEnd(d);
              }} //{this.props.changeEndDateDashboard(d)}}//{this.props.handleChangeEnd}
              minDate={this.state.startDate} //{  this.props.analyticsReducer.startDateDashboard}//{this.props.startDate}
              //isClearable={true}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              //className="input-border"
              dateFormat="YYYY/MM/DD  hh:mm a"
              className="form-control"
              timeInputLabel={i18next.t('hour')}
              showTimeInput
            />
          </Col>
          <Col xs={2} md={2} lg={2} className="dp_b">
            <Button
              id="btn_filtrar"
              className="btn btn-guarumo-md"
              onClick={(e) => {
                this.reload();
              }}
            >
              {" "}
              {i18next.t('filter')}
            </Button>
          </Col>
        </Row>
      );
    }
  };

  render() {
    return this.showContent();
  }

  changeDateStart(d) {
    this.setState({ startDate: d });
  }
  changeDateEnd(d) {
    this.setState({ endDate: d });
  }

  async reload() {
    //console.log("Fecha inicial:"+UTCFormatter(this.state.startDate)+" Fecha final: "+UTCFormatter(this.state.endDate));

    //Muestra el icono de carga
    await this.props.iconLoad();

    //Actualiza las fechas
    await this.props.changeDatesDashboard(
      this.state.startDate,
      this.state.endDate
    );

    //Genera la consulta y la ejecuta
    //console.log("onFilter2: ", this.props.onFilter);
    if (typeof this.props.onFilter != "undefined") {
      await this.props.onFilter();
    } else {
      // await this.props.interactionReportTable(1);
    }

    //Oculta el icono de carga
    await this.props.iconHideLoad();
  }
}

const mapStateToProps = ({ analyticsReducer }) => {
  return { analyticsReducer };
};

const mapDispatchToProps = {
  isDidMount,
  willUnMount,
  changeStartDateDashboard,
  changeEndDateDashboard,
  changeDatesDashboard,
  getBuilderQuery,
  interactionReportTable,
  getPagination,
  loadReportSet,
  iconLoad,
  iconHideLoad,
};

export default connect(mapStateToProps, mapDispatchToProps)(Date);
