import { BOX_ACTIONS_TYPES } from '../types/boxActionsTypes';
import moment from 'moment';

const INITIAL_STATE = {
    selectedBoxOperator:[],
    configBoxOperator:[],
    variablesBoxOperator:[],
    dinamicVariablesBoxOperator:[],
    exit_var:false,
    botmsg_text:'',
    avatarid:'',
    listAllOperators:[],
    varList:[],
    node:[],
    varListSelected:[],
    modalViewBoxAgent:false,
    modalViewBoxBlockchain:false,
    modalViewBoxCalendar:false,
    modalViewBoxCarousel:false,
    modalViewBoxCatalog:false,
    modalViewBoxCReport:false,
    modalViewBoxLocation:false,
    modalViewBoxMultimedia:false,
    modalViewBoxPayment:false,
    modalViewBoxScanQR:false,

    

    modalViewBoxApi:false,
    modalViewBoxConditional:false,
    modalViewBoxDesuscription:false,
    modalViewBoxButtons:false,
    modalViewBoxEmail:false,
    modalViewBoxForm:false,
    modalViewBoxGoTo:false,
    modalViewBoxQuickReply:false,
    modalViewBoxText:false,
    modalViewBoxSMS:false,
    modalViewBoxTrigger:false,
    modalViewBoxUploadFile:false,
    modalViewBoxVariable:false,
    modalViewBoxWebview:false,

    msmbtnPostback:'',
    data_dict:{},
    data_ws:'',
    activeItem:"1",
    statement_id:'',
    listTypeCalendar:[],
    listTypeScanner:[],
    listTypeCatalog:[],
    listTypeSourceCatalog:[{id:'shopify',name:'Shopify'},{id:'vtex',name:'vTex'},{id:'woocommerce',name:'Woocommerce'},{id:'variable',name:'Variable'}],
    listTypeSourcePayment:[{id:'wompi',name:'Wompy'},{id:'epayco',name:'ePayco'},{id:'paymentsway',name:'Payments Way'}],
    listCurrencyPayment:[{id:'COP',name:'COP'},{id:'USD',name:'USD'}],
    selectedTypeCalendar:[],
    listTypeLocation:[],
    selectedTypeLocation:[],
    listLocation:[],
    selectedListLocation:[],
    selectedTypeMultimedia:[],
    urlMultimediaPreview:'',
    fileMultimediaPreview:'',
    contentType:'',
    startDate:moment(),
    endDate:null,
    showTimeSelect:false,
    timeIntervals:30,
    minDate:null,
    maxDate: null,
    maxTime: null,
    minTime: null,
    excludeDates: null,
    excludeTimes: null,
    listContractBlockchain:[],
    listProviderBlockchain:[],
    selectedContractBlockchain:[],
    selectedProviderBlockchain:[],
    text_blocks:{},
    buttons:{},
    head:'',
    postback_buttons:{}

}

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {

         /**
         * Reducer Config Box Operator
         */
        case BOX_ACTIONS_TYPES.selectedBoxOperator():
            return {
                ...state,
                selectedBoxOperator: action.payload,
            };
        case BOX_ACTIONS_TYPES.requestGetListBoxOperator():
            return {
                ...state,
                error:'',
            };

        case BOX_ACTIONS_TYPES.successGetListBoxOperator():
            return {
                ...state,
                listAllOperators: action.payload,
                error:'',
            };
        case BOX_ACTIONS_TYPES.errorGetListBoxOperator():
            return {
                ...state,
                error: action.payload,
            };

        case BOX_ACTIONS_TYPES.notFoundGetListBoxOperator():
            return {
                ...state,
                listAllOperators: [],
                error: action.payload,
            };

         /**
         * Reducer Config Box Operator
         */
       
        case BOX_ACTIONS_TYPES.requestGetConfigBoxOperator():
            return {
                ...state,
                error:'',
            };

        case BOX_ACTIONS_TYPES.successGetConfigBoxOperator():
            return {
                ...state,
                configBoxOperator: action.payload,
                error:'',
            };
        case BOX_ACTIONS_TYPES.errorGetConfigBoxOperator():
            return {
                ...state,
                error: action.payload,
            };

        case BOX_ACTIONS_TYPES.notFoundGetConfigBoxOperator():
            return {
                ...state,
                configBoxOperator: [],
                error: action.payload,
            };

        case BOX_ACTIONS_TYPES.toggleModalViewBoxCalendar():
            return {
                ...state,
                modalViewBoxCalendar: !state.modalViewBoxCalendar,
            };
        case BOX_ACTIONS_TYPES.toggleModalViewBoxScanQR():
            return {
                ...state,
                modalViewBoxScanQR: !state.modalViewBoxScanQR,
            };
        case BOX_ACTIONS_TYPES.toggleModalViewBoxCatalog():
            return {
                ...state,
                modalViewBoxCatalog: !state.modalViewBoxCatalog,
            };
        case BOX_ACTIONS_TYPES.toggleModalViewBoxPayment():
            return {
                ...state,
                modalViewBoxPayment: !state.modalViewBoxPayment,
            };
        case BOX_ACTIONS_TYPES.toggleModalViewBoxBlockchain():
            return {
                ...state,
                modalViewBoxBlockchain: !state.modalViewBoxBlockchain,
            };
        case BOX_ACTIONS_TYPES.toggleModalViewBoxMultimedia():
            return {
                ...state,
                modalViewBoxMultimedia: !state.modalViewBoxMultimedia,
            };    
        case BOX_ACTIONS_TYPES.toggleModalViewBoxLocation():
            return {
                ...state,
                modalViewBoxLocation: !state.modalViewBoxLocation,
            };
        case BOX_ACTIONS_TYPES.toggleModalViewBoxCarousel():
            return {
                ...state,
                modalViewBoxCarousel: !state.modalViewBoxCarousel,
            };
        case BOX_ACTIONS_TYPES.toggleModalViewBoxAgent():
            return {
                ...state,
                modalViewBoxAgent: !state.modalViewBoxAgent,
            };
        case BOX_ACTIONS_TYPES.toggleModalViewBoxCReport():
            return {
                ...state,
                modalViewBoxCReport: !state.modalViewBoxCReport,
            };
        case BOX_ACTIONS_TYPES.toggleModalViewBoxWebview():
            return {
            ...state,
            modalViewBoxWebview: !state.modalViewBoxWebview,
            };
        case BOX_ACTIONS_TYPES.toggleModalViewBoxVariable():
            return {
            ...state,
            modalViewBoxVariable: !state.modalViewBoxVariable,
            };
        case BOX_ACTIONS_TYPES.toggleModalViewBoxUploadFile():
            return {
            ...state,
            modalViewBoxUploadFile: !state.modalViewBoxUploadFile,
            };
        case BOX_ACTIONS_TYPES.toggleModalViewBoxTrigger():
            return {
            ...state,
            modalViewBoxTrigger: !state.modalViewBoxTrigger,
            };
        case BOX_ACTIONS_TYPES.toggleModalViewBoxSMS():
            return {
            ...state,
            modalViewBoxSMS: !state.modalViewBoxSMS,
            };
        case BOX_ACTIONS_TYPES.toggleModalViewBoxText():
            return {
            ...state,
            modalViewBoxText: !state.modalViewBoxText,
            };
        case BOX_ACTIONS_TYPES.toggleModalViewBoxQuickReply():
            return {
            ...state,
            modalViewBoxQuickReply: !state.modalViewBoxQuickReply,
            };
        case BOX_ACTIONS_TYPES.toggleModalViewBoxGoTo():
            return {
            ...state,
            modalViewBoxGoTo: !state.modalViewBoxGoTo,
            };
        case BOX_ACTIONS_TYPES.toggleModalViewBoxForm():
            return {
            ...state,
            modalViewBoxForm: !state.modalViewBoxForm,
            };
        case BOX_ACTIONS_TYPES.toggleModalViewBoxEmail():
            return {
            ...state,
            modalViewBoxEmail: !state.modalViewBoxEmail,
            };
        case BOX_ACTIONS_TYPES.toggleModalViewBoxButtons():
            return {
            ...state,
            modalViewBoxButtons: !state.modalViewBoxButtons,
            };
        case BOX_ACTIONS_TYPES.toggleModalViewBoxDesuscription():
            return {
            ...state,
            modalViewBoxDesuscription: !state.modalViewBoxDesuscription,
            };
        case BOX_ACTIONS_TYPES.toggleModalViewBoxConditional():
            return {
            ...state,
            modalViewBoxConditional: !state.modalViewBoxConditional,
            };
        case BOX_ACTIONS_TYPES.toggleModalViewBoxApi():
            return {
            ...state,
            modalViewBoxApi: !state.modalViewBoxApi,
            };
        case BOX_ACTIONS_TYPES.changeActiveTabBoxOperator():
            return {
                ...state,
                activeItem: action.payload ,
                error: '',
                //loagin: false,
            };
        case BOX_ACTIONS_TYPES.setExitVar():
            return {
                ...state,
                exit_var: action.payload ,
            };

            case BOX_ACTIONS_TYPES.setNode():
                return {
                    ...state,
                    node: action.payload ,
                };
         /**
         * Reducer Box Calendar
         */

        case BOX_ACTIONS_TYPES.requestGetListTypeCalendar():
            return {
                ...state,
                error:'',
            };

        case BOX_ACTIONS_TYPES.successGetListTypeCalendar():
            return {
                ...state,
                listTypeCalendar: action.payload,
                error:'',
            };

        case BOX_ACTIONS_TYPES.setListTypeScanner():
            return {
                ...state,
                listTypeScanner: action.payload,
                error:'',
            };
        case BOX_ACTIONS_TYPES.setListTypeCatalog():
            return {
                ...state,
                listTypeCatalog: action.payload,
                error:'',
            };
        case BOX_ACTIONS_TYPES.errorGetListTypeCalendar():
            return {
                ...state,
                error: action.payload,
            };

        case BOX_ACTIONS_TYPES.notFoundGetListTypeCalendar():
            return {
                ...state,
                listTypeCalendar: [],
                error: action.payload,
            };
        case BOX_ACTIONS_TYPES.setStatement():
            return {
                ...state,
                statement_id: action.payload,
            };
        case BOX_ACTIONS_TYPES.setBotmsgText():
            return {
                ...state,
                botmsg_text: action.payload,
            };
        case BOX_ACTIONS_TYPES.setAvatarid():
            return {
                ...state,
                avatarid: action.payload,
            };
         /**
         * Reducer Box Location
         */

        case BOX_ACTIONS_TYPES.requestGetListTypeLocation():
            return {
                ...state,
                error:'',
            };

        case BOX_ACTIONS_TYPES.successGetListTypeLocation():
            return {
                ...state,
                listTypeLocation: action.payload,
                error:'',
            };
        case BOX_ACTIONS_TYPES.errorGetListTypeLocation():
            return {
                ...state,
                error: action.payload,
            };

        case BOX_ACTIONS_TYPES.notFoundGetListTypeLocation():
            return {
                ...state,
                listTypeLocation: [],
                error: action.payload,
            };
        case BOX_ACTIONS_TYPES.selectedTypeLocation():
            return {
                ...state,
                selectedTypeLocation: action.payload,
            };
        case BOX_ACTIONS_TYPES.selectedTypeMultimedia():
            return {
                ...state,
                selectedTypeMultimedia: action.payload,
            };
            
        
         // Lista de localizaciones
         

        case BOX_ACTIONS_TYPES.requestGetListLocation():
            return {
                ...state,
                error:'',
            };

        case BOX_ACTIONS_TYPES.successGetListLocation():
            return {
                ...state,
                listLocation: action.payload,
                error:'',
            };
        case BOX_ACTIONS_TYPES.errorGetListLocation():
            return {
                ...state,
                error: action.payload,
            };

        case BOX_ACTIONS_TYPES.notFoundGetListLocation():
            return {
                ...state,
                listLocation: [],
                error: action.payload,
            };
        case BOX_ACTIONS_TYPES.selectedListLocation():
            return {
                ...state,
                selectedListLocation: action.payload,
            };
        case BOX_ACTIONS_TYPES.setUrlMultimediaPreview():
            return {
                ...state,
                urlMultimediaPreview: action.payload,
            };
        case BOX_ACTIONS_TYPES.setFileMultimediaPreview():
            return {
                ...state,
                fileMultimediaPreview: action.payload,
            };
        case BOX_ACTIONS_TYPES.setContentType():
            return {
                ...state,
                contentType: action.payload,
            };
        case BOX_ACTIONS_TYPES.setVariablesBoxOperator():
            return {
                ...state,
                variablesBoxOperator: action.payload,
            };
        case BOX_ACTIONS_TYPES.setDinamicVariablesBoxOperator():
            return {
                ...state,
                dinamicVariablesBoxOperator: action.payload,
            };
        case BOX_ACTIONS_TYPES.setVarList():
            return {
                ...state,
                varList: action.payload,
        };
        case BOX_ACTIONS_TYPES.setVarListSelected():
            return {
                ...state,
                varListSelected: action.payload,
        }; 
        case BOX_ACTIONS_TYPES.setData_ws():
            return {
                ...state,
                data_ws: action.payload,
            };
        case BOX_ACTIONS_TYPES.setMsmbtnPostback():
            return {
                ...state,
                msmbtnPostback: action.payload,
            };
        case BOX_ACTIONS_TYPES.setData_dict():
            return {
                ...state,
                data_dict: action.payload,
            };
        case BOX_ACTIONS_TYPES.selectedTypeCalendar():
            return {
                ...state,
                selectedTypeCalendar: action.payload,
            };
        
        case BOX_ACTIONS_TYPES.setStartDate():
            return {
                ...state,
                startDate: action.payload,
            };
            case BOX_ACTIONS_TYPES.setEndDate():
        return {
            ...state,
            endDate: action.payload,
        };
        case BOX_ACTIONS_TYPES.setShowTimeSelect():
        return {
            ...state,
            showTimeSelect: action.payload,
        };
        case BOX_ACTIONS_TYPES.setTimeIntervals():
        return {
            ...state,
            timeIntervals: action.payload,
        };
        case BOX_ACTIONS_TYPES.setMinDate():
        return {
            ...state,
            minDate: action.payload,
        };
        case BOX_ACTIONS_TYPES.setMaxDate():
        return {
            ...state,
            maxDate: action.payload,
        };
        case BOX_ACTIONS_TYPES.setMaxTime():
        return {
            ...state,
            maxTime: action.payload,               
        };
        case BOX_ACTIONS_TYPES.setMinTime():
        return {
            ...state,
            minTime: action.payload,
        };
        case BOX_ACTIONS_TYPES.setExcludeDates():
        return {
            ...state,
            excludeDates: action.payload,
        };
        case BOX_ACTIONS_TYPES.setSelectedProviderBlockchain():
        return {
            ...state,
            selectedProviderBlockchain: action.payload,
        };
        case BOX_ACTIONS_TYPES.setSelectedContractBlockchain():
        return {
            ...state,
            selectedContractBlockchain: action.payload,
        };
        case BOX_ACTIONS_TYPES.setListProviderBlockchain():
        return {
            ...state,
            listProviderBlockchain: action.payload,
        };
        case BOX_ACTIONS_TYPES.setListContractBlockchain():
        return {
            ...state,
            listContractBlockchain: action.payload,
        };
        case BOX_ACTIONS_TYPES.setTextBlock():
            return {
                ...state,
                text_blocks: action.payload,
            };
        case BOX_ACTIONS_TYPES.setButtons():
            return {
                ...state,
                buttons: action.payload,
            };
        case BOX_ACTIONS_TYPES.setPostback_buttons():
            return {
                ...state,
                postback_buttons: action.payload,
            };
        case BOX_ACTIONS_TYPES.setHead():
            return {
                ...state,
                head: action.payload,
            };
        
        default: return state;
	};
};