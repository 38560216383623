// Dependencies
import React, {Component} from 'react';
import { 
    Button, Modal,  ModalHeader, ModalBody, ModalFooter,
    Form, FormGroup, Col, Row, Label, UncontrolledTooltip,
    Input,Nav,NavItem,NavLink,TabContent,TabPane
} from 'reactstrap';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';

// Components
import Mentions from '../../../../components/mentions/mentions';
import MentionsInput from '../../../../components/mentions/metions-alone';
import Emoji from '../../../../components/emoji-picker';
import ModalUrl from '../../../../components/modals/shorturl/modal-shorturl';
import TabAdvanced from "../../../../components/box/tab_advanced";

// Assets
import "../../../../components/modals/modal.css";

import TitleOperator from "../../../../components/title-operator";
import classnames from "classnames";
import i18next from '../../../../../i18n';
class SMSMLayout extends Component {

    constructor(props){
        super(props)
        this.state = {
            activeTab: '1'
            };
            this.toggleTab = this.toggleTab.bind(this);
    }
    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
          this.setState({
            activeTab: tab
          });
        }
    }

    render(){
        return (
            <React.Fragment>
                <Modal className="sms" isOpen={this.props.isOpen} toggle={this.props.closeModal} fade size="lg">
                     <ModalHeader toggle={this.props.closeModal}>{i18next.t('set_up')} SMS </ModalHeader>
                    <ModalBody>
                    <Form id="SMSM-form" role="form">  
                        <Nav tabs>
                                <NavItem>
                                    <NavLink
                                    className={classnames({ active: this.state.activeTab === '1' })}
                                    onClick={() => { this.toggleTab('1'); }} >
                                    {i18next.t('basic')}
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                    className={classnames({ active: this.state.activeTab === '2' })}
                                    onClick={() => { this.toggleTab('2'); }}>
                                    {i18next.t('advanced')}
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={this.state.activeTab}>
                                <TabPane tabId="1">
                            <Row>
                                <Col md={12}>
                                            
                                    <Label>{i18next.t('sended_time')}</Label>
                                    <Input type="select" name="select" onChange={(event) => {this.props.showMetion(event) }}  defaultValue={this.props.config}>
                                        <option value="">- {i18next.t('select_one')} -</option>
                                        <option value="0">{i18next.t('immediate')}</option>
                                        <option value="programado">{i18next.t('scheduled')}</option>
                                    </Input>
                                    <div hidden={this.props.config == '0'}>
                                         <Row>
                                            <Col md={6}>
                                                <Label>{i18next.t('number')}</Label>
                                                <MentionsInput  
                                                k="time" 
                                                varList={this.props.varList}
                                                  defaultValue={this.props.time || ''}
                                                    updateItem={this.props.updateItem} 
                                                    maxlength={'20'} msg_error={i18next.t('limit_allowed') + ' 20'}/>
                                            </Col>
                                            <Col md={6}>
                                                <Label>{i18next.t('type')}</Label>
                                                <Input
                                                type="select"
                                                className="inputModal"
                                                id="tipoEnvio"
                                                name="tipoEnvio"
                                                onChange={this.props.changeTypeTime}
                                                defaultValue={this.props.typeTime}
                                                required> 
                                                    <option key={"tipo-0"} value='' >-{i18next.t('select_option')}-</option>
                                                    <option key={"tipo-1"} value='dias' >{i18next.t('days')}</option>
                                                    <option key={"tipo-2"} value='horas' >{i18next.t('hours')}</option>
                                                </Input> 
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                <Col sm="12" md="6">
                                    <FormGroup>
                                        <Label>{i18next.t('phone_celnumber')+': '} <i className="fa fa-info-circle" id="tooltipcelular"></i></Label>
                                        <UncontrolledTooltip placement="right" target="tooltipcelular"> {i18next.t('phone_number_to_msg')}</UncontrolledTooltip>
                                    </FormGroup>
                                    <Mentions className='td-flujo' varList={this.props.varList} defaultValue={this.props.celular} k="celular" updateItem={this.props.updateItem} />
                                </Col>
                                <Col sm="12" md="6">
                                    <FormGroup>
                                        <Label>{i18next.t('message')+': '} <i className="fa fa-info-circle" id="tooltipmensaje"></i></Label>
                                        <UncontrolledTooltip placement="right" target="tooltipmensaje"> {i18next.t('sms_message_info')}</UncontrolledTooltip>
                                    </FormGroup>
                                    <Mentions className='td-flujo' varList={this.props.varList} defaultValue={this.props.mensaje} k="mensaje" maxlength="160" updateItem={this.props.updateItem} />
                                    <div className="options-bar-input"  style={{'right':'15px','bottom':'-5px'}}>
                                        <Emoji id={'mensaje'} callEmoji={this.props.callEmoji} type="item"/>
                                        <Button color="info" size="lg" onClick={() => this.props.toggleModalUrl('mensaje') }>
                                            <FontAwesomeIcon icon={faLink} />
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                            </TabPane>
                                <TabPane tabId="2">
                                    <TabAdvanced isKeywordValid={this.props.isKeywordValid} ></TabAdvanced>
                                 </TabPane>
                            </TabContent>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Col sm="12" align="right">
                            <Button color="success" className="btn-guarumo-modal-sm" type="button" onClick={this.props.toggleModuleModal} >{i18next.t('finish')}</Button>
                        </Col>
                    </ModalFooter>
                
                </Modal>
                <ModalUrl isOpen={this.props.isOpenUrl} toggleModal={this.props.toggleModalUrl} bindValue={this.props.getUrl} input={this.props.inputStatement}/>
                <ToastsContainer store={ToastsStore} />
            </React.Fragment>
        );
    }


}

  
export default SMSMLayout
