import { AbstractNodeFactory } from "storm-react-diagrams";
import React from "react";

import FRMNodeWidget from "./FRMNodeWidget";
import FRMNodeModel from "./FRMNodeModel";

class FRMNodeFactory extends AbstractNodeFactory {
	constructor() {
		super("frm");
	}

	generateReactWidget(diagramEngine, node) {
		return <FRMNodeWidget node={node} />
	}

	getNewInstance() {
		return new FRMNodeModel();
	}
}

export default FRMNodeFactory