import React, {useState} from 'react';
import { DATABASE_ACTIONS } from '../../redux/types/databaseActionTypes';
import i18next from '../../../i18n';
import Admin from '../admin/containers/admin';
import SliceMenuApp from '../admin/components/slice-menu'
import { 
  Col,
  Row,
  Button, 
  Container,
  Modal, 
  ModalHeader, 
  ModalBody, 
  ModalFooter,
  FormGroup, 
  Label,
  Input,
  UncontrolledTooltip,
  Tooltip,
  Table
} from 'reactstrap';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ToolbarApp from '../admin/components/toolbar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash,faUser, faCheck, faCopy, faEye, faDownload } from '@fortawesome/free-solid-svg-icons'
import AdminMenuOptions from '../menus/admin-menu-options';
import './companies-layout.css';
import { uploadFileDinamic, uploadFileDinamicUpdate, showDatabaseCollect, uploadFileDinamicVisualizar, filtrarCollection, visualizarLogs,uploadDatabaseName } from '../../redux/actions/databaseAction';
import {setSelectedCompany} from '../../redux/actions/analyticsActions'
import { getRolUser } from '../../utils/commonFunction';
import DataTableFilter from '../analytics/infoViz/common/dataTableFilter'
import Filter from '../../components/filters/filter';
import FilterSearch from '../../components/filters/filterSearch';
import FilterLogs from '../../components/filters/filterLogs';

import SelectedCompanies from '../companies/selected-company';

    /* IMAGE */
import newDatabase from '../../../static/img/menu-icons/nuevo_bot.svg'
import './FileUploadButton.css'; 



function DataBaseLayout(props) {
    const { listCollection } = props.databaseReducer;

    const newListCollection = listCollection.map((item, index) => ({
      name: item
    }));
  
    
    const [addRegister, setAddRegister] = useState(true);
    const [deleteRegister, setDeleteRegister] = useState(false);
    const [nameCompany, setNameCompany] = useState(null);

    const [showModalAlert, setShowModalAlert] = useState(false);
    
    const handleDelete = async () => {
      // Lógica para eliminar el registro
      await handleSubmitUpdate();
      await setShowModalAlert(false); // Cerrar el modal después de eliminar el registro
    };

    const [error, setError] = useState('');
  
    const handleAddRegisterChange = () => {
      setAddRegister(true);
      setDeleteRegister(false);
    };
  
    const handleDeleteRegisterChange = () => {
      setDeleteRegister(true);
      setAddRegister(false);
    };

    const [isOpen, setIsOpen] = useState(false);
    const [isOpenUpdate, setIsOpenUpdate] = useState(false);
    const [isOpenCreateDatabase, setIsOpenCreateDatabase] = useState(false);
    const [nameLogs, setNameLogs] = useState('');

    const [formulario, setFormulario] = useState({nameCollection   : '',archivoCollection: null});
    const [formularioUpdate, setFormularioUpdate] = useState({nameCollection:null, 
                                                              archivoCollection: null,
                                                              addRegister:true,
                                                              deleteRegister:false});
    /*AGREGADO EL DIA 13 DE MARZON* */
    const [fileName, setFileName] = useState('');
    const [fileNameTwo, setFileNameTwo] = useState(''); // Estado para almacenar el nombre del archivo seleccionado
    const [logsName, setLogsName] = useState('');
    // Estado para almacenar el nombre del archivo seleccionado
    /*FIN* */

    const handleChangeFormulario = (event) => {
      const { name, value, type, checked, files } = event.target;
      if (type === 'checkbox') {
        setFormulario((prevFormulario) => ({
          ...prevFormulario,
          [name]: checked
        }));
      } else if (type === 'file') {
        setFileNameTwo(name);
        setFormulario((prevFormulario) => ({
          ...prevFormulario,
          [name]: files[0]
        }));
      } else {
        setFileName(name);
        setFormulario((prevFormulario) => ({
          ...prevFormulario,
          [name]: value
        }));
      }
    };

    const handleChangeFormularioUpdate = (event) => {
      const { name, value, type, files } = event.target;
      if (type === 'file') {
        
        setFileName(name);

        setFormularioUpdate((prevFormulario) => ({
          ...prevFormulario,
          [name]: files[0]
        }));
      } else {
        setFileName(name);
        setFormularioUpdate((prevFormulario) => ({
          ...prevFormulario,
          [name]: value
        }));
      }
    };

    const handleSubmitCreate = async (event) => {
      event.preventDefault(); 
        // Validar que ninguno de los campos esté vacío
      const isEmpty = Object.values(formulario).some((value) => value === '' || value === null);
      if (isEmpty) {
        setError(i18next.t('complete_info'));
        return;
      }else{
        setError('');
      } 
      await props.uploadFileDinamic(formulario);
      setFileNameTwo('')
      setFormulario({nameCollection   : '',archivoCollection: null})
      toggleModalCreateDatabase();
      //props.closeModalC();
    };

    const handleSubmitUpdate = async (event) => {

      //event.preventDefault(); 

      formularioUpdate.addRegister    = addRegister
      formularioUpdate.deleteRegister = deleteRegister
      formularioUpdate.nameCollection = formularioUpdate.nameCollection?formularioUpdate.nameCollection:nameCompany

      //nameCompany
      const isEmpty = Object.values(formularioUpdate).some((value) => value === '' || value === null);

      if (isEmpty) {
        setError(i18next.t('complete_info'));
        return;
      }else{
        setError('');
      } 

      await props.uploadFileDinamicUpdate(formularioUpdate);
      //await props.showDatabaseCollect(0);
      toggleModalUpdate();
      
      setFormularioUpdate({
        nameCollection: null,
        archivoCollection: null,
        addRegister: true,
        deleteRegister: false
      });

    };

    const toggleModalUpdate = (name) => {
      setNameCompany(name);
      setIsOpenUpdate(!isOpenUpdate);
    };

    const toggleModalCreateDatabase = (name) => {
      // setNameCompany(name);
      setIsOpenCreateDatabase(!isOpenCreateDatabase);
    };

    

    const toggleModalVisualizar = async (name) => {
      if(!isOpen){
        setNameLogs('');
        setLogsName('');
        await props.uploadDatabaseName(name);
        //await props.uploadFileDinamicVisualizar(name);
        setNameCompany(name);
      }
      setIsOpen(!isOpen);
    };

    const toggleModalVisualizarLogs = async (name,logs) => {
      if(!isOpen){
        setLogsName(logs);
        setNameLogs(name);
        await props.visualizarLogs(name);
        setNameCompany(name);
      }
      setIsOpen(!isOpen);
    };


    async function onchangeCompanies(e){
      const id_company=e.value;   
      await props.setSelectedCompany(id_company);
      // await props.showDatabaseCollect(id_company);
    }

    var dataTable=null;
    if(newListCollection[0]){
       
         dataTable = {
            columns: [
              {
                label: i18next.t('name'),
                field: 'name',
                sort: 'asc',
           //     width: 270
              },
              {
                label: i18next.t('edition_option'),
                field: 'options',
                sort: 'asc',
                width: 670,
                options: {
                    filter: false,
                    sort: false
                   }
              }],
              rows: []};
 

              const rows = newListCollection.map((cp, index) => {

                const redirectTo = () => {
                    props.history.push(`/users/${cp.id}`)
                };

                //k = JSON.parse(k);  
                const fin=[];
                fin.push(cp.name);
               
                fin.push(
                    <Row className="d-flex align-items-center justify-content-center"> 
                            {/*<React.Fragment>
                                <Button id="tooltiNewDownload" outline color="primary" onClick={ (e) => redirectTo() } >
                                  <FontAwesomeIcon icon={faDownload} />
                                </Button>
                                <UncontrolledTooltip placement="top" target="tooltiNewDownload">{i18next.t('download')}</UncontrolledTooltip> 
                            </React.Fragment>*/}
                            { props.rol != 'Reportes'?(
                                <React.Fragment>
                                <Button id="tooltiNewCopy" outline color="primary" onClick={(e) => toggleModalUpdate(cp.name)}>
                                  <FontAwesomeIcon icon={faCopy} />
                                </Button>  
                                <UncontrolledTooltip placement="top" target="tooltiNewCopy">{i18next.t('Sobreescribir')}</UncontrolledTooltip> 
                              </React.Fragment>
                            ):('') }
                            {
                                props.isAdmin && 
                                <div className="d-inline-block">
                                  <React.Fragment>
                                    <Button id="tooltiNewEye" outline color="success" onClick={(e) => toggleModalVisualizar(cp.name)}>
                                        <FontAwesomeIcon icon={faEye} />
                                    </Button> 
                                    <UncontrolledTooltip placement="top" target="tooltiNewEye">{i18next.t('Visualizacion')}</UncontrolledTooltip> 
                                  </React.Fragment>

                                  <React.Fragment>
                                    <Button id="tooltiNewEyeLogs" outline color="danger" onClick={(e) => toggleModalVisualizarLogs(cp.name, 'logs')}>
                                        <FontAwesomeIcon icon={faCopy} />
                                    </Button> 
                                    <UncontrolledTooltip placement="top" target="tooltiNewEyeLogs">{i18next.t('Visualizacion_logs')}</UncontrolledTooltip> 
                                  </React.Fragment>
                                  {/*<React.Fragment>
                                    <Button id="tooltiNewDelete" outline color="danger" onClick={() => setShowModalAlert(true)}>
                                        {
                                            cp.name?
                                            <FontAwesomeIcon icon={faTrash} />
                                            :
                                            <FontAwesomeIcon icon={faCheck} />
                                        }
                                    </Button>
                                      <UncontrolledTooltip placement="top" target="tooltiNewDelete">{i18next.t('Eliminar')}</UncontrolledTooltip>  
                                      </React.Fragment>*/}
                                </div>
                            }
                         </Row>
                )
                dataTable.rows.push(fin); 
                return cp;
            });
        }
 
        const options = {
            filterType: 'multiselect',
            selectableRows:'none',
            resizableColumns: false,
            responsive: "scrollMaxHeight",
            rowsPerPage:10,
            print:false,
            download:false,
            viewColumns:false,
          };
          
          var title = ''

    return (    
    <div className="UserLayout">
          <Admin>
          { props.rol == 'SuperUser' && 
            <React.Fragment>
                <button id="tooltinewDatabase" className="btn-float"  onClick={(e)=>{toggleModalCreateDatabase()}}>
                    <img src={newDatabase}/>
                </button> 
                <UncontrolledTooltip placement="right" target="tooltinewDatabase">{i18next.t('create_database')}</UncontrolledTooltip> 
            </React.Fragment>
            }
             <SliceMenuApp>
                <AdminMenuOptions />
            </SliceMenuApp>
            <Container fluid>
              <ToolbarApp>
                    <Row>
                        <Col sm="9" md="9" className="text pdtoolbar">
                        <FormGroup className="dp">
                              <h2 className="text-left ml-4 font-weight-bold">{i18next.t('admin_database')}</h2>                      
                          </FormGroup>
                        </Col>
                        {props.isAdmin?(
                            <SelectedCompanies onchangeCompanies={onchangeCompanies} ></SelectedCompanies>
                      ):null}
                    </Row>
                </ToolbarApp>
            </Container>

                <Container fluid className="box-fluid">
                <Col sm="12" md="10" className="stadistics-container offset-md-1">    
                    {
                       dataTable?(
                        <Row >
                            <Col xs={12} lg={12} >
                                <DataTableFilter
                                  title={title}
                                  data={ dataTable.rows}
                                  columns={dataTable.columns}
                                  options={options}
                                />
                           </Col>
                        </Row>): 
                          <Col xs={12} lg={8} className="offset-lg-2 intro-funnels">
                                <Row>
                                    <Col xs={12}><img src="/static/img/blank_state.png" className="img-fluid"/></Col>
                                    <Col xs={12}>
                                        <h2>{i18next.t('database_admin_msg')}</h2>
                                        <h3>{i18next.t('database_admin_msg2')}</h3>
                                        <p>{i18next.t('database_admin_msg3')}</p>
                                    </Col>
                                </Row>
                            </Col>
                        }
                    </Col>
                </Container>
          </Admin>

          <Modal isOpen={isOpenUpdate} toggle={toggleModalUpdate} fade size="lg" >
          <ModalHeader toggle={toggleModalUpdate}>{i18next.t('sobreescribir_database')}</ModalHeader>
          <form>
            <ModalBody>
                <Row>
                    <Col sm="6">
                        <FormGroup>
                            <Label>{i18next.t('base_de_datos_sobrescribir')} </Label>
                            <Input type="select" 
                                   className="inputModal"
                                   name="nameCollection"
                                   placeholder={i18next.t('company_name')} 
                                   onChange={(e) => handleChangeFormularioUpdate(e)} 
                                   defaultValue={nameCompany?nameCompany: ""}
                                   required>
                                  {newListCollection &&
                                      newListCollection.length > 0 &&
                                      newListCollection.map((company, i) => {
                                      return (
                                          <option key={company.name} value={company.name}>
                                          {company.name}
                                          </option>
                                      );
                                      })}
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col sm="6 mt-5">
                    <FormGroup check>
                      <Input
                        type="checkbox"
                        checked={addRegister}
                        onChange={handleAddRegisterChange}
                      />
                      {' '}
                      <Label check className="ml-2">
                        {i18next.t('base_de_datos_add_new_register')}
                      </Label>
                    </FormGroup>

                    <FormGroup check>
                      <Input
                        type="checkbox"
                        checked={deleteRegister}
                        onChange={handleDeleteRegisterChange}
                      />
                      {' '}
                      <Label check className="ml-2">
                        {i18next.t('base_de_datos_delete_register')}
                      </Label>
                    </FormGroup>
                    </Col>
                </Row>
                <Row>
                  <Col sm="6">
                    <FormGroup>
                      <label htmlFor="exampleFile" className="custom-file-upload btn btn-outline-secondary">
                      {i18next.t('file_selected')} {' '} {i18next.t('upload_file')} 
                        <Input id="exampleFile" 
                              name="archivoCollection" 
                              type="file"
                              style={{ display: 'none' }}
                              onChange={(e) => handleChangeFormularioUpdate(e)}/>
                      </label>
                      {fileName && <span style={{color: '#000000', marginLeft: '8px' }}>{fileName}</span>}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12">
                    <FormGroup>
                      <p style={{color:'red'}}>{error && <p>{error}</p>}</p>
                    </FormGroup>
                  </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Col sm="6" align="right">
                    <Button color="success" size="lg" type="button" onClick={() => setShowModalAlert(true)}>{i18next.t('finish')}</Button>
                </Col>
            </ModalFooter>
          </form>
          </Modal>

          <Modal isOpen={isOpenCreateDatabase} fade size="lg" toggle={ toggleModalCreateDatabase }>
            <ModalHeader toggle={toggleModalCreateDatabase }>{i18next.t('create_database')}</ModalHeader>
              <form onSubmit={handleSubmitCreate}>
              <ModalBody> 
                <Row>
                    <Col sm="6">
                        <FormGroup>
                            <Label>{i18next.t('name_coleccion')}</Label>
                            <Input type="text" 
                                   className="inputModal" 
                                   placeholder={i18next.t('name_coleccion')}
                                   value={formulario.nameCollection}
                                   name="nameCollection"
                                   onChange={(e) => handleChangeFormulario(e)} 
                                   required>
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                  <Col sm="6">
                    <FormGroup>
                      <label htmlFor="exampleFileTwo" className="custom-file-upload btn btn-outline-secondary control-label">
                      {i18next.t('file_selected')} {' '} {i18next.t('upload_file')} 
                      </label>
                      <Input id="exampleFileTwo" 
                              name="archivoCollection" 
                              type="file"
                              style={{ display: 'none' }}
                              onChange={(e) => handleChangeFormulario(e)}/>
                       {fileNameTwo && <span style={{color: '#000000', marginLeft: '8px' }}>{fileNameTwo}</span>}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12">
                    <FormGroup>
                      <p style={{color:'red'}}>{error && <p>{error}</p>}</p>
                    </FormGroup>
                  </Col>
                </Row>
            </ModalBody>

            <ModalFooter>
                <Col sm="6" align="right">
                    <Button color="success" size="lg" type="submit">{i18next.t('finish')}</Button>
                </Col>
            </ModalFooter>
          </form>
          </Modal>
                                
          <Modal isOpen={isOpen} toggle={toggleModalVisualizar} fade size="lg" >
          <ModalHeader toggle={toggleModalVisualizar}>{nameLogs == '' ? i18next.t('sobreescribir_database') : nameLogs }</ModalHeader>
             {logsName == 'logs' ? <FilterLogs /> :  <FilterSearch />}
          </Modal>

                                    
          <Modal isOpen={showModalAlert} toggle={() => setShowModalAlert(false)} fade size="lg">
            <ModalHeader toggle={() => setShowModalAlert(false)}>
              {addRegister ? i18next.t('title_add_register') :
                i18next.t('title_delete_register')}
            </ModalHeader>
            <ModalBody>
              {
                addRegister?i18next.t('add_register'):
                            i18next.t('delete_register')
              }
              
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={() => setShowModalAlert(false)}>
              {i18next.t('cancel')}
              </Button>
              <Button color="success" onClick={handleDelete}>
              {i18next.t('confirm')}
              </Button>
            </ModalFooter>
          </Modal>
    </div>
    )
}

const mapStateToPros = ({companyReducer, databaseReducer, analyticReducer}) => ({companyReducer, databaseReducer,analyticReducer})

const mapDispatchToProps = {
    uploadFileDinamic,
    uploadFileDinamicUpdate,
    setSelectedCompany,
    showDatabaseCollect,
    uploadFileDinamicVisualizar,
    uploadDatabaseName,
    filtrarCollection,
    visualizarLogs
}; 

export default connect(mapStateToPros, mapDispatchToProps)(withRouter(DataBaseLayout))