import React, { Component } from 'react';
import UsersLayout from './users-layout';
import ModalLoad from '../../components/modals/Loading/moda-loading';
import { connect } from 'react-redux';
import { getListUser, saveEditUser, saveNewUser, deleteUser } from '../../redux/actions/companyActions';
import i18next from '../../../i18n';

class Users extends Component {

  constructor(props) {
    super(props);
    this.state = {
      users: [],
      new_user: {},
      modalOpenC: false,
      c_user: {},
      modalOpen: false
    }
  }
  componentDidMount() {
    const companyID = this.props.match.params.companyID;
    const { getListUser } = this.props;
    getListUser(companyID);
  }

  editUser = (user) => {

    this.setState({
      modalOpen: true,
      c_user: user
    });
  }

  createUser = () => {
    this.setState({
      modalOpenC: true
    });
  }
  updatePropNewUser = (value, key) => {
    let c = this.state.new_user;
    
    c[key] = value;
    this.setState({
      new_user: c
    });
  }

  updatePropUser = (value, key) => {
    let c = this.state.c_user;
  
    c[key] = value;
    this.setState({
      c_user: c
    });
  }

  closeModalC = () => {
    this.setState({
      modalOpenC: false
    });
  }

  closeModal = () => {
    this.setState({
      modalOpen: false
    });
  }

  saveUser = async () => {
    const c = this.state.c_user;
    
    const regex_pass = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{10,}$/gm;
    const mail = /^[^@]+@[^@]+\.[a-zA-Z]{2,}$/gm;


    if (c.password_n && c.password_n.length > 0) {
      if (c.password_n.length < 10) {
        alert(i18next.t('validate_message'));
        return;
      } else {
        if (regex_pass.test(c.password_n) == false) {
          alert(i18next.t('validate_message2'));
          return;
        }
      }
    }

    if (!c.username || c.username.length < 6) {
      alert(i18next.t('validate_message3'));
      return;
    }

    if (mail.test(c.email) == false) {
      alert(i18next.t('validate_message6'));
      return;
    }
    if (/[^A-Za-z0-9]+/.test(c.username)) {
      alert(i18next.t('validate_message4'));
      return;
    }

    if (/[\s]+/.test(c.username)) {
      alert(i18next.t('validate_message5'));
      return;
    }

    if (!c.rol || c.rol.length <= 0) {
      alert(i18next.t('validate_message7'));
      return;
    }

    const companyID = this.props.match.params.companyID;
    let data = {
      user_id: c.id,
      roltype_id: c.rol,
      company_id: companyID,
      grant_type: 'password',
      username: c.username,
      email: c.email,
      first_name: c.first_name,
      last_name: c.last_name,
      password_expiration: c.password_expiration
    }
    
    if (c.password_n && c.password_n.length > 0) {
      data.password = c.password_n
    }


    const { saveEditUser } = this.props;
    
    await saveEditUser(data);
    this.closeModal();
    this.setState({ new_user: {} });

  }

  saveNewUser = async () => {
    let c = this.state.new_user;
    const regex_pass = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{10,}$/gm;
    const mail = /^[^@]+@[^@]+\.[a-zA-Z]{2,}$/gm;

    if (!c.password || c.password.length < 10) {
      alert(i18next.t('validate_message'));
      return;
    }
    if (!c.username || c.username.length < 10) {
      alert(i18next.t('validate_message3'));
      return;
    }
    if (regex_pass.test(c.password) == false) {
      alert(i18next.t('validate_message2'));
      return;
    }
    if (mail.test(c.email) == false) {
      alert(i18next.t('validate_message6'));
      return;
    }
    if (/[^A-Za-z0-9]+/.test(c.username)) {
      alert(i18next.t('validate_message4'));
      return;
    }

    if (/[\s]+/.test(c.username)) {
      alert(i18next.t('validate_message5'));
      return;
    }

    if (!c.rol || c.rol.length <= 0) {
      alert(i18next.t('validate_message7'));
      return;
    }

    const companyID = this.props.match.params.companyID;
   
    const data = {
      roltype_id: c.rol,
      company_id: companyID,
      grant_type: 'password',
      username: c.username,
      password: c.password,
      email: c.email,
      first_name: c.first_name,
      last_name: c.last_name,
      password_expiration: c.password_expiration !== undefined && c.password_expiration !== null ? c.password_expiration : true
      //password_expiration: c.PasswordExpiration !== undefined && c.PasswordExpiration !== null ? c.PasswordExpiration : false
    }

    const { saveNewUser } = this.props;
    await saveNewUser(data);
    this.closeModalC();
  }

  deleteUser = (user_id) => {
    const c = confirm(i18next.t('validate_message8'));
    if (!c) {
      return;
    }

    const companyID = this.props.match.params.companyID;
    const { deleteUser } = this.props;

    deleteUser(user_id, companyID);

  }

  render() {
    return (
      <div>
        <UsersLayout
          users={this.state.users}
          editUser={this.editUser}

          new_user={this.state.new_user}
          modalOpenC={this.state.modalOpenC}
          updatePropC={this.updatePropNewUser}
          closeModalC={this.closeModalC}
          createUser={this.createUser}
          saveNewUser={this.saveNewUser}

          c_user={this.state.c_user}
          modalOpen={this.state.modalOpen}
          updateProp={this.updatePropUser}
          closeModal={this.closeModal}
          saveUser={this.saveUser}

          deleteUser={this.deleteUser}

        />
        <ModalLoad />
      </div>
    )
  }
}




const mapDispatchToProps = {
  getListUser, saveEditUser, saveNewUser, deleteUser
}

export default connect(null, mapDispatchToProps)(Users);


