import React, {useState} from "react";
import i18next from "../../../../i18n";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  Row
} from "reactstrap";
import "./toolbar.css";
import "./modal-nuevo-bot.css";
import { saveNewBot,toggleModalNewBot } from "../../../redux/actions/homeActions";
import { getLoadBot } from "../../../redux/actions/botActions";

function ModalNuevoBot(props) {
  var plat = [];
  
  const [isChecked, setIsChecked] = useState(false);
  const [archivoBot, setArchivoBot] = useState(null);

    /*AGREGADO EL DIA 13 DE MARZON* */
    const [fileName, setFileName] = useState(''); // Estado para almacenar el nombre del archivo seleccionado
    /*FIN* */

  const subirArchivo = (e) =>{
    const file = e[0].name;
    if (file) {
        setFileName(file);
    }
    setArchivoBot(e);
  }

  const insertarArchivo = () => {
      props.getLoadBot(archivoBot)
  }

  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };

  const { homeReducer: { allPlatforms = [], modalNewBot }, toggleModalNewBot, saveNewBot } = props;
  
  allPlatforms.map((item, index)=>{
    let aux = JSON.parse(item);
    if(aux.id != 1){
      plat.push(
        <option key={index} value={aux.id}>
          {aux.platform}
        </option>
      );
    }
  });

  return (
    <div>
      <Modal
        isOpen={modalNewBot}
        toggle={toggleModalNewBot}
        className="modal-lg"
        fade
      >
        <ModalHeader toggle={toggleModalNewBot} charCode="X">
        {i18next.t('new_bot')}
        </ModalHeader>
        <Form id="form-createbot" onSubmit={
          (e)=>{
          saveNewBot(e,(urlDirect)=>{
            props.history.push(urlDirect);
          });
        }} role="form">
          <ModalBody>
          <FormGroup>
              <Row className="mb-3">
                <Col sm="2">
                </Col>
                <Col className="d-flex justify-content-start">
                <FormGroup check>
                      <Input
                        name="radio1"
                        type="radio"
                        checked={!isChecked}
                        onChange={handleOnChange}
                      />
                      {' '}
                      <Label check className="ml-2 mr-4">
                      {i18next.t('create_bot')+': '}
                      </Label>
                    </FormGroup>
                    {/* <FormGroup check>
                      <Input
                        name="radio2"
                        type="radio"
                        checked={isChecked}
                        onChange={handleOnChange}
                      />
                      {' '}
                      <Label check className="ml-2">
                      {i18next.t('import_bot')+': '}
                      </Label>
                    </FormGroup> */}
                </Col>
              </Row>
              {
                !isChecked?'':(<Row>
                  <Col sm="2 mr-5">
                    <Label className="btn btn-outline-secondary control-label" 
                            for="botDescription">
                     {i18next.t('file_selected')} {' '} {i18next.t('import')} :{" "}
                    </Label>
                  </Col>
                  <Col sm="10">
                    <Input
                      id="botDescription"
                      name="file"
                      type="file"
                      style={{ display: 'none' }}
                      onChange={(e) => subirArchivo(e.target.files)}
                    />
                    {fileName && <span style={{color: '#000000', marginLeft: '8px' }}>{fileName}</span>}
                  </Col>
                </Row>)
              }
            </FormGroup>

            {
              !isChecked?(
                <div>
                <FormGroup>
                <Row>
                  <Col sm="2">
                    <Label for="botName" className="control-label required-field">
                    {i18next.t('name')+': '}
                    </Label>
                  </Col>
                  <Col sm="10">
                    <Input
                      type="text"
                      className="inputModal"
                      id="botName"
                      name="botName"
                      maxLength="30"
                      placeholder={i18next.t('bot_name')}
                      required
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col sm="2">
                    <Label className="control-label" for="botDescription">
                    {i18next.t('description')} ({i18next.t('optional')}):{" "}
                    </Label>
                  </Col>
                  <Col sm="10">
                    <Input
                      type="textarea"
                      rows={4}
                      name="botDescription"
                      id="botDescription"
                      maxLength="100"
                      placeholder={i18next.t('description')}
                    />
                  </Col>
                </Row>
              </FormGroup>
              {allPlatforms.length > 0 && (
                <FormGroup>
                  <Row>
                    <Col sm="2">
                      <Label
                        className="control-label required-field"
                        for="botPlatform"
                      >
                        {i18next.t('platform')+': '}
                      </Label>
                    </Col>
                    <Col sm="10">
                      <Input
                        type="select" className="platforms-container"name="botPlatform">
                        {plat}
                      </Input>
                    </Col>
                  </Row>
                </FormGroup>
              )}
              </div>):''
            }
            
          </ModalBody>
          <ModalFooter>
            <Button onClick={toggleModalNewBot} outline color="secondary">
            {i18next.t('cancel')}
            </Button>
            
            {!isChecked?(<Button type="submit" size="lg" className="btn-guarumo-modal">
            {i18next.t('create')}
            </Button>):
            (
              <Button type="button" onClick={ () => insertarArchivo() } size="lg" className="btn-guarumo-modal">
              {i18next.t('import')}
            </Button>
            )
            }
          
          
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
}

const mapStateToProps = ({ homeReducer,  }) => {
	return { homeReducer,  };
};

const mapDispatchToProps = {
  saveNewBot,
  toggleModalNewBot,
  getLoadBot
};


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ModalNuevoBot));
