import React, { Component } from 'react';
import axios from 'axios';
import {  
    FormGroup, 
    Input, 
    Col,
    Row,
    Label,
    UncontrolledTooltip
} from 'reactstrap';
import i18next from '../../i18n';


class CMSSelector extends Component {

    state = {
        operatorDynamic: [],
        operator:this.props.operator,
    }

    constructor(props){
        super(props);
    }

    /***** **** **** **** **** **** 
                    AJAX HANDLERS */

     // GET requests handler
     getHandler(url){
        var auth=JSON.parse(localStorage.getItem('Authorization'));
        var headers={
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': 'Bearer '+auth.access_token
        }

        return axios({
          method:'get',
          url: HOST_API +url,
          headers:headers
        })
     }

    // Crea options del select
    getOptionsOperatorList () {
        var list = [
            {
                id:0,
                titulo:'No'
            },
            {
                id:1,
                titulo:i18next.t('yes')
            }
        ];
        this.setState({ operatorDynamic: list});
    }
    bindValue = () => {
        if(typeof this.props.bindValue === "function")
             this.props.bindValue(this.state.operator);
    }
    updateCMS = (event) => {
        this.setState({operator: event.target.value}, () => {
            this.bindValue();
        });
    }

    componentDidMount = () => {
        this.setState({ operator: this.props.operator});
        this.getOptionsOperatorList();
    }


    render() {
        const list = this.state.operatorDynamic;
        const options = list.map((item, index) => {    
            return <option key={index + 1} value={item.id}>{item.titulo}</option>
        });

        return (
            <Row>
                <Col sm="12">
                    <FormGroup>
                        <Label>¿{i18next.t('message54')}? <i className="fa fa-info-circle" id="tooltipdinamico"></i></Label>
                        <UncontrolledTooltip placement="right" target="tooltipdinamico">{i18next.t('enable_dinamic_operator')} </UncontrolledTooltip>
                        <Input type="select" name="select" onChange={(event) => { this.updateCMS(event) }} value={this.state.operator}>
                            {
                                options
                            }
                        </Input>
                    </FormGroup>
                </Col>
            </Row>
        )
    }
}

export default CMSSelector