  import React from 'react';
  //import BarData from './d3-examples/barChart';
  import ScaleColor from './d3Components/ScaleColor';
 
  
  class convertScaleColor extends React.Component {
    constructor(props) {
      super(props);
      this.state = {d3: ''} 
    }
  
    componentDidMount() {
     // this.setState({d3: BarData});
      
    }
  
    render() {
      
      return(      
        <div>
     
          <ScaleColor nameDiv={this.props.nameDiv} data={this.props.data} color={this.props.color} short_name={this.props.short_name}  typeScaleColor={this.props.typeScaleColor}/>
        </div>
          
      )
    }
  }
  export default convertScaleColor;