import { ANALYTICS_ACTIONS_TYPES } from '../types/analyticsActionsTypes';
import moment from 'moment';


const INITIAL_STATE = {
    dataframe:[],
    totalMetrics: 1,
    listAllMetrics: [],
    typeCharts: [], 
    typeChart: [],
    listSelectedTypeCharts: [],
    listAllCharts: [],
    totalDashboards: 1, 
    listAllDashboards: [],
    totalUnits: 1, 
    listAllUnits: [],
    totalDimesions: 1, 
    listAllDimensions: [],
    numDimensions:0,
    timeDimensions:false,
    numMeasures:0,
    allPlatforms: [],
    allPalettes: [],
    selectedPalette: [],
    listAllMeasures: [],
    listSelectedMeasures: [],
    selectedMetric: [],
    listSelectedChart: [],
    selectedChart: [],
    listAllCustomReports:[],
    selectedReport:[],
    //loagin: false,
    updateMetric: false,
    modalNewMetric: false,
    modalViewMetric: false,
    updateChart: false,
    viewChart: false,
    viewDashboard:false,
    modalNewChart: false,
    modalViewChart: false,
    modalNewDashboard: false,
    searcher: '',
    MetricClone: [],
    error:'',
    activePage: 1,
    previews: [],
    allPreviews: [],
    selectedDashboard:[],
    items:[],
    listAllBotsbyCompany:[],
    listAllChats:[],
    languages:[],
    activeItem:"1",
    dataChart:[],
    selectedCompany:'',
    selectedBot:[],
    granularity:"hour",
    startDateDashboard:moment().subtract(1, 'day'),//moment().subtract(1, 'months')
    endDateDashboard:moment(),
    pagination:{
        current: 1,
        pages: 1
      },
    downloadLink: "",
    operationFinal:{},
    operationCount:{},
    loadReport:false,
    uiReport:[],
    tokenCubejs:"",
    dataInteractionReport:[],
    reportType:''

};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {

         /**
         * Reducer Chat_traceability
         */

        case ANALYTICS_ACTIONS_TYPES.requestGetListChats():
            return {
                ...state,
                //loagin: true,
                error:'',
            };

        case ANALYTICS_ACTIONS_TYPES.successGetListChats():
            return {
                ...state,
                listAllChats: action.payload,
                error:'',
                //loagin: false,
            };
        case ANALYTICS_ACTIONS_TYPES.errorGetListChats():
            return {
                ...state,
                error: action.payload,
                //loagin: false,
            };

        case ANALYTICS_ACTIONS_TYPES.notFoundGetListChats():
            return {
                ...state,
                listAllChats: [],
                error: action.payload,
                //loagin: false,
            };
         /**
         * Reducer Bots
         */

            case ANALYTICS_ACTIONS_TYPES.requestGetListBotsbyCompany():
                return {
                    ...state,
                    //loagin: true,
                    error:'',
                };
    
            case ANALYTICS_ACTIONS_TYPES.successGetListBotsbyCompany():
                return {
                    ...state,
                    listAllBotsbyCompany: action.payload,
                    error:'',
                    //loagin: false,
                };
            case ANALYTICS_ACTIONS_TYPES.errorGetListBotsbyCompany():
                return {
                    ...state,
                    error: action.payload,
                    //loagin: false,
                };
    
            case ANALYTICS_ACTIONS_TYPES.notFoundGetListBotsbyCompany():
                return {
                    ...state,
                    listAllBotsbyCompany: [],
                    error: action.payload,
                    //loagin: false,
                };
        /**
         * Reducer Metrics
         */
        case ANALYTICS_ACTIONS_TYPES.errorDeleteMetric():
            return {
                ...state,
                error: action.payload
            };
        case ANALYTICS_ACTIONS_TYPES.successDeleteMetric():
            return {
                ...state,
                error: action.payload
            };

        case ANALYTICS_ACTIONS_TYPES.toggleModalNewMetric():
            return {
                ...state,
                modalNewMetric: !state.modalNewMetric,
            };

            case ANALYTICS_ACTIONS_TYPES.toggleModalViewMetric():
                return {
                    ...state,
                    modalViewMetric: !state.modalViewMetric,
                };

            case ANALYTICS_ACTIONS_TYPES.successGetTotalMetrics():
                return {
                    ...state,
                    totalMetrics: action.payload,
                    error: ''
                };
    
            case ANALYTICS_ACTIONS_TYPES.requestGetListMetrics():
                return {
                    ...state,
                    //loagin: true,
                    error:'',
                };
    
            case ANALYTICS_ACTIONS_TYPES.successGetListMetrics():
                return {
                    ...state,
                    listAllMetrics: action.payload,
                    error:'',
                    //loagin: false,
                };
            case ANALYTICS_ACTIONS_TYPES.successGetListMeasures():
                return {
                    ...state,
                    listAllMeasures: action.payload,
                    error:'',
                    //loagin: false,
                };
    
            case ANALYTICS_ACTIONS_TYPES.errorGetListMetrics():
                return {
                    ...state,
                    error: action.payload,
                    //loagin: false,
                };
    
            case ANALYTICS_ACTIONS_TYPES.notFoundGetListMetrics():
                return {
                    ...state,
                    listAllMetrics: [],
                    error: action.payload,
                    //loagin: false,
                };
            case ANALYTICS_ACTIONS_TYPES.errorUpdateMetric():
                return {
                    ...state,
                    listAllMetrics: [],
                    error: action.payload,
                    //loagin: false,
                };
            case ANALYTICS_ACTIONS_TYPES.successUpdateMetric():
                return {
                    ...state,
                 //   listAllMetrics: [],
                    error: action.payload,
                    //loagin: false,
                };
            case ANALYTICS_ACTIONS_TYPES.selectedMetricChange():
                return {
                    ...state,
                    selectedMetric: action.payload,
                //    loagin: false,
                };
            case ANALYTICS_ACTIONS_TYPES.selectedUpdateMetric():
            return {
                ...state,
                selectedMetric: action.payload,
                updateMetric: true
            //    loagin: false,
            };
            case ANALYTICS_ACTIONS_TYPES.selectedCreateMetrica():
                return {
                    ...state,
                    selectedMetric:[],
                    updateMetric: false,
                    listSelectedMeasures:[]
                //    loagin: false,
                };

         /**
         * Reducer Charts
         */
        case ANALYTICS_ACTIONS_TYPES.errorDeleteChart():
            return {
                ...state,
                error: action.payload
            };
        case ANALYTICS_ACTIONS_TYPES.successDeleteChart():
            return {
                ...state,
                error: action.payload
            };

        case ANALYTICS_ACTIONS_TYPES.toggleModalNewChart():
            return {
                ...state,
                modalNewChart: !state.modalNewChart,
            };

            case ANALYTICS_ACTIONS_TYPES.toggleModalViewChart():
                return {
                    ...state,
                    modalViewChart: !state.modalViewChart,
                };

            
            case ANALYTICS_ACTIONS_TYPES.requestGetListCharts():
                return {
                    ...state,
                    //loagin: true,
                    error:'',
                };
    
            case ANALYTICS_ACTIONS_TYPES.successGetListCharts():
                return {
                    ...state,
                    listAllCharts: action.payload,
                    error:'',
                    //loagin: false,
                };
            
            case ANALYTICS_ACTIONS_TYPES.errorGetListCharts():
                return {
                    ...state,
                    error: action.payload,
                    //loagin: false,
                };
    
            case ANALYTICS_ACTIONS_TYPES.notFoundGetListCharts():
                return {
                    ...state,
                    listAllCharts: [],
                    error: action.payload,
                    //loagin: false,
                };
            case ANALYTICS_ACTIONS_TYPES.errorUpdateChart():
                return {
                    ...state,
                    listAllCharts: [],
                    error: action.payload,
                    //loagin: false,
                };
            case ANALYTICS_ACTIONS_TYPES.successUpdateChart():
                return {
                    ...state,
                 //   listAllMetrics: [],
                    error: action.payload,
                    //loagin: false,
                };
            case ANALYTICS_ACTIONS_TYPES.selectedViewChart():
                return {
                    ...state,
                    selectedChart: action.payload,
                    viewChart:true
                //    loagin: false,
                };
    
            case ANALYTICS_ACTIONS_TYPES.selectedUpdateChart():
            return {
                ...state,
                selectedChart: action.payload,
                updateChart: true,
                viewChart: false
            //    loagin: false,
            };
            case ANALYTICS_ACTIONS_TYPES.selectedCreateChartR():
                return {
                    ...state,
                    selectedChart:[],
                    updateChart: false,
                    viewChart: false,
                    selectedMetric:action.payload
                   
                }; 
        /**
         * Reducer Dashboard
         */
        case ANALYTICS_ACTIONS_TYPES.errorDeleteDashboard():
            return {
                ...state,
                error: action.payload
            };
        case ANALYTICS_ACTIONS_TYPES.successDeleteDashboard():
            return {
                ...state,
                error: action.payload
            };

        case ANALYTICS_ACTIONS_TYPES.toggleModalNewDashboards():
            return {
                ...state,
                viewDashboard:(state.modalNewDashboard==true)&&(state.viewDashboard==true)?!state.viewDashboard:(state.viewDashboard),
                modalNewDashboard: !state.modalNewDashboard,
               
            };

            
            case ANALYTICS_ACTIONS_TYPES.requestGetListDashboards():
                return {
                    ...state,
                    //loagin: true,
                    error:'',
                };
    
            case ANALYTICS_ACTIONS_TYPES.successGetListDashboards():
                return {
                    ...state,
                    listAllDashboards: action.payload,
                    error:'',
                    //loagin: false,
                };
            
                case ANALYTICS_ACTIONS_TYPES.cleanListDashboards():
                    return {
                        ...state,
                        listAllDashboards: [],
                        selectedDashboard:[],
                        items:[],
                        granularity:"hour",
                        error:'',
                        //loagin: false,
                    };
            case ANALYTICS_ACTIONS_TYPES.cleanListMetrics():
                return {
                    ...state,
                    listAllDimensions: [],
                    selectedMetric:[],
                    error:'',
                    //loagin: false,
                };
            case ANALYTICS_ACTIONS_TYPES.cleanListCharts():
                return {
                    ...state,
                    listAllCharts: [],
                    error:'',
                    //loagin: false,
                };
            case ANALYTICS_ACTIONS_TYPES.cleanListBots():
                return {
                    ...state,
                    listAllBotsbyCompany: [],
                    selectedBot:[],
                    operationFinal:{},
                    error:'',
                    //loagin: false,
                };
            case ANALYTICS_ACTIONS_TYPES.errorGetListDashboards():
                return {
                    ...state,
                    error: action.payload,
                    //loagin: false,
                };
    
            case ANALYTICS_ACTIONS_TYPES.notFoundGetListDashboards():
                return {
                    ...state,
                    listAllDashboards: [],
                    error: action.payload,
                    //loagin: false,
                };
            case ANALYTICS_ACTIONS_TYPES.errorUpdateDashboard():
                return {
                    ...state,
                    listAllDashboards: [],
                    error: action.payload,
                    //loagin: false,
                };
            case ANALYTICS_ACTIONS_TYPES.successUpdateDashboard():
                return {
                    ...state,
                 //   listAllMetrics: [],
                    error: action.payload,
                    //loagin: false,
                };
            case ANALYTICS_ACTIONS_TYPES.selectedViewDashboard():
                   
                return {
                    ...state,
                    selectedDashboard: action.payload,
                    viewDashboard:false
                    
                //    loagin: false,
            };
            case ANALYTICS_ACTIONS_TYPES.selectedViewDashboardModal():
                return {
                    ...state,
                    selectedDashboard: action.payload,
                    viewDashboard:true
                //    loagin: false,
                };
            case ANALYTICS_ACTIONS_TYPES.selectedUpdateDashboard():
            return {
                ...state,
                selectedDashboard: action.payload,
                updateDashboard: true,
                viewDashboard:false
            //    loagin: false,
            };
            case ANALYTICS_ACTIONS_TYPES.selectedCreateDashboards():
                return {
                    ...state,
                    selectedDashboard:[],
                    updateDashboard: false,
                    viewDashboard:false,
                    items:[]
                   
                };

          /**
         * Reducer Unity
         */
        
            case ANALYTICS_ACTIONS_TYPES.successGetTotalUnits():
                return {
                    ...state,
                    totalUnits: action.payload,
                    error: ''
                };
    
            case ANALYTICS_ACTIONS_TYPES.requestGetListUnits():
                return {
                    ...state,
                   // loagin: true,
                    error:'',
                };
    
            case ANALYTICS_ACTIONS_TYPES.successGetListUnits():
                return {
                    ...state,
                    listAllUnits: action.payload,
                    error:'',
                 //   loagin: false,
                };
    
            case ANALYTICS_ACTIONS_TYPES.errorGetListUnits():
                return {
                    ...state,
                    error: action.payload,
                //    loagin: false,
                };
    
            case ANALYTICS_ACTIONS_TYPES.notFoundGetListUnits():
                return {
                    ...state,
                    listAllUnits: [],
                    error: action.payload,
                    //loagin: false,
                };
        /**
         * Reducer Dimension
         */
        
            case ANALYTICS_ACTIONS_TYPES.successGetTotalDimensions():
                return {
                    ...state,
                    totalDimesions: action.payload,
                    error: ''
                };
    
            case ANALYTICS_ACTIONS_TYPES.requestGetListDimensions():
                return {
                    ...state,
                    //loagin: true,
                    error:'',
                };
    
            case ANALYTICS_ACTIONS_TYPES.successGetListDimensions():
                return {
                    ...state,
                    listAllDimensions: action.payload,
                    error:'',
                    //loagin: false,
                };
    
            case ANALYTICS_ACTIONS_TYPES.errorGetListDimensions():
                return {
                    ...state,
                    error: action.payload,
                    //loagin: false,
                };
    
            case ANALYTICS_ACTIONS_TYPES.notFoundGetListDimensions():
                return {
                    ...state,
                    listAllDimensions: [],
                    error: action.payload,
                    //loagin: false,
                };

        /**
         *  Reducer Plafforms
         */
        case ANALYTICS_ACTIONS_TYPES.successGetListPlafforms():
            return {
                ...state,
                allPlatforms: action.payload,
                //loagin: false,
                error: ''
            };
            case ANALYTICS_ACTIONS_TYPES.errorGetListPlafforms():
                return {
                    ...state,
                    error: action.payload,
                    //loagin: false,
                    error: ''
                };
            case ANALYTICS_ACTIONS_TYPES.requestGetListPlafforms():
                return {
                    ...state,
                    //loagin: true,
                    error: ''
                };
         /**
         *  Reducer Palettes
         */
        case ANALYTICS_ACTIONS_TYPES.successGetListPalettes():
                return {
                    ...state,
                    AllPalettes: action.payload,
                    //loagin: false,
                    error: ''
                };
                case ANALYTICS_ACTIONS_TYPES.errorGetListPalettes():
                    return {
                        ...state,
                        error: action.payload,
                        //loagin: false
                    };
                case ANALYTICS_ACTIONS_TYPES.requestGetListPalettes():
                    return {
                        ...state,
                        //loagin: true,
                        error: ''
                    };
            case ANALYTICS_ACTIONS_TYPES.selectedPalette():
                return {
                    ...state,
                    error: '',
                    //loagin: false,
                    selectedPalette: action.payload
                };
             
         /**
         *  Reducer Type_Chart
         */
        case ANALYTICS_ACTIONS_TYPES.successGetListTypeCharts():
            return {
                ...state,
                typeCharts: action.payload,
                //loagin: false,
                error: ''
            };
            case ANALYTICS_ACTIONS_TYPES.errorGetListTypeCharts():
                return {
                    ...state,
                    error: action.payload,
                    //loagin: false,
                    error: ''
                };
            case ANALYTICS_ACTIONS_TYPES.requestGetListTypeCharts():
                return {
                    ...state,
                    //loagin: true,
                    error: ''
                };
       case ANALYTICS_ACTIONS_TYPES.getListSelectedTypeChart():
            return {
                ...state,
                listSelectedTypeCharts: action.payload,
                //loagin: false,
                error: ''
            }; 

            case ANALYTICS_ACTIONS_TYPES.getTypeChart():
            return {
                ...state,
                typeChart: action.payload,
                //loagin: false,
                error: ''
            }; 
        
            /**
         *  Reducer Languages
         */
        case ANALYTICS_ACTIONS_TYPES.successGetListLanguages():
            return {
                ...state,
                languages: action.payload,
                //loagin: false,
                error: ''
            };
            case ANALYTICS_ACTIONS_TYPES.errorGetListLanguages():
                return {
                    ...state,
                    error: action.payload,
                    //loagin: false,
                    error: ''
                };
            case ANALYTICS_ACTIONS_TYPES.requestGetListLanguages():
                return {
                    ...state,
                    //loagin: true,
                    error: ''
                };

                  /**
         *  Reducer DataInteractionReport
         */
        case ANALYTICS_ACTIONS_TYPES.successGetListDataInteractionReport():
            return {
                ...state,
                DataInteractionReport: action.payload,
                //loagin: false,
                error: ''
            };
            case ANALYTICS_ACTIONS_TYPES.errorGetListDataInteractionReport():
                return {
                    ...state,
                    error: action.payload,
                    //loagin: false,
                    error: ''
                };
            case ANALYTICS_ACTIONS_TYPES.requestGetListDataInteractionReport():
                return {
                    ...state,
                    //loagin: true,
                    error: ''
                };

                /**
                 * Reducer Search and pagination
                 */
        case ANALYTICS_ACTIONS_TYPES.handleClearSearcher():
            return {
                ...state,
                searcher:  '',
                //loagin: false,
                error: ''
            };

        case ANALYTICS_ACTIONS_TYPES.handleSearching():
            return {
                ...state,
                searcher:  action.payload,
                //loagin: false,
                error: ''
            };

       
        case ANALYTICS_ACTIONS_TYPES.changePageActive():
            return {
                ...state,
                activePage: action.payload ,
                error: '',
                //loagin: false,
            };
        
            case ANALYTICS_ACTIONS_TYPES.changeActiveTabMetric():
            return {
                ...state,
                activeItem: action.payload ,
                error: '',
                //loagin: false,
            };

            case ANALYTICS_ACTIONS_TYPES.setDataChart():
            return {
                ...state,
                dataChart: action.payload ,
                error: '',
                //loagin: false,
            };
            case ANALYTICS_ACTIONS_TYPES.handleChangeStart():
            return {
                ...state,
                startDateDashboard: action.payload ,
                error: '',
                //loagin: false,
            };
            case ANALYTICS_ACTIONS_TYPES.handleChangeEnd():
            return {
                ...state,
                endDateDashboard: action.payload ,
                error: '',
                //loagin: false,
            };
            case ANALYTICS_ACTIONS_TYPES.handleChangeGranularity():
                return {
                    ...state,
                    granularity: action.payload ,
                    error: '',
                    //loagin: false,
                };     
            case ANALYTICS_ACTIONS_TYPES.cleanDataChart():
                return {
                    ...state,
                    dataChart: [] ,
                    error: '',
                    //loagin: false,
                };

            case ANALYTICS_ACTIONS_TYPES.changeBetweenList():
            return {
                ...state,
                listSelectedMeasures: action.payload ,
                error: '',
                //loagin: false,
            };

            case ANALYTICS_ACTIONS_TYPES.countMeasure():
            return {
                ...state,
                error: '',
                //loagin: false,
                numMeasures:action.payload
            };
            case ANALYTICS_ACTIONS_TYPES.cleanPreview():
            return {
                ...state,
                error: '',
                //loagin: false,
                previews:[]
            };
            case ANALYTICS_ACTIONS_TYPES.cleanAllPreview():
            return {
                ...state,
                error: '',
                //loagin: false,
                allPreviews:[]
            };

            case ANALYTICS_ACTIONS_TYPES.cleanAllItems():
            return {
                ...state,
                error: '',
                //loagin: false,
                items:[]
            };
            
            case ANALYTICS_ACTIONS_TYPES.getItems():
            return {
                ...state,
                error: '',
                //loagin: false,
                items:action.payload
            };

            case ANALYTICS_ACTIONS_TYPES.getPreviews():
            return {
                ...state,
                error: '',
                //loagin: false,
                previews:action.payload
            };
            case ANALYTICS_ACTIONS_TYPES.getAllPreview():
                    return {
                        ...state,
                        error: '',
                        //loagin: false,
                        allPreviews: action.payload
                    };
            case ANALYTICS_ACTIONS_TYPES.countDimension():
            return {
                ...state,
                error: '',
                //loagin: false,
                numDimensions: action.payload
            };
            case ANALYTICS_ACTIONS_TYPES.countTimeDimension():
            return {
                ...state,
                error: '',
                //loagin: false,
                timeDimensions: action.payload
            };
            case ANALYTICS_ACTIONS_TYPES.selectedCompany():
                return {
                    ...state,
                    selectedCompany: action.payload,
                //    //loagin: false,
                };
            case ANALYTICS_ACTIONS_TYPES.selectedBot():
                    return {
                        ...state,
                        selectedBot: action.payload,
                        operationFinal:{}
                    //    //loagin: false,
                    };
            case ANALYTICS_ACTIONS_TYPES.setLoadReport():
                return {
                    ...state,
                    loadReport: action.payload,
                //    loagin: false,
                };
                case ANALYTICS_ACTIONS_TYPES.setOperationFinal():
            return {
                ...state,
                operationFinal: action.payload,
            //    loagin: false,
            };
            case ANALYTICS_ACTIONS_TYPES.setResulset():
                return {
                    ...state,
                    dataframe: action.payload,
                    //loagin: false,
                };
            
            case ANALYTICS_ACTIONS_TYPES.setOperationCount():
                return {
                    ...state,
                    operationCount: action.payload,
                //    loagin: false,
                };
            case ANALYTICS_ACTIONS_TYPES.setUiReport():
            return {
                ...state,
                uiReport: action.payload,
            //    loagin: false,
            };
            case ANALYTICS_ACTIONS_TYPES.setTockenCube():
                return {
                    ...state,
                    tokenCubejs: action.payload,
                //    loagin: false,
            };
            case ANALYTICS_ACTIONS_TYPES.setPagination():
                return {
                    ...state,
                    pagination: action.payload,
                //    loagin: false,
            };
            case ANALYTICS_ACTIONS_TYPES.setDownloadLink():
                return {
                    ...state,
                    downloadLink: action.payload,
                //    loagin: false,
            };
            case ANALYTICS_ACTIONS_TYPES.setReportType():
                return {
                    ...state,
                    reportType: action.payload,
                //    loagin: false,
            };

            case ANALYTICS_ACTIONS_TYPES.setListAllReports():
                return {
                    ...state,
                    listAllCustomReports: action.payload,
                //    loagin: false,
            };
            case ANALYTICS_ACTIONS_TYPES.setSelectedReport():
                return {
                    ...state,
                    selectedReport:action.payload,
                //    loagin: false,
            };


		default: return state;
	};
};