// Actions
export const BOT_ACTIONS_TYPES = {

    setBotVersionId: () => 'SET_BOT_VERSION_ID',
    setBot: () => 'SET_BOT',
    setCompanyId: () => 'SET_COMPANY_ID',
    setIa_activated: () => 'SET_IA_ACTIVATED',
    setListIntent: () => 'SET_LIST_INTENT',
    setPlatform: () => 'SET_PLATFORM',
    setIdSchema: () => 'SET_ID_SCHEMA',
    setSchema: () => 'SET_SCHEMA',
    setUrlPath: () => 'SET_URLPATH',
    setTimeout: () => 'SET_TIMEOUT',
    setShowEdition:() => 'SET_SHOWEDITION',
    setShowConfig:() => 'SET_SHOWECONFIG',
    setShowEditionEntity:() => 'SET_SHOWEDITIONENTITY',
    setShowQuestions:() => 'SET_SHOWQUESTIONS',
    setShowAnswers:() => 'SET_SHOWANSWERS',
    setHideModal:()   => 'SET_HIDEMODAL',
    setNameModule:()   => 'SET_NAMEMODULE',
    setIdAnswers:()    => 'SET_IDANSWERS',
};