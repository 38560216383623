import React from 'react';
import Admin from '../../admin/containers/admin';
import SliceMenuApp from '../../admin/components/slice-menu'
import { 
  Col,
  Row,
  Button, 
  Container,
  Modal, 
  ModalHeader, 
  ModalBody, 
  Form,
  FormGroup, 
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  Table,
  UncontrolledTooltip
} from 'reactstrap';
import ToolbarApp from '../../admin/components/toolbar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faImages, faSave, faArrowLeft, faTimes, faCheck } from '@fortawesome/free-solid-svg-icons'
import BotMenuOptions from'../../menus/bot-menu-options';
import Dropzone from 'react-dropzone';
import './cms-layout.css';
import Emoji from '../../../components/emoji-picker-simple';

import DataTableFilter from '../../analytics/infoViz/common/dataTableFilter'

      /* IMAGE */
import imageNew from '../../../../static/img/menu-icons/nuevo_bot.svg'
import i18next from '../../../../i18n';

function CmsLayout(props) {
    var styles ={
        height: {
            width: 100 
        } 
    };


  let operator = props.listOperators || [];
 
  var dataTable=null;
  if(operator[0]){     
       dataTable = {
          columns: [
            {
              label: i18next.t('flow'),
              field: i18next.t('flow'),
              sort: 'asc',
             // width: 150
            },
            {
              label: i18next.t('operator_title'),
              field: i18next.t('operator'),
              sort: 'asc',
             // width: 150
            },
            {
              label: i18next.t('operator_type'),
              field: 'tipo_operador',
              sort: 'asc',
             // width: 150
            },
            {
              label: i18next.t('edition_option'),
              field: 'options',
              sort: 'asc',
              width: 670,
              options: {
                  filter: false,
                  sort: false
                 }
            }],
            rows: []};

            const rows =  operator.map((op, index) => {
             
              const fin=[];
              fin.push(op);
              dataTable.rows.push(fin); 
              return op;
          });
      }

      const options = {
          filterType: 'multiselect',
          selectableRows:'none',
          resizableColumns: false,
          responsive: "scrollMaxHeight",
          rowsPerPage:5 ,
          print:false,
          download:false,
          viewColumns:false,
          customRowRender: data => {
            const [ op ] = data;
            const index = op._id;
            let classColor = '';
            if(op.tipo == 'carousel')
                classColor='carusel';
            else
                classColor='texto';
            return (
                <tr key={index}>
                    <td>{op.title_scheme}</td>
                    <td>{op.titulo}</td>
                    <td className={classColor}>{op.tipo}</td>
                    <td>
                    {
                        <div className="d-inline-block">
                        <Button outline color="success" onClick={ (e) => props.getOperator(op.id)}>
                            <FontAwesomeIcon icon={faPen} id={'buttonEdit'+index}/>
                        </Button>
                        <UncontrolledTooltip placement="top" target={'buttonEdit'+index}> {i18next.t('edit')}</UncontrolledTooltip>
                        </div>
                    }                  
                    </td>
                </tr>
            )
          },
         
        };
        
            var title = ''



  const bls = props.items || {};
  const kblocks = Object.keys(bls);
  let blocks = [];
  if(bls.length > 0 ){    
     blocks = kblocks.map((k, index) => {
        return <tr key={bls[k].id}>
            <td  style={{width:'75%'}}>
              <Form id={k} onSubmit={props.saveOperatorItem}>
                  <input type="hidden" name="idOperator" defaultValue={props.operator.id || ''}/>
                  <input type="hidden" name="description" defaultValue={bls[k].descripcion || ''}/>
                  <input type="hidden" name="image" defaultValue={bls[k].url || ''}/>
                  <input type="hidden" name="idItem" defaultValue={bls[k].id}/>
                  <Input id={'input-'+k} type="text" name="title" defaultValue={decodeURI(bls[k].titulo)} 
                  
                  maxLength={props.operator.type == 'carousel' ? '80':'2000'}/>
              </Form>
            </td>
            <td>
             <Emoji id={k} callEmoji={props.callEmoji} type="item"/>
  
                <Button outline color="success" id={'buttonSaveCard_'+index} type="submit"  form={k}><FontAwesomeIcon icon={faSave}  />
                 {/* <UncontrolledTooltip placement="top" target={'buttonSaveCard_'+index}> Guardar</UncontrolledTooltip> */}
              </Button>
             
               {props.operator.type == 'carousel' && 
                  <Button outline color="primary" onClick={ (e) => props.getOperatorItem(bls[k].id || '')} id={'buttonEditCard'+index}><FontAwesomeIcon icon={faPen} />
                  <UncontrolledTooltip placement="top" target={'buttonEditCard'+index}> Editar</UncontrolledTooltip>
                  </Button>
              }
            </td>
        </tr>
    });
  }  

    var imag =props.modal_image;
    const thumbsImgModal = imag.map(file => (
        <div key={"thumb-1"}>
          <div className="container-image">
            <img
              className="img-fluid"
              src={file.preview}
            />
          </div>
        </div>
      ));


  return (
    <div className="CMSLayout">
          <Admin>
            <SliceMenuApp>
            <BotMenuOptions botId={props.botId} ia_activated={props.ia_activated} idSchema = {props.idSchema}/>
            </SliceMenuApp>
            <Container fluid>
                <ToolbarApp>
                    <Row>
                        <Col sm="9" md="9" lg="10" className="text pdtoolbar">
                        <h2 className="text-left ml-4 font-weight-bold">{i18next.t('config_cms')}</h2>                      
                        </Col>
                    </Row>
                </ToolbarApp>
            </Container>
            <Container fluid className="box-fluid">
                <Col sm="12" md="10" className="stadistics-container offset-md-1">    
                    {
                       dataTable?(
                        <Row >
                            <Col xs={12} lg={12} >
                                <DataTableFilter
                                title={title}
                                data={ dataTable.rows}
                                columns={dataTable.columns}
                                options={options}
                                />
                           </Col>
                        </Row>): 
                          <Col xs={12} lg={8} className="offset-lg-2 intro-funnels">
                                <Row>
                                    <Col xs={12}><img src="/static/img/blank_state.png" className="img-fluid"/></Col>
                                    <Col xs={12}>
                                        <h2>{i18next.t('config_cms_msg')}</h2>
                                        <h3>{i18next.t('config_cms_msg2')}</h3>
                                        <p>{i18next.t('config_cms_msg3')}</p>
                                    </Col>
                                </Row>
                            </Col>
                        }
                    </Col>
                </Container>
          </Admin>
                                    
            <Modal className="modalRight" isOpen={props.modalOpenC} fade toggle={ () => props.closeModalC() }>
                <ModalHeader toggle={() => props.closeModalC() } className={props.operator.type}>{i18next.t('config_operator')}</ModalHeader>
              <ModalBody>
                  <Row className="m-0" >
                  <Col md={12} className="sectionBox">
                            <input type="hidden" name="idOperatorDynamic" defaultValue={props.operator.id || ''}/>
                            <input type="hidden" name="idOperator" defaultValue={props.operator.operator_id || ''}/>
                            <FormGroup>
                                <Label>{i18next.t('title')+': '} </Label>
                                <span className="infoDetail">&nbsp;{props.operator.title || ''}</span><br></br>
                                <Label>{i18next.t('type')+': '} </Label>
                                <span className="infoDetail">&nbsp;{props.operator.type || ''}</span>
                            </FormGroup> 
                        </Col>
                        <Col className="p-0">
                            <Col className={'subRow '+props.operator.type}><h4>{props.operator.type == 'carousel' ? i18next.t('tarjects'):i18next.t('statements')}</h4></Col>
                        </Col>
                        <Col md={12} className="buttonBox">
                        <table>
                            <tbody>
                                <tr>
                                    <td style={{width:'75%'}}>
                                        { props.limit_input &&
                                            <span className="warning-character">&nbsp;{i18next.t('max_item_lose')}</span>
                                        }
                                    </td>
                                </tr>
                                
                            </tbody>
                        </table>
                        </Col>
                        <Col className="col-12 text-center tableBox" style={styles.height} >
                            <Row>
                                <Col md={12} className="marginBox">
                                    <Table className="itemTable">
                                        <tbody>
                                          { blocks  }
                                        </tbody> 
                                    </Table> 
                                </Col>
                            </Row>
                        </Col> 

                        <Col  className="buttonModal">
                            <Col className="col-6 offset-3">
                                <Row>
                                    <Col sm={12} md={12}><Button className="btn-block btn-guarumo-modal-sm" onClick={()=>{ props.closeModalC()}}>{i18next.t('finish')}</Button></Col>         
                                </Row>
                            </Col>
                        </Col>
                  </Row>
              </ModalBody>

            </Modal> 
            
            { props.operatorDetail.length != 0 && 
                <Modal className="modalRight moreModal" isOpen={props.modalOpenMore} fade toggle={ () => props.closeModalOpenMore() }> 
                    <ModalHeader toggle={() => props.closeModalOpenMore() } className={props.operator.type}>
                    <FontAwesomeIcon icon={faArrowLeft} onClick={()=>{ props.closeModalOpenMore()}}/> 
                        <span className="spanTitle">{decodeURI(props.operatorDetail[0].titulo) || ''}</span>
                    </ModalHeader>
                    <ModalBody>
                        <Row className="m-0">
                            <Col md={12} className="sectionBox">
                                <Form id="frm_updateOperatorItem" onSubmit={props.saveOperatorItem}>
                                    <input type="hidden" name="idOperator" defaultValue={props.operator.id || ''}/>
                                    <input type="hidden" name="idItem" defaultValue={props.operatorDetail[0].id || ''}/>
                                    <FormGroup>
                                        <Label>{i18next.t('title')}</Label>
                                        <Input type="text" id="title" name="title" defaultValue={decodeURI(props.operatorDetail[0].titulo) || ''} maxLength={'80'}></Input>
                                    </FormGroup>  
                                    <FormGroup>
                                        <Label>{i18next.t('description')}</Label>
                                        <Input type="text" id="description" name="description" defaultValue={decodeURI(props.operatorDetail[0].descripcion) || ''} maxLength={'80'}></Input>
                                    </FormGroup>
                                    <FormGroup>
                                        <Input type="hidden" id="image" name="image" defaultValue={props.tmpImage}/>
                                        <Button className="btn-lg btn-block" color="primary" onClick={ (e) => props.toggleDropzone()}><FontAwesomeIcon icon={faImages}/> {i18next.t('add_image')}</Button>
                                    </FormGroup>
                                    <Row>
                                        <Col className="text-center previewCard">
                                            <h4>{i18next.t('preview')}</h4>
                                            <img src={props.tmpImage} className="img-fluid" style={{'maxHeight':'300px', maxWidth:'320px'}}/>
                                        </Col>
                                    </Row>
                                    
                                    
                                </Form>          
                            </Col>
                            <Col  className="buttonModal">
                                <Col className="col-10 offset-1">
                                    <Row>
                                        <Col className="col-6 offset-6"><Button className="btn-block btn-guarumo-modal-sm" type="submit" form="frm_updateOperatorItem">{i18next.t('save')}</Button></Col>         
                                    </Row>
                                </Col>
                            </Col>
                        </Row>
                </ModalBody>
                </Modal> 
            }   
            { props.operatorDetail.length != 0 && 
            <Modal className="modalRight moreModal" isOpen={props.modalOpenFile} fade toggle={ () => props.closeModalFile() }>
                <ModalHeader toggle={() => props.closeModalFile() } className={props.operator.type}>
                    <FontAwesomeIcon icon={faArrowLeft} onClick={()=>{ props.closeModalFile()}}/> 
                        <span className="spanTitle">{props.operatorDetail[0].titulo || ''}</span>
                </ModalHeader>
                <ModalBody>
                    <Row className="m-0">
                        <Col md={12} className="sectionBox">
                                <FormGroup>
                                <Label>{i18next.t('url_image')}</Label>
                                    <Form  id="frm_file" onSubmit={props.saveUrl}>
                                        <InputGroup> 
                                            <Input className="inputGroup" type="text" id="imageModal" name="imageModal"/> 
                                            <InputGroupAddon addonType="append"><Button color="success" type="submit" style={{'background':'#75b74b', 'borderColor':'#75b74b','fontSize':'12px'}}>{i18next.t('save')}</Button></InputGroupAddon> 
                                        </InputGroup> 
                                    </Form>
                                </FormGroup>
                                <p className="text-center">o</p>
                                <Form id="frm_file">
                                <FormGroup>
                                    <Label>{i18next.t('upload_image2')}</Label>
                                    <Col md={12} className="p-0">
                                        <h5 className="text-danger" hidden={!props.error_modal_size}><FontAwesomeIcon icon={faTimes} /> {props.msj_error_modal_size}</h5>
                                        <h5 className="text-success" hidden={!props.ok_ventana_size }><FontAwesomeIcon icon={faCheck} /> {i18next.t('valid_size')}</h5>
                                    </Col>
                                    <div className="dropzone"> 
                                            <Dropzone 
                                            accept="image/*" 
                                            onDrop={props.onDrop1} 
                                            multiple={false} 
                                            >
                                              <aside> 
                                                {thumbsImgModal} 
                                              </aside> 
                                              <div className="text-center"> 
                                              <label id="label-dropzone-chat">{i18next.t('drag_click_image')}</label> 
                                            </div> 
                                          </Dropzone> 
                                          <Col md={12} className="text-center pt-2"> 
                                            <Button className="btn btn-guarumo-modal-sm" onClick={(e)=>{props.subirImg()}} disabled={props.buttonModalImg}>{i18next.t('upload')}</Button> 
                                          </Col> 
                                    </div> 
                                </FormGroup>  
                                <Col md={12}>
                                  <h5 className="text-primary"> * {i18next.t('upload_image_msg')}</h5>
                                </Col>
                            </Form>          
                        </Col>
                    </Row>
               </ModalBody>
            </Modal> 
            }
    </div>
  )
}

export default CmsLayout