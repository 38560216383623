import { AbstractNodeFactory } from "storm-react-diagrams";
import React from "react";
import BNodeWidget from "./BNodeWidget";
import BNodeModel from "./BNodeModel";

class BNodeFactory extends AbstractNodeFactory {
	constructor() {
		super("blockchain");
	}

	generateReactWidget(diagramEngine, node) {
		return <BNodeWidget node={node} />
	}

	getNewInstance() {
		return new BNodeModel();
	}
}

export default BNodeFactory