import React from 'react';
import { 
    Button, 
    Modal, 
    ModalHeader, 
    ModalBody, 
    ModalFooter,
    Form, 
    FormGroup, 
    Label, 
    Input, 
    Col,
    Row,
} from 'reactstrap';
import '../../admin/components/toolbar.css';
import '../../admin/components/modal-nuevo-bot.css'
import i18next from '../../../../i18n';

function ModalNuevoSchema(props){
    return (
        <div>
            <Modal isOpen={props.isOpen} toggle={props.toggleModal} className="modal-lg" fade>
                <ModalHeader toggle={props.toggleModal} charCode="X">{i18next.t('edit_flow')}</ModalHeader>
                <Form id="form-createSchema" onSubmit={props.updateSchema} role="form">
                <ModalBody>
                        <Input type="hidden" name="scheme_id" defaultValue={props.dataSchema.idschema}></Input>
                        <FormGroup>
                            <Row>
                                <Col sm="2">
                                    <Label for="title" className="control-label required-field">{i18next.t('title')+': '}</Label>
                                </Col>
                                <Col sm="10">
                                    <Input type="text" className="inputModal" id="title" name="title" maxLength="30" required defaultValue={props.dataSchema.title}></Input>
                                </Col>
                            </Row>
                        </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={props.toggleModal} outline color="secondary">{i18next.t('cancel')}</Button>
                    <Button type="submit" size="lg" className="btn-guarumo-modal">{i18next.t('save')}</Button>
                </ModalFooter>
                </Form>
            </Modal>
        </div>
    );
}
  
export default ModalNuevoSchema