import React, { Component } from 'react';
import WVMLayout from './web-view-modal-layout';
import axios from 'axios';
import qs from 'qs';
import { getHandlerGET,getHandlerPUT } from '../../../../utils/handlersApi'
import { cerrarSesion } from '../../../../utils/commonFunction'

class WVModal extends Component {

    state = {
        nodeId: "",
        title: "",
        selection:  null,
        range: null,
        keyword_valid: true,
        keyword: "",
    }

    constructor(props){
        super(props);
    }

    componentWillMount(){
        var auth=JSON.parse(localStorage.getItem('Authorization'));
        if(!auth || !auth.access_token)
          this.props.history.push("/login");
    }

    getAvatarId = (avatarid) => {
        this.props.modal.node.extras.avatar = avatarid;
    }
    getTitleOperator = (titleOperator) => {
        this.props.modal.node.extras.titleOperator = titleOperator
    }
    getCloseButton = (closeButton) => {
        this.props.modal.node.extras.xCheck = closeButton.target.checked
    }
    getHandler(url){
        var auth=JSON.parse(localStorage.getItem('Authorization'));
        var headers={
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': 'Bearer '+auth.access_token
        }

        return axios({
          method:'get',
          url: url,
          headers:headers
        })
    }

    saveInputs = (operator, form, inputs) => {
        const data = {
            operator_id: operator,
            form_id: form,
            inputs: inputs
        };

        var auth=JSON.parse(localStorage.getItem('Authorization'));
        var headers={
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer '+ auth.access_token 
        }

        return axios({
            method:'post',
            url:HOST_API +'/api/webforms/inputs',
            data:qs.stringify(data),
            headers:headers
        });
    }

    toggleModuleModal = () =>{
        if(!this.state.keyword_valid)
            return false;

        let form = new FormData(document.getElementById('WVM-form'));
        let node = this.props.modal.node;
        node.extras.botmsg_text = $('#input-head').val();
        // node.extras.keyword = form.get('keyword');
        node.extras.botmsg_id = form.get('botmsg_id');
        node.extras.webform = form.get('webform');
       
        let idFrm = parseInt(node.extras.webform.replace("/webform/", ""));
        let title = this.props.modal.node.extras.titleOperator;
        this.saveTitle(title).then(res => {
            this.props.updateSchema();
            this.forceUpdate();
        }).catch((error)=>{
            console.log('catch saveTitle',error);
            if (error.response && error.response.status && error.response.status == 403){
                cerrarSesion();
            }
        });
        if(idFrm){
        //de ir un condicional, solo viene id cuando se crear el form
        getHandlerGET(HOST_API +"/api/webforms/?id="+idFrm)
            .then((res) =>{
                if(res.status==200){
                    if(res.data.webform.template){
                        const inputs = JSON.parse(res.data.webform.template);
                        let frmInputs = [];
                        inputs.forEach((input, index) => {
                            frmInputs.push({name: input.name, text: input.label,is_var:false,var_name:''});
                        });

                        this.saveInputs(node.extras.dbId, idFrm, JSON.stringify(frmInputs)).then((res2) => {
                        }).then((err) => true).catch((error)=>{
                            console.log('catch saveInputs',error);
                            if (error.response && error.response.status && error.response.status == 403){
                                cerrarSesion();
                            }
                        });
                    }else{
                        console.log("este form no fue creado usando el form builder");           
                    }
                }else{
                    console.log(" No se pudo recuperar la informacion del webform");
                }
            }).catch((error)=>{
                console.log('catch getHandlerGET',error);
                if (error.response && error.response.status && error.response.status == 403){
                    cerrarSesion();
                }
            });
        }
        //TO-DO
        /*
        * *
        * * Consultar todos los inputs del formulario seleccionado
        * * Los guardo asincronamente en base de datos
        * * Todo continua con normalidad
        * *
        */

        //create the botmsg statement if it doesn't exists.
        if(!node.extras.botmsg_id){
            this.saveStatement(node.extras.dbId)
                .then((res) => {
                    if (res.status== 200){
                        node.extras.botmsg_id = res.data.statement.id;
                        this.updateStatement(node.extras.botmsg_id, node.extras.botmsg_text, node.extras.botmsg_text).then((res) => {
                            this.props.updateSchema();
                            this.forceUpdate(); 
                        })
                    }
                }).catch((error)=>{
                    console.log('catch saveStatement',error);
                    if (error.response && error.response.status && error.response.status == 403){
                        cerrarSesion();
                    }
                });
        }else { // edit the statement.
            this.updateStatement(node.extras.botmsg_id, node.extras.botmsg_text, node.extras.botmsg_text).then((res) => {
                this.props.updateSchema();
                this.forceUpdate();
            }).catch((error)=>{
                console.log('catch updateStatement',error);
                if (error.response && error.response.status && error.response.status == 403){
                    cerrarSesion();
                }
            });
        }

        this.props.toggleModuleModal('webview');

    }

    saveTitle = (title) => {
        const data = {
            id: this.props.modal.node.extras.dbId,
            title:title
        };

        var auth=JSON.parse(localStorage.getItem('Authorization'));
        var headers={
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer '+ auth.access_token 
        }

        return axios({
            method:'put',
            url:HOST_API +'/api/scheme/operator/',
            data:qs.stringify(data),
            headers:headers
        })
    }

    saveStatement = (operator) => {
        const data = {
            operator_id: operator,
            text: "",
            name: ""
        };

        var auth=JSON.parse(localStorage.getItem('Authorization'));
        var headers={
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer '+ auth.access_token 
        }

        return axios({
            method:'post',
            url:HOST_API +'/api/scheme/statement/',
            data:qs.stringify(data),
            headers: headers
        });
    }
    updateItem = (text) => {  
        this.props.modal.node.extras.botmsg_text = text;
        this.forceUpdate();
    }
    updateStatement = (statement, text, name) => {
        const data = {
            id: statement,
            text: text,
            name: name,
            is_var:false,
            var_name:''
        };

        var auth=JSON.parse(localStorage.getItem('Authorization'));
        var headers={
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer '+ auth.access_token 
        }

        return axios({
            method:'put',
            url:HOST_API +'/api/scheme/statement/',
            data:qs.stringify(data),
            headers:headers
        });
    }


    /* END SEARCH VARIABLE FUNCTIONS AND HANDLERS */

    isKeywordValid = (val) => {        
        this.setState({keyword_valid:val}, ()=>{
            this.forceUpdate();
        })
    }

    closeModal = () => {
        this.props.closeModal('m7');
        this.forceUpdate();
    }

    updateKeyword = (text) => {  
        this.props.modal.node.extras.keyword = text;
        this.saveKeyword(text);
        this.forceUpdate();
    }


        /* Update data de keyword */
    saveKeyword = (keyword) => {
            const data = {
                id: this.props.modal.node.extras.dbId,
                keyword:keyword
            };
            var auth=JSON.parse(localStorage.getItem('Authorization'));
            var headers={
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Bearer '+ auth.access_token 
            }
            getHandlerPUT(HOST_API +'/api/scheme/operator/',data)
            .then((res)=>{
                if(res.status!=200)
                    console.log('Error actualizando updateKeyword',res);
            }).catch((error) => { 
                console.log('catch updateKeyword',error)
                if (error.response.status == 403)
                    cerrarSesion();
            }); 
        }

            //Emoji
    callEmoji = (code,k,type)=>{
        let text = this.props.modal.node.extras.botmsg_text
            text += ` ${code}`;
        this.updateItem(text) 
    }

     // IA set node extras
     getIAId = IAid => this.props.modal.node.extras.ia_id = IAid;

    render() {
        return (
          <WVMLayout 
          toggleModuleModal={this.toggleModuleModal}
          isOpen={this.props.modal.isOpen}
          botmsg_text={this.props.modal.node.extras.botmsg_text}
          botmsg_id={this.props.modal.node.extras.botmsg_id}
          webform={this.props.modal.node.extras.webform}
          listWebform={this.props.listWebform}
          nodeId={this.state.nodeId}

          keyword={this.props.modal.node.extras.keyword}
          isKeywordValid={this.isKeywordValid}
          updateKeyword={this.updateKeyword}

          title={this.state.title}
          botversion_id={this.props.botversion_id}
          operator_id={this.props.modal.node.extras.dbId}
          ia={this.props.modal.node.extras.ia_id || 'none'}
          varList={this.props.varList}
          updateItem={this.updateItem}
          closeModal={this.closeModal}

          avatarid={this.props.modal.node.extras.avatar || 0}
          getAvatarId={this.getAvatarId}
          getIAId = {this.getIAId}
          company_id={this.props.company_id}
          titleOperator = {this.props.modal.node.extras.titleOperator || ''}
          getTitleOperator = {this.getTitleOperator}
          callEmoji = {this.callEmoji}
          xCheck = {this.props.modal.node.extras.xCheck}
          getCloseButton = {this.getCloseButton}
          />
        )
    }
}

export default WVModal