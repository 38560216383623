import { COMPANY_ACTIONS } from '../types/companyActionsTypes';
import { ANALYTICS_ACTIONS_TYPES } from "../types/analyticsActionsTypes";
import { DATABASE_ACTIONS } from '../types/databaseActionTypes';
import { MODAL_LOAD_ACTIONS_TYPES } from "../types/loadStateActionsTypes";
import { getHandlerGET, getHandlerPOST, getHandlerDELETE, getHandlerPUT, fileHandlerPOST_Token, getHandlerGET_TOKEN, getHandlerTokenGET } from '../../utils/handlersApi';
import { logOut } from './loginActions';
import { showSuccess, loading, showWarning, hideAlert } from './loadStateActions';
import { cerrarSesion, isEmpty } from '../../utils/commonFunction';
import i18next from '../../../i18n';


/* ========================================
    SHOW COLLECTION DATABASE FRO COMPANY
==========================================*/
export const showDatabaseCollect = (id_company) => async (dispatch, getState) => {

    const { selectedCompany } = getState().analyticsReducer;
    let idCompany = selectedCompany?selectedCompany:id_company;
    
    const URL = `${HOST_API}/api_backend_ai/dinamic-db/collections_company/${idCompany}`;

    dispatch(loading());
    await getHandlerGET_TOKEN(URL)
    .then((res)=>{
        if (res.status == 200) {
            console.log('res.data.collections ',res.data.collections);
            if (!isEmpty(res.data.collections)) {
                dispatch({
                    type: DATABASE_ACTIONS.getCollectionByCompany(),
                    payload: res.data.collections
                });
            } else {
                dispatch({
                    type: DATABASE_ACTIONS.getCollectionByCompany(),
                    payload: []
                });
            }
        }
        dispatch(hideAlert());
    })
    .catch((error)=>{
        if (error.response.status == 403){
            dispatch(logOut());
            cerrarSesion();
        }
        dispatch(hideAlert());
        dispatch(showWarning(i18next.t('message144')));
    });
    
};
/* =============================================
    END SHOW COLLECTION DATABASE FRO COMPANY
================================================*/



/* ========================================
        CREATE COLLECTION DATABASE 
==========================================*/
export const uploadFileDinamic = (formulario) => async (dispatch, getState) => {

    const { selectedCompany } = getState().analyticsReducer;
    const URL = `${HOST_API}/api_backend_ai/dinamic-db/upload_file/${selectedCompany}/${formulario.nameCollection}`; 

    let form = new FormData();
    
    form.append("overwrite", "true");
    form.append("data_file", formulario.archivoCollection);
    //await dispatch(iconLoad());
    await fileHandlerPOST_Token(URL, form)
        .then((res)=>{
            console.log('RES DATA ',res);
            if(res.status==200){
                dispatch(showSuccess(i18next.t('message188'),3000));
            }
            else if(res.status==202 ){
                dispatch(showSuccess(i18next.t('db_generate_msg'),3000));
            }
            else if(res.status==504){
                dispatch(showWarning(i18next.t('db_generate_msg'),3000));
            }
        
        })
        .catch((error) => {
            dispatch({
                type: MODAL_LOAD_ACTIONS_TYPES.showWarning(),
                payload: '¡Error al crear el bot, intente nuevamente!'
            });
            const { response: { status } = { status } } = error; 
            if (status  && status == 403){
                logOut();
                cerrarSesion();
            }
        });
     dispatch(showSuccess(i18next.t('message188'),4000));
     setTimeout(() => {
        dispatch(showDatabaseCollect(0));
     },2000)
     
    //await dispatch(iconHideLoad());
};
/* ========================================
        END CREATE COLLECTION DATABASE 
==========================================*/

/* ========================================
        UPDATE COLLECTION DATABASE 
==========================================*/
export const uploadFileDinamicUpdate = (formulario) => async (dispatch, getState) => {

    const { selectedCompany } = getState().analyticsReducer;

    //const URL = `${HOST_API}/api_backend_ai/dinamic-db/upload_file/${selectedCompany}/${formulario.nameCollection}`; 
    const URL = `${HOST_API}/api_backend_ai/dinamic-db/upload_file/${selectedCompany}/${formulario.nameCollection}`; 
   
    let form = new FormData();
   
    if(formulario.addRegister == true && formulario.deleteRegister == false){
        form.append("overwrite", "false"); //agrega registros y elimina los antiguos
    }else{
        form.append("overwrite", "true"); //agrega registros y no elimina los antiguos
    }
    
    form.append("data_file", formulario.archivoCollection);
    await dispatch(iconLoad());
    await fileHandlerPOST_Token(URL, form)
        .then((res)=>{
            console.log('RES DATA ',res);
            if(res.status==200){
                dispatch(showSuccess(i18next.t('message187'),3000));
            }else if(res.status==202){
                dispatch(showSuccess(i18next.t('db_generate_msg'),3000));
            }else if(res.status==504){
                dispatch(showWarning(i18next.t('db_generate_msg'),3000));
            }

        })
        .catch((error) => {
            dispatch({
                type: MODAL_LOAD_ACTIONS_TYPES.showWarning(),
                payload: '¡Error al crear el bot, intente nuevamente!'
            });
            const { response: { status } = { status } } = error; 
            if (status  && status == 403){
                logOut();
                cerrarSesion();
            }
        });
    //await dispatch(showSuccess(i18next.t('message187'),3000));
    //await dispatch(iconHideLoad());
};
/* ========================================
        END UPDATE COLLECTION DATABASE 
==========================================*/


/* ========================================
        VISUALIZAR COLLECTION DATABASE 
==========================================*/
export const uploadFileDinamicVisualizar = (formulario,page="",rowPage="") => async (dispatch, getState) => {
    

    const { selectedCompany } = getState().analyticsReducer;
    const { nombreCompany } = getState().databaseReducer;
    let idCompany = selectedCompany?selectedCompany:'';
    

    const URL = `${HOST_API}/api_backend_ai/dinamic-db/report/${idCompany}/${nombreCompany == null ? formulario : nombreCompany}?pagination=${rowPage == '' ? 5 :rowPage}&page=${page == '' ? 1 : page}`;

    console.log('showDatabaseCollect URL ',URL);
    
    //return;

    dispatch(loading());
    await getHandlerGET_TOKEN(URL)
    .then((res)=>{
        if (res.status == 200) {

            console.log('uploadFileDinamicVisualizar res.data.collections ',res.data);

            if (!isEmpty(res.data.results)) {

                dispatch({
                    type: DATABASE_ACTIONS.getVisualizarCollection(),
                    payload: res.data.results
                });

                dispatch({
                    type: DATABASE_ACTIONS.getVisualizarCollectionPagination(),
                    payload: res.data
                });

                dispatch({
                    type: DATABASE_ACTIONS.getNameCollection(),
                    payload: nombreCompany == null ? formulario : nombreCompany
                });



            } else {

                dispatch({
                    type: DATABASE_ACTIONS.getVisualizarCollection(),
                    payload: []
                });


                
            }
        }
        dispatch(hideAlert());
    })
    .catch((error)=>{
        if (error.response.status == 403){
            dispatch(logOut());
            cerrarSesion();
        }
        dispatch(hideAlert());
        dispatch(showWarning(i18next.t('message144')));
    });

    await dispatch(iconHideLoad());
};
/* ========================================
        END VISUALIZAR COLLECTION DATABASE 
==========================================*/

export const uploadDatabaseName = (formulario) => async (dispatch, getState) => {
    console.log("uploadDatabaseName formulario ",formulario);
    dispatch({
        type: DATABASE_ACTIONS.getNombreCompany(),
        payload: formulario
    });
};

const array = [
    {
      columna: "FECHA",
      comparar: "43746",
      condicion: ">"
    },
    {
      columna: "DESCRIPCION",
      comparar: "44844",
      condicion: "<="
    },
    {
      columna: "TIPO ID",
      comparar: "Documento unico de identificación",
      condicion: ">="
    }
  ];

  const r = array.map( i => `("${i.columna}","${i.condicion}","${i.comparar}")`);
console.log(r)

/* ========================================
        FILTRAR COLLECTION DATABASE 
==========================================*/
export const filtrarCollection = (formulario,pagination=5,page=1) => async (dispatch, getState) => {

    const { selectedCompany } = getState().analyticsReducer;
    const { nameCollection } = getState().databaseReducer;
    
    let infoData = JSON.parse(formulario);
    
    console.log("infoData ",infoData);

    let idCompany = selectedCompany?selectedCompany:'';
    
    const baseUrl = `${HOST_API}/api_backend_ai/dinamic-db/report/${idCompany}/${nameCollection}`;

    
    let sendFilter = infoData.map((i, index) => {
        const tuple = `("${i.columna}","${i.condicion}","${i.comparar}")`;
        return index < array.length - 1 ? tuple + ',' : tuple;
      }).join('\n')
    
      let Params = '';

    if(infoData.length <= 0){
        
        Params = {
            pagination: pagination,
            page: page == 0 ? 1: page,
        }
      
    }else{
        
        Params = {
            pagination: pagination,
            page: page == 0 ? 1: page,
            filters:  `[`+sendFilter+`]` //'[("Fecha_hora_inicio_ciclo",">=","2024-02-02")]'
        }

    }
    

      const urlParams = Object.keys(Params)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(Params[key])}`)
      .join('&');

      
    const urloriginal = `${baseUrl}?${urlParams}`;
      
    dispatch(loading());
    
    await getHandlerGET_TOKEN(urloriginal)
    .then((res)=>{
        if (res.status == 200) {

            console.log('filtrarCollection res.data.collections ',res.data.results);

            if (!isEmpty(res.data.results) && Array.isArray(res.data.results)){
                
     

                dispatch({
                    type: DATABASE_ACTIONS.getVisualizarCollection(),
                    payload: res.data.results
                });


                dispatch({
                    type: DATABASE_ACTIONS.getVisualizarCollectionPagination(),
                    payload: res.data
                });
                
                dispatch(iconHideLoad());

                /*dispatch({
                    type: DATABASE_ACTIONS.getSinRegistrosFiltroCollection(),
                    payload: true
                });*/

            } else {
                 dispatch(showWarning(res.data.results,6000));
            }
        }
        
    })
    .catch((error)=>{
        if (error.response.status == 403){
            dispatch(logOut());
            cerrarSesion();
        }
        dispatch(hideAlert());
        dispatch(showWarning(i18next.t('message144')));
    });

 
};
/* ========================================
        END FILTRAR COLLECTION DATABASE 
==========================================*/

/*=========================================
            SELECT COMPANY ID
==========================================*/
export const selectCompanyAction = (company_id) => async (dispatch) => {
    dispatch({
        type   : ANALYTICS_ACTIONS_TYPES.selectedCompany(),
        payload: company_id
    });      
}
/*=========================================
        END SELECT COMPANY ID
==========================================*/

/*=========================================
            ICONO DE CARGAR
===========================================*/
export const iconLoad = () => (dispatch, getState) => {
    dispatch({
      type: MODAL_LOAD_ACTIONS_TYPES.loading(),
    });
  };
  
export const iconHideLoad = () => (dispatch, getState) => {
    dispatch({
      type: MODAL_LOAD_ACTIONS_TYPES.hideLoad(),
    });
  };
/*=========================================
            END IDONO DE CARGAR
===========================================*/

export const deleteCompany = (companyID, data) => async (dispatch) => {
    const URL = `${HOST_API}/api/company/status/${companyID}`;
    dispatch(loading());

    await getHandlerPUT(URL, {status: data})
    .then((res)=>{
        if(res.status == 200){
            dispatch(showSuccess(i18next.t('message136'),2000));
            dispatch(getAllCompanies());
        }        
    })
    .catch((error)=>{
        if (error.response.status == 403){
            dispatch(logOut());
            cerrarSesion();
        }
        dispatch(hideAlert());
        dispatch(showWarning(i18next.t('message137')));
    })
        
};

export const saveCompany = (data,createUser = () => {}) => async (dispatch) => {
    const URL = `${HOST_API}/api/company/`;

    dispatch(loading());

    await getHandlerPOST(URL, data)
    .then((res)=>{
        if (res.status == 200) {
            createUser(res.data.company.id);
            dispatch(showSuccess(i18next.t('message138'),2000));
            dispatch(getAllCompanies());
        }
    })
    .catch((error)=>{
        if (error.response.status == 403){
            dispatch(logOut());
            cerrarSesion();
        }
        dispatch(hideAlert());
        dispatch(showWarning(i18next.t('message139')));
    });
};

export const editCompany = (companyID,data) => async (dispatch) => {

    const URL = `${HOST_API}/api/company/${companyID}`;
    dispatch(loading());

    await getHandlerPUT(URL, data)
    .then((res)=>{
        if (res.status == 200) {
            dispatch(showSuccess(i18next.t('message140'),2000));
            dispatch(getAllCompanies());
        }
    })
    .catch((error)=>{
        if (error.status == 403){
            dispatch(logOut());
            cerrarSesion();
        }
        dispatch(hideAlert());
        dispatch(showWarning(i18next.t('message141')));
    });
};

    /* Users Actions */
export const newUser = (data) => async (dispatch) => {
    const URL = `${HOST_API}/api/user/new_user`
    await getHandlerPOST(URL, data)
    .then((res)=>{
        if (res.status == 200) {
            dispatch(showSuccess(i18next.t('message142'),2000));
        }
    })
    .catch((error)=>{
        if (error.response.status == 403){
            dispatch(logOut());
            cerrarSesion();
        }
        dispatch(hideAlert());
        dispatch(showWarning(i18next.t('message143')));
    });
};

export const getListUser = (companyID) => async (dispatch) => {
    const URL = `${HOST_API}/api/company/users/${companyID}`;
};

export const saveEditUser = (data, success) => async (dispatch) => {
    const URL = `${HOST_API}/api/user/admin`;
    dispatch(loading());
    await getHandlerPOST(URL,data)
    .then((res)=>{
        if (res.status == 200) {
            let ms = '';
            if(!success){
                ms=i18next.t('message145');
                dispatch(getListUser(data.company_id));
            }else{
                ms = i18next.t('message146');
                success();
            }
            dispatch(showSuccess(ms,2000));
        }
    })
    .catch((error)=>{
        if (error.response.status == 403){
            dispatch(logOut());
            cerrarSesion();
        }
        dispatch(hideAlert());
        dispatch(showWarning(i18next.t('message147')));
    });
};

export const saveNewUser = (data) => async (dispatch) => {
    const URL = `${HOST_API}/api/user/new_user`;
    dispatch(loading());
    await getHandlerPOST(URL, data)
    .then((res)=>{
        if (res.status == 200) {
           dispatch(showSuccess(i18next.t('message142'),1000));
           dispatch(getListUser(data.company_id));
        }
    })
    .catch((error)=>{
        if (error.response.status == 403){
            dispatch(logOut());
            cerrarSesion();
        }
        dispatch(hideAlert());
        dispatch(showWarning(i18next.t('message143')));
    });
};

export const deleteUser = (user_id, company_id) => async (dispatch) => {
    const URL = `${HOST_API}/api/user/${user_id}`;
    dispatch(loading());
    await getHandlerDELETE(URL)
    .then((res)=>{
        if (res.status == 200) {
           dispatch(showSuccess(i18next.t('message148'), 2000));
           dispatch(getListUser(company_id));
        }
    })
    .catch((error)=>{
        if (error.response.status == 403){
            dispatch(logOut());
            cerrarSesion();
        }
        dispatch(hideAlert());
        dispatch(showWarning(i18next.t('message149')));
    });
};



 /* Avatar */
 export const listAvatar = (company_id) => async (dispatch) => {
    const URL = `${HOST_API}/api/avatar/?company_id=${company_id}`;
    
    await getHandlerGET(URL)
      .then((res) => {
        console.log("response: ", res);
        if (res.status == 200)
        {
            dispatch({
                type: COMPANY_ACTIONS.getAvatarsByCompany(),
                payload: res.data.data
            });        
        }
      })
      .catch((error)=>{
        if (error.response.status == 403){
            dispatch(logOut());
            cerrarSesion();
        }
        dispatch(hideAlert());
        dispatch(showWarning(i18next.t('message149')));
    });
  }

export const listAvatarBot = (bot_id) => async (dispatch) => {
    const URL = `${HOST_API}/api/avatar/bot/${bot_id}`;
   
    await getHandlerGET(URL)
      .then((res) => {
        if (res.status == 200) {
          let result = res.data.data,
            ar2 = Object.keys(result),
            ar = Object.values(result);
          let arreglo = {};
          for (let i = 0; i < ar2.length; i++) {
            if (arreglo[ar[i]]) {
              arreglo[ar[i]] += ", " + ar2[i];
            } else {
              arreglo[ar[i]] = ar2[i];
            }
          }
          this.setState({ avatarListBot: arreglo });
        }
      })
      .catch((error)=>{
        if (error.response.status == 403){
            dispatch(logOut());
            cerrarSesion();
        }
        dispatch(hideAlert());
        dispatch(showWarning(i18next.t('message149')));
    });
  }


/* ========================================
        VISUALIZAR COLLECTION DATABASE 
==========================================*/
export const visualizarLogs = (formulario) => async (dispatch, getState) => {

    const { selectedCompany } = getState().analyticsReducer;
    
    let idCompany = selectedCompany?selectedCompany:'';
   
    const URL = `${HOST_API}/api_backend_ai/dinamic-db/logs/${idCompany}?collection=${formulario}`;

    dispatch(loading());
    await getHandlerGET_TOKEN(URL)
    .then((res)=>{
        if (res.status == 200) {

            if (!isEmpty(res.data.result) && res.data.result != "null") {

                dispatch({
                    type: DATABASE_ACTIONS.getVisualizarCollection(),
                    payload: res.data.result
                });

                dispatch({
                    type: DATABASE_ACTIONS.getNombreCompany(),
                    payload: formulario
                });

            } else {

                dispatch({
                    type: DATABASE_ACTIONS.getVisualizarCollection(),
                    payload: []
                });

                dispatch({
                    type: DATABASE_ACTIONS.getNombreCompany(),
                    payload: formulario
                });
                
            }
        }
        dispatch(hideAlert());
    })
    .catch((error)=>{
        if (error.response.status == 403){
            dispatch(logOut());
            cerrarSesion();
        }
        dispatch(hideAlert());
        dispatch(showWarning(i18next.t('message144')));
    });

    await dispatch(iconHideLoad());
};
/* ========================================
        END VISUALIZAR COLLECTION DATABASE 
==========================================*/

export const showValues = (name) => async (dispatch, getState) => {
    
    const cleanedName = name.replace(/['"]+/g, '');


    const { selectedCompany } = getState().analyticsReducer;
    const { nombreCompany } = getState().databaseReducer;
    
    let idCompany = selectedCompany?selectedCompany:'';

    let URL = `${HOST_API}/api_backend_ai/dinamic-db/get_filters/${idCompany}/${nombreCompany}/${cleanedName}`;
    


    dispatch(loading());
    
    await getHandlerTokenGET(`${URL}`).then((res)=>{
        if(res.status==200||res.status==204){  
            if(res.data && res.data.result.length > 0 && res.data.result != "null"){
                dispatch({
                    type: DATABASE_ACTIONS.arrayDataBasesShowValues(),
                    payload: res.data.result
                });
            }
        }
    }).catch((error) => {});

    await dispatch(iconHideLoad());

}