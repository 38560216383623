import React, { Component } from 'react';
import { connect } from "react-redux";

import ModalLoading from  '../../../components/modals/Loading/moda-loading';

import DashboardLayout from './bot-dashboard-layout';
import moment from "moment";

import { isAuthorizationValid,getRolUser,isEmpty } from "../../../utils/commonFunction";
//import DonutData from '../components/infoViz/chart-react-d3/d3-examples/donutChart';



import { APPLICANT } from "../../../redux/listComponents";
import { isDidMount, willUnMount } from "../../../redux/actions/componentMountActions";
import { iconLoad, iconHideLoad, getTockenCubejs,  getAllListCompanies,getAllDashboards,getDashboard, getPreview, getAllPalettes, cleanAllPreviews, getItems } from "../../../redux/actions/analyticsActions";


class Dashboard extends Component {
  constructor(props){
    super(props);
    this.state = {
        isAdmin: false,
        isSuperUser: false,
        hideLoad: false,
        user:'',
       // companies : [],
        startDate: moment(),
        endDate: moment(),
    }; 

    this.handleChangeStart = this.handleChangeStart.bind(this);
    this.handleChangeEnd = this.handleChangeEnd.bind(this);
}


componentWillMount(){
  isAuthorizationValid()
  const { isAdmin = false, isSuper = false } = getRolUser();
  this.setState({
      isAdmin: isAdmin,
      isSuperUser: isSuper
  });

}

  /*
    * Función para cambiar las fechas del filtro
    * d es la fecha en moment.js
    */
   handleChangeStart(d){
    this.setState({startDate: d});
    }
    handleChangeEnd(d){
        this.setState({endDate: d});
    }


    
  async componentDidMount(){

    const { analyticsReducer : { activePage,listAllDashboards},cleanAllPreviews } = this.props;
    const { homeReducer : {  listAllCompanies,allPlatforms} } = this.props;

    this.props.isDidMount(APPLICANT.HOME());    

   
        //----------------------------------------------------------------
        //  PONE EL ICONO DE CARGA
        //----------------------------------------------------------------
        await this.props.iconLoad();
        //----------------------------------------------------------------
        //  ACTUALIZA EL TOCKEN DE CUBEJS
        //----------------------------------------------------------------
        await this.props.getTockenCubejs();

        //----------------------------------------------------------------
        //  LISTA TODAS LAS COMPAÑÍAS, COJE LA PRIMERA COMO SELECTCOMPANY
        //----------------------------------------------------------------
        if(isEmpty(listAllCompanies)){
          await this.props.getAllListCompanies();     
        }

        const { analyticsReducer : {selectedCompany }} = this.props;
        //--------------------------------------------------------------------------------------------
        //  LISTA TODOS LOS DASHBOARD - COJE EL PRIMERO CON SELECTEDDASHBOARD y LIMPIA LOS PREVIEWS
        //--------------------------------------------------------------------------------------------
        if(selectedCompany!='' && isEmpty(listAllDashboards)){
          await this.props.getAllDashboards(activePage); 
        }

     
     
    }

  shouldComponentUpdate(nextProps, nextState) {
    //----------------------------------------------------
    //  SOLO EJECUTA EL UPDATE SI LA COMPAÑIA FUE CAMBIADA
    //----------------------------------------------------
    const nextCompany = nextProps.analyticsReducer.selectedCompany;
    const company = this.props.analyticsReducer.selectedCompany;
    return nextCompany !== company;
  }

  componentWillUpdate(nextProps,nextState)
  {

    //-------------------------------------------------------------------
    //  ACTUALIZA LA LISTA DE DASHBOARD DE LA COMPAÑIA SI SE SELECCIONA OTRA COMPAÑÍA
    //------------------------------------------------------------------- 
    const nextCompany = nextProps.analyticsReducer.selectedCompany;
    const company = this.props.analyticsReducer.selectedCompany;

    if(nextCompany!==company)
      this.props.getAllDashboards(); 

  }
  componentWillUnmount() {
    this.props.willUnMount(APPLICANT.HOME());
  }


    drawGrapihc = () => {
      console.log("Fechas: ",startDate,endDate)
  }


showContent=()=>{
  const {  isLogin } = getRolUser();
  if(isLogin){
    return (
      <React.Fragment>
        <DashboardLayout
            isAdmin={this.state.isAdmin}
            isSuperUser={this.state.isSuperUser}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            handleChangeStart ={this.handleChangeStart}  
            handleChangeEnd ={this.handleChangeEnd} 
            getData = {this.drawGrapihc}
            forceUpdate ={this.forceUpdate}
            /> 
  
   </React.Fragment>
   
    );
   }
   return <ModalLoading />
 }
    
   render() {
       return this.showContent();
   }
}
const mapStateToProps = ({ analyticsReducer,homeReducer, componentMountReducer }) => {
 return { analyticsReducer,homeReducer, componentMountReducer };
};

const mapDispatchToProps = {
 getAllDashboards,
 getDashboard,
 getAllListCompanies,
 isDidMount,
 willUnMount,
 iconLoad,
 getPreview,
 getAllPalettes,
 cleanAllPreviews,
 getTockenCubejs,
 iconHideLoad,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

