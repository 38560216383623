import { NodeModel } from "storm-react-diagrams";
import SMPortModel from "../simplePortModel/SMPortModel";

class TRIGGERNodeModel extends NodeModel {
	extras = {
		title: "TRIGGER",
		width: 200,
        dbId: 0,
		edited:false,
		
        listEvents: [],
        listLinks: [],

    }
	


	removeBox = null
	changeHome = null
	toggleEdit = null
	customClone = null
	
	constructor() {
		super("trigger");
		this.addPort(new SMPortModel(false, "left",'trigger'));
	}
}

export default TRIGGERNodeModel