import React from 'react';
import {
  Col,
  Row,
  Card,
  CardBody,
  CardHeader,
  Container,
  Form,
  Input,
 } from 'reactstrap';
 import FormRender from '../../../components/form-render';
 import './bot-webforms-layout.css';

function BotWebFormRenderLayout(props){

    return (
        <div>
            <Container fluid >
                <Row>
                    <div className="no-paddings" >
                      <Card>
                        <Form id="form-webform-builder">
                            <CardHeader>
                                <Row>
                                    <Col xs="12">
                                      <Input type="hidden" defaultValue={props.currentWebForm.id} name="id" hidden/>
                                      <h2 className="" >{props.currentWebForm.title} </h2>
                                    </Col>
                                </Row>
                            </CardHeader>
                        </Form>
                        <CardBody>
                            { props.currentWebForm.template?
                            (
                                <FormRender botSession={props.botSession} formData={props.currentWebForm.template} saveFormBuilder={props.saveFormBuilder} formId={props.currentWebForm.id}/> 
                            ) : null
                            } 
                        </CardBody>                        
                      </Card>
                    </div>
                </Row>
            </Container>  
    </div>
    );
}
  
export default BotWebFormRenderLayout;


