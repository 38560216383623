import React, { Component } from 'react';
import { connect } from "react-redux";
import i18next from '../../../../../i18n';

import CDMLayout from './calendar-date-modal-layout';
import axios from 'axios';
import qs from 'qs';
import { getHandlerGET } from '../../../../utils/handlersApi'

import { isAuthorizationValid,getRolUser,isEmpty,cerrarSesion } from "../../../../utils/commonFunction";

import { APPLICANT } from "../../../../redux/listComponents";
import { isDidMount, willUnMount } from "../../../../redux/actions/componentMountActions";

import { iconLoad, iconHideLoad, getListTypeCalendar,setBotmsgText,setChangeConfig,updateBoxOperator,toggleModalViewBoxCalendar,
    updateOperator,
    createNewStatement,
    updateStatement,
    removeStatement,
    cleanSelection,
    setVarList} from "../../../../redux/actions/boxActions";


class CDModal extends Component {

    constructor(props){
        super(props);
        this.state = {
            nodeId: "",
            title: "",
            selection:  null,
            range: null,
            keyword_valid: true,
            listTypeCalendar:[],
        
            isAdmin: false,
            isSuperUser: false,
            hideLoad: false,
            user:'',
        }; 
        
    }
    
     componentWillMount(){
        isAuthorizationValid()
        const { isAdmin = false, isSuper = false } = getRolUser();
        this.setState({
            isAdmin: isAdmin,
            isSuperUser: isSuper
        });
      }


    componentDidMount()
    {
        const { boxReducer : { listTypeCalendar,configBoxOperator} } = this.props;

        this.props.isDidMount(APPLICANT.HOME());    

        //----------------------------------------------------------------
        //  PONE EL ICONO DE CARGA
        //----------------------------------------------------------------
         this.props.iconLoad();

        //----------------------------------------------------------------
        //  LISTA TODAS LOS TIPOS DE CALENDARIO, COJE EL PRIMERO COMO SELECTTYPECALENDAR
        //----------------------------------------------------------------
        if(isEmpty(listTypeCalendar)){
             this.props.getListTypeCalendar();     
        }


        //----------------------------------------------------
        //  OCULTA EL ICONO DE CARGA
        //----------------------------------------------------
        this.props.iconHideLoad(); 

    }
   
   /* componentWillUpdate(nextProps,nextState)
    {
        const tab = this.props.boxReducer.activeItem;
        const newTab = nextProps.boxReducer.activeItem;

        if(tab != newTab)
        {
            this.forceUpdate();
        }
    }*/

    componentWillUnmount() {
        this.props.willUnMount(APPLICANT.HOME());
      }


    getAvatarId = (avatarid) => {
        this.props.modal.node.extras.avatar = avatarid;
    }
    getTitleOperator = (titleOperator) => {
        this.props.modal.node.extras.titleOperator = titleOperator
    }
    getHandler(url){
        var auth=JSON.parse(localStorage.getItem('Authorization'));
        var headers={
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': 'Bearer '+auth.access_token
        }

        return axios({
          method:'get',
          url: url,
          headers:headers
        })
    }

    saveInputs = (operator, form, inputs) => {
        const data = {
            operator_id: operator,
            form_id: form,
            inputs: inputs
        };

        var auth=JSON.parse(localStorage.getItem('Authorization'));
        var headers={
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer '+ auth.access_token 
        }

        return axios({
            method:'post',
            url:HOST_API +'/api/webforms/inputs',
            data:qs.stringify(data),
            headers:headers
        });
    }

    
    toggleModuleModal = async () =>{
        if(!this.state.keyword_valid)
            return false;


             //Pone icono de carga
        this.props.iconLoad();

        //Carga el formulario para extraer los datos
        let form = new FormData(document.getElementById('CDM-form'));

        //Selecciona el nodo asociado a la caja
        let node = this.props.modal.node;
        
        //Extrae y actualiza el mensaje
        node.extras.botmsg_text = $('#input-head_0').val();
        this.props.setChangeConfig('botmsg_text',node.extras.botmsg_text);

        //Extrae y actualiza el avatar
        //node.extras.avatar = $('#input-head').val();
        this.props.setChangeConfig('avatarid',node.extras.avatar);

        
        //Estrae y actualiza el título del operador
        node.extras.titleOperator = form.get('titleOperator');
        //OJO SE DEBE CAMBIAR AL COMPONENTE title-operator.js cuando todas las cajas usen redux
        await this.props.updateBoxOperator('title', node.extras.titleOperator);//this.getTitleOperator(node.extras.titleOperator);   

    
        const {configBoxOperator,selectedBoxOperator,variablesBoxOperator} = this.props.boxReducer;

        
        //Extrae y actualiza los Keywords
        node.extras.keyword = selectedBoxOperator.keyword;
        node.extras.botmsg_id = form.get('botmsg_id');
        
 
        
        let blocks = node.extras.statements_id;
        let kblocks = Object.keys(blocks);

        this.updateStatement(''+kblocks[0], node.extras.title, 'var_main',node.extras.is_var,node.extras.var_name).then((res) => {
            
        }).catch((error)=>{
            console.log('catch updateStatement',error);
            if (error.response && error.response.status && error.response.status == 403){
                cerrarSesion();
            }
        });

        this.updateStatement(''+kblocks[1], node.extras.title, 'var_json',node.extras.is_var,node.extras.var_name_json).then((res) => {
           
        }).catch((error)=>{
            console.log('catch updateStatement',error);
            if (error.response && error.response.status && error.response.status == 403){
                cerrarSesion();
            }
        });

       
       //Extrae y guarda la nueva configuración
       node.extras.config = configBoxOperator;

       await this.props.updateOperator(node.extras.titleOperator,node.extras.keyword,node.extras.config);

     
        //Actualiza el esquema
       await this.props.updateSchema();

       //Limpia selecciones
       await this.props.cleanSelection();

        //Actualiza la lista de variables
       await this.props.setVarList();

        //Cierra el modal
       await this.props.toggleModalViewBoxCalendar();

    }

    saveStatement = (operator) => {
        const data = {
            operator_id: operator,
            text: "",
            name: ""
        };

        var auth=JSON.parse(localStorage.getItem('Authorization'));
        var headers={
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer '+ auth.access_token 
        }

        return axios({
            method:'post',
            url:HOST_API +'/api/scheme/statement/',
            data:qs.stringify(data),
            headers: headers
        });
    }
    updateItem = (k,text) => {  
        this.props.modal.node.extras.botmsg_text = text;
        this.forceUpdate();
    }

    updateVarName = (event) => {
        this.props.modal.node.extras.var_name = event.target.value ;
        this.forceUpdate();
    }

    updateVarNameJson = (event) => {
        this.props.modal.node.extras.var_name_json = event.target.value ;
        this.forceUpdate();
    }

    updateStatement = (statement, text, name,is_var,var_name) => {
        const data = {
            id: statement,
            text: text,
            name: name,
            is_var:true,
            var_name:var_name
        };

        var auth=JSON.parse(localStorage.getItem('Authorization'));
        var headers={
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer '+ auth.access_token 
        }

        return axios({
            method:'put',
            url:HOST_API +'/api/scheme/statement/',
            data:qs.stringify(data),
            headers:headers
        });
    }


    /* END SEARCH VARIABLE FUNCTIONS AND HANDLERS */

    isKeywordValid = (val) => {        
        this.setState({keyword_valid:val}, ()=>{
            this.forceUpdate();
        })
    }

    closeModal = () => {
        this.props.closeModal('m_cd');
        this.forceUpdate();
    }

    updateKeyword = (text) => {  
        this.props.modal.node.extras.keyword = text;
        this.saveKeyword(text);
        this.forceUpdate();
    }

        /* Update data de keyword */
    saveKeyword = (keyword) => {

        const {selectedBoxOperator } = this.props.boxReducer;

            const data = {
                id: selectedBoxOperator.id,
                keyword:keyword
            };
            var auth=JSON.parse(localStorage.getItem('Authorization'));
            var headers={
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Bearer '+ auth.access_token 
            }
            return axios({
                method:'put',
                url:HOST_API +'/api/scheme/operator/',
                data:qs.stringify(data),
                headers:headers
            })
        }

            //Emoji
    callEmoji = (code,k,type)=>{
        let text = this.props.modal.node.extras.botmsg_text?this.props.modal.node.extras.botmsg_text:'';
            text += ` ${code}`;
       
        this.updateItem(k,text);
        
        this.props.setBotmsgText(text);
    }

    

    showContent=()=>{
        const {  isLogin } = getRolUser();
        if(isLogin){
          return (
 
          <CDMLayout 
          var_name={this.props.modal.node.extras.var_name}
          var_name_json={this.props.modal.node.extras.var_name_json}
          getAvatarId={this.getAvatarId}
          isKeywordValid={this.isKeywordValid}
          toggleModuleModal={this.toggleModuleModal}

          isOpen={this.props.modal.isOpen}
          botmsg_id={this.props.modal.node.extras.botmsg_id}
          type={this.props.modal.node.extras.type}
          listTypeCalendar={this.state.listTypeCalendar}
          nodeId={this.state.nodeId}

          keyword={this.props.modal.node.extras.keyword}
          
          updateKeyword={this.updateKeyword}

          title={this.state.title}
          botversion_id={this.props.botversion_id}
          operator_id={this.props.modal.node.extras.dbId}
          ia={this.props.modal.node.extras.ia_id || 'none'}
          varList={this.props.varList}
          updateItem={this.updateItem}
          updateVarName={this.updateVarName}
          updateVarNameJson={this.updateVarNameJson}
          closeModal={this.closeModal}

         
          avatarid={this.props.modal.node.extras.avatar || 0}
         
          company_id={this.props.company_id}
          titleOperator = {this.props.modal.node.extras.titleOperator || ''}
          getTitleOperator = {this.getTitleOperator}
          callEmoji = {this.callEmoji}

          config= {this.props.modal.node.extras.config|| ''}
          />
          );
        }
        return <ModalLoading />
      }

    render() {
        const {selectedBoxOperator } = this.props.boxReducer;
            //Si la caja seleccionada es tipo calendario
          if(selectedBoxOperator&&selectedBoxOperator.operatortype_id==19)
          {
            return this.showContent();
          }
          else
            return <div></div>
    }
 }

const mapStateToProps = ({ boxReducer,homeReducer, componentMountReducer }) => {
    return { boxReducer,homeReducer, componentMountReducer };
   };
   
   const mapDispatchToProps = {
    isDidMount,
    willUnMount,
    iconLoad,
    iconHideLoad,
    getListTypeCalendar,
    setBotmsgText,
    setChangeConfig,
    updateBoxOperator,
    toggleModalViewBoxCalendar,
    updateOperator,
    createNewStatement,
    updateStatement,
    removeStatement,
    cleanSelection,
    setVarList
    
   };
   
   export default connect(mapStateToProps, mapDispatchToProps)(CDModal);
