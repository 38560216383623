import React from 'react';
import { 
    Button, 
    Modal, 
    ModalHeader, 
    ModalBody, 
    ModalFooter,
    Form, 
    FormGroup, 
    Label, 
    Input, 
    Col,
    Row,
} from 'reactstrap';
import '../pages/admin/components/toolbar.css';
import '../pages/admin/components/modal-nuevo-bot.css'
import i18next from '../../i18n';

function ModalNewGroup(props){

    return (
        <div>
            <Modal isOpen={props.isOpen} toggle={props.toggleModal} fade>
                <ModalHeader toggle={props.toggleModal}>{i18next.t('create_new_group')}</ModalHeader>
                <Form id="form-createbot" onSubmit={props.saveGroup} role="form">
                <ModalBody>
                        <FormGroup>
                            <Row>
                                <Col sm="3">
                                    <Label for="groupName" className="control-label required-field">{i18next.t('name')+': '}</Label>
                                </Col>
                                <Col sm="9">
                                    <Input type="text" className="inputModal" id="groupName" name="groupName" maxLength="30" placeholder={i18next.t('name_group')} required></Input>
                                </Col>
                            </Row>
                        </FormGroup>
                    
                </ModalBody>
                <ModalFooter>
                    <Button outline color="secondary"size="lg" onClick={props.toggleModal}>{i18next.t('finish')}</Button>
                    <Button type="submit" className="btn-guarumo-modal" color="success" size="lg">{i18next.t('create')}</Button>
                </ModalFooter>
                </Form>
            </Modal>
        </div>
    );
}
  
export default ModalNewGroup