import React, { Component } from 'react';
import ValidateLayout from './validate-layout';
import ModalLoad from '../../components/modals/Loading/moda-loading';
import { connect } from 'react-redux';
import {logIn, logInOTP} from "../../redux/actions/loginActions";
import { getAuthorization } from '../../utils/commonFunction';

class Validate extends Component {
    state = {
        login:{
            otp:'',
            user_id: ''
        },
        redirect: false,
        showAlert:false,
        showAlertWarning: false,
        warningMsg: "",
    }

    constructor(props) {
        super(props);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleOtp = this.handleOtp.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.hideAlert = this.hideAlert.bind(this);
    }

    hideAlert=(e)=>{
        this.setState({showAlert:false});
    }
    
    handleOtp = (e) => {
        this.setState({login:{otp:          e.target.value,
                              user_id:      localStorage.getItem("user_id")}});
    }

    handleEmailChange= (e)=> {
        this.setState({login:{username: e.target.value,password:this.state.login.password,grant_type:this.state.login.grant_type}});
    }

    handlePasswordChange= (e)=> {
        this.setState({login:{username:this.state.login.username,password: e.target.value,grant_type:this.state.login.grant_type}});
    }
    
    onSubmit = async (e) => { 

        e.preventDefault();
        
        const { logIn, logInOTP } = this.props;    
        // Request Log In     
       
        await logInOTP(this.state.login);

        const { loginReducer: {isLogin, email, otp,sendOpt, userInfo }  } = this.props;
        
        let userInfoLocal = JSON.parse(localStorage.getItem('UserInfo'));
        console.log("otp ",otp)
        console.log("isLogin ",isLogin)
        //console.log("new_login ",new_login)
        console.log("userInfo.new_login ",userInfo.new_login);
        if(otp === false){
            
            this.setState({showAlertWarning: true, warningMsg: "Lo siento, pero el token ingresado es incorrecto."});
           
            setTimeout(() => {
                this.setState({showAlertWarning: false});
            }, 2000);

        }else if(otp === true && isLogin === true && userInfo.new_login === false){
            

            setTimeout(() => {
                this.hideAlert();
                this.props.history.push("/");
            }, 1000);

        }else if(otp === true &&  isLogin === true && userInfo.new_login === true){
            setTimeout(() => {
                this.hideAlert();
                this.props.history.push("/changepassword");
            }, 1000);
        }  
    }

    render() {
        return (
            <div>
                <ValidateLayout
                onSubmit={this.onSubmit}
                handleEmailChange={this.handleEmailChange}
                handlePasswordChange={this.handlePasswordChange}
                handleOtp={this.handleOtp}
                hideAlert={this.hideAlert}
                showAlert={this.state.showAlert}
                showAlertWarning={this.state.showAlertWarning}
                warningMsg={this.state.warningMsg}
                />
                <ModalLoad  />
            </div>
            
        )
    }
}

const mapStateToProps = ({loginReducer,}) => {
	return {loginReducer,};
};

const mapDispatchToProps = {
	logIn,
    logInOTP,
};

export default connect(mapStateToProps, mapDispatchToProps)(Validate);

