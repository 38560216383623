import React from 'react';
import Admin from '../../admin/containers/admin';
import ModalNuevoSchema from './modal-nuevo-schema'
import ModalEditarSchema from './modal-editar-schema'
import SliceMenuApp from '../../admin/components/slice-menu'
import BotsLayout from '../../admin/components/bots-layout'
import AdminMenuOptions from '../../menus/bot-menu-options';
import { UncontrolledTooltip} from 'reactstrap';
import '../../home/home-layout.css';
import i18next from '../../../../i18n';

/* IMAGE */
import newBot from '../../../../static/img/menu-icons/nuevo_bot.svg'

function SchemasLayout(props) {
  return (
    <div className="HomeLayout">
          <Admin>
            <SliceMenuApp>
              <AdminMenuOptions 
                platform={props.platform} 
                botId={props.botId} 
                ia_activated={props.ia_activated}
                idSchema = {props.idSchema}
                admin={props.isAdmin} 
                schemaOption={props.botId}
                />
            </SliceMenuApp>   
            <button id="tooltipFlujo" className="btn-float" onClick={props.toggleModalNewSchema}>
              <img src={newBot} style={{'height':'36px'}}/>
           </button>
            <UncontrolledTooltip placement="right" target="tooltipFlujo">{i18next.t('new_flow')}</UncontrolledTooltip>      
            <BotsLayout getExportScheme = {props.getExportScheme} getDuplicateScheme={props.getDuplicateScheme}dataView={props.listSchemas} deleteBot={props.deleteBot} type={'schemes'} editSchema={props.editSchema} deleteSchema={props.deleteSchema} initial={props.initial} initialStep={props.initialStep} anonymous={props.anonymous} anonimo={props.anonimo}></BotsLayout>
            <ModalNuevoSchema dataView={props.listSchemas} getLoadSchema={props.getLoadSchema } toggleModal={props.toggleModalNewSchema} isOpen={props.modalSchema} saveSchema={props.saveSchema}/> 
            <ModalEditarSchema getDuplicateScheme={props.getDuplicateScheme } toggleModal={props.toggleModalEditSchema} isOpen={props.modalEditarSchema} updateSchema={props.updateSchema} dataSchema={props.dataSchema}/>
          </Admin>      
    </div>
  )
}

export default SchemasLayout

