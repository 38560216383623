import React from 'react';
import { connect } from "react-redux";

import Admin from '../../admin/containers/admin';
import SliceMenuApp from '../../admin/components/slice-menu';
//import DashboardGrid from '../containers/analytics/bot_dashboard_grid';
import DashboardGrid from '../grid/dashboard_view_grid'
import SelectedCompanies from '../../companies/selected-company';

import '../../../../static/css/dashboard.css';

import { 
    Col,
    Row,
    Button, 
    Container,
    Card,
    FormGroup,
    Label,
    Input
  
  } from 'reactstrap';
  import ToolbarApp from '../../admin/components/toolbar';
  import BotMenuOptions from '../../menus/analytics-menu-options';
 
  import i18next from "../../../../i18n";

  import {   getDashboard, 
    iconLoad,
    getPreview,
    cleanListDashboard, 
    cleanAllPreviews ,
    setSelectedCompany,  
    getAllMetrics,
    getAllCharts,
    getAllDashboards,
    getTockenCubejs} from "../../../redux/actions/analyticsActions";

function BotStatisticsLayout(props) {
    const {  activePage = 1,listAllDashboards = [], selectedDashboard,selectedCompany,items} = props.analyticsReducer;
    const {  listAllCompanies=[] } = props.homeReducer;

    return (
        <div className="BotLayout">

            <Admin>
                <SliceMenuApp>
                    <BotMenuOptions admin={props.isAdmin} handleFind={props.handleFind}/>
                </SliceMenuApp>
                <Container fluid>
                <ToolbarApp>
                    <Row>
                        <Col sm="6" md="6" className="text pdtoolbar">
                        <FormGroup className="dp">
                            <h2 className="text-left ml-4 font-weight-bold">{i18next.t('dashboard')} <span className="btn-help"></span></h2>                      
                       </FormGroup>
                        </Col>
                        {props.isAdmin?(
                            <SelectedCompanies onchangeCompanies={onchangeCompanies} ></SelectedCompanies>
                      ):null}
                        
                        <Col sm="3" md="3" className="filter-stuff">
                        <FormGroup>
                        
                        <Label sm={3} for="dashboardName" className="text-left font-weight-bold" style={{color:'#5a5a5a'}}>
                            {i18next.t('dashboard')+': '}
                        </Label>
                        <Input type="select" name="select"  bsSize="sm" id="dashboardSelect" defaultValue={(selectedDashboard)?selectedDashboard.id:''} onChange={(e)=>onchangeDashboard(e)}>
                        
                        {listAllDashboards &&(listAllDashboards.length > 0 && (
                            listAllDashboards.map((dashboard,i)=>{
                                dashboard=JSON.parse(dashboard);
                            return(<option key={dashboard.id} 
                                           value={dashboard.id} 
                                           data={dashboard}
                                           >{dashboard.name}</option>
                        )})
                        ))}
                        </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                </ToolbarApp>
            </Container>
            <Container fluid className="box-fluid">
            {
                listAllDashboards &&(listAllDashboards.length > 0 )?(
                 <div id="dashboard-pre">
                     <DashboardGrid 
                        className={"layout"} 
                        rowHeight= {100}
                    /> 
                 </div>
                 ): 
                     <Col sm="12" md="10" className="stadistics-container offset-md-1">
                          <Col xs={12} lg={8} className="offset-lg-2 intro-funnels">
                                <Row>
                                    <Col xs={12}><img src="/static/img/blank_state.png" className="img-fluid"/></Col>
                                    <Col xs={12}>
                                        <h2>{i18next.t('message175')}</h2>
                                        <h3>{i18next.t('message176')}</h3>
                                        <p>{i18next.t('message177')}</p>
                                    </Col>
                                </Row>
                            </Col>
                        </Col>
                        }
                
             </Container>
            </Admin>
        </div>
    )

    //---------------------------------------------------------------------
    //  CUANDO SE SELECCIONA UN DASHBOARD ACTUALIZA EL SELECTEDDASHBOARD
    //  LIMPIA TODOS LOS PREVIEWS
    //---------------------------------------------------------------------
    async function onchangeDashboard(e)
    {
        const id_company=e.target.value;  

        await props.iconLoad();
        await props.getDashboard("",id_company);
        await props.cleanAllPreviews();
    
    
    }
    //----------------------------------------------------------------------------
    //  CUANDO SE SELECCIONA UNA COMPAÑÍA ACTUALIZA EL SELECTEDCOMPANY
    //------------------------------------------------------------------------------
    async function onchangeCompanies(e)
    {
        const id_company=e.value;  

        await props.iconLoad(); 
        await props.setSelectedCompany(id_company);
      
    }
}

const mapStateToProps = ({analyticsReducer,homeReducer}) => {
    return {analyticsReducer, homeReducer};
    };
    
    const mapDispatchToProps = {
        getDashboard,
        iconLoad,
        getPreview,
       cleanListDashboard,
        cleanAllPreviews,
        setSelectedCompany ,
        getAllMetrics,
        getAllCharts,
        getAllDashboards,
        getTockenCubejs
    };
    
    export default connect(mapStateToProps, mapDispatchToProps )(BotStatisticsLayout);

