import React, { Component } from 'react';
import { connect } from "react-redux";
import Pagination from "react-js-pagination";
import {Col,UncontrolledTooltip} from 'reactstrap';
import Admin from '../admin/containers/admin';
import ModalNuevoBot from '../admin/components/modal-nuevo-bot'
import SliceMenuApp from '../admin/components/slice-menu'
import BotsLayout from '../admin/components/bots-layout'
import AdminMenuOptions from '../menus/admin-menu-options';
import { getAllbots, changePageActive,deleteBot, handleSearching, toggleModalNewBot } from "../../redux/actions/homeActions";
import { getExportBot, getDuplicateBot, getExportScheme, getLoadSchema } from "../../redux/actions/botActions";
import './home-layout.css';
import Buscador from '../admin/components/buscador';
import i18next from '../../../i18n';

/* IMAGE */
import newBot from '../../../static/img/menu-icons/nuevo_bot.svg'

class HomeLayout extends Component {
 
  constructor(props) {
    super(props);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  handlePageChange(pageNumber) {
    const { getAllbots, changePageActive } = this.props;
    changePageActive( pageNumber );
    getAllbots( pageNumber );
  }

  displayPage = all => Math.round(all / 12)

  render() {
    const { totalBots , activePage = 1, listAllBots = [] } = this.props.homeReducer;
    const { handleSearching, toggleModalNewBot, deleteBot } = this.props;
    let display = this.displayPage(totalBots) > 6 ? 6 : this.displayPage(totalBots);

    return (
      <div className="HomeLayout">
          <Admin>
            <button id="tooltiNewBot" className="btn-float" onClick={toggleModalNewBot}>
                <img src={newBot}/>
            </button>
            <UncontrolledTooltip placement="right" target="tooltiNewBot">{i18next.t('new_bot')}</UncontrolledTooltip>
            <SliceMenuApp>
              <AdminMenuOptions admin={this.props.isAdmin} handleFind={handleSearching} />     
            </SliceMenuApp>    
              <Col className="float-search">
                <Buscador  />
              </Col>    
              <BotsLayout 
                dataView={listAllBots}
                deleteBot={deleteBot} 
                cloneBot={this.props.cloneBot} 
                anonymous = {this.anonymous}
                type={'bot'} 
                getExportBot={getExportBot}
                getDuplicateBot={getDuplicateBot}
                getExportScheme={getExportScheme}
                />
              
              <Col className="col-4 offset-4">
                <Pagination
                  activePage={activePage}
                  itemsCountPerPage={12}
                  totalItemsCount={totalBots} 
                  pageRangeDisplayed={display}
                  onChange={this.handlePageChange}/>
              </Col>
              <ModalNuevoBot  />
            </Admin>  
      </div>
    );
  }


}


const mapStateToProps = ({homeReducer}) => {
	return {homeReducer};
};

const mapDispatchToProps = {
  getAllbots,
  changePageActive,
  deleteBot,
  handleSearching,
  toggleModalNewBot,
  getExportBot,
  getDuplicateBot,
  getExportScheme,
  getLoadSchema,
};

export default connect(mapStateToProps, mapDispatchToProps )(HomeLayout);