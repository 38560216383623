import React, { useState, useEffect } from 'react';
import Admin from '../admin/containers/admin';
import SliceMenuApp from '../admin/components/slice-menu'
import {
    Col,
    Row,
    Button,
    Container,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Label,
    Input,
    UncontrolledTooltip
} from 'reactstrap';
import { connect } from 'react-redux';
import ToolbarApp from '../admin/components/toolbar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import AdminMenuOptions from '../menus/admin-menu-options';
import '../companies/companies-layout.css';
import { getRolUser } from '../../utils/commonFunction';

import DataTableFilter from '../analytics/infoViz/common/dataTableFilter'
import i18next from '../../../i18n';


      /* IMAGE */
import imageNew from '../../../static/img/menu-icons/nuevo_bot.svg'

function UsersLayout(props) {
    const { companyReducer: { users = [] } } = props;
    const { isAdmin = false } = getRolUser();
    
    const [isChecked, setIsChecked] = useState(true);
    
    useEffect(() => {
       
        setIsChecked(props.c_user.password_expiration !== undefined ? props.c_user.password_expiration : true);

    },[props.c_user.id])


    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
        props.updatePropC(!isChecked, 'password_expiration')
    };

    const handleCheckboxClick = () => {
        setIsChecked(!isChecked);
        props.updateProp(!isChecked, 'password_expiration')
    };

    var dataTable=null;
  
    if(users[0]){
       
         dataTable = {

            columns: [
              {
                label: i18next.t('names'),
                field: 'name',
                sort: 'asc',
               // width: 150
              },
              {
                label: i18next.t('last_name'),
                field: 'apellidos',
                sort: 'asc',
           //     width: 270
              },
              {
                label: i18next.t('user'),
                field: 'usuario',
                sort: 'asc',
           //     width: 270
              },
              {
                label: i18next.t('email'),
                field: 'email',
                sort: 'asc',
           //     width: 270
              },
              {
                label: i18next.t('rol'),
                field: 'rol',
                sort: 'asc',
           //     width: 270
              },
              {
                label: i18next.t('edition_option'),
                field: 'options',
                sort: 'asc',
                width: 670,
                options: {
                    filter: false,
                    sort: false
                   }
              }],
              rows: []};

              const rows = users.map((usr, index) => {

                const redirectTo = () => {
                    props.history.push(`/users/${cp.id}`)
                };

                //k = JSON.parse(k);  
                const fin=[];
                fin.push(usr.profile.first_name);
                fin.push(usr.profile.last_name);

                fin.push(usr.profile.username);
                fin.push(usr.profile.email);
                fin.push(usr.role.type);
                
             
               
                fin.push(
                    <Row className="d-flex align-items-center justify-content-center">
                            {
                                 isAdmin &&
                                 <div className="d-inline-block">
                                     <Button outline onClick={(e) => { const u = usr.profile; u.rol = usr.role.id; props.editUser(u) }} color="success">
                                         <FontAwesomeIcon icon={faPen} />
                                     </Button>
         
                                     <Button outline color="danger" onClick={(e) => props.deleteUser(usr.profile.id)}  >
                                         <FontAwesomeIcon icon={faTrash} />
                                     </Button>
                                 </div>
                            }
                         </Row>
                )
                dataTable.rows.push(fin); 
                return usr;
            });
        }
 
        const options = {
            filterType: 'multiselect',
            selectableRows:'none',
            resizableColumns: false,
            responsive: "scrollMaxHeight",
            rowsPerPage:5 ,
            print:false,
            download:false,
            viewColumns:false,
           
          };
           const redirectTo = () => {
                    props.history.push(`/users/${cp.id}`)
                };
              var title = ''

                
    return (
        <div className="UserLayout">
            <Admin>
          { isAdmin && 
              <button id="tooltiNewUser" className="btn-float"  onClick={(e)=>{props.createUser()}}>
                <img src={imageNew}/>
            </button> 
            }
            <UncontrolledTooltip placement="right" target="tooltiNewUser">{i18next.t('create_user')}</UncontrolledTooltip> 
                <SliceMenuApp>
                    <AdminMenuOptions />
                </SliceMenuApp>
                <Container fluid>
                    <ToolbarApp>
                        <Row>
                            <Col sm="9" md="9" lg="10" className="text pdtoolbar">
                                <h2 className="text-left ml-4 font-weight-bold">{i18next.t('admin_users')}</h2>
                            </Col>
                        </Row>
                    </ToolbarApp>
                </Container>
                <Container fluid className="box-fluid">
                <Col sm="12" md="10" className="stadistics-container offset-md-1">    
                    {
                       dataTable?(
                        <Row >
                            <Col xs={12} lg={12} >
                                <DataTableFilter
                                title={title}
                                data={ dataTable.rows}
                                columns={dataTable.columns}
                                options={options}
                                />
                           </Col>
                        </Row>): 
                          <Col xs={12} lg={8} className="offset-lg-2 intro-funnels">
                                <Row>
                                    <Col xs={12}><img src="/static/img/blank_state.png" className="img-fluid"/></Col>
                                    <Col xs={12}>
                                        <h2>{i18next.t('admin_user_message')}</h2>
                                        <h3>{i18next.t('admin_user_message2')}</h3>
                                        <p>{i18next.t('admin_user_message3')}</p>
                                    </Col>
                                </Row>
                            </Col>
                        }
                    </Col>
                </Container>
            </Admin>

            <Modal isOpen={props.modalOpenC} fade size="lg" toggle={() => props.closeModalC()}>
                <ModalHeader toggle={() => props.closeModalC()}>{i18next.t('create_user')}</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col sm="6">
                            <FormGroup>
                                <Label>{i18next.t('name')}</Label>
                                <Input type="text" className="inputModal" minLength="3" placeholder={i18next.t('names')} defaultValue={props.new_user.first_name || ''} onChange={(e) => props.updatePropC(e.target.value, 'first_name')} required></Input>
                            </FormGroup>
                        </Col>

                        <Col sm="6">
                            <FormGroup>
                                <Label>{i18next.t('last_name')}</Label>
                                <Input type="text" className="inputModal" minLength="3" placeholder={i18next.t('last_name')} defaultValue={props.new_user.last_name || ''} onChange={(e) => props.updatePropC(e.target.value, 'last_name')} required></Input>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm="6">
                            <FormGroup>
                                <Label>{i18next.t('email')}</Label>
                                <Input type="text" className="inputModal" minLength="3" placeholder={i18next.t('email')}  defaultValue={props.new_user.email || ''} onChange={(e) => props.updatePropC(e.target.value, 'email')} required></Input>
                            </FormGroup>
                        </Col>

                        <Col sm="6">
                            <FormGroup>
                                <Label>{i18next.t('rol')}</Label>
                                <Input type="select" className="inputModal" minLength="3" placeholder={i18next.t('validate_message7')} onChange={(e) => props.updatePropC(e.target.value, 'rol')} required>
                                    <option value="">{i18next.t('validate_message7')}</option>
                                    <option value="1">{i18next.t('user')}</option>
                                    <option value="2">{i18next.t('admin')}</option>
                                    <option value="4">{i18next.t('reports')}</option>
                                    <option value="6">{i18next.t('admin')} {i18next.t('campaigns')}</option>
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm="6">
                            <FormGroup>
                                <Label>{i18next.t('user')}</Label>
                                <Input type="text" className="inputModal" minLength="6" placeholder={i18next.t('username')}  defaultValue={props.new_user.username || ''} onChange={(e) => props.updatePropC(e.target.value, 'username')} required></Input>
                            </FormGroup>
                        </Col>

                        <Col sm="6">
                            <FormGroup>
                                <Label>{i18next.t('password')}</Label>
                                <Input type="password" className="inputModal" minLength="6" placeholder={i18next.t('password')} defaultValue={props.new_user.password || ''} onChange={(e) => props.updatePropC(e.target.value, 'password')} required></Input>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm="6">
                        <FormGroup check>
                                <Input
                                    type="checkbox"
                                    checked={isChecked}
                                    onChange={handleCheckboxChange}
                                    className="PasswordExpiration mr-1"
                                    name="PasswordExpiration"
                                />
                                {' '}{' '}
                                <Label check className="ml-1">
                                {i18next.t('change_password_editar_60')}
                                </Label>
                            </FormGroup>               
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Col sm="6" align="right">
                        <Button className="btn-guarumo-modal" type="button" onClick={props.saveNewUser}>{i18next.t('finish')}</Button>
                    </Col>
                </ModalFooter>
            </Modal>

            <Modal isOpen={props.modalOpen} fade size="lg" toggle={() => props.closeModal()}>
                <ModalHeader toggle={() => props.closeModal()}>{i18next.t('edit_user')}</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col sm="6">
                            <FormGroup>
                                <Label>{i18next.t('name')}</Label>
                                <Input type="text" className="inputModal" minLength="3" placeholder={i18next.t('name')}  defaultValue={props.c_user.first_name || ''} onChange={(e) => props.updateProp(e.target.value, 'first_name')} required></Input>
                            </FormGroup>
                        </Col>

                        <Col sm="6">
                            <FormGroup>
                                <Label>{i18next.t('last_name')}</Label>
                                <Input type="text" className="inputModal" minLength="3" placeholder={i18next.t('last_name')}  defaultValue={props.c_user.last_name || ''} onChange={(e) => props.updateProp(e.target.value, 'last_name')} required></Input>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm="6">
                            <FormGroup>
                                <Label>{i18next.t('email')}</Label>
                                <Input type="text" className="inputModal" minLength="3" placeholder={i18next.t('email')}  defaultValue={props.c_user.email || ''} onChange={(e) => props.updateProp(e.target.value, 'email')} required></Input>
                            </FormGroup>
                        </Col>

                        <Col sm="6">
                            <FormGroup>
                                <Label>{i18next.t('rol')}</Label>
                                <Input type="select" className="inputModal" minLength="3" placeholder={i18next.t('validate_message7')} value={props.c_user.rol || ''} onChange={(e) => props.updatePropC(e.target.value, 'rol')} required>
                                    <option value="">{i18next.t('validate_message7')}</option>
                                    <option value="1">{i18next.t('user')}</option>
                                    <option value="2">{i18next.t('admin')}</option>
                                    <option value="4">{i18next.t('reports')}</option>
                                    <option value="6">{i18next.t('admin')} {i18next.t('campaigns')}</option>
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm="6">
                            <FormGroup>
                                <Label>{i18next.t('user')}</Label>
                                <Input disabled readOnly type="text" className="inputModal" minLength="6" placeholder={i18next.t('username')}  defaultValue={props.c_user.username || ''} ></Input>
                            </FormGroup>
                        </Col>

                        <Col sm="6">
                            <FormGroup>
                                <Label>{i18next.t('new_password')}</Label>
                                <Input type="password" className="inputModal" minLength="6" placeholder={i18next.t('password')} defaultValue={props.c_user.password_n || ''} onChange={(e) => props.updateProp(e.target.value, 'password_n')} required></Input>
                            </FormGroup>
                        </Col>
                    </Row>


                    <Row>
                        <Col sm="6">
                            <FormGroup check>
                                <Input
                                    type="checkbox"
                                    checked={isChecked}
                                    onClick={handleCheckboxClick}
                                    className="PasswordExpiration mr-1"
                                    name="PasswordExpiration"
                                />
                                {' '}{' '}
                                <Label check className="ml-1">
                                    {i18next.t('change_password_editar_60')}
                                </Label>
                            </FormGroup>               
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Col sm="6" align="right">
                        <Button className="btn-guarumo-modal" type="button" onClick={props.saveUser}>{i18next.t('finish')}</Button>
                    </Col>
                </ModalFooter>
            </Modal>
        </div>
    )
}



const mapStateToProps = ({ companyReducer, }) =>
    ({ companyReducer, });


export default connect(mapStateToProps)(UsersLayout);
