import { AbstractNodeFactory } from "storm-react-diagrams";
import React from "react";
import EMAILNodeModel from "./EMAILNodeModel";
import EMAILNodeWidget from "./EMAILNodeWidget";

class EMAILNodeFactory extends AbstractNodeFactory {
	constructor() {
		super("email");
	}

	generateReactWidget(diagramEngine, node) {
		return <EMAILNodeWidget node={node} />
	}

	getNewInstance() {
		return new EMAILNodeModel();
	}
}

export default EMAILNodeFactory