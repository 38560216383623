// Dependencies
import React from "react";
import { Label, UncontrolledTooltip, Row, Col } from "reactstrap";
import Clipboard from "react-clipboard.js";
import CreatableSelect from "react-select/creatable";
import SyntaxHighlighter from "react-syntax-highlighter";
import { github } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { isvalidatedOnlyNumberText } from "../../utils/isValidate";
import { isEmpty } from "../../utils/commonFunction";

import i18next from '../../../i18n';

export default class TabWEBConfi extends React.Component {

  constructor(props) {
    super(props);
    this.handleChangeSessionVar = this.handleChangeSessionVar.bind(this);
  }

  defaultScript() {
    const { expected_user_varsSelected = [], expected_user_vars = [] } =
      this.props.props;
    let user_var = "";
    if (expected_user_varsSelected.length > 0) {
      expected_user_varsSelected.map((item) => {
        if (!isEmpty(item)) {
          user_var += "'" + item + "' : '', ";
        }
      });
    } else {
      expected_user_vars.map((item) => {
        if (!isEmpty(item)) {
          user_var += "'" + item + "' : '', ";
        }
      });
    }

    const defaultCode =
      ` 
      <div id="live-chat" ></div> 
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no">
      <link rel="stylesheet" type="text/css" href="${HOST_API}/static/css/chat_box.css"> 
      <script src="https://code.jquery.com/jquery-3.2.1.min.js" integrity="sha256-hwg4gsxgFZhOsEEamdOYGBf13FyQuiTwlAQgxVSNgt4=" crossorigin="anonymous"></script>
      <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.min.js"></script>
      <script src="${HOST_API}/static/js/linkify.js"></script> 
      <script src="${HOST_API}/static/js/linkify-html.js"></script> 
      <script src="https://tweetnacl.js.org/nacl.min.js"></script>
      <script src="https://tweetnacl.js.org/nacl-util.min.js"></script>
      <script src="https://bundle.run/buffer@6.0.3"></script>
      <script src="https://cdn.jsdelivr.net/npm/tweetnacl-sealedbox-js@1.2.0/sealedbox.web.js"></script>
      <script type="text/javascript" src="${HOST_API}/static/js/BotScript/chatbot.js"></script> 
      <script type="text/javascript" src="${HOST_API}/static/js/BotScript/geolocalization.js"></script> 
      <script type="text/javascript" src="${HOST_API}/static/js/BotScript/calendar.js"></script> 
      <script type="text/javascript"> 
        var  Params = { 
          'BotId':` +
      this.props.props.botId +
      `, 
          'BotVersionId':` +
      this.props.props.botversion_id +
      `, 
          'BotHost' : '${HOST_API}', 
          'Initial_Step': '/reset',
          'user_vars':  {
            ${user_var}
          }
   
        } 
        var  ChatBotItem = new ChatBot(Params); 
      </script> `;
    return defaultCode;
  }

  handleChangeSessionVar= (event) => {
   this.props.handleChangeSessionVar(event);
 };

  createOption = (label) => ({
    label,
    value: label.toLowerCase().replace(/\W/g, ""),
  });

  render() {
    const expected_user_varsSelected =
      this.props.props.expected_user_varsSelected.map((item) => {
        if (!isEmpty(item)) {
          return this.createOption(item);
        }
        return "";
      });

    const expected_user_vars = this.props.props.expected_user_vars.map(
      (item) => {
        if (!isEmpty(item)) {
          return this.createOption(item);
        }
        return "";
      }
    );

    const defaultScript = this.defaultScript();

    return (
      <Row>
        <Col lg="12">
          <Row>
            <Label id="session_vars_label" for="session_vars">
            {i18next.t('user_variable')}
            </Label>
            <UncontrolledTooltip placement="right" target="session_vars_label">
            {i18next.t('user_variable_msg')}
            </UncontrolledTooltip>
          </Row>
          <Row>
            <Col lg="12">
              <CreatableSelect
                id="session_vars"
                isMulti
                onChange={this.handleChangeSessionVar}
                options={expected_user_vars}
                value={
                  expected_user_varsSelected.length > 0
                    ? expected_user_varsSelected
                    : expected_user_vars
                }
                placeholder={i18next.t('name_new_variable')}
                loadingMessage={i18next.t('enter_create')}
                onInputChange={(inputText) => {
                  if (!isvalidatedOnlyNumberText(inputText)) {
                    ToastsStore.warning(i18next.t('validate_number_text'));
                    return "";
                  }
                }}
              />
            </Col>
          </Row>
          <br></br>
          <Row>
            <Label>{i18next.t('code')}</Label>
          </Row>
          <Row>
            <Clipboard
              data-clipboard-target="#script"
              onSuccess={this.props.props.onSuccess}
            >
              <i className="fa fa-copy" id="copyCode"></i>
            </Clipboard>
            <UncontrolledTooltip placement="bottom" target="copyCode">
            {i18next.t('copy_code')}
            </UncontrolledTooltip>
          </Row>
          <Row>
            <SyntaxHighlighter language="html" id="script" style={github}>
              {defaultScript}
            </SyntaxHighlighter>
          </Row>
        </Col>
      </Row>
    );
  }
}
