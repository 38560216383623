import React, { Component } from "react";
import ReactDOM from "react-dom";
import BotWebFormRenderLayout from './bot-webform-render-layout';
import ModalLoad from '../../../components/modals/modal-load';
import axios from 'axios';
import qs from 'qs';

class BotWebFormRender extends Component {

    state = {
        hideLoad:0,
        botSession: this.props.match.params.session,
        botversion_id: "",
        ia_activated: false,
        currentWebForm: {
            id: this.props.match.params.id,
            title:"",
            url:"",
            reg_date:""
        }
       
    };
    constructor(props){
        super(props);
    }

    componentDidMount() {
        this.getWebFormInfo();
    }


    /*  **** **** **** **** **** **** 
                CRUD OF WEBFORMS */

    /* handles if the form is to create a new webform or to edit an existing one (from the form builder) 
        @template: array with all the formbuilder data
    */
    saveFormBuilder = (template) => {
        let form = new FormData(document.getElementById('form-webform-builder'));

        if(form.get('id')){  // is editing
            this.updateWebform(form, template);
        }else { // is creating a new
            this.createWebform(form, template);
        }

    }

    /* updating an specific webform from form builder handler 
        @form = data form
        @template: array with all the formbuilder data
    */
    updateWebform (form, template) {
        let cont = this.state.hideLoad; this.setState({ hideLoad: cont+1});
        let data = {
            title : form.get('title'),
            template: JSON.stringify(template),
            botversion_id : this.state.botversion_id,
            id: this.state.currentWebForm.id
        };

        this.putHandler(HOST_API +'/api/ia/webforms/',data).
            then((res) => {
                if(res.status==200){
                    var aux = this.state.listWebForms;
                    for(let i=0; i< aux.length; i++){
                        if(aux[i].id == res.data.webform.id){
                            aux[i].title = res.data.webform.title;
                            aux[i].reg_date = res.data.webform.reg_date;
                            break;
                        }
                    }   
                    // saves and returns to the webforms list
                    this.setState({ 
                        listWebForms: aux,
                        showFormBuilder: false,
                        showWebFormList: true
                    });
                    
                }else{
                  console.log(" No se pudo actualizar el webform");
                }
                let cont = this.state.hideLoad; this.setState({ hideLoad: cont-1});
            }).catch((error) => {
                console.log(error);
                let cont = this.state.hideLoad; this.setState({ hideLoad: cont-1});
            });
    }

    /* Get an specific WebForm info Handler
        id = webform_id
    */
    getWebFormInfo () {
        let cont = this.state.hideLoad; this.setState({ hideLoad: cont+1});
        this.getHandler("/api/webforms/?id="+this.state.currentWebForm.id)
            .then((res) =>{
                if(res.status==200){
                    let aux = {};
                    if(res.data.webform.template){
                        let aux_array = JSON.parse(res.data.webform.template);
                        let kObj = Object.keys(aux_array);
                        var template = [];
                        kObj.forEach((k)=>{
                            template.push(aux_array[k]);
                        });

                        aux = {
                            id : this.state.currentWebForm.id,
                            template: template,
                            title: res.data.webform.title,
                            reg_date: res.data.webform.reg_date
                        };
                        this.setState({ 
                            currentWebForm: aux
                        });

                    } else {
                        console.log("este form no fue creado usando el form builder");           
                    }

                }else{
                    console.log(" No se pudo recuperar la informacion del webform");
                }
                let cont = this.state.hideLoad; this.setState({ hideLoad: cont-1});
            }).catch((error)=> {
                console.log(error);
                let cont = this.state.hideLoad; this.setState({ hideLoad: cont-1});
            });

    }


    /*   **** **** **** **** **** **** 
                    AJAX HANDLERS */

    // GET requests handler
    getHandler(url){
        var auth=JSON.parse(localStorage.getItem('Authorization'));
        var headers={
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': 'Bearer '+auth.access_token
        }

        return axios({
          method:'get',
          url: HOST_API + url,
          headers:headers
        })
    }

     // POST requests handler
     postHandler(url, data){
        var auth=JSON.parse(localStorage.getItem('Authorization'));
        var headers={
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': 'Bearer '+auth.access_token
        }

        return axios({
          method:'post',
          url:HOST_API +url,
          data: qs.stringify(data),
          headers:headers
        })
     }

     // POST MULTIFORM requests handler
     postMultiHandler(url, data){
        var auth=JSON.parse(localStorage.getItem('Authorization'));
        var headers={
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer '+auth.access_token
        }

        return axios({
          method:'post',
          url:HOST_API +url,
          data: data,
          headers:headers
        })
     }

    
    render() {
        return (
        <div>
          <BotWebFormRenderLayout 
          saveFormBuilder={this.saveFormBuilder}
          currentWebForm={this.state.currentWebForm}
          ia_activated={this.state.ia_activated}
          plusSlides={this.plusSlides}
          currentSlide={this.currentSlide}
          botSession={this.state.botSession}
          />

          <ModalLoad hideLoad={this.state.hideLoad} />
          </div>
        )
    }
}

export default BotWebFormRender;