import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { QueryRenderer } from "@cubejs-client/react";

import cubejs from "@cubejs-client/core";
import DataTable from "./dataTableCubejs";

import Date from "../../../../components/datepicker-dashboard";

import { downloadFile } from "../../../../redux/actions/analyticsActions";

import { Col, Row } from "reactstrap";
function cubejsInteractionReport(props) {
  const {
    operationFinal,
    tokenCubejs,
    selectedBot,
    dataframe,
  } = props.analyticsReducer;
 
  let url = props.match.path;
  const cubejsApi = cubejs(tokenCubejs, { apiUrl: CUBE_API });

  if (operationFinal.timeDimensions) {

    if (!operationFinal.filters[0].values[0]) {
    } else {
      console.log(operationFinal);
      console.log(
        "Cubejs Bot: ",
        selectedBot,
        "operation:",
        operationFinal.filters[0].values[0]
      );

      return (
        <React.Fragment>
          <Row>
            <Col
              sm="6"
              md="6"
              className="offset-md-2 offset-lg-6 filter-stuff col-sm-12 col-md-10 col-lg-6"
            >
              <div key="a" id="content-datepicker">
                {url != '/customersReport'?<Date onFilter={props.onFilter} />:''}
              </div>
            </Col>
          </Row>
        </React.Fragment>
      );
    }
  } else {
    return (
      <React.Fragment>
        {/* SIN CUBEJS */}
        <Row>
          <Col
            sm="6"
            md="6"
            className="offset-md-2 offset-lg-6 filter-stuff col-sm-12 col-md-10 col-lg-6"
          >
            <div key="a" id="content-datepicker">
            {url != '/customersReport'?<Date onFilter={props.onFilter} />:''}
            </div>
          </Col>
        </Row>
        <Row>
          <DataTable resultSet={dataframe} props={props} />
        </Row>
      </React.Fragment>
    );
  }
}
const mapStateToProps = ({ analyticsReducer, botReducer }) => {
  return { analyticsReducer, botReducer };
};

const mapDispatchToProps = {
  downloadFile,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(cubejsInteractionReport));
