import React, { Component } from 'react';
import { Input, Alert, FormGroup, Label,UncontrolledTooltip } from 'reactstrap'
import i18next from '../../../i18n';

class Timeout extends Component {
      MIN_VAL = 1
      MIN_VAL_MSG = 0
      MAX_VAL = 86400
      MAX_LENGTH_VAL = 5
      MAX_LEN_MSG = 2000

      state = {
        value: 0,
        msg: "", 
        validVal:false,
        validMsg: false,
        valid: true
      }

      constructor(props){
          super(props)
          this.state.value = props.defaultValue || 0
          this.state.msg = props.defaultMsg || ""
      }

      
      validateMsg = (val) => {
        if (this.state.value >=  this.MIN_VAL_MSG && (val.length <= 0 || val.length > this.MAX_LEN_MSG))
            this.setState({validMsg: true})
        else
            this.setState({validMsg: false})
      }

      validateValue = (e) => {
        this.setState({validVal: false})
        if(e.target.value.length > this.MAX_LENGTH_VAL) {
            e.target.value = e.target.value.substr(0, this.MAX_LENGTH_VAL);   
        }
        if(e.target.value == 0){
            this.setState({validVal: false,validMsg: false })
        }else{
            if( e.target.value > this.MAX_VAL || e.target.value < this.MIN_VAL){
                this.setState({validVal: true})
            }
            this.setValue(e.target.value)
            this.validateMsg(this.state.msg)
        }
      }

      bindValue = () => {
        if(typeof this.props.bindValue === "function")
            this.props.bindValue(this.state.value);
      }
      bindMsg = () => {
        if(typeof this.props.bindMsg === "function")
            this.props.bindMsg(this.state.msg);
      }

      bindValid = () => {
        if(typeof this.props.bindValid === "function")
            this.props.bindValid(this.isValid());
      }
      setValue = (val) => {
        this.setState({value: val}, () => {
            this.bindValue();
            this.bindValid();
        });
      }
      setMsg = (val) => {      
        this.setState({msg: val}, () => {
            this.bindMsg();
            this.bindValid();
        });        
      }
      isValid = () => {
            return this.state.value == this.MIN_VAL_MSG || (this.state.value >= this.MIN_VAL && this.state.msg.length > this.MIN_VAL_MSG && this.state.msg.length <= this.MAX_LEN_MSG && this.state.value <= this.MAX_VAL)
      }
      preventNonNumericalInput(e){
        e = e || window.event;
        var charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
        var charStr = String.fromCharCode(charCode);
      
        if (!charStr.match(/^[0-9]+$/))
          e.preventDefault();
      }

      render() {
          return (
              <div>
                <FormGroup>
                    <Label>{i18next.t('inactivity')} <i className="fa fa-info-circle" id="tooltiptime"></i></Label>
                    <UncontrolledTooltip placement="right" target="tooltiptime">{i18next.t('timeout_message')}</UncontrolledTooltip>
                    <Input type="number" onKeyPress={(e)=>{this.preventNonNumericalInput(e)}} min={this.MIN_VAL} max={this.MAX_VAL} maxLength='2' placeholder={i18next.t('seconds')} onKeyUp={ (e) =>{ this.validateValue(e)}} defaultValue={this.state.value} onChange={ (e) => this.setValue(e.target.value) }/>
                </FormGroup>
                <Alert color="danger" isOpen={this.state.validVal}>
                    {i18next.t('invalid_phone')}
                </Alert>
                <FormGroup>
                    <Label>{i18next.t('message')} {i18next.t('inactivity')} <i className="fa fa-info-circle" id="tooltipmsjtime"></i></Label>
                    <UncontrolledTooltip placement="right" target="tooltipmsjtime">{i18next.t('timeout_message2')}</UncontrolledTooltip>
                    <Input type="text" placeholder={i18next.t('timeout')} defaultValue={this.state.msg} onKeyUp={ (e) =>{ this.validateMsg(e)}} onChange={ (e) => this.setMsg(e.target.value) } />
                </FormGroup>   
                <Alert color="danger" isOpen={this.state.validMsg}>
                {i18next.t('write_message')}
                </Alert>
              </div>
          )
      }

  }
  
  export default Timeout
