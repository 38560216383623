import React, { Component } from 'react';
import { connect } from "react-redux";
import HomeLayout from './home-layout';
import BotDashboard from "../analytics/dashboard/bot-dashboard";
import ModalLoading from '../../components/modals/Loading/moda-loading';
import { getRolUser, isEmpty } from '../../utils/commonFunction'
import { APPLICANT } from "../../redux/listComponents";
import { isDidMount, willUnMount } from "../../redux/actions/componentMountActions";
import { getTotalBots, getAllbots, getAllPlafform } from "../../redux/actions/homeActions";

import { Redirect } from 'react-router-dom'
import { useHistory } from "react-router-dom"

class Home extends Component {

    state = {
      botClone: [], //
      isAdmin: false // debe quitar, para que Los Menus acceden al redux
    }

    componentWillMount(){
      const { isAdmin = false, isSuperUser = false } = getRolUser();
      this.setState({
        isAdmin: isAdmin,
        isSuperUser: isSuperUser
      });     
    }

    componentDidMount(){
      const { homeReducer : { activePage, allPlatforms, } } = this.props;
      this.props.isDidMount(APPLICANT.HOME());      
      const { isLogin,rol } = getRolUser();
    if(isLogin){
      if(rol!='Reportes' && rol!='AdminCampaign'){

      this.props.getTotalBots();
      this.props.getAllbots(activePage); 
      if(isEmpty(allPlatforms)){
        this.props.getAllPlafform();       
      }
    }}
    }

    componentWillUnmount() {
      this.props.willUnMount(APPLICANT.HOME());
    }
 



    /*
    * Función para clonar un bot trae Id de los statements clonados
    * @bot_version : int
    * @is_new : 0 valor constante 
    */ 
    // cloneBot = bot_version => {
    //   let cont = this.state.hideLoad; 
    //   this.setState({ hideLoad: cont+1});
    //   let data = { bot_version : bot_version, is_new:0}
    //   getHandlerPost(HOST_API +'/api/scheme/clone_scheme/', data)
    //     .then((res)=>{
    //         if(res.status==200){
    //           let result = res.data.data;
    //           //Creo objeto con la data recibida para actualizar los schemes
    //           let jsonClone = {};
    //           for (let i = 0; i < result.length; i++) {
    //             let temp = { operators: result[i].operators };
    //             jsonClone[result[i].new_id]=temp;
    //             this.Schema(result[i].new_id);
    //           }
    //           this.setState({botClone:jsonClone},()=>{console.log('state cloneBot',this.state.botClone)})
    //         }else
    //           console.log(" No se pudo crear el bot");
    //         this.setState({ hideLoad: cont-1});
    //     }).catch((error) => {
    //       console.log('Catch POST cloneBot:',error);
    //       this.setState({ hideLoad: cont-1});
    //       if (error.response.status == 403)
    //         cerrarSesion();
    //     }); 
    // }
    
    // Schema = idSchema => {
    //   getHandlerGET(HOST_API +'/api/scheme/?id='+idSchema)
    //   .then((res1) => {
    //       let cont = this.state.hideLoad; 
    //       if(res1.status == 200){
    //           if(res1.data){
    //             let result = res1.data;

    //             let botSchema = this.state.botClone;
    //             console.log('botSchema del state del res del clone',botSchema)
                
    //             if(result.scheme.content != ''){

    //               //toma posicion del resultado del clone para compararlo 
    //               //con el json del content del scheme 
    //               console.log('schemaClone',botSchema[idSchema])
    //               console.log('content del scheme',JSON.parse(result.scheme.content))

    //               //nodos del schema
    //               let nodes = JSON.parse(result.scheme.content).nodes;
    //               for (let i = 0; i < nodes.length; i++) {
    //                 //editamos extras de nodo dependiendo del type
    //                 nodes[i].extras.dbId = botSchema[idSchema].operators[i].new_id
    //                 let type = nodes[i].extras.type
    //                 switch (type) {
    //                   case 1:
                        
    //                   break;
    //                   case 2:
                        
    //                   break;
    //                   case 3:
                      
    //                   break;
    //                   case 4:
                      
    //                   break;
    //                   case 5:
                      
    //                   break;
    //                   case 6:
                      
    //                   break;
    //                   case 7:
                      
    //                   break;
    //                   case 8:
    //                     //texto plano, no tiene enunciado
    //                     nodes[i].extras.text_blocks
    //                     console.log('node[i].extras.text_blocks',nodes[i].extras.text_blocks)
    //                   break;
                    
    //                   default:
    //                     break;
    //                 }
    //                 //nodes[i].extras
    //                 console.log('nodos i',nodes[i])
    //               }
    //             }
    //           }
    //       }
    //   }).catch((error) => {
    //       let cont = this.state.hideLoad; this.setState({ hideLoad: cont-1});
    //       console.log("error getScheme:", error)
          
    //       if (error.response && error.response.status && error.response.status == 403){
    //         this.setState({ hideLoad: cont-1});
    //         cerrarSesion();
    //       }
    //   });
    // } 


  showContent=()=>{
    const { isLogin,rol } = getRolUser();

    if(isLogin){
      if(rol!='Reportes' && rol!='AdminCampaign'){
      return (
        <div>
        <HomeLayout
          isAdmin={this.state.isAdmin}
          />
        </div>
      );
    }else if(rol=='Reportes'){
      return (
        <div>
        <BotDashboard
          isAdmin={this.state.isAdmin}
          />
        </div>
      );
    }else {
     
        window.location.href = '/campaigns/list-campaigns';
      // <Redirect to='/campaigns/list-campaigns' />
    }
  }
    //window.location.href = '/login';
    this.props.history.push("/login");
    return <ModalLoading />
  }

  render() {
    return this.showContent()
  }
}

const mapStateToProps = ({ homeReducer, componentMountReducer }) => {
	return { homeReducer, componentMountReducer };
};

const mapDispatchToProps = {
  getTotalBots,
  getAllbots,
  getAllPlafform,
  isDidMount,
  willUnMount,
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);

