import React from 'react';
import { connect } from "react-redux";

import { APPLICANT } from "../../../../redux/listComponents";
import { isDidMount, willUnMount } from "../../../../redux/actions/componentMountActions";
import { isAuthorizationValid,getRolUser,isEmpty } from "../../../../utils/commonFunction";

import TreeView from 'react-treeview';

import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Label,
    Input,
    Col,
    Row,
    UncontrolledTooltip,
    FormText
  } from "reactstrap";

// This example data format is totally arbitrary. No data massaging is
// required and you use regular js in `render` to iterate through and
// construct your nodes.
const dataSource = [
  ['Apple', 'Orange'],
  ['Facebook', 'Google'],
  ['Celery', 'Cheeseburger'],
];

// A controlled TreeView, akin to React's controlled inputs
// (http://facebook.github.io/react/docs/forms.html#controlled-components), has
// many benefits. Among others, you can expand/collapse everything (i.e. easily
// trigger those somewhere else).


class Lists extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      collapsedBookkeeping: dataSource.map(() => false)
    };
    this.handleClick = this.handleClick.bind(this);
    this.collapseAll = this.collapseAll.bind(this);
  }

  componentDidMount(){
    const { analyticsReducer : {  selectedMetric, listAllMetrics } } = this.props;
  }

  handleClick(i) {
    let [...collapsedBookkeeping] = this.state.collapsedBookkeeping;
    collapsedBookkeeping[i] = !collapsedBookkeeping[i];
    this.setState({collapsedBookkeeping: collapsedBookkeeping});
  }

  collapseAll() {
    this.setState({
      collapsedBookkeeping: this.state.collapsedBookkeeping.map(() => true),
    });
  }

  findSonInfo(i)
  {
    const met = this.props.analyticsReducer.listAllMetrics.find((metric)=>{   
    // metric=JSON.parse(metric);
        if(metric.id===i ) {
         return(
           metric
           )
        }   
        })
  
        if(met)
            return JSON.parse(met)
            else
            return ""
  }

  makeTree(metric)
  {
    const collapsedBookkeeping = this.state.collapsedBookkeeping;
   return(metric.childs.map((node,i) => {
        // Let's make it so that the tree also toggles when we click the
        // label. Controlled components make this effortless.
        let label=""
        const son=this.findSonInfo(node);
        if(son!="")
        {
            if(son.childs.length==0)
            {
                label =
                <FormText>
                    <li><span className="node" onClick={this.handleClick.bind(null, i)}>
                    {son.name}
                    </span></li>
                </FormText>
            }
            else
            {
                label=<TreeView
                key={son.id}
                nodeLabel=
                {
                    <small>
                        <span className="node" onClick={this.handleClick.bind(null, son.id)}>
                        {son.name}
                        </span>
                    </small>
                  }
                collapsed={collapsedBookkeeping[son.id]}
                onClick={this.handleClick.bind(null, son.id)}>
                    {this.makeTree(son)}
                    </TreeView>
            }
        }
        return(label)
    }
    )
   )
  }

  showContent=()=>{
    const {  isLogin } = getRolUser();
    const collapsedBookkeeping = this.state.collapsedBookkeeping;
    if(isLogin){
      return (
        <React.Fragment>

        <TreeView
              key={this.props.analyticsReducer.selectedMetric.id}
              nodeLabel={
                // <FormText>
                <small>
                    <span className="node" onClick={this.handleClick.bind(null, this.props.analyticsReducer.selectedMetric.id)}>
                    {this.props.analyticsReducer.selectedMetric.name}
                    </span>
                </small>
                // </FormText>
              }
              collapsed={collapsedBookkeeping[this.props.analyticsReducer.selectedMetric.id]}
              onClick={this.handleClick.bind(null, this.props.analyticsReducer.selectedMetric.id)}>
                  {this.makeTree(this.props.analyticsReducer.selectedMetric)}
              {/* {node.map(entry => <div className="info" key={entry}>{entry}</div>)} */}
              {/* {this.props.analyticsReducer.selectedMetric.childs.map((node,i) => {
                    // Let's make it so that the tree also toggles when we click the
                    // label. Controlled components make this effortless.
                    let label=""
                    const son=this.findSonInfo(node);
                    if(son!="")
                    {
                        if(son.childs.lenght==0)
                        {
                            label =
                            <FormText>
                                <span className="node" onClick={this.handleClick.bind(null, i)}>
                                {son.name}
                                </span>
                            </FormText>
                        }
                        else
                        {


                        }
                    }
                    return(label)
                }
                )
                } */}
            </TreeView>
        </React.Fragment>
      )
    }
}
  render() {
    return this.showContent();
}
}

const mapStateToProps = ({ analyticsReducer, componentMountReducer }) => {
	return { analyticsReducer, componentMountReducer };
};

const mapDispatchToProps = {
  isDidMount,
  willUnMount,
  
};

export default connect(mapStateToProps, mapDispatchToProps)(Lists);

