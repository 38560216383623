
// Build data for a classic donut chart
const data = {}

// Labels are displayed in component, quantities are calculated to define size of each slice

  data.dataSet = [
        {category: "Web", measure: 1},
	      {category: "Facebook", measure: 2},
	      {category: "Whatsapp", measure: 1}
 ]

 data.datasetBarChart = [
  { group: "All", category: "Bot", measure: 63850.4963 }, 
  { group: "All", category: "Usuario", measure: 78258.0845 }, 
  { group: "All", category: "Paso Agente", measure: 60610.2355 }, 
  { group: "All", category: "No entendidos", measure: 30493.1686 }, 
  { group: "Web", category: "Bot", measure: 19441.5648 }, 
  { group: "Web", category: "Usuario", measure: 25922.0864 }, 
  { group: "Web", category: "Paso Agente", measure: 9720.7824 }, 
  { group: "Web", category: "No entendidos", measure: 6480.5216 }, 
  { group: "Facebook", category: "Bot", measure: 22913.2728 }, 
  { group: "Facebook", category: "Usuario", measure: 7637.7576 }, 
  { group: "Facebook", category: "Paso Agente", measure: 23549.7526 }, 
  { group: "Facebook", category: "No entendidos", measure: 1909.4394 }, 
  { group: "Whatsapp", category: "Bot", measure: 1041.5124 }, 
  { group: "Whatsapp", category: "Usuario", measure: 2430.1956 }, 
  { group: "Whatsapp", category: "Paso Agente", measure: 15275.5152 }, 
  { group: "Whatsapp", category: "No entendidos", measure: 4166.0496 } 
  ]
  ;

  /*data.datasetLineChart = [
    { group: "All", category: 2008, measure: 28 }, 
    { group: "All", category: 2009, measure: 23 }, 
    { group: "All", category: 2010, measure: 31 }, 
    { group: "All", category: 2011, measure: 22 }, 
    { group: "All", category: 2012, measure: 24 }, 
    { group: "Web", category: 2008, measure: 3 }, 
    { group: "Web", category: 2009, measure: 2 }, 
    { group: "Web", category: 2010, measure: 1 }, 
    { group: "Web", category: 2011, measure: 4 }, 
    { group: "Web", category: 2012, measure: 5 }, 
    { group: "Facebook", category: 2008, measure: 5}, 
    { group: "Facebook", category: 2009, measure: 10 }, 
    { group: "Facebook", category: 2010, measure: 15 }, 
    { group: "Facebook", category: 2011, measure: 20 }, 
    { group: "Facebook", category: 2012, measure: 25 }, 
    { group: "Whatsapp", category: 2008, measure: 10 }, 
    { group: "Whatsapp", category: 2009, measure: 20 }, 
    { group: "Whatsapp", category: 2010, measure: 30 }, 
    { group: "Whatsapp", category: 2011, measure: 40 }, 
    { group: "Whatsapp", category: 2012, measure: 50 }
 
    ]
    ;*/
    data.datasetLineChart = [
      {
        name: "Bot 1",
        values: [
            { group: "All", category: 2008, measure: 28 }, 
            { group: "All", category: 2009, measure: 23 }, 
            { group: "All", category: 2010, measure: 31 }, 
            { group: "All", category: 2011, measure: 22 }, 
            { group: "All", category: 2012, measure: 24 }, 
            { group: "Web", category: 2008, measure: 15 }, 
            { group: "Web", category: 2009, measure: 24 }, 
            { group: "Web", category: 2010, measure: 23 }, 
            { group: "Web", category: 2011, measure: 12 }, 
            { group: "Web", category: 2012, measure: 11 }, 
            { group: "Facebook", category: 2008, measure: 25}, 
            { group: "Facebook", category: 2009, measure: 20 }, 
            { group: "Facebook", category: 2010, measure: 25 }, 
            { group: "Facebook", category: 2011, measure: 10 }, 
            { group: "Facebook", category: 2012, measure: 5 }, 
            { group: "Whatsapp", category: 2008, measure: 20 }, 
            { group: "Whatsapp", category: 2009, measure: 10 }, 
            { group: "Whatsapp", category: 2010, measure: 10 }, 
            { group: "Whatsapp", category: 2011, measure: 30 }, 
            { group: "Whatsapp", category: 2012, measure: 40 }
        ]
      },
      {
        name: "Bot 2",
        values: [
            { group: "All", category: 2008, measure: 8 }, 
            { group: "All", category: 2009, measure: 3 }, 
            { group: "All", category: 2010, measure: 1 }, 
            { group: "All", category: 2011, measure: 2 }, 
            { group: "All", category: 2012, measure: 4 }, 
            { group: "Web", category: 2008, measure: 10 }, 
            { group: "Web", category: 2009, measure: 10 }, 
            { group: "Web", category: 2010, measure: 23 }, 
            { group: "Web", category: 2011, measure: 6 }, 
            { group: "Web", category: 2012, measure: 15 }, 
            { group: "Facebook", category: 2008, measure: 15}, 
            { group: "Facebook", category: 2009, measure: 20 }, 
            { group: "Facebook", category: 2010, measure: 5 }, 
            { group: "Facebook", category: 2011, measure: 10 }, 
            { group: "Facebook", category: 2012, measure: 5 }, 
            { group: "Whatsapp", category: 2008, measure: 20 }, 
            { group: "Whatsapp", category: 2009, measure: 10 }, 
            { group: "Whatsapp", category: 2010, measure: 20 }, 
            { group: "Whatsapp", category: 2011, measure: 14 }, 
            { group: "Whatsapp", category: 2012, measure: 25 }
        ]
      },
      {
        name: "Bot 3",
        values: [
            { group: "All", category: 2008, measure: 18 }, 
            { group: "All", category: 2009, measure: 13 }, 
            { group: "All", category: 2010, measure: 21 }, 
            { group: "All", category: 2011, measure: 12 }, 
            { group: "All", category: 2012, measure: 14 }, 
            { group: "Web", category: 2008, measure: 11 }, 
            { group: "Web", category: 2009, measure: 21 }, 
            { group: "Web", category: 2010, measure: 13 }, 
            { group: "Web", category: 2011, measure: 14 }, 
            { group: "Web", category: 2012, measure: 25 }, 
            { group: "Facebook", category: 2008, measure: 25}, 
            { group: "Facebook", category: 2009, measure: 20 }, 
            { group: "Facebook", category: 2010, measure: 15 }, 
            { group: "Facebook", category: 2011, measure: 20 }, 
            { group: "Facebook", category: 2012, measure: 5 }, 
            { group: "Whatsapp", category: 2008, measure: 1 }, 
            { group: "Whatsapp", category: 2009, measure: 12 }, 
            { group: "Whatsapp", category: 2010, measure: 23 }, 
            { group: "Whatsapp", category: 2011, measure: 30 }, 
            { group: "Whatsapp", category: 2012, measure: 40 }
        ]
      }
    ];

    data.datasetSquareText = [
      {
        name: "Estadística 1",
        title: "Duración Promedio Sesión",
        icon: "https://image.flaticon.com/icons/svg/784/784662.svg",
        values: [
            { group: "All", bot: "All", category: 2008, measure: 28 }, 
            { group: "All", bot: "All", category: 2009, measure: 23 }, 
            { group: "All", bot: "All", category: 2010, measure: 31 }, 
            { group: "All", bot: "All", category: 2011, measure: 22 }, 
            { group: "All", bot: "All", category: 2012, measure: 24 }, 
            { group: "Web", bot: "All", category: 2008, measure: 5 }, 
            { group: "Web", bot: "Bot 1", category: 2008, measure: 2 }, 
            { group: "Web", bot: "Bot 2", category: 2008, measure: 2 }, 
            { group: "Web", bot: "Bot 3", category: 2008, measure: 1 }, 
            { group: "Web", bot: "All", category: 2009, measure: 4 }, 
            { group: "Web", bot: "All", category: 2010, measure: 3 }, 
            { group: "Web", bot: "All", category: 2011, measure: 2 }, 
            { group: "Web", bot: "All", category: 2012, measure: 1 }, 
            { group: "Facebook", bot: "All", category: 2008, measure: 25}, 
            { group: "Facebook", bot: "Bot 1", category: 2008, measure: 12 }, 
            { group: "Facebook", bot: "Bot 2", category: 2008, measure: 3 }, 
            { group: "Facebook", bot: "Bot 3", category: 2008, measure: 10 }, 
            { group: "Facebook", bot: "All", category: 2009, measure: 20 }, 
            { group: "Facebook", bot: "All", category: 2010, measure: 25 }, 
            { group: "Facebook", bot: "All", category: 2011, measure: 10 }, 
            { group: "Facebook", bot: "All", category: 2012, measure: 5 }, 
            { group: "Whatsapp", bot: "All", category: 2008, measure: 20 }, 
            { group: "Whatsapp", bot: "All", category: 2009, measure: 10 }, 
            { group: "Whatsapp", bot: "All", category: 2010, measure: 10 }, 
            { group: "Whatsapp", bot: "All", category: 2011, measure: 30 }, 
            { group: "Whatsapp", bot: "All", category: 2012, measure: 40 }
        ]
      },
      {
        name: "Estadística 2",
        title: "Sesión más larga",
        icon: "https://image.flaticon.com/icons/svg/149/149234.svg",
        values: [
            { group: "All", bot: "All", category: 2008, measure: 8 }, 
            { group: "All", bot: "All", category: 2009, measure: 3 }, 
            { group: "All", bot: "All", category: 2010, measure: 1 }, 
            { group: "All", bot: "All", category: 2011, measure: 2 }, 
            { group: "All", bot: "All", category: 2012, measure: 4 }, 
            { group: "Web", bot: "All", category: 2008, measure: 10 }, 
            { group: "Web", bot: "Bot 1", category: 2008, measure: 2 }, 
            { group: "Web", bot: "Bot 2", category: 2008, measure: 3 }, 
            { group: "Web", bot: "Bot 3", category: 2008, measure: 5 }, 
            { group: "Web", bot: "All", category: 2009, measure: 2 }, 
            { group: "Web", bot: "All", category: 2010, measure: 23 }, 
            { group: "Web", bot: "All", category: 2011, measure: 41 }, 
            { group: "Web", bot: "All", category: 2012, measure: 15 }, 
            { group: "Facebook", bot: "All", category: 2008, measure: 15}, 
            { group: "Facebook", bot: "Bot 1", category: 2008, measure: 8 }, 
            { group: "Facebook", bot: "Bot 2", category: 2008, measure: 3 }, 
            { group: "Facebook", bot: "Bot 3", category: 2008, measure: 4 }, 
            { group: "Facebook", bot: "All", category: 2009, measure: 20 }, 
            { group: "Facebook", bot: "All", category: 2010, measure: 5 }, 
            { group: "Facebook", bot: "All", category: 2011, measure: 10 }, 
            { group: "Facebook", bot: "All", category: 2012, measure: 5 }, 
            { group: "Whatsapp", bot: "All", category: 2008, measure: 20 }, 
            { group: "Whatsapp", bot: "All", category: 2009, measure: 10 }, 
            { group: "Whatsapp", bot: "All", category: 2010, measure: 20 }, 
            { group: "Whatsapp", bot: "All", category: 2011, measure: 14 }, 
            { group: "Whatsapp", bot: "All", category: 2012, measure: 25 }
        ]
      },
      {
        name: "Estadística 3",
        title:"Tiempo promedio con Agente",
        icon: "https://image.flaticon.com/icons/svg/950/950299.svg",
        values: [
            { group: "All", bot: "All", category: 2008, measure: 18 }, 
            { group: "All", bot: "All", category: 2009, measure: 13 }, 
            { group: "All", bot: "All", category: 2010, measure: 21 }, 
            { group: "All", bot: "All", category: 2011, measure: 12 }, 
            { group: "All", bot: "All", category: 2012, measure: 14 }, 
            { group: "Web", bot: "All", category: 2008, measure: 11 }, 
            { group: "Web", bot: "Bot 1", category: 2008, measure: 7 }, 
            { group: "Web", bot: "Bot 2", category: 2008, measure: 3 }, 
            { group: "Web", bot: "Bot 3", category: 2008, measure: 1 }, 
            { group: "Web", bot: "All", category: 2009, measure: 21 }, 
            { group: "Web", bot: "All", category: 2010, measure: 13 }, 
            { group: "Web", bot: "All", category: 2011, measure: 14 }, 
            { group: "Web", bot: "All", category: 2012, measure: 25 }, 
            { group: "Facebook", bot: "All", category: 2008, measure: 25}, 
            { group: "Facebook", bot: "Bot 1", category: 2008, measure: 12 }, 
            { group: "Facebook", bot: "Bot 2", category: 2008, measure: 3 }, 
            { group: "Facebook", bot: "Bot 3", category: 2008, measure: 10 }, 
            { group: "Facebook", bot: "All", category: 2009, measure: 20 }, 
            { group: "Facebook", bot: "All", category: 2010, measure: 15 }, 
            { group: "Facebook", bot: "All", category: 2011, measure: 20 }, 
            { group: "Facebook", bot: "All", category: 2012, measure: 5 }, 
            { group: "Whatsapp", bot: "All", category: 2008, measure: 1 }, 
            { group: "Whatsapp", bot: "All", category: 2009, measure: 12 }, 
            { group: "Whatsapp", bot: "All", category: 2010, measure: 23 }, 
            { group: "Whatsapp", bot: "All", category: 2011, measure: 30 }, 
            { group: "Whatsapp", bot: "All", category: 2012, measure: 40 }
        ]
      },
      {
        name: "Estadística 4",
        title: "Tiempo promedio de respuesta del bot",
        icon: "https://image.flaticon.com/icons/svg/1766/1766441.svg",
        values: [
            { group: "All", bot: "All", category: 2008, measure: 18 }, 
            { group: "All", bot: "All", category: 2009, measure: 13 }, 
            { group: "All", bot: "All", category: 2010, measure: 21 }, 
            { group: "All", bot: "All", category: 2011, measure: 12 }, 
            { group: "All", bot: "All", category: 2012, measure: 14 }, 
            { group: "Web", bot: "All", category: 2008, measure: 11 },
            { group: "Web", bot: "Bot 1", category: 2008, measure: 2 }, 
            { group: "Web", bot: "Bot 2", category: 2008, measure: 5 }, 
            { group: "Web", bot: "Bot 3", category: 2008, measure: 4 },  
            { group: "Web", bot: "All", category: 2009, measure: 21 }, 
            { group: "Web", bot: "All", category: 2010, measure: 13 }, 
            { group: "Web", bot: "All", category: 2011, measure: 14 }, 
            { group: "Web", bot: "All", category: 2012, measure: 25 }, 
            { group: "Facebook", bot: "All", category: 2008, measure: 25}, 
            { group: "Facebook", bot: "Bot 1", category: 2008, measure: 10 }, 
            { group: "Facebook", bot: "Bot 2", category: 2008, measure: 3 }, 
            { group: "Facebook", bot: "Bot 3", category: 2008, measure: 12 }, 
            { group: "Facebook", bot: "All", category: 2009, measure: 20 }, 
            { group: "Facebook", bot: "All", category: 2010, measure: 15 }, 
            { group: "Facebook", bot: "All", category: 2011, measure: 20 }, 
            { group: "Facebook", bot: "All", category: 2012, measure: 5 }, 
            { group: "Whatsapp", bot: "All", category: 2008, measure: 1 }, 
            { group: "Whatsapp", bot: "All", category: 2009, measure: 12 }, 
            { group: "Whatsapp", bot: "All", category: 2010, measure: 23 }, 
            { group: "Whatsapp", bot: "All", category: 2011, measure: 30 }, 
            { group: "Whatsapp", bot: "All", category: 2012, measure: 40 }
        ]
      }
    
    ];

   var 
    config = 
 {
    containerId: "chartContainer",
    values: [{
            group: "All", bot: "All", category: 2008,
            data: [
                {value: 25,label: "Nuevos",inner: [
                                                        {value: 15,label: "Comprometidos"}, 
                                                        {value: 10,label: "No Comprometidos"}
                                                    ]}, 
                {value: 50,label: "Recurrentes",inner: [
                                                            {value: 20,label: "Comprometidos"}, 
                                                            {value: 30,label: "No Comprometidos"}
                                                        ]}
                    ]
                },
                {
                    group: "Facebook", bot: "All", category: 2008,
                    data: [
                        {value: 8,label: "Nuevos",inner: [
                                                                {value: 5,label: "Comprometidos"}, 
                                                                {value: 3,label: "No Comprometidos"}
                                                            ]}, 
                        {value: 10,label: "Recurrentes",inner: [
                                                                    {value: 2,label: "Comprometidos"}, 
                                                                    {value: 8,label: "No Comprometidos"}
                                                                ]}
                            ]
                },{
                    group: "Whatsapp", bot: "All", category: 2008,
                    data: [
                        {value: 35,label: "Nuevos",inner: [
                                                                {value: 20,label: "Comprometidos"}, 
                                                                {value: 15,label: "No Comprometidos"}
                                                            ]}, 
                        {value: 20,label: "Recurrentes",inner: [
                                                                    {value: 12,label: "Comprometidos"}, 
                                                                    {value: 8,label: "No Comprometidos"}
                                                                ]}
                            ]
                        },
                        {
                            group: "Web", bot: "All", category: 2008,
                            data: [
                                {value: 35,label: "Nuevos",inner: [
                                                                        {value: 12,label: "Comprometidos"}, 
                                                                        {value: 23,label: "No Comprometidos"}
                                                                    ]}, 
                                {value: 43,label: "Recurrentes",inner: [
                                                                            {value: 23,label: "Comprometidos"}, 
                                                                            {value: 20,label: "No Comprometidos"}
                                                                        ]}
                                    ]
                                }
            ]
};
data.datasetPSChart={config:config};
/*
  // Colors for each slice
data.colors = ["blue", "orange", "purple", 'green', 'red', "yellow", 'lemonChiffon'];

//Define the width of the svg element on the page
data.width = 960;

//Define the actual height(diameter) of the pie chart, this should not be larger than the width
data.height = 700;

//Define a class for the svg element for styling
data.arcClass = 'arc';
*/
export default data;