import { LOGIN_ACTIONS_TYPES } from '../types/loginActionsTypes';


const INITIAL_STATE = {
    isLogin: false,
    error: '',
    loading: false,
    sendOpt: false,
    otp:false,
    userBlock: false,
    changepassword:false,
    changepasswordmsg: '',
    password:'',
    username:'',
    email:'',
    userInfo:''
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
        case LOGIN_ACTIONS_TYPES.getAuthorization():
			return {
                ...state,
                authorization: action.payload,
				loading: false,
				error: ''
            };
		case LOGIN_ACTIONS_TYPES.requestLogin():
			return {
				...state,
				loading: true,
				error: ''
            };
        case LOGIN_ACTIONS_TYPES.requestBlockUser():
            return {
                ...state,
                userBlock: true,
                error: ''
            };
        case LOGIN_ACTIONS_TYPES.requestOtpsendLogin():
            return {
                    ...state,
                    sendOpt: true,
            }; 
        case LOGIN_ACTIONS_TYPES.requestPasswordsendLogin():
            return {
                    ...state,
                    password: action.payload,
            }; 
        case LOGIN_ACTIONS_TYPES.requestUsernamesendLogin():
            return {
                    ...state,
                    username: action.payload,
            };  
        case LOGIN_ACTIONS_TYPES.getEmailUsarAuthorization():
            return {
                    ...state,
                    email: action.payload,
            };
        case LOGIN_ACTIONS_TYPES.requestOtpLogin():
            return {
                    ...state,
                    otp: true,
            };
        case LOGIN_ACTIONS_TYPES.requestChangepassword():
            return {
                ...state,
                changepassword: action.payload,
            };
        case LOGIN_ACTIONS_TYPES.requestChangepasswordMsg():
                return {
                    ...state,
                    changepasswordmsg: action.payload,
                };
        case LOGIN_ACTIONS_TYPES.requestLogout():
            return {
                ...state,
                isLogin: false,
                authorization: null,
                userInfo: null,
                error:'',
                loading: false,
                otp: false,
                sendOpt: false,
            };

        case LOGIN_ACTIONS_TYPES.successLogin():
        return {
            ...state,
            userInfo: action.payload,
            loading: false,
            isLogin: true,
            error: ''
        };

        case LOGIN_ACTIONS_TYPES.errorLogin():
        return {
            ...state,
            loading: false,
            error: action.payload
        };

        
		default: return state;
	};
};