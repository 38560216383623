/* 
*   Valida el tipo de caja a pintar en home-layout y bot-schemas-layout 
*   agrega las características de cada una. 
*/ 

import React, {useState, useEffect} from 'react'; 
import { connect } from "react-redux";

import { Col, Row, UncontrolledTooltip } from 'reactstrap'; 
import {Link} from 'react-router-dom'

/* imagen */
import defaultIcon from  '../../../../static/img/bot-icons/1.png';
import menu from '../../../../static/img/menu-options.png';
import flujo from '../../../../static/img/bot-icons/flujo.png';
import { useTranslation } from 'react-i18next';
import i18next from '../../../../i18n'

import base64 from 'base-64'
import utf8 from 'utf8'
import {getDuplicateBot,getExportBot,getExportScheme,getDuplicateScheme} from '../../../redux/actions/botActions'

function BotBox(props){ 

    const { t, i18n } = useTranslation();

    useEffect(() => {
        i18next
      },[i18n.language]);

    var data = JSON.parse(props.data); 
    console.log("BOT BotBox ",data)
    console.log("BOT BotBox data.bot_version ",data.bot_version)
    
    let test_url =''; 
    let bot_version ='';
    if(props.type != 'schemes'){ 
        if(data.bot_version && data.bot_version.length > 0){
            bot_version =data.bot_version[0].botversion_id;
            if(data.image_link_testing)
                test_url = HOST_API + '/api/testing?i='+data.id+'&v='+bot_version +"&u="+
                base64.encode(utf8.encode(data.image_link_testing));
            else
                test_url = HOST_API + '/api/testing?i='+data.id+'&v='+bot_version+"&u="+
                base64.encode(utf8.encode('https://s3.amazonaws.com/Guarumo/landing-vertical.png'));
                
        } 
        let change_date_format = date => { 
            date = date.slice(0,10); 
            let aux = date.split('-'); 
            date = aux[2]+"/"+aux[1]+"/"+aux[0]; 
            return date; 
        }  
        // dates: 
        data.reg_date = change_date_format(data.reg_date); 
        data.mod_date = change_date_format(data.mod_date);  
    } 
    return ( 
            <Col className="dropdown colBot" md="6" sm="12" lg="4" xl="3" > 
                { 
                    props.type == 'bot' && 
                        <div className="thumbnail bot dropdown-toggle2" type="button" data-toggle="dropdown"> 
                            <Row> 
                                <div> 
                                    <img className="menu-options" src={menu} />
                                </div> 
                                <Col md="4" className="bot-icons-div d-flex align-items-center"> 
                                    { data.icon ? ( 
                                        <img className="img-fluid" src={data.icon} />
                                    ) : ( 
                                        <img className="img-fluid" src={defaultIcon} />
                                    )} 
                                </Col> 
                                <Col md={{ size: 8 }} style={{paddingBottom:'10px',paddingLeft:'10px',paddingRight:'20px'}}> 
                                    <p className="title-bot">{data.name}</p> 
                                    <small>{i18next.t('modified_in')+': '} {data.mod_date}</small> 
                                    <small>{i18next.t('created_in')+': '} {data.reg_date}</small> 
                                </Col> 
                            </Row> 
                        </div> 
                } 
                { 
                    props.type == 'schemes' && 
                        <div className={props.anonimo == data.idschema ? 'thumbnail bot dropdown-toggle2 anonimo':'thumbnail bot dropdown-toggle2' } type="button" data-toggle="dropdown" id={'tooltipAnonimo'+data.idschema}> 
                            <Row style={{'padding':'10px 0px'}}> 
                            <UncontrolledTooltip placement="right" target={'tooltipAnonimo'+data.idschema}>{props.anonimo == data.idschema ? i18next.t('anonymous_flow'):i18next.t('flow') }</UncontrolledTooltip>
                                <div> 
                                    <img className="menu-options" src={menu} />
                                </div> 
                                <Col md="4" className="bot-icons-div d-flex align-items-center"> 
                                    <img className="img-fluid" src={flujo} />
                                </Col> 
                                <Col md={{ size: 8 }} style={{'position':'relative'}}> 
                                { 
                                    props.initial == data.idschema && 
                                            <Col className="check-avatar"> 
                                                <div className="checkmark customCheckmark" id={'tooltip'+data.idschema}> 
                                                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 161.2 161.2" enableBackground="new 0 0 161.2 161.2"> 
                                                    <path className="path" fill="none" stroke="#7DB0D5" strokeMiterlimit="10" d="M425.9,52.1L425.9,52.1c-2.2-2.6-6-2.6-8.3-0.1l-42.7,46.2l-14.3-16.4c0.3-0.3,0.5-0.6,0.7-0.9l46.3-50.1C427.7,57.5,427.7,54.2,425.9,52.1z"/> 
                                                    <circle className="path" fill="none" stroke="#7DB0D5" strokeWidth="4" strokeMiterlimit="10" cx="80.6" cy="80.6" r="62.1"/> 
                                                    <polyline className="path" fill="none" stroke="#7DB0D5" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" points="113,52.8 74.1,108.4 48.2,86.4 "/> 
                                                    <circle className="spin" fill="none" stroke="#7DB0D5" strokeWidth="4" strokeMiterlimit="10" strokeDasharray="12.2175,12.2175" cx="80.6" cy="80.6" r="73.9"/> 
                                                    </svg> 
                                                </div> 
                                                <UncontrolledTooltip placement="right" target={'tooltip'+data.idschema}>{i18next.t('start_flow')}</UncontrolledTooltip> 
                                            </Col> 
                                } 
                                    <p className="title-bot" style={{'fontSize':'14px'}}>{i18next.t('title')+': '}<br></br><small style={{'fontSize':'15px'}}><b>{data.title}</b></small></p> 
                                </Col> 
                            </Row> 
                        </div> 
                } 
                {/* Menú según la caja */}
                { 
                   
                    props.type == 'bot' &&  
                        <ul className="dropdown-menu dropdown-bot"> 
                            <li> <a href={test_url} target="_blank" >{i18next.t('test')}</a></li> 
                            <li><Link to={"/schemas/"+data.id}>{i18next.t('edit')}</Link></li> 
                            <li onClick={() => props.getExportBot(bot_version)}><a href="#">{i18next.t('export')}</a></li> 
                            <li onClick={() => props.getDuplicateBot(bot_version,data.name)}><a href="#">{i18next.t('duplicatedBot')}</a></li> 
                            {/* item eliminar ocultado temporalmente  */}
                            {/* <li><a className="eliminarBot" onClick={ (e) => props.deleteBot(data.id) }>{i18next.t('delete')}</a></li>  */}
                        </ul> 
                } 
                { 
                    props.type == 'schemes' &&  
                        <ul className="dropdown-menu dropdown-bot"> 
                            <li style={{'paddingLeft':'10px','cursor':'pointer'}} onClick={ (e) => props.editSchema(data)}>{i18next.t('rename')}</li> 
                            <li style={{'paddingLeft':'10px','cursor':'pointer'}} onClick={ (e) => props.initialStep(data.idschema)}>{i18next.t('home')}</li> 
                            <li style={{'paddingLeft':'10px','cursor':'pointer'}} onClick={ (e) => props.anonymous(data.idschema)}>{props.anonimo == data.idschema ? i18next.t('anonymous_flow_deactivate'):i18next.t('anonymous_flow')}</li> 
                            <li><Link to={"/bot/"+data.id+"/"+data.idschema}>{i18next.t('edit')}</Link></li> 
                            <li onClick={() => props.getExportScheme(data.idschema)}><a href="#">{i18next.t('export')}</a></li> 
                            <li onClick={() => props.getDuplicateScheme(data.idschema, data.id)}><a href="#">{i18next.t('duplicatedShema')}</a></li> 
                            {/* <li><a className="eliminarBot" onClick={ (e) => props.deleteSchema(data.idschema) }>{i18next.t('delete')}</a></li>  */}
                        </ul> 
                } 
            </Col> 

             
    ); 
} 

const mapStateToProps = ({boxReducer,botReducer,homeReducer}) => {
    return {boxReducer, botReducer, homeReducer};
    };
    
const mapDispatchToProps = {  
    getDuplicateBot,
    getExportBot,
    getExportScheme,
    getDuplicateScheme
};

export default connect(mapStateToProps, mapDispatchToProps )(BotBox);
   
