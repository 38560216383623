import React from 'react';  
import i18next from '../../../../i18n';
import Admin from '../../admin/containers/admin';  
import { Col,Row,Container, Button } from 'reactstrap';  
import ModalContactos from '../../../components/modals/contacts/modal-contactos';   
import { DiagramWidget } from "storm-react-diagrams"  
import TMModal from '../operators/single-message/text-module-modal';  



import GOTOModal from '../operators/go-to/go-modal';  
import SMSModal from '../operators/sms/sms-modal';  
import QRModal from '../operators/quick-replies/quick-replies-modal';  
import UFModal from '../operators/upload-file/upload-file-modal';  
import EBModal from '../operators/elements-button/elements-buttons-modal';  
import CNModal from '../operators/carousel/carousel-modal';  
import FRMModal from '../operators/form/form-modal';  
import WVModal from '../operators/web-view/web-view-modal';  
import APModal from '../operators/agent-pass/agent-pass-modal';  
import APIModal from '../operators/api/api-module-modal';  
import IFModal from '../operators/conditional/if-modal';  
import EventModal from '../../../components/events/event';  
import HelpModal from '../../../components/modals/help/help-modal';  
import MMModal from '../operators/multimedia/multimedia-module-modal';
import DSUSModal from '../operators/desuscripcion/ds-modal';
import EMAILModal from '../operators/email/email-modal';
import TRIGGERModal from '../operators/trigger/trigger-modal';
import VariableModal from '../operators/variable/variable-modal';
import CDModal from '../operators/calendar-date/calendar-date-modal';  
import SQRModal from '../operators/scan-qr/scan-qr-modal';  
import CATModal from '../operators/catalog/catalog-modal';  
import PAYModal from '../operators/payment/payment-modal'; 
import CRModal from '../operators/custom-report/custom-report-modal';  
import BModal from '../operators/blockchain/blockchain-modal';  
import LCModal from '../operators/location-coordinates/location-coordinates-modal'; 

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'  
import { faArrowLeft, faSave, faPhone} from '@fortawesome/free-solid-svg-icons'  
import BtnTooltip from '../../../components/btn-tooltip';
import {Link} from 'react-router-dom' 


/* imagenes */
import listaBotones from '../../../../static/img/module-icons/modulo2.png'
import carrusel from '../../../../static/img/module-icons/modulo3.png'
import textoPlano from '../../../../static/img/module-icons/modulo8.png'
import formulario from '../../../../static/img/module-icons/modulo4.png'
import api from '../../../../static/img/module-icons/modulo7.png'
import condicional from '../../../../static/img/module-icons/2.png'
import respuestasRapidas from '../../../../static/img/module-icons/modulo5.png'
import goTo from '../../../../static/img/module-icons/modulo10.png'
import pasoAgente from '../../../../static/img/module-icons/modulo9.png'
import vistaWeb from '../../../../static/img/module-icons/modulo1.png'
import calendarDate from '../../../../static/img/module-icons/calendar.png'
import scanqr from '../../../../static/img/module-icons/qr.png'
import catalog from '../../../../static/img/module-icons/catalogo.png'
import payment from '../../../../static/img/module-icons/payment.png'
import customReport from '../../../../static/img/module-icons/reporte.png'
import locationCoordinates from '../../../../static/img/module-icons/location.png'
import uploadFile from '../../../../static/img/module-icons/modulo6.png'
import sms from '../../../../static/img/module-icons/sms.png'
import img from '../../../../static/img/module-icons/img.png'
import dsus from "../../../../static/img/module-icons/modulo15.png"
import email from "../../../../static/img/module-icons/email.png";
import trigger from "../../../../static/img/module-icons/trigger.png";
import variable from "../../../../static/img/module-icons/variable.png";

import blockchain from "../../../../static/img/module-icons/blockchain.png";

import help from '../../../../static/img/ayuda.png';
import info from '../../../../static/img/icono_informacion_small.png';

 
require("storm-react-diagrams/dist/style.min.css");  
require("./bot-layout.css")  
  
function BotLayout(props) {  
  //Esconde todos los home de los operadores, si el schema no es el principal  
  props.hideAll(props.idSchema);  
 

    var buttons=<Col className="col-12 p-0 mt-2"> 
    <BtnTooltip text={i18next.t('save')} className="btn-module" clickAction={props.saveBotAndGenerate} elementID="save_bot_script">  
      <FontAwesomeIcon icon={faSave} />  
    </BtnTooltip>  
    
    <BtnTooltip text={i18next.t('plane_text')} className="btn-module" clickAction={() => props.drawModule('text_module') } elementID="add_text_module">
      <img src={textoPlano} />
    </BtnTooltip>  
 
    <BtnTooltip text={i18next.t('form')} className="btn-module" clickAction={() => props.drawModule('form') } elementID="add_form_module">
      <img src={formulario} />
    </BtnTooltip>  

    <BtnTooltip text={i18next.t('quick_replies')} className="btn-module" clickAction={() => props.drawModule('quickreplies') } elementID="add_quickreplies_module">
      <img src={respuestasRapidas} />
    </BtnTooltip>   

    <BtnTooltip text={i18next.t('buttons_list')} className="btn-module" clickAction={() => props.drawModule('elements_button') } elementID="add_buttons_module">
         <img src={listaBotones} />
    </BtnTooltip>  
 
    <BtnTooltip text="Multimedia" className="btn-module" clickAction={() => props.drawModule('multimedia') } elementID="add_multimedia_module">
      <img src={img} />
    </BtnTooltip>  
 
    <BtnTooltip text={i18next.t('upload_file')} className="btn-module" clickAction={() => props.drawModule('upload_file') } elementID="add_upload_module">
      <img src={uploadFile} />
    </BtnTooltip> 

    <BtnTooltip text={i18next.t('carousel')} className="btn-module" clickAction={() => props.drawModule('elements_generic') } elementID="add_carousel_module">
      <img src={carrusel} />
    </BtnTooltip>  

    <BtnTooltip text={i18next.t('external_api')} className="btn-module"  clickAction={() => props.drawModule('external_api_begin') } elementID="add_api_module">
      <img src={api}/>
    </BtnTooltip> 
 
    <BtnTooltip text={i18next.t('conditional')} className="btn-module" clickAction={() => props.drawModule('conditional') } elementID="add_conditional_module">
      <img src={condicional} />
    </BtnTooltip> 
 
    <BtnTooltip text="Variables" className="btn-module" clickAction={() => props.drawModule('variable') } elementID="add_variable_module">
      <img src={variable} />
    </BtnTooltip>  
    
    <BtnTooltip text="Go to" className="btn-module" clickAction={() => props.drawModule('go_to') } elementID="add_goto_module">
      <img src={goTo} />
    </BtnTooltip>  

    <BtnTooltip text={i18next.t('switch_to_agent')} className="btn-module" clickAction={() => props.drawModule('agent_pass')} elementID="add_agent_module">
      <img src={pasoAgente} />
    </BtnTooltip>  
        
       
    <BtnTooltip text={i18next.t('custom_report')} className="btn-module" clickAction={() => props.drawModule('custom_report') } elementID="add_custom_report_module">
      <img src={customReport} />
    </BtnTooltip> 

   
    <BtnTooltip text={i18next.t('module_web_views')} className="btn-module" clickAction={() => props.drawModule('webview') } elementID="add_webview_module">
      <img src={vistaWeb} />
    </BtnTooltip> 
    {/* operador nuevo*/}
    <BtnTooltip text={i18next.t('calendar')} className="btn-module" clickAction={() => props.drawModule('calendar') } elementID="add_calendar_module">
      <img src={calendarDate} />
    </BtnTooltip> 

    <BtnTooltip text={i18next.t('location')} className="btn-module" clickAction={() => props.drawModule('location') } elementID="add_location_module">
      <img src={locationCoordinates} />
    </BtnTooltip> 

    <BtnTooltip text={i18next.t('scanner') + ' QR'} className="btn-module" clickAction={() => props.drawModule('scanqr') } elementID="add_scanqr_module">
      <img src={scanqr} />
    </BtnTooltip> 

    <BtnTooltip text={i18next.t('catalog')} className="btn-module" clickAction={() => props.drawModule('catalog') } elementID="add_catalog_module">
      <img src={catalog} />
    </BtnTooltip> 

    <BtnTooltip text={i18next.t('payments_online')} className="btn-module" clickAction={() => props.drawModule('payment') } elementID="add_payment_module">
      <img src={payment} />
    </BtnTooltip> 

    <BtnTooltip text="SMS" elementID="add_sms_module" clickAction={() => props.drawModule('sms') }> 
      <img src={sms} /> 
    </BtnTooltip> 

    <BtnTooltip text="Email" className="btn-module" clickAction={() => props.drawModule('email') } elementID="add_email_module">
      <img src={email} />
    </BtnTooltip> 
      
   <BtnTooltip text="Blockchain" className="btn-module" clickAction={() => props.drawModule('blockchain') } elementID="add_blockchain_module">
      <img src={blockchain} />
    </BtnTooltip> 

    {/* <BtnTooltip text="Trigger" className="btn-module" clickAction={() => props.drawModule('trigger') } elementID="add_trigger_module">
      <img src={trigger} />
    </BtnTooltip> 
    
    <BtnTooltip text={i18next.t('unsubscribe')} className="btn-module" clickAction={() => props.drawModule('dsus') } elementID="add_dsus_module">
      <img src={dsus} />
    </BtnTooltip> 

    <BtnTooltip text={i18next.t('contacts')} className="btn-module" clickAction={props.toggleContactModal} elementID="add_contacts_module">  
        <FontAwesomeIcon icon={faPhone} />  
      </BtnTooltip>   */}

  </Col>;  
  
  return (  
    <div className="BotLayout">  
          <Admin>  
            <Container fluid>  
              <Col className="bots-flujo pl-0 col-3 col-xl-2">  
                <h4 className="text-left ml-4 font-weight-bold">
                  <Link to={`/schemas/${props.botId}`} className="float__Label">
                    <FontAwesomeIcon icon={faArrowLeft} />
                  </Link>
                  &nbsp;
                  <Link to={`/schemas/${props.botId}`}>
                  {i18next.t('bot_edition')}
                  </Link>
                  <span className="btn-help" onClick={props.toggleHelpModal}>
                    <img className="img-help" src={help}/>
                  </span>
                  {/* <span className="btn-help" onClick={() => props.engine.zoomToFit() }>
                  <FontAwesomeIcon icon={faMapMarkerAlt} />
                  </span> */}
                 
                </h4> 
              </Col>  
              <Col className="sidebar-menu-operator p-0">
                {buttons}
              </Col>  
              <Col className="bots-flujo-left pl-0 col-12 col-xl-12">  
                <h4 className="text-left ml-4 font-weight-bold">
                  <span className="btn-help" >
                    <img className="img-help" src={info}/>
                    {' '+i18next.t('box')+': '+ props.totalbox +' '+i18next.t('bot_id')+': '+ props.botId + ' '+i18next.t('bot_version')+': '+props.botversion_id+ ' '+ i18next.t('company') + ': ' + props.company_id}
                  </span>
                </h4> 
              </Col>  
            </Container>  
            <Container fluid>  
              <Row>  
                <Col>  
                  <DiagramWidget diagramEngine={props.engine} className="flowchart-container" />  
                </Col>  
              </Row>  
            </Container>  
            <TMModal company_id={props.company_id} botId={props.botId} modal={props.m1} closeModal={props.closeModal} varList={props.varList} updateSchema={props.updateSchema} toggleModuleModal={props.toggleModuleModal} botversion_id={props.botversion_id} />  
            <CRModal company_id={props.company_id} botId={props.botId} modal={props.m_creport} closeModal={props.closeModal} varList={props.varList} updateSchema={props.updateSchema} toggleModuleModal={props.toggleModuleModal} listWebform={props.listWebform} botversion_id={props.botversion_id} />  
            <BModal company_id={props.company_id} botId={props.botId} modal={props.m_blockchain} closeModal={props.closeModal} varList={props.varList} updateSchema={props.updateSchema} toggleModuleModal={props.toggleModuleModal} listWebform={props.listWebform} botversion_id={props.botversion_id} />  
            
            <QRModal company_id={props.company_id} modal={props.m2} closeModal={props.closeModal} varList={props.varList} updateSchema={props.updateSchema} toggleModuleModal={props.toggleModuleModal} botversion_id={props.botversion_id} />  
            <UFModal company_id={props.company_id} modal={props.m3} closeModal={props.closeModal} varList={props.varList} updateSchema={props.updateSchema} toggleModuleModal={props.toggleModuleModal} botversion_id={props.botversion_id} />  
            <EBModal company_id={props.company_id} modal={props.m4} closeModal={props.closeModal} varList={props.varList} updateSchema={props.updateSchema} toggleModuleModal={props.toggleModuleModal} botversion_id={props.botversion_id} />  
            <CNModal company_id={props.company_id} botId={props.botId} modal={props.m5} closeModal={props.closeModal} varList={props.varList} updateSchema={props.updateSchema} toggleModuleModal={props.toggleModuleModal} botversion_id={props.botversion_id} />  
            <FRMModal company_id={props.company_id} modal={props.m6} closeModal={props.closeModal} varList={props.varList} updateSchema={props.updateSchema} toggleModuleModal={props.toggleModuleModal} botversion_id={props.botversion_id} />  
            <WVModal company_id={props.company_id} modal={props.m7} closeModal={props.closeModal} varList={props.varList} updateSchema={props.updateSchema} toggleModuleModal={props.toggleModuleModal} listWebform={props.listWebform} botversion_id={props.botversion_id} />  
            <CDModal company_id={props.company_id} modal={props.m_cd} closeModal={props.closeModal} varList={props.varList} updateSchema={props.updateSchema} toggleModuleModal={props.toggleModuleModal} listWebform={props.listWebform} botversion_id={props.botversion_id} />  
            <SQRModal company_id={props.company_id} modal={props.m_scanqr} closeModal={props.closeModal} varList={props.varList} updateSchema={props.updateSchema} toggleModuleModal={props.toggleModuleModal} listWebform={props.listWebform} botversion_id={props.botversion_id} />  
            <CATModal company_id={props.company_id} modal={props.m_catalog} closeModal={props.closeModal} varList={props.varList} updateSchema={props.updateSchema} toggleModuleModal={props.toggleModuleModal} listWebform={props.listWebform} botversion_id={props.botversion_id} />  
            <PAYModal company_id={props.company_id} modal={props.m_payment} closeModal={props.closeModal} varList={props.varList} updateSchema={props.updateSchema} toggleModuleModal={props.toggleModuleModal} listWebform={props.listWebform} botversion_id={props.botversion_id} />  
            <LCModal  modal={props.m_location}  updateSchema={props.updateSchema} />  
            <MMModal modal={props.m_multimedia}  updateSchema={props.updateSchema} />
            <APModal modal={props.m8} closeModal={props.closeModal} updateSchema={props.updateSchema} toggleModuleModal={props.toggleModuleModal} />  
            <APIModal company_id={props.company_id} modal={props.m9} closeModal={props.closeModal} varList={props.varList} updateSchema={props.updateSchema} toggleModuleModal={props.toggleModuleModal} botversion_id={props.botversion_id} />  
            <EventModal node={props.m10.node} isOpen={props.m10.isOpen} closeModal={props.closeModal} botversion_id={props.m10.botversion_id} updateSchema={props.updateSchema} toggleEventModal={props.toggleEventModal}  />  
            <HelpModal isOpen={props.m11.isOpen} closeModal={props.closeModal} toggleHelpModal={props.toggleHelpModal}/>  
            <IFModal modal={props.m_if} varList={props.varList} closeModal={props.closeModal} updateSchema={props.updateSchema} toggleModuleModal={props.toggleModuleModal} botversion_id={props.botversion_id} />  
            <GOTOModal idSchema={props.idSchema} company_id={props.company_id} botId={props.botId} modal={props.m12} closeModal={props.closeModal} updateSchema={props.updateSchema} toggleModuleModal={props.toggleModuleModal} botversion_id={props.botversion_id}/> 
            <SMSModal idSchema={props.idSchema} varList={props.varList} company_id={props.company_id} botId={props.botId} modal={props.m13} closeModal={props.closeModal} updateSchema={props.updateSchema} toggleModuleModal={props.toggleModuleModal} botversion_id={props.botversion_id}/>  
            
            <DSUSModal idSchema={props.idSchema} varList={props.varList} company_id={props.company_id} botId={props.botId} modal={props.m15} closeModal={props.closeModal} updateSchema={props.updateSchema} toggleModuleModal={props.toggleModuleModal} botversion_id={props.botversion_id}/>  
            <EMAILModal idSchema={props.idSchema} varList={props.varList} company_id={props.company_id} botId={props.botId} modal={props.m16} closeModal={props.closeModal} updateSchema={props.updateSchema} toggleModuleModal={props.toggleModuleModal} botversion_id={props.botversion_id}/>  
            <TRIGGERModal  company_id={props.company_id} botId={props.botId} modal={props.m17} closeModal={props.closeModal} updateSchema={props.updateSchema} toggleModuleModal={props.toggleModuleModal} botversion_id={props.botversion_id}/>  
            <VariableModal idSchema={props.idSchema} varList={props.varList} company_id={props.company_id} botId={props.botId} modal={props.m18} closeModal={props.closeModal} updateSchema={props.updateSchema} toggleModuleModal={props.toggleModuleModal} botversion_id={props.botversion_id}/>  

            <ModalContactos   
              botId={props.botId}
              toggleContactModal={props.toggleContactModal}   
              isOpen={props.modalContact}/> 
          </Admin>  
          <div id="live-chat"></div>  
    </div>  
  )  
}  
  
export default BotLayout