import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Select from 'react-select'
import DataTableFilter from '../../pages/analytics/infoViz/common/dataTableFilter';
/*DATA TABLE FILTER AXIOS*/
import DataTableAxiosFilter from '../../pages/analytics/infoViz/common/dataTableAxiosFilter';
/*END DATA TABLE FILTER AXIOS*/
import i18next from '../../../i18n';

import { 
    Col,
    Row,
    Button,
    ModalBody, 
    Input,
    Label,
  } from 'reactstrap';


import { deleteCompany } from '../../redux/actions/companyActions';
import { uploadFileDinamic, selectCompanyAction, uploadFileDinamicUpdate, showDatabaseCollect, 
        uploadFileDinamicVisualizar, filtrarCollection, showValues } from '../../redux/actions/databaseAction';
        
import IconButton from '@material-ui/core/IconButton';
import FilterListIcon from '@material-ui/icons/FilterList'; // Importa el icono FilterList


 
function FilterSearch(props){

    const { companyReducer: { listAllCompanies = [] }, deleteCompany } = props;

    const { visualizarCollection, sinRegistroFiltro, nombreCompany, visualizarCollectionPagination, databasesShowValues } = props.databaseReducer;
    
    const [searchSectionVisible, setSearchSectionVisible] = useState(false);

    const [isChecked1, setIsChecked1] = useState(true);
    const [isChecked2, setIsChecked2] = useState(false);
  
    const handleCheckboxChangeInfo = (checkboxNumber) => {
      if (checkboxNumber === 1) {
        setIsChecked1(true);
        setIsChecked2(false);
      } else {
        setIsChecked1(false);
        setIsChecked2(true);
      }
    };
 
    //const [data, setData] = useState([]);
    const [dataTable, setDataTable] = useState([]);
    
    const [page, setPage] = useState(0);

    const [rowsPerPage,setRowsPerPage] = useState(20);

    

    const [formData, setFormData] = useState({
          columna: '',
          condicion: '',
          comparar: ''
    });

    function onChangeDataBaseShow(e){

      const { name, value } = e.target;
     
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    
    }

    const [columnsVisibility, setColumnsVisibility] = useState([]);
    const [columnsIn, setColumnsIn] = useState([]);

    useEffect(() => {
      setColumnsIn(databasesShowValues.map(column => ({ value: column, label: column})))
    }, [databasesShowValues]);

    useEffect(() => {
        handlePageChange(page,rowsPerPage)
        setPage(page)
        //setData(visualizarCollection);
    }, [page, rowsPerPage]);

    /*useEffect(() => {
      setData(visualizarCollection);
    }, []);*/
    

    const handlePageChange = async (page, rowsPerPage) => {
      page = Number(page) + 1;
      await props.uploadFileDinamicVisualizar(nombreCompany, page, rowsPerPage);
    };


    const handleAdd = () => {
      
      if(formData.condicion == "in" && isChecked1 == true){
         formData.comparar = selectedIn.map(item => item.value).join(',')
      }

      if (!formData.columna) {
        alert('El campo "Columna" es obligatorio.');
        return;
      }
  
      if (!formData.condicion) {
        alert('El campo "Condicional" es obligatorio.');
        return;
      }
  
      if (!formData.comparar) {
        alert('El campo "información" es obligatorio.');
        return;
      }
      
      setDataTable(prevData => [...prevData, formData]);
        // Limpiar el formulario después de enviar los datos
        setFormData({
          columna: '',
          condicion: '',
          comparar: ''
        });
      
        setColumnsIn([]);

        setSelectedOptions(null);
    
      };

    const handleDelete = (index) => {
      setDataTable(prevData => prevData.filter((_, i) => i !== index));
    };
    

  {/* ==============================================
              VOLVER DINAMIDO
    =============================================== */}
    
    let resultado = [];

    const reorderedKeysVisualizar = visualizarCollection && visualizarCollection.length > 0 ? 
      Array.from(new Set(visualizarCollection.reduce((allKeys, obj) => {
        // Obtenemos las claves de cada objeto
        const keys = Object.keys(obj);
        // Agregamos las claves al conjunto (set)
        return allKeys.concat(keys);
      }, []))) : [];
      
          // Reorder keys to move 'created' to the end
    const reorderKeys = (keys, keyToMove) => {
      // Find the index of the key to move
      const index = keys.indexOf(keyToMove);
      if (index !== -1) {
          // Remove the key from its current position
          const [removedKey] = keys.splice(index, 1);
          // Append the key to the end of the array
          keys.push(removedKey);
      }
      return keys;
    };

    // Reorder the keys such that 'created' is at the end
    const keysVisualizar = reorderKeys(reorderedKeysVisualizar, 'created');

    const visualizarCollectionArray = visualizarCollection && visualizarCollection.length > 0?visualizarCollection:[];
   
    const columnsVisualizar = keysVisualizar.length > 0 ? keysVisualizar.map((field) => ({
      label: field,
      field: field,
      sort: 'asc',
    })) : [];

    useEffect(() => {
      // Inicializamos el estado `columnsVisibility` con los valores de `databasesFiles`
      setColumnsVisibility(columnsVisualizar.map(column => ({ value: column.field, label: column.field, isVisible: false })));
    }, [visualizarCollection]);

    const [selectedOptions, setSelectedOptions] = useState([]);

    const handleSelectChange = (selectedOptions) => {
      
      setFormData(prevState => ({
        ...prevState,
        ['columna']: selectedOptions.value
      }));

      props.showValues(selectedOptions.value);
      setSelectedOptions(selectedOptions);

    };


    if (sinRegistroFiltro) {
        // Transform each row in visualizarCollectionArray to an array of values matching columnsVisualizar
        resultado = visualizarCollectionArray && visualizarCollectionArray.length>0 ? visualizarCollectionArray.map((row) => {
            // Create an array for each row
            let transformedRowArray = [];
            // Add values in the order of columnsVisualizar fields
            columnsVisualizar.forEach((column) => {
                const key = column.field;
                // Include the value for each key, or 'N/A' if it's undefined
                let value = row[key] !== undefined ? row[key] : 'N/A';
                
                if (typeof value === 'object' && value !== null) {
                  value = JSON.stringify(value);
                }
                
                transformedRowArray.push(value);
            });
            return transformedRowArray;
        }): [] ;
    } else {
        resultado = [];
    }
      
    const handleSubmitDinamic = async (e) => {
      e.preventDefault();
      dataTable.rowsPerPage = rowsPerPage;
      await props.filtrarCollection(JSON.stringify(dataTable),rowsPerPage,page);
    };
  
    {/* ========================================
              FIN VOLVER DINAMIDO
    =========================================== */}


    {/*=======================================
          INFORMACION PARA TABLA VISUALIZAR
      ======================================== */}
    let titleTable ='';

    let optionsTable = {
        filterType: 'dropdown', // Habilitar menús de filtrado
        filter: false,
        selectableRows:'none',
        resizableColumns: false,
        responsive: "scrollMaxHeight",
        serverSide: true,
        count: visualizarCollectionPagination.total_records,
        page: page,
        rowsPerPage: rowsPerPage,
        onTableChange: (action, tableState) => {
            if (action === 'changePage') {
                setPage(tableState.page);
            } else if (action === 'changeRowsPerPage') {
              // Si se cambia el número de registros por página, actualiza `rowsPerPage` y realiza una nueva consulta
              setRowsPerPage(tableState.rowsPerPage);
              setPage(0); // Reiniciar la página a 0 para la nueva consulta
            }
        },
        print:true,
        download: true, // Habilitar la opción de descarga
        viewColumns:false,
        search: true, 
        customToolbar: () => {
          return (
            <div>
              <IconButton onClick={() => toggleSearchSection()}>
                <FilterListIcon />
              </IconButton>
            </div>
          );
        },
    };
    
    const toggleSearchSection = () => {
      setSearchSectionVisible(!searchSectionVisible);
    };
    
    {/*=======================================
        END INFORMACION PARA TABLA VISUALIZAR
      ======================================== */}

    return (
        <ModalBody>
            {searchSectionVisible && (
              <div className="search-section" style={{ maxHeight: '800px', overflowY: 'auto' }}>
                <Row>

                <Col lg={4}>
                    <label for="exampleFormControlSelect1">Selecciona Columna</label>
                  <Select
                      closeMenuOnSelect={false}
                      className="basic-single"
                      options={columnsVisibility}
                      onChange={handleSelectChange}
                      value={selectedOptions}
                    />

                </Col>

                  <Col lg={4}>
                    <div className="form-group">
                        <label for="exampleFormControlSelect1">Selecciona Condicional</label>
                        <select className="form-control" name="condicion" id="exampleFormControlSelect1" value={formData.condicion} onChange={onChangeDataBaseShow}>
                          <option value="">Selecciona...</option>
                          <option value="=="> == </option>
                          <option value="!="> != </option>
                          {/* <option value="in"> in </option> */}
                          <option value="<"> {'<'} </option>
                          <option value=">"> {'>'} </option>
                          <option value="<="> {'<='} </option>
                          <option value=">="> {'>='} </option>
                        </select>
                      </div>
                  </Col>


                  <Col lg={4}>
                    <div claclassNamess="form-group">
                    
                    <label>
                          <input
                            type="checkbox"
                            checked={isChecked1}
                            onChange={() => handleCheckboxChangeInfo(1)}
                          />
                          información
                        </label>
                        <label>
                          <input
                            type="checkbox"
                            checked={isChecked2}
                            onChange={() => handleCheckboxChangeInfo(2)}
                          />
                          Escribir
                        </label>
                        {isChecked1 == true && isChecked2 == false ?
                          (
                            <select className="form-control" name="comparar" id="exampleFormControlSelect1" value={formData.comparar} onChange={onChangeDataBaseShow}>
                              <option value="">Selecciona...</option>
                              {databasesShowValues && databasesShowValues.map((column, index) => (
                                <option value={column} key={index}>{column}</option>
                              ))}
                            </select>
                        ):(
                            <input
                              className="form-control" 
                              name="comparar" 
                              id="exampleFormControlSelect1" 
                              value={formData.comparar} 
                              onChange={onChangeDataBaseShow}
                            />
                          )
                        } 
                    </div>
                  </Col>
                  
                  <Col xs={12} lg={12} className="mb-3">
                        <Button className="btn-block" color="success" size="lg" type="button" onClick={() => handleAdd()}>Agregar</Button>
                    </Col>

                    <table className="table table-striped" style={{ width: '100%'}}>
                      <thead>
                        <tr>
                          <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>Columna</th>
                          <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>Condicional</th>
                          <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>Comparar</th>
                          <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>Acción</th>
                        </tr>
                      </thead>
                      <tbody>
                      {
                        dataTable.length <= 0 ? (
                          <tr>
                            <td colSpan="4" style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>
                              No se encontraron registros coincidentes
                            </td>
                          </tr>
                        ):dataTable.map((item, index) => {
                          return (
                            <tr key={index} style={{ borderBottom: '1px solid #ddd' }}>
                              <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>{item.columna}</td>
                              <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>{item.condicion}</td>
                              <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>{item.comparar}</td>
                              <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>
                                <button 
                                  onClick={() => handleDelete(index)} 
                                  style={{ 
                                    padding: '5px 10px', 
                                    backgroundColor: '#007bff', 
                                    color: 'white', 
                                    border: 'none', 
                                    borderRadius: '3px', 
                                    cursor: 'pointer' 
                                  }}
                                >
                                  Eliminar
                                </button>
                              </td>
                            </tr>
                          );
                        })
                      }
                    </tbody>
                    </table>

                    <Col xs={12} lg={12} className="mb-3">
                        <Button className="btn-block" color="success" 
                                //disabled={dataTable.length <= 0}
                                size="lg" 
                                type="button" 
                                onClick={(e) => handleSubmitDinamic(e)}>{i18next.t('search')}</Button>
                    </Col>

                </Row>
              </div>
            )}

            <Row >
                <Col xs={12} lg={12} >
                {
                    <Row >
                        <Col xs={12} lg={12} >
                            <DataTableAxiosFilter
                                title={titleTable}
                                data={resultado}
                                columns={columnsVisualizar}
                                options={optionsTable}
                            />
                        </Col>
                    </Row>
                }
                </Col>
            </Row>
        </ModalBody>
    );
}


const mapStateToPros = ({companyReducer, databaseReducer}) => ({companyReducer, databaseReducer})

const mapDispatchToProps = {
    deleteCompany,
    uploadFileDinamic,
    uploadFileDinamicUpdate,
    selectCompanyAction,
    showDatabaseCollect,
    uploadFileDinamicVisualizar,
    filtrarCollection,
    showValues
}; 

export default connect(mapStateToPros, mapDispatchToProps)(withRouter(FilterSearch))