import React, {Component, useState} from 'react';
import { connect } from "react-redux";

import { Label,Button,Modal,ModalHeader,ModalBody,ModalFooter,Form,FormGroup,Input,Col,Row,Nav,NavItem,TabContent,TabPane,NavLink,UncontrolledTooltip} from 'reactstrap';
import IASelector from '../../../../components/ia';
import Keyword from '../../../../components/keyword/keyword-redux';
import Mentions from '../../../../components/mentions/metions-redux';
import Avatar from '../../../../components/avatar';
import TitleOperator from "../../../../components/title-operator";
import Emoji from '../../../../components/emoji-picker';
import classnames from 'classnames';

import { isEmpty} from "../../../../utils/commonFunction";
import { iconLoad, 
        iconHideLoad, 
        getListTypeCalendar,
        setSelectedTypeCalendar, 
        toggleModalViewBoxCalendar,
        changeActiveTab,
        setChangeConfig } from "../../../../redux/actions/boxActions";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import '../../../../../static/css/DatePickerSimple.css'; // Custom Styling for Calendar

var momentTZ = require('moment-timezone');

import moment from 'moment';

import i18next from '../../../../../i18n';

let item_type,startDate,endDate,showTimeSelect,zone, selectedDefaultOption,selectedMaxDate,selectedMinDate, minDate, maxDate, maxTime,minTime;


function CDMLayout(props) {
   
    
    const {selectedBoxOperator,listTypeCalendar, configBoxOperator, modalViewBoxCalendar,activeItem,avatarid,botmsg_text} =props.boxReducer;

   // const {selectedTypeLocation,variablesBoxOperator,dinamicVariablesBoxOperator,exit_var} =props.boxReducer;

    const { bot,botVersionId } = props.botReducer;
   
    item_type = null;
    startDate  = null;
    endDate = null;
    minDate  = null;
    maxDate =  null;
    minTime = null;
    maxTime = null;
    showTimeSelect = null;
    zone = null;
    selectedDefaultOption = null;
    selectedMaxDate = null;
    selectedMinDate = null;

   
    

    const 
            defaultMinTime = moment().hours(0).minutes(0),
            defaultMaxTime = moment().hours(23).minutes(45)
          //  [minTime, setMinTime] = useState(defaultMinTime),
          //  [maxTime, setMaxTime] = useState(defaultMaxTime)

    if(selectedBoxOperator&&Object.keys(selectedBoxOperator).length>0&&selectedBoxOperator.operatortype_id==19)
    {

    
            const listZones=momentTZ.tz.names();

            if(configBoxOperator&&configBoxOperator.item_type)
            {
                //selectedDate = moment(new Date(configBoxOperator.selectedDate));
                item_type = configBoxOperator.item_type;
                startDate  = moment(new Date(configBoxOperator.startDate));
                endDate = moment(new Date(configBoxOperator.endDate));
                minDate  = configBoxOperator.minDate?moment(new Date(configBoxOperator.minDate)):null;
                maxDate =  configBoxOperator.maxDate?moment(new Date(configBoxOperator.maxDate)):null;
                minTime  = configBoxOperator.minTime?moment(new Date(configBoxOperator.minTime)):defaultMinTime;
                maxTime =  configBoxOperator.maxTime?moment(new Date(configBoxOperator.maxTime)):defaultMaxTime;
                showTimeSelect = (configBoxOperator.showTimeSelect=='False')?false:true;
                zone = configBoxOperator.zone;
                selectedDefaultOption = configBoxOperator.selectedDefaultOption;
                selectedMaxDate = configBoxOperator.selectedMaxDate
                selectedMinDate = configBoxOperator.selectedMinDate
          

                return (
                    <React.Fragment>
                    <Modal className="calendar"
                    isOpen={modalViewBoxCalendar}
                    toggle={props.toggleModalViewBoxCalendar} 
                    // isOpen={props.isOpen} toggle={props.closeModal} 
                    fade size="lg">
                        <ModalHeader toggle={props.toggleModalViewBoxCalendar}>{i18next.t('config_calendar')}</ModalHeader>
                        <Form id="CDM-form" role="form">  
                        <ModalBody>
                        <Nav tabs>
                                <NavItem>
                                <NavLink
                                    className={classnames({ active: activeItem === '1' })}
                                    onClick={(e) => { toggleTab('1'); }} >
                                    {i18next.t('basic')}
                                </NavLink>
                          
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                   className={classnames({ active:activeItem === '2' })}
                                    onClick={(e) => toggleTab("2")} 
                                    //className={classnames({ active: activeItem === '2' })}
                                    //onClick={() => { props.changeActiveTab('2'); }}>
                                   // disabled
                                    >
                                    {i18next.t('advanced')}
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={activeItem}>
                                <TabPane tabId="1">
                                    <TitleOperator titleOperator={selectedBoxOperator.title} />
                                        <Row>
                                            <Col sm="12"> 
                                                <Label>{i18next.t('statement')} <i className="fa fa-info-circle" id="tooltipEnunciado"></i></Label>
                                                <UncontrolledTooltip placement="right" target="tooltipEnunciado"> {i18next.t('message8')}</UncontrolledTooltip>
                                                  <FormGroup>
                                                    <Mentions defaultValue={botmsg_text || ''}  maxlength={'2000'} msg_error={i18next.t('msg_error_limit')} k={0} updateItem={props.updateItem}/>
                                        
                                         <div className="options-bar-input" style={{'right':'1px'}}>
                                                        <Emoji id={'head'} callEmoji={props.callEmoji} type="head" style={{'display':'none',position: 'absolute',right: 268+'px',zIndex: 1}}/>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                            <Col sm="3">
                                                    <Label>{i18next.t('message9')} <i className="fa fa-info-circle" id="tooltipVariable"></i></Label>
                                                    <UncontrolledTooltip placement="right" target="tooltipVariable"> {i18next.t('message10')}</UncontrolledTooltip>
                                                    <FormGroup>
                                                        <Input type="text" className="inputModal" placeholder={i18next.t('message9')} onChange={(event) => { props.updateVarName( event) }}  defaultValue={props.var_name} />
                                                    </FormGroup>
                                                </Col>
                                                <Col sm="3">
                                                    <Label>{i18next.t('variable_json_api')} <i className="fa fa-info-circle" id="tooltipVariableJson"></i></Label>
                                                    <UncontrolledTooltip placement="right" target="tooltipVariableJson"> {i18next.t('message11')}</UncontrolledTooltip>
                                                    <FormGroup>
                                                        <Input type="text" className="inputModal" placeholder={i18next.t('variable_json_api')} onChange={(event) => { props.updateVarNameJson( event) }}  defaultValue={props.var_name_json} />
                                                    </FormGroup>
                                                </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="6">
                                            <Label>{i18next.t('type_calendar')}  <i className="fa fa-info-circle" id="tooltipType"></i></Label>
                                            <UncontrolledTooltip placement="right" target="tooltipType"> {i18next.t('type_calendar_config')}</UncontrolledTooltip>
                                                
                                            {listTypeCalendar &&(Object.keys(listTypeCalendar).length > 0 && (
                                            <Input  type="select"
                                                    className="inputModal" 
                                                    name="type" 
                                                    defaultValue={(configBoxOperator)?item_type:''}
                                                    onChange={(e) => onchangeTypeCalendar(e)}
                                                    required>
                                                { Object.keys(listTypeCalendar).map((k, index) => {
                                                    return <option key={index} value={listTypeCalendar[k].type} >{listTypeCalendar[k].text}</option>;
                                                    
                                                    })
                                                }
                                            </Input> 
                                            ))}
                                            </Col>
                                            <Col lg="6">
                                                <Label>{i18next.t('time_zone')}  <i className="fa fa-info-circle" id="tooltipZone"></i></Label>
                                                <UncontrolledTooltip placement="right" target="tooltipZone"> {i18next.t('time_zone_config')}</UncontrolledTooltip>
                                                {listZones &&(listZones.length > 0 && (
                                                <Input  type="select"
                                                        className="inputModal" 
                                                        name="zone" 
                                                        defaultValue={(configBoxOperator)?zone:''}
                                                        onChange={(e) => onchangeZoneCalendar(e)}
                                                        required>
                                                    {listZones.map((zone,i) => {
                                                        // zone=JSON.parse(zone); 
                                                        zone=<option key={i} value={zone}>{zone}</option>
                                                    return zone})}
                                                </Input>
                                                ))}
                                            </Col>
                                        </Row> 
                                        <Row  style={{"marginTop": 15+"px"}}>
                                            <Col xs={3} md={3} lg={3} >
                                            <Label check style={{"marginLeft":15+"px"}}>
                                                <Input type="checkbox" id='dateMin' name="selectedMinDate" value={i18next.t('minimum_date')}
                                                defaultChecked={(configBoxOperator)&&(selectedMinDate=='true')?true:false} 
                                                onChange={(e) => setChangeMinMaxDate('selectedMinDate',(selectedMinDate=='true')?'false':'true',selectedDefaultOption)}></Input>
                                                &nbsp;
                                                        
                                                {i18next.t('minimum_date')}  <i className="fa fa-info-circle" id="tooltipMinDate"></i></Label>
                                            <UncontrolledTooltip placement="right" target="tooltipMinDate"> {i18next.t('minimum_date_config')}</UncontrolledTooltip>
                                                
                                            <div id="datepicker-simple-box">
                                            <DatePicker
                                                            selected={(configBoxOperator)? minDate:null}//{this.state.startDate}//{this.props.analyticsReducer.startDateDashboard}//{this.props.startDate}
                                                            selectsStart
                                                            startDate={(configBoxOperator)? minDate:null}//{this.state.startDate}//{  this.props.analyticsReducer.startDateDashboard}//{this.props.startDate}
                                                            endDate={(configBoxOperator)? maxDate:null}//{this.state.endDate}//{  this.props.analyticsReducer.endDateDashboard}//{this.props.endDate}
                                                            onChange={(d)=>{changeDateStart(d)}}//{this.props.changeStartDateDashboard(d)}}//{this.props.handleChangeStart}
                                                            //isClearable={true}
                                                            maxDate={(configBoxOperator)? maxDate:null}

                                                            minTime={(configBoxOperator)? defaultMinTime:null}
                                                            maxTime={(configBoxOperator)? maxTime:defaultMaxTime}
                                                            peekNextMonth
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode="select"
                                                            showTimeSelect = {true}
                                                            timeFormat="HH:mm"
                                                            timeIntervals={15}
                                                            //className="input-border"
                                                            dateFormat="YYYY/MM/DD  hh:mm a"
                                                            className="form-control"
                                                            timeInputLabel={i18next.t('hour')}
                                                            disabled = {(configBoxOperator)&&(selectedMinDate=='true')?false:true}
                                                            
                                                        />
                                                    </div>
                                            </Col>
                                            <Col xs={3} md={3} lg={3} >
                                            <Label check style={{"marginLeft":15+"px"}}>
                                                            <Input type="checkbox" id='dateMax' name="selectedMaxDate" value={i18next.t('maximum_date')}
                                                            defaultChecked={(configBoxOperator)&&(selectedMaxDate=='true'&&maxDate)?true:false} 
                                                            onChange={(e) => setChangeMinMaxDate('selectedMaxDate',(selectedMaxDate=='true')?'false':'true',selectedDefaultOption)}></Input> 
                                                            &nbsp;
                                                            {i18next.t('maximum_date')}  <i className="fa fa-info-circle" id="tooltipMaxDate"></i></Label>
                                            <UncontrolledTooltip placement="right" target="tooltipMaxDate"> {i18next.t('maximum_date_config')}</UncontrolledTooltip>
                                           
                                            <div id="datepicker-simple-box">
                                            <DatePicker
                                                            selected={(configBoxOperator)? maxDate:null}//{this.state.endDate}//{  this.props.analyticsReducer.endDateDashboard}//{this.props.endDate}
                                                            selectsEnd
                                                            startDate={(configBoxOperator)?minDate:null}//{this.state.startDate}//{  this.props.analyticsReducer.startDateDashboard}//{this.props.startDate}
                                                            endDate={(configBoxOperator)? maxDate:null}//{this.state.endDate}//{  this.props.analyticsReducer.endDateDashboard}//{this.props.endDate}
                                                            onChange= {(d)=>{changeDateEnd(d)}}//{this.props.changeEndDateDashboard(d)}}//{this.props.handleChangeEnd}
                                                            minDate={(configBoxOperator)? minDate:null}//{this.state.startDate}//{  this.props.analyticsReducer.startDateDashboard}//{this.props.startDate}
                                                            minTime={(configBoxOperator)? minTime:defaultMinTime}
                                                            maxTime={(configBoxOperator)? defaultMaxTime:null}
                                                            peekNextMonth
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode="select"
                                                            //isClearable={true}
                                                            showTimeSelect = {true}
                                                            timeFormat="HH:mm"
                                                            timeIntervals={15}
                                                            //className="input-border"
                                                            dateFormat="YYYY/MM/DD  hh:mm a"
                                                            className="form-control"
                                                            timeInputLabel={i18next.t('hour')}
                                                            showTimeInput    
                                                            disabled = {(selectedMaxDate=='true')?false:true}
                                                        /> 
                                                        </div>
                                            </Col>
                        
                                            <Col xs={6} md={6} lg={6}>
                                                <Label>{i18next.t('date_selected_default')+': '}  <i className="fa fa-info-circle" id="tooltipSelectedDate"></i></Label>      
                                                <UncontrolledTooltip placement="right" target="tooltipSelectedDate"> {i18next.t('date_selected_default2')}</UncontrolledTooltip>
                                                <FormGroup check>
                                                    <Row>
                                                    <Col xs={4} md={4} lg={4}>
                                                        <Label check>
                                                            <Input type="radio" id='dateTodayDefault' name="dateDefault" value={i18next.t('current_date')}
                                                            defaultChecked={(configBoxOperator)&&(selectedDefaultOption=="dateTodayDefault")?true:false} 
                                                            onChange={(e) =>  setChangeDefaultDate('selectedDefaultOption','dateTodayDefault',null)}
                                                            disabled={(selectedMaxDate=='true')?true:false}></Input>
                                                            &nbsp;{i18next.t('current_date')}
                                                        </Label>
                                                    </Col>
                                                    <Col xs={4} md={4} lg={4}>
                                                    <Label check>
                                                        <Input type="radio" id='dateStartDefault' name="dateDefault" value={i18next.t('minimum_date')}
                                                        defaultChecked={(configBoxOperator)&&(selectedDefaultOption=="dateStartDefault")?true:false} 
                                                        onChange={(e) =>  setChangeDefaultDate('selectedDefaultOption','dateStartDefault',minDate)}
                                                        disabled={(selectedMinDate=='true')?false:true}></Input>
                                                        &nbsp;{i18next.t('minimum_date')}
                                                    </Label>
                                                    </Col>
                                                    <Col xs={4} md={4} lg={4}>
                                                    <Label check>
                                                        <Input type="radio" id='dateEndDefault' name="dateDefault" value={i18next.t('maximum_date')}
                                                        defaultChecked={(configBoxOperator)&&(selectedDefaultOption=="dateEndDefault")?true:false} 
                                                        onChange={(e) =>  setChangeDefaultDate('selectedDefaultOption','dateEndDefault',maxDate)}
                                                        disabled={(selectedMaxDate=='true')?false:true}></Input>
                                                        &nbsp;{i18next.t('maximum_date')}
                                                    </Label>
                                                    </Col>

                                                    {/*<Col>
                                                        <Input type="text" id="color" name="color"  onChange={(e) => props.setChangeConfig('color','red')}/>
                                                    </Col>*/}

                                                    </Row>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    {/* </Col> */}

                                </TabPane>
                                <TabPane tabId="2">
                                    <Row>
                                        <Col sm="6">
                                            <IASelector botversion_id={botVersionId} operator_id={selectedBoxOperator.id} ia={selectedBoxOperator.ia_id} />
                                            <Avatar company_id={bot.company_id} avatarid={avatarid} bindValue={props.getAvatarId} />
                                        </Col>
                                        <Col sm="6">
                                        <Keyword keyword={selectedBoxOperator.keyword} isValid={props.isKeywordValid} />
                                        </Col>
                                    </Row>
                                </TabPane>
                            </TabContent>                  
                        </ModalBody>
                        <ModalFooter>
                            <Col sm="12" align="right">
                                <Button color="success" size="lg" type="button" onClick={props.toggleModuleModal} >{i18next.t('finish')}</Button>
                            </Col>
                        </ModalFooter>
                        </Form>
                    </Modal>           
                </React.Fragment>
                    )
        }
        else
        {
            return(<div></div>)
        }

    }
    else
    {
        return(<div></div>)
    }
       
              
 function changeDateStart(selectedDate)
 {
    props.setChangeConfig('minDate',selectedDate.format('MM/DD/YYYY h:mm a'));
    
    props.setChangeConfig('minTime',moment(selectedDate));
         
    if(configBoxOperator.selectedDefaultOption=='dateStartDefault')
        props.setChangeConfig('selectedStartDate',selectedDate);  

    if(configBoxOperator.maxDate){
        if(
            maxDate.isSame(selectedDate, 'month') && 
            maxDate.isSame(selectedDate, 'day')
        )
            //setMaxTime(moment(maxDate))
            props.setChangeConfig('maxTime',moment(maxDate));
        else
            //setMaxTime(defaultMaxTime)
            props.setChangeConfig('maxTime',defaultMaxTime);
    }

    
 }
 function changeDateEnd(selectedDate)
 {
    props.setChangeConfig('maxDate',selectedDate.format('MM/DD/YYYY h:mm a'));

    props.setChangeConfig('maxTime',moment(selectedDate));

    if(configBoxOperator.selectedDefaultOption=='dateEndDefault')
        props.setChangeConfig('selectedStartDate',selectedDate);  

    if(configBoxOperator.minDate){
        if(
            minDate.isSame(selectedDate, 'month') && 
            minDate.isSame(selectedDate, 'day')
        )
            //setMinTime(moment(minDate))
            props.setChangeConfig('minTime',moment(minDate));
        else
            //setMinTime(defaultMinTime)
            props.setChangeConfig('minTime',defaultMinTime);
    }

   
 }

 function setChangeDefaultDate(key,value,validation)
 {
     

     if(value=='dateTodayDefault')
        props.setChangeConfig('selectedStartDate',null);
    else if(value=='dateStartDefault')
        props.setChangeConfig('selectedStartDate',validation);
    else if(value=='dateEndDefault')
        props.setChangeConfig('selectedStartDate',validation);

    props.setChangeConfig(key,value)
 }
 function setChangeMinMaxDate(key,value,validation)
 {
        if(key=='selectedMinDate'&&value=='false')
        {
            if(validation=='dateStartDefault')
                props.setChangeConfig('selectedDefaultOption','dateTodayDefault')
            
            props.setChangeConfig('minDate',null);
        }
        else if(key=='selectedMaxDate'&&value=='false')
        {
            if(validation=='dateEndDefault')
                props.setChangeConfig('selectedDefaultOption','dateTodayDefault')
           
            props.setChangeConfig('maxDate',null);
        }
        else if(key=='selectedMaxDate'&&value=='true')
        {
            if(validation=='dateTodayDefault')
                props.setChangeConfig('selectedDefaultOption','dateEndDefault')

            props.setChangeConfig('maxDate',moment());
        }
        else if(key=='selectedMinDate'&&value=='true')
        {
            props.setChangeConfig('minDate',moment());
        }
        
        props.setChangeConfig(key,value)
 }

 
 function onchangeTypeCalendar(e)
 {
   
   var type=e.target.value;
 
   //props.setSelectedTypeCalendar(type);

   props.setChangeConfig('item_type',type);

   if(type=='datetime_calendar')
        props.setChangeConfig('showTimeSelect',true);
   else
        props.setChangeConfig('showTimeSelect',false);
   
   //item_type = type;
 }
 
 function onchangeZoneCalendar(e)
 {
   
    zone=e.target.value;
 
    props.setChangeConfig('zone',zone);
   
 }
 function toggleTab(tab) {
    
    // e.preventDefault();
    // console.log('The link was clicked.',e);
     props.changeActiveTab(tab);
 
}

 }
 
 
 
 
 
 const mapStateToProps = ({boxReducer,botReducer,homeReducer}) => {
     return {boxReducer, botReducer, homeReducer};
     };
     
     const mapDispatchToProps = {
         iconLoad,
         iconHideLoad,
         getListTypeCalendar,
         setSelectedTypeCalendar,
         toggleModalViewBoxCalendar,
         changeActiveTab,
         setChangeConfig
     };
     
     export default connect(mapStateToProps, mapDispatchToProps )(CDMLayout);
