import React, { Component } from 'react';
import {fileHandlerPOST } from '../utils/handlersApi'
import {  
    FormGroup, 
    Input, 
    Col,
    Row,
    Label,
    UncontrolledTooltip
} from 'reactstrap';
import i18next from '../../i18n';

class Audio extends Component {

    state = {
        player: false,
        previewAudio:'',
        showloading:true,
        fileName: ''// Estado para almacenar el nombre del archivo seleccionado
    }

    constructor(props){
        super(props);
    }
        
    componentDidMount = () => {
        this.setState({previewAudio:this.props.audio})
    }

    bindValue = () => {
        if(typeof this.props.bindValue === "function")
             this.props.bindValue(this.state.previewAudio);
    }
 
    updateAudio = e =>{
        this.setState({showloading:false})
        let fileInput= document.getElementById('audio'+this.props.operator_id)
        if(fileInput.files[0].size < 1000000){
            const formData = new FormData();
        formData.append('audio', fileInput.files[0]);
          fileHandlerPOST(HOST_API +'/api/bot/upload_audios/',formData)
            .then((res)=>{
              if(res.status==200){
                this.setState({showloading:true})
                this.setState({
                    previewAudio: res.data.audio,
                    fileName: fileInput.files[0].name
                },()=> this.bindValue()) 
              }
            }).catch((error) => {
              console.log('Catch POST updateAudio:',error);
              if (error.response.status == 403)
                cerrarSesion();
            }); 
        }else{
            alert(i18next.t('msg_file'))
            this.setState({showloading:true})
        } 
    }

    render() {
        return (
            <Row>
                <Col sm="12">
                    <FormGroup>
                        <Label for={'audio'+this.props.operator_id} 
                               htmlFor={'audio'+this.props.operator_id}
                               className="btn btn-outline-secondary" style={{cursor: "pointer"}}
                               > 
                               {i18next.t('file_selected')} {' '} {i18next.t('audio')}  
                               <i className="fa fa-info-circle" id="tooltipaudio"></i>
                        </Label>
                        <UncontrolledTooltip placement="right" target="tooltipaudio"> {i18next.t('tooltip_audio')} </UncontrolledTooltip>
                        <Input type="file" 
                               id={'audio'+this.props.operator_id}
                               name="audio" 
                               accept="audio/mp3, audio/wav" 
                               style={{ display: 'none' }}
                               onChange={(event) => { this.updateAudio(event) }} />
                               {this.state.fileName && <span>{this.state.fileName}</span>}
                    </FormGroup>
                </Col>
                <Col sm="12" id={"audio_div"} hidden={this.state.previewAudio == ''}>
                    <audio controls="" src={this.state.previewAudio} className="player" controls controlsList="nodownload" autoPlay={false} />
                </Col>
                <Col className="text-center" sm="12" id={"audio_div"} hidden={this.state.showloading == true}>
                    <img src="/static/img/loading2.gif" />
                </Col>
            </Row>
        )
    }
}

export default Audio