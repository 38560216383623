import React, { Component } from 'react';
import ModalLoad from '../../../components/modals/modal-load';
import BotEventsLayout from './bot-events-layout';
import moment from "moment";
import axios from 'axios';
import qs from 'qs';

class BotEvents extends Component {
    
    constructor(props){
        super(props);
        this.state = {
            ia_activated: false,
            botId: this.props.match.params.id,
            languages:[],
            hideLoad: 0,
            botVersionId:'',
            bot:'',
            buscador:'',
            data:[],
            pagination:{
              current: 1,
              pages: 1
            }
        }; 
        this.handleFind = this.handleFind.bind(this);
    }


    /*
    * Función que consulta el de bot de base de datos
    * @botid = int (id del bot)
    */
    getBot(botid){
        //obtenemos el token de autenticación
        var auth=JSON.parse(localStorage.getItem('Authorization'));

        var headers={
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer '+ auth.access_token 
        }
  
        return axios({
            method:'get',
            url:HOST_API +'/api/bot/?id='+botid,
            headers:headers
        })
    }
     /*
    * Función para almacenar la busqueda
    * b string con la cadena a buscar
    */
    handleFind(b){
     this.setState({buscador: b.target.value});
    }
    /*
    * Función que consulta info para reportes
    * @page= int (page number)*/

    tableData = (page) => {
        let cont = this.state.hideLoad;
        this.setState({ hideLoad: cont+1});
        let params = "botversion_id="+this.state.botVersionId+ "&p="+page;
        if(this.state.buscador){
          params = params + "&s=" + this.state.buscador;
        } 
        this.state.pagination.current = page;

        this.getHandler(HOST_API +'/api/reports/events?'+params).then(
            (result) => {
                if(result.status==200){
                  let data = [];
                  if(result.data.events) {
                    data = result.data.events;
                  }else {
                    data = result.data.event;
                  }
                    this.state.pagination.pages = result.data.pages;
                    this.setState({
                        data: data
                    });
                }
                let cont = this.state.hideLoad;
                this.setState({ hideLoad: cont-1});
            }
        ).catch((error) => {
            console.log('catch tableData',error);
            let cont = this.state.hideLoad;
            this.setState({ hideLoad: cont-1});
            if (error.response && error.response.status && error.response.status == 403){
                this.cerrarSesion();
            }
        });
    }

    componentDidMount(){
        //obtengo el id del bot enviado como parametro en la url
        const { id } = this.props.match.params
        //guardo el id en el state
        this.setState({
            botId: id
        });
        this.loadLanguages(); // laod languages

        this.getBot(this.state.botId).then((res) => {
            if(res.status==200){
                if(res.data.bot && res.data.bot.length > 0){
                    const bot = res.data.bot[0];
                    //guardamos en el state la iformación del bot actual y su version
                    this.setState({
                        botVersionId: bot.bot_version[0].botversion_id,
                        bot: bot,
                        botId: bot.id,
                        ia_activated: bot.ia_activated
                    });
                    this.tableData("all");//OJO si envía 0 trae toda la data y no pagina en backend
                }
            }
        }).catch((error) => {
            console.log('catch getBot',error);
            if (error.response && error.response.status && error.response.status == 403){
                this.cerrarSesion();
            }
        });
    }

  // loads the languages availables.
  loadLanguages() {
    this.getHandler(HOST_API +'/api/language/').
        then((res)=>{
            if(res.status==200){
              var list = [];
              if(res.data.languages){
                for(let i=0; i<res.data.languages.length;i++) {
                  list.push(JSON.stringify(res.data.languages[i]));
                }
              }
              this.setState({ languages: list});
            }else{
              console.log(" No se pudo obtener la lista");
            }
        }).catch((error) => {
            console.log('catch loadLanguages',error);
    }); 
  }

  /* ajax handler for GET calls */
  getHandler(url){
    var auth=JSON.parse(localStorage.getItem('Authorization'));
      var headers={
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': 'Bearer '+auth.access_token 
      }

      return axios({
          method:'get',
          url:url,
          headers:headers
      })
  }


    render() {
        return (
          <div>
            <ModalLoad hideLoad={this.state.hideLoad}/>
            <BotEventsLayout 
              ia_activated={this.state.ia_activated}
              botId={this.state.botId} 
              buscador={this.state.buscador}
              languages={this.state.languages}
              data ={this.state.data} 
              handleFind ={this.handleFind} 
              tableData ={this.tableData} 
              pagination={this.state.pagination}
              />
          </div>
        )
    }
}

export default BotEvents