import React from 'react'; 
import { Col, Row, Card, CardBody } from 'reactstrap'; 

// Assets 
import './box-channels.css'

export default (props) => {  
    const { title = '', textColor='', avatar='', detail='', background='', onclick , className=''} = props;   
    return ( 
        <Col style={{padding:'0px', }}  >
            <Card className={className} onDragOver={onclick} onClick={onclick} style={{margin: '10px',  padding: '5px' , height:'200px' , background: `${background}` }} >                

                <Row > 
                    <Col style={{textAlign: 'center', margin:'15px 0px 5px 0px '}}>
                        <img src={avatar}/>
                    </Col>
                </Row>  
                <Row > 
                    <Col style={{textAlign: 'center',  }} >
                        <h3 className='box-channel-title' style={{ color: `${textColor}`, margin: '0px' }}> {title} </h3>
                    </Col>
                </Row>
                <Row > 
                    <Col style={{textAlign: 'center', color: `${textColor}`}}>
                        <CardBody> {detail} </CardBody> 
                    </Col>
                </Row>
            </Card>
            <div className='options-item'  >
                {props.children}                     
            </div>
        </Col>   
    );
};

