import React, { Component } from 'react';
import ModalLoad from '../../../components/modals/modal-load';
import BotFunnelsLayout from './bot-funnels-layout';
import {getHandlerGET, getHandlerPOST, getHandlerDELETE, getHandlerPUT, fileHandlerPOST} from '../../../utils/handlersApi'
import {cerrarSesion,isAuthorizationValid} from '../../../utils/commonFunction'
import i18next from '../../../../i18n';


class BotFunnels extends Component {
    
    constructor(props){
        super(props);
        this.state = {
            ia_activated: false,
            botId: this.props.match.params.id,
            languages:[],
            hideLoad: 0,
            botVersionId:'',
            bot:'',
            buscador:'',
            data:[],
            pagination:{
              current: 1,
              pages: 1
            },
            showList: true,
            current:{
              id:null,
              name:"",
              events:[]
            },
            eventList:[]
        }; 
        this.handleFind = this.handleFind.bind(this);
    }

    componentWillMount(){
      isAuthorizationValid();
    }

    componentDidMount(){
        //obtengo el id del bot enviado como parametro en la url
        const { id } = this.props.match.params
        //guardo el id en el state
        this.setState({
            botId: id
        });
        getHandlerGET(HOST_API +'/api/bot/?id='+this.state.botId).then((res) => {
            if(res.status==200){
                if(res.data.bot && res.data.bot.length > 0){
                    const bot = res.data.bot[0];
                    //guardamos en el state la información del bot actual y su version
                    this.setState({
                        botVersionId: bot.bot_version[0].botversion_id,
                        bot: bot,
                        botId: bot.id,
                        ia_activated: bot.ia_activated
                    });
                    this.listEvents();
                    this.tableData("all");//OJO si envía all trae toda la data y no pagina en backend

                }
            }
        }).catch((error) => {
            console.log(error);
            if (error.response && error.response.status && error.response.status == 403){
                cerrarSesion();
            }
        });
    }

    /* List events */
    listEvents(){
      let cont = this.state.hideLoad;
      this.setState({ hideLoad: cont+1});
      getHandlerGET(HOST_API +'/api/reports/filterevent?botversion_id='+this.state.botVersionId+'')
      .then((res)=>{
          if(res.status==200){
            this.setState({eventList: res.data.events},()=>{this.forceUpdate()});
          }
          let cont = this.state.hideLoad; this.setState({ hideLoad: cont-1});
      }).catch((error)=>{
            console.log('catch listEvents',error);
            if (error.response && error.response.status && error.response.status == 403){
                cerrarSesion();
            }
            let cont = this.state.hideLoad; this.setState({ hideLoad: cont-1});
      });
    }

    listEventsFilter(string){
      let cont = this.state.hideLoad;
      this.setState({ hideLoad: cont+1});
      getHandlerGET(HOST_API +'/api/reports/filterevent?botversion_id='+this.state.botVersionId+'&search='+string)
      .then((res)=>{
          if(res.status==200){
            this.setState({eventList: res.data.events},()=>{this.forceUpdate()});
          }
          let cont = this.state.hideLoad; this.setState({ hideLoad: cont-1});
      }).catch((error)=>{
            console.log('catch listEventsFilter',error);
            if (error.response && error.response.status && error.response.status == 403){
                cerrarSesion();
            }
            let cont = this.state.hideLoad; this.setState({ hideLoad: cont-1});
      });
    }

    /* Controls wheter is creating or editing a funnel */
    submitFunnel = (event) => {
      event.preventDefault();
      let form = new FormData(event.target);
      if(form.get('id')) { 
        this.updateFunnel(form);
      } else { 
        this.createFunnel(form);
      }
    } 

    /* Create funnel 
    @form = form data
    */
    createFunnel (form) {
      let cont = this.state.hideLoad; 
      this.setState({ hideLoad: cont+1});
      var events = ""
      if(this.state.current.events.length > 0){
        this.state.current.events.forEach((event)=>{
          events = events + "," + event.value;
        });
        events= events.substr(1);
      }else{
         alert (i18next.t('message171'))
         this.setState({ hideLoad: cont-1});
         return false;
      }
      let data = {
        botversion_id: this.state.botVersionId,
        events_ids: events,
        funnel_name: form.get('name')
      };
      getHandlerPOST(HOST_API +'/api/reports/funnels',data)
        .then((res)=>{
          if(res.status==200){
            let list = this.state.data;
            list.push(res.data.funnel);
            this.setState({data: list});
            this.toggleList(1);
          }
          let cont = this.state.hideLoad;
          this.setState({ hideLoad: cont-1});
        }).catch((error)=>{
          let cont = this.state.hideLoad; 
          this.setState({ hideLoad: cont-1});
            console.log('catch createFunnel',error);
            if (error.response && error.response.status && error.response.status == 403)
                cerrarSesion();
        });
    }

    /* 
    * Updating the funnel 
    * @form = form data
    */
    updateFunnel (form) {
     let cont = this.state.hideLoad; 
     this.setState({ hideLoad: cont+1});
      var events = ""
      let removeComma = false; //remueve coma al inicio
      if(this.state.current.events.length > 0){
        this.state.current.events.forEach((event)=>{
          if(event.value){
            removeComma = true
            events = events + "," + event.value;
          }else
            events += event + ","
        });
        if(removeComma === true)
          events= events.substr(1);
        else
          events= events.slice(0, -1) //remueve coma al final
      }else{
        alert (i18next.t('message171'))
        this.setState({ hideLoad: cont-1});
        return false;
      }
      let data = {
        id: form.get('id'),
        botversion_id: this.state.botVersionId,
        events_ids: events,
        funnel_name: form.get('name')
      };
      getHandlerPUT(HOST_API +'/api/reports/funnels',data)
        .then((res)=>{
          if(res.status==200){
            let list = this.state.data;
              list.map((f)=>{
                if(f.id == res.data.funnel.id){
                  f.funnel_name = res.data.funnel.funnel_name,
                  f.events_ids = res.data.funnel.events_ids
                } 
              })
            this.setState({data: list});
            this.toggleList(1);
          }
          let cont = this.state.hideLoad; this.setState({ hideLoad: cont-1});
        }).catch((error)=>{
          let cont = this.state.hideLoad; 
          this.setState({ hideLoad: cont-1});
            console.log("catch updateFunnel",error);
            if (error.response && error.response.status && error.response.status == 403){
                cerrarSesion();
            }
        });
    }

    /* Delete funnel */
    deleteFunnel = (index) => {
      if (confirm(i18next.t('message172'))) {
        let cont = this.state.hideLoad; this.setState({ hideLoad: cont+1});
        let data ={
          id: this.state.data[index].id
        };
        this.myHelp = index;
        getHandlerDELETE(HOST_API +'/api/reports/funnels', data)
          .then((res)=>{
            if(res.status==200){
              let list = this.state.data;
              list.splice(this.myHelp,1);
              this.setState({data: list});
            }
            let cont = this.state.hideLoad; this.setState({ hideLoad: cont-1});
          }).catch((error)=>{
            let cont = this.state.hideLoad; this.setState({ hideLoad: cont-1});
              console.log(error);
              if (error.response && error.response.status && error.response.status == 403){
                  cerrarSesion();
              }
          });
      }
    }

 
     /*
    * Función para almacenar la busqueda
    * b string con la cadena a buscar
    */
    handleFind(b){
     this.setState({buscador: b.target.value});
    }
    /*
    * Función que consulta info para reportes
    * @page= int (page number)
      @search= bool (indicates if it's a search or not) */

    tableData = (page) => {
      let cont = this.state.hideLoad; this.setState({ hideLoad: cont+1});
        let params = "botversion_id="+this.state.botVersionId + "&p="+page;
        if(this.state.buscador)
          params = params + "&s=" + this.state.buscador;
        this.state.pagination.current = page;
        getHandlerGET(HOST_API +'/api/reports/funnels?'+params)
          .then(
            (result) => {
                if(result.status==200){
                  let data = [];
                  if(result.data.funnels) 
                    data = result.data.funnels;
                  else 
                    data = result.data.funnel;
                    this.state.pagination.pages = result.data.pages;
                    this.setState({
                        data: data
                    });
                }
                let cont = this.state.hideLoad; this.setState({ hideLoad: cont-1});
            }
          ).catch((error) => {
              console.log('catch tableData',error);
              let cont = this.state.hideLoad; this.setState({ hideLoad: cont-1});
              if (error.response && error.response.status && error.response.status == 403){
                  cerrarSesion();
              }
          });
    }


  updateEvents = (selectedOption) => {
    if(selectedOption && selectedOption.target){
      if(selectedOption.target.value != ''){
        this.listEventsFilter(selectedOption.target.value)
      }else{
        this.listEvents()
      } 
    }
    let aux = this.state.current;
    aux.events = selectedOption
    this.setState({ current: aux });
  }

  toggleList = (op, index=null) => {
    if(op == 1){ // is going to create a new funnel
      this.state.current = {
        id:null,
        name: "",
        events: []
      }
    }else { // is going to edit an existing funnel
      let aux = this.state.data[index];
      this.state.current = {
        id: aux.id,
        name: aux.funnel_name,
        events: aux.events_ids.split(",")
      }
    }
    this.state.showList = !this.state.showList;
    this.forceUpdate();
  }


    render() {
        return (
          <div>
            <ModalLoad hideLoad={this.state.hideLoad}/>
            <BotFunnelsLayout 
              ia_activated={this.state.ia_activated}
              botId={this.state.botId} 
              buscador={this.state.buscador}
              languages={this.state.languages}
              data ={this.state.data} 
              handleFind ={this.handleFind} 
              tableData ={this.tableData} 
              pagination={this.state.pagination}
              showList={this.state.showList}
              toggleList={this.toggleList}
              submitFunnel={this.submitFunnel}
              current={this.state.current}
              updateEvents={this.updateEvents}
              eventList={this.state.eventList}
              deleteFunnel={this.deleteFunnel}
              />
          </div>
        )
    }
}

export default BotFunnels