import React, { Component } from 'react';
import GOMLayout from './go-modal-layout';
import axios from 'axios';
import qs from 'qs';
import SMPortModel from '../simplePortModel/SMPortModel';

import { getHandlerGET } from '../../../../utils/handlersApi'
import { cerrarSesion } from '../../../../utils/commonFunction'

class GOTOModal extends Component {
    _isMounted = false;
    state = {
        nodeId: "",
        title: "",
        validSchema:false,
        validOperator:false,
        schemaSelected: '',
        operatorsList: [],
        idSchema: ''
    }

    constructor(props){
        super(props)
        this.listOperatorRef = React.createRef();
    }

    componentWillMount(){
        var auth=JSON.parse(localStorage.getItem('Authorization'));
        if(!auth || !auth.access_token)
          this.props.history.push("/login");
    }


    componentDidMount = () => {
        this._isMounted = true;
    }

    componentWillUnmount = () => this._isMounted = false;


    getOperatorsList () {
        getHandlerGET("/api/scheme/operator/?scheme_id="+this.state.idSchema)
            .then((res) =>{
                if(res.status==200){
                    if(res.data.operators){
                        if(res.data.operators.length > 0){
                            var items = [...res.data.operators];
                            //a = array con resultados filtrados
                            let a = items.filter(e => e.title && e.title.length > 0);
                            var list = [];
                            for(let i=0; i<a.length;i++) {
                                let temp ={
                                    'title':a[i].title,
                                    'id': a[i].id 
                                }
                                list.push(temp);
                            }
                            if (this._isMounted)
                                this.setState({ operatorsList: list});
                        }
                    }
                }else{
                    console.log(" No se pudo obtener la lista");
                }
            }).catch((error)=> {
                console.log('catch getOperatorsList',error)
                if (error.response.status == 403)
                    cerrarSesion();
            });
    }
    
    getOperator = (operator,k) => {
        this.props.modal.node.extras.operatorGoTo = operator
        if (operator !='')
            this.setState({ validOperator: false}, () => { this.forceUpdate();}); 
    }

    getSchema = (schema,k) => {
        this.props.modal.node.extras.schema = schema;
        if (schema !='')
            this.setState({ 
                validSchema: false,
                idSchema: schema
            }, () => { 
                this.forceUpdate();
                this.getOperatorsList();
            });  
    }

    getTitleOperator = (titleOperator) => {
        this.props.modal.node.extras.titleOperator = titleOperator
    }

    validar = () => {
        let flujo = this.props.modal.node.extras.schema;
        if(flujo == '')
            this.setState({ validSchema: true})
        else
            this.setState({ validSchema: false})
        

        let op = this.props.modal.node.extras.operatorGoTo;
        if(op == ''){
            this.setState({ validOperator: true}); 
        }else{
            this.setState({ validOperator: false}); 
        }
    }
    /*
    * Función que consulta el de bot de base de datos
    * @botid = int (id del bot)
    */
 

    toggleModuleModal = () => {
        
        this.validar();
        let node = this.props.modal.node; 
        const ports = node.getPorts();
        const kports = Object.keys(ports);
        const kport = kports.find(k => {
            const port = ports[k]
            return port.name == "right-timeout"
        })
        let title = this.props.modal.node.extras.titleOperator;
        this.saveTitle(title).then(res => {
            this.props.updateSchema();
            this.forceUpdate();
        }).catch((error)=>{
            console.log('catch saveTitle',error);
            if (error.response && error.response.status && error.response.status == 403){
                cerrarSesion();
            }
        });
        if(!kport){
            if(node.extras.timeout > 0){
                node.addPort(new SMPortModel(true, "right-timeout", 'sm'));
            }            
        }else{
            if(node.extras.timeout <= 0){
                kports.forEach((kport) => {
                    const port = ports[kport];                     
                    if(port.name == "right-timeout"){
                        const links = port.getLinks();
                        const klinks = Object.keys(links);
                        klinks.forEach(klink => {
                            const link = links[klink];
                            link.remove();
                        });
                        node.removePort(port);
                    }                    
                })
            } 
        }
        
        let flujo = this.props.modal.node.extras.schema;
        let nextOperator = this.props.modal.node.extras.operatorGoTo;
        if(flujo != '' && nextOperator != ''){
            this.props.toggleModuleModal('go_to')
        }
    }
    saveTitle = (title) => {
        const data = {
            id: this.props.modal.node.extras.dbId,
            title:title
        };

        var auth=JSON.parse(localStorage.getItem('Authorization'));
        var headers={
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer '+ auth.access_token 
        }

        return axios({
            method:'put',
            url:HOST_API +'/api/scheme/operator/',
            data:qs.stringify(data),
            headers:headers
        })
    }
    closeModal = () => {
        this.props.closeModal('m12');
        this.setState({ validSchema: false, validOperator: false}, () => { this.forceUpdate();}); 
    }


    render() {
        return (
          <GOMLayout 
          toggleModuleModal={this.toggleModuleModal}
          isOpen={this.props.modal.isOpen} 
          nodeId={this.state.nodeId} 
          title={this.state.title} 
          botversion_id={this.props.botversion_id}
          operator_id={this.props.modal.node.extras.dbId}
          operator = {this.props.modal.node.extras.operatorGoTo || ''}
          titleOperator = {this.props.modal.node.extras.titleOperator || ''}
          company_id={this.props.company_id}
          botId ={this.props.botId}
          getOperator = {this.getOperator}
          getSchema = {this.getSchema}
          scheme = {this.props.modal.node.extras.schema || ''}
          getTitleOperator = {this.getTitleOperator}
          closeModal ={this.closeModal}
          idSchema = {this.state.idSchema}
          validSchema = {this.state.validSchema}
          validOperator = {this.state.validOperator}
          operatorsList={this.state.operatorsList}
          />
        )
    }
}

export default GOTOModal
