import React, { Component } from 'react';
import CTMLayout from './modal-contactos-layout';

import { getHandlerGET,getHandlerPOST, getHandlerDELETE, getHandlerPOSTJson } from '../../../utils/handlersApi'
import { cerrarSesion } from '../../../utils/commonFunction'
import i18next from '../../../../i18n';


class CTModal extends Component {
    _isMounted = false;
    state = {
        contacts: [{name:"", phone:"",errorName:"",errorPhone:""}],
        contactsLoaded: [],
        botId:this.props.botId
    }
 
    constructor(props){
        super(props)
    }
    componentDidMount(){
        this._isMounted = true;
        this.getContacts();
    }

    componentWillMount(){
        var auth=JSON.parse(localStorage.getItem('Authorization'));
        if(!auth || !auth.access_token)
          this.props.history.push("/login");
    }

    componentWillUnmount = () => this._isMounted = false;
  

    getContacts = () =>{
        getHandlerGET(HOST_API + '/api/bot/contacts/'+this.state.botId) 
            .then((res)=>{ 
                if(res.status==200){ 
                    if (this._isMounted)
                        this.setState({contactsLoaded:res.data.contacts}); 
                } 
            }).catch((error) => { 
                console.log('catch toggleContactModal',error);
                if (error.response.status == 403) 
                    cerrarSesion(); 
            });
    }

    addContact = (e) => { 
        this.setState((prevState) => ({ 
            contacts: [...prevState.contacts, {name:"", phone:"",errorName:"",errorPhone:""}], 
        })); 
    } 

    sendHSMToAll = (e) =>{ 
        var loading = document.getElementById('loading-hsm'); 
        e.preventDefault(); 
        let idsContactsLoaded=[]; 
        this.state.contactsLoaded.forEach((item) => { 
            idsContactsLoaded.push(item.id); 
        }); 
 
        let data = { 
            contacts :  idsContactsLoaded.join(",") 
        } 
        loading.style.display = 'block'; 
        getHandlerPOST(HOST_API+'/api/bot/contacts_hsm/'+this.state.botId,data). 
            then((res)=>{ 
                if(res.status==200){ 
                    var label = document.getElementById('messageHSM'); 
                    label.style.display = 'block'; 
                    setTimeout(function(){  
                        if (label != null) { 
                            label.style.display = 'none'; 
                        } 
                     }, 3000); 
                    let contactsLoaded = [...this.state.contactsLoaded]   
                    contactsLoaded.forEach(contact => { 
                        contact.hsm_sent=true; 
                    });  
                    this.setState({ contactsLoaded }); 
                }else{ 
                  console.log("No se pudieron enviar los mensajes HSM"); 
                } 
            }).catch((error) => { 
                console.log('catch sendHSMToAll getHandlerPOST',error);
                if (error.response.status == 403) 
                    cerrarSesion(); 
            })
            .then(function() { 
                loading.style.display = 'none'; 
            }); 
    } 
 
    sendHSM = (e) =>{ 
        let i = e.target.dataset.id; 
        const data = { 
            contact_id: this.state.contactsLoaded[i].id, 
        }; 
        getHandlerPOST('/api/bot/contacts_hsm/'+this.state.contactsLoaded[i].bot_id_id, data)
            .then((res)=>{ 
                if(res.status==200){ 
                    let contactsLoaded = [...this.state.contactsLoaded]    
                    contactsLoaded[index].hsm_sent=true; 
                    this.setState({ contactsLoaded }); 
                } 
            }).catch((error) => { 
                console.log('catch sendHSM',error);
                if (error.response.status == 403) 
                    cerrarSesion(); 
            });
    } 

    saveContacts = (event) => {   
        event.preventDefault(); 
        let formValid = true; 
        let minLength = 9; 
        let contacts = [] 
        this.state.contacts.forEach((item,index) => { 
            item.errorName=""; 
            item.errorPhone=""; 
            if( item.phone.trim().length<minLength){ 
                formValid = false; 
                item.errorPhone = "Min caracteres: 9"; 
            } 
            if(item.name.trim().length==0){ 
                formValid = false; 
                item.errorName = i18next.t('contact_name'); 
            } 
            contacts.push(item); 
        }); 
        this.setState({contacts:contacts}); 
        let data = { 
            contacts : this.state.contacts
        } 
        if(formValid){ 
            getHandlerPOSTJson(HOST_API+'/api/bot/contacts/'+this.state.botId,data). 
                then((res)=>{ 
                    if(res.status==200){ 
                        this.listContacts(); 
                    }
                }).catch((error) => { 
                    console.log('catch getHandlerPOSTJson',error)
                    if(error.response.status == 500)
                        alert(i18next.t('contact_phone'));   
                    
                    if (error.response.status == 403)
                        cerrarSesion(); 
                }); 
        } 
    } 

    listContacts = () =>{ 
        getHandlerGET(HOST_API +'/api/bot/contacts/'+this.state.botId). 
            then((res)=>{ 
                if(res.status==200){ 
                    let contacts = res.data.contacts.reverse() 
                    this.setState({contactsLoaded:contacts,contacts:[{name:"", phone:"",errorName:"",errorPhone:""}]}); 
                } 
            }).catch((error) => { 
                console.log('catch getHandlerPOSTJson',error)
                    if (error.response.status == 403)
                        cerrarSesion(); 
            }); 
    } 
 

    removeContactLoaded = (e) => { 
        let index = e.target.dataset.id; 
        getHandlerDELETE(HOST_API+'/api/bot/contacts/'+this.state.contactsLoaded[index].bot_id_id+"/"+this.state.contactsLoaded[index].id) 
            .then((res)=>{ 
                if(res.status==200){ 
                    let contactsLoaded = [...this.state.contactsLoaded]    
                    contactsLoaded.splice(index,1); 
                    this.setState({ contactsLoaded }); 
                } 
            }).catch((error) => { 
                console.log('catch removeContactLoaded',error);
                if (error.response.status == 403) 
                    cerrarSesion(); 
            }); 
    } 
 
    deleteContact = (e) => { 
        let contacts = [...this.state.contacts]    
        contacts.splice(e.target.dataset.id,1); 
        this.setState({ contacts }); 
    } 

    handleChangeContact = (e) => { 
        let contacts = [...this.state.contacts]; 
        let length = e.target.value.length; 
        let minLength = 9; 
        if(e.target.dataset.type=="phone"){ 
            if(length >0){ 
                if(length==1){ 
                    if(e.target.value=="+" || isNaN(e.target.value[length-1]))
                        contacts[e.target.dataset.id]["phone"] = "+"; 
                    else
                        contacts[e.target.dataset.id]["phone"] = "+"+e.target.value; 
                }else if(length >1){ 
                    if(!isNaN(e.target.value[length-1])){ 
                        contacts[e.target.dataset.id]["phone"] = e.target.value; 
                    } 
                } 
            } 
            contacts[e.target.dataset.id]["errorPhone"] = ""; 
            if(length<minLength){ 
                contacts[e.target.dataset.id]["errorPhone"] = "Min caracteres: 9"; 
            } 
 
        }else{ 
            contacts[e.target.dataset.id]["errorName"] =""; 
            if(length==0){ 
                contacts[e.target.dataset.id]["errorName"] =i18next.t('contact_name'); 
            } 
            contacts[e.target.dataset.id][e.target.dataset.type] = e.target.value; 
        } 
        this.setState({ contacts }); 
    } 
 
    cleanEmptyContacts = () =>{ 
        let contacts = this.state.contacts; 
        contacts.forEach((item,index) => { 
            if(item.name.trim().length==0 || item.phone.trim().length==0){ 
                contacts.splice(index,1); 
            } 
        }); 
        return contacts; 
    } 

  

    render() {
        return (
            <CTMLayout 
            isOpen={this.props.isOpen} 
            toggleContactModal={this.props.toggleContactModal} 
            contactsLoaded = {this.state.contactsLoaded}
            contacts={this.state.contacts} 
            sendHSM = {this.sendHSM}
            deleteContactLoaded = {this.removeContactLoaded} 
            saveContacts = {this.saveContacts}
            addContact = {this.addContact}
            onChangeContact = {this.handleChangeContact}
            listContacts={this.listContacts} 
            deleteContact = {this.deleteContact} 
            sendHSMToAll = {this.sendHSMToAll}
            />
        )
    }
}

export default CTModal