import { Redirect } from "react-router-dom";
import React from "react";
import i18next from "../../../i18n";
import { ANALYTICS_ACTIONS_TYPES } from "../types/analyticsActionsTypes";
import { HOME_ACTIONS_TYPES } from "../types/homeActionsTypes";
import { MODAL_LOAD_ACTIONS_TYPES } from "../types/loadStateActionsTypes";
import { logOut } from "../actions/loginActions";
import {
  getHandlerGET,
  getHandlerPOST,
  getHandlerDELETE,
  getHandlerPUT,
  getHandlerPOSTJson,
  getHandlerPUTJson,
} from "../../utils/handlersApi";

import { getBot } from "../actions/botActions";

import {
  cerrarSesion,
  UTCFormatter,
  isEmpty,
} from "../../utils/commonFunction";
import { find, propEq } from "ramda";
import qs from "qs";
import moment from "moment";

import { QueryBuilder, QueryRenderer } from "@cubejs-client/react";
import cubejs from "@cubejs-client/core";

import CubejsFilter from "../../pages/analytics/infoViz/cubejs/cubejsQueryRenderData";
//import { Pagination } from "antd";

const checkStatusComponent = (components = [{ name: "" }], nameComponent) => {
  let component = components.filter((item) => item.name == nameComponent);
  return component.length == 0 ? false : true;
};

/**
 * ACTIONS BOTS
 */
export const getAllBotsbyCompany = () => async (
  dispatch,
  getState
) => {
  const { searcher, selectedCompany } = getState().analyticsReducer;
  //const { components } = getState().componentMountReducer;
  const {
    authorization: { access_token } = { access_token },
  } = getState().loginReducer;
  let params = "" + selectedCompany;
  if (searcher) {
    params = params + "&s=" + searcher;
    dispatch({
      type: ANALYTICS_ACTIONS_TYPES.handleClearSearcher(),
    });
  }
  const url = `${HOST_API}/api/bot/list_names/${params}`;
  console.log('FIRST ENDPOINT URL ',url)
  // Pone el ícono de carga

  await getHandlerGET(url, access_token)
    .then((res) => {
      if (res.status == 200) {
        // if (checkStatusComponent(components , nameComponent)){
        if (res.data.msg === "No objects founded") {
          dispatch({
            type: ANALYTICS_ACTIONS_TYPES.notFoundGetListBotsbyCompany(),
            payload: "¡No se encontraron bots!",
          });
        }
        if (res.data) {
          const listbotsbycompany = res.data.data;
          dispatch({
            type: ANALYTICS_ACTIONS_TYPES.successGetListBotsbyCompany(),
            payload: listbotsbycompany,
          });
          if (listbotsbycompany.length > 0) {
            dispatch(setSelectedBot(listbotsbycompany[0].id));
          }
          else
          {
             dispatch({
                 type: MODAL_LOAD_ACTIONS_TYPES.hideLoad(),
             });
          }
        }
      }
    })
    .catch((error) => {
      dispatch({
        type: ANALYTICS_ACTIONS_TYPES.errorGetListBotsbyCompany(),
        payload: "¡La lista de bots no pudo ser obtenida!" + error,
      });
      const { response: { status } = { status } } = error;
      if (status && status == 403) {
        logOut();
        cerrarSesion();
      }
    });

  const { error } = getState().analyticsReducer;
  if (error) {
    dispatch({
      type: MODAL_LOAD_ACTIONS_TYPES.showWarning(),
      payload: error,
    });
  }
  //else
};



/**
 * ACTIONS CHATS
 */
export const getAllChats = (query) => async (dispatch, getState) => {
  const { searcher } = getState().analyticsReducer;
  //const { components } = getState().componentMountReducer;
  const {
    authorization: { access_token } = { access_token },
  } = getState().loginReducer;
  let params = "?query=" + query;
  if (searcher) {
    params = params + "&s=" + searcher;
    dispatch({
      type: ANALYTICS_ACTIONS_TYPES.handleClearSearcher(),
    });
  }

  const url = `${HOST_API}/cube/webdatarocks${params}`;

  // Pone el ícono de carga

  await getHandlerGET(url, access_token)
    .then((res) => {
      if (res.status == 200) {
        // if (checkStatusComponent(components , nameComponent)){
        if (res.data.msg === "No objects founded") {
          dispatch({
            type: ANALYTICS_ACTIONS_TYPES.notFoundGetListChats(),
            payload: "¡No se encontraron chats!",
          });
        }
        if (res.data) {
          let listchats = res.data;
          dispatch({
            type: ANALYTICS_ACTIONS_TYPES.successGetListChats(),
            payload: listchats,
          });
        }
        // dispatch({
        //     type: MODAL_LOAD_ACTIONS_TYPES.hideLoad(),
        // });

        //}
      }
    })
    .catch((error) => {
      dispatch({
        type: MODAL_LOAD_ACTIONS_TYPES.setTitle(),
        payload: i18next.t('message79'),
      });
      dispatch({
        type: ANALYTICS_ACTIONS_TYPES.errorGetListChats(),
        payload: i18next.t('message160') + error,
      });
      // dispatch({
      //     type: MODAL_LOAD_ACTIONS_TYPES.hideLoad(),
      // });
      const { response: { status } = { status } } = error;
      if (status && status == 403) {
        logOut();
        cerrarSesion();
      }
    });

  const { error } = getState().analyticsReducer;
  if (error) {
    dispatch({
      type: MODAL_LOAD_ACTIONS_TYPES.showWarning(),
      payload: error,
    });
  }
  //else
};

/**
 * ACTIONS METRICS
 */
export const getMetricsWithoutParent = () => async (dispatch, getState) => {
  const { searcher } = getState().analyticsReducer;
  //const { components } = getState().componentMountReducer;
  const {
    authorization: { access_token } = { access_token },
  } = getState().loginReducer;
  let params = "&parent=null";

  const url = `${METRICS_API}/metric?${params}`;

  // Pone el ícono de carga

  await getHandlerGET(url, access_token)
    .then((res) => {
      if (res.status == 200) {
        // if (checkStatusComponent(components , nameComponent)){
        if (res.data.msg === "No objects founded") {
          dispatch({
            type: ANALYTICS_ACTIONS_TYPES.notFoundGetListMetrics(),
            payload: i18next.t('message112'),
          });
        }
        if (res.data) {
          let listmetric = res.data.map((e) => JSON.stringify(e));
          dispatch({
            type: ANALYTICS_ACTIONS_TYPES.successGetListMeasures(),
            payload: listmetric,
          });
        }
        // dispatch({
        //     type: MODAL_LOAD_ACTIONS_TYPES.hideLoad(),
        // });

        //}
      }
    })
    .catch((error) => {
      dispatch({
        type: MODAL_LOAD_ACTIONS_TYPES.setTitle(),
        payload: i18next.t('message79'),
      });
      dispatch({
        type: ANALYTICS_ACTIONS_TYPES.errorGetListMetrics(),
        payload: i18next.t('message113'),
      });

      const { response: { status } = { status } } = error;
      if (status && status == 403) {
        logOut();
        cerrarSesion();
      }
    });

  const { error } = getState().analyticsReducer;
  if (error) {
    dispatch({
      type: MODAL_LOAD_ACTIONS_TYPES.showWarning(),
      payload: error,
    });
  }
  //else
};

export const getAllMetrics = (page) => async (dispatch, getState) => {
  const { searcher, selectedCompany } = getState().analyticsReducer;
  //const { components } = getState().componentMountReducer;
  const {
    authorization: { access_token } = { access_token },
  } = getState().loginReducer;
  // let params = "&p="+page;
  let params = "?";
  if (selectedCompany)
    params = params + "company=" + selectedCompany + "&public=true"; //OJO SOLO ESTA TRAYENDO LAS DE LA EMPRESA
  if (searcher) {
    params = params + "&s=" + searcher;
    dispatch({
      type: ANALYTICS_ACTIONS_TYPES.handleClearSearcher(),
    });
  }
  const url = `${METRICS_API}/metric/${params}`;

  // Pone el ícono de carga

  await getHandlerGET(url, access_token)
    .then((res) => {
      if (res.status == 200) {
        // if (checkStatusComponent(components , nameComponent)){
        if (res.data.msg === "No objects founded") {
          dispatch({
            type: ANALYTICS_ACTIONS_TYPES.notFoundGetListMetrics(),
            payload: i18next.t('message112'),
          });
        }
        if (res.data.length > 0) {
          let listmetric = res.data;
          dispatch({
            type: ANALYTICS_ACTIONS_TYPES.successGetListMetrics(),
            payload: listmetric,
          });

          dispatch({
            type: ANALYTICS_ACTIONS_TYPES.selectedMetricChange(),
            payload: res.data[0],
          });

          //   dispatch(countDimensions(res.data[0].id)); OJO REVISAR SI SE REQUIERE

          //   dispatch(countMeasures(res.data[0].id)); OJO REVISAR SI SE REQUIERE

          //   dispatch(countTimeDimensions(res.data[0].id)); OJO REVISAR SI SE REQUIERE
        }
      }
    })
    .catch((error) => {
      dispatch({
        type: MODAL_LOAD_ACTIONS_TYPES.setTitle(),
        payload: i18next.t('message79'),
      });
      dispatch({
        type: ANALYTICS_ACTIONS_TYPES.errorGetListMetrics(),
        payload: i18next.t('message114') + error,
      });
      const { response: { status } = { status } } = error;
      if (status && status == 403) {
        logOut();
        cerrarSesion();
      }
    });

  const { error } = getState().analyticsReducer;
  if (error) {
    dispatch({
      type: MODAL_LOAD_ACTIONS_TYPES.showWarning(),
      payload: error,
    });
  }
  //else
};

export const getMetricbyId = (idMeasure) => async (dispatch, getState) => {
  const {
    authorization: { access_token } = { access_token },
  } = getState().loginReducer;

  const url = `${METRICS_API}/metric/` + idMeasure; //?${params}`;

  // Pone el ícono de carga

  await getHandlerGET(url, access_token)
    .then((res) => {
      if (res.status == 200) {
        // if (checkStatusComponent(components , nameComponent)){

        if (res.data.msg === "No objects founded") {
          dispatch({
            type: ANALYTICS_ACTIONS_TYPES.notFoundGetListMetrics(),
            payload: i18next.t('message112'),
          });
        }
        if (res.data) {
          //let listmetric = res.data.map(e =>JSON.stringify(e))

          dispatch({
            type: ANALYTICS_ACTIONS_TYPES.selectedMetricChange(),
            payload: res.data,
          });
        }
      }
    })
    .catch((error) => {
      dispatch({
        type: MODAL_LOAD_ACTIONS_TYPES.setTitle(),
        payload: i18next.t('message79'),
      });
      dispatch({
        type: ANALYTICS_ACTIONS_TYPES.errorGetListMetrics(),
        payload: i18next.t('message115') + error,
      });
      const { response: { status } = { status } } = error;
      if (status && status == 403) {
        logOut();
        cerrarSesion();
      }
    });

  const { error } = getState().analyticsReducer;
  if (error) {
    dispatch({
      type: MODAL_LOAD_ACTIONS_TYPES.showWarning(),
      payload: error,
    });
  }
  // else
};

export const saveNewMetric = (
  event,
  listSelectedMeasures,
  RedirectonSuccess
) => async (dispatch, getState) => {
  event.preventDefault();
  // await dispatch({
  //     type: MODAL_LOAD_ACTIONS_TYPES.loading()
  // });
  const { selectedCompany } = getState().analyticsReducer;

  const url = `${METRICS_API}/metric/`;
  const form = new FormData(event.target);

  var type_indicator = form.get("metricType");
  if (type_indicator == "operacional") type_indicator = "OPE";
  else type_indicator = "FUN";

  var type_metric = form.get("metricTypeIndi");
  if (type_metric == "medida") {
    type_metric = "MEA";
    //Guardar dimensiones
  } else {
    type_metric = "MET";

    var sons = listSelectedMeasures;
    const dataSon = {
      parent: "",
    };

    sons.map((item, index) => {
      // await getHandlerPUT(METRICS_API +'/metric/'+item,dataSon)
      // .then((res)=>{
      //     if(res.status==200||res.status==204){
      //     }
      // }).catch((error) => {
      //     dispatch({
      //         type: ANALYTICS_ACTIONS_TYPES.errorGetListMetrics(),
      //         payload: '¡Las metricas hijas no pudieron ser actualizadas!'
      //     });
      //     dispatch({
      //         type: MODAL_LOAD_ACTIONS_TYPES.hideLoad(),
      //     });
      //     const { response: { status } = { status } } = error;
      //     if (status  && status == 403){
      //         logOut();
      //         cerrarSesion();
      //     }
      // });
    });

    //Debe actualizar la columna parent de las métricas hijo
  }

  var unit_id = form.get("metricUnity");

  const data = {
    name: form.get("metricName"),
    short_name: form.get("metricShortName"),
    type_metric: type_metric,
    type_indicator: type_indicator,
    description: form.get("metricDescription"),
    operation:
      form.get("metricDimension") +
      " " +
      form.get("metricFormula") +
      " " +
      form.get("metricCondition"),
    unit: unit_id,
    childs: [],
    company: [selectedCompany],
    dimension: [1], //PONER DIMENSIONES QUE SON
  };

  await getHandlerPOSTJson(url, data)
    .then((res) => {
      if (res.status == 200) {
        if (res.data) {
          // dispatch({
          //     type: MODAL_LOAD_ACTIONS_TYPES.hideLoad(),
          // });
          dispatch({
            type: MODAL_LOAD_ACTIONS_TYPES.showSuccess(),
            payload: i18next.t('message116'),
          });

          setTimeout(() => {
            const { activePage } = getState().analyticsReducer;
            dispatch(getAllMetrics(activePage));

            dispatch({
              type: ANALYTICS_ACTIONS_TYPES.toggleModalNewMetric(),
            });

            // RedirectonSuccess(`/adminmetricas/`);
          }, 1200);
        }
      }
    })
    .catch((error) => {
      dispatch({
        type: MODAL_LOAD_ACTIONS_TYPES.setTitle(),
        payload: i18next.t('message79'),
      });
      dispatch({
        type: MODAL_LOAD_ACTIONS_TYPES.showError(),
        payload: i18next.t('message117') + "\n" + error,
      });
      const { response: { status } = { status } } = error;
      if (status && status == 403) {
        logOut();
        cerrarSesion();
      }
    });
};

export const updateInfoMetric = (id, event, RedirectonSuccess) => async (
  dispatch,
  getState
) => {
  event.preventDefault();
  const { selectedCompany } = getState().analyticsReducer;

  // Pone el ícono de carga

  const form = new FormData(event.target);

  var type_indicator = form.get("metricType");
  if (type_indicator == "operacional") type_indicator = "OPE";
  else type_indicator = "FUN";

  var type_metric = form.get("metricTypeIndi");
  if (type_metric == "medida") {
    type_metric = "MEA";
    //Guardar dimensiones
  } else {
    type_metric = "MET";
    //Debe actualizar la columna parent de las métricas hijo
  }

  var unit_id = form.get("metricUnity");

  const data = {
    id: id,
    name: form.get("metricName"),
    short_name: form.get("metricShortName"),
    type_metric: type_metric,
    type_indicator: type_indicator,
    description: form.get("metricDescription"),
    operation:
      form.get("metricDimension") +
      " " +
      form.get("metricFormula") +
      " " +
      form.get("metricCondition"),
    unit: unit_id,
    parent: null,
    //dimension:[]
    company: [selectedCompany],
  };

  await getHandlerPUTJson(METRICS_API + "/metric/" + id, data)
    .then((res) => {
      if (res.status == 200 || res.status == 204) {
        dispatch({
          type: MODAL_LOAD_ACTIONS_TYPES.showSuccess(),
          payload: i18next.t('message118'),
        });
        setTimeout(() => {
          const { activePage } = getState().analyticsReducer;
          dispatch(getAllMetrics(activePage));

          dispatch({
            type: ANALYTICS_ACTIONS_TYPES.toggleModalNewMetric(),
          });
        }, 1200);
      }
      // dispatch({
      //     type: MODAL_LOAD_ACTIONS_TYPES.hideLoad(),
      // });
    })
    .catch((error) => {
      alert(error);
      console.log(error);
      //  dispatch({
      //      type: MODAL_LOAD_ACTIONS_TYPES.hideLoad()
      //  })
      setTimeout(() => {
        dispatch({
          type: MODAL_LOAD_ACTIONS_TYPES.setTitle(),
          payload: i18next.t('message79'),
        });
        dispatch({
          type: ANALYTICS_ACTIONS_TYPES.errorUpdateMetric(),
          payload: i18next.t('message119') + "\n" + error,
        });
      }, 1200);
      if (error.response) {
        if (error.response.status == 403) {
          logOut();
          cerrarSesion();
        }
      }
    });

  const { error } = getState().analyticsReducer;
  if (error) {
    dispatch({
      type: MODAL_LOAD_ACTIONS_TYPES.showWarning(),
      payload: error,
    });
  }
  // else
};
export const deleteMetric = (id) => async (dispatch, getState) => {
  if (confirm(" ¿ " + i18next.t('message77') + " ? ")) {
    // Pone el ícono de carga

    var data = id;

    await getHandlerDELETE(METRICS_API + "/metric/" + data, data)
      .then((res) => {
        if (res.status == 200 || res.status == 204) {
          const { activePage } = getState().analyticsReducer;

          // dispatch({
          //     type: MODAL_LOAD_ACTIONS_TYPES.hideLoad()
          // })
          dispatch({
            type: ANALYTICS_ACTIONS_TYPES.successDeleteMetric(),
            payload: i18next.t('message75'),
          });
          setTimeout(() => {
            dispatch(getAllMetrics(activePage));
          }, 1200);
        }
      })
      .catch((error) => {
        dispatch({
          type: MODAL_LOAD_ACTIONS_TYPES.setTitle(),
          payload: i18next.t('message79'),
        });
        dispatch({
          type: ANALYTICS_ACTIONS_TYPES.errorDeleteMetric(),
          payload: i18next.t('message78') + "\n" + error,
        });
        if (error.response) {
          if (error.response.status == 403) {
            logOut();
            cerrarSesion();
          }
        }
      });
  }

  const { error } = getState().analyticsReducer;
  if (error) {
    dispatch({
      type: MODAL_LOAD_ACTIONS_TYPES.showWarning(),
      payload: error,
    });
  }
  // else
};

export const toggleModalNewMetric = () => (dispatch) => {
  dispatch({
    type: ANALYTICS_ACTIONS_TYPES.toggleModalNewMetric(),
  });
};

export const toggleModalViewMetric = () => (dispatch) => {
  dispatch({
    type: ANALYTICS_ACTIONS_TYPES.toggleModalViewMetric(),
  });
};

export const selectedViewMetric = (metric) => (dispatch) => {
  dispatch({
    type: ANALYTICS_ACTIONS_TYPES.selectedMetricChange(),
    payload: metric,
  });
  dispatch({
    type: ANALYTICS_ACTIONS_TYPES.toggleModalViewMetric(),
  });
};
export const selectedUpdateMetric = (metric) => (dispatch) => {
  dispatch({
    type: ANALYTICS_ACTIONS_TYPES.selectedUpdateMetric(),
    payload: metric,
    updateMetric: true,
  });
  dispatch({
    type: ANALYTICS_ACTIONS_TYPES.toggleModalNewMetric(),
  });
};

export const selectedCreateMetric = () => (dispatch) => {
  dispatch({
    type: ANALYTICS_ACTIONS_TYPES.selectedCreateMetrica(),
  });
  dispatch({
    type: ANALYTICS_ACTIONS_TYPES.toggleModalNewMetric(),
  });
};
/**
 * ACTIONS CHARTS
 */

export const getAllCharts = (page) => async (dispatch, getState) => {
  const { searcher, selectedCompany } = getState().analyticsReducer;
  //const { components } = getState().componentMountReducer;
  const {
    authorization: { access_token } = { access_token },
  } = getState().loginReducer;
  let params = "?";
  if (selectedCompany)
    params = params + "company=" + selectedCompany + "&public=true"; //OJO SOLO ESTA TRAYENDO LAS DE LA EMPRESA
  if (searcher) {
    params = params + "&s=" + searcher;
    dispatch({
      type: ANALYTICS_ACTIONS_TYPES.handleClearSearcher(),
    });
  }
  const url = `${METRICS_API}/chart/${params}`;

  // Pone el ícono de carga

  await getHandlerGET(url, access_token)
    .then((res) => {
      if (res.status == 200) {
        // if (checkStatusComponent(components , nameComponent)){
        if (res.data.msg === "No objects founded") {
          dispatch({
            type: ANALYTICS_ACTIONS_TYPES.notFoundGetListCharts(),
            payload: i18next.t('message80'),
          });
        }
        if (res.data) {
          let listCharts = res.data.map((e) => JSON.stringify(e));
          dispatch({
            type: ANALYTICS_ACTIONS_TYPES.successGetListCharts(),
            payload: listCharts,
          });
        }
      }
    })
    .catch((error) => {

      dispatch({
        type: MODAL_LOAD_ACTIONS_TYPES.setTitle(),
        payload: i18next.t('message79'),
      });

      dispatch({
        type: ANALYTICS_ACTIONS_TYPES.errorGetListCharts(),
        payload: i18next.t('message81') + "\n" + error,
      });

      const { response: { status } = { status } } = error;
      if (status && status == 403) {
        logOut();
        cerrarSesion();
      }
    });

  const { error } = getState().analyticsReducer;
  if (error) {
    dispatch({
      type: MODAL_LOAD_ACTIONS_TYPES.showWarning(),
      payload: error,
    });
  }
  // else
};

export const saveNewChart = (event, RedirectonSuccess) => async (
  dispatch,
  getState
) => {
  event.preventDefault();
  const { type_chart, selectedCompany } = getState().analyticsReducer;
  const {
    authorization: { access_token } = { access_token },
  } = getState().loginReducer;

  const url = `${METRICS_API}/chart/`;
  const form = new FormData(event.target);

  var type_chart_id = parseInt(form.get("typechart"), 10);
  var color = parseInt(form.get("palettes"), 10);

  var name = form.get("name");

  const data = {
    metric: form.get("metrics"),
    nameDiV:
      "chart_" +
      form.get("metrics") +
      "_" +
      form.get("typechart") +
      "_" +
      form.get("palettes"),
    type_chart: type_chart_id,
    color: color,
    icon: "",
    company: [selectedCompany],
    name: name, //OJO DEBE CREARSE EN BACKEND
  };

  if (!type_chart_id || !color) {
    dispatch({
      type: MODAL_LOAD_ACTIONS_TYPES.showWarning(),
      payload:
        i18next.t('message82'),
    });
  } else {
    // Pone el ícono de carga

    await getHandlerPOSTJson(url, data)
      .then((res) => {
        if (res.status >= 200 || res.status <= 204) {
          if (res.data) {
            // dispatch({
            //     type: MODAL_LOAD_ACTIONS_TYPES.hideLoad(),
            // });
            dispatch({
              type: MODAL_LOAD_ACTIONS_TYPES.showSuccess(),
              payload: i18next.t('message83'),
            });

            setTimeout(() => {
              const { activePage } = getState().analyticsReducer;
              dispatch(getAllCharts(activePage));

              dispatch({
                type: ANALYTICS_ACTIONS_TYPES.toggleModalNewChart(),
              });

              // RedirectonSuccess(`/adminmetricas/`);
            }, 1200);
          }
        }
      })
      .catch((error) => {
        dispatch({
          type: MODAL_LOAD_ACTIONS_TYPES.setTitle(),
          payload: i18next.t('message79'),
        });
        dispatch({
          type: MODAL_LOAD_ACTIONS_TYPES.showWarning(),
          payload: i18next.t('message84') + "\n" + error,
        });
        const { response: { status } = { status } } = error;
        if (status && status == 403) {
          logOut();
          cerrarSesion();
        }
      });
  }
};

export const updateInfoChart = (id, event, RedirectonSuccess) => async (
  dispatch,
  getState
) => {
  event.preventDefault();
  const { selectedCompany } = getState().analyticsReducer;

  const form = new FormData(event.target);

  var type_chart_id = parseInt(form.get("typechart"), 10);
  var color = parseInt(form.get("palettes"), 10);

  const data = {
    metric: form.get("metrics"),
    nameDiV:
      "chart_" +
      form.get("metrics") +
      "_" +
      form.get("typechart") +
      "_" +
      form.get("palettes"),
    type_chart: type_chart_id,
    color: color,
    icon: "",
    company: [selectedCompany],
  };
  // Pone el ícono de carga

  await getHandlerPUTJson(METRICS_API + "/chart/" + id, data)
    .then((res) => {
      if (res.status == 200 || res.status == 204) {
        dispatch({
          type: MODAL_LOAD_ACTIONS_TYPES.showSuccess(),
          payload: i18next.t('message85'),
        });
        setTimeout(() => {
          const { activePage } = getState().analyticsReducer;
          dispatch(getAllCharts(activePage));

          dispatch({
            type: ANALYTICS_ACTIONS_TYPES.toggleModalNewChart(),
          });
        }, 1200);
      }
    })
    .catch((error) => {
      setTimeout(() => {
        dispatch({
          type: MODAL_LOAD_ACTIONS_TYPES.setTitle(),
          payload: i18next.t('message79'),
        });
        dispatch({
          type: ANALYTICS_ACTIONS_TYPES.errorUpdateChart(),
          payload: i18next.t('message86') + "\n" + error,
        });
      }, 1200);
      if (error.response) {
        if (error.response.status == 403) {
          logOut();
          cerrarSesion();
        }
      }
    });

  const { error } = getState().analyticsReducer;
  if (error) {
    dispatch({
      type: MODAL_LOAD_ACTIONS_TYPES.showWarning(),
      payload: error,
    });
  }
  //  else
};
export const deleteChart = (id) => async (dispatch, getState) => {
  if (confirm(i18next.t('message87'))) {
    // Pone el ícono de carga

    var data = id;

    await getHandlerDELETE(METRICS_API + "/chart/" + data, data)
      .then((res) => {
        if (res.status == 200 || res.status == 204) {
          const { activePage } = getState().analyticsReducer;

          // dispatch({
          //     type: MODAL_LOAD_ACTIONS_TYPES.hideLoad()
          // })
          dispatch({
            type: ANALYTICS_ACTIONS_TYPES.successDeleteChart(),
            payload: i18next.t('message88'),
          });
          setTimeout(() => {
            dispatch(getAllCharts(activePage));
          }, 1200);
        }
      })
      .catch((error) => {
        dispatch({
          type: MODAL_LOAD_ACTIONS_TYPES.setTitle(),
          payload: i18next.t('message79'),
        });
        dispatch({
          type: ANALYTICS_ACTIONS_TYPES.errorDeleteChart(),
          payload: i18next.t('message89') + "\n" + error,
        });
        if (error.response) {
          if (error.response.status == 403) {
            logOut();
            cerrarSesion();
          }
        }
      });
  }

  const { error } = getState().analyticsReducer;
  if (error) {
    dispatch({
      type: MODAL_LOAD_ACTIONS_TYPES.showWarning(),
      payload: error,
    });
  }
  //    else
};

export const toggleModalNewChart = () => (dispatch) => {
  dispatch({
    type: ANALYTICS_ACTIONS_TYPES.toggleModalNewChart(),
  });
};

export const toggleModalViewChart = () => (dispatch) => {
  dispatch({
    type: ANALYTICS_ACTIONS_TYPES.toggleModalViewChart(),
  });
};

export const selectedViewChart = (chart) => async (dispatch, getState) => {
  const {
    AllPalettes,
    typeCharts,
    listAllMetrics,
  } = getState().analyticsReducer;

  await dispatch(iconLoad());

  if (isEmpty(AllPalettes)) {
    await dispatch(getAllPalettes());
  }

  if (isEmpty(typeCharts)) {
    await dispatch(getAllTypeChart());
  }

  if (!listAllMetrics || isEmpty(listAllMetrics)) {
    await dispatch(getAllMetrics());
  }

  /**
   * Muestra ícono de carga
   */

  await dispatch(countDimensions(chart.metric.id));
  await dispatch(countMeasures(chart.metric.id));
  await dispatch(countTimeDimensions(chart.metric.id));

  await dispatch(getListSelectedTypeCharts());

  await dispatch(
    getPreview(
      chart.type_chart.id,
      chart.color.id,
      chart.color.palette_type,
      "preview",
      chart.metric,
      chart.icon
    )
  );

  await dispatch({
    type: ANALYTICS_ACTIONS_TYPES.selectedViewChart(),
    payload: chart,
    viewChart: true,
  });

  await dispatch({
    type: ANALYTICS_ACTIONS_TYPES.toggleModalNewChart(),
  });

  /**
   * Oculta ícono de carga
   */
  await dispatch(iconHideLoad());
};
export const selectedUpdateChart = (chart) => async (dispatch) => {
  // Pone el ícono de carga
  //

  await dispatch(countDimensions(chart.metric.id));
  await dispatch(countMeasures(chart.metric.id));
  await dispatch(countTimeDimensions(chart.metric.id));

  await dispatch(getListSelectedTypeCharts());

  await dispatch(
    getPreview(
      chart.type_chart.id,
      chart.color.id,
      chart.color.palette_type,
      "preview",
      chart.metric,
      chart.icon
    )
  );

  await dispatch({
    type: ANALYTICS_ACTIONS_TYPES.selectedUpdateChart(),
    payload: chart,
    updateChart: true,
    viewChart: false,
  });
  await dispatch({
    type: ANALYTICS_ACTIONS_TYPES.toggleModalNewChart(),
  });
};

export const selectedCreateChart = () => async (dispatch, getState) => {
  const { listAllMetrics } = getState().analyticsReducer;

  if (listAllMetrics.length > 0) {
    var allMetric = listAllMetrics[0];

    // Pone el ícono de carga
    //

    await dispatch(countDimensions(allMetric.id));
    await dispatch(countMeasures(allMetric.id));
    await dispatch(countTimeDimensions(allMetric.id));
    await dispatch(getListSelectedTypeCharts());

    await dispatch({
      type: ANALYTICS_ACTIONS_TYPES.selectedCreateChartR(),
      payload: allMetric,
    });
  }
  await dispatch({
    type: ANALYTICS_ACTIONS_TYPES.toggleModalNewChart(),
  });
};
/**
 * ACTIONS DASHBOARDS
 */

export const getAllDashboards = (page) => async (dispatch, getState) => {
  const { searcher, selectedCompany } = getState().analyticsReducer;
  //const { components } = getState().componentMountReducer;
  const {
    authorization: { access_token } = { access_token },
  } = getState().loginReducer;
  let params = "?";
  if (selectedCompany)
    params = params + "company=" + selectedCompany + "&public=true"; //OJO SOLO ESTA TRAYENDO LAS DE LA EMPRESA
  if (searcher) {
    params = params + "&s=" + searcher;
    dispatch({
      type: ANALYTICS_ACTIONS_TYPES.handleClearSearcher(),
    });
  }
  const url = `${METRICS_API}/dashboard/${params}`;

  /**
   * Muestra ícono de carga
   */

  await getHandlerGET(url, access_token)
    .then((res) => {
      if (res.status == 200) {
        // if (checkStatusComponent(components , nameComponent)){
        if (res.data.msg === "No objects founded") {
          dispatch({
            type: ANALYTICS_ACTIONS_TYPES.notFoundGetListDashboards(),
            payload: i18next.t('message97'),
          });
        }
        if (res.data.length > 0) {
          let listDashboard = res.data.map((e) => JSON.stringify(e));

          dispatch({
            type: ANALYTICS_ACTIONS_TYPES.successGetListDashboards(),
            payload: listDashboard,
          });
          const selectDashboard = res.data[0];

          // dispatch(selectedViewDashboard1(selectDashboard));

         // dispatch(getDashboard(null, selectDashboard.id));
        }
      }
    })
    .catch((error) => {
      dispatch({
        type: MODAL_LOAD_ACTIONS_TYPES.setTitle(),
        payload: i18next.t('message79'),
      });
      dispatch({
        type: ANALYTICS_ACTIONS_TYPES.errorGetListDashboards(),
        payload: i18next.t('message90') + "\n" + error,
      });
      const { response: { status } = { status } } = error;
      if (status && status == 403) {
        logOut();
        cerrarSesion();
      }
    });

  const { error } = getState().analyticsReducer;
  if (error) {
    dispatch({
      type: MODAL_LOAD_ACTIONS_TYPES.showWarning(),
      payload: error,
    });
  }
  //  else
};

export const saveNewDashboard = (event, RedirectonSuccess) => async (
  dispatch,
  getState
) => {
  event.preventDefault();
  const {
    type_chart,
    selectedDashboard,
    selectedCompany,
  } = getState().analyticsReducer;
  const {
    authorization: { access_token } = { access_token },
  } = getState().loginReducer;

  // Pone el ícono de carga
  //
  const url = `${METRICS_API}/dashboard/`;

  const form = new FormData(event.target);

  // var c=form.get("chart1");
  var layout = document.getElementsByClassName("react-grid-layout");
  layout = layout[0].childNodes;

  var charts = [];

  for (var j = 0; j < layout.length; j++) {
    var i = layout[j].childNodes[0].getAttribute("i");
    var c = document.getElementById("chart" + i);
    var id = c.getAttribute("i");
    //  id=id.substring(0,id.indexOf("_"));//+"_"+selectedDashboard.id
    var x = c.getAttribute("x");
    var y = c.getAttribute("y");
    var w = c.getAttribute("w");
    var h = c.getAttribute("h");

    charts.push({
      x: Number(x) * 2,
      y: Number(y),
      width: Number(w) * 2,
      heigth: Number(h) * 3,
      chart: parseInt(id),
    });
  }

  var data = {
    name: form.get("dashboardName"),
    description: form.get("dashboardDescription"),
    company: [selectedCompany],
    charts: charts,
  };

  data = JSON.stringify(data);
  // await dispatch({
  //     type: MODAL_LOAD_ACTIONS_TYPES.loading(),
  // });
  await getHandlerPOSTJson(url, data)
    .then((res) => {
      if (res.status >= 200 && res.status <= 204) {
        if (res.data) {
          dispatch({
            type: MODAL_LOAD_ACTIONS_TYPES.showSuccess(),
            payload: i18next.t('message120'),
          });

          setTimeout(() => {
            const { activePage } = getState().analyticsReducer;
            dispatch(getAllDashboards(activePage));

            dispatch({
              type: ANALYTICS_ACTIONS_TYPES.toggleModalNewDashboards(),
            });
            // dispatch({
            //     type: MODAL_LOAD_ACTIONS_TYPES.hideLoad(),
            // });

            // RedirectonSuccess(`/adminmetricas/`);
          }, 1200);
        }
      }
      // dispatch({
      //     type: MODAL_LOAD_ACTIONS_TYPES.hideLoad(),
      // });
    })
    .catch((error) => {
      dispatch({
        type: MODAL_LOAD_ACTIONS_TYPES.setTitle(),
        payload: i18next.t('message79'),
      });
      dispatch({
        type: MODAL_LOAD_ACTIONS_TYPES.showWarning(),
        payload: i18next.t('mesage91') + "!\n" + error,
      });
      const { response: { status } = { status } } = error;
      if (status && status == 403) {
        logOut();
        cerrarSesion();
      }
    });
};

export const updateInfoDashboard = (
  id_dashboard,
  event,
  RedirectonSuccess
) => async (dispatch, getState) => {
  event.preventDefault();

  const { selectedDashboard, selectedCompany } = getState().analyticsReducer;

  const form = new FormData(event.target);

  var layout = document.getElementsByClassName("react-grid-layout");
  layout = layout[0].childNodes;

  var charts = [];

  for (var j = 0; j < layout.length; j++) {
    var i = layout[j].childNodes[0].getAttribute("i");
    var c = document.getElementById("chart" + i);
    var id = c.getAttribute("i");
    id = id.substring(0, id.indexOf("_")); //+"_"+selectedDashboard.id
    var x = c.getAttribute("x");
    var y = c.getAttribute("y");
    var w = c.getAttribute("w");
    var h = c.getAttribute("h");

    charts.push({
      x: Number(x) * 2,
      y: Number(y),
      width: Number(w) * 2,
      heigth: Number(h) * 3,
      chart: parseInt(id),
    });
  }

  var data = {
    name: form.get("dashboardName"),
    description: form.get("dashboardDescription"),
    company: [selectedCompany],
    charts: charts,
  };

  data = JSON.stringify(data);
  // Pone el ícono de carga
  //

  await getHandlerPUTJson(METRICS_API + "/dashboard/" + id_dashboard, data)
    .then((res) => {
      if (res.status >= 200 || res.status <= 204) {
        dispatch({
          type: MODAL_LOAD_ACTIONS_TYPES.showSuccess(),
          payload: i18next.t('message92'),
        });
        setTimeout(() => {
          const { activePage } = getState().analyticsReducer;
          dispatch(getAllDashboards(activePage));

          dispatch({
            type: ANALYTICS_ACTIONS_TYPES.selectedCreateDashboards(),
          });

          // dispatch(cleanAllPreviews());

          dispatch(getDashboard(activePage, selectedDashboard.id));

          dispatch({
            type: ANALYTICS_ACTIONS_TYPES.toggleModalNewDashboards(),
          });

          if (RedirectonSuccess) {
            RedirectonSuccess();
          }
        }, 1200);
      }
      // dispatch({
      //     type: MODAL_LOAD_ACTIONS_TYPES.hideLoad(),
      // });
    })
    .catch((error) => {
      console.log(error);
      //  dispatch({
      //      type: MODAL_LOAD_ACTIONS_TYPES.hideLoad()
      //  })
      setTimeout(() => {
        dispatch({
          type: MODAL_LOAD_ACTIONS_TYPES.setTitle(),
          payload: i18next.t('message79'),
        });
        dispatch({
          type: ANALYTICS_ACTIONS_TYPES.errorUpdateDashboard(),
          payload: i18next.t('message93') +"\n" + error,
        });
      }, 1200);
      if (error.response) {
        if (error.response.status == 403) {
          logOut();
          cerrarSesion();
        }
      }
    });

  const { error } = getState().analyticsReducer;
  if (error) {
    alert(error);
    dispatch({
      type: MODAL_LOAD_ACTIONS_TYPES.showWarning(),
      payload: error,
    });
  }
};
export const deleteDashboard = (id) => async (dispatch, getState) => {
  if (confirm(i18next.t('message94'))) {
    // Pone el ícono de carga
    //

    var data = id;

    await getHandlerDELETE(METRICS_API + "/dashboard/" + data, data)
      .then((res) => {
        if (res.status == 200 || res.status == 204) {
          const { activePage } = getState().analyticsReducer;

          // dispatch({
          //     type: MODAL_LOAD_ACTIONS_TYPES.hideLoad()
          // })
          dispatch({
            type: ANALYTICS_ACTIONS_TYPES.successDeleteDashboard(),
            payload: i18next.t('message95'),
          });
          setTimeout(() => {
            dispatch(getAllDashboards(activePage));
          }, 1200);
        }
      })
      .catch((error) => {
        dispatch({
          type: MODAL_LOAD_ACTIONS_TYPES.setTitle(),
          payload: i18next.t('message79'),
        });

        dispatch({
          type: ANALYTICS_ACTIONS_TYPES.errorDeleteDashboard(),
          payload: i18next.t('message96') + "\n" + error,
        });
        if (error.response) {
          if (error.response.status == 403) {
            logOut();
            cerrarSesion();
          }
        }
      });
  }

  const { error } = getState().analyticsReducer;
  if (error) {
    dispatch({
      type: MODAL_LOAD_ACTIONS_TYPES.showWarning(),
      payload: error,
    });
  }
};

export const getDashboard = (page, id) => async (dispatch, getState) => {
  const { searcher, selectedDashboard } = getState().analyticsReducer;

  if(selectedDashboard.id!=id)
  {
    //const { components } = getState().componentMountReducer;
  const {
    authorization: { access_token } = { access_token },
  } = getState().loginReducer;
  let params = "?";

  params = params + "public=true"; //OJO SOLO ESTA TRAYENDO LAS DE LA EMPRESA

  if (searcher) {
    params = params + "&s=" + searcher;
    dispatch({
      type: ANALYTICS_ACTIONS_TYPES.handleClearSearcher(),
    });
  }
  const url = `${METRICS_API}/dashboard/` + id + `${params}`;

  /**
   * Muestra ícono de carga
   */

  await getHandlerGET(url, access_token)
    .then((res) => {
      if (res.status == 200) {
        // if (checkStatusComponent(components , nameComponent)){
        if (res.data.msg === "No objects founded") {
          dispatch({
            type: ANALYTICS_ACTIONS_TYPES.notFoundGetListDashboards(),
            payload: i18next.t('message97'),
          });
        }
        if (res.data) {
          let listCharts = res.data;
          dispatch(selectedViewDashboard1(listCharts));
        }
      }
    })
    .catch((error) => {
      dispatch({
        type: MODAL_LOAD_ACTIONS_TYPES.setTitle(),
        payload: i18next.t('message79'),
      });
      dispatch({
        type: ANALYTICS_ACTIONS_TYPES.errorGetListDashboards(),
        payload: i18next.t('message98') + "\n" + error,
      });
      const { response: { status } = { status } } = error;
      if (status && status == 403) {
        logOut();
        cerrarSesion();
      }
    });

  const { error } = getState().analyticsReducer;
  if (error) {
    dispatch({
      type: MODAL_LOAD_ACTIONS_TYPES.showWarning(),
      payload: error,
    });
  }

  }
};
export const toggleModalNewDashboard = () => async (dispatch) => {
  await dispatch(cleanAllPreviews());

  await dispatch({
    type: ANALYTICS_ACTIONS_TYPES.toggleModalNewDashboards(),
  });
};

export const selectedViewDashboard1 = (dashboard, success) => async (
  dispatch
) => {
  await dispatch({
    type: ANALYTICS_ACTIONS_TYPES.selectedViewDashboard(),
    payload: dashboard,
  });
};
export const selectedViewDashboardModal = (dashboard, success) => async (
  dispatch,
  getState
) => {
  const {
    AllPalettes,
    typeCharts,
    listAllMetrics,
  } = getState().analyticsReducer;

  await dispatch(iconLoad());

  if (isEmpty(AllPalettes)) {
    await dispatch(getAllPalettes());
  }

  if (isEmpty(typeCharts)) {
    await dispatch(getAllTypeChart());
  }

  if (!listAllMetrics || isEmpty(listAllMetrics)) {
    await dispatch(getAllMetrics());
  }

  await dispatch(cleanAllPreviews());

  await dispatch({
    type: ANALYTICS_ACTIONS_TYPES.selectedViewDashboardModal(),
    payload: dashboard,
  });

  await dispatch({
    type: ANALYTICS_ACTIONS_TYPES.toggleModalNewDashboards(),
  });
  //  if(success){
  //      success();
  //  }
};

export const selectedUpdateDashboard = (dashboard) => (dispatch) => {
  dispatch({
    type: ANALYTICS_ACTIONS_TYPES.selectedUpdateDashboard(),
    payload: dashboard,
  });
  dispatch({
    type: ANALYTICS_ACTIONS_TYPES.toggleModalNewDashboards(),
  });
};

export const selectedCreateDashboard = () => (dispatch) => {
  dispatch({
    type: ANALYTICS_ACTIONS_TYPES.selectedCreateDashboards(),
    selectedDashboard: [],
  });
  dispatch({
    type: ANALYTICS_ACTIONS_TYPES.toggleModalNewDashboards(),
  });
};
/**
 * ACTIONS UNITS
 */
export const getTotalUnits = () => async (dispatch, getState) => {
  // get stateAnotherReducer
  // const { components } = getState().componentMountReducer;
  const { searcher = "" } = getState().analyticsReducer;
  const {
    authorization: { access_token } = { access_token },
  } = getState().loginReducer;

  const parameters = searcher ? `&s=${searcher}` : "";
  const url = HOST_API + "/api/unit/total_units/?" + parameters;

  // Getting total Units
  await getHandlerGET(url, access_token)
    .then((res) => {
      if (res.status == 200 && res.data.total_units) {
        //if (checkStatusComponent(components , nameComponent)){
        dispatch({
          type: ANALYTICS_ACTIONS_TYPES.successGetTotalUnits(),
          payload: res.data.total_units,
        });
        //}
      } else {
        dispatch({
          type: MODAL_LOAD_ACTIONS_TYPES.showWarning(),
          payload: i18next.t('message99'),
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: MODAL_LOAD_ACTIONS_TYPES.setTitle(),
        payload: i18next.t('message79'),
      });
      dispatch({
        type: MODAL_LOAD_ACTIONS_TYPES.showWarning(),
        payload: i18next.t('message100') + "\n" + error,
      });

      const { response: { status } = { status } } = error;
      if (status && status == 403) {
        logOut();
        cerrarSesion();
      }
    });
};

export const getAllUnits = (page) => async (dispatch, getState) => {
  const { searcher } = getState().analyticsReducer;
  //const { components } = getState().componentMountReducer;
  const {
    authorization: { access_token } = { access_token },
  } = getState().loginReducer;
  let params = "&p=" + page;
  if (searcher) {
    params = params + "&s=" + searcher;
    dispatch({
      type: ANALYTICS_ACTIONS_TYPES.handleClearSearcher(),
    });
  }
  const url = `${METRICS_API}/unity/`; //?${params}`;

  await getHandlerGET(url, access_token)
    .then((res) => {
      if (res.status == 200) {
        // if (checkStatusComponent(components , nameComponent)){
        if (res.data.msg === "No objects founded") {
          dispatch({
            type: ANALYTICS_ACTIONS_TYPES.notFoundGetListUnits(),
            payload: i18next.t('message101'),
          });
        }
        if (res.data) {
          let listunity = res.data.map((e) => JSON.stringify(e));
          dispatch({
            type: ANALYTICS_ACTIONS_TYPES.successGetListUnits(),
            payload: listunity,
          });
        }
      }
    })
    .catch((error) => {
      dispatch({
        type: MODAL_LOAD_ACTIONS_TYPES.setTitle(),
        payload: i18next.t('message79'),
      });
      dispatch({
        type: ANALYTICS_ACTIONS_TYPES.errorGetListUnits(),
        payload: i18next.t('message102') + "\n" + error,
      });

      const { response: { status } = { status } } = error;
      if (status && status == 403) {
        logOut();
        cerrarSesion();
      }
    });

  const { error } = getState().analyticsReducer;
  if (error) {
    dispatch({
      type: MODAL_LOAD_ACTIONS_TYPES.showWarning(),
      payload: error,
    });
  }
};

/**
 * ACTIONS DIMENSIONS
 */
export const getAllDimensions = (page) => async (dispatch, getState) => {
  const { searcher } = getState().analyticsReducer;
  //const { components } = getState().componentMountReducer;
  const {
    authorization: { access_token } = { access_token },
  } = getState().loginReducer;
  let params = "&p=" + page;
  if (searcher) {
    params = params + "&s=" + searcher;
    dispatch({
      type: ANALYTICS_ACTIONS_TYPES.handleClearSearcher(),
    });
  }
  const url = `${METRICS_API}/dimension/`; //?${params}`;

  // Loading getting list dashboard
  // await dispatch({
  //     type: MODAL_LOAD_ACTIONS_TYPES.loading(),
  // });
  await getHandlerGET(url, access_token)
    .then((res) => {
      if (res.status == 200) {
        // if (checkStatusComponent(components , nameComponent)){
        if (res.data.msg === "No objects founded") {
          dispatch({
            type: ANALYTICS_ACTIONS_TYPES.notFoundGetListDimensions(),
            payload: i18next.t('message103'),
          });
        }
        if (res.data) {
          let listdimension = res.data.map((e) => JSON.stringify(e));
          dispatch({
            type: ANALYTICS_ACTIONS_TYPES.successGetListDimensions(),
            payload: listdimension,
          });
        }
        // dispatch({
        //     type: MODAL_LOAD_ACTIONS_TYPES.hideLoad(),
        // });

        //}
      }
    })
    .catch((error) => {
      dispatch({
        type: MODAL_LOAD_ACTIONS_TYPES.setTitle(),
        payload: i18next.t('message79'),
      });
      dispatch({
        type: ANALYTICS_ACTIONS_TYPES.errorGetListDimensions(),
        payload: i18next.t('message104') + "\n" + error,
      });
      // dispatch({
      //     type: MODAL_LOAD_ACTIONS_TYPES.hideLoad(),
      // });
      const { response: { status } = { status } } = error;
      if (status && status == 403) {
        logOut();
        cerrarSesion();
      }
    });

  const { error } = getState().analyticsReducer;
  if (error) {
    dispatch({
      type: MODAL_LOAD_ACTIONS_TYPES.showWarning(),
      payload: error,
    });
  }
};

/**
 * ACTIONS TYPECHARTS
 */

export const getAllTypeChart = (page) => async (dispatch, getState) => {
  const { searcher } = getState().analyticsReducer;
  //const { components } = getState().componentMountReducer;
  const {
    authorization: { access_token } = { access_token },
  } = getState().loginReducer;
  let params = "&p=" + page;
  if (searcher) {
    params = params + "&s=" + searcher;
    dispatch({
      type: ANALYTICS_ACTIONS_TYPES.handleClearSearcher(),
    });
  }
  const url = `${METRICS_API}/chart_type/`; //?${params}`;

  // Loading getting list TypeChart

  await getHandlerGET(url, access_token)
    .then((res) => {
      if (res.status == 200) {
        // if (checkStatusComponent(components , nameComponent)){
        if (res.data.msg === "No objects founded") {
          dispatch({
            type: ANALYTICS_ACTIONS_TYPES.notFoundGetListTypeCharts(),
            payload: i18next.t('message105'),
          });
        }
        if (res.data) {
          let listtypechart = res.data.map((e) => JSON.stringify(e));
          dispatch({
            type: ANALYTICS_ACTIONS_TYPES.successGetListTypeCharts(),
            payload: listtypechart,
          });
        }

        //}
      }
    })
    .catch((error) => {
      dispatch({
        type: MODAL_LOAD_ACTIONS_TYPES.setTitle(),
        payload: i18next.t('message79'),
      });
      dispatch({
        type: ANALYTICS_ACTIONS_TYPES.errorGetListTypeCharts(),
        payload: i18next.t('message106') + "\n" + error,
      });

      const { response: { status } = { status } } = error;
      if (status && status == 403) {
        logOut();
        cerrarSesion();
      }
    });

  const { error } = getState().analyticsReducer;
  if (error) {
    dispatch({
      type: MODAL_LOAD_ACTIONS_TYPES.showWarning(),
      payload: error,
    });
  }
};

export const getTypeChart = (id) => async (dispatch, getState) => {
  const {
    authorization: { access_token } = { access_token },
  } = getState().loginReducer;

  const url = `${METRICS_API}/chart_type/` + id; //?${params}`;

  // Loading getting list TypeChart
  // await dispatch({
  //     type: MODAL_LOAD_ACTIONS_TYPES.loading(),
  // });
  await getHandlerGET(url, access_token)
    .then((res) => {
      if (res.status == 200) {
        // if (checkStatusComponent(components , nameComponent)){
        if (res.data.msg === "No objects founded") {
          dispatch({
            type: ANALYTICS_ACTIONS_TYPES.notFoundGetListTypeCharts(),
            payload: i18next.t('message105'),
          });
        }
        if (res.data) {
          let typechart = res.data.map((e) => JSON.stringify(e));
          dispatch({
            type: ANALYTICS_ACTIONS_TYPES.successGetTypeChart(),
            payload: typechart,
          });
        }
        // dispatch({
        //     type: MODAL_LOAD_ACTIONS_TYPES.hideLoad(),
        // });

        //}
      }
    })
    .catch((error) => {
      dispatch({
        type: MODAL_LOAD_ACTIONS_TYPES.setTitle(),
        payload: i18next.t('message79'),
      });
      dispatch({
        type: ANALYTICS_ACTIONS_TYPES.errorGetListTypeCharts(),
        payload: i18next.t('message107') + "\n" + error,
      });
      // dispatch({
      //     type: MODAL_LOAD_ACTIONS_TYPES.hideLoad(),
      // });
      const { response: { status } = { status } } = error;
      if (status && status == 403) {
        logOut();
        cerrarSesion();
      }
    });

  const { error } = getState().analyticsReducer;
  if (error) {
    dispatch({
      type: MODAL_LOAD_ACTIONS_TYPES.showWarning(),
      payload: error,
    });
  }
};

export const getListSelectedTypeCharts = () => async (dispatch, getState) => {
  const {
    typeCharts,
    numDimensions,
    numMeasures,
    timeDimensions,
  } = getState().analyticsReducer;
  var newTypes = [];

  var numDimension = numDimensions,
    numMeasure = numMeasures,
    timeDimension = timeDimensions;

  for (var i = 0; i < typeCharts.length; i++) {
    var typeChart = JSON.parse(typeCharts[i]);
    for (var j = 0; j < typeChart.conditions.length; j++) {
      if (eval(typeChart.conditions[j].condition)) {
        newTypes.push(typeCharts[i]);
        break;
      }
    }
  }
  // await dispatch({
  //     type: MODAL_LOAD_ACTIONS_TYPES.loading(),
  // });

  await dispatch({
    type: ANALYTICS_ACTIONS_TYPES.getListSelectedTypeChart(),
    payload: newTypes,
  });
  // await dispatch({
  //     type: MODAL_LOAD_ACTIONS_TYPES.hideLoad(),
  // });
};

/**
 * ACTIONS PLAFFORM
 */
export const getAllPlafform = () => async (dispatch, getState) => {
  const url = HOST_API + "/api/platforms/";
  await dispatch({
    type: ANALYTICS_ACTIONS_TYPES.requestGetListPlafforms(),
  });
  // await dispatch({
  //     type: MODAL_LOAD_ACTIONS_TYPES.loading(),
  // });

  await getHandlerGET(url)
    .then((res) => {
      if (res.status == 200) {
        if (res.data.platforms) {
          let platforms = res.data.platforms.map((e) => JSON.stringify(e));
          dispatch({
            type: ANALYTICS_ACTIONS_TYPES.successGetListPlafforms(),
            payload: platforms,
          });
        }
      } else {
        dispatch({
          type: ANALYTICS_ACTIONS_TYPES.errorGetListPlafforms(),
          payload: i18next.t('message108'),
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: MODAL_LOAD_ACTIONS_TYPES.setTitle(),
        payload: i18next.t('message79'),
      });
      dispatch({
        type: ANALYTICS_ACTIONS_TYPES.errorGetListPlafforms(),
        payload: i18next.t('message109') + "\n" + error,
      });
      if (error.response.status == 403) {
        logOut();
        cerrarSesion();
      }
    });

  const { error } = getState().analyticsReducer;
  if (error) {
    dispatch({
      type: MODAL_LOAD_ACTIONS_TYPES.showWarning(),
      payload: error,
    });
  }
};

/**
 * ACTIONS PALETTES
 */
export const getAllPalettes = () => async (dispatch, getState) => {
  const url = METRICS_API + "/palette/";

  /**
   * Muestra ícono de carga
   */

  await dispatch({
    type: ANALYTICS_ACTIONS_TYPES.requestGetListPalettes(),
  });

  await getHandlerGET(url)
    .then((res) => {
      if (res.status == 200) {
        if (res.data) {
          let palettes = res.data.map((e) => JSON.stringify(e));
          dispatch({
            type: ANALYTICS_ACTIONS_TYPES.successGetListPalettes(),
            payload: palettes,
          });
        }
      } else {
        dispatch({
          type: ANALYTICS_ACTIONS_TYPES.errorGetListPalettes(),
          payload: i18next.t('message110'),
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: MODAL_LOAD_ACTIONS_TYPES.setTitle(),
        payload: i18next.t('message79'),
      });
      dispatch({
        type: ANALYTICS_ACTIONS_TYPES.errorGetListPalettes(),
        payload: i18next.t('message111') + "\n",
      });
      if (error.response.status == 403) {
        logOut();
        cerrarSesion();
      }
    });

  const { error } = getState().analyticsReducer;
  if (error) {
    dispatch({
      type: MODAL_LOAD_ACTIONS_TYPES.showWarning(),
      payload: error,
    });
  }
};

/**
 * ACTIONS SEARCH AND PAGINATION
 * @param {*} pageActive
 */
export const changePageActive = (pageActive) => (dispatch) => {
  dispatch({
    type: ANALYTICS_ACTIONS_TYPES.changePageActive(),
    payload: pageActive,
  });
};

export const changeActiveTab = (tabActive) => (dispatch) => {
  dispatch({
    type: ANALYTICS_ACTIONS_TYPES.changeActiveTabMetric(),
    payload: tabActive,
  });
};

export const setDataChart = (dataChart) => (dispatch) => {
  dispatch({
    type: ANALYTICS_ACTIONS_TYPES.setDataChart(),
    payload: dataChart,
  });
};

export const cleanDataChart = () => (dispatch) => {
  dispatch({
    type: ANALYTICS_ACTIONS_TYPES.cleanDataChart(),
    payload: [],
  });
};

export const updateReportType = (type) => (dispatch) => {
  dispatch({
    type: ANALYTICS_ACTIONS_TYPES.setReportType(),
    payload: type,
  });
};

export const handleSearching = (query) => (dispatch) => {
  
  console.log("handleSearching ",query);

  let searchOld = "";


  if (query && query.target && query.target.value) {
    searchOld = query.target.value;
  } else {
    searchOld = query;
  }
  
  console.log("searchOld ",searchOld);
  dispatch({
    type: ANALYTICS_ACTIONS_TYPES.handleSearching(),
    payload: searchOld,
  });

};

export const changeBetweenList = (selected) => (dispatch) => {
  dispatch({
    type: ANALYTICS_ACTIONS_TYPES.changeBetweenList(),
    payload: selected,
  });
};

export const getPreview = (
  typechart,
  colorID,
  typepalette,
  nameDiv,
  metric,
  icon
) => async (dispatch, getState) => {
  const { AllPalettes } = getState().analyticsReducer;

  var preview = [],
    color = [];

  //---------------------------------------------------------------------
  // HACE LA CONSULTA A LA BD Y CREA LA GRÁFICA
  //---------------------------------------------------------------------
  function getDataChart() {
    try {
      return (
        <CubejsFilter
          typechart={typechart}
          nameDiv={nameDiv}
          color={color}
          typepalette={typepalette}
          metric={metric}
          icon={icon}
        ></CubejsFilter>
      );
    } catch (error) {
      dispatch({
        type: MODAL_LOAD_ACTIONS_TYPES.setTitle(),
        payload: i18next.t('message79'),
      });

      const { response: { status } = { status } } = error;
      if (status && status == 403) {
        logOut();
        cerrarSesion();
      }
    }
  }

  //----------------------------------------------------
  //  LISTA TODAS LAS PALETAS PARA USARLAS AL GRAFICAR
  //----------------------------------------------------
  if (isEmpty(AllPalettes)) {
    await dispatch(getAllPalettes());
  }

  const { AllPalettes: listAllPalettes } = getState().analyticsReducer;

  if (listAllPalettes) {
    //AllPalettes=JSON.parse(AllPalettes);

    listAllPalettes.map((typePalette) => {
      typePalette = JSON.parse(typePalette);
      if (typePalette.type == typepalette) {
        color = find(propEq("id", Number(colorID)))(typePalette.values);
        // typePalette.values.map((palette)=>{
        //     if(palette.id == colorID)
        //         return color=palette;
        // })
      }
    });

    await dispatch({
      type: ANALYTICS_ACTIONS_TYPES.selectedPalette(),
      payload: color,
    });

    preview = { nameDiv: nameDiv, chart: await getDataChart() };

    await dispatch({
      type: ANALYTICS_ACTIONS_TYPES.getPreviews(),
      payload: preview,
    });

    await dispatch(getAllPreviews());
  }
  // Loading getting list bots
  // await dispatch({
  //     type: MODAL_LOAD_ACTIONS_TYPES.hideLoad(),
  // });
};
export const getAllPreviews = () => async (dispatch, getState) => {
  const { previews, allPreviews } = getState().analyticsReducer;

  await allPreviews.push(previews);

  await dispatch({
    type: ANALYTICS_ACTIONS_TYPES.getAllPreview(),
    payload: allPreviews,
  });
};

export const cleanPreview = (num) => async (dispatch, getState) => {
  await dispatch({
    type: ANALYTICS_ACTIONS_TYPES.cleanPreview(),
    payload: [],
  });

  if (num != 1) {
    dispatch({
      type: ANALYTICS_ACTIONS_TYPES.toggleModalNewChart(),
    });
  }
};

export const cleanAllItems = (num) => async (dispatch, getState) => {
  await dispatch({
    type: ANALYTICS_ACTIONS_TYPES.cleanAllItems(),
    payload: [],
  });
};

export const getItems = (items) => async (dispatch, getState) => {
  await dispatch({
    type: ANALYTICS_ACTIONS_TYPES.getItems(),
    payload: items,
  });
};

export const cleanAllPreviews = () => async (dispatch, getState) => {
  //
  await dispatch({
    type: ANALYTICS_ACTIONS_TYPES.cleanAllPreview(),
  });
};

export const setSelectedCompany = (id_company) => async (
  dispatch,
  getState
) => {
  const { activePage } = getState().analyticsReducer;

  /**
   * Limpia la lista de Bots
   */
  await dispatch(cleanListBots());

  /**
   * Limpia la lista de Dashboars
   */
  await dispatch(cleanListDashboard());

  /**
   * Limpia la lista de Metricas
   */
  await dispatch(cleanListMetrics());

  /**
   * Limpia la lista de Charts
   */
  await dispatch(cleanListCharts());

  /**
   * Limpia los preview de los dashboard
   */
  await dispatch(cleanAllPreviews());


  /**
   * Limpia la lista de reportes personalizados
   */

   await dispatch({
    type: ANALYTICS_ACTIONS_TYPES.setListAllReports(),
    payload: [],
  });

  
   await dispatch({
      type: ANALYTICS_ACTIONS_TYPES.setSelectedReport(),
      payload: [],
    });

    dispatch({
      type: ANALYTICS_ACTIONS_TYPES.setResulset(),
      payload: [],
    });

  /**
   * Selecciona una compañía
   */
  await dispatch({
    type: ANALYTICS_ACTIONS_TYPES.selectedCompany(),
    payload: id_company,
  });

  /*

    

    /**
     * Carga los Chart, bots, dashboards y métricas de una compañía
     * /
    await dispatch(getAllDashboards(activePage));
    await dispatch(getAllCharts(activePage));
    await dispatch(getAllBotsbyCompany(id_company)); 
    await dispatch(getAllMetrics(activePage));

    /**
     * Pone por defecto la granularidad en día
     * /
    await dispatch(changeGranularityDashboard("day"));

    /**
     * Calcula el total de páginas y carga el reporte de iteraciones
     * /
    await dispatch(getPagination());
    await dispatch(getBuilderQuery(1));

    
    /**
     * loadReportSet en true grafica la tabla de reporte de interacciones
     * / 
    await dispatch(loadReportSet(false));

*/
};

export const getPagination = () => async (dispatch, getState) => {
  const {
    analyticsReducer: {
      selectedBot,
      selectedCompany,
      granularity,
      startDateDashboard,
      endDateDashboard,
    },
  } = getState();

  let limit = 1;

  let operation = {};

  let dimensions = [];
  let measures = ["ChatTraceability.count"];

  let filters = [
    {
      dimension: "ChatTraceability.companyId",
      operator: "equals",
      values: ["" + selectedCompany],
    },
    {
      dimension: "ChatTraceability.botId",
      operator: "equals",
      values: ["" + selectedBot.id],
    },
    //   ,{
    //     dimension: 'ChatTraceability.withUser',
    //     operator: 'equals',
    //     values: ["true"]
    //   }
  ];

  let granu = granularity;

  // if(granularity=="null")
  granu = null;

  let timeDimensions = [
    {
      dimension: "ChatTraceability.startTime",
      dateRange: [startDateDashboard, endDateDashboard],
      granularity: granu,
    },
  ];

  operation = {
    measures: measures, //, "LineItems.quantity", "LineItems.price", "Orders.count",  "Orders.number", "Users.count"'],
    dimensions: dimensions, //, "Orders.status",  "Orders.createdAt", "Orders.completedAt",  "Users.city",  "Users.gender","Users.company", "Users.createdAt"']
    filters: filters,
    timeDimensions: timeDimensions,
    timezone: "America/Bogota",
    limit: limit,
  };

  await dispatch(operationCountSet(operation));
};

export const drawDashboard = () => async (dispatch, getState) => {
  const {
    selectedDashboard: selectedDashboardNew,
    viewDashboard,
    listAllCharts,
  } = getState().analyticsReducer;

  let itemsNew = [];

  //--------------------------------------------------------------------------------------------
  //  CREA EL GRID DEL DASHBOARD SELECCIONADO. Si es viewdashboard el tamaño es más pequeño
  //--------------------------------------------------------------------------------------------
  if (viewDashboard == true) {
    if (selectedDashboardNew.charts) {
      itemsNew = selectedDashboardNew.charts.map(function (chart, key, list) {
        return {
          i: chart.id.toString(),
          x: chart.x / 2,
          y: chart.y,
          w: chart.width / 2,
          h: chart.heigth / 3,
          add: chart.id === (list.length - 1).toString(),
          metric: chart.metric,
        };
      });
    }
  } else {
    if (selectedDashboardNew.charts) {
      itemsNew = selectedDashboardNew.charts.map(function (chart, key, list) {
        return {
          i: chart.id.toString(),
          x: chart.x,
          y: chart.y,
          w: chart.width,
          h: chart.heigth / 3,
          add: chart.id === (list.length - 1).toString(),
          metric: chart.metric,
        };
      });
    }
  }

  await dispatch(getItems(itemsNew));

  //--------------------------------------------------------------------------------------------
  //  CREA EL ARRAY DE CHARTS DEL DASHBOARD SELECCIONADO.
  //--------------------------------------------------------------------------------------------
  if (isEmpty(listChart)) {
    var listChart = [],
      defaultValue = [];
    listAllCharts.map((chart, index) => {
      chart = JSON.parse(chart);
      var data = {
        value: "" + chart.id,
        label: chart.metric.name,
        typechart: chart.type_chart.name,
        type_chartid: chart.type_chart.id,
        color: chart.color.id,
        palette_type: chart.color.palette_type,
      };
      listChart.push(data);

      if (selectedDashboardNew.charts) {
        for (var j = 0; j < selectedDashboardNew.charts.length; j++) {
          if (chart.id == selectedDashboardNew.charts[j].chart) {
            defaultValue.push(listChart[index]);
            break;
          }
        }
      }
      return listChart;
    });

    // this.setState({
    //     listChart: listChart,
    //     charts:selectedDashboardNew.charts,
    //      items:itemsNew
    // });

    //--------------------------------------------------------------------------------------------
    //  CREA CADA GRÁFICO
    //--------------------------------------------------------------------------------------------
    if (selectedDashboardNew.charts) {
      for (var j = 0; j < selectedDashboardNew.charts.length; j++) {
        var chart = selectedDashboardNew.charts[j];
        await dispatch(
          getPreview(
            chart.type_chart.id,
            chart.color.id,
            chart.color.palette_type,
            "chart" + chart.id,
            chart.metric,
            chart.icon
          )
        );
      }
    }
  }
};

export const setSelectedBot = (id_bot) => async (dispatch, getState) => {
  const { listAllBotsbyCompany } = getState().analyticsReducer;

  var bot = [];

  if (listAllBotsbyCompany)
    bot = find(propEq("id", Number(id_bot)))(listAllBotsbyCompany);
  console.log('BOTBOT ',bot)
  /**
   * Limpia la lista de reportes personalizados
   */
  await dispatch(getBot(bot.id));

  await dispatch({
    type: ANALYTICS_ACTIONS_TYPES.selectedBot(),
    payload: bot,
  });
  
   
};

export const countMeasures = (idMeasure) => async (dispatch, getState) => {
  const {
    authorization: { access_token } = { access_token },
  } = getState().loginReducer;

  //OJO REVISAR SI ESTA SE LA MEJOR FORMA O A TRAVES DE LA COLUMNA MEASURES DE CADA METRICA

  const url = `${METRICS_API}/metric/graph/` + idMeasure; //?${params}`;

  // Loading getting list metrics
  //await dispatch({type: MODAL_LOAD_ACTIONS_TYPES.loading(),});
  await getHandlerGET(url, access_token)
    .then((res) => {
      if (res.status == 200) {
        // if (checkStatusComponent(components , nameComponent)){

        if (res.data.msg === "No objects founded") {
          dispatch({
            type: ANALYTICS_ACTIONS_TYPES.notFoundGetListMetrics(),
            payload: i18next.t('message112'),
          });
        }
        if (res.data) {
          //let listmetric = res.data.map(e =>JSON.stringify(e))
          var numMeasures = 0;

          if (res.data.length) {
            for (var i = 0; i < res.data.length; i++) {
              if (res.data[i].adjacencies) {
              } else numMeasures++;
            }
          } else numMeasures = 1;
          dispatch({
            type: ANALYTICS_ACTIONS_TYPES.countMeasure(),
            payload: numMeasures,
          });
        }
        //   dispatch({
        //       type: MODAL_LOAD_ACTIONS_TYPES.hideLoad(),
        //   });

        //}
      }
    })
    .catch((error) => {
      dispatch({
        type: MODAL_LOAD_ACTIONS_TYPES.setTitle(),
        payload: i18next.t('message79'),
      });
      dispatch({
        type: ANALYTICS_ACTIONS_TYPES.errorGetListMetrics(),
        payload: i18next.t('message121') + "\n" + error,
      });
      //   dispatch({
      //       type: MODAL_LOAD_ACTIONS_TYPES.hideLoad(),
      //   });
      const { response: { status } = { status } } = error;
      if (status && status == 403) {
        logOut();
        cerrarSesion();
      }
    });

  const { error } = getState().analyticsReducer;
  if (error) {
    dispatch({
      type: MODAL_LOAD_ACTIONS_TYPES.showWarning(),
      payload: error,
    });
  }
};

export const countDimensions = (id) => (dispatch, getState) => {
  const { listAllMetrics } = getState().analyticsReducer;

  var numDimension = 0;

  if (listAllMetrics)
    numDimension = find(propEq("id", id))(listAllMetrics).dimension.length;

  dispatch({
    type: ANALYTICS_ACTIONS_TYPES.countDimension(),
    payload: numDimension,
  });
};

export const countTimeDimensions = (id) => async (dispatch, getState) => {
  const { listAllMetrics } = getState().analyticsReducer;

  var timeDimension = [],
    isTimeDimension = false;

  //    await dispatch({
  //         type: MODAL_LOAD_ACTIONS_TYPES.loading(),
  //     });

  timeDimension = find(propEq("id", id))(listAllMetrics).time_dimension;

  if (timeDimension == null) isTimeDimension = false;
  else if (timeDimension.granularity == true) isTimeDimension = true;
  else isTimeDimension = false;

  await dispatch({
    type: ANALYTICS_ACTIONS_TYPES.countTimeDimension(),
    payload: isTimeDimension,
  });
  // await dispatch({
  //     type: MODAL_LOAD_ACTIONS_TYPES.hideLoad(),
  // });
};

export const changeDatesDashboard = (startDate, endDate) => async (
  dispatch,
  getState
) => {
  if (startDate == null) {
    startDate = getState().analyticsReducer.startDateDashboard;
    dispatch({
      type: MODAL_LOAD_ACTIONS_TYPES.showWarning(),
      payload: i18next.t('message122') + "\n",
    });
  }
  if (endDate == null) {
    endDate = getState().analyticsReducer.endDateDashboard;
    dispatch({
      type: MODAL_LOAD_ACTIONS_TYPES.showWarning(),
      payload: i18next.t('message123') + "\n",
    });
  }

  // console.log("Cambiar a Fecha inicial:"+UTCFormatter(startDate)+" Fecha final: "+UTCFormatter(endDate));

  dispatch({
    type: ANALYTICS_ACTIONS_TYPES.handleChangeStart(),
    payload: startDate,
  });

  dispatch({
    type: ANALYTICS_ACTIONS_TYPES.handleChangeEnd(),
    payload: endDate,
  });
};

export const changeStartDateDashboard = (startDate) => (dispatch, getState) => {
  dispatch({
    type: ANALYTICS_ACTIONS_TYPES.handleChangeStart(),
    payload: startDate,
  });
};

export const changeEndDateDashboard = (endDate) => (dispatch, getState) => {
  dispatch({
    type: ANALYTICS_ACTIONS_TYPES.handleChangeEnd(),
    payload: endDate,
  });
};

export const changeGranularityDashboard = (granularity) => async (
  dispatch,
  getState
) => {
  // Pone el ícono de carga

  await dispatch({
    type: ANALYTICS_ACTIONS_TYPES.handleChangeGranularity(),
    payload: granularity,
  });
};

export const cleanListDashboard = () => async (dispatch, getState) => {
  // Pone el ícono de carga
  //

  await dispatch({
    type: ANALYTICS_ACTIONS_TYPES.cleanListDashboards(),
    payload: [],
  });
};

export const cleanListMetrics = () => async (dispatch, getState) => {
  // Pone el ícono de carga
  //

  await dispatch({
    type: ANALYTICS_ACTIONS_TYPES.cleanListMetrics(),
    payload: [],
  });
};

export const cleanListCharts = () => async (dispatch, getState) => {
  // Pone el ícono de carga
  //

  await dispatch({
    type: ANALYTICS_ACTIONS_TYPES.cleanListCharts(),
    payload: [],
  });
};

export const cleanListBots = () => async (dispatch, getState) => {
  // Pone el ícono de carga
  //

  await dispatch({
    type: ANALYTICS_ACTIONS_TYPES.cleanListBots(),
    payload: [],
  });
};

export const loadReportSet = (loadReport) => async (dispatch, getState) => {
  // Pone el ícono de carga

  await dispatch({
    type: ANALYTICS_ACTIONS_TYPES.setLoadReport(),
    payload: loadReport,
  });
};
export const operationFinalSet = (operationFinal) => (dispatch, getState) => {
  dispatch({
    type: ANALYTICS_ACTIONS_TYPES.setOperationFinal(),
    payload: operationFinal,
  });
};

export const operationCountSet = (operationFinal) => (dispatch, getState) => {
  dispatch({
    type: ANALYTICS_ACTIONS_TYPES.setOperationCount(),
    payload: operationFinal,
  });
};

export const uiReportSet = (uiReport) => (dispatch, getState) => {
  dispatch({
    type: ANALYTICS_ACTIONS_TYPES.setUiReport(),
    payload: uiReport,
  });
};
export const getTockenCubejs = () => async (dispatch, getState) => {
  const url = HOST_API + "/app-metrics/token/cube/";
  // await dispatch({
  //     type: MODAL_LOAD_ACTIONS_TYPES.loading(),
  // });

  await getHandlerGET(url)
    .then((res) => {
      if (res.status == 200) {
        if (res.data) {
          let tocken = res.data.token;
          dispatch({
            type: ANALYTICS_ACTIONS_TYPES.setTockenCube(),
            payload: tocken,
          });
        }
      } else {
        dispatch({
          type: ANALYTICS_ACTIONS_TYPES.errorGetListPalettes(),
          payload: i18next.t('message124'),
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: MODAL_LOAD_ACTIONS_TYPES.setTitle(),
        payload: i18next.t('message79'),
      });
      dispatch({
        type: ANALYTICS_ACTIONS_TYPES.errorGetListPalettes(),
        payload: i18next.t('message125') + "\n" + error,
      });
      if (error.response.status == 403) {
        logOut();
        cerrarSesion();
      }
    });

  const { error } = getState().analyticsReducer;
  if (error) {
    dispatch({
      type: MODAL_LOAD_ACTIONS_TYPES.showWarning(),
      payload: error,
    });
  }
};

export const getBuilderQuery = (page) => async (dispatch, getState) => {
  const {
    analyticsReducer: {
      pagination,
      activePage,
      selectedBot,
      selectedCompany,
      granularity,
      startDateDashboard,
      endDateDashboard,
    },
  } = getState();

  console.log(
    "Inicion función getBuiderQuery - fechas:" +
      UTCFormatter(startDateDashboard) +
      " - " +
      UTCFormatter(endDateDashboard)
  );
  /**
   * SIN CUBEJS
   */

  // Pone el ícono de carga
  //

  // dispatch(tableData(1));

  /**
   * CON CUBEJS
   */

  if (selectedBot.id) {
    console.log("Bot seleccionado: " + selectedBot.id);
    const filterData = {
      bot_id: selectedBot.id,
    };

    if (
      startDateDashboard != "" &&
      startDateDashboard != null &&
      endDateDashboard != null
    ) {
      filterData.period = UTCFormatter(startDateDashboard);
      if (endDateDashboard != "") {
        filterData.period += "|" + UTCFormatter(endDateDashboard);
      } else {
        filterData.period += "|" + UTCFormatter(startDateDashboard);
      }
    } else {
      alert(
        i18next.t('message126')
      );
      filterData.period = UTCFormatter(moment()) + "|" + UTCFormatter(moment());
    }

    let limit = 10;
    let offset = limit * page - (limit - 1);

    let operation = {};

    let dimensions = [
      "ChatTraceability.conversation",
      "ChatTraceability.sessionId",
      "ChatTraceability.moment",
      "ChatTraceability.interactorName",
      "ChatTraceability.textMessage",
    ];
    let measures = []; //['ChatTraceability.countDistinctSession']

    let filters = [
      /*{
            dimension: 'ChatTraceability.companyId',
            operator: 'equals',
            values: [""+selectedCompany]
        },*/ {
        dimension: "ChatTraceability.botId",
        operator: "equals",
        values: ["" + selectedBot.id],
      },
      //   ,{
      //     dimension: 'ChatTraceability.withUser',
      //     operator: 'equals',
      //     values: ["true"]
      //   }
    ];

    let granu = granularity;

    // if(granularity=="null")
    granu = null;

    let timeDimensions = [
      {
        dimension: "ChatTraceability.moment",
        dateRange: [startDateDashboard, endDateDashboard],
        granularity: granu,
      },
    ];

    operation = {
      timeDimensions: timeDimensions,
      measures: measures, //, "LineItems.quantity", "LineItems.price", "Orders.count",  "Orders.number", "Users.count"'],
      dimensions: dimensions, //, "Orders.status",  "Orders.createdAt", "Orders.completedAt",  "Users.city",  "Users.gender","Users.company", "Users.createdAt"']
      filters: filters,
      timezone: "America/Bogota",
      order: {
        "ChatTraceability.moment": "asc",
        "ChatTraceability.conversation": "asc",
      },
    };
    //  ,
    //   limit:limit,
    //   offset:offset,
    let paginationNew = [];
    paginationNew.current = page;
    paginationNew.pages = pagination.pages;

    dispatch({
      type: ANALYTICS_ACTIONS_TYPES.setPagination(),
      payload: paginationNew,
    });

    filterData.query = operation;

    await dispatch(operationFinalSet(operation));

    //Actualiza el link de descarga

    const downloadLink =
      " /api/cube_metrics/report/send_download/chat_report?" + qs.stringify(filterData);

    dispatch({
      type: ANALYTICS_ACTIONS_TYPES.setDownloadLink(),
      payload: downloadLink,
    });
  }
};

export const downloadFile = () => async (dispatch, getState) => {
  let email = JSON.parse(localStorage.getItem("UserInfo")).email;
  
  console.log(localStorage);

  const {
    selectedBot,
    startDateDashboard,
    endDateDashboard,
    downloadLink,
  } = getState().analyticsReducer;

  let titulo =
    'bot <span style="font-weight:bold">' +
    selectedBot.name +
    ' </span> entre las fechas <span style="font-weight:bold">' +
    moment(new Date(startDateDashboard)).format("DD/MM/YYYY  hh:mm a") +
    '</span> y <span style="font-weight:bold">' +
    moment(new Date(endDateDashboard)).format("DD/MM/YYYY  hh:mm a") +
    "</span>.";
  let tituloFile =
    selectedBot.name +
    "_" +
    moment(new Date(startDateDashboard)).format("DD/MM/YYYY") +
    "_" +
    moment(new Date(endDateDashboard)).format("DD/MM/YYYY");

  let _this = this;

  var auth = JSON.parse(localStorage.getItem("Authorization"));

  dispatch({
    type: MODAL_LOAD_ACTIONS_TYPES.loading(),
  });

  var req = new XMLHttpRequest();

  req.onreadystatechange = function () {
    if (req.readyState === 4 && req.status == 200) {
      console.log("respuesta: ", req);
      dispatch({
        type: MODAL_LOAD_ACTIONS_TYPES.setTitle(),
        payload: i18next.t('report_list'),
      });
      setTimeout(() => {
        dispatch({
          type: MODAL_LOAD_ACTIONS_TYPES.showSuccess(),
          payload:
            i18next.t('message127') +
            titulo +
            ' al correo electrónico <span style="font-weight:bold">' +
            email +
            "</span>",
        });
      }, 1200);

      let a = document.createElement("a");
      a.href = JSON.parse(req.response).message;
      a.download = "Reporte " + tituloFile + ".zip";
      a.click();
      console.log(a);
      dispatch({
        type: MODAL_LOAD_ACTIONS_TYPES.hideLoad(),
      });
    } else if (req.readyState === 4) {
      console.log("Error en la respuesta:", req);
      let title = i18next.t('message151');
      let msg = "";

      if (req.statusText == "" || req.status == 504) {
        title = i18next.t('time_limit');
        msg = i18next.t('message128');
      } else if (req.statusText != "") {
        title = req.statusText;
        if (JSON.parse(req.responseText).error)
          msg = JSON.parse(req.responseText).error;
        else msg = req.responseText;
      }

      dispatch({
        type: MODAL_LOAD_ACTIONS_TYPES.setTitle(),
        payload: title,
      });
      setTimeout(() => {
        dispatch({
          type: MODAL_LOAD_ACTIONS_TYPES.showError(),
          payload: msg,
        });
      }, 1200);

      dispatch({
        type: MODAL_LOAD_ACTIONS_TYPES.hideLoad(),
      });
    }
  };

  req.open("GET", downloadLink, true);
  req.withCredentials = true;
  req.responseType = "text";
  req.setRequestHeader("Authorization", "Bearer " + auth.access_token);
  req.send();
};



/**
 * Carga Languages
 */
export const loadLanguages = () => async (dispatch, getState) => {
  //    await dispatch({
  //         type: MODAL_LOAD_ACTIONS_TYPES.loading(),
  //     });

  await getHandlerGET(HOST_API + "/api/language/")
    .then((res) => {
      if (res.status == 200) {
        var list = [];
        if (res.data.languages) {
          for (let i = 0; i < res.data.languages.length; i++) {
            list.push(JSON.stringify(res.data.languages[i]));
          }
        }
        dispatch({
          type: ANALYTICS_ACTIONS_TYPES.successGetListLanguages(),
          payload: list,
        });
      } else {
        dispatch({
          type: ANALYTICS_ACTIONS_TYPES.errorGetListLanguages(),
          payload: i18next.t('message129'),
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: MODAL_LOAD_ACTIONS_TYPES.setTitle(),
        payload: i18next.t('message79'),
      });
      dispatch({
        type: ANALYTICS_ACTIONS_TYPES.errorGetListLanguages(),
        payload: i18next.t('message130') + "\n" + error,
      });
      if (error.response.status == 403) {
        logOut();
        cerrarSesion();
      }
      // dispatch({
      //     type: MODAL_LOAD_ACTIONS_TYPES.hideLoad(),
      // });
    });
};


/* Función que consulta info para reporte de interaciones
* @page= int (page number)
  @search= bool (indicates if it's a search or not) */
export const interactionReportTable = (type) => async (dispatch, getState) => {
  
  const {
    selectedBot,
    startDateDashboard,
    endDateDashboard,
    selectedCompany,
    listAllBotsbyCompany
  } = getState().analyticsReducer;



  if(!selectedBot.id){
      selectedBot.id = listAllBotsbyCompany&&listAllBotsbyCompany.length!=0?listAllBotsbyCompany[0].id:'';
  }

  const { botVersionId } = getState().botReducer;
  const filterData = {
    bot_id: selectedBot.id,
  };

  const startDate = moment(new Date(startDateDashboard)).format("YYYY-MM-DDTHH:mm:ss") +'Z'//`${startDateDashboard.format("YYYY-MM-DDTHH:MM:ss")}Z`;
  const endDate = moment(new Date(endDateDashboard)).format("YYYY-MM-DDTHH:mm:ss") +'Z'//`${endDateDashboard.format("YYYY-MM-DDTHH:MM:ss")}Z`;

  const period = `${startDate}|${endDate}`;

  if (
    startDateDashboard != "" &&
    startDateDashboard != null &&
    endDateDashboard != null
  ) {
    filterData.period = UTCFormatter(startDateDashboard);
    if (endDateDashboard != "") {
      filterData.period += "|" + UTCFormatter(endDateDashboard);
    } else {
      filterData.period += "|" + UTCFormatter(startDateDashboard);
    }
  } else {
    alert(
      i18next.t('message126')
    );
    filterData.period = UTCFormatter(moment()) + "|" + UTCFormatter(moment());
  }

  filterData.period = period;

  var str = qs.stringify(filterData);
  console.log("str ",str)
  let downloadLink = "",url="";

  if(type && type=='customers')
  {
    downloadLink = " /api/cube_metrics/report/send_download/customers_report?" + str;
    url =  HOST_API + `/api/cube_metrics/report/customers_report?company=`+selectedCompany+`&bot_version=`+botVersionId+``;
  }
  else
  {
    downloadLink = " /api/cube_metrics/report/send_download/chat_report?" + str;
    url =  HOST_API + "/api/cube_metrics/report/chat_report?" + str;
  }
  let titulo =
    'bot <span style="font-weight:bold">' +
    selectedBot.name +
    ' </span> entre las fechas <span style="font-weight:bold">' +
    moment(new Date(startDateDashboard)).format("DD/MM/YYYY  hh:mm a") +
    '</span> y <span style="font-weight:bold">' +
    moment(new Date(endDateDashboard)).format("DD/MM/YYYY  hh:mm a") +
    "</span>.";

  dispatch({
    type: ANALYTICS_ACTIONS_TYPES.setDownloadLink(),
    payload: downloadLink,
  });

  
  if(selectedBot.id){
    await getHandlerGET(
    url
    )
      .then((res) => {
        console.log("res ",res.data.dataframe);
        if (res.status == 200) {
          if (res.data.dataframe) {
            dispatch({
              type: ANALYTICS_ACTIONS_TYPES.setResulset(),
              payload: res.data.dataframe,
            });
          } else {
            dispatch({
              type: ANALYTICS_ACTIONS_TYPES.setResulset(),
              payload: [],
            });

            setTimeout(() => {
              dispatch({
                type: MODAL_LOAD_ACTIONS_TYPES.setTitle(),
                payload: type!='customers'?i18next.t('report_interactions') + "!":i18next.t('report_customer') ,
              });
              dispatch({
                type: MODAL_LOAD_ACTIONS_TYPES.showSuccess(),
                payload: res.data.message,
              });
            }, 1200);
          }
        }
      })
      .catch((error) => {
        dispatch({
          type: MODAL_LOAD_ACTIONS_TYPES.setTitle(),
          payload: i18next.t('message151'),
        });

        console.log("ERROR - CATCH: ", error);
        let msg =
          i18next.t('message131') +
          titulo +
          "\n\n" +
          error;

        if (
          error.response &&
          error.response.status &&
          error.response.status == 504
        )
          (msg =
            i18next.t('message132') +
            titulo +
            "\n\n" + i18next.t('message133')),
            setTimeout(() => {
              dispatch({
                type: MODAL_LOAD_ACTIONS_TYPES.showError(),
                payload: msg,
              });
            }, 1200);
        const { response: { status } = { status } } = error;
        if (
          error.response &&
          error.response.status &&
          error.response.status == 403
        ) {
          logOut();
          cerrarSesion();
        }
        dispatch({
          type: MODAL_LOAD_ACTIONS_TYPES.hideLoad(),
        });
      });
  }else {
    dispatch({
      type: ANALYTICS_ACTIONS_TYPES.setResulset(),
      payload: [],
    });

    setTimeout(() => {
      dispatch({
        type: MODAL_LOAD_ACTIONS_TYPES.setTitle(),
        payload: type!='customers'?i18next.t('report_interactions') + "!":i18next.t('report_customer') ,
      });
      dispatch({
        type: MODAL_LOAD_ACTIONS_TYPES.showSuccess(),
        payload: i18next.t('error_selected_bot'),
      });
    }, 1200);
  }
};

export const iconLoad = () => (dispatch, getState) => {
  dispatch({
    type: MODAL_LOAD_ACTIONS_TYPES.loading(),
  });
};

export const iconHideLoad = () => (dispatch, getState) => {
  dispatch({
    type: MODAL_LOAD_ACTIONS_TYPES.hideLoad(),
  });
};

export const getAllListCompanies = () => async (dispatch, getState) => {
  const url = HOST_API + "/api/company/."; //OJO solo trae AVON /16
  /**
   * Muestra ícono de carga
   */

  await dispatch({
    type: HOME_ACTIONS_TYPES.requestGetListCompanies(),
  });

  await getHandlerGET(url)
    .then((res) => {
      if (res.status == 200) {
        if (res.data.companies) {
          //OJO COMPANIES PARA TODAS (res.data.company)
          let companies = res.data.companies; //OJO PARA TODAS CON COMPANIES res.data.companies;[res.data.company]
          dispatch({
            type: HOME_ACTIONS_TYPES.successGetListCompanies(),
            payload: companies,
          });

          dispatch(setSelectedCompany(companies[0].id)); //OJO companies[0].id));
        }
      } else {
        dispatch({
          type: HOME_ACTIONS_TYPES.errorGetListCompanies(),
          payload: i18next.t('message134'),
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: MODAL_LOAD_ACTIONS_TYPES.setTitle(),
        payload: i18next.t('message79'),
      });
      dispatch({
        type: HOME_ACTIONS_TYPES.errorGetListPlafforms(),
        payload: i18next.t('message135') + "\n" + error,
      });
      if (error.response.status == 403) {
        logOut();
        cerrarSesion();
      }
    });

  const { error } = getState().homeReducer;
  if (error) {
    dispatch({
      type: MODAL_LOAD_ACTIONS_TYPES.showWarning(),
      payload: error,
    });
  }
};

export const getReportsUser = () => async (dispatch, getState) => {
  const {
    selectedBot,
    startDateDashboard,
    endDateDashboard,
  } = getState().analyticsReducer;

  const startDate = moment(new Date(startDateDashboard)).format("YYYY-MM-DDTHH:mm:ss") +'Z'//`${startDateDashboard.format("YYYY-MM-DDTHH:MM:ss")}Z`;
  const endDate = moment(new Date(endDateDashboard)).format("YYYY-MM-DDTHH:mm:ss") +'Z'//`${endDateDashboard.format("YYYY-MM-DDTHH:MM:ss")}Z`;

  const period = `${startDate}|${endDate}`;
  const bot_id = selectedBot.id;

  if(bot_id)
  {
  
    let titulo =
      'bot <span style="font-weight:bold">' +
      selectedBot.name +
      ' </span> entre las fechas <span style="font-weight:bold">' +
      moment(new Date(startDateDashboard)).format("DD/MM/YYYY  hh:mm a") +
      '</span> y <span style="font-weight:bold">' +
      moment(new Date(endDateDashboard)).format("DD/MM/YYYY  hh:mm a") +
      "</span>.";

    const downloadLink =
      `/api/cube_metrics/report/send_download/email_report?bot_id=${bot_id}&period=${period}`;


    dispatch({
      type: ANALYTICS_ACTIONS_TYPES.setDownloadLink(),
      payload: downloadLink,
    });

    const url = `${HOST_API}/api/cube_metrics/report/email_report?bot_id=${bot_id}&period=${period}`;
    await getHandlerGET(url)
      .then((res) => {
        //console.log("get reports user: ", res)
        if (res.status == 200) {
          if (res.data.dataframe) {
            dispatch({
              type: ANALYTICS_ACTIONS_TYPES.setResulset(),
              payload: res.data.dataframe,
            });
          } else {
            dispatch({
              type: ANALYTICS_ACTIONS_TYPES.setResulset(),
              payload: [],
            });

            setTimeout(() => {
              dispatch({
                type: MODAL_LOAD_ACTIONS_TYPES.setTitle(),
                payload: i18next.t('message150'),
              });
              dispatch({
                type: MODAL_LOAD_ACTIONS_TYPES.showSuccess(),
                payload: res.data.message,
              });
            }, 1200);
          }
        }
      })
      .catch((error) => {
        dispatch({
          type: MODAL_LOAD_ACTIONS_TYPES.setTitle(),
          payload: i18next.t('message151'),
        });

        console.log("ERROR - CATCH: ", error);
        let msg = i18next.t('message152') + titulo + "\n\n" + error;

        if (
          error.response &&
          error.response.status &&
          error.response.status == 504
        )
          (msg =
            i18next.t('message132') + titulo + "\n\n" + i18next.t('message133')),
            setTimeout(() => {
              dispatch({
                type: MODAL_LOAD_ACTIONS_TYPES.showError(),
                payload: msg,
              });
            }, 1200);
        const { response: { status } = { status } } = error;
        if (
          error.response &&
          error.response.status &&
          error.response.status == 403
        ) {
          logOut();
          cerrarSesion();
        }
        dispatch({
          type: MODAL_LOAD_ACTIONS_TYPES.hideLoad(),
        });
      });
    }
    else
    {
        dispatch({
          type: ANALYTICS_ACTIONS_TYPES.setResulset(),
          payload: [],
        });

       
      setTimeout(() => {
        dispatch({
          type: MODAL_LOAD_ACTIONS_TYPES.setTitle(),
          payload: i18next.t('attention'),
        });
        dispatch({
          type: MODAL_LOAD_ACTIONS_TYPES.showSuccess(),
          payload: i18next.t('error_selected_bot'),
        });
      }, 1200);
    }
};

export const getCustomReport = () => async (dispatch, getState) => {
  const {
    selectedBot,
    startDateDashboard,
    endDateDashboard,
    selectedReport
  } = getState().analyticsReducer;

  const startDate = moment(new Date(startDateDashboard)).format("YYYY-MM-DDTHH:mm:ss") +'Z'//`${startDateDashboard.format("YYYY-MM-DDTHH:MM:ss")}Z`;
  const endDate = moment(new Date(endDateDashboard)).format("YYYY-MM-DDTHH:mm:ss") +'Z'//`${endDateDashboard.format("YYYY-MM-DDTHH:MM:ss")}Z`;

  const period = `${startDate}|${endDate}`;
  const bot_id = selectedBot.id;
  if(selectedReport && selectedReport.id)
  {
    const operator_id = selectedReport.id;

    
    let titulo =
      'bot <span style="font-weight:bold">' +
      selectedBot.name +
      ' </span> entre las fechas <span style="font-weight:bold">' +
      moment(new Date(startDateDashboard)).format("DD/MM/YYYY  hh:mm a") +
      '</span> y <span style="font-weight:bold">' +
      moment(new Date(endDateDashboard)).format("DD/MM/YYYY  hh:mm a") +
      "</span>.";

    const downloadLink =
      `/api/cube_metrics/report/send_download/custom_report?operator_id=${operator_id}&bot_id=${bot_id}&period=${period}`;


    dispatch({
      type: ANALYTICS_ACTIONS_TYPES.setDownloadLink(),
      payload: downloadLink,
    });

    const url = `${HOST_API}/api/cube_metrics/report/custom_report?operator_id=${operator_id}&bot_id=${bot_id}&period=${period}`;
    await getHandlerGET(url)
      .then((res) => {
        //console.log("get reports user: ", res)
        if (res.status == 200) {
          if (res.data.dataframe) {
            
            dispatch({
              type: ANALYTICS_ACTIONS_TYPES.setResulset(),
              payload: res.data.dataframe,
            });
          } else {
            dispatch({
              type: ANALYTICS_ACTIONS_TYPES.setResulset(),
              payload: [],
            });

            setTimeout(() => {
              dispatch({
                type: MODAL_LOAD_ACTIONS_TYPES.setTitle(),
                payload: "Reporte personalizado!",
              });
              dispatch({
                type: MODAL_LOAD_ACTIONS_TYPES.showSuccess(),
                payload: res.data.message,
              });
            }, 1200);
          }
        }
      })
      .catch((error) => {
        dispatch({
          type: MODAL_LOAD_ACTIONS_TYPES.setTitle(),
          payload: i18next.t('message151'),
        });

        console.log("ERROR - CATCH: ", error);
        let msg = i18next.t('message153') + titulo +
          "\n\n" +
          error;

        if (
          error.response &&
          error.response.status &&
          error.response.status == 504
        )
          (msg =
            i18next.t('message132') +
            titulo + "\n\n" + i18next.t('message133')),
            setTimeout(() => {
              dispatch({
                type: MODAL_LOAD_ACTIONS_TYPES.showError(),
                payload: msg,
              });
            }, 1200);
        const { response: { status } = { status } } = error;
        if (
          error.response &&
          error.response.status &&
          error.response.status == 403
        ) {
          logOut();
          cerrarSesion();
        }
        dispatch({
          type: MODAL_LOAD_ACTIONS_TYPES.hideLoad(),
        });
      });

    }
};
export const getCustomersReport = () => async (dispatch, getState) => {
  const {
    selectedBot,
    startDateDashboard,
    endDateDashboard,
    selectedReport
  } = getState().analyticsReducer;

  const startDate = moment(new Date(startDateDashboard)).format("YYYY-MM-DDTHH:mm:ss") +'Z'//`${startDateDashboard.format("YYYY-MM-DDTHH:MM:ss")}Z`;
  const endDate = moment(new Date(endDateDashboard)).format("YYYY-MM-DDTHH:mm:ss") +'Z'//`${endDateDashboard.format("YYYY-MM-DDTHH:MM:ss")}Z`;

  const period = `${startDate}|${endDate}`;
  const bot_id = selectedBot.id;
  if(selectedReport && selectedReport.id)
  {
    const operator_id = selectedReport.id;

    
    let titulo =
      'bot <span style="font-weight:bold">' +
      selectedBot.name +
      ' </span> entre las fechas <span style="font-weight:bold">' +
      moment(new Date(startDateDashboard)).format("DD/MM/YYYY  hh:mm a") +
      '</span> y <span style="font-weight:bold">' +
      moment(new Date(endDateDashboard)).format("DD/MM/YYYY  hh:mm a") +
      "</span>.";

    const downloadLink =
      `/api/cube_metrics/report/send_download/customers_report?operator_id=${operator_id}&bot_id=${bot_id}&period=${period}`;


    dispatch({
      type: ANALYTICS_ACTIONS_TYPES.setDownloadLink(),
      payload: downloadLink,
    });

    const url = `${HOST_API}/api/cube_metrics/report/customers_report?operator_id=${operator_id}&bot_id=${bot_id}&period=${period}`;
    await getHandlerGET(url)
      .then((res) => {
        //console.log("get reports user: ", res)
        if (res.status == 200) {
          if (res.data.dataframe) {
            dispatch({
              type: ANALYTICS_ACTIONS_TYPES.setResulset(),
              payload: res.data.dataframe,
            });
          } else {
            dispatch({
              type: ANALYTICS_ACTIONS_TYPES.setResulset(),
              payload: [],
            });

            setTimeout(() => {
              dispatch({
                type: MODAL_LOAD_ACTIONS_TYPES.setTitle(),
                payload: "Reporte Clientes!",
              });
              dispatch({
                type: MODAL_LOAD_ACTIONS_TYPES.showSuccess(),
                payload: res.data.message,
              });
            }, 1200);
          }
        }
      })
      .catch((error) => {
        dispatch({
          type: MODAL_LOAD_ACTIONS_TYPES.setTitle(),
          payload: i18next.t('message151'),
        });

        console.log("ERROR - CATCH: ", error);
        let msg =
          i18next.t('message154') +
          titulo +
          "\n\n" +
          error;

        if (
          error.response &&
          error.response.status &&
          error.response.status == 504
        )
          (msg =
            i18next.t('message132') +
            titulo +
            "\n\n" + i18next.t('message133')),
            setTimeout(() => {
              dispatch({
                type: MODAL_LOAD_ACTIONS_TYPES.showError(),
                payload: msg,
              });
            }, 1200);
        const { response: { status } = { status } } = error;
        if (
          error.response &&
          error.response.status &&
          error.response.status == 403
        ) {
          logOut();
          cerrarSesion();
        }
        dispatch({
          type: MODAL_LOAD_ACTIONS_TYPES.hideLoad(),
        });
      });

    }
};

/**
 * Carga Reportes personalizados o reportes blockchain
 */
 export const setListAllReports = () => async (dispatch, getState) => {

  const {
    analyticsReducer: {
      selectedBot,
      selectedCompany,
      reportType,
    },
  } = getState();

  let url='',collection='';

  if(reportType=='custom'){
    url = "/api/cube_metrics/report/bot_with_report?bot_id="
    collection= "&collection=bots_reports"
    url = HOST_API + url + selectedBot.id + collection
  }
  else if(reportType=='customers'){
    url = "/api/cube_metrics/report/bot_with_report?company="
    url = HOST_API + url + selectedCompany
  }
  else if(reportType=='blockchain'){
    url = "/api/cube_metrics/report/bot_with_report?bot_id="
    collection="&collection="+reportType
    url = HOST_API + url + selectedBot.id + collection
  }
 
  await getHandlerGET(url)
    .then(async (res) => {
      if (res.status == 200) {
          let list_report = [];
          if (res.data.bots&&res.data.bots.operators.length > 0) {
              list_report = res.data.bots;
              //list_report = find(propEq("id", selectedBot.id))(list);
              
              if(!list_report )
                list_report = [];
              else
                list_report = list_report.operators;
              
              await dispatch({
                type: ANALYTICS_ACTIONS_TYPES.setListAllReports(),
                payload: list_report,
              });
              console.log("list_report",list_report)
              if( list_report != []){
                await dispatch({
                  type: ANALYTICS_ACTIONS_TYPES.setSelectedReport(),
                  payload: list_report[0],
                });
              }
              else
              {
                await dispatch({
                  type: ANALYTICS_ACTIONS_TYPES.setListAllReports(),
                  payload: [],
                });
                 await dispatch({
                    type: ANALYTICS_ACTIONS_TYPES.setSelectedReport(),
                    payload: [],
                  });
                dispatch({
                  type: MODAL_LOAD_ACTIONS_TYPES.hideLoad(),
                });
              }
              
             
        } else {
          dispatch({
            type: MODAL_LOAD_ACTIONS_TYPES.setTitle(),
            payload: i18next.t('attention'),
          });
          dispatch({
            type: MODAL_LOAD_ACTIONS_TYPES.showSuccess(),
            payload: i18next.t('message155') + "\n",
          });
        }

      } else {
        dispatch({
          type: MODAL_LOAD_ACTIONS_TYPES.setTitle(),
          payload: i18next.t('message79'),
        });
        dispatch({
          type: MODAL_LOAD_ACTIONS_TYPES.showError(),
          payload: i18next.t('message156') + "\n" + error,
        });
      }
    
    })
    .catch((error) => {
      dispatch({
        type: MODAL_LOAD_ACTIONS_TYPES.setTitle(),
        payload: i18next.t('message79'),
      });
      dispatch({
        type: MODAL_LOAD_ACTIONS_TYPES.showError(),
        payload: i18next.t('message156') + "\n" + error,
      });
      const { response: { status } = { status } } = error;
      if (
        error.response &&
        error.response.status &&
        error.response.status == 403
      ) {
        logOut();
        cerrarSesion();
      }
     
    });
};

export const setSelectedReport = (operator_id) => async (
  dispatch,
  getState
) => {
  
  const {
    analyticsReducer: {
      listAllCustomReports
    },
  } = getState();
  

  const report = find(propEq("id", parseInt(operator_id)))(listAllCustomReports);

  await dispatch({
    type: ANALYTICS_ACTIONS_TYPES.setSelectedReport(),
    payload: report,
  });
};


/**
 * REPORTE SMS
 * @returns 
 */

export const getSMSReport = () => async (dispatch, getState) => {
  const {
    selectedBot,
    startDateDashboard,
    endDateDashboard,
  } = getState().analyticsReducer;

  const startDate = moment(new Date(startDateDashboard)).format("YYYY-MM-DDTHH:mm:ss") +'Z'//`${startDateDashboard.format("YYYY-MM-DDTHH:MM:ss")}Z`;
  const endDate = moment(new Date(endDateDashboard)).format("YYYY-MM-DDTHH:mm:ss") +'Z'//`${endDateDashboard.format("YYYY-MM-DDTHH:MM:ss")}Z`;

  const period = `${startDate}|${endDate}`;
  const bot_id = selectedBot.id;

  
  let titulo =
    'bot <span style="font-weight:bold">' +
    selectedBot.name +
    ' </span> entre las fechas <span style="font-weight:bold">' +
    moment(new Date(startDateDashboard)).format("DD/MM/YYYY  hh:mm a") +
    '</span> y <span style="font-weight:bold">' +
    moment(new Date(endDateDashboard)).format("DD/MM/YYYY  hh:mm a") +
    "</span>.";

  const downloadLink =
    `/api/cube_metrics/report/send_download/sms_report?bot_id=${bot_id}&period=${period}`;


  dispatch({
    type: ANALYTICS_ACTIONS_TYPES.setDownloadLink(),
    payload: downloadLink,
  });
  if(bot_id){
    const url = `${HOST_API}/api/cube_metrics/report/sms_report?bot_id=${bot_id}&period=${period}`;
    await getHandlerGET(url)
      .then((res) => {
        //console.log("get reports user: ", res)
        if (res.status == 200) {
          if (res.data.dataframe) {
            dispatch({
              type: ANALYTICS_ACTIONS_TYPES.setResulset(),
              payload: res.data.dataframe,
            });
          } else {
            dispatch({
              type: ANALYTICS_ACTIONS_TYPES.setResulset(),
              payload: [],
            });

            setTimeout(() => {
              dispatch({
                type: MODAL_LOAD_ACTIONS_TYPES.setTitle(),
                payload: "Reporte de SMS!",
              });
              dispatch({
                type: MODAL_LOAD_ACTIONS_TYPES.showSuccess(),
                payload: res.data.message,
              });
            }, 1200);
          }
        }
      })
      .catch((error) => {
        dispatch({
          type: MODAL_LOAD_ACTIONS_TYPES.setTitle(),
          payload: i18next.t('message151'),
        });

        console.log("ERROR - CATCH: ", error);
        let msg =
          i18next.t('message157') +
          titulo +
          "\n\n" +
          error;

        if (
          error.response &&
          error.response.status &&
          error.response.status == 504
        )
          (msg =
            i18next.t('message132') +
            titulo + "\n\n" + i18next.t('message133')),
            setTimeout(() => {
              dispatch({
                type: MODAL_LOAD_ACTIONS_TYPES.showError(),
                payload: msg,
              });
            }, 1200);
        const { response: { status } = { status } } = error;
        if (
          error.response &&
          error.response.status &&
          error.response.status == 403
        ) {
          logOut();
          cerrarSesion();
        }
        dispatch({
          type: MODAL_LOAD_ACTIONS_TYPES.hideLoad(),
        });
      });
    }
    else {
      dispatch({
        type: ANALYTICS_ACTIONS_TYPES.setResulset(),
        payload: [],
      });
  
      setTimeout(() => {
        dispatch({
          type: MODAL_LOAD_ACTIONS_TYPES.setTitle(),
          payload: i18next.t('attention') + "!",
        });
        dispatch({
          type: MODAL_LOAD_ACTIONS_TYPES.showSuccess(),
          payload: i18next.t('error_selected_bot'),
        });
      }, 1200);
    }
};


export const getBlockChainReport = () => async (dispatch, getState) => {
  const {
    selectedBot,
    startDateDashboard,
    endDateDashboard,
    selectedReport
  } = getState().analyticsReducer;

  const startDate = moment(new Date(startDateDashboard)).format("YYYY-MM-DDTHH:mm:ss") +'Z'//`${startDateDashboard.format("YYYY-MM-DDTHH:MM:ss")}Z`;
  const endDate = moment(new Date(endDateDashboard)).format("YYYY-MM-DDTHH:mm:ss") +'Z'//`${endDateDashboard.format("YYYY-MM-DDTHH:MM:ss")}Z`;

  const period = `${startDate}|${endDate}`;
  const bot_id = selectedBot.id;
  if(selectedReport && selectedReport.id)
  {
    const operator_id = selectedReport.id;

    
    let titulo =
      'bot <span style="font-weight:bold">' +
      selectedBot.name +
      ' </span> entre las fechas <span style="font-weight:bold">' +
      moment(new Date(startDateDashboard)).format("DD/MM/YYYY  hh:mm a") +
      '</span> y <span style="font-weight:bold">' +
      moment(new Date(endDateDashboard)).format("DD/MM/YYYY  hh:mm a") +
      "</span>.";

    const downloadLink =
      `/api/cube_metrics/report/send_download/blockchain_report?operator_id=${operator_id}&bot_id=${bot_id}&period=${period}`;


    dispatch({
      type: ANALYTICS_ACTIONS_TYPES.setDownloadLink(),
      payload: downloadLink,
    });

    const url = `${HOST_API}/api/cube_metrics/report/blockchain_report?operator_id=${operator_id}&bot_id=${bot_id}&period=${period}`;
    await getHandlerGET(url)
      .then((res) => {
        //console.log("get reports user: ", res)
        if (res.status == 200) {
          if (res.data.dataframe) {
            dispatch({
              type: ANALYTICS_ACTIONS_TYPES.setResulset(),
              payload: res.data.dataframe,
            });
          } else {
            dispatch({
              type: ANALYTICS_ACTIONS_TYPES.setResulset(),
              payload: [],
            });

            setTimeout(() => {
              dispatch({
                type: MODAL_LOAD_ACTIONS_TYPES.setTitle(),
                payload: i18next.t('message159'),
              });
              dispatch({
                type: MODAL_LOAD_ACTIONS_TYPES.showSuccess(),
                payload: res.data.message,
              });
            }, 1200);
          }
        }
      })
      .catch((error) => {
        dispatch({
          type: MODAL_LOAD_ACTIONS_TYPES.setTitle(),
          payload: i18next.t('message151'),
        });

        console.log("ERROR - CATCH: ", error);
        let msg = i18next.t('message158') + titulo + "\n\n" + error;

        if (
          error.response &&
          error.response.status &&
          error.response.status == 504
        )
          (msg =
            i18next.t('message132') + 
            titulo + "\n\n" + i18next.t('message133')),
            setTimeout(() => {
              dispatch({
                type: MODAL_LOAD_ACTIONS_TYPES.showError(),
                payload: msg,
              });
            }, 1200);
        const { response: { status } = { status } } = error;
        if (
          error.response &&
          error.response.status &&
          error.response.status == 403
        ) {
          logOut();
          cerrarSesion();
        }
        dispatch({
          type: MODAL_LOAD_ACTIONS_TYPES.hideLoad(),
        });
      });

    }
};