import React from 'react';
import { connect } from "react-redux";
import i18next from '../../../../../i18n';

import { 
    Button, Input,
    Modal, ModalHeader, ModalBody, 
    ModalFooter, Form, FormGroup, 
    Col, Row, Label
} from 'reactstrap';
import Select from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

// Assets
import './trigger-modal.css';
import abierto from "../../../../../static/img/trigger/abierto.svg";
import click from "../../../../../static/img/trigger/click.svg";
import entregado from "../../../../../static/img/trigger/entregado.svg";
import rechazado from "../../../../../static/img/trigger/rechazado.svg";

function TRIGGERModalLayout(props){
  
    const { listAllLinks } = props;
    const listOptionsLinks = () =>{
       return listAllLinks.map((item) => ({label: item, value: item }));
    };
    
    const listLinksAdded = () => {
        const { listLinksAdded = [] } = props;                
        return listLinksAdded.map((item,index) => {
            return (
                <React.Fragment key={index}>
                    <Row>
                        <Col>
                            <Label> Link #{index+1} </Label>
                            <Input value = {item} disabled  />
                        </Col>
                    </Row>
                    <Button onClick={() => props.deleteLinkFromList(index) } >
                        <FontAwesomeIcon icon={faTrash}  />
                    </Button>
                </React.Fragment>
            );
        });
    };

    const customStyles = {
        ///.....
        menuPortal: provided => ({ ...provided, zIndex: 9999 }),
        menu: provided => ({ ...provided, zIndex: 9999 })
        ///.....
      }
    const { selectedBoxOperator,varList,configBoxOperator,node,botmsg_text,modalViewBoxTrigger } = props.boxReducer;
    const { bot,botVersionId } = props.botReducer;

        
    if(selectedBoxOperator&&Object.keys(selectedBoxOperator).length>0&&selectedBoxOperator.operatortype_id==17&&modalViewBoxTrigger)
    {

    return (       
        <div>
             <Modal className="trigger" isOpen={props.isOpen} toggle={props.closeModal} fade size="lg">
                    <ModalHeader toggle={props.closeModal}>{i18next.t('set_up')} trigger - {node.id}</ModalHeader>
                    <ModalBody>
                    <TitleOperator titleOperator={selectedBoxOperator.title} />
                                            
                        <Form id="TRM-form" role="form">                              
                            <FormGroup>
                                <Row className = "justify-content-md-center">

                                    <Label> {i18next.t('events_message')} </Label>
                                    <br/>

                                    <div className = 'container-event'>
                                        <div className={( props.listEventsAdded.includes('delivery') ) ? 'eventSelected' : 'event'} onClick={()=>props.handleEvents('delivery')} >
                                            <div className={(props.listEventsAdded.includes('delivery') ) ? 'iconSelected' : 'icon'}  />
                                            <img src={entregado} />
                                            <strong>
                                            {i18next.t('delivered')}
                                            </strong>
                                            {i18next.t('message51')} 
                                        </div>
                                        <div className={(props.listEventsAdded.includes('open') ) ? 'eventSelected' : 'event'} onClick={()=>props.handleEvents('open')} > 
                                            <div className={(props.listEventsAdded.includes('open') ) ? 'iconSelected' : 'icon'}  />
                                            <img src={abierto} />
                                            <strong>
                                            {i18next.t('open')}
                                            </strong>
                                            {i18next.t('message51')} 
                            
                                        </div>
                                        <div className= {(props.listEventsAdded.includes('rejected') ) ? 'eventSelected' : 'event'} onClick={ ()=>props.handleEvents('rejected')} >
                                            <div className={(props.listEventsAdded.includes('rejected') ) ? 'iconSelected' : 'icon'}  />
                                            <img src={rechazado} />
                                            <strong>
                                            {i18next.t('refused')}
                                            </strong>
                                            {i18next.t('message51')} 

                                        </div>
                                        <div className= {(props.listEventsAdded.includes('click') ) ? 'eventSelected' : 'event'} onClick={()=>props.handleEvents('click')} >
                                            <div className={(props.listEventsAdded.includes('click') ) ? 'iconSelected' : 'icon'} />
                                            <img src={click} />
                                            <strong>
                                                click
                                            </strong>
                                            {i18next.t('message51')} 
                                        </div>
                                    </div>
                                </Row>

                                { props.listEventsAdded.includes('click') && 
                                    <React.Fragment>
                                        <hr/>
                                        <div className = 'container-add-links' >
                                            <Row>
                                                <Col md={9}>
                                                    <Label>{i18next.t('links_event')} <small>( {i18next.t('maximum')} 5 )</small> </Label>
                                                    {/* <Input defaultValue='select'/> */}
                                                    <Select                                                     
                                                        id = 'links-template'
                                                        name = 'links-template'
                                                        placeholder = "Selecciona un link" 
                                                        onChange={props.handleChangeLink}
                                                        // defaultValue={defaultOptions}
                                                        options = { listOptionsLinks() }
                                                        isMulti = { false }
                                                        required
                                                        menuPortalTarget={document.body}
                                                        menuPosition={'fixed'} 
                                                        styles={customStyles}
                                                        />
                                                </Col>
                                                <Col md={2}>
                                                    <Button onClick = { () => props.addLinkToList() } >
                                                        <strong>
                                                            + {i18next.t('add_link')}
                                                        </strong>
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </div>
                                        <Row>
                                            <div className = 'container-links-trigger' >
                                                {listLinksAdded()}
                                            </div>
                                        </Row>
                                </React.Fragment>
                                }

                            </FormGroup>                            
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Col sm="12" align="right">
                            <Button color="success" className="btn-guarumo-modal-sm" type="button" onClick={props.toggleModuleModal} >{i18next.t('finish')}</Button>
                        </Col>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
    else
    {
        return(<div></div>)
    }
}
 
const mapStateToProps = ({botReducer, boxReducer,homeReducer}) => {
    return {botReducer,boxReducer, homeReducer};
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps )(TRIGGERModalLayout);  

