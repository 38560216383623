import React, {useState} from 'react';
import {
  Col,
  Row,
  Container,
  Form,
  Button,
  Input,
  Label,
  FormGroup,
  UncontrolledTooltip
 } from 'reactstrap';
 import Admin from '../../admin/containers/admin';
 import SliceMenuApp from '../../admin/components/slice-menu';
 import ToolbarApp from '../../admin/components/toolbar';
 import BotMenuOptions from'../../menus/bot-menu-options';
 import './bot-ia-layout.css';
 import DataTableFilter from "../../analytics/infoViz/common/dataTableFilter";
 import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
 import { faPen, faTrash, faSave, faArrowLeft, faTimes, faCheck } from '@fortawesome/free-solid-svg-icons'
 import { find, propEq } from "ramda";
 import qs from 'qs';
 import i18next from '../../../../i18n';

function BotIALayoutTable(props){
    
    let cantidadTotal = 0;

    var styles ={
        height: {
            width: 100 
        } 
    };

    let handleClick = value => {
        console.log("value", value);
        const { id } = value;
       
      };

  let operator = props.uaQuestions || [];

  let selectedQ = [],allData=[], removeAll=false;

    /*AGREGADO EL DIA 13 DE MARZON* */
    const [fileName, setFileName] = useState(''); // Estado para almacenar el nombre del archivo seleccionado
    const updateFileName = (event) => {
        const file = event.target.files[0];
        if (file) {
            setFileName(file.name);
        }
    };
  /*FIN* */
 
  var dataTable=null;
  if(operator[0]){     
       dataTable = {
          columns: [
            {
              label: 'id',
              field: 'id',
              sort: 'asc',
             // width: 150
            },
            {
                label: 'Pregunta',
                field: 'pregunta',
                sort: 'asc',
               // width: 150
            },
            {
                label: 'Respuesta',
                field: 'respuesta',
                sort: 'asc',
               // width: 150
            },
            {
                label: 'Otras respuestas',
                field: 'otras respuestas',
                sort: 'asc',
               // width: 150
            },
            {
                label: 'sender_id',
                field: 'sender_id',
                sort: 'asc',
               // width: 150
            },
            {
                label: 'Fecha',
                field: 'fecha',
                sort: 'asc',
               // width: 150
            },
        ],
            rows: []};
            const rows = props.uaQuestions?( props.uaQuestions.map((op, index) => {
              console.log('op.repetitions ',op);
              const fin=[];
              fin.push(op.id);
              fin.push(op.question);
              fin.push(op.response);
              fin.push(op.others_responses);
              fin.push(op.sender_id);
              fin.push(op.created);
              cantidadTotal += op.repetitions;
              dataTable.rows.push(fin);  
              return op;
          })):[];
      }


    const options = {
        filterType: "textField",
        fixedHeader: true,
        sort: true,
        search: true,
        selectableRows: "multiple",
        responsive: "scrollMaxHeight",
        rowsPerPage: 100,
        rowHove: true,
        selectableRowsHeader: true,
        onTableChange: (action, state) => {
            console.log("action ",action);
            console.log("state ",state);
            if(action=='rowsSelect')
            {
                selectedQ = state.selectedRows.data;
                allData = state.data;
                if(selectedQ.length==allData.length)
                    removeAll=true;
                 else
                    removeAll=false;

            }
            else if(action=='rowDelete')
            {   
                if(removeAll){
                    selectedQ.map((op, index) => {
                        const uaq= find(propEq("index", op.index ))(allData);
                        console.log("uaq.data[0] ",uaq.data[0]);
                        props.deleteAllUAQuestion(uaq.data[0]);
                    })
                    //props.deleteAllUAQuestion();
                    removeAll=false;
                }else{
                    selectedQ.map((op, index) => {
                        const uaq= find(propEq("index", op.index ))(allData);
                        props.resolveUAQuestion(uaq.data[0]);
                    })
                }
                
            }
          },
          onRowsClick: (rowData, rowMeta) => {
            console.log("rowData", rowData);
            handleClick(this.state.data[rowMeta.dataIndex]);
          },
          onRowsExpand: (curExpanded, allExpanded) => {
            console.log("rowExpand", curExpanded, allExpanded[0]);
            handleClick(this.state.data[allExpanded[0].dataIndex]);
          }
       
      };
      
          var title = i18next.t('ia_multiple_response') + `(${operator.length})`;

    return (
        <div className="BotLayout">
          <Admin>
            <SliceMenuApp>
              <BotMenuOptions botId={props.botId} ia_activated={props.ia_activated}/>
            </SliceMenuApp>
            <Container fluid>
                <ToolbarApp>
                    <Row>
                        <Col xs="12" className="text pdtoolbar">
                            <h2 className="text-left ml-4 font-weight-bold">{i18next.t('module_ia')} </h2>                      
                        </Col>
                    </Row>
                </ToolbarApp>
            </Container>
            <Container fluid className="box-fluid">
                <Row>
                    <Col sm="12" md="10" className="ia-container offset-md-1">
                       <Col md="12" className="card-configuration card">
                       { props.showConfig? (
                        <Row id="configuration_ia-panel">
                            <Col md="12">
                                <Form onSubmit={props.linkingIA}>
                                    <Col md="12">
                                        <Row>
                                            <Col md="6" lg="8">
                                                <h2 className="font-weight-bold">{i18next.t('link_bot')}</h2>
                                            </Col>
                                            <Col md="6" lg="4">
                                                <Row>
                                                    <Col className="button-config">
                                                        <Button type="button" className="btn btn-block btn-block-secundary" onClick={props.configBotBack}>{i18next.t('cancel')}</Button>
                                                    </Col>
                                                    <Col className="button-config">
                                                        <Button type="submit" className="btn btn-guarumo-md btn-block">{i18next.t('connect')}</Button>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md="12">
                                            <FormGroup>
                                                <Label for="exampleInputEmail1">Id Dialogflow</Label>
                                                <Input type="text" id="id_Dialogflow_text" name="dialogflow_id" placeholder={i18next.t('ia_message2')} className=""/>
                                            </FormGroup>
                                            <FormGroup className="form-group col-12" style={{paddingLeft:'0px'}}>
                                                
                                                <Label for="fileBotid" 
                                                       className="mt-4 btn btn-outline-secondary"
                                                       style={{cursor: "pointer"}}>
                                                        {i18next.t('file_selected')} {' '}  {/*i18next.t('config_file')*/}
                                                </Label>

                                                <Input type="file" 
                                                       id="fileBotid"
                                                       name="json_config"
                                                       style={{ display: 'none' }}
                                                       onChange={updateFileName}/>
                                                {fileName && <span style={{color: '#000000', marginLeft: '8px' }}>{fileName}</span>}
                                            </FormGroup>
                                    </Col>
                                </Form>
                            </Col>
                        </Row>
                        ):null}

                        { props.showEdition? (
                        <Row id="edition_ia-card">

                            <Col>
                                {/*<div className=" mb-4"> 
                                    <div className="panel-body-module_ia">
                                    {props.listIntent.map((item,index) => 
                                    <Form key={index} onSubmit={props.letEditEntity}> 
                                        <Input type="hidden" defaultValue={item.id} name="id" hidden/>
                                        <Button key={index} type="submit" className='list-group-item list-group-item-action modules_intents card-body' > {item.name}</Button>
                                    </Form>
                                        )}
                                    </div>
                                </div>*/}
                                
                
                                <div className="module_questions "> 

                               
                    {
                       dataTable && dataTable.rows!=[]?(
                        <Row >
                            <Col xs={12} lg={12} >
                                <h3>{title}</h3>
                                <br/>
                                <DataTableFilter
                                title={''}
                                data={ dataTable.rows}
                                columns={dataTable.columns}
                                options={options}
                                />
                           </Col>
                        </Row>): 
                          <Col xs={12} lg={8} className="offset-lg-2 intro-funnels">
                                <Row>
                                    <Col xs={12}><img src="/static/img/blank_state.png" className="img-fluid"/></Col>
                                    <Col xs={12}>
                                        <h2>{i18next.t('ia_message7')}</h2>
                                        <h3>{i18next.t('ia_message8')}</h3>
                                        <p>{i18next.t('ia_message5')}</p>
                                    </Col>
                                </Row>
                            </Col>
                        }
                 
               

                                </div>
                            
                            </Col>
                        </Row>
                        ):null}

                        { props.showEditionEntity? (
                        <Row id="ia_edition_entity" className="ia_edition_entity">
                            <Col md="12" className="pt-4">
                                <h2 className="titleh2 font-weight-bold">{i18next.t('new_module')}</h2>
                            </Col>
                            
                            <Col md="12">
                                <Form id="form-intent-submit" onSubmit={props.intentFormSubmit}>
                                    <Row>
                                        <Col md="8" lg="7">
                                            <Label for="exampleInputEmail1">Nombre módulo</Label>
                                            <Input type="hidden" defaultValue={props.currentIntent.id} name="id" hidden/>
                                            <Input className="" name="name" type="text" defaultValue={props.currentIntent.name} placeholder="Nombre módulo"/>
                                        </Col>
                                        <Col md="4" lg="5" className="button-form row">
                                            <Col md="6" className="p-2">
                                                <Button type="button" color="secondary" className="btn btn-block" onClick={props.hideEntity}>{i18next.t('back')}</Button>
                                            </Col>
                                            <Col  md="6" className="p-2">
                                                <Button type="submit" className="btn btn-guarumo-md btn-block">{i18next.t('save')}</Button>
                                            </Col>
                                            
                                            
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                            
                            <Col md="12" className="module_questions">
                                <div>	
                                    <Label>{i18next.t('question_user')}...</Label>
                                    <Form className="form-wrapper" onSubmit={props.appendQuestion}>
                                        <input type="text" name="question" placeholder={i18next.t('question_write')} required />
                                        <button type="submit" className="btn-save"><i className="fa fa-save"> </i></button>
                                    </Form>
                                    <div className="preguntas_ia">
                                    {props.currentIntent.questions.map((item,index) => 
                                    <Form key={index} onSubmit={props.removeQuestion}>
                                        <div className='list-group-item list-group-item-action card-body-question' onMouseEnter={props.showOptions} onMouseLeave={props.hideOptions} >
                                            {item}
                                            <Input type="hidden" defaultValue={index} name="index" hidden/> 
                                            <button type='submit' className='btn btn-danger pull-right btn-delete'> 
                                                <i className="fa fa-1x fa-trash"> </i>
                                            </button>
                                        </div>
                                    </Form>
                                    )}
                                    </div>
                                </div>
                                <div className="module_questions  mt-4">	
                                    <Label>{i18next.t('bot_can_response')}</Label>
                                    <Form className="form-wrapper" onSubmit={(e) => props.appendAnswer(e)}>
                                        <input type="text" name="answer" className="answer_input" placeholder={i18next.t('write_response')} required/>
                                        <button type="submit" className="btn-save"><i className="fa fa-save"> </i></button>
                                    </Form>
                                    <div className="respuestas_ia col-12" >
                                    {props.currentIntent.answers.map((item,index) => 
                                        <Form key={index} onSubmit={(e) => props.removeAnswer(e)}>
                                        <div className='list-group-item list-group-item-action card-body-question' onMouseEnter={props.showOptions} onMouseLeave={props.hideOptions} >
                                                {item}
                                                <Input type="hidden" defaultValue={index} name="index" hidden/> 
                                                <button type='submit' className='btn btn-danger pull-right btn-delete'> 
                                                <i className="fa fa-1x fa-trash"> </i>
                                                </button>
                                        </div>
                                        </Form>
                                        )}  
                                    </div>
                                </div>
                                    
                            </Col>
                        

                            { props.currentIntent.id ? (
                            <Col>
                                <Row>
                                <Col xs="6" className="pt-4">
                                        <h2 className="font-weight-bold">{i18next.t('delete_module')}</h2>
                                </Col> 
                                <Col xs="6" className="text-right">
                                <Form onSubmit={props.deleteIntent}>
                                        <Input type="hidden" defaultValue={props.currentIntent.id} name="id" hidden />
                                        <Button type='submit' className='btn btn-danger btn-16' style={{'fontSize':'14px','marginTop':'10px'}}> {i18next.t('delete_module')}</Button>
                                </Form>
                                </Col> 
                                </Row>
                            </Col>
                            ): null}
                        </Row>
                        ):null}
                       </Col>
                    </Col>
                </Row>
            </Container>
          </Admin>      
    </div>
    );
}
  
export default BotIALayoutTable;