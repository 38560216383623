import React, { Component} from 'react';
import { connect } from "react-redux";

import { Input,Button,Modal,ModalHeader,ModalBody,ModalFooter,Form,FormGroup,Label,Col,Row,Nav,NavItem,TabContent,TabPane,NavLink,UncontrolledTooltip, Alert} from 'reactstrap';
import IASelector from '../../../../components/ia'
//import Mentions from '../../../../components/mentions/metions-alone';
import Keyword from '../../../../components/keyword/keyword-redux';
import Mentions from '../../../../components/mentions/metions-redux';
import TitleOperator from "../../../../components/title-operator";
import Emoji from '../../../../components/emoji-picker';
import classnames from 'classnames';
import i18next from '../../../../../i18n';

import SelectStatements from '../../../../components/selected-statements'

import { 
    setChangeConfig,
    toggleModalViewBoxAgent
     } from "../../../../redux/actions/boxActions";
     
class APMLayout extends Component {
    constructor(props){
        super(props)
        this.state = {
            activeTab: '1',
            openOtherVars: true
        };
        this.toggleTab = this.toggleTab.bind(this);
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
          this.setState({
            ...this.state,
            activeTab: tab,
          });
        }
    }


    toggleOtherVars(event){
        event.preventDefault();
        this.setState({
            ...this.state,
            openOtherVars: !this.state.openOtherVars,
          });
    }
    
    
    render(){
        const label = i18next.t('switch_to_agent_var') + ' ';
        const tooltip = i18next.t('switch_to_agent_msg'); 
        const tooltipOtras = i18next.t('switch_to_agent_msg2'); 


        const { selectedBoxOperator,varList, varListSelected,configBoxOperator,avatarid,botmsg_text,modalViewBoxAgent } = this.props.boxReducer;
        const { bot,botVersionId } = this.props.botReducer;
        if(selectedBoxOperator&&Object.keys(selectedBoxOperator).length>0&&selectedBoxOperator.operatortype_id==9)
        {
            return (
                <div>
                    <Modal className="agente" isOpen={modalViewBoxAgent} toggle={this.props.toggleModalViewBoxAgent} fade size="lg">
                        <ModalHeader toggle={this.props.toggleModalViewBoxAgent}>{i18next.t('switch_to_agent_msg3') }</ModalHeader>
                        <Form id="APM-form" role="form">  
                        <ModalBody>
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab === '1' })}
                                            onClick={() => { this.toggleTab('1'); }} >
                                        {i18next.t('basic')}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab === '2' })}
                                            onClick={() => { this.toggleTab('2'); }}>
                                        {i18next.t('advanced')}
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={this.state.activeTab}>
                                    <TabPane tabId="1">
                                        <Col sm="12">
                                            <TitleOperator titleOperator={selectedBoxOperator.title} />
                                            <FormGroup>
                                                <Label>{i18next.t('welcome_msg')} <i className="fa fa-info-circle" id="tooltipEnunciado"></i></Label>
                                                <UncontrolledTooltip placement="right" target="tooltipEnunciado"> {i18next.t('welcome_msg2')}</UncontrolledTooltip>
                                                
                                                <Mentions defaultValue={botmsg_text || ''}  maxlength={'2000'} msg_error={i18next.t('msg_error_limit')} k={0} updateItem={this.props.updateItem} index={0}  />
                    
                                               { this.props.error_head_empty && 
                                                    <span className="warning-character">{i18next.t('add_message')}</span>
                                                } 
                                                <div className="options-bar-input" style={{'right':'1px'}}>
                                                    <Emoji id={'head'} callEmoji={this.props.callEmoji} type="head" style={{'display':'none',position: 'absolute',right: 268+'px',zIndex: 100}}/>
                                                    
                                                </div>
                                            </FormGroup>
                                            <Label>{i18next.t('switch_to_agent_url')+': '}  <i className="fa fa-info-circle" id="tooltipUrl"></i></Label>
                                            <UncontrolledTooltip placement="right" target="tooltipUrl"> {i18next.t('switch_to_agent_msg4')}</UncontrolledTooltip>
                                            
                                            <Input className="modal-input" id="input-url" name="url" type="url" defaultValue={this.props.url} placeholder={i18next.t('attention') + " e: http://... o https://... "} />
                                            <Alert color="warning">
                                                <small><strong>{i18next.t('attention')+': '} </strong> {i18next.t('message')}</small>
                                            </Alert>
                                        
                                            {varListSelected&&varListSelected.length > 0 ? (
                                                    <SelectStatements 
                                                    varList={ varListSelected } 
                                                        varSelected={ configBoxOperator && configBoxOperator.variable_agent?configBoxOperator.variable_agent:'' } 
                                                        onchangeStatements={ this.props.setChangeConfig } 
                                                        nameVar={ 'variable_agent' } 
                                                        label={ label } 
                                                        tooltip={ tooltip }
                                                        k={'0'}
                                                        placeholder={i18next.t('select')}
                                                    ></SelectStatements>
                                            ):null}

                                                <div style={{ marginTop: 20 }}>
                                                    {
                                                        !this.state.openOtherVars?(
                                                            <div style={{ clear: "both" }}>
                                                                <a 
                                                                    href="#"
                                                                    style={{ float: "right" }}
                                                                    onClick={(e)=> this.toggleOtherVars(e) }
                                                                >{i18next.t('other_vars')}...</a>
                                                            </div>
                                                        ):null
                                                    }

                                                    {
                                                        varListSelected&&varListSelected.length > 0 && this.state.openOtherVars ? (
                                                            <SelectStatements 
                                                                varList={ varListSelected } 
                                                                varSelected={ 
                                                                    configBoxOperator && (typeof configBoxOperator.var_list == "string")?
                                                                    JSON.parse(configBoxOperator.var_list):
                                                                    configBoxOperator.var_list
                                                                } 
                                                                onchangeStatements={ this.props.setChangeConfig } 
                                                                nameVar={ "var_list" } 
                                                                isMulti={ true }
                                                                label={ "Otras variables" } 
                                                                tooltip={ tooltipOtras }
                                                                 placeholder={i18next.t('select')}
                                                            ></SelectStatements>
                                                        ):null
                                                    }
                                                </div>
                                           
                                        </Col>
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <Row>
                                        <Col sm="6">
                                            {selectedBoxOperator?(
                                                <IASelector botversion_id={botVersionId} operator_id={selectedBoxOperator.id} ia={selectedBoxOperator.ia_id} />
                                            ):null}
                                        </Col>
                                        <Col sm="6">
                                            <Keyword keyword={selectedBoxOperator.keyword} isValid={this.props.isKeywordValid} />
                                        </Col>
                                        </Row>
                                    </TabPane>
                                </TabContent>
                        </ModalBody>
                        <ModalFooter>
                                <Col sm="12" align="right">
                                    <Button color="success" size="lg" type="button" onClick={this.props.toggleModuleModal} >{i18next.t('finish')}</Button>
                                </Col>
                        </ModalFooter>
                        </Form>
                    </Modal>           
                </div>
            );
        }
        else
        {
            return(<div></div>)
        }
    }
}

 
const mapStateToProps = ({botReducer, boxReducer,homeReducer}) => {
    return {botReducer,boxReducer, homeReducer};
    };
    
    const mapDispatchToProps = {
        setChangeConfig,
        toggleModalViewBoxAgent
    };
    
    export default connect(mapStateToProps, mapDispatchToProps )(APMLayout);

 