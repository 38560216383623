import React, { Component } from 'react';
import {  
    FormGroup, 
    Input, 
    Col,
    Row,
    Label,
    UncontrolledTooltip
} from 'reactstrap';

import i18next from "../../i18n";

class MsgError extends Component {

    state = {
        msgError: this.props.msgError
    }

    constructor(props){
        super(props);
    }

    bindValue = () => {
        if(typeof this.props.bindValue === "function")
            this.props.bindValue(this.state.msgError);
      }

    updateType = (event) => {
        this.setState({msgError: event.target.value},
            () => {
                this.bindValue();
            }
        );
    }

    componentDidMount = () => {
        this.setState({ msgError: this.props.msgError});
    }

    render() {

        return (
            <Row>
                <Col sm="12">
                    <FormGroup>
                        <Label>{i18next.t('message_error_label')} <i className="fa fa-info-circle" id="tooltipMsgError"></i></Label>
       
                        <UncontrolledTooltip placement="right" target="tooltipMsgError"> {i18next.t('message_error')} </UncontrolledTooltip>
                        <Input 
                         type="text" 
                         name="typesFile"
                         onChange={(event) => { this.updateType(event) }}
                         defaultValue={this.state.msgError}
                         maxLength={'50'}/>
                    </FormGroup>
                </Col>
            </Row>
        )
    }
}

export default MsgError