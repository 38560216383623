import React from 'react';
import { connect } from "react-redux";
import { UncontrolledTooltip,FormGroup,Input} from 'reactstrap';
import { handleSearching , getAllbots } from "../../../redux/actions/homeActions";
import i18next from '../../../../i18n';

function keyboard(e,fn){
    if(e.key == "Enter"){
        fn(1)
    }
}

function Buscador(props){
    const { handleSearching ,getAllbots, homeReducer: { searcher = '' } } = props;
    return (
            <FormGroup className='float-search-box' id="float-search-box">
                <span className="search-button"></span>
                <Input className='float-search-input' type="text" value={searcher} onChange={handleSearching} onKeyDown={(e) => {keyboard(e,getAllbots)}} id="buscador" name="buscador" placeholder={i18next.t('search')} aria-describedby="button-addon1"/>     
                <UncontrolledTooltip placement="right" target="buscador">{i18next.t('search')}</UncontrolledTooltip>
            </FormGroup>

    );
}

const mapStateToProps = ({ homeReducer }) => {
	return { homeReducer  };
};
const mapDispatchToProps = {
    handleSearching ,
    getAllbots 
};

export default connect(mapStateToProps, mapDispatchToProps)(Buscador);

