import React, {useState} from "react";

import ReactExport from "react-export-excel";

import CustomToolbar from "../common/CustomToolbar";

import { getHandlerGET } from "../../../../utils/handlersApi";


import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

import DataTableFilter from "../common/dataTableFilter";

import PaginationTable from "../common/paginationTable";

const utf8 = require("utf8");
var dateFormat = require("dateformat");
import moment from "moment";

import { Col, Button,Badge, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import { getTockenCubejs, seleccionarReporte } from "../../../../redux/actions/analyticsActions";
//import { findConfigFile } from "typescript";

import { QueryRenderer } from "@cubejs-client/react";
import cubejs from "@cubejs-client/core";
import { Row } from "antd";
import i18next from "../../../../../i18n";

let dataframeColumUno = [
  "city",
  "name",
  "address",
  "phone",
  "nacionalidad",
  "company",
  "identity",
  "date",
  "city",
  "name",
  "address",
  "phone",
  "nacionalidad",
  "company",
  "identity",
  "date",
  "details",
  "sessions"];
  
  let dataframeDataUno = [
    [
      "Bucaramanga",
      "SERGIO AMAYA DOS",
      "Calle 50 No. 8-14",
      "+573202921664",
      "Colombia",
      7,
      "1234567892",
      "2023-02-22T20:56:13.818000",
      "Bucaramanga",
      "SERGIO AMAYA DOS",
      "Calle 50 No. 8-14",
      "+573202921664",
      "Colombia",
      7,
      "1234567892",
      "2023-02-22T20:56:13.818000",
      [
        {
          "session_id": 888888888,
          "platform": "whatsapp",
          "bot_version": 1330,
          "sender_id": "+5491137664874"
        },
        {
          "session_id": 99999999,
          "platform": "whatsapp",
          "bot_version": 1330,
          "sender_id": "+5491137664874"
        }
      ],
      [
        68155222
      ]
    ],
    [
      "Bucaramanga",
      "SERGIO RUEDA DOS",
      "Calle 50 No. 8-14",
      "+573202921664",
      "Colombia",
      7,
      "1234567893",
      "2023-02-22T20:57:34.090000",
      "Bucaramanga",
      "SERGIO RUEDA DOS",
      "Calle 50 No. 8-14",
      "+573202921664",
      "Colombia",
      7,
      "1234567893",
      "2023-02-22T20:57:34.090000",
      [
        {
          "session_id": 68155223,
          "platform": "whatsapp",
          "bot_version": 1330,
          "sender_id": "+5491137664874"
        }
      ],
      [
        68155223
      ]
    ]
  ]
  
  let datadata = dataframeDataUno.map((d) => {
    return d.map( (dd) => {
        if(typeof dd === 'number' || typeof dd === 'string'){
            return dd;	
        }else{
          return JSON.stringify(dd);
        }
        
    })
  })
  
function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];

const DataTable = ({ resultSet, props }) => {
  
  const [modal, setModal] = useState(false);
  const [dataModal, setDataModal] = useState(false);
  const [dataframeColum, setDataframeColum] = useState();
  const [dataframeData, setDataframeData] = useState();
  
  let dataTable = null;
  let columns = [];
  let total_columns = [];
  let headers = [];
  let title = "";
  let url = props.match.path;

  const { granularity, pagination, selectedBot,selectedCompany } = props.analyticsReducer;
  const { botVersionId } = props.botReducer?props.botReducer:'';

  async function  modalInformacion(enviaData){
        let dataEnviar = enviaData
        console.log("dataEnviar ",dataEnviar)
        const url = `${HOST_API}/api/cube_metrics/report/customers_report?company=`+selectedCompany+`&session_id=`+dataEnviar.session_id+`&bot_version=`+botVersionId+`&platform=`+dataEnviar.platform;
      await getHandlerGET(url)
        .then((res) => {
          console.log("res ",res)
          if (res.status == 200) {
            if (res.data) {
                if(res.data.message == "No records found "){
                  setDataModal(false);
                  console.log("res.data ",res.data);
                }else{
                  console.log("res.dataframe.columns ",res.data.dataframe.columns);
                  setDataframeColum(res.data.dataframe.columns);
                  console.log("res.data ",res.data.dataframe.data);
                  setDataframeData(res.data.dataframe.data);
                  setDataModal(true);
                }
                console.log("res.data ",res.data.message);
            }
          }
        })
        .catch((error) => {
          console.log("ERROR ",error)
        });
        toggle();
  }

  /*MODAL */
   const toggle = () => setModal(!modal);
  /*FIN MODAL */

  if (resultSet.resultSet) {
    //const { granularity, pagination, selectedBot,selectedCompany } = props.analyticsReducer;
    
    let data = {};
    let datosTotal = [];
    let preview = [];

    let numDimensions = 0;
    let numMeasures = 0;
    let numTimeDimensions = 0;

    if (resultSet.resultSet.loadResponse.query.dimensions)
      numDimensions = resultSet.resultSet.loadResponse.query.dimensions.length;

    if (resultSet.resultSet.loadResponse.query.measures)
      numMeasures = resultSet.resultSet.loadResponse.query.measures.length;

    if (resultSet.resultSet.loadResponse.query.timeDimensions)
      numTimeDimensions =
        resultSet.resultSet.loadResponse.query.timeDimensions.length;

    if (numTimeDimensions > 0) {
      if (
        resultSet.resultSet.loadResponse.query.timeDimensions[0].granularity ==
        null
      )
        numTimeDimensions = 0;
    }

    let total_dimensions = resultSet.resultSet.loadResponse.query.dimensions;
    let total_measures = resultSet.resultSet.loadResponse.query.measures;
    let total_timedimensions = resultSet.resultSet.loadResponse.query.timeDimensions;

    dateFormat.i18n = {
      dayNames: [
        "Dom",
        "Lun",
        "Mar",
        "Mier",
        "Jue",
        "Vie",
        "Sab",
        "Domingo",
        "Lunes",
        "Martes",
        "Miércoles",
        "Jueves",
        "Viernes",
        "Sábado",
      ],
      monthNames: [
        "Ene",
        "Feb",
        "Mar",
        "Abr",
        "May",
        "Jun",
        "Jul",
        "Ago",
        "Sep",
        "Oct",
        "Nov",
        "Dic",
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
      timeNames: ["a", "p", "am", "pm", "A", "P", "AM", "PM"],
    };

    if (total_dimensions) {
      for (var h = 0; h < total_dimensions.length; h++) {
        const c = {
          label:
            resultSet.resultSet.loadResponse.annotation.dimensions[
              total_dimensions[h]
            ].shortTitle,
          name: resultSet.resultSet.loadResponse.annotation.dimensions[
            total_dimensions[h]
          ].shortTitle,
          field: total_dimensions[h],
          sort: "asc",
          style: { font: { sz: "24", bold: true } },
        };
        columns.push(c);
        headers.push(c.label);
        total_columns.push(total_dimensions[h]);
      }
    }
    let time_dimension = "";

    if (
      total_dimensions.length >= 1 ||
      total_timedimensions[0].dimension == "Conversations.startTime"
    ) {
      if (total_timedimensions) {
        for (var y = 0; y < total_timedimensions.length; y++) {
          if(resultSet.resultSet.loadResponse.annotation.timeDimensions[total_timedimensions[y].dimension]){
          const t = {
            label:
              resultSet.resultSet.loadResponse.annotation.timeDimensions[
                total_timedimensions[y].dimension
              ].shortTitle,
            name: resultSet.resultSet.loadResponse.annotation.timeDimensions[
              total_timedimensions[y].dimension
            ].shortTitle,
            field: total_timedimensions[y].dimension,
            sort: "asc",
          };
          columns.push(t);
          time_dimension = t.field;
          headers.push(t.label);
          total_columns.push(total_timedimensions[y].dimension);
        }
        }
      }
    }

    if (total_measures) {
      for (var g = 0; g < total_measures.length; g++) {
        const m = {
          label:
            resultSet.resultSet.loadResponse.annotation.measures[
              total_measures[g]
            ].shortTitle,
          name: resultSet.resultSet.loadResponse.annotation.measures[
            total_measures[g]
          ].shortTitle,
          field: total_measures[g],
          sort: "asc",
        };
        columns.push(m);
        headers.push(m.label);
        total_columns.push(total_measures[g]);
      }
    }

    dataTable = {
      columns: columns,
      rows: [],
    };

    const rows = resultSet.resultSet.rawData().map((k, index) => {
      const fin = [];
      const rowExcel = {};
      let type = "";
      for (var t = 0; t < total_columns.length; t++) {
        const dimension =
          resultSet.resultSet.loadResponse.annotation.dimensions[
            total_columns[t]
          ];
        const measure =
          resultSet.resultSet.loadResponse.annotation.measures[
            total_columns[t]
          ];
        if (dimension) type = dimension.type;
        else if (measure) type = measure.type;

        if (
          total_columns[t] == "ChatTraceability.textMessage" &&
          k["ChatTraceability.interactorName"] == "Usuario"
        ) {
          //if(total_columns[t]=='ChatTraceability.message'&&k['ChatTraceability.interactorName']=='Bot')
          let message = k[total_columns[t]]; //JSON.parse(k[total_columns[t]])
          try {
            let message = k[total_columns[t]]; //JSON.parse(k[total_columns[t]])
            //  console.log("antes: ",message);
            // console.log("encode: ",utf8.encode(message));
            // console.log("decode: ",utf8.decode(message));
            //base64.encode(utf8.encode(h.text))
            fin.push(utf8.decode(message));
          } catch (error) {
            try {
              fin.push(message);
            } catch (error) {
              // console.log("Message error",error.message);
            }
            // console.log("Message no codificado:", error.message);
          }
        } else if (total_columns[t] == "ChatTraceability.textMessage") {
          let mess = k[total_columns[t]];
          if (mess) {
            mess = mess.toString().replace(/(?:\r\n|\r|\n|\\n)/g, " ");
            try {
              //console.log("Mensaje original: "+mess+" men dec: "+decodeURIComponent(mess)+" mens utf8: "+utf8.decode(mess)+" encode "+ utf8.encode(mess)+" otro: "+decodeURIComponent(escape(mess)));
              fin.push(utf8.decode(mess));
            } catch (error) {
              fin.push(mess);
            }
          } else fin.push(" ");
          //  /(?:\r\n|\r|\n)/g
        } else if (total_columns[t] == time_dimension) {
          let date = k[total_columns[t]];

          if (granularity == "year") {
            date = dateFormat(date, "yyyy");
          } else if (granularity == "month") {
            date = dateFormat(date, "yyyy-mm");
          } else if (granularity == "week" || granularity == "day") {
            date = dateFormat(date, "dddd, dd-mm-yyyy");
          } else if (granularity == "hour") {
            date = dateFormat(date, "dddd, dd-mm-yyyy hh tt");
          } else {
            date = dateFormat(date, "dddd, dd-mm-yyyy hh:MM:ss.l tt");
          }

          fin.push(date);
        } else if (
          total_columns[t] == "ChatTraceability.moment" ||
          total_columns[t] == "Conversations.startTime"
        ) {
          let date = k[total_columns[t]];
          //  console.log("Fecha desde cube: ",date);
          date = moment.utc(date).tz("America/Bogota");
          // console.log("Fecha después moment: ",date);
          date = dateFormat(date, "yyyy-mm-dd HH:MM:ss.l Z");
          //   console.log("Fecha desde dateFormat: ",date);
          fin.push(date);
        } else {
          if (type == "number") fin.push(Number(k[total_columns[t]]));
          else fin.push(k[total_columns[t]]);
        }
      }
      dataTable.rows.push(fin);
      return k;
    });

    const configStyle = {
      position: "absolute",
      right: "15px",
      top: 0,
      cursor: "pointer",
    };

    let options = {
      filterType: "multiselect",
      selectableRows: "none",
      resizableColumns: false,
      responsive: "scrollMaxHeight",
      rowsPerPage: 5,
      customToolbar: () => {
        return (
          <CustomToolbar
            dataTable={dataTable}
            resultSet={resultSet}
            props={props}
          />
        );
      },
    };

    if (
      props.analyticsReducer.modalNewDashboard ||
      props.analyticsReducer.modalViewChart ||
      props.analyticsReducer.modalNewChart ||
      props.analyticsReducer.modalViewMetric ||
      props.analyticsReducer.modalNewMetric
    ) {
      options.filter = false;
      options.print = false;
      options.download = false;
      options.viewColumns = false;
    }

    if (props) {
      if (props.metric) title = props.metric.short_name;
      else {
        title = i18next.t('first_1000_records');
        options.rowsPerPage = 100;
      }
      return (
        <React.Fragment>
          <Col xs={12} lg={12}>
            <DataTableFilter
              title={title}
              data={dataTable.rows}
              columns={dataTable.columns}
              options={options}
            />
          </Col>
        </React.Fragment>
      );
    } else {
      title = i18next.t('report_interactions');
      var match = [];
      match.params = [];
      match.params.id = 0;

      getTockenCubejs();

      const { tokenCubejs, operationCount } = props.analyticsReducer;

      const cubejsApi = cubejs(tokenCubejs, { apiUrl: CUBE_API });

      return (
        <QueryRenderer
          query={operationCount}
          cubejsApi={cubejsApi}
          render={(resultSet) => {
            if (!resultSet.resultSet) {
              return (
                <img
                  src="/static/img/loading.gif"
                  style={{ display: "block", margin: "auto" }}
                />
              );
            } else if (resultSet.resultSet.loadResponse) {
              let rawData = resultSet.resultSet.rawData();
              const aux = rawData[0];

              //  dispatch({
              //     type: ANALYTICS_ACTIONS_TYPES.setPagination(),
              //     payload: aux
              // });

              var paginationNew = [];
              paginationNew.current = pagination.current;
              paginationNew.pages = Number(aux["ChatTraceability.count"]) / 10;

              return (
                <PaginationTable
                  title={title}
                  data={dataTable.rows}
                  columns={dataTable.columns}
                  pagination={paginationNew}
                  props={props}
                />
              );
            }
          }}
        />
      );
    }
  } else {
    title = i18next.t('first_1000_records');

    const data = verificarLinks(resultSet);
    if(url == '/reportes'){

      /*AGREGAMOS EL "VER" EN EL ARRAY */
      if(resultSet.columns){
        if(!resultSet.columns.includes('Ver')){
          resultSet.columns.push('Ver')
        }
      }
      /*FIN AGREGAMOS EL "VER" EN EL ARRAY */

    
      if(data){
          for(var i=0; i < data.length; i++){
              let platform = data[i][2];
              let sender_id = data[i][3];
              let session_id = data[i][1];
              let enviaData = {platform, sender_id, session_id};
              data[i].push(<Button key={"buttom_view_blockchain_"+i} 
                                   type="submit" className="btn-square btn-link-eye"
                                   onClick={(e)=>{modalInformacion(enviaData)}}>
                                    <i className="fa fa-eye "></i>
                          </Button>);
          }
      }
      resultSet.columns?(
        resultSet.columns.length <= 10 ?(dataTable = {
        columns: resultSet.columns,
        rows: data,
      }):(dataTable = {
        columns: [],  
        rows: [],
      })):(dataTable = {
        columns: [],
        rows: [],
      })

    }else if(url == '/reportes/user'){
      
        /*AGREGAMOS EL "VER" EN EL ARRAY */
        if(resultSet.columns){
          if(!resultSet.columns.includes('Ver')){
            //resultSet.columns.push('Ver')
          }
        }
        /*FIN AGREGAMOS EL "VER" EN EL ARRAY */
  
      
        if(data){
            for(var i=0; i < data.length; i++){
                let platform = data[i][2];
                let sender_id = data[i][3];
                let session_id = data[i][1];
                let enviaData = {platform, sender_id, session_id};
                /*data[i].push(<Button key={"buttom_view_blockchain_"+i} 
                                      type="submit" className="btn-square btn-link-eye"
                                      onClick={(e)=>{modalInformacion(enviaData)}}>
                                      <i className="fa fa-eye "></i>
                            </Button>);*/
            }
        }
        resultSet.columns?(
          resultSet.columns.length <= 10 ?(dataTable = {
          columns: resultSet.columns,
          rows: data,
        }):(dataTable = {
          columns: [],  
          rows: [],
        })):(dataTable = {
          columns: [],
          rows: [],
        })

    }else if(url == '/reportes/sms'){
        /*AGREGAMOS EL "VER" EN EL ARRAY */
        if(resultSet.columns){
          if(!resultSet.columns.includes('Ver')){
            //resultSet.columns.push('Ver')
          }
        }
        /*FIN AGREGAMOS EL "VER" EN EL ARRAY */
  
      
        if(data){
            for(var i=0; i < data.length; i++){
                let platform = data[i][2];
                let sender_id = data[i][3];
                let session_id = data[i][1];
                let enviaData = {platform, sender_id, session_id};
                /*data[i].push(<Button key={"buttom_view_blockchain_"+i} 
                                      type="submit" className="btn-square btn-link-eye"
                                      onClick={(e)=>{modalInformacion(enviaData)}}>
                                      <i className="fa fa-eye "></i>
                            </Button>);*/
            }
        }
        resultSet.columns?(
          resultSet.columns.length <= 10 ?(dataTable = {
          columns: resultSet.columns,
          rows: data,
        }):(dataTable = {
          columns: [],  
          rows: [],
        })):(dataTable = {
          columns: [],
          rows: [],
        })

    }else if(url == '/reportes/custom'){
      /*AGREGAMOS EL "VER" EN EL ARRAY */
      console.log("resultSet ",resultSet);
      console.log("resultSet.columns ",resultSet.columns);
      if(resultSet.columns){
        if(!resultSet.columns.includes('Ver')){
          //resultSet.columns.push('Ver')
        }
      }
      /*FIN AGREGAMOS EL "VER" EN EL ARRAY */

    
      if(data){
        console.log("data 1 ",data);
          for(var i=0; i < data.length; i++){
              let platform = data[i][2];
              let sender_id = data[i][3];
              let session_id = data[i][1];
              let enviaData = {platform, sender_id, session_id};
              /*data[i].push(<Button key={"buttom_view_blockchain_"+i} 
                                    type="submit" className="btn-square btn-link-eye"
                                    onClick={(e)=>{modalInformacion(enviaData)}}>
                                    <i className="fa fa-eye "></i>
                          </Button>);*/
          }
      }
      resultSet.columns?(
        resultSet.columns.length <= 10 ?(dataTable = {
        columns: resultSet.columns,
        rows: data,
      }):(dataTable = {
        columns: resultSet.columns,
        rows: data,
      })):(dataTable = {
        columns: [],
        rows: [],
      })

  }else if (url == '/customersReport'){
      let dataJson = '';
      
      if(data){
        
        dataJson = data.map((d) => {
          return d.map( (dd) => {
              if(typeof dd === 'number' || typeof dd === 'string'){
                  return dd;	
              }else{
                return dd.props?'':JSON.stringify(dd);
              }
              
          })
        })

      }


      dataTable = {
        columns: resultSet.columns,
        rows: dataJson?dataJson:[],
      };
    }else{
      let dataJson = '';
      dataTable = {
        columns: resultSet.columns,
        rows: dataJson?dataJson:[],
      };
    }
    let options = '';
    if(url == '/customersReport'){
       options = {
        filterType: "multiselect",
        selectableRows: "none",
        resizableColumns: false,
        responsive: "scrollMaxHeight",
        rowsPerPage: 100,
        expandableRows: true,
        expandableRowsHeader: false,
        expandableRowsOnClick: true,
        isRowExpandable: (dataIndex, expandedRows) => {
      
          // Prevent expand/collapse of any row if there are 4 rows expanded already (but allow those already expanded to be collapsed)
          if (expandedRows.data.length > 4 && expandedRows.data.filter(d => d.dataIndex === dataIndex).length === 0)
            return true;
          return true;
        },
        rowsExpanded: [0, 1],
        renderExpandableRow: (rowData, rowMeta) => {
          const colSpan = rowData.length + 1;
      
          let nuevaData = rowData.map((r) => {
            try {
                return JSON.parse(r);
              } catch (e) {
                return r;
              }
          })
      
          return (
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
             
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {
      
                        nuevaData.map((t,i) => {
                          return typeof nuevaData[i] === "object"?
                          Object.keys(nuevaData[i][0]).map((k) => {
                            return (
                                <TableCell>{k}</TableCell>
                            )
                        }):''
                        })
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                  {
                    nuevaData.map((t,i) => {
                      return typeof nuevaData[i] === "object"?
                        nuevaData[i].map((d) => {
                          return (
                             <TableRow key={d.session_id}>
                                    <TableCell component="th" scope="row">{d.session_id}</TableCell>
                                      <TableCell component="th" scope="row">{d.platform}</TableCell>
                                      <TableCell component="th" scope="row">{d.bot_version}</TableCell>
                                      <TableCell component="th" scope="row">{d.sender_id}</TableCell>
                                </TableRow>
                          )
                        })
                      :''
                    })
                  }
              </TableBody>
            </Table>
          
            </Table>
          );
        },
        customToolbar: () => {
          return (
            <CustomToolbar
              dataTable={dataTable}
              resultSet={resultSet}
              props={props}
            />
          );
        },
      };
  
    }else{
     options = {
      filterType: "multiselect",
      selectableRows: "none",
      resizableColumns: false,
      responsive: "scrollMaxHeight",
      rowsPerPage: 100,
      customToolbar: () => {
        return (
          <CustomToolbar
            dataTable={dataTable}
            resultSet={resultSet}
            props={props}
          />
        );
      },
    };
    }

    return (
      <React.Fragment>
        <Col xs={12} lg={12}>
        <Modal isOpen={modal} toggle={toggle} className="text-center modal-lg">
        <ModalHeader toggle={toggle} className="text-center modal-lg">{i18next.t('report_interactions')}</ModalHeader>
        <ModalBody className="text-center modal-lg">
         {dataModal?
         (<Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableBody>
              {
                dataframeColum.map((i,c) => {
                    return (<TableRow
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                <TableCell component="th" scope="row">
                                    {i}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {typeof dataframeData[0][c] === 'number' || typeof dataframeData[0][c] === 'string'
                                     ?dataframeData[0][c]:(<Table sx={{ minWidth: 650 }} aria-label="caption table">
                                                <TableHead>
                                                <TableRow>
                                                    {
                                                        typeof dataframeData[0][c][0] === "object"?
                                                        Object.keys(dataframeData[0][c][0]).map((k) => {
                                                            return (
                                                                <TableCell>{k}</TableCell>
                                                            )
                                                        }):<TableCell>session_id</TableCell>
                                                    }
                                                    
                                                </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                {typeof dataframeData[0][c][0] === "object"?
                                                dataframeData[0][c].map((row) => (
                                                    <TableRow key={row.session_id}>
                                                    
                                                    <TableCell component="th" scope="row">
                                                        {row.session_id}
                                                    </TableCell>

                                                    <TableCell component="th" scope="row">
                                                        {row.platform}
                                                    </TableCell>

                                                    <TableCell component="th" scope="row">
                                                        {row.bot_version}
                                                    </TableCell>

                                                    <TableCell component="th" scope="row">
                                                        {row.sender_id}
                                                    </TableCell>

                                                    </TableRow>
                                                )):''
                                       
                                                }
                                                </TableBody>
                                            </Table>)
                                     }
                                </TableCell>
        
                                </TableRow>);
                })
            }
              </TableBody>
            </Table>
         ):( <Row>
          <Col xs={12} className="text-center"><img src="/static/img/blank_state.png" className="img-fluid text-center"/></Col>
          <Col xs={12}>
              <h2>{i18next.t('report_interactions_msg')}</h2>
              <h3>{i18next.t('report_interactions_msg2')}</h3>
          </Col>
      </Row>)}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
          {i18next.t('cancel')}
          </Button>
        </ModalFooter>
      </Modal>

          <DataTableFilter
            title={title}
            data={dataTable.rows}
            columns={dataTable.columns}
            options={options}
          />
        </Col>
      </React.Fragment>
    );
  }
};

function verificarLinks(resultSet)
{
  let data = resultSet.data;
  if(data)
  {
      if(data.length>0){
      
              const rows = data.map((k, index) => {    
          
                  const fin=[];
                  let valor='';
                  for(var j=0;j<k.length;j++)
                  {
                    valor=''+k[j];

                      if(valor.startsWith('http'))
                      {
                          fin.push(
                              <Row key={k.id} className="d-flex align-items-center justify-content-center">
                                      <Col className="col-2"> 
                                         <Badge href={k[j]} target="_blank" color="info">{i18next.t('see_more')}</Badge>
                                       </Col>
                                     
                                  </Row>
                          )
                      }
                      else
                          fin.push(k[j]);
                  } 
                  return fin;
              });
              return rows;
          }
      }
}

export default DataTable;
