import React, { Component } from "react";
import { Col } from 'reactstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export class Date extends Component {
       
    render() {
      return (
            <Col className="col-12">
                    <DatePicker
                        selected={this.props.startDate}
                        selectsStart
                        startDate={this.props.startDate}
                        endDate={this.props.endDate}
                        onChange={this.props.handleChangeStart}
                        isClearable={true}
                        className="input-border"
                        dateFormat="YYYY/MM/DD"
                        className="form-control"
                    />

                    <DatePicker
                        selected={this.props.endDate}
                        selectsEnd
                        startDate={this.props.startDate}
                        endDate={this.props.endDate}
                        onChange={this.props.handleChangeEnd}
                        isClearable={true}
                        className="input-border"
                        dateFormat="YYYY/MM/DD"
                        className="form-control"
                    /> 
            </Col>
      );
    }
  }
  
  export default Date;