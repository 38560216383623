import React, { Component } from "react";
import { connect } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

import { find, propEq } from "ramda";

import {
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  Col,
  Row,
  InputGroup,
  InputGroupAddon,
  thumbsImgModal,
  UncontrolledTooltip,
  Table,
  Nav,NavItem,TabContent,TabPane,NavLink
} from "reactstrap";
import Select from "react-select";

import Mentions from "../../../../components/mentions/metions-redux";

require("../../../../components/modals/modal.css");

import Dropzone from "react-dropzone";
import { faTimes, faCheck } from "@fortawesome/free-solid-svg-icons";

import noMultimedia from "../../../../../static/img/noimg.jpg";

import {
  setSelectedTypeMultimedia,
  toggleModalViewBoxMultimedia,
  changeActiveTab,
  setChangeConfig,
  setInitialDinamicVariables,
  updateVariableBoxOperator,
  setUrlMultimediaPreview,
  setContentType,
  updateBoxOperator,
  removeStatementBlock,
  
} from "../../../../redux/actions/boxActions";

import { getVariableTemplate,imageTemplate,updateTempVariableTemplate } from "../../../../redux/actions/templateActions";
import classnames from 'classnames';

import TitleOperator from "../../../../components/title-operator";
import i18next from "../../../../../i18n";

let item_type, multimedia_type;

function MMMLayout(props) {
  const {
    selectedBoxOperator,
    varList,
    varListSelected,
    selectedTypeMultimedia,
    contentType,
    configBoxOperator,
    modalViewBoxMultimedia,
    activeItem,
    variablesBoxOperator,
    dinamicVariablesBoxOperator,
    avatarid,
    botmsg_text,
    exit_var,
    fileMultimediaPreview,
    urlMultimediaPreview,
    node,
  } = props.boxReducer;
  const { listAllTemplate, tempVariablesTemplate} = props.templateReducer;

  const { bot, botVersionId } = props.botReducer;
  item_type = null;
  multimedia_type = null;

  if (
    selectedBoxOperator &&
    Object.keys(selectedBoxOperator).length > 0 &&
    selectedBoxOperator.operatortype_id == 13
  ) {
    if (configBoxOperator && configBoxOperator.item_type) {
      item_type = selectedTypeMultimedia; //configBoxOperator.item_type;
      multimedia_type = configBoxOperator.multimedia_type
        ? configBoxOperator.multimedia_type
        : "";

      let name_varSelected = find(
        propEq("value", parseInt(configBoxOperator.url_statement))
      )(varListSelected);
      let name_templateSelected = find(
        propEq("value", parseInt(configBoxOperator.idtemplateSelected))
      )(listAllTemplate);

      let variables = variablesBoxOperator;
      let kblocksVar = Object.keys(variables);

      let dinamicVariables = dinamicVariablesBoxOperator;
      let kblocksDinVar = [];

      if (dinamicVariables) kblocksDinVar = Object.keys(dinamicVariables);

      var multi = props.modal_multimedia;

      let ultimo = props.multimedia.lastIndexOf("\\");
      if (ultimo == -1) ultimo = props.multimedia.lastIndexOf("/");

      const name = props.multimedia.substring(
        ultimo + 1,
        props.multimedia.length
      );

      const thumbsImgModal = multi.map((file) => (
        <div key={"thumb-1"}>
                   {" "}
          <div className="container-multimedia">
            {file.type.indexOf("image") != -1 ? (
              <img className="multimedia-fluid" src={file.preview} />
            ) : file.type.indexOf("video") != -1 ? (
              <video width="220" height="140" controls>
                <source src={file.preview} type={file.type} />
              </video>
            ) : file.type.indexOf("audio") != -1 ? (
              <audio controls>
                <source src={file.preview} type={file.type} />
              </audio>
            ) : (
              <div></div>
            )}
                     {" "}
          </div>
                 {" "}
        </div>
      ));

      const customStyles = {
        ///.....
        menuPortal: provided => ({ ...provided, zIndex: 9999 }),
        menu: provided => ({ ...provided, zIndex: 9999 })
        ///.....
      }

      return (
        <React.Fragment>
          <Modal
            className="multimedia"
            isOpen={modalViewBoxMultimedia}
            toggle={props.toggleModalViewBoxMultimedia}
            fade
            size="lg"
          >
            <ModalHeader toggle={props.toggleModalViewBoxMultimedia}>
              {i18next.t('configure_file')} Multimedia - {node.id}
            </ModalHeader>
            <Form id="MMM-form" role="form">
              <ModalBody>
              <Nav tabs>
                <NavItem>
                    <NavLink
                        className={classnames({ active: activeItem === '1' })}
                        onClick={(e) => { toggleTab('1'); }} >
                        {i18next.t('basic')}
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={activeItem}>
                <TabPane tabId="1">
                  <Row>
                    <Col sm="12" md="6">
                      
                    <TitleOperator titleOperator={selectedBoxOperator.title} />

                      <br></br>
                      <Col xs={12} md={12} lg={12}>
                        <Label>
                          {i18next.t('upload_file')} :{" "}
                          <i
                            className="fa fa-info-circle"
                            id="tooltipSelectedType"
                          ></i>{" "}
                        </Label>
                        <UncontrolledTooltip
                          placement="right"
                          target="tooltipSelectedType"
                        >
                          ¿{i18next.t('message28')}?
                        </UncontrolledTooltip>

                        <FormGroup check>
                          <br></br>
                          <Row>
                            <Col xs={2} md={2} lg={2}>
                              <Label check>
                                <Input
                                  type="radio"
                                  id="urlType"
                                  name="typeDefault"
                                  value="url_multimedia"
                                  defaultChecked={
                                    configBoxOperator &&
                                    item_type == "url_multimedia"
                                      ? true
                                      : false
                                  }
                                  onChange={(e) => onchangeTypeMultimedia(e)}
                                ></Input>
                                &nbsp;url
                              </Label>
                            </Col>
                            <Col xs={4} md={4} lg={4}>
                              <Label check>
                                <Input
                                  type="radio"
                                  id="local_type"
                                  name="typeDefault"
                                  value="local_multimedia"
                                  defaultChecked={
                                    configBoxOperator &&
                                    item_type == "local_multimedia"
                                      ? true
                                      : false
                                  }
                                  onChange={(e) => onchangeTypeMultimedia(e)}
                                ></Input>
                                &nbsp;{i18next.t('files_locals')}
                              </Label>
                            </Col>
                            <Col xs={3} md={3} lg={3}>
                              <Label check>
                                <Input
                                  type="radio"
                                  id="variable_type"
                                  name="typeDefault"
                                  value="variable_multimedia"
                                  defaultChecked={
                                    configBoxOperator &&
                                    item_type == "variable_multimedia"
                                      ? true
                                      : false
                                  }
                                  onChange={(e) => onchangeTypeMultimedia(e)}
                                ></Input>
                                &nbsp;{i18next.t('variable')}
                              </Label>
                            </Col>
                            <Col xs={3} md={3} lg={3}>
                              <Label check>
                                <Input
                                  type="radio"
                                  id="template_type"
                                  name="typeDefault"
                                  value="template_multimedia"
                                  defaultChecked={
                                    configBoxOperator &&
                                    item_type == "template_multimedia"
                                      ? true
                                      : false
                                  }
                                  onChange={(e) => onchangeTypeMultimedia(e)}
                                ></Input>
                                &nbsp;{i18next.t('template')}
                              </Label>
                            </Col>
                          </Row>
                        </FormGroup>
                      </Col>
                      <hr></hr>
                      <br></br>
                      {(item_type == "url_multimedia" ||
                        item_type == "local_multimedia" ||
                        item_type == "template_multimedia") &&
                      Object.keys(variablesBoxOperator).length > 0 ? (
                        <FormGroup>
                          <Label>
                            {i18next.t('message9')}{" "}
                            <i
                              className="fa fa-info-circle"
                              id="tooltipVariable"
                            ></i>
                          </Label>
                          <UncontrolledTooltip
                            placement="right"
                            target="tooltipVariable"
                          >
                            {" "}
                            {i18next.t('message29')}
                          </UncontrolledTooltip>

                          <Input
                            type="text"
                            className="inputModal"
                            placeholder={i18next.t('variable_multimedia')}
                            onChange={(event) => {
                              props.updateVariableBoxOperator(
                                "var_main",
                                event.target.value,
                                "variables"
                              );
                            }}
                            defaultValue={
                              variablesBoxOperator && variablesBoxOperator["var_main"]
                                ? variablesBoxOperator["var_main"].var_name
                                : ""
                            }
                          />
                        </FormGroup>
                      ) : null}
                      {exit_var ? (
                        <Row>
                          <div className="alert alert-danger" role="alert">
                            {i18next.t('message30')}
                          </div>
                        </Row>
                      ) : (
                        <div></div>
                      )}
                      <br></br>

                      {item_type == "url_multimedia" ? (
                        <FormGroup id="box-form">
                          <Label>URL {i18next.t('file')}</Label>
                          <Form id="frm_file_url" onSubmit={props.saveUrl}>
                            <InputGroup>
                              <Input
                                className="inputGroup"
                                type="text"
                                defaultValue={
                                  urlMultimediaPreview != "none"
                                    ? urlMultimediaPreview
                                    : ""
                                }
                                id="multimediaModal"
                                name="multimediaModal"
                              />
                              <InputGroupAddon addonType="append">
                                <Button
                                  color="success"
                                  type="submit"
                                  style={{
                                    background: "#75b74b",
                                    borderColor: "#75b74b",
                                    fontSize: "12px",
                                  }}
                                >
                                  {i18next.t('upload_file')}
                                </Button>
                              </InputGroupAddon>
                            </InputGroup>
                          </Form>
                        </FormGroup>
                      ) : item_type == "local_multimedia" ? (
                        <FormGroup id="box-form-drop">
                          <Form id={"frm_file" + selectedBoxOperator.id}>
                            <FormGroup>
                              <Input
                                type="text"
                                className="inputModal"
                                placeholder={i18next.t('url_nueva')}
                                defaultValue={
                                  urlMultimediaPreview != "none"
                                    ? urlMultimediaPreview
                                    : ""
                                }
                                disabled
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label>{i18next.t('upload_file')}</Label>
                              <div className="dropzone">
                                <Dropzone
                                  accept="image/*,audio/*,video/*,application/*,text/*"
                                  onDrop={props.onDrop1}
                                  multiple={false}
                                >
                                  <aside>{thumbsImgModal}</aside>
                                  <div className="text-center">
                                    <label id="label-dropzone-chat">
                                      {i18next.t('message31')}
                                    </label>
                                  </div>
                                </Dropzone>
                              </div>
                            </FormGroup>
                          </Form>
                        </FormGroup>
                      ) : item_type == "variable_multimedia" ? (
                        <FormGroup>
                          <Label>
                            {i18next.t('var_url_file')}{" "}
                            <i
                              className="fa fa-info-circle"
                              id={"tooltipListVariables"}
                            ></i>
                          </Label>
                          <UncontrolledTooltip
                            placement="right"
                            target={"tooltipListVariables"}
                          >
                            {" "}
                            {i18next.t('message32')}
                          </UncontrolledTooltip>
                          {varList && varList.length > 0 ? (
                            <Select
                              className="basic-single"
                              classNamePrefix="select"
                              defaultValue={
                                configBoxOperator ? name_varSelected : "none"
                              }
                              isDisabled={false}
                              isLoading={false}
                              isClearable={true}
                              isRtl={false}
                              isSearchable={true}
                              name="type"
                              options={varListSelected ? varListSelected : []}
                              onChange={(value) =>
                                props.setChangeConfig(
                                  "url_statement",
                                  value && value.value ? value.value : "none"
                                )
                              }
                              menuPortalTarget={document.body}
                              menuPosition={'fixed'} 
                              styles={customStyles}
                            />
                          ) : (
                            <div className="alert alert-danger" role="alert">
                              {i18next.t('msg_error_variable')}
                            </div>
                          )}
                        </FormGroup>
                      ) : null}
                    </Col>
                    <Col sm="12" md="6">
                      <Row>
                        <Col className="text-center">
                          <br></br>
                          <Label>{i18next.t('previews')}</Label>
                          <Col className="col-6 offset-3 d-flex justify-content-center align-items-center">
                            {urlMultimediaPreview != "none" ? (
                              !contentType?(urlMultimediaPreview.includes("https://www.youtube.com/watch")?
                              <iframe width="220" height="140" src={"https://www.youtube.com/embed/"+urlMultimediaPreview.substring(urlMultimediaPreview.lastIndexOf('?v=') + 3, urlMultimediaPreview.length )}
                              title="YouTube video player" frameborder="0" 
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                              </iframe>:urlMultimediaPreview.includes("https://vimeo.com")?
                              <iframe width="220" height="140" src={"https://player.vimeo.com/video/"+urlMultimediaPreview.substring(urlMultimediaPreview.lastIndexOf('/') + 1, urlMultimediaPreview.length )}
                              title="YouTube video player" frameborder="0" 
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                              </iframe>:urlMultimediaPreview.includes("image/")?
                              <img
                                className="multimedia-fluid"
                                src={urlMultimediaPreview}
                                style={{ maxWidth: "150px" }}
                              />
                              :null)
                              :
                              (contentType != "" &&
                                contentType.includes("image/")) ||
                              (contentType == false &&
                                urlMultimediaPreview.includes("image/")) ? (
                                <img
                                  className="multimedia-fluid"
                                  src={urlMultimediaPreview}
                                  style={{ maxWidth: "150px" }}
                                />
                              ) : contentType != "" &&
                                contentType.includes("video") ? (
                                <video width="220" height="140" controls>
                                  <source
                                    src={urlMultimediaPreview}
                                    type={contentType}
                                  />
                                </video>
                              ) : contentType != "" &&
                                contentType.includes("audio") ? (
                                <audio controls>
                                  <source
                                    src={urlMultimediaPreview}
                                    type={contentType}
                                  />
                                </audio>
                              ) : (
                                <div>
                                  {name}
                                  <img
                                    src={noMultimedia}
                                    style={{ maxWidth: "150px" }}
                                  />
                                </div>
                              )
                            ) : fileMultimediaPreview != [] ? (
                              props.multimedia && (
                                <div>
                                  {contentType != "" &&
                                  contentType.includes("image/") ? (
                                    <img
                                      className="multimedia-fluid"
                                      src={props.multimedia}
                                      style={{ maxWidth: "150px" }}
                                    />
                                  ) : contentType != "" &&
                                    contentType.includes("video") ? (
                                    <video width="220" height="140" controls>
                                      <source
                                        src={props.multimedia}
                                        type={contentType}
                                      />
                                    </video>
                                  ) : contentType != "" &&
                                    contentType.includes("audio") ? (
                                    <audio controls>
                                      <source
                                        src={props.multimedia}
                                        type={contentType}
                                      />
                                    </audio>
                                  ) : (
                                    <div>
                                      {name}
                                      <img
                                        src={noMultimedia}
                                        style={{ maxWidth: "150px" }}
                                      />
                                    </div>
                                  )}
                                </div>
                              )
                            ) : (
                              <img
                                src={noMultimedia}
                                style={{ maxWidth: "150px" }}
                              />
                            )}
                          </Col>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {item_type == "template_multimedia" ? (
                    <FormGroup>
                      <Row>
                        <Col>
                          <Label>
                            {i18next.t('select_template_for_file')}{" "}
                            <i
                              className="fa fa-info-circle"
                              id={"tooltipListTemplates"}
                            ></i>
                          </Label>
                          <UncontrolledTooltip
                            placement="right"
                            target={"tooltipListTemplates"}
                          >
                            {" "}
                            {i18next.t('template_use_for_file')}
                          </UncontrolledTooltip>
                          {listAllTemplate && listAllTemplate.length > 0 ? (
                            <Select
                              className="basic-single"
                              classNamePrefix="select"
                              defaultValue={
                                name_templateSelected
                                  ? name_templateSelected
                                  : "none"
                              }
                              isDisabled={false}
                              isLoading={false}
                              isClearable={true}
                              isRtl={false}
                              isSearchable={true}
                              name="name"
                              options={listAllTemplate ? listAllTemplate : []}
                              onChange={(value) =>
                                onchangeTemplateSelected(value)
                              }
                              menuPortalTarget={document.body}
                              menuPosition={'fixed'} 
                              styles={customStyles}
                            />
                          ) : (
                            <div className="alert alert-danger" role="alert">
                              {i18next.t('message33')}
                            </div>
                          )}
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>
                              ¿{i18next.t('message34')}?{" "}
                              <i
                                className="fa fa-info-circle"
                                id="tooltipSelectedTypeGenerate"
                              ></i>
                            </Label>
                            <UncontrolledTooltip
                              placement="right"
                              target="tooltipSelectedTypeGenerate"
                            >
                              {i18next.t('select_file_to_create')}
                            </UncontrolledTooltip>

                            <FormGroup check>
                              <Row>
                                <Col xs={4} md={4} lg={4}>
                                  <Label check>
                                    <Input
                                      type="radio"
                                      id="multimedia_type_pdf"
                                      name="typeFileDefault"
                                      value="pdf"
                                      defaultChecked={
                                        configBoxOperator &&
                                        multimedia_type == "pdf"
                                          ? true
                                          : false
                                      }
                                      onChange={(e) =>
                                        props.setChangeConfig(
                                          "multimedia_type",
                                          "pdf"
                                        )
                                      }
                                    ></Input>
                                    &nbsp;{i18next.t('create')} pdf
                                  </Label>
                                </Col>
                                <Col xs={4} md={4} lg={4}>
                                  <Label check>
                                    <Input
                                      type="radio"
                                      id="multimedia_type_png"
                                      name="typeFileDefault"
                                      value="png"
                                      defaultChecked={
                                        configBoxOperator &&
                                        multimedia_type == "png"
                                          ? true
                                          : false
                                      }
                                      onChange={(e) =>
                                        props.setChangeConfig(
                                          "multimedia_type",
                                          "png"
                                        )
                                      }
                                    ></Input>
                                    &nbsp;{i18next.t('create')} png
                                  </Label>
                                </Col>
                                <Col xs={4} md={4} lg={4}>
                                  <Label check>
                                    <Input
                                      type="radio"
                                      id="multimedia_type_jpeg"
                                      name="typeFileDefault"
                                      value="jpeg"
                                      defaultChecked={
                                        configBoxOperator &&
                                        multimedia_type == "jpeg"
                                          ? true
                                          : false
                                      }
                                      onChange={(e) =>
                                        props.setChangeConfig(
                                          "multimedia_type",
                                          "jpeg"
                                        )
                                      }
                                    ></Input>
                                    &nbsp;{i18next.t('create')} jpeg
                                  </Label>
                                </Col>
                              </Row>
                            </FormGroup>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br></br>
                      <Row>
                        <Col>
                          <Label>
                            {i18next.t('template_vars')}{" "}
                            <i
                              className="fa fa-info-circle"
                              id={"tooltipVariableTemplates"}
                            ></i>
                          </Label>
                          <UncontrolledTooltip
                            placement="right"
                            target={"tooltipVariableTemplates"}
                          >
                            {" "}
                            {i18next.t('create_vars_msg')}
                          </UncontrolledTooltip>

                          <FormGroup>
                            <Table style={{ textAlign: "center" }}>
                              <thead>
                                <tr>
                                  <th>{i18next.t('name_var')}</th>
                                  <th>{i18next.t('value')}</th>
                                </tr>
                              </thead>
                              <tbody style={{ overflowY: scroll }}>
                                {variableElements()}
                              </tbody>
                            </Table>
                          </FormGroup>
                      </Col>
                    </Row>
                  </FormGroup>
                ) : null}
                </TabPane>
                </TabContent> 
              </ModalBody>
              <ModalFooter>
                <Col sm="12" align="right">
                  {exit_var ? (
                    <Button
                      color="default"
                      size="lg"
                      type="button"
                      onClick={props.toggleModalViewBoxMultimedia}
                    >
                      {i18next.t('cancel')}
                    </Button>
                  ) : (
                    <Button
                      color="success"
                      className="btn-guarumo-modal-sm"
                      type="button"
                      onClick={props.toggleModuleModal}
                    >
                      {i18next.t('finish')}
                    </Button>
                  )}
                </Col>
              </ModalFooter>
            </Form>
          </Modal>
        </React.Fragment>
      );
    } else {
      return <div></div>;
    }
  } else {
    return <div></div>;
  }

  async function onchangeTemplateSelected(value) {
    await props.setChangeConfig(
      "idtemplateSelected",
      value && value.value ? value.value : "none"
    );

    await props.imageTemplate( value.value );

    await props.getVariableTemplate(value.value,props.variables);

    
  }

  function onchangeTypeMultimedia(e) {
    const { configBoxOperator } = props.boxReducer;

    item_type = e.target.value;

    props.setSelectedTypeMultimedia(item_type);

    /*if(item_type=='variable_multimedia')
                {
                    props.setUrlMultimediaPreview('none');
                    props.setContentType('');
                }
                else if(item_type=='url_multimedia')
                {
                    props.setUrlMultimediaPreview(configBoxOperator.url);
                    props.setContentType(configBoxOperator.content_type);
                }
                else if(item_type=='local_multimedia')
                {
                    props.setUrlMultimediaPreview('none');
                    props.setContentType(configBoxOperator.content_type);
                }*/
  }
  function variableElements() {
    let listElements = [];

    tempVariablesTemplate
      ? tempVariablesTemplate.map((value, index) => {
          listElements.push(
            <tr key={index}>
              <td style={{ width: "50%" }}>
                <Input
                  disabled={true} 
                  type="text"
                  id={"key-" + index}
                  defaultValue={value.key}
                  onChange={(e) =>
                    props.handleChangeNameVar(index, e.target.value)
                  }
                />
              </td>
              <td style={{ width: "50%", textAlign: "center" }}>
                <Mentions
                  defaultValue={value.value}
                  maxlength={"2000"}
                  msg_error={i18next.t('msg_error_limit')}
                  updateItem={props.updateTempVariableTemplate}
                  index={index}
                  k={index}
                />
              </td>
            </tr>
          );
        })
      : null;

    return listElements;
  }

  function toggleTab(tab) {
    
    console.log("TAB: "+tab);
     props.changeActiveTab(tab);
 
}
}

const mapStateToProps = ({
  botReducer,
  boxReducer,
  homeReducer,
  templateReducer,
}) => {
  return { botReducer, boxReducer, homeReducer, templateReducer };
};

const mapDispatchToProps = {
  setSelectedTypeMultimedia,
  toggleModalViewBoxMultimedia,
  setUrlMultimediaPreview,
  setContentType,
  changeActiveTab,
  setChangeConfig,
  setInitialDinamicVariables,
  updateVariableBoxOperator,
  updateBoxOperator,
  removeStatementBlock,
  getVariableTemplate,
  imageTemplate,
  updateTempVariableTemplate
};

export default connect(mapStateToProps, mapDispatchToProps)(MMMLayout);
