import React, { Component} from 'react';
import { connect } from "react-redux";

import { Button,Modal,ModalHeader,ModalBody,ModalFooter,Form,FormGroup,Table,Input,Col,Row,Label,Nav,NavItem,TabContent,TabPane,NavLink,UncontrolledTooltip} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import CMSSelector from '../../../../components/cms'
import Mentions from '../../../../components/mentions/metions-alone';
import InputValidate from '../../../../components/input-validate';
import TitleOperator from "../../../../components/title-operator";
import {Link} from 'react-router-dom'
import Emoji from '../../../../components/emoji-picker';
import classnames from 'classnames';
import TabAdvanced from "../../../../components/box/tab_advanced";
import MainStatement from '../../../../components/box/main_statement'
import MentionsR from '../../../../components/mentions/metions-redux';

import { 
    setChangeConfig,
    changeActiveTab,
    updateTextBlock,
    removeTextBlock,
    toggleModalViewBoxCarousel
     } from "../../../../redux/actions/boxActions";

import SelectStatements from '../../../../components/selected-statements'
import i18next from '../../../../../i18n';

function CNMLayout(props) {

function toggleTab(tab) {
    
    // e.preventDefault();
    // console.log('The link was clicked.',e);
    console.log("TAB: "+tab);
     props.changeActiveTab(tab);
 
}

function handleChange(e) {

    interactive = !interactive;
    props.setChangeConfig('interactive_message',interactive===true?'True':'False');
}


    const onchangeStatements = (name,value) => { 
        props.setChangeConfig(name,'[var='+value+']');
        props.setChangeConfig('id_var',value);
    }

    function drawCard  (bls,kblocks) {
       
        const label = i18next.t('var_carousel');
        const tooltip = i18next.t('carouse_info'); 
        const { varListSelected,configBoxOperator } = props.boxReducer;
        const { bot } = props.botReducer;

        let blocks = kblocks.map((card, index) => {

          
        // let blocks = cards.map((card, index) => {
            if(card == null)
                return;   
            const buttons = bls[card].buttons;
            let tbuttons = []
           
            if(buttons)
            {
                const kbuttons = Object.keys(buttons);        
                tbuttons = kbuttons.map((k) => {
                
                    return <tr key={'tr-'+card+"-"+k} id={'tr-'+card+"-"+k}>
                        <td k={'td-input-'+card+'-'+k} 
                                key={'te-input-'+card+'-'+k} >
                                    <InputValidate
                                        key={card+'-'+k}
                                        id={card+'-'+k}
                                        k={card+'-'+k}
                                        maxLength={'20'} 
                                        msg_error={i18next.t('max_20_chars')}
                                        defaultValue={buttons[k].title} 
                                        error_input_empty={props.error_input_empty} 
                                        varList={varListSelected}
                                        onChange={(event) => { props.updateButton(card,k, 'title', event) }} 
                                        required/>
                            {/* <InputValidate 
                                
                                /> */}
                        </td>
                        <td key={'td-buttonsSelect-'+card+"-"+k} id={'td-buttonsSelect-'+card+"-"+k}>
                            <Input key={'buttonsSelect-'+card+"-"+k} id={'buttonsSelect-'+card+"-"+k} type="select" name="select" onChange={(event) => { props.updateButton(card, k, 'type', event) }} value={buttons[k].type}>
                                <option  key={'postback-'+card+"-"+k} id={'postback-'+card+"-"+k} value="postback">{i18next.t('advance')}</option>
                                <option  key={'web_url-'+card+"-"+k} id={'web_url-'+card+"-"+k} value="web_url">URL/{i18next.t('new_tab')}</option>
                                <option  key={'reload_url-'+card+"-"+k} id={'reload_url-'+card+"-"+k} value="reload_url">URL/{i18next.t('reload')}</option>
                            </Input>
                        </td>
                        <td key={'td-textPostback-'+card+"-"+k} id={'td-textPostback-'+card+"-"+k}>
                            <Input key={'textPostback-'+card+"-"+k} id={'textPostback-'+card+"-"+k} type="text" hidden={buttons[k].type == "postback"} className="inputModal" placeholder="URL" 
                            onChange={(event) => { props.updateButton(card, k, 'url', event) }} value={buttons[k].url} ></Input>
                            {
                            props.error_input_type[k] &&
                                <span className="warning-character">URL {i18next.t('invalid')}</span>
                            }
                        </td>
                        <td key={'td-buttRemove-'+card+"-"+k} id={'td-buttRemove-'+card+"-"+k}>
                            <Button key={'buttRemove-'+card+"-"+k} id={'buttRemove-'+card+"-"+k} color="info" size="lg" onClick={() => props.removeButton(card, k) }><FontAwesomeIcon icon={faTrash} /></Button>
                            <Emoji key={'emojiItem-'+card+"-"+k} id={card+"-"+k} callEmoji={props.callEmoji} type="item"style={{'display':'none',position:'fixed',marginLeft: -265+'px',zIndex: 100}}/>
                        </td>
                    </tr>
                });
            }
            return  configBoxOperator.type =='var'?
            (<tr id={'row-'+card} key={ 'row-'+card}>
                <td>
                {varListSelected&&varListSelected.length > 0 ? (
                        <SelectStatements 
                            varSelected={ configBoxOperator.id_var } 
                            onchangeStatements={ onchangeStatements } 
                            nameVar={ 'var' } 
                            label={ label } 
                            tooltip={ tooltip }
                            k={'0'}
                        ></SelectStatements>
                ):null}
                </td>
                <td key={'td-table-'+card} id={'td-table-'+card} style={{'width':'46%'}}>
                    <Table key={'table-'+card} id={'table-'+card}>     
                        <thead key={'thead-'+card} id={'thead-'+card}>
                            <tr key={'thead-tr-'+card} id={'thead-tr-'+card}> 
                                <th key={'title-'+card} id={'title-'+card}>{i18next.t('title')}</th>
                                <th key={'type-'+card} id={'type-'+card}>{i18next.t('type')}</th>
                                <th key={'url-'+card} id={'url-'+card}>URL</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody key={'tbody-'+card} id={'tbody-'+card}>
                            {tbuttons}
                        </tbody>
                    </Table>
                    <Col sm="12" className="button-add">
                        <Button color="info" size="lg" onClick={() => props.addNewButton(card) }>{i18next.t('add_new')}</Button>
                    </Col>
                </td>   
            </tr>):
            <tr id={'rows-'+card} key={'row-'+card}>
                <td key={'td-botonRemove'+card}>
                    <Button key={'botonRemove'+card} color="info" size="lg" onClick={() => props.removeTextBlock(card) }><FontAwesomeIcon icon={faTrash} /></Button>
                </td>
                <td colSpan='3' className={ props.visible == 1 ? 'visibilityBlockInput':'visibilityInput' } >
                    <p className="text-center">
                        <Link style={{'color':'#494949'}} target="_blank" to={'/cms/'+bot.id+'?operador='+props.operator}>{i18next.t('set_up')} CMS</Link></p>
                </td>
                <td key={'td-titulo-'+card} id={'td-titulo-'+card} className={ props.visible == 1 ? 'visibilityInput':'' }>
                   <Input key={'titulo-'+card} id={'titulo-Emojititulo-'+card} type="text" className="inputModal" 
                   placeholder="Título" onKeyDown={(event) => { props.limitTextBlock('title', event,80) }} 
                    onChange={(event) => { props.updateTextBlock(card, 'title', event.target.value) }} 
                   defaultValue={ bls[card].title} required></Input> 
                   <Emoji  key={'Emojititulo-'+card} id={'Emojititulo-'+card} callEmoji={props.callEmoji} type="titulo" style={{'display':'none',position:'fixed',marginLeft: -265+'px',zIndex: 100}}/>
                     
                </td>
                <td key={'td-url-'+card} id={'td-url-'+card}  className={ props.visible == 1 ? 'visibilityInput':'' }>
                    <Input key={'url-'+card} id={'url-'+card} type="text" className="inputModal" placeholder={i18next.t('image') + ' URL'}
                    onChange={(event) => { props.updateTextBlock(card, 'url', event.target.value) }} 
                     value={bls[card].url} required></Input>
                </td>
                <td key={'td-subtitulo-'+card} id={'td-subtitulo'+card} className={ props.visible == 1 ? 'visibilityInput':'' }> 
                    <Input key={'subtitulo-'+card} id={'subtitulo-Emojisubtitulo-'+card} type="text" className="inputModal" placeholder={'Sub' + i18next.t('title').toLowerCase()} onKeyDown={(event) => { props.limitTextBlock('subtitle', event,80) }} 
                    onChange={(event) => { props.updateTextBlock(card, 'subtitle', event.target.value) }}
                     defaultValue={bls[card].subtitle}></Input>
                    <Emoji key={'Emojisubtitulo-'+card} id={'Emojisubtitulo-'+card} callEmoji={props.callEmoji} type="subtitulo" style={{'display':'none',position:'fixed',marginLeft: -265+'px',zIndex: 100}}/>
                                                
                </td>
                <td  key={'td-table-'+card} id={'td-table-'+card} style={{'width':'46%'}}>
                    <Table key={'table-'+card} id={'table-'+card}>     
                        <thead key={'thead-'+card} id={'thead-'+card}>
                            <tr key={'thead-tr-'+card} id={'thead-tr-'+card}> 
                                <th key={'title-'+card} id={'title-'+card}>{i18next.t('title')}</th>
                                <th key={'type-'+card} id={'type-'+card}>{i18next.t('type')}</th>
                                <th key={'url-'+card} id={'url-'+card}>URL</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody key={'tbody-'+card} id={'tbody-'+card}>
                            {tbuttons}
                        </tbody>
                    </Table>
                   <Col sm="12" className="button-add">
                        <Button color="info" size="lg" onClick={() => props.addNewButton(card) }>{i18next.t('add_new')}</Button>
                    </Col>
                    
                </td>            
            </tr>
        });
        return blocks;
    }

    const { text_blocks } = props.boxReducer;
  
    // let cards = props.cards || [];
    let bls = text_blocks || {};
    let kblocks = Object.keys(bls);
    let blocks = drawCard(bls,kblocks)
    //let blocks = drawCard(cards);
    
    const { selectedBoxOperator,configBoxOperator,botmsg_text,node,modalViewBoxCarousel,activeItem,varList} = props.boxReducer;
    
    const { bot } = props.botReducer;
    
    if(selectedBoxOperator&&Object.keys(selectedBoxOperator).length>0&&selectedBoxOperator.operatortype_id==3&&modalViewBoxCarousel)
    {
    
        return (
            <React.Fragment>
                <Modal className="elements_generic" isOpen={modalViewBoxCarousel} toggle={props.toggleModalViewBoxCarousel} fade size="lg">
                    <ModalHeader toggle={props.toggleModalViewBoxCarousel}>{i18next.t('set_up')} {i18next.t('carousel')}  - {node.id}</ModalHeader>
                    <ModalBody>
                        <Form id="CM-form" role="form">  
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                        className={classnames({ active: activeItem === '1' })}
                                        onClick={() => { toggleTab('1'); }} >
                                        {i18next.t('basic')}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                        className={classnames({ active: activeItem === '2' })}
                                        onClick={() => { toggleTab('2'); }}>
                                        {i18next.t('advanced')}
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={activeItem}>
                                    <TabPane tabId="1">
                                        <Row>
                                            <Col sm="12">
                                                <FormGroup>
                                                    <TitleOperator titleOperator={selectedBoxOperator.title} />
                                                <FormGroup>
                                                <MainStatement head={botmsg_text || ''} ></MainStatement>
                                                    <Row>
                                                            <Col>
                                                                <Label check style={{"marginLeft":15+"px"}}>
                                                                    <Input type="checkbox" id='interactive_menu' name="interactive_menu" value={i18next.t('name_var')}
                                                                    defaultChecked={configBoxOperator.type=='var'?true:false} 
                                                                    onChange={(e) => props.handleChange(e)}></Input>
                                                                    &nbsp;
                                                                            
                                                                    {i18next.t('load_data_from_var')}  <i className="fa fa-info-circle" id="tooltipInteractiveMenu"></i></Label>
                                                                <UncontrolledTooltip placement="right" target="tooltipInteractiveMenu"> {i18next.t('carousel_info2')}</UncontrolledTooltip>
                                                            </Col>
                                                        </Row>
                                                    </FormGroup>
                                                    {/* {
                                                        props.type =='true'?
                                                        <div></div>
                                                        :( */}
                                                            <React.Fragment>
                                                            <Table>
                                                        <thead>
                                                        <tr>
                                                            <th></th>
                                                            <th>{i18next.t('title')}</th>
                                                            <th>URL {i18next.t('image')}</th>
                                                            <th>Sub {i18next.t('title')}</th>
                                                            <th>{i18next.t('buttons')}</th>                                        
                                                        </tr>
                                                        </thead>
                                                        
                                                        <tbody>
                                                        {blocks}
                                                        </tbody>
                                                    </Table>     
                                                    { configBoxOperator.type =='var'?null:
                                                    <Col sm="12" className="button-add">
                                                        {
                                                            props.error_limit_item && 
                                                            <Col sm="12">
                                                                <span className="warning-character">{i18next.t('max_buttons_allowed') + ' 11'}</span>
                                                            </Col>
                                                        }
                                                        <Col sm="12">
                                                            <Button color="info" size="lg" onClick={props.addNewTextBlock}>{i18next.t('add_new')}</Button>
                                                        </Col>
                                                    
                                                    </Col>  
                                                    }
                                                    </React.Fragment>
                                                        {/* )
                                                    } */}
                                                    
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId="2">
                                    <TabAdvanced isKeywordValid={props.isKeywordValid} timeout={true} getTimeout={props.getTimeout} getMsgTimeout={props.getMsgTimeout} isTimeoutValid={props.isTimeoutValid} timeoutValue={props.timeoutValue} timeoutMsg={props.timeoutMsg}></TabAdvanced>
                                        <Row>
                                            <Col sm="6">
                                                <CMSSelector bindValue={props.getOperatorVisible} bot_id={bot.id} operator={props.visible} filter={"carousel"}/>
                                                <Label>URL</Label>
                                                <Input id={'urlCarrusel'} type="text" className="inputModal" placeholder="URL" onChange={(event) => { props.getUrlCarrusel(event) }} defaultValue={props.urlCarrusel}></Input> 
                                            </Col>
                                            
                                        </Row>
                                    </TabPane>
                                </TabContent>
                        </Form>
                    </ModalBody>
                    <ModalFooter>            
                        <Button color="success" size="lg" onClick={() => props.toggleModuleModal()}>{i18next.t('finish')}</Button>
                    </ModalFooter>
                </Modal>           
            </React.Fragment>
        );
    }
    else
    {
        return(<div></div>)
    }
}
 
const mapStateToProps = ({botReducer, boxReducer,homeReducer}) => {
    return {botReducer,boxReducer, homeReducer};
    };
    
    const mapDispatchToProps = {
        setChangeConfig,
        changeActiveTab,
        updateTextBlock,
        removeTextBlock,
        toggleModalViewBoxCarousel
    };
    
    
    export default connect(mapStateToProps, mapDispatchToProps )(CNMLayout);
