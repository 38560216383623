import React from 'react';
import { 
    Button, 
    Modal, 
    ModalHeader, 
    ModalBody, 
    ModalFooter,
    Form, 
    FormGroup, 
    Label, 
    Input, 
    Col,
    Row,
} from 'reactstrap';
import '../pages/admin/components/toolbar.css';
import './modal-avatar.css'
import Dropzone from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faCheck} from '@fortawesome/free-solid-svg-icons'
import i18next from '../../i18n';


function ModalNuevoAvatar(props){

    return (
        <div>
            <Modal isOpen={props.isOpen} toggle={props.toggleModal} className="modal-md" fade>
                <ModalHeader toggle={props.toggleModal} charCode="X">{i18next.t('new_avatar')}</ModalHeader>
                <Form id="form-createavatar" onSubmit={props.saveAvatar} role="form">
                <ModalBody>
                    <input id="" name="company_id" type="hidden" value={props.companyId}/>
                        <FormGroup>
                            <Row>
                                <Col sm="2">
                                    <Label for="botName" className="control-label required-field">{i18next.t('title')+':'}</Label>
                                </Col>
                                <Col sm="10" className="p-0">
                                    <Input type="text" className="inputModal" id="title" name="title" maxLength="50" required></Input>
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <Row className="modalAvatar">
                                <Col md={10} className="offset-2">
                                    <h5 className="text-danger" hidden={!props.error_modal_relacion}><FontAwesomeIcon icon={faTimes} /> {props.msj_error_modal_relacion}</h5>
                                    <h5 className="text-danger" hidden={!props.error_modal_size}><FontAwesomeIcon icon={faTimes} /> {props.msj_error_modal_size}</h5>
                                    <h5 className="text-success" hidden={!props.ok_modal_relacion }><FontAwesomeIcon icon={faCheck} /> {i18next.t('valid_dimensions')}</h5>
                                    <h5 className="text-success" hidden={!props.ok_modal_size }><FontAwesomeIcon icon={faCheck} /> {i18next.t('valid_size')}</h5>
                                </Col>
                                <Col sm="2">
                                    <Label for="botName" className="control-label required-field"></Label>
                                </Col>
                                <Col sm="10" className="dropzone">
                                    <Dropzone
                                    accept="image/*"
                                    onDrop={props.onDrop}
                                    multiple={false}
                                    >   
                                    <aside>
                                    {props.thumbsImgModal}
                                    </aside>
                                    <div className="text-center">
                                        <label id="label-dropzone-chat-modal">{i18next.t('drag_click_image')}</label>
                                    </div>
                                    </Dropzone>
                                </Col>
                                <Col md={10} className="offset-2">
                                  <h5 className="text-primary"> * {i18next.t('limit_image')}</h5>
                                </Col>
                            </Row>
                        </FormGroup>
                       
                </ModalBody>
                <ModalFooter>
                    <Button onClick={props.toggleModal} outline color="secondary">{i18next.t('cancel')}</Button>
                    <Button type="submit" disabled={props.buttonModalImg || props.buttonModalImgSize} size="lg" className="btn-guarumo-modal">{i18next.t('save')}</Button>
                </ModalFooter>
                </Form>
            </Modal>
        </div>
    );
}
  
export default ModalNuevoAvatar