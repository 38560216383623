import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

// All pages in the platform
import HandleError from "../components/error/containers/handle-error";
import Home from "../pages/home/home";
import Users from "../pages/users/users";
import Companies from "../pages/companies/companies";
import Login from "../pages/login/login";
import ChangePassword from "../pages/changepassword/changepassword";
import Validate from "../pages/validate/validate";
import Bot from "../pages/adminBots/mainBot/bot";
import BotConfiguration from "../pages/adminBots/configurationBot/bot-configuration";


import BotIA from "../pages/adminBots/ia/bot-ia";
import BotIAModule from "../pages/adminBots/ia/bot-iamodule";
import BotIAModuleTable from "../pages/adminBots/ia/bot-iamoduletable";


import BotAIML from "../pages/adminBots/aiml/bot-aiml";
import BotAIMLGroup from "../pages/adminBots/aiml/bot-aiml-group";
import BotStatistics from "../pages/adminBots/statistic/bot-statistics";
import BotDashboard from "../pages/analytics/dashboard/bot-dashboard";
import BotAdminDashboard from "../pages/analytics/administration/bot_admin_dashboard";
import BotAdminMetricas from "../pages/analytics/administration/bot_admin_metricas";
import BotAdminChart from "../pages/analytics/administration/bot_admin_chart";
import BotReportes from "../pages/analytics/report/bot_reportes";
import BotCustomersReport from "../pages/analytics/report/bot_customers_report";
import BotCustomReport from "../pages/analytics/report/bot_custom_report.jsx";
import BotSMSReport from "../pages/analytics/report/bot_sms_report.jsx";
import BotBlockChainReport from "../pages/analytics/report/bot_blockchain_report.jsx";
import BotUser from "../pages/analytics/report/bot_user.jsx";
import BotFunnel from "../pages/adminBots/funnels/bot-funnel";
import BotFunnels from "../pages/adminBots/funnels/bot-funnels";
import BotEvents from "../pages/adminBots/events/bot-events";
import BotWebForms from "../pages/adminBots/webform/bot-webforms";
import BotWebFormRender from "../pages/adminBots/webform/bot-webform-render";
import UnsuscribeRender from "../pages/adminBots/unsuscribe/bot-unsuscribe-render";
import BotGeneralStats from "../pages/adminBots/statistic/bot-general-stats";
import BotSchemas from "../pages/adminBots/schemas/bot-schemas";
import CMS from "../pages/adminBots/cms/cms";
import ContactLists from "../pages/campaigns/contact-lists/contact-list";
import DetailLists from "../pages/campaigns/contact-lists/detail-list";
import Notifications from "../pages/campaigns/notifications/notifications";
import Templates from "../pages/campaigns/templates/template";
import Newtemplates from "../pages/campaigns/templates/new-template";
import MassiveCampaign from "../pages/campaigns/massive-campaign/massive-campaign";
import ListMassiveCampaign from "../pages/campaigns/massive-campaign/list-massive-campaign";
import Emoji from "../components/emoji-picker";
import Calendar from "../components/DatePickerSimple/DatePickerSimple";
import Sender from "../pages/campaigns/senders/senders";
import DataBase from "../pages/dataBases/database";

const AppRoutes = () => (
  <BrowserRouter>
    <HandleError>
      <Switch>
        <Route exact path="/bot/:id/generalstats" component={BotGeneralStats} />
        <Route exact path="/bot/:id/events" component={BotEvents} />
        <Route exact path="/bot/:id/funnels" component={BotFunnels} />
        <Route exact path="/bot/:id/funnel/:idfunnel" component={BotFunnel} />
        <Route exact path="/bot/:id/statistics" component={BotStatistics} />
        <Route exact path="/bot/:id/dashboard" component={BotDashboard} />
        
        
        <Route exact path="/bot/:id/ia" component={BotIA} />
        <Route exact path="/bot/:id/iamodule" component={BotIAModule} />
        
        <Route exact path="/bot/:id/iamoduletable" component={BotIAModuleTable} />

        
        <Route exact path="/bot/:id/:groupid/aiml" component={BotAIML} />
        <Route exact path="/bot/:id/aiml-group" component={BotAIMLGroup} />
        <Route exact path="/bot/:id/setup" component={BotConfiguration} />
        <Route exact path="/bot/:id/web-forms" component={BotWebForms} />
        <Route exact path="/webform/:id/:session" component={BotWebFormRender}/>
        <Route exact path="/unsuscribe/:botversion/:session/:channel/:operatorid" component={UnsuscribeRender} />
        <Route exact path="/bot/:id/:idSchema" component={Bot} />
        <Route exact path="/schemas/:id" component={BotSchemas} />
        <Route exact path="/users/:companyID" component={Users} />
        <Route exact path="/companies" component={Companies} />
        <Route exact path="/adminmetricas" component={BotAdminMetricas} />
        <Route exact path="/admindashboard" component={BotAdminDashboard} />
        <Route exact path="/adminchart" component={BotAdminChart} />
        <Route exact path="/reportes" component={BotReportes} />
        <Route exact path="/customersReport" component={BotCustomersReport} />
        <Route exact path="/reportes/user" component={BotUser} />
        <Route exact path="/reportes/custom" component={BotCustomReport} />
        <Route exact path="/reportes/sms" component={BotSMSReport} />
        <Route exact path="/reportes/blockchain" component={BotBlockChainReport} />
        <Route exact path="/dashboard" component={BotDashboard} />
        <Route exact path="/cms/:id" component={CMS} />
        <Route exact path="/detailists/:lista" component={DetailLists} />
        <Route exact path="/contact-lists" component={ContactLists} />
        <Route exact path="/notifications/:id" component={Notifications} />
        <Route path="/Newtemplates/:tempid" component={Newtemplates} />
        <Route path="/Newtemplates/" component={Newtemplates} />
        <Route exact path="/templates" component={Templates} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/validate" component={Validate} />
        <Route exact path="/" component={Home} />
        <Route exact path="/prueba" component={Emoji} />
        <Route exact path="/calendar" component={Calendar} />
        <Route exact path="/list-massive-campaign/" component={ListMassiveCampaign}/>
        {/* <Route exact path="/campaigns/list-campaigns/"../>   */}
        <Route exact path="/massive-campaign/" component={MassiveCampaign} />
        <Route exact path="/database/" component={DataBase} />
        <Route exact path="/changepassword/" component={ChangePassword} />
      </Switch>
    </HandleError>
  </BrowserRouter>
);

export default AppRoutes;
