import React, { Component } from 'react';
import axios from 'axios';
import {  
    FormGroup, 
    Input, 
    Col,
    Row,
    Label,
    UncontrolledTooltip
} from 'reactstrap';
import i18next from '../../i18n';


class OperatorsSelector extends Component {

    state = {
        operator: this.props.operator,
        banderaList:false,
        schemaId:'',
        options:[]
    }

    constructor(props){
        super(props);
        if(this.props.schemaId){
            this.getListOperators(this.props.schemaId)
        }   
        this.updateOperatorData = this.updateOperatorData.bind(this);
    }

     /***** **** **** **** **** **** 
                    AJAX HANDLERS */

     // GET requests handler
     getHandler(url){
        var auth=JSON.parse(localStorage.getItem('Authorization'));
        var headers={
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': 'Bearer '+auth.access_token
        }

        return axios({
          method:'get',
          url: HOST_API +url,
          headers:headers
        })
     }

    // Create a list 
    getListOperators (schemaId) {
        this.getHandler("/api/scheme/operator/?scheme_id="+schemaId).
            then((res) =>{
                if(res.status==200){
                    var list = [];
                    if(res.data.operators.length > 0){
                        var items = [...res.data.operators];
                        let a = items.filter(e => e.title && e.title.length > 0); 
                        var list = [];
                        for(let i=0; i<a.length;i++) {
                            let temp ={
                                'title':a[i].title,
                                'id': a[i].id 
                            }
                            list.push(temp);
                        }
                        const options = list.map((item, index) => {   
                            return <option key={index + 1} value={item.id}>{item.title.replace(/%20/g, " ")}</option>
                        });

                        this.setState({ operatorsList: list,banderaList:true, schemaId, options});
                    }
                }else{
                    console.log(" No se pudo obtener la lista");
                }
            }).catch((error)=> {
                console.log('catch getOperatorsList',error)
                if (error.response.status == 403)
                    cerrarSesion();
            });
    }

    bindValue = () => {
        if(typeof this.props.bindValue === "function")
             this.props.bindValue(this.state.operator,this.props.k?this.props.k:'');
    }
    updateOperator = (event) => {
        this.setState({operator: event.target.value}, () => {
            this.bindValue();
        });
    }

    updateOperatorData(){
        if(this.props.operatorSeleccion1){
                this.setState({operator: this.props.operatorSeleccion1}, () => {
                this.bindValue();
            });
        }
    }

    componentDidMount = () => {
        this.setState({ operator: this.props.operator,schemaId:this.props.schemaId});
        this.updateOperatorData();
    }

    render() {
        let list = []
        if(this.state.banderaList && (this.state.schemaId == this.props.schemaId)){
            list = this.state.operatorsList;
        }else if(this.props.operatorsList){
            list = this.props.operatorsList;
        }
        const options = list.map((item, index) => {   
            return <option key={index + 1} value={item.id}>{item.title.replace(/%20/g, " ")}</option>
        });

        return (
            <Row>
                <Col sm="12">
                    <FormGroup>
                        <Label>Operador <i className="fa fa-info-circle" id="tooltipOperator"></i></Label>
                        <UncontrolledTooltip placement="right" target="tooltipOperator">{i18next.t('message39')}</UncontrolledTooltip>
                        <Input type="select" name={this.props.k?this.props.k:"select"} onChange={(event) => { this.updateOperator(event) }} value={this.state.operator?this.state.operator:this.props.operatorSeleccion1} required>
                            <option value='' key={0}>{i18next.t('select_one')}</option>
                            {
                                options
                            }
                        </Input>
                    </FormGroup>
                </Col>
            </Row>
        )
    }
}

export default OperatorsSelector