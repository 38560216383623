import { AbstractNodeFactory } from "storm-react-diagrams";
import React from "react";

import QRNodeWidget from "./QRNodeWidget";
import QRNodeModel from "./QRNodeModel";

class QRNodeFactory extends AbstractNodeFactory {
	constructor() {
		super("qr");
	}

	generateReactWidget(diagramEngine, node) {
		return <QRNodeWidget node={node} />
	}

	getNewInstance() {
		return new QRNodeModel();
	}
}

export default QRNodeFactory