import React, {useState} from 'react';
import i18next from '../../../../i18n';
import { Link } from "react-router-dom";
import Admin from '../../admin/containers/admin';
import SliceMenuApp from '../../admin/components/slice-menu'
import {
    Col,
    Row,
    Button,
    Container,
    Modal,
    ModalHeader,
    ModalBody,
    FormGroup,
    Label,
    Input,
    Form,
    Table,
    Card,
    UncontrolledTooltip
} from 'reactstrap';
import Select from 'react-select';
import ToolbarApp from '../../admin/components/toolbar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash, faSync, faStopCircle } from '@fortawesome/free-solid-svg-icons'

import Audio from '../../../components/audio-input';
import ModalCustomURL from './modal-custom-url';
import BotMenuOptions from '../../menus/bot-menu-options.js';
import './massive-campaign-layout.css';
import Datetime from 'react-datetime';
import './react-datetime.css';
import Mentions from '../../../components/mentions/mentions-header';
require('moment/locale/es')
const utf8 = require('utf8');
var momentzone = require('moment-timezone');
var dateFormat = require('dateformat');
import { isEmpty } from '../../../utils/commonFunction';


import DataTableFilter from '../../analytics/infoViz/common/dataTableFilter'

    /* IMAGE */
import imageNew from '../../../../static/img/menu-icons/nuevo_bot.svg'

function statusCampaign(status) {
    let response = ''
    switch (status) {
        case 'PE':
            response = 'Pendiente'
            break;
        case 'CA':
            response = 'Cancelada'
            break;
        case 'OK':
            response = 'OK'
            break;
        case 'PR':
            response = 'Procesando'
            break;
        default :
            response = 'Pendiente'
        break;
    }
    return response
}


function formateDate(send_date) {
    let hoy = new Date().getTime();
    let date = new Date('2018-06-03T09:30:17.000Z').getTime();
    console.log('hoy',new Date(),'date',new Date('2019-07-02T09:30:17.000Z'))
    let diferencia ='';
    let diferenciaHora=''
    let diferenciaDias=''
    let diferenciaSemana=''
    let diferenciaMes=''
    let diferenciaAnno=''
    let before_after=''
    if (date > hoy) {
        // tiempo despues
        before_after = 'Faltan '
        diferencia = date - hoy;
    } else if (hoy > date) {
        // tiempo atras
        before_after = 'Hace '
        diferencia = hoy - date; //if mayor a 24 { faltan # dias} else if { ayer }
    }
    diferenciaHora = Math.round(diferencia / (1000 * 60 * 60));
    diferenciaDias = Math.round(diferencia / (1000 * 60 * 60 * 24));
    diferenciaSemana = Math.round(diferencia / (1000 * 60 * 60 * 24 * 7));
    diferenciaMes = Math.round(diferencia / (1000 * 60 * 60 * 24 * 7 * 30));
    diferenciaAnno = Math.round(diferencia / (1000 * 60 * 60 * 24 * 7 * 30 * 12));
    
    /*
    console.log('diferenciaHora', diferenciaHora, 'diferenciaDias', diferenciaDias, 'diferenciaSemana', diferenciaSemana,
        'diferenciaMes', diferenciaMes, 'diferenciaAnno', diferenciaAnno)
    */
    return diferenciaHora;
}

function dateLoca(date) {
    let date_moment = new Date(`${date} UTC +0`)
    date_moment = dateFormat(date_moment, "yyyy-mm-dd HH:MM:ss");
    return date_moment
}


function useForceUpdate() {
    let [value, setState] = useState(true);
    return () => setState(!value);
}



function MassiveCampaignLayout(props) {    
    let _showSms = false;
    let _showContactsList = false;
    let _showSender = false;
    let _showEmail = false;
    let _showMessageVoz = false;
    let _showBot = false;
    let _showOnlySubject = false;
    let _showUpAudio = false;
    let _showWpFb = false;
    let _channel_id =  -1; 

    const _showInput = (type, channel_id) => {
        // Reset variables
        _showSms = false;
        _showContactsList = false;
        _showSender = false;
        _showEmail = false;
        _showMessageVoz = false;
        _showBot = false;
        _showOnlySubject = false;
        _showUpAudio = false;
        _showWpFb = false;
        
        switch (`${type}:${channel_id}`) {
            case 'TX:1':
                _showSms = true;
                _showContactsList = true;
                _showSender = true;
                break;
    
            case 'TX:3':
                _showContactsList = true;
                _showSender = true;
                _showEmail = true;
                break;
    
            case 'TX:5':
                _showMessageVoz = true;
                _showContactsList = true;
                _showSender = true;
                break;
    
            case 'AT:1':
                _showBot = true;
                break;
    
            case 'AT:3':
                _showBot = true;
                _showOnlySubject = true;
                break;
    
            case 'AT:5':
                _showContactsList = true;
                _showBot = true;
                break;
              
            case 'AU:5':
                _showContactsList = true;
                _showSender = true;
                _showUpAudio = true;
            break;

            default:
            break;
        }
      }


    const _messageType = (type)=>{
          let res = 0;
          switch (type) {
            case 'TX':
                res = 1;
                break;
            case 'AT':
                res = 2;
                break;
            case 'AU':
                res = 3;
                break;
            default:
                break;
          }
          return res;
      }

    if (!isEmpty(props.campaignEditing)){
        const { message_type = '', channel: { id = '' } } = props.campaignEditing;
        _channel_id = id;
        _showInput(message_type, id);

    }

    // Listando las campañas
    const campaign = props.listAllCampaign || [];
    let listAllCampaign = []
    

    var dataTable=null;
    if(campaign[0]){

        <th width="250">Acciones</th>
         dataTable = {
            columns: [
              {
                label: i18next.t('title'),
                field: 'titulo',
                sort: 'asc',
               // width: 150
              },
              {
                label: i18next.t('bot_name'),
                field: 'name_bot',
                sort: 'asc',
           //     width: 270
              },
              {
                label: i18next.t('channel'),
                field: 'canal',
                sort: 'asc',
               // width: 150
              },
              {
                label: i18next.t('send_date'),
                field: 'fecha_envio',
                sort: 'asc',
           //     width: 270
              },
              {
                label: i18next.t('status'),
                field: 'estado',
                sort: 'asc',
           //     width: 270
              },
              {
                label: i18next.t('edition_option'),
                field: 'options',
                sort: 'asc',
                width: 670,
                options: {
                    filter: false,
                    sort: false
                   }
              }],
              rows: []};
 

              const rows = campaign.map((campaign, index) => {

            
                const fin=[];
                fin.push(utf8.decode(campaign.title));
                fin.push(campaign.bot.name);
               
                fin.push(campaign.channel.channel);
                fin.push(dateLoca(campaign.send_date));
                fin.push(statusCampaign(campaign.status));
                fin.push(
                        <div className="d-inline-block">
                            {(campaign.status != 'PE') ||
                                <Button outline color="success" onClick={(e) => props.handleEditCampaign(campaign, true)}>
                                    <FontAwesomeIcon icon={faPen} id={'buttonEdit' + index} />
                                    <UncontrolledTooltip placement="top" target={'buttonEdit' + index}> {i18next.t('edit')}</UncontrolledTooltip>
                                </Button>
                            }

                            <Button outline color="info" onClick={(e) => props.stopCampaign(campaign.id)}  >
                                <FontAwesomeIcon icon={faStopCircle} id={'buttonDetener' + index} />
                            </Button>
                            <UncontrolledTooltip placement="top" target={'buttonDetener' + index} >{i18next.t('stop_campaign')}</UncontrolledTooltip>


                            <Button outline color="danger" onClick={(e) => props.deleteCampaign(campaign.id)}>
                                <FontAwesomeIcon icon={faTrash} id={'buttonDelete' + index} />
                            </Button>
                            <UncontrolledTooltip placement="top" target={'buttonDelete' + index} > {i18next.t('delete')}</UncontrolledTooltip>
                        </div>
                
                )
                dataTable.rows.push(fin); 
                return campaign;
            });
        }

        const options = {
            filterType: 'multiselect',
            selectableRows:'none',
            resizableColumns: false,
            responsive: "scrollMaxHeight",
            rowsPerPage:5 ,
            print:false,
            download:false,
            viewColumns:false,
           
          };
          
              var title = ''

    const _optionsListSenders = (channel_id) => {
        let channel = '';
        let channelTW = '';
        switch (channel_id) {
            case 3:
                channel = 'EM';
                break;
            case 5:
                channel = 'PH';
                channelTW = 'TW';
                break;
            case 1:
                channel = 'PH';
                channelTW = 'TW';
                break;
        
            default:
                break;
        }
                
        const res = props.listAllSender
        .filter((item)=>( item.channel_type === channel || item.channel_type === channelTW ))
        .map(( item ) => ({value: item.id, label: item.name}))
        
        //res.push( {value: 0, label: "POR DEFECTO"} );  
        
        return res;    
    };

    const _defaultSender = (channel_id) => {
        let channel ='';
        let channelTW='';
        switch (channel_id) {
            case 3:
                channel='EM';
                break;
            case 5:
                channel='PH';
                channelTW='TW';
                break;
            case 1:
                channel='PH';
                channelTW='TW';
                break;
        
            default:
                break;
        }
        
        const { sender_id = '' } = props.campaignEditing;
        return props.listAllSender
        .filter((item)=>(
             (item.channel_type === channel ||
             item.channel_type === channelTW) && 
             sender_id == item.id  ))
        .map(( item ) => ({value: item.id, label: item.name}) );
      
    }

    // Lista de opciones para el template de email
    const template = props.listTemplates || [];
    const sel_template = [];
    template.map((t, i) => {
        let channelSelected = false;
        if (!isEmpty(props.campaignEditing) && props.campaignEditing.channel.id == 3) {
            // Mantener la plantilla seleccionada
            channelSelected = ( props.campaignEditing.template_id && t.id == props.campaignEditing.template_id.id) ? true : false
        }
        sel_template.push(<option key={"template_id-" + i} value={t.id} selected={channelSelected}>{t.template_name}</option>);
    })

    let _defaultListContact = () => {
        // Force update as stateless
        const { listContact = []} = props;
        const { list_id = '' } = props.campaignEditing;
        return listContact.filter(( item ) => item._id == list_id ).map(( item )=>({value: item._id, label: item.titulo }));
    };
    
    const _optionsListContact = () => {
         const { listContact = [] } = props;
         return listContact.map(( item )=>({value: item._id, label: item.titulo }));
    };

    return (
        <div className="NotificaionsLayout">
            <Admin>
                <SliceMenuApp>
                    <BotMenuOptions admin={props.isAdmin} />
                </SliceMenuApp>
                <Container fluid>
                    <ToolbarApp>
                        <Row>
                            <Col sm="9" md="9" lg="9" className="text pdtoolbar">
                                <h2 className="text-left ml-4 font-weight-bold">{i18next.t('campaign_massive')}</h2>
                            </Col>
                            <Col className="filter-stuff col-sm-4 col-md-3 col-lg-3">
                                <Row>
                                    <Col md={2}>
                                        <Button outline color="success" onClick={ (e) => props.getListCampaign("all")}> 
                                            <FontAwesomeIcon icon={faSync} id='buttonRefreshE' style={{'fontSize':'18px'}} />
                                        </Button>
                                    </Col>
                                    <Col md={8}>
                                        <Link to='/massive-campaign/'>
                                            <Button className="pull-right btn-block" color="success" >{i18next.t('new_campaign')}</Button>
                                        </Link>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </ToolbarApp>
                </Container>
                <Container fluid className="box-fluid">
                <Col sm="12" md="10" className="stadistics-container offset-md-1">    
                    {
                       dataTable?(
                        <Row >
                            <Col xs={12} lg={12} >
                                <DataTableFilter
                                title={title}
                                data={ dataTable.rows}
                                columns={dataTable.columns}
                                options={options}
                                />
                           </Col>
                        </Row>): 
                          <Col xs={12} lg={8} className="offset-lg-2 intro-funnels">
                                <Row>
                                    <Col xs={12}><img src="/static/img/blank_state.png" className="img-fluid"/></Col>
                                    <Col xs={12}>
                                        <h2>{i18next.t('msg_without_campaign')}</h2>
                                        <h3>{i18next.t('msg_here_existing_campigns')}</h3>
                                        <p>{i18next.t('new_campaign')}.</p>
                                    </Col>
                                </Row>
                            </Col>
                        }
                    </Col>
                </Container>

                <Modal className="modalRight" isOpen={props.modalNewNotification} fade toggle={() => ((props.editedCampaign) ? props.toggleModalEditCampaign() : props.toggleModalNewCampaign())}>
                    <ModalHeader toggle={() => ((props.editedCampaign) ? props.toggleModalEditCampaign() : props.toggleModalNewCampaign())} className='notification'>{i18next.t('configure_campaign')}</ModalHeader>
                    <ModalBody className='notification-body'>
                        <Col md={12} className="sectionBox">
                            <Form id="frm_notification" onSubmit={props.handleSaveCampaign}>
                                <FormGroup>
                                    {props.editedCampaign &&
                                        <Input type="hidden" id="campaign_id" name="campaign_id" defaultValue={props.campaignEditing.id} />
                                    }
                                    <Input type="hidden" id="sender_id" name="sender_id" defaultValue={props.selectedOptionSender.value} />
                                    <Input type="hidden" id="channel" name="channel" defaultValue={(!!props.campaignEditing.channel)?props.campaignEditing.channel.id:''} />
                                    <Input type="hidden" id="tipo_Mensaje" name="tipo_Mensaje" defaultValue={_messageType(props.campaignEditing.message_type)} />

                                    <Label>{i18next.t('title')}</Label>
                                    <Input type="text" id="title" name="title" maxLength={200}
                                        defaultValue={(!isEmpty(props.campaignEditing)) ? utf8.decode(props.campaignEditing.title) : null}
                                        required />    

                                    { _showBot &&
                                        <div>
                                            <Label>Bot</Label>
                                            <Select id="bot" defaultValue={(!isEmpty(props.campaignEditing)) ? { value: props.campaignEditing.bot.id, label: props.campaignEditing.bot.name } : null } onInputChange={props.handleChangeInputValueBots} onChange={props.handleChangeBots} options={props.listBots} isMulti={false} name="bot" className="bot" required />
                                        </div>
                                    }
                                    {   _showContactsList &&
                                        <div>
                                            <Label className="plusBtn">{i18next.t('contact_list')} &nbsp;<a target="_blank" href={"/contact-lists/"} className="pull-right btn btn-success">{i18next.t('new_list')}</a></Label>
                                            <Row>
                                                <Col className="col-10">
                                                    <Select id="list" defaultValue = {  _defaultListContact() } onChange={props.handleChangeSelectContact} options={_optionsListContact()} isMulti={false} name="list" />
                                                </Col>
                                                <Col className="col-2 pl-0">
                                                    <Button outline color="success" onClick={ (e) => props.getLists()}>
                                                        <FontAwesomeIcon icon={faSync} id='buttonRefreshE' style={{'fontSize':'18px'}} />
                                                    </Button>
                                                    <UncontrolledTooltip placement="bottom" target='buttonRefreshE'> {i18next.t('refresh_contact_list')}</UncontrolledTooltip>
                                                </Col>
                                            </Row>
                                        </div>
                                    }
                                    {   _showSms && 
                                        <div>
                                            <Label>{i18next.t('message')} SMS</Label><Button color="success" className="btn-sm float-right" onClick={()=>props.toggleModalCustomUrl()}>{i18next.t('add_url')}</Button>
                                            <Mentions k={1} varList={props.varList}  defaultValue={props.message || ''} updateItem={props.updateItem} maxlength={'160'} msg_error={'Máximo permitido 160'}/>
                                        </div>  
                                    }

                                    {   _showMessageVoz &&
                                        <div >
                                            <Label>{i18next.t('message')} </Label>
                                            <Mentions k={1} varList={props.varList}  defaultValue={ isEmpty(props.message)?`${(props.campaignEditing.message || '')}` : props.message } updateItem={props.updateItem} maxlength={'16000'} msg_error={'Máximo permitido 16000'}/>
                                        </div> 
                                    } 
                                    {   _showUpAudio &&
                                        <Audio operator_id='campaign' audio={props.campaignEditing.message} bindValue={props.getAudio}/>
                                    }
                                   
                                    <i hidden={!_showBot}>Se usuara un número o código doble vía donde un bot será quien interactue con cada uno de sus contactos.</i>
                                    <i hidden={!_showSms}>Escriba el mensaje que quiere enviar a sus contactos, los usuarios no podrán responder a este mensaje.</i>

                                    {   _showWpFb &&
                                        <div>
                                            <Label>{i18next.t('message')} Whatsapp o Facebook</Label>
                                            <Mentions k={2} varList={props.varList}  defaultValue={props.message || ''} updateItem={props.updateItem} maxlength={'2000'} msg_error={'Máximo permitido 2000'}/>
                                        </div>
                                    }

                                    { _showOnlySubject &&
                                        <div>
                                            <Label>{i18next.t('subject')}</Label> 
                                            <Input type="text" id="subject" name="subject" maxLength={500} defaultValue={(!isEmpty(props.campaignEditing)) ? utf8.decode(props.campaignEditing.subject) : ''} />  
                                        </div>
                                    }

                                    { _showEmail &&
                                        <React.Fragment>
                                            <Label>{i18next.t('subject')}</Label> 
                                            <Input type="text" id="subject" name="subject" maxLength={500} defaultValue={(!isEmpty(props.campaignEditing)) ? utf8.decode(props.campaignEditing.subject) : ''} />  
                                            <Label className="plusBtn">{i18next.t('template_email')} &nbsp;<a target="_blank" href="/Newtemplates" className="pull-right btn btn-success">{i18next.t('new_template')}</a> </Label>
                                            <Row>
                                                <Col className="col-10">
                                                    <Input type="select" name="plantillaEmail" id="plantillaEmail" className="plantillaEmailBox">
                                                        <option key={"template_id-0"} value='' style={{ 'display': 'none' }}>-{i18next.t('select_option')}-</option>
                                                        {sel_template}
                                                    </Input>
                                                </Col>
                                                <Col className="col-2 pl-0">
                                                    <Button outline color="success" onClick={(e) => props.getlistTemplates()}>
                                                        <FontAwesomeIcon icon={faSync} id='buttonRefresh' style={{ 'fontSize': '18px' }} />
                                                    </Button>
                                                    <UncontrolledTooltip placement="bottom" target='buttonRefresh'> {i18next.t('refresh_template_email_list')}</UncontrolledTooltip>
                                                </Col>
                                            </Row>

                                        </React.Fragment>
                                    }
                                    { _showSender && 
                                        <div >
                                            <Label >{i18next.t('sender')}</Label>
                                            <Select id="remitente_campaign" onChange={props.handleChangeSender} defaultValue = {_defaultSender(_channel_id)} options = { _optionsListSenders(_channel_id) } isMulti={false} name="remitente_campaign" />
                                        </div>
                                    }                 

                                    <br/>
                                    <Label>{i18next.t('send_date')}</Label>
                                    <Select id="tipo_envio" onChange={props.handleChangeSendDate} options={props.SendDateOptions} isMulti={false} name="tipo_envio" className="tipo_envio" />
                                    { props.showSendDate && 
                                        <Datetime id='send_date' name='send_date' required defaultValue={(props.editedCampaign) ? dateLoca(props.campaignEditing.send_date) : props.selectedDay} dateFormat={'YYYY-MM-DD'} timeFormat={'HH:mm:ss'} onChange={props.handleChangeDay} inputProps={{ placeholder: 'YYYY-MM-DD HH:mm:ss', readOnly: true }} locale="es" />
                                    }
                                    
                                </FormGroup>
                            </Form>
                        </Col>
                        <Col className="buttonModal">
                            <Col className="col-10 offset-1">
                                <Row>
                                    <Col className=" col-6 offset-3">
                                        <Button className="btn-block btn-guarumo-modal-sm" type="submit" form="frm_notification">
                                        {i18next.t('save')}
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Col>

                    </ModalBody>
                </Modal>                  
               <ModalCustomURL clean={props.cleanInput} isOpen={props.isOpenCustomUrl} toggleModal={props.toggleModalCustomUrl} varList={props.varList} ConcatItem={props.ConcatItem} updateItem={props.updateItem} dataurl={props.dataurl}/>
            </Admin>



        </div>
    )
}

export default MassiveCampaignLayout