import React, { Component } from 'react';
import { connect } from "react-redux";

import LCMLayout from './location-coordinates-modal-layout';

import { isAuthorizationValid,getRolUser,isEmpty,cerrarSesion } from "../../../../utils/commonFunction";

import { APPLICANT } from "../../../../redux/listComponents";
import { isDidMount, willUnMount } from "../../../../redux/actions/componentMountActions";

import { iconLoad, 
         iconHideLoad, 
         getListTypeLocation,
         getListLocation,
         updateBoxOperator,
         setBotmsgText,
         toggleModalViewBoxLocation,
         setChangeConfig,
         updateOperator,
         createNewStatement,
         updateStatement,
         removeStatement,
         cleanSelection,
         setVarList } from "../../../../redux/actions/boxActions";


class LCModal extends Component {

    constructor(props){
        super(props);
        this.state = {
            keyword_valid: true,
        
            isAdmin: false,
            isSuperUser: false,
            hideLoad: false,
            user:'',
        }; 
        
    }
    
     componentWillMount(){
        isAuthorizationValid()
        const { isAdmin = false, isSuper = false } = getRolUser();
        this.setState({
            isAdmin: isAdmin,
            isSuperUser: isSuper
        });
      }


    componentDidMount()
    {
        const { boxReducer : { listTypeLocation,listLocation} } = this.props;

        this.props.isDidMount(APPLICANT.HOME());    

        //----------------------------------------------------------------
        //  PONE EL ICONO DE CARGA
        //----------------------------------------------------------------
         this.props.iconLoad();

        //----------------------------------------------------------------
        //  LISTA TODAS LOS TIPOS DE LOCALIZACIÓN, COJE EL PRIMERO COMO SELECTTYPELOCATION
        //----------------------------------------------------------------
        if(isEmpty(listTypeLocation)){
             this.props.getListTypeLocation();     
        }


        //----------------------------------------------------------------
        //  LISTA TODAS LAS LISTAS DE  LOCALIZACIONES, COJE EL PRIMERO COMO SELECTLOCATION
        //----------------------------------------------------------------
        if(isEmpty(listLocation)){
            this.props.getListLocation();     
       }

        //----------------------------------------------------
        //  OCULTA EL ICONO DE CARGA
        //----------------------------------------------------
        this.props.iconHideLoad(); 

    }
    shouldComponentUpdate(nextProps, nextState)
    {
        const nextModal = nextProps.modal;
        const modal = this.props.modal;

        return modal !== nextModal;

    }
   /* componentWillUpdate(nextProps,nextState)
    {
        const tab = this.props.boxReducer.activeItem;
        const newTab = nextProps.boxReducer.activeItem;

        if(tab != newTab)
        {
            this.forceUpdate();
        }
    }*/

    componentWillUnmount() {
        this.props.willUnMount(APPLICANT.HOME());
      }


    getAvatarId = (avatarid) => {

         //OJO SE DEBE CAMBIAR AL COMPONENTE avatar.js cuando todas las cajas usen redux
         //OJO SOLO ESTA EN EL COOKIECUTER NO SE GUARDA EN LA BD!! Se debería guardar como atributo de??
         //this.props.updateBoxOperator('avatar_id',avatarid);
        this.props.modal.node.extras.avatar = avatarid;
       
    }

    //FUNCION AL PRESIONAR EL BOTÓN FINALIZAR DEL MODAL
    toggleModuleModal = async ()  =>{
        if(!this.state.keyword_valid)
            return false;

        //Pone icono de carga
        this.props.iconLoad();

        //Carga el formulario para extraer los datos
        let form = new FormData(document.getElementById('LCM-form'));

        //Selecciona el nodo asociado a la caja
        let node = this.props.modal.node;
        
        //Extrae y actualiza el mensaje
        node.extras.botmsg_text = $('#input-head_0').val();
        this.props.setChangeConfig('botmsg_text',node.extras.botmsg_text);

        //Extrae y actualiza el avatar
        //node.extras.avatar = $('#input-head').val();
        this.props.setChangeConfig('avatarid',node.extras.avatar);

        
        //Estrae y actualiza el título del operador
        node.extras.titleOperator = form.get('titleOperator');
        //OJO SE DEBE CAMBIAR AL COMPONENTE title-operator.js cuando todas las cajas usen redux
        await this.props.updateBoxOperator('title', node.extras.titleOperator);//this.getTitleOperator(node.extras.titleOperator);   

        
        // node.extras.botmsg_id = form.get('botmsg_id');

        const {configBoxOperator,selectedTypeLocation,variablesBoxOperator,dinamicVariablesBoxOperator,listLocation,selectedBoxOperator,selectedListLocation} = this.props.boxReducer;

         //Si el tipo de localizacion cambió se eliminan los statements exixtentes para crear las nuevas variables
        if( selectedTypeLocation.type&&node.extras.config.item_type!=selectedTypeLocation.type)
        {
            //Actualiza el tipo seleccionado
            await this.props.setChangeConfig('item_type',selectedTypeLocation.type);
            node.extras.config.item_type = selectedTypeLocation.type;

             //Elimina todo los staments para crear los nuevos
            await this.removeAllStatements();

            

        }
       
        //Si es tipo closest_location y solo hay una lista de ubicaciones coja esa.
        if(selectedTypeLocation.type=='closest_location')
        {
            if(!selectedListLocation.id)
                await this.props.setChangeConfig('file',listLocation[0].id);

            if(selectedListLocation.id!=node.extras.config.file){
                //Actualiza el archivo seleccionado
                await this.props.setChangeConfig('file',selectedListLocation.id);

                //Elimina todo los staments para crear los nuevos
                await this.removeAllStatements();
            }
             

        }
        


        //Extrae y actualiza los Keywords
        node.extras.keyword = selectedBoxOperator.keyword;


        //Actualiza las variables. Si no cambió el tipo actualiza los statements, si cambió crea los nuevos
        let blocksVar = variablesBoxOperator;
        let kblocksVar = Object.keys(blocksVar);

        if( kblocksVar.length==0)
            node.extras.variables={}

        await kblocksVar.map((variable)  =>   {
            //Si no se tiene un id de statement se crea en la base de datos
            if(blocksVar[variable].statement_id==''){
                this.props.createNewStatement(node.extras.dbId,variable,node,'variables');
               
            }else { 
                // Edita el statement cuando ya existe en la base de datos
                this.updateStatement(blocksVar[variable].statement_id, variable, variable, blocksVar[variable].var_name!=''?true:false,blocksVar[variable].var_name)
            }
        });


         //Actualiza las variables dinámicas. Si no cambió el tipo actualiza los statements, si cambió crea los nuevos
         let blocksDinVar = dinamicVariablesBoxOperator;
         let kblocksDinVar = Object.keys(blocksDinVar);
 
         if( kblocksDinVar.length==0)
            node.extras.dinamicVariables={}

         await kblocksDinVar.map((variable) =>  {
       
             //Si no se tiene un id de statement se crea en la base de datos
             if(blocksDinVar[variable].statement_id==''){
                this.props.createNewStatement(node.extras.dbId,variable,node,'dinamicas');
                
             }else { 
                 // Edita el statement cuando ya existe en la base de datos
                   this.updateStatement(blocksDinVar[variable].statement_id, variable, variable, blocksDinVar[variable].var_name!=''?true:false,blocksDinVar[variable].var_name)
             }
         });
        
         //Extrae y guarda la nueva configuración
         node.extras.config = configBoxOperator;

         await this.props.updateOperator(node.extras.titleOperator,node.extras.keyword,node.extras.config);

       
          //Actualiza el esquema
         await this.props.updateSchema();

         //Limpia selecciones
         await this.props.cleanSelection();

          //Actualiza la lista de variables
         await this.props.setVarList();

         //Cierra el modal
         await this.props.toggleModalViewBoxLocation();
    }

    

    //Valida si es un keyword valido
    isKeywordValid = (val) => {        
        this.setState({keyword_valid:val}, ()=>{
            this.forceUpdate();
        })
    }


    //Inserta los Emoji al enunciado
    callEmoji = (code,k,type)=>{

        let botmsg_text ='';
        let text = '';
        
        if(type=='pop_up')
        {
            botmsg_text = $('#input-head').val();

            text = botmsg_text; 
            text += ` ${code}`;

            this.props.setChangeConfig("pop_up",text);
            
        }
        else
        {
            botmsg_text = $('#input-head_0').val();

            text = botmsg_text; 
            text += ` ${code}`;
            
            this.props.setBotmsgText(text);
        }
           
        
    }
    
    //Actualizar información de statement
    updateStatement = (statement, text, name, is_var, var_name) => {
        const data = {
            id: statement,
            text: text,
            name: name,
            is_var:is_var,
            var_name:var_name
        };

        this.props.updateStatement(data);
    
    }

    //Eliminar un Statment
    removeStatementbyId = (statement) => {
        this.setState({error_limit_item:false});
        const data = {
            id: statement,
        };

        this.props.removeStatement(data);
        this.props.updateSchema();

    }

    //Eliminar todos los statements
    removeAllStatements = () => {
        let blocks = this.props.modal.node.extras.statements_id;
        let kblocks = Object.keys(blocks);
        console.log("Statements a eliminar: ",kblocks)
        kblocks.map((variable) => {
            this.removeStatementbyId(variable);
            delete blocks[variable];

        });
        
    }


    /* END SEARCH VARIABLE FUNCTIONS AND HANDLERS */
    showContent=()=>{
        const {  isLogin } = getRolUser();

        const {selectedBoxOperator } = this.props.boxReducer;
        if(isLogin){
            //Si la caja seleccionada es tipo localización
          if(selectedBoxOperator&&selectedBoxOperator.operatortype_id==20)
          {
            return (
            
                <LCMLayout 
                toggleModuleModal={this.toggleModuleModal}
                isKeywordValid={this.isKeywordValid}
               
                getAvatarId={this.getAvatarId}
                callEmoji = {this.callEmoji}
      
              //   botmsg_id={this.props.modal.node.extras.botmsg_id}
      
                />
                );
          }
          else
          {
              return(<div></div>);
          }
            
        }
        return <ModalLoading />
      }

    render() {
        return this.showContent();
    }
 }

const mapStateToProps = ({ botReducer,boxReducer,homeReducer, componentMountReducer }) => {
    return { botReducer,boxReducer,homeReducer, componentMountReducer };
   };
   
   const mapDispatchToProps = {
    isDidMount,
    willUnMount,
    iconLoad,
    iconHideLoad,
    getListTypeLocation,
    getListLocation,
    updateBoxOperator,
    setBotmsgText,
    toggleModalViewBoxLocation,
    setChangeConfig,
    updateOperator,
    createNewStatement,
    updateStatement,
    removeStatement,
    cleanSelection,
    setVarList
    
   };
   
   export default connect(mapStateToProps, mapDispatchToProps)(LCModal);
