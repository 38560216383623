import React, { Component } from 'react';
import ModalLoad from '../../../components/modals/modal-load';
import BotFunnelLayout from './bot-funnel-layout';
import moment from "moment";
import qs from 'qs';
import {getHandlerGET} from '../../../utils/handlersApi'
import {cerrarSesion,isAuthorizationValid} from '../../../utils/commonFunction'
import i18next from '../../../../i18n';

class BotFunnel extends Component {
    
    constructor(props){
        super(props);
        this.state = {
            botId: this.props.match.params.id,
            hideLoad: 0,
            botVersionId:'',
            bot:'',
            funnel:'',
            funnelName:'',
            startDate: moment(),
            endDate: moment(),
            g3: { data:{} },
            breadcrumb:''
        }; 
        this.handleChangeStart = this.handleChangeStart.bind(this);
        this.handleChangeEnd = this.handleChangeEnd.bind(this);
    }

   getData = () => {
        let filterData = {
            id:this.state.funnel
        }
        if(this.state.startDate != '' && this.state.startDate != null && this.state.endDate != null){
            filterData.period = this.state.startDate.format('YYYY/MM/DD');
            if(this.state.endDate != ''){
                filterData.period +='-'+this.state.endDate.format('YYYY/MM/DD');
            }else{
                filterData.period +='-'+this.state.startDate.format('YYYY/MM/DD');
            }
        }else{
            alert(i18next.t('contact_filter_msg'))
            this.setState({
              startDate: moment(),
              endDate: moment()
            })
        }
        
        let str = qs.stringify(filterData);
        return getHandlerGET(HOST_API +'/api/reports/funnels?'+str)

    }

    drawGrapihc = () => {
        let cont = this.state.hideLoad;
        this.setState({ hideLoad: cont+1});
        this.getData().then(
            (result) => {
                if(result.status==200){
                    const funnel_Name =result.data.funnel[0].funnel_name;
                    const data = result.data.data;
                    const labelsFunnel = data.labels;
                    const datosFunnel = data.data;
                    
                    var color = [];
                    var dynamicColors = function() {
                        var r = 0;
                        var g = Math.floor(51 + Math.random() * 204);;
                        var b = 0;
                        return "rgb(" + r + "," + g + "," + b + ")";
                     };

                     for (var i in datosFunnel) {
                        color.push(dynamicColors());
                     }

                    const g3 = {
                                data :{
                                    labels:labelsFunnel,
                                    datasets:[
                                        {
                                            "label":"Usuarios",
                                            "data":datosFunnel,
                                            "fill":true,
                                            "borderColor":"rgb(75, 192, 192)",
                                            "lineTension":0.1,
                                            "pointRadius":10,
                                            "pointStyle":'rectRounded',
                                            "backgroundColor":color,
                                            
                                        }
                                    ]
                                }
                        
                    };
                    this.setState({
                        g3: g3,
                        breadcrumb:labelsFunnel,
                        funnelName:funnel_Name
                    });
                    
                }
            let cont = this.state.hideLoad;
            this.setState({ hideLoad: cont-1});
            }
        ).catch((error) => {
            console.log('catch drawGrapihc',error);
            if (error.response && error.response.status && error.response.status == 403){
                cerrarSesion();
            }
            let cont = this.state.hideLoad;
            this.setState({ hideLoad: cont-1});
        });
    }
  
    /*
    * Función para cambiar las fechas del filtro
    * d es la fecha en moment.js
    */
    handleChangeStart(d){
        this.setState({startDate: d});
    }
    handleChangeEnd(d){
        this.setState({endDate: d});
    }

    componentWillMount(){
        isAuthorizationValid();
    }

    componentDidMount(){
        //obtengo el id del bot enviado como parametro en la url
        const { id, idfunnel } = this.props.match.params;
        //guardo el id en el state
        this.setState({
            botId: id,
            funnel:idfunnel
        });
        getHandlerGET(HOST_API +'/api/bot/?id='+this.state.botId)
        .then((res) => {
            if(res.status==200){
                if(res.data.bot && res.data.bot.length > 0){
                    const bot = res.data.bot[0];
                   
                    //guardamos en el state la iformación del bot actual y su version
                    this.setState({
                        botVersionId: bot.bot_version[0].botversion_id,
                        bot: bot,
                        botId: bot.id,
                        ia_activated: bot.ia_activated
                    });
                    this.drawGrapihc();
                }
            }
        }).catch((error) => {
            console.log('catch getHandlerGET bot',error);
            if (error.response && error.response.status && error.response.status == 403){
                cerrarSesion();
            }
        });
    }

    render() {
        return (
          <div>
            <ModalLoad hideLoad={this.state.hideLoad}/>
            <BotFunnelLayout 
              botId={this.state.botId} 
              data3={this.state.g3.data} 
              options3={this.state.g3.options}
              breadcrumb={this.state.breadcrumb}
              startDate ={this.state.startDate}
              endDate ={this.state.endDate}
              handleChangeStart ={this.handleChangeStart}  
              handleChangeEnd ={this.handleChangeEnd} 
              getData = {this.drawGrapihc}
              funnelname ={this.state.funnelName} 
              />
          </div>
        )
    }
}

export default BotFunnel