import React, {Fragment} from 'react';

import { 
    Button, 
    Modal, 
    ModalHeader, 
    ModalBody, 
    ModalFooter,
    Form, 
    FormGroup, 
    Table, 
    Input, 
    Col,
    Row,
    Alert,
    Label,
    UncontrolledTooltip
} from 'reactstrap';
import TitleOperator from "../../../../components/title-operator";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'

import '../../../../components/search-variable.css';
import TextVar from '../../../../components/textvar/textvar';
import i18next from '../../../../../i18n';

function makeVariables(varList, current){
    let var_list = varList;
    let vars = var_list.map((item, key) => {
        if(`[var=${item.id}]` == current)
            return <option key={key} value={`[var=${item.id}]`} selected>{item.text}</option>;
        else
            return <option key={key} value={`[var=${item.id}]`}>{item.text}</option>;
    });
    return vars;
}

function makeForm(props){
    let items = [];
    let cs = props.conditionals;
    let keys_cs = Object.keys(cs);

    const label=i18next.t('select_var');
    const tooltip = i18next.t('select_var_compare'); 
    
    keys_cs.forEach( (k, i) => {
        let item = cs[k];
        
        items.push(
            <FormGroup key={k}>
                <Row>
                    <Col sm="2">
                    {
                        i > 0 &&
                        <Input type="select" onChange={(e) => {props.setConditional(k, 'connector', e.target.value)}} value={item.connector}>
                            <option value="">{i18next.t('select_one')}</option>
                            <option value="and">Y</option>
                            <option value="or">O</option>
                        </Input>
                    }
                    </Col>

                    <Col sm="3">
                        <Input type="select" onChange={(e) => {props.setConditional(k, 'conditionLeft', e.target.value)}} defaultValue={item.conditionLeft}>
                            <option value="">{i18next.t('select_var')}</option>
                            {
                                makeVariables(props.varList, item.conditionLeft)
                            }
                        </Input>
                    </Col>

                    <Col sm="2">
                        <Label>{i18next.t('conditional')} <i className="fa fa-info-circle" id={"tooltipOperator_"+k}></i></Label>
                        <UncontrolledTooltip placement="right" target={"tooltipOperator_"+k}> {i18next.t('infor_select_operator')}</UncontrolledTooltip>
                           
                        <Input type="select" name="select" onChange={ (e) => {props.setConditional(k, 'operator', e.target.value)}} value={item.operator}>                                    
                            <option value="">{i18next.t('select_one')}</option>
                            <option>&#61;&#61;</option>
                            <option>&#33;&#61;</option>
                            <option>&#60;</option>
                            <option>&#62;</option>
                            <option>&#60;&#61;</option>
                            <option>&#62;&#61;</option>
                        </Input>
                    </Col>

                    <Col sm="3">
                        <TextVar variables={props.varList} onChange={(e) => {props.setConditional(k, 'conditionRight', e.value)}} defaultValue={item.conditionRight} />
                    </Col>

                    <Col sm="2">
                        {
                            keys_cs.length - 1 == i && 
                            <Button color="success" type="button" onClick={props.addConditionalBlock}>
                                <FontAwesomeIcon icon={faPlus} />
                            </Button>
                        }

                        <Button color="warning" type="button" onClick={ () => props.removeConditionalBlock(k) }>
                            <FontAwesomeIcon icon={faMinus} />
                        </Button>
                    </Col>
                </Row>

                <Row>
                    <Col sm="12">
                        <Alert color="danger" isOpen={props.show_error}>
                            {i18next.t('verify_list_fields')}
                        </Alert>
                    </Col>
                </Row>
            </FormGroup>
        );
    });
    return items;
}

function IFMLayout(props){
        return (
            <div>
                <Modal className="upload_file" isOpen={props.isOpen} toggle={props.closeModal} fade size="lg">
                <ModalHeader toggle={props.closeModal}>{i18next.t('configure_conditional')} </ModalHeader>
                    <Form id="CONM-form" role="form">  
                    <ModalBody>
                    <TitleOperator titleOperator={props.titleOperator} bindValue={props.getTitleOperator} />
                        {
                            makeForm(props)
                        }
                    </ModalBody>
                        <ModalFooter>
                            <Col sm="6">
                                
                            </Col>
                            <Col sm="6" align="right">
                                <Button color="success" size="lg" type="button" onClick={props.toggleModuleModal} >{i18next.t('finish')}</Button>
                            </Col>
                        </ModalFooter>
                    </Form>
                </Modal>           
            </div>
        );
}
  
export default IFMLayout
 