import { NodeModel } from "storm-react-diagrams";
import SMPortModel from "../simplePortModel/SMPortModel";

class DSNodeModel extends NodeModel {
	extras = {
		title: "DESUSCRIPCIÓN",
		width: 200,
        dbId: 0,
		question:'',
		idStatementQuestion:0,
		edited:false,
		sayBye:'',
		idStatementSayBye:0
    }
    
	removeBox = null
	changeHome = null
	toggleEdit = null
	customClone = null
	
	constructor() {
		super("dsus");
        this.addPort(new SMPortModel(false, "left"));
		this.addPort(new SMPortModel(true, "right"));
	}
}

export default DSNodeModel