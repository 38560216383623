// Dependencies
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Components
import NewTemplateLayout from './new-template-layout';
import ModalLoad from '../../../components/modals/modal-load';

// Common functions
import { cerrarSesion, getUserInfoLocalStorage, isEmpty} from '../../../utils/commonFunction'
import { getHandlerGET } from '../../../utils/handlersApi'
import { saveTemplate, updateTemplate, getTemplateByiD, resetTemplate } from '../../../redux/actions/templateActions';
import { setSelectedBot } from '../../../redux/actions/botActions';

class NewTemplate extends Component {

  state = {
    isAuth: false,
    hideLoad: 0,
    botId: this.props.match.params.id,
    botVersionId: '',
    ia_activated: false,
    contentHtml: '',
    contentDesign: '',
    listContact: [],
    isOpen: false,
    isOpenEdit: false,
    listVariables: [],
  }

  constructor(props) {
    super(props)

    this.saveTemplate = this.saveTemplate.bind(this);
    this.saveEditTemplate = this.saveEditTemplate.bind(this);
  }

  componentDidMount() {
    const { getTemplateByiD, match: { params: { tempid = '' } }, resetTemplate } = this.props;

    const { bot } = this.props.botReducer; 

    //obtengo el id del bot enviado como parametro en la url
        const { id } = this.props.match.params
        //guardo el id en el state
        this.setState({
            botId: id
        });


      if(isEmpty(bot) && id)
      {
        this.props.setSelectedBot(id);
      }
    
    this.getList();
    if (tempid !== '') {
      getTemplateByiD(tempid, () => {
        this.forceUpdate();
      });
    } else {
      resetTemplate();
    }

  }

  /**
   * @name toggleEditModal: 
   * **NOTE:** Abre y cierra, el modal de `creación` de una plantilla
   */
  toggleModal = () => this.setState({ isOpen: !this.state.isOpen });

  /**
   * @name toggleEditModal: 
   * **NOTE:** Abre y cierra, el modal de `edición` de una plantilla
   */
  toggleEditModal = () => this.setState({ isOpenEdit: !this.state.isOpenEdit });


 /**
   * @name saveTemplate: 
   * **NOTE:** Envía por metodo `POST` el contenido en JSON, usando Redux.
   */
  saveTemplate = (event) => {
    event.preventDefault();
    const form = new FormData(event.target);
    let list = '';
    if (form.get('tempStateList') == '')
      list = form.get('lista')
    else
      list = form.get('tempStateList')
    const data = {
      template_name: form.get('title'),
      template_content: JSON.stringify(this.state.contentHtml),
      list_id: list,
      template_json_content: JSON.stringify(this.state.contentDesign)
    }

    const { saveTemplate } = this.props;
    // Request to save template
    saveTemplate(data, () => {
      this.props.history.push("/templates/");
    });

  }

 /**
   * @name saveEditTemplate: 
   * **NOTE:** Envía por metodo `PUT` el contenido en JSON, usando Redux.
   */
  saveEditTemplate = (event) => {
    event.preventDefault();
    const form = new FormData(event.target);
    let list = '';
    if (form.get('tempStateList') == '')
      list = form.get('listaEdit')
    else
      list = form.get('tempStateList')

    const data = {
      tempid: form.get('tempEditId'),
      template_name: form.get('editTitle'),
      template_content: JSON.stringify(this.state.contentHtml),
      list_id: list,
      template_json_content: JSON.stringify(this.state.contentDesign)
    }

    const { updateTemplate } = this.props;
    updateTemplate(data, () => {
      this.props.history.push("/templates/");
    });

  }

  /**
   * @name getList: Get all list contact by company
   */
  getList = () => {
    getHandlerGET(HOST_API + "/api/listas/bycompany?id_company=" + getUserInfoLocalStorage().company_id).
      then((res) => {
        if (res.status == 200)
          this.setState({ listContact: res.data })
        else
          console.log(" No se pudo obtener la lista");
      }).catch((error) => {
        console.log('catch getList', error);
        if (error.response.status == 403)
          cerrarSesion();
      });
  }

  /**
   * @name getVariables: Get all field that have a specific list contact
   * @param {String} e: Value del componente selector de variables
   */
  getVariables = (e) => {
    getHandlerGET(HOST_API + '/api/listas/cabecerajs?_id=' + e)
      .then((res) => {
        if (res.status == 200) {
          this.setState({ listVariables: res.data })
        }
        else
          console.log(" No se pudo obtener la lista");
      }).catch((error) => {
        console.log('catch GET getVariables', error);
        if (error.response.status == 403)
          cerrarSesion();
      });

  }

  /**
   * @name receiveData: Save html and design template in the state
   * @param html: json html code 
   * @param design: json online design
   */
  receiveData = (html, design) => this.setState({ contentHtml: html, contentDesign: design });

  render() {
    return (
      <div>
        <NewTemplateLayout
          botId={this.state.botId}
          ia_activated={this.state.ia_activated}
          receiveData={this.receiveData}
          toggleModal={this.toggleModal}
          toggleEditModal={this.toggleEditModal}
          isOpen={this.state.isOpen}
          isOpenEdit={this.state.isOpenEdit}
          listContact={this.state.listContact}
          saveTemplate={this.saveTemplate}
          saveEditTemplate={this.saveEditTemplate}
          getVariables={this.getVariables}
          listVariables={this.state.listVariables}

        />
        <ModalLoad hideLoad={this.state.hideLoad} />
      </div>
    )
  }
}



const mapStateToProps = ({ templateReducer,botReducer }) => ({ templateReducer,botReducer });

const mapDispatchToProps = {
  saveTemplate,
  updateTemplate,
  getTemplateByiD,
  resetTemplate,
  setSelectedBot
};

export default connect(mapStateToProps, mapDispatchToProps)(NewTemplate);


