import { NodeModel } from "storm-react-diagrams";
import SMPortModel from "../simplePortModel/SMPortModel";
import i18next from '../../../../../i18n';

class VariableNodeModel extends NodeModel {
    extras = {
        text: i18next.t('without_text'),
		title: i18next.t('variable').toUpperCase(),
        width: 200,
        dbId: 0,
        edited: false,

        idStatementSubject: 0,
        subject: '',
        idtemplateSelected: 0,
        VariableReceiver: '',
        emailSender: '',
        emailsCCO: '',
        variables: []
    }

    removeBox = null
    changeHome = null
    toggleEdit = null
    customClone = null

    constructor() {
        super("variable");
        this.addPort(new SMPortModel(false, "left"));
        this.addPort(new SMPortModel(true, "right"));
    }
}

export default VariableNodeModel