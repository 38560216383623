import { COMPANY_ACTIONS } from '../types/companyActionsTypes';


const INITIAL_STATE = {
    listAllCompanies: [],
    users: [],
    avatarList: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
        case COMPANY_ACTIONS.getAllCompanies():
            return {
                ...state,
                listAllCompanies: action.payload,
            };
        case COMPANY_ACTIONS.getUsersByCompany():
            return {
                ...state,
                users: action.payload,
            };
        case COMPANY_ACTIONS.getAvatarsByCompany():
            return {
                ...state,
                avatarList: action.payload,
            };
		default: return state;
	};
};