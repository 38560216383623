import { AbstractNodeFactory } from "storm-react-diagrams";
import React from "react";

import MMNodeWidget from "./MMNodeWidget";
import MMNodeModel from "./MMNodeModel";

class MMNodeFactory extends AbstractNodeFactory {
	constructor() {
		super("multimedia");
	}

	generateReactWidget(diagramEngine, node) {
		return <MMNodeWidget node={node} />
	}

	getNewInstance() {
		return new MMNodeModel();
	}
}

export default MMNodeFactory