import React, { Component, KeyboardEventHandler } from 'react';
import { connect } from "react-redux";

import CreatableSelect from 'react-select/creatable';
import { ActionMeta, OnChangeValue } from 'react-select';
import i18next from '../../i18n';
const components = {
  DropdownIndicator: null,
};

// interface Option {
//   readonly label: string;
//   readonly value: string;
// }

const createOption = (label) => ({
  label,
  value: label,
});

// interface State {
//   readonly inputValue: string;
//   readonly value: readonly Option[];
// }

export  class CreatableInputOnly extends Component{

  constructor(props) {
    super(props);
    let values = []
    console.log(props.values);

    if(props.values)
      values = JSON.parse(props.values);
    
  
    console.log(values);
    this.state = {
      inputValue: '',
      value: values,
    };
  }
  
  handleChange = (
    value,
    actionMeta
  ) => {
    console.group('Value Changed');
    console.log(value);
    console.log(`action: ${actionMeta.action}`);
    console.groupEnd();
    this.setState({ value });

    if(this.props.getHandlerRemove && actionMeta.action == 'remove-value')
      this.props.getHandlerRemove(value);
    
  };
  handleInputChange = (inputValue) => {
    this.setState({ inputValue });
  };
  handleKeyDown = (event) => {
    const { inputValue, value } = this.state;
    if (!inputValue) return;

    switch (event.key) {
      case 'Enter':
      case 'Tab':
        
        const nuevo= createOption(inputValue);
        
        this.setState({
          inputValue: '',
          value: [...value, nuevo],
        });
       
        event.preventDefault();

        if(this.props.getHandler)
          this.props.getHandler(nuevo);

    }
  };
  render() {
    const { inputValue, value } = this.state;
    return (
      <CreatableSelect
        components={components}
        inputValue={inputValue}
        isClearable
        isMulti
        menuIsOpen={false}
        onChange={this.handleChange}
        onInputChange={this.handleInputChange}
        onKeyDown={this.handleKeyDown}
        placeholder= {i18next.t('write_enter')}
        value={value}
      />
    );
  }
}


const mapStateToProps = ({ boxReducer,analyticsReducer }) => {
  return { boxReducer,analyticsReducer };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(CreatableInputOnly);