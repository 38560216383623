import React, { Component } from "react";
import ModalLoad from "../../../components/modals/modal-load";
import ContactListsLayout from "./layout";
import {getHandlerPOST,getHandlerGET,fileHandlerPOST} from '../../../utils/handlersApi'
import {cerrarSesion,getUserInfoLocalStorage,getRolUser} from '../../../utils/commonFunction'
import i18next from "../../../../i18n";

class ContactLists extends Component {
  constructor(props) {
    super(props);
    this.state = {
      company_id: 0,
      hideLoad: 0,
      contactLists: [],
      botInfo: null,
      modalNewList: false,
      modalEditList: false,
      botversion_id: "",
      files: [],
      contacts: [],
      listElement: {},
      modalEditContact:false,
      editContact:{},
      idListContactEdited:'',
      totalRepeat:0,
      isAdmin:false
    };
  }
  
  componentWillMount(){
    //console.log('Llegue will')
    const { isAdmin = false, isSuper = false } = getRolUser();
    this.setState({
        isAdmin: isAdmin,
        isSuperUser: isSuper
    });
  }

  componentDidMount() {
    const { isLogin=false } = getRolUser();
    if(isLogin){
      this.getAllLists();
    }
  }

  /*Funciton open and close modals */
  toggleModalNewList = () => {
    let frm = document.getElementById("frm_save_new");
    if (frm) document.getElementById("frm_save_new").reset();
    this.setState({
      modalNewList: !this.state.modalNewList,
      listElement: {},
      contacts: []
    });
  };

  toggleModalEditList = index => {
    let listElement = this.state.contactLists[index];
    this.getContacts(listElement._id);
    this.getTotalRepeatRow(listElement._id)
    this.setState({
      modalNewList: !this.state.modalNewList,
      listElement: listElement
    });
  };

  deleteList = index => {
    if (confirm(i18next.t('message180'))) {
      let listElement = this.state.contactLists[index];
      var data = {
        _id: listElement._id
      };
      let url_api = HOST_API + "/api/listas/delete_list";
      getHandlerPOST(url_api, data)
        .then(res => {
            if(!res.data.deleted){
              alert(i18next.t('message181'))
            }
          this.getAllLists();
        })
        .catch(this.errorAction);
    }
  };

  editContactHandler = event => {
    let resultAction = res => {   
      let frm = document.getElementById("frm_edit_contact");
      if (frm) document.getElementById("frm_edit_contact").reset();           
      if (res.status == 200) {
        alert(i18next.t('contact_edit_msg'));       
        this.getContacts(this.state.idListContactEdited); 
        this.toggleOpenModalEditContact();       
      } else console.log(" No se pudo GUARDAR");
    };
      event.preventDefault();
      let form = new FormData(event.target); 
      // Guardamos la edicion
      var data = {};
      form.forEach((value, key) => {   
        data[key] = value;     
      });   
    
      let url_api = HOST_API + "/api/listas/edit_contact";  
      getHandlerPOST(url_api, data)
        .then(resultAction)
        .catch(this.errorAction);    
  };

  toggleModalEditContact=(contact,_id) => {
    this.setState({
      modalEditContact: !this.state.modalEditContact,
      editContact:contact,
      idListContactEdited:_id
    });
  };

 /*Funciton open and close modals */
  toggleOpenModalEditContact = () => {
    let frm = document.getElementById("frm_edit_contact");
    if (frm) document.getElementById("frm_edit_contact").reset();
    this.setState({
      modalEditContact: !this.state.modalEditContact,     
    });
  };

  deleteContact=(num_row,_id)=>{
    if (confirm("Desea eliminar el contacto?")) {
      var data = {
        num_row: num_row,
        _id: _id
      };
      let url_api = HOST_API + "/api/listas/delete_contacts";
      getHandlerPOST(url_api, data)
        .then(res => {
        this.getContacts(_id);
        })
        .catch(this.errorAction);
    }
  };

  /*
   * getAllLists
   * Trae todos las listas de envío de una compañía   
   * Devuelve un objeto
   */
  getAllLists = () => {
    let cont = this.state.hideLoad;
    this.setState({ hideLoad: cont + 1 });
    getHandlerGET(
        HOST_API + "/api/listas/bycompany?id_company=" + getUserInfoLocalStorage().company_id
      ) 
      .then(res => {
        if (res.status == 200) {
          this.setState({ contactLists: res.data });
        }
        let cont = this.state.hideLoad;
        this.setState({ hideLoad: cont - 1 });
      })
      .catch(error => {
        if (
          error.response &&
          error.response.status &&
          error.response.status == 403
        ) {
          cerrarSesion();
        }
        let cont = this.state.hideLoad;
        this.setState({ hideLoad: cont - 1 });
      });
  };

  /*
   * choiseFile
   * Evento onsubmit del formulario de creacion
   * ---
   */

  chosenFile = event => {
    this.setState({
      files: event.target.files
    });
  };

  getContacts = id => {
    let cont = this.state.hideLoad;
    this.setState({ hideLoad: cont + 1 });
    getHandlerGET(HOST_API + "/api/listas/content?_id=" + id)
      .then(res => {
        if (res.status == 200){
          if(res.data.data){
            this.setState({ contacts: res.data.data.data });
          }
        }
        let cont = this.state.hideLoad;
        this.setState({ hideLoad: cont - 1 });
      })
      .catch(error => {
        console.log('catch getContacts',error);
        if (
          error.response &&
          error.response.status &&
          error.response.status == 403
        ) {
          this.cerrarSesion();
        }
        let cont = this.state.hideLoad;
        this.setState({ hideLoad: cont - 1 });
      });
  };

  getTotalRepeatRow = id => {
    let cont = this.state.hideLoad;
    this.setState({ hideLoad: cont + 1 });
    getHandlerGET(HOST_API + "/api/listas/recors_repeated_total/?_id="+id)
      .then(res => {
        if (res.status == 200) {
          this.setState({totalRepeat:res.data.total})
        }
        let cont = this.state.hideLoad;
        this.setState({ hideLoad: cont - 1 });
      })
      .catch(error => {
        console.log('catch getTotalRepeatRow',error);
        let cont = this.state.hideLoad;
        this.setState({ hideLoad: cont - 1 });
      });
  };

  errorAction = error => {
    console.log("catch POST saveNotiHandler", error);
    if (error.response && error.response.status && error.response.status == 403)
      this.cerrarSesion();
  };

  /*
   * saveNewHandler
   * Evento onsubmit del formulario de creacion
   * ---
   */

  saveNewHandler = event => {
    let resultAction = res => {
      this.setState({
        files: []
      });

      document.getElementById("lista").value = "";
      if (res.status == 200) {
        alert("Datos guardados con exito");
        this.getAllLists();
        this.setState({ listID: res.data.id });
        if (!this.state.listElement._id) {
          this.toggleModalNewList();
        }
      } else console.log(" No se pudo GUARDAR");
    };

    event.preventDefault();
    let form = new FormData(event.target);

    if (this.state.files.length > 0) {
      //Subimos archivo
      form.append("files", this.state.files[0].file);
      let url_api = HOST_API + "/api/listas/create";
      if (this.state.listElement._id) {
        url_api = HOST_API + "/api/listas/update_with_file";
      }
      fileHandlerPOST(url_api, form)
        .then(resultAction =>{
          if(resultAction.status==200){
            this.setState({files:[]})
            if(resultAction.data.total_repeated && resultAction.data.total_repeated > 0){
              alert(`La lista que acaba de agregar tiene ${resultAction.data.total_repeated} registro(s) repetido(s).`)
            }
            this.setState({modalNewList:!this.state.modalNewList},()=>this.getAllLists())
          }
          
        })
        .catch(this.errorAction);
    }else{
      // Guardamos la lista sin data
      var data = {};
      form.forEach((value, key) => {
        data[key] = value;
      });
      let url_api = HOST_API + "/api/listas/createjs";
      if (this.state.listElement._id) {
        url_api = HOST_API + "/api/listas/updatejs";
      }
      getHandlerPOST(url_api, data)
        .then(resultAction =>{
          if(resultAction.status == 200){
            this.setState({files:[]})
            alert(i18next.t('data_update_msg'));    
            this.toggleModalNewList()
            this.getAllLists();
          }
        })
        .catch(this.errorAction);
    }
  };
  validateData = (event) =>{
    event.preventDefault();
    let form = new FormData(event.target);
    let data = {
      _id:form.get('idlista'),
      check_domain: (form.get('check') == 'on'),
      ping_email:(form.get('ping') == 'on')
    }
    getHandlerPOST(HOST_API+"/api/listas/validate_emails/",data)
    .then(res => {
      if (res.status == 200) {
        if(res.data.msg == "Ok"){
          alert('Validación completa');
        }else{
          alert(i18next.t('message182'));
        }
         
      }else{
        console.log(" No se pudo recuperar info de la lista");
      }
      let cont = this.state.hideLoad;
      this.setState({ hideLoad: cont - 1 });
    })
    .catch(error => {
      console.log('catch validateData',error);
      let cont = this.state.hideLoad;
      this.setState({ hideLoad: cont - 1 });
    });
  }

  showContent=()=>{
    const {  isLogin } = getRolUser();
    if(isLogin){
      return (
        <div>
          <ContactListsLayout
            isAdmin={this.state.isAdmin}   
            contactLists={this.state.contactLists}
            modalNewList={this.state.modalNewList}
            toggleModalNewList={this.toggleModalNewList}
            modalEditList={this.state.modalEditList}
            toggleModalEditList={this.toggleModalEditList}
            botId={this.state.botId}
            saveNewHandler={this.saveNewHandler}
            company_id={getUserInfoLocalStorage().company_id}
            chosenFile={this.chosenFile}
            contacts={this.state.contacts}
            listElement={this.state.listElement}
            deleteList={this.deleteList}
            deleteContact={this.deleteContact} 
            modalEditContact={this.state.modalEditContact} 
            toggleOpenModalEditContact={this.toggleOpenModalEditContact}  
            toggleModalEditContact={this.toggleModalEditContact}
            editContact={this.state.editContact}
            editContactHandler={this.editContactHandler}
            totalRepeat = {this.state.totalRepeat}
            validateData={this.validateData}
          />
          <ModalLoad hideLoad={this.state.hideLoad} />
        </div>
      );
    }
    return <ModalLoad hideLoad={1} />
  }


  render() {
   return this.showContent();
  }
}

export default ContactLists;
