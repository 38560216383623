import React from "react";
import { Link } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";

/* IMAGES */
import volver from "../../../static/img/menu-icons/volver.svg";
import dashboard from "../../../static/img/menu-icons/dashboard_home.svg";
import metricas from "../../../static/img/menu-icons/metricas.svg";

import crearDashboard from "../../../static/img/menu-icons/crear_dashboard.svg";
import crearChart from "../../../static/img/menu-icons/reportes_dashboard.svg";
import reportes from "../../../static/img/menu-icons/web_forms.svg";
import reportesMail from "../../../static/img/menu-icons/push.svg";
import i18next from "../../../i18n"

let showSubMenu = () =>
  (document.getElementById("stats-submenu").hidden = false);
let hideSubMenu = () =>
  (document.getElementById("stats-submenu").hidden = true);

function AnalyticsMenuOptions(props) {
  return (
    <span>
      <li id="tooltipHomeAdmin">
        <Link to="/">
          <img src={volver} />
          <UncontrolledTooltip placement="right" target="tooltipHomeAdmin">
          {i18next.t('back')}
          </UncontrolledTooltip>
        </Link>
      </li>
      <li id="tooltipDashboard">
        <Link to="/dashboard">
          <img src={dashboard} />
          <UncontrolledTooltip placement="right" target="tooltipDashboard">
          {i18next.t('dashboard')}
          </UncontrolledTooltip>
        </Link>
      </li>
     {/* <li id="tooltipReportes"> 
        <Link to="/reportes"> 
        <img src={reportes} /> 
        <UncontrolledTooltip placement="right" target="tooltipReportes">Reporte Interacciones</UncontrolledTooltip>
        </Link>  
    </li>
    <li id="tooltipReportesUser"> 
        <Link to="/reportes/user"> 
        <img src={reportesMail} /> 
        <UncontrolledTooltip placement="right" target="tooltipReportesUser">Reporte Email</UncontrolledTooltip>
        </Link>  
    </li>  */}
      <li
        id="tooltipReport"
        className="stat-menu-item"
        onMouseOver={showSubMenu}
        onMouseLeave={hideSubMenu}
      >
        <a className="sideboard-btn sideboard-statistics">
          <img src={reportes} />
        </a>
        <div id="stats-submenu" hidden>
          <ul>
          <li id="tooltipReportes"> 
              <Link to="/reportes"> 
              {i18next.t('report_interactions')}
              </Link>
            </li>
            <li id="tooltipReportesUser"> 
              <Link to="/reportes/custom"> 
              {i18next.t('custom_reports')}
              </Link>  
          </li>
          <li id="tooltipCustomersReport"> 
              <Link to="/customersReport"> 
                {i18next.t('report_customer')}
              </Link>  
          </li> 
          
          <li id="tooltipReportesBlockChain"> 
              <Link to="/reportes/blockchain"> 
              {i18next.t('report')} BlockChain
              </Link>  
          </li> 
            <li id="tooltipReportesUser"> 
              <Link to="/reportes/user"> 
              {i18next.t('report')} Email
              </Link>  
          </li> 
          <li id="tooltipReportesSMS"> 
              <Link to="/reportes/sms"> 
              {i18next.t('report')} SMS
              </Link>  
          </li> 
          </ul>
        </div>
        <UncontrolledTooltip placement="top" target="tooltipReport">
        {i18next.t('report')}
        </UncontrolledTooltip>
      </li> 

      {props.admin && (
        <li id="tooltipMetricas">
          <Link to="/adminmetricas">
            <img src={metricas} />
            <UncontrolledTooltip placement="right" target="tooltipMetricas">
              {i18next.t('admin_metrics')}
            </UncontrolledTooltip>
          </Link>
        </li>
      )}
      {props.admin && (
        <li id="tooltipChartNew">
          <Link to="/adminchart">
            <img src={crearChart} />
            <UncontrolledTooltip placement="right" target="tooltipChartNew">
              {i18next.t('admin_graphics')}
            </UncontrolledTooltip>
          </Link>
        </li>
      )}
      {props.admin && (
        <li id="tooltipDashboardNew">
          <Link to="/admindashboard">
            <img src={crearDashboard} />
            <UncontrolledTooltip placement="right" target="tooltipDashboardNew">
              {i18next.t('admin_dashboard')}
            </UncontrolledTooltip>
          </Link>
        </li>
      )}
    </span>
  );
}

export default AnalyticsMenuOptions;
