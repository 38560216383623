import React from 'react';

import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';

import Select from 'react-select';
import { find, propEq, map, pipe, toPairs, transpose, converge, zipObj, head, last } from "ramda";

import { 
    Col,
    Row,
    FormGroup,
    Label,
    Input, 
  } from 'reactstrap';

import i18next from '../../../i18n';

function SelectedCompany(props){
    const { selectedCompany } = props.analyticsReducer;   
    const { listAllCompanies } = props.homeReducer;

    const renameKeys = keysMap => pipe(
        toPairs, // convert to entries
        transpose, // convert to array of keys, and array of values
        converge(zipObj, [ // zip back to object
          pipe(head, map(key => keysMap[key] || key)), // rename the keys
          last // get the values
        ])
      )

    if(listAllCompanies && listAllCompanies.length>0)
    {
      
        
  
        const listCompanies = map(renameKeys({ name: 'label', id: 'value' }), listAllCompanies)


        let varSelected = find(propEq("value",  parseInt(selectedCompany)))(listCompanies)

        const colourStyles = {
            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                color: isDisabled
                ? '#ccc'
                : isSelected
                ? 'white'
                : 'black'
            };
            },///.....
            menuPortal: provided => ({ ...provided, zIndex: 9999 }),
            menu: provided => ({ ...provided, zIndex: 9999 })
            ///.....
        };



  
        return (
            varSelected && varSelected!=''?( 
            <Col sm="3" md="3" className="filter-stuff">
                <FormGroup>
                    <Row>
                        <Col className="col-lg-11">
                             <Label sm={3} for="companyName" className="text-left font-weight-bold" style={{color:'#5a5a5a'}}>
                                {i18next.t('company')+': '}
                            </Label>
                            {listCompanies&&listCompanies.length > 0 ? (
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                defaultValue={(selectedCompany)?varSelected:''}
                                isDisabled={false}
                                isLoading={false}
                                isClearable={false}
                                isRtl={false}
                                isSearchable={true}
                                name="type"
                                options={listCompanies?listCompanies:[]}
                                onChange={(e) => props.onchangeCompanies(e)}
                                styles={colourStyles}
                                menuPortalTarget={document.body}
                                menuPosition={'fixed'} 
                             
                                />
                            ):<div className="alert alert-danger" role="alert">
                                {i18next.t('company_message')}
                            </div>
                            }
                        </Col>
                    </Row>
                </FormGroup>
            </Col>):
            <div className="alert alert-danger" role="alert">
                {i18next.t('company_message')}
            </div>
            
        );
    }
    else 
        return(
            <div className="alert alert-danger" role="alert">
                {i18next.t('company_message')}
            </div>
        );
}
  

const mapStateToProps = ({homeReducer,analyticsReducer}) => {
    return {homeReducer,analyticsReducer};
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps )(withRouter(SelectedCompany));