import React, { Component } from 'react';
import { connect } from "react-redux";

import { MentionsInput, Mention } from "react-mentions";
import { isEmpty } from '../../utils/commonFunction';

import { setVarList,setBotmsgText } from "../../redux/actions/boxActions";
import { find, propEq } from "ramda";
import i18next from '../../../i18n';

const defaultStyle = {
  control: {
    height: "auto",
    width: "100%",
    textAlign:'left',
    position: "flex",

  },
  highlighter: {
    overflow: 'hidden',
  },
  input:{
    borderRadius:"4px",
    border:"1px solid #cdcdcd",
    padding:"6px",
    overflow: 'auto',
    height: 50,
    textAlign:'left'
  },
  suggestions: {
    marginTop: "30px",
    marginLeft: "10px",
    textAlign:'left',
    // zIndex: "5!important",
    list: {
      backgroundColor: "#fff",
      // border: "1px solid #d2d6de",
      position:'fixed',
      zIndex: "20000!important",
      height: "131px",
      overflow: "auto",
      borderStyle: "solid",
      borderColor: "silver",
      borderWidth: "0.5px",
      

    },
    item: {
      padding: "5px 15px",
      borderBottom: "1px solid #cdcdcd",

      "&focused": {
        backgroundColor: "#5a5a5a",
        color: "white",
        fontSize: 18,
      }
    },
  }
};

const defaultMentionStyle ={
    backgroundColor: '#75b74b',
    borderRadius:"3px",
    paddingBottom:'2px',
    textAlign:'left'
}

class Mentions extends Component {
  constructor(props){
    super(props);
}
    state = {
        searchActive: true,
        search:'',
        error_input:false,
        value: this.props.defaultValue
    }
    
    componentDidMount()
    {

      const {varListSelected} = this.props.boxReducer;

      if(isEmpty(varListSelected))
        this.props.setVarList();
    }

    componentWillUpdate(nextProps,nextState){

      const  message  = nextProps.defaultValue;

       if(message!=this.state.value)
       {
           this.setState({value: message});
       }
  }
  
    onChange = e => {
        let value = e.target.value;
        let statement_length = value.length;
          if(statement_length > this.props.maxlength){
            this.setState({error_input:true});
          }else{
            if(this.props.updateItem)
              this.updateItem(value);
            
            this.props.setBotmsgText(value);
            this.setState({error_input:false});
          }
          this.setState({value});
    };
    updateItem = (e) =>{
      this.props.updateItem(this.props.index,e)
    }
    render() {

      const formatIdList = (id, index) => {
  
        // El id no es 0, corresponde a una variable diferente de session_var
        if( id && id != 0 ) return id;

        return `s${index}`;
      };

        var data =[]
        let k = this.props.k;
        
        const {varListSelected} = this.props.boxReducer;

        if(varListSelected.length > 0){
          varListSelected.map((elem, index )=>{
               
              let type = "";
              let display = "";
              if (elem.operator_id) {
                type = "normal";
                display = elem.name;
              }else{
                type = "header";
                display = elem.text;
              } 
  
              const temp = {
                id: formatIdList(elem.id, index),
                display: display,
                type: ("session_var" in elem && elem.session_var ) ? "session_var" : type,
              };
              if(display != "")
                data.push(temp);
            });
        }

        return (
          // <div className="modal-maxWidth-alone">
          <div>
            <MentionsInput
                
                allowSpaceInQuery
                style={defaultStyle}
                value={this.state.value}
                onChange={this.onChange}
                id={"input-head_"+ k}
                name={'name'}
                markup="[__id__]"
                displayTransform={id => { 
                  let text =String(id); 
                  let bandera = '';
                  let nameVar = '';

                  let variable = find(
                    propEq("id", parseInt(id))
                  )(data);
                  
                  if(variable){
                    bandera = variable.type;
                    nameVar = variable.display;
                  }
                  
                   // De acuerdo a la bandera  será el formato del mentions
                  if (bandera ==  "session_var") {

                    if (text.search('ses') == -1) {
                      return "[ses=" + nameVar + "]";
                    }else{
                      return "[" + nameVar + "]";
                    } 

                  }else if(bandera == "normal"){

                    if (text.search('var') == -1) {
                      return  "[var=" + id + "]";
                    }else{
                      return "[" + id + "]";
                    } 

                  }else if(bandera == "header"){

                    if (text.search('var_list') == -1) {
                      return "[var_list=" + id + "]";
                    }else{
                      return "[" + id + "]";
                    } 

                  }else{

                    return "[" + id + "]";

                  } 

              }}
            >
                <Mention trigger={/(\@([A-Za-z0-9_.]*))$/} data={data} style={defaultMentionStyle}/>
                
            </MentionsInput>
            <small><strong>@</strong> {i18next.t('use_var')}</small>
            { this.state.error_input && 
                <span className="warning-character">{this.props.msg_error}</span>
            } 
        </div>
        );
    }
}

const mapStateToProps = ({ boxReducer,homeReducer, componentMountReducer }) => {
  return { boxReducer,homeReducer, componentMountReducer };
 };
 
 const mapDispatchToProps = {
  setVarList,
  setBotmsgText
 };
 
 export default connect(mapStateToProps, mapDispatchToProps)(Mentions);

