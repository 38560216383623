import React, { Component } from 'react';
import EventLayout from './event-layout';
import axios from 'axios';
import qs from 'qs';
import {isvalidatedOnlyNumberText} from '../../utils/isValidate'
import {ToastsContainer, ToastsStore} from 'react-toasts';
import { cerrarSesion, isEmpty } from '../../utils/commonFunction'
import i18next from '../../../i18n';

class EventModal extends Component {

    state = {
    }

    constructor(props){
        super(props);
    }

    toggleModuleModal = () =>{
        const node = this.props.node;
        //create the botmsg statement if it doesn't exists.
       
        if ( !node.extras.isEventEdited ) {
            this.saveEvent(node.extras.event, this.props.botversion_id ,node.extras.dbId)
                .then((res) => {
                    if (res.status== 200){
                        node.extras.event_id = res.data.event.id;
                        this.props.updateSchema();
                        this.forceUpdate();
                    }
                }).catch((error)=>{
                    console.log('catch toggleModuleModal',error);
                    if (error.response && error.response.status && error.response.status == 403){
                        cerrarSesion();
                    }
                });
        }else { // edit the statement.
            this.updateEvent(node.extras.event, node.extras.event_id)
                .then((res) => {
                    if (res.status== 200){
                        this.props.updateSchema();
                        this.forceUpdate();
                    }
                });
        }

        this.props.toggleEventModal({
            extras: {}
        }, false);

    }

    saveEvent = (name, version, operador) => {
        //data para enviar en el servicio web
        const data = {
            event_name: name,
            botversion_id: version,
            operator_id: operador
        };

        //obtenemos el token de autenticación
        var auth=JSON.parse(localStorage.getItem('Authorization'));
        var headers={
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer '+ auth.access_token 
        }

        
        return axios({
            method:'post',
            url:HOST_API +'/api/reports/events',
            data:qs.stringify(data),
            headers:headers
        })
    }

    updateEvent = (name, event_id) => {
        //data para enviar en el servicio web
        const data = {
            event_name: name,
            id: event_id
        };

        //obtenemos el token de autenticación
        var auth=JSON.parse(localStorage.getItem('Authorization'));
        var headers={
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer '+ auth.access_token 
        }

        return axios({
            method:'put',
            url:HOST_API +'/api/reports/events',
            data:qs.stringify(data),
            headers:headers
        })
    }

    updateEventName = (event) => {
        let url=  window.location.pathname
        let val=url.substr(url.lastIndexOf("/")+1,url.length)       
        if(isvalidatedOnlyNumberText(event.target.value)){
            this.props.node.extras.eventTemp = event.target.value
            this.props.node.extras.event = val+'_'+event.target.value
            if ( this.props.node.extras.event_id) {
                this.props.node.extras.isEventEdited = true;
            }

            this.forceUpdate();            
        }else{
            ToastsStore.warning(i18next.t('validate_number_text'))
        }
    }

    /* END SEARCH VARIABLE FUNCTIONS AND HANDLERS */

    render() {
        return (
          <EventLayout 
          toggleModuleModal={this.toggleModuleModal}
          isOpen={this.props.isOpen} 
          eventTemp={this.props.node.extras.eventTemp || ''}
          event={this.props.node.extras.event || ''}
          updateEventName={this.updateEventName}
          />
        )
    }
}

export default EventModal