// Dependencies
import React from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Select from "react-select";
import base64 from 'base-64'
import utf8 from 'utf8'
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Col,
  Row,
  Button,
  Container,
  Form,
  Card,
  CardBody,
  FormGroup,
  Input,
  Label,
  InputGroup,
  InputGroupAddon,
  UncontrolledTooltip,
  Table,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Alert
} from "reactstrap";
import classnames from "classnames";
import { SketchPicker } from "react-color";
import reactCSS from "reactcss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faSync, faPlus } from "@fortawesome/free-solid-svg-icons";
import { ToastsContainer, ToastsStore } from "react-toasts";

// Components
import ModalNuevoAvatar from "../../../components/modal-avatar";
import BotMenuOptions from "../../menus/bot-menu-options";
import ModalUpdateAvatar from "../../../components/modal-update-avatar";
import Admin from "../../admin/containers/admin";
import SliceMenuApp from "../../admin/components/slice-menu";
import ToolbarApp from "../../admin/components/toolbar";
import Emoji from "../../../components/emoji-picker-simple";
import TabChannelConfi from "../../../components/channels/tab_channel_confi";

import SchemesSelector from '../../../components/schemes';
import AllOperatorSelector from '../../../components/allOperator';

// Assets
import "./bot-configuration-layout.css";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { constructN } from "ramda";
import {
  getHandlerGET} from "../../../utils/handlersApi";

import {setChangeConfig,setChangeTimeoutConfi  } from '../../../redux/actions/botActions';

import i18next from "../../../../i18n";

class Setup extends React.Component {
  _data = {};
  _botversion = {};
  _url = "";
  _test_url = "";
  _inputDisable = "";
  _email = "";

  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "1",
      showIconTxtBtnSend: "",
      selectedOption: "",
      ButtonDropdown: false,
      wp_username: "",
      wp_phone: "",
      wp_token: "",
      tw_username: "",
      tw_token: "",
      gbm_username: "",
      gbm_password: "",
      selectedOptionInput: "",
      selectedOptionHistory:"",
      textSize: [
        { val: 12 },
        { val: 13 },
        { val: 14 },
        { val: 15 },
        { val: 16 },
      ],
      fileSize: [
        { val: 5242880, tag: "5 Mb" },
        { val: 10485760, tag: "10 Mb" },
        { val: 15728640, tag: "15 Mb" },
        { val: 20971520, tag: "20 Mb" },
      ],
      animation: [
        { val: "Ninguna", tag: "Ninguna" },
        { val: "zoom", tag: "Zoom" },
        { val: "shake", tag: "Shake" },
        { val: "blur", tag: "Blur" },
      ],
      optionApiWS: [
        { value: "SMOOCH", text: "Smooch" },
        { value: "MASIVIAN", text: "Masivian" },
        { value: "GUPSHUP", text: "Gupshup" },
        { value: "ALDEAMO", text: "Aldeamo" },
        { value: "WAVY", text: "Wavy" },
      ],
      optionApiTW: [{ value: "SMOOCH", text: "Smooch" }],
      optionApiGBM: [{ value: "ROUTEMOBILE", text: "Route Mobile" }],
      positionBot: [
        { val: 1, tag: i18next.t('corner_lower_left') },
        { val: 2, tag: i18next.t('border_lower_center') },
        { val: 3, tag: i18next.t('corner_lower_right' )},
        { val: 4, tag: i18next.t('corner_up_right') },
        { val: 5, tag: i18next.t('corner_up_left') },
      ],
      button_title: {
        title1: i18next.t('follow_asking'),
        title2: i18next.t('return'),
      },
      image_link_testing:'',
      word_to_return:'return',
      word_out_queue:'leave',
      animacion: "Ninguna",
      file_encripted:true
    };
    
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
    this.handleChangeSessionVar = this.handleChangeSessionVar.bind(this);
    this.changesaveUrlImage = this.changesaveUrlImage.bind(this);
    this.filepreview = '';
  }


   changesaveUrlImage = (e) => {
      console.log("IMG ",e.target.files[0]);
      this.filepreview = URL.createObjectURL(e.target.files[0]);
      console.log("this.state.filepreview ",this.filepreview)
      this.props.saveUrlImage(e);
    }
    
  handleChangeSessionVar = (event) => {
    this.props.handleChangeSessionVar(event);
  };

  

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.botInfo != this.props.botInfo) {
      this.setState({
        languages: this.props.botInfo.language_id,
        chat_textsize: this.props.botInfo.textsize,
        bot_position: this.props.botInfo.bot_position,
        show_window: this.props.botInfo.show_window,
        show_window_movil: this.props.botInfo.show_window_movil,
        animacion: this.props.botInfo.animacion || "Ninguna",
        contact_list: this.props.idcontactlist || "",
        wp_api: this.props.botInfo.wp_api,
        twitter_api: this.props.botInfo.twitter_api,
        telegram_api: this.props.botInfo.telegram_api,
        imessage_api: this.props.botInfo.imessage_api,
        wp_phone:this.props.botInfo.wp_phone,
        chat_filesize: this.props.botInfo.chat_filesize,
        active: this.props.botInfo.active,
        word_to_return:this.props.botInfo.word_to_return,
        word_out_queue:this.props.botInfo.word_out_queue,
        file_encripted:this.props.botInfo.file_encripted,
        button_title: {
          ...this.state.button_title,
          ...this.props.botInfo.button_title,
        },
        main_timeout:{
          ...this.props.botInfo.main_timeout,
        },
      });
    }
  }

  defaultColor() {
    const styles = reactCSS({
      default: {
        color1: {
          width: "100%",
          height: "32px",
          borderRadius: "2px",
          background: this.props.color1,
        },
        color2: {
          width: "100%",
          height: "32px",
          borderRadius: "2px",
          background: this.props.color2,
        },
        color3: {
          width: "100%",
          height: "32px",
          borderRadius: "2px",
          background: this.props.color3,
        },
        color4: {
          width: "100%",
          height: "32px",
          borderRadius: "2px",
          background: this.props.color4,
        },
        color5: {
          width: "100%",
          height: "32px",
          borderRadius: "2px",
          background: this.props.color5,
        },
        color6: {
          width: "100%",
          height: "32px",
          borderRadius: "2px",
          background: this.props.color6,
        },
        color7: {
          width: "100%",
          height: "32px",
          borderRadius: "2px",
          background: this.props.color7,
        },
        color8: {
          width: "100%",
          height: "32px",
          borderRadius: "2px",
          background: this.props.color8,
        },
        color9: {
          width: "100%",
          height: "32px",
          borderRadius: "2px",
          background: this.props.color9,
        },
        color10: {
          width: "100%",
          height: "32px",
          borderRadius: "2px",
          background: this.props.color10,
        },
        color11: {
          width: "100%",
          height: "32px",
          borderRadius: "2px",
          background: this.props.color11,
        },
        color12: {
          width: "100%",
          height: "32px",
          borderRadius: "2px",
          background: this.props.color12,
        },
        color13: {
          width: "100%",
          height: "32px",
          borderRadius: "2px",
          background: this.props.color13,
        },
        color14: {
          width: "100%",
          height: "32px",
          borderRadius: "2px",
          background: this.props.color14,
        },
        color15: {
          width: "100%",
          height: "32px",
          borderRadius: "2px",
          background: this.props.color15,
        },
        swatch: {
          background: "#fff",
          borderRadius: "1px",
          boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
          display: "inline-block",
          cursor: "pointer",
          width: "100%",
        },
        popover: {
          position: "absolute",
          zIndex: "2",
        },
        cover: {
          position: "fixed",
          top: "0px",
          right: "0px",
          bottom: "0px",
          left: "0px",
        },
      },
    });
    return styles;
  }

  itemElementContactList = () => {
    const { contact_lists = [] } = this.props;
    return contact_lists.map((el, index) => {
      return (
        <option
          key={el._id}
          value={el._id}
          selected={el._id == this.props.idcontactlist}
        >
          {el.titulo}
        </option>
      );
    });
  };
  defaultUrl() {
    if (this.props.botInfo) {
      this._data = this.props.botInfo;
      this._botversion = this._data.bot_version[0];
      this._url =
        `${HOST_API}/api/facebook/webhook/` +
        this.props.botId +
        "/" +
        this._botversion.botversion_id;
        if(this._data.image_link_testing){
          this._test_url =
            HOST_API +
            "/api/testing?i=" +
            this.props.botId +
            "&v=" +
            this._botversion.botversion_id+
            "&u="+
            base64.encode(utf8.encode(this._data.image_link_testing));
        }
        else
        {
          this._test_url =
            HOST_API +
            "/api/testing?i=" +
            this.props.botId +
            "&v=" +
            this._botversion.botversion_id+"&u="+
            base64.encode(utf8.encode('https://s3.amazonaws.com/Guarumo/landing-vertical.png'));
        }
      this._email = this._botversion.email_remitente || "";
    }
  }
  defaultThumbsImgModal() {
    const modal_image = this.props.modal_image;
    if (modal_image) {
      const thumbsImgModal = modal_image.map((file) => (
        <div key={"thumb-3"}>
          <div className="container-image">
            <img className="img-fluid" src={file.preview} />
          </div>
        </div>
      ));
      return thumbsImgModal;
    }
  }
  defaultThumbsImgModalUpdate() {
    const modal_image_update = this.props.modal_image_update;
    if (modal_image_update) {
      const thumbsImgModal = modal_image_update.map((file) => (
        <div key={"thumb-3"}>
          <div className="container-image">
            <img className="img-fluid" src={file.preview} />
          </div>
        </div>
      ));
      return thumbsImgModal;
    }
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }
  handleOptionChange = (e) =>
    this.setState({
      selectedOption: e.target.value,
      showIconTxtBtnSend: e.target.value,
    });
    handleOptionChangeHistory = (e) =>
    this.setState({ selectedOptionHistory: e.target.value === "true" });

    handleOptionChangeInput = (e) =>
    this.setState({ selectedOptionInput: e.target.value === "true" });
  
  defaultAvatarCompany() {
    //avatarList const avatarBots = this.props.avatarListBot;
    console.log("this.props avatarList ",this.props);
    const avatarBots = this.props.avatarList;
    console.log("avatarBots ",avatarBots);

    const dataTable = avatarBots.map((k, index) => {
      let item = k.id;
      return (
        <Col sm={6} md={4} lg={3} xl={3} key={item}>
          <Col md={12} className="card-avatar mb-4">
            <Row>
              <Col className="box-check-avatar">
                {item in avatarBots && (
                  <Col className="check-avatar">
                    <div className="checkmark" id={"tooltip" + item}>
                      <svg
                        version="1.1"
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        viewBox="0 0 161.2 161.2"
                        enableBackground="new 0 0 161.2 161.2"
                      >
                        <path
                          className="path"
                          fill="none"
                          stroke="#7DB0D5"
                          strokeMiterlimit="10"
                          d="M425.9,52.1L425.9,52.1c-2.2-2.6-6-2.6-8.3-0.1l-42.7,46.2l-14.3-16.4c0.3-0.3,0.5-0.6,0.7-0.9l46.3-50.1C427.7,57.5,427.7,54.2,425.9,52.1z"
                        />
                        <circle
                          className="path"
                          fill="none"
                          stroke="#7DB0D5"
                          strokeWidth="4"
                          strokeMiterlimit="10"
                          cx="80.6"
                          cy="80.6"
                          r="62.1"
                        />
                        <polyline
                          className="path"
                          fill="none"
                          stroke="#7DB0D5"
                          strokeWidth="6"
                          strokeLinecap="round"
                          strokeMiterlimit="10"
                          points="113,52.8 74.1,108.4 48.2,86.4 "
                        />
                        <circle
                          className="spin"
                          fill="none"
                          stroke="#7DB0D5"
                          strokeWidth="4"
                          strokeMiterlimit="10"
                          strokeDasharray="12.2175,12.2175"
                          cx="80.6"
                          cy="80.6"
                          r="73.9"
                        />
                      </svg>
                    </div>
                    <UncontrolledTooltip
                      placement="right"
                      target={"tooltip" + item}
                    >
                      {avatarBots[item]}
                    </UncontrolledTooltip>
                  </Col>
                )}
              </Col>
              <Col md={12} className="preview-avatar-img">
                <img src={k.url} className="img-fluid"></img>
              </Col>
              <Col md={12}>
                <Row>
                  <Col md={12} className="title-avatar">
                    <p>{k.titulo}</p>
                  </Col>
                  <Col
                    md={12}
                    className="card-option-avatar d-flex justify-content-center"
                  >
                    <Dropdown
                      isOpen={this.state[`button${item}`]}
                      toggle={() => {
                        this.setState({
                          [`button${item}`]: !this.state[`button${item}`],
                        });
                      }}
                    >
                      <DropdownToggle caret>{i18next.t('use_avatar')}</DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={(e) => {
                            this.props.saveState(e, item, "icon");
                          }}
                        >
                          {i18next.t('default')}
                        </DropdownItem>
                        <DropdownItem
                          onClick={(e) => {
                            this.props.saveState(e, item, "closed");
                          }}
                        >
                          {i18next.t('close_windows')}
                        </DropdownItem>
                        <DropdownItem
                          onClick={(e) => {
                            this.props.saveState(e, item, "ununderstand");
                          }}
                        >
                          {i18next.t('bot_not_understand')}
                        </DropdownItem>
                        <DropdownItem
                          onClick={(e) => {
                            this.props.saveState(e, item, "bot_typing");
                          }}
                        >
                          {i18next.t('bot_writing')}
                        </DropdownItem>
                        <DropdownItem
                          onClick={(e) => {
                            this.props.saveState(e, item, "user_typing");
                          }}
                        >
                          {i18next.t('user_writing')}
                        </DropdownItem>
                        <DropdownItem
                          onClick={(e) => {
                            this.props.saveState(e, item, "start_chat");
                          }}
                        >
                          {i18next.t('start_chat')}
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                    <Button
                      type="button"
                      outline
                      color="danger"
                      className="btn-square mr-2"
                      onClick={(e) => this.props.deleteAvatar(item)}
                    >
                      <i className="fa fa-trash"></i>
                    </Button>
                    <Button
                      type="button"
                      outline
                      color="primary"
                      className="btn-square mr-2"
                      onClick={(e) =>
                        this.props.toggleModalUpdateAvatar(e, item, k.titulo)
                      }
                    >
                      <FontAwesomeIcon icon={faPen} />
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Col>
      );
    });
    return dataTable;
  }

  handleChangeSelect = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  optionsSenderVoice = () => {
    const options = [{ label: "POR DEFECTO", value: 0 }];
    const { listAllSender = [] } = this.props;
    listAllSender.map((item) => {
      if (item.channel_type == "PH") {
        options.push({ label: `${item.name}: ${item.value}`, value: item.id });
      }
    });
    return options;
  };

  optionsSenderEmail = () => {
    const options = [{ label: "POR DEFECTO", value: 0 }];
    const { listAllSender = [] } = this.props;
    listAllSender.map((item) => {
      if (item.channel_type == "EM") {
        options.push({ label: `${item.name}: ${item.value}`, value: item.id });
      }
    });
    return options;
  };

  optionsTemplateEmail = () => {
    return this.props.listTemplates.map((item) => ({
      label: item.template_name,
      value: item.id,
    }));
  };

  onChangeTimeout=(type, e) => {
    const {bot} = this.props.botReducer;
    let new_bot = bot;
    let main_timeout = new_bot['main_timeout']
    main_timeout[type] = e.target.value;
    this.props.setChangeConfig("main_timeout",main_timeout);
  }

  render() {
    const styles = this.defaultColor();
    const data = this._data;
    const botversion = this._botversion;
    const test_url = this._test_url;
    const thumbsImgModal = this.defaultThumbsImgModal();

    this.defaultUrl();
    //getAvatarsByCompany
    const avatarCompany = this.defaultAvatarCompany();
    
    const thumbsImgModalUpdate = this.defaultThumbsImgModalUpdate();

    const customStyles = {
      ///.....
      menuPortal: provided => ({ ...provided, zIndex: 9999 }),
      menu: provided => ({ ...provided, zIndex: 9999 })
      ///.....
    }

    const {bot,timeout} = this.props.botReducer;
    
    if (data && bot) {
      return (
        <div className="BotLayout">
          <Admin>
            <SliceMenuApp>
              <BotMenuOptions
                botId={this.props.botId}
                ia_activated={this.props.ia_activated}
              />
            </SliceMenuApp>
            <Form id="frm_bot_setup" onSubmit={this.props.saveConfigHandler}>
              <Container fluid>
                <ToolbarApp>
                  <Row>
                    <Col sm="5" md="5" lg="4" className="text pdtoolbar">
                      <h2 className="text-left ml-4 font-weight-bold">
                        {i18next.t('configure_bot')}<span className="btn-help"></span>
                      </h2>
                    </Col>
                    <Col sm="7" md="7" className="filter-stuff">
                      <Button className="btn btn-guarumo-md" type="submit">
                        {i18next.t('save_changes')}
                      </Button>
                    </Col>
                  </Row>
                </ToolbarApp>
              </Container>
              <Container fluid className="box-fluid">
                <Row>
                  <Col md="12">
                    <Row>
                      <Col md={{ size: "10", offset: 1 }}>
                        <Card className="card-configuration">
                          <CardBody>
                            <Nav tabs>
                              <NavItem>
                                <NavLink
                                  className={classnames({
                                    active: this.state.activeTab === "1",
                                  })}
                                  onClick={() => {
                                    this.toggle("1");
                                  }}
                                >
                                  {i18next.t('basic_data')}
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  className={classnames({
                                    active: this.state.activeTab === "2",
                                  })}
                                  onClick={() => {
                                    this.toggle("2");
                                  }}
                                >
                                  {i18next.t('visual_appearance')}
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  className={classnames({
                                    active: this.state.activeTab === "3",
                                  })}
                                  onClick={() => {
                                    this.toggle("3");
                                  }}
                                >
                                  {i18next.t('advanceds')}
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  className={classnames({
                                    active: this.state.activeTab === "5",
                                  })}
                                  onClick={() => {
                                    this.toggle("5");
                                  }}
                                >
                                  {i18next.t('integrations')}
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  className={classnames({
                                    active: this.state.activeTab === "4",
                                  })}
                                  onClick={() => {
                                    this.toggle("4");
                                  }}
                                >
                                  {i18next.t('avatars')}
                                </NavLink>
                              </NavItem>

                              <NavItem>
                                <NavLink
                                  className={classnames({
                                    active: this.state.activeTab === "6",
                                  })}
                                  onClick={() => {
                                    this.toggle("6");
                                  }}
                                >
                                  {i18next.t('timeout')}
                                </NavLink>
                              </NavItem>
                              
                            </Nav>
                            <TabContent activeTab={this.state.activeTab}>
                              <TabPane tabId="1">
                                <Row>
                                  <Col sm="12">
                                    <Input
                                      type="hidden"
                                      name="botID"
                                      defaultValue={data.id || ""}
                                    />
                                    <Input
                                      type="hidden"
                                      name="botversion_id"
                                      defaultValue={
                                        botversion.botversion_id || ""
                                      }
                                    />
                                    <Row form>
                                      <Col md={4}>
                                        <FormGroup>
                                          <Label
                                            for="botName"
                                            className="required-field"
                                          >
                                            {i18next.t('project_name')}
                                          </Label>
                                          <Input
                                            className="config-input"
                                            type="text"
                                            id="botName"
                                            name="botName"
                                            maxLength="30"
                                            placeholder="Nombre del Bot"
                                            required
                                            defaultValue={data.name}
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col md={4}>
                                        <FormGroup>
                                          <Label
                                            for="nickname"
                                            className="required-field"
                                          >
                                            {i18next.t('bot_name')}
                                          </Label>
                                          <Input
                                            className="config-input"
                                            type="text"
                                            id="nickname"
                                            name="nickname"
                                            defaultValue={data.nickname || ""}
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col md={2}>
                                        <FormGroup>
                                          <Label
                                            for="botid"
                                            className="required-field"
                                          >
                                            {i18next.t('bot_id')}
                                          </Label>
                                          <Input
                                            className="config-input"
                                            type="text"
                                            id="botid"
                                            name="botid"
                                            defaultValue={botversion.bot_id || ""}
                                            disabled={true}
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col md={2}>
                                        <FormGroup>
                                          <Label
                                            for="botversion"
                                            className="required-field"
                                          >
                                            {i18next.t('bot_version')}
                                          </Label>
                                          <Input
                                            className="config-input"
                                            type="text"
                                            id="botversion"
                                            name="botversion"
                                            defaultValue={botversion.botversion_id || ""}
                                            disabled={true}
                                          />
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                    <Row form>
                                      <Col md={6}>
                                        <FormGroup>
                                          <Label for="botDescription">
                                            {i18next.t('description')}{" "}
                                            <i
                                              className="fa fa-info-circle"
                                              id="tooltipdescrip"
                                            ></i>
                                          </Label>
                                          <UncontrolledTooltip
                                            placement="right"
                                            target="tooltipdescrip"
                                          >
                                            {" "}
                                            {i18next.t('description')} {i18next.t('bot_to_create')}
                                          </UncontrolledTooltip>
                                          <Input
                                            className="config-input"
                                            type="text"
                                            id="botDescription2"
                                            name="botDescription"
                                            placeholder={i18next.t('description') + " del Bot"}
                                            maxLength="100"
                                            defaultValue={
                                              botversion.description
                                            }
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col md={3}>
                                        <FormGroup>
                                          ·
                                          <Label for="languages">
                                          {i18next.t('language')}{" "}
                                            <i
                                              className="fa fa-info-circle"
                                              id="tooltipidioma"
                                            ></i>
                                          </Label>
                                          ·
                                          <UncontrolledTooltip
                                            placement="right"
                                            target="tooltipidioma"
                                          >
                                            {i18next.t('languages_msg')}
                                          </UncontrolledTooltip>
                                          <select
                                            value={this.state.languages}
                                            onChange={this.handleChangeSelect}
                                            className="form-control languages-container config-input"
                                            name="languages"
                                            required
                                            menuPortalTarget={document.body}
                                            menuPosition={'fixed'} 
                                            styles={customStyles}
                                          >
                                            <option key={"list-0"} value="">
                                              - {i18next.t('select_one')} -
                                            </option>
                                            {this.props.languages.map((el) => (
                                              <option
                                                key={JSON.parse(el).id}
                                                value={JSON.parse(el).id}
                                              >
                                                {JSON.parse(el).language}
                                              </option>
                                            ))}
                                          </select>
                                          ·
                                        </FormGroup>
                                      </Col>
                                      <Col md={3}>
                                        <Label>Id {i18next.t('company')+': '} </Label>
                                        <Input
                                            className="config-input"
                                            type="text"
                                            id="nickname"
                                            name="nickname"
                                            defaultValue={this.props.botReducer.company_id || ""}
                                            disabled={true}
                                          />
                                      </Col>
                                    </Row>
                                    <Row form>
                                      <Col md={12}>
                                        <Label>{i18next.t('platforms')}</Label>
                                      </Col>
                                      <Col md={6}>
                                        <FormGroup>
                                          <Label for="botDescription">
                                            {" "}
                                            {i18next.t('primary_platform')}
                                          </Label>
                                          <Select
                                            value={this.props.selectedOption}
                                            onChange={
                                              this.props
                                                .handleChangePlatformsMain
                                            }
                                            options={this.props.platforms}
                                            isMulti={false}
                                            name="platformMain"
                                            menuPortalTarget={document.body}
                                            menuPosition={'fixed'} 
                                            styles={customStyles}
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col md={6}>
                                        <FormGroup>
                                          ·
                                          <Label for="languages">
                                            {i18next.t('add_platforms')}
                                            <i
                                              className="fa fa-info-circle"
                                              id="tooltipplatformadicional"
                                            ></i>
                                          </Label>
                                          ·
                                          <UncontrolledTooltip
                                            placement="right"
                                            target="tooltipplatformadicional"
                                          >
                                            {i18next.t('add_platforms')} bot
                                          </UncontrolledTooltip>
                                          ·
                                          <Select
                                            value={this.props.selectedOption1}
                                            onChange={
                                              this.props.handleChangePlatforms
                                            }
                                            options={[
                                              {
                                                label: i18next.t('select_one'), // Texto descriptivo, no se seleccionará
                                                options: this.props.platforms
                                              }
                                            ]}
                                            isMulti={true}
                                            name="platform"
                                            menuPortalTarget={document.body}
                                            menuPosition={'fixed'} 
                                            styles={customStyles}
                                          />
                                          ·
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md={6}>
                                        <FormGroup>
                                          <Label for="webhook_facebook">
                                          {i18next.t('testing_bot')}
                                          </Label>
                                          <p>
                                            <a
                                              href={test_url}
                                              className="config-link"
                                              target="_blank"
                                            >
                                              {i18next.t('click_here')}
                                            </a>
                                          </p>
                                        </FormGroup>
                                      </Col>
                                      <Col md={4}>
                                        <Label>{i18next.t('activate_bot')}</Label>
                                        <Switch
                                          style={{
                                            color: this.state.active
                                              ? "green"
                                              : "gray",
                                          }}
                                          name="activeBot"
                                          checked={
                                            this.state.active
                                              ? this.state.active
                                              : false
                                          }
                                          onClick={() => {
                                            this.setState({
                                              active: !this.state.active,
                                            });
                                          }}
                                          value={true}
                                        />
                                      </Col>
                                    </Row>
                                    
                                    <FormGroup className="d-block d-md-none mt-4 d-flex justify-content-center">
                                      <Button
                                        className="btn btn-guarumo-md"
                                        form="frm_bot_setup"
                                        type="submit"
                                      >
                                        {i18next.t('save_changes')}
                                      </Button>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </TabPane>
                              <TabPane tabId="2">
                                <Row>
                                  <Col md={12}>
                                    <FormGroup>
                                      <Label for="chat_textsize">
                                        {i18next.t('font_size')}
                                      </Label>
                                      <select
                                        value={this.state.chat_textsize}
                                        className="form-control config-input"
                                        name="chat_textsize"
                                        onChange={this.handleChangeSelect}
                                        menuPortalTarget={document.body}
                                        menuPosition={'fixed'} 
                                        styles={customStyles}
                                      >
                                        <option value="">
                                          - {i18next.t('select_one')} -
                                        </option>
                                        {this.state.textSize.map((el, i) => (
                                          <option
                                            key={"text_id" + i}
                                            value={el.val}
                                          >
                                            {el.val}
                                          </option>
                                        ))}
                                      </select>
                                    </FormGroup>
                                  </Col>

                                  <Col md={3}>
                                    <FormGroup>
                                      <Label for="color-window">
                                        {i18next.t('background_header')}
                                      </Label>
                                      <div>
                                        <div
                                          style={styles.swatch}
                                          onClick={(e) => {
                                            this.props.handleClick(
                                              "displayColorPicker1"
                                            );
                                          }}
                                        >
                                          <div style={styles.color1} />
                                        </div>
                                        {this.props.displayColorPicker1 ? (
                                          <div style={styles.popover}>
                                            <div
                                              style={styles.cover}
                                              onClick={(e) => {
                                                this.props.handleClose(
                                                  "displayColorPicker1"
                                                );
                                              }}
                                            />
                                            <SketchPicker
                                              color={this.props.color1}
                                              onChange={(e) => {
                                                this.props.handleChange(
                                                  e,
                                                  "color1"
                                                );
                                              }}
                                            />
                                          </div>
                                        ) : null}
                                      </div>
                                    </FormGroup>
                                  </Col>
                                  <Col md={3}>
                                    <FormGroup>
                                      <Label for="objects_colors">
                                        {i18next.t('user_balloon_background')}
                                      </Label>
                                      <div>
                                        <div
                                          style={styles.swatch}
                                          onClick={(e) => {
                                            this.props.handleClick(
                                              "displayColorPicker2"
                                            );
                                          }}
                                        >
                                          <div style={styles.color2} />
                                        </div>
                                        {this.props.displayColorPicker2 ? (
                                          <div style={styles.popover}>
                                            <div
                                              style={styles.cover}
                                              onClick={(e) => {
                                                this.props.handleClose(
                                                  "displayColorPicker2"
                                                );
                                              }}
                                            />
                                            <SketchPicker
                                              color={this.props.color2}
                                              onChange={(e) => {
                                                this.props.handleChange(
                                                  e,
                                                  "color2"
                                                );
                                              }}
                                            />
                                          </div>
                                        ) : null}
                                      </div>
                                    </FormGroup>
                                    ·
                                  </Col>
                                  <Col md={3}>
                                    <FormGroup>
                                      <Label for="objects_colors">
                                        {i18next.t('bot_balloon_background')}
                                      </Label>
                                      <div>
                                        <div
                                          style={styles.swatch}
                                          onClick={(e) => {
                                            this.props.handleClick(
                                              "displayColorPicker3"
                                            );
                                          }}
                                        >
                                          <div style={styles.color3} />
                                        </div>
                                        {this.props.displayColorPicker3 ? (
                                          <div style={styles.popover}>
                                            <div
                                              style={styles.cover}
                                              onClick={(e) => {
                                                this.props.handleClose(
                                                  "displayColorPicker3"
                                                );
                                              }}
                                            />
                                            <SketchPicker
                                              color={this.props.color3}
                                              onChange={(e) => {
                                                this.props.handleChange(
                                                  e,
                                                  "color3"
                                                );
                                              }}
                                            />
                                          </div>
                                        ) : null}
                                      </div>
                                    </FormGroup>
                                    ·
                                  </Col>
                                  <Col md={3}>
                                    <FormGroup>
                                      <Label for="objects_colors">
                                        {i18next.t('background_chat')}
                                      </Label>
                                      <div>
                                        <div
                                          style={styles.swatch}
                                          onClick={(e) => {
                                            this.props.handleClick(
                                              "displayColorPicker4"
                                            );
                                          }}
                                        >
                                          <div style={styles.color4} />
                                        </div>
                                        {this.props.displayColorPicker4 ? (
                                          <div style={styles.popover}>
                                            <div
                                              style={styles.cover}
                                              onClick={(e) => {
                                                this.props.handleClose(
                                                  "displayColorPicker4"
                                                );
                                              }}
                                            />
                                            <SketchPicker
                                              color={this.props.color4}
                                              onChange={(e) => {
                                                this.props.handleChange(
                                                  e,
                                                  "color4"
                                                );
                                              }}
                                            />
                                          </div>
                                        ) : null}
                                      </div>
                                    </FormGroup>{" "}
                                    ·
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md={3}>
                                    <FormGroup>
                                      <Label for="objects_colors">
                                        {i18next.t('background_buttons')}
                                      </Label>
                                      <div>
                                        <div
                                          style={styles.swatch}
                                          onClick={(e) => {
                                            this.props.handleClick(
                                              "displayColorPicker12"
                                            );
                                          }}
                                        >
                                          <div style={styles.color12} />
                                        </div>
                                        {this.props.displayColorPicker12 ? (
                                          <div style={styles.popover}>
                                            <div
                                              style={styles.cover}
                                              onClick={(e) => {
                                                this.props.handleClose(
                                                  "displayColorPicker12"
                                                );
                                              }}
                                            />
                                            <SketchPicker
                                              color={this.props.color12}
                                              onChange={(e) => {
                                                this.props.handleChange(
                                                  e,
                                                  "color12"
                                                );
                                              }}
                                            />
                                          </div>
                                        ) : null}
                                      </div>
                                    </FormGroup>
                                  </Col>
                                  <Col md={3}>
                                    <FormGroup>
                                      <Label for="objects_colors">
                                        {i18next.t('border_color_buttons')}
                                      </Label>
                                      <div>
                                        <div
                                          style={styles.swatch}
                                          onClick={(e) => {
                                            this.props.handleClick(
                                              "displayColorPicker11"
                                            );
                                          }}
                                        >
                                          <div style={styles.color11} />
                                        </div>
                                        {this.props.displayColorPicker11 ? (
                                          <div style={styles.popover}>
                                            <div
                                              style={styles.cover}
                                              onClick={(e) => {
                                                this.props.handleClose(
                                                  "displayColorPicker11"
                                                );
                                              }}
                                            />
                                            <SketchPicker
                                              color={this.props.color11}
                                              onChange={(e) => {
                                                this.props.handleChange(
                                                  e,
                                                  "color11"
                                                );
                                              }}
                                            />
                                          </div>
                                        ) : null}
                                      </div>
                                    </FormGroup>
                                  </Col>
                                  <Col md={3}>
                                    <FormGroup>
                                      <Label for="objects_colors">
                                        {i18next.t('color_text_balloon_user')}
                                      </Label>
                                      <div>
                                        <div
                                          style={styles.swatch}
                                          onClick={(e) => {
                                            this.props.handleClick(
                                              "displayColorPicker5"
                                            );
                                          }}
                                        >
                                          <div style={styles.color5} />
                                        </div>
                                        {this.props.displayColorPicker5 ? (
                                          <div style={styles.popover}>
                                            <div
                                              style={styles.cover}
                                              onClick={(e) => {
                                                this.props.handleClose(
                                                  "displayColorPicker5"
                                                );
                                              }}
                                            />
                                            <SketchPicker
                                              color={this.props.color5}
                                              onChange={(e) => {
                                                this.props.handleChange(
                                                  e,
                                                  "color5"
                                                );
                                              }}
                                            />
                                          </div>
                                        ) : null}
                                      </div>
                                    </FormGroup>
                                  </Col>
                                  <Col md={3}>
                                    <FormGroup>
                                      <Label for="objects_colors">
                                        {i18next.t('color_text_balloon_bot')}
                                      </Label>
                                      <div>
                                        <div
                                          style={styles.swatch}
                                          onClick={(e) => {
                                            this.props.handleClick(
                                              "displayColorPicker7"
                                            );
                                          }}
                                        >
                                          <div style={styles.color7} />
                                        </div>
                                        {this.props.displayColorPicker7 ? (
                                          <div style={styles.popover}>
                                            <div
                                              style={styles.cover}
                                              onClick={(e) => {
                                                this.props.handleClose(
                                                  "displayColorPicker7"
                                                );
                                              }}
                                            />
                                            <SketchPicker
                                              color={this.props.color7}
                                              onChange={(e) => {
                                                this.props.handleChange(
                                                  e,
                                                  "color7"
                                                );
                                              }}
                                            />
                                          </div>
                                        ) : null}
                                      </div>
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md={3}>
                                    <FormGroup>
                                      <Label for="objects_colors">
                                        {i18next.t('color_text_buttons')}
                                      </Label>
                                      <div>
                                        <div
                                          style={styles.swatch}
                                          onClick={(e) => {
                                            this.props.handleClick(
                                              "displayColorPicker13"
                                            );
                                          }}
                                        >
                                          <div style={styles.color13} />
                                        </div>
                                        {this.props.displayColorPicker13 ? (
                                          <div style={styles.popover}>
                                            <div
                                              style={styles.cover}
                                              onClick={(e) => {
                                                this.props.handleClose(
                                                  "displayColorPicker13"
                                                );
                                              }}
                                            />
                                            <SketchPicker
                                              color={this.props.color13}
                                              onChange={(e) => {
                                                this.props.handleChange(
                                                  e,
                                                  "color13"
                                                );
                                              }}
                                            />
                                          </div>
                                        ) : null}
                                      </div>
                                    </FormGroup>
                                  </Col>
                                  <Col md={3}>
                                    <FormGroup>
                                      <Label for="objects_colors">
                                        {i18next.t('color_text_send_button')}
                                      </Label>
                                      <div>
                                        <div
                                          style={styles.swatch}
                                          onClick={(e) => {
                                            this.props.handleClick(
                                              "displayColorPicker10"
                                            );
                                          }}
                                        >
                                          <div style={styles.color10} />
                                        </div>
                                        {this.props.displayColorPicker10 ? (
                                          <div style={styles.popover}>
                                            <div
                                              style={styles.cover}
                                              onClick={(e) => {
                                                this.props.handleClose(
                                                  "displayColorPicker10"
                                                );
                                              }}
                                            />
                                            <SketchPicker
                                              color={this.props.color10}
                                              onChange={(e) => {
                                                this.props.handleChange(
                                                  e,
                                                  "color10"
                                                );
                                              }}
                                            />
                                          </div>
                                        ) : null}
                                      </div>
                                    </FormGroup>
                                  </Col>
                                  <Col md={3}>
                                    <FormGroup>
                                      <Label for="objects_colors">
                                        {i18next.t('color_link_balloon_bot')}
                                      </Label>
                                      <div>
                                        <div
                                          style={styles.swatch}
                                          onClick={(e) => {
                                            this.props.handleClick(
                                              "displayColorPicker8"
                                            );
                                          }}
                                        >
                                          <div style={styles.color8} />
                                        </div>
                                        {this.props.displayColorPicker8 ? (
                                          <div style={styles.popover}>
                                            <div
                                              style={styles.cover}
                                              onClick={(e) => {
                                                this.props.handleClose(
                                                  "displayColorPicker8"
                                                );
                                              }}
                                            />
                                            <SketchPicker
                                              color={this.props.color8}
                                              onChange={(e) => {
                                                this.props.handleChange(
                                                  e,
                                                  "color8"
                                                );
                                              }}
                                            />
                                          </div>
                                        ) : null}
                                      </div>
                                    </FormGroup>
                                  </Col>
                                  <Col md={3}>
                                    <FormGroup>
                                      <Label for="objects_colors">
                                        {i18next.t('color_link_balloon_user')}
                                      </Label>
                                      <div>
                                        <div
                                          style={styles.swatch}
                                          onClick={(e) => {
                                            this.props.handleClick(
                                              "displayColorPicker6"
                                            );
                                          }}
                                        >
                                          <div style={styles.color6} />
                                        </div>
                                        {this.props.displayColorPicker6 ? (
                                          <div style={styles.popover}>
                                            <div
                                              style={styles.cover}
                                              onClick={(e) => {
                                                this.props.handleClose(
                                                  "displayColorPicker6"
                                                );
                                              }}
                                            />
                                            <SketchPicker
                                              color={this.props.color6}
                                              onChange={(e) => {
                                                this.props.handleChange(
                                                  e,
                                                  "color6"
                                                );
                                              }}
                                            />
                                          </div>
                                        ) : null}
                                      </div>
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md={3}>
                                    <FormGroup>
                                      <Label for="objects_colors">
                                        {i18next.t('background_button_send')}
                                      </Label>
                                      <div>
                                        <div
                                          style={styles.swatch}
                                          onClick={(e) => {
                                            this.props.handleClick(
                                              "displayColorPicker9"
                                            );
                                          }}
                                        >
                                          <div style={styles.color9} />
                                        </div>
                                        {this.props.displayColorPicker9 ? (
                                          <div style={styles.popover}>
                                            <div
                                              style={styles.cover}
                                              onClick={(e) => {
                                                this.props.handleClose(
                                                  "displayColorPicker9"
                                                );
                                              }}
                                            />
                                            <SketchPicker
                                              color={this.props.color9}
                                              onChange={(e) => {
                                                this.props.handleChange(
                                                  e,
                                                  "color9"
                                                );
                                              }}
                                            />
                                          </div>
                                        ) : null}
                                      </div>
                                    </FormGroup>
                                  </Col>
                                  <Col md={3}>
                                    <FormGroup>
                                      <Label for="objects_colors">
                                        {i18next.t('background_chat_close')}
                                      </Label>
                                      <div>
                                        <div
                                          style={styles.swatch}
                                          onClick={(e) => {
                                            this.props.handleClick(
                                              "displayColorPicker14"
                                            );
                                          }}
                                        >
                                          <div style={styles.color14} />
                                        </div>
                                        {this.props.displayColorPicker14 ? (
                                          <div style={styles.popover}>
                                            <div
                                              style={styles.cover}
                                              onClick={(e) => {
                                                this.props.handleClose(
                                                  "displayColorPicker14"
                                                );
                                              }}
                                            />
                                            <SketchPicker
                                              color={this.props.color14}
                                              onChange={(e) => {
                                                this.props.handleChange(
                                                  e,
                                                  "color14"
                                                );
                                              }}
                                            />
                                          </div>
                                        ) : null}
                                      </div>
                                    </FormGroup>
                                  </Col>
                                  <Col md={3}>
                                    <FormGroup>
                                      <Label for="objects_colors">
                                        {i18next.t('color_chat_close')}
                                      </Label>
                                      <div>
                                        <div
                                          style={styles.swatch}
                                          onClick={(e) => {
                                            this.props.handleClick(
                                              "displayColorPicker15"
                                            );
                                          }}
                                        >
                                          <div style={styles.color15} />
                                        </div>
                                        {this.props.displayColorPicker15 ? (
                                          <div style={styles.popover}>
                                            <div
                                              style={styles.cover}
                                              onClick={(e) => {
                                                this.props.handleClose(
                                                  "displayColorPicker15"
                                                );
                                              }}
                                            />
                                            <SketchPicker
                                              color={this.props.color15}
                                              onChange={(e) => {
                                                this.props.handleChange(
                                                  e,
                                                  "color15"
                                                );
                                              }}
                                            />
                                          </div>
                                        ) : null}
                                      </div>
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md={4}>
                                    <Label for="msg_no_agente">
                                      {i18next.t('text_button_send')}{" "}
                                      <i
                                        className="fa fa-info-circle"
                                        id="tooltipBotonEnviar"
                                      ></i>
                                    </Label>
                                    <br></br>
                                    <FormGroup check>
                                      <UncontrolledTooltip
                                        placement="right"
                                        target="tooltipBotonEnviar"
                                      >
                                        {i18next.t('warning5')}
                                      </UncontrolledTooltip>
                                      <Label check>
                                        <Input
                                          type="radio"
                                          name="TxtBtnSend"
                                          value="on"
                                          checked={
                                            (data.iconTxtBtnSend == "on" &&
                                              this.state.selectedOption ==
                                                "") ||
                                            this.state.selectedOption == "on"
                                          }
                                          onChange={(e) => {
                                            this.handleOptionChange(e);
                                          }}
                                        />
                                        &nbsp;{i18next.t('use_icon')}
                                      </Label>
                                    </FormGroup>
                                    <FormGroup check>
                                      <Label check>
                                        <Input
                                          type="radio"
                                          name="TxtBtnSend"
                                          value="off"
                                          checked={
                                            (data.iconTxtBtnSend == "off" &&
                                              this.state.selectedOption ==
                                                "") ||
                                            this.state.selectedOption == "off"
                                          }
                                          onChange={(e) => {
                                            this.handleOptionChange(e);
                                          }}
                                        />
                                        &nbsp;{i18next.t('use_text')}
                                      </Label>
                                    </FormGroup>

                                    <FormGroup>
                                      <Input
                                        hidden={
                                          (this.state.showIconTxtBtnSend ==
                                            "on" &&
                                            data.iconTxtBtnSend == "off") ||
                                          (data.iconTxtBtnSend == "on" &&
                                            this.state.showIconTxtBtnSend !=
                                              "off")
                                        }
                                        className="config-input"
                                        type="text"
                                        id="text_btn_send"
                                        name="text_btn_send"
                                        maxLength="30"
                                        placeholder=""
                                        defaultValue={data.text_btn_send || ""}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={4}>
                                        <Label>
                                          {i18next.t('in_text_chat')}{" "}
                                          <i
                                            className="fa fa-info-circle"
                                            id="tooltipInputChat"
                                          ></i>
                                        </Label>
                                        <br></br>
                                        <UncontrolledTooltip
                                          placement="right"
                                          target="tooltipInputChat"
                                        >
                                          {i18next.t('warning6')}
                                          {console.log("botversion.disable_text_input ",botversion)}
                                        </UncontrolledTooltip>
                                        <FormGroup check>
                                          <Label check>
                                            <Input
                                              type="radio"
                                              name="disable_text_input"
                                              value="true"
                                              checked={
                                                (botversion.disable_text_input &&
                                                  this.state
                                                    .selectedOptionInput ==
                                                    "") ||
                                                this.state
                                                  .selectedOptionInput === true
                                              }
                                              onChange={(e) => {
                                                this.handleOptionChangeInput(e);
                                              }}
                                            />
                                            &nbsp;{i18next.t('enable')}
                                          </Label>
                                        </FormGroup>
                                        <FormGroup check>
                                          <Label check>
                                            <Input
                                              type="radio"
                                              name="disable_text_input"
                                              value="false"
                                              checked={
                                                (botversion.disable_text_input ===
                                                  false &&
                                                  this.state
                                                    .selectedOptionInput ==
                                                    "") ||
                                                this.state
                                                  .selectedOptionInput === false
                                              }
                                              onChange={(e) => {
                                                this.handleOptionChangeInput(e);
                                              }}
                                            />
                                            &nbsp;{i18next.t('disable')}
                                          </Label>
                                        </FormGroup>
                                  </Col>
                                  <Col md={4}>
                                        <Label>
                                        {i18next.t('keep_web_history')}{" "}
                                          <i
                                            className="fa fa-info-circle"
                                            id="tooltipInputChatHistory"
                                          ></i>
                                        </Label>
                                        <br></br>
                                        <UncontrolledTooltip
                                          placement="right"
                                          target="tooltipInputChatHistory"
                                        >
                                          {i18next.t('keep_web_history_info')}
                                          {console.log("data.active_history_web ",data)}
                                        </UncontrolledTooltip>
                                        <FormGroup check>
                                          <Label check>
                                            <Input
                                              type="radio"
                                              name="active_history_web"
                                              value="true"
                                              checked={
                                    
                                                (data.active_history_web &&
                                                  this.state
                                                    .selectedOptionHistory ==
                                                    "") ||
                                                this.state
                                                  .selectedOptionHistory === true
                                              }
                                              onChange={(e) => {
                                                this.handleOptionChangeHistory(e);
                                              }}
                                            />
                                            &nbsp;{i18next.t('enable')}
                                          </Label>
                                        </FormGroup>
                                        <FormGroup check>
                                          <Label check>
                                            <Input
                                              type="radio"
                                              name="active_history_web"
                                              value="false"
                                              checked={
                                                (data.active_history_web ===
                                                  false &&
                                                  this.state
                                                    .selectedOptionHistory ==
                                                    "") ||
                                                this.state
                                                  .selectedOptionHistory === false
                                              }
                                              onChange={(e) => {
                                                this.handleOptionChangeHistory(e);
                                              }}
                                            />
                                            &nbsp;{i18next.t('disable')}
                                          </Label>
                                        </FormGroup>
                                  </Col>
                                  
                                </Row>
                                <Row>
                                  <Col md={6}>
                                    <FormGroup>
                                      <Label for="bot_position">
                                        {i18next.t('locate_bot')}{" "}
                                        <i
                                          className="fa fa-info-circle"
                                          id="bot_position"
                                        ></i>
                                      </Label>
                                      <UncontrolledTooltip
                                        placement="right"
                                        target="bot_position"
                                      >
                                        {i18next.t('warning7')}
                                      </UncontrolledTooltip>
                                      <select
                                        value={this.state.bot_position}
                                        className="form-control config-input"
                                        name="bot_position"
                                        onChange={this.handleChangeSelect}
                                        required
                                        menuPortalTarget={document.body}
                                        menuPosition={'fixed'} 
                                        styles={customStyles}
                                      >
                                        <option key={"list-0"} value="">
                                          - {i18next.t('select_one')} -
                                        </option>
                                        {this.state.positionBot.map((el, i) => (
                                          <option
                                            key={"pos_id-" + i}
                                            value={el.val}
                                          >
                                            {el.tag}
                                          </option>
                                        ))}
                                      </select>
                                    </FormGroup>
                                  </Col>

                                  <Col md={3}>
                                    <FormGroup>
                                      <Label for="show_window">
                                        {i18next.t('up_bot_start')}
                                        <i
                                          className="fa fa-info-circle"
                                          id="tooltipDespliegue"
                                        ></i>
                                      </Label>
                                      <UncontrolledTooltip
                                        placement="right"
                                        target="tooltipDespliegue"
                                      >
                                        {i18next.t('enable_up_window_bot')}
                                      </UncontrolledTooltip>
                                      <select
                                        value={
                                          this.state.show_window == false ||
                                          this.state.show_window == 0
                                            ? 0
                                            : 1
                                        }
                                        className="form-control config-input"
                                        name="show_window"
                                        onChange={this.handleChangeSelect}
                                        required
                                        menuPortalTarget={document.body}
                                        menuPosition={'fixed'} 
                                        styles={customStyles}
                                      >
                                        <option value="1">{i18next.t('yes')}</option>
                                        <option value="0">{i18next.t('no')}</option>
                                      </select>
                                    </FormGroup>
                                  </Col>

                                  <Col md={3}>
                                    <FormGroup>
                                      <Label for="show_window_movil">
                                        {i18next.t('up_bot_start_movil')}
                                        <i
                                          className="fa fa-info-circle"
                                          id="tooltipDespliegue"
                                        ></i>
                                      </Label>
                                      <UncontrolledTooltip
                                        placement="right"
                                        target="tooltipDespliegue"
                                      >
                                        {i18next.t('enable_up_window_bot')}
                                      </UncontrolledTooltip>
                                      {console.log('this.state.show_window_movil ',this.state.show_window_movil)}
                                      <select
                                        value={
                                          this.state.show_window_movil == false ||
                                          this.state.show_window_movil == 0
                                            ? 0
                                            : 1
                                        }
                                        className="form-control config-input"
                                        name="show_window_movil"
                                        onChange={this.handleChangeSelect}
                                        required
                                        menuPortalTarget={document.body}
                                        menuPosition={'fixed'} 
                                        styles={customStyles}
                                      >
                                        <option value="1">{i18next.t('yes')}</option>
                                        <option value="0">{i18next.t('no')}</option>
                                      </select>
                                    </FormGroup>
                                  </Col>

                                </Row>
                                <Row>
                                  <Col md={6}>
                                    <FormGroup>
                                      <Label for="animacion">
                                        {i18next.t('avatar_animation')}
                                      </Label>
                                      <select
                                        value={this.state.animacion}
                                        className="form-control config-input"
                                        name="animacion"
                                        onChange={this.handleChangeSelect}
                                        required
                                        menuPortalTarget={document.body}
                                        menuPosition={'fixed'} 
                                        styles={customStyles}
                                      >
                                        <option value="">
                                          - {i18next.t('select_one')} -
                                        </option>
                                        {this.state.animation.map((el, i) => (
                                          <option
                                            key={"pos_id-" + i}
                                            value={el.val}
                                          >
                                            {el.tag}
                                          </option>
                                        ))}
                                        
                                      </select>
                                      
                                    </FormGroup>
                                  </Col>
                                  <Col md={6}>
                                  <FormGroup>
                                      <Label for="msg_no_agente">
                                        {i18next.t('placeholder_field_text')}{" "}
                                        <i
                                          className="fa fa-info-circle"
                                          id="tooltipInputEnviar"
                                        ></i>
                                      </Label>
                                      <UncontrolledTooltip
                                        placement="right"
                                        target="tooltipInputEnviar"
                                      >
                                        {i18next.t('description_field_text')}
                                      </UncontrolledTooltip>
                                      <Input
                                        className="config-input"
                                        type="text"
                                        id="placeholder"
                                        name="placeholder"
                                        maxLength="30"
                                        placeholder={i18next.t('write_message')}
                                        defaultValue={data.placeholder}
                                      />
                                    </FormGroup>
                                   
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md={6}>
                                    <FormGroup>
                                      <Label for="animacion">
                                        {i18next.t('msg_button_back_ia')}
                                      </Label>
                                      <Input
                                        placeholder={i18next.t('back')}
                                        maxLength="30"
                                        name="button_title2"
                                        value={
                                          this.state.button_title
                                            ? this.state.button_title.title2
                                            : ""
                                        }
                                        onChange={({ target }) => {
                                          this.setState({
                                            button_title: {
                                              ...this.state.button_title,
                                              title2: target.value,
                                            },
                                          });
                                        }}
                                        required
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={6}>
                                  <FormGroup>
                                      <Label for="animacion">
                                        {i18next.t('msg_button_continue_ia')}
                                      </Label>
                                      <Input
                                        placeholder={i18next.t('continue')}
                                        maxLength="30"
                                        name="button_title1"
                                        type="text"
                                        value={
                                          this.state.button_title
                                            ? this.state.button_title.title1
                                            : ""
                                        }
                                        onChange={({ target }) => {
                                          this.setState({
                                            button_title: {
                                              ...this.state.button_title,
                                              title1: target.value,
                                            },
                                          });
                                        }}
                                        required
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md={6}>
                                    <FormGroup>
                                      <Label for="regresar">
                                        {i18next.t('msg_return')}
                                      </Label>
                                      <Input
                                        placeholder={i18next.t('back')}
                                        maxLength="30"
                                        name="button_title3"
                                        value={
                                          this.state.word_to_return
                                            ? this.state.word_to_return
                                            : ""
                                        }
                                        onChange={({ target }) => {
                                          this.setState({
                                              word_to_return: target.value,
                                          });
                                        }}
                                        required
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={6}>
                                    <FormGroup>
                                      <Label for="abandonar">
                                        {i18next.t('word_return_queue_pa')}
                                      </Label>
                                      <Input
                                        placeholder={i18next.t('continue')}
                                        maxLength="30"
                                        name="button_title4"
                                        value={
                                          this.state.word_out_queue
                                            ? this.state.word_out_queue
                                            : ""
                                        }
                                        onChange={({ target }) => {
                                          this.setState({
                                            word_out_queue: target.value,
                                          });
                                        }}
                                        required
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                               
                                <Row md={10} offset={1} className="mb-3">
                                      <Col md={6}>
                                      <FormGroup>
                                          <Label for="exampleFile">
                                          {i18next.t('upload_image2')}
                                          </Label>
                                          <Input
                                            id="exampleFile"
                                            name="file"
                                            type="file"
                                            onChange={(e) => this.changesaveUrlImage(e)}
                                          />
                                        </FormGroup>
                                          <img class="img-preview" width={'100'} src={this.filepreview?this.filepreview:this._data.image_link_testing?this._data.image_link_testing:this.filepreview}/>
                                      </Col>
                                 </Row>
                              </TabPane>
                              <TabPane tabId="3">
                                <Row form>
                                  <Col md={12}>
                                    <Label>{i18next.t('platforms')}</Label>
                                  </Col>
                                  <Col md={6}>
                                    <FormGroup>
                                      <Label for="url_agente">
                                        {i18next.t('url_chat_pa')}
                                      </Label>
                                      <Input
                                        className="config-input"
                                        type="text"
                                        id="url_agente"
                                        name="url_agente"
                                        placeholder=""
                                        defaultValue={data.url_agente}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={6}>
                                    <Row>
                                      <Col md={10}>
                                        <FormGroup>
                                          <Label for="url_agente">
                                            {i18next.t('msg_not_connect')}
                                          </Label>
                                          <Input
                                            className="config-input"
                                            type="text"
                                            id="input-0"
                                            name="msj_agente"
                                            placeholder=""
                                            maxLength={2000}
                                            defaultValue={
                                              botversion.msg_no_connected
                                            }
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col md={2} className="mt-4 pt-3 pl-0">
                                        <Emoji
                                          id={0}
                                          callEmoji={this.props.callEmoji}
                                          type="item"
                                        />
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col sm="12">
                                    <Label>{i18next.t('advanced')} Chat</Label>
                                    <Row form>
                                      <Col md={6}>
                                        <FormGroup>
                                          <Label>
                                            {i18next.t('size_files')}{" "}
                                            <i
                                              className="fa fa-info-circle"
                                              id="tooltiparchivo"
                                            ></i>
                                          </Label>
                                          <UncontrolledTooltip
                                            placement="right"
                                            target="tooltiparchivo"
                                          >
                                            {i18next.t('size_files_max')}
                                          </UncontrolledTooltip>
                                          <select
                                            value={this.state.chat_filesize}
                                            className="form-control config-input"
                                            id="chat_filesize"
                                            name="chat_filesize"
                                            onChange={this.handleChangeSelect}
                                            required
                                            menuPortalTarget={document.body}
                                            menuPosition={'fixed'} 
                                            styles={customStyles}
                                          >
                                            <option key={"list-0"} value="">
                                              - {i18next.t('select_one')} -
                                            </option>
                                            {this.state.fileSize.map(
                                              (el, i) => (
                                                <option
                                                  key={"file_id-" + i}
                                                  value={el.val}
                                                >
                                                  {el.tag}
                                                </option>
                                              )
                                            )}
                                          </select>
                                        </FormGroup>
                                      </Col>
                                      <Col md={6}>
                                        <Label>{i18next.t('file_encripted')}</Label>
                                        <Switch
                                          style={{
                                            color: this.state.file_encripted
                                              ? "green"
                                              : "gray",
                                          }}
                                          name="fileEncripted"
                                          checked={
                                            this.state.file_encripted
                                              ? this.state.file_encripted
                                              : false
                                          }
                                          onClick={() => {
                                            this.setState({
                                              file_encripted: !this.state.file_encripted,
                                            });
                                          }}
                                          value={true}
                                        />
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                                <br />

                                <Row>
                                  <Col sm="12">
                                    <FormGroup className="d-block d-md-none mt-4 d-flex justify-content-center">
                                      <Button
                                        className="btn btn-guarumo-md"
                                        form="frm_bot_setup"
                                        type="submit"
                                      >
                                        {i18next.t('save_changes')}
                                      </Button>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </TabPane>
                              <TabPane tabId="4">
                                <Row>
                                  <Col sm="12">
                                    <Row md={10} offset={1}>
                                      <Col md={6} className="pb-4">
                                        <Label>{i18next.t('avatars_list')}</Label>
                                      </Col>
                                      <Col md={6}>
                                        <Button
                                          onClick={
                                            this.props.toggleModalNewAvatar
                                          }
                                          className="btn btn-guarumo-md float-right mb-4"
                                          type="button"
                                        >
                                          {i18next.t('new_avatar')}
                                        </Button>
                                      </Col>
                                    </Row>
                                    <Col md={12}>
                                      <Row>{avatarCompany}</Row>
                                    </Col>
                                  </Col>
                                </Row>
                              </TabPane>
                              <TabPane tabId="5">
                                <TabChannelConfi
                                  props={this.props}
                                  handleChangeSelect={this.handleChangeSelect}
                                  handleChangeSessionVar={
                                    this.handleChangeSessionVar
                                  }
                                  expected_user_varsSelected={this.props.expected_user_varsSelected}
                                  expected_user_vars = {this.props.expected_user_vars}
                                  setValue = {this.props.setValue}
                                ></TabChannelConfi>
                              </TabPane>



                              <TabPane tabId="6">
                                <Row>
                                  <Col sm="12">
                                  <Label for="timeout">
                                  {i18next.t('timeout')} 
                                </Label>
                                  <Row>
                                    <Col md={4}>
                                    <FormGroup>
                                      <Label for="timeout1">
                                        {i18next.t('timeout_type_first')}
                                      </Label>
                                      <Input
                                        name="timeout_type_first"
                                        type="select"
                                        defaultValue={
                                          bot.main_timeout
                                            ? bot.main_timeout.type1
                                            : ""
                                        }
                                        onChange={(e) =>
                                          this.onChangeTimeout('type1', e)
                                        }
                                        ><option value='send_message' key={0}>{i18next.t('send_message')}</option>
                                        <option value='go_to' key={1}>{i18next.t('go_to')}</option></Input>
                                    </FormGroup>
                                  </Col>
                                   <Col md={3}>
                                    <FormGroup>
                                      <Label for="timeout1">
                                        {i18next.t('firts_timeout')}
                                      </Label>
                                      <Input
                                        maxLength="30"
                                        name="timeout_time1"
                                        type="number"
                                        min="5"
                                        max="60"
                                        defaultValue={
                                          bot.main_timeout
                                            ? bot.main_timeout.time1
                                            : ""
                                        }
                                        onChange={(target) =>
                                          this.onChangeTimeout('time1', target)
                                        }
                                        
                                      />
                                    </FormGroup>
                                  </Col>
                                  {bot.main_timeout && (bot.main_timeout.type1==''||bot.main_timeout.type1=='send_message')?(null):this._botversion.botversion_id?(
                                    <Col>
                                    <SchemesSelector bindValue={this.props.getScheme} 
                                      key={"scheme1"}
                                      k={"scheme1"}
                                      botversion_id={this._botversion.botversion_id?this._botversion.botversion_id:''} 
                                        scheme={bot.main_timeout?bot.main_timeout.scheme1: ""}
                                        schemeSeleccion1={bot.main_timeout.scheme1?bot.main_timeout.scheme1:''}
                                        />
                                        <Alert color="danger" isOpen={timeout.validScheme1}>
                                            {i18next.t('must_select_scheme')}
                                        </Alert> 
                                        </Col>
                                        ):null
                                  }
                                   {bot.main_timeout &&(bot.main_timeout.type1==''||bot.main_timeout.type1=='send_message')?(
                                  <Col md={4}>
                                    <FormGroup>
                                      <Label for="timeout1">
                                        {i18next.t('firts_msg_timeout')}
                                      </Label>
                                      <Input
                                        name="timeout_msg1"
                                        value={
                                          bot.main_timeout
                                            ? bot.main_timeout.msg1
                                            : ""
                                        }
                                        onChange={(target) =>
                                          this.onChangeTimeout('msg1', target)
                                        }
                                        
                                      />
                                    </FormGroup>
                                  </Col>
                                  ):this._botversion.botversion_id?(
                                        <Col>
                                        <AllOperatorSelector bindValue={this.props.getOperator}
                                        botversion_id={this._botversion.botversion_id? this._botversion.botversion_id:''} 
                                        key={"operator1"}
                                        k={"operator1"}
                                        operator={bot.main_timeout.operator1
                                          ? bot.main_timeout.operator1
                                          : ""} 
                                        schemeId={bot.main_timeout.scheme1
                                          ? bot.main_timeout.scheme1
                                          : ""} 
                                        operatorsList={timeout.operatorsList1} 
                                        operatorSeleccion1={bot.main_timeout.operator1?bot.main_timeout.operator1:''}/>
                                        <Alert color="danger" isOpen={timeout.validOperator1}>
                                            {i18next.t('must_select_operator')}
                                        </Alert>
                                        </Col>
                                  ):null
                                  } 
                                  </Row>

                                  <Row>
                                    <Col md={4}>
                                    <FormGroup>
                                      <Label for="timeout2">
                                        {i18next.t('timeout_type_second')}
                                      </Label>
                                      <Input
                                        name="timeout_type_second"
                                        type="select"
                                        defaultValue={
                                          bot.main_timeout
                                            ? bot.main_timeout.type2
                                            : ""
                                        }
                                        onChange={(target) =>
                                          this.onChangeTimeout('type2', target)
                                        }
                                        ><option value='send_message' key={0}>{i18next.t('send_message')}</option>
                                        <option value='go_to' key={1}>{i18next.t('go_to')}</option></Input>
                                    </FormGroup>
                                  </Col>
                                   <Col md={3}>
                                    <FormGroup>
                                      <Label for="timeout2">
                                        {i18next.t('second_timeout')}
                                      </Label>
                                      <Input
                                        maxLength="30"
                                        name="timeout_time2"
                                        type="number"
                                        min="5"
                                        max="60"
                                        value={
                                          bot.main_timeout
                                            ? bot.main_timeout.time2
                                            : ""
                                        }
                                        onChange={(target) =>
                                          this.onChangeTimeout('time2', target)
                                        }
                                        
                                      />
                                    </FormGroup>
                                  </Col>
                                  {bot.main_timeout &&(bot.main_timeout.type2==''||bot.main_timeout.type2=='send_message')?(null):this._botversion.botversion_id?(
                                    <Col>
                                    <SchemesSelector bindValue={this.props.getScheme}  
                                      key={"scheme2"}
                                      k={"scheme2"}
                                      botversion_id={this._botversion.botversion_id?this._botversion.botversion_id:''} 
                                        scheme={bot.main_timeout
                                          ? bot.main_timeout.scheme2
                                          : ""}
                                          schemeSeleccion1={bot.main_timeout.scheme2?bot.main_timeout.scheme2:''}/>
                                        <Alert color="danger" isOpen={timeout.validScheme2}>
                                            {i18next.t('must_select_scheme')}
                                        </Alert> 
                                        </Col>
                                        ):null
                                  }
                                   {bot.main_timeout &&(bot.main_timeout.type2==''||bot.main_timeout.type2=='send_message')?(
                                  <Col md={3}>
                                    <FormGroup>
                                      <Label for="timeout2">
                                        {i18next.t('second_msg_timeout')}
                                      </Label>
                                      <Input
                                        name="timeout_msg2"
                                        value={
                                          bot.main_timeout
                                            ? bot.main_timeout.msg2
                                            : ""
                                        }
                                        onChange={(target) =>
                                          this.onChangeTimeout('msg2', target)
                                        }
                                        
                                      />
                                    </FormGroup>
                                  </Col>
                                  ):this._botversion.botversion_id?(
                                        <Col>
                                        <AllOperatorSelector bindValue={this.props.getOperator}
                                        botversion_id={this._botversion.botversion_id? this._botversion.botversion_id:''} 
                                        key={"operator2"}
                                        k={"operator2"}
                                        operator={bot.main_timeout.operator2
                                          ? bot.main_timeout.operator2
                                          : ""} 
                                        schemeId={bot.main_timeout.scheme2
                                          ? bot.main_timeout.scheme2
                                          : ""} 
                                        operatorsList={timeout.operatorsList2} 
                                        operatorSeleccion1={bot.main_timeout.operator2?bot.main_timeout.operator2:''}/>
                                        <Alert color="danger" isOpen={timeout.validOperator2}>
                                            {i18next.t('must_select_operator')}
                                        </Alert>
                                        </Col>
                                  ):null
                                  } 
                                  </Row>
                                  
                                  <Row>
                                    <Col md={4}>
                                    <FormGroup>
                                      <Label for="timeout3">
                                        {i18next.t('timeout_type_third')}
                                      </Label>
                                      <Input
                                        name="timeout_type_third"
                                        type="select"
                                        defaultValue={
                                          bot.main_timeout
                                            ? bot.main_timeout.type3
                                            : ""
                                        }
                                        onChange={(target) =>
                                          this.onChangeTimeout('type3', target)
                                        }
                                        ><option value='send_message' key={0}>{i18next.t('send_message')}</option>
                                        <option value='go_to' key={1}>{i18next.t('go_to')}</option></Input>
                                    </FormGroup>
                                  </Col>
                                   <Col md={3}>
                                    <FormGroup>
                                      <Label for="timeout3">
                                        {i18next.t('third_timeout')}
                                      </Label>
                                      <Input
                                        maxLength="30"
                                        name="timeout_time3"
                                        type="number"
                                        min="5"
                                        max="60"
                                        value={
                                          bot.main_timeout
                                            ? bot.main_timeout.time3
                                            : ""
                                        }
                                        onChange={(target) =>
                                          this.onChangeTimeout('time3', target)
                                        }
                                        
                                      />
                                    </FormGroup>
                                  </Col>
                                  {bot.main_timeout &&(bot.main_timeout.type3==''||bot.main_timeout.type3=='send_message')?(null):this._botversion.botversion_id?(
                                    <Col>
                                    <SchemesSelector bindValue={this.props.getScheme} 
                                      key={"scheme3"}
                                      k={"scheme3"}
                                      botversion_id={this._botversion.botversion_id?this._botversion.botversion_id:''} 
                                        scheme={bot.main_timeout
                                          ? bot.main_timeout.scheme3
                                          : ""}
                                          schemeSeleccion1={bot.main_timeout.scheme3?bot.main_timeout.scheme3:''}/>
                                        <Alert color="danger" isOpen={timeout.validScheme3}>
                                            {i18next.t('must_select_scheme')}
                                        </Alert> 
                                        </Col>
                                        ):null
                                  }
                                   {bot.main_timeout &&(bot.main_timeout.type3==''||bot.main_timeout.type3=='send_message')?(
                                  <Col md={4}>
                                    <FormGroup>
                                      <Label for="timeout3">
                                        {i18next.t('third_msg_timeout')}
                                      </Label>
                                      <Input
                                       
                                       name="timeout_msg3"
                                        value={
                                          bot.main_timeout
                                            ? bot.main_timeout.msg3
                                            : ""
                                        }
                                        onChange={(target) =>
                                          this.onChangeTimeout('msg3', target)
                                        }
                                        
                                      />
                                    </FormGroup>
                                  </Col>
                                  ):this._botversion.botversion_id?(
                                        <Col>
                                        <AllOperatorSelector bindValue={this.props.getOperator}
                                        botversion_id={this._botversion.botversion_id? this._botversion.botversion_id:''} 
                                        key={"operator3"}
                                        k={"operator3"}
                                        operator={bot.main_timeout.operator3
                                          ? bot.main_timeout.operator3
                                          : ""} 
                                        schemeId={bot.main_timeout.scheme3
                                          ? bot.main_timeout.scheme3
                                          : ""} 
                                        operatorsList={timeout.operatorsList3} 
                                        operatorSeleccion1={bot.main_timeout.operator3?bot.main_timeout.operator3:''}/>
                                        <Alert color="danger" isOpen={timeout.validOperator3}>
                                            {i18next.t('must_select_operator')}
                                        </Alert>
                                        </Col>
                                  ):null
                                  } 
                                  </Row>
                                  </Col>
                                </Row>
                              </TabPane>
                            </TabContent>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </Form>
            <ToastsContainer store={ToastsStore} />

            <ModalNuevoAvatar
              onDrop={this.props.onDrop3}
              thumbsImgModal={thumbsImgModal}
              companyId={data.company_id}
              toggleModal={this.props.toggleModalNewAvatar}
              isOpen={this.props.modalAvatar}
              saveAvatar={this.props.saveAvatar}
              buttonModalImg={this.props.buttonModalImg}
              buttonModalImgSize={this.props.buttonModalImgSize}
              error_modal_size={this.props.error_modal_size}
              error_modal_relacion={this.props.error_modal_relacion}
              msj_error_modal_size={this.props.msj_error_modal_size}
              msj_error_modal_relacion={this.props.msj_error_modal_relacion}
              ok_modal_relacion={this.props.ok_modal_relacion}
              ok_modal_size={this.props.ok_modal_size}
            />

            <ModalUpdateAvatar
              dataIdUpdate={this.props.idUpdate}
              dataTituloUpdate={this.props.titleUpdate}
              onDrop={this.props.onDrop4}
              thumbsImgModal={thumbsImgModalUpdate}
              companyId={data.company_id}
              toggleModal={this.props.toggleModalUpdateAvatar}
              isOpen={this.props.modalAvatar_update}
              updateAvatar={this.props.updateAvatar}
              error_modal_size_update={this.props.error_modal_size_update}
              error_modal_relacion_update={
                this.props.error_modal_relacion_update
              }
              msj_error_modal_size_update={
                this.props.msj_error_modal_size_update
              }
              msj_error_modal_relacion_update={
                this.props.msj_error_modal_relacion_update
              }
              ok_modal_relacion_update={this.props.ok_modal_relacion_update}
              ok_modal_size_update={this.props.ok_modal_size_update}
              buttonModalImgUpdate={this.props.buttonModalImgUpdate}
              buttonModalImgSizeUpdate={this.props.buttonModalImgSizeUpdate}
            />
          </Admin>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
  
}

const mapStateToPros = ({companyReducer,botReducer}) => ({companyReducer,botReducer})

const mapDispatchToProps = {
  setChangeConfig,
  setChangeTimeoutConfi
    
}; 

export default connect(mapStateToPros, mapDispatchToProps)(withRouter(Setup))

