import React, { Component } from 'react';
import APILayout from './api-module-layout';
import axios from 'axios';
import qs from 'qs';
import i18next from '../../../../../i18n';

import { cerrarSesion } from '../../../../utils/commonFunction'

const new_param = {
    key: "",
    value: ""
};

class APIModal extends Component {
    _isMounted = false;
    state = {
        text_blocks: {'bl_0': ""},
        nodeId: "",
        keyword: "",
        title: "",
        selection:  null,
        range: null,
        error_url: false,
        error_method: false,
        error_recibido: false,
        error_encode: false,
        error_parameter: false,
        current_parameter: new_param
    }

    constructor(props){
        super(props)
    }


    componentWillMount(){
        var auth=JSON.parse(localStorage.getItem('Authorization'));
        if(!auth || !auth.access_token)
          this.props.history.push("/login");
      }

    componentWillUnmount = () => this._isMounted = false;


    closeModal = () => this.props.closeModal('m9');
    
    getTitleOperator = titleOperator => this.props.modal.node.extras.titleOperator = titleOperator
    

    toggleModuleModal = () => {
        let title = this.props.modal.node.extras.titleOperator;
        let url = $('#input-head').val(); 
        this.saveTitle(title)
        .then(res => {
            this.props.updateSchema();
            this.forceUpdate();
        }).catch((error) => {
            console.log('Catch saveTitle:',error);
            if (error.response.status == 403)
              cerrarSesion();
        }); 
        this.props.modal.node.extras.url_api = url
        if(!this.props.modal.node.extras.url_api || this.props.modal.node.extras.url_api.length < 12){
            this.setState({error_url: true});
            return;
        }else{
            if(!(/^(ftp|http|https):\/\/[^ "]+$/.test(this.props.modal.node.extras.url_api))){
                this.setState({error_method: true});
            }
        }

        if(!this.props.modal.node.extras.method || this.props.modal.node.extras.method.length <= 0){
            this.setState({error_method: true});
            return;
        }

        if(!this.props.modal.node.extras.enctype || this.props.modal.node.extras.enctype.length <= 0){
            this.setState({error_encode: true});
            return;
        }

        let received_headers = this.props.modal.node.extras.received_headers;
        if(this.props.modal.node.extras.validate == true && (!received_headers || received_headers.length <= 0)){
            this.setState({error_recibido: true});
            return;
        }

        this.setState({
            error_url: false,
            error_method: false,
            error_encode: false,
            error_recibido: false
        });


        let custom_keys = this.props.modal.node.extras.custom_keys;
        if(custom_keys && custom_keys.length > 0){
            const ncustom_keys = custom_keys.filter((k) => {
                return k != null
            });
            this.props.modal.node.extras.custom_keys = ncustom_keys;
        } 
        
        let custom_headers = this.props.modal.node.extras.custom_headers;
        if(custom_headers && custom_headers.length > 0){
            const ncustom_headers = custom_headers.filter((k) => {
                return k != null
            });
            this.props.modal.node.extras.custom_headers = ncustom_headers;
        }  

        if(received_headers && received_headers.length > 0){
            const nreceived_headers = received_headers.filter((k) => {
                return k != null
            });
            this.props.modal.node.extras.received_headers = nreceived_headers;
        }        
        this.forceUpdate();

        let hks = this.props.modal.node.extras.headers_keys;
        if(hks){
            const khks = Object.keys(hks);
            khks.forEach((k) => {
                let hk = hks[k];
                if(hk.statement && hk.statement != ''){
                    // No se actualiza los datos en la base de datos, porque el flujo los
                    // requiere en el JSON y ya se estan guardando ahí.

                    // (statement, text, name,is_var,var_name)
                    // this.updateStatement(hk.statement, hk.key, hk.key,false,'').then((res) => {
                    //     if (res.status== 200){
                    //         this.forceUpdate();
                    //         this.props.updateSchema();
                    //     }            
                    // });
                }else{
                    let node = this.props.modal.node;
                    this.saveStatement(node.extras.dbId).then((res) => {
                        if (res.status== 200){
                            hk.statement = res.data.statement.id;
                            this.forceUpdate();
                            this.props.updateSchema();
                        }            
                    });
                    
                }
            });
        }else{

        }

        this.props.updateSchema();
        this.props.toggleModuleModal('api');
        this.forceUpdate();
    }

    addNewTextBlock = () => {
        let node = this.props.modal.node;
        this.saveStatement(node.extras.dbId).then((res) => {
            if (res.status== 200){
                let blocks = node.extras.text_blocks;
                blocks[res.data.statement.id] = "";
                this.forceUpdate();
                this.props.updateSchema()
            }            
        });
    }

    saveTitle = (title) => {
        const data = {
            id: this.props.modal.node.extras.dbId,
            title:title
        };

        var auth=JSON.parse(localStorage.getItem('Authorization'));
        var headers={
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer '+ auth.access_token 
        }

        return axios({
            method:'put',
            url:HOST_API +'/api/scheme/operator/',
            data:qs.stringify(data),
            headers:headers
        })
    }

    removeStatement = (statement) => {
        const data = {
            id: statement,
        };

        var auth=JSON.parse(localStorage.getItem('Authorization'));
        var headers={
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer '+ auth.access_token 
        }

        return axios({
            method:'delete',
            url:HOST_API +'/api/scheme/statement/',
            data:qs.stringify(data),
            headers:headers
        })
    }

    removeTextBlock = (index) => {
        let blocks = this.props.modal.node.extras.text_blocks;
        delete blocks[index];
        this.removeStatement(index)
        .then(res => {
            this.props.updateSchema()
        }).catch((error) => {
            console.log('Catch removeTextBlock:',error);
            if (error.response.status == 403)
              cerrarSesion();
        }); 
        this.forceUpdate();
    }



    saveStatement = (operator) => {
        const data = {
            operator_id: operator,
            text: "",
            name: ""
        };

        var auth=JSON.parse(localStorage.getItem('Authorization'));
        var headers={
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer '+ auth.access_token 
        }

        return axios({
            method:'post',
            url:HOST_API +'/api/scheme/statement/',
            data:qs.stringify(data),
            headers:headers
        });
    }

    updateStatement = (statement, text, name,is_var,var_name) => {
        const data = {
            id: statement,
            text,
            name,
            is_var,
            var_name
        };
        var auth=JSON.parse(localStorage.getItem('Authorization'));
        var headers={
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer '+ auth.access_token 
        }

        return axios({
            method:'put',
            url:HOST_API +'/api/scheme/statement/',
            data:qs.stringify(data),
            headers:headers
        });
    }

    /* END SEARCH VARIABLE FUNCTIONS AND HANDLERS */

    handleValidate = (checked) => {
        this.props.modal.node.extras.validate = checked;
        this.forceUpdate();
    }

    handleValidateSession = (checked) => {
        this.props.modal.node.extras.config.send_session_id = checked;
        this.forceUpdate();
    }

    updateMethod = (event) => {
        this.props.modal.node.extras.method = event.target.value;
        this.forceUpdate();
    }

    updateEncType = (event) => {
        this.props.modal.node.extras.enctype = event.target.value;
        this.forceUpdate();
    }

    updateCustomKey = (event, index, prop) => {
        this.props.modal.node.extras.custom_keys[index][prop] = event.target.value;
        this.forceUpdate();
    }
    updateItemHeader = (event, index, prop) => {
        this.props.modal.node.extras.custom_headers[index][prop] = event.target.value;
        this.forceUpdate();
    }

    removeCustomKey = (index) => {
        let blocks = this.props.modal.node.extras.custom_keys;
        delete blocks[index];        
        this.forceUpdate();
    }

    removeCustomHeader = (index) => {
        let blocks = this.props.modal.node.extras.custom_headers;
        delete blocks[index];        
        this.forceUpdate();
    }

    addCustomkey = () => {
        if(!this.props.modal.node.extras.custom_keys)
            this.props.modal.node.extras.custom_keys = [];
        
        this.props.modal.node.extras.custom_keys.push({name: "", text: ""});
        this.forceUpdate();
    }
    addCustomHeader = () => {
        if(!this.props.modal.node.extras.custom_headers)
            this.props.modal.node.extras.custom_headers = [];
        
        this.props.modal.node.extras.custom_headers.push({name: "", text: ""});
        this.forceUpdate();
    }

    updateReceivedKey = (event, index, prop) => {
        this.props.modal.node.extras.received_headers[index][prop] = event.target.value;
        // (statement, text, name,is_var,var_name)
        
        this.updateStatement(this.props.modal.node.extras.received_headers[index].statement,
            this.props.modal.node.extras.received_headers[index].name, 
            this.props.modal.node.extras.received_headers[index].name,
            this.props.modal.node.extras.received_headers[index].is_var,
            this.props.modal.node.extras.received_headers[index].var_name)
            .then((res) => {
                if (res.status== 200){
                    this.forceUpdate();
                }            
            }).catch((error) => {
                console.log('Catch updateReceivedKey:',error);
                if (error.response.status == 403)
                  cerrarSesion();
            });        
        this.forceUpdate();
    }

    removeReceivedKey = (index) => {
        let blocks = this.props.modal.node.extras.received_headers;
        this.removeStatement(blocks[index].statement)
        .then(res => {
            this.props.updateSchema();
        }).catch((error) => {
            console.log('Catch removeReceivedKey:',error);
            if (error.response.status == 403)
              cerrarSesion();
        }); 
        delete blocks[index];
        this.forceUpdate();
    }
    
    addReceivedkey = () => {
        if(!this.props.modal.node.extras.received_headers)
            this.props.modal.node.extras.received_headers = [];

        let node = this.props.modal.node;
        this.saveStatement(node.extras.dbId).then((res) => {
            if (res.status== 200){
                this.props.modal.node.extras.received_headers.push({name: "", statement: res.data.statement.id, is_var: false, var_name:''});
                this.forceUpdate();                
                this.props.updateSchema()
            }
        }).catch((error) => {
            console.log('Catch addReceivedkey:',error);
            if (error.response.status == 403)
              cerrarSesion();
        }); 
    }

    updateHeaderKey = (event, index, text, type, payload) => {
        if(!this.props.modal.node.extras.headers_keys)
            this.props.modal.node.extras.headers_keys = {}
        
        if(!this.props.modal.node.extras.headers_keys[index]){
            this.props.modal.node.extras.headers_keys[index] = {
                key: event.target.value, 
                text: text, 
                type: type, 
                payload: payload,
                statement: ''
            };
        }else{
            let d = {
                key: event.target.value, 
                text: text, 
                type: type, 
                payload: payload,
                statement: ''
            };
            
            if(this.props.modal.node.extras.headers_keys[index].statement && this.props.modal.node.extras.headers_keys[index].statement != '')
                d.statement = this.props.modal.node.extras.headers_keys[index].statement;
            
            this.props.modal.node.extras.headers_keys[index] = d;
        }
        
        this.forceUpdate();
    }

    changeValueParameter = (key, value) => {
        let param = this.state.current_parameter;
        param[key] = value;
        this.setState({current_parameter:param})
    }

    addParameter = () => {

        let node = this.props.modal.node;
        if(this.state.current_parameter.key.length <= 0){
            alert(i18next.t('message42'));

            this.setState({
                error_parameter: true
            });
            return false;
        }

        if(this.state.current_parameter.value.length <= 0){
            alert(i18next.t('field_required'));
            this.setState({
                error_parameter: true
            });
            return false;
        } 

        this.setState({
            error_parameter: false
        });

        this.saveStatement(node.extras.dbId).then((res) => {
            if (res.status== 200){
                let extras = this.props.modal.node.extras;
                if(!extras.headers_keys){
                    extras.headers_keys = {};
                }
                extras.headers_keys[res.data.statement.id] = {
                    key: this.state.current_parameter.key,
                    value: this.state.current_parameter.value
                }
                this.setState({current_parameter: new_param});
                this.props.updateSchema();
                this.forceUpdate();
            }
        }).catch((error) => {
            console.log('Catch saveStatement:',error);
            if (error.response.status == 403)
              cerrarSesion();
        }); 


    }

    removeParameter = (key) => {
        let extras = this.props.modal.node.extras;
        if(extras.headers_keys){
            delete extras.headers_keys[key]
            this.removeStatement(key);
        }
        this.props.updateSchema()
        this.forceUpdate();
    }

    updateItem = (text) => {   
        this.props.modal.node.extras.url_api = text; 
        this.forceUpdate(); 
    } 

    
    updateCustomHeader = (k, text) => {
        this.props.modal.node.extras.custom_headers[k].text = text;
        this.forceUpdate();
    }

    updateValidation = (index, event) => {
        let blocks = this.props.modal.node.extras.received_headers;
        blocks[index].is_var = (event.target.value == 'true');
        if(!blocks[index].is_var){
            blocks[index].var_name = ''
        }
        this.updateReceivedKey(event,index,'is_var')
        this.forceUpdate();
    }

    updateVariable = (index, event) => {
        let blocks = this.props.modal.node.extras.received_headers;
        blocks[index].var_name = event.target.value ;
        this.updateReceivedKey(event,index,'var_name')
        this.forceUpdate();
    }

     // IA set node extras
     getIAId = IAid => this.props.modal.node.extras.ia_id = IAid;
     
    render() {
        return (
          <APILayout 
          toggleModuleModal={this.toggleModuleModal}
          addNewTextBlock={this.addNewTextBlock}
          removeTextBlock={this.removeTextBlock}
          begin={this.props.modal.node.extras.begin} 
          isOpen={this.props.modal.isOpen} 
          text_blocks={this.props.modal.node.extras.text_blocks} 
          nodeId={this.state.nodeId}          
          title={this.state.title} 
          url_api={this.props.modal.node.extras.url_api} 
          validate={this.props.modal.node.extras.validate || false}
          send_session_id={this.props.modal.node.extras.config?this.props.modal.node.extras.config.send_session_id :true}
          handleValidate={this.handleValidate}
          handleValidateSession={this.handleValidateSession}
          method={this.props.modal.node.extras.method}
          updateMethod={this.updateMethod}
          headers={this.props.modal.headers || {}}
          h_keys = {this.props.modal.node.extras.headers_keys || {}}
          updateHeaderKey={this.updateHeaderKey}
          updateCustomKey={this.updateCustomKey}
          updateItemHeader={this.updateItemHeader}
          updateCustomHeader={this.updateCustomHeader}
          removeCustomKey={this.removeCustomKey}
          removeCustomHeader={this.removeCustomHeader}
          addCustomkey = {this.addCustomkey}
          addCustomHeader = {this.addCustomHeader}
          custom_keys = {this.props.modal.node.extras.custom_keys || []}
          custom_headers = {this.props.modal.node.extras.custom_headers || []}
          updateEncType = {this.updateEncType}
          enctype={this.props.modal.node.extras.enctype}
          addReceivedKey={this.addReceivedkey}          
          updateReceivedKey={this.updateReceivedKey}
          removeReceivedKey={this.removeReceivedKey}          
          received_headers={this.props.modal.node.extras.received_headers || []}
          updateValidation = {this.updateValidation}
          updateVariable = {this.updateVariable}
          
          updateItem={this.updateItem} 
          varList={this.props.varList}
          changeValueParameter={this.changeValueParameter}
          addParameter={this.addParameter}
          removeParameter={this.removeParameter}

          botversion_id={this.props.botversion_id}
          operator_id={this.props.modal.node.extras.dbId}
          ia={this.props.modal.node.extras.ia_id || 'none'}
          getIAId = {this.getIAId}

          closeModal={this.closeModal}
          error_url={this.state.error_url}
          error_method={this.state.error_method}
          error_recibido={this.state.error_recibido}
          error_encode={this.state.error_encode}
          getTitleOperator = {this.getTitleOperator}
          titleOperator = {this.props.modal.node.extras.titleOperator || ''}
          
          />
        )
    }
}

export default APIModal
