import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
 

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  Row,
  UncontrolledTooltip,
  FormText
} from "reactstrap";
import "../../../../static/css/modal-nuevo.css";

import { saveNewMetric,toggleModalViewMetric, selectedMetric} from "../../../redux/actions/analyticsActions";

import TreeView from '../infoViz/common/treeview';
import "../../../../static/css/treeview.css" 

import QueryCubejs from "../infoViz/cubejs/cubejsQueryRenderData"

var operators=[],
    functions=[],
    textareaFocus="metricFormula";

    import i18next from "../../../../i18n";


function ModalViewMetric(props) {
  
  const { analyticsReducer: { listAllUnits = [], listAllDimensions = [],modalViewMetric,selectedMetric=[] }, toggleModalViewMetric, saveNewMetric } = props;
  
  createListSelect(props);
 

  return (
   
    <div>
      <Modal
        isOpen={modalViewMetric}
        toggle={toggleModalViewMetric}
        className="modal-lg"
        fade
      >
        <ModalHeader toggle={toggleModalViewMetric} charCode="X">
        {i18next.t('general_information')}
          {selectedMetric.type_metric=='MEA'?(" Medida"):" Métrica"}
        </ModalHeader>
        <Form id="form-createmetrica" onSubmit={
          (e)=>{
          saveNewMetric(e,(urlDirect)=>{
            props.history.push(urlDirect);
          });
        }} role="form">
          <ModalBody>
          {
                  selectedMetric.id?(
                    <Row>
                <Col sm="5">
                    <FormGroup row>
                        <Label for="metricName" sm={4} className="control-label required-field">
                            {i18next.t('name')+': '}
                        </Label>
                        <Col sm={8}>
                        <FormText>
                             {selectedMetric.name}
                        </FormText>
                        </Col>
        
                    </FormGroup>
                    <FormGroup row>
                      <Label for="metricShortName" sm={4} className="control-label required-field">
                      {i18next.t('name_short')+': '}
                      </Label>
                      <Col sm={8}>
                        <FormText>
                            {selectedMetric.short_name}
                        </FormText>
                      </Col>
                  </FormGroup>
                  <FormGroup row>
                      <Label
                        sm={4}
                        className="control-label required-field"
                        for="metricUnity"
                      >
                        {i18next.t('unit')+': '}
                      </Label>
                      <Col sm={8}>
                        <FormText>
                            {selectedMetric.unit.name}
                        </FormText>
                      </Col>
                      </FormGroup>
                      
                <FormGroup row>   
                    <Label
                       sm={4}
                      className="control-label required-field"
                      for="metricBot"
                    >
                       {i18next.t('indicator_type')+': '}
                    </Label>
                    <Col sm={8}>
                      <FormText>
                              {selectedMetric.type_indi=='OPE'?("Operacional"):"Funcional"}
                      </FormText>
                      </Col>
              </FormGroup>
          
                </Col>
                <Col sm="7">
                <FormGroup row>
                  <Label sm={4} className="control-label" for="metricDescription">
                  {i18next.t('description')+': '}{" "}
                </Label>
                <Col sm={8}>
                      <FormText>
                          {selectedMetric.description}
                      </FormText>
                    </Col>
              </FormGroup>   
                {selectedMetric.type_metric=='MEA'?(
                <React.Fragment>
                    {/* <FormGroup row>   
                      <Label
                        sm={4}
                        className="control-label required-field"
                        for="metricBot"
                      >
                        Operación:
                      </Label>
                      <Col sm={8}>
                        <FormText>
                                {selectedMetric.operation}
                        </FormText>
                        </Col>
                </FormGroup> */}
                <FormGroup row> 
                    <Label
                          sm={4}
                          className="control-label required-field"
                          for="metricType"
                        >
                          {i18next.t('dimentions')+': '}
                        </Label>
                        <Col sm={8}>
                        <FormText>
                                {selectedMetric.dimension.name}
                        </FormText>
                        </Col>
                  </FormGroup>
                </React.Fragment>
                ):
                <FormGroup row> 
                   <Label
                        sm={4}
                        className="control-label required-field"
                        for="metricType"
                      >
                        {i18next.t('measures_tree')+': '}
                      </Label>
                      <Col sm={8}>
                      <TreeView></TreeView>
                      </Col>
                  </FormGroup>
                  }
                </Col>               
              </Row>
                  ):
               <div>HOLA</div>
              }
              
              <hr style={{"border":"1px solid #28a745",size:"10"}}/>
              <br></br>
              {selectedMetric.type_metric=='MEA'?(
                <React.Fragment>
                  <FormGroup style={{overflow:'auto'}}>
                    <QueryCubejs
                      typechart={'6'}
                      nameDiv={'table'}
                      // color={color}
                      // typepalette={typepalette}
                      metric={selectedMetric}
                      // icon={icon}
                      >
                    </QueryCubejs>
                    </FormGroup> 
                </React.Fragment>
              ):<div></div>
              }
          </ModalBody>
          <ModalFooter>
            <Button onClick={toggleModalViewMetric} className="btn-guarumo-modal">
            {i18next.t('close')}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
}

function onchangeType(e)
{
  var bots=document.getElementById("metricBot");
  if(e.target.value=='operacional'){
    bots.disabled=true;
    bots.value=""
  }
  else
    bots.disabled=false;
    
}
function onclick(e,type)
{
  // var formula=document.getElementById("metricFormula");
  // var condition=document.getElementById("metricCondition");
  // var dimension=document.getElementById("metricDimension");

  var text="";
  //var text="<span style='color: blue;'>["+e.target.value+"]</span>";
  if(type=="field")
  text+="["+e.target.value+"]";
else if(type=="operator")
  text+=e.target.value;
else if(type=="function")
  text+=e.target.value+"()";

  if(textareaFocus=="metricDimension"&&(type=="operator"||type=="function")){}
  else
    insertAtCaret(textareaFocus, text);
  
  
}

function insertAtCaret(areaId, text) {
  var txtarea = document.getElementById(areaId);
  var scrollPos = txtarea.scrollTop;
  var caretPos = txtarea.selectionStart;

  var front = (txtarea.value).substring(0, caretPos);
  var back = (txtarea.value).substring(txtarea.selectionEnd, txtarea.value.length);
  txtarea.value = front + text + back;
  caretPos = caretPos + text.length;
  txtarea.selectionStart = caretPos;
  txtarea.selectionEnd = caretPos;
  txtarea.focus();
  txtarea.scrollTop = scrollPos;
}

function onFocus(e,textarea)
{
  textareaFocus=textarea;

  var operadores=document.getElementById("metricOperators");
  var funciones=document.getElementById("metricFunction");

  if(textareaFocus=="metricDimension")
  {
    
    operadores.disabled=true;
    funciones.disabled=true;
  }
  else
  {
    operadores.disabled=false;
    funciones.disabled=false;
  }

}

function onTry(e)
{

}

function createListSelect(props)
{

  /**
   * Select Operators
   */
  if(operators.length==0){
  if(props.operators){
    if (props.operators.length > 0) {
      for (var i = 0; i < props.operators.length; i++) {
        let aux = props.operators[i];
        if(aux.id != 1){
          operators.push(
            <option key={i} value={aux.name}>
              {aux.name}
            </option>
          );
        }
      }
    }
  }
}

  /**
   * Select Functions
   */
  if(functions.length==0){
  if(props.functions){
    if (props.functions.length > 0) {
      for (var i = 0; i < props.functions.length; i++) {
        let aux = props.functions[i];
        if(aux.id != 1){
          functions.push(
            <option key={i} value={aux.id}>
              {aux.name}
            </option>
          );
        }
      }
    }
  }
}

}

const mapStateToProps = ({ analyticsReducer,  }) => {
	return { analyticsReducer,  };
};

const mapDispatchToProps = {
  saveNewMetric,
  toggleModalViewMetric
 
};


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ModalViewMetric));
