import React from 'react';
import i18next from '../../../../i18n';
import { Link } from "react-router-dom";
import Admin from '../../admin/containers/admin';
import SliceMenuApp from '../../admin/components/slice-menu'
import {
    Col,
    Row,
    Button,
    Container,
    Modal,
    ModalHeader,
    ModalBody,
    FormGroup,
    Label,
    Input,
    Form,
    UncontrolledTooltip
} from 'reactstrap';
import Select from 'react-select';
import ToolbarApp from '../../admin/components/toolbar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync } from '@fortawesome/free-solid-svg-icons'
import ModalCustomURL from './modal-custom-url';
// import AdminMenuOptions from "../../menus/massive-campaigns-menu-options";
import BotMenuOptions from '../../menus/bot-menu-options.js';
import './massive-campaign-layout.css';
import Datetime from 'react-datetime';
import './react-datetime.css';
import Mentions from '../../../components/mentions/mentions-header';
require('moment/locale/es')
const utf8 = require('utf8');
import Audio from '../../../components/audio-input';
import { isDefined} from '../../../utils/isValidate';

import BoxChannels from "./box-channels";
import ItemOpcions from "./itemOpcions";


// Assets
import emailColor from '../../../../static/img/campaign/email1.png';
import emailWhite from '../../../../static/img/campaign/email2.png';
import messengerColor from '../../../../static/img/campaign/messenger1.png';
import messengerWhite from '../../../../static/img/campaign/messenger2.png';
import smsColor from '../../../../static/img/campaign/sms1.png';
import smsWhite from '../../../../static/img/campaign/sms2.png';
import vozColor from '../../../../static/img/campaign/voz1.png';
import vozWhite from '../../../../static/img/campaign/voz2.png';
import whatsappColor from '../../../../static/img/campaign/whatsapp1.png';
import whatsappWhite from '../../../../static/img/campaign/whatsapp2.png';
import simple from '../../../../static/img/campaign/simple.png';
import automatizado from '../../../../static/img/campaign/automatizado.png';

import './box-channels.css';



function MassiveCampaignLayout(props) {    

    // console.log('type:',props.type,'channel_id:',props.channel_id)
    const { itemSelected, handleChangeSelectCard, channel_id , type, titleOpcion, listChannels = [] }= props;    

    let onClikcOptions = (type, channel_id ,title )=>{
        props.toggleModalNewCampaign(type, channel_id,title);
    }

    let channelSelected = (channelSelected) => {   
        const temp = listChannels.filter((item) => item.value == channelSelected)[0];       
        if(temp){
            const { label =''  } = temp;
            return ` de ${label}`;
        }
        return;        
    } 

    let remitente = (channel_id) => {
        let channel ='';
        let channelTW='';
        switch (channel_id) {
            case 3:
                channel='EM';
                break;
            case 5:
                channel='PH';
                channelTW='TW';
                break;
            case 1:
                channel='PH';
                channelTW='TW';
                break;
        
            default:
                break;
        }
        //const senderOpcion = [{value: 0, label: "POR DEFECTO"}];
        const senderOpcion = [];
        if ( isDefined(props.listAllSender) ){
            const temp = props.listAllSender.filter((item)=>( item.channel_type === channel || item.channel_type === channelTW ));
            temp.map((item)=>{
                senderOpcion.push({value: item.id, label: item.name});
            });
        }
        return senderOpcion;
    }


    // features Cards
    let itemMessenger = {
        title:'Messenger',
        avatarC: messengerColor,
        avatarW: messengerWhite,
        detail: 'Haz algún comentario para iniciar una conversación, crear una nota o comentar en la mesa de trabajo.',
        color: '#3E59C1',
        ColorW:'#FFF',
        ColorB: '#000'
    }

    let itemSMS = {
        title:'SMS',
        avatarC: smsColor,
        avatarW: smsWhite,
        detail: 'Haz algún comentario para iniciar una conversación, crear una nota o comentar en la mesa de trabajo.',
        color: '#F7C137',
        ColorW:'#FFF',
        ColorB: '#000'
    }
  
    let itemEmail = {
        title:'E-mail',
        avatarC: emailColor,
        avatarW: emailWhite,
        detail: 'Haz algún comentario para iniciar una conversación, crear una nota o comentar en la mesa de trabajo.',
        color: '#16A2B7',
        ColorW:'#FFF',
        ColorB: '#000'
    }

    let itemVoz = {
        title:'Voz',
        avatarC: vozColor,
        avatarW: vozWhite,
        detail: 'Haz algún comentario para iniciar una conversación, crear una nota o comentar en la mesa de trabajo.',
        color: '#8C54FF',
        ColorW:'#FFF',
        ColorB: '#000'
    }
  
    let itemWhatsapp = {
        title:'Whatsapp',
        avatarC: whatsappColor,
        avatarW: whatsappWhite,
        detail: 'Haz algún comentario para iniciar una conversación, crear una nota o comentar en la mesa de trabajo.',
        color: '#75B74B',
        ColorW:'#FFF',
        ColorB: '#000'
    }


    // Lista de opciones para el template de email
    let template = props.listTemplates || [];
    var sel_template = [];
    template.map((t, i) => {
        let tempChannelSelected = false;
        if (props.editedCampaign && props.campaignEditing.channel.id == 3) {
            // Mantener la plantilla seleccionada
            tempChannelSelected = (t.id == props.campaignEditing.template_id.id) ? true : false
        }
        sel_template.push(<option key={"template_id-" + i} value={t.id} selected={tempChannelSelected}>{t.template_name}</option>);
    })

    let contact = props.listContact || [];
    var aux2 = false; 

    let sel_contat = contact.map((n, i) => {
        if(n._id == props.idList) 
            aux2 = true;
        return <option key={"contEdit_id-"+i} value={n._id} selected={aux2}>{n.titulo}</option>;
    })

    
    return (
        <div className="NotificaionsLayout">
            <Admin>
                <SliceMenuApp>
                    <BotMenuOptions admin={props.isAdmin} />
                </SliceMenuApp>
                <Container fluid>
                    <ToolbarApp>
                        <Row>
                            <Col sm="9" md="9" lg="9" className="text pdtoolbar">
                                <h2 className="text-left ml-4 font-weight-bold">{i18next.t('campaign_massive')}</h2>
                            </Col>
                            <Col className="filter-stuff col-sm-4 col-md-3 col-lg-3">
                                <Link to='/list-massive-campaign/'>
                                    <Button className="pull-right btn-block" color="success" >{i18next.t('campaign_look')}</Button>
                                </Link>
                            </Col>
                        </Row>
                    </ToolbarApp>
                </Container>
                
                <Container fluid className="box-fluid">
                    <Container className=" col-10 offset-1 pl-0 pr-0">
                        <Col md="12" className="pl-0 pr-0">
                            <Row>      
                                {/* <BoxChannels title= {itemMessenger.title}
                                    avatar= {(itemSelected != itemMessenger.title) ? itemMessenger.avatarC : itemMessenger.avatarW } detail= { itemMessenger.detail } background= {(itemSelected != itemMessenger.title) ? itemMessenger.ColorW  : itemMessenger.color } textColor= {(itemSelected != itemMessenger.title) ? itemMessenger.ColorB : itemMessenger.ColorW  }
                                    onclick= {()=>handleChangeSelectCard(itemMessenger.title)} >
                                    { itemSelected== itemMessenger.title && 
                                        options(2)                              
                                    }
                                </BoxChannels> */}
                                
                                <BoxChannels className='box-channels-item-sms' title= {itemSMS.title} avatar= {(itemSelected != itemSMS.title) ? itemSMS.avatarC : itemSMS.avatarW } detail= { itemSMS.detail } background= {(itemSelected != itemSMS.title) ? itemSMS.ColorW  : itemSMS.color } textColor= {(itemSelected != itemSMS.title) ? itemSMS.ColorB : itemSMS.ColorW  } onclick= {()=>handleChangeSelectCard(itemSMS.title)} >
                                    { itemSelected== itemSMS.title && 
                                        <React.Fragment>
                                            <div className='fadeInDown' >
                                                <ItemOpcions title = 'Simplificado' detail= 'Lopsum' img={simple} channel_id = {1} type={1} onClikcOptions={onClikcOptions} />                                             
                                                <ItemOpcions title = 'Automatizado' detail= 'Lopsum' img={simple} channel_id = {1} type={2} onClikcOptions={onClikcOptions} /> 
                                            </div>
                                        </React.Fragment>                            
                                    }
                                </BoxChannels>

                                <BoxChannels
                                    className='box-channels-item-email' title= {itemEmail.title} avatar= {(itemSelected != itemEmail.title) ? itemEmail.avatarC : itemEmail.avatarW } detail= { itemEmail.detail } background= {(itemSelected != itemEmail.title) ? itemEmail.ColorW  : itemEmail.color  } textColor= {(itemSelected != itemEmail.title) ? itemEmail.ColorB : itemEmail.ColorW  } onclick= {()=>handleChangeSelectCard(itemEmail.title)} >
                                    { itemSelected== itemEmail.title &&                                         
                                        <div className='fadeInDown' >
                                            <ItemOpcions title = 'Simplificado' detail= 'Lopsum' img={simple} channel_id = {3} type={1} onClikcOptions={onClikcOptions} />
                                            <ItemOpcions title = 'Automatizado' detail= 'Lopsum' img={automatizado} channel_id = {3} type={2} onClikcOptions={onClikcOptions} /> 
                                        </div>
                                                                    
                                    }
                                </BoxChannels>

                                <BoxChannels className='box-channels-item-voz' title= {itemVoz.title} avatar= {(itemSelected != itemVoz.title) ? itemVoz.avatarC : itemVoz.avatarW }              detail= { itemVoz.detail } background= {(itemSelected != itemVoz.title) ? itemVoz.ColorW  : itemVoz.color } textColor= {(itemSelected != itemVoz.title) ? itemVoz.ColorB : itemVoz.ColorW  } onclick= {()=>handleChangeSelectCard(itemVoz.title)} >
                                    { itemSelected== itemVoz.title &&      
                                       <div className='fadeInDown'>
                                            <ItemOpcions title = 'Con texto' detail= 'Lopsum' img={simple} channel_id = {5} type={1} onClikcOptions={onClikcOptions} /> 
                                            <ItemOpcions title = 'Con audio' detail= 'Lopsum' img={simple} channel_id = {5} type={3} onClikcOptions={onClikcOptions} /> 
                                            <ItemOpcions title = 'Automatizado' detail= 'Lopsum' img={simple} channel_id = {5} type={2} onClikcOptions={onClikcOptions} />
                                        </div>
                                    }
                                </BoxChannels>

                                <BoxChannels className='box-channels-item-whatsapp'
                                    title= {itemWhatsapp.title} avatar= {(itemSelected != itemWhatsapp.title) ? itemWhatsapp.avatarC : itemWhatsapp.avatarW } detail= { itemWhatsapp.detail } background= {(itemSelected != itemWhatsapp.title) ? itemWhatsapp.ColorW  : itemWhatsapp.color } textColor= {(itemSelected != itemWhatsapp.title) ? itemWhatsapp.ColorB : itemWhatsapp.ColorW  } onclick= {()=>handleChangeSelectCard(itemWhatsapp.title)} >
                                    {/* { itemSelected== itemWhatsapp.title && 
                                         <div className='fadeInDown' >
                                         <ItemOpcions title = 'Simplificado' detail= 'Lopsum' img={simple} channel_id = {4} type={1} onClikcOptions={onClikcOptions} /> 
                                         <ItemOpcions title = 'Automatizado' detail= 'Lopsum' img={simple} channel_id = {4} type={2} onClikcOptions={onClikcOptions} /> 
                                     </div>                               
                                    } */}
                                </BoxChannels>

                                
                            </Row>
                        </Col>
                    </Container>
                </Container>

                <Modal className="modalRight" isOpen={props.modalNewNotification} fade toggle={() => ((props.editedCampaign) ? props.toggleModalEditCampaign() : props.toggleModalNewCampaign())}>
                    <ModalHeader toggle={() => ((props.editedCampaign) ? props.toggleModalEditCampaign() : props.toggleModalNewCampaign())} className='notification'>{i18next.t('configure_campaign')} {channelSelected(channel_id)}</ModalHeader>
                    <ModalBody className='notification-body'>
                        <Col md={12} className="sectionBox">
                            <Form id="frm_notification" onSubmit={props.handleSaveCampaign}>
                                <FormGroup>
                                    {   props.editedCampaign &&
                                        <Input type="hidden" id="campaign_id" name="campaign_id" defaultValue={props.campaignEditing.id} />

                                    }
                                    <Input type="hidden" id="channel" name="channel" defaultValue={channel_id} />
                                    <Input type="hidden" id="tipo_Mensaje" name="tipo_Mensaje" defaultValue={type} />
                                    <Input type="hidden" id="sender_id" name="sender_id" defaultValue={props.selectedOptionSender.value} />

                                    <Label>{i18next.t('campaign_type')}</Label>
                                    <Input type="text" id="tipo_Ms" name="tipo_Ms" disabled defaultValue={titleOpcion} />   

                                    <Label>{i18next.t('title')}</Label>
                                    <Input type="text" id="title" name="title" maxLength={200}
                                        defaultValue={(props.editedCampaign) ? utf8.decode(props.campaignEditing.title) : null}
                                        required />       

                                    {   props.showBot && 
                                        <div>
                                            <Label>Bot</Label>
                                            <Select id="bot" defaultValue={(props.editedCampaign) ? { value: props.campaignEditing.bot.id, label: props.campaignEditing.bot.name } : null } onInputChange={props.handleChangeInputValueBots} onChange={props.handleChangeBots} options={props.listBots} isMulti={false} name="bot" className="bot" required />
                                            <UncontrolledTooltip placement="bottom" target='bot'>Se usuara un número o código doble vía donde un bot será quien interactue con cada uno de sus contactos.</UncontrolledTooltip>
                                        </div>
                                    }
                                    
                                    {   props.showContactsList && 
                                        <div>
                                            <Label className="plusBtn">{i18next.t('contact_list')}&nbsp;<a target="_blank" href={"/contact-lists/"} className="pull-right btn btn-success">{i18next.t('new_list')}</a></Label>
                                            <Row>
                                                <Col className="col-10">
                                                    <Input type="select" onChange={props.handleChangeSelectContact} className="config-input" name="list">
                                                    <option key={"contact_id-0"} value='' style={{'display':'none'}}>-{i18next.t('select_one')} -</option>
                                                    {sel_contat}
                                                    </Input>
                                                </Col>
                                                <Col className="col-2 pl-0">
                                                    <Button outline color="success" onClick={ (e) => props.getLists()}>
                                                        <FontAwesomeIcon icon={faSync} id='buttonRefreshE' style={{'fontSize':'18px'}} />
                                                    </Button>
                                                    <UncontrolledTooltip placement="bottom" target='buttonRefreshE'> {i18next.t('refresh_contact_list')}</UncontrolledTooltip>
                                                </Col>
                                            </Row>
                                        </div>
                                    }

                                    {   props.showSms &&
                                        <div>
                                            <Label>{i18next.t('message')} SMS</Label><Button color="success" className="btn-sm float-right" onClick={()=>props.toggleModalCustomUrl()}>{i18next.t('add_url')}</Button>
                                            <Mentions k={1} varList={props.varList}  defaultValue={props.message || ''} updateItem={props.updateItem} maxlength={'1600'} msg_error={'Máximo permitido 1600'}/>
                                        </div>     
                                    }

                                    {   props.showMessageVoz &&
                                        <div>
                                            <Label>{i18next.t('message')} </Label>
                                            <Mentions k={1} varList={props.varList}  defaultValue={props.message || ''} updateItem={props.updateItem} maxlength={'1600'} msg_error={'Máximo permitido 1600'}/>
                                        </div> 
                                    }   
                                    {   props.showWpFb &&
                                        <div >
                                            <Label>{i18next.t('message')} Whatsapp / Facebook</Label>
                                            <Mentions k={2} varList={props.varList}  defaultValue={props.message || ''} updateItem={props.updateItem} maxlength={'2000'} msg_error={'Máximo permitido 2000'}/>
                                        </div>
                                    }
                                    {   props.showSms && 
                                        <i>Escriba el mensaje que quiere enviar a sus contactos, los usuarios no podrán responder a este mensaje.</i>
                                    }
                                   
                                    {    props.showUpAudio && 
                                        <div>
                                            <Audio operator_id='campaign' audio={props.message} bindValue={props.getAudio}/>
                                        </div>
                                    }
                                    
                                    { props.showOnlySubject && 
                                        <div>
                                            <Label>{i18next.t('subject')}</Label> 
                                            <Input type="text" id="subject" name="subject" maxLength={500} defaultValue={(props.editedCampaign) ? utf8.decode(props.campaignEditing.subject) : ''} />  
                                        </div>
                                    }

                                    { props.showEmail &&
                                        <div>                                   
                                            <React.Fragment>
                                                <Label>{i18next.t('subject')}</Label> 
                                                <Input type="text" id="subject" name="subject" maxLength={500} defaultValue={(props.editedCampaign) ? utf8.decode(props.campaignEditing.subject) : ''} />  
                                                <Label className="plusBtn">{i18next.t('email_template')} &nbsp;<a target="_blank" href="/Newtemplates" className="pull-right btn btn-success">{i18next.t('new_template')}</a> </Label>
                                                <Row>
                                                    <Col className="col-10">
                                                        <Input type="select" name="plantillaEmail" id="plantillaEmail" className="plantillaEmailBox">
                                                            <option key={"template_id-0"} value='' style={{ 'display': 'none' }}>-{i18next.t('select_option')}-</option>
                                                            {sel_template}
                                                        </Input>
                                                    </Col>
                                                    <Col className="col-2 pl-0">
                                                        <Button outline color="success" onClick={(e) => props.getlistTemplates()}>
                                                            <FontAwesomeIcon icon={faSync} id='buttonRefresh' style={{ 'fontSize': '18px' }} />
                                                        </Button>
                                                        <UncontrolledTooltip placement="bottom" target='buttonRefresh'> {i18next.t('refresh_template_email_list')}</UncontrolledTooltip>
                                                    </Col>
                                                </Row>
                                            </React.Fragment>
                                        </div>
                                    }

                                    { props.showSender &&
                                        <div>
                                            <Label>{i18next.t('sender')}</Label>
                                            <Select id="remitente_campaign" onChange={props.handleChangeSender} options={remitente(channel_id)} isMulti={false} name="remitente_campaign" />
                                        </div>
                                    }

                                    <Label >{i18next.t('send_date')}</Label>
                                    <Select id="tipo_envio" onChange={props.handleChangeSendDate} options={props.SendDateOptions} isMulti={false} name="tipo_envio" className="tipo_envio" required />
                                    {   props.showSendDate &&
                                        <Datetime id='send_date' name='send_date' required defaultValue={(props.editedCampaign) ? dateLoca(props.campaignEditing.send_date) : props.selectedDay} dateFormat={'YYYY-MM-DD'} timeFormat={'HH:mm:ss'} onChange={props.handleChangeDay} inputProps={{ placeholder: 'YYYY-MM-DD HH:mm:ss', readOnly: true }} locale="es" />
                                    }
                                    <div style={{height: '25px'}} />
                                </FormGroup>
                            </Form>
                        </Col>
                        <Col className="buttonModal">
                            <Col className="col-10 offset-1">
                                <Row>
                                    <Col className=" col-6 offset-3">
                                        <Button className="btn-block btn-guarumo-modal-sm" type="submit" form="frm_notification">{i18next.t('save')}</Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Col>

                    </ModalBody>
                </Modal>                  
               <ModalCustomURL clean={props.cleanInput} isOpen={props.isOpenCustomUrl} toggleModal={props.toggleModalCustomUrl} varList={props.varList} ConcatItem={props.ConcatItem} updateItem={props.updateItem} dataurl={props.dataurl}/>
           
           </Admin>
        </div>
    )
}

export default MassiveCampaignLayout