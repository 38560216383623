import React, { Component } from 'react';
import TemplateLayout from '../../campaigns/templates/template-layout';
import ModalLoad from '../../../components/modals/Loading/moda-loading';
import { connect } from 'react-redux';
import { getAllTemplate } from '../../../redux/actions/templateActions';
import { withRouter } from "react-router-dom";
import {
  isEmpty,
} from "../../../utils/commonFunction";
import { setSelectedBot } from '../../../redux/actions/botActions';

class Templates extends Component {
  state = {
    botId: this.props.match.params.id,
    ia_activated: false,
  }
  
  constructor(props) {
    super(props);
    
  }
  async componentDidMount() {
    const { listAllTemplate } = this.props.templateReducer;

    const { bot } = this.props.botReducer; 
    //obtengo el id del bot enviado como parametro en la url
    const { id } = this.props.match.params
    //guardo el id en el state
    this.setState({
        botId: id
    });

    if(isEmpty(bot) && id)
    {
      this.props.setSelectedBot(id);
    }

    if (isEmpty(listAllTemplate)) 
      await this.props.getAllTemplate();
  }

  render() {
    return (
      <div>
        <TemplateLayout ia_activated={this.state.ia_activated}
              botId={this.state.botId}  />
        <ModalLoad />
      </div>
    )
  }
}

const mapStateToProps = ({ templateReducer,botReducer }) => ({ templateReducer , botReducer});

const mapDispatchToProps = {
  getAllTemplate,
  setSelectedBot
};

export default connect(mapStateToProps, mapDispatchToProps)(Templates);
