// Actions
export const BOX_ACTIONS_TYPES = {

    /**
     *  Box Operator
     */
    requestGetListBoxOperator: () => 'REQUEST_GET_BOX_OPERATOR',
    successGetListBoxOperator: () => 'SUCCESS_GET_BOX_OPERATOR',
    errorGetListBoxOperator: () => 'ERROR_GET_BOX_OPERATOR',
    notFoundGetListBoxOperator: () => 'NOT_FOUND_BOX_OPERATOR',

    errorDeleteBoxOperator: () => 'ERROR_DELETE_BOX_OPERATOR',
    successDeleteBoxOperator: () => 'SUCCESS_DELETE_BOX_OPERATOR',
    errorUpdateBoxOperator: () => 'ERROR_UPDATE_BOX_OPERATOR',
    successUpdatBoxOperator: () => 'SUCCESS_UPDATE_BOX_OPERATOR',

    selectedBoxOperator: () => 'SELECTED_BOX_OPERATOR',
    toggleModalViewBoxCalendar: () => 'TOGGLE_MODAL_VIEW_BOX_CALENDAR',
    toggleModalViewBoxScanQR:() => 'TOGGLE_MODAL_VIEW_BOX_SCAN_QR',
    toggleModalViewBoxCatalog:() => 'TOGGLE_MODAL_VIEW_BOX_CATALOG',
    toggleModalViewBoxPayment:() => 'TOGGLE_MODAL_VIEW_BOX_PAYMENT',
    toggleModalViewBoxBlockchain: () => 'TOGGLE_MODAL_VIEW_BOX_BLOCKCHAIN',
    toggleModalViewBoxLocation: () => 'TOGGLE_MODAL_VIEW_BOX_LOCATION',
    toggleModalViewBoxCReport: () => 'TOGGLE_MODAL_VIEW_BOX_REPORT',
    toggleModalViewBoxCarousel: () => 'TOGGLE_MODAL_VIEW_BOX_CAROUSEL',
    toggleModalViewBoxAgent: () => 'TOGGLE_MODAL_VIEW_BOX_AGENT',
    toggleModalViewBoxMultimedia: () => 'TOGGLE_MODAL_VIEW_BOX_MULTIMEDIA',
    toggleModalViewBoxWebview: () => 'TOGGLE_MODAL_VIEW_BOX_WEBVIEW',
    toggleModalViewBoxVariable: () => 'TOGGLE_MODAL_VIEW_BOX_VARIABLE',
    toggleModalViewBoxUploadFile: () => 'TOGGLE_MODAL_VIEW_BOX_UPLOADFILE',
    toggleModalViewBoxTrigger: () => 'TOGGLE_MODAL_VIEW_BOX_TRIGGER',
    toggleModalViewBoxSMS: () => 'TOGGLE_MODAL_VIEW_BOX_SMS',
    toggleModalViewBoxText: () => 'TOGGLE_MODAL_VIEW_BOX_TEXT',
    toggleModalViewBoxQuickReply: () => 'TOGGLE_MODAL_VIEW_BOX_QUICKREPLY',
    toggleModalViewBoxGoTo: () => 'TOGGLE_MODAL_VIEW_BOX_GOTO',
    toggleModalViewBoxForm: () => 'TOGGLE_MODAL_VIEW_BOX_FORM',
    toggleModalViewBoxEmail: () => 'TOGGLE_MODAL_VIEW_BOX_EMAIL',
    toggleModalViewBoxButtons: () => 'TOGGLE_MODAL_VIEW_BOX_BUTTONS',
    toggleModalViewBoxDesuscription: () => 'TOGGLE_MODAL_VIEW_BOX_DESUSCRIPTIONS',
    toggleModalViewBoxConditional: () => 'TOGGLE_MODAL_VIEW_BOX_CONDITIONAL',
    toggleModalViewBoxApi: () => 'TOGGLE_MODAL_VIEW_BOX_API',
       
    changeActiveTabBoxOperator: () => 'TOGGLE_MODAL_TAB_BOX_OPERATOR',
    
    /**
     * Statements Box Operator
     */
    requestGetStatementBoxOperator: () => 'REQUEST_GET_STATEMENT_BOX_OPERATOR',
    successGetStatementBoxOperator: () => 'SUCCESS_GET_STATEMENT_BOX_OPERATOR',
    errorGetStatementBoxOperator: () => 'ERROR_GET_STATEMENT_BOX_OPERATOR',
    notFoundGetStatementBoxOperator: () => 'NOT_FOUND_STATEMENT_BOX_OPERATOR',
    setStatement: () => 'SET_STATEMENT',
    setBotmsgText: () => 'SET_BOTMSGTEXT',
    setAvatarid: () => 'SET_AVATARID',
    setData_ws: () => 'SET_DATA_WS',
    setMsmbtnPostback: () => 'SET_MSMBTNPOSTBACK',
    setData_dict: () => 'SET_DATA_DICT',
    setVarList: () => 'SET_VAR_LIST',
    setVarListSelected: () => 'SET_VAR_LIST_SELECTED',
    setTextBlock: () => 'SET_TEXT_BLOCK',
    setButtons: () => 'SET_BUTTONS',
    setPostback_buttons: () => 'SET_POSTBACK_BUTTONS',
    setNode: () => 'SET_NODE',
    setHead: () => 'SET_HEAD',
    /**
     * Config Box Operator
     */
    requestGetConfigBoxOperator: () => 'REQUEST_GET_CONFIG_BOX_OPERATOR',
    successGetConfigBoxOperator: () => 'SUCCESS_GET_CONFIG_BOX_OPERATOR',
    errorGetConfigBoxOperator: () => 'ERROR_GET_CONFIG_BOX_OPERATOR',
    notFoundGetConfigBoxOperator: () => 'NOT_FOUND_CONFIG_BOX_OPERATOR',
    /**
     * Types Calendar Box
     */
    requestGetListTypeCalendar: () => 'REQUEST_GET_LIST_TYPE_CALENDAR',
    successGetListTypeCalendar: () => 'SUCCESS_GET_LIST_TYPE_CALENDAR',
    errorGetListTypeCalendar: () => 'ERROR_GET_LIST_TYPE_CALENDAR',
    notFoundGetListTypeCalendar: () => 'NOT_FOUND_GET_LIST_TYPE_CALENDAR',
    selectedTypeCalendar: () => 'SELECTED_TYPE_CALENDAR',
    setStartDate: () => 'SELECTED_START_DATE_CALENDAR',
    setEndDate: () => 'SELECTED_END_DATE_CALENDAR',
    setShowTimeSelect: () => 'SELECTED_SHOW_TIME_SELECTED_CALENDAR',
    setTimeIntervals: () => 'SELECTED_TIME_INTERVALS_CALENDAR',
    setMinDate: () => 'SELECTED_MIN_DATE_CALENDAR',
    setMaxDate: () => 'SELECTED_MAX_DATE_CALENDAR',
    setMaxTime: () => 'SELECTED_MAX_TIME_CALENDAR',
    setMinTime: () => 'SELECTED_MIN_TIME_CALENDAR',
    setExcludeDates: () => 'SELECTED_EXCLUDE_DATES_CALENDAR',
    setExcludeTimes: () => 'SELECTED_EXCLUDE_TIMES_CALENDAR',  

    setSelectedProviderBlockchain: () => 'SELECTED_PROVIDER_BLOCKCHAIN', 
    setSelectedContractBlockchain: () => 'SELECTED_CONTRACT_BLOCKCHAIN', 
    setListProviderBlockchain: () => 'LIST_PROVIDER_BLOCKCHAIN', 
    setListContractBlockchain: () => 'LIST_CONTRACT_BLOCKCHAIN', 
    
    /**
     * Types Location Types Box
     */
    requestGetListTypeLocation: () => 'REQUEST_GET_LIST_TYPE_LOCATION',
    successGetListTypeLocation: () => 'SUCCESS_GET_LIST_TYPE_LOCATION',
    errorGetListTypeLocation: () => 'ERROR_GET_LIST_TYPE_LOCATION',
    notFoundGetListTypeLocation: () => 'NOT_FOUND_GET_LIST_TYPE_LOCATION',
    selectedTypeLocation: () => 'SELECTED_TYPE_LOCATION',

    /**
     * Types Location Box
     */
    requestGetListLocation: () => 'REQUEST_GET_LIST_LOCATION',
    successGetListLocation: () => 'SUCCESS_GET_LIST_LOCATION',
    errorGetListLocation: () => 'ERROR_GET_LIST_LOCATION',
    notFoundGetListLocation: () => 'NOT_FOUND_GET_LIST_LOCATION',
    selectedListLocation: () => 'SELECTED_LIST_LOCATION',

    setListTypeScanner: () => 'SELECTED_LIST_SCANNER',
    setListTypeCatalog: () => 'SELECTED_LIST_CATALOG',

    /**
     * Types Multimedia Box
     */
    selectedTypeMultimedia: () => 'SELECTED_TYPE_MULTIMEDIA',
    setUrlMultimediaPreview: () => 'SET_URL_MULTIMEDIA_PREVIEW',
    setFileMultimediaPreview: () => 'SET_FILE_MULTIMEDIA_PREVIEW',
    setContentType: () => 'SET_CONTENT_TYPE',

    setVariablesBoxOperator: () => 'SET_VARIABLES_BOX_OPERATOR',
    setDinamicVariablesBoxOperator: () => 'SET_DINAMIC_VARIABLES_BOX_OPERATOR',
    setExitVar: () => 'SET_EXIT_VAR',
    setListFileTemplate: () => 'SET_LIST_FILE_TEMPLATE',


};
  