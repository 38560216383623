import React, { Component } from 'react';
import VariableLayout from './variable-layout';
import axios from 'axios';
import qs from 'qs';

import { cerrarSesion } from '../../../../utils/commonFunction'
import { getHandlerPUT, getHandlerPOST } from '../../../../utils/handlersApi';

class VariableModal extends Component {
 
    state = {
        variables: [],
        nodeId: ""
    }

    constructor(props){
        super(props)
    }

    closeModal = () => this.props.closeModal('m18');    

    toggleModuleModal = () => {
        const URL = HOST_API +'/api/scheme/statement/';
        this.props.modal.node.extras.variables.map((item, index)=>{
            const text = $('#TMM-input-'+index).val();
            this.props.modal.node.extras.variables[index].value = text;
           
            const data = {
                id: item.id,
                text: item.name,
                name: item.name,
                is_var: true,
                var_name: item.name
            };

            getHandlerPUT(URL, data)
            .then((res)=>{})
            .catch(( error )=>{
                console.log('error on toggleModuleModal ',error);
            });

        });

        this.props.updateSchema();
        this.props.toggleModuleModal('variable');
        this.forceUpdate();
    }


    removeStatement = (statement) => {
        const data = {
            id: statement,
        };

        var auth=JSON.parse(localStorage.getItem('Authorization'));
        var headers={
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer '+ auth.access_token 
        }

        return axios({
            method: 'delete',
            url: HOST_API +'/api/scheme/statement/',
            data: qs.stringify(data),
            headers: headers
        })
    }

   
    addNewTextBlock = async() => {
        const URL =  `${HOST_API}/api/scheme/statement/`;
        const operator = this.props.modal.node.extras.dbId;
        const data = {
            operator_id: operator,
            text: '',
            name: ''
        };
     

        //TODO: pendiente validar name: 'var_'+stid

        // let blocks = node.extras.variables;
        // const stid = res.data.statement.id;

        // blocks[stid] = {
        //     name: "var_" + stid,
        //     value: 1
        // };
        //  name: "var_" + res.data.statement.id, 

        await getHandlerPOST(URL,data)
        .then((res)=>{
            if (res.status===200 ) {
                this.props.modal.node.extras.variables.push({
                    value: res.data.statement.text, 
                    name: res.data.statement.name, 
                    id: res.data.statement.id,
                    isNew: true
                });
                this.forceUpdate();
            }
        })
        .catch((error)=>{
            console.log('Error on addCurrentVar: ',error);
        });
    }

    removeTextBlock = (index) => {
        this.removeStatement(index)
        .then(res => {
            this.props.updateSchema()
        }).catch((error) => {
            console.log('Catch removeTextBlock:',error);
            if (error.response.status == 403)
              cerrarSesion();
        }); 
        this.forceUpdate();
    }

    removeParameter = (key) => {
        this.props.modal.node.extras.variables.splice(key, 1);        
        this.forceUpdate();
    }

    addCurrentVar = (statement) => {
        
        this.props.modal.node.extras.variables.push(statement);
        this.forceUpdate();
       
    }

    updateReceivedKey = (key, value, isNAme) => {   
        const statement = this.props.modal.node.extras.variables[key];
        if(isNAme && isNAme === 'name' ){
            statement.name = value;
        }else{
            statement.value = value;
        }
        this.props.modal.node.extras.variables[key] = statement;
        this.forceUpdate();
    }

    render() {
        return (
          <VariableLayout 
          isOpen = {this.props.modal.isOpen} 
          varList = {this.props.varList}
          botversion_id = {this.props.botversion_id}
          operator_id = {this.props.modal.node.extras.dbId}
          variables = {this.props.modal.node.extras.variables || [] } 
          
          // Functions
          addCurrentVar = {this.addCurrentVar}
          addNewTextBlock = {this.addNewTextBlock}
          closeModal = {this.closeModal}          
          toggleModuleModal = {this.toggleModuleModal}
          removeParameter = {this.removeParameter}
          updateReceivedKey = {this.updateReceivedKey}
          />
        )
    }
}

export default VariableModal