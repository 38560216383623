import React from 'react';
import d3DataToJSX from '../../../../../components/infoviz/react-d3/d3DataToJSX';
import ChildComponent from './ChildComponent';
import createScaleColor from '../basic-graphs/createScaleColor';
import FormatD3 from '../../../../../components/infoviz/utils/defineFormats'

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {d3DOM: [], state: [],nameDiv: " "};
  }

  
  componentWillMount() {
    this.setState({nameDiv:this.props.nameDiv });
  }
  componentWillReceiveProps(nextProps) {
    let d3Data = d3DataToJSX(createScaleColor(nextProps.nameDiv,nextProps.data,nextProps.color, nextProps.typeScaleColor,nextProps.short_name));
    this.setState({d3DOM: d3Data.mappedData, state: d3Data.state})
  }

  render() {
    return (
      <React.Fragment>
        <ChildComponent data={this.state} />
        <div id={"tooltip_"+this.state.nameDiv} className="tooltip"></div>
      </React.Fragment>
    )
  }
};