import React, { Component } from 'react';
import UFMLayout from './upload-file-modal-layout';
import axios from 'axios';
import qs from 'qs';
import SMPortModel from '../simplePortModel/SMPortModel';
import { getHandlerPOST, getHandlerPUT, getHandlerDELETE } from '../../../../utils/handlersApi'
import { cerrarSesion } from '../../../../utils/commonFunction'

class UFModal extends Component {

    state = {
        nodeId: "",
        title:"",
        isVariable:false,
        name_variable: "",
        selection:  null,
        range: null,
        keyword_valid: true,
        timeout_valid: true,
        error_input_cancel: false,
        error_input_empty:false,
        error_head_empty:false,
        error_variable_empty:false,
        keyword: "",
    }

    constructor(props){
        super(props);
    }

    getAvatarId = (avatarid) => {
        this.props.modal.node.extras.avatar = avatarid;
    }
    getTitleOperator = (titleOperator) => {
        this.props.modal.node.extras.titleOperator = titleOperator
    }

    getVariable = (isVar,name_var) => {
        this.setState({isVariable:isVar,name_variable:name_var})
       
        this.props.modal.node.extras.isVariable = isVar;
        this.props.modal.node.extras.name_variable = name_var;
    }

    validateEmptyHead = (node)=>{
        let blocks = node.extras.botmsg_text;
        let error_head_empty = false
        let err = false;
        if(blocks.length <= 0){
            err = true;
        }
        error_head_empty = blocks.length <= 0;
        this.setState({
            error_head_empty: error_head_empty
        }, ()=> {
            this.forceUpdate();
        });
        return err;
    }
    validateEmptyVariable = ()=>{
        let err=false;

        if(this.state.isVariable==true&&this.state.name_variable==''){
            err = true;
        }
        
        this.setState({
            error_variable_empty: err
        }, ()=> {
            this.forceUpdate();
        });
        return err;
    }
    validateEmptyButton = (node)=>{
        let button = node.extras.cancelmsg;
        let error_input_empty=false
        let err = false;
        if(button.length <= 0){
            err = true;
        }
        error_input_empty = button.length <= 0;
        this.setState({
            error_input_empty:error_input_empty
        }, ()=> {
            this.forceUpdate();
        });
        return err;
    }
    

    toggleModuleModal = () =>{
        if(!this.state.keyword_valid)
            return false;

        let form = new FormData(document.getElementById('UFM-form'));
        let node = this.props.modal.node;

        if(!this.state.timeout_valid)
            return false
        if(this.validateEmptyHead(node))
            return false;
        const ports = node.getPorts();
        const kports = Object.keys(ports);

        const kport = kports.find(k => {
            const port = ports[k]
            return port.name == "right-timeout"
        })
        let title = this.props.modal.node.extras.titleOperator;
        this.saveTitle(title).then(res => {
            this.props.updateSchema();
            this.forceUpdate();
        }).catch((error)=>{
            console.log('catch saveTitle',error);
            if (error.response && error.response.status && error.response.status == 403){
                cerrarSesion();
            }
        });

        if(!kport){
            if(node.extras.timeout > 0){
                node.addPort(new SMPortModel(true, "right-timeout", 'sm'));
            }            
        }else{
            if(node.extras.timeout <= 0){
                kports.forEach((kport) => {
                    const port = ports[kport];                     
                    if(port.name == "right-timeout"){
                        const links = port.getLinks();
                        const klinks = Object.keys(links);
                        klinks.forEach(klink => {
                            const link = links[klink];
                            link.remove();
                        });
                        node.removePort(port);
                    }                    
                })
            } 
        }
       
        node.extras.cancelmsg = form.get('cancelmsg');
        if(this.validateEmptyButton(node))
            return false;
        node.extras.botmsg_text =  $('#input-head').val();
        //node.extras.keyword = form.get('keyword');
        node.extras.botmsg_id = form.get('botmsg_id');

        node.extras.isVariable = $('#checkbox_variable')[0].checked
        node.extras.name_variable = form.get('name_variable');//this.state.name_variable;

        if(this.validateEmptyVariable())
        {
            console.log("error");
            return false;
        }
        else{

        

        //create the botmsg statement if it doesn't exists.
        if(!node.extras.botmsg_id){
            this.saveStatement(node.extras.dbId)
                .then((res) => {
                    if (res.status== 200){
                        node.extras.botmsg_id = res.data.statement.id;
                        this.updateStatement(node.extras.botmsg_id, node.extras.botmsg_text,node.extras.botmsg_text,node.extras.isVariable,node.extras.name_variable).then((res) => {
                            this.props.updateSchema();
                            this.forceUpdate();
                        })
                    }

                }).catch((error)=>{
                    console.log('catch saveStatement',error);
                    if (error.response && error.response.status && error.response.status == 403){
                        cerrarSesion();
                    }
                });
        }else { // edit the statement.
            this.updateStatement(node.extras.botmsg_id, node.extras.botmsg_text, node.extras.botmsg_text,node.extras.isVariable,node.extras.name_variable).then((res) => {
                this.props.updateSchema();
                this.forceUpdate();
            }).catch((error)=>{
                console.log('catch updateStatement',error);
                if (error.response && error.response.status && error.response.status == 403){
                    cerrarSesion();
                }
            });
        }

        if(this.state.keyword_valid)
            this.props.toggleModuleModal('upload_file');
        }

    }

    saveTitle = (title) => {
        const data = {
            id: this.props.modal.node.extras.dbId,
            title:title
        };

        var auth=JSON.parse(localStorage.getItem('Authorization'));
        var headers={
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer '+ auth.access_token 
        }
        return axios({
            method:'put',
            url:HOST_API +'/api/scheme/operator/',
            data:qs.stringify(data),
            headers:headers
        })
    }
    
    saveStatement = (operator) => {
        const data = {
            operator_id: operator,
            text: "",
            name: "",
            isVariable:false,
            name_variable:""
        };

        var auth=JSON.parse(localStorage.getItem('Authorization'));
        var headers={
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer '+ auth.access_token 
        }
        return axios({
            method:'post',
            url:HOST_API +'/api/scheme/statement/',
            data:qs.stringify(data),
            headers:headers
        });
    }

    updateStatement = (statement, text, name,is_var,name_var) => {
        const data = {
            id: statement,
            text: text,
            name: name,
            is_var:is_var,
            var_name:name_var
        };

        var auth=JSON.parse(localStorage.getItem('Authorization'));
        var headers={
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer '+ auth.access_token 
        }
        return axios({
            method:'put',
            url:HOST_API +'/api/scheme/statement/',
            data:qs.stringify(data),
            headers:headers
        });
    }


    validateCancelMsg = (event) => {
        let data = event.target.value.length;
        let button = event.target.value;
        let error_input_empty=false
        let e = this.state.error_input_cancel;
        if(button.length <= 0)
            e = true;
        error_input_empty = button.length <= 0;
        this.setState({
            error_input_empty:error_input_empty
        }, ()=> {
            this.forceUpdate();
        });
        if (event.which !== 8) {
            if(data >= 20){
                event.preventDefault(); 
                event.stopPropagation();
                e = true
                this.setState({error_input_cancel:e})
            }else{
                e = false;
                this.setState({error_input_cancel:e})
            }
        }else{
            e = false;
            this.setState({error_input_cancel:e})
        }
        return e;
    }



    validateEmptyButton = (node)=>{
        let button = node.extras.cancelmsg;
        let error_input_empty=false
        let err = false;
        if(button.length <= 0)
            err = true;
        error_input_empty = button.length <= 0;
        this.setState({ error_input_empty:error_input_empty }, ()=> { this.forceUpdate(); });
        return err;
    }


    updateKeyword = (text) => {    
        this.props.modal.node.extras.keyword = text;
        this.saveKeyword(text);
        this.forceUpdate();
    }

    /* Update data de keyword */
    saveKeyword = (text) => {
        const data = {
            id: this.props.modal.node.extras.dbId,
            keyword:text
        };
        getHandlerPUT(HOST_API +'/api/scheme/operator/',data)
            .then((res)=>{
                if(res.status!=200)
                    console.log('Error actualizando updateKeyword',res);
            }).catch((error) => { 
                console.log('catch updateKeyword',error)
                if (error.response.status == 403)
                    cerrarSesion();
            }); 
    }

    isKeywordValid = (val) => {        
        this.setState({keyword_valid:val}, ()=>{
            this.forceUpdate();
        })
    }
    /* END SEARCH VARIABLE FUNCTIONS AND HANDLERS */
    updateItem = (text) => {  
        this.props.modal.node.extras.botmsg_text = text;
        this.forceUpdate();
    }
    closeModal = () => {
        this.props.closeModal('m3');
        this.forceUpdate();
    }

    getmsgError= (msgError)=>{
        this.props.modal.node.extras.msgError = msgError
    }

    getExtensions=(extensions)=>{
        this.props.modal.node.extras.extensions = extensions
   
    }

    handleDeleteExtension=(extensions)=>{
        let index = this.props.modal.node.extras.extensions.indexOf(extensions);
        if (index > -1) {
            this.props.modal.node.extras.extensions.splice(index, 1);
        }
        this.forceUpdate();
    }

    // Timeout functions
    getTimeout = (time) => {
        this.props.modal.node.extras.timeout = time
    }

    getMsgTimeout = (msg) => {
        this.props.modal.node.extras.msg_timeout = msg
    }

    isTimeoutValid = (valid) => {
        this.setState({timeout_valid: valid}, ()=>{
            this.forceUpdate();
        })
    }

        //Emoji
        callEmoji = (code,k,type)=>{
            if(type === "head"){
            let text = this.props.modal.node.extras.botmsg_text
                text += ` ${code}`;
                this.updateItem(text)
            }else{
            let text = $('#cancelmsgText').val();
                text += ` ${code}`;
                $('#cancelmsgText').val(text)  
            }
        }

         // IA set node extras
    getIAId = IAid => this.props.modal.node.extras.ia_id = IAid;
    
    render() {
        return (
          <UFMLayout 
          toggleModuleModal={this.toggleModuleModal}
          isOpen={this.props.modal.isOpen} 
          botmsg_text={this.props.modal.node.extras.botmsg_text} 
          botmsg_id={this.props.modal.node.extras.botmsg_id} 
          cancelmsg={this.props.modal.node.extras.cancelmsg} 
          nodeId={this.state.nodeId} 
          title={this.state.title}
          isVariable={this.props.modal.node.extras.isVariable || false}
          name_variable={this.props.modal.node.extras.name_variable || ''}
          keyword={this.props.modal.node.extras.keyword} 
          isKeywordValid={this.isKeywordValid}
          updateKeyword={this.updateKeyword}
          varList={this.props.varList}
          botversion_id={this.props.botversion_id}
          operator_id={this.props.modal.node.extras.dbId}
          ia={this.props.modal.node.extras.ia_id || 'none'}
          error_head_empty={this.state.error_head_empty}
          error_variable_empty={this.state.error_variable_empty}
          error_input_cancel={this.state.error_input_cancel}
          error_input_empty={this.state.error_input_empty}
          validateCancelMsg={this.validateCancelMsg}
          closeModal={this.closeModal}
          updateItem={this.updateItem}
          getTimeout={this.getTimeout}
          getMsgTimeout={this.getMsgTimeout}
          isTimeoutValid={this.isTimeoutValid}
          timeoutValue={this.props.modal.node.extras.timeout}
          timeoutMsg={this.props.modal.node.extras.msg_timeout}
          avatarid={this.props.modal.node.extras.avatar || 0}
          getAvatarId={this.getAvatarId}
          getIAId = {this.getIAId}
          company_id={this.props.company_id}
          getTitleOperator = {this.getTitleOperator}
          getVariable = {this.getVariable}
          titleOperator = {this.props.modal.node.extras.titleOperator || ''}
          callEmoji = {this.callEmoji}
          getmsgError={this.getmsgError}
          msgError={this.props.modal.node.extras.msgError}
          getExtensions={this.getExtensions}
          extensions={this.props.modal.node.extras.extensions}
          handleDeleteExtension={this.handleDeleteExtension}
          />
        )
    }
}

export default UFModal