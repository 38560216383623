// Actions
export const ANALYTICS_ACTIONS_TYPES = {

    /**
     * SABANA DE DATOS
     */
    setResulset: () => 'SET_RESULTSET',
    setReportType: () => 'SET_REPORT_TYPE',
    setListAllReports: () => 'SET_LIST_ALL_REPORT',
    setSelectedReport: () => 'SET_SELECTED_REPORT',
    /**
     * Types Bots
     */
    notFoundGetListBotsbyCompany: () => 'NOT_FOUND_GET_LIST_BOTS_BY_COMPANY',
    requestGetListBotsbyCompany: () => 'REQUEST_GET_LIST_BOTS_BY_COMPANY',
    successGetListBotsbyCompany: () => 'SUCCESS_GET_LIST_BOTS_BY_COMPANY',
    errorGetListBotsbyCompany: () => 'ERROR_GET_LIST_BOTS_BY_COMPANY',

    /**
     * Types Chats_traceability
     */
    notFoundGetListChats: () => 'NOT_FOUND_GET_LIST_CHATS',
    requestGetListChats: () => 'REQUEST_GET_LIST_CHATS',
    successGetListChats: () => 'SUCCESS_GET_LIST_CHATS',
    errorGetListChats: () => 'ERROR_GET_LIST_CHATS',

    /**
     * Types Metrics
     */
    successGetTotalMetrics: () => 'SUCCESS_GET_TOTAL_METRICS',
    notFoundGetListMetrics: () => 'NOT_FOUND_GET_LIST_METRICS',
    requestGetListMetrics: () => 'REQUEST_GET_LIST_METRICS',
    successGetListMetrics: () => 'SUCCESS_GET_LIST_METRICS',
    successGetListMeasures: () => 'SUCCESS_GET_LIST_MEASURES',
    errorGetListMetrics: () => 'ERROR_GET_LIST_METRICS',
    toggleModalNewMetric: () => 'TOGGLE_MODAL_NEW_METRIC',
    toggleModalViewMetric: () => 'TOGGLE_MODAL_VIEW_METRIC',
    errorDeleteMetric: () => 'ERROR_DELETE_METRIC',
    successDeleteMetric: () => 'SUCCESS_DELETE_METRIC',
    errorUpdateMetric: () => 'ERROR_UPDATE_METRIC',
    successUpdateMetric: () => 'SUCCESS_UPDATE_METRIC',
    selectedMetricChange: () => 'SELECTED_METRIC',
    selectedUpdateMetric: () => 'SELECTED_UPDATE_METRIC',
    selectedCreateMetrica: () => 'SELECTED_CREATE_METRIC',

     /**
     * Types Charts
     */

    successGetTotalCharts: () => 'SUCCESS_GET_TOTAL_CHARTS',
    notFoundGetListCharts: () => 'NOT_FOUND_GET_LIST_CHARTS',
    requestGetListCharts: () => 'REQUEST_GET_LIST_CHARTS',
    successGetListCharts: () => 'SUCCESS_GET_LIST_CHARTS',
    errorGetListCharts: () => 'ERROR_GET_LIST_CHARTS',
    toggleModalNewChart: () => 'TOGGLE_MODAL_NEW_CHART',
    toggleModalViewChart: () => 'TOGGLE_MODAL_VIEW_CHART',
    errorDeleteChart: () => 'ERROR_DELETE_CHART',
    successDeleteChart: () => 'SUCCESS_DELETE_CHART',
    errorUpdateChart: () => 'ERROR_UPDATE_CHART',
    successUpdateChart: () => 'SUCCESS_UPDATE_CHART',
    selectedViewChart: () => 'SELECTED_VIEW_CHART',
    selectedUpdateChart: () => 'SELECTED_UPDATE_CHART',
    selectedCreateChartR: () => 'SELECTED_CREATE_CHART',

     /**
     * Types Dashboards
     */
    successGetTotalDashboards: () => 'SUCCESS_GET_TOTAL_DASHBOARDS',
    notFoundGetListDashboards: () => 'NOT_FOUND_GET_LIST_DASHBOARDS',
    requestGetListDashboards: () => 'REQUEST_GET_LIST_DASHBOARDS',
    successGetListDashboards: () => 'SUCCESS_GET_LIST_DASHBOARDS',
    errorGetListDashboards: () => 'ERROR_GET_LIST_DASHBOARDS',
    toggleModalNewDashboards: () => 'TOGGLE_MODAL_NEW_DASHBOARD',
    errorDeleteDashboard: () => 'ERROR_DELETE_DASHBOARD',
    successDeleteDashboard: () => 'SUCCESS_DELETE_DASHBOARD',
    errorUpdateDashboard: () => 'ERROR_UPDATE_DASHBOARD',
    successUpdateDashboard: () => 'SUCCESS_UPDATE_DASHBOARD',
    selectedViewDashboard: () => 'SELECTED_VIEW_DASHBOARD',
    selectedViewDashboardModal: () => 'SELECTED_VIEW_DASHBOARD_MODAL',
    selectedUpdateDashboard: () => 'SELECTED_UPDATE_DASHBOARD',
    selectedCreateDashboards: () => 'SELECTED_CREATE_DASHBOARD',
    cleanListDashboards: () =>'CLEAN_LIST_DASHBOARD',
    cleanListMetrics: () =>'CLEAN_LIST_METRICS',
    cleanListCharts: () =>'CLEAN_LIST_CHARTS',
    cleanListBots: () =>'CLEAN_LIST_BOTS',
     /**
     * Types Units
     */
    successGetTotalUnits: () => 'SUCCESS_GET_TOTAL_UNITS',
    notFoundGetListUnits: () => 'NOT_FOUND_GET_LIST_UNITS',
    requestGetListUnits: () => 'REQUEST_GET_LIST_UNITS',
    successGetListUnits: () => 'SUCCESS_GET_LIST_UNITS',
    errorGetListUnits: () => 'ERROR_GET_LIST_UNITS',

     /**
     * Types Dimensions
     */
    successGetTotalDimensions: () => 'SUCCESS_GET_TOTAL_DIMENSIONS',
    notFoundGetListDimensions: () => 'NOT_FOUND_GET_LIST_DIMENSIONS',
    requestGetListDimensions: () => 'REQUEST_GET_LIST_DIMENSIONS',
    successGetListDimensions: () => 'SUCCESS_GET_LIST_DIMENSIONS',
    errorGetListDimensions: () => 'ERROR_GET_LIST_DIMENSIONS',

     /**
     * Types type_chart
     */
    successGetTotalTypeCharts: () => 'SUCCESS_GET_TOTAL_TYPECHARTS',
    notFoundGetListTypeCharts: () => 'NOT_FOUND_GET_LIST_TYPECHARTS',
    requestGetListTypeCharts: () => 'REQUEST_GET_LIST_TYPECHARTS',
    successGetListTypeCharts: () => 'SUCCESS_GET_LIST_TYPECHARTS',
    errorGetListTypeCharts: () => 'ERROR_GET_LIST_TYPECHARTS',
    getListSelectedTypeChart: () =>'LIST_SELCTED_TYPECHARTS',
    getTypeChart: () => 'GET_TYPECHART',
    getPreviews: () => 'GET_PREVIEW',
    getAllPreview: () => 'GET_ALL_PREVIEWS',

    /**
     * LANGUAGES
     */
    successGetListLanguages: () => 'SUCCESS_GET_LANGUAGES',
    errorGetListLanguages: () => 'ERROR_GET_LIST_LANGUAGES',
    requestGetListLanguages: () =>'LIST_SELCTED_LANGUAGES',

/*
*DataInteractionReport
*/
    successGetListDataInteractionReport: () => 'SUCCESS_GET_DATA_INTERACTION_REPORT',
    errorGetListDataInteractionReport: () => 'ERROR_GET_LIST_DATA_INTERACTION_REPORT',
    requestGetListDataInteractionReport: () =>'LIST_SELCTED_DATA_INTERACTION_REPORT',
    
    /**
     * Types Search and pagination
     */
    setPagination: () => 'SET_PAGINATION',
    setDownloadLink: () => 'SET_DOWNLOAD_LINK',

    changePageActive: () => 'CHANGE_PAGE_ACTIVE',
    changeActiveTabMetric: () => 'CHANGE_TAB_ACTIVE',
    handleSearching: () => 'HANDLE_SEARCHING',
    handleClearSearcher: () => 'HANDLE_CLEAR_SEARCHER',
    changeBetweenList:() => 'CHANGE_BETWEEN_LISTS',
    countMeasure: () => 'COUNT_MEASURES',
    countDimension: () => 'COUNT_DIMENSIONS',
    countTimeDimension: () =>'TIME_DIMENSIONS',
    cleanPreview: () => 'CLEAN_PREVIEW',
    cleanAllPreview: () => 'CLEAN_ALL_PREVIEWS',
    getItems: () => 'GET_ITEMS',
    cleanAllItems: () => 'CLEAN_ALL_ITEMS',

    setDataChart:() =>'SET_DATA_CHART',
    cleanDataChart:() =>'CLEAN_DATA_CHART',


    selectedCompany: () => 'SELECTED_COMPANY',
    selectedBot: () => 'SELECTED_BOT',
    handleChangeStart: () =>'HANDLE_CHANGE_START_DATE',
    handleChangeEnd: () =>'HANDLE_CHANGE_END_DATE',
    handleChangeGranularity: () => 'HANDLE_CHANGE_GRANULARITY',

    /**
     * Types Plafforms
     */
    requestGetListPlafforms: () => 'REQUEST_GET_LIST_PLAFFORMS',
    successGetListPlafforms: () => 'SUCCESS_GET_LIST_PLAFFORMS',
    errorGetListPlafforms: () => 'ERROR_GET_LIST_PLAFFORMS',

    /**
     * Types Palettes
     */
    requestGetListPalettes: () => 'REQUEST_GET_LIST_PALETTES',
    successGetListPalettes: () => 'SUCCESS_GET_LIST_PALETTES',
    errorGetListPalettes: () => 'ERROR_GET_LIST_PALETTES',
    selectedPalette: () => 'SELECTED_PALETTE',

   
    setLoadReport: () => 'SET_LOAD_REPORT',
    setOperationFinal: () => 'SET_OPERATION_FINAL',
    setOperationCount: () => 'SET_OPERATION_COUNT',
    setUiReport: () => 'SET_UI_REPORT',

    /*
     * TOCKEN CUBEJS
     */
    setTockenCube:()=> 'SET_TOCKEN_CUBEJS',


};
  