import React, { Component } from 'react';
import IFMLayout from './if-modal-layout';
import axios from 'axios';
import qs from 'qs';

import { cerrarSesion } from '../../../../utils/commonFunction'

let NEW_CONDITIONAL = {
    conditionLeft: "",
    conditionRight: "",
    operator: "",
    connector: ""
};

class IFModal extends Component {

    state = {
        nodeId: "",        
        show_error: false
    }

    constructor(props){
        super(props);
    }

    getTitleOperator = (titleOperator) => {
        this.props.modal.node.extras.titleOperator = titleOperator
    }

    removeConditionalBlock = (index) =>{
        let e_conditionals = this.props.modal.node.extras.conditionals;
        delete e_conditionals[index];
        this.forceUpdate();
    }

    addConditionalBlock = () => {
        let extras = this.props.modal.node.extras;
        let k = Object.keys(extras.conditionals);
        let i = 0;

        if(k.length >= 4)
            return false

        if(k.length > 0){
            let b = k.map(function (x) { 
                return parseInt(x, 10); 
            });
            i = Math.max(...b) + 1;
        }
        NEW_CONDITIONAL = {
            conditionLeft: "",
            conditionRight: "",
            operator: "",
            connector: ""
        };
        extras.conditionals[String(i)] = NEW_CONDITIONAL;
        this.forceUpdate();
    }

    toggleModuleModal = () =>{
        let node = this.props.modal.node;
        let err = false;
        let k = Object.keys(node.extras.conditionals);
        let title = this.props.modal.node.extras.titleOperator;
        this.saveTitle(title).then(res => {
            this.props.updateSchema();
            this.forceUpdate();
        }).catch((error) => {
            console.log('Catch saveTitle:',error);
            if (error.response.status == 403)
              cerrarSesion();
        });
        k.forEach((key) => {
            let condition = node.extras.conditionals[key];

            if(condition.conditionLeft == "" || condition.conditionLeft.length <= 0) {
                err = true;
            }
    
            if(condition.conditionRight == "" || condition.conditionRight.length <= 0) {
                err = true;
            }
    
            if(condition.operator == "" || condition.operator.length <= 0) {
                err = true;
            }
        });

        this.setState({show_error: err});
        if(err){            
            return false;
        }
        
        this.props.updateSchema();
        this.closeModal()
    }

    saveTitle = (title) => {
        const data = {
            id: this.props.modal.node.extras.dbId,
            title:title
        };

        var auth=JSON.parse(localStorage.getItem('Authorization'));
        var headers={
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer '+ auth.access_token 
        }

        return axios({
            method:'put',
            url:HOST_API +'/api/scheme/operator/',
            data:qs.stringify(data),
            headers:headers
        })
    }

    setConditional = (pos, key, val) => {
        let conditionals_ = this.props.modal.node.extras.conditionals;
        if(!conditionals_[pos]){
            conditionals_[pos] = {
                conditionLeft: "",
                conditionRight: "",
                operator: "",
                connector: ""
            };
        }        
        conditionals_[pos][key] =  val;
        this.forceUpdate()
    }
    /* END SEARCH VARIABLE FUNCTIONS AND HANDLERS */

    closeModal = () => {
        this.props.closeModal('m_if');
        this.forceUpdate();
    }

    parseConditionals = () => {
        let extras = this.props.modal.node.extras;
        let c = {
            "0": NEW_CONDITIONAL
        };

        if(Object.keys(extras).indexOf("conditionals") >= 0){
            if(Object.keys(extras.conditionals).length > 0){
                c = extras.conditionals;
            }
            else
            {
                NEW_CONDITIONAL = {
                    conditionLeft: "",
                    conditionRight: "",
                    operator: "",
                    connector: ""
                };
                c = {
                    "0": NEW_CONDITIONAL
                };
        
            }
        }
        
        
        return c;        
    }

    render() {
        let conditionals = this.parseConditionals();

        return (
          <IFMLayout 
          toggleModuleModal={this.toggleModuleModal}
          isOpen={this.props.modal.isOpen} 
          botmsg_text={this.props.modal.node.extras.botmsg_text} 
          botmsg_name={this.props.modal.node.extras.botmsg_name} 
          botmsg_id={this.props.modal.node.extras.botmsg_id} 
          cancelmsg={this.props.modal.node.extras.cancelmsg} 
          nodeId={this.state.nodeId} 
          keyword={this.props.modal.node.extras.keyword} 
          isKeywordValid={this.isKeywordValid}
          updateKeyword={this.updateKeyword}
          title={this.state.title} 
          insertVar={this.insertVar}
          selectEditable={this.selectEditable}
          avoidEnter={this.avoidEnter}
          botversion_id={this.props.botversion_id}
          operator_id={this.props.modal.node.extras.dbId}
          ia={this.props.modal.node.extras.ia_id || 'none'}
          error_input_mensaje={this.state.error_input_mensaje}
          error_input_cancel={this.state.error_input_cancel}
          validateCancelMsg={this.validateCancelMsg}

          closeModal={this.closeModal}
          varList={this.props.varList}
          setConditional={this.setConditional}
          show_error={this.state.show_error}
          conditionals={conditionals}
          addConditionalBlock={this.addConditionalBlock}
          removeConditionalBlock={this.removeConditionalBlock}
          getTitleOperator = {this.getTitleOperator}
          titleOperator = {this.props.modal.node.extras.titleOperator || ''}
          />
        )
    }
}

export default IFModal